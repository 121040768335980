import { ACCEPTED_MIME_TYPES } from "@/components/FilesContents/AcceptedMIMETypes";
import { getApiUrl } from "@/store/emptyApi";
import { CustomError } from "./CustomError";

export const useUploadFiles = () => async (files: File[], basePath: string) => {
    const apiUrl = getApiUrl();
    if (files.length === 0) return [];
    const allFilesAreValid = files.every((file) => {
        const fileType = file.type;
        const textRegex = /^text\/.*/;
        const imageRegex = /^image\/.*/;
        return ACCEPTED_MIME_TYPES.includes(fileType) || textRegex.test(fileType) || imageRegex.test(fileType);
    });
    if (!allFilesAreValid)
        throw new CustomError("アップロードされたファイルの形式はサポートされていません。", "FileTypeError");
    const formData = new FormData();
    files.forEach((file) => {
        formData.append("files", file);
    });
    formData.append("basePath", basePath);
    const urls = (
        await fetch(`${apiUrl}/api/v1/upload`, {
            method: "POST",
            body: formData,
        })
    ).json() as any as string[];
    return urls;
};

export const useUploadCoursethumbnailObjectKeys = async (files: File[], courseId: string) => {
    const apiUrl = getApiUrl();
    if (files.length === 0) return [];
    const allFilesAreValid = files.every((file) => {
        const fileType = file.type;
        const textRegex = /^text\/.*/;
        const imageRegex = /^image\/.*/;
        return ACCEPTED_MIME_TYPES.includes(fileType) || textRegex.test(fileType) || imageRegex.test(fileType);
    });
    if (!allFilesAreValid)
        throw new CustomError("アップロードされたファイルの形式はサポートされていません。", "FileTypeError");
    const formData = new FormData();
    files.forEach((file) => {
        formData.append("files", file);
    });
    formData.append("courseId", courseId);
    const objectKeys = (
        await fetch(`${apiUrl}/api/v1/upload/course`, {
            method: "POST",
            body: formData,
        })
    ).json() as any as string[];
    return objectKeys;
};

export const useUploadFilesForDummyData = async (files: File[], basePath: string) => {
    const apiUrl = getApiUrl();
    if (files.length === 0) return [];
    const formData = new FormData();
    files.forEach((file) => {
        formData.append("files", file);
    });
    formData.append("basePath", basePath);
    const regex = /[^/]+$/;
    const extensions = files.map((file) => {
        const extension = file.type.match(regex);
        if (!extension) return "";
        return extension[0];
    });
    formData.append("extensions", JSON.stringify(extensions));
    const objectKeys = (
        await fetch(`${apiUrl}/api/v1/upload`, {
            method: "POST",
            body: formData,
        })
    ).json() as any as string[];
    return objectKeys;
};

export const useUploadFileToStrope = async (file: File) => {
    const apiUrl = getApiUrl();
    const formData = new FormData();
    formData.append("file", file);
    const regex = /[^/]+$/;
    const regExpMatchArray = file.type.match(regex);
    if (!regExpMatchArray) throw new Error("File type is not supported");
    const extension = regExpMatchArray[0];
    formData.append("extension", extension);
    const fileId = (
        await fetch(`${apiUrl}/api/v1/stripe/upload`, {
            method: "POST",
            body: formData,
        })
    ).json() as any as string;
    return fileId;
};
