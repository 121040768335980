import { memo, useCallback, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Auth } from "aws-amplify";
import { logout } from "@/actions/logging";
import { useCognitoUser } from "@/components/Authentication";

import { FileInfo } from "@/components/TrimmingModal";
import { RootState } from "@/ducks";
import {
    CreateTeacherRequestBody,
    CreateUserRequestBody,
    EducationalBackgroundResponse,
    NominalTypingEducationalBackgroundString,
    SubjectResponse,
} from "@/store/autogenApi";
import { useCreateTeacherMutation } from "@/store/hooks/teachers";
import { useCreateUserMutation } from "@/store/hooks/users";
import { useUploadFiles } from "@/utils/UploadFiles";

import { teacherConfig } from "./Config";
import { FormContents } from "./FormContents";

interface Props {
    createUserRequestBody?: CreateUserRequestBody | undefined;
    deleteUser: () => void;
    setIsCompleted: React.Dispatch<React.SetStateAction<boolean>>;
    handleHasErrorChange: (hasError: boolean) => void;
}

export const TeacherProfileInput: React.VFC<Props> = memo(function TeacherProfileInput(props) {
    const [checked, setChecked] = useState<boolean>(false);
    const [iconImageFileInfo, setIconImageFileInfo] = useState<FileInfo | undefined>(undefined);
    const [backgroundImageFileInfo, setBackgroundImageFileInfo] = useState<FileInfo | undefined>(undefined);
    const [open, setOpen] = useState(false);
    const [teachableSubjects, setTeachableSubjects] = useState<SubjectResponse[]>([]);
    const [nickname, setNickname] = useState("");
    const [catchCopy, setCatchCopy] = useState("");
    const [backgroundDescription, setBackgroundDescription] = useState("");
    const [contentDescription, setContentDescription] = useState("");
    const [educationalBackground, setEducationalBackground] = useState<EducationalBackgroundResponse>({
        educationalBackgroundId: "" as NominalTypingEducationalBackgroundString,
        university: "",
        faculty: "",
        department: "",
    });
    const [noUniversityBoxChecked, setNoUniversityBoxChecked] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const userId = useSelector((state: RootState) => state.jwt.userId as string);

    const createTeacher = useCreateTeacherMutation();
    const createUser = useCreateUserMutation();
    const uploadFiles = useUploadFiles();

    const dispatch = useDispatch();
    const history = useHistory();

    const cognitoUser = useCognitoUser() as any;

    const confirm = useCallback(async () => {
        try {
            setIsProcessing(true);
            const iconImageFiles = iconImageFileInfo?.file ? [iconImageFileInfo.file] : [];
            const iconImageUrls = await uploadFiles(iconImageFiles, `users/${userId}/teacher/iconImage`);
            const backgroundImageFiles = backgroundImageFileInfo?.file ? [backgroundImageFileInfo.file] : [];
            const backgroundImageUrls = await uploadFiles(
                backgroundImageFiles,
                `users/${userId}/teacher/backgroundImage`,
            );
            const modifiedCreateUserRequestBody: CreateUserRequestBody = {
                ...(props.createUserRequestBody as CreateUserRequestBody),
                userId: userId,
                email: cognitoUser.attributes.email,
            };
            await createUser({
                createUserRequestBody: modifiedCreateUserRequestBody,
            });
            const teachableSubjectIds = teachableSubjects.map((subject) => subject.subjectId as string);
            const modifiedCreateTeacherRequestBody: CreateTeacherRequestBody = {
                userId: userId,
                nickname: nickname,
                catchCopy: catchCopy,
                backgroundDescription: backgroundDescription,
                contentDescription: contentDescription,
                iconImageUrl: iconImageUrls[0],
                backgroundImageUrl: backgroundImageUrls[0],
                teachableSubjectIds,
                educationalBackgroundId: noUniversityBoxChecked
                    ? undefined
                    : educationalBackground.educationalBackgroundId,
            };
            await createTeacher({
                createTeacherRequestBody: modifiedCreateTeacherRequestBody,
            });
            removeEventListener("unload", props.deleteUser);
            dispatch(logout());
            localStorage.clear();
            props.setIsCompleted(true);
        } catch (error) {
            console.log(error);
            setIsProcessing(false);
            alert("予期せぬエラーが発生しました。登録をやり直してください。");
            await Auth.deleteUser();
            dispatch(logout());
            localStorage.clear();
            props.handleHasErrorChange(true);
        }
    }, [
        props.createUserRequestBody,
        userId,
        dispatch,
        history,
        createTeacher,
        createUser,
        iconImageFileInfo,
        backgroundImageFileInfo,
        nickname,
        catchCopy,
        backgroundDescription,
        contentDescription,
        cognitoUser,
        teachableSubjects,
        educationalBackground,
        noUniversityBoxChecked,
        props.handleHasErrorChange,
    ]);

    const isValidTeacherInfoValue = useCallback((key: keyof CreateTeacherRequestBody, value: string) => {
        const upperLimit = teacherConfig[key];
        if (upperLimit) {
            return value.length > 0 && value.length <= upperLimit;
        } else {
            false;
        }
    }, []);

    const handleOpen = useCallback(() => {
        const teacherTargets = {
            nickname: nickname,
            catchCopy: catchCopy,
            backgroundDescription: backgroundDescription,
            contentDescription: contentDescription,
        };
        const failureFunc = () => {
            setChecked(true);
            window.scrollTo(0, 150);
        };
        const characterLimit = Object.entries(teacherTargets).every(([key, value]) =>
            value ? isValidTeacherInfoValue(key as keyof CreateTeacherRequestBody, value.toString()) : false,
        );
        const isEducationalBackgroundValid = noUniversityBoxChecked
            ? true
            : educationalBackground.educationalBackgroundId !== "";
        characterLimit && isEducationalBackgroundValid ? setOpen(true) : failureFunc();
    }, [
        nickname,
        catchCopy,
        backgroundDescription,
        contentDescription,
        noUniversityBoxChecked,
        educationalBackground,
        isValidTeacherInfoValue,
    ]);
    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleNicknameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setNickname(event.target.value);
    }, []);

    const handleCatchCopyChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCatchCopy(event.target.value);
    }, []);

    const handleBackgroundDescriptionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setBackgroundDescription(event.target.value);
    }, []);

    const handleContentDescriptionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setContentDescription(event.target.value);
    }, []);

    const handleNoUniversityCheckChange = useCallback((checked: boolean) => {
        if (checked) {
            setEducationalBackground({
                educationalBackgroundId: "" as NominalTypingEducationalBackgroundString,
                university: "",
                faculty: "",
                department: "",
            });
            setNoUniversityBoxChecked(true);
        } else {
            setNoUniversityBoxChecked(false);
        }
    }, []);

    return (
        <FormContents
            checked={checked}
            confirmModalOpen={open}
            iconImageFileInfo={iconImageFileInfo}
            backgroundImageFileInfo={backgroundImageFileInfo}
            teachableSubjects={teachableSubjects}
            nickname={nickname}
            catchCopy={catchCopy}
            backgroundDescription={backgroundDescription}
            contentDescription={contentDescription}
            educationalBackground={educationalBackground}
            noUniversityBoxChecked={noUniversityBoxChecked}
            isProcessing={isProcessing}
            setTeachableSubjects={setTeachableSubjects}
            setIconImageFileInfo={setIconImageFileInfo}
            setBackgroundImageFileInfo={setBackgroundImageFileInfo}
            setEducationalBackground={setEducationalBackground}
            handleOpen={handleOpen}
            handleClose={handleClose}
            confirm={confirm}
            handleNicknameChange={handleNicknameChange}
            handleCatchCopyChange={handleCatchCopyChange}
            handleBackgroundDescriptionChange={handleBackgroundDescriptionChange}
            handleContentDescriptionChange={handleContentDescriptionChange}
            handleNoUniversityCheckChange={handleNoUniversityCheckChange}
        />
    );
});
