import { UserType } from "@/protectedRouter/ProtectedRouterConfig";
//Action type
export type actionType = {
    type: "login" | "logout" | "transition";
    payload: {
        userMode: UserType;
        userId: string | null;
        studentId: string | null;
        teacherId: string | null;
    };
};

export function login(
    userMode: userType,
    userId: string | null,
    studentId: string | null,
    teacherId: string | null,
): actionType {
    return {
        type: "login",
        payload: {
            userMode: userMode,
            userId: userId,
            studentId: studentId,
            teacherId: teacherId,
        },
    };
}

export function transition(userId: string): actionType {
    return {
        type: "transition",
        payload: {
            userMode: "anonymous",
            userId: userId,
            studentId: null,
            teacherId: null,
        },
    };
}

export function logout(): actionType {
    return {
        type: "logout",
        payload: {
            userMode: "anonymous",
            userId: null,
            studentId: null,
            teacherId: null,
        },
    };
}
