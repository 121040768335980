import { Button, CircularProgress, LinearProgress } from "@material-ui/core";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "@/ducks";
import { LinkZoomAccountRequestBody } from "@/store/autogenApi";
import { useLinkZoomAccountMutation } from "@/store/hooks/teachers";
import styles from "./index.module.scss";

export const LinkingZoomAccount: React.VFC = () => {
    const [email, setEmail] = useState<string>("");
    const [error, setError] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);

    const linkZoomAccount = useLinkZoomAccountMutation();

    const handleSubmitButtonClick = useCallback(async () => {
        const regexp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.+-]*@[A-Za-z0-9_.-]+\.[A-Za-z0-9]+$/;
        if (regexp.test(email)) {
            setIsProcessing(true);
            setError(false);
            const linkZoomAccountRequestBody: LinkZoomAccountRequestBody = {
                teacherId: teacherId,
                email: email,
            };
            const result = await linkZoomAccount({
                linkZoomAccountRequestBody: linkZoomAccountRequestBody,
            });
            if (!result.isSuccess) throw new Error("Failed to link Zoom account");
            setSubmitted(true);
        } else {
            setError(true);
        }
        setIsProcessing(false);
    }, [teacherId, email]);

    const history = useHistory();

    const handleMyPageButtonClick = useCallback(() => {
        history.push("/Teacher/MyPage/Profile");
    }, []);

    return (
        <div className={styles.linkingZoomAccountWrapper}>
            <div className={styles.linkingZoomAccountTitle}>Zoomアカウント発行</div>
            {submitted ? (
                <div className={styles.afterSubmittingWrapper}>
                    <div className={styles.submittedText}>
                        「{email}
                        」に連携確認メールが送信されました。「Zoomアカウントのアクティベート」をクリックして画面の指示に従って連携を完了させてください。Zoom連携の完了はマイページをご確認ください。
                    </div>
                    <Button className={styles.myPageButton} onClick={handleMyPageButtonClick}>
                        マイページ
                    </Button>
                </div>
            ) : (
                <div className={styles.beforeSubmittingWrapper}>
                    <div className={styles.linkingZoomAccountDescription}>
                        Trail用のZoomアカウントを発行または既存のZoomアカウントをTrailと連携します。登録料・使用料は一切かかりません。
                        <br />
                        メールアドレスを入力すると確認メールが送信されます。なお、既存のZoomアカウントのメールアドレスをご利用すると、元のZoomアカウントの設定情報が破損する場合があり、それに対する責任は負いかねますので、未使用のメールアドレスを推奨しております。
                    </div>
                    <div className={styles.linkingZoomAccountForm}>
                        <div className={styles.inputWrapper}>
                            <div className={styles.inputTitle}>メールアドレス</div>
                            <input
                                className={styles.linkingZoomAccountFormInput}
                                placeholder="メールアドレスを入力"
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        {error && (
                            <div className={styles.error}>
                                メールアドレスが正しくありません。入力を確認してください。
                            </div>
                        )}
                        <Button className={styles.linkingZoomAccountFormSubmitButton} onClick={handleSubmitButtonClick}>
                            {isProcessing ? (
                                <CircularProgress className={styles.progress} />
                            ) : (
                                <div className={styles.submit}>送信</div>
                            )}
                        </Button>
                    </div>
                    <a className={styles.aboutZoom} href="https://explore.zoom.us/ja/products/meetings" target="_blank">
                        Zoomについて（公式サイト）
                    </a>
                </div>
            )}
        </div>
    );
};
