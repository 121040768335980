import { memo, useCallback } from "react";
import {
    CreateBaseScheduleParams,
    CreateLessonRequestParams,
    LessonResponse,
} from "@/store/autogenApi";

import { RegularCourse } from "./RegularCourse";
import { ShortCourse } from "./ShortCourse";
import { ListItem } from "..";

interface Props {
    isConfirmMode?: boolean;
    isChecked: boolean;
    courseType: string;
    lessons: CreateLessonRequestParams[];
    existingLessons: LessonResponse[];
    price: number;
    lessonsDuplicateValidation: boolean;
    otherCourseLessonDuplicateValidation: boolean;
    baseSchedules: CreateBaseScheduleParams[];
    handleBaseScheduleDeleteButtonClick: (idx: number) => void;
    handleBaseSchedulesChange: (newBaseSchedules: CreateBaseScheduleParams[]) => void;
    handleLessonsChange: (newLessons: CreateLessonRequestParams[]) => void;
}

export const Schedules: React.VFC<Props> = memo(function Schedules(props) {
    const getScheduleTitle = useCallback(() => {
        if (props.courseType === "short") {
            return "日程";
        } else if (props.courseType === "regular") {
            return "基本スケジュール";
        }
    }, [props.courseType]);

    return (
        <ListItem title={getScheduleTitle() as string} isConfirmMode={!!props.isConfirmMode}>
            <>
                {props.courseType === "short" && (
                    <ShortCourse
                        isChecked={props.isChecked}
                        isConfirmMode={props.isConfirmMode}
                        existingLessons={props.existingLessons}
                        lessons={props.lessons}
                        price={props.price}
                        lengthValidation={props.lessons.length > 0}
                        lessonsDuplicateValidation={props.lessonsDuplicateValidation}
                        otherCourseLessonDuplicateValidation={props.otherCourseLessonDuplicateValidation}
                        handleLessonsChange={props.handleLessonsChange}
                    />
                )}
                {props.courseType === "regular" && (
                    <RegularCourse
                        isChecked={props.isChecked}
                        isConfirmMode={props.isConfirmMode}
                        existingLessons={props.existingLessons}
                        baseSchedules={props.baseSchedules}
                        pricePerHour={props.price}
                        handleBaseScheduleDeleteButtonClick={props.handleBaseScheduleDeleteButtonClick}
                        handleBaseSchedulesChange={props.handleBaseSchedulesChange}
                    />
                )}
            </>
        </ListItem>
    );
});
