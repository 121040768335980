import { memo, useCallback, useState } from "react";

import { Button } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import { AiOutlineCheckCircle, AiOutlineLink } from "react-icons/ai";
import { MdOutlinePermIdentity } from "react-icons/md";
import { useHistory } from "react-router";

import { FileInfo, TrimmingModal } from "@/components/TrimmingModal";
import styles from "@/pages/Teacher/MyPage/index.module.scss";
import { Optional } from "@/components/Tag/Optional";
import { EducationalBackgroundResponse, TeacherIdentificationStatus, ZoomAccountStatus } from "@/store/autogenApi";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";
import { Link } from "react-router-dom";
import { SchoolIcon } from "@/globalIcons";

interface Props {
    educationalBackground: EducationalBackgroundResponse;
    identificationStatus: TeacherIdentificationStatus;
    isEducationalBackgroundIdentified: boolean;
    zoomAccountStatus: ZoomAccountStatus;
    checked: boolean;
    edit: boolean;
    iconImageFileInfo: FileInfo | undefined;
    existingIconImageUrl?: string;
    existingIconImageObjectKey?: string;
    courseLength: number;
    setIconImageFileInfo?: React.Dispatch<React.SetStateAction<FileInfo>>;
}

export const IconImageWrapper: React.VFC<Props> = memo(function IconImageWrapper(props) {
    const [trimmingModalOpen, setTrimmingModalOpen] = useState(false);
    const [targetFile, setTargetFile] = useState<File | undefined>(undefined);
    const selectImg = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            setTargetFile(file);
            handleTrimmingModalOpen();
        }
    }, []);
    const handleTrimmingModalClose = useCallback(() => {
        setTrimmingModalOpen(false);
    }, []);
    const handleTrimmingModalOpen = useCallback(() => {
        setTrimmingModalOpen(true);
    }, []);

    const history = useHistory();

    const handleIdentificationButtonClick = useCallback(() => {
        history.push("/Teacher/Identification");
    }, []);
    const handleLinkZoomButtonClick = useCallback(() => {
        history.push("/LinkZoomAccount");
    }, []);

    const getEducationalBackground = useCallback(() => {
        if (props.educationalBackground == undefined) return "未登録";
        const educationalBackground = props.educationalBackground;
        return `${educationalBackground.university} ${educationalBackground.faculty} ${educationalBackground.department}`;
    }, [props.educationalBackground]);
    return (
        <>
            {props.edit ? (
                <li className={styles.editListItem}>
                    <div className={styles.leftWrapper}>
                        <div className={styles.titleWrapper}>
                            <div className={styles.title}>アイコン画像</div>
                            <Optional />
                        </div>
                    </div>
                    <div className={styles.rightWrapper}>
                        <label className={styles.iconImageLabel}>
                            <AvatarFromS3 url={props.iconImageFileInfo?.url} className={styles.avatar} />
                            <input type="file" accept="image/*" onChange={selectImg} className={styles.photoInput} />
                            <TrimmingModal
                                open={trimmingModalOpen}
                                handleClose={handleTrimmingModalClose}
                                setFileInfo={props.setIconImageFileInfo}
                                targetFile={targetFile}
                                aspect={1 / 1}
                                shape="round"
                            />
                        </label>
                        {props.iconImageFileInfo?.url == undefined &&
                            !props.existingIconImageUrl &&
                            !props.existingIconImageObjectKey &&
                            props.checked && (
                                <FormHelperText className={styles.helperText}>
                                    画像をアップロードしてください。
                                </FormHelperText>
                            )}
                    </div>
                </li>
            ) : (
                <div className={styles.imageWrapper}>
                    <AvatarFromS3
                        url={props.existingIconImageUrl}
                        objectKey={props.existingIconImageObjectKey}
                        className={styles.avatar}
                    />
                    <div className={styles.educationalBackgroundWrapper}>
                        <SchoolIcon
                            className={
                                props.isEducationalBackgroundIdentified
                                    ? styles.identifiedSchoolIcon
                                    : styles.schoolIcon
                            }
                        />
                        <div className={styles.educationalBackground}>{getEducationalBackground()}</div>
                    </div>
                    <div className={styles.zoomWrapper}>
                        {props.zoomAccountStatus === "active" ? (
                            <div className={styles.linkedWrapper}>
                                <div className={styles.isLinked}>Zoom連携済み</div>
                                <AiOutlineCheckCircle className={styles.linkedIcon} />
                            </div>
                        ) : props.zoomAccountStatus === "pending" ? (
                            <div className={styles.pendingWrapper}>
                                <div className={styles.pending}>Zoom未認証</div>
                                <div className={styles.pleaseLink}>
                                    Zoomからの認証メールを確認して認証を行ってください。
                                    <br />
                                    届いていない場合は、迷惑メールフォルダもご確認ください。
                                    <br />
                                    それでも届いていない場合は、
                                    <Link style={{ cursor: "pointer" }} to="/Inquiry">
                                        お問い合わせ
                                    </Link>
                                    ください。
                                </div>
                            </div>
                        ) : (
                            <div className={styles.notLinkedWrapper}>
                                <div className={styles.isNotLinked}>Zoom未連携</div>
                                <Button className={styles.linkZoomButton} onClick={handleLinkZoomButtonClick}>
                                    <AiOutlineLink className={styles.linkIcon} />
                                    <div className={styles.linkZoom}>Zoom連携へ</div>
                                </Button>
                            </div>
                        )}
                    </div>
                    <div className={styles.identificationWrapper}>
                        {props.identificationStatus === "approved" ? (
                            <div className={styles.isIdentifiedWrapper}>
                                <div className={styles.isIdentified}>本人確認済み</div>
                                <AiOutlineCheckCircle className={styles.checkedIcon} />
                            </div>
                        ) : (
                            <>
                                {props.identificationStatus === "underReview" ? (
                                    <div className={styles.isUnderReviewWrapper}>
                                        <div className={styles.isUnderReview}>本人確認審査中</div>
                                    </div>
                                ) : (
                                    <div className={styles.notIsIdentifiedWrapper}>
                                        <div className={styles.notIsIdentified}>本人確認未完了</div>
                                        <Button
                                            className={styles.toIdentificationButton}
                                            onClick={handleIdentificationButtonClick}
                                        >
                                            <MdOutlinePermIdentity className={styles.identificationIcon} />
                                            <div className={styles.identification}>本人確認へ</div>
                                        </Button>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
});
