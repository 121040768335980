import { CourseResponse, GetCourseSearchResultsApiArg } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions } from "./utils/types";

export const useGetCourseWithValidLessonsQuery = (courseId: string) => {
    const result = defaultApi.endpoints.getCourseWithValidLessons.useQuery({ courseId });
    return useQueryResult(result, (response: CourseResponse) => response);
};

export const useGetCourseForCourseDetailsQuery = (courseId: string) => {
    const result = defaultApi.endpoints.getCourseForCourseDetails.useQuery({ courseId });
    return useQueryResult(result, (response: CourseResponse) => response);
};

export const useGetCourseForUpdatingCourseQuery = (courseId: string) => {
    const result = defaultApi.endpoints.getCourseForUpdatingCourse.useQuery({ courseId });
    return useQueryResult(result, (response: CourseResponse) => response);
};

export const useGetCourseForCourseManagementQuery = (courseId: string) => {
    const result = defaultApi.endpoints.getCourseForCourseManagement.useQuery({ courseId });
    return useQueryResult(result, (response: CourseResponse) => response);
};

export const useGetMyCoursesQuery = (teacherId: string) => {
    const result = defaultApi.endpoints.getMyCoursesByTeacherId.useQuery({ teacherId });
    return useQueryResult(result, (response: CourseResponse[]) => response);
};

export const useGetLatestShortCoursesQuery = (howMany: number) => {
    const result = defaultApi.endpoints.getLatestShortCourses.useQuery({ howMany });
    return useQueryResult(result, (response: CourseResponse[]) => response);
};

export const useGetLatestRegularCoursesQuery = (howMany: number) => {
    const result = defaultApi.endpoints.getLatestRegularCourses.useQuery({ howMany });
    return useQueryResult(result, (response: CourseResponse[]) => response);
};

export const useGetApproachingDeadlineShortCoursesQuery = (howMany: number) => {
    const result = defaultApi.endpoints.getApproachingDeadlineShortCourses.useQuery({ howMany });
    return useQueryResult(result, (response: CourseResponse[]) => response);
};

export const useGetApproachingDeadlineRegularCoursesQuery = (howMany: number) => {
    const result = defaultApi.endpoints.getApproachingDeadlineRegularCourses.useQuery({ howMany });
    return useQueryResult(result, (response: CourseResponse[]) => response);
};

export const useGetCourseSearchResultsQuery = (args: GetCourseSearchResultsApiArg) => {
    const result = defaultApi.endpoints.getCourseSearchResults.useQuery(args);
    return useQueryResult(result, (response: CourseResponse[]) => response);
};

export const useCreateCourseMutation = (options?: UseMutationOptions) => {
    const [createCourse] = defaultApi.endpoints.createCourse.useMutation();
    return useMutationFunctionWrapper(createCourse, options?.errorMessages);
};

export const useUpdateCourseMutation = (options?: UseMutationOptions) => {
    const [updateCourse] = defaultApi.endpoints.updateCourse.useMutation();
    return useMutationFunctionWrapper(updateCourse, options?.errorMessages);
};

export const useGetCourseFavoriteCountQuery = (courseId: string) => {
    const result = defaultApi.endpoints.getCourseFavoriteCount.useQuery({ courseId });
    return useQueryResult(result, (response: number) => response);
};

export const useGetRecommendedShortCoursesQuery = (studentId: string, howMany: number) => {
    const result = defaultApi.endpoints.getRecommendedShortCourses.useQuery({
        studentId: studentId,
        howMany: howMany,
    });
    return useQueryResult(result, (response: CourseResponse[]) => response);
};

export const useGetRecommendedRegularCoursesQuery = (studentId: string, howMany: number) => {
    const result = defaultApi.endpoints.getRecommendedRegularCourses.useQuery({
        studentId: studentId,
        howMany: howMany,
    });
    return useQueryResult(result, (response: CourseResponse[]) => response);
};
