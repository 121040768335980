import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { memo, useCallback } from "react";

import { Mandatory } from "@/components/Tag/Mandatory";
import styles from "@/pages/Student/MyPage/index.module.scss";
import { None } from "../Errors/None";

interface Props {
    value: string;
    checked: boolean;
    edit: boolean;
    year: number | undefined;
    month: number | undefined;
    date: number | undefined;
    isPublic: boolean;
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    handleYearChange: (e: React.ChangeEvent<HTMLSelectElement | { value: unknown }>) => void;
    handleMonthChange: (e: React.ChangeEvent<HTMLSelectElement | { value: unknown }>) => void;
    handleDateChange: (e: React.ChangeEvent<HTMLSelectElement | { value: unknown }>) => void;
}

export const BirthYear: React.VFC<Props> = memo(function BirthYear(props) {
    const YEAR_LIST = [...Array(123)].map((_, i) => i + 1900);
    const MONTH_LIST = [...Array(12)].map((_, i) => i + 1);
    const DATE_LIST = [...Array(31)].map((_, i) => i + 1);
    const getJapaneseBirthDate = useCallback(() => {
        if (props.value) {
            const fixedValue = new Date(props.value);
            const year = fixedValue.getFullYear();
            const month = fixedValue.getMonth() + 1;
            const date = fixedValue.getDate();
            return `${year}年${month}月${date}日`;
        } else {
            return "";
        }
    }, [props.value]);
    return (
        <>
            {props.edit ? (
                <>
                    <li className={styles.editListItem}>
                        <div className={styles.leftWrapper}>
                            <div className={styles.titleWrapper}>
                                <div className={styles.title}>生年月日</div>
                                <Mandatory />
                            </div>
                        </div>
                        <div className={styles.rightWrapper}>
                            <div className={styles.selectWrappers}>
                                <div className={styles.selectWrapper}>
                                    <Select
                                        variant="outlined"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="birthDate"
                                        value={props.year}
                                        onChange={props.handleYearChange}
                                        className={styles.select}
                                    >
                                        {YEAR_LIST.map((year) => (
                                            <MenuItem key={year} value={year}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <div className={styles.selectUnit}>年</div>
                                </div>
                                <div className={styles.selectWrapper}>
                                    <Select
                                        variant="outlined"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="birthDate"
                                        value={props.month}
                                        onChange={props.handleMonthChange}
                                        className={styles.select}
                                    >
                                        {MONTH_LIST.map((month) => (
                                            <MenuItem key={month} value={month}>
                                                {month}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <div className={styles.selectUnit}>月</div>
                                </div>
                                <div className={styles.selectWrapper}>
                                    <Select
                                        variant="outlined"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="birthDate"
                                        value={props.date}
                                        onChange={props.handleYearChange}
                                        className={styles.select}
                                    >
                                        {DATE_LIST.map((date) => (
                                            <MenuItem key={date} value={date}>
                                                {date}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <div className={styles.selectUnit}>日</div>
                                </div>
                            </div>
                            <None value={getJapaneseBirthDate()} checked={props.checked} />
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="isBirthDatePublic"
                                    onChange={props.handleChange}
                                    value={props.isPublic ? "public" : "private"}
                                >
                                    <FormControlLabel
                                        value="public"
                                        control={<Radio />}
                                        label="公開"
                                        className={styles.radioButtonLabel}
                                    />
                                    <FormControlLabel
                                        value="private"
                                        control={<Radio />}
                                        label="非公開"
                                        className={styles.radioButtonLabel}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </li>
                </>
            ) : (
                <li className={styles.profileItem} style={{ borderBottom: props.edit ? "" : "#305077 1px solid" }}>
                    <div className={styles.profileTitleWrapper}>
                        <div className={styles.profileTitle}>生年月日</div>
                        <div className={styles.isPublic}>{props.isPublic ? "公開" : "非公開"}</div>
                    </div>
                    <div className={styles.profileSpace}></div>
                    <div className={styles.profileValue}>{getJapaneseBirthDate()}</div>
                </li>
            )}
        </>
    );
});
