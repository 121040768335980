import { useState } from "react";

import { Badge, Button, makeStyles, useMediaQuery } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import NotificationIcon from "@material-ui/icons/Notifications";
import MailIcon from "@material-ui/icons/Mail";
import { VscArrowSwap } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { login } from "@/actions/logging";
import { TeacherToDoList } from "../ToDoList/TeacherToDoList";
import styles from "../index.module.scss";

import { PleaseStudentSignUpModal } from "./PleaseStudentSignUpModal";
import { Profile } from "./Profile";
import { RootState } from "@/ducks";
import { useGetPrivateTeacherQuery } from "@/store/hooks/teachers";
import { useGetPrivateUserQuery } from "@/store/hooks/users";
import { QueryLoadingWrapper } from "../../QueryLoadingWrapper";
import { TeacherAnnouncementList } from "../AnnouncementList/TeacherAnnouncementList";
import { useGetTeacherChatsQueryState } from "@/store/hooks/chats";
import { getTeacherHowManyUnreadMessages } from "@/utils/ChatUtils";

export const ForTeacher: React.VFC = () => {
    const [open, setOpen] = useState(false);

    const studentId = useSelector((state: RootState) => state.jwt.studentId);
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);
    const userId = useSelector((state: RootState) => state.jwt.userId as string);

    const sm = useMediaQuery("(min-width:600px)");
    const smallerThanThreshold = useMediaQuery("(max-width:639px)");

    const teacherQueryState = useGetPrivateTeacherQuery(teacherId);
    const userQueryState = useGetPrivateUserQuery(userId);
    const teacherChatsQueryState = useGetTeacherChatsQueryState(teacherId);

    const useStyles = makeStyles(() => ({
        navbar: {
            height: 32,
            minHeight: 32,
        },
        badge: {
            color: "#FFF",
            backgroundColor: "#C09547",
        },
    }));

    const style = useStyles();

    const dispatch = useDispatch();

    const history = useHistory();
    const handleSwitchButtonClick = () => {
        if (studentId) {
            dispatch(login("student", userId, studentId, teacherId));
            history.push("/");
        } else {
            setOpen(true);
        }
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div className={styles.forTeacher}>
            <PleaseStudentSignUpModal open={open} handleClose={handleClose} />
            {!(sm && smallerThanThreshold) && (
                <Button
                    color="inherit"
                    className={`${styles.iconButton} ${styles.xsHidden}`}
                    component={Link}
                    to="/Teacher/MyPage/Favorite"
                >
                    <FavoriteIcon />
                </Button>
            )}
            <Button color="inherit" className={styles.iconButton} component={Link} to="/Teacher/MyPage/Chats">
                <QueryLoadingWrapper
                    {...teacherChatsQueryState}
                    loadingComponent={
                        <Badge badgeContent={0} classes={{ badge: style.badge }} overlap="rectangular">
                            <MailIcon />
                        </Badge>
                    }
                >
                    {(chats) => {
                        const howManyUnreadMessages = getTeacherHowManyUnreadMessages(chats, teacherId);
                        return (
                            <Badge
                                badgeContent={howManyUnreadMessages}
                                classes={{ badge: style.badge }}
                                overlap="rectangular"
                            >
                                <MailIcon />
                            </Badge>
                        );
                    }}
                </QueryLoadingWrapper>
            </Button>
            <TeacherAnnouncementList />
            <TeacherToDoList />
            <QueryLoadingWrapper {...teacherQueryState}>
                {(teacher) => (
                    <QueryLoadingWrapper {...userQueryState}>
                        {(user) => (
                            <>
                                {user && (
                                    <Profile
                                        teacher={teacher}
                                        user={user}
                                        handleSwitchButtonClick={handleSwitchButtonClick}
                                    />
                                )}
                            </>
                        )}
                    </QueryLoadingWrapper>
                )}
            </QueryLoadingWrapper>
        </div>
    );
};
