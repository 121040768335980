import { memo } from "react";
import { Mandatory } from "@/components/Tag/Mandatory";
import styles from "../index.module.scss";

interface Props {
    children: React.ReactNode;
    title: string;
    isConfirmMode: boolean;
}

export const ListItem: React.VFC<Props> = memo(function ListItem(props) {
    return (
        <li className={styles.listItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>{props.title}</div>
                    {!props.isConfirmMode && <Mandatory />}
                </div>
            </div>
            <div className={styles.rightWrapper}>{props.children}</div>
        </li>
    );
});
