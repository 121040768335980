import { memo, useCallback } from "react";
import { Modal } from "@material-ui/core";
import { LessonResponse } from "@/store/autogenApi";
import styles from "./index.module.scss";
import { WhiteButton } from "@/components/Buttons/WhiteButton";
import { NavyButton } from "@/components/Buttons/NavyButton";
import { getLessonTime } from "@/utils/LessonUtils";

interface Props {
    lessonDetailsModalOpen: boolean;
    targetLesson: LessonResponse;
    handleLessonDetailsModalClose: () => void;
}

export const LessonDetailsModal: React.VFC<Props> = memo(function LessonDetailsModal(props) {
    const handleCancelButtonClick = useCallback(() => {
        props.handleLessonDetailsModalClose();
    }, [props.handleLessonDetailsModalClose]);

    const handleStartButtonClick = useCallback(() => {
        props.handleLessonDetailsModalClose();
        window.open(props.targetLesson.startUrl, "_blank");
    }, [props.handleLessonDetailsModalClose, props.targetLesson.joinUrl]);
    return (
        <Modal open={props.lessonDetailsModalOpen} onClose={props.handleLessonDetailsModalClose}>
            <div className={styles.lessonDetailsModalContents}>
                <div className={styles.lessonTime}>{getLessonTime(props.targetLesson)}</div>
                <div className={styles.confirmDescription}>この授業を開始します。よろしいですか？</div>
                <div className={styles.buttonsWrapper}>
                    <WhiteButton isFlex handleClick={handleCancelButtonClick}>
                        キャンセル
                    </WhiteButton>
                    <NavyButton isFlex handleClick={handleStartButtonClick}>
                        開始
                    </NavyButton>
                </div>
            </div>
        </Modal>
    );
});
