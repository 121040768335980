import { Link } from "react-router-dom";

import styles from "./index.module.scss";

export const Menu: React.VFC = () => {
    return (
        <>
            <ul className={styles.menu}>
                <li className={styles.menuItem}>
                    <Link to="/TermsOfService" className={styles.menuButton}>
                        利用規約
                    </Link>
                </li>
                <li className={styles.menuItem}>
                    <Link to="/ExternalTransmission" className={styles.menuButton}>
                        情報の外部送信について
                    </Link>
                </li>
                <li className={styles.menuItem}>
                    <Link to="/CourseGuidelines" className={styles.menuButton}>
                        受講ガイドライン
                    </Link>
                </li>
                <li className={styles.menuItem}>
                    <Link to="/TeacherGuidelines" className={styles.menuButton}>
                        先生ガイドライン
                    </Link>
                </li>
                <li className={styles.menuItem}>
                    <Link to="/ReviewSubmissionGuidelines" className={styles.menuButton}>
                        レビュー投稿ガイドライン
                    </Link>
                </li>
                {/* <li className={styles.menuItem}>
                    <Link to="/" className={styles.menuButton}>
                        特定商取引法の表示
                    </Link>
                </li> */}
                <li className={styles.menuItem}>
                    <Link to="/PrivacyPolicy" className={styles.menuButton}>
                        プライバシーポリシー
                    </Link>
                </li>
                <li className={styles.menuItem}>
                    <Link to="/FAQ" className={styles.menuButton}>
                        ヘルプ・よくある質問
                    </Link>
                </li>
            </ul>
        </>
    );
};
