import { useCallback, useEffect, useState } from "react";
import styles from "@/pages/Common/TeacherDetails/index.module.scss";
import { PublicTeacherResponse } from "@/store/autogenApi";
import { useGetMyCoursesQuery } from "@/store/hooks/courses";
import { useGetPublicUserQuery } from "@/store/hooks/users";

import { TabsComponent } from "./Tabs";
import { TopInfo } from "./TopInfo";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";

interface Props {
    teacher: PublicTeacherResponse;
}

export const TeacherDetailsComponent: React.VFC<Props> = (props) => {
    const [currentTopInfoWrapperEl, setCurrentTopInfoWrapperEl] = useState<HTMLElement | null>(null);
    const [currentTabsWrapperEl, setCurrentTabsWrapperEl] = useState<HTMLElement | null>(null);

    const myCoursesQueryState = useGetMyCoursesQuery(props.teacher.teacherId);
    const publicUserQueryState = useGetPublicUserQuery(props.teacher.userId);

    const topInfoWrapperRef = useCallback((headerEl: HTMLElement | null) => {
        setCurrentTopInfoWrapperEl(headerEl);
    }, []);
    const tabsWrapperRef = useCallback((headerEl: HTMLElement | null) => {
        setCurrentTabsWrapperEl(headerEl);
    }, []);

    const changeTopInfoWrapperHeight = useCallback(() => {
        const body = document.getElementById("body");
        if (!body || !currentTopInfoWrapperEl || !currentTabsWrapperEl) return;
        const bodyMinHeight = parseInt(body.style.minHeight);
        const topInfoWrapperHeight = currentTopInfoWrapperEl.clientHeight;
        // 調整用
        const delta = 0;
        currentTabsWrapperEl.style.minHeight = `${bodyMinHeight - (topInfoWrapperHeight + delta)}px`;
    }, [currentTopInfoWrapperEl, currentTabsWrapperEl]);

    useEffect(() => {
        changeTopInfoWrapperHeight();
        window.addEventListener("resize", changeTopInfoWrapperHeight);
        return () => {
            window.removeEventListener("resize", changeTopInfoWrapperHeight);
        };
    }, [changeTopInfoWrapperHeight]);

    return (
        <div className={styles.teacherDetailsWrapper}>
            <QueryLoadingWrapper {...myCoursesQueryState}>
                {(courses) => (
                    <QueryLoadingWrapper {...publicUserQueryState}>
                        {(publicUser) => (
                            <>
                                <TopInfo
                                    teacher={props.teacher}
                                    user={publicUser}
                                    courses={courses}
                                    topInfoWrapperRef={topInfoWrapperRef}
                                />
                                <TabsComponent
                                    teacher={props.teacher}
                                    user={publicUser}
                                    courses={courses}
                                    tabsWrapperRef={tabsWrapperRef}
                                />
                            </>
                        )}
                    </QueryLoadingWrapper>
                )}
            </QueryLoadingWrapper>
        </div>
    );
};
