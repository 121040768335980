import MenuBookIcon from "@material-ui/icons/MenuBook";

import styles from "@/pages/Common/PublicRequestDetails/index.module.scss";
import { PublicRequestResponse } from "@/store/autogenApi";

import { ProposalComponent } from "./Proposal";
import { memo } from "react";

interface Props {
    publicRequest: PublicRequestResponse;
}

export const ProposalList: React.VFC<Props> = memo(function (props) {
    return (
        <div className={`${styles.detailWrapper} ${styles.proposalWrapper}`}>
            <div className={styles.detailHeadingWrapper}>
                <span className={styles.detailHeading}>
                    提案一覧
                    {props.publicRequest.proposals?.length === 0
                        ? ""
                        : `（${props.publicRequest.proposals?.length}件）`}
                </span>
            </div>
            <div className={styles.detail}>
                {props.publicRequest.proposals?.length === 0 ? (
                    "先生からの提案はありません。"
                ) : (
                    <div className={styles.proposalList}>
                        {props.publicRequest.proposals?.map((proposal, idx) => {
                            return (
                                <ProposalComponent proposal={proposal} publicRequest={props.publicRequest} key={idx} />
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
});
