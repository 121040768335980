import { Button, Modal, TextareaAutosize } from "@material-ui/core";

import styles from "./index.module.scss";
import { memo, useCallback, useState } from "react";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Processing } from "@/components/Processing";
import { useWithdrawFromClassMutation } from "@/store/hooks/classes";
import { ClassResponse } from "@/store/autogenApi";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { ToastContents } from "@/components/Toast";

interface Props {
    targetClass: ClassResponse;
    modalOpen: boolean;
    handleModalClose: () => void;
}

export const WithdrawFromClassModal: React.VFC<Props> = memo(function WithdrawFromClassModal(props) {
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [withdrawMessage, setWithdrawMessage] = useState<string>("");
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isConfirmMode, setIsConfirmMode] = useState<boolean>(false);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);

    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);

    const withdrawFromClass = useWithdrawFromClassMutation();

    const handleWithdrawMessageChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setWithdrawMessage(e.target.value);
    }, []);

    const handleCheckButtonClick = useCallback(() => {
        setIsChecked(true);
        if (withdrawMessage.length < 10 || withdrawMessage.length > 300) return;
        setIsConfirmMode(true);
    }, [withdrawMessage]);

    const handleConfirmButtonClick = useCallback(async () => {
        setIsProcessing(true);
        const { isSuccess } = await withdrawFromClass({
            withdrawFromClassRequestBody: {
                classId: props.targetClass.classId,
                studentId,
                message: withdrawMessage,
            },
        });
        setIsProcessing(false);
        if (isSuccess) {
            setIsCompleted(true);
            toast(<ToastContents title="受講停止完了" isCompleted />);
        } else {
            toast(<ToastContents title="受講停止失敗" isFailed />);
        }
    }, [studentId, withdrawMessage, withdrawFromClass]);

    const history = useHistory();

    const handleModalClose = useCallback(() => {
        if (isProcessing) return;
        if (isCompleted) {
            history.push(`/MyPage/ReservedCourses?courseType=${props.targetClass.course?.courseType}`);
        }
        setIsChecked(false);
        setWithdrawMessage("");
        setIsProcessing(false);
        setIsConfirmMode(false);
        setIsCompleted(false);
        props.handleModalClose();
    }, [isProcessing, isCompleted, props.targetClass, props.handleModalClose]);

    const handleBackButtonClick = useCallback(() => {
        setIsConfirmMode(false);
    }, []);

    return (
        <Modal open={props.modalOpen} onClose={handleModalClose}>
            <div className={styles.withdrawModalContents}>
                {isConfirmMode ? (
                    <div className={styles.confirmModeContents}>
                        <div className={styles.withdrawNotice}>受講停止を確定します。よろしいですか？</div>
                        <div className={styles.withdrawMessageWrapper}>
                            <div className={styles.withdrawMessageTitle}>先生へのメッセージ</div>
                            <div className={styles.withdrawMessage}>{withdrawMessage}</div>
                        </div>
                        <div className={styles.buttonsWrapper}>
                            <Button className={styles.backButton} onClick={handleBackButtonClick}>
                                戻る
                            </Button>
                            <Button className={styles.confirmButton} onClick={handleConfirmButtonClick}>
                                {isProcessing ? <Processing /> : "確定"}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className={styles.inputModeContents}>
                        <div className={styles.withdrawNotice}>
                            受講停止すると即座にこのクラスから脱退し、以降このクラスの授業を受けることができなくなります。
                            それでも問題なければ、先生へのメッセージを入力して「確認」ボタンをクリックしてください。
                        </div>
                        <div className={styles.withdrawMessageWrapper}>
                            <TextareaAutosize
                                className={styles.withdrawMessageTextarea}
                                value={withdrawMessage}
                                onChange={handleWithdrawMessageChange}
                                placeholder="(必須・10字以上300字以下)&#13;&#10;例1）&#13;&#10;2年間にわたりご指導いただきありがとうございました。...&#13;&#10;例2）&#13;&#10;私用のため、受講ができなくなってしまいました。...&#13;&#10;"
                                minRows={8}
                                maxRows={8}
                            />
                            <div className={styles.count}>{withdrawMessage.length}/300文字</div>
                            <ErrorMessage
                                content="10字以上300字以下で入力してください。"
                                when={isChecked && (withdrawMessage.length < 10 || withdrawMessage.length > 300)}
                            />
                        </div>
                        <div className={styles.buttonsWrapper}>
                            <Button className={styles.closeButton} onClick={handleModalClose}>
                                閉じる
                            </Button>
                            <Button className={styles.checkButton} onClick={handleCheckButtonClick}>
                                確認
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
});
