import { FAQDetailsBase } from "../../FAQDetailsBase";

import { CAUTION_CONFIGS } from "./Caution";

export const Caution: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="講座開催の注意事項について"
            text="本ページでは、講座を開催するにあたり、注意するべきことをまとめてご紹介します。
            クラスを追加する前に必ず以下の内容を確認し、当てはまる事項があれば修正をお願いいたします。今後、弊社にて、修正がされていない、あるいは利用規約・各ガイドラインに抵触する記載もしくは行為が確認できた場合は、Trail運営事務局よりお知らせをする場合があります。
            予めご了承ください。"
            toc={[
                {
                    title: "講座の内容に関する事項",
                    list: ["対象者を限定した募集について"],
                },
                {
                    title: "権利・法律に関する事項",
                    list: ["知的財産権にかかわる使用許諾について"],
                },
            ]}
            FAQConfigs={CAUTION_CONFIGS}
            showIdx
        />
    );
};
