import { memo } from "react";

import { ListItem } from "@material-ui/core";

import styles from "./index.module.scss";
import { StyledSelect } from "../StyledSelect";
import { HourAndMinute } from "@/utils/DateUtils";

interface Props {
    hourAndMinute: HourAndMinute;
    whichTimeString: "startTime" | "endTime";
    handleChange: (
        e: React.ChangeEvent<{
            name?: string;
            value: number;
        }>,
    ) => void;
}

export const HourAndMinuteComponent: React.VFC<Props> = memo(function WhichTimeComponent(props) {
    return (
        <div className={styles.hourAndMinuteWrapper}>
            <div className={styles.hourWrapper}>
                <StyledSelect
                    name="hour"
                    value={props.hourAndMinute.hour ?? undefined}
                    handleChange={props.handleChange}
                >
                    <ListItem
                        value={undefined}
                        className={styles.minuteListItem}
                        style={{
                            cursor: "pointer",
                            backgroundColor: props.hourAndMinute.hour == null ? "#EEE" : undefined,
                        }}
                    />
                    {[...Array(24)].map((_: undefined, hour: number) => (
                        <ListItem
                            value={hour}
                            key={hour}
                            className={styles.hourListItem}
                            style={{
                                cursor: "pointer",
                                backgroundColor: props.hourAndMinute.hour == hour ? "#EEE" : undefined,
                            }}
                        >
                            {("00" + hour).slice(-2)}
                        </ListItem>
                    ))}
                </StyledSelect>
            </div>
            <div className={styles.colon}>:</div>
            <div className={styles.minuteWrapper}>
                <StyledSelect
                    name="minute"
                    value={props.hourAndMinute.minute ?? undefined}
                    handleChange={props.handleChange}
                >
                    <ListItem
                        value={undefined}
                        className={styles.minuteListItem}
                        style={{
                            cursor: "pointer",
                            backgroundColor: props.hourAndMinute.minute == null ? "#EEE" : undefined,
                        }}
                    />
                    {[...Array(12)].map((_: undefined, idx: number) => {
                        const minute = idx * 5;
                        return (
                            <ListItem
                                value={minute}
                                key={minute}
                                className={styles.minuteListItem}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: props.hourAndMinute.minute == minute ? "#EEE" : undefined,
                                }}
                            >
                                {("00" + minute).slice(-2)}
                            </ListItem>
                        );
                    })}
                </StyledSelect>
            </div>
        </div>
    );
});
