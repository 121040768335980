import { createTheme, MuiThemeProvider, TextareaAutosize, TextField } from "@material-ui/core";
import { memo } from "react";
import { ErrorMessage } from "@/components/ErrorMessage";

import styles from "./index.module.scss";

interface Props {
    isRequestChecked: boolean;
    value: string;
    handleChange?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

export const StartDateDescription: React.VFC<Props> = memo(function StartDateDescription(props) {
    const theme = createTheme({
        overrides: {
            MuiOutlinedInput: {
                input: {
                    padding: "10px",
                    color: "#305077",
                    fontSize: "14px",
                    "@media (max-width: 600px)": {
                        fontSize: "12px",
                    },
                    "&::placeholder": {
                        color: "#305077",
                        fontSize: "14px",
                        "@media (max-width: 600px)": {
                            fontSize: "12px",
                        },
                    },
                },
            },
            MuiFormControl: {
                marginNormal: {
                    marginTop: "0",
                    marginBottom: "0",
                },
            },
        },
    });

    return (
        <MuiThemeProvider theme={theme}>
            <div className={styles.textAreaWrapper}>
                <div className={styles.startDateNotice}>
                    先生が承認してから初回日までに支払いを行う必要があるため、余裕ある日程を設定することをおすすめします。
                </div>
                <TextareaAutosize
                    className={styles.textArea}
                    value={props.value}
                    placeholder={`(必須・500文字以内)\n\n例）\n○月○日か○月○日でお願いします。\n`}
                    onChange={props.handleChange}
                    maxRows={7}
                    minRows={7}
                />
                <div className={styles.count}>{props.value.length}/500文字</div>
                <ErrorMessage
                    content="この項目は必須です。入力してください。"
                    when={props.isRequestChecked && props.value.length === 0}
                />
                <ErrorMessage
                    content="500文字以内で入力してください。"
                    when={props.isRequestChecked && props.value.length > 500}
                />
            </div>
        </MuiThemeProvider>
    );
});
