import { useMediaQuery } from "@material-ui/core";
import { useEffect } from "react";
import { useParams } from "react-router";

import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { TeacherDetailsComponent } from "@/pageComponents/Common/TeacherDetails";
import { useGetPublicTeacherQuery } from "@/store/hooks/teachers";

export const TeacherDetails: React.VFC = () => {
    const { teacherId } = useParams<{ teacherId: string }>();
    const publicTeacherQuery = useGetPublicTeacherQuery(teacherId);

    return (
        <>
            <QueryLoadingWrapper {...publicTeacherQuery} isHidden>
                {(teacher) => <TeacherDetailsComponent teacher={teacher} />}
            </QueryLoadingWrapper>
        </>
    );
};
