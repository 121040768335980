import { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { RootState } from "@/ducks";
import { CommonComponent } from "@/pageComponents/Teacher/CreateCourse/Functional/Common";
import { UpdateComponent } from "@/pageComponents/Teacher/CreateCourse/Functional/Update";
import { useGetMyCoursesQuery } from "@/store/hooks/courses";
import { useGetPrivateTeacherQuery } from "@/store/hooks/teachers";

import styles from "./index.module.scss";
import { ButtonPair } from "@/components/ButtonPair";
import { useGetTeacherLessonsQuery } from "@/store/hooks/lessons";
import { Button, CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import { AiFillBook, AiFillHome } from "react-icons/ai";
import { Navigation, NavigationComponent } from "@/components/Navigation";
import { useNavigation } from "@/components/Navigation/NavigationContext";

const NAVIGATION_LIST: Navigation[] = [
    {
        title: "講座作成",
        url: "/CreateCourse",
    },
];

export const CreateCourse: React.VFC = memo(function CreateCourse() {
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [isTemporarySaved, setIsTemporarySaved] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [newCourseId, setNewCourseId] = useState<string | undefined>(undefined);

    const { currentCourseId } = useParams<{ currentCourseId: string | undefined }>();

    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);

    const teacherQueryState = useGetPrivateTeacherQuery(teacherId);
    const existingLessonsQueryState = useGetTeacherLessonsQuery(teacherId);
    const myCoursesQueryState = useGetMyCoursesQuery(teacherId);

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    const history = useHistory();
    const handleHomeButtonClick = useCallback(() => {
        history.push("/");
    }, []);
    const handleMyCoursesButtonClick = useCallback(() => {
        history.push("/Teacher/MyPage/MyCourses");
    }, []);

    const handleIsCompletedChange = useCallback((isCompleted: boolean) => {
        setIsCompleted(isCompleted);
    }, []);

    const handleIsTemporarySavedChange = useCallback((isTemporarySaved: boolean) => {
        setIsTemporarySaved(isTemporarySaved);
    }, []);

    const handleHasErrorChange = useCallback((hasError: boolean) => {
        setHasError(hasError);
    }, []);

    const handleNewCourseIdChange = useCallback((newCourseId: string | undefined) => {
        setNewCourseId(newCourseId);
    }, []);

    const handleIsProcessingChange = useCallback((isProcessing: boolean) => {
        setIsProcessing(isProcessing);
    }, []);

    if (isCompleted || isTemporarySaved || hasError || isProcessing) {
        return (
            <div className={styles.pageBody}>
                <div className={styles.createCourseWrapper}>
                    <div className={styles.contents}>
                        {hasError ? (
                            <div className={styles.hasUpdatingErrorWrapper}>
                                <div className={styles.messageWrapper}>
                                    <p className={styles.message}>エラーが発生しました。</p>
                                </div>
                                <div className={styles.buttonsWrapper}>
                                    <Button component={Link} to="/" className={styles.courseManagementButton}>
                                        ホームに戻る
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <>
                                {isCompleted ? (
                                    <div className={styles.completeWrapper}>
                                        {isTemporarySaved ? (
                                            <>
                                                <div className={styles.messageWrapper}>
                                                    <p className={styles.message}>講座が下書きとして保存されました！</p>
                                                </div>
                                                <div className={styles.buttonsWrapper}>
                                                    <Button component={Link} to="/" className={styles.toHomeButton}>
                                                        <AiFillHome className={styles.homeIcon} />
                                                        <div className={styles.text}>ホームに戻る</div>
                                                    </Button>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className={styles.messageWrapper}>
                                                    <p className={styles.message}>
                                                        講座が{currentCourseId ? "更新" : "作成"}されました！
                                                    </p>
                                                    <p className={styles.message}>生徒さんの応募をお待ちください。</p>
                                                </div>
                                                <div className={styles.buttonsWrapper}>
                                                    <Button component={Link} to="/" className={styles.toHomeButton}>
                                                        <AiFillHome className={styles.homeIcon} />
                                                        <div className={styles.text}>ホームに戻る</div>
                                                    </Button>
                                                    <Button
                                                        component={Link}
                                                        to={`/CourseDetails/${newCourseId}`}
                                                        className={styles.toCourseDetailsButton}
                                                    >
                                                        <AiFillBook className={styles.bookIcon} />
                                                        <div className={styles.text}>講座を確認する</div>
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <>
                                        {isProcessing && (
                                            <div className={styles.isProcessingWrapper}>
                                                <CircularProgress className={styles.progress} />
                                                <div className={styles.isProcessing}>処理中です...</div>
                                                <div className={styles.isProcessing}>
                                                    この処理は時間がかかることがあります。
                                                </div>
                                                <div className={styles.isProcessing}>
                                                    ウィンドウを閉じないでください。
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    if (currentCourseId) {
        return (
            <QueryLoadingWrapper {...teacherQueryState}>
                {(teacher) => (
                    <QueryLoadingWrapper {...existingLessonsQueryState}>
                        {(existingLessons) => (
                            <UpdateComponent
                                currentCourseId={currentCourseId}
                                zoomAccountStatus={teacher.zoomAccountStatus}
                                existingLessons={existingLessons}
                                isCompleted={isCompleted}
                                isProcessing={isProcessing}
                                handleIsCompletedChange={handleIsCompletedChange}
                                handleIsTemporarySavedChange={handleIsTemporarySavedChange}
                                handleHasErrorChange={handleHasErrorChange}
                                handleNewCourseIdChange={handleNewCourseIdChange}
                                handleIsProcessingChange={handleIsProcessingChange}
                            />
                        )}
                    </QueryLoadingWrapper>
                )}
            </QueryLoadingWrapper>
        );
    } else {
        return (
            <QueryLoadingWrapper {...teacherQueryState}>
                {(teacher) => (
                    <QueryLoadingWrapper {...existingLessonsQueryState}>
                        {(existingLessons) => (
                            <QueryLoadingWrapper {...myCoursesQueryState}>
                                {(myCourses) => {
                                    if (myCourses.length > 30) {
                                        return (
                                            <div className={styles.limitWrapper}>
                                                <div className={styles.limitContents}>
                                                    <div className={styles.limitDescription}>
                                                        作成できる最大講座数は30です。新しく講座を作成したい場合は既存の講座を削除してください。
                                                    </div>
                                                    <ButtonPair
                                                        pairType={1}
                                                        leftButtonContents="ホームに戻る"
                                                        rightButtonContents="My講座一覧"
                                                        handleLeftButtonClick={handleHomeButtonClick}
                                                        handleRightButtonClick={handleMyCoursesButtonClick}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }
                                    return (
                                        <CommonComponent
                                            zoomAccountStatus={teacher.zoomAccountStatus}
                                            existingLessons={existingLessons}
                                            isCompleted={isCompleted}
                                            isProcessing={isProcessing}
                                            handleIsCompletedChange={handleIsCompletedChange}
                                            handleIsTemporarySavedChange={handleIsTemporarySavedChange}
                                            handleHasErrorChange={handleHasErrorChange}
                                            handleNewCourseIdChange={handleNewCourseIdChange}
                                            handleIsProcessingChange={handleIsProcessingChange}
                                        />
                                    );
                                }}
                            </QueryLoadingWrapper>
                        )}
                    </QueryLoadingWrapper>
                )}
            </QueryLoadingWrapper>
        );
    }
});
