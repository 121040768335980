import { Button, Modal } from "@material-ui/core";
import { memo, useCallback, useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { FaChalkboardTeacher, FaHandPaper } from "react-icons/fa";
import { MdMessage } from "react-icons/md";
import styles from "@/pages/Common/PublicRequestDetails/index.module.scss";
import { PrivateUserResponse, ProposalResponse, PublicStudentResponse, ZoomAccountStatus } from "@/store/autogenApi";
import { LeftTime } from "./index";
import { ProposalModal } from "./ProposalModal";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { useGetTeacherLessonsQuery } from "@/store/hooks/lessons";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { useGetPrivateTeacherQuery } from "@/store/hooks/teachers";
import { PleaseLinkZoomModal } from "@/components/PleaseLinkZoomModal";
import { ChatModal } from "@/components/ChatModal";
import { CloseIcon } from "@/globalIcons";

interface Props {
    user: PrivateUserResponse;
    proposals: ProposalResponse[];
    publicRequestId: string;
    student: PublicStudentResponse;
    leftTimeModalOpen: boolean;
    proposalModalOpen: boolean;
    checked: boolean;
    courseType: string;
    applyingDeadline: Date;
    myProposalModalOpen: boolean;
    appliedModalOpen: boolean;
    leftTime: LeftTime | undefined;
    targetProposal: ProposalResponse | undefined;
    pleaseConnectZoomModalOpen: boolean;
    chatModalOpen: boolean;
    isWithdrawalMode: boolean;
    handleUpdateProposalButtonClick: (proposal: ProposalResponse) => void;
    handleMyProposalModalClose: () => void;
    handleMyProposalModalOpen: () => void;
    handleChatModalOpen: (teacherId: string) => void;
    handleChatModalClose: () => void;
    handleCourseManagementButtonClick: (proposal: ProposalResponse) => void;
    handleWithdrawButtonClick: () => void;
    handleConfirmWithdrawButtonClick: (proposalId: string) => void;
    handleProposalModalOpen: (zoomAccountStatus: ZoomAccountStatus) => void;
    handleProposalModalClose: () => void;
    handlePleaseLinkZoomModalClose: () => void;
}

export const SPTeacherContents: React.VFC<Props> = memo(function SPTeacherContents(props) {
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);

    const teacherQueryState = useGetPrivateTeacherQuery(teacherId);
    const existingLessonsQueryState = useGetTeacherLessonsQuery(teacherId);
    return (
        <QueryLoadingWrapper {...teacherQueryState}>
            {(teacher) => (
                <div className={styles.buttonsWrapper}>
                    {props.proposals.some((proposal) => proposal.teacher?.teacherId === props.user.teacherId) ? (
                        <>
                            {props.proposals
                                .filter((proposal) => proposal.teacher?.teacherId === props.user.teacherId)
                                .map((proposal) => {
                                    return (
                                        <>
                                            {proposal.class?.course ? (
                                                <>
                                                    <div className={styles.requestedWrapper}>
                                                        <AiOutlineCheckCircle className={styles.checkIcon} />
                                                        <div className={styles.requested}>授業を依頼されています</div>
                                                    </div>
                                                    <Button
                                                        className={styles.classButton}
                                                        onClick={() => {
                                                            props.handleCourseManagementButtonClick(proposal);
                                                        }}
                                                    >
                                                        <FaChalkboardTeacher className={styles.classIcon} />
                                                        <span className={styles.class}>クラスページへ</span>
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <Button
                                                        className={styles.appliedButton}
                                                        onClick={props.handleMyProposalModalOpen}
                                                    >
                                                        <AiOutlineCheckCircle className={styles.checkIcon} />
                                                        <div className={styles.applied}>提案済み</div>
                                                    </Button>
                                                    <Modal
                                                        open={props.myProposalModalOpen}
                                                        onClose={props.handleMyProposalModalClose}
                                                    >
                                                        <div className={styles.withdrawProposalModalWrapper}>
                                                            <Button
                                                                className={styles.closeButton}
                                                                onClick={props.handleMyProposalModalClose}
                                                            >
                                                                <CloseIcon className={styles.closeIcon} />
                                                            </Button>
                                                            {props.isWithdrawalMode ? (
                                                                <div className={styles.withdrawalDescription}>
                                                                    提案の復元はできませんが、取り下げを確定してもよろしいですか？
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className={styles.canEdit}>
                                                                        講座リクエストの募集締め切りまでは提案の編集が可能です。
                                                                    </div>
                                                                    <div className={styles.withdrawProposal}>
                                                                        また、日程などに不都合が生じた場合、提案を取り下げることも可能です。
                                                                    </div>
                                                                </>
                                                            )}
                                                            <div className={styles.withdrawProposalButtonsWrapper}>
                                                                {props.isWithdrawalMode ? (
                                                                    <Button
                                                                        className={styles.withdrawButton}
                                                                        onClick={() => {
                                                                            proposal.proposalId &&
                                                                                props.handleConfirmWithdrawButtonClick(
                                                                                    proposal.proposalId,
                                                                                );
                                                                        }}
                                                                    >
                                                                        確定
                                                                    </Button>
                                                                ) : (
                                                                    <>
                                                                        <Button
                                                                            className={styles.proposalModalCloseButton}
                                                                            onClick={() => {
                                                                                props.handleUpdateProposalButtonClick(
                                                                                    proposal,
                                                                                );
                                                                            }}
                                                                        >
                                                                            編集する
                                                                        </Button>
                                                                        <Button
                                                                            className={styles.withdrawButton}
                                                                            onClick={props.handleWithdrawButtonClick}
                                                                        >
                                                                            取り下げる
                                                                        </Button>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Modal>
                                                </>
                                            )}
                                            <Button
                                                className={styles.questionButton}
                                                onClick={() => {
                                                    props.user.teacherId &&
                                                        props.handleChatModalOpen(props.user.teacherId);
                                                }}
                                            >
                                                <MdMessage className={styles.messageIcon} />
                                                <span className={styles.message}>チャット</span>
                                            </Button>
                                            {props.chatModalOpen && (
                                                <ChatModal
                                                    handleClose={props.handleChatModalClose}
                                                    studentId={props.student.studentId}
                                                    teacherId={teacher.teacherId}
                                                />
                                            )}
                                        </>
                                    );
                                })}
                        </>
                    ) : (
                        <>
                            {props.applyingDeadline > new Date() && (
                                <Button
                                    className={styles.buttonToBeInCharge}
                                    onClick={() => props.handleProposalModalOpen(teacher.zoomAccountStatus)}
                                >
                                    <FaHandPaper className={styles.handIcon} />
                                    <div className={styles.application}>提案を送る</div>
                                </Button>
                            )}
                        </>
                    )}
                    <QueryLoadingWrapper {...existingLessonsQueryState}>
                        {(existingLessons) => (
                            <ProposalModal
                                courseType={props.courseType}
                                publicRequestId={props.publicRequestId}
                                studentId={props.student.studentId}
                                existingLessons={existingLessons}
                                proposalModalOpen={props.proposalModalOpen}
                                targetProposal={props.targetProposal}
                                handleProposalModalClose={props.handleProposalModalClose}
                            />
                        )}
                    </QueryLoadingWrapper>
                    <PleaseLinkZoomModal
                        modalOpen={props.pleaseConnectZoomModalOpen}
                        targetAction="提案を作成する"
                        zoomAccountStatus={teacher.zoomAccountStatus}
                        handleModalClose={props.handlePleaseLinkZoomModalClose}
                        handleBackButtonClick={props.handlePleaseLinkZoomModalClose}
                    />
                </div>
            )}
        </QueryLoadingWrapper>
    );
});
