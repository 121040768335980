import { memo, useCallback, useEffect, useState } from "react";

import { Button } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";

import styles from "../index.module.scss";
import { UniversitiesSearchModal } from "./UniversitiesSearchModal";
import { EducationalBackGroundQueries } from "@/pages/Common/CourseSearchResults";

interface Props {
    targetEducationalBackground: EducationalBackGroundQueries;
    isConfirmed: boolean;
    setIsConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    search: () => void;
    setTargetEducationalBackground: React.Dispatch<React.SetStateAction<EducationalBackGroundQueries>>;
    checkNarrowDownHeight: () => void;
}

export const BelongingConditions: React.VFC<Props> = memo(function BelongingConditions(props) {
    const [accordionOpen, setAccordionOpen] = useState<boolean>(false);
    const [universitiesSearchModalOpen, setUniversitiesSearchModalOpen] = useState<boolean>(false);

    useEffect(() => {
        const university = props.targetEducationalBackground.university;
        if (university) {
            setAccordionOpen(true);
        } else {
            setAccordionOpen(false);
        }
    }, [props.targetEducationalBackground]);

    const handleAccordionClick = useCallback(() => {
        setAccordionOpen(!accordionOpen);
        props.checkNarrowDownHeight();
    }, [accordionOpen]);

    const handleJapaneseSyllabaryButtonClick = useCallback(() => {
        setUniversitiesSearchModalOpen(true);
    }, []);

    const handleUniversitiesSearchModalClose = useCallback(() => {
        setUniversitiesSearchModalOpen(false);
        props.setTargetEducationalBackground({
            university: "",
            faculty: "",
            department: "",
        });
    }, []);

    const handleBelongingNarrowDownButtonClick = useCallback(() => {
        props.search();
        setUniversitiesSearchModalOpen(false);
    }, [props.search]);

    const handleIsConfirmedChange = useCallback(() => {
        props.setIsConfirmed(!props.isConfirmed);
        setUniversitiesSearchModalOpen(false);
    }, [props.isConfirmed]);

    const handleClearButtonClick = useCallback(() => {
        props.setIsConfirmed(false);
        props.setTargetEducationalBackground({
            university: "",
            faculty: "",
            department: "",
        });
        setUniversitiesSearchModalOpen(false);
    }, []);

    const getBelongingCondition = useCallback(() => {
        const university = props.targetEducationalBackground.university;
        if (university) {
            const faculty = props.targetEducationalBackground.faculty;
            if (faculty) {
                const department = props.targetEducationalBackground.department;
                if (department) {
                    return `${university}-${faculty}-${department}`;
                } else {
                    return `${university}-${faculty}`;
                }
            } else {
                return university;
            }
        } else {
            return "";
        }
    }, [props.targetEducationalBackground]);

    return (
        <div className={styles.conditionsWrapper}>
            <Button className={styles.accordionButton} onClick={handleAccordionClick}>
                <div className={styles.narrowDownTitle}>先生の在・卒大学</div>
                <div className={styles.flex} />
                {accordionOpen ? (
                    <RiArrowUpSLine className={styles.arrow} />
                ) : (
                    <RiArrowDownSLine className={styles.arrow} />
                )}
            </Button>
            {accordionOpen && (
                <div className={styles.conditions}>
                    <div className={styles.condition}>
                        {props.isConfirmed ? (
                            <div className={styles.confirmedConditionWrapper}>
                                <div className={styles.confirmedCondition}>{getBelongingCondition()}</div>
                                <Button className={styles.conditionClearButton} onClick={handleClearButtonClick}>
                                    <ClearIcon className={styles.clearIcon} />
                                </Button>
                            </div>
                        ) : (
                            <>
                                <Button
                                    className={styles.searchByJapaneseSyllabaryButton}
                                    onClick={handleJapaneseSyllabaryButtonClick}
                                    variant="contained"
                                >
                                    50音から大学を探す
                                </Button>
                                <UniversitiesSearchModal
                                    open={universitiesSearchModalOpen}
                                    targetEducationalBackground={props.targetEducationalBackground}
                                    handleClose={handleUniversitiesSearchModalClose}
                                    handleIsConfirmedChange={handleIsConfirmedChange}
                                    handleBelongingNarrowDownButtonClick={handleBelongingNarrowDownButtonClick}
                                    setTargetEducationalBackground={props.setTargetEducationalBackground}
                                />
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
});
