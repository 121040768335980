import { Modal } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";

import { logout } from "@/actions/logging";
import { UserType } from "@/protectedRouter/ProtectedRouterConfig";

import styles from "./index.module.scss";
import { useCallback } from "react";
import { NavyButton } from "../Buttons/NavyButton";
import { WhiteButton } from "../Buttons/WhiteButton";

interface Props {
    userMode: UserType;
    modalOpen: boolean;
    actionTitle?: string;
    handleModalClose?: () => void;
}

export const PleaseSignUpModal: React.VFC<Props> = (props) => {
    const history = useHistory();

    const goBack = useCallback(() => {
        if (props.handleModalClose) {
            props.handleModalClose();
        } else {
            history.goBack();
        }
    }, [props.handleModalClose]);

    const handleSignUpButtonClick = useCallback(() => {
        history.push("/SignUp");
    }, []);

    return (
        <Modal open={props.modalOpen} onClose={props.handleModalClose} className={styles.courseCompleteModal}>
            <div className={styles.pleaseSignUpModalContents}>
                {props.userMode === "anonymous" && (
                    <>
                        <div className={styles.requireWrapper}>
                            {props.actionTitle ?? "Trailをご利用いただく"}には会員登録（無料）が必要です。
                        </div>
                        <div className={styles.pleaseReadWrapper}>
                            <Link to="/SignUp" className={styles.privacyPolicy}>
                                新規登録
                            </Link>
                            または
                            <Link to="/SignIn" className={styles.termsOfService}>
                                ログイン
                            </Link>
                            をしてください。
                        </div>
                    </>
                )}
                {props.userMode === "teacher" && (
                    <>
                        <div className={styles.requireWrapper}>現在ご利用中のアカウントは先生用アカウントです。</div>
                        <div className={styles.pleaseReadWrapper}>
                            <div className={styles.privacyPolicy} onClick={logout}>
                                ログアウト
                            </div>
                            をして生徒用アカウントでログインをしてください。
                        </div>
                    </>
                )}
                <div className={styles.buttonsWrapper}>
                    <WhiteButton isFlex handleClick={goBack}>
                        {props.handleModalClose ? "閉じる" : "戻る"}
                    </WhiteButton>
                    <NavyButton style={{ flex: 1 }} handleClick={handleSignUpButtonClick}>
                        新規登録
                    </NavyButton>
                </div>
            </div>
        </Modal>
    );
};
