import { QueryLoadingWrapperOnlyPart } from "@/components/QueryLoadingWrapper/QueryLoadingWrapperPart";
import { useGetLatestPublicRequestsForRegularCourseQuery } from "@/store/hooks/publicRequests";

import { RequestCardsBase } from "@/components/RequestCardsBase";

interface Props {
    repetitionNumber: number | undefined;
}

export const LatestRequestsForRegularCourse: React.VFC<Props> = (props) => {
    const latestRequestsForRegularCourseQuery = useGetLatestPublicRequestsForRegularCourseQuery(15);
    return (
        <QueryLoadingWrapperOnlyPart {...latestRequestsForRegularCourseQuery}>
            {(requests, isLoaded) => (
                <RequestCardsBase
                    title="新着定期講座リクエスト"
                    requests={requests ?? []}
                    destination="/SearchResults?searchType=publicRequest&keyword=&courseType=regular&level=&category=&subCategory=&rankNumber=&university=&faculty=&department=&orderBy=latest"
                    isLoaded={isLoaded}
                    repetitionNumber={props.repetitionNumber}
                    hasTwoLines
                />
            )}
        </QueryLoadingWrapperOnlyPart>
    );
};
