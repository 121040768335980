import { Modal, useMediaQuery } from "@material-ui/core";
import { memo, useCallback } from "react";
import styles from "./index.module.scss";
import { DraftPublicRequestResponse } from "@/store/autogenApi";
import { WhiteButton } from "@/components/Buttons/WhiteButton";
import { NavyButton } from "@/components/Buttons/NavyButton";
import { useHistory } from "react-router";
import { getCourseType } from "@/utils/DraftPublicRequestUtils";
import { FormItem } from "@/components/FormItem";
import { StyledModal } from "@/components/StyledModal";

interface Props {
    targetDraftPublicRequest: DraftPublicRequestResponse;
    handleDraftPublicRequestDetailsModalClose: () => void;
}

export const DraftPublicRequestDetailsModal: React.VFC<Props> = memo(function DraftPublicRequestDetailsModal(
    props: Props,
) {
    const history = useHistory();

    const mdUp = useMediaQuery("(min-width:960px)");

    const handlePublicRequestDetailsButtonClick = useCallback(() => {
        props.handleDraftPublicRequestDetailsModalClose();
        history.push(`/PublicRequestDetails/${props.targetDraftPublicRequest.publicRequest?.publicRequestId}`);
    }, [props.targetDraftPublicRequest]);

    const handleEditButtonClick = useCallback(() => {
        props.handleDraftPublicRequestDetailsModalClose();
        const draftPublicRequestQuery = props.targetDraftPublicRequest
            ? `?draftPublicRequestId=${props.targetDraftPublicRequest.draftPublicRequestId}`
            : "";
        if (props.targetDraftPublicRequest.publicRequest?.publicRequestId) {
            history.push(
                `/CreatePublicRequest/${props.targetDraftPublicRequest.publicRequest?.publicRequestId}${draftPublicRequestQuery}`,
            );
            return;
        }
        history.push(`/CreatePublicRequest${draftPublicRequestQuery}`);
    }, [props.targetDraftPublicRequest, props.handleDraftPublicRequestDetailsModalClose]);

    return (
        <StyledModal
            open={true}
            autoResize
            onClose={props.handleDraftPublicRequestDetailsModalClose}
            style={{ maxWidth: "400px" }}
        >
            <div className={styles.courseDetailsModalContents}>
                <ul className={styles.itemList}>
                    <FormItem
                        itemTitle="講座形態"
                        itemDescription={
                            props.targetDraftPublicRequest ? getCourseType(props.targetDraftPublicRequest) : "-"
                        }
                        isFirstItem
                    />
                    <FormItem
                        itemTitle="タイトル"
                        itemDescription={
                            props.targetDraftPublicRequest?.title === "" ? "-" : props.targetDraftPublicRequest?.title
                        }
                    />
                    <FormItem itemTitle="科目">
                        {props.targetDraftPublicRequest?.subjects ||
                        props.targetDraftPublicRequest?.subjects.length === 0 ? (
                            "-"
                        ) : (
                            <ul className={styles.subjects}>
                                {props.targetDraftPublicRequest?.subjects.map((subject) => {
                                    return <li className={styles.subject}>{subject}</li>;
                                })}
                            </ul>
                        )}
                    </FormItem>
                    <FormItem
                        itemTitle="講座リクエストの詳細"
                        itemDescription={
                            props.targetDraftPublicRequest?.publicRequestDescription === ""
                                ? "-"
                                : props.targetDraftPublicRequest?.publicRequestDescription
                        }
                    />
                    <FormItem
                        itemTitle="日程"
                        itemDescription={
                            props.targetDraftPublicRequest?.scheduleDescription === ""
                                ? "-"
                                : props.targetDraftPublicRequest?.scheduleDescription
                        }
                    />
                    <FormItem
                        itemTitle="希望価格"
                        itemDescription={
                            props.targetDraftPublicRequest?.minPrice == undefined ||
                            props.targetDraftPublicRequest?.maxPrice == undefined
                                ? "-"
                                : `¥${new Intl.NumberFormat("ja-JP").format(
                                      props.targetDraftPublicRequest?.minPrice,
                                  )} ~ ¥${new Intl.NumberFormat("ja-JP").format(
                                      props.targetDraftPublicRequest?.maxPrice,
                                  )}`
                        }
                    />
                    <FormItem
                        itemTitle="備考"
                        itemDescription={
                            props.targetDraftPublicRequest?.additionalDescription == null
                                ? "-"
                                : props.targetDraftPublicRequest?.additionalDescription
                        }
                    />
                </ul>
                <div className={styles.buttonsWrapper}>
                    {props.targetDraftPublicRequest.publicRequest?.publicRequestId && (
                        <WhiteButton
                            handleClick={handlePublicRequestDetailsButtonClick}
                            fontSize={mdUp ? 15 : 14}
                            isFlex
                        >
                            現在の講座リクエストを確認
                        </WhiteButton>
                    )}
                    <NavyButton
                        handleClick={handleEditButtonClick}
                        fontSize={mdUp ? 15 : 14}
                        isFlex
                        style={{ maxWidth: "200px" }}
                    >
                        編集する
                    </NavyButton>
                </div>
            </div>
        </StyledModal>
    );
});
