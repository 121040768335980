import { memo, useCallback, useEffect, useState } from "react";
import {
    EmailNotificationsSettingResponse,
    PushNotificationsSettingResponse,
    UpdateEmailNotificationRequestBody,
    UpdatePushNotificationRequestBody,
} from "@/store/autogenApi";
import styles from "../index.module.scss";
import { toast } from "react-toastify";
import { getFCMToken } from "@/InitializeFirebase";
import { Button } from "@material-ui/core";
import { useUpdateNotificationsSettingMutation } from "@/store/hooks/notificationsSetting";
import { ToastContents } from "@/components/Toast";
import { PushNotificationsSetting } from "../Target/PushNotificationsSetting";
import { EmailNotificationsSetting } from "../Target/EmailNotificationsSetting";
import { useUpdateUserMutation } from "@/store/hooks/users";
import { RootState } from "@/ducks";
import { useSelector } from "react-redux";
import { useGetConfig } from "@/store/hooks/config";

interface Props {
    pushNotificationsSetting: PushNotificationsSettingResponse;
    emailNotificationsSetting: EmailNotificationsSettingResponse;
}

export const NotificationsSetting: React.VFC<Props> = memo(function NotificationSetting(props) {
    const [isEdited, setIsEdited] = useState(false);

    const [allowPushNotifications, setAllowPushNotifications] = useState(false);
    const [messagePush, setMessagePush] = useState<boolean>(true);
    const [favoritePush, setFavoritePush] = useState<boolean>(true);
    const [informationPush, setInformationPush] = useState<boolean>(true);
    const [recommendationPush, setRecommendationPush] = useState<boolean>(true);
    const [maintenancePush, setMaintenancePush] = useState<boolean>(true);
    const [reviewPush, setReviewPush] = useState<boolean>(true);
    const [newCoursePush, setNewCoursePush] = useState<boolean>(true);
    const [newPublicRequestPush, setNewPublicRequestPush] = useState<boolean>(true);

    const [allowEmailNotifications, setAllowEmailNotifications] = useState(false);
    const [messageEmail, setMessageEmail] = useState<boolean>(true);
    const [favoriteEmail, setFavoriteEmail] = useState<boolean>(true);
    const [informationEmail, setInformationEmail] = useState<boolean>(true);
    const [recommendationEmail, setRecommendationEmail] = useState<boolean>(true);
    const [maintenanceEmail, setMaintenanceEmail] = useState<boolean>(true);
    const [reviewEmail, setReviewEmail] = useState<boolean>(true);
    const [newCourseEmail, setNewCourseEmail] = useState<boolean>(true);
    const [newPublicRequestEmail, setNewPublicRequestEmail] = useState<boolean>(true);

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [getConfig] = useGetConfig();
    const updateNotificationsSetting = useUpdateNotificationsSettingMutation();
    const updateUser = useUpdateUserMutation();

    const userId = useSelector((state: RootState) => state.jwt.userId as string);

    const getIsEveryNotificationsItemTheSame = useCallback(() => {
        return (
            props.pushNotificationsSetting.allowNotifications === allowPushNotifications &&
            props.pushNotificationsSetting.message === messagePush &&
            props.pushNotificationsSetting.favorite === favoritePush &&
            props.pushNotificationsSetting.information === informationPush &&
            props.pushNotificationsSetting.recommendation === recommendationPush &&
            props.pushNotificationsSetting.maintenance === maintenancePush &&
            props.pushNotificationsSetting.review === reviewPush &&
            props.pushNotificationsSetting.newCourse === newCoursePush &&
            props.pushNotificationsSetting.newPublicRequest === newPublicRequestPush &&
            props.emailNotificationsSetting.allowNotifications === allowEmailNotifications &&
            props.emailNotificationsSetting.message === messageEmail &&
            props.emailNotificationsSetting.favorite === favoriteEmail &&
            props.emailNotificationsSetting.information === informationEmail &&
            props.emailNotificationsSetting.recommendation === recommendationEmail &&
            props.emailNotificationsSetting.maintenance === maintenanceEmail &&
            props.emailNotificationsSetting.review === reviewEmail &&
            props.emailNotificationsSetting.newCourse === newCourseEmail &&
            props.emailNotificationsSetting.newPublicRequest === newPublicRequestEmail
        );
    }, [
        props.pushNotificationsSetting,
        allowPushNotifications,
        messagePush,
        favoritePush,
        informationPush,
        recommendationPush,
        maintenancePush,
        reviewPush,
        newCoursePush,
        newPublicRequestPush,
        props.emailNotificationsSetting,
        allowEmailNotifications,
        messageEmail,
        favoriteEmail,
        informationEmail,
        recommendationEmail,
        maintenanceEmail,
        reviewEmail,
        newCourseEmail,
        newPublicRequestEmail,
    ]);

    useEffect(() => {
        const isEveryNotificationsItemTheSame = getIsEveryNotificationsItemTheSame();
        setIsEdited(!isEveryNotificationsItemTheSame);
    }, [getIsEveryNotificationsItemTheSame]);

    useEffect(() => {
        if (props.pushNotificationsSetting) {
            setAllowPushNotifications(
                props.pushNotificationsSetting.allowNotifications && Notification.permission === "granted",
            );
            setMessagePush(props.pushNotificationsSetting.message);
            setFavoritePush(props.pushNotificationsSetting.favorite);
            setInformationPush(props.pushNotificationsSetting.information);
            setRecommendationPush(props.pushNotificationsSetting.recommendation);
            setMaintenancePush(props.pushNotificationsSetting.maintenance);
            setReviewPush(props.pushNotificationsSetting.review);
            setNewCoursePush(props.pushNotificationsSetting.newCourse);
            setNewPublicRequestPush(props.pushNotificationsSetting.newPublicRequest);
        }
    }, [props.pushNotificationsSetting]);

    useEffect(() => {
        if (props.emailNotificationsSetting) {
            setAllowEmailNotifications(props.emailNotificationsSetting.allowNotifications);
            setMessageEmail(props.emailNotificationsSetting.message);
            setFavoriteEmail(props.emailNotificationsSetting.favorite);
            setInformationEmail(props.emailNotificationsSetting.information);
            setRecommendationEmail(props.emailNotificationsSetting.recommendation);
            setMaintenanceEmail(props.emailNotificationsSetting.maintenance);
            setReviewEmail(props.emailNotificationsSetting.review);
            setNewCourseEmail(props.emailNotificationsSetting.newCourse);
            setNewPublicRequestEmail(props.emailNotificationsSetting.newPublicRequest);
        }
    }, [props.emailNotificationsSetting]);

    const handleAllowPushNotificationsChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            (async () => {
                const config = await getConfig();
                if (checked) {
                    try {
                        setIsProcessing(true);
                        const FCMToken = await getFCMToken(config.data.firebase.vapidKey);
                        if (FCMToken) {
                            await updateUser({
                                updateUserRequestBody: {
                                    userId: userId,
                                    FCMToken: FCMToken,
                                },
                            });
                            setAllowPushNotifications(true);
                        }
                        setIsProcessing(false);
                    } catch (err) {
                        console.error("An error occured while retrieving firebase token. ", err);
                        setIsProcessing(false);
                    }
                } else {
                    setAllowPushNotifications(false);
                    if ("serviceWorker" in navigator) {
                        const serviceWorker = await window.navigator.serviceWorker.getRegistration("/");
                        if (!serviceWorker) return;
                        serviceWorker.unregister();
                    }
                }
            })();
        },
        [],
    );

    const handleAllowEmailNotificationsChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            setAllowEmailNotifications(checked);
        },
        [],
    );

    const handleMessagePushChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setMessagePush(checked);
    }, []);
    const handleFavoritePushChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setFavoritePush(checked);
    }, []);
    const handleInformationPushChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setInformationPush(checked);
    }, []);
    const handleRecommendationPushChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            setRecommendationPush(checked);
        },
        [],
    );
    const handleMaintenancePushChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setMaintenancePush(checked);
    }, []);
    const handleReviewPushChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setReviewPush(checked);
    }, []);
    const handleNewCoursePushChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setNewCoursePush(checked);
    }, []);
    const handleNewPublicRequestPushChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            setNewPublicRequestPush(checked);
        },
        [],
    );

    const handleMessageEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setMessageEmail(checked);
    }, []);
    const handleFavoriteEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setFavoriteEmail(checked);
    }, []);
    const handleInformationEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setInformationEmail(checked);
    }, []);
    const handleRecommendationEmailChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            setRecommendationEmail(checked);
        },
        [],
    );
    const handleMaintenanceEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setMaintenanceEmail(checked);
    }, []);
    const handleReviewEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setReviewEmail(checked);
    }, []);
    const handleNewCourseEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setNewCourseEmail(checked);
    }, []);
    const handleNewPublicRequestEmailChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            setNewPublicRequestEmail(checked);
        },
        [],
    );

    const handleUpdateButtonClick = useCallback(async () => {
        try {
            const pushNotificationsSetting: UpdatePushNotificationRequestBody = {
                pushNotificationsSettingId: props.pushNotificationsSetting.pushNotificationsSettingId,
                allowNotifications: allowPushNotifications,
                message: messagePush,
                favorite: favoritePush,
                information: informationPush,
                recommendation: recommendationPush,
                maintenance: maintenancePush,
                review: reviewPush,
                newCourse: newCoursePush,
                newPublicRequest: newPublicRequestPush,
            };
            const emailNotificationsSetting: UpdateEmailNotificationRequestBody = {
                emailNotificationsSettingId: props.emailNotificationsSetting.emailNotificationsSettingId,
                allowNotifications: allowEmailNotifications,
                message: messageEmail,
                favorite: favoriteEmail,
                information: informationEmail,
                recommendation: recommendationEmail,
                maintenance: maintenanceEmail,
                review: reviewEmail,
                newCourse: newCourseEmail,
                newPublicRequest: newPublicRequestEmail,
            };
            await updateNotificationsSetting({
                updateNotificationsSettingRequestBody: {
                    pushNotificationsSetting,
                    emailNotificationsSetting,
                },
            });
            toast(<ToastContents title="設定を更新しました" isCompleted />);
        } catch (err) {
            toast(<ToastContents title="設定の更新に失敗しました" isFailed />);
        }
    }, [
        props.pushNotificationsSetting,
        props.emailNotificationsSetting,
        allowPushNotifications,
        messagePush,
        favoritePush,
        informationPush,
        recommendationPush,
        maintenancePush,
        reviewPush,
        newCoursePush,
        newPublicRequestPush,
        allowEmailNotifications,
        messageEmail,
        favoriteEmail,
        informationEmail,
        recommendationEmail,
        maintenanceEmail,
        reviewEmail,
        newCourseEmail,
        newPublicRequestEmail,
    ]);
    return (
        <div className={styles.settingTargetsWrapper}>
            <div className={styles.settingTargetsTitle}>通知</div>
            <div className={styles.settingDescription}>
                支払い・開講中の講座に関する通知はメール通知のON/OFFに関わらずメールにお届けします。
            </div>
            <div className={styles.settingTargets}>
                <PushNotificationsSetting
                    isProcessing={isProcessing}
                    allowPushNotifications={allowPushNotifications}
                    message={messagePush}
                    favorite={favoritePush}
                    information={informationPush}
                    recommendation={recommendationPush}
                    maintenance={maintenancePush}
                    review={reviewPush}
                    newCourse={newCoursePush}
                    newRequest={newPublicRequestPush}
                    pushNotificationsSetting={props.pushNotificationsSetting}
                    getIsEveryNotificationsItemTheSame={getIsEveryNotificationsItemTheSame}
                    setIsEdited={setIsEdited}
                    handleMessageChange={handleMessagePushChange}
                    handleFavoriteChange={handleFavoritePushChange}
                    handleInformationChange={handleInformationPushChange}
                    handleRecommendationChange={handleRecommendationPushChange}
                    handleMaintenanceChange={handleMaintenancePushChange}
                    handleReviewChange={handleReviewPushChange}
                    handleNewCourseChange={handleNewCoursePushChange}
                    handleNewRequestChange={handleNewPublicRequestPushChange}
                    handleAllowPushNotificationsChange={handleAllowPushNotificationsChange}
                />
                <EmailNotificationsSetting
                    allowEmailNotifications={allowEmailNotifications}
                    message={messageEmail}
                    favorite={favoriteEmail}
                    information={informationEmail}
                    recommendation={recommendationEmail}
                    maintenance={maintenanceEmail}
                    review={reviewEmail}
                    newCourse={newCourseEmail}
                    newRequest={newPublicRequestEmail}
                    emailNotificationsSetting={props.emailNotificationsSetting}
                    getIsEveryNotificationsItemTheSame={getIsEveryNotificationsItemTheSame}
                    setIsEdited={setIsEdited}
                    handleMessageChange={handleMessageEmailChange}
                    handleFavoriteChange={handleFavoriteEmailChange}
                    handleInformationChange={handleInformationEmailChange}
                    handleRecommendationChange={handleRecommendationEmailChange}
                    handleMaintenanceChange={handleMaintenanceEmailChange}
                    handleReviewChange={handleReviewEmailChange}
                    handleNewCourseChange={handleNewCourseEmailChange}
                    handleNewRequestChange={handleNewPublicRequestEmailChange}
                    handleAllowEmailNotificationsChange={handleAllowEmailNotificationsChange}
                />
            </div>
            <Button
                className={styles.updateButton}
                onClick={handleUpdateButtonClick}
                style={
                    isEdited
                        ? { backgroundColor: "#305077", pointerEvents: "auto" }
                        : { backgroundColor: "#AAA", pointerEvents: "none" }
                }
            >
                更新
            </Button>
        </div>
    );
});
