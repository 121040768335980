import { Button, MenuItem } from "@material-ui/core";

import { WhichTimePopover } from "@/components/WhichTimePopover";
import styles from "./index.module.scss";
import { CreateAvailableTimeRequestParams, UpdateAvailableTimeRequestBody } from "@/store/autogenApi";

import { memo } from "react";
import { StyledSelect } from "@/components/StyledSelect";
import { ErrorMessage } from "@/components/ErrorMessage";
import { getOnlyDay } from "@/utils/AvailableTimeUtils";
import { HourAndMinute } from "@/utils/DateUtils";
import { MINUTES_LIST_PER_LESSON } from "@/constants";

interface Props {
    isChecked: boolean;
    availableTimes: (CreateAvailableTimeRequestParams | UpdateAvailableTimeRequestBody)[];
    newStartTime: HourAndMinute;
    newEndTime: HourAndMinute;
    anchorEl: HTMLButtonElement | null;
    maxDaysInAWeek: number;
    maxMinutesPerLesson: number;
    activeDayOfWeekPopoverIdx: number | undefined;
    availableTimesLengthValidation: boolean;
    handleMaxMinutesPerLessonChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleMaxDaysInAWeekChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    confirmDayTime: () => void;
    handleDayTimeButtonClick: (event: React.MouseEvent<HTMLButtonElement>, idx: number) => void;
    handleStartTimeChange: (
        e: React.ChangeEvent<{
            name: string;
            value: number;
        }>,
    ) => void;
    handleEndTimeChange: (
        e: React.ChangeEvent<{
            name: string;
            value: number;
        }>,
    ) => void;
    handleDayTimePopoverClose: () => void;
    handleResetButtonClick: () => void;
    checkDayTime: (idx: number) => boolean | undefined;
}

export const WeeklyScheduleComponent: React.VFC<Props> = memo(function WeeklyScheduleComponent(props) {
    return (
        <div className={styles.weeklyScheduleWrapper}>
            <div className={styles.weeklySchedule}>
                <div className={styles.messageWrapper}>
                    <p className={styles.message}>授業対応可能な時間帯を提示してください。</p>
                    <p className={styles.message}>
                        詳細な授業時間は後ほど生徒さんとのやりとりにおいて決定してください。
                    </p>
                </div>
                <ul className={styles.dayList}>
                    {props.availableTimes.map((availableTime, idx) => {
                        return (
                            <li key={idx} className={styles.dayListItem}>
                                <div className={styles.itemContents}>
                                    <div className={styles.dayTitle}>{getOnlyDay(availableTime)}</div>
                                    <span className={styles.colonSpan}>:</span>
                                    <Button
                                        className={styles.dayTimeButton}
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                            props.handleDayTimeButtonClick(e, idx);
                                        }}
                                    >
                                        {availableTime.isActive ? (
                                            <div className={styles.selectedDayTimesWrapper}>
                                                <div className={styles.selectedDayTime}>
                                                    <span className={styles.whichTime}>
                                                        {("00" + availableTime.startHour).slice(-2)}
                                                    </span>
                                                    <span className={styles.colon}>:</span>
                                                    <span className={styles.whichTime}>
                                                        {("00" + availableTime.startMinute).slice(-2)}
                                                    </span>
                                                </div>
                                                <div className={styles.tilde}>~</div>
                                                <div className={styles.selectedDayTime}>
                                                    <span className={styles.whichTime}>
                                                        {("00" + availableTime.endHour).slice(-2)}
                                                    </span>
                                                    <span className={styles.colon}>:</span>
                                                    <span className={styles.whichTime}>
                                                        {("00" + availableTime.endMinute).slice(-2)}
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className={styles.selectedDayTimesWrapper}>空きなし</div>
                                        )}
                                    </Button>
                                    <WhichTimePopover
                                        open={props.activeDayOfWeekPopoverIdx === idx}
                                        newStartTime={props.newStartTime}
                                        newEndTime={props.newEndTime}
                                        anchorEl={props.anchorEl}
                                        handleClose={props.handleDayTimePopoverClose}
                                        handleStartTimeChange={props.handleStartTimeChange}
                                        handleEndTimeChange={props.handleEndTimeChange}
                                        handleResetButtonClick={props.handleResetButtonClick}
                                        confirmWhichTime={props.confirmDayTime}
                                    />
                                    <ErrorMessage
                                        content="終了時刻は開始時刻よりも後に来るようにしてください"
                                        when={props.checkDayTime(idx) === false}
                                    />
                                </div>
                            </li>
                        );
                    })}
                </ul>
                <ErrorMessage
                    when={props.isChecked && !props.availableTimesLengthValidation}
                    content="この項目は必須です。少なくとも1つ選択してください。"
                />
            </div>
            <div className={styles.aboutScheduleWrapper}>
                <div className={styles.selectsWrapper}>
                    <div className={styles.selectWrapper}>
                        週に
                        <StyledSelect
                            name="maxDaysInAWeek"
                            value={props.maxDaysInAWeek}
                            handleChange={props.handleMaxDaysInAWeekChange}
                        >
                            <MenuItem key={0} value={0} className={`${styles.numberItem} ${styles.zero}`} />
                            {[...Array(7)].map((_, i) => (
                                <MenuItem key={i} value={i + 1} className={styles.numberItem}>
                                    {i + 1}
                                </MenuItem>
                            ))}
                        </StyledSelect>
                        回以内
                    </div>
                    ・
                    <div className={styles.selectWrapper}>
                        各
                        <StyledSelect
                            name="maxMinutesPerLesson"
                            value={props.maxMinutesPerLesson}
                            handleChange={props.handleMaxMinutesPerLessonChange}
                        >
                            <MenuItem key={0} value={0} className={`${styles.numberItem} ${styles.zero}`} />
                            {MINUTES_LIST_PER_LESSON.map((target) => (
                                <MenuItem key={target} value={target} className={styles.numberItem}>
                                    {target}
                                </MenuItem>
                            ))}
                        </StyledSelect>
                        分以内を想定
                    </div>
                </div>
                <ErrorMessage
                    when={props.isChecked && (props.maxDaysInAWeek === 0 || props.maxMinutesPerLesson === 0)}
                    content="この項目は必須です。選択してください。"
                />
            </div>
        </div>
    );
});
