import { UploadingFilesContents } from "../../UploadingFilesContents";
import styles from "../index.module.scss";
import { memo } from "react";

interface Props {
    idx: number;
    temporaryObjectURLs: string[];
    messagesEl: HTMLElement | null;
}

export const TemporaryMessage: React.VFC<Props> = memo(function TemporaryMessage(props) {
    return (
        <li className={styles.messageItem} key={props.idx}>
            <div className={styles.myWrapper} style={{ marginRight: "12px" }}>
                <UploadingFilesContents fileUrls={props.temporaryObjectURLs} messagesEl={props.messagesEl} />
            </div>
        </li>
    );
});
