import { useMediaQuery } from "@material-ui/core";
import { useHistory, useLocation } from "react-router";

import { CourseType, PublicRequestResponse } from "@/store/autogenApi";
import { memo, useCallback, useEffect, useState } from "react";
import { PublicRequestDetailsModal } from "./PublicRequestDetailsModal";
import { MyPublicRequestsTabContents } from "./MyPublicRequestsTabContents";
import { TabsTemplate } from "@/components/TabsTemplate";
import { getTabInfoList } from "@/utils/PublicRequestUtils";

interface Props {
    publicRequests: PublicRequestResponse[];
}

const COURSE_TYPES: CourseType[] = ["regular", "short"];

export const MyPublicRequestsContents: React.VFC<Props> = memo(function MyPublicRequestsContents(props) {
    const [courseType, setCourseType] = useState<CourseType>("regular");
    const [targetPublicRequest, setTargetPublicRequest] = useState<PublicRequestResponse | undefined>(undefined);

    const sm = useMediaQuery("(min-width:600px)");
    const md = useMediaQuery("(min-width:960px)");
    const lg = useMediaQuery("(min-width:1280px)");
    const xl = useMediaQuery("(min-width:1536px)");

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const courseTypeQueryParams = searchParams.get("courseType");

    useEffect(() => {
        if (courseTypeQueryParams) {
            setCourseType(courseTypeQueryParams as CourseType);
        }
    }, [courseTypeQueryParams]);

    const history = useHistory();

    const handleTabChange = useCallback((event: React.ChangeEvent<Record<string, unknown>>, value: string) => {
        switch (value) {
            case "regular":
                history.push("/MyPage/MyPublicRequests?courseType=regular");
                break;
            case "short":
                history.push("/MyPage/MyPublicRequests?courseType=short");
                break;
        }
    }, []);

    const handlePublicRequestCardClick = useCallback((PublicRequest: PublicRequestResponse) => {
        setTargetPublicRequest(PublicRequest);
    }, []);

    const handlePublicRequestDetailsModalClose = useCallback(() => {
        setTargetPublicRequest(undefined);
    }, []);

    console.log(props.publicRequests);

    return (
        <>
            <TabsTemplate
                targetTabValue={courseType}
                tabInfoList={getTabInfoList(props.publicRequests)}
                handleTabChange={handleTabChange}
            >
                {COURSE_TYPES.map((targetCourseType) => (
                    <MyPublicRequestsTabContents
                        visible={targetCourseType === courseType}
                        publicRequests={props.publicRequests.filter(
                            (publicRequest) => publicRequest.courseType === targetCourseType,
                        )}
                        repetition={xl ? 6 : lg ? 6 : md ? 6 : sm ? 5 : 3}
                        handlePublicRequestCardClick={handlePublicRequestCardClick}
                    />
                ))}
            </TabsTemplate>
            {targetPublicRequest && (
                <PublicRequestDetailsModal
                    targetPublicRequest={targetPublicRequest}
                    handlePublicRequestDetailsModalClose={handlePublicRequestDetailsModalClose}
                />
            )}
        </>
    );
});
