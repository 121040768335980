import { useCallback, useEffect, useState } from "react";

import { Paper } from "@material-ui/core";
import { Hidden } from "@material-ui/core";

import styles from "../index.module.scss";

import { MenuContents } from "./MenuContents";
import { PrivateStudentResponse, PrivateUserResponse } from "@/store/autogenApi";
import { useHistory } from "react-router";

interface Props {
    student: PrivateStudentResponse;
    user: PrivateUserResponse;
    isProcessingForSignOut: boolean;
    handleLinkButtonClick: (params: string) => void;
    handleChargeButtonClick: () => void;
    handleSignOutButtonClick: () => void;
    handleSwitchButtonClick: () => void;
    handleCreatePublicRequestButtonClick: () => void;
}
/**
 * TODO: handleSomething はデバッグ用。後で正式な実装に置き換える。。削除してもよい。
 */
export const ProfileMenu: React.VFC<Props> = (props) => {
    const [PCMenuEl, setPCMenuEl] = useState<null | HTMLElement>(null);
    const [isAdded, setIsAdded] = useState(false);
    const history = useHistory();
    useEffect(() => {
        if (PCMenuEl) {
            setHeight();
            if (!isAdded) {
                setIsAdded(true);
                window.addEventListener("resize", setHeight);
            }
            return () => {
                window.removeEventListener("resize", setHeight);
            };
        }
    }, [PCMenuEl]);
    const PCMenuRef = useCallback((PCMenuEl: HTMLElement | null) => {
        setPCMenuEl(PCMenuEl);
    }, []);
    const setHeight = () => {
        if (PCMenuEl) {
            const viewHeight = window.innerHeight;
            const height = viewHeight * 0.8;
            PCMenuEl.style.height = `${height}px`;
        }
    };
    return (
        <>
            {/* PC版 */}
            <Hidden smDown>
                <Paper elevation={5} className={styles.menuPaper} ref={PCMenuRef}>
                    <MenuContents
                        student={props.student}
                        user={props.user}
                        isProcessingForSignOut={props.isProcessingForSignOut}
                        handleLinkButtonClick={props.handleLinkButtonClick}
                        handleChargeButtonClick={props.handleChargeButtonClick}
                        handleSignOutButtonClick={props.handleSignOutButtonClick}
                        handleSwitchButtonClick={props.handleSwitchButtonClick}
                        handleCreatePublicRequestButtonClick={props.handleCreatePublicRequestButtonClick}
                    />
                </Paper>
            </Hidden>
            {/* SP版 */}
            <Hidden mdUp>
                <div className={styles.drawer}>
                    <MenuContents
                        student={props.student}
                        user={props.user}
                        isProcessingForSignOut={props.isProcessingForSignOut}
                        handleLinkButtonClick={props.handleLinkButtonClick}
                        handleChargeButtonClick={props.handleChargeButtonClick}
                        handleSignOutButtonClick={props.handleSignOutButtonClick}
                        handleSwitchButtonClick={props.handleSwitchButtonClick}
                        handleCreatePublicRequestButtonClick={props.handleCreatePublicRequestButtonClick}
                    />
                </div>
            </Hidden>
        </>
    );
};
