import { Button } from "@material-ui/core";
import { memo, useCallback, useState } from "react";
import { toast } from "react-toastify";
import { Processing } from "../../Processing";
import { ToastContents } from "../../Toast";

import styles from "../index.module.scss";
import { StudentToDoResponse } from "@/store/autogenApi";
import { useApproveTemporaryUpdateMonthlyFeeMutation, useApproveUpdateMonthlyFeeMutation } from "@/store/hooks/classes";
import { MutationState } from "@/store/hooks/utils/mutationFunction";

interface Props {
    targetStudentToDo: StudentToDoResponse | undefined;
    maxHeight: number;
    handleBackButtonClick: () => void;
    handleClose: () => void;
}

export const ApproveModeContents: React.VFC<Props> = memo(function ApproveModalContents(props) {
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const approveUpdateMonthlyFee = useApproveUpdateMonthlyFeeMutation();
    const approveTemporaryUpdateMonthlyFee = useApproveTemporaryUpdateMonthlyFeeMutation();

    const handleConfirmButtonClick = useCallback(async () => {
        if (!props.targetStudentToDo) return;
        setIsProcessing(true);
        if (!["approveUpdateMonthlyFee", "approveTemporaryUpdateMonthlyFee"].includes(props.targetStudentToDo.toDoType))
            return;
        let result: MutationState<unknown>;
        if (props.targetStudentToDo.toDoType === "approveUpdateMonthlyFee") {
            const subscription = props.targetStudentToDo.subscription;
            if (!subscription) {
                setIsProcessing(false);
                return;
            }
            result = await approveUpdateMonthlyFee({
                approveUpdateMonthlyFeeRequestBody: {
                    subscriptionId: subscription.subscriptionId,
                    studentToDoId: props.targetStudentToDo.studentToDoId,
                },
            });
        } else {
            const subscriptionSchedule = props.targetStudentToDo.subscriptionSchedule;
            if (!subscriptionSchedule) {
                setIsProcessing(false);
                return;
            }
            result = await approveTemporaryUpdateMonthlyFee({
                approveTemporaryUpdateMonthlyFeeRequestBody: {
                    subscriptionScheduleId: subscriptionSchedule.subscriptionScheduleId,
                    studentToDoId: props.targetStudentToDo.studentToDoId,
                },
            });
        }
        setIsProcessing(false);
        props.handleClose();
        if (result.isSuccess) {
            toast(<ToastContents title="月額更新申請承認完了" isCompleted />);
        } else {
            toast(<ToastContents title="月額更新申請承認失敗" isFailed />);
        }
    }, [props.targetStudentToDo, approveUpdateMonthlyFee, approveTemporaryUpdateMonthlyFee, props.handleClose]);

    return (
        <div className={styles.approvalWrapper} style={{ maxHeight: props.maxHeight }}>
            <div className={styles.confirmMessage}>月額更新申請を承認します。よろしいですか？</div>
            <div className={styles.buttonsWrapper}>
                <Button className={styles.backButton} onClick={props.handleBackButtonClick}>
                    戻る
                </Button>
                <Button className={styles.checkButton} onClick={handleConfirmButtonClick}>
                    {isProcessing ? <Processing /> : "確定"}
                </Button>
            </div>
        </div>
    );
});
