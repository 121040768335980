import { PublicTeacherResponse } from "@/store/autogenApi";
import { ListItem } from "./ListItem";

import styles from "./index.module.scss";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    teacher: PublicTeacherResponse | undefined;
}

export const TeacherInfo: React.VFC<Props> = (props) => {
    return (
        <ListItem title="担当の先生" borderBottom>
            <>
                <AvatarFromS3
                    className={styles.targetCourseStudentIcon}
                    url={props.teacher?.iconImageUrl}
                    objectKey={props.teacher?.iconImageObjectKey}
                />
                <div className={styles.targetCourseStudentName}>{`${props.teacher?.nickname}先生`}</div>
            </>
        </ListItem>
    );
};
