import { GetMessagesByStudentIdAndTeacherIdApiArg, MessageResponse } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions } from "./utils/types";

export const useGetStudentMessagesListQuery = (studentId: string) => {
    const result = defaultApi.endpoints.getStudentMessagesList.useQuery({ studentId: studentId });
    return useQueryResult(result, (response: MessageResponse[][]) => response);
};

export const useGetTeacherMessagesListQuery = (teacherId: string) => {
    const result = defaultApi.endpoints.getTeacherMessagesList.useQuery({ teacherId: teacherId });
    return useQueryResult(result, (response: MessageResponse[][]) => response);
};

export const useGetMessagesByStudentIdAndTeacherIdQuery = (args: GetMessagesByStudentIdAndTeacherIdApiArg) => {
    const result = defaultApi.endpoints.getMessagesByStudentIdAndTeacherId.useQuery(args);
    return useQueryResult(result, (response: MessageResponse[]) => response);
};

export const useCreateStudentMessageMutation = (options?: UseMutationOptions) => {
    const [createStudentMessage] = defaultApi.endpoints.createStudentMessage.useMutation();
    return useMutationFunctionWrapper(createStudentMessage, options?.errorMessages);
};

export const useCreateTeacherMessageMutation = (options?: UseMutationOptions) => {
    const [createTeacherMessage] = defaultApi.endpoints.createTeacherMessage.useMutation();
    return useMutationFunctionWrapper(createTeacherMessage, options?.errorMessages);
};
