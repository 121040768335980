import { Button, CircularProgress, Modal, TextField } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { memo, useCallback, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { useCognitoUser } from "@/components/Authentication";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Password } from "@/components/Password";
import { TextInput } from "@/components/TextInput";
import { ToastContents } from "@/components/Toast";

import styles from "../index.module.scss";

interface Props {}

export const PasswordSetting: React.VFC<Props> = memo(function ForgetPassword(props) {
    const [verificationCode, setVerificationCode] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [isProcessingForSending, setIsProcessingForSending] = useState<boolean>(false);
    const [isSentVerificationCode, setIsSentVerificationCode] = useState<boolean>(false);
    const [isProcessingForSubmitting, setIsProcessingForSubmitting] = useState<boolean>(false);
    const [isResent, setIsResent] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const [updatePasswordModalOpen, setUpdatePasswordModalOpen] = useState<boolean>(false);
    const [isVerificationCodeValid, setIsVerificationCodeValid] = useState(true);

    const history = useHistory();

    const cognitoUser = useCognitoUser() as any;

    const handlePasswordChangeButtonClick = useCallback(() => {
        setUpdatePasswordModalOpen(true);
    }, []);

    const ratz = /[a-z]/,
        rAtZ = /[A-Z]/,
        r0t9 = /[0-9]/;
    const handleVerificationCodeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setVerificationCode(value);
    }, []);

    const handleNewPasswordChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setNewPassword(value);
        setIsPasswordValid(ratz.test(value) && rAtZ.test(value) && r0t9.test(value));
    }, []);

    const handleSendVerificationCodeButtonClick = useCallback(async () => {
        setIsProcessingForSending(true);
        try {
            await Auth.forgotPassword(cognitoUser.attributes.email);
            setIsProcessingForSending(false);
            setIsSentVerificationCode(true);
        } catch (error) {
            if (error.name === "UsernameExistsException") {
                alert("このメールアドレスは既に登録されています。ログインしてください。");
                history.push("/SignIn");
            }
        }
    }, [isPasswordValid, cognitoUser, history]);

    const handleVerificationCodeSubmitButtonClick = useCallback(async () => {
        setIsChecked(true);
        if (!isPasswordValid) return;
        setIsProcessingForSubmitting(true);
        try {
            await Auth.forgotPasswordSubmit(cognitoUser.attributes.email, verificationCode, newPassword);
            toast(<ToastContents title="パスワードを更新しました。" isCompleted />);
            handleUpdatePasswordModalClose();
            setIsProcessingForSubmitting(false);
        } catch (error) {
            if (error.name === "CodeMismatchException") {
                setIsVerificationCodeValid(false);
            }
            setIsProcessingForSubmitting(false);
            console.log(error);
        }
    }, [verificationCode, newPassword, cognitoUser]);

    const handleResendVerificationCodeButtonClick = useCallback(async () => {
        setIsProcessingForSending(true);
        try {
            await Auth.forgotPassword(cognitoUser.attributes.email);
            setIsProcessingForSending(false);
            setIsSentVerificationCode(true);
            setIsResent(true);
        } catch (error) {
            console.log(error);
        }
    }, [cognitoUser]);

    const handleUpdatePasswordModalClose = useCallback(() => {
        setUpdatePasswordModalOpen(false);
        setIsChecked(false);
        setVerificationCode("");
        setIsSentVerificationCode(false);
        setIsVerificationCodeValid(true);
        setIsResent(false);
        setIsPasswordValid(true);
    }, []);

    return (
        <>
            <div className={styles.targetWrapper}>
                <div className={styles.targetTitle}>パスワード</div>
                <div className={styles.target}>●●●●●</div>
                <button className={styles.changeButton} onClick={handlePasswordChangeButtonClick}>
                    変更
                </button>
            </div>
            <Modal open={updatePasswordModalOpen} onClose={handleUpdatePasswordModalClose}>
                <div className={styles.updateModalContents}>
                    <>
                        {isSentVerificationCode ? (
                            <div className={styles.sentWrapper}>
                                <div className={styles.message}>
                                    以下のメールアドレスに認証コードを{isResent ? "再送信" : "送信"}しました。
                                </div>
                                <div className={styles.email}>{cognitoUser.attributes.email}</div>
                                <div className={styles.verticalWrapper}>
                                    <TextInput
                                        label="認証コード"
                                        value={verificationCode}
                                        handleChange={handleVerificationCodeChange}
                                    />
                                    <ErrorMessage
                                        content="認証コードが正しくありません。"
                                        when={isChecked && (!isVerificationCodeValid || verificationCode === "")}
                                        style={{ textAlign: "left", margin: "0 auto" }}
                                    />
                                    <Password
                                        label="新しいパスワード"
                                        value={newPassword}
                                        handleChange={handleNewPasswordChange}
                                    />
                                    <ErrorMessage
                                        content="パスワードは小文字、大文字、数字をそれぞれ1文字以上含む8文字以上で入力してください。"
                                        when={isChecked && (!isPasswordValid || newPassword === "")}
                                        style={{ maxWidth: "350px" }}
                                    />
                                </div>
                                <Button
                                    onClick={handleVerificationCodeSubmitButtonClick}
                                    className={styles.confirmButton}
                                >
                                    {isProcessingForSubmitting ? (
                                        <CircularProgress className={styles.processing} />
                                    ) : (
                                        <div className={styles.submit}>確定</div>
                                    )}
                                </Button>
                                <button
                                    onClick={handleResendVerificationCodeButtonClick}
                                    className={styles.resendButton}
                                >
                                    {isProcessingForSending ? (
                                        <CircularProgress className={styles.progress} />
                                    ) : (
                                        <div className={styles.resend}>認証コードを再送信</div>
                                    )}
                                </button>
                            </div>
                        ) : (
                            <div className={styles.beforeSentWrapper}>
                                <div className={styles.inputWrapper}>
                                    <Button
                                        onClick={handleSendVerificationCodeButtonClick}
                                        className={styles.sendVerificationCodeButton}
                                        style={{ marginTop: 0 }}
                                    >
                                        {isProcessingForSending ? (
                                            <CircularProgress className={styles.processing} />
                                        ) : (
                                            <div className={styles.send}>認証コードを送信</div>
                                        )}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </>
                </div>
            </Modal>
        </>
    );
});
