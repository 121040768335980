import { Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { memo } from "react";
import { Processing } from "@/components/Processing";

import styles from "@/pages/Teacher/IdentificationForStripe/index.module.scss";

interface Props {
    url: string;
    open: boolean;
    postalCode: string;
    state: string;
    city: string;
    town: string;
    line1: string;
    line2: string;
    email: string;
    phone: string;
    isProcessing: boolean;
    modalHeight: number;
    handleClose: () => void;
    confirm: () => void;
}

export const IdentificationModal: React.VFC<Props> = memo(function IdentificationModal(props) {
    return (
        <>
            <Modal
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={styles.modal}
            >
                <div className={styles.identificationModalContents} style={{ maxHeight: props.modalHeight }}>
                    {/* <div className={styles.topMessage}>以下の内容で提出してよろしいですか？</div> */}
                    <div className={styles.confirmList}>
                        <div className={styles.confirmItem} style={{ borderBottom: "1px solid #CCC" }}>
                            <div className={styles.leftWrapper}>
                                <div className={styles.itemTitle}>住所</div>
                            </div>
                            <div className={styles.rightWrapper}>
                                <div className={styles.item}>{`〒${props.postalCode.slice(
                                    0,
                                    3,
                                )}-${props.postalCode.slice(3)} ${props.state} ${props.city} ${props.town} ${
                                    props.line1
                                } ${props.line2}`}</div>
                            </div>
                        </div>
                        <div className={styles.confirmItem} style={{ borderBottom: "1px solid #CCC" }}>
                            <div className={styles.leftWrapper}>
                                <div className={styles.itemTitle}>身分証</div>
                            </div>
                            <div className={styles.rightWrapper}>
                                <img src={props.url} className={styles.image} />
                            </div>
                        </div>
                        <div className={styles.confirmItem}>
                            <div className={styles.leftWrapper}>
                                <div className={styles.itemTitle}>連絡先</div>
                            </div>
                            <div className={styles.rightWrapper}>
                                <div className={styles.item}>{`メールアドレス：${props.email}`}</div>
                                <div className={styles.item}>{`電話番号：${props.phone}`}</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.bottomMessage}>この内容でよろしければ「確定」を押してください。</div>
                    <div className={styles.buttonsWrapper}>
                        <Button className={styles.backButton} variant="contained" onClick={props.handleClose}>
                            戻る
                        </Button>
                        <Button className={styles.confirmButton} variant="contained" onClick={props.confirm}>
                            {props.isProcessing ? <Processing /> : "確定"}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
});
