import { memo, useCallback, useEffect, useState } from "react";
import styles from "../../index.module.scss";
import { LessonResponse } from "@/store/autogenApi";

interface Props {
    newLessonStartYIdx: number | undefined;
    newLessonEndYIdx: number | undefined;
    newLessonTimeString: string;
    isDragging: boolean;
    targetLesson: LessonResponse | undefined;
    handleNewLessonAnchorElChange: (el: HTMLElement) => void;
    handleNewLessonStartYIdxChange: (idx: number | undefined) => void;
    handleNewLessonEndYIdxChange: (idx: number | undefined) => void;
}

export const NewLessonTimeBlocks: React.VFC<Props> = memo((props) => {
    const [newLessonTimeBlocks, setNewLessonTimeBlocks] = useState<HTMLElement | null>(null);

    useEffect(() => {
        if (!newLessonTimeBlocks || props.newLessonStartYIdx == undefined) return;
        // props.newLessonStartYIdx番目の子要素を取得
        const startBlockEl = newLessonTimeBlocks.children[props.newLessonStartYIdx];
        if (!startBlockEl) return;
        props.handleNewLessonAnchorElChange(startBlockEl as HTMLElement);
    }, [newLessonTimeBlocks, props.newLessonStartYIdx]);

    const newLessonTimeBlocksRef = useCallback((el: HTMLElement | null) => {
        setNewLessonTimeBlocks(el);
    }, []);

    const getIsInRangeForNewLesson = useCallback(
        (idx: number) => {
            if (props.newLessonStartYIdx == undefined || props.newLessonEndYIdx == undefined) return false;
            return props.newLessonStartYIdx <= idx && idx < props.newLessonEndYIdx;
        },
        [props.newLessonStartYIdx, props.newLessonEndYIdx],
    );

    const getIsTheFirstIdx = useCallback(
        (idx: number) => {
            if (props.newLessonStartYIdx == undefined) return false;
            return idx === props.newLessonStartYIdx;
        },
        [props.newLessonStartYIdx],
    );

    const getIsTheLastIdx = useCallback(
        (idx: number) => {
            if (props.newLessonEndYIdx == undefined) return false;
            return idx === props.newLessonEndYIdx - 1;
        },
        [props.newLessonEndYIdx],
    );

    return (
        <div className={styles.lessonTimeBlocks} ref={newLessonTimeBlocksRef}>
            {[...Array(96)].map((_, k) => {
                const isInRange = getIsInRangeForNewLesson(k);
                const isTheFirstValidIdx = getIsTheFirstIdx(k);
                const isTheLastValidIdx = getIsTheLastIdx(k);
                const isPerHour = k % 4 === 0;
                return (
                    <div
                        className={styles.lessonTimeBlock}
                        style={{
                            backgroundColor: isInRange ? "#305077" : "transparent",
                            position: isTheFirstValidIdx ? "relative" : "unset",
                            borderTop: isPerHour && k != 0 && !isTheLastValidIdx ? "1px solid transparent" : "none",
                            borderRight: isInRange ? "1px solid #FFF" : "none",
                            borderLeft: isInRange ? "1px solid #FFF" : "none",
                            borderRadius: isTheFirstValidIdx ? "5px 5px 0 0" : isTheLastValidIdx ? "0 0 5px 5px" : "0",
                            cursor: isInRange ? "pointer" : "unset",
                            zIndex: isInRange ? 1 : 0,
                        }}
                    >
                        {isTheFirstValidIdx && !props.isDragging && (
                            <div className={styles.blockContentWrapper} style={{ zIndex: isInRange ? 1 : 0 }}>
                                <div className={styles.lessonTitle}>
                                    {props.targetLesson ? props.targetLesson.class?.course?.title : "新規授業"}
                                </div>
                                <div className={styles.lessonTime}>{props.newLessonTimeString}</div>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
});
