import { CreatePublicRequestRequestBody, UpdatePublicRequestRequestBody } from "@/store/autogenApi";

export type ConfigKey =
    | keyof (CreatePublicRequestRequestBody | UpdatePublicRequestRequestBody)
    | "applicationPeriod"
    | "howManyDays";

type Config = Record<ConfigKey, number[]>;

export const config: Partial<Config> = {
    title: [1, 30],
    additionalDescription: [1, 300],
    firstDateDescription: [1, 300],
    scheduleDescription: [1, 500],
    publicRequestDescription: [1, 1000],
    applicationPeriod: [1, 10],
    maxPrice: [1000, 100000],
    minPrice: [1000, 100000],
};
