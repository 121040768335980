import { ProceedsRecordResponse } from "@/store/autogenApi";
import { WHAT_DAY_LIST } from "@/utils/WhatDayList";
import styles from "./index.module.scss";
import { memo, useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { PageNumbers } from "@/components/PageNumbers";
import { useGetPrivateTeacherQuery } from "@/store/hooks/teachers";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";

interface Props {
    proceedsRecords: ProceedsRecordResponse[];
}

export const ProceedsRecordsContents: React.VFC<Props> = memo(function ProceedsRecordsContents(props) {
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [totalProceeds, setTotalProceeds] = useState<number>(0);

    const smallThresholdUp = useMediaQuery("(min-width:450px)");
    const mediumThresholdUp = useMediaQuery("(min-width:600px)");
    const largeThresholdUp = useMediaQuery("(min-width:1000px)");

    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);
    const teacherQueryState = useGetPrivateTeacherQuery(teacherId);

    useEffect(() => {
        let totalProceeds = 0;
        props.proceedsRecords.forEach((proceedsRecord) => {
            totalProceeds += proceedsRecord.amount;
        });
        setTotalProceeds(totalProceeds);
    }, [props.proceedsRecords]);

    const getContent = useCallback((proceedsRecord: ProceedsRecordResponse) => {
        if (proceedsRecord.type === "fee") {
            return `講座「${proceedsRecord.class?.course?.title}」の売上金`;
        }
        if (proceedsRecord.type === "proratedFee") {
            return `講座「${proceedsRecord.class?.course?.title}」の売上金`;
        }
        if (proceedsRecord.type === "conversion") {
            return `Trailポイントへの変換`;
        }
        if (proceedsRecord.type === "transfer") {
            return `売上金の振込`;
        }
        if (proceedsRecord.type === "compensation") {
            return "補填";
        }
    }, []);

    const getTime = useCallback((createdAt: Date | undefined) => {
        if (createdAt) {
            const modifiedCreatedAt = new Date(createdAt);
            const year = modifiedCreatedAt.getFullYear();
            const month = modifiedCreatedAt.getMonth() + 1;
            const date = modifiedCreatedAt.getDate();
            const day = WHAT_DAY_LIST[modifiedCreatedAt.getDay()];
            const hour = ("00" + modifiedCreatedAt.getHours()).slice(-2);
            const minute = ("00" + modifiedCreatedAt.getMinutes()).slice(-2);
            return `${year}/${month}/${date}(${day}) ${hour}:${minute}`;
        }
    }, []);

    const thresholdNumber = largeThresholdUp ? 10 : mediumThresholdUp ? 8 : smallThresholdUp ? 6 : 4;
    return (
        <div className={styles.proceedsRecordsWrapper}>
            <div className={styles.currentProceedsWrapper}>
                <div className={styles.currentProceedsTitle}>現在の売上金</div>
                <div className={styles.currentProceeds}>¥{new Intl.NumberFormat("ja-JP").format(totalProceeds)}</div>
                {props.proceedsRecords.length > 0 && (
                    <div className={styles.currentTarget}>
                        {props.proceedsRecords.length}件中 {(pageNumber - 1) * 20 + 1} ~{" "}
                        {pageNumber * 20 < props.proceedsRecords.length
                            ? pageNumber * 20
                            : props.proceedsRecords.length}
                        件 を表示
                    </div>
                )}
            </div>
            {props.proceedsRecords.length === 0 ? (
                <div className={styles.emptyProceedsRecord}>売上金履歴はありません。</div>
            ) : (
                <ul className={styles.proceedsRecords}>
                    <li className={styles.topProceedsRecord}>
                        <div className={styles.topProceedsRecordItemDate}>日時</div>
                        <div className={styles.topProceedsRecordItemContent}>内容</div>
                        <div className={styles.topProceedsRecordItemPoint}>増減</div>
                    </li>
                    {props.proceedsRecords
                        .slice((pageNumber - 1) * 20, (pageNumber - 1) * 20 + 20)
                        .map((proceedsRecord) => {
                            return (
                                <li key={proceedsRecord.proceedsRecordId} className={styles.proceedsRecord}>
                                    <div className={styles.proceedsRecordItemDate}>
                                        {getTime(new Date(proceedsRecord.createdAt))}
                                    </div>
                                    <div className={styles.proceedsRecordItemContent}>{getContent(proceedsRecord)}</div>
                                    <div className={styles.proceedsRecordItemPoint}>
                                        {typeof proceedsRecord.amount === "number" && proceedsRecord.amount > 0
                                            ? `+${proceedsRecord.amount}`
                                            : `proceedsRecord.amount`}
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            )}
            {20 < props.proceedsRecords.length && (
                <PageNumbers
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    items={props.proceedsRecords}
                    itemsPerPage={20}
                    thresholdNumber={thresholdNumber}
                />
            )}
        </div>
    );
});
