import { Button, Popover } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import styles from "@/pages/Common/CourseDetails/index.module.scss";
import { ClassResponse } from "@/store/autogenApi";
import { Favorite } from "../../Favorite";
import { getOnlyTime } from "@/utils/GetOnlyTime";

interface Props {
    title?: string;
    courseId: string;
    isFavorite: boolean;
    isHostTeacher: boolean;
    isReserved: boolean;
    targetClass: ClassResponse | undefined;
    addScheduleModalOpen: boolean;
    howMuchLackOfPoints: number;
    activeClasses: ClassResponse[];
    fixedContentsEl: React.MutableRefObject<HTMLElement | null>;
    totalNecessaryPoints: number;
    formula: string;
    userMode: string;
    isReservationButtonClicked: boolean;
    thresholdOver: boolean;
    getJapaneseDay: (idx: number) => string;
    handleTargetClassChange: (classId: string | undefined) => void;
    handleChargeButtonClick: () => void;
    handleReservationButtonClick: (classId: string) => void;
    handleAddScheduleModalOpenButtonClick: () => void;
    handleAddScheduleModalClose: () => void;
    handleClassButtonClick: (classId: string) => void;
    handleSignUpModalOpen: () => void;
    initializeReservationButtonClicked: () => void;
}

export const SPContents: React.VFC<Props> = (props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    useEffect(() => {
        const body = document.getElementById("body");
        const fixedContentsEl = props.fixedContentsEl.current;
        const opacityFunc = () => {
            if (body && fixedContentsEl) {
                const headerAndNavigationHeight = 105;
                const maxScrollY = body.clientHeight + headerAndNavigationHeight - window.innerHeight;
                if (window.scrollY > maxScrollY) {
                    if (fixedContentsEl.style.opacity === "1") {
                        fixedContentsEl.style.opacity = "0";
                    }
                } else {
                    if (fixedContentsEl.style.opacity === "0") {
                        fixedContentsEl.style.opacity = "1";
                    }
                }
            }
        };
        window.addEventListener("scroll", opacityFunc);
        return () => {
            window.removeEventListener("scroll", opacityFunc);
        };
    }, [props.fixedContentsEl.current]);

    const handleOtherScheduleButtonClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handlePopoverClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const open = Boolean(anchorEl);

    return (
        <div
            className={styles.extraSmallFixedContents}
            ref={(node: HTMLElement | null) => {
                props.fixedContentsEl.current = node;
            }}
        >
            {props.isHostTeacher ? (
                <div className={styles.yourWrapper}>
                    <div className={styles.yourCourse}>あなたが作成した講座です。</div>
                    <Button
                        className={styles.courseManagementButton}
                        component={Link}
                        to={`/CourseManagement/${props.courseId}`}
                    >
                        講座管理
                    </Button>
                </div>
            ) : (
                <>
                    {props.activeClasses.length > 0 ? (
                        <>
                            <div className={styles.topWrapper}>
                                {props.activeClasses &&
                                    props.activeClasses.map((classResponse) => {
                                        const lessons = classResponse.lessons;
                                        if (lessons && classResponse.classId === props.targetClass?.classId) {
                                            return (
                                                <div className={styles.dateItem} key={classResponse.classId}>
                                                    {lessons.length === 1 && lessons[0].startTime ? (
                                                        <>
                                                            <span className={styles.period}>{`${
                                                                new Date(lessons[0].startTime).getMonth() + 1
                                                            }月${new Date(
                                                                lessons[0].startTime,
                                                            ).getDate()}日(${props.getJapaneseDay(
                                                                new Date(lessons[0].startTime).getDay(),
                                                            )}) ${getOnlyTime(
                                                                new Date(lessons[0].startTime),
                                                            )}~${getOnlyTime(new Date(lessons[0].endTime))}`}</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {lessons.map((lesson, idx) => {
                                                                if (idx === 0 && lesson.startTime) {
                                                                    return (
                                                                        <>
                                                                            <div key={lesson.lessonId}>
                                                                                <span className={styles.period}>{`${
                                                                                    new Date(
                                                                                        lesson.startTime,
                                                                                    ).getMonth() + 1
                                                                                }月${new Date(
                                                                                    lesson.startTime,
                                                                                ).getDate()}日(${props.getJapaneseDay(
                                                                                    new Date(lesson.startTime).getDay(),
                                                                                )})`}</span>
                                                                            </div>
                                                                            <span className={styles.bar}>-</span>
                                                                        </>
                                                                    );
                                                                } else if (
                                                                    lessons.length &&
                                                                    idx === lessons.length - 1 &&
                                                                    lessons[idx].endTime
                                                                ) {
                                                                    return (
                                                                        <>
                                                                            <span className={styles.period}>
                                                                                {`${
                                                                                    new Date(
                                                                                        lessons[idx].endTime,
                                                                                    ).getMonth() + 1
                                                                                }月${new Date(
                                                                                    lessons[idx].endTime,
                                                                                ).getDate()}日(${props.getJapaneseDay(
                                                                                    new Date(
                                                                                        lessons[idx].endTime,
                                                                                    ).getDay(),
                                                                                )})`}
                                                                            </span>
                                                                        </>
                                                                    );
                                                                } else {
                                                                    return <></>;
                                                                }
                                                            })}
                                                        </>
                                                    )}
                                                </div>
                                            );
                                        }
                                    })}
                                {props.targetClass?.pricePerPayment && (
                                    <div className={styles.price}>
                                        ¥
                                        {new Intl.NumberFormat("ja-JP").format(
                                            Math.floor(props.targetClass.pricePerPayment),
                                        )}
                                    </div>
                                )}
                            </div>
                            {props.isReserved && (
                                <div className={styles.reservedWrapper}>
                                    <BsCheckCircleFill className={styles.checkIcon} />
                                    <div className={styles.reserved}>予約済み</div>
                                </div>
                            )}
                            <div className={styles.bottomWrapper}>
                                {props.isReserved ? (
                                    <Button
                                        className={styles.classButton}
                                        variant="contained"
                                        onClick={() => {
                                            props.activeClasses[0].classId &&
                                                props.handleClassButtonClick(props.activeClasses[0].classId);
                                        }}
                                    >
                                        授業管理
                                    </Button>
                                ) : (
                                    <>
                                        <Popover
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handlePopoverClose}
                                            anchorOrigin={{
                                                vertical: "top",
                                                horizontal: "center",
                                            }}
                                            transformOrigin={{
                                                vertical: "bottom",
                                                horizontal: "center",
                                            }}
                                            style={{ marginBottom: "10px" }}
                                        >
                                            <div className={styles.lessonsPopoverContents}>
                                                {props.activeClasses &&
                                                    props.activeClasses.map((classResponse) => {
                                                        const lessons = classResponse.lessons;
                                                        if (lessons) {
                                                            return (
                                                                <button
                                                                    className={styles.lessonItemButton}
                                                                    key={classResponse.classId}
                                                                    onClick={() => {
                                                                        props.handleTargetClassChange(
                                                                            classResponse.classId,
                                                                        );
                                                                        handlePopoverClose();
                                                                    }}
                                                                >
                                                                    {lessons.length === 1 && lessons[0].startTime ? (
                                                                        <>
                                                                            <span className={styles.period}>{`${
                                                                                new Date(
                                                                                    lessons[0].startTime,
                                                                                ).getMonth() + 1
                                                                            }月${new Date(
                                                                                lessons[0].startTime,
                                                                            ).getDate()}日(${props.getJapaneseDay(
                                                                                new Date(lessons[0].startTime).getDay(),
                                                                            )}) ${getOnlyTime(
                                                                                new Date(lessons[0].startTime),
                                                                            )}~${getOnlyTime(
                                                                                new Date(lessons[0].endTime),
                                                                            )}`}</span>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {lessons.map((lesson, idx) => {
                                                                                if (idx === 0 && lesson.startTime) {
                                                                                    return (
                                                                                        <>
                                                                                            <div key={lesson.lessonId}>
                                                                                                <span
                                                                                                    className={
                                                                                                        styles.period
                                                                                                    }
                                                                                                >{`${
                                                                                                    new Date(
                                                                                                        lesson.startTime,
                                                                                                    ).getMonth() + 1
                                                                                                }月${new Date(
                                                                                                    lesson.startTime,
                                                                                                ).getDate()}日(${props.getJapaneseDay(
                                                                                                    new Date(
                                                                                                        lesson.startTime,
                                                                                                    ).getDay(),
                                                                                                )})`}</span>
                                                                                            </div>
                                                                                            <span
                                                                                                className={styles.bar}
                                                                                            >
                                                                                                -
                                                                                            </span>
                                                                                        </>
                                                                                    );
                                                                                } else if (
                                                                                    lessons.length &&
                                                                                    idx === lessons.length - 1 &&
                                                                                    lessons[idx].endTime
                                                                                ) {
                                                                                    return (
                                                                                        <>
                                                                                            <span
                                                                                                className={
                                                                                                    styles.period
                                                                                                }
                                                                                            >
                                                                                                {`${
                                                                                                    new Date(
                                                                                                        lessons[
                                                                                                            idx
                                                                                                        ].endTime,
                                                                                                    ).getMonth() + 1
                                                                                                }月${new Date(
                                                                                                    lessons[
                                                                                                        idx
                                                                                                    ].endTime,
                                                                                                ).getDate()}日(${props.getJapaneseDay(
                                                                                                    new Date(
                                                                                                        lessons[
                                                                                                            idx
                                                                                                        ].endTime,
                                                                                                    ).getDay(),
                                                                                                )})`}
                                                                                            </span>
                                                                                        </>
                                                                                    );
                                                                                } else {
                                                                                    return <></>;
                                                                                }
                                                                            })}
                                                                        </>
                                                                    )}
                                                                </button>
                                                            );
                                                        }
                                                    })}
                                            </div>
                                        </Popover>
                                        <Button
                                            className={styles.otherScheduleButton}
                                            variant="contained"
                                            onClick={handleOtherScheduleButtonClick}
                                        >
                                            他日程を選ぶ
                                        </Button>
                                        <Button
                                            className={styles.reservationButton}
                                            variant="contained"
                                            onClick={() => {
                                                if (!props.targetClass?.classId) return;
                                                props.handleReservationButtonClick(props.targetClass?.classId);
                                            }}
                                        >
                                            予約へ進む
                                        </Button>
                                    </>
                                )}
                                {/* {props.howMuchLackOfPoints > 0 ? (
                            <div className={styles.chargeWrapper}>
                                <div className={styles.howMuchLackOfPoints}>
                                    {props.howMuchLackOfPoints}pt不足しています。
                                </div>
                                <Button
                                    className={styles.chargeButton}
                                    variant="contained"
                                    onClick={props.handleChargeButtonClick}
                                >
                                    ポイントをチャージ
                                </Button>
                            </div>
                        ) : (
                            <Button
                                className={styles.reservationButton}
                                variant="contained"
                                onClick={props.handleReservationButtonClick}
                            >
                                予約へ進む
                            </Button>
                        )} */}
                                <Favorite
                                    isSPVersion
                                    isSimplified
                                    userMode={props.userMode}
                                    isReservationButtonClicked={props.isReservationButtonClicked}
                                    handleSignUpModalOpen={props.handleSignUpModalOpen}
                                    initializeReservationButtonClicked={props.initializeReservationButtonClicked}
                                />
                            </div>
                        </>
                    ) : (
                        <div className={styles.noScheduleWrapper}>
                            <div className={styles.noSchedule}>現在、受講者募集中のクラスはありません。</div>
                            <Favorite
                                isSPVersion
                                userMode={props.userMode}
                                isReservationButtonClicked={props.isReservationButtonClicked}
                                handleSignUpModalOpen={props.handleSignUpModalOpen}
                                initializeReservationButtonClicked={props.initializeReservationButtonClicked}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
