import { memo, useCallback, useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import styles from "./index.module.scss";
import defaultCourseImage from "@/images/defaultCourseImage.jpg";
import { ClassResponse, StudentToDoResponse } from "@/store/autogenApi";
import { useHistory } from "react-router";
import { OverWrapper } from "@/components/OverWrapper";
import { NextLessonInfo } from "./NextLessonInfo";
import { UserInfoModal } from "@/components/UserInfoModal";
import { ToDo } from "./ToDo";
import { ImageFromS3 } from "@/components/atoms/images/ImageFromS3";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    class: ClassResponse;
    disableToJoin: boolean;
    targetStudentToDo: StudentToDoResponse | undefined;
}

export const ManagementTopContents: React.VFC<Props> = memo(function ManagementTopContents(props) {
    const [teacherInfoModalOpen, setTeacherInfoModalOpen] = useState<boolean>(false);
    const history = useHistory();

    const handleDetailsButtonClick = useCallback(() => {
        if (props.class.course?.publicRequest) {
            history.push(`/PublicRequestDetails/${props.class.course.publicRequest.publicRequestId}`);
        } else {
            history.push(`/CourseDetails/${props.class.course?.courseId}`);
        }
    }, [props.class.course]);

    const handleTeacherButtonClick = useCallback(() => {
        setTeacherInfoModalOpen(true);
    }, []);

    const handleTeacherInfoModalClose = useCallback(() => {
        setTeacherInfoModalOpen(false);
    }, []);

    return (
        <OverWrapper style={{ backgroundColor: "#F1F1F1", paddingBottom: "20px" }}>
            <div className={styles.managementTopContentsWrapper}>
                <div className={styles.managementTopContents}>
                    <div className={styles.basicInfoWrapper}>
                        <div className={styles.imageAndCommonWrapper}>
                            <ImageFromS3
                                url={props.class.course.thumbnailUrl || defaultCourseImage}
                                objectKey={props.class.course.thumbnailObjectKey}
                                className={styles.courseBaseImage}
                            />
                            <div className={styles.commonWrapper}>
                                <div className={styles.courseType}>
                                    {props.class.course?.courseType === "short" ? "単発・短期講座" : "定期講座"}
                                </div>
                                <div className={styles.courseBaseTitle}>{props.class.course?.title}</div>
                                <button className={styles.teacherButton} onClick={handleTeacherButtonClick}>
                                    <AvatarFromS3
                                        className={styles.teacherAvatar}
                                        url={props.class.course.teacher.iconImageUrl}
                                        objectKey={props.class.course.teacher.iconImageObjectKey}
                                    />
                                    <div className={styles.teacherName}>
                                        {props.class.course?.teacher?.nickname}先生
                                    </div>
                                </button>
                                {teacherInfoModalOpen && props.class.course?.teacher && (
                                    <UserInfoModal
                                        openedTarget={props.class.course?.teacher}
                                        handleClose={handleTeacherInfoModalClose}
                                        zIndex={1000}
                                        isChatVisible
                                    />
                                )}
                                <div className={styles.buttonsWrapper}>
                                    <Button onClick={handleDetailsButtonClick} className={styles.courseDetailsButton}>
                                        詳細
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {props.class.course && (
                        <NextLessonInfo
                            courseType={props.class.course.courseType}
                            class={props.class}
                            disableToJoin={props.disableToJoin}
                        />
                    )}
                </div>
                {/* ヘッダーにTODOリストあるのでおそらくToDoコンポーネントは必要ない。一旦コメントアウトしておく */}
                {/* {props.targetStudentToDo && <ToDo targetStudentToDo={props.targetStudentToDo} />} */}
            </div>
        </OverWrapper>
    );
});
