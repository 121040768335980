import { createTheme, Step, StepLabel, Stepper, ThemeProvider } from "@material-ui/core";

interface Props {
    activeStep: number;
}

const STEPS = ["支払い情報の入力", "支払い内容の確認", "完了"];

const theme = createTheme({
    overrides: {
        MuiStepper: {
            root: {
                padding: "10px",
            },
        },
        MuiStepIcon: {
            active: {
                color: "#305077 !important",
            },
            completed: {
                color: "#305077 !important",
            },
        },
        MuiStepLabel: {
            label: {
                color: "#333 !important",
                fontWeight: "bold !important" as "bold",
                fontSize: "16px",
                "@media (max-width: 960px)": {
                    fontSize: "14px",
                },
                "@media (max-width: 600px)": {
                    fontSize: "14px",
                },
            },
        },
        MuiTypography: {
            body1: {
                fontSize: "16px",
                "@media (max-width: 960px)": {
                    fontSize: "14px",
                },
                "@media (max-width: 600px)": {
                    fontSize: "14px",
                },
            },
        },
        MuiSvgIcon: {
            root: {
                fontSize: "30px",
                "@media (max-width: 960px)": {
                    fontSize: "25px",
                },
                "@media (max-width: 600px)": {
                    fontSize: "25px",
                },
            },
        },
    },
});

export const StyledStepper: React.VFC<Props> = (props) => {
    return (
        <ThemeProvider theme={theme}>
            <Stepper alternativeLabel activeStep={props.activeStep}>
                {STEPS.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </ThemeProvider>
    );
};
