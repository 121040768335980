const STUDENT = "student";
const TEACHER = "teacher";
const ANONYMOUS = "anonymous";

export type UserType = typeof STUDENT | typeof TEACHER | typeof ANONYMOUS;

export type modeType = {
    [STUDENT]: boolean;
    [TEACHER]: boolean;
    [ANONYMOUS]: boolean;
};

export type modesType = {
    student: modeType;
    teacher: modeType;
    anonymous: modeType;
    uninitializedStudent: modeType;
    uninitializedTeacher: modeType;
    login: modeType;
    everyone: modeType;
    uninitializedUser: modeType;
};

export const MODES: modesType = {
    student: {
        [STUDENT]: true,
        [TEACHER]: false,
        [ANONYMOUS]: false,
    },
    teacher: {
        [STUDENT]: false,
        [TEACHER]: true,
        [ANONYMOUS]: false,
    },
    anonymous: {
        [STUDENT]: false,
        [TEACHER]: false,
        [ANONYMOUS]: true,
    },
    uninitializedStudent: {
        [STUDENT]: false,
        [TEACHER]: false,
        [ANONYMOUS]: false,
    },
    uninitializedTeacher: {
        [STUDENT]: false,
        [TEACHER]: false,
        [ANONYMOUS]: false,
    },
    login: {
        [STUDENT]: true,
        [TEACHER]: true,
        [ANONYMOUS]: false,
    },
    everyone: {
        [STUDENT]: true,
        [TEACHER]: true,
        [ANONYMOUS]: true,
    },
    uninitializedUser: {
        [STUDENT]: false,
        [TEACHER]: false,
        [ANONYMOUS]: false,
    },
};
