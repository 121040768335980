import { MyProposalCard } from "./MyProposalCard";
import styles from "./index.module.scss";
import { ProposalResponse } from "@/store/autogenApi";

interface Props {
    visible: boolean;
    proposals: ProposalResponse[];
    repetition: number;
    handleProposalCardClick: (proposal: ProposalResponse) => void;
}

export const MyProposalsTabContents: React.VFC<Props> = (props) => {
    return (
        <div role="tabpanel" hidden={!props.visible} className={styles.tabContents}>
            {props.visible && (
                <>
                    {props.proposals.length === 0 ? (
                        <div className={styles.emptyProposals}>対象の提案がありません。</div>
                    ) : (
                        <div className={styles.cardsList}>
                            {[...Array(Math.ceil(props.proposals.length / props.repetition))]
                                .map((_, i) => i)
                                .map((idx) => (
                                    <>
                                        <div className={styles.cards} key={idx}>
                                            {props.proposals
                                                .slice(
                                                    idx * props.repetition,
                                                    idx * props.repetition + props.repetition,
                                                )
                                                .map((proposal) => {
                                                    return (
                                                        <MyProposalCard
                                                            proposal={proposal}
                                                            key={proposal.proposalId}
                                                            isFlex
                                                            handleProposalCardClick={props.handleProposalCardClick}
                                                        />
                                                    );
                                                })}
                                            {props.proposals.slice(
                                                idx * props.repetition,
                                                idx * props.repetition + props.repetition,
                                            ).length < props.repetition &&
                                                [
                                                    ...Array(
                                                        props.repetition -
                                                            props.proposals.slice(
                                                                idx * props.repetition,
                                                                idx * props.repetition + props.repetition,
                                                            ).length,
                                                    ),
                                                ].map((_, idx) => (
                                                    <MyProposalCard
                                                        key={idx}
                                                        isHidden
                                                        isFlex
                                                        handleProposalCardClick={props.handleProposalCardClick}
                                                    />
                                                ))}
                                        </div>
                                    </>
                                ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
