import { FAQDetailsBase } from "../../FAQDetailsBase";

import { REFUND_FOR_NON_OPENING_CONFIGS } from "./RefundForNonOpening";

export const RefundForNonOpening: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="講座が開講されなかった場合の返金について"
            text="参加者数が先生が定める開講な必要な人数に達しなかった場合は開講されません。支払い方法によって返金方法が異なります。"
            list={["クレジットカード払い"]}
            showIdx
            FAQConfigs={REFUND_FOR_NON_OPENING_CONFIGS}
        />
    );
};
