import MenuItem from "@material-ui/core/MenuItem";

import styles from "../../userInfoInput.module.scss";
import { None } from "../Errors/None";
import { memo } from "react";
import { Mandatory } from "@/components/Tag/Mandatory";
import { IsPublicRadioButtons } from "@/components/IsPublicRadioButtons";
import { DefaultSelect } from "@/components/DefaultSelect";
import { GRADE_LIST_FOR_STUDENT } from "@/utils/GradeListForStudent";
import { GRADE_LIST_FOR_TEACHER } from "@/utils/GradeListForTeacher";
import { useLocation } from "react-router";

interface Props {
    handleChange?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    open?: boolean;
    value?: string;
    checked: boolean;
    isPublic: boolean;
    handleIsPublicChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Grade: React.VFC<Props> = memo(function Grade(props) {
    const location = useLocation();
    const currentPath = location.pathname;
    const gradeList = currentPath === "/InfoInput" ? GRADE_LIST_FOR_STUDENT : GRADE_LIST_FOR_TEACHER;

    return (
        <>
            <div className={styles.inputItem}>
                <div className={styles.leftWrapper}>
                    <div className={styles.itemTitleWrapper}>
                        <span className={styles.itemTitle}>職業・学年</span>
                    </div>
                    {!props.open && <Mandatory />}
                </div>
                <div className={styles.rightWrapper}>
                    {props.open ? (
                        <div className={styles.openTrue}>{`${props.value} - ${
                            props.isPublic ? "公開" : "非公開"
                        }`}</div>
                    ) : (
                        <>
                            {props.handleChange && (
                                <DefaultSelect
                                    name="grade"
                                    value={props.value ?? ""}
                                    handleChange={props.handleChange}
                                    options={["", ...gradeList].map((grade) => ({
                                        value: grade,
                                        label: grade,
                                    }))}
                                />
                            )}
                            {props.checked && <None value={props.value ?? ""} />}
                            <IsPublicRadioButtons
                                isPublic={props.isPublic}
                                handleIsPublicChange={props.handleIsPublicChange}
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    );
});
