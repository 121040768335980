import styles from "@/pages/Common/FAQDetails/index.module.scss";
import { UrlInfo } from "../FAQDetailsBase";

interface Props {
    urlInfos: UrlInfo[];
}

export const UrlInfos: React.VFC<Props> = (props) => {
    return (
        <ul className={styles.urlList}>
            {props.urlInfos.map((urlInfo, idx) => (
                <li className={styles.urlListItem} key={idx}>
                    <a href={urlInfo.url} className={styles.url}>
                        ・{urlInfo.word}
                    </a>
                </li>
            ))}
        </ul>
    );
};
