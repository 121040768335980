import { useEffect, useState } from "react";

import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import styles from "./index.module.scss";
import { RootState } from "@/ducks";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { Navigation } from "@/components/Navigation";

const NAVIGATION_LIST: Navigation[] = [{ title: "レビュー投稿ガイドライン", url: "/ReviewSubmissionGuidelines" }];

export const ReviewSubmissionGuidelines: React.VFC = () => {
    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    const userMode = useSelector((state: RootState) => state.jwt.userMode);
    return (
        <>
            <div className={styles.reviewSubmissionGuidelinesWrapper}>
                <h2 className={styles.title}>Trail レビュー投稿ガイドライン </h2>
                <section className={styles.reviewSubmissionGuidelines}>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>はじめに</h3>
                        <section className={styles.description}>
                            Trailは、講座情報の可視化を通じて、学びをよりオープンで気軽なものにしていくことを目指しております。
                        </section>
                        <section className={styles.description}>
                            ユーザーによるレビュー投稿は、講座の受講をはじめとしたTrailで掲載されているサービスの購入を検討しているユーザーにとって貴重な情報源であり、同時にTrailで教えている先生にとって先生の実績及びブランド蓄積につながる貴重な資産となっています。
                        </section>
                        <section className={styles.description}>
                            上記を踏まえ、教える側、学ぶ側の双方にとって質が高く価値のあるレビュー投稿を維持するために、本ガイドラインを制定させて頂きます。
                        </section>
                        <section className={styles.description}>
                            また、先生が生徒からのレビューに対する返信を投稿する場合（レビューコメント）についても、本ガイドラインを遵守してください。
                        </section>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1)レビューが投稿できる方
                                <section className={styles.itemDescription}>
                                    レビューは、講座の受講等、先生により提供されるサービスの体験についての感想を投稿するものであるため、実際に受講を体験した生徒のみ投稿することができます。
                                    レビューは以下のタイミングで投稿することができます。
                                </section>
                                <ol className={styles.smallItems}>
                                    <li className={styles.smallItem}>・単発・短期講座を受講したあと</li>
                                    <li className={styles.smallItem}>・定期講座：1か月ごと/終了時</li>
                                </ol>
                            </li>
                            <li className={styles.item}>
                                2)投稿に際してのお願い
                                <section className={styles.itemDescription}>
                                    講座を実際に受講しなかった、または、サービスの提供を受けなかった場合はレビュー投稿をお控えください。
                                    投稿は、実際に受講した講座および提供されたサービスで体験した事柄に関する内容のみとすることをお願いします。
                                    それ以外の内容については、サイト内メッセージを使い、先生へ直接連絡をしてください。
                                    レビューは、公に残るものであること、開催者にとって自己ブランディングの資産となるものであることなどを踏まえ、ユーザーの皆様にはガイドライン遵守へのご協力をお願いします。
                                </section>
                            </li>
                        </ol>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>禁止行為</h3>
                        <section className={styles.description}>
                            各種利用規約、ルール、ガイドライン等で定める事項のほか、下記に定める行為を禁止行為とします。
                            利用者がこれらの行為を行ったと当社が判断した場合には、事前の通知なしにレビューの削除、レビューの全部又は、一部の利用停止、その他当社が必要と判断する措置を講じることができるものとします。
                        </section>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                <section className={styles.itemDescription}></section>
                                <ol className={styles.smallItems}>
                                    <li className={styles.smallItem}>
                                        ・当社、又は他の登録ユーザー、第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為（かかる侵害を直接又は間接に惹起する行為を含む。）
                                    </li>
                                    <li className={styles.smallItem}>
                                        ・犯罪予告、犯罪の指南等、犯罪を引き起こすおそれのある行為、その他犯罪行為に関連する行為又は公序良俗に反する行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        ・猥褻な情報又は青少年に有害な情報を送信する行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        ・法令又は当社若しくは登録ユーザーが所属する業界団体の内部規則に違反する行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        ・人種、民族、信条、性別、社会的身分、居住場所、身体的特徴、病歴、教育、財産及び収入等を根拠にする差別的表現行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        ・倫理的に問題がある低俗、有害、下品な行為、他人に嫌悪感を与える内容の情報を開示する行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        ・迷惑行為、嫌がらせ行為、誹謗中傷行為、その他当社、又は他の登録ユーザー、外部SNS事業者その他の第三者に精神的被害・経済的被害を与える可能性のある行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        ・広告又は報酬以外の営利を目的として、講座ページを作成する行為及びリンクを設置する行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        ・事実と異なる内容を記述する行為 ・その他、当社が不適切と判断する行為
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>レビュー内容に関する注意事項</h3>
                        <section className={styles.description}>
                            本サービスにおける口コミ情報、コメント、質問、回答、画像などのレビュー投稿は、利用規約及び、本ガイドラインを遵守し、ユーザーコミュニティに対する尊重を持って行ってください。
                            レビューは、１回のみ投稿を行えるものとし、ガイドライン違反と判断された場合を除いて修正等を行うことは原則できないものとします。
                            なお、以下のような投稿を行った場合、投稿の削除や利用停止などの措置の対象となりますのでご注意ください。
                        </section>
                        <ol className={styles.items}>
                            <li className={styles.item}>・個人への誹謗中傷</li>
                            <li className={styles.item}>・事実根拠の不明確な表現 </li>
                            <li className={styles.item}>・事実と異なる内容についての記載 </li>
                            <li className={styles.item}>・ご自身が体験していない事柄についての感想</li>
                            <li className={styles.item}>・氏名、住所、電話番号などの個人情報の記載</li>
                            <li className={styles.item}>・第三者のプライバシーを侵害する記載</li>
                            <li className={styles.item}>・該当の講座に関連のない記載</li>
                            <li className={styles.item}>
                                ・開催側に対して返金や質問など個別要求を一方的に感想投稿の場を使って求める行為
                            </li>
                        </ol>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>ガイドラインに違反する投稿に対する通報と対応</h3>
                        <section className={styles.description}>
                            本ガイドラインに違反する投稿が確認された場合は、サイト運営側の判断により、投稿者に対して修正・削除を依頼する、もしくは、サイト運営側で直接修正・削除を行う場合があります。
                            なお、投稿者本人以外の方からの通報については、違反の事象に関する報告及び指摘のみを受け付けており、文章を特定した具体的な修正依頼は受け付けません。
                        </section>
                    </section>
                    <section className={styles.dateWrapper}>
                        <div className={styles.date}>2024年4月12日制定</div>
                    </section>
                </section>
                <Button component={Link} to={userMode === "teacher" ? "/" : "/"} className={styles.toHomeButton}>
                    ホームに戻る
                </Button>
            </div>
        </>
    );
};
