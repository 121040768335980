import { Button, useMediaQuery } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "@/actions/logging";
import { decodeJwt } from "@/ducks/jwt";
import { JwtRequestBody } from "@/store/autogenApi";

import styles from "./index.module.scss";

export const Forbidden = memo(function Forbidden() {
    const [currentForbiddenWrapperEl, setCurrentForbiddenWrapperEl] = useState<HTMLElement | null>(null);

    const sm = useMediaQuery("(min-width: 600px)");
    const md = useMediaQuery("(min-width: 960px)");

    const forbiddenWrapperRef = useCallback((headerEl: HTMLElement | null) => {
        setCurrentForbiddenWrapperEl(headerEl);
    }, []);

    const dispatch = useDispatch();

    const deleteUser = useCallback(async () => {
        const token = localStorage.getItem("token");
        const jwtRequestBody = decodeJwt(token) as JwtRequestBody;
        const userMode = jwtRequestBody.userMode;
        const userId = jwtRequestBody.userId;
        if (userMode !== "anonymous" || !userId) return;
        await Auth.deleteUser();
        dispatch(logout());
        localStorage.clear();
    }, [dispatch]);

    useEffect(() => {
        deleteUser();
    }, [deleteUser]);

    return (
        <div className={styles.forbiddenWrapper} ref={forbiddenWrapperRef}>
            <div className={styles.errorTitle}>エラーが発生しました。</div>
            <div className={styles.causeWrapper}>
                <ul className={styles.causeList}>
                    <li className={styles.cause}>・ページが存在しない</li>
                    <li className={styles.cause}>・処理中にリロードを行った</li>
                    <li className={styles.cause}>・アクセス権のないページにアクセスしようとした</li>
                </ul>
                <div className={styles.message}>などが原因として考えられます。</div>
            </div>
            <Button className={styles.homeButton} component={Link} to="/">
                ホームに戻る
            </Button>
        </div>
    );
});
