import { memo, useCallback, useState } from "react";
import { Mandatory } from "@/components/Tag/Mandatory";

import styles from "@/pages/Teacher/CreateCourse/index.module.scss";
import {
    CreateAvailableTimeRequestParams,
    CreateLessonRequestParams,
    LessonResponse,
    UpdateAvailableTimeRequestBody,
} from "@/store/autogenApi";

import { RegularCourse } from "./RegularCourse";
import { ShortCourse } from "./ShortCourse";
import { HourAndMinute } from "@/utils/DateUtils";

interface Props {
    isChecked: boolean;
    courseType: string;
    deadlineNumber: number;
    confirmModalOpen?: boolean;
    maxDaysInAWeek: number;
    maxMinutesPerLesson: number;
    availableTimes: (CreateAvailableTimeRequestParams | UpdateAvailableTimeRequestBody)[];
    otherCourseScheduleValidation: boolean;
    isNotDuplicateValidation: boolean;
    deadlineValidation: boolean;
    existingLessons: LessonResponse[];
    lessons: CreateLessonRequestParams[];
    applyingDeadline: Date;
    availableTimesLengthValidation: boolean;
    setAvailableTimes: React.Dispatch<
        React.SetStateAction<(CreateAvailableTimeRequestParams | UpdateAvailableTimeRequestBody)[]>
    >;
    handleMaxMinutesPerLessonChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleMaxDaysInAWeekChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleLessonsChange: (newLessons: CreateLessonRequestParams[]) => void;
    handleApplyingDeadlineChange: (newApplyingDeadline: Date) => void;
}

export interface ActiveWhichTimePopOver {
    coursesListIdx: number;
    coursesIdx: number;
}

export const Schedules: React.VFC<Props> = memo(function Schedules(props) {
    const [dayAnchorEl, setDayAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [activeDayOfWeekPopoverIdx, setActiveDayOfWeekPopoverIdx] = useState<number | undefined>(undefined);
    const [scheduleAnchorEl, setScheduleAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [deletedAvailableTimes, setDeletedAvailableTimes] = useState<Partial<UpdateAvailableTimeRequestBody>[]>([]);
    const [newDeadlineTime, setNewDeadlineTime] = useState<HourAndMinute>({
        hour: null,
        minute: null,
    });
    const [newStartTime, setNewStartTime] = useState<HourAndMinute>({
        hour: null,
        minute: null,
    });
    const [newEndTime, setNewEndTime] = useState<HourAndMinute>({
        hour: null,
        minute: null,
    });

    const handleDayTimeButtonClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>, idx: number) => {
            setActiveDayOfWeekPopoverIdx(idx);
            setDayAnchorEl(event.currentTarget);
            const targetAvailableTime = props.availableTimes.find(
                (availableTime) => availableTime.dayOfWeekIndex === idx,
            );
            if (!targetAvailableTime) return;
            const startHour = targetAvailableTime.startHour == undefined ? null : targetAvailableTime.startHour;
            const startMinute = targetAvailableTime.startMinute == undefined ? null : targetAvailableTime.startMinute;
            const endHour = targetAvailableTime.endHour == undefined ? null : targetAvailableTime.endHour;
            const endMinute = targetAvailableTime.endMinute == undefined ? null : targetAvailableTime.endMinute;
            setNewStartTime({
                hour: startHour,
                minute: startMinute,
            });
            setNewEndTime({
                hour: endHour,
                minute: endMinute,
            });
        },
        [props.availableTimes],
    );

    const handleDayTimePopoverClose = useCallback(() => {
        setActiveDayOfWeekPopoverIdx(undefined);
        setDayAnchorEl(null);
        setNewStartTime({
            hour: null,
            minute: null,
        });
        setNewEndTime({
            hour: null,
            minute: null,
        });
    }, []);

    const handleResetButtonClick = useCallback(() => {
        const newAvailableTimes = props.availableTimes.map((availableTime) => {
            if (availableTime.dayOfWeekIndex === activeDayOfWeekPopoverIdx) {
                const newAvailableTime = {
                    dayOfWeekIndex: availableTime.dayOfWeekIndex,
                    isActive: false,
                    startHour: null,
                    startMinute: null,
                    endHour: null,
                    endMinute: null,
                };
                return newAvailableTime;
            }
            return availableTime;
        });
        props.setAvailableTimes(newAvailableTimes);
        handleDayTimePopoverClose();
    }, [activeDayOfWeekPopoverIdx, props.availableTimes, props.setAvailableTimes]);

    // 定期講座confirm
    const confirmDayTime = useCallback(() => {
        if (
            newStartTime.hour == null ||
            newStartTime.minute == null ||
            newEndTime.hour == null ||
            newEndTime.minute == null ||
            activeDayOfWeekPopoverIdx == undefined
        )
            return;
        const existingAvailableTime = deletedAvailableTimes.find(
            (availableTime) => availableTime.dayOfWeekIndex === activeDayOfWeekPopoverIdx,
        );
        const newAvailableTimeId = existingAvailableTime ? existingAvailableTime.availableTimeId : undefined;
        const newAvailableTime = {
            dayOfWeekIndex: activeDayOfWeekPopoverIdx,
            startHour: newStartTime.hour,
            startMinute: newStartTime.minute,
            endHour: newEndTime.hour,
            endMinute: newEndTime.minute,
            isActive: true,
        };
        const modifiedAvailableTime: CreateAvailableTimeRequestParams | UpdateAvailableTimeRequestBody =
            newAvailableTimeId
                ? {
                      availableTimeId: newAvailableTimeId,
                      ...newAvailableTime,
                  }
                : (newAvailableTime as CreateAvailableTimeRequestParams);
        const newAvailableTimes = props.availableTimes.map((availableTime) => {
            if (availableTime.dayOfWeekIndex === activeDayOfWeekPopoverIdx) {
                return modifiedAvailableTime;
            } else {
                return availableTime;
            }
        });
        props.setAvailableTimes(newAvailableTimes);
        handleDayTimePopoverClose();
    }, [activeDayOfWeekPopoverIdx, deletedAvailableTimes, newStartTime, newEndTime, handleDayTimePopoverClose]);

    const deleteTargetLesson = useCallback(
        (idx: number) => {
            const newLessons = props.lessons?.filter((_, lessonIdx) => lessonIdx !== idx);
            props.handleLessonsChange(newLessons);
        },
        [props.lessons, props.handleLessonsChange],
    );

    const handleStartTimeChange = useCallback(
        (e: React.ChangeEvent<{ name: string; value: number }>) => {
            if (e.target.name === "hour") {
                setNewStartTime({ ...newStartTime, hour: e.target.value });
            } else {
                setNewStartTime({ ...newStartTime, minute: e.target.value });
            }
        },
        [newStartTime],
    );

    const handleEndTimeChange = useCallback(
        (e: React.ChangeEvent<{ name: string; value: number }>) => {
            if (e.target.name === "hour") {
                setNewEndTime({ ...newEndTime, hour: e.target.value });
            } else {
                setNewEndTime({ ...newEndTime, minute: e.target.value });
            }
        },
        [newEndTime],
    );

    const handleDeadlineDateChange = useCallback(
        (newDate: Date) => {
            if (!props.applyingDeadline) return;
            const newDeadline = new Date(props.applyingDeadline);
            newDeadline.setFullYear(newDate.getFullYear());
            newDeadline.setMonth(newDate.getMonth());
            newDeadline.setDate(newDate.getDate());
            props.handleApplyingDeadlineChange(newDeadline);
        },
        [props.applyingDeadline, props.handleApplyingDeadlineChange],
    );

    const handleDeadlineTimeChange = useCallback(
        (e: React.ChangeEvent<{ name: string; value: number }>) => {
            if (e.target.name === "hour") {
                setNewDeadlineTime({ ...newDeadlineTime, hour: e.target.value });
            } else {
                setNewDeadlineTime({ ...newDeadlineTime, minute: e.target.value });
            }
        },
        [newDeadlineTime],
    );

    const handleSchedulePopoverClose = useCallback(() => {
        setScheduleAnchorEl(null);
        setNewStartTime({
            hour: null,
            minute: null,
        });
        setNewEndTime({
            hour: null,
            minute: null,
        });
    }, []);

    const getCourseTypeTitle = useCallback(() => {
        if (props.courseType === "short") {
            return "日程";
        } else {
            return "対応可能時間帯";
        }
    }, [props.courseType]);

    const handleNewDeadlineTimeChange = useCallback((hourAndMinute: HourAndMinute) => {
        setNewDeadlineTime(hourAndMinute);
    }, []);

    return (
        <li className={styles.listItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>{getCourseTypeTitle()}</div>
                    {!props.confirmModalOpen && <Mandatory />}
                </div>
            </div>
            <div className={`${styles.rightWrapper} ${styles.specialRightWrapper}`}>
                {props.courseType === "short" && (
                    <ShortCourse
                        scheduleAnchorEl={scheduleAnchorEl}
                        checked={props.isChecked}
                        confirmModalOpen={props.confirmModalOpen}
                        isNotDuplicateValidation={props.isNotDuplicateValidation}
                        deadlineValidation={props.deadlineValidation}
                        lengthValidation={props.lessons.length > 0}
                        otherCourseScheduleValidation={props.otherCourseScheduleValidation}
                        newStartTime={newStartTime}
                        newEndTime={newEndTime}
                        newDeadlineTime={newDeadlineTime}
                        existingLessons={props.existingLessons}
                        lessons={props.lessons}
                        applyingDeadline={props.applyingDeadline}
                        handleSchedulePopoverClose={handleSchedulePopoverClose}
                        handleStartTimeChange={handleStartTimeChange}
                        handleEndTimeChange={handleEndTimeChange}
                        deleteTargetLesson={deleteTargetLesson}
                        handleDeadlineTimeChange={handleDeadlineTimeChange}
                        handleDeadlineDateChange={handleDeadlineDateChange}
                        handleNewDeadlineTimeChange={handleNewDeadlineTimeChange}
                        handleLessonsChange={props.handleLessonsChange}
                        handleApplyingDeadlineChange={props.handleApplyingDeadlineChange}
                    />
                )}
                {props.courseType === "regular" && (
                    <RegularCourse
                        activeDayOfWeekPopoverIdx={activeDayOfWeekPopoverIdx}
                        dayAnchorEl={dayAnchorEl}
                        checked={props.isChecked}
                        availableTimesLengthValidation={props.availableTimesLengthValidation}
                        availableTimes={props.availableTimes}
                        confirmModalOpen={props.confirmModalOpen}
                        newStartTime={newStartTime}
                        newEndTime={newEndTime}
                        maxMinutesPerLesson={props.maxMinutesPerLesson}
                        handleMaxMinutesPerLessonChange={props.handleMaxMinutesPerLessonChange}
                        maxDaysInAWeek={props.maxDaysInAWeek}
                        existingLessons={props.existingLessons}
                        handleMaxDaysInAWeekChange={props.handleMaxDaysInAWeekChange}
                        confirmDayTime={confirmDayTime}
                        handleStartTimeChange={handleStartTimeChange}
                        handleEndTimeChange={handleEndTimeChange}
                        handleDayTimePopoverClose={handleDayTimePopoverClose}
                        handleDayTimeButtonClick={handleDayTimeButtonClick}
                        handleResetButtonClick={handleResetButtonClick}
                    />
                )}
            </div>
        </li>
    );
});
