import { Button, Hidden, useMediaQuery } from "@material-ui/core";
import { memo, useCallback, useEffect, useState } from "react";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { CommonComponent } from "./CommonComponent";
import { ForTeacherComponent } from "./ForTeacherComponent";

import styles from "./index.module.scss";
import { PublicRequestResponse } from "@/store/autogenApi";

interface Props {
    userMode: string;
    publicRequestId: string;
}

export const Favorite: React.VFC<Props> = memo(function Favorite(props) {
    if (props.userMode === "teacher") {
        return <ForTeacherComponent userMode="teacher" />;
    } else {
        return <CommonComponent isFavorite={false} userMode={props.userMode} />;
    }
});
