import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditIcon from "@material-ui/icons/Edit";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { memo, useCallback, useEffect, useState } from "react";
import { TiArrowBack } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { ToastContents } from "@/components/Toast";

import { FileInfo } from "@/components/TrimmingModal";
import { RootState } from "@/ducks";
import styles from "@/pages/Student/MyPage/index.module.scss";
import {
    ClassResponse,
    PrivateStudentResponse,
    PrivateUserResponse,
    SubjectResponse,
    UpdateStudentRequestBody,
    UpdateUserRequestBody,
} from "@/store/autogenApi";
import {
    useGetPrivateStudentQuery,
    useGetReservedClassesQuery,
    useUpdateStudentMutation,
} from "@/store/hooks/students";
import { useGetPrivateUserQuery, useUpdateUserMutation } from "@/store/hooks/users";
import { dateToString } from "@/utils/DateUtils";
import { useUploadFiles } from "@/utils/UploadFiles";
import { config } from "./Config";

import { ImageWrapper } from "./ImageWrapper";
import { BirthYear } from "./Items/BirthYear";
import { FavoriteSubjects } from "./Items/FavoriteSubjects";
import { Gender } from "./Items/Gender";
import { Grade } from "./Items/Grade";
import { NickName } from "./Items/NickName";
import { Prefecture } from "./Items/Prefecture";

interface Props {
    edit: boolean;
    existingStudent: PrivateStudentResponse;
    existingUser: PrivateUserResponse;
    takenClasses: ClassResponse[];
    setEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Contents: React.VFC<Props> = memo(function Contents(props) {
    const [fileInfo, setFileInfo] = useState<FileInfo | undefined>(undefined);
    const [checked, setChecked] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [newStudent, setNewStudent] = useState<UpdateStudentRequestBody>();
    const [newUser, setNewUser] = useState<UpdateUserRequestBody>();
    const [newBirthDate, setNewBirthDate] = useState<Date | undefined>(undefined);
    const [year, setYear] = useState<number | undefined>(undefined);
    const [month, setMonth] = useState<number | undefined>(undefined);
    const [date, setDate] = useState<number | undefined>(undefined);
    const [favoriteSubjects, setFavoriteSubjects] = useState<SubjectResponse[]>([]);
    const [takenClassIds, setTakenClassIds] = useState<string[]>([]);

    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);
    const userId = useSelector((state: RootState) => state.jwt.userId as string);

    const updateUser = useUpdateUserMutation();
    const updateStudent = useUpdateStudentMutation();
    const uploadFiles = useUploadFiles();

    useEffect(() => {
        setNewStudent({
            ...newStudent,
            ...props.existingStudent,
        });
        setFileInfo({
            url: props.existingStudent.iconImageUrl,
            file: undefined,
        });
        if (props.existingStudent.favoriteSubjects) {
            setFavoriteSubjects(props.existingStudent.favoriteSubjects);
        }
    }, [props.existingStudent]);

    useEffect(() => {
        setNewUser({
            ...newUser,
            ...props.existingUser,
        });
        const modifiedBirthDate = new Date(props.existingUser.birthDate);
        setYear(modifiedBirthDate.getFullYear());
        setMonth(modifiedBirthDate.getMonth() + 1);
        setDate(modifiedBirthDate.getDate());
    }, [props.existingUser]);

    useEffect(() => {
        setTakenClassIds(props.takenClasses.map((classResponse) => classResponse.classId));
    }, [props.takenClasses]);

    const handleStudentChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | { name?: string | undefined; value: unknown }>) => {
            const name = e.target.name;
            const value = e.target.value as string;
            if (name) {
                setNewStudent({ ...newStudent, [name]: value, studentId: studentId });
            }
        },
        [newStudent, studentId],
    );

    const handleUserChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | { name?: string | undefined; value: unknown }>) => {
            const name = e.target.name;
            const value = e.target.value as string;
            if (name) {
                if (value === "private") {
                    setNewUser({ ...newUser, [name]: false, userId: userId });
                } else if (value === "public") {
                    setNewUser({ ...newUser, [name]: true, userId: userId });
                } else {
                    setNewUser({ ...newUser, [name]: value, userId: userId });
                }
            }
        },
        [newUser, userId],
    );

    const confirm = useCallback(async () => {
        try {
            setIsProcessing(true);
            const iconImageFiles = fileInfo?.file ? [fileInfo.file] : [];
            const urls = await uploadFiles(iconImageFiles, `users/${userId}/student/iconImage`);
            const updateStudentRequestBody: UpdateStudentRequestBody = {
                studentId: studentId,
                nickname: newStudent.nickname,
                iconImageUrl: urls[0],
                favoriteSubjectIds: favoriteSubjects.map((subject) => subject.subjectId),
            };
            await updateStudent({ updateStudentRequestBody: updateStudentRequestBody });
            const updateUserRequestBody: UpdateUserRequestBody = {
                userId,
                birthDate: newBirthDate ? dateToString(newBirthDate) : undefined,
                isBirthDatePublic: newUser.isBirthDatePublic,
                gender: newUser?.gender,
                isGenderPublic: newUser?.isGenderPublic,
                prefecture: newUser?.prefecture,
                isPrefecturePublic: newUser?.isPrefecturePublic,
                grade: newUser?.grade,
                isGradePublic: newUser?.isGradePublic,
            };
            await updateUser({
                updateUserRequestBody: updateUserRequestBody,
            });
            toast(<ToastContents title="生徒プロフィール更新完了" isCompleted />);
            setIsProcessing(false);
            props.setEdit(false);
            window.scrollTo(0, 0);
        } catch (error) {
            console.log(error);
        }
    }, [
        studentId,
        userId,
        fileInfo,
        favoriteSubjects,
        updateStudent,
        updateUser,
        newBirthDate,
        newStudent,
        newUser,
        favoriteSubjects,
        props.setEdit,
    ]);

    const editFunc = useCallback(() => {
        if (props.edit && newStudent && newUser) {
            const targetStudentParams = {
                nickname: newStudent.nickname,
            };
            const teacherLimitValidation = Object.entries(targetStudentParams).every(([key, value]) => {
                const limitNumber = config[key as keyof UpdateStudentRequestBody];
                if (limitNumber && value) {
                    return 0 < value.length && value.length <= limitNumber;
                } else {
                    return false;
                }
            });
            const userLimitValidation = newUser.gender && newUser.birthDate && newUser.grade && newUser.prefecture;
            if (teacherLimitValidation && userLimitValidation) {
                confirm();
            } else {
                setChecked(true);
                window.scrollTo(0, 0);
            }
        } else {
            props.setEdit(true);
        }
    }, [props.edit, newStudent, newUser, confirm]);

    const handleBackButtonClick = useCallback(() => {
        setNewStudent({
            ...newStudent,
            ...props.existingStudent,
        });
        setNewUser({
            ...newUser,
            ...props.existingUser,
        });
        props.setEdit(false);
    }, [newStudent, newUser, props.existingStudent, props.existingUser]);

    const handleYearChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement | { value: unknown }>) => {
            const value = Number(e.target.value);
            if (!Number.isNaN(value)) {
                setYear(Number(value));
                if (month && date) {
                    setNewBirthDate(new Date(value, month, date));
                }
            }
        },
        [month, date],
    );

    const handleMonthChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement | { value: unknown }>) => {
            const value = Number(e.target.value);
            if (!Number.isNaN(value)) {
                setMonth(Number(value));
                if (year && date) {
                    setNewBirthDate(new Date(year, value, date));
                }
            }
        },
        [year, date],
    );

    const handleDateChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement | { value: unknown }>) => {
            const value = Number(e.target.value);
            if (!Number.isNaN(value)) {
                setDate(Number(value));
                if (year && month) {
                    setNewBirthDate(new Date(year, month, value));
                }
            }
        },
        [year, month],
    );
    return (
        <div className={styles.accountContents}>
            <div className={styles.centerDiv}>
                <div className={styles.infoWrapper} style={{ display: props.edit ? "block" : "flex" }}>
                    {!props.edit && (
                        <ImageWrapper
                            edit={props.edit}
                            checked={checked}
                            fileInfo={fileInfo}
                            iconImageUrl={newStudent?.iconImageUrl}
                            courseLength={takenClassIds.length}
                            setFileInfo={setFileInfo}
                        />
                    )}
                    <div className={styles.profileWrapper}>
                        {props.edit && (
                            <>
                                <Button className={styles.backButton} onClick={handleBackButtonClick}>
                                    <div className={styles.backDiv}>
                                        <TiArrowBack className={styles.backIcon} />
                                        <span className={styles.text}>戻る</span>
                                    </div>
                                </Button>
                                <div className={styles.editTitle}>プロフィール編集</div>
                            </>
                        )}
                        <ul
                            className={styles.profileList}
                            style={{
                                border: props.edit ? "1px solid #CCC" : "none",
                                boxShadow: props.edit ? "rgba(0, 0, 0, 0.1) 0px 4px 12px" : "none",
                            }}
                        >
                            <NickName
                                handleChange={handleStudentChange}
                                checked={checked}
                                edit={props.edit}
                                value={newStudent?.nickname as string}
                            />
                            <BirthYear
                                checked={checked}
                                edit={props.edit}
                                value={newUser?.birthDate?.toString() as string}
                                year={year}
                                month={month}
                                date={date}
                                isPublic={newUser?.isBirthDatePublic as boolean}
                                handleChange={handleUserChange}
                                handleYearChange={handleYearChange}
                                handleMonthChange={handleMonthChange}
                                handleDateChange={handleDateChange}
                            />
                            <Gender
                                handleChange={handleUserChange}
                                checked={checked}
                                edit={props.edit}
                                isPublic={newUser?.isGenderPublic as boolean}
                                value={newUser?.gender as string}
                            />
                            <Grade
                                handleChange={handleUserChange}
                                checked={checked}
                                edit={props.edit}
                                isPublic={newUser?.isGradePublic as boolean}
                                value={newUser?.grade as string}
                            />
                            <Prefecture
                                handleChange={handleUserChange}
                                checked={checked}
                                edit={props.edit}
                                isPublic={newUser?.isPrefecturePublic as boolean}
                                value={newUser?.prefecture as string}
                            />
                            {props.edit && (
                                <ImageWrapper
                                    edit={props.edit}
                                    checked={checked}
                                    fileInfo={fileInfo}
                                    iconImageUrl={newStudent?.iconImageUrl}
                                    setFileInfo={setFileInfo}
                                    courseLength={takenClassIds.length}
                                />
                            )}
                            <FavoriteSubjects
                                favoriteSubjects={favoriteSubjects}
                                setFavoriteSubjects={setFavoriteSubjects}
                                checked={checked}
                                edit={props.edit}
                            />
                        </ul>
                    </div>
                </div>
                <Button
                    className={styles.editButton}
                    onClick={editFunc}
                    style={{
                        paddingRight: props.edit ? "20px" : "",
                        paddingLeft: props.edit ? "20px" : "",
                    }}
                >
                    {props.edit ? (
                        isProcessing ? (
                            <CircularProgress className={styles.circle} size={24} />
                        ) : (
                            "確定"
                        )
                    ) : (
                        <div className={styles.editDiv}>
                            <EditIcon className={styles.editIcon} />
                            プロフィールを編集する
                        </div>
                    )}
                </Button>
            </div>
        </div>
    );
});
