import { memo, useCallback, useEffect } from "react";
import { useState } from "react";

import queryString from "query-string";
import { useHistory } from "react-router";

import {
    HIGH_SCHOOL_SUBJECT_CONFIG,
    JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG,
    ELEMENTARY_SCHOOL_SUBJECT_CONFIG,
    SubjectConfig,
    UNIVERSITY_SUBJECT_CONFIG,
} from "@/SubjectConfig";
import { TeacherSearchResultComponent } from "@/pageComponents/Common/TeacherSearchResults";
import { useGetTeacherSearchResultsQuery } from "@/store/hooks/teachers";
import { QueryLoadingWrapperOnlyPart } from "@/components/QueryLoadingWrapper/QueryLoadingWrapperPart";
import { OrderBy } from "@/store/autogenApi";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { Navigation } from "@/components/Navigation";

export interface Props {
    query: queryString.ParsedQuery<string>;
}

export interface SubjectQueries {
    level: string;
    category: string;
    subCategory: string;
}

export interface EducationalBackGroundQueries {
    university: string;
    faculty: string;
    department: string;
}

const NAVIGATION_LIST: Navigation[] = [{ title: "先生検索", url: "/SearchResults?searchType=teacher" }];

export const TeacherSearchResults: React.VFC<Props> = memo(function TeacherSearchResults(props) {
    const [keyword, setKeyword] = useState<string>("");
    const [targetSubject, setTargetSubject] = useState<SubjectQueries>({
        level: "",
        category: "",
        subCategory: "",
    });
    const [rankNumber, setRank] = useState<number>(0);
    const [currentEducationalBackground, setCurrentEducationalBackground] = useState<EducationalBackGroundQueries>({
        university: "",
        faculty: "",
        department: "",
    });
    const [targetEducationalBackground, setTargetEducationalBackground] = useState<EducationalBackGroundQueries>({
        university: "",
        faculty: "",
        department: "",
    });
    const [orderBy, setOrderBy] = useState<OrderBy>("latest");
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [existCondition, setExistCondition] = useState<boolean>(false);

    const history = useHistory();

    const teacherSearchResultQueryState = useGetTeacherSearchResultsQuery({
        keyword: Boolean(props.query.keyword) ? (props.query.keyword as string) : undefined,
        level: Boolean(props.query.level) ? (props.query.level as string) : undefined,
        category: Boolean(props.query.category) ? (props.query.category as string) : undefined,
        subCategory: Boolean(props.query.subCategory) ? (props.query.subCategory as string) : undefined,
        rankNumber: Boolean(props.query.rankNumber) ? (props.query.rankNumber as string) : undefined,
        university: Boolean(props.query.university) ? (props.query.university as string) : undefined,
        faculty: Boolean(props.query.faculty) ? (props.query.faculty as string) : undefined,
        department: Boolean(props.query.department) ? (props.query.department as string) : undefined,
        orderBy: Boolean(props.query.orderBy) ? (props.query.orderBy as OrderBy) : undefined,
    });

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    useEffect(() => {
        setTargetEducationalBackground(currentEducationalBackground);
        if (currentEducationalBackground.university) {
            setIsConfirmed(true);
        } else {
            setIsConfirmed(false);
        }
    }, [currentEducationalBackground]);

    useEffect(() => {
        (async () => {
            setKeyword(props.query.keyword as string);
            setTargetSubject({
                level: props.query.level as string,
                category: props.query.category as string,
                subCategory: props.query.subCategory as string,
            });
            setRank(Number(props.query.rankNumber));
            setCurrentEducationalBackground({
                university: props.query.university as string,
                faculty: props.query.faculty as string,
                department: props.query.department as string,
            });
            const orderBy =
                !props.query.orderBy || props.query.orderBy === "" ? "latest" : (props.query.orderBy as OrderBy);
            setOrderBy(orderBy);
            if (props.query.pageNumber) {
                setPageNumber(Number(props.query.pageNumber));
            }
            if (
                Boolean(props.query.keyword) ||
                Boolean(props.query.level) ||
                Boolean(props.query.category) ||
                Boolean(props.query.subCategory) ||
                Boolean(props.query.rankNumber) ||
                Boolean(props.query.university) ||
                Boolean(props.query.faculty) ||
                Boolean(props.query.department)
            ) {
                setExistCondition(true);
            } else {
                setExistCondition(false);
            }
        })();
    }, [history.location.search]);

    const getSubjectCondition = useCallback(() => {
        let targetSubjectConfig: SubjectConfig;
        let level: string;
        if (props.query.level === "university") {
            targetSubjectConfig = UNIVERSITY_SUBJECT_CONFIG;
            level = "大学生・社会人";
        } else if (props.query.level === "highSchool") {
            targetSubjectConfig = HIGH_SCHOOL_SUBJECT_CONFIG;
            level = "高校生";
        } else if (props.query.level === "juniorHighSchool") {
            targetSubjectConfig = JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG;
            level = "中学生";
        } else if (props.query.level === "elementarySchool") {
            targetSubjectConfig = ELEMENTARY_SCHOOL_SUBJECT_CONFIG;
            level = "小学生";
        } else {
            return "";
        }
        if (!props.query.category || typeof props.query.category !== "string") return level;
        const category = targetSubjectConfig[props.query.category].label;
        const subCategories = targetSubjectConfig[props.query.category].sub;
        if (subCategories && props.query.subCategory && typeof props.query.subCategory === "string") {
            const subCategory = subCategories[props.query.subCategory].label;
            return `${level}-${category}-${subCategory}`;
        } else {
            return `${level}-${category}`;
        }
    }, [props.query.level, props.query.category, props.query.subCategory]);

    const getBelongingCondition = useCallback(() => {
        const university = currentEducationalBackground.university;
        const faculty = currentEducationalBackground.faculty;
        const department = currentEducationalBackground.department;
        let condition: string;
        if (faculty) {
            if (department) {
                return `${university} - ${faculty} - ${department}`;
            } else {
                return `${university} - ${faculty}`;
            }
        } else {
            return `${university}`;
        }
        return "";
    }, [
        currentEducationalBackground.university,
        currentEducationalBackground.faculty,
        currentEducationalBackground.department,
    ]);

    const getRankCondition = useCallback((rankNumber: number) => {
        if (rankNumber === 1) {
            return "レギュラー以上";
        } else if (rankNumber === 2) {
            return "ブロンズ以上";
        } else if (rankNumber === 3) {
            return "シルバー以上";
        } else if (rankNumber === 4) {
            return "ゴールド以上";
        } else if (rankNumber === 5) {
            return "プラチナ";
        } else {
            return "-";
        }
    }, []);

    const search = useCallback(() => {
        const keywordQueryParams = keyword ? `&keyword=${keyword}` : "";
        const levelQueryParams = targetSubject.level ? `&level=${targetSubject.level}` : "";
        const categoryQueryParams = targetSubject.category ? `&category=${targetSubject.category}` : "";
        const subCategoryQueryParams = targetSubject.subCategory ? `&subCategory=${targetSubject.subCategory}` : "";
        const rankNumberQueryParams = rankNumber ? `&rankNumber=${rankNumber}` : "";
        const universityQueryParams = targetEducationalBackground.university
            ? `&university=${targetEducationalBackground.university}`
            : "";
        const facultyQueryParams = targetEducationalBackground.faculty
            ? `&faculty=${targetEducationalBackground.faculty}`
            : "";
        const departmentQueryParams = targetEducationalBackground.department
            ? `&department=${targetEducationalBackground.department}`
            : "";
        const orderByQueryParams = orderBy ? `&orderBy=${orderBy}` : "";
        const queryParams = [
            keywordQueryParams,
            levelQueryParams,
            categoryQueryParams,
            subCategoryQueryParams,
            rankNumberQueryParams,
            universityQueryParams,
            facultyQueryParams,
            departmentQueryParams,
            orderByQueryParams,
        ].join("");
        history.push(`/SearchResults?searchType=teacher${queryParams}`);
    }, [keyword, targetSubject, rankNumber, targetEducationalBackground]);

    const deleteKeywordCondition = useCallback(() => {
        const levelQueryParams = props.query.level ? `&level=${props.query.level}` : "";
        const categoryQueryParams = props.query.category ? `&category=${props.query.category}` : "";
        const subCategoryQueryParams = props.query.subCategory ? `&subCategory=${props.query.subCategory}` : "";
        const rankNumberQueryParams = props.query.rankNumber ? `&rankNumber=${props.query.rankNumber}` : "";
        const universityQueryParams = props.query.university ? `&university=${props.query.university}` : "";
        const facultyQueryParams = props.query.faculty ? `&faculty=${props.query.faculty}` : "";
        const departmentQueryParams = props.query.department ? `&department=${props.query.department}` : "";
        const orderByQueryParams = props.query.orderBy ? `&orderBy=${props.query.orderBy}` : "";
        const queryParams = [
            levelQueryParams,
            categoryQueryParams,
            subCategoryQueryParams,
            rankNumberQueryParams,
            universityQueryParams,
            facultyQueryParams,
            departmentQueryParams,
            orderByQueryParams,
        ].join("");
        history.push(`/SearchResults?searchType=teacher${queryParams}`);
    }, [props.query]);

    const deleteSubjectCondition = useCallback(() => {
        const keywordQueryParams = props.query.keyword ? `&keyword=${props.query.keyword}` : "";
        const rankNumberQueryParams = props.query.rankNumber ? `&rankNumber=${props.query.rankNumber}` : "";
        const universityQueryParams = props.query.university ? `&university=${props.query.university}` : "";
        const facultyQueryParams = props.query.faculty ? `&faculty=${props.query.faculty}` : "";
        const departmentQueryParams = props.query.department ? `&department=${props.query.department}` : "";
        const orderByQueryParams = props.query.orderBy ? `&orderBy=${props.query.orderBy}` : "";
        const queryParams = [
            keywordQueryParams,
            rankNumberQueryParams,
            universityQueryParams,
            facultyQueryParams,
            departmentQueryParams,
            orderByQueryParams,
        ].join("");
        history.push(`/SearchResults?searchType=teacher${queryParams}`);
    }, [props.query]);

    const deleteRankCondition = useCallback(() => {
        const keywordQueryParams = props.query.keyword ? `&keyword=${props.query.keyword}` : "";
        const levelQueryParams = props.query.level ? `&level=${props.query.level}` : "";
        const categoryQueryParams = props.query.category ? `&category=${props.query.category}` : "";
        const subCategoryQueryParams = props.query.subCategory ? `&subCategory=${props.query.subCategory}` : "";
        const universityQueryParams = props.query.university ? `&university=${props.query.university}` : "";
        const facultyQueryParams = props.query.faculty ? `&faculty=${props.query.faculty}` : "";
        const departmentQueryParams = props.query.department ? `&department=${props.query.department}` : "";
        const orderByQueryParams = props.query.orderBy ? `&orderBy=${props.query.orderBy}` : "";
        const queryParams = [
            keywordQueryParams,
            levelQueryParams,
            categoryQueryParams,
            subCategoryQueryParams,
            universityQueryParams,
            facultyQueryParams,
            departmentQueryParams,
            orderByQueryParams,
        ].join("");
        history.push(`/SearchResults?searchType=teacher${queryParams}`);
    }, [props.query]);

    const deleteBelongingCondition = useCallback(() => {
        const keywordQueryParams = props.query.keyword ? `&keyword=${props.query.keyword}` : "";
        const levelQueryParams = props.query.level ? `&level=${props.query.level}` : "";
        const categoryQueryParams = props.query.category ? `&category=${props.query.category}` : "";
        const subCategoryQueryParams = props.query.subCategory ? `&subCategory=${props.query.subCategory}` : "";
        const rankNumberQueryParams = props.query.rankNumber ? `&rankNumber=${props.query.rankNumber}` : "";
        const orderByQueryParams = props.query.orderBy ? `&orderBy=${props.query.orderBy}` : "";
        const queryParams = [
            keywordQueryParams,
            levelQueryParams,
            categoryQueryParams,
            subCategoryQueryParams,
            rankNumberQueryParams,
            orderByQueryParams,
        ].join("");
        history.push(`/SearchResults?searchType=teacher${queryParams}`);
    }, [props.query]);

    const handleClearButtonClick = useCallback(() => {
        history.push(`/SearchResults?searchType=teacher&orderBy=${props.query.orderBy}`);
    }, [props.query]);

    const handleOrderByChange = useCallback(
        (
            e: React.ChangeEvent<{
                value: string;
            }>,
        ) => {
            const newOrder = e.target.value;
            history.push(
                `/SearchResults?searchType=teacher&keyword=${keyword}&level=${targetSubject.level}&category=${targetSubject.category}&subCategory=${targetSubject.subCategory}&rankNumber=${rankNumber}&university=${targetEducationalBackground.university}&faculty=${targetEducationalBackground.faculty}&department=${targetEducationalBackground.department}&orderBy=${newOrder}`,
            );
        },
        [],
    );

    return (
        <>
            <QueryLoadingWrapperOnlyPart {...teacherSearchResultQueryState}>
                {(teachers, isLoaded) => (
                    <TeacherSearchResultComponent
                        targetSubject={targetSubject}
                        setTargetSubject={setTargetSubject}
                        rankNumber={rankNumber}
                        query={props.query}
                        keyword={keyword}
                        pageNumber={pageNumber}
                        orderBy={orderBy}
                        existCondition={existCondition}
                        setPageNumber={setPageNumber}
                        setKeyword={setKeyword}
                        setRank={setRank}
                        targetEducationalBackground={targetEducationalBackground}
                        setTargetEducationalBackground={setTargetEducationalBackground}
                        search={search}
                        getSubjectCondition={getSubjectCondition}
                        getRankCondition={getRankCondition}
                        getBelongingCondition={getBelongingCondition}
                        deleteSubjectCondition={deleteSubjectCondition}
                        deleteKeywordCondition={deleteKeywordCondition}
                        deleteRankCondition={deleteRankCondition}
                        deleteBelongingCondition={deleteBelongingCondition}
                        handleClearButtonClick={handleClearButtonClick}
                        handleOrderByChange={handleOrderByChange}
                        searchResults={teachers ?? []}
                        isLoaded={isLoaded}
                        isConfirmed={isConfirmed}
                        setIsConfirmed={setIsConfirmed}
                    />
                )}
            </QueryLoadingWrapperOnlyPart>
        </>
    );
});
