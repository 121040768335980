import { Button, Hidden, useMediaQuery } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";

import { UserType } from "@/protectedRouter/ProtectedRouterConfig";
import { ForNonUser } from "../ForNonUser";
import { ForStudent } from "../ForStudent";
import { ForTeacher } from "../ForTeacher";
import styles from "../index.module.scss";
import logo from "@/images/logo-v2-with-trail.jpg";
import { SearchTypes } from "../SearchTypes";
import { LOCATIONS_WITH_ONLY_LOGO } from "@/constants";

interface Props {
    userMode: UserType;
    currentBodyEl: HTMLElement | null;
}

const LP_LOCATIONS = ["/AboutTrail", "/AboutTeacher"];

export const HeaderContents: React.VFC<Props> = (props) => {
    const location = useLocation();
    const xsUp = useMediaQuery("(min-width:600px)");

    return (
        <>
            {/* スマホ版 */}
            <Hidden mdUp>
                <div className={styles.mobileWrapper}>
                    {LOCATIONS_WITH_ONLY_LOGO.includes(location.pathname) ? (
                        <div className={styles.onlyLogoHeaderWrapper}>
                            <Link to="/" className={styles.onlyLogoLink}>
                                <img src={logo} alt="" className={styles.logo} />
                            </Link>
                            {LP_LOCATIONS.includes(location.pathname) && (
                                <Button
                                    className={styles.signUpButton}
                                    variant="contained"
                                    component={Link}
                                    to="/SignUp"
                                >
                                    新規登録
                                </Button>
                            )}
                        </div>
                    ) : (
                        <>
                            <div className={styles.topWrapper}>
                                {props.userMode === "teacher" ? (
                                    <Link to="/" className={styles.spLink}>
                                        <img src={logo} alt="" className={styles.logo} />
                                    </Link>
                                ) : (
                                    <Link to="/" className={styles.spLink}>
                                        <img src={logo} alt="" className={styles.logo} />
                                    </Link>
                                )}
                                {xsUp && <SearchTypes />}
                                {props.userMode === "anonymous" && <ForNonUser />}
                                {props.userMode === "student" && <ForStudent />}
                                {props.userMode === "teacher" && <ForTeacher />}
                            </div>
                            {!xsUp && (
                                <div className={styles.bottomWrapper}>
                                    {/* <SearchBox props.userMode={props.userMode} /> */}
                                    <SearchTypes />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </Hidden>
            {/* PC版 */}
            <Hidden smDown>
                <div className={styles.desktopWrapper}>
                    {LOCATIONS_WITH_ONLY_LOGO.includes(location.pathname) ? (
                        <div className={styles.onlyLogoHeaderWrapper}>
                            <Link to="/" className={styles.onlyLogoLink}>
                                <img src={logo} alt="" className={styles.logo} />
                            </Link>
                            {LP_LOCATIONS.includes(location.pathname) && (
                                <Button
                                    className={styles.signUpButton}
                                    variant="contained"
                                    component={Link}
                                    to="/SignUp"
                                >
                                    新規登録
                                </Button>
                            )}
                        </div>
                    ) : (
                        <>
                            {props.userMode === "teacher" ? (
                                <Link to="/" className={styles.pcLink}>
                                    <img src={logo} alt="" className={styles.logo} />
                                </Link>
                            ) : (
                                <Link to="/" className={styles.pcLink}>
                                    <img src={logo} alt="" className={styles.logo} />
                                </Link>
                            )}
                            <SearchTypes />
                            {/* <SearchBox props.userMode={props.userMode} /> */}
                            <div className={styles.grow} />
                            {props.userMode === "anonymous" && <ForNonUser />}
                            {props.userMode === "student" && <ForStudent />}
                            {props.userMode === "teacher" && <ForTeacher />}
                        </>
                    )}
                </div>
            </Hidden>
        </>
    );
};
