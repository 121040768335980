import { AboutTeacherContents } from "@/pageComponents/Common/AboutTeacher";
import { useEffect } from "react";
import { useLocation } from "react-router";

export const AboutTeacher: React.VFC = () => {
    const query = new URLSearchParams(useLocation().search);
    const queryViewMode = query.get("from");

    useEffect(() => {
        // 広告からの流入判定
        if (queryViewMode !== "teacherPromo") {
            return;
        }
        //ローカルストレージにfromをセット
        localStorage.setItem("from", "teacherPromo");
    }, [queryViewMode]);

    return <AboutTeacherContents />;
};
