import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { memo, useCallback } from "react";
import { Mandatory } from "@/components/Tag/Mandatory";
import styles from "@/pages/Student/CreatePublicRequest/index.module.scss";
import { CourseType } from "@/store/autogenApi";

interface Props {
    checked: boolean;
    courseType: CourseType | undefined;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    open?: boolean;
    validation: boolean;
}

export const CourseTypeItem: React.VFC<Props> = memo(function CourseTypeItem(props) {
    const getCourseType = useCallback(() => {
        if (props.courseType === "short") {
            return "単発・短期講座";
        } else if (props.courseType === "regular") {
            return "定期講座";
        }
    }, [props.courseType]);

    return (
        <li className={styles.listItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>講座形態</div>
                    {!props.open && <Mandatory />}
                </div>
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>{getCourseType()}</div>
                ) : (
                    <>
                        <FormControl component="fieldset" className={styles.formControl}>
                            <RadioGroup
                                aria-label="profile"
                                name="courseType"
                                value={props.courseType}
                                onChange={props.handleChange}
                                className={styles.radioGroup}
                            >
                                <FormControlLabel
                                    value="short"
                                    control={<Radio className={styles.radio} />}
                                    label={<span className={styles.label}>単発・短期講座</span>}
                                    className={styles.formControlLabel}
                                />
                                <FormControlLabel
                                    value="regular"
                                    control={<Radio className={styles.radio} />}
                                    label={<span className={styles.label}>定期講座</span>}
                                    className={styles.formControlLabel}
                                />
                            </RadioGroup>
                        </FormControl>
                        <div className={styles.description}>
                            ※
                            単発・短期講座は日時・回数を指定して集中的に行う講座、定期講座は長い期間で継続的に行う講座(オンライン家庭教師)のことを指します。
                        </div>
                        {props.checked && !props.validation && (
                            <div className={styles.helperText}>選択してください。</div>
                        )}
                    </>
                )}
            </div>
        </li>
    );
});
