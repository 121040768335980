import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import styles from "./index.module.scss";
import { RootState } from "@/ducks";
import { useGetNewsRecordsQuery } from "@/store/hooks/newsRecords";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { MainContents } from "./MainContents";
import { NewsTargetType } from "@/store/autogenApi";
import { memo } from "react";

export const NewsList: React.VFC = memo(function NewsList() {
    const userMode = useSelector((state: RootState) => state.jwt.userMode);

    const newsRecordsQueryState = useGetNewsRecordsQuery(userMode as NewsTargetType);

    return (
        <>
            <div className={styles.newsListWrapper}>
                <QueryLoadingWrapper {...newsRecordsQueryState}>
                    {(newsRecords) => <MainContents newsRecords={newsRecords} />}
                </QueryLoadingWrapper>
                <Button component={Link} to="/" className={styles.toHomeButton}>
                    ホームに戻る
                </Button>
            </div>
        </>
    );
});
