import { FormHelperText } from "@material-ui/core";
import { memo } from "react";
import { Mandatory } from "@/components/Tag/Mandatory";
import styles from "@/pageComponents/Common/InfoInput/userInfoInput.module.scss";
import { StyledTextFieldForKana } from "@/pageComponents/Common/InfoInput/ProfileInput/Items/StyledTextFieldForKana";
import { StyledTextField } from "@/components/StyledTextField";

interface Props {
    open?: boolean;
    firstName?: string;
    lastName?: string;
    firstNameKana?: string;
    lastNameKana?: string;
    checked: boolean;
    handleFirstNameChange?: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleLastNameChange?: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleFirstNameKanaChange?: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleLastNameKanaChange?: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleIsComposingChange?: (value: boolean) => void;
}

export const RealName: React.VFC<Props> = memo(function RealName(props) {
    return (
        <div className={styles.inputItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitleWrapper}>
                    <span className={styles.itemTitle}>本名</span>
                </div>
                {!props.open && <Mandatory />}
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>
                        {props.lastName} {props.firstName}（{props.lastNameKana} {props.firstNameKana}）
                    </div>
                ) : (
                    <>
                        <div className={styles.notice}>
                            姓名を入力すると読み仮名が自動入力されます。（お使いのデバイスによっては動作しないことがあります。）必要に応じてご修正ください。
                        </div>
                        <div className={styles.importantNotice}>※本名は公開されません。</div>
                        <div className={styles.firstAndLastName}>
                            <StyledTextField
                                name="lastName"
                                placeholder="姓"
                                value={props.lastName ?? ""}
                                handleChange={props.handleLastNameChange}
                                handleIsComposingChange={props.handleIsComposingChange}
                            />
                            <StyledTextField
                                name="firstName"
                                placeholder="名"
                                value={props.firstName ?? ""}
                                handleChange={props.handleFirstNameChange}
                                handleIsComposingChange={props.handleIsComposingChange}
                            />
                        </div>
                        <div className={styles.firstAndLastNameKana}>
                            <StyledTextFieldForKana
                                name="lastNameKana"
                                placeholder="姓（かな）"
                                value={props.lastNameKana ?? ""}
                                handleChange={props.handleLastNameKanaChange}
                            />
                            <StyledTextFieldForKana
                                name="firstNameKana"
                                placeholder="名（かな）"
                                value={props.firstNameKana ?? ""}
                                handleChange={props.handleFirstNameKanaChange}
                            />
                        </div>

                        {props.checked && (
                            <>
                                {((props.firstName && props.firstName.length > 30) ||
                                    (props.lastName && props.lastName.length > 30)) && (
                                    <FormHelperText className={styles.helperText}>
                                        姓・名は各30文字以下で入力してください。
                                    </FormHelperText>
                                )}
                            </>
                        )}
                        {props.checked && (
                            <>
                                {(!props.firstName || !props.lastName) && (
                                    <FormHelperText className={styles.helperText}>入力してください。</FormHelperText>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
});
