import React from "react";
import { useGetPresignedUrlForGet } from "@/store/hooks/files";

interface Props {
    objectKey: string;
    className?: string;
    ref?: (node: HTMLImageElement | null) => void;
}

export const ImageFromObjectKey: React.VFC<Props> = ({ objectKey, className, ref }) => {
    const preSignedUrlQueryState = useGetPresignedUrlForGet(objectKey);

    return preSignedUrlQueryState.isSuccess && preSignedUrlQueryState.data !== undefined ? (
        <img src={preSignedUrlQueryState.data.preSignedUrl} className={className} ref={ref} alt="ロード中です" />
    ) : null;
};
