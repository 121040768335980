import { memo, useCallback, useEffect } from "react";

import { TextareaAutosize } from "@material-ui/core";

import styles from "@/pages/Teacher/InfoInput/index.module.scss";
import { None } from "../Errors/None";
import { Over } from "../Errors/Over";
import { Mandatory } from "@/components/Tag/Mandatory";

interface Props {
    handleChange?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    open?: boolean;
    value: string;
    checked: boolean;
    upperLimit?: number;
}

export const Background: React.VFC<Props> = memo(function Background(props) {
    const fixPlaceholder = useCallback(() => {
        const element: HTMLInputElement | null = document.getElementById("backgroundTextArea") as HTMLInputElement;
        if (element) {
            element.placeholder = element.placeholder.replace(/\\n/g, "\n");
        }
    }, []);

    useEffect(() => {
        fixPlaceholder();
    }, []);

    return (
        <div className={styles.inputItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitleWrapper}>
                    <span className={styles.itemTitle}>実績・バックグラウンド</span>
                </div>
                {!props.open && <Mandatory />}
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>{props.value}</div>
                ) : (
                    <>
                        <TextareaAutosize
                            required
                            placeholder={`実績・バックグラウンド(500字以内)\n例）トレ田イル道です！よろしくお願いします。\n〇〇高校を卒業後、〇〇大学工学部情報工学科に進学し、現在はネットワークセキュリティに関する卒業研究に取り組んでいます。\n...`}
                            className={styles.textArea}
                            name="backgroundDescription"
                            onChange={props.handleChange}
                            minRows={7}
                            id="backgroundTextArea"
                            value={props.value}
                        />
                        <div className={styles.wordCount}>
                            {props.value.length}/{props.upperLimit}文字
                        </div>
                        {props.checked && props.upperLimit && (
                            <Over value={props.value} upperLimit={props.upperLimit} />
                        )}
                        {props.checked && <None value={props.value} />}
                    </>
                )}
            </div>
        </div>
    );
});
