import { memo, useCallback, useEffect, useState } from "react";

import { Button, MenuItem, Select } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";

import styles from "../index.module.scss";

interface Props {
    rankNumber: number;
    setRank: React.Dispatch<React.SetStateAction<number>>;
    getRankCondition: (
        rankNumber: number,
    ) => "レギュラー以上" | "ブロンズ以上" | "シルバー以上" | "ゴールド以上" | "プラチナ" | "-" | undefined;
    checkNarrowDownHeight: () => void;
}

const theme = createTheme({
    overrides: {
        MuiList: {
            root: {
                maxHeight: "500px",
                overflowY: "scroll",
            },
            padding: {
                paddingTop: "0",
                paddingBottom: "0",
            },
        },
    },
});

export const RankConditions: React.VFC<Props> = memo(function RankConditions(props) {
    const [accordionOpen, setAccordionOpen] = useState<boolean>(false);

    useEffect(() => {
        const rankNumber = props.rankNumber;
        if (rankNumber > 0) {
            setAccordionOpen(true);
        } else {
            setAccordionOpen(false);
        }
    }, [props.rankNumber]);

    const handleChange = useCallback(
        (
            e: React.ChangeEvent<{
                value: unknown;
            }>,
        ) => {
            const value = e.target.value as number;
            props.setRank(value);
        },
        [props.setRank],
    );

    const handleAccordionClick = useCallback(() => {
        setAccordionOpen(!accordionOpen);
        props.checkNarrowDownHeight();
    }, [accordionOpen]);

    return (
        <div className={styles.conditionsWrapper} style={{ borderBottom: "1px solid #305077" }}>
            <Button className={styles.accordionButton} onClick={handleAccordionClick}>
                <div className={styles.narrowDownTitle}>先生のランク</div>
                <div className={styles.flex} />
                {accordionOpen ? (
                    <RiArrowUpSLine className={styles.arrow} />
                ) : (
                    <RiArrowDownSLine className={styles.arrow} />
                )}
            </Button>
            {accordionOpen && (
                <div className={styles.conditions}>
                    <div className={styles.condition}>
                        {/* <div className={styles.conditionTitle}>ランク</div> */}
                        <ThemeProvider theme={theme}>
                            <Select
                                className={styles.select}
                                disableUnderline
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "center",
                                        horizontal: "right",
                                    },
                                    transformOrigin: {
                                        vertical: "center",
                                        horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                }}
                                value={props.rankNumber}
                                onChange={handleChange}
                            >
                                <MenuItem key={0} value={0} className={styles.selectItem}></MenuItem>
                                {[...Array(5)].map((_, i) => {
                                    return (
                                        <MenuItem key={i + 1} value={i + 1} className={styles.selectItem}>
                                            {props.getRankCondition(i + 1)}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </ThemeProvider>
                    </div>
                </div>
            )}
        </div>
    );
});
