import bronzeBadge from "../images/B.png";
import goldBadge from "../images/G.png";
import platinumBadge from "../images/P.png";
import regularBadge from "../images/R.png";
import silverBadge from "../images/S.png";

export const getRankBadgeSrc = (rankNumber: number) => {
    if (rankNumber === 1) {
        return regularBadge;
    } else if (rankNumber === 2) {
        return bronzeBadge;
    } else if (rankNumber === 3) {
        return silverBadge;
    } else if (rankNumber === 4) {
        return goldBadge;
    } else if (rankNumber === 5) {
        return platinumBadge;
    } else {
        return "";
    }
};

export const getRankStr = (rankNumber: number) => {
    switch (rankNumber) {
        case 1:
            return "レギュラー";
        case 2:
            return "ブロンズ";
        case 3:
            return "シルバー";
        case 4:
            return "ゴールド";
        case 5:
            return "プラチナ";
        default:
            return "なし";
    }
};
