import { QuerySubState } from "@reduxjs/toolkit/dist/query/core/apiState";
import { QueryDefinition, ResultTypeFrom } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { Id, Override } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { FetchBaseQueryError, QueryStatus } from "@reduxjs/toolkit/query";
import { BaseQueryFn } from "@reduxjs/toolkit/src/query/baseQueryTypes";

import { getRtkQueryErrorMessage } from "./mutationFunction";
import { ErrorMessages } from "./types";

export type QueryState<T> =
    // リクエストに成功したけどリフェッチが走っている状態
    | { isSuccess: true; isFetching: true; data: T; refetch: () => void }
    // リクエストに成功した状態
    | { isSuccess: true; isFetching: false; data: T; refetch: () => void }
    // 初回リクエスト等、データは保持していないけどフェッチ中の状態
    | { isSuccess: false; isFetching: true; data: undefined; refetch: () => void }
    // 失敗した状態
    | { isSuccess: false; isFetching: false; data: undefined; refetch: () => void };

/**
 * 自動生成されたApiクライアントの返り値を内部モデルにして返却するカスタムフック。
 */
export const useQueryResult = <
    Response,
    Model,
>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    result: UseQueryStateDefaultResult<QueryDefinition<any, BaseQueryFn<any, any, FetchBaseQueryError, any, any>, string, Response>>,
    fromResponse: (response: Response) => Model,
    errorMessages?: ErrorMessages,
): QueryState<Model> => {
    const { isSuccess, isFetching, isError, data, error, refetch } = result;
    if (isError) {
        // eslint-disable-next-line @typescript-eslint/no-console
        console.error(
            errorMessages?.title || "予期せぬエラーが発生しました。",
            error ? JSON.stringify(getRtkQueryErrorMessage(error)) : undefined,
        );
        return { isFetching: false, isSuccess: false, data: undefined, refetch };
    }
    if (isSuccess) {
        return { isFetching, isSuccess, data: fromResponse(data), refetch };
    } else {
        return { isFetching, isSuccess, data: undefined, refetch };
    }
};

/**
 * redux-toolkitの型定義だけどexportされていないのでコピーしている。
 * ほしいのはReturnType<UseQuery>だが、型定義が複雑過ぎて、きれいに取得できない(握りつぶされてanyになっちゃう)のでまるっとコピー。
 * そのうちexportしてくれるっぽい雰囲気(https://github.com/reduxjs/redux-toolkit/pull/2276)はあるので逐次確認してこのコピーを消す。
 */
type UseQueryStateDefaultResult<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    D extends QueryDefinition<any, BaseQueryFn, string, any>,
> = Id<
    | Override<Extract<UseQueryStateBaseResult<D>, { status: QueryStatus.uninitialized }>, { isUninitialized: true }>
    | Override<
          UseQueryStateBaseResult<D>,
          | { isLoading: true; isFetching: boolean; data: undefined }
          | ({
                isSuccess: true;
                isFetching: true;
                error: undefined;
            } & Required<Pick<UseQueryStateBaseResult<D>, "data" | "fulfilledTimeStamp">>)
          | ({
                isSuccess: true;
                isFetching: false;
                error: undefined;
            } & Required<Pick<UseQueryStateBaseResult<D>, "data" | "fulfilledTimeStamp" | "currentData">>)
          | ({ isError: true } & Required<Pick<UseQueryStateBaseResult<D>, "error">>)
      >
> & {
    /**
     * @deprecated will be removed in the next version
     * please use the `isLoading`, `isFetching`, `isSuccess`, `isError`
     * and `isUninitialized` flags instead
     */
    status: QueryStatus;
};

/**
 * {@link UseQueryStateDefaultResult}と同じくRTK-Query内で利用されている型
 */
type UseQueryStateBaseResult<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    D extends QueryDefinition<any, BaseQueryFn, string, any>,
> = QuerySubState<D> & {
    /**
     * Where `data` tries to hold data as much as possible, also re-using
     * data from the last arguments passed into the hook, this property
     * will always contain the received data from the query, for the current query arguments.
     */
    currentData?: ResultTypeFrom<D>;
    /**
     * Query has not started yet.
     */
    isUninitialized: false;
    /**
     * Query is currently loading for the first time. No data yet.
     */
    isLoading: false;
    /**
     * Query is currently fetching, but might have data from an earlier publicRequest.
     */
    isFetching: false;
    /**
     * Query has data from a successful load.
     */
    isSuccess: false;
    /**
     * Query is currently in "error" state.
     */
    isError: false;

    refetch: () => void;
};
