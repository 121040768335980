import { Link } from "react-router-dom";
import { CourseResponse } from "@/store/autogenApi";
import { ListItem } from "./ListItem";

interface Props {
    course: CourseResponse | undefined;
}

export const TargetCourse: React.VFC<Props> = (props) => {
    return (
        <ListItem title="対象の講座" borderBottom>
            <Link to={`/CourseDetails/${props.course?.courseId}`}>{props.course?.title}</Link>
        </ListItem>
    );
};
