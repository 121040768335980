import { Button } from "@material-ui/core";
import { useHistory } from "react-router";
import styles from "@/pages/Common/CourseDetails/index.module.scss";
import { PublicRequestResponse } from "@/store/autogenApi";
import { memo } from "react";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    publicRequest: PublicRequestResponse;
}

export const BaseRequestComponent: React.VFC<Props> = memo(function BaseRequest(props) {
    const history = useHistory();
    const detail = () => {
        history.push(`/PublicRequestDetails/${props.publicRequest.publicRequestId}`);
    };
    return (
        <>
            <div className={styles.itemWrapper}>
                <div className={styles.itemHeadingWrapper}>
                    <span className={styles.itemHeading}>元の講座リクエスト</span>
                </div>
                <div className={styles.item} id="detail">
                    <Button className={styles.requestDetailsButton} onClick={detail}>
                        <div className={styles.requestTitle}>{props.publicRequest.title}</div>
                        <div className={styles.description}>{props.publicRequest.publicRequestDescription}</div>
                        <div className={styles.studentInfoWrapper}>
                            <AvatarFromS3
                                className={styles.avatar}
                                url={props.publicRequest.student.iconImageUrl}
                                objectKey={props.publicRequest.student.iconImageObjectKey}
                            />
                            <div className={styles.studentNickname}>{props.publicRequest.student?.nickname} さん</div>
                        </div>
                    </Button>
                </div>
            </div>
        </>
    );
});
