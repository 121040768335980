import { Button } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import { memo } from "react";

import styles from "@/pages/Teacher/IdentificationForStripe/index.module.scss";

interface Props {
    addPhoto: (e: React.ChangeEvent<HTMLInputElement>) => void;
    deletePhoto: (buttonName: string) => void;
    url?: string;
    name: string;
    checked: boolean;
    mandatory: boolean;
}

export const PictureUpload: React.VFC<Props> = memo(function PictureUpload(props) {
    return (
        <div className={styles.pictureUpload}>
            {!props.url && (
                <Button className={styles.selectButton}>
                    <label className={styles.photoLabel}>
                        ファイルを選択
                        <input
                            name={props.name}
                            type="file"
                            accept="image/*"
                            onChange={props.addPhoto}
                            className={styles.photoInput}
                        ></input>
                    </label>
                </Button>
            )}
            {props.url && (
                <div className={styles.imageWrapper}>
                    <img src={props.url} className={styles.image}></img>
                    <button
                        className={styles.deleteButton}
                        type="button"
                        onClick={() => {
                            props.deletePhoto(props.name);
                        }}
                    >
                        ×
                    </button>
                </div>
            )}
            {props.mandatory && props.url == "" && props.checked && (
                <FormHelperText className={styles.helperText}>画像をアップロードしてください。</FormHelperText>
            )}
        </div>
    );
});
