import styles from "@/pages/Common/FAQDetails/index.module.scss";

export interface Content {
    title: string;
    list?: string[];
}

interface Props {
    toc: Content[];
}

export const TOC: React.VFC<Props> = (props) => {
    return (
        <div className={styles.tocWrapper}>
            <div className={styles.tocTitle}>目次</div>
            {props.toc.map((content) => (
                <div className={styles.content}>
                    <div className={styles.contentTitle}>● {content.title}</div>
                    <ul className={styles.contentList}>
                        {content.list?.map((item, idx) => (
                            <li className={styles.contentListItem} key={idx}>
                                ・{item}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};
