import { memo, useCallback, useState } from "react";

import styles from "./index.module.scss";

import queryString from "query-string";

interface Props {
    pageNumber: number;
    setPageNumber: React.Dispatch<React.SetStateAction<number>>;
    thresholdNumber: number;
    items: any[];
    itemsPerPage: number;
}

export const PageNumbers: React.VFC<Props> = memo(function PageNumbers(props) {
    const handlePageNumberButtonClick = useCallback(
        (targetNumber: number) => {
            window.history.pushState(null, "");
            props.setPageNumber(targetNumber);
            window.scrollTo(0, 0);
        },
        [props.setPageNumber],
    );

    const handlePrevButtonClick = useCallback(() => {
        props.setPageNumber(props.pageNumber - 1);
        window.scrollTo(0, 0);
    }, [props.setPageNumber, props.pageNumber]);

    const handleNextButtonClick = useCallback(() => {
        props.setPageNumber(props.pageNumber + 1);
        window.scrollTo(0, 0);
    }, [props.setPageNumber, props.pageNumber]);

    return (
        <div className={styles.pageNumbersWrapper}>
            {props.pageNumber > 1 && (
                <button className={styles.prevButton} onClick={handlePrevButtonClick}>
                    前へ
                </button>
            )}
            {props.pageNumber >= props.thresholdNumber && (
                <>
                    <button className={styles.pageNumberButton} onClick={() => handlePageNumberButtonClick(1)}>
                        1
                    </button>
                </>
            )}
            {props.pageNumber > props.thresholdNumber && <div className={styles.ellipsis}>...</div>}
            <div className={styles.pageNumbers}>
                {[...Array(Math.ceil(props.items.length / props.itemsPerPage))].map((_, idx1, self) => {
                    if (self.length === 1) return;
                    const displayPageNumbers =
                        props.pageNumber >= props.thresholdNumber
                            ? Math.ceil(props.items.length / props.itemsPerPage) <
                              props.pageNumber + props.thresholdNumber
                                ? [...Array(props.thresholdNumber)].map(
                                      (_, idx2) => props.pageNumber - props.thresholdNumber / 2 + idx2,
                                  )
                                : [...Array(props.thresholdNumber)].map(
                                      (_, idx3) => idx3 + props.pageNumber - props.thresholdNumber / 2,
                                  )
                            : [...Array(props.thresholdNumber)].map((_, idx4) => idx4 + 1);
                    if (!displayPageNumbers.includes(idx1 + 1)) return;
                    return (
                        <button
                            className={
                                idx1 + 1 === props.pageNumber
                                    ? `${styles.pageNumberButton} ${styles.active}`
                                    : styles.pageNumberButton
                            }
                            onClick={() => handlePageNumberButtonClick(idx1 + 1)}
                        >
                            {idx1 + 1}
                        </button>
                    );
                })}
            </div>
            {props.pageNumber < Math.ceil(props.items.length / props.itemsPerPage) - props.thresholdNumber / 2 && (
                <div className={styles.ellipsis}>...</div>
            )}
            {Math.ceil(props.items.length / props.itemsPerPage) >= props.thresholdNumber &&
                props.pageNumber <= Math.ceil(props.items.length / props.itemsPerPage) - props.thresholdNumber / 2 && (
                    <>
                        <button
                            className={styles.pageNumberButton}
                            onClick={() =>
                                handlePageNumberButtonClick(Math.ceil(props.items.length / props.itemsPerPage))
                            }
                        >
                            {Math.ceil(props.items.length / props.itemsPerPage)}
                        </button>
                    </>
                )}
            {props.pageNumber * props.itemsPerPage < props.items.length && (
                <button className={styles.nextButton} onClick={handleNextButtonClick}>
                    次へ
                </button>
            )}
        </div>
    );
});
