import { FAQDetailsBase } from "../../FAQDetailsBase";

import { FAVORITE_COURSE_CONFIGS } from "./FavoriteCourseConfigs";

export const FavoriteCourse: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="気になった講座を「気になる」登録する"
            text="このページでは、気になった講座を「気になる」登録する手順についてご説明します。"
            FAQConfigs={FAVORITE_COURSE_CONFIGS}
        />
    );
};
