import styles from "@/pages/Common/AboutStudent/index.module.scss";

// import alertImage from "./img/alert.png";
import bookingImage from "./img/booking.png";
import courseImage from "./img/course.png";
// import creditImage from "./img/credit.png";
// import graphImage from "./img/graph.png";
import courseTypeImage from "./img/courseType.jpg";
import linkImage from "./img/link.png";
import manImage from "./img/male.png";
import messageImage from "./img/message.png";
import moneyImage from "./img/money.png";
import onlineIconImage from "./img/onlineIconImage.png";
import replyImage from "./img/reply.png";
import { OverWrapper } from "@/components/OverWrapper";

interface Props {
    userMode: string;
}

export const Functions: React.VFC<Props> = (props) => {
    return (
        <>
            {/* 内容は後で作成する */}
            <OverWrapper style={{ borderBottom: "1px solid #305077" }}>
                <div className={styles.tabTitle}>機能</div>
            </OverWrapper>
            <div className={styles.functionListsWrapper}>
                <div className={styles.functionListWrapper}>
                    <div className={styles.functionsTitle}>先生と繋がる</div>
                    <div className={styles.functionsWrapper}>
                        <div className={styles.flexRow}>
                            <div className={styles.function}>
                                <img src={courseImage} alt="" className={styles.functionImage} />
                                <div className={styles.functionTitle}>既存の講座を予約</div>
                                <div className={styles.functionText}>
                                    先生が得意を生かして作成した講座を予約することができます。
                                </div>
                            </div>
                            <div className={styles.function}>
                                <img src={manImage} alt="" className={styles.functionImage} />
                                <div className={styles.functionTitle}>講座リクエストを作成</div>
                                <div className={styles.functionText}>
                                    あなたの希望に合わせた講座を先生にリクエストすることができます。
                                </div>
                            </div>
                            {/* <div className={styles.function}>
                                <img src={graphImage} alt="" className={styles.functionImage} />
                                <div className={styles.functionTitle}>アクセス解析</div>
                                <div className={styles.functionText}>
                                    公開した授業ページの訪問者のデータが見えるため、集客に役立てられます。
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className={styles.functionListWrapper}>
                    <div className={styles.functionsTitle}>受講する</div>
                    <div className={styles.functionsWrapper}>
                        <div className={styles.flexRow}>
                            <div className={styles.function}>
                                <img src={courseTypeImage} alt="" className={styles.functionImage} />
                                <div className={styles.functionTitle}>授業形態</div>
                                <div className={styles.functionText}>
                                    単発・短期授業、定期授業の2形態が存在し、目的に合わせて柔軟に受講ができます。
                                </div>
                            </div>
                            <div className={styles.function}>
                                <img src={moneyImage} alt="" className={styles.functionImage} />
                                <div className={styles.functionTitle}>決済代行</div>
                                <div className={styles.functionText}>
                                    授業の予約をTrailが受け付けします。Webで完結なので振込の必要もありません。
                                </div>
                            </div>
                        </div>
                        <div className={styles.flexRow}>
                            <div className={styles.function}>
                                <img src={messageImage} alt="" className={styles.functionImage} />
                                <div className={styles.functionTitle}>メッセージ</div>
                                <div className={styles.functionText}>
                                    先生とのやりとりはすべてTrail上で行うことができます。
                                </div>
                            </div>
                            <div className={styles.function}>
                                <img src={onlineIconImage} alt="" className={styles.functionImage} />
                                <div className={styles.functionTitle}>完全オンライン</div>
                                <div className={styles.functionText}>
                                    完全オンラインとなるため、自宅にいながら授業を行うことができます。
                                </div>
                            </div>
                            <div className={styles.function}>
                                <img src={linkImage} alt="" className={styles.functionImage} />
                                <div className={styles.functionTitle}>Zoom連携</div>
                                <div className={styles.functionText}>
                                    会議ツールZoom（ズーム）を利用した快適な授業が受けられます。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
