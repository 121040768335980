import {
    HIGH_SCHOOL_SUBJECT_CONFIG,
    JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG,
    ELEMENTARY_SCHOOL_SUBJECT_CONFIG,
    UNIVERSITY_SUBJECT_CONFIG,
} from "@/SubjectConfig";
import styles from "./index.module.scss";

import { SchoolCategory } from "./SchoolCategory";
import { memo } from "react";

interface Props {
    mode: "student" | "teacher";
}

export const SPSearchBySubject: React.VFC<Props> = memo(function SPSearchBySubject(props) {
    return (
        <div className={styles.searchBySubject}>
            <div className={styles.searchBySubjectTitle}>
                分野から{props.mode === "teacher" ? "講座リクエスト" : "講座"}検索
            </div>
            <div className={styles.schoolCategories}>
                <SchoolCategory
                    title="大学生・社会人"
                    level="university"
                    subjectConfig={UNIVERSITY_SUBJECT_CONFIG}
                    mode={props.mode}
                />
                <SchoolCategory
                    title="高校生"
                    level="highSchool"
                    subjectConfig={HIGH_SCHOOL_SUBJECT_CONFIG}
                    mode={props.mode}
                />
                <SchoolCategory
                    title="中学生"
                    level="juniorHighSchool"
                    subjectConfig={JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG}
                    mode={props.mode}
                />
                <SchoolCategory
                    title="小学生"
                    level="elementarySchool"
                    subjectConfig={ELEMENTARY_SCHOOL_SUBJECT_CONFIG}
                    mode={props.mode}
                />
            </div>
        </div>
    );
});
