import { defaultApi } from "../defaultApi"
import { useQueryResult } from "./utils/parseResult"

export const useGetPresignedUrlForGet = (objectKey: string) => {
  const result = defaultApi.endpoints.getPresignedUrlForGet.useQuery({
    objectKey
  })
  return useQueryResult(
    result,
    (res) => res
  )
}