import { useEffect } from "react";
import { Button } from "@material-ui/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FAQConfig } from "@/FAQConfig";
import styles from "./index.module.scss";
import { useNavigation } from "@/components/Navigation/NavigationContext";

export const FAQCategory: React.VFC = () => {
    const query = new URLSearchParams(useLocation().search);
    const type = query.get("type");

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        const targetCategories = FAQConfig.filter((categories) =>
            categories.contents?.some((category) => category.type === type),
        );
        const targetCategory = targetCategories[0].contents.filter((category) => category.type === type)[0];
        updateNavigationList([
            {
                title: `ヘルプ・よくある質問`,
                url: `/FAQ`,
            },
            {
                title: `${targetCategory.title}`,
                url: `/FAQCategory/${targetCategory.type}`,
            },
        ]);
    }, []);
    const history = useHistory();
    const handleBackButtonClick = () => {
        history.push("/FAQ");
    };
    return (
        <div className={styles.FAQCategoryWrapper}>
            <div className={styles.FAQCategoryContents}>
                {FAQConfig.filter((categories) => categories.contents.some((category) => category.type === type)).map(
                    (categories) => {
                        return categories.contents.map((category) => {
                            if (category.type === type) {
                                return (
                                    <div className={styles.targetCategory}>
                                        <div className={styles.targetCategoryTitle}>{category.title}</div>
                                        <ul className={styles.targetItems}>
                                            {category.contents.map((item, idx) => {
                                                return (
                                                    <li className={styles.targetItem} key={idx}>
                                                        <Link
                                                            to={`/FAQDetails?type=${item.type}`}
                                                            className={styles.link}
                                                        >
                                                            {item.title}
                                                        </Link>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        });
                    },
                )}
                <Button className={styles.backButton} onClick={handleBackButtonClick}>
                    戻る
                </Button>
            </div>
        </div>
    );
};
