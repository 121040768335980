import { AddressInfo, CourseResponse, GetCourseSearchResultsApiArg } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions } from "./utils/types";

export const useGetAddressInfoListQuery = () => {
    const result = defaultApi.endpoints.getAddressInfoList.useQuery();
    return useQueryResult(result, (response: AddressInfo[]) => response);
};
