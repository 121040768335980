import { PrivateRequestResponse } from "@/store/autogenApi";
import { ListItem } from "./ListItem";

import styles from "./index.module.scss";

interface Props {
    privateRequest: PrivateRequestResponse | undefined;
}

export const FirstDateDescription: React.VFC<Props> = (props) => {
    return (
        <ListItem title="講座開始希望日" borderBottom>
            <>{props.privateRequest?.firstDateDescription}</>
        </ListItem>
    );
};
