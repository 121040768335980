import { memo } from "react";
import { FormControl, FormControlLabel, Radio, RadioGroup, ThemeProvider, createTheme } from "@material-ui/core";
import styles from "./index.module.scss";

interface Props {
    isPublic: boolean;
    handleIsPublicChange: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
}

const theme = createTheme({
    overrides: {
        MuiRadio: {
            root: {
                padding: "0",
            },
        },
        MuiSvgIcon: {
            root: {
                fontSize: "24px !important",
                "@media (max-width: 600px)": {
                    fontSize: "20px !important",
                },
            },
        },
    },
});

export const IsPublicRadioButtons: React.VFC<Props> = memo(function IsPublicRadioButtons(props) {
    const { isPublic, handleIsPublicChange } = props;

    return (
        <ThemeProvider theme={theme}>
            <FormControl>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={handleIsPublicChange}
                    value={isPublic ? "public" : "private"}
                    className={styles.radioButtons}
                >
                    <FormControlLabel
                        value="public"
                        control={<Radio />}
                        label="公開"
                        className={styles.radioButtonLabel}
                    />
                    <FormControlLabel
                        value="private"
                        control={<Radio />}
                        label="非公開"
                        className={styles.radioButtonLabel}
                    />
                </RadioGroup>
            </FormControl>
        </ThemeProvider>
    );
});
