import { TextField } from "@material-ui/core";
import { memo } from "react";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Mandatory } from "@/components/Tag/Mandatory";

import styles from "@/pages/Teacher/IdentificationForStripe/index.module.scss";

interface Props {
    checked: boolean;
    town: string;
    handleTownChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const Town: React.VFC<Props> = memo(function City(props) {
    return (
        <div className={styles.formItem} style={{ borderBottom: "1px solid #CCC" }}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitle}>町域</div>
                <Mandatory />
            </div>
            <div className={styles.rightWrapper}>
                <TextField
                    variant="outlined"
                    placeholder="〇〇 〇〇丁目"
                    className={styles.textField}
                    value={props.town}
                    onChange={props.handleTownChange}
                />
                <ErrorMessage content="町域を入力してください。" when={props.checked && props.town.length === 0} />
            </div>
        </div>
    );
});
