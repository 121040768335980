import MenuItem from "@material-ui/core/MenuItem";
import { memo } from "react";
import { Mandatory } from "@/components/Tag/Mandatory";

import styles from "../../userInfoInput.module.scss";
import { None } from "../Errors/None";
import { IsPublicRadioButtons } from "@/components/IsPublicRadioButtons";
import { DefaultSelect } from "@/components/DefaultSelect";
import { genderSet } from "@/utils/GenderSet";

interface Props {
    handleChange?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    open?: boolean;
    value?: string;
    checked: boolean;
    isPublic: boolean;
    handleIsPublicChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Gender: React.VFC<Props> = memo(function Gender(props) {
    const getJapaneseGender = () => {
        if (props.value === "male") {
            return "男性";
        } else if (props.value === "female") {
            return "女性";
        } else if (props.value === "other") {
            return "その他";
        } else if (props.value === "noAnswer") {
            return "回答しない";
        }
    };
    return (
        <div className={styles.inputItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitleWrapper}>
                    <span className={styles.itemTitle}>性別</span>
                </div>
                {!props.open && <Mandatory />}
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>{`${getJapaneseGender()} - ${
                        props.isPublic ? "公開" : "非公開"
                    }`}</div>
                ) : (
                    <>
                        {props.handleChange && (
                            <DefaultSelect
                                name="gender"
                                value={props.value ?? ""}
                                handleChange={props.handleChange}
                                options={["", "male", "female", "other", "noAnswer"].map((value) => ({
                                    value: value,
                                    label: value === "" ? "" : genderSet[value],
                                }))}
                            />
                        )}
                        {props.checked && <None value={props.value ?? ""} />}
                        <IsPublicRadioButtons
                            isPublic={props.isPublic}
                            handleIsPublicChange={props.handleIsPublicChange}
                        />
                    </>
                )}
            </div>
        </div>
    );
});
