import { WHAT_DAY_LIST } from "./WhatDayList";

export const getFullDate = (value: Date | undefined) => {
    if (!value) return;
    const year = value.getFullYear();
    const month = value.getMonth() + 1;
    const date = value.getDate();
    const hour = ("00" + value.getHours()).slice(-2);
    const minute = ("00" + value.getMinutes()).slice(-2);
    const day = value.getDay();
    const dayOfWeek = WHAT_DAY_LIST[day];
    return `${year}年${month}月${date}日(${dayOfWeek}) ${hour}:${minute}`;
};
