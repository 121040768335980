import { FAQConfig } from "@/FAQConfig";

export const LOGIN_FAILED_CONFIGS: FAQConfig[] = [
    {
        title: "「ログイン」ではなく「新規登録」しようとしている",
        text: "画面上部に「ログイン」と表示されれいるかご確認ください。",
    },
    {
        title: "メールアドレスが正しくない",
        text: "登録したメールアドレスでログインしようとしている、スペル間違いが無いかご確認ください。",
    },
    {
        title: "パスワードが正しくない",
        text: "パスワードが正しいかご確認ください。大文字・小文字の間違いなどもご確認ください。",
    },
];
