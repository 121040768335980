import { memo, useEffect } from "react";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { NewsRecordResponse } from "@/store/autogenApi";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import styles from "./index.module.scss";
import "./index.css";

interface Props {
    newsRecord: NewsRecordResponse;
}

export const NewsContents: React.VFC<Props> = memo(function NewsContents(props) {
    const { updateNavigationList } = useNavigation();
    useEffect(() => {
        updateNavigationList([
            {
                title: "ニュース一覧",
                url: "/NewsList",
            },
            {
                title: props.newsRecord.title,
                url: `/News/${props.newsRecord.newsRecordId}}`,
            },
        ]);
    }, [props.newsRecord]);

    return (
        <div className={styles.newsContents}>
            <h1 className={styles.title}>{props.newsRecord.title}</h1>
            <div
                className={styles.newsBody}
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(marked(props.newsRecord.body)),
                }}
            />
        </div>
    );
});
