import { memo } from "react";
import styles from "./index.module.scss";
import { PublicTeacherResponse, PublicUserResponse } from "@/store/autogenApi";
import { BasicInfo } from "./BasicInfo";
import { Subjects } from "./Subjects";
import { Background } from "./Background";
import { ContentDescription } from "./ContentDescription";
import { AvatarFromS3 } from "../atoms/images/AvatarFromS3";

interface Props {
    teacher: PublicTeacherResponse;
    user: PublicUserResponse;
    disableToChangeLocation?: boolean;
    handleClose: () => void;
}

export const TeacherDetailInfo: React.VFC<Props> = memo(function UserInfoModal(props) {
    return (
        <div className={styles.topTabContentsWrapper}>
            <AvatarFromS3
                url={props.teacher.iconImageUrl}
                objectKey={props.teacher.iconImageObjectKey}
                className={styles.avatar}
            />
            <div className={styles.teacherNickname}>{props.teacher.nickname}先生</div>
            <BasicInfo teacher={props.teacher} user={props.user} />
            <Subjects
                subjects={props.teacher.teachableSubjects}
                disableToChangeLocation={props.disableToChangeLocation}
            />
            <Background background={props.teacher.backgroundDescription ? props.teacher.backgroundDescription : ""} />
            <ContentDescription
                contentDescription={props.teacher.contentDescription ? props.teacher.contentDescription : ""}
            />
        </div>
    );
});
