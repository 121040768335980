import React, { createContext, useContext, useState } from "react";
import { Navigation } from "./index";

interface NavigationContextProps {
    navigationList: Navigation[];
    updateNavigationList: (newNavigationList: Navigation[]) => void;
}

const NavigationContext = createContext<NavigationContextProps | undefined>(undefined);

export const useNavigation = () => {
    const context = useContext(NavigationContext);
    if (!context) {
        throw new Error("useNavigation must be used within a NavigationProvider");
    }
    return context;
};

export const NavigationProvider = ({ children }) => {
    const [navigationList, setNavigationList] = useState<Navigation[]>([]);

    const updateNavigationList = (newList) => {
        setNavigationList(newList);
    };

    return (
        <NavigationContext.Provider value={{ navigationList, updateNavigationList }}>
            {children}
        </NavigationContext.Provider>
    );
};
