import { Button, InputAdornment, TextField } from "@material-ui/core";
import { memo, useCallback, useState } from "react";
import { toast } from "react-toastify";
import { TeacherToDoResponse } from "@/store/autogenApi";
import { useApprovePrivateRequestMutation } from "@/store/hooks/privateRequests";
import { WHAT_DAY_LIST } from "@/utils/WhatDayList";
import { ErrorMessage } from "../../../../../ErrorMessage";
import { Processing } from "../../../../../Processing";
import { ToastContents } from "../../../../../Toast";

import styles from "../index.module.scss";
import { TargetCourse } from "../TargetCourse";
import { ListItem } from "../ListItem";
import { StudentInfo } from "../StudentInfo";
import { BaseSchedules } from "../BaseSchedules";
import { FirstDate } from "./FirstDate";
import { Estimate } from "./Estimate";
import { FirstDateDescription } from "../FirstDateDescription";
import { ApplicationMessage } from "../ApplicationMessage";
import { dateToString } from "@/utils/DateUtils";

interface Props {
    targetTeacherToDo: TeacherToDoResponse | undefined;
    maxHeight: number;
    handleBackButtonClick: () => void;
    handleClose: () => void;
}

export const ApproveModeContents: React.VFC<Props> = memo(function ApproveModalContents(props) {
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [isConfirmMode, setIsConfirmMode] = useState<boolean>(false);
    const [confirmedMonthlyFee, setConfirmedMonthlyFee] = useState<number | undefined>(undefined);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [confirmedFirstDate, setConfirmedFirstDate] = useState<Date | undefined>(undefined);

    const approvePrivateRequest = useApprovePrivateRequestMutation();

    const handleMonthlyFeeChange = useCallback((e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        setConfirmedMonthlyFee(e.target.value as number);
    }, []);

    const handleCheckButtonClick = useCallback(() => {
        setIsChecked(true);
        if (!confirmedMonthlyFee || confirmedMonthlyFee < 100 || 1000000 < confirmedMonthlyFee) return;
        setIsConfirmMode(true);
    }, [confirmedMonthlyFee]);

    const handleBackButtonClick = useCallback(() => {
        setIsConfirmMode(false);
    }, []);

    const handleConfirmButtonClick = useCallback(async () => {
        if (!props.targetTeacherToDo?.class?.privateRequest || !confirmedMonthlyFee || !confirmedFirstDate) return;
        setIsProcessing(true);
        const result = await approvePrivateRequest({
            approvePrivateRequestRequestBody: {
                privateRequestId: props.targetTeacherToDo.class?.privateRequest?.privateRequestId,
                confirmedMonthlyFee,
                confirmedFirstDate: dateToString(confirmedFirstDate),
                teacherToDoId: props.targetTeacherToDo.teacherToDoId,
            },
        });
        setIsProcessing(false);
        if (result.isSuccess) {
            toast(<ToastContents title="スケジュール申請承認完了" isCompleted />);
        } else {
            toast(<ToastContents title="スケジュール申請承認失敗" isFailed />);
        }
    }, [approvePrivateRequest, confirmedMonthlyFee, confirmedFirstDate, props.targetTeacherToDo]);

    const handleFirstDateChange = useCallback((date: Date | undefined) => {
        setConfirmedFirstDate(date);
    }, []);

    const getFirstDate = useCallback(() => {
        if (!confirmedFirstDate) return "";
        const year = confirmedFirstDate.getFullYear();
        const month = confirmedFirstDate.getMonth() + 1;
        const date = confirmedFirstDate.getDate();
        const day = WHAT_DAY_LIST[confirmedFirstDate.getDay()];
        return `${year}年${month}月${date}日(${day})`;
    }, [confirmedFirstDate]);

    return (
        <div className={styles.approvalWrapper} style={{ maxHeight: props.maxHeight }}>
            {isConfirmMode ? (
                <>
                    <div className={styles.confirmMessage}>
                        確定後、生徒さんからの入金が確認でき次第通知を送信します。以下の内容で確定しますが、よろしいですか？
                    </div>
                    <ul className={styles.itemList}>
                        <TargetCourse course={props.targetTeacherToDo?.class?.course} />
                        <StudentInfo student={props.targetTeacherToDo?.student} />
                        <BaseSchedules
                            baseSchedules={props.targetTeacherToDo?.class?.privateRequest?.baseSchedules ?? []}
                            isConfirmed
                        />
                        <ListItem title="月額料金" borderBottom>
                            {confirmedMonthlyFee
                                ? `¥${new Intl.NumberFormat("ja-JP").format(confirmedMonthlyFee)}`
                                : `¥0`}
                        </ListItem>
                        <ListItem title="開始日">{getFirstDate()}</ListItem>
                    </ul>
                    <div className={styles.buttonsWrapper}>
                        <Button className={styles.backButton} onClick={handleBackButtonClick}>
                            戻る
                        </Button>
                        <Button className={styles.checkButton} onClick={handleConfirmButtonClick}>
                            {isProcessing ? <Processing /> : "確定"}
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <ul className={styles.itemList}>
                        <BaseSchedules
                            baseSchedules={props.targetTeacherToDo?.class?.privateRequest?.baseSchedules ?? []}
                        />
                        <Estimate class={props.targetTeacherToDo?.class} />
                        <FirstDateDescription privateRequest={props.targetTeacherToDo?.class?.privateRequest} />
                        <ApplicationMessage privateRequest={props.targetTeacherToDo?.class?.privateRequest} />
                    </ul>
                    <div className={styles.confirmedMonthlyFeeWrapper}>
                        <div className={styles.confirmedMonthlyFeeTitle}>月額料金</div>
                        <TextField
                            type="number"
                            value={confirmedMonthlyFee}
                            onChange={handleMonthlyFeeChange}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">¥</InputAdornment>,
                            }}
                            className={styles.confirmedMonthlyFeeTextField}
                            placeholder="例）10000"
                        />
                        <ErrorMessage
                            content="入力してください。"
                            when={isChecked && confirmedMonthlyFee == undefined}
                        />
                        <ErrorMessage
                            content="100以上1000000以下で入力してください。"
                            when={
                                isChecked &&
                                confirmedMonthlyFee != undefined &&
                                (confirmedMonthlyFee < 100 || 1000000 < confirmedMonthlyFee)
                            }
                        />
                    </div>
                    <FirstDate
                        confirmedFirstDate={confirmedFirstDate}
                        isChecked={isChecked}
                        handleFirstDateChange={handleFirstDateChange}
                    />
                    <div className={styles.buttonsWrapper}>
                        <Button className={styles.backButton} onClick={props.handleBackButtonClick}>
                            戻る
                        </Button>
                        <Button className={styles.checkButton} onClick={handleCheckButtonClick}>
                            確認
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
});
