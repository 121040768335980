import styles from "@/pages/Teacher/MyPage/index.module.scss";
import { memo } from "react";
import { Mandatory } from "@/components/Tag/Mandatory";
import { SubjectResponse } from "@/store/autogenApi";
import { getSubject } from "@/utils/SubjectUtils";
import { SubjectsComponent } from "@/components/SubjectsComponent";

interface Props {
    checked: boolean;
    edit: boolean;
    teachableSubjects: SubjectResponse[];
    setTeachableSubjects: React.Dispatch<React.SetStateAction<SubjectResponse[]>>;
}

export const TeachableSubjects: React.VFC<Props> = memo(function TeachableSubjects(props) {
    return (
        <>
            {props.edit ? (
                <>
                    <li className={styles.editListItem}>
                        <div className={styles.leftWrapper}>
                            <div className={styles.titleWrapper}>
                                <div className={styles.title}>対応可能科目</div>
                                <Mandatory />
                            </div>
                        </div>
                        <div className={styles.rightWrapper}>
                            <SubjectsComponent
                                checked={props.checked}
                                subjects={props.teachableSubjects}
                                setSubjects={props.setTeachableSubjects}
                                style={{ padding: 0 }}
                            />
                        </div>
                    </li>
                </>
            ) : (
                <li className={styles.profileItem} style={{ borderBottom: props.edit ? "" : "#305077 1px solid" }}>
                    <div className={styles.profileTitle}>対応可能科目</div>
                    <div className={styles.profileSpace}></div>
                    <ul className={styles.teachableSubjects}>
                        {props.teachableSubjects.map((subject) => (
                            <li className={styles.teachableSubject}>{getSubject(subject)}</li>
                        ))}
                    </ul>
                </li>
            )}
        </>
    );
});
