import MenuItem from "@material-ui/core/MenuItem";

import styles from "../../userInfoInput.module.scss";
import { None } from "../Errors/None";
import { PREFECTURE_LIST } from "./prefectureList";
import { memo } from "react";
import { Mandatory } from "@/components/Tag/Mandatory";
import { IsPublicRadioButtons } from "@/components/IsPublicRadioButtons";
import { StyledSelect } from "@/components/StyledSelect";
import { DefaultSelect } from "@/components/DefaultSelect";

interface Props {
    handleChange?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    open?: boolean;
    value?: string;
    checked: boolean;
    isPublic: boolean;
    handleIsPublicChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Prefecture: React.VFC<Props> = memo(function Prefecture(props) {
    return (
        <div className={styles.inputItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitleWrapper}>
                    <span className={styles.itemTitle}>お住まいの都道府県</span>
                </div>
                {!props.open && <Mandatory />}
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>{`${props.value} - ${props.isPublic ? "公開" : "非公開"}`}</div>
                ) : (
                    <>
                        {props.handleChange && (
                            <DefaultSelect
                                name="prefecture"
                                value={props.value ?? ""}
                                handleChange={props.handleChange}
                                options={["", ...PREFECTURE_LIST].map((prefecture) => ({
                                    value: prefecture,
                                    label: prefecture,
                                }))}
                            />
                        )}
                        {props.checked && <None value={props.value ?? ""} />}
                        <IsPublicRadioButtons
                            isPublic={props.isPublic}
                            handleIsPublicChange={props.handleIsPublicChange}
                        />
                    </>
                )}
            </div>
        </div>
    );
});
