import { memo, useCallback } from "react";

import styles from "../index.module.scss";
import { MessageResponse } from "@/store/autogenApi";
import { FilesContents } from "../../FilesContents";

interface Props {
    message: MessageResponse;
    AMOrPM: string;
    readInfo: string;
    createdAt: string;
}

export const MyMessage: React.VFC<Props> = memo(function NormalMessage(props) {
    const getPersonalMeetingContent = useCallback(() => {
        const personalMeetingUrl = props.message.personalMeetingUrl;
        if (!personalMeetingUrl) return;
        const teacherNickname = props.message.teacher?.nickname;
        if (!teacherNickname) return;
        return (
            <div className={styles.invitation}>
                トピック: {teacherNickname}'s Personal Meeting Room
                <br />
                <br />
                Zoomミーティングに参加する
                <br />
                <a className={styles.meetingUrl} href={props.message.personalMeetingUrl}>
                    {props.message.personalMeetingUrl}
                </a>
            </div>
        );
    }, [props.message]);

    return (
        <li className={styles.messageItem} key={props.message.messageId}>
            <div
                className={styles.myWrapper}
                style={props.message.messageType === "file" ? { marginRight: "12px" } : {}}
            >
                <div className={styles.myTimeAndReadWrapper}>
                    {props.readInfo && <div className={styles.read}>{props.readInfo}</div>}
                    <div className={styles.myTimeWrapper}>
                        <div className={styles.AMOrPM}>{props.AMOrPM}</div>
                        <div className={styles.createdAt}>{props.createdAt}</div>
                    </div>
                </div>
                {props.message.messageType === "file" ? (
                    <FilesContents
                        fileUrls={props.message.fileUrls}
                        fileObjectKeys={props.message.fileObjectKeys}
                        textAlignRight
                    />
                ) : (
                    <div className={styles.myBalloon}>
                        {props.message.messageType === "personalMeeting" && getPersonalMeetingContent()}
                        {props.message.messageType === "text" && (
                            <div className={styles.text}>{props.message.text}</div>
                        )}
                    </div>
                )}
            </div>
        </li>
    );
});
