import TextField from "@material-ui/core/TextField";
import { memo } from "react";

import { Mandatory } from "@/components/Tag/Mandatory";
import styles from "@/pages/Student/MyPage/index.module.scss";
import { None } from "../Errors/None";
import { Over } from "../Errors/Over";

interface Props {
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>, upperLimit: number) => void;
    checked: boolean;
    edit: boolean;
    value: string;
}

export const NickName: React.VFC<Props> = memo(function NickName(props) {
    return (
        <>
            {props.edit ? (
                <>
                    <li className={styles.editListItem}>
                        <div className={styles.leftWrapper}>
                            <div className={styles.titleWrapper}>
                                <div className={styles.title}>生徒用ニックネーム</div>
                                <div className={styles.limit}>(20文字以内)</div>
                                <Mandatory />
                            </div>
                        </div>
                        <div className={styles.rightWrapper}>
                            <TextField
                                variant="outlined"
                                required
                                placeholder="例）トレ田イル道"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className={styles.input}
                                name="nickname"
                                value={props.value}
                                onChange={(e) => {
                                    props.handleChange(e, 20);
                                }}
                            />
                            <Over value={props.value} upperLimit={20} />
                            <None value={props.value} checked={props.checked} />
                        </div>
                    </li>
                </>
            ) : (
                <li className={styles.profileItem} style={{ borderBottom: props.edit ? "" : "#305077 1px solid" }}>
                    <div className={styles.profileTitleWrapper}>
                        <div className={styles.profileTitle}>ニックネーム</div>
                    </div>
                    <div className={styles.profileSpace}></div>
                    <div className={styles.profileValue}>{props.value}</div>
                </li>
            )}
        </>
    );
});
