import { memo, useCallback, useEffect, useState } from "react";
import styles from "../index.module.scss";
import { LessonResponse, CreateLessonRequestParams } from "@/store/autogenApi";

interface Props {
    lessons: LessonResponse | CreateLessonRequestParams[];
    lessonCalendarLanesEl: HTMLElement | null;
    targetDayOfWeekIdx: number;
    newLessonStartYIdx: number | undefined;
    newLessonEndYIdx: number | undefined;
    enableToUpsertLesson: boolean;
    handleNewLessonStartYIdxChange: (idx: number | undefined) => void;
    handleNewLessonEndYIdxChange: (idx: number | undefined) => void;
    handleNewLessonXIdxChange: (idx: number | undefined) => void;
    handleNewLessonPopoverOpen: () => void;
    handleIsDraggingChange: (isDragging: boolean) => void;
}

export const DraggableArea: React.VFC<Props> = memo((props) => {
    const [draggableAreaEl, setDraggableAreaEl] = useState<null | HTMLElement>(null);
    const [initialBlockIdx, setInitialBlockIdx] = useState<number | undefined>(undefined);

    useEffect(() => {
        if (!draggableAreaEl) return;
        draggableAreaEl.addEventListener("dragend", (event) => {
            event.preventDefault();
        });
    }, [draggableAreaEl]);

    const draggableAreaRef = useCallback((el: HTMLElement | null) => {
        setDraggableAreaEl(el);
    }, []);

    const getBlockIdxFromEvent = useCallback(
        (e: React.DragEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            // lessonCalendarLanesWrapperのY座標を取得
            const lessonCalendarLanesWrapperEl = props.lessonCalendarLanesEl?.parentElement;
            if (!lessonCalendarLanesWrapperEl) return;
            const lessonCalendarLanesWrapperElY = lessonCalendarLanesWrapperEl.getBoundingClientRect().y;
            // カーソルのY座標を取得
            const cursorY = e.clientY;
            // カーソルの相対座標を取得
            const cursorRelativeY = cursorY - lessonCalendarLanesWrapperElY;
            // lessonCalendarLanesのスクロール位置を取得
            const lessonCalendarLanesWrapperElScrollTop = lessonCalendarLanesWrapperEl.scrollTop;
            // 4ブロック（1時間）につき50px+border1pxで合計51px
            // 1ブロック（15分）につき12.75px
            // イベント位置に最も近いブロックのidxを取得
            const y = (lessonCalendarLanesWrapperElScrollTop + cursorRelativeY) / 12.75;
            const upperY = Math.floor(y);
            const lowerY = Math.ceil(y);
            const blockIdx = Math.abs(upperY - y) < Math.abs(lowerY - y) ? upperY : lowerY;
            return blockIdx;
        },
        [props.lessonCalendarLanesEl],
    );

    const handleDragStart = useCallback(
        (e: React.DragEvent<HTMLDivElement>, idx: number) => {
            const initialIdx = getBlockIdxFromEvent(e);
            if (initialIdx === undefined) return;
            props.handleIsDraggingChange(true);
            setInitialBlockIdx(initialIdx);
            props.handleNewLessonXIdxChange(idx);
        },
        [getBlockIdxFromEvent, props.handleNewLessonStartYIdxChange, props.handleNewLessonXIdxChange],
    );

    const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        // dragEndをスムーズに行うために必要な処理
        // カーソルを通常の矢印にする
        e.dataTransfer.dropEffect = "move";
        e.stopPropagation();
        e.preventDefault();
    }, []);

    const handleDrag = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            // 呼び出し回数が多すぎるので、ミリ秒が5で割り切れるときのみ処理を行う
            const now = new Date();
            const remainder = now.getMilliseconds();
            if (remainder % 5 !== 0) return;
            e.preventDefault();
            const currentIdx = getBlockIdxFromEvent(e);
            if (currentIdx == undefined || initialBlockIdx == undefined) return;
            // initialBlockIdxとcurrentIdxの差分を取得
            const deltaIdx = currentIdx - initialBlockIdx;
            // 差分が0のときはstartもendもundefinedにする
            if (deltaIdx === 0) {
                props.handleNewLessonStartYIdxChange(undefined);
                props.handleNewLessonEndYIdxChange(undefined);
                return;
            }
            // 差分が正（下方向にドラッグ）のときはstartにinitialBlockIdxを、endにcurrentIdxを入れる
            if (deltaIdx > 0) {
                // 既にendに同じ値が入っているときは何もしない
                if (props.newLessonEndYIdx === currentIdx) return;
                props.handleNewLessonStartYIdxChange(initialBlockIdx);
                props.handleNewLessonEndYIdxChange(currentIdx);
                return;
            }
            // 差分が負（上方向にドラッグ）のときはstartにcurrentIdxを、endにinitialBlockIdxを入れる
            if (deltaIdx < 0) {
                // 既にstartに同じ値が入っているときは何もしない
                if (props.newLessonStartYIdx === currentIdx) return;
                props.handleNewLessonStartYIdxChange(currentIdx);
                props.handleNewLessonEndYIdxChange(initialBlockIdx);
                return;
            }
        },
        [
            getBlockIdxFromEvent,
            props.handleNewLessonEndYIdxChange,
            props.handleNewLessonStartYIdxChange,
            props.newLessonStartYIdx,
            props.newLessonEndYIdx,
            initialBlockIdx,
        ],
    );

    const handleDragEnd = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            props.handleIsDraggingChange(false);
            const currentIdx = getBlockIdxFromEvent(e);
            if (currentIdx == undefined || initialBlockIdx == undefined) return;
            // initialBlockIdxとcurrentIdxの差分を取得
            const deltaIdx = currentIdx - initialBlockIdx;
            // 差分が0のときはstartもendもundefinedにする
            if (deltaIdx === 0) {
                props.handleNewLessonStartYIdxChange(undefined);
                props.handleNewLessonEndYIdxChange(undefined);
                return;
            }
            // 差分が正（下方向にドラッグ）のときはstartにinitialBlockIdxを、endにcurrentIdxを入れる
            if (deltaIdx > 0) {
                props.handleNewLessonStartYIdxChange(initialBlockIdx);
                props.handleNewLessonEndYIdxChange(currentIdx);
                props.handleNewLessonPopoverOpen();
                return;
            }
            // 差分が負（上方向にドラッグ）のときはstartにcurrentIdxを、endにinitialBlockIdxを入れる
            if (deltaIdx < 0) {
                props.handleNewLessonStartYIdxChange(currentIdx);
                props.handleNewLessonEndYIdxChange(initialBlockIdx);
                props.handleNewLessonPopoverOpen();
                return;
            }
        },
        [getBlockIdxFromEvent, props.handleNewLessonEndYIdxChange, initialBlockIdx],
    );

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>, idx) => {
            if (!props.enableToUpsertLesson) return;
            const startIdx = getBlockIdxFromEvent(e);
            if (startIdx === undefined) return;
            const endIdx = startIdx + 4;
            setInitialBlockIdx(startIdx);
            props.handleNewLessonXIdxChange(idx);
            props.handleNewLessonStartYIdxChange(startIdx);
            props.handleNewLessonEndYIdxChange(endIdx);
            props.handleNewLessonPopoverOpen();
        },
        [
            getBlockIdxFromEvent,
            props.handleNewLessonEndYIdxChange,
            props.handleNewLessonXIdxChange,
            props.handleNewLessonPopoverOpen,
            props.handleNewLessonStartYIdxChange,
            props.enableToUpsertLesson,
        ],
    );
    return (
        <div
            ref={draggableAreaRef}
            className={styles.draggableArea}
            draggable={props.enableToUpsertLesson}
            style={{ cursor: props.enableToUpsertLesson ? "move" : "default" }}
            onDragStart={(e) => {
                handleDragStart(e, props.targetDayOfWeekIdx);
            }}
            onDrag={handleDrag}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            onClick={(e) => {
                handleClick(e, props.targetDayOfWeekIdx);
            }}
        />
    );
});
