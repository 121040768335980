import { BaseSchedulesInput } from "@/components/BaseSchedulesInput";
import styles from "./index.module.scss";
import { CreateBaseScheduleParams, LessonResponse } from "@/store/autogenApi";
import { getBaseScheduleInfo } from "@/utils/BaseScheduleUtils";
import { memo } from "react";

interface Props {
    isChecked: boolean;
    isConfirmMode?: boolean;
    existingLessons: LessonResponse[];
    baseSchedules: CreateBaseScheduleParams[];
    pricePerHour: number;
    handleBaseScheduleDeleteButtonClick: (idx: number) => void;
    handleBaseSchedulesChange: (newBaseSchedules: CreateBaseScheduleParams[]) => void;
}

export const RegularCourse: React.VFC<Props> = memo(function RegularCourse(props) {
    return (
        <>
            {props.isConfirmMode ? (
                <div className={styles.openTrue}>
                    <ul className={styles.baseSchedules}>
                        {props.baseSchedules.map((baseSchedule, idx) => (
                            <li className={styles.baseSchedule} key={idx}>
                                {getBaseScheduleInfo(baseSchedule)}
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <>
                    <BaseSchedulesInput
                        isRequestChecked={props.isChecked}
                        validation={props.baseSchedules.length > 0}
                        baseSchedules={props.baseSchedules}
                        pricePerHour={props.pricePerHour}
                        existingLessons={props.existingLessons}
                        handleBaseScheduleDeleteButtonClick={props.handleBaseScheduleDeleteButtonClick}
                        handleBaseSchedulesChange={props.handleBaseSchedulesChange}
                    />
                </>
            )}
        </>
    );
});
