import { FAQDetailsBase } from "../../FAQDetailsBase";

export const RuleOrGuideline: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="利用のルール・ガイドラインはありますか？"
            text="受講ガイドライン・先生ガイドラインがございます。必ずこれらを守ってのご利用をよろしくお願いいたします。"
            urlInfos={[
                {
                    url: "/CourseGuidelines",
                    word: "受講ガイドライン",
                },
                {
                    url: "/TeacherGuidelines",
                    word: "先生ガイドライン",
                },
            ]}
        />
    );
};
