import styles from "@/pages/Common/AboutRequest/index.module.scss";

import { RegularCourse } from "./RegularCourse";
import { ShortCourse } from "./ShortCourse";

export const CourseTypes: React.VFC = () => {
    return (
        <div className={styles.courseTypesWrapper}>
            <div className={styles.courseTypesTitle}>2つの講座形態</div>
            <ShortCourse />
            <RegularCourse />
        </div>
    );
};
