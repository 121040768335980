import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

import styles from "@/pages/Teacher/IdentificationForStripe/index.module.scss";

import { Description } from "./Description";
import { IdentificationModal } from "./IdentificationModal";
import { IdCard } from "./Items/IdCard";
import { State } from "./Items/State";
import { City } from "./Items/City";
import { Line1 } from "./Items/Line1";
import { PostalCode } from "./Items/PostalCode";
import { Line2 } from "./Items/Line2";
import { memo } from "react";

import { FileInfo } from "@/components/TrimmingModal";
import { Town } from "./Items/Town";
import { Phone } from "./Items/Phone";
import { Email } from "./Items/Email";

interface Props {
    checked: boolean;
    open: boolean;
    idCardFileInfo: FileInfo | undefined;
    complete: boolean;
    modalHeight: number;
    postalCode: string;
    state: string;
    city: string;
    town: string;
    line1: string;
    line2: string;
    email: string;
    phone: string;
    isProcessing: boolean;
    isError: boolean;
    handleOpen: () => void;
    handleClose: () => void;
    addPhoto: (e: React.ChangeEvent<HTMLInputElement>) => void;
    deletePhoto: (buttonName: string) => void;
    confirm: () => Promise<void>;
    handleStateChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleCityChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleTownChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleLine1Change: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleLine2Change: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handlePostalCodeChange: (value: string) => void;
    handleEmailChange: (value: string) => void;
    handlePhoneChange: (value: string) => void;
}

export const FormContents: React.VFC<Props> = memo(function FormContents(props) {
    return (
        <div className={styles.identificationForStripeWrapper}>
            <div className={styles.identificationForStripe}>
                {props.isError ? (
                    <div className={styles.errorWrapper}>
                        <div className={styles.error}>処理中にエラーが発生しました。再度お試しください。</div>
                        <Button
                            className={styles.applicationButton}
                            component={Link}
                            to="/Teacher/MyPage/ApplicationForTransfer"
                        >
                            振込申請
                        </Button>
                    </div>
                ) : (
                    <>
                        {props.complete ? (
                            <div className={styles.completeWrapper}>
                                <div className={styles.complete}>
                                    Stripe利用のための情報入力が完了しました。以降、振込申請をご利用いただけます。
                                </div>
                                <Button
                                    className={styles.applicationButton}
                                    component={Link}
                                    to="/Teacher/MyPage/ApplicationForTransfer"
                                >
                                    振込申請
                                </Button>
                            </div>
                        ) : (
                            <>
                                <Description />
                                <div className={styles.formWrapper}>
                                    <div className={styles.form}>
                                        <div className={styles.formTitle}>・住所</div>
                                        <div className={styles.formItemWrapper}>
                                            <PostalCode
                                                checked={props.checked}
                                                handlePostalCodeChange={props.handlePostalCodeChange}
                                            />
                                            <State
                                                checked={props.checked}
                                                state={props.state}
                                                handleStateChange={props.handleStateChange}
                                            />
                                            <City
                                                checked={props.checked}
                                                city={props.city}
                                                handleCityChange={props.handleCityChange}
                                            />
                                            <Town
                                                checked={props.checked}
                                                town={props.town}
                                                handleTownChange={props.handleTownChange}
                                            />
                                            <Line1
                                                checked={props.checked}
                                                line1={props.line1}
                                                handleLine1Change={props.handleLine1Change}
                                            />
                                            <Line2
                                                checked={props.checked}
                                                line2={props.line2}
                                                handleLine2Change={props.handleLine2Change}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.form}>
                                        <div className={styles.formTitle}>・身分証明書</div>
                                        <div className={styles.formItemWrapper}>
                                            <IdCard
                                                addPhoto={props.addPhoto}
                                                deletePhoto={props.deletePhoto}
                                                url={props.idCardFileInfo?.url ?? ""}
                                                checked={props.checked}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.form}>
                                        <div className={styles.formTitle}>・連絡先</div>
                                        <div className={styles.formItemWrapper}>
                                            <Email
                                                checked={props.checked}
                                                handleEmailChange={props.handleEmailChange}
                                            />
                                            <Phone
                                                checked={props.checked}
                                                handlePhoneChange={props.handlePhoneChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <Button className={styles.checkButton} variant="contained" onClick={props.handleOpen}>
                                    確認
                                </Button>
                                <IdentificationModal
                                    modalHeight={props.modalHeight}
                                    postalCode={props.postalCode}
                                    state={props.state}
                                    city={props.city}
                                    town={props.town}
                                    line1={props.line1}
                                    line2={props.line2}
                                    email={props.email}
                                    phone={props.phone}
                                    url={props.idCardFileInfo?.url ?? ""}
                                    open={props.open}
                                    isProcessing={props.isProcessing}
                                    handleClose={props.handleClose}
                                    confirm={props.confirm}
                                />
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
});
