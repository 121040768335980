import FormHelperText from "@material-ui/core/FormHelperText";

import styles from "@/pages/Student/InfoInput/index.module.scss";

interface Props {
    value: string;
    upperLimit: number;
}

export const Over: React.VFC<Props> = (props) => {
    return (
        <>
            {props.value.length > props.upperLimit && (
                <FormHelperText className={styles.helperText}>
                    {props.upperLimit}文字以下で入力してください。
                </FormHelperText>
            )}
        </>
    );
};
