import styles from "@/pages/Common/FAQDetails/index.module.scss";

interface Props {
    list: string[];
    showIdx?: boolean;
}

export const List: React.VFC<Props> = (props) => {
    return (
        <ul className={styles.list}>
            {props.list.map((item, idx) => (
                <li className={styles.listItem} key={idx}>
                    {props.showIdx ? `${idx + 1}. ` : "・"} {item}
                </li>
            ))}
        </ul>
    );
};
