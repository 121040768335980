import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { memo } from "react";
import { Mandatory } from "@/components/Tag/Mandatory";
import styles from "@/pages/Teacher/MyPage/index.module.scss";
import { None } from "../Errors/None";
import { GRADE_LIST_FOR_TEACHER } from "@/utils/GradeListForTeacher";

interface Props {
    value: string | undefined;
    checked: boolean;
    edit: boolean;
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

export const Profession: React.VFC<Props> = memo(function Profession(props) {
    return (
        <>
            {props.edit ? (
                <>
                    <li className={styles.editListItem}>
                        <div className={styles.leftWrapper}>
                            <div className={styles.titleWrapper}>
                                <div className={styles.title}>職業・学年</div>
                                <Mandatory />
                            </div>
                        </div>
                        <div className={styles.rightWrapper}>
                            <Select
                                variant="outlined"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="profession"
                                value={props.value}
                                onChange={props.handleChange}
                                className={styles.select}
                            >
                                {GRADE_LIST_FOR_TEACHER.map((grade, index) => (
                                    <MenuItem key={index} value={grade}>
                                        {grade}
                                    </MenuItem>
                                ))}
                            </Select>
                            <None value={props.value} checked={props.checked} />
                        </div>
                    </li>
                </>
            ) : (
                <li className={styles.profileItem} style={{ borderBottom: props.edit ? "" : "#305077 1px solid" }}>
                    <div className={styles.profileTitle}>職業・学年</div>
                    <div className={styles.profileSpace}></div>
                    <div className={styles.profileValue}>{props.value}</div>
                </li>
            )}
        </>
    );
});
