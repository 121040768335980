import { FAQConfig } from "@/FAQConfig";

export const REGISTER_AS_TEACHER_CONFIGS: FAQConfig[] = [
    {
        title: "メールアドレスで新規登録",
        text: "メールアドレスで新規登録する手順について説明します。",
        contents: [
            {
                title: "①新規登録ページに移動",
                text: "トップページからこちらのボタンを押してください",
            },
            {
                title: "②メールアドレスを入力",
                text: "「生徒として登録」を選択し、メールアドレスを入力してください。メールアドレスは、Trailでのログインに使用します。",
            },
            {
                title: "③受信メールを確認",
                text: "入力したメールアドレスに、登録用のメールが届きます。メールに記載されているURLをクリックしてください。",
            },
            {
                title: "④必要事項の入力",
                text: "メールに記載されているURLをクリックすると、登録用のページに移動します。画面の指示に従って必要事項を入力してください。",
            },
            {
                title: "⑤登録完了",
                text: "この画面が表示されれば登録完了となります。登録したメールアドレスとパスワードでログインしてください。",
            },
        ],
    },
];
