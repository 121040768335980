import { memo, useRef } from "react";
import { useState, useEffect } from "react";
import { Avatar, Button, useMediaQuery } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { MdRateReview } from "react-icons/md";
import { useHistory } from "react-router";
import styles from "@/pages/Common/CourseDetails/index.module.scss";
import { ReviewResponse } from "@/store/autogenApi";
import boy from "./images/boy.jpg";
import girl from "./images/girl.jpg";
import undefined from "./images/undefined.jpg";

interface Props {
    reviews: ReviewResponse[];
    reviewsRef: React.RefObject<HTMLDivElement>;
    courseId: string;
}

export const ReviewsComponent: React.VFC<Props> = memo(function ReviewsComponent(props) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOver, setIsOver] = useState<boolean>(false);

    const reviewListEl: React.MutableRefObject<HTMLElement | null | undefined> = useRef();

    useEffect(() => {
        const scrollHeight = reviewListEl.current?.scrollHeight;
        if (scrollHeight && scrollHeight > 400) {
            setIsOver(true);
        }
    }, [reviewListEl]);

    const showMore = () => {
        setIsOpen(!isOpen);
    };

    const getSrc = (review: ReviewResponse) => {
        if (review.anonymous) {
            if (review.user?.gender === "male") {
                return boy;
            } else if (review.user?.gender === "female") {
                return girl;
            } else {
                return undefined;
            }
        } else {
            return review.student?.iconImageUrl;
        }
    };

    const mdUp = useMediaQuery("(min-width:960px)");

    const history = useHistory();

    const handleAllReviewsButtonClick = (courseId: string) => {
        history.push(`/Reviews/${courseId}`);
    };

    return (
        <div className={styles.reviewWrapper} ref={props.reviewsRef}>
            <div className={styles.topWrapper}>
                <div className={styles.reviewHeadingWrapper}>
                    <span className={styles.reviewHeading}>レビュー</span>
                </div>
                <Button
                    className={styles.allReviewsButton}
                    onClick={() => {
                        handleAllReviewsButtonClick(props.courseId);
                    }}
                >
                    全てのレビューを見る
                </Button>
            </div>
            <ul
                className={styles.reviewList}
                style={{ overflowY: isOpen ? "auto" : "hidden", maxHeight: isOpen ? "none" : "400px" }}
                id="reviewList"
                ref={(node: HTMLElement | null) => {
                    reviewListEl.current = node;
                }}
            >
                {props.reviews.map((review, idx) => {
                    return (
                        <li className={styles.reviewListItem} key={idx}>
                            <div className={styles.reviewerInfo}>
                                {review.anonymous ? (
                                    <>
                                        <Avatar className={styles.avatar} src={getSrc(review)} />
                                        <div className={styles.gradeAndGender}>
                                            {review.user?.grade}-{review.user?.gender}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <Avatar className={styles.avatar} src={getSrc(review)} />
                                        <div className={styles.gradeAndGender}>{review.student?.nickname}</div>
                                    </>
                                )}
                            </div>
                            <div className={styles.reviewInfo}>
                                <div className={styles.contentsWithFlexGrow}>
                                    <div className={styles.reviewContents}>
                                        <Rating
                                            name="half-rating-read"
                                            defaultValue={review.score}
                                            precision={0.5}
                                            readOnly
                                            className={styles.rating}
                                            size={mdUp ? "medium" : "small"}
                                        />
                                        <div className={styles.reviewTitle}>{review.title}</div>
                                        <div className={styles.reviewText}>{review.comment}</div>
                                    </div>
                                    <div className={styles.flexGrow} />
                                </div>
                                <div className={styles.month}>{`評価対象期間：${review.evaluationPeriod}`}</div>
                            </div>
                        </li>
                    );
                })}
            </ul>
            {isOver && (
                <div className={styles.moreButtonWrapper} style={{ position: isOpen ? "inherit" : "absolute" }}>
                    <Button className={styles.reviewButton} onClick={showMore}>
                        {isOpen ? "閉じる" : "すべて見る"}
                    </Button>
                </div>
            )}
        </div>
    );
});
