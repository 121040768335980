import { memo, useCallback, useEffect, useState } from "react";

import { SignInComponent } from "@/pageComponents/Common/SignIn";

import { SignInProcess } from "@/pageComponents/Common/SignIn/SignInProcess";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { Navigation } from "@/components/Navigation";

const NAVIGATION_LIST: Navigation[] = [
    {
        title: "ログイン",
        url: "/SignIn",
    },
];

export const SignIn: React.VFC = memo(function SignIn() {
    const [signInMode, setSignInMode] = useState<string>("");
    const [userId, setUserId] = useState<string | undefined>(undefined);
    const [isProcessingForSignIn, setIsProcessingForSignIn] = useState<boolean>(false);
    const [byEmail, setByEmail] = useState(false);

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    const handleEmailButtonClick = useCallback(() => {
        setByEmail(true);
    }, []);

    const handleGoogleButtonClick = useCallback(async (forStudent: boolean) => {}, []);

    const handleSignInAsStudentButtonClick = useCallback(() => {
        setSignInMode("student");
    }, []);

    const handleSignInAsTeacherButtonClick = useCallback(() => {
        setSignInMode("teacher");
    }, []);

    const handleClose = useCallback(() => {
        setSignInMode("");
        setByEmail(false);
    }, []);

    const handleIsProcessingForSignInChange = useCallback((value: boolean) => {
        setIsProcessingForSignIn(value);
    }, []);

    const handleUserIdChange = useCallback((value: string) => {
        setUserId(value);
    }, []);

    return (
        <>
            {userId && signInMode && (
                <SignInProcess
                    userId={userId}
                    forStudent={signInMode === "student" ? true : false}
                    handleClose={handleClose}
                    handleIsProcessingForSignInChange={handleIsProcessingForSignInChange}
                />
            )}
            <SignInComponent
                handleEmailButtonClick={handleEmailButtonClick}
                handleGoogleButtonClick={handleGoogleButtonClick}
                handleSignInAsStudentButtonClick={handleSignInAsStudentButtonClick}
                handleSignInAsTeacherButtonClick={handleSignInAsTeacherButtonClick}
                handleClose={handleClose}
                handleUserIdChange={handleUserIdChange}
                handleIsProcessingForSignInChange={handleIsProcessingForSignInChange}
                signInMode={signInMode}
                byEmail={byEmail}
                isProcessingForSignIn={isProcessingForSignIn}
            />
        </>
    );
});
