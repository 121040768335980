import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { HeaderContents } from "./HeaderContents";
import styles from "./index.module.scss";
import { RootState } from "@/ducks";
import { useLocation } from "react-router";
import { useInvalidateTagsMutation } from "@/store/hooks/invalidateTags";
import useLocationChange from "../UseLocationChange";
import { useMediaQuery } from "@material-ui/core";
import { NavigationComponent } from "../Navigation";
import { useNavigation } from "../Navigation/NavigationContext";
import ReactGA from "react-ga4";
const TRACKING_ID = "G-FG6CXZS5E1";

interface Props {
    currentBodyEl: HTMLElement | null;
}

export const Header: React.VFC<Props> = (props) => {
    const [previousPathname, setPreviousPathname] = useState<string | undefined>(undefined);

    const jwt = useSelector((state: RootState) => state.jwt);
    const { navigationList } = useNavigation();

    const smUp = useMediaQuery("(min-width:600px)");

    useLocationChange((location) => {
        (async () => {
            ReactGA.initialize(TRACKING_ID);
            ReactGA.send({
                hitType: "pageview",
                page: location.pathname + location.search,
            });
            if (refCallback.current) {
                refCallback.current(location as unknown as Location);
            }
            if (userMode === "student") {
                await invalidateTags({
                    targetAccountType: "student",
                });
            } else if (userMode === "teacher") {
                await invalidateTags({
                    targetAccountType: "teacher",
                });
            }
        })();
        return () => {
            setPreviousPathname(location.pathname);
        };
    });

    const refCallback = useRef<undefined | ((location: Location) => void)>();
    const userMode = useSelector((state: RootState) => state.jwt.userMode);
    const invalidateTags = useInvalidateTagsMutation();

    return (
        <div className={styles.headerWrapper} id="header" key={document.location.href}>
            <div className={styles.header}>
                <HeaderContents userMode={jwt.userMode} currentBodyEl={props.currentBodyEl} />
            </div>
            <NavigationComponent navigationList={navigationList} currentBodyEl={props.currentBodyEl} />
        </div>
    );
};
