import React, { useContext, useEffect, useState } from "react";

import { StaticContext, useHistory, useLocation } from "react-router";
import { BrowserRouter, Switch, Route, RouteComponentProps } from "react-router-dom";

import { ScrollToTop } from "@/components/ScrollToTop";

import { Forbidden } from "./Forbidden";
import { userType, modesType, MODES } from "./ProtectedRouterConfig";

interface BrowserRouterProps {
    target: userType;
    children?: React.ReactNode;
}

//eslint-disable-next-line
const dummyCheck = (mode: keyof modesType, strict: boolean, hist: string[]) => {
    return false;
};

const dummyHist: Array<string> = [];

// eslint-disable-next-line
const dummyHistSetter = (newURL: string) => {};

type contextType = {
    checkPermission: (mode: keyof modesType, strict: boolean, hist: string[]) => boolean;
    hist: string[];
    setHist: (newURL: string) => void;
};

const initContext: contextType = {
    checkPermission: dummyCheck,
    hist: dummyHist,
    setHist: dummyHistSetter,
};

const source: modesType = MODES;

export const browserContext = React.createContext(initContext);

export const ProtectedBrowserRouter = ({ target, children }: BrowserRouterProps) => {
    const havePermission = (mode: keyof modesType, strict: boolean, hist: string[]) => {
        if (strict) {
            return source[mode][target] && hist.length != 0;
        } else {
            return source[mode][target];
        }
    };

    const [hist, setHist] = useState<string[]>([]);

    const histSetter = (newURL: string) => {
        setHist([...hist, newURL]);
    };

    const contextValue = { checkPermission: havePermission, hist: hist, setHist: histSetter };

    return (
        <browserContext.Provider value={contextValue}>
            <BrowserRouter
                getUserConfirmation={() => {
                    /* Empty callback to block the default browser prompt */
                }}
            >
                {children}
            </BrowserRouter>
        </browserContext.Provider>
    );
};

interface SwitchProps {
    children?: React.ReactNode;
}

export const ProtectedSwitch = ({ children }: SwitchProps) => {
    /* eslint-disable */
    return <Switch children={children} />;
    /* eslint-enable */
};

interface RouteProps {
    path: string;
    strict?: boolean;
    modes: (keyof modesType)[];
    exact?: boolean;
    component?:
        | React.ComponentType<any> // eslint-disable-line
        | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>>; // eslint-disable-line
    children?: any; // eslint-disable-line
}

export const ProtectedRoute = ({ exact = false, strict = false, path, modes, component, children }: RouteProps) => {
    const history = useHistory();
    const location = useLocation();
    const { checkPermission, hist, setHist } = useContext(browserContext);

    useEffect(() => {
        setHist(location.pathname);
    }, [location]);

    const permission = modes.some((mode) => checkPermission(mode, strict, hist));
    if (permission) {
        return (
            <ScrollToTop>
                <Route exact={exact} path={path} component={component}>
                    {children}
                </Route>
            </ScrollToTop>
        ); // eslint-disable-line
    } else {
        history.push("/Forbidden");
        return (
            <ScrollToTop>
                <Route exact={exact} path={path + ":true"} component={Forbidden} />
            </ScrollToTop>
        );
    }
};
