import { memo } from "react";
import { ListItem } from "./ListItem";

import { getPeriod } from "@/utils/DateUtils";

interface Props {
    startDate: Date | undefined;
    endDate: Date | undefined;
}

export const Period: React.VFC<Props> = memo(function Period(props) {
    return (
        <ListItem title="更新期間">
            <>{getPeriod(props.startDate, props.endDate)}</>
        </ListItem>
    );
});
