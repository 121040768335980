import { memo, useCallback, useEffect, useState } from "react";

import { Button, Modal } from "@material-ui/core";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";

import styles from "../../index.module.scss";

import { EducationalBackGroundQueries } from "@/pages/Common/CourseSearchResults";
import { UniversitiesSearch } from "./UniversitiesSearch";

interface Props {
    open: boolean;
    targetEducationalBackground: EducationalBackGroundQueries;
    handleClose: () => void;
    handleIsConfirmedChange: () => void;
    handleBelongingNarrowDownButtonClick: () => void;
    setTargetEducationalBackground: React.Dispatch<React.SetStateAction<EducationalBackGroundQueries>>;
}

export const UniversitiesSearchModal: React.VFC<Props> = memo(function UniversitiesSearchModal(props) {
    const [initial, setInitial] = useState<string | undefined>(undefined);
    const [modalHeight, setModalHeight] = useState<number>(0);
    const JAPANESE_SYLLABARIES_LIST = [
        ["あ", "い", "う", "え", "お"],
        ["か", "き", "く", "け", "こ"],
        ["さ", "し", "す", "せ", "そ"],
        ["た", "ち", "つ", "て", "と"],
        ["な", "に", "ぬ", "ね", "の"],
        ["は", "ひ", "ふ", "へ", "ほ"],
        ["ま", "み", "む", "め", "も"],
        ["や", "", "ゆ", "", "よ"],
        ["ら", "り", "る", "れ", "ろ"],
        ["わ", "", "を", "", "ん"],
    ];

    const changeModalHeight = useCallback(() => {
        const innerHeight = window.innerHeight;
        setModalHeight(innerHeight * 0.75);
    }, []);

    useEffect(() => {
        changeModalHeight();
        window.addEventListener("resize", () => {
            changeModalHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalHeight();
            });
        };
    }, []);

    const handleInitialChange = useCallback((newInitial: string) => {
        setInitial(newInitial);
    }, []);

    const handleBackButtonClick = useCallback(() => {
        setInitial(undefined);
        props.setTargetEducationalBackground({
            university: "",
            faculty: "",
            department: "",
        });
    }, []);

    const handleClose = useCallback(() => {
        setInitial(undefined);
        props.handleClose();
    }, [props.handleClose]);

    return (
        <Modal open={props.open} onClose={handleClose}>
            <div className={styles.universityModalContents} style={{ outline: "none" }}>
                <div className={styles.universitySearchTitleWrapper}>
                    {initial == undefined ? (
                        <Button className={styles.closeButton} onClick={handleClose}>
                            <CloseIcon className={styles.closeIcon} />
                        </Button>
                    ) : (
                        <Button className={styles.backButton} onClick={handleBackButtonClick}>
                            <BackIcon className={styles.backIcon} />
                        </Button>
                    )}
                    <div className={styles.universitySearchTitle}>大学検索</div>
                </div>
                {initial == undefined ? (
                    <div className={styles.syllabariesWrapper}>
                        <div className={styles.description}>大学名の頭文字を選択してください。</div>
                        <div className={styles.syllabariesContents}>
                            {JAPANESE_SYLLABARIES_LIST.map((syllabaries, idx1) => {
                                return (
                                    <div className={styles.syllabaries} key={idx1}>
                                        {syllabaries.map((syllabary, idx2) => {
                                            return (
                                                <Button
                                                    className={
                                                        syllabary
                                                            ? `${styles.syllabaryButton}`
                                                            : `${styles.syllabaryButton} ${styles.space}`
                                                    }
                                                    key={idx2}
                                                    onClick={() => {
                                                        handleInitialChange(syllabary);
                                                    }}
                                                >
                                                    {syllabary}
                                                </Button>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <UniversitiesSearch
                        initial={initial}
                        targetEducationalBackground={props.targetEducationalBackground}
                        handleIsConfirmedChange={props.handleIsConfirmedChange}
                        handleBelongingNarrowDownButtonClick={props.handleBelongingNarrowDownButtonClick}
                        setTargetEducationalBackground={props.setTargetEducationalBackground}
                    />
                )}
            </div>
        </Modal>
    );
});
