import { useEffect, useState } from "react";

import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Navigation, NavigationComponent } from "@/components/Navigation";

import styles from "./index.module.scss";
import { RootState } from "@/ducks";
import { useNavigation } from "@/components/Navigation/NavigationContext";

const NAVIGATION_LIST: Navigation[] = [{ title: "プライバシーポリシー", url: "/PrivacyPolicy" }];

export const PrivacyPolicy: React.VFC = () => {
    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    const userMode = useSelector((state: RootState) => state.jwt.userMode);
    return (
        <>
            <div className={styles.privacyPolicyWrapper}>
                <h2 className={styles.title}>プライバシーポリシー</h2>
                <section className={styles.privacyPolicy}>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>個人情報に対する基本的な考え方</h3>
                        <section className={styles.description}>
                            株式会社オノレ（以下「当社」）は、当社のウェブサービス「Trail」（以下「本サービス」）を提供するにあたり、必要となる情報をユーザーから取得します。当社は、ユーザー（ユーザー登録した「登録ユーザー」および登録ユーザー以外で本サービスを閲覧する「ゲストユーザー」を指します）から取得した個人情報の保護に最大限の注意を払います。当社の個人情報に対する考え方は以下の通りです。なお、当プライバシーポリシーにおける用語は、当プライバシーポリシーに定義されるもののほか、本サービスの利用規約、ヘルプやガイドライン（以下「利用規約等」）において定義される意味によるものとします。
                        </section>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>個人情報の取得</h3>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                (1) 本サービスの利用にあたり、以下の情報をご提供いただく場合があります。
                                <ol className={styles.smallItems}>
                                    <li className={styles.smallItem}>・氏名</li>
                                    <li className={styles.smallItem}>・性別</li>
                                    <li className={styles.smallItem}>・メールアドレス</li>
                                    <li className={styles.smallItem}>・年齢</li>
                                    <li className={styles.smallItem}>・お住まいの地域</li>
                                    <li className={styles.smallItem}>
                                        ・その他当社が定める入力フォームにユーザーが入力する情報
                                    </li>
                                </ol>
                            </li>
                            <li className={styles.item}>
                                (2)
                                ユーザーが、当社が指定するサードパーティ（以下「特定サードパーティ」）のサービスを通じたID連携を希望する場合には、当社は、第(1)項に替えて、特定サードパーティのサービスを通じ、ユーザーが特定サードパーティに登録した前項に掲げる情報を含む個人情報（ただし、当社が特定サードパーティから取得することにつきユーザーが承諾したものに限ります。以下「登録ユーザー情報」）を取得します。
                            </li>
                            <li className={styles.item}>
                                (3)
                                当社は、ユーザーのIPアドレス、クッキー情報やユーザーが閲覧したページ、ユーザーの利用環境などの情報をユーザーのブラウザから自動的に受け取り、サーバーに記録します。
                            </li>
                            <li className={styles.item}>
                                (4)
                                当社は、提携先やその他の第三者から、ユーザーまたは第三者の個人情報や信用情報を含む通知を受け取ったり、規約違反行為の調査等のため、公開された個人情報を正当な手段により取得する場合があります。
                            </li>
                            <li className={styles.item}>
                                (5)
                                当社は、次の場合を除き、あらかじめユーザーの同意を得ないで、要配慮個人情報（個人情報保護法第2条第3項に定義されるものを意味します。）を取得しません。
                                <ol className={styles.smallItems}>
                                    <li className={styles.smallItem}>(a) 法令に基づく場合</li>
                                    <li className={styles.smallItem}>
                                        (b)
                                        人の生命、身体又は財産の保護のために必要がある場合であって、ユーザーの同意を得ることが困難である場合
                                    </li>
                                    <li className={styles.smallItem}>
                                        (c)
                                        公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、ユーザーの同意を得ることが困難である場合
                                    </li>
                                    <li className={styles.smallItem}>
                                        (d)
                                        国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
                                    </li>
                                    <li className={styles.smallItem}>
                                        (e)
                                        当該要配慮個人情報が、ユーザー、国の機関、地方公共団体、個人情報保護法第76条第1項各号に掲げる者その他個人情報保護委員会規則で定める者により公開されている場合
                                    </li>
                                    <li className={styles.smallItem}>
                                        (f)
                                        ユーザーを目視し、又は撮影することにより、その外形上明らかな要配慮個人情報を取得する場合
                                    </li>
                                    <li className={styles.smallItem}>
                                        (g)
                                        個人情報保護法上第三者提供にあたらないものとされる態様にて要配慮個人情報の提供を受けるとき
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>個人情報の利用</h3>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                (1)当社は個人情報を以下の場合に利用します。
                                <ol className={styles.smallItems}>
                                    <li className={styles.smallItem}>(a) ユーザーが本サービスを利用する場合</li>
                                    <li className={styles.smallItem}>
                                        (b)
                                        ユーザーが興味があると思われる情報を掲示するなど、各ユーザーに最適化した情報を本サービス上に表示する場合
                                    </li>
                                    <li className={styles.smallItem}>
                                        (c) 当社のサービスを向上させることを目的として、サービスの利用状況を分析する場合
                                    </li>
                                    <li className={styles.smallItem}>
                                        (d)
                                        ユーザーまたは第三者に本サービスについて説明するため、ユーザーの統計情報を用いる場合{" "}
                                    </li>
                                    <li className={styles.smallItem}>
                                        (e)
                                        ユーザーまたは第三者への連絡を行ったり、本人確認、規約違反行為の調査および対応、問い合わせ内容の調査など、個別の処理や業務に必要である場合
                                    </li>
                                    <li className={styles.smallItem}>(f) 当社の商品、サービス等のご案内のため</li>
                                    <li className={styles.smallItem}>
                                        (g)
                                        本サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
                                    </li>
                                    <li className={styles.smallItem}>
                                        (h) 本サービスに関する規約等の変更などを通知するため
                                    </li>
                                    <li className={styles.smallItem}>
                                        (i) 当社のサービスの改善、新サービスの開発等に役立てるため
                                    </li>
                                    <li className={styles.smallItem}>
                                        (j)
                                        当社のサービスに関連して、個人を識別できない形式に加工した統計データを作成するため
                                    </li>
                                    <li className={styles.smallItem}>(k) その他、上記利用目的に付随する目的のため </li>
                                </ol>
                            </li>
                            <li className={styles.item}>
                                (2) 当社は上記以外を目的として、個人情報を閲覧、利用することはありません。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>個人情報の開示</h3>
                        <section className={styles.description}></section>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                (1)
                                当社は、ユーザーご本人から個人情報の開示を求められた場合には、遅滞なくこれを開示します。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。
                            </li>
                            <li className={styles.item}>
                                (2)
                                当社は、先生である登録ユーザーがクラスの参加者を把握し、参加者に連絡等するため、クラスを予約したユーザーの登録ユーザー情報を先生であるユーザーに提供することができるものとし、ユーザーはこれを異議なく承諾いたします。
                            </li>
                            <li className={styles.item}>
                                (3)
                                当社は、受講料の決済のため、当社が決済代行を委託する者として予め登録ユーザーが知りうる状態に置いた者に、登録ユーザー情報を提供することができるものとし、登録ユーザーはこれを異議なく承諾いたします。
                            </li>
                            <li className={styles.item}>
                                (4)
                                前各項の場合のほか、当社は、以下の場合を除いて、個人情報を第三者に開示することはありません。
                                <ol className={styles.smallItems}>
                                    <li className={styles.smallItem}>
                                        (a) 個人情報の開示にユーザー本人の同意がある場合。
                                    </li>
                                    <li className={styles.smallItem}>
                                        (b)
                                        アクセスログの表示など本サービスの運営上必要であると当社がみなした場合。ただしこの場合に、登録ユーザー情報が開示されることはありません。
                                    </li>
                                    <li className={styles.smallItem}>
                                        (c)
                                        当社の業務提携先、広告主に対し、提携する業務の遂行に必要な最低限の個人情報を用いる場合。ただしこの場合、開示先の企業は提携する業務遂行の目的以外に、当社から開示された個人情報を利用することはできません。
                                    </li>
                                    <li className={styles.smallItem}>
                                        (d)
                                        令状に基づき開示を求められた場合や、特定電気通信役務提供者の損害賠償責任の制限及び発信者情報の開示に関する法律（プロバイダ責任制限法）に基づく発信者情報開示請求を受けた場合など、個人情報を開示すべき法的義務を当社が負っている場合。
                                    </li>
                                    <li className={styles.smallItem}>
                                        (e)
                                        ユーザーが利用規約に反したとする合理的な疑いがあり、当社の権利、財産やサービス等を保護するため、必要と認められる場合。
                                    </li>
                                    <li className={styles.smallItem}>
                                        (f)
                                        ユーザーまたは第三者の生命、身体および財産等に対する差し迫った危険性があり、緊急の必要性がある場合で、本人の同意を得ることが困難な場合。
                                    </li>
                                    <li className={styles.smallItem}>
                                        (g) その他法令等により個人情報を第三者に提供することが認められている場合。
                                    </li>
                                </ol>
                            </li>
                            <li className={styles.item}>
                                (5)
                                当社は、外国（個人情報保護法第24条に基づき個人情報保護委員会規則で指定される国を除きます。）にある第三者（個人情報保護法第24条に基づき個人情報保護委員会規則で指定される基準に適合する体制を整備している者を除きます。）に個人情報を提供する場合には、あらかじめ外国にある第三者への提供を認める旨のユーザーの同意を得るものとします。但し、個人情報保護法上、かかる同意が不要とされている場合はこの限りではありません。
                            </li>
                            <li className={styles.item}>
                                (6)
                                当社は、個人情報を第三者に提供したときは、個人情報保護法第25条に従い、記録の作成及び保存を行います。
                            </li>
                            <li className={styles.item}>
                                (7)
                                当社は、第三者から個人情報の提供を受ける場合には、個人情報保護法第26条に従い、必要な確認を行い、当該確認にかかる記録の作成及び保存を行うものとします。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>匿名加工情報の取扱い</h3>
                        <section className={styles.description}></section>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                (1)
                                当社は、匿名加工情報（個人情報保護法第2条第9項に定めるものを意味し、同法第2条第10項に定める匿名加工情報データベース等を構成するものに限ります。以下同じ。）を作成するときは、個人情報保護委員会規則で定める基準に従い、個人情報を加工するものとします。
                            </li>
                            <li className={styles.item}>
                                (2)
                                当社は、匿名加工情報を作成したときは、個人情報保護委員会規則で定める基準に従い、安全管理のための措置を講じます。
                            </li>
                            <li className={styles.item}>
                                (3)
                                当社は、匿名加工情報を作成したときは、個人情報保護委員会規則で定めるところにより、当該匿名加工情報に含まれる個人に関する情報の項目を公表します。
                            </li>
                            <li className={styles.item}>
                                (4)
                                当社は、匿名加工情報（当社が作成したもの及び第三者から提供を受けたものを含みます。以下別段の定めがない限り同様とします。）を第三者に提供するときは、個人情報保護委員会規則で定めるところにより、あらかじめ、
                                第三者に提供される匿名加工情報に含まれる個人に関する情報の項目及びその提供の方法について公表するとともに、当該第三者に対して、当該提供に係る情報が匿名加工情報である旨を明示します。
                            </li>
                            <li className={styles.item}>
                                (5)
                                当社は、匿名加工情報を取り扱うに当たっては、匿名加工情報の作成に用いられた個人情報に係るユーザーを識別するために、(i)匿名加工情報を他の情報と照合すること、及び(ii)当該個人情報から削除された記述等若しくは個人識別符号又は個人情報保護法第36条第1項の規定により行われた加工の方法に関する情報を取得すること（(ii)は第三者から提供を受けた当該匿名加工情報についてのみ）を行わないものとします。
                            </li>
                            <li className={styles.item}>
                                (6)
                                当社は、匿名加工情報の安全管理のために必要かつ適切な措置、匿名加工情報の作成その他の取扱いに関する苦情の処理その他の匿名加工情報の適正な取扱いを確保するために
                                必要な措置を自ら講じ、かつ、当該措置の内容を公表するよう努めるものとします。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>個人情報の削除</h3>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                (1)
                                当社は、登録ユーザーがご自身の登録ユーザー情報（以下「登録ユーザー個人情報」）について、削除できるようにしています。ご自身の個人情報を削除するには
                                support@trail-official.com
                                までお問い合わせください。但し、個人情報保護法その他の法令により、当社が削除の義務を負わない場合は、この限りではありません。
                            </li>
                            <li className={styles.item}>
                                (2)
                                削除処理を行うと、登録ユーザー個人情報は一定期間保存された後、削除されます。当社は削除処理前の登録ユーザー個人情報をバックアップとして一定期間保存しますが、これは操作ミスの際にユーザーの不利益を防止したり、規約違反情報を投稿してすぐに退会するなどの不正行為が発覚した際に、調査を行うことなどを目的とするものです。削除処理後の登録ユーザー個人情報が本サービスに利用されることはありません。
                            </li>
                            <li className={styles.item}>
                                (3)
                                講座を開講し、または講座への参加手続きを完了した登録ユーザーの登録ユーザー個人情報について、第１項に基づき削除の手続きをとられた場合、前項の規定にかかわらず、講座が開催され終了するまでの間、登録ユーザー個人情報は本サービスのために利用されることがあるものとし、登録ユーザーはこれに異議を申し述べないものとします。また、当社はサービス運営上の混乱を防止する目的でユーザー登録の抹消後もユーザー氏名を保有します。
                            </li>
                            <li className={styles.item}>
                                (4)
                                第２項にかかわらず、先生であるユーザーの登録ユーザー個人情報の削除については、別に定めるところによるものとします。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>個人情報の保護、管理</h3>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                (1)
                                当社は、登録ユーザー個人情報について、当社担当職員以外による参照を禁じています。また、登録ユーザー個人情報は安全な場所に保管されており、当社が管理する区画の外に持ち出されることはありません。
                            </li>
                            <li className={styles.item}>
                                (2)
                                当社は、当社職員と秘密保持契約を締結し、当社の定めるセキュリティポリシーの遵守を義務付けております。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>クッキー(Cookie)</h3>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                (1)
                                当社はユニークユーザー数の測定、およびユーザー認証のためクッキーを使用しています。クッキーの仕組みは以下の通りです。
                                <ol className={styles.smallItems}>
                                    <li className={styles.smallItem}>
                                        (a)
                                        ユーザーが本サービスを閲覧する際、当社はユーザーのブラウザに対してユーザーを識別するためのクッキーを送信します。
                                    </li>
                                    <li className={styles.smallItem}>
                                        (b)
                                        ユーザーがブラウザの設定でクッキーの受信を許可している場合、ユーザーが使用しているコンピュータ内にクッキー情報が保管されます。
                                    </li>
                                    <li className={styles.smallItem}>
                                        (c)
                                        ユーザーがブラウザの設定でクッキーの送信を許可している場合、ウェブサーバーはユーザーのブラウザからクッキー情報を受け取ります。
                                        ただしユーザーのブラウザはプライバシー保護のため、そのウェブサイトのサーバー自身が送信したクッキーのみ送信を行います。
                                    </li>
                                    <li className={styles.smallItem}>
                                        (d)
                                        ユーザーはクッキーの送受信に関する設定で、「クッキーの送受信を拒否する」といった選択を行うことでクッキーの送受信を拒否することができます。この場合、本サービス内で、クッキーが必要なサービスを利用することができなくなります。
                                    </li>
                                </ol>
                            </li>
                            <li className={styles.item}>
                                (2)
                                本サービス上に表示された第三者の広告によりクッキーが使用される場合があります。この場合、広告主によるクッキーの利用は、広告主のプライバシーの考え方に従って行われます。
                            </li>
                            <li className={styles.item}>
                                (3) 本サービスはクッキーを活用したGoogle アナリティクス
                                リマーケティングを使用して、オンライン広告を掲載しております。
                                <ol className={styles.smallItems}>
                                    <li className={styles.smallItem}>
                                        (a) Google
                                        を含む第三者配信事業者が、インターネット上のさまざまなサイトで本サービスの広告を掲載しております。
                                    </li>
                                    <li className={styles.smallItem}>
                                        (b) Google
                                        を含む第三者配信事業者がファーストパーティ・クッキーとサードパーティ・クッキーを組み合わせて、過去にユーザーがサイトにアクセスしたときの行動に基づいて広告を作成、最適化、配信しております。
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>第三者のトラッキングシステム</h3>
                        <section className={styles.description}>
                            当サイトでは、利用者の動向を調査する為に第三者によるクッキー、画像ファイル（ウェブビーコン）などを用い、統計的なサイト利用情報を取得しています。
                        </section>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>当ポリシーが適用される範囲</h3>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                (1)
                                ユーザーが本サービス上で自発的に個人情報を開示した場合、その情報は第三者によって収集されたり悪用されたりする恐れがあります。この場合、当社は一切責任を負いません。
                            </li>
                            <li className={styles.item}>
                                (2)
                                本サービス内で特に定める場合を除き、本サービス上からリンクされた当社の別サイトまたは第三者のサイトおよびサービスは、当プライバシーポリシーとは別の独立した個人情報の保護に関する規程を定めています。当社は第三者のサイトやサービスにおけるこれらの規定や活動についていかなる義務や責任も負いかねます。個人情報の提供にあたっては、各サイトの定める規約等を事前にご確認ください。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>プライバシーポリシーの改定</h3>
                        <section className={styles.description}>
                            当社は当プライバシーポリシーの全部または一部を改定することがあります。重要な変更がある場合には、本サービス上などで分かりやすい方法でお知らせします。この場合、ユーザーの個人情報は、改定後のプライバシーポリシーに従って取り扱われることになります。
                        </section>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>個人情報管理責任者 </h3>
                        <section className={styles.description}>当社の個人情報管理責任者は下記の通りです。</section>
                        <ol className={styles.items}>
                            <li className={styles.item}>株式会社オノレ 代表取締役社長 石川頌悟</li>
                        </ol>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>プライバシーに関する意見・苦情・異議申立て</h3>
                        <section className={styles.description}>
                            プライバシーに関する意見・苦情・異議申立て等がございましたら、information@trail-official.comまでお問い合わせください。内容を確認し、折り返しメールでのご連絡をした後、適切な処理ができるよう努めます。
                        </section>
                    </section>
                    <section className={styles.dateWrapper}>
                        <div className={styles.date}>2024年4月13日制定</div>
                    </section>
                </section>
                <Button component={Link} to={userMode === "teacher" ? "/" : "/"} className={styles.toHomeButton}>
                    ホームに戻る
                </Button>
            </div>
        </>
    );
};
