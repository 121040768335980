import { memo, useCallback, useState } from "react";

import { Optional } from "@/components/Tag/Optional";
import { FileInfo, TrimmingModal } from "@/components/TrimmingModal";
import styles from "@/pages/Student/MyPage/index.module.scss";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    checked: boolean;
    edit: boolean;
    fileInfo: FileInfo | undefined;
    iconImageUrl?: string;
    iconImageObjectKey?: string;
    courseLength: number;
    setFileInfo: React.Dispatch<React.SetStateAction<FileInfo>>;
}

export const ImageWrapper: React.VFC<Props> = memo(function ImageWrapper(props) {
    const [trimmingModalOpen, setTrimmingModalOpen] = useState(false);
    const [targetFile, setTargetFile] = useState<File | undefined>(undefined);
    const selectImg = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            setTargetFile(file);
            handleTrimmingModalOpen();
        }
    }, []);
    const handleTrimmingModalClose = useCallback(() => {
        setTrimmingModalOpen(false);
    }, []);
    const handleTrimmingModalOpen = useCallback(() => {
        setTrimmingModalOpen(true);
    }, []);
    console.log(props.fileInfo);
    return (
        <>
            {props.edit ? (
                <li className={styles.editListItem}>
                    <div className={styles.leftWrapper}>
                        <div className={styles.titleWrapper}>
                            <div className={styles.title}>アイコン画像</div>
                            <Optional />
                        </div>
                    </div>
                    <div className={styles.rightWrapper}>
                        <label className={styles.photoLabel}>
                            <AvatarFromS3 url={props.fileInfo?.url} className={styles.avatar} />
                            <input type="file" accept="image/*" onChange={selectImg} className={styles.photoInput} />
                        </label>
                        <TrimmingModal
                            open={trimmingModalOpen}
                            handleClose={handleTrimmingModalClose}
                            setFileInfo={props.setFileInfo}
                            targetFile={targetFile}
                            aspect={1 / 1}
                            shape="round"
                        />
                    </div>
                </li>
            ) : (
                <div className={styles.imageWrapper}>
                    <AvatarFromS3
                        url={props.iconImageUrl}
                        objectKey={props.iconImageObjectKey}
                        className={styles.avatar}
                    />
                    <div className={styles.howMany}>受講回数：{props.courseLength}回</div>
                </div>
            )}
        </>
    );
});
