import { Hidden } from "@material-ui/core";
import { memo } from "react";
import { PCSearchBySubject } from "./PCSearchBySubject";
import { SPSearchBySubject } from "./SPSearchBySubject";

interface Props {
    mode: "student" | "teacher";
}

export const SearchBySubject: React.VFC<Props> = memo(function SearchBySubject(props) {
    return (
        <>
            <Hidden smDown>
                <PCSearchBySubject mode={props.mode} />
            </Hidden>
            <Hidden mdUp>
                <SPSearchBySubject mode={props.mode} />
            </Hidden>
        </>
    );
});
