import { memo, useCallback, useState } from "react";

import { Avatar } from "@material-ui/core";

import { FileInfo, TrimmingModal } from "@/components/TrimmingModal";
import styles from "@/pages/Teacher/InfoInput/index.module.scss";
import { Optional } from "@/components/Tag/Optional";

interface Props {
    setFileInfo?: React.Dispatch<React.SetStateAction<FileInfo>>;
    confirmModalOpen?: boolean;
    iconImageFileInfo: FileInfo | undefined;
    checked: boolean;
}

export const IconImage: React.VFC<Props> = memo(function IconImage(props) {
    const [trimmingModalOpen, setTrimmingModalOpen] = useState(false);
    const [targetFile, setTargetFile] = useState<File | undefined>(undefined);
    const selectImg = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            setTargetFile(file);
            handleTrimmingModalOpen();
        }
    }, []);
    const handleTrimmingModalClose = useCallback(() => {
        setTrimmingModalOpen(false);
    }, []);
    const handleTrimmingModalOpen = useCallback(() => {
        setTrimmingModalOpen(true);
    }, []);
    return (
        <div className={styles.inputItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitleWrapper}>
                    <span className={styles.itemTitle}>アイコン用画像</span>
                </div>
                {!props.confirmModalOpen && <Optional />}
            </div>
            <div className={styles.rightWrapper}>
                {props.confirmModalOpen ? (
                    <div className={styles.openTrue}>
                        <Avatar src={props.iconImageFileInfo?.url} className={styles.avatar} />
                    </div>
                ) : (
                    <>
                        <label className={styles.iconImageLabel}>
                            <Avatar src={props.iconImageFileInfo?.url} className={styles.avatar} />
                            <input
                                type="file"
                                accept="image/*"
                                onChange={selectImg}
                                onClick={(e) => {
                                    e.currentTarget.value = "";
                                }}
                                className={styles.photoInput}
                            />
                        </label>
                        <TrimmingModal
                            open={trimmingModalOpen}
                            handleClose={handleTrimmingModalClose}
                            setFileInfo={props.setFileInfo}
                            targetFile={targetFile}
                            aspect={1 / 1}
                            shape="round"
                        />
                        {props.iconImageFileInfo && (
                            <div className={styles.notice}>
                                ※ 画像をアップロードしない場合、こちらのデフォルト画像が適用されます。
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
});
