import { memo } from "react";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { LessonResponse, ZoomAccountStatus } from "@/store/autogenApi";
import { useGetCourseForUpdatingCourseQuery } from "@/store/hooks/courses";
import { CommonComponent } from "./Common";

interface Props {
    currentCourseId: string;
    zoomAccountStatus: ZoomAccountStatus;
    existingLessons: LessonResponse[];
    isCompleted: boolean;
    isProcessing: boolean;
    handleIsCompletedChange: (isCompleted: boolean) => void;
    handleIsTemporarySavedChange: (isTemporarySaved: boolean) => void;
    handleHasErrorChange: (hasError: boolean) => void;
    handleNewCourseIdChange: (newCourseId: string | undefined) => void;
    handleIsProcessingChange: (isProcessing: boolean) => void;
}

export const UpdateComponent: React.VFC<Props> = memo(function UpdateComponent(props) {
    const courseQueryState = useGetCourseForUpdatingCourseQuery(props.currentCourseId);

    return (
        <QueryLoadingWrapper {...courseQueryState}>
            {(course) => (
                <CommonComponent
                    existingCourse={course}
                    zoomAccountStatus={props.zoomAccountStatus}
                    existingLessons={props.existingLessons}
                    isCompleted={props.isCompleted}
                    isProcessing={props.isProcessing}
                    handleIsCompletedChange={props.handleIsCompletedChange}
                    handleIsTemporarySavedChange={props.handleIsTemporarySavedChange}
                    handleHasErrorChange={props.handleHasErrorChange}
                    handleNewCourseIdChange={props.handleNewCourseIdChange}
                    handleIsProcessingChange={props.handleIsProcessingChange}
                />
            )}
        </QueryLoadingWrapper>
    );
});
