import {
    CourseResponse,
    CourseType,
    GetTeacherSearchResultsApiArg,
    NominalTypingAnnouncementString,
    PrivateTeacherResponse,
    PublicTeacherResponse,
    PublicRequestResponse,
} from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions } from "./utils/types";

export const useGetPublicTeacherQuery = (id: string) => {
    const result = defaultApi.endpoints.getPublicTeacher.useQuery({ teacherId: id });
    return useQueryResult(result, (response: PublicTeacherResponse) => response);
};

export const useGetPrivateTeacherQuery = (id: string) => {
    const result = defaultApi.endpoints.getPrivateTeacher.useQuery({ teacherId: id });
    return useQueryResult(result, (response: PrivateTeacherResponse) => response);
};

export const useGetRequestBrowsingHistoryQuery = (teacherId: string, courseType?: CourseType) => {
    const result = defaultApi.endpoints.getPublicRequestBrowsingHistory.useQuery({ teacherId, courseType });
    return useQueryResult(result, (response: PublicRequestResponse[]) => response);
};

export const useGetAllFavoriteRequestsQuery = (id: string) => {
    const result = defaultApi.endpoints.getAllFavoritePublicRequests.useQuery({ teacherId: id });
    return useQueryResult(result, (response: PublicRequestResponse[]) => response);
};

export const useGetFavoriteRequestsQuery = (teacherId: string, courseType: CourseType) => {
    const result = defaultApi.endpoints.getFavoritePublicRequests.useQuery({ teacherId, courseType });
    return useQueryResult(result, (response: PublicRequestResponse[]) => response);
};

export const useGetActiveCoursesQuery = (teacherId: string) => {
    const result = defaultApi.endpoints.getCoursesOfTargetTeacher.useQuery({ teacherId: teacherId });
    return useQueryResult(result, (response: CourseResponse[]) => response);
};

export const useCreateTeacherMutation = (options?: UseMutationOptions) => {
    const [createTeacher] = defaultApi.endpoints.createTeacher.useMutation();
    return useMutationFunctionWrapper(createTeacher, options?.errorMessages);
};

export const useLinkZoomAccountMutation = (options?: UseMutationOptions) => {
    const [linkZoomAccount] = defaultApi.endpoints.linkZoomAccount.useMutation();
    return useMutationFunctionWrapper(linkZoomAccount, options?.errorMessages);
};

export const useUpdateTeacherMutation = (options?: UseMutationOptions) => {
    const [updateTeacher] = defaultApi.endpoints.updateTeacher.useMutation();
    return useMutationFunctionWrapper(updateTeacher, options?.errorMessages);
};

export const useUpdateRequestBrowsingHistoryMutation = (options?: UseMutationOptions) => {
    const [updateRequestBrowsingHistory] = defaultApi.endpoints.updatePublicRequestBrowsingHistory.useMutation();
    return useMutationFunctionWrapper(updateRequestBrowsingHistory, options?.errorMessages);
};

export const useUpdateFavoriteRequestsMutation = (options?: UseMutationOptions) => {
    const [updateFavoriteRequests] = defaultApi.endpoints.updateFavoritePublicRequests.useMutation();
    return useMutationFunctionWrapper(updateFavoriteRequests, options?.errorMessages);
};

export const useDeleteTeacherMutation = (options?: UseMutationOptions) => {
    const [deleteTeacher] = defaultApi.endpoints.deleteTeacher.useMutation();
    return useMutationFunctionWrapper(deleteTeacher, options?.errorMessages);
};

export const useGetTeacherSearchResultsQuery = (args: GetTeacherSearchResultsApiArg) => {
    const result = defaultApi.endpoints.getTeacherSearchResults.useQuery(args);
    return useQueryResult(result, (response: PublicTeacherResponse[]) => response);
};

export const useGetLatestTeachersQuery = () => {
    const result = defaultApi.endpoints.getLatestTeachers.useQuery();
    return useQueryResult(result, (response: PublicTeacherResponse[]) => response);
};

export const useGetTeacherCheckedAnnouncementIdsQuery = (teacherId: string) => {
    const result = defaultApi.endpoints.getTeacherCheckedAnnouncementIds.useQuery({
        teacherId: teacherId,
    });
    return useQueryResult(result, (response: NominalTypingAnnouncementString[]) => response);
};

export const useUpdateTeacherCheckedAnnouncementsMutation = (options?: UseMutationOptions) => {
    const [updateTeacherCheckedAnnouncements] = defaultApi.endpoints.updateTeacherCheckedAnnouncements.useMutation();
    return useMutationFunctionWrapper(updateTeacherCheckedAnnouncements, options?.errorMessages);
};
