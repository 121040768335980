import { memo, useCallback, useEffect, useState } from "react";

import { Button } from "@material-ui/core";
import { MdFace } from "react-icons/md";

import { StudentInfoModal } from "@/components/StudentInfoModal";
import styles from "@/pages/Common/PublicRequestDetails/index.module.scss";
import { PublicStudentResponse } from "@/store/autogenApi";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    student?: PublicStudentResponse;
    studentInfoModalOpen: boolean;
    setStudentInfoModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const StudentInfoComponent: React.VFC<Props> = memo(function StudentInfoComponent(props) {
    const [modalHeight, setModalHeight] = useState<number>(0);

    useEffect(() => {
        changeModalHeight();
        window.addEventListener("resize", () => {
            changeModalHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalHeight();
            });
        };
    }, []);

    const changeModalHeight = useCallback(() => {
        const innerHeight = window.innerHeight;
        setModalHeight(innerHeight * 0.75);
    }, []);

    const handleClose = useCallback(() => {
        props.setStudentInfoModalOpen(false);
    }, []);

    return (
        <div className={styles.detailWrapper}>
            <div className={styles.detailHeadingWrapper}>
                <span className={styles.detailHeading}>生徒情報</span>
            </div>
            <div className={styles.detail} id="detail">
                <Button
                    className={styles.studentInfoButton}
                    onClick={() => {
                        props.setStudentInfoModalOpen(true);
                    }}
                >
                    <AvatarFromS3
                        url={props.student?.iconImageUrl}
                        objectKey={props.student?.iconImageObjectKey}
                        className={styles.avatar}
                    />
                    <div className={styles.studentNickName}>{props.student?.nickname}さん</div>
                </Button>
                {props.student && (
                    <StudentInfoModal
                        open={props.studentInfoModalOpen}
                        handleStudentInfoModalClose={handleClose}
                        student={props.student}
                    />
                )}
            </div>
        </div>
    );
});
