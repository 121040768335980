import { Button } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { RootState } from "@/ducks";
import { UpdateFavoriteCoursesRequestBody } from "@/store/autogenApi";
import { useUpdateFavoriteCoursesMutation } from "@/store/hooks/students";

import styles from "./index.module.scss";

interface Props {
    isSPVersion?: boolean;
    isSimplified?: boolean;
    userMode: string;
    isFavorite: boolean;
    favoriteCount: number;
    isReservationButtonClicked: boolean;
    handleSignUpModalOpen: () => void;
    initializeReservationButtonClicked: () => void;
    handleFavoriteButtonClick: () => void;
}

export const FavoriteContents: React.VFC<Props> = memo(function FavoriteContents(props) {
    const [favoriteCount, setFavoriteCount] = useState<number>(0);

    useEffect(() => {
        setFavoriteCount(props.favoriteCount);
    }, [props.favoriteCount]);

    useEffect(() => {
        props.initializeReservationButtonClicked();
        if (props.isReservationButtonClicked && !props.isFavorite) {
            props.handleFavoriteButtonClick();
        }
    }, [props.isReservationButtonClicked, props.isFavorite, props.initializeReservationButtonClicked]);

    return (
        <>
            {props.isSPVersion ? (
                <>
                    {props.isSimplified ? (
                        <div className={styles.simplifiedFavoriteWrapper}>
                            <Button className={styles.favoriteButton} onClick={props.handleFavoriteButtonClick}>
                                {props.isFavorite ? (
                                    <FavoriteIcon className={styles.favoriteIcon} />
                                ) : (
                                    <FavoriteBorderIcon className={styles.favoriteBorderIcon} />
                                )}
                            </Button>
                            <div className={styles.favoriteCount}>({favoriteCount})</div>
                        </div>
                    ) : (
                        <div className={styles.SPNormalFavoriteWrapper}>
                            <Button
                                className={styles.favoriteButton}
                                onClick={props.handleFavoriteButtonClick}
                                style={{
                                    borderColor: props.isFavorite ? "#F50057" : "#999",
                                }}
                            >
                                <FavoriteIcon
                                    className={styles.icon}
                                    style={{ color: props.isFavorite ? "#F50057" : "#999" }}
                                />
                                <div
                                    className={styles.favorite}
                                    style={{ color: props.isFavorite ? "#F50057" : "#999" }}
                                >
                                    気になる！
                                </div>
                            </Button>
                            <div className={styles.favoriteCount}>
                                ({favoriteCount}人が「気になる！」登録をしています。)
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div className={styles.PCFavoriteWrapper}>
                    {props.userMode !== "teacher" && (
                        <Button
                            className={styles.favoriteButton}
                            variant="contained"
                            onClick={props.handleFavoriteButtonClick}
                            style={{
                                borderColor: props.isFavorite ? "#F50057" : "#CCC",
                            }}
                        >
                            <FavoriteIcon
                                className={styles.icon}
                                style={{ color: props.isFavorite ? "#F50057" : "#CCC" }}
                            />
                            <div className={styles.favorite} style={{ color: props.isFavorite ? "#F50057" : "#CCC" }}>
                                気になる！
                            </div>
                        </Button>
                    )}
                    <div className={styles.theNumberOfSignUp}>{favoriteCount}人が「気になる！」登録をしています</div>
                </div>
            )}
        </>
    );
});
