import { useSelector } from "react-redux";
import { RootState } from "@/ducks";

import { useGetStudentToDosByStudentIdQuery } from "@/store/hooks/studentToDos";
import { QueryLoadingWrapper } from "../../../QueryLoadingWrapper";

import { StudentToDoListContents } from "./StudentToDoListContents";

import { ToDosTooltip } from "../ToDosTooltip";

export const StudentToDoList: React.VFC = () => {
    const studentId = useSelector((state: RootState) => state.jwt.studentId) as string;
    const studentToDosQuery = useGetStudentToDosByStudentIdQuery(studentId);

    return (
        <QueryLoadingWrapper
            {...studentToDosQuery}
            loadingComponent={
                <ToDosTooltip
                    open={false}
                    title={<></>}
                    badgeContent={0}
                    handleCheckButtonClick={() => {}}
                    handleTooltipOpen={() => {}}
                    handleTooltipClose={() => {}}
                />
            }
        >
            {(studentToDos) => <StudentToDoListContents studentToDos={studentToDos} />}
        </QueryLoadingWrapper>
    );
};
