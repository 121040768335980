import { Modal, useMediaQuery } from "@material-ui/core";
import { memo, useCallback } from "react";
import styles from "./index.module.scss";
import { PublicRequestResponse } from "@/store/autogenApi";
import { NavyButton } from "@/components/Buttons/NavyButton";
import { useHistory } from "react-router";
import { getSubject } from "@/utils/SubjectUtils";
import { getCourseType, getPrice, getStatus } from "@/utils/PublicRequestUtils";
import { getTime } from "@/utils/DateUtils";

interface Props {
    targetPublicRequest: PublicRequestResponse;
    handlePublicRequestDetailsModalClose: () => void;
}

export const PublicRequestDetailsModal: React.VFC<Props> = memo(function PublicRequestDetailsModal(props: Props) {
    const history = useHistory();

    const mdUp = useMediaQuery("(min-width:960px)");

    const handlePublicRequestDetailsButtonClick = useCallback(() => {
        props.handlePublicRequestDetailsModalClose();
        history.push(`/PublicRequestDetails/${props.targetPublicRequest.publicRequestId}`);
    }, [props.targetPublicRequest]);

    return (
        <Modal open={true} onClose={props.handlePublicRequestDetailsModalClose}>
            <div className={styles.courseDetailsModalContents}>
                <div className={styles.topFlex}>
                    <div className={styles.flexRightWrapper}>
                        <div className={styles.topCourseContents}>
                            <div className={styles.courseType}>
                                {getCourseType(props.targetPublicRequest as PublicRequestResponse)}
                            </div>
                        </div>
                        <div className={styles.courseDetailsTitle}>{props.targetPublicRequest.title}</div>
                        <div className={styles.subjects}>
                            {props.targetPublicRequest.subjects.map((subject) => (
                                <div className={styles.subject}>{getSubject(subject)}</div>
                            ))}
                        </div>
                        <div className={styles.applyingDeadlineWrapper}>
                            <div className={styles.applyingDeadlineTitle}>募集締切：</div>
                            <div className={styles.applyingDeadline}>
                                {getTime(props.targetPublicRequest.applyingDeadline)} (
                                {getStatus(props.targetPublicRequest)})
                            </div>
                        </div>
                        <div className={styles.price}>希望価格：{getPrice(props.targetPublicRequest)}/時間</div>
                        <div className={styles.courseDescription}>
                            {props.targetPublicRequest.publicRequestDescription}
                        </div>
                    </div>
                </div>
                <div className={styles.buttonsWrapper}>
                    <NavyButton handleClick={handlePublicRequestDetailsButtonClick} fontSize={mdUp ? 15 : 14}>
                        詳細
                    </NavyButton>
                </div>
            </div>
        </Modal>
    );
});
