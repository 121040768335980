import { FAQDetailsBase } from "../../FAQDetailsBase";

export const Receipt: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="領収書を発行してほしい"
            text="領収書は入金時に自動的に発行され、登録したメールアドレスに送信されます。受信したメールをご確認ください。"
        />
    );
};
