import { ChatResponse } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useQueryResult } from "./utils/parseResult";

export const useGetStudentChatsQueryState = (studentId: string) => {
    const result = defaultApi.endpoints.getStudentChats.useQuery({ studentId });
    return useQueryResult(result, (response: ChatResponse[]) => response);
};

export const useGetTeacherChatsQueryState = (teacherId: string) => {
    const result = defaultApi.endpoints.getTeacherChats.useQuery({ teacherId });
    return useQueryResult(result, (response: ChatResponse[]) => response);
};

export const useGetIndividualChatQueryState = (studentId: string, teacherId: string) => {
    const result = defaultApi.endpoints.getIndividualChat.useQuery({ studentId, teacherId });
    return useQueryResult(result, (response: ChatResponse) => response);
};

export const useGetGroupChatQueryState = (chatId: string) => {
    const result = defaultApi.endpoints.getGroupChat.useQuery({ chatId });
    return useQueryResult(result, (response: ChatResponse) => response);
};
