import { FAQDetailsBase } from "../../FAQDetailsBase";

import { CANCEL_HOLDING_COURSE_CONFIGS } from "./CancelCourseConfigs";

export const CancelHoldingCourse: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="講座の開催をキャンセルする"
            text="このページでは、講座の開催をキャンセルする方法についてご説明します。講座の開催をキャンセルするには以下の手順で行うことができます。"
            FAQConfigs={CANCEL_HOLDING_COURSE_CONFIGS}
        />
    );
};
