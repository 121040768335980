import { memo } from "react";
import { ListItem } from "./ListItem";
import { getOnlyDate } from "@/utils/DateUtils";

interface Props {
    startDate: Date | undefined;
}

export const StartDate: React.VFC<Props> = memo(function StartDate(props) {
    return (
        <ListItem title="開始日">
            <>{getOnlyDate(props.startDate)}</>
        </ListItem>
    );
});
