import { memo, useEffect, useState } from "react";

import { ClassResponse, StudentToDoResponse } from "@/store/autogenApi";

import styles from "@/pages/Student/Class/index.module.scss";

import { MainContents } from "./MainContents";
import { ManagementTopContents } from "./ManagementTopContents";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { useNavigation } from "@/components/Navigation/NavigationContext";

interface Props {
    class: ClassResponse;
}

export const ClassContents: React.VFC<Props> = memo(function ShortCourseContents(props) {
    const [targetStudentToDo, setTargetStudentToDo] = useState<StudentToDoResponse | undefined>(undefined);
    const [disableToJoin, setDisableToJoin] = useState<boolean>(false);

    const studentId = useSelector((state: RootState) => state.jwt.studentId);

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        if (!props.class.course) return;
        const { title, courseType } = props.class.course;
        if (!title) return;
        const navigationList = [
            { title: "予約済の講座一覧", url: `/MyPage/ReservedCourses/?courseType=${courseType}` },
            {
                title: title,
                url: `/Class/${props.class.course?.courseId}`,
            },
        ];
        updateNavigationList(navigationList);
    }, [props.class]);

    useEffect(() => {
        const studentToDo = props.class.studentToDos.find(
            (studentToDo) => !studentToDo.isCompleted && !studentToDo.isCanceled && studentToDo.studentId === studentId,
        );
        if (!studentToDo) return;
        const modifiedStudentToDo = { ...studentToDo, class: props.class };
        setTargetStudentToDo(modifiedStudentToDo);
        if (studentToDo.toDoType === "payForCourse") {
            setDisableToJoin(true);
        }
    }, [props.class, studentId]);

    return (
        <div className={styles.courseManagementContents}>
            <ManagementTopContents
                class={props.class}
                targetStudentToDo={targetStudentToDo}
                disableToJoin={disableToJoin}
            />
            <MainContents class={props.class} disableToJoin={disableToJoin} />
        </div>
    );
});
