import styles from "./index.module.scss";
import { memo, useCallback, useState } from "react";
import { ANALYTICS_SERVICES, ExternalService } from "./externalService";
import { OverWrapper } from "@/components/OverWrapper";
import { InfoIcon, LinkIcon } from "@/globalIcons";
import { StyledModal } from "@/components/StyledModal";

interface Props {
    servicesBlockTitle: string;
    servicesBlockDescription: string;
    services: ExternalService[];
}

export const ServicesBlock: React.VFC<Props> = memo(function ServicesBlock(props) {
    const [modalOpenIdx, setModalOpenIdx] = useState<number | undefined>(undefined);
    const handleModalOpen = useCallback((idx: number) => {
        setModalOpenIdx(idx);
    }, []);
    const handleModalClose = useCallback(() => {
        setModalOpenIdx(undefined);
    }, []);
    return (
        <div className={styles.servicesBlock}>
            <div className={styles.servicesBlockTitle}>{props.servicesBlockTitle}</div>
            <div className={styles.servicesBlockDescription}>{props.servicesBlockDescription}</div>
            <table className={styles.servicesTable}>
                <tr>
                    <th className={styles.idx}>#</th>
                    <th>サービス名</th>
                    <th>送信先</th>
                    <th>送信される情報の例</th>
                    <th>プライバシーポリシー</th>
                    <th>オプトアウト</th>
                </tr>
                {props.services.map((service, idx) => (
                    <tr key={service.serviceName}>
                        <td className={styles.idx}>{idx}</td>
                        <td>{service.serviceName}</td>
                        <td>
                            <a href={service.destinationLink}>{service.destinationName}</a>
                        </td>
                        <td>
                            <button className={styles.infoButton} onClick={() => handleModalOpen(idx)}>
                                <InfoIcon className={styles.icon} />
                            </button>
                            <StyledModal
                                open={modalOpenIdx === idx}
                                onClose={handleModalClose}
                                style={{ width: "fit-content" }}
                            >
                                <div className={styles.infoExamplesModalContents}>
                                    <div className={styles.infoExamplesTitle}>{service.serviceName}</div>
                                    <ul className={styles.infoExamples}>
                                        {service.infoExamples.map((infoExample) => (
                                            <li className={styles.infoExample}>{infoExample}</li>
                                        ))}
                                    </ul>
                                </div>
                            </StyledModal>
                        </td>
                        <td>
                            <a href={service.privacyPolicy} rel="noopener noreferrer">
                                <LinkIcon className={styles.icon} />
                            </a>
                        </td>
                        <td>
                            <a href={service.optOutNormalLink} rel="noopener noreferrer">
                                <LinkIcon className={styles.icon} />
                            </a>
                        </td>
                    </tr>
                ))}
            </table>
        </div>
    );
});
