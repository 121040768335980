import { useParams } from "react-router";
import React from "react";

import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { useGetCourseForCourseManagementQuery } from "@/store/hooks/courses";

import { memo } from "react";
import { CourseManagementContents } from "@/pageComponents/Teacher/CourseManagement";

export const CourseManagement = memo(function CourseManagement() {
    const { courseId } = useParams<{ courseId: string; classId?: string }>();

    const courseQueryState = useGetCourseForCourseManagementQuery(courseId);

    return (
        <QueryLoadingWrapper {...courseQueryState}>
            {(course) => <CourseManagementContents course={course} />}
        </QueryLoadingWrapper>
    );
});
