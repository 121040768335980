import { useEffect, useRef } from "react";

// ※ React Router v6
import { useLocation } from "react-router-dom";
import * as H from "history";

const useLocationChange = (callback: (location: H.Location) => void) => {
    const refCallback = useRef<undefined | ((location: H.Location) => void)>();
    const location = useLocation();

    useEffect(() => {
        refCallback.current = callback;
    }, [callback]);

    // ロケーションに変更があったときに処理実行
    useEffect(() => {
        if (refCallback.current) {
            refCallback.current(location);
        }
    }, [location]);
};

export default useLocationChange;
