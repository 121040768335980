import { useSelector } from "react-redux";
import { RootState } from "@/ducks";

import { StudentTopPage } from "./StudentTopPage";
import { TeacherTopPage } from "./TeacherTopPage";
import { memo, useEffect, useState } from "react";

export const TopPage: React.VFC = memo(function TopPage() {
    const [viewMode, setViewMode] = useState<"student" | "teacher">("student");
    const userMode = useSelector((state: RootState) => state.jwt.userMode);

    useEffect(() => {
        if (userMode === "teacher") {
            setViewMode("teacher");
            return;
        }
        //ローカルストレージに保存されているfromを取得
        const from = localStorage.getItem("from");
        if (from === "teacherPromo") {
            setViewMode("teacher");
            return;
        }
    }, [userMode]);

    return <>{viewMode === "teacher" ? <TeacherTopPage /> : <StudentTopPage />}</>;
});
