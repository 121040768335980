import { Hidden } from "@material-ui/core";
import { BiDownArrowAlt, BiRightArrowAlt } from "react-icons/bi";

import BronzeBadge from "@/images/B.png";
import GoldBadge from "@/images/G.png";
import PlatinumBadge from "@/images/P.png";
import RegularBadge from "@/images/R.png";
import SilverBadge from "@/images/S.png";
import styles from "@/pages/Common/AboutStudent/index.module.scss";
import { OverWrapper } from "@/components/OverWrapper";
import { MatchingTypes } from "./MatchingTypes";

interface Props {
    userMode: string;
}

export const Features: React.VFC<Props> = (props) => {
    return (
        <>
            {/* 内容は後で作成する */}
            <OverWrapper style={{ borderBottom: "1px solid #305077" }}>
                <div className={styles.tabTitle}>特徴</div>
            </OverWrapper>
            <div className={styles.costWrapper}>
                <div className={styles.otherCostsWrapper}>
                    <div className={styles.otherCostsTitle}>登録料・掲載料</div>
                    <div className={styles.otherCostsDescription}>
                        <div className={styles.howMuchCosts}>
                            <span className={styles.yen}>¥</span>
                            <span className={styles.number}>0</span>
                        </div>
                        <div className={styles.CostsText}>
                            登録料や掲載料は一切かかりません。
                            授業の作成、予約管理、メッセージなど授業にあたって必要な機能がすべて無料でお使いいただけます。
                        </div>
                    </div>
                </div>
                <div className={styles.feeWrapper}>
                    <div className={styles.feeTitle}>2つのマッチング方法</div>
                    <div className={styles.feeDescription}>
                        <div className={styles.feeText}>Trailのマッチング方法は2通り存在します。</div>
                    </div>
                    <MatchingTypes />
                </div>
            </div>
        </>
    );
};
