import {
    ELEMENTARY_SCHOOL_SUBJECT_CONFIG,
    HIGH_SCHOOL_SUBJECT_CONFIG,
    JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG,
} from "@/SubjectConfig";
import { SubjectsComponent } from "@/components/SubjectsComponent";
import styles from "@/pages/Student/CreatePublicRequest/index.module.scss";
import { memo, useCallback, useState } from "react";
import { SubjectResponse } from "@/store/autogenApi";
import { Mandatory } from "@/components/Tag/Mandatory";

interface Props {
    open?: boolean;
    checked: boolean;
    subjects: SubjectResponse[];
    courseType: string;
    validation: boolean;
    setSubjects: React.Dispatch<React.SetStateAction<SubjectResponse[]>>;
}

export interface Open {
    level: string;
    subject: string;
}

export const Subjects: React.VFC<Props> = memo(function Subjects(props) {
    const [open, setOpen] = useState<Open>({
        level: "",
        subject: "",
    });

    const handlePopoverOpen = useCallback((level: string, subject: string) => {
        setOpen({ level: level, subject: subject });
    }, []);

    const handlePopoverClose = useCallback(() => {
        setOpen({ level: "", subject: "" });
    }, []);

    const getConfig = useCallback((level: string) => {
        if (level === "highSchool") {
            return HIGH_SCHOOL_SUBJECT_CONFIG;
        } else if (level === "juniorHighSchool") {
            return JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG;
        } else if (level === "elementarySchool") {
            return ELEMENTARY_SCHOOL_SUBJECT_CONFIG;
        } else {
            return "";
        }
    }, []);

    const getSchool = useCallback((school: string) => {
        if (school === "highSchool") {
            return "高校";
        } else if (school === "juniorHighSchool") {
            return "中学校";
        } else {
            return "小学校";
        }
    }, []);

    return (
        <li className={styles.listItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>科目（複数選択可）</div>
                    {!props.open && <Mandatory />}
                </div>
            </div>
            <div className={`${styles.rightWrapper} ${styles.subjectsRightWrapper}`}>
                {props.open ? (
                    <div className={styles.openTrue}>
                        <ul className={styles.openSubjectList}>
                            {props.subjects.map((subject, idx) => {
                                const config = subject.level ? getConfig(subject.level) : "";
                                if (config) {
                                    const subjectInfo = subject.category ? config[subject.category] : "";
                                    const subCategoryKey = subject.subCategory;
                                    return (
                                        <>
                                            {subjectInfo && subjectInfo.sub && subCategoryKey && (
                                                <li key={idx} className={styles.openListItem}>
                                                    {subject.level && getSchool(subject.level)} - {subjectInfo.label} -{" "}
                                                    {subjectInfo.sub[subCategoryKey].label}
                                                </li>
                                            )}
                                        </>
                                    );
                                }
                            })}
                        </ul>
                    </div>
                ) : (
                    <>
                        <SubjectsComponent
                            checked={props.checked}
                            subjects={props.subjects}
                            setSubjects={props.setSubjects}
                        />
                        {props.checked && !props.validation && (
                            <div className={styles.subjectsError}>
                                この項目は必須です。少なくとも1つチェックをつけてください。
                            </div>
                        )}
                    </>
                )}
            </div>
        </li>
    );
});
