import { Button, Hidden, useMediaQuery } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { memo, useCallback, useEffect, useState } from "react";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { RootState } from "@/ducks";
import { UpdateFavoriteCoursesRequestBody } from "@/store/autogenApi";
import { useUpdateFavoriteCoursesMutation } from "@/store/hooks/students";

import styles from "./index.module.scss";

interface Props {
    userMode: string;
    isFavorite: boolean;
    favoriteCount: number;
    handleFavoriteButtonClick: () => void;
}

export const FavoriteContents: React.VFC<Props> = memo(function FavoriteContents(props) {
    const [favoriteCount, setFavoriteCount] = useState<number>(0);

    const upperThanSmall = useMediaQuery("(min-width:600px)");

    useEffect(() => {
        setFavoriteCount(props.favoriteCount);
    }, [props.favoriteCount]);

    return (
        <div
            className={styles.favoriteWrapper}
            style={{
                borderLeft: upperThanSmall ? "" : props.userMode === "student" ? "none" : "1px solid #999",
            }}
        >
            {props.userMode === "teacher" && (
                <>
                    <Button
                        className={styles.favoriteButton}
                        onClick={props.handleFavoriteButtonClick}
                        style={{
                            borderColor: props.isFavorite ? "#F50057" : "#999",
                        }}
                    >
                        {props.isFavorite ? (
                            <MdFavorite
                                className={styles.favoriteIcon}
                                style={{ color: props.isFavorite ? "#F50057" : "#999" }}
                            />
                        ) : (
                            <MdFavoriteBorder className={styles.favoriteBorderIcon} />
                        )}
                        <Hidden xsDown>
                            <div className={styles.favorite} style={{ color: props.isFavorite ? "#F50057" : "#999" }}>
                                気になる！
                            </div>
                        </Hidden>
                    </Button>
                    <Hidden smUp>
                        <div className={styles.onlyNumber} style={{ color: props.isFavorite ? "#F50057" : "#999" }}>
                            ({favoriteCount})
                        </div>
                    </Hidden>
                </>
            )}
            <Hidden xsDown>
                <div className={styles.theNumberOfSignUp}>{favoriteCount}人が「気になる！」登録しています。</div>
            </Hidden>
        </div>
    );
});
