import { Modal, useMediaQuery } from "@material-ui/core";
import { memo, useCallback } from "react";
import styles from "./index.module.scss";
import { CourseResponse, ClassResponse } from "@/store/autogenApi";
import { WhiteButton } from "@/components/Buttons/WhiteButton";
import { NavyButton } from "@/components/Buttons/NavyButton";
import { useHistory } from "react-router";
import { AiFillStar } from "react-icons/ai";
import { getSubject } from "@/utils/SubjectUtils";
import { getCourseType } from "@/utils/CourseUtils";
import { getLessonTime } from "@/utils/LessonUtils";
import defaultCourseImage from "@/images/defaultCourseImage.jpg";
import { ImageFromS3 } from "@/components/atoms/images/ImageFromS3";

interface Props {
    targetClass: ClassResponse;
    handleCourseDetailsModalClose: () => void;
}

export const CourseDetailsModal: React.VFC<Props> = memo(function CourseDetailsModal(props: Props) {
    const history = useHistory();

    const mdUp = useMediaQuery("(min-width:960px)");

    const handleCourseDetailsButtonClick = useCallback(() => {
        props.handleCourseDetailsModalClose();
        history.push(`/CourseDetails/${props.targetClass.course?.courseId}`);
    }, [props.targetClass]);

    const handleClassButtonClick = useCallback(() => {
        props.handleCourseDetailsModalClose();
        history.push(`/Class/${props.targetClass.classId}`);
    }, [props.targetClass]);

    const getCourseScore = useCallback(() => {
        if (props.targetClass.course?.reviews) {
            if (props.targetClass.course?.reviews?.length === 0) {
                return 0;
            } else if (props.targetClass.course?.reviews?.length === 1) {
                return props.targetClass.course?.reviews[0].score;
            } else {
                const sum = props.targetClass.course?.reviews.reduce((acc, cur) => acc + (cur.score as number), 0);
                return sum / props.targetClass.course?.reviews.length;
            }
        } else {
            return 0;
        }
    }, [props.targetClass]);

    return (
        <Modal open={true} onClose={props.handleCourseDetailsModalClose}>
            <div className={styles.courseDetailsModalContents}>
                <div className={styles.topFlex}>
                    <ImageFromS3
                        url={props.targetClass?.course?.thumbnailUrl || defaultCourseImage}
                        objectKey={props.targetClass?.course?.thumbnailObjectKey}
                        className={styles.thumbnail}
                    />
                    <div className={styles.flexRightWrapper}>
                        <div className={styles.topCourseContents}>
                            <div className={styles.courseType}>
                                {getCourseType(props.targetClass?.course as CourseResponse)}
                            </div>
                            <div className={styles.ratingWrapper}>
                                <AiFillStar className={styles.star} />
                                <div className={styles.averageReviewScore}>{getCourseScore()}</div>
                                <div className={styles.totalAchievements}>
                                    ({props.targetClass?.course?.reviews?.length})
                                </div>
                            </div>
                        </div>
                        <div className={styles.courseDetailsTitle}>{props.targetClass.course?.title}</div>
                        <div className={styles.subjects}>
                            {props.targetClass.course?.subjects.map((subject) => (
                                <div className={styles.subject}>{getSubject(subject)}</div>
                            ))}
                        </div>
                        {/* <div className={styles.classesInfo}>
                            {props.targetClass.classes.length == 0
                                ? "日程なし"
                                : `日程：${props.targetClass.classes.length}件あり`}
                        </div> */}
                        <div className={styles.latestScheduleWrapper}>
                            <div className={styles.latestScheduleTitle}>直近のスケジュール</div>
                            {props.targetClass.lessons.length === 0 ? (
                                <div className={styles.noSchedule}>なし</div>
                            ) : (
                                <ul className={styles.lessonTimes}>
                                    {props.targetClass.lessons.slice(0, 3).map((lesson) => (
                                        <li className={styles.lessonTime}>{getLessonTime(lesson)}</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        {props.targetClass.course?.courseDescription && (
                            <div className={styles.courseDescription}>
                                {props.targetClass.course?.courseDescription}
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.buttonsWrapper}>
                    <NavyButton handleClick={handleClassButtonClick} fontSize={mdUp ? 15 : 14}>
                        クラスページへ
                    </NavyButton>
                </div>
            </div>
        </Modal>
    );
});
