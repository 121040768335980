import { memo, useCallback, useEffect, useState } from "react";
import styles from "../../index.module.scss";
import { LessonResponse, CreateLessonRequestParams } from "@/store/autogenApi";
import { getLessonTimeOnly } from "@/utils/LessonUtils";
import { useParams } from "react-router";

import imageSrc from "@/images/transparent.png";

interface Props {
    key: number;
    lesson: LessonResponse | CreateLessonRequestParams;
    targetDayOfWeekIdx: number;
    lessonTimeRange: number[];
    lessonCalendarLanesEl: HTMLElement | null;
    targetLesson: LessonResponse | undefined;
    isUpdatingTemporaryLesson: boolean;
    targetNewLessonIdx?: number;
    newLessonIdx: number;
    isClickedValidBlock: boolean;
    handleValidBlockClick: (lesson: LessonResponse | CreateLessonRequestParams, idx: number, el: HTMLElement) => void;
    handleNewLessonStartYIdxChange: (idx: number | undefined) => void;
    handleNewLessonEndYIdxChange: (idx: number | undefined) => void;
    handleNewLessonXIdxChange: (idx: number | undefined) => void;
    handleNewLessonPopoverOpen: () => void;
    // 既存授業
    handleTargetLessonChange: (lesson: LessonResponse | undefined) => void;
    // 新規授業
    handleTargetNewLessonIdxChange: (lesson: CreateLessonRequestParams) => void;
}

export const ConfirmedLessonTimeBlocks: React.VFC<Props> = memo((props) => {
    const [hoveringLessonId, setHoveringLessonId] = useState<string | undefined>(undefined);
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const [initialXIdx, setInitialXIdx] = useState<number | undefined>(undefined);
    const [initialYIdx, setInitialYIdx] = useState<number | undefined>(undefined);
    const [draggingImage, setDraggingImage] = useState<HTMLImageElement | undefined>(undefined);
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const { classId } = useParams<{ classId?: string }>();

    const isInCreateCourse = location.pathname.includes("/CreateCourse");
    const isInClass = location.pathname.includes("/Class/");
    const isInMyPage = location.pathname.includes("/MyPage/");

    useEffect(() => {
        const img = new Image(0, 0);
        img.src = imageSrc;
        setDraggingImage(img);
    }, []);

    const getIsInRange = useCallback((range: number[], idx: number) => {
        if (range[0] == undefined || range[1] == undefined) return false;
        return range[0] <= idx && idx < range[1];
    }, []);

    const handleMouseEnter = useCallback((lessonId: string | undefined) => {
        setHoveringLessonId(lessonId);
        setIsHovering(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setHoveringLessonId(undefined);
        setIsHovering(false);
    }, []);

    const getBlockContentWrapperHeight = useCallback((range: number[]) => {
        const howManyBlocks = range[1] - range[0];
        // 4ブロック（1時間）につき50px+border1pxで合計51px
        const howManyBorders = Math.floor(howManyBlocks / 4);
        // padding10px分を引く
        const howManyPx = howManyBlocks * 12.5 + howManyBorders * 1 - 10;
        return howManyPx;
    }, []);

    const getBlockXIdxFromEvent = useCallback(
        (e: React.DragEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (props.lessonCalendarLanesEl == null) return;
            // lessonCalendarLanesWrapperのX座標を取得
            const lessonCalendarLanesElX = props.lessonCalendarLanesEl.getBoundingClientRect().x;
            // カーソルのX座標を取得
            const cursorX = e.clientX;
            // カーソルの相対座標を取得
            const cursorRelativeX = cursorX - lessonCalendarLanesElX;
            // lessonCalendarLanesの幅を7で割ってどこにいるかを取得
            const x = cursorRelativeX / (props.lessonCalendarLanesEl.clientWidth / 7);
            const blockIdx = Math.floor(x);
            return blockIdx;
        },
        [props.lessonCalendarLanesEl],
    );

    const getBlockYIdxFromEvent = useCallback(
        (e: React.DragEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            // lessonCalendarLanesWrapperのY座標を取得
            const lessonCalendarLanesWrapperEl = props.lessonCalendarLanesEl?.parentElement;
            if (!lessonCalendarLanesWrapperEl) return;
            const lessonCalendarLanesWrapperElY = lessonCalendarLanesWrapperEl.getBoundingClientRect().y;
            // カーソルのY座標を取得
            const cursorY = e.clientY;
            // カーソルの相対座標を取得
            const cursorRelativeY = cursorY - lessonCalendarLanesWrapperElY;
            // lessonCalendarLanesのスクロール位置を取得
            const lessonCalendarLanesWrapperElScrollTop = lessonCalendarLanesWrapperEl.scrollTop;
            // 4ブロック（1時間）につき50px+border1pxで合計51px
            // 1ブロック（15分）につき12.75px
            // イベント位置に最も近いブロックのidxを取得
            const y = (lessonCalendarLanesWrapperElScrollTop + cursorRelativeY) / 12.75;
            const upperY = Math.floor(y);
            const lowerY = Math.ceil(y);
            const blockIdx = Math.abs(upperY - y) < Math.abs(lowerY - y) ? upperY : lowerY;
            return blockIdx;
        },
        [props.lessonCalendarLanesEl],
    );

    const handleDragStart = useCallback(
        (e: React.DragEvent<HTMLDivElement>, lesson: LessonResponse | CreateLessonRequestParams) => {
            if (!draggingImage) return;
            setIsDragging(true);
            e.currentTarget.style.opacity = "0.6";
            e.dataTransfer.setDragImage(draggingImage, 0, 0);
            const xIdx = getBlockXIdxFromEvent(e);
            const yIdx = getBlockYIdxFromEvent(e);
            setInitialXIdx(xIdx);
            setInitialYIdx(yIdx);
            if (lesson.hasOwnProperty("lessonId")) {
                props.handleTargetLessonChange(lesson as LessonResponse);
            } else {
                props.handleTargetNewLessonIdxChange(lesson);
            }
        },
        [draggingImage, getBlockXIdxFromEvent, getBlockYIdxFromEvent],
    );

    const handleDrag = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            // 呼び出し回数が多すぎるので、ミリ秒が5で割り切れるときのみ処理を行う
            const now = new Date();
            const remainder = now.getMilliseconds();
            if (remainder % 5 !== 0) return;
            e.preventDefault();
            e.stopPropagation();
            const xIdx = getBlockXIdxFromEvent(e);
            const yIdx = getBlockYIdxFromEvent(e);
            if (xIdx == undefined || initialXIdx == undefined) return;
            const deltaXIdx = xIdx - initialXIdx;
            if (yIdx == undefined || initialYIdx == undefined) return;
            const deltaYIdx = yIdx - initialYIdx;
            // 差分が0のときはstartもendもundefinedにする
            if (deltaXIdx == 0 && deltaYIdx == 0) {
                props.handleNewLessonStartYIdxChange(undefined);
                props.handleNewLessonEndYIdxChange(undefined);
                return;
            }
            // 差分が0でないときはstartもendも変更する
            props.handleNewLessonXIdxChange(xIdx);
            const newStartY = props.lessonTimeRange[0] + deltaYIdx;
            props.handleNewLessonStartYIdxChange(newStartY);
            const newEndY = props.lessonTimeRange[1] + deltaYIdx;
            props.handleNewLessonEndYIdxChange(newEndY);
        },
        [
            initialXIdx,
            initialYIdx,
            getBlockXIdxFromEvent,
            getBlockYIdxFromEvent,
            props.lessonTimeRange,
            props.handleNewLessonXIdxChange,
            props.handleNewLessonStartYIdxChange,
            props.handleNewLessonEndYIdxChange,
        ],
    );

    const handleDragEnd = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            setIsDragging(false);
            e.currentTarget.style.opacity = "1";
            e.preventDefault();
            e.stopPropagation();
            const xIdx = getBlockXIdxFromEvent(e);
            const yIdx = getBlockYIdxFromEvent(e);
            if (xIdx == undefined || initialXIdx == undefined) return;
            const deltaXIdx = xIdx - initialXIdx;
            if (yIdx == undefined || initialYIdx == undefined) return;
            const deltaYIdx = yIdx - initialYIdx;
            // 差分が0のときはstartもendもundefinedにする
            if (deltaXIdx == 0 && deltaYIdx == 0) {
                props.handleNewLessonStartYIdxChange(undefined);
                props.handleNewLessonEndYIdxChange(undefined);
                return;
            }
            // 差分が0でないときはstartもendも変更する
            props.handleNewLessonXIdxChange(xIdx);
            const newStartY = props.lessonTimeRange[0] + deltaYIdx;
            props.handleNewLessonStartYIdxChange(newStartY);
            const newEndY = props.lessonTimeRange[1] + deltaYIdx;
            props.handleNewLessonEndYIdxChange(newEndY);
            props.handleNewLessonPopoverOpen();
        },
        [
            props.lessonTimeRange,
            props.lessonCalendarLanesEl,
            initialXIdx,
            initialYIdx,
            getBlockXIdxFromEvent,
            getBlockYIdxFromEvent,
            props.handleNewLessonStartYIdxChange,
            props.handleNewLessonEndYIdxChange,
        ],
    );

    return (
        <div className={styles.lessonTimeBlocks} key={props.key}>
            {[...Array(96)].map((_, k) => {
                const isInRange = getIsInRange(props.lessonTimeRange, k);
                const isTheFirstValidIdx = k === props.lessonTimeRange[0];
                const isTheLastValidIdx = k === props.lessonTimeRange[1] - 1;
                const isPerHour = k % 4 === 0;
                const targetClassId = props.lesson.hasOwnProperty("lessonId")
                    ? (props.lesson as LessonResponse).class?.classId
                    : undefined;
                const isNotTargetCourse =
                    (classId && targetClassId !== classId) ||
                    (isInCreateCourse && props.lesson.hasOwnProperty("lessonId"));
                return (
                    <div
                        id={`lessonTimeBlock-${
                            props.lesson.hasOwnProperty("lessonId") && isTheFirstValidIdx
                                ? (props.lesson as LessonResponse).lessonId
                                : `key-${props.key}`
                        }`}
                        className={styles.lessonTimeBlock}
                        style={{
                            position: isTheFirstValidIdx ? "relative" : "unset",
                            borderTop: isTheFirstValidIdx
                                ? "1px solid #DDD"
                                : isPerHour && k != 0
                                ? "1px solid transparent"
                                : "none",
                            borderBottom: isInRange && isTheLastValidIdx ? "1px solid #DDD" : "none",
                            borderRight: isInRange ? "1px solid #DDD" : "none",
                            borderLeft: isInRange ? "1px solid #DDD" : "none",
                            borderRadius: isTheFirstValidIdx
                                ? "5px 5px 0 0"
                                : isTheLastValidIdx
                                ? "0 0 5px 5px"
                                : "0px",
                            opacity:
                                // 確定授業
                                (isHovering &&
                                    hoveringLessonId &&
                                    props.lesson.hasOwnProperty("lessonId") &&
                                    hoveringLessonId === (props.lesson as LessonResponse).lessonId &&
                                    isInRange) ||
                                // CreateCourseにおいて作成中の授業
                                (isHovering &&
                                    !hoveringLessonId &&
                                    !props.lesson.hasOwnProperty("lessonId") &&
                                    isInRange &&
                                    isInCreateCourse)
                                    ? 0.8
                                    : 1,
                            zIndex: isInRange ? 1 : 0,
                            display:
                                // drag後の確定授業
                                (props.lesson.hasOwnProperty("lessonId") &&
                                    props.targetLesson?.lessonId === (props.lesson as LessonResponse).lessonId &&
                                    !isDragging &&
                                    !props.isClickedValidBlock &&
                                    isInRange &&
                                    !isNotTargetCourse) ||
                                // CreateCourseにおいて作成中のdrag後授業
                                (!isDragging &&
                                    !props.isClickedValidBlock &&
                                    props.isUpdatingTemporaryLesson &&
                                    // 既存授業であれば-1になる
                                    props.newLessonIdx >= 0 &&
                                    props.newLessonIdx === props.targetNewLessonIdx)
                                    ? "none"
                                    : "block",
                            pointerEvents: isNotTargetCourse ? "none" : "auto",
                        }}
                        draggable={isInRange && !isNotTargetCourse && !isInMyPage && !isInClass}
                        onDragStart={(e) => handleDragStart(e, props.lesson)}
                        onDrag={handleDrag}
                        onDragEnd={handleDragEnd}
                        onMouseEnter={
                            isInRange
                                ? () => {
                                      const params = props.lesson.hasOwnProperty("lessonId")
                                          ? (props.lesson as LessonResponse).lessonId
                                          : undefined;
                                      handleMouseEnter(params);
                                  }
                                : undefined
                        }
                        onMouseLeave={isInRange ? handleMouseLeave : undefined}
                        onClick={(e) => {
                            if (!isInRange) return;
                            props.handleValidBlockClick(
                                props.lesson,
                                props.targetDayOfWeekIdx,
                                e.currentTarget as HTMLElement,
                            );
                        }}
                    >
                        {isTheFirstValidIdx && (
                            <div
                                className={styles.blockContentWrapper}
                                style={{
                                    height: `${getBlockContentWrapperHeight(props.lessonTimeRange)}px`,
                                    zIndex: isInRange ? 1 : 0,
                                    backgroundColor: isInRange
                                        ? isNotTargetCourse
                                            ? "#999"
                                            : "#305077"
                                        : "transparent",
                                    opacity:
                                        // 既存授業
                                        (props.lesson.hasOwnProperty("lessonId") &&
                                            (props.lesson as LessonResponse).lessonId ===
                                                props.targetLesson?.lessonId) ||
                                        // CreateCourseにおいて確定中の授業
                                        props.newLessonIdx === props.targetNewLessonIdx
                                            ? 0.6
                                            : 1,
                                }}
                            >
                                <div className={styles.lessonTitle}>
                                    {props.lesson.hasOwnProperty("lessonId")
                                        ? (props.lesson as LessonResponse).class?.course?.title
                                        : "新規授業"}
                                </div>
                                <div className={styles.lessonTime}>{getLessonTimeOnly(props.lesson)}</div>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
});
