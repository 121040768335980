import { TextField } from "@material-ui/core";
import { memo, useCallback, useEffect, useState } from "react";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Mandatory } from "@/components/Tag/Mandatory";

import styles from "@/pages/Teacher/IdentificationForStripe/index.module.scss";

interface Props {
    checked: boolean;
    handleEmailChange: (value: string) => void;
}

export const Email: React.VFC<Props> = memo(function Email(props) {
    const [firstHalf, setFirstHalf] = useState("");
    const [secondHalf, setSecondHalf] = useState("");
    const [isValid, setIsValid] = useState(false);

    const regexp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.+-]*@[A-Za-z0-9_.-]+\.[A-Za-z0-9]+$/;

    useEffect(() => {
        if (firstHalf.length > 0 && secondHalf.length > 0) {
            const email = `${firstHalf}@${secondHalf}`;
            if (regexp.test(email)) {
                props.handleEmailChange(email);
                setIsValid(true);
            } else {
                props.handleEmailChange("");
                setIsValid(false);
            }
        } else {
            props.handleEmailChange("");
            setIsValid(false);
        }
    }, [firstHalf, secondHalf]);

    const handleFirstHalfChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        setFirstHalf(value);
    }, []);

    const handleSecondHalfChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        setSecondHalf(value);
    }, []);

    return (
        <div className={styles.formItem} style={{ borderBottom: "1px solid #CCC" }}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitle}>メールアドレス</div>
                <Mandatory />
            </div>
            <div className={styles.rightWrapper}>
                <div className={styles.emailWrapper}>
                    <TextField
                        variant="outlined"
                        placeholder="〇〇〇"
                        className={styles.textField}
                        value={firstHalf}
                        onChange={handleFirstHalfChange}
                    />
                    <div className={styles.bar}>@</div>
                    <TextField
                        variant="outlined"
                        placeholder="〇〇〇.〇〇〇"
                        className={styles.textField}
                        value={secondHalf}
                        onChange={handleSecondHalfChange}
                    />
                </div>
                <ErrorMessage
                    content="メールアドレスを入力してください。"
                    when={props.checked && (firstHalf.length === 0 || secondHalf.length === 0)}
                />
                <ErrorMessage
                    content="不正なメールアドレスです。"
                    when={props.checked && firstHalf.length > 0 && secondHalf.length > 0 && !isValid}
                />
            </div>
        </div>
    );
});
