import { useEffect } from "react";

import { useHistory } from "react-router";

interface Props {
    children: React.ReactNode;
}

export const ScrollToTop: React.VFC<Props> = (props) => {
    const history = useHistory();
    useEffect(() => {
        const scrollToTop = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            scrollToTop();
        };
    }, []);

    return <>{props.children}</>;
};
