import { Hidden } from "@material-ui/core";
import { CourseResponse, PublicTeacherResponse, PublicUserResponse } from "@/store/autogenApi";

import { TopInfoPCContents } from "./PCContents";
import { TopInfoSPContents } from "./SPContents";

import styles from "@/pages/Common/TeacherDetails/index.module.scss";
import { OverWrapper } from "@/components/OverWrapper";
import { TabInfo } from "@/components/TabsTemplate";

interface Props {
    teacher: PublicTeacherResponse;
    user: PublicUserResponse;
    courses: CourseResponse[];
    topInfoWrapperRef: (headerEl: HTMLElement | null) => void;
}

const TAB_INFO_LIST: TabInfo[] = [
    { value: "top", label: "トップ" },
    { value: "courses", label: "講座" },
    { value: "review", label: "レビュー" },
];

export const TopInfo: React.VFC<Props> = (props) => {
    const getTotalReviewsLength = () => {
        const reviewsLengthList = props.courses.map((course) => {
            const reviews = course.reviews;
            if (reviews) {
                return reviews.length;
            } else {
                return 0;
            }
        });
        if (reviewsLengthList.length > 0) {
            const result = reviewsLengthList.reduce((a, b) => a + b);
            return result;
        } else {
            return 0;
        }
    };

    return (
        <OverWrapper style={{ backgroundColor: "#F3F3F3" }}>
            <div className={styles.topInfoWrapper} ref={props.topInfoWrapperRef}>
                <Hidden smDown>
                    <TopInfoPCContents
                        teacher={props.teacher}
                        user={props.user}
                        courses={props.courses}
                        getTotalReviewsLength={getTotalReviewsLength}
                    />
                </Hidden>
                <Hidden mdUp>
                    <TopInfoSPContents
                        teacher={props.teacher}
                        user={props.user}
                        courses={props.courses}
                        getTotalReviewsLength={getTotalReviewsLength}
                    />
                </Hidden>
            </div>
        </OverWrapper>
    );
};
