import { useCallback, useState } from "react";

import { Button, Popover } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import styles from "@/pages/Common/CourseDetails/index.module.scss";
import { ClassResponse } from "@/store/autogenApi";

interface Props {
    smUp: boolean;
    targetClass: ClassResponse | undefined;
    activeClasses: ClassResponse[];
    getJapaneseDay: (idx: number) => string;
    handleTargetClassChange: (classId: string | undefined) => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        popover: {},
        button: {
            display: "block",
            borderRadius: "0",
            borderBottom: "1px solid #AAA",
            padding: "10px 30px",
        },
        popoverContents: {
            maxHeight: "250px",
            overflow: "scroll",
        },
        popoverContentsButton: {
            display: "block",
            padding: "5px 15px",
            boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.1)",
        },
        period: {
            fontWeight: "bold",
            fontSize: "12px",
        },
        shortScheduleList: {
            display: "flex",
            flexDirection: "column",
            gap: "3px",
            margin: "5px 0 0 0",
            padding: "0",
        },
        singleShortScheduleItem: {
            display: "flex",
            alignItems: "center",
            gap: "5px",
        },
        shortScheduleItem: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        idx: {
            fontSize: "10px",
        },
        schedule: {
            display: "flex",
            gap: "3px",
        },
        scheduleDate: {
            fontSize: "10px",
        },
        scheduleTime: {
            fontSize: "10px",
        },
        date: {
            fontSize: "12px",
        },
        time: {
            fontSize: "12px",
        },
    }),
);

export const SelectButtonAndPopover: React.VFC<Props> = (props) => {
    const classes = useStyles({});
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleSelectButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTargetButtonClick = useCallback(
        (classResponse: ClassResponse) => {
            setAnchorEl(null);
            props.handleTargetClassChange(classResponse.classId);
        },
        [props.handleTargetClassChange],
    );

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <>
            <Button
                variant="contained"
                onClick={handleSelectButtonClick}
                className={styles.selectButton}
                style={{ backgroundColor: open ? "rgba(0, 57, 136, 0.1)" : "" }}
            >
                {props.activeClasses.map((classResponse) => {
                    if (classResponse.classId === props.targetClass?.classId) {
                        return (
                            <li className={styles.dateItem} key={classResponse.classId}>
                                <div className={styles.leftWrapper}>
                                    <div className={styles.periodWrapper}>
                                        {classResponse.lessons &&
                                            classResponse.lessons.length > 1 &&
                                            classResponse.lessons.map((lesson, idx) => {
                                                if (idx === 0 && lesson.startTime) {
                                                    return (
                                                        <>
                                                            <div key={idx} className={styles.dateWrapper}>
                                                                <span className={styles.date} key={idx}>{`${
                                                                    new Date(lesson.startTime).getMonth() + 1
                                                                }月${new Date(
                                                                    lesson.startTime,
                                                                ).getDate()}日(${props.getJapaneseDay(
                                                                    new Date(lesson.startTime).getDay(),
                                                                )})`}</span>
                                                            </div>
                                                            <span className={styles.bar}>{props.smUp ? "|" : "-"}</span>
                                                        </>
                                                    );
                                                } else if (
                                                    classResponse.lessons &&
                                                    idx === classResponse.lessons.length - 1 &&
                                                    lesson.endTime
                                                ) {
                                                    return (
                                                        <div key={idx} className={styles.dateWrapper}>
                                                            <span className={styles.date}>
                                                                {`${
                                                                    new Date(lesson.endTime).getMonth() + 1
                                                                }月${new Date(
                                                                    lesson.endTime,
                                                                ).getDate()}日(${props.getJapaneseDay(
                                                                    new Date(lesson.endTime).getDay(),
                                                                )})`}
                                                            </span>
                                                        </div>
                                                    );
                                                } else {
                                                    return <></>;
                                                }
                                            })}
                                    </div>
                                    {props.activeClasses && (
                                        <ul className={styles.shortScheduleList}>
                                            {classResponse.lessons &&
                                                classResponse.lessons.map((lesson, idx2) => {
                                                    if (lesson.startTime && lesson.endTime) {
                                                        const startTime = new Date(lesson.startTime);
                                                        const endTime = new Date(lesson.endTime);
                                                        return (
                                                            <li
                                                                key={idx2}
                                                                className={
                                                                    classResponse.lessons &&
                                                                    classResponse.lessons.length === 1
                                                                        ? styles.singleShortScheduleItem
                                                                        : styles.shortScheduleItem
                                                                }
                                                            >
                                                                {classResponse.lessons &&
                                                                    classResponse.lessons.length > 1 && (
                                                                        <span className={styles.idx}>{`${
                                                                            idx2 + 1
                                                                        }日目`}</span>
                                                                    )}
                                                                <div className={styles.schedule}>
                                                                    <span className={styles.date}>
                                                                        {`${
                                                                            startTime.getMonth() + 1
                                                                        }月${startTime.getDate()}日(${props.getJapaneseDay(
                                                                            startTime.getDay(),
                                                                        )})`}
                                                                    </span>
                                                                    <span className={styles.time}>
                                                                        {`${startTime.getHours()}:${(
                                                                            "00" + startTime.getMinutes()
                                                                        ).slice(-2)} - ${endTime.getHours()}:${(
                                                                            "00" + endTime.getMinutes()
                                                                        ).slice(-2)}`}
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        );
                                                    }
                                                })}
                                        </ul>
                                    )}
                                </div>
                            </li>
                        );
                    }
                })}
                <ExpandMoreIcon className={styles.icon} />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                className={classes.popover}
            >
                <div className={classes.popoverContents}>
                    {props.activeClasses?.map((classResponse) => {
                        if (classResponse.applyingDeadline) {
                            const now = new Date();
                            const deadline = new Date(classResponse.applyingDeadline);
                            if (deadline.getTime() - now.getTime() > 0) {
                                return (
                                    <Button
                                        value={10}
                                        className={classes.popoverContentsButton}
                                        style={{
                                            backgroundColor:
                                                props.targetClass?.classId === classResponse.classId
                                                    ? "rgba(0, 57, 136, 0.1)"
                                                    : "",
                                        }}
                                        key={classResponse.classId}
                                        onClick={() => {
                                            handleTargetButtonClick(classResponse);
                                        }}
                                    >
                                        {classResponse.lessons && classResponse.lessons.length > 1 && (
                                            <div className={classes.period}>
                                                {classResponse.lessons.map((lesson, idx2) => {
                                                    if (idx2 === 0 && lesson.startTime) {
                                                        return `${
                                                            new Date(lesson.startTime).getMonth() + 1
                                                        }月${new Date(lesson.startTime).getDate()}日 - `;
                                                    } else if (
                                                        classResponse.lessons &&
                                                        idx2 === classResponse.lessons.length - 1 &&
                                                        lesson.endTime
                                                    ) {
                                                        return `${new Date(lesson.endTime).getMonth() + 1}月${new Date(
                                                            lesson.endTime,
                                                        ).getDate()}日`;
                                                    } else {
                                                        return "";
                                                    }
                                                })}
                                            </div>
                                        )}
                                        {props.activeClasses && (
                                            <ul className={classes.shortScheduleList}>
                                                {classResponse.lessons &&
                                                    classResponse.lessons.map((lesson, idx2) => {
                                                        if (lesson.startTime && lesson.endTime) {
                                                            const startTime = new Date(lesson.startTime);
                                                            const endTime = new Date(lesson.endTime);
                                                            return (
                                                                <li
                                                                    key={idx2}
                                                                    className={
                                                                        classResponse.lessons &&
                                                                        classResponse.lessons.length === 1
                                                                            ? classes.singleShortScheduleItem
                                                                            : classes.shortScheduleItem
                                                                    }
                                                                >
                                                                    {classResponse.lessons &&
                                                                        classResponse.lessons.length > 1 && (
                                                                            <span className={classes.idx}>{`${
                                                                                idx2 + 1
                                                                            }日目`}</span>
                                                                        )}
                                                                    <div
                                                                        className={
                                                                            classResponse.lessons &&
                                                                            classResponse.lessons.length > 1
                                                                                ? classes.schedule
                                                                                : ""
                                                                        }
                                                                    >
                                                                        <span
                                                                            className={
                                                                                classResponse.lessons &&
                                                                                classResponse.lessons.length > 1
                                                                                    ? classes.scheduleDate
                                                                                    : classes.date
                                                                            }
                                                                        >
                                                                            {`${
                                                                                startTime.getMonth() + 1
                                                                            }月${startTime.getDate()}日(${props.getJapaneseDay(
                                                                                startTime.getDay(),
                                                                            )})`}
                                                                        </span>
                                                                        <span
                                                                            className={
                                                                                classResponse.lessons &&
                                                                                classResponse.lessons.length > 1
                                                                                    ? classes.scheduleTime
                                                                                    : classes.time
                                                                            }
                                                                        >
                                                                            {`${startTime.getHours()}:${(
                                                                                "00" + startTime.getMinutes()
                                                                            ).slice(-2)} - ${endTime.getHours()}:${(
                                                                                "00" + endTime.getMinutes()
                                                                            ).slice(-2)}`}
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            );
                                                        }
                                                    })}
                                            </ul>
                                        )}
                                    </Button>
                                );
                            }
                        }
                    })}
                </div>
            </Popover>
        </>
    );
};
