import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import styles from "./index.module.scss";
import { ProposalResponse } from "@/store/autogenApi";
import { useCallback } from "react";

interface Props {
    proposal?: ProposalResponse;
    isHidden?: boolean;
    isFlex?: boolean;
    handleProposalCardClick: (proposal: ProposalResponse) => void;
}

export const MyProposalCard: React.VFC<Props> = (props) => {
    const getStatus = useCallback(() => {
        if (props.proposal?.class?.course) return "講座あり";
        return "提案中";
    }, [props.proposal]);

    const getPrice = useCallback(() => {
        const courseType = props.proposal?.publicRequest?.courseType;
        const pricePerPayment = props.proposal?.pricePerPayment;
        if (!courseType || !pricePerPayment) return "";
        if (courseType === "short") {
            return `¥${new Intl.NumberFormat("ja-JP").format(pricePerPayment)}`;
        }
        if (courseType === "regular") {
            return `¥${new Intl.NumberFormat("ja-JP").format(pricePerPayment)}/月`;
        }
    }, [props.proposal]);

    return (
        <Card
            className={styles.card}
            style={{
                visibility: props.isHidden ? "hidden" : "visible",
                flex: props.isFlex ? 1 : "none",
                width: props.isFlex ? "auto" : "150px",
            }}
            onClick={() => {
                if (!props.proposal) return;
                props.handleProposalCardClick(props.proposal);
            }}
        >
            <CardContent className={styles.cardContents}>
                <div className={styles.status}>{getStatus()}</div>
                <div className={styles.proposalContent}>{props.proposal?.content}</div>
                <div className={styles.priceWrapper}>
                    <div className={styles.price}>
                        <div className={styles.strong}>{getPrice()}</div>
                    </div>
                </div>
                <div className={styles.publicRequestTitle}>{props.proposal?.publicRequest?.title}</div>
            </CardContent>
        </Card>
    );
};
