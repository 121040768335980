import { memo, useEffect } from "react";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { login, transition } from "@/actions/logging";
import { useCreateJWTMutation } from "@/store/hooks/jwt";
import { PrivateUserResponse } from "@/store/autogenApi";

interface Props {
    user: PrivateUserResponse | null;
    userId: string;
    forStudent: boolean;
    handleClose: () => void;
    handleIsProcessingForSignInChange: (value: boolean) => void;
}

export const Process: React.VFC<Props> = memo(function Process(props) {
    useEffect(() => {
        (async () => {
            if (!props.user) {
                dispatch(transition(props.userId));
                const tokenMutationState = await createJWTMutation({
                    jwtRequestBody: {
                        login: true,
                        userMode: "anonymous",
                        userId: props.userId,
                        studentId: null,
                        teacherId: null,
                    },
                });
                if (tokenMutationState.isSuccess) {
                    localStorage.setItem("token", tokenMutationState.data);
                }
                alert("情報入力が完了していません。情報入力ページに移動します。");
                props.handleIsProcessingForSignInChange(false);
                props.handleClose();
                if (props.forStudent) {
                    history.push("/InfoInput");
                } else {
                    history.push("/Teacher/InfoInput");
                }
                return <></>;
            }
            if (props.forStudent) {
                if (props.user.studentId) {
                    dispatch(login("student", props.user.userId, props.user.studentId, props.user.teacherId ?? null));
                    const tokenMutationState = await createJWTMutation({
                        jwtRequestBody: {
                            login: true,
                            userMode: "student",
                            userId: props.user.userId,
                            studentId: props.user.studentId,
                            teacherId: props.user.teacherId ?? null,
                        },
                    });
                    if (tokenMutationState.isSuccess) {
                        localStorage.setItem("token", tokenMutationState.data);
                    }
                    props.handleIsProcessingForSignInChange(false);
                    history.push("/");
                } else if (props.user.teacherId) {
                    props.handleIsProcessingForSignInChange(false);
                    props.handleClose();
                    alert("こちらのアカウントは生徒登録されていません。\n生徒登録するか先生ログインしてください。");
                }
            } else {
                if (props.user.teacherId) {
                    dispatch(login("teacher", props.user.userId, props.user.studentId ?? null, props.user.teacherId));
                    const tokenMutationState = await createJWTMutation({
                        jwtRequestBody: {
                            login: true,
                            userMode: "teacher",
                            userId: props.user.userId,
                            studentId: props.user.studentId ?? null,
                            teacherId: props.user.teacherId,
                        },
                    });
                    if (tokenMutationState.isSuccess) {
                        localStorage.setItem("token", tokenMutationState.data);
                    }
                    props.handleIsProcessingForSignInChange(false);
                    history.push("/");
                } else if (props.user.studentId) {
                    props.handleIsProcessingForSignInChange(false);
                    props.handleClose();
                    alert("こちらのアカウントは先生登録されていません。\n先生登録するか生徒ログインしてください。");
                }
            }
        })();
    }, [props.user, props.userId, props.forStudent, props.handleClose, props.handleIsProcessingForSignInChange]);

    const dispatch = useDispatch();
    const history = useHistory();
    const createJWTMutation = useCreateJWTMutation();
    return null;
});
