import { useMediaQuery } from "@material-ui/core";
import { memo, useEffect } from "react";
import { useParams } from "react-router";
import { CommonComponent } from "@/pageComponents/Student/CreatePublicRequest/Functional/Common";
import { UpdateComponent } from "@/pageComponents/Student/CreatePublicRequest/Functional/Update";
import { Navigation } from "@/components/Navigation";
import { useNavigation } from "@/components/Navigation/NavigationContext";

const NAVIGATION_LIST: Navigation[] = [
    {
        title: "講座リクエスト作成",
        url: "/CreatePublicRequest",
    },
];

export const CreatePublicRequest: React.VFC = memo(function CreatePublicRequest() {
    const { currentRequestId } = useParams<{ currentRequestId: string }>();

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    if (currentRequestId) {
        return <UpdateComponent currentRequestId={currentRequestId} />;
    } else {
        return <CommonComponent />;
    }
});
