import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { memo } from "react";
import { Mandatory } from "@/components/Tag/Mandatory";
import styles from "@/pages/Teacher/MyPage/index.module.scss";
import { None } from "../Errors/None";
import { PREFECTURE_LIST } from "./prefectureList";

interface Props {
    value: string | undefined;
    checked: boolean;
    edit: boolean;
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

export const Prefecture: React.VFC<Props> = memo(function Prefecture(props) {
    return (
        <>
            {props.edit ? (
                <>
                    <li className={styles.editListItem}>
                        <div className={styles.leftWrapper}>
                            <div className={styles.titleWrapper}>
                                <div className={styles.title}>都道府県</div>
                                <Mandatory />
                            </div>
                        </div>
                        <div className={styles.rightWrapper}>
                            <Select
                                variant="outlined"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="prefecture"
                                value={props.value}
                                onChange={props.handleChange}
                                className={styles.select}
                            >
                                {PREFECTURE_LIST.map((prefecture, index) => (
                                    <MenuItem key={index} value={prefecture}>
                                        {prefecture}
                                    </MenuItem>
                                ))}
                            </Select>
                            <None value={props.value} checked={props.checked} />
                        </div>
                    </li>
                </>
            ) : (
                <li className={styles.profileItem} style={{ borderBottom: props.edit ? "" : "#305077 1px solid" }}>
                    <div className={styles.profileTitle}>都道府県</div>
                    <div className={styles.profileSpace}></div>
                    <div className={styles.profileValue}>{props.value}</div>
                </li>
            )}
        </>
    );
});
