import { CourseCardsBase } from "@/components/CourseCardsBase";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { SubjectResponse } from "@/store/autogenApi";
import { useGetCourseSearchResultsQuery } from "@/store/hooks/courses";
import { getSubject } from "@/utils/SubjectUtils";

interface Props {
    subject: SubjectResponse;
    repetitionNumber?: number;
}

export const CoursesWithTargetSubject: React.VFC<Props> = (props) => {
    const coursesQueryState = useGetCourseSearchResultsQuery({
        level: props.subject.level,
        category: props.subject.category,
        subCategory: props.subject.subCategory,
        take: 5,
        orderBy: "latest",
    });
    return (
        <QueryLoadingWrapper {...coursesQueryState}>
            {(courses) => (
                <>
                    <CourseCardsBase
                        title={`「${getSubject(props.subject)}」の定期講座`}
                        courses={courses.filter(
                            (course) => course.courseType === "regular" && course.classes.length > 0,
                        )}
                        destination={`/SearchResults?searchType=course&keyword=&courseType=regular&level=${
                            props.subject.level
                        }&category=${props.subject.category ?? ""}&subCategory=${
                            props.subject.subCategory ?? ""
                        }&rankNumber=&university=&faculty=&department=&orderBy=latest`}
                        isLoaded={true}
                        repetitionNumber={props.repetitionNumber}
                    />
                    <CourseCardsBase
                        title={`「${getSubject(props.subject)}」の単発・短期講座`}
                        courses={courses.filter((course) => course.courseType === "short" && course.classes.length > 0)}
                        destination={`/SearchResults?searchType=course&keyword=&courseType=short&level=${
                            props.subject.level
                        }&category=${props.subject.category ?? ""}&subCategory=${
                            props.subject.subCategory ?? ""
                        }&rankNumber=&university=&faculty=&department=&orderBy=latest`}
                        isLoaded={true}
                        repetitionNumber={props.repetitionNumber}
                    />
                </>
            )}
        </QueryLoadingWrapper>
    );
};
