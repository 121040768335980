import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import styles from "./index.module.scss";

import defaultDraftCourseImage from "@/images/defaultCourseImage.jpg";
import { DraftCourseResponse } from "@/store/autogenApi";
import { useCallback } from "react";
import { getLessonTime } from "@/utils/LessonUtils";
import { ImageFromS3 } from "@/components/atoms/images/ImageFromS3";

interface Props {
    draftCourse?: DraftCourseResponse;
    isHidden?: boolean;
    isFlex?: boolean;
    handleDraftCourseCardClick: (draftCourse: DraftCourseResponse) => void;
}

export const MyDraftCourseCard: React.VFC<Props> = (props) => {
    return (
        <Card
            className={styles.card}
            style={{
                visibility: props.isHidden ? "hidden" : "visible",
                flex: props.isFlex ? 1 : "none",
                width: props.isFlex ? "auto" : "150px",
            }}
            onClick={() => {
                if (!props.draftCourse) return;
                props.handleDraftCourseCardClick(props.draftCourse);
            }}
        >
            <div className={styles.mediaWrapper}>
                {props.draftCourse?.course?.thumbnailUrl ? (
                    <ImageFromS3
                        url={props.draftCourse?.course?.thumbnailUrl}
                        objectKey={props.draftCourse?.course?.thumbnailObjectKey}
                        className={styles.media}
                    />
                ) : (
                    <CardMedia className={styles.media} image={defaultDraftCourseImage} />
                )}
            </div>
            <CardContent className={styles.cardContents}>
                <Typography variant="body2" color="textSecondary" component="div" className={styles.title}>
                    {props.draftCourse?.title == "" ? "新規講座" : props.draftCourse?.title}
                </Typography>
            </CardContent>
        </Card>
    );
};
