import styles from "./index.module.scss";
import { Button, CircularProgress } from "@material-ui/core";
import { FileIcon } from "./FileIcon";
import { memo, useCallback, useEffect, useState } from "react";
import { ImageFromS3 } from "@/components/atoms/images/ImageFromS3";

interface Props {
    fileUrl?: string;
    idx: number;
    messagesEl: HTMLElement | null;
}

export const FileContents: React.VFC<Props> = memo(function FileContents(props) {
    const [isImage, setIsImage] = useState<boolean | undefined>(undefined);

    const imageRef = useCallback(
        (node: HTMLImageElement | null) => {
            if (!props.messagesEl) return;
            props.messagesEl.scroll({
                top: props.messagesEl.scrollHeight,
                behavior: "smooth",
            });
        },
        [props.messagesEl],
    );

    const buttonRef = useCallback(
        (node: HTMLElement | null) => {
            if (!props.messagesEl) return;
            props.messagesEl.scroll({
                top: props.messagesEl.scrollHeight,
                behavior: "smooth",
            });
        },
        [props.messagesEl],
    );

    useEffect(() => {
        (async () => {
            const isImagePromise = () => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.src = props.fileUrl;
                    img.onload = () => resolve(true);
                    img.onerror = () => reject(false);
                });
            };
            const result = (await isImagePromise()) as boolean;
            setIsImage(result);
        })();
    }, [props.fileUrl]);

    const getFileNameBody = useCallback(() => {
        const fileObjectKeyArray = props.fileUrl.split("/");
        const fileName = fileObjectKeyArray[fileObjectKeyArray.length - 1];
        const fileNameBody = fileName.split(".").slice(0, -1).join(".");
        return fileNameBody;
    }, [props.fileUrl]);

    const getFileExtension = useCallback(() => {
        const fileObjectKeyArray = props.fileUrl.split("/");
        const fileName = fileObjectKeyArray[fileObjectKeyArray.length - 1];
        const fileExtension = fileName.split(".");
        if (fileExtension.length > 1) {
            return `.${fileExtension[fileExtension.length - 1]}`;
        }
        return "";
    }, [props.fileUrl]);

    return (
        <li key={props.fileUrl} className={styles.file} style={{ border: isImage ? "1px solid #CCC" : "none" }}>
            <div className={styles.progressWrapper}>
                <CircularProgress className={styles.progress} />
            </div>
            {isImage === true && (
                <ImageFromS3 url={props.fileUrl} objectKey={props.fileUrl} className={styles.image} ref={imageRef} />
            )}
            {isImage === false && (
                <Button href={props.fileUrl} target="_blank" className={styles.downloadButton} ref={buttonRef}>
                    <FileIcon fileUrl={props.fileUrl} />
                    <div className={styles.fileNameBody}>{getFileNameBody()}</div>
                    <div className={styles.fileExtension}>{getFileExtension()}</div>
                </Button>
            )}
        </li>
    );
});
