import { Avatar } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";

import styles from "@/pages/Common/TeacherDetails/index.module.scss";
import { ReviewResponse } from "@/store/autogenApi";

import boy from "./images/boy.jpg";
import girl from "./images/girl.jpg";
import undefined from "./images/undefined.jpg";

interface Props {
    review: ReviewResponse;
}

export const ReviewComponent: React.VFC<Props> = (props) => {
    const getSrc = (review: ReviewResponse) => {
        if (review.anonymous) {
            if (review.user?.gender === "male") {
                return boy;
            } else if (review.user?.gender === "female") {
                return girl;
            } else {
                return undefined;
            }
        } else {
            return review.student?.iconImageUrl;
        }
    };

    return (
        <li className={styles.reviewListItem}>
            <div className={styles.reviewerInfo}>
                {props.review.anonymous ? (
                    <>
                        <Avatar className={styles.avatar} src={getSrc(props.review)} />
                        <div className={styles.gradeAndGender}>
                            {props.review.user?.grade}-{props.review.user?.gender}
                        </div>
                    </>
                ) : (
                    <>
                        <Avatar className={styles.avatar} src={getSrc(props.review)} />
                        <div className={styles.gradeAndGender}>{props.review.student?.nickname}</div>
                    </>
                )}
            </div>
            <div className={styles.reviewInfo}>
                <div className={styles.contentsWithFlexGrow}>
                    <div className={styles.reviewContents}>
                        <Rating
                            name="half-rating-read"
                            defaultValue={props.review.score}
                            precision={0.5}
                            readOnly
                            className={styles.rating}
                        />
                        <div className={styles.reviewTitle}>{props.review.title}</div>
                        <div className={styles.reviewText}>{props.review.comment}</div>
                    </div>
                    <div className={styles.flexGrow} />
                </div>
                <div className={styles.month}>{`評価対象期間：${props.review.evaluationPeriod}`}</div>
            </div>
        </li>
    );
};
