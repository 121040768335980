import { Modal } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { memo, useCallback, useEffect, useState } from "react";
import { StudentToDoResponse } from "@/store/autogenApi";
import styles from "./index.module.scss";
import { DisapprovalModeContents } from "./DisapproveModeContents";
import { UpdateFeeInfo } from "./UpdateFeeInfo";
import { ApproveModeContents } from "./ApproveModeContents";

interface Props {
    open: boolean;
    targetStudentToDo: StudentToDoResponse;
    handleModalClose: () => void;
}

export const ApproveUpdateFeeModal: React.VFC<Props> = memo(function ApprovePrivateRequestModal(props) {
    const [approvalMode, setApprovalMode] = useState<boolean>(false);
    const [disapprovalMode, setDisapprovalMode] = useState<boolean>(false);
    const [maxModalHeight, setMaxModalHeight] = useState<number>(0);
    const [modalContentsEl, setModalContentsEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        changeModalHeight();
        window.addEventListener("resize", () => {
            changeModalHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalHeight();
            });
        };
    }, []);

    const changeModalHeight = useCallback(() => {
        const innerHeight = window.innerHeight;
        setMaxModalHeight(innerHeight * 0.75);
    }, []);

    const modalContentsRef = useCallback((el: HTMLElement | null) => {
        setModalContentsEl(el);
    }, []);

    const scrollToTop = useCallback(() => {
        modalContentsEl?.scrollTo({ top: 0 });
    }, [modalContentsEl]);

    const handleApproveButtonClick = useCallback(() => {
        setApprovalMode(true);
        scrollToTop();
    }, [scrollToTop]);

    const handleBackButtonClick = useCallback(() => {
        setApprovalMode(false);
        setDisapprovalMode(false);
        scrollToTop();
    }, [scrollToTop]);

    const handleDisapproveButtonClick = useCallback(() => {
        setDisapprovalMode(true);
        scrollToTop();
    }, [scrollToTop]);

    const handleClose = useCallback(() => {
        props.handleModalClose();
        setApprovalMode(false);
        setDisapprovalMode(false);
    }, []);

    const getCourseTitle = useCallback(() => {
        const course = props.targetStudentToDo.subscription?.class?.course;
        return course?.title ?? "";
    }, [props.targetStudentToDo.subscription?.class?.course?.title]);

    return (
        <Modal open={props.open} onClose={handleClose} className={styles.approvePrivateRequestModal}>
            <div className={styles.approvePrivateRequestModalContents} ref={modalContentsRef}>
                <div className={styles.modalHeader}>
                    <button className={styles.laterButton} onClick={handleClose}>
                        <CloseIcon />
                    </button>
                    <div className={styles.modalTitle}>
                        {approvalMode
                            ? "月額更新申請の承認"
                            : disapprovalMode
                            ? "月額更新申請の非承認"
                            : "月額更新申請の詳細"}
                    </div>
                </div>
                {approvalMode && (
                    <ApproveModeContents
                        targetStudentToDo={props.targetStudentToDo}
                        maxHeight={maxModalHeight}
                        handleBackButtonClick={handleBackButtonClick}
                        handleClose={handleClose}
                    />
                )}
                {disapprovalMode && (
                    <DisapprovalModeContents
                        targetStudentToDo={props.targetStudentToDo}
                        maxHeight={maxModalHeight}
                        handleBackButtonClick={handleBackButtonClick}
                        handleClose={handleClose}
                    />
                )}
                {!approvalMode && !disapprovalMode && (
                    <UpdateFeeInfo
                        maxModalHeight={maxModalHeight}
                        targetStudentToDo={props.targetStudentToDo}
                        handleApproveButtonClick={handleApproveButtonClick}
                        handleDisapproveButtonClick={handleDisapproveButtonClick}
                    />
                )}
            </div>
        </Modal>
    );
});
