import { useSelector } from "react-redux";

import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { useGetReservedClassesQuery } from "@/store/hooks/students";

import { MyPage } from ".";
import { RootState } from "@/ducks";
import { ReservedCoursesContents } from "@/pageComponents/Student/MyPage/ReservedCourses";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { useEffect } from "react";
import { Navigation } from "@/components/Navigation";

const NAVIGATION_LIST: Navigation[] = [
    { title: "マイページ", url: "/MyPage/Profile" },
    { title: "予約済みの講座一覧", url: "/MyPage/ReservedCourses" },
];

export const ReservedCourses: React.VFC = () => {
    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);

    const classesQuery = useGetReservedClassesQuery(studentId);

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    return (
        <MyPage title="予約済みの講座" activeTab="reservedCourses">
            <QueryLoadingWrapper {...classesQuery}>
                {(classes) => <ReservedCoursesContents classes={classes} />}
            </QueryLoadingWrapper>
        </MyPage>
    );
};
