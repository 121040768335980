import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { memo, useCallback } from "react";
import { Mandatory } from "@/components/Tag/Mandatory";

import styles from "@/pages/Teacher/CreateCourse/index.module.scss";

interface Props {
    isChecked: boolean;
    courseType: string | undefined;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    open?: boolean;
    validation: boolean;
    existStudent?: boolean;
}

export const CourseTypeComponent: React.VFC<Props> = memo(function CourseType(props) {
    const getCourseTypeLabel = useCallback(() => {
        if (props.courseType === "short") {
            return "単発・短期";
        } else if (props.courseType === "regular") {
            return "定期";
        }
    }, [props.courseType]);
    return (
        <li
            className={styles.listItem}
            style={{
                borderBottom: Boolean(props.courseType) ? "1px solid #CCC" : "none",
            }}
        >
            <div className={styles.leftWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>講座形態</div>
                    {!props.open && <Mandatory />}
                </div>
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>{getCourseTypeLabel()}</div>
                ) : (
                    <>
                        {props.existStudent ? (
                            <div className={styles.existStudentWrapper}>
                                <div className={styles.existStudent}>
                                    既に参加者がいるため、この項目は変更できません。
                                </div>
                                <div className={styles.existingValue}>{getCourseTypeLabel()}</div>
                            </div>
                        ) : (
                            <>
                                <FormControl component="fieldset" className={styles.formControl}>
                                    <RadioGroup
                                        aria-label="profile"
                                        name="courseType"
                                        value={props.courseType}
                                        onChange={props.handleChange}
                                        className={styles.radioGroup}
                                    >
                                        <FormControlLabel
                                            value="short"
                                            control={<Radio className={styles.radio} />}
                                            label={<span className={styles.label}>単発・短期</span>}
                                            className={styles.formControlLabel}
                                        />
                                        <FormControlLabel
                                            value="regular"
                                            control={<Radio className={styles.radio} />}
                                            label={<span className={styles.label}>定期</span>}
                                            className={styles.formControlLabel}
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <div className={styles.description}>
                                    ※
                                    単発・短期講座は日時・回数を指定して集中的に行う講座、定期講座は1対1で長い期間継続的に行う講座(オンライン家庭教師)のことを指します。
                                </div>
                                {props.isChecked && !props.validation && (
                                    <div className={styles.helperText}>選択してください。</div>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </li>
    );
});
