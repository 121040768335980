import { memo, useCallback, useEffect } from "react";

import { TextareaAutosize } from "@material-ui/core";

import styles from "@/pages/Teacher/InfoInput/index.module.scss";
import { None } from "../Errors/None";
import { Over } from "../Errors/Over";
import { Mandatory } from "@/components/Tag/Mandatory";

interface Props {
    handleChange?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    open?: boolean;
    value: string;
    checked: boolean;
    upperLimit?: number;
}

export const ContentDescription: React.VFC<Props> = memo(function ContentDescription(props) {
    const fixPlaceholder = useCallback(() => {
        const element: HTMLInputElement | null = document.getElementById("profileTextArea") as HTMLInputElement;
        if (element) {
            element.placeholder = element.placeholder.replace(/\\n/g, "\n");
        }
    }, []);

    useEffect(() => {
        fixPlaceholder();
    }, []);

    return (
        <div className={styles.inputItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitleWrapper}>
                    <span className={styles.itemTitle}>こんなこと教えます</span>
                </div>
                {!props.open && <Mandatory />}
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>{props.value}</div>
                ) : (
                    <>
                        <TextareaAutosize
                            required
                            placeholder="こんなこと教えます(1,000字以内)\n\n例）自分は理系で大学受験をしました。&#13;&#10;
                    英語・数学・物理・化学について満遍なく教えることができます。&#13;&#10;
                    特に数学は、元々苦手科目でしたがコツを掴み最終的には得意科目にできた科目なので、......"
                            className={styles.textArea}
                            name="contentDescription"
                            onChange={props.handleChange}
                            minRows={7}
                            id="profileTextArea"
                            value={props.value}
                        />
                        <div className={styles.wordCount}>
                            {props.value.length}/{props.upperLimit}文字
                        </div>
                        {props.checked && props.upperLimit && (
                            <Over value={props.value} upperLimit={props.upperLimit} />
                        )}
                        {props.checked && <None value={props.value} />}
                    </>
                )}
            </div>
        </div>
    );
});
