import { memo, useCallback, useEffect, useState } from "react";

import { Button, Modal } from "@material-ui/core";
import { useHistory } from "react-router";

import styles from "./index.module.scss";

interface Props {
    when: boolean;
    onOK: () => boolean;
    onCancel: () => boolean;
    message: string;
}

export const RouterPrompt: React.VFC<Props> = memo(function RouterPrompt(props) {
    const history = useHistory();

    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState("");

    useEffect(() => {
        if (props.when) {
            history.block((prompt) => {
                setCurrentPath(prompt.pathname);
                setShowPrompt(true);
                return false;
            });
        } else {
            history.block(() => {});
        }

        return () => {
            history.block(() => {});
        };
    }, [props.when]);

    const handleOK = useCallback(async () => {
        if (props.onOK) {
            const canRoute = await Promise.resolve(props.onOK());
            if (canRoute) {
                history.block(() => {});
                history.push(currentPath);
            }
        }
    }, [currentPath, history, props.onOK]);

    const handleCancel = useCallback(async () => {
        if (props.onCancel) {
            const canRoute = await Promise.resolve(props.onCancel());
            if (canRoute) {
                history.block(() => {});
                history.push(currentPath);
            }
        }
        setShowPrompt(false);
    }, [currentPath, history, props.onCancel]);

    return (
        <>
            <Modal
                open={showPrompt}
                onClose={() => {
                    (async () => {
                        await handleCancel();
                    })();
                }}
            >
                <div className={styles.promptModalContents}>
                    <div className={styles.message}>{props.message}</div>
                    <div className={styles.buttonsWrapper}>
                        <Button
                            className={styles.cancelButton}
                            onClick={() => {
                                (async () => {
                                    await handleCancel();
                                })();
                            }}
                        >
                            キャンセル
                        </Button>
                        <Button
                            className={styles.okButton}
                            onClick={() => {
                                (async () => {
                                    await handleOK();
                                })();
                            }}
                        >
                            OK
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
});
