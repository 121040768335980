import { useEffect } from "react";

import { useLocation } from "react-router";

import { FAQConfig } from "@/FAQConfig";
import { Navigation } from "@/components/Navigation";
import { Caution } from "@/pageComponents/Common/FAQDetails/AboutCourse/Caution";
import { CourseType } from "@/pageComponents/Common/FAQDetails/AboutCourse/CourseType";
import { ChangeCourseFee } from "@/pageComponents/Common/FAQDetails/AboutCourseCreation/ChangeCourseFee";
import { ForgotPassword } from "@/pageComponents/Common/FAQDetails/AboutLogin/ForgotPassword";
import { LoginFailed } from "@/pageComponents/Common/FAQDetails/AboutLogin/LoginFailed";
import { Message } from "@/pageComponents/Common/FAQDetails/AboutOperation/Message";
import { Profile } from "@/pageComponents/Common/FAQDetails/AboutOperation/Profile";
import { Contact } from "@/pageComponents/Common/FAQDetails/AboutOther/Contact";
import { Withdrawal } from "@/pageComponents/Common/FAQDetails/AboutOther/Withdrawal";
import { CancelCourse } from "@/pageComponents/Common/FAQDetails/AboutPayment/CancelCourse";
import { CreditCard } from "@/pageComponents/Common/FAQDetails/AboutPayment/CreditCard";
import { Receipt } from "@/pageComponents/Common/FAQDetails/AboutPayment/Receipt";
import { Refund } from "@/pageComponents/Common/FAQDetails/AboutPayment/Refund";
import { Tax } from "@/pageComponents/Common/FAQDetails/AboutPayment/Tax";
import { EmailNotArrived } from "@/pageComponents/Common/FAQDetails/AboutSignUp/EmailNotArrived";
import { TaxReturn } from "@/pageComponents/Common/FAQDetails/AboutTax/TaxReturn";
import { RegistrationRequired } from "@/pageComponents/Common/FAQDetails/AboutTrail/RegistrationRequired";
import { ServiceOfTrail } from "@/pageComponents/Common/FAQDetails/AboutTrail/ServiceOfTrail";
import { SupportTime } from "@/pageComponents/Common/FAQDetails/AboutTrail/SupportTime";
import { WhatToLearn } from "@/pageComponents/Common/FAQDetails/AboutTrail/WhatToLearn";
import { ReleaseInfo202404 } from "@/pageComponents/Common/FAQDetails/ReleaseInfo/ReleaseInfo202404";
import { AboutReview } from "@/pageComponents/Common/FAQDetails/RuleOfTrail/AboutReview";
import { ProhibitedAction } from "@/pageComponents/Common/FAQDetails/RuleOfTrail/ProhibitedAction";
import { RuleOrGuideline } from "@/pageComponents/Common/FAQDetails/RuleOfTrail/RuleOrGuideline";
import { FavoriteCourse } from "@/pageComponents/Common/FAQDetails/StudentGuideline/FavoriteCourse";
import { PostReview } from "@/pageComponents/Common/FAQDetails/StudentGuideline/PostReview";
import { SignUpAsStudent } from "@/pageComponents/Common/FAQDetails/StudentGuideline/SignUpAsStudent";
import { ReserveCourse } from "@/pageComponents/Common/FAQDetails/StudentGuideline/ReserveCourse";
import { SearchCourse } from "@/pageComponents/Common/FAQDetails/StudentGuideline/SearchCourse";
import { AboutTeacherDetailsPage } from "@/pageComponents/Common/FAQDetails/TeacherGuideline/AboutTeacherDetailsPage";
import { CancelHoldingCourse } from "@/pageComponents/Common/FAQDetails/TeacherGuideline/CancelHoldingCourse";
import { CheckReservation } from "@/pageComponents/Common/FAQDetails/TeacherGuideline/CheckReservation";
import { CourseFlow } from "@/pageComponents/Common/FAQDetails/TeacherGuideline/CourseFlow";
import { SignUpAsTeacher } from "@/pageComponents/Common/FAQDetails/TeacherGuideline/SignUpAsTeacher";
import { TeacherVerification } from "@/pageComponents/Common/FAQDetails/TeacherGuideline/TeacherVerification";

import styles from "./index.module.scss";
import { MonthlyFee } from "@/pageComponents/Common/FAQDetails/AboutPayment/MonthlyFee";
import { RefundForNonOpening } from "@/pageComponents/Common/FAQDetails/AboutPayment/RefundForNonOpening";
import { useNavigation } from "@/components/Navigation/NavigationContext";

export const FAQDetails: React.VFC = () => {
    const query = new URLSearchParams(useLocation().search);
    const type = query.get("type");

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        const category = FAQConfig.filter((categoriesList) =>
            categoriesList.contents.some((categories) =>
                categories.contents.some((category) => category.type === type),
            ),
        )[0].contents.filter((categories) => categories.contents.some((category) => category.type === type))[0];
        const categoryTitle = category.title;
        const categoryType = category.type;
        const detailsTitle = category.contents[0].title;

        const navigationList: Navigation[] = [
            {
                title: "ヘルプ・よくある質問",
                url: "/FAQ",
            },
            {
                title: categoryTitle,
                url: `/FAQCategory?type=${categoryType}`,
            },
            {
                title: detailsTitle,
                url: `/FAQCategory?type=${type}`,
            },
        ];
        updateNavigationList(navigationList);
    }, []);
    return (
        <div className={styles.FAQDetailsWrapper}>
            <div className={styles.FAQDetails}>
                <>
                    {/* StudentGuideline */}
                    {type === "signUpAsStudent" && <SignUpAsStudent />}
                    {type === "searchCourse" && <SearchCourse />}
                    {type === "favoriteCourse" && <FavoriteCourse />}
                    {type === "reserveCourse" && <ReserveCourse />}
                    {type === "postReview" && <PostReview />}
                </>
                <>
                    {/* TeacherGuideline */}
                    {type === "signUpAsTeacher" && <SignUpAsTeacher />}
                    {type === "aboutTeacherDetailsPage" && <AboutTeacherDetailsPage />}
                    {type === "courseFlow" && <CourseFlow />}
                    {type === "checkReservation" && <CheckReservation />}
                    {type === "cancelHoldingCourse" && <CancelHoldingCourse />}
                    {type === "teacherVerification" && <TeacherVerification />}
                </>
                <>
                    {/* AboutTrail */}
                    {type === "serviceOfTrail" && <ServiceOfTrail />}
                    {type === "whatToLearn" && <WhatToLearn />}
                    {type === "supportTime" && <SupportTime />}
                    {type === "registrationRequired" && <RegistrationRequired />}
                </>
                <>
                    {/* RuleOfTrail */}
                    {type === "ruleOrGuideline" && <RuleOrGuideline />}
                    {type === "prohibitedAction" && <ProhibitedAction />}
                    {type === "aboutReview" && <AboutReview />}
                </>
                <>
                    {/* AboutSignUp */}
                    {type === "emailNotArrived" && <EmailNotArrived />}
                </>
                <>
                    {/* {AboutLogin} */}
                    {type === "loginFailed" && <LoginFailed />}
                    {type === "forgotPassword" && <ForgotPassword />}
                </>
                <>
                    {/* {AboutPayment} */}
                    {type === "receipt" && <Receipt />}
                    {type === "cancelCourse" && <CancelCourse />}
                    {type === "refund" && <Refund />}
                    {type === "refundForNonOpening" && <RefundForNonOpening />}
                    {type === "creditCard" && <CreditCard />}
                    {type === "tax" && <Tax />}
                    {type === "monthlyFee" && <MonthlyFee />}
                </>
                <>
                    {/* AboutOperation */}
                    {type === "message" && <Message />}
                    {type === "profile" && <Profile />}
                </>
                <>
                    {/* AboutCourse */}
                    {type === "caution" && <Caution />}
                    {type === "courseType" && <CourseType />}
                </>
                <>
                    {/* AboutCourseCreation */}
                    {type === "changeCourseFee" && <ChangeCourseFee />}
                </>
                <>
                    {/* AboutTax */}
                    {type === "taxReturn" && <TaxReturn />}
                </>
                <>
                    {/* AboutOther */}
                    {type === "contact" && <Contact />}
                    {type === "withdrawal" && <Withdrawal />}
                </>
                <>
                    {/* ReleaseInfo */}
                    {type === "ReleaseInfo202404" && <ReleaseInfo202404 />}
                </>
            </div>
        </div>
    );
};
