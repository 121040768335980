import { FaFistRaised, FaPlus, FaRegLightbulb } from "react-icons/fa";

import styles from "@/pages/Common/AboutRequest/index.module.scss";

export const ShortCourse: React.VFC = () => {
    return (
        <div className={styles.courseTypeWrapper}>
            <div className={styles.titleWrapper}>
                <div className={styles.outline}>最短1回からピンポイントで</div>
                <div className={styles.courseTypeTitle}>単発・短期講座</div>
                <div className={styles.descriptionWrapper}>
                    <div className={styles.description}>Trailでは、先生によって講座の内容は千差万別。</div>
                    <div className={styles.description}>
                        気になった講座を最短1回からピンポイントで受けることができます。
                    </div>
                </div>
            </div>
            <div className={styles.recommendWrapper}>
                <div className={styles.recommendTitle}>こんな方におすすめ</div>
                <ul className={styles.recommendList}>
                    <li className={styles.recommendItem}>
                        <FaRegLightbulb className={styles.icon} />
                        <div className={styles.description}>Trailに興味があるけど、まずはお試ししてみたい</div>
                    </li>
                    <li className={styles.recommendItem}>
                        <FaFistRaised className={styles.icon} />
                        <div className={styles.description}>苦手分野を集中的に潰したい</div>
                    </li>
                    <li className={styles.recommendItem}>
                        <FaPlus className={styles.icon} />
                        <div className={styles.description}>必要に応じて授業の補修をしたい</div>
                    </li>
                </ul>
            </div>
        </div>
    );
};
