import { Button } from "@material-ui/core";
import { memo, useCallback } from "react";
import { useHistory } from "react-router";
import styles from "./index.module.scss";

export const CompletedWithdrawal: React.VFC = memo(function CompletedWithdrawal() {
    const history = useHistory();
    const handleHomeButtonClick = useCallback(() => {
        history.push("/");
    }, []);
    return (
        <div className={styles.completedWithdrawalWrapper}>
            <div className={styles.completedWithdrawalContents}>
                <div className={styles.thanks}>Trailをご利用いただきありがとうございました。</div>
                <Button className={styles.homeButton} onClick={handleHomeButtonClick}>
                    ホームに戻る
                </Button>
            </div>
        </div>
    );
});
