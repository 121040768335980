import {
    Button,
    CircularProgress,
    // Button,
    Modal,
    // TextField
} from "@material-ui/core";

import styles from "./index.module.scss";

// import googleButton from "./images/googleButton.png";

interface Props {
    modalOpen: boolean;
    isDetaching: boolean;
    handleClose: () => void;
    handleDeleteConfirmButtonClick: () => void;
}

export const DeleteConfirmationModal: React.VFC<Props> = (props) => {
    return (
        <Modal open={props.modalOpen} onClose={props.handleClose} className={styles.deleteConfirmationModal}>
            <div className={styles.deleteConfirmationModalContents}>
                <div className={styles.confirmMessage}>この項目を削除してもよろしいですか？</div>
                <div className={styles.buttonsWrapper}>
                    <Button className={styles.backButton} onClick={props.handleClose}>
                        キャンセル
                    </Button>
                    <Button className={styles.deleteButton} onClick={props.handleDeleteConfirmButtonClick}>
                        {props.isDetaching ? (
                            <CircularProgress className={styles.isDetaching} />
                        ) : (
                            <div className={styles.detach}>削除</div>
                        )}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
