import { FAQConfig } from "@/FAQConfig";

export const RESERVE_COURSE_CONFIGS: FAQConfig[] = [
    {
        title: "単発・短期講座の受講予約",
        text: "単発・短期講座の受講予約をする手順について説明します。",
        contents: [
            {
                title: "①講座詳細ページに移動",
                text: "講座詳細ページを開いてください。",
            },
            {
                title: "②「受講予約」ボタンを押す",
                text: "内容を確認して、よろしければ「受講予約」ボタンを押してください。",
            },
            {
                title: "③「確定」ボタンを押す",
                text: "単発・短期講座では、講座予約の際にポイントが差し引かれます。内容を確認して、よろしければ「確定」ボタンを押してください。",
            },
            {
                title: "④クラスページに移動",
                text: "講座予約が完了すると、クラスページへ移動できるようになります。クラスページに移動してください。",
            },
            {
                title: "⑤授業予定の確認",
                text: "クラスページの「詳細」タブから、予約した講座の詳細を確認できます。忘れずに受講しましょう。",
            },
        ],
    },
    {
        title: "定期講座の受講予約",
        text: "定期講座の受講予約をする手順について説明します。",
        contents: [
            {
                title: "①講座詳細ページに移動",
                text: "講座詳細ページを開いてください。",
            },
            {
                title: "②「受講予約」ボタンを押す",
                text: "内容を確認して、よろしければ「受講予約」ボタンを押してください。",
            },
            {
                title: "③「確定」ボタンを押す",
                text: "定期講座では、講座予約の際のポイント変動はありません。内容を確認して。よろしければ「確定」ボタンを押してください。",
            },
            {
                title: "④クラスページに移動",
                text: "講座予約が完了すると、クラスページへ移動できるようになります。クラスページに移動してください。",
            },
            {
                title: "⑤授業予定の確認",
                text: "クラスページの「詳細」タブから、予約した講座の詳細を確認できます。",
            },
            {
                title: "⑥個々の授業予約",
                text: "定期講座では、先生が追加した日程のそれぞれに対して、個々の授業予約をする必要があります。授業を受けたい場合「予約する」を押してください。",
            },
            {
                title: "⑦「確定」ボタンを押す",
                text: "内容を確認して。よろしければ「確定」ボタンを押してください。",
            },
            {
                title: "⑧授業予約の確認",
                text: "「授業を受ける」ボタンが表示されていれば、その授業の予約が完了しています。忘れずに受講しましょう。",
            },
        ],
    },
];
