import { Button } from "@material-ui/core";
import { memo, useCallback } from "react";

import styles from "./index.module.scss";
import { MessageResponse } from "@/store/autogenApi";
import { ChatInfo } from "./ChatsContents";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    latestMessage: MessageResponse | undefined;
    unreadLength: number;
    chatInfo: ChatInfo | undefined;
    userMode: string;
    pointerEventsValue: string;
    time: string;
    isTarget: boolean;
    handleChatItemButtonClick: () => void;
}

export const ChatItemButton: React.VFC<Props> = memo(function ChatItemButton(props) {
    const getUrl = useCallback(() => {
        if (!props.chatInfo) return "";
        if (props.chatInfo.chatType === "individual") {
            if (props.userMode === "student") {
                return props.chatInfo.teachers[0].iconImageUrl;
            } else {
                return props.chatInfo.students[0].iconImageUrl;
            }
        }
        return props.chatInfo.class?.course?.thumbnailUrl;
    }, [props.chatInfo, props.userMode]);

    const getObjectKey = useCallback(() => {
        if (!props.chatInfo) return "";
        if (props.chatInfo.chatType === "individual") {
            if (props.userMode === "student") {
                return props.chatInfo.teachers[0].iconImageObjectKey;
            } else {
                return props.chatInfo.students[0].iconImageObjectKey;
            }
        }
        return props.chatInfo.class?.course?.thumbnailObjectKey;
    }, [props.chatInfo, props.userMode]);

    const getNickname = useCallback(() => {
        if (!props.chatInfo) return "";
        if (props.chatInfo.chatType === "individual") {
            if (props.userMode === "student") {
                return props.chatInfo.teachers[0].nickname;
            } else {
                return props.chatInfo.students[0].nickname;
            }
        }
        const howManyParticipants = props.chatInfo.students.length + props.chatInfo.teachers.length;
        return `${props.chatInfo.class?.className}【${props.chatInfo.class?.course?.title}】(${howManyParticipants})`;
    }, [props.chatInfo, props.userMode]);
    return (
        <Button
            className={styles.messageItemButton}
            onClick={props.handleChatItemButtonClick}
            style={{
                pointerEvents: props.pointerEventsValue as any,
                backgroundColor: props.isTarget ? "transparent" : "#FFF",
            }}
        >
            <AvatarFromS3 url={getUrl()} objectKey={getObjectKey()} className={styles.avatar} />
            <div className={styles.centerWrapper}>
                <div className={styles.nickname}>{getNickname()}</div>
                <div className={styles.latestMessage}>{props.latestMessage?.text}</div>
            </div>
            <div className={styles.rightWrapper}>
                <div className={styles.diffTime}>{props.time}</div>
                <div
                    className={styles.unreadLength}
                    style={{ backgroundColor: props.unreadLength > 0 ? "#C09547" : "transparent" }}
                >
                    {props.unreadLength > 0 ? props.unreadLength : ""}
                </div>
            </div>
        </Button>
    );
});
