import { OverWrapper } from "@/components/OverWrapper";
import styles from "@/pages/Common/LP/index.module.scss";

export const EasyToStart: React.VFC = () => {
    return (
        <OverWrapper style={{ backgroundColor: "#e7f3ff" }}>
            <div className={styles.easyToStartWrapper}>
                <div className={styles.easyToStartTitle}>簡単に始められる3つの特徴</div>
                <div className={styles.features}>
                    <div className={styles.feature}>
                        <div className={styles.featureDescription}>入会金・登録料</div>
                        <div className={styles.featureStrongDescription}>無料！</div>
                    </div>
                    <div className={styles.feature}>
                        <div className={styles.featureDescription}>講座作成/管理・授業・決済まで</div>
                        <div className={styles.featureStrongDescription}>オンラインで完結！</div>
                    </div>
                    <div className={styles.feature}>
                        <div className={styles.featureDescription}>あなたのバックグラウンドや得意を活かそう</div>
                        <div className={styles.featureStrongDescription}>年齢不問・未経験も大歓迎！</div>
                    </div>
                </div>
            </div>
        </OverWrapper>
    );
};
