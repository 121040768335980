import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

import styles from "../index.module.scss";

export const ForNonUser: React.VFC = () => {
    return (
        <div className={styles.forNonUser}>
            <Button className={styles.signUpButton} variant="contained" component={Link} to="/SignUp">
                新規登録
            </Button>
            <Button className={styles.signInButton} variant="contained" component={Link} to="/SignIn">
                ログイン
            </Button>
        </div>
    );
};
