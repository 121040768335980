import { Button, TextareaAutosize } from "@material-ui/core";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { ErrorMessage } from "../../ErrorMessage";
import { Processing } from "../../Processing";
import { ToastContents } from "../../Toast";
import styles from "../index.module.scss";
import { StudentToDoResponse } from "@/store/autogenApi";
import {
    useDisapproveTemporaryUpdateMonthlyFeeMutation,
    useDisapproveUpdateMonthlyFeeMutation,
} from "@/store/hooks/classes";
import { MutationState } from "@/store/hooks/utils/mutationFunction";

interface Props {
    targetStudentToDo: StudentToDoResponse | null;
    maxHeight: number;
    handleClose: () => void;
    handleBackButtonClick: () => void;
}

export const DisapprovalModeContents: React.VFC<Props> = (props) => {
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [disapprovalMessage, setDisapprovalMessage] = useState<string>("");
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const disapproveUpdateMonthlyFee = useDisapproveUpdateMonthlyFeeMutation();
    const disapproveTemporaryUpdateMonthlyFee = useDisapproveTemporaryUpdateMonthlyFeeMutation();

    const handleDisapprovalCommentChange = useCallback((e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        setDisapprovalMessage(e.target.value as string);
    }, []);

    const handleConfirmButtonClick = useCallback(async () => {
        setIsChecked(true);
        if (disapprovalMessage.length < 10 || 500 < disapprovalMessage.length) return;
        if (!props.targetStudentToDo) return;
        const toDoType = props.targetStudentToDo?.toDoType;
        if (toDoType !== "approveUpdateMonthlyFee" && toDoType !== "approveTemporaryUpdateMonthlyFee") return;
        setIsProcessing(true);
        let result: MutationState<unknown>;
        if (toDoType === "approveUpdateMonthlyFee") {
            const subscription = props.targetStudentToDo.subscription;
            if (!subscription) {
                setIsProcessing(false);
                return;
            }
            result = await disapproveUpdateMonthlyFee({
                disapproveUpdateMonthlyFeeRequestBody: {
                    subscriptionId: subscription.subscriptionId,
                    studentToDoId: props.targetStudentToDo.studentToDoId,
                    message: disapprovalMessage,
                },
            });
        } else {
            const subscriptionSchedule = props.targetStudentToDo.subscriptionSchedule;
            if (!subscriptionSchedule) {
                setIsProcessing(false);
                return;
            }
            result = await disapproveTemporaryUpdateMonthlyFee({
                disapproveTemporaryUpdateMonthlyFeeRequestBody: {
                    subscriptionScheduleId: subscriptionSchedule.subscriptionScheduleId,
                    studentToDoId: props.targetStudentToDo.studentToDoId,
                    message: disapprovalMessage,
                },
            });
        }
        setIsProcessing(false);
        props.handleClose();
        if (result.isSuccess) {
            toast(<ToastContents title="月額更新申請非承認完了" isCompleted />);
        } else {
            toast(<ToastContents title="月額更新申請非承認失敗" isFailed />);
        }
    }, [
        props.targetStudentToDo,
        disapprovalMessage,
        disapproveUpdateMonthlyFee,
        disapproveTemporaryUpdateMonthlyFee,
        props.handleClose,
    ]);

    return (
        <div className={styles.disapprovalWrapper} style={{ maxHeight: props.maxHeight }}>
            <div className={styles.disapprovalTitle}>非承認</div>
            <div className={styles.pleaseInput}>非承認の理由を記入して、「確定」を押してください。</div>
            <TextareaAutosize
                className={styles.disapprovalComment}
                value={disapprovalMessage}
                onChange={handleDisapprovalCommentChange}
                placeholder="10文字以上500文字以内"
                minRows={5}
                maxRows={10}
            />
            <ErrorMessage
                content="10文字以上500文字以内で入力してください。"
                when={isChecked && (disapprovalMessage.length < 10 || 500 < disapprovalMessage.length)}
            />
            <div className={styles.buttonsWrapper}>
                <Button className={styles.backButton} onClick={props.handleBackButtonClick}>
                    戻る
                </Button>
                <Button className={styles.disapprovalConfirmButton} onClick={handleConfirmButtonClick}>
                    {isProcessing ? <Processing /> : "確定"}
                </Button>
            </div>
        </div>
    );
};
