import { memo, useCallback, useEffect } from "react";
import { NewsRecordResponse } from "@/store/autogenApi";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import styles from "./index.module.scss";
import { dateToOnlyDateString } from "@/utils/DateUtils";
import { useHistory } from "react-router";

interface Props {
    newsRecords: NewsRecordResponse[];
}

const NAVIGATION_LIST = [
    {
        title: "ニュース一覧",
        url: "/NewsList",
    },
];

export const MainContents: React.VFC<Props> = memo(function MainContents(props) {
    const { updateNavigationList } = useNavigation();
    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    const history = useHistory();
    const handleNewsRecordClick = useCallback((newsRecord: NewsRecordResponse) => {
        history.push(`/News/${newsRecord.newsRecordId}`);
    }, []);

    return (
        <div className={styles.mainContents}>
            <div className={styles.title}>ニュース一覧</div>
            <ul className={styles.newsRecords}>
                {props.newsRecords.map((newsRecord, idx) => (
                    <li
                        className={styles.newsRecord}
                        style={{ borderTop: idx === 0 ? "none" : "1px solid #999" }}
                        onClick={() => {
                            handleNewsRecordClick(newsRecord);
                        }}
                    >
                        <div className={styles.newsRecordDate}>
                            {dateToOnlyDateString(newsRecord.createdAt ? new Date(newsRecord.createdAt) : undefined)}
                        </div>
                        <div className={styles.newsRecordTitle}>{newsRecord.title}</div>
                    </li>
                ))}
            </ul>
        </div>
    );
});
