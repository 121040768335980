import { memo } from "react";
import styles from "./index.module.scss";

interface Props {
    children: React.ReactNode;
    formTitle?: string;
    formDescription?: string;
}

export const Form: React.VFC<Props> = memo(function Form(props) {
    return (
        <div className={styles.formWrapper}>
            {props.formTitle && <div className={styles.formTitle}>{props.formTitle}</div>}
            {props.formDescription && <div className={styles.formDescription}>{props.formDescription}</div>}
            <ul className={styles.formItemList}>{props.children}</ul>
        </div>
    );
});
