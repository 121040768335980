import { memo } from "react";
import styles from "@/pages/Teacher/Identification/index.module.scss";

export const Description: React.VFC = memo(function Description() {
    return (
        <div className={styles.descriptionWrapper}>
            <div className={styles.identificationTitle}>本人確認</div>
            <div className={styles.descriptionWrapper}>
                <div className={styles.description}>
                    Trailでは安心・安全に講座が行えるように先生の皆様には本人確認をお願いしております。
                </div>
                <div className={styles.description}>
                    本名の入力、顔写真のついた身分証の写しと顔写真の提出をそれぞれしていただき、こちらで審査をいたします。
                </div>
                <div className={styles.description}>本人確認の審査が終わるまでは一部機能を制限させていただきます。</div>
                <div className={styles.description}>
                    審査に通りましたら「本人確認済みバッジ」が付与され、それ以降制限なくTrailをご利用いただけます。
                </div>
                <div className={styles.description}>
                    また、学生証や学位記等、学歴の証明になるものを合わせてご提示いただきました場合、大学・学部・学科についても認定いたします。
                </div>
            </div>
        </div>
    );
});
