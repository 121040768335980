import { memo, useCallback } from "react";

import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";

import styles from "@/pageComponents/Common/InfoInput/termsOfService.module.scss";
import { TermsOfServiceContents } from "@/components/TermsOfServiceContents";

interface Props {
    checked: boolean;
    changeTermsOfServiceIsChecked: () => void;
}

export const TermsOfService: React.VFC<Props> = memo((props) => {
    const changeChecked = useCallback(() => {
        props.changeTermsOfServiceIsChecked();
    }, [props.changeTermsOfServiceIsChecked]);

    return (
        <div className={styles.termsOfService}>
            <TermsOfServiceContents className={styles.scroll} />
            <FormControlLabel
                className={styles.formControlLabel}
                control={<Checkbox checked={props.checked} onChange={changeChecked} name="checked" />}
                label="利用規約に同意"
            />
        </div>
    );
});
