import { Button } from "@material-ui/core";
import { BsCheckCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";

import styles from "@/pages/Common/CourseDetails/index.module.scss";
import { UserType } from "@/protectedRouter/ProtectedRouterConfig";
import { ClassResponse } from "@/store/autogenApi";
import { Favorite } from "../../Favorite";

import { SelectButtonAndPopover } from "./SelectButtonAndPopover";

interface Props {
    frequency?: string;
    smUp: boolean;
    courseId: string;
    howMuchLackOfPoints: number;
    title?: string;
    isFavorite: boolean;
    targetClass: ClassResponse | undefined;
    isReserved: boolean;
    isHostTeacher: boolean;
    userMode: UserType;
    addScheduleModalOpen: boolean;
    activeClasses: ClassResponse[];
    fixedContentsEl: React.MutableRefObject<HTMLElement | null>;
    totalNecessaryPoints: number;
    formula: string;
    isReservationButtonClicked: boolean;
    thresholdOver: boolean;
    getJapaneseDay: (idx: number) => string;
    getDiffTime: (date: Date) => string;
    handleTargetClassChange: (classId: string | undefined) => void;
    handleChargeButtonClick: () => void;
    handleReservationButtonClick: (classId: string) => void;
    handleAddScheduleModalOpenButtonClick: () => void;
    handleAddScheduleModalClose: () => void;
    handleClassButtonClick: (classId: string) => void;
    handleSignUpModalOpen: () => void;
    initializeReservationButtonClicked: () => void;
}

export const PCContents: React.VFC<Props> = (props) => {
    return (
        <div
            className={styles.fixedContents}
            ref={(node: HTMLElement | null) => {
                props.fixedContentsEl.current = node;
            }}
        >
            <div className={styles.reservationWrapper}>
                {props.activeClasses.length > 0 ? (
                    <>
                        {props.userMode !== "teacher" && (
                            <SelectButtonAndPopover
                                smUp={props.smUp}
                                targetClass={props.targetClass}
                                activeClasses={props.activeClasses}
                                getJapaneseDay={props.getJapaneseDay}
                                handleTargetClassChange={props.handleTargetClassChange}
                            />
                        )}
                        {props.isHostTeacher ? (
                            <div className={styles.yourWrapper}>
                                <div className={styles.yourCourse}>あなたが作成した講座です。</div>
                                <Button
                                    className={styles.courseManagementButton}
                                    component={Link}
                                    to={`/CourseManagement/${props.courseId}`}
                                >
                                    講座管理
                                </Button>
                            </div>
                        ) : (
                            <>
                                {props.activeClasses.map((classResponse) => {
                                    const deadline = classResponse.applyingDeadline;
                                    if (classResponse.lessons && classResponse.lessons[0]) {
                                        if (
                                            deadline &&
                                            classResponse.participants &&
                                            classResponse.classId === props.targetClass?.classId
                                        ) {
                                            const fixedDeadline = new Date(deadline);
                                            const number = classResponse.participants.length;
                                            return (
                                                <div key={classResponse.classId}>
                                                    <div
                                                        className={styles.deadlineWrapper}
                                                        style={{ marginTop: "10px" }}
                                                    >
                                                        <div className={styles.deadlineTitle}>
                                                            締め切りまでの残り時間
                                                        </div>
                                                        <div className={styles.deadline}>
                                                            {props.getDiffTime(fixedDeadline)}
                                                        </div>
                                                    </div>
                                                    <div className={styles.theNumberOfParticipantsWrapper}>
                                                        <div className={styles.theNumberOfParticipantsTitle}>
                                                            参加人数
                                                        </div>
                                                        <div
                                                            className={styles.theNumberOfParticipants}
                                                        >{`${number}人 / ${classResponse.maxParticipants}人`}</div>
                                                        {number === classResponse.maxParticipants && (
                                                            <div className={styles.reachedMaxNumber}>
                                                                定員に達しました。
                                                            </div>
                                                        )}
                                                    </div>
                                                    {props.targetClass.pricePerPayment && (
                                                        <div className={styles.priceWrapper}>
                                                            <div className={styles.priceTitle}>価格</div>
                                                            <div className={styles.price}>
                                                                ￥
                                                                {new Intl.NumberFormat("ja-JP").format(
                                                                    Math.floor(props.targetClass.pricePerPayment),
                                                                )}
                                                            </div>
                                                            <div className={styles.formula}>{props.formula}</div>
                                                        </div>
                                                    )}
                                                    {props.userMode !== "teacher" && (
                                                        <>
                                                            {props.isReserved ? (
                                                                <>
                                                                    <div className={styles.reservedWrapper}>
                                                                        <BsCheckCircleFill
                                                                            className={styles.checkIcon}
                                                                        />
                                                                        <div className={styles.reserved}>予約済み</div>
                                                                    </div>
                                                                    <Button
                                                                        className={styles.classButton}
                                                                        variant="contained"
                                                                        onClick={() => {
                                                                            classResponse.classId &&
                                                                                props.handleClassButtonClick(
                                                                                    classResponse.classId,
                                                                                );
                                                                        }}
                                                                    >
                                                                        授業管理
                                                                    </Button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {classResponse.maxParticipants &&
                                                                        number < classResponse.maxParticipants && (
                                                                            <Button
                                                                                className={styles.reservationButton}
                                                                                variant="contained"
                                                                                onClick={() => {
                                                                                    props.handleReservationButtonClick(
                                                                                        classResponse.classId,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                予約へ進む
                                                                            </Button>
                                                                        )}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            );
                                        }
                                    }
                                })}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <div className={styles.noSchedule}>現在、受講者募集中のクラスはありません。</div>
                    </>
                )}
            </div>
            <Favorite
                userMode={props.userMode}
                isReservationButtonClicked={props.isReservationButtonClicked}
                handleSignUpModalOpen={props.handleSignUpModalOpen}
                initializeReservationButtonClicked={props.initializeReservationButtonClicked}
            />
        </div>
    );
};
