import { useEffect, useState } from "react";

import { MdOutlineSearch } from "react-icons/md";
import { useHistory, useLocation } from "react-router";

import styles from "./index.module.scss";

export const SearchFAQ: React.VFC = () => {
    const [keyword, setKeyword] = useState<string>("");
    const query = new URLSearchParams(useLocation().search);
    const queryKeyword = query.get("keyword");
    useEffect(() => {
        if (queryKeyword != null && queryKeyword !== keyword) {
            setKeyword(queryKeyword);
        }
    }, []);
    const handleKeywordChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const value = e.target.value as string;
        setKeyword(value);
    };
    const history = useHistory();
    const handleSearch = () => {
        history.push(`/FAQSearchResult?keyword=${keyword}`);
    };
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.nativeEvent.isComposing || e.key !== "Enter") return;
        handleSearch();
    };
    return (
        <div className={styles.searchFAQWrapper}>
            <input
                type="text"
                className={styles.input}
                placeholder="キーワードで検索"
                value={keyword}
                onChange={handleKeywordChange}
                onKeyDown={handleKeyDown}
            />
            <button className={styles.button} onClick={handleSearch}>
                <MdOutlineSearch className={styles.searchIcon} />
            </button>
        </div>
    );
};
