import { FAQConfig } from "@/FAQConfig";

export const REFUND_CONFIGS: FAQConfig[] = [
    {
        title: "単発・短期講座",
        text: "単発・短期講座を受講停止した場合の返金についてご説明します。",
        contents: [
            {
                title: "募集締切前に受講停止した場合",
                text: "受講料の100%が返金されます。",
            },
            {
                title: "募集締切後に受講停止した場合",
                text: "受講料は返金されません。",
            },
        ],
    },
    {
        title: "定期講座",
        text: "定期講座を受講停止した場合の返金についてご説明します。",
        contents: [
            {
                title: "講座開始前に受講停止した場合",
                text: "受講料の100%が返金されます。",
            },
            {
                title: "講座開始後に受講停止した場合",
                text: "現在の請求期間の残り日数に応じて、受講料の返金額が日割り計算によって変動します。請求期間は、ある請求日から次の請求日の前日までの期間で、請求期間の残り日数は、講座開始日の前日までの日数です。請求期間の残り日数が0日の場合は、受講料は返金されません。",
            },
        ],
    },
];
