import { Modal, useMediaQuery } from "@material-ui/core";
import { memo, useCallback, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { ProposalResponse } from "@/store/autogenApi";
import { WhiteButton } from "@/components/Buttons/WhiteButton";
import { NavyButton } from "@/components/Buttons/NavyButton";
import { useHistory } from "react-router";
import { FormItem } from "@/components/FormItem";

interface Props {
    targetProposal: ProposalResponse;
    handleProposalDetailsModalClose: () => void;
}

export const ProposalDetailsModal: React.VFC<Props> = memo(function ProposalDetailsModal(props: Props) {
    const [proposalContent, setProposalContent] = useState<string | undefined>(undefined);

    useEffect(() => {
        const content = props.targetProposal.content;
        // 100文字以上の場合は、100文字まで表示する
        if (content.length > 100) {
            setProposalContent(content.slice(0, 100) + "...");
        } else {
            setProposalContent(content);
        }
    }, [props.targetProposal]);

    const history = useHistory();

    const mdUp = useMediaQuery("(min-width:960px)");

    const handlePublicRequestDetailsButtonClick = useCallback(() => {
        props.handleProposalDetailsModalClose();
        history.push(`/PublicRequestDetails/${props.targetProposal.publicRequest?.publicRequestId}`);
    }, [props.targetProposal]);

    const handleCourseManagementButtonClick = useCallback(() => {
        props.handleProposalDetailsModalClose();
        history.push(`/CourseManagement/${props.targetProposal.class?.course?.courseId}`);
    }, [props.targetProposal]);

    const getPrice = useCallback(() => {
        const courseType = props.targetProposal?.publicRequest?.courseType;
        const pricePerPayment = props.targetProposal?.pricePerPayment;
        if (!courseType || !pricePerPayment) return "";
        if (courseType === "short") {
            return `¥${new Intl.NumberFormat("ja-JP").format(pricePerPayment)}`;
        }
        if (courseType === "regular") {
            return `¥${new Intl.NumberFormat("ja-JP").format(pricePerPayment)}/月`;
        }
    }, [props.targetProposal]);

    return (
        <Modal open={true} onClose={props.handleProposalDetailsModalClose}>
            <div className={styles.proposalDetailsModalContents}>
                <div className={styles.topFlex}>
                    <div className={styles.proposalWrapper}>
                        <div className={styles.proposalTitle}>提案</div>
                        <ul className={styles.proposalItems}>
                            <FormItem itemTitle="内容" itemDescription={proposalContent} isFirstItem />
                            <FormItem itemTitle="価格" itemDescription={getPrice()} />
                        </ul>
                    </div>
                    <div className={styles.publicRequestWrapper}>
                        <div className={styles.publicRequestTitle}>講座リクエスト</div>
                        <ul className={styles.publicRequestItems}>
                            <FormItem
                                itemTitle="タイトル"
                                itemDescription={props.targetProposal.publicRequest?.title}
                                isFirstItem
                            />
                        </ul>
                    </div>
                </div>
                <div className={styles.buttonsWrapper}>
                    {props.targetProposal.class?.course ? (
                        <>
                            <WhiteButton handleClick={handlePublicRequestDetailsButtonClick} fontSize={mdUp ? 15 : 14}>
                                講座リクエストを確認
                            </WhiteButton>
                            <NavyButton handleClick={handleCourseManagementButtonClick} fontSize={mdUp ? 15 : 14}>
                                講座管理
                            </NavyButton>
                        </>
                    ) : (
                        <NavyButton handleClick={handlePublicRequestDetailsButtonClick} fontSize={mdUp ? 15 : 14}>
                            提案を確認
                        </NavyButton>
                    )}
                </div>
            </div>
        </Modal>
    );
});
