import { Button, CircularProgress, TextField } from "@material-ui/core";
import { memo, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ErrorMessage } from "@/components/ErrorMessage";

import styles from "@/pages/Common/SignUp/index.module.scss";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router";
import { useCreateJWTMutation } from "@/store/hooks/jwt";

import { logout, transition } from "@/actions/logging";
import { CurrentCognitoUserContext } from "@/components/Authentication";
import { TextInput } from "@/components/TextInput";
import { Password } from "@/components/Password";

interface Props {
    setSignUpMode: React.Dispatch<React.SetStateAction<string>>;
    setByEmail: React.Dispatch<React.SetStateAction<boolean>>;
    signUpMode: string;
    userId: string | undefined;
    setUserId: React.Dispatch<React.SetStateAction<string | undefined>>;
    isSentVerificationCode: boolean;
    setIsSentVerificationCode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SignUpByEmail: React.VFC<Props> = memo(function SignUpByEmail(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isValidPassword, setIsValidPassword] = useState(false);
    const [isResent, setIsResent] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [isProcessingForVerifying, setIsProcessingForVerifying] = useState<boolean>(false);
    const [isValidVerificationCode, setIsValidVerificationCode] = useState(true);
    const [isProcessingForSendingVerificationCode, setIsProcessingForSendingVerificationCode] =
        useState<boolean>(false);
    const [verificationCode, setVerificationCode] = useState<string>("");

    const dispatch = useDispatch();
    const history = useHistory();

    const createJWTMutation = useCreateJWTMutation();

    useEffect(() => {
        if (!isVerified) return;
        if (props.userId == undefined) return;
        (async () => {
            dispatch(transition(props.userId as string));
            const tokenMutationState = await createJWTMutation({
                jwtRequestBody: {
                    login: false,
                    userMode: "anonymous",
                    userId: props.userId ?? null,
                    studentId: null,
                    teacherId: null,
                },
            });
            if (tokenMutationState.isSuccess) {
                localStorage.setItem("token", tokenMutationState.data);
            }
            if (props.signUpMode === "student") {
                history.push("/InfoInput");
            } else {
                history.push("/Teacher/InfoInput");
            }
        })();
        return () => {
            // 要チェック
            if (!isVerified && props.userId) {
                (async () => {
                    await Auth.deleteUser();
                    Auth.signOut();
                    dispatch(logout());
                    localStorage.clear();
                })();
            }
        };
    }, [isVerified, props.userId, props.signUpMode]);

    const regexp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.+-]*@[A-Za-z0-9_.-]+\.[A-Za-z0-9]+$/;
    const handleEmailChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
            setIsValidEmail(regexp.test(event.target.value));
        },
        [regexp],
    );

    const ratz = /[a-z]/,
        rAtZ = /[A-Z]/,
        r0t9 = /[0-9]/;
    const handlePasswordChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            setPassword(value);
            const result = ratz.test(value) && rAtZ.test(value) && r0t9.test(value) && value.length >= 8;
            setIsValidPassword(result);
        },
        [ratz, rAtZ, r0t9],
    );
    const handleSendVerificationCodeButtonClick = useCallback(async () => {
        setIsChecked(true);
        if (!isValidEmail) return;
        if (!isValidPassword) return;
        try {
            setIsProcessingForSendingVerificationCode(true);
            const { user, userSub } = await Auth.signUp({
                username: email,
                password,
            });
            setIsProcessingForSendingVerificationCode(false);
            props.setIsSentVerificationCode(true);
            if (userSub) {
                props.setUserId(userSub);
            }
        } catch (error) {
            if (error.name === "UsernameExistsException") {
                alert("このメールアドレスは既に登録されています。ログインしてください。");
                history.push("/SignIn");
            }
        }
    }, [email, password, isValidEmail, isValidPassword, props.setIsSentVerificationCode, props.setUserId, history]);

    const { setCurrentCognitoUser } = useContext(CurrentCognitoUserContext);

    const handleVerificationCodeSubmitButtonClick = useCallback(async () => {
        try {
            setIsProcessingForVerifying(true);
            const result = await Auth.confirmSignUp(email, verificationCode);
            if (result === "SUCCESS" && props.userId) {
                const cognitoUser = await Auth.signIn(email, password);
                setCurrentCognitoUser(cognitoUser);
                setIsProcessingForVerifying(false);
                setIsVerified(true);
            } else {
                setIsProcessingForVerifying(false);
                setIsValidVerificationCode(false);
            }
        } catch (error) {
            console.log(error);
            setIsProcessingForVerifying(false);
            setIsValidVerificationCode(false);
        }
    }, [email, verificationCode, props.userId, props.signUpMode, dispatch, history]);

    const handleResendVerificationCodeButtonClick = useCallback(async () => {
        setIsChecked(true);
        setIsValidVerificationCode(true);
        setVerificationCode("");
        if (!isValidEmail) return;
        setIsProcessingForSendingVerificationCode(true);
        try {
            await Auth.resendSignUp(email);
            setIsProcessingForSendingVerificationCode(false);
            props.setIsSentVerificationCode(true);
            setIsResent(true);
        } catch (error) {
            console.log(error);
        }
    }, [email]);

    const onChangeVerificationCode = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIsValidVerificationCode(true);
        setVerificationCode(event.target.value);
    }, []);
    return (
        <>
            {props.isSentVerificationCode ? (
                <div className={styles.sentWrapper}>
                    <div className={styles.message}>
                        以下のメールアドレスに認証コードを{isResent ? "再送信" : "送信"}しました。
                    </div>
                    <div className={styles.email}>{email}</div>
                    <div className={styles.verificationCodeWrapper}>
                        <div className={styles.horizontalWrapper}>
                            <TextField
                                variant="outlined"
                                className={styles.verificationCodeInput}
                                value={verificationCode}
                                label={"認証コードを入力"}
                                onChange={onChangeVerificationCode}
                            />
                            <Button
                                onClick={handleVerificationCodeSubmitButtonClick}
                                className={styles.verificationCodeSubmitButton}
                            >
                                {isProcessingForVerifying ? (
                                    <CircularProgress className={styles.processing} />
                                ) : (
                                    <div className={styles.verify}>認証</div>
                                )}
                            </Button>
                        </div>
                        <ErrorMessage
                            content="認証コードが正しくありません。メールアドレスを確認してください。"
                            when={isChecked && !isValidVerificationCode}
                            style={{ textAlign: "left", margin: "5px auto 0" }}
                        />
                        <button onClick={handleResendVerificationCodeButtonClick} className={styles.resendButton}>
                            {isProcessingForSendingVerificationCode ? (
                                <CircularProgress className={styles.progress} />
                            ) : (
                                <div className={styles.resend}>認証コードを再送信</div>
                            )}
                        </button>
                    </div>
                </div>
            ) : (
                <div className={styles.emailSignUpWrapper}>
                    <div className={styles.emailSignUpTitle}>メールアドレスで登録</div>
                    {/* {props.error && (
                        <div className={styles.errorMessage}>メールアドレスまたはパスワードに誤りがあります</div>
                    )} */}
                    <div className={styles.inputWrapper}>
                        <TextInput label="メールアドレス" value={email} handleChange={handleEmailChange} />
                        <ErrorMessage
                            content="メールアドレスが正しくありません。"
                            when={isChecked && !isValidEmail}
                            style={{ marginBottom: "5px" }}
                        />
                        <Password label="パスワード" value={password} handleChange={handlePasswordChange} />
                        <ErrorMessage
                            content="パスワードは小文字、大文字、数字をそれぞれ1文字以上含む8文字以上で入力してください。"
                            when={isChecked && !isValidPassword}
                            style={{ marginBottom: "5px" }}
                        />
                    </div>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleSendVerificationCodeButtonClick}
                        className={styles.sendVerificationCodeButton}
                    >
                        {isProcessingForSendingVerificationCode ? (
                            <CircularProgress className={styles.processing} />
                        ) : (
                            <div className={styles.send}>認証コードを送信</div>
                        )}
                    </Button>
                </div>
            )}
        </>
    );
});
