import { LessonResponse } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions } from "./utils/types";

export const useReportWholeLessonMutation = (options?: UseMutationOptions) => {
    const [reportWholeLesson] = defaultApi.endpoints.reportWholeLesson.useMutation();
    return useMutationFunctionWrapper(reportWholeLesson, options?.errorMessages);
};

export const useGetStudentLessonsQuery = (studentId: string) => {
    const result = defaultApi.endpoints.getStudentLessons.useQuery({ studentId });
    return useQueryResult(result, (response: LessonResponse[]) => response);
};

export const useGetTeacherLessonsQuery = (teacherId: string) => {
    const result = defaultApi.endpoints.getTeacherLessons.useQuery({ teacherId });
    return useQueryResult(result, (response: LessonResponse[]) => response);
};

export const useAddLessonToClassMutation = (options?: UseMutationOptions) => {
    const [addLessonToClass] = defaultApi.endpoints.addLessonToClass.useMutation();
    return useMutationFunctionWrapper(addLessonToClass, options?.errorMessages);
};

export const useUpdateLessonMutation = (options?: UseMutationOptions) => {
    const [updateLesson] = defaultApi.endpoints.updateLesson.useMutation();
    return useMutationFunctionWrapper(updateLesson, options?.errorMessages);
};

export const useDeleteLessonMutation = (options?: UseMutationOptions) => {
    const [deleteLesson] = defaultApi.endpoints.deleteLesson.useMutation();
    return useMutationFunctionWrapper(deleteLesson, options?.errorMessages);
};
