import styles from "@/pages/Common/TeacherDetails/index.module.scss";
import { PublicTeacherResponse, PublicUserResponse } from "@/store/autogenApi";
import { genderSet } from "@/utils/GenderSet";

interface Props {
    teacher: PublicTeacherResponse;
    user: PublicUserResponse;
}

export const BasicInfo: React.VFC<Props> = (props) => {
    const getAge = (birthday: Date) => {
        //今日
        const today = new Date();
        //今年の誕生日
        const thisYearsBirthday = new Date(today.getFullYear(), birthday.getMonth() - 1, birthday.getDate());
        //年齢
        let age = today.getFullYear() - birthday.getFullYear();
        if (today < thisYearsBirthday) {
            //今年まだ誕生日が来ていない
            age--;
        }
        return age;
    };
    const getEducationalBackground = () => {
        if (!props.teacher.educationalBackground?.university) {
            return "該当なし";
        }
        if (!props.teacher.educationalBackground?.faculty) {
            return props.teacher.educationalBackground?.university;
        }
        if (!props.teacher.educationalBackground?.department) {
            return props.teacher.educationalBackground?.university + "-" + props.teacher.educationalBackground?.faculty;
        }
        return (
            props.teacher.educationalBackground?.university +
            "-" +
            props.teacher.educationalBackground?.faculty +
            "-" +
            props.teacher.educationalBackground?.department
        );
    };
    return (
        <div className={styles.basicInfoWrapper}>
            <div className={styles.basicInfoTitle}>基本情報</div>
            <div className={styles.basicInfo}>
                <div className={styles.itemWrapper}>
                    <div className={styles.key}>性別：</div>
                    <div className={styles.value}>{genderSet[props.user.gender]}</div>
                </div>
                <div className={styles.itemWrapper}>
                    <div className={styles.key}>年齢：</div>
                    <div className={styles.value}>
                        {props.user.birthDate && getAge(new Date(props.user.birthDate))}歳
                    </div>
                </div>
                <div className={styles.itemWrapper}>
                    <div className={styles.key}>お住まいの都道府県：</div>
                    <div className={styles.value}>{props.user.prefecture}</div>
                </div>
                <div className={styles.itemWrapper}>
                    <div className={styles.key}>職業・学年：</div>
                    <div className={styles.value}>{props.user.grade}</div>
                </div>
                <div className={styles.itemWrapper} style={{ borderBottom: "1px solid #CCC" }}>
                    <div className={styles.key}>在卒大学：</div>
                    <div className={styles.value}>{getEducationalBackground()}</div>
                </div>
            </div>
        </div>
    );
};
