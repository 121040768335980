import { FAQConfig } from "@/FAQConfig";

export const SERVICE_OF_TRAIL_CONFIGS: FAQConfig[] = [
    {
        title: "講座検索",
        text: "キーワードを指定して講座の検索が可能です。科目などの条件で絞り込むことができます。講座には定員があるので、早めの予約を心がけましょう。",
    },
    {
        title: "講座リクエスト",
        text: "講座検索で受けたい講座が見つからない場合、講座リクエストで様々な条件を指定してピンポイントで公開依頼を行うことができます。依頼に対して応募した先生から提案が届くので、その中から自分に合った先生を選ぶことができます。",
    },
];
