import { Button } from "@material-ui/core";
import { BsCheckCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";

import styles from "@/pages/Common/CourseDetails/index.module.scss";
import { UserType } from "@/protectedRouter/ProtectedRouterConfig";
import { ClassResponse, PrivateRequestResponse } from "@/store/autogenApi";
import { PrivateRequestApplication } from "../../PrivateRequestApplication";
import { Favorite } from "../Favorite";

interface Props {
    frequency?: string;
    courseId: string;
    title?: string;
    isFavorite: boolean;
    isHostTeacher: boolean;
    userMode: UserType;
    addScheduleModalOpen: boolean;
    classes: ClassResponse[];
    fixedContentsEl: React.MutableRefObject<HTMLElement | null>;
    privateRequestModalOpen: boolean;
    courseTeacherId: string;
    isReservationButtonClicked: boolean;
    privateRequest: PrivateRequestResponse | undefined;
    thresholdOver: boolean;
    activeClass: ClassResponse | undefined;
    reservedClass: ClassResponse | undefined;
    getJapaneseDay: (idx: number) => string;
    getDiffTime: (date: Date) => string;
    handleReservationButtonClick: (classId: string) => void;
    handleAddScheduleModalClose: () => void;
    handleAddScheduleModalOpenButtonClick: () => void;
    handleClassButtonClick: (classId: string) => void;
    handlePrivateRequestModalClose: () => void;
    handleSignUpModalOpen: () => void;
    initializeReservationButtonClicked: () => void;
}

export const SPContents: React.VFC<Props> = (props) => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return (
        <div
            className={styles.extraSmallFixedContents}
            ref={(node: HTMLElement | null) => {
                props.fixedContentsEl.current = node;
            }}
            id="extraSmallFixedContents"
        >
            {props.isHostTeacher ? (
                <div className={styles.yourWrapper}>
                    <div className={styles.yourCourse}>あなたが作成した講座です。</div>
                    <Button
                        className={styles.courseManagementButton}
                        component={Link}
                        to={`/CourseManagement/${props.courseId}`}
                    >
                        講座管理
                    </Button>
                </div>
            ) : (
                <>
                    {props.reservedClass ? (
                        <div className={styles.bottomWrapper}>
                            <div
                                className={styles.reservedWrapper}
                                style={{ marginTop: props.activeClass ? "20px" : "0" }}
                            >
                                <BsCheckCircleFill className={styles.checkIcon} />
                                <div className={styles.reserved}>依頼済み</div>
                            </div>
                            <Button
                                className={styles.classButton}
                                variant="contained"
                                onClick={() => {
                                    if (!props.reservedClass) return;
                                    props.handleClassButtonClick(props.reservedClass.classId);
                                }}
                            >
                                授業管理
                            </Button>
                            <Favorite
                                isSPVersion
                                isSimplified
                                userMode={props.userMode}
                                isReservationButtonClicked={props.isReservationButtonClicked}
                                handleSignUpModalOpen={props.handleSignUpModalOpen}
                                initializeReservationButtonClicked={props.initializeReservationButtonClicked}
                            />
                        </div>
                    ) : (
                        <>
                            {props.activeClass && props.activeClass.applyingDeadline ? (
                                <>
                                    <div className={styles.topWrapper}>
                                        <div className={styles.deadlineWrapper}>
                                            <div className={styles.deadlineTitle}>締め切りまでの残り時間</div>
                                            <div className={styles.deadline}>
                                                {props.getDiffTime(new Date(props.activeClass.applyingDeadline))}
                                            </div>
                                        </div>
                                    </div>
                                    {props.activeClass?.pricePerHour && (
                                        <div className={styles.middleWrapper}>
                                            <div className={styles.priceWrapper}>
                                                <div className={styles.priceTitle}>価格：</div>
                                                <div className={styles.price}>{`¥${new Intl.NumberFormat(
                                                    "ja-JP",
                                                ).format(props.activeClass?.pricePerHour)}/1時間`}</div>
                                            </div>
                                        </div>
                                    )}
                                    <div className={styles.bottomWrapper}>
                                        <PrivateRequestApplication
                                            privateRequest={props.privateRequest}
                                            classId={props.activeClass.classId}
                                            handleReservationButtonClick={props.handleReservationButtonClick}
                                        />
                                        <Favorite
                                            isSPVersion
                                            isSimplified
                                            userMode={props.userMode}
                                            isReservationButtonClicked={props.isReservationButtonClicked}
                                            handleSignUpModalOpen={props.handleSignUpModalOpen}
                                            initializeReservationButtonClicked={
                                                props.initializeReservationButtonClicked
                                            }
                                        />
                                    </div>
                                </>
                            ) : (
                                <div className={styles.noScheduleWrapper}>
                                    <div className={styles.noSchedule}>現在、受講者募集中のクラスはありません。</div>
                                    <Favorite
                                        isSPVersion
                                        userMode={props.userMode}
                                        isReservationButtonClicked={props.isReservationButtonClicked}
                                        handleSignUpModalOpen={props.handleSignUpModalOpen}
                                        initializeReservationButtonClicked={props.initializeReservationButtonClicked}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
};
