import styles from "./index.module.scss";

interface Props {
    content: string;
    when: boolean;
    style?: React.CSSProperties;
}

export const ErrorMessage: React.VFC<Props> = (props) => {
    if (!props.when) return null;
    return (
        <div className={styles.errorMessage} style={props.style}>
            {props.content}
        </div>
    );
};
