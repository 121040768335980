import { useEffect } from "react";

import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import styles from "./index.module.scss";
import { RootState } from "@/ducks";
import { Navigation } from "@/components/Navigation";
import { useNavigation } from "@/components/Navigation/NavigationContext";

const NAVIGATION_LIST: Navigation[] = [{ title: "受講ガイドライン", url: "/CourseGuidelines" }];

export const CourseGuidelines: React.VFC = () => {
    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    const userMode = useSelector((state: RootState) => state.jwt.userMode);
    return (
        <>
            <div className={styles.courseGuidelinesWrapper}>
                <h2 className={styles.title}>Trail講座受講ガイドライン</h2>
                <section className={styles.courseGuidelines}>
                    <section className={styles.sentence}>
                        以下、Trailを介して講座の受講予約および参加を行う場合に該当するガイドラインになります。
                    </section>
                    <section className={styles.sentence}>
                        「生徒」とは講座に対して受講予約をしたユーザーを指します。「先生」とは講座を提供する先生ユーザーのことを指します。
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>1.受講予約申込みの確定及び確認について</h3>
                        <section className={styles.description}>
                            受講予約の確定は、Trailからの予約確認通知メールの到達およびマイページ画面上での[予約済の講座一覧]での表示を持って確定し、受講権を得るものとします。但し、生徒の責めに帰すべき事由により当該予約確認通知メールが到達しなかった場合、到達すべき時点において到達したものとみなします。
                            なお、決済および[予約済の講座一覧]画面上での予約ステータスの確認は受講前に生徒側が責任を持って行うものとし、これを怠り損害が発生した場合についても、株式会社オノレ（以下、当社）および先生は一切の責任を負わないものとしします。
                        </section>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                (1)単発・短期講座
                                <section className={styles.itemDescription}>
                                    生徒が決済を伴う予約申し込みを実行した時点で受講料が発生するものとします。
                                </section>
                            </li>
                            <li className={styles.item}>
                                (2)定期講座
                                <section className={styles.itemDescription}>
                                    初回授業開始日、およびそれから1ヶ月毎に授業料の支払いが発生するものとします。授業料の支払いが遅れた場合、生徒は当該授業の受講権を失うものとします。
                                </section>
                            </li>
                        </ol>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>2.生徒の受講権と情報の取り扱いについて</h3>
                        <section className={styles.description}>
                            生徒は講座の予約をした際に、先生に対して、Trailにご登録の登録ユーザー名、プロフィール画像、性別、年齢が開示されることに同意します。
                            また、当日受講権を享受するにあたり、先生から求められた場合は本人確認書類（運転免許証、健康保険証など）の提示が必要になることに、あらかじめ同意するものとします。
                        </section>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>3.予約の日程振替について</h3>
                        <section className={styles.description}>
                            受講予約について、単発講座は開催日程毎に申し込みを必要とし、同じ講座であっても別の日程への振替は原則行うことができないものとします。別の日程での参加を希望する場合は、一旦参加不可になった日程での予約をキャンセルし、原則参加可能な日程で再度予約申し込みをする必要があるものとします。また、定期講座に関しては基本スケジュールに沿って授業を受けることを基本とし、先生の合意があれば別日程への変更を可能とします。
                        </section>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>4.受講予約のキャンセル・返金について </h3>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                (1)単発講座
                                <section className={styles.itemDescription}>
                                    生徒は予約締切日時前に限り、自由に予約をキャンセルすることができますが、それ以降はできないものとします。Trail側の理由により予約締切日時までにキャンセルが実行できなかった場合を除いて、返金を伴う予約のキャンセルは、生徒自身がサイト上でキャンセルの実行を行い、キャンセル確定の自動通知メールと、［予約の状況］画面にて当該講座が予約済みの講座にない旨を確認する必要があります。生徒側からの任意の連絡のみでは予約のキャンセルは成立したものとはみなされませんのでご留意ください。
                                </section>
                            </li>
                            <li className={styles.item}>
                                (2)定期講座
                                <section className={styles.itemDescription}>
                                    生徒はいつでもキャンセルをすることができるものとします。キャンセルをするとサブスクリプションが解除され、以降の請求は行われません。
                                </section>
                            </li>
                        </ol>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>5.先生側の理由による開催のキャンセルについて</h3>
                        <section className={styles.description}>
                            先生は日程を公開したとき、やむを得ない理由を除いては、責任を持って告知した内容の通りに日程を開催する義務を負うこととします。万が一やむを得ない理由により先生側から開催のキャンセルをしなければいけない事態が発生した場合の一切の対応は、先生に委ねるものとし、その実施に伴ういかなる争いや損害が発生した場合についても、当社は一切の責任を負わず不介入の立場をとるものとします。
                        </section>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>6.当日の参加および参加中の立ち振るまいについて</h3>
                        <section className={styles.description}>
                            生徒は、他の参加者の迷惑とならないよう、講座が開始する5分前にはオンラインにて接続して待機しているよう、最大限の努力を果たすものとします。万が一時間通りにたどり着けない場合はサイトを通じて取得した講座の先生の緊急連絡先あるいはチャットを通じて速やかに連絡するものとします。
                            講座受講中は、先生の指示に従うものとし、他の参加者に迷惑になる行為については控えるものとします。仮に生徒につき受講を妨害する行為その他迷惑になる行為があったと先生が判断した場合には、先生には当該生徒に退場を命じる権利があります。この場合、生徒は、受講料の返金を請求することはできないものとします。
                            生徒は講座へは、勉強することを主な目的として参加するものとします。もし講座の先生が、生徒がそれ以外の目的(例えば別のイベント・団体への勧誘や競合調査など)で参加したということが明らかであると判断した場合は、生徒による講座への参加を参加の途中であっても断る権利を持っています。この場合、生徒は、受講料の返金を請求することはできないものとします。
                        </section>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>7.直接取引の禁止</h3>
                        <section className={styles.description}>
                            手段に関わらず、生徒と先生側の間での直接の受講料の支払いは禁止します。支払が完了していない場合における当日参加は出来かねますので、「1.受講予約申込みの確定及び確認について」の通り、
                            生徒は、決済および[予約済の講座一覧]画面上での予約ステータスが受講料支払済みであることの確認を受講前に生徒側が自己の責任で行ってください。これを怠り損害が発生した場合についても、当社・先生は一切の責任を負わないものとします。
                        </section>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>8.オンライン講座の受講について</h3>
                        <section className={styles.description}>
                            オンライン講座とは、ビデオ会議ツールZoomを使ったオンライン形式の講座を指し、Trailではオンライン講座のみを行うものとします。
                        </section>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                (1)オンライン講座への参加について
                                <section className={styles.itemDescription}>
                                    オンライン講座を受講する生徒は、以下について同意し遵守するものとします。
                                </section>
                                <ol className={styles.smallItems}>
                                    <li className={styles.smallItem}>
                                        1）Zoomの事前登録、アプリ等のインストール、設定は生徒自身でおこなうものとする
                                    </li>
                                    <li className={styles.smallItem}>
                                        2）オンライン講座を受講するために必要な機材（PC、カメラ、スマートフォン等）や通信費は生徒自身の負担とする
                                    </li>
                                    <li className={styles.smallItem}>
                                        3）生徒は、周囲の騒音が少なく、通信が安定している環境で参加するものとし、他の参加者・講座の進行の妨げにならない環境づくりに努めるものする
                                    </li>
                                    <li className={styles.smallItem}>
                                        4）先生から配布された参加URLは、予約をしていない第3者が不正に参加することがないように取り扱いに注意する（URL以外にも画面キャプチャなどSNSへの投稿時は注意してください）
                                    </li>
                                    <li className={styles.smallItem}>
                                        5）受講開始時刻をすぎても生徒からの接続が確認できない・連絡がない場合は、先生は開催を取りやめることができるものとする
                                    </li>
                                    <li className={styles.smallItem}>
                                        6）生徒側の接続環境や通信環境により講座の進行が中断・継続できなくなった場合、原則、先生および弊社は返金・振替または講座の延長の責任は負わないものとする
                                    </li>
                                    <li className={styles.smallItem}>
                                        7）先生の許可なく、講座の録画・録音、写真、画面キャプチャー等の撮影はしないものとし、許可なく撮影した場合は、速やかに該当データを削除するものとする
                                    </li>
                                </ol>
                            </li>
                            <li className={styles.item}>
                                (2)接続サポート・トラブル対応について
                                <section className={styles.itemDescription}>
                                    当日の接続サポートは当社では対応しかねますので、事前に接続テストをおこない、スムーズに受講できるよう十分に準備をおこなってください。
                                    また、先生においても他の参加者がいるなどを理由に、開催時刻を過ぎても問題が解決されない場合、サポートを中止し、講座の進行を優先する権利があります。
                                </section>
                            </li>
                            <li className={styles.item}>
                                (3)開催の中止・受講の取り止めについて
                                <section className={styles.itemDescription}>
                                    講座開始前・開催中において、生徒側の機器または通信トラブルにより講座の受講が困難となった場合、先生もしくは当社は、受講料の返金や日程の振替対応の義務は原則発生しないことを前提として、受講の中止について先生と協議するものしてください。また、講座中に発生した機器・通信トラブルの原因の特定、責任の所在について、当社は一切の判断ができかねますので予めご了承ください。
                                </section>
                            </li>
                        </ol>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>9.異議申し立てについて</h3>
                        <section className={styles.description}>
                            もし講座が告知通りに開催されなかった場合、講座を受講予約した生徒は、講座の開催中から、講座の終了から1週間までの期間中に、先生による違反行為の指摘および役務提供についての異議申し立てが出来ることとします。
                            異議申し立てを受けた後、当社は講座開催の真偽について事実確認を行い、先生側に問題があると判断された場合には然るべき対応（生徒へのポイント返還、先生に対する返金要求等）を行います。
                        </section>
                    </section>
                    <section className={styles.dateWrapper}>
                        <div className={styles.date}>2024年4月12日制定</div>
                    </section>
                </section>
                <Button component={Link} to={userMode === "teacher" ? "/" : "/"} className={styles.toHomeButton}>
                    ホームに戻る
                </Button>
            </div>
        </>
    );
};
