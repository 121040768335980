import { FAQConfig } from "@/FAQConfig";

export const COURSE_FLOW_CONFIGS: FAQConfig[] = [
    {
        title: "単発・短期講座",
        text: "単発・短期講座を開催する手順について説明します。",
        contents: [
            {
                title: "①講座作成ページに移動",
                text: "メニューバーの「講座を作成する」から講座作成ページに移動します。",
            },
            {
                title: "②講座内容の入力",
                text: "「講座の種類」は「単発・短期講座」を選択し、続いて講座の内容を入力します。",
            },
            {
                title: "③入力内容の確認",
                text: "「確認」ボタンを押すと、入力内容の一覧が表示されます。内容を確認して、よろしければ「確定」ボタンを押してください。",
            },
            {
                title: "④講座作成完了",
                text: "この画面で講座の作成が完了します。生徒さんの予約を待ちましょう。",
            },
            {
                title: "⑤ご挨拶",
                text: "生徒さんの予約があったら、受講への感謝の意を伝え、講座の開催に必要な注意事項があればそれも伝達しましょう。",
            },
            {
                title: "⑥授業を行う",
                text: "指定の日時になったら講座管理ページに移動し、講座を開始してください。",
            },
        ],
    },
    {
        title: "定期講座",
        text: "定期講座を開催する手順について説明します。",
        contents: [
            {
                title: "①講座作成ページに移動",
                text: "メニューバーの「講座を作成する」から講座作成ページに移動します。",
            },
            {
                title: "②講座内容の入力",
                text: "「講座の種類」は「定期講座」を選択し、続いて講座の内容を入力します。",
            },
            {
                title: "③入力内容の確認",
                text: "「確認」ボタンを押すと、入力内容の一覧が表示されます。内容を確認して、よろしければ「確定」ボタンを押してください。",
            },
            {
                title: "④講座作成完了",
                text: "この画面で講座の作成が完了します。生徒さんの予約を待ちましょう。",
            },
            {
                title: "⑤ご挨拶",
                text: "生徒さんの予約があったら、受講への感謝の意を伝え、講座の開催に必要な注意事項があればそれも伝達しましょう。",
            },
            {
                title: "⑥スケジュールの入力",
                text: "予約があったら、その生徒さんに希望曜日・時刻を確認し、それに従ってスケジュールを入力します。生徒さんに個々の授業の予約をお願いします。",
            },
            {
                title: "⑦授業を行う",
                text: "指定の日時になったら講座管理ページに移動し、講座を開始してください。",
            },
        ],
    },
];
