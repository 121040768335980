import { useState, useEffect } from "react";

import { CourseResponse } from "@/store/autogenApi";
import { useGetLatestShortCoursesQuery } from "@/store/hooks/courses";
import { CourseCardsBase } from "@/components/CourseCardsBase";

interface Props {
    repetitionNumber?: number;
}

export const LatestShortCourses: React.VFC<Props> = (props) => {
    const [courses, setCourses] = useState<CourseResponse[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const latestShortCoursesQueryState = useGetLatestShortCoursesQuery(15);
    useEffect(() => {
        (async () => {
            if (!latestShortCoursesQueryState.isFetching && latestShortCoursesQueryState.isSuccess) {
                setCourses(latestShortCoursesQueryState.data);
                setIsLoaded(true);
            }
        })();
    }, [latestShortCoursesQueryState]);

    return (
        <CourseCardsBase
            title="新着単発・短期講座"
            courses={courses}
            destination="/SearchResults?searchType=course&keyword=&courseType=short&level=&category=&subCategory=&rankNumber=&university=&faculty=&department=&orderBy=latest"
            isLoaded={isLoaded}
            repetitionNumber={props.repetitionNumber}
            hasTwoLines
        />
    );
};
