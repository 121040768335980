import { ProposalResponse } from "@/store/autogenApi";

export const getCourseType = (proposal: ProposalResponse) => {
    if (proposal.publicRequest?.courseType === "short") {
        return "単発・短期";
    } else {
        return "定期";
    }
};

export const getTabInfoList = (proposals: ProposalResponse[]) => {
    let shortCount = 0;
    let regularCount = 0;
    proposals.forEach((proposal) => {
        if (proposal.publicRequest?.courseType === "short") {
            shortCount++;
        } else {
            regularCount++;
        }
    });
    return [
        { value: "regular", label: `定期(${regularCount})` },
        { value: "short", label: `単発・短期(${shortCount})` },
    ];
};
