import { useState } from "react";

import { Avatar } from "@material-ui/core";

import { FileInfo, TrimmingModal } from "@/components/TrimmingModal";
import styles from "@/pages/Student/InfoInput/index.module.scss";
import { Optional } from "../Tag/Optional";

interface Props {
    setFileInfo?: React.Dispatch<React.SetStateAction<FileInfo>>;
    open?: boolean;
    fileInfo: FileInfo | undefined;
    checked: boolean;
}

export const IconImage: React.VFC<Props> = (props) => {
    const [trimmingModalOpen, setTrimmingModalOpen] = useState(false);
    const [targetFile, setTargetFile] = useState<File | undefined>(undefined);
    const selectImg = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            setTargetFile(file);
            handleTrimmingModalOpen();
        }
    };
    const handleTrimmingModalClose = () => {
        setTrimmingModalOpen(false);
    };
    const handleTrimmingModalOpen = () => {
        setTrimmingModalOpen(true);
    };
    return (
        <div className={styles.inputItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitleWrapper}>
                    <span className={styles.itemTitle}>アイコン用画像</span>
                </div>
                <Optional />
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>
                        <Avatar alt="aaa" src={props.fileInfo?.url} className={styles.avatar} />
                    </div>
                ) : (
                    <>
                        <label className={styles.iconImageLabel}>
                            <Avatar alt="aaa" src={props.fileInfo?.url} className={styles.avatar} />
                            <input type="file" accept="image/*" onChange={selectImg} className={styles.photoInput} />
                        </label>
                        <TrimmingModal
                            open={trimmingModalOpen}
                            handleClose={handleTrimmingModalClose}
                            setFileInfo={props.setFileInfo}
                            targetFile={targetFile}
                            aspect={1 / 1}
                            shape="round"
                        />
                        {props.fileInfo === undefined && (
                            <div className={styles.notice}>
                                ※ 画像をアップロードしない場合、こちらのデフォルト画像が適用されます。
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
