import { memo, useCallback, useEffect, useRef, useState } from "react";

import { Hidden } from "@material-ui/core";
import { useHistory } from "react-router";

import { UserType } from "@/protectedRouter/ProtectedRouterConfig";
import { PrivateUserResponse, ProposalResponse, PublicStudentResponse, ZoomAccountStatus } from "@/store/autogenApi";
import { useDeleteProposalMutation } from "@/store/hooks/publicRequests";
import { useUpdateFavoriteRequestsMutation } from "@/store/hooks/teachers";

import { PCContents } from "./PCContents";
import { SPContents } from "./SPContents";

interface Props {
    proposals: ProposalResponse[];
    userMode: UserType;
    user: PrivateUserResponse;
    publicRequestId: string;
    student: PublicStudentResponse;
    courseType: string;
    applyingDeadline: Date;
    title: string;
    fixedContentsEl: React.MutableRefObject<HTMLElement | null>;
    handleEditButtonClick: (params: string) => void;
}

export interface LeftTime {
    date: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export const FixedContents: React.VFC<Props> = memo(function FixedContents(props) {
    const [leftTimeModalOpen, setLeftTimeModalOpen] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(false);
    const [chatModalOpen, setChatModalOpen] = useState<boolean>(false);
    const [proposalModalOpen, setProposalModalOpen] = useState<boolean>(false);
    const [myProposalModalOpen, setWithdrawModalOpen] = useState<boolean>(false);
    const [appliedModalOpen, setAppliedModalOpen] = useState<boolean>(false);
    const [pleaseConnectZoomModalOpen, setPleaseLinkZoomModalOpen] = useState<boolean>(false);
    const [leftTime, setLeftTime] = useState<LeftTime | undefined>(undefined);
    const [isWithdrawalMode, setIsWithdrawalMode] = useState<boolean>(false);
    const [targetProposal, setTargetProposal] = useState<ProposalResponse | undefined>(undefined);

    const updateFavoriteRequests = useUpdateFavoriteRequestsMutation();
    const deleteProposal = useDeleteProposalMutation();

    const intervalRef = useRef<any>(null);

    const checkDeadline = useCallback(() => {
        const deadline = new Date(props.applyingDeadline);
        if (deadline) {
            const fixedDeadline = new Date(deadline);
            intervalRef.current = setInterval(() => {
                const currentTime = new Date();
                const leftTime = fixedDeadline.getTime() - currentTime.getTime();
                const diffDate = Math.floor(leftTime / (1000 * 60 * 60 * 24));
                const diffHours = Math.floor((leftTime - diffDate * (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const diffMinutes = Math.floor(
                    (leftTime - diffDate * (1000 * 60 * 60 * 24) - diffHours * (1000 * 60 * 60)) / (1000 * 60),
                );
                const diffSeconds = Math.floor(
                    (leftTime -
                        diffDate * (1000 * 60 * 60 * 24) -
                        diffHours * (1000 * 60 * 60) -
                        diffMinutes * (1000 * 60)) /
                        1000,
                );
                setLeftTime({
                    date: diffDate,
                    hours: diffHours,
                    minutes: diffMinutes,
                    seconds: diffSeconds,
                });
            }, 1000);
        }
    }, [props.applyingDeadline]);

    useEffect(() => {
        checkDeadline();
        return () => {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        };
    }, [checkDeadline]);

    const handleChatModalOpen = useCallback(() => {
        setChatModalOpen(true);
    }, []);

    const handleChatModalClose = useCallback(() => {
        setChatModalOpen(false);
    }, []);

    const getLeftTime = useCallback(() => {
        const diffDate = leftTime?.date;
        const diffHours = leftTime?.hours;
        const diffMinutes = leftTime?.minutes;
        const diffSeconds = leftTime?.seconds;
        if (
            typeof diffDate === "number" &&
            typeof diffHours === "number" &&
            typeof diffMinutes === "number" &&
            typeof diffSeconds === "number"
        ) {
            if (diffDate >= 1) {
                const result = `${diffDate}日`;
                return result;
            } else if (diffDate === 0) {
                if (diffHours >= 1) {
                    const result = `${diffHours}時間`;
                    return result;
                } else if (diffHours === 0) {
                    if (diffMinutes >= 1) {
                        const result = `${diffMinutes}分`;
                        return result;
                    } else if (diffMinutes === 0) {
                        if (diffSeconds > 0) {
                            const result = `${diffSeconds}秒`;
                            return result;
                        } else {
                            return "募集期間終了";
                        }
                    } else {
                        return "募集期間終了";
                    }
                } else {
                    return "募集期間終了";
                }
            } else {
                return "募集期間終了";
            }
        } else {
            return "";
        }
    }, [leftTime]);

    const getDetailLeftTime = useCallback(() => {
        const diffDate = leftTime?.date;
        const diffHours = leftTime?.hours;
        const diffMinutes = leftTime?.minutes;
        const diffSeconds = leftTime?.seconds;
        if (
            typeof diffDate === "number" &&
            typeof diffHours === "number" &&
            typeof diffMinutes === "number" &&
            typeof diffSeconds === "number"
        ) {
            if (diffDate >= 1) {
                const result = `${diffDate}日${("0" + diffHours).slice(-2)}時間${("0" + diffMinutes).slice(-2)}日${(
                    "0" + diffSeconds
                ).slice(-2)}秒`;
                return result;
            } else if (diffDate === 0) {
                if (diffHours >= 1) {
                    const result = `${("0" + diffHours).slice(-2)}時間${("0" + diffMinutes).slice(-2)}分${(
                        "0" + diffSeconds
                    ).slice(-2)}秒`;
                    return result;
                } else if (diffHours === 0) {
                    if (diffMinutes >= 1) {
                        const result = `${("0" + diffMinutes).slice(-2)}分${("0" + diffSeconds).slice(-2)}秒`;
                        return result;
                    } else if (diffMinutes === 0) {
                        if (diffSeconds > 0) {
                            const result = `${("0" + diffSeconds).slice(-2)}秒`;
                            return result;
                        } else {
                            return "募集期間終了";
                        }
                    } else {
                        return "募集期間終了";
                    }
                } else {
                    return "募集期間終了";
                }
            } else {
                return "募集期間終了";
            }
        } else {
            return "";
        }
    }, [leftTime]);

    const history = useHistory();

    const handleCourseManagementButtonClick = useCallback(
        (proposal: ProposalResponse) => {
            const courseId = proposal.class?.course?.courseId;
            if (courseId) {
                history.push(`/CourseManagement/${courseId}`);
            }
        },
        [history],
    );

    const handleWithdrawButtonClick = useCallback(() => {
        setIsWithdrawalMode(true);
    }, [deleteProposal]);

    const handleConfirmWithdrawButtonClick = useCallback(
        (proposalId: string) => {
            (async () => {
                await deleteProposal({ proposalId: proposalId });
                window.location.reload();
            })();
        },
        [deleteProposal],
    );

    const handleMyProposalModalClose = useCallback(() => {
        setWithdrawModalOpen(false);
        setIsWithdrawalMode(false);
    }, []);

    const handleMyProposalModalOpen = useCallback(() => {
        setWithdrawModalOpen(true);
    }, []);

    const handleProposalModalOpen = useCallback((zoomAccountStatus: ZoomAccountStatus) => {
        if (zoomAccountStatus !== "active") {
            setPleaseLinkZoomModalOpen(true);
            return;
        }
        setProposalModalOpen(true);
    }, []);

    const handleProposalModalClose = useCallback(() => {
        setProposalModalOpen(false);
        setTargetProposal(undefined);
    }, []);

    const handlePleaseLinkZoomModalClose = useCallback(() => {
        setPleaseLinkZoomModalOpen(false);
    }, []);

    const handleAppliedModalOpen = useCallback(() => {
        setAppliedModalOpen(true);
    }, []);

    const handleAppliedModalClose = useCallback(() => {
        setAppliedModalOpen(false);
    }, []);

    const handleLeftTimeModalOpen = useCallback(() => {
        setLeftTimeModalOpen(true);
    }, []);

    const handleLeftTimeModalClose = useCallback(() => {
        setLeftTimeModalOpen(false);
    }, []);

    const handleUpdateProposalButtonClick = useCallback(
        (proposal: ProposalResponse) => {
            setTargetProposal(proposal);
            setWithdrawModalOpen(false);
            setProposalModalOpen(true);
        },
        [handleProposalModalOpen],
    );

    return (
        <>
            <Hidden xsDown>
                <PCContents
                    user={props.user}
                    proposals={props.proposals}
                    leftTime={leftTime}
                    userMode={props.userMode}
                    publicRequestId={props.publicRequestId}
                    student={props.student}
                    leftTimeModalOpen={leftTimeModalOpen}
                    checked={checked}
                    chatModalOpen={chatModalOpen}
                    courseType={props.courseType}
                    applyingDeadline={props.applyingDeadline}
                    fixedContentsEl={props.fixedContentsEl}
                    myProposalModalOpen={myProposalModalOpen}
                    proposalModalOpen={proposalModalOpen}
                    title={props.title}
                    appliedModalOpen={appliedModalOpen}
                    isWithdrawalMode={isWithdrawalMode}
                    targetProposal={targetProposal}
                    pleaseConnectZoomModalOpen={pleaseConnectZoomModalOpen}
                    handleUpdateProposalButtonClick={handleUpdateProposalButtonClick}
                    handleAppliedModalOpen={handleAppliedModalOpen}
                    handleAppliedModalClose={handleAppliedModalClose}
                    handleMyProposalModalClose={handleMyProposalModalClose}
                    handleMyProposalModalOpen={handleMyProposalModalOpen}
                    handleWithdrawButtonClick={handleWithdrawButtonClick}
                    handleLeftTimeModalOpen={handleLeftTimeModalOpen}
                    handleLeftTimeModalClose={handleLeftTimeModalClose}
                    handleChatModalOpen={handleChatModalOpen}
                    handleChatModalClose={handleChatModalClose}
                    getLeftTime={getLeftTime}
                    getDetailLeftTime={getDetailLeftTime}
                    setChecked={setChecked}
                    handleEditButtonClick={props.handleEditButtonClick}
                    handleCourseManagementButtonClick={handleCourseManagementButtonClick}
                    handleProposalModalOpen={handleProposalModalOpen}
                    handleProposalModalClose={handleProposalModalClose}
                    handleConfirmWithdrawButtonClick={handleConfirmWithdrawButtonClick}
                    handlePleaseLinkZoomModalClose={handlePleaseLinkZoomModalClose}
                />
            </Hidden>
            <Hidden smUp>
                <SPContents
                    user={props.user}
                    proposals={props.proposals}
                    userMode={props.userMode}
                    publicRequestId={props.publicRequestId}
                    student={props.student}
                    leftTimeModalOpen={leftTimeModalOpen}
                    checked={checked}
                    title={props.title}
                    chatModalOpen={chatModalOpen}
                    courseType={props.courseType}
                    applyingDeadline={props.applyingDeadline}
                    fixedContentsEl={props.fixedContentsEl}
                    myProposalModalOpen={myProposalModalOpen}
                    proposalModalOpen={proposalModalOpen}
                    appliedModalOpen={appliedModalOpen}
                    leftTime={leftTime}
                    isWithdrawalMode={isWithdrawalMode}
                    targetProposal={targetProposal}
                    pleaseConnectZoomModalOpen={pleaseConnectZoomModalOpen}
                    handleUpdateProposalButtonClick={handleUpdateProposalButtonClick}
                    handleAppliedModalOpen={handleAppliedModalOpen}
                    handleAppliedModalClose={handleAppliedModalClose}
                    handleMyProposalModalClose={handleMyProposalModalClose}
                    handleMyProposalModalOpen={handleMyProposalModalOpen}
                    handleWithdrawButtonClick={handleWithdrawButtonClick}
                    handleLeftTimeModalOpen={handleLeftTimeModalOpen}
                    handleLeftTimeModalClose={handleLeftTimeModalClose}
                    handleChatModalOpen={handleChatModalOpen}
                    handleChatModalClose={handleChatModalClose}
                    getLeftTime={getLeftTime}
                    getDetailLeftTime={getDetailLeftTime}
                    setChecked={setChecked}
                    handleEditButtonClick={props.handleEditButtonClick}
                    handleCourseManagementButtonClick={handleCourseManagementButtonClick}
                    handleProposalModalOpen={handleProposalModalOpen}
                    handleProposalModalClose={handleProposalModalClose}
                    handleConfirmWithdrawButtonClick={handleConfirmWithdrawButtonClick}
                    handlePleaseLinkZoomModalClose={handlePleaseLinkZoomModalClose}
                />
            </Hidden>
        </>
    );
});
