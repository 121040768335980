import { FAQConfig } from "@/FAQConfig";

export const FAVORITE_COURSE_CONFIGS: FAQConfig[] = [
    {
        title: "講座詳細ページを開く",
        text: "対象の講座詳細ページを表示します。",
    },
    {
        title: "「気になる」ボタンを押す",
        text: "「気になる」ボタンを押すと、ボタンが赤くなり。登録完了となります。",
    },
    {
        title: "気になる講座の確認",
        text: "マイページの「気になる講座一覧」から、気になる講座を確認することができます。",
    },
];
