import { RequestCard } from "@/components/RequestCard";
import styles from "@/pages/Teacher/MyPage/index.module.scss";
import { PublicRequestResponse } from "@/store/autogenApi";

interface Props {
    visible: boolean;
    requests: PublicRequestResponse[];
    repetition: number;
}

export const TabContents: React.VFC<Props> = (props) => {
    return (
        <div role="tabpanel" hidden={!props.visible} className={styles.tabContents}>
            {props.visible && (
                <>
                    {props.requests.length === 0 ? (
                        <div className={styles.emptyRequests}>対象の講座リクエストがありません。</div>
                    ) : (
                        <div className={styles.cardsList}>
                            {[...Array(Math.ceil(props.requests.length / props.repetition))]
                                .map((_, i) => i)
                                .map((idx) => (
                                    <>
                                        <div className={styles.cards} key={idx}>
                                            {props.requests
                                                .slice(
                                                    idx * props.repetition,
                                                    idx * props.repetition + props.repetition,
                                                )
                                                .map((publicRequest) => {
                                                    return (
                                                        <RequestCard
                                                            publicRequest={publicRequest}
                                                            key={publicRequest.publicRequestId}
                                                            isFlex
                                                        />
                                                    );
                                                })}
                                            {props.requests.slice(
                                                idx * props.repetition,
                                                idx * props.repetition + props.repetition,
                                            ).length < props.repetition &&
                                                [
                                                    ...Array(
                                                        props.repetition -
                                                            props.requests.slice(
                                                                idx * props.repetition,
                                                                idx * props.repetition + props.repetition,
                                                            ).length,
                                                    ),
                                                ].map((_, idx) => <RequestCard key={idx} isHidden isFlex />)}
                                        </div>
                                    </>
                                ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
