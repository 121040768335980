import { Link } from "react-router-dom";

import { FAQConfig } from "@/FAQConfig";

import styles from "./index.module.scss";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { useEffect } from "react";

const NAVIGATION_LIST = [
    {
        title: `ヘルプ・よくある質問`,
        url: `/FAQ`,
    },
];

export const FaqTop: React.VFC = () => {
    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    return (
        <div className={styles.FAQWrapper}>
            <div className={styles.FAQTitle}>ヘルプ・よくある質問</div>
            {/* <SearchFAQ /> */}
            <div className={styles.contentsWrapper}>
                {FAQConfig.map((categories, idx1) => (
                    <div key={idx1} className={styles.categoriesWrapper}>
                        <div className={styles.categoriesTitle}>{categories.title}</div>
                        <div className={styles.categories}>
                            {categories.contents.map((category, idx2) => (
                                <div className={styles.category} key={idx2}>
                                    <div className={styles.categoryTitle} key={idx2}>
                                        {category.title}
                                    </div>
                                    <ul className={styles.items}>
                                        {category.contents.map((item, idx3) => {
                                            if (idx3 < 5) {
                                                return (
                                                    <li className={styles.item} key={idx3}>
                                                        <Link
                                                            to={`/FAQDetails?type=${item.type}`}
                                                            className={styles.link}
                                                        >
                                                            {item.title}
                                                        </Link>
                                                    </li>
                                                );
                                            } else if (idx3 === 5) {
                                                return (
                                                    <li className={styles.item} key={idx3}>
                                                        <Link
                                                            to={`/FAQCategory?type=${category.type}`}
                                                            className={styles.categoryLink}
                                                        >
                                                            {category.contents.length}件の記事を全て表示
                                                        </Link>
                                                    </li>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </ul>
                                </div>
                            ))}
                            {categories.contents.length % 2 === 1 && <div className={styles.category} />}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
