import { useLocation, useParams } from "react-router";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { PaymentContents } from "@/pageComponents/Student/Payment/PaymentContents";
import { useGetPrivateUserQuery } from "@/store/hooks/users";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { useGetDefaultPaymentMethodQuery } from "@/store/hooks/stripe";
import { useGetClassQuery } from "@/store/hooks/classes";
import { useEffect, useState } from "react";

export const Payment: React.VFC = () => {
    const [studentToDoId, setStudentToDoId] = useState<string | undefined>(undefined);
    const { classId } = useParams<{
        classId: string;
    }>();
    const location = useLocation();
    useEffect(() => {
        const { search } = location;
        const searchParams = new URLSearchParams(search);
        const studentToDoIdQueryParam = searchParams.get("studentToDoId");
        setStudentToDoId(studentToDoIdQueryParam ?? undefined);
    }, [location]);
    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);
    const userId = useSelector((state: RootState) => state.jwt.userId as string);
    const classQueryState = useGetClassQuery(classId);
    const userQueryState = useGetPrivateUserQuery(userId);
    const defaultPaymentMethodQueryState = useGetDefaultPaymentMethodQuery(studentId);

    return (
        <QueryLoadingWrapper {...classQueryState}>
            {(classResponse) => (
                <QueryLoadingWrapper {...userQueryState}>
                    {(user) => (
                        <QueryLoadingWrapper {...defaultPaymentMethodQueryState}>
                            {(defaultPaymentMethod) => (
                                <PaymentContents
                                    defaultPaymentMethod={defaultPaymentMethod}
                                    class={classResponse}
                                    pointsBalance={user ? user.pointsBalance : 0}
                                    studentToDoId={studentToDoId}
                                />
                            )}
                        </QueryLoadingWrapper>
                    )}
                </QueryLoadingWrapper>
            )}
        </QueryLoadingWrapper>
    );
};
