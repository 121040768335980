import { createTheme, MuiThemeProvider, TextField } from "@material-ui/core";

import styles from "@/pages/Teacher/MyPage/index.module.scss";

interface Props {
    placeholder: string;
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

export const StyledTextField: React.VFC<Props> = (props) => {
    const theme = createTheme({
        overrides: {
            MuiOutlinedInput: {
                input: {
                    padding: "10px",
                    color: "#305077",
                    fontSize: "14px",
                    "@media (max-width: 600px)": {
                        fontSize: "12px",
                    },
                    "&::placeholder": {
                        color: "#305077",
                        fontSize: "14px",
                        "@media (max-width: 600px)": {
                            fontSize: "12px",
                        },
                    },
                },
            },
            MuiFormControl: {
                marginNormal: {
                    marginTop: "0",
                    marginBottom: "0",
                },
            },
        },
    });

    return (
        <MuiThemeProvider theme={theme}>
            <TextField
                label=""
                variant="outlined"
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                className={styles.input}
                placeholder={props.placeholder}
                onChange={props.handleChange}
            />
        </MuiThemeProvider>
    );
};
