import { Button, CircularProgress, List } from "@material-ui/core";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FavoriteIcon from "@material-ui/icons/Favorite";
import HistoryIcon from "@material-ui/icons/History";
import NotificationIcon from "@material-ui/icons/Notifications";
import MessageIcon from "@material-ui/icons/Mail";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PaymentIcon from "@material-ui/icons/Payment";
// import SecurityIcon from "@material-ui/icons/Security";
import { BiCoinStack } from "react-icons/bi";
import { MdAddCircle, MdCreate } from "react-icons/md";
import { RiFileList2Line } from "react-icons/ri";
import { VscArrowSwap } from "react-icons/vsc";

import { LinkButtonTemplate } from "../LinkButtonTemplate";
import styles from "../index.module.scss";
import { PrivateStudentResponse, PrivateUserResponse } from "@/store/autogenApi";
import { AiFillSetting } from "react-icons/ai";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    student: PrivateStudentResponse;
    user: PrivateUserResponse;
    isProcessingForSignOut: boolean;
    handleLinkButtonClick: (params: string) => void;
    handleChargeButtonClick: () => void;
    handleSignOutButtonClick: () => void;
    handleSwitchButtonClick: () => void;
    handleCreatePublicRequestButtonClick: () => void;
}

export const MenuContents: React.VFC<Props> = (props) => {
    return (
        <div className={styles.menuContentsWrapper}>
            <div className={styles.userInfo}>
                <AvatarFromS3
                    url={props.student.iconImageUrl}
                    objectKey={props.student.iconImageObjectKey}
                    className={styles.avatar}
                />
                <div className={styles.nickname}>{props.student?.nickname}</div>
                <div className={styles.pointsWrapper}>
                    <div className={styles.pointsHeld}>
                        保有ポイント：{new Intl.NumberFormat("ja-JP").format(props.user.pointsBalance)}pt
                    </div>
                </div>
            </div>
            <Button className={styles.createPublicRequestButton} onClick={props.handleCreatePublicRequestButtonClick}>
                <MdCreate className={styles.requestIcon} />
                <div className={styles.createPublicRequest}>講座リクエストを作成</div>
            </Button>
            <List className={styles.menuList}>
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/MyPage/Profile");
                    }}
                    itemTitle="プロフィール"
                    IconJSX={<AssignmentIndIcon className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/MyPage/History");
                    }}
                    itemTitle="最近チェックした講座"
                    IconJSX={<HistoryIcon className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/MyPage/Favorite");
                    }}
                    itemTitle="気になる講座"
                    IconJSX={<FavoriteIcon className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/MyPage/Announcements");
                    }}
                    itemTitle="お知らせ"
                    IconJSX={<NotificationIcon className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/MyPage/Chats");
                    }}
                    itemTitle="メッセージ"
                    IconJSX={<MessageIcon className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/MyPage/MyPublicRequests");
                    }}
                    itemTitle="My講座リクエスト一覧"
                    IconJSX={<RiFileList2Line className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/MyPage/MyDraftPublicRequests");
                    }}
                    itemTitle="下書き講座リクエスト一覧"
                    IconJSX={<RiFileList2Line className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/MyPage/ReservedCourses");
                    }}
                    itemTitle="予約済みの講座一覧"
                    IconJSX={<CheckCircleOutlineIcon className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/MyPage/LessonCalendar");
                    }}
                    itemTitle="授業カレンダー"
                    IconJSX={<CalendarMonthIcon className={styles.icon} />}
                    borderBottom
                />
                {/* <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/MyPage/Privacy");
                    }}
                    itemTitle="プライバシー設定"
                    IconJSX={<SecurityIcon className={styles.icon} />}
                    borderBottom
                /> */}
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/MyPage/PaymentSetting");
                    }}
                    itemTitle="お支払い設定"
                    IconJSX={<PaymentIcon className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/MyPage/PointsRecordHistory");
                    }}
                    itemTitle="ポイント履歴"
                    IconJSX={<BiCoinStack className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/MyPage/Setting");
                    }}
                    itemTitle="設定"
                    IconJSX={<AiFillSetting className={styles.icon} />}
                    borderBottom
                />
            </List>
            <div className={styles.accountWrapper}>
                <Button className={styles.switchToTeacherAccountButton} onClick={props.handleSwitchButtonClick}>
                    <VscArrowSwap className={styles.switchIcon} />
                    <div className={styles.switchToTeacherAccount}>先生アカウントへ切替</div>
                </Button>
                <Button className={styles.logOutButton} onClick={props.handleSignOutButtonClick}>
                    {props.isProcessingForSignOut ? (
                        <CircularProgress className={styles.progress} />
                    ) : (
                        <div className={styles.logOutWrapper}>
                            <ExitToAppIcon className={styles.exitIcon} />
                            <div className={styles.logOut}>ログアウト</div>
                        </div>
                    )}
                </Button>
            </div>
        </div>
    );
};
