import styles from "../index.module.scss";

import { memo, useCallback, useEffect, useState } from "react";
import { NavyButton } from "@/components/Buttons/NavyButton";
import { NormalUpdateContents } from "./NormalUpdateContents";
import { MAX_PRICE, MIN_PRICE } from "@/constants";
import { TemporaryUpdateContents } from "./TemporaryUpdateContents";

interface Props {
    newPricePerPayment: number | undefined;
    startDate: Date | undefined;
    endDate: Date | undefined;
    isTemporaryMode: boolean | undefined;
    handleIsTemporaryModeChange: (newIsTemporaryMode: boolean) => void;
    handleStartDateChange: (newStartDate: Date | undefined) => void;
    handleEndDateChange: (newEndDate: Date | undefined) => void;
    handleModalClose: () => void;
    handleIsConfirmModeChange: (isConfirmMode: boolean) => void;
    handleNewPricePerPaymentChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
}

export const EditModeContents: React.VFC<Props> = memo(function EditModeContents(props) {
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [isNewPricePerPaymentValid, setIsNewPricePerPaymentValid] = useState<boolean>(true);

    useEffect(() => {
        if (props.newPricePerPayment === undefined) return;
        const result = MIN_PRICE <= props.newPricePerPayment && props.newPricePerPayment <= MAX_PRICE;
        setIsNewPricePerPaymentValid(result);
    }, [props.newPricePerPayment]);

    const handleCheckButtonClick = useCallback(() => {
        setIsChecked(true);
        if (!isNewPricePerPaymentValid) return;
        if (!props.startDate) return;
        if (props.isTemporaryMode && (!props.endDate || (props.endDate && props.startDate >= props.endDate))) return;
        props.handleIsConfirmModeChange(true);
    }, [
        isNewPricePerPaymentValid,
        props.startDate,
        props.endDate,
        props.isTemporaryMode,
        props.handleIsConfirmModeChange,
    ]);

    const handleModalClose = useCallback(() => {
        props.handleIsConfirmModeChange(false);
        setIsChecked(false);
        setIsNewPricePerPaymentValid(false);
        props.handleModalClose();
    }, [props.handleModalClose, props.handleIsConfirmModeChange]);

    const handleNormalUpdateButtonClick = useCallback(() => {
        props.handleIsTemporaryModeChange(false);
    }, [props.handleIsTemporaryModeChange]);

    const handleTemporaryUpdateButtonClick = useCallback(() => {
        props.handleIsTemporaryModeChange(true);
    }, [props.handleIsTemporaryModeChange]);

    return (
        <div className={styles.editModeContents}>
            {props.isTemporaryMode === undefined ? (
                <div className={styles.temporaryModeSelectWrapper}>
                    <div className={styles.temporaryModeSelectTitle}>月額更新の種類選択</div>
                    <div className={styles.updateDescription}>
                        年度の切り替わりでの月額UPや基本スケジュールが変更された場合など、基本月額を更新したい場合は「通常更新」
                        <br />
                        夏季休業中に授業数を増やすなど、一時的に月額を変更したい場合は「一時的更新」を選択してください。
                    </div>
                    <div className={styles.modeSelectButtons}>
                        <NavyButton handleClick={handleNormalUpdateButtonClick} isFlex>
                            通常更新
                        </NavyButton>
                        <NavyButton handleClick={handleTemporaryUpdateButtonClick} isFlex>
                            一時的更新
                        </NavyButton>
                    </div>
                </div>
            ) : props.isTemporaryMode === true ? (
                <TemporaryUpdateContents
                    isChecked={isChecked}
                    newPricePerPayment={props.newPricePerPayment}
                    priceValidation={isNewPricePerPaymentValid}
                    startDate={props.startDate}
                    endDate={props.endDate}
                    handleStartDateChange={props.handleStartDateChange}
                    handleEndDateChange={props.handleEndDateChange}
                    handleModalClose={handleModalClose}
                    handleCheckButtonClick={handleCheckButtonClick}
                    handleNewPricePerPaymentChange={props.handleNewPricePerPaymentChange}
                />
            ) : (
                <NormalUpdateContents
                    isChecked={isChecked}
                    newPricePerPayment={props.newPricePerPayment}
                    priceValidation={isNewPricePerPaymentValid}
                    startDate={props.startDate}
                    handleStartDateChange={props.handleStartDateChange}
                    handleModalClose={handleModalClose}
                    handleCheckButtonClick={handleCheckButtonClick}
                    handleNewPricePerPaymentChange={props.handleNewPricePerPaymentChange}
                />
            )}
        </div>
    );
});
