import { useMediaQuery } from "@material-ui/core";
import { memo, useCallback, useEffect, useState } from "react";
import { AiFillHome } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";

import styles from "./index.module.scss";
import { OverWrapper } from "../OverWrapper";
import { LOCATIONS_WITH_ONLY_LOGO } from "@/constants";

interface Props {
    navigationList: Navigation[];
    currentBodyEl: HTMLElement | null;
}

export interface Navigation {
    title: string;
    url: string;
}

export const NavigationComponent: React.VFC<Props> = memo(function NavigationComponent(props) {
    const [currentNavigationWrapperEl, setCurrentNavigationWrapperEl] = useState<HTMLElement | null>(null);

    const location = useLocation();

    const smUp = useMediaQuery("(min-width:600px)");
    const mdUp = useMediaQuery("(min-width:960px)");

    const navigationWrapperRef = useCallback((headerEl: HTMLElement | null) => {
        setCurrentNavigationWrapperEl(headerEl);
    }, []);

    const changeHeaderWrapperHeight = useCallback(() => {
        const header = document.getElementById("header");
        if (!header || !currentNavigationWrapperEl) return;
        const headerHeight = header.clientHeight;
        // 調整用
        const delta = 0;
        currentNavigationWrapperEl.style.top = `${headerHeight}px`;
    }, [currentNavigationWrapperEl]);

    useEffect(() => {
        changeHeaderWrapperHeight();
        window.addEventListener("resize", changeHeaderWrapperHeight);
        return () => {
            window.removeEventListener("resize", changeHeaderWrapperHeight);
        };
    }, [changeHeaderWrapperHeight]);

    useEffect(() => {
        const pathname = location.pathname;
        if (!props.currentBodyEl) return;
        if (LOCATIONS_WITH_ONLY_LOGO.includes(pathname)) {
            props.currentBodyEl.style.paddingTop = "60px";
            return;
        }
        if (props.navigationList.length === 0) {
            // bodyのpaddingTopを調整
            props.currentBodyEl.style.paddingTop = mdUp ? "70px" : smUp ? "60px" : "104px";
        } else {
            props.currentBodyEl.style.paddingTop = mdUp ? "94px" : smUp ? "84px" : "128px";
        }
    }, [props.navigationList, smUp, mdUp, props.currentBodyEl]);

    if (props.navigationList.length === 0) return <></>;
    return (
        <OverWrapper
            style={{
                backgroundColor: "#F1F1F1",
                boxShadow: "inset 0px 8px 25px -20px rgba(0, 0, 0, 0.4)",
                zIndex: "10000",
            }}
        >
            <div className={styles.navigationWrapper} id="navigationWrapper" ref={navigationWrapperRef}>
                <ul className={styles.navigationList}>
                    <li className={styles.navigation}>
                        <Link to="/" className={styles.normalLink}>
                            <AiFillHome className={styles.homeIcon} />
                        </Link>
                    </li>
                    {props.navigationList.map((navigation, idx) => {
                        return (
                            <li className={styles.navigation} key={idx}>
                                <div className={styles.arrow}>{`>`}</div>
                                <Link
                                    to={navigation.url}
                                    className={`${
                                        idx === props.navigationList.length - 1
                                            ? styles.disabledLink
                                            : styles.normalLink
                                    }`}
                                >
                                    <div className={styles.title}>{navigation.title}</div>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </OverWrapper>
    );
});
