import styles from "@/pages/Common/TeacherDetails/index.module.scss";

interface Props {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const TabContents: React.VFC<Props> = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            className={styles.tabContents}
        >
            {value === index && <>{children}</>}
        </div>
    );
};
