import { useCallback, useEffect, useState } from "react";

import { Avatar, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { StudentToDoResponse } from "@/store/autogenApi";
import { useCompleteStudentToDoMutation } from "@/store/hooks/studentToDos";
import { EvaluationModal } from "../../../EvaluationModal";
import styles from "../../index.module.scss";
import { ToDosTooltip } from "../ToDosTooltip";
import { ApproveUpdateFeeModal } from "../../../ApproveUpdateFeeModal";
import { PayForCourseModal } from "./PayForCourseModal";

interface Props {
    studentToDos: StudentToDoResponse[];
}

export const StudentToDoListContents: React.VFC<Props> = (props) => {
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const [evaluationModalOpen, setEvaluationModalOpen] = useState<boolean>(false);
    const [updateFeeModalOpen, setUpdateFeeModalOpen] = useState<boolean>(false);
    const [payForCourseModalOpen, setPayForCourseModalOpen] = useState<boolean>(false);
    const [targetStudentToDo, setTargetStudentToDo] = useState<StudentToDoResponse | undefined>(undefined);
    const [maxModalHeight, setMaxModalHeight] = useState<number>(0);

    useEffect(() => {
        changeModalHeight();
        window.addEventListener("resize", () => {
            changeModalHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalHeight();
            });
        };
    }, []);

    const changeModalHeight = useCallback(() => {
        const innerHeight = window.innerHeight;
        setMaxModalHeight(innerHeight * 0.75);
    }, []);

    const completeStudentToDo = useCompleteStudentToDoMutation();

    const getDiffTime = useCallback((createdAt: Date) => {
        const currentTime = new Date();
        const fixedTime = new Date(createdAt);
        const diffTimeByMillisecond = currentTime.getTime() - fixedTime.getTime();
        const diffDate = Math.floor(diffTimeByMillisecond / (1000 * 60 * 60 * 24));
        const diffHours = Math.floor(diffTimeByMillisecond / (1000 * 60 * 60)) - diffDate * 24;
        const diffMinutes = Math.floor(diffTimeByMillisecond / (1000 * 60)) - diffHours * 60;
        if (diffDate === 0) {
            if (diffHours === 0) {
                if (diffMinutes === 0) {
                    return "ちょうど今";
                } else {
                    return diffMinutes + "分前";
                }
            } else {
                return diffHours + "時間前";
            }
        } else {
            return diffDate + "日前";
        }
    }, []);

    const handleTooltipClose = useCallback(() => {
        setTooltipOpen(false);
    }, []);

    const handleTooltipOpen = useCallback(() => {
        setTooltipOpen(true);
    }, []);

    const handleCheckButtonClick = useCallback(() => {
        if (tooltipOpen) {
            setTooltipOpen(false);
        } else {
            setTooltipOpen(true);
        }
    }, [tooltipOpen]);

    const handleEvaluationModalClose = useCallback(() => {
        setEvaluationModalOpen(false);
    }, []);

    const history = useHistory();

    const handleStudentToDoClick = useCallback(
        (studentToDo: StudentToDoResponse) => {
            (async () => {
                setTooltipOpen(false);
                const type = studentToDo.toDoType;
                if (type === "overallEvaluation" || type === "regularEvaluation") {
                    setTargetStudentToDo(studentToDo);
                    setEvaluationModalOpen(true);
                } else if (type === "payForCourse") {
                    setTargetStudentToDo(studentToDo);
                    setPayForCourseModalOpen(true);
                } else if (type === "approveUpdateMonthlyFee" || type === "approveTemporaryUpdateMonthlyFee") {
                    setTargetStudentToDo(studentToDo);
                    setUpdateFeeModalOpen(true);
                }
            })();
        },
        [completeStudentToDo],
    );

    const getToDoTitle = useCallback((studentToDo: StudentToDoResponse) => {
        if (studentToDo.toDoType === "overallEvaluation") {
            return "全授業完了の報告が届きました。";
        } else if (studentToDo.toDoType === "regularEvaluation") {
            return "定期講座の月一授業評価";
        } else if (studentToDo.toDoType === "payForCourse") {
            return "スケジュール申請が承認されました。";
        } else if (studentToDo.toDoType === "approveUpdateMonthlyFee") {
            return "月謝の更新申請が届きました。";
        } else if (studentToDo.toDoType === "approveTemporaryUpdateMonthlyFee") {
            return "月謝の更新（期間指定あり）申請が届きました。";
        }
    }, []);

    const getToDoAction = useCallback((studentToDo: StudentToDoResponse) => {
        if (studentToDo.toDoType === "overallEvaluation") {
            return "総合評価を行ってください";
        } else if (studentToDo.toDoType === "regularEvaluation") {
            return "対象期間の授業評価を行ってください";
        } else if (studentToDo.toDoType === "payForCourse") {
            return "支払い情報を入力してください";
        } else if (studentToDo.toDoType === "approveUpdateMonthlyFee") {
            return "承認を判断してください";
        } else if (studentToDo.toDoType === "approveTemporaryUpdateMonthlyFee") {
            return "承認を判断してください";
        }
    }, []);

    const getSrc = useCallback((studentToDo: StudentToDoResponse) => {
        if (studentToDo.toDoType === "approveUpdateMonthlyFee") {
            return studentToDo.subscription?.class?.course?.teacher?.iconImageUrl;
        } else if (studentToDo.toDoType === "approveTemporaryUpdateMonthlyFee") {
            return studentToDo.subscriptionSchedule?.subscription?.class?.course?.teacher?.iconImageUrl;
        } else {
            return studentToDo.class?.course?.teacher?.iconImageUrl;
        }
    }, []);

    const handleUpdateFeeModalClose = useCallback(() => {
        setUpdateFeeModalOpen(false);
    }, []);

    const handlePayForCourseModalClose = useCallback(() => {
        setPayForCourseModalOpen(false);
    }, []);

    return (
        <>
            <ToDosTooltip
                open={tooltipOpen}
                badgeContent={props.studentToDos.length}
                handleTooltipClose={handleTooltipClose}
                handleTooltipOpen={handleTooltipOpen}
                handleCheckButtonClick={handleCheckButtonClick}
                title={
                    <div className={styles.toDoTooltipContents}>
                        <div className={styles.toDoListTitle}>やることリスト</div>

                        {props.studentToDos.length ? (
                            <ul className={styles.toDos} style={{ maxHeight: maxModalHeight }}>
                                {props.studentToDos.map((toDo, idx) => {
                                    return (
                                        <li className={styles.toDo}>
                                            <Button
                                                key={toDo.studentToDoId}
                                                className={styles.toDoButton}
                                                onClick={() => {
                                                    handleStudentToDoClick(toDo);
                                                }}
                                                style={{ borderTop: idx === 0 ? "none" : "1px solid #CCC" }}
                                            >
                                                <Avatar src={getSrc(toDo)} className={styles.avatar} />
                                                <div className={styles.centerWrapper}>
                                                    <div className={styles.title}>{getToDoTitle(toDo)}</div>
                                                    <div className={styles.publicRequest}>{getToDoAction(toDo)}</div>
                                                </div>
                                                <div className={styles.diffTime}>
                                                    {toDo.createdAt && getDiffTime(new Date(toDo.createdAt))}
                                                </div>
                                            </Button>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            <div className={styles.nothing}>現在やることはありません。</div>
                        )}
                    </div>
                }
            />
            {/* payForCourseModalOpen時にエラー吐くので一旦evaluationModalOpen を条件に加える */}
            {targetStudentToDo && evaluationModalOpen && (
                <EvaluationModal
                    open={evaluationModalOpen}
                    activeStudentToDo={targetStudentToDo}
                    handleModalClose={handleEvaluationModalClose}
                />
            )}
            {targetStudentToDo && updateFeeModalOpen && (
                <ApproveUpdateFeeModal
                    open={updateFeeModalOpen}
                    targetStudentToDo={targetStudentToDo}
                    handleModalClose={handleUpdateFeeModalClose}
                />
            )}
            {targetStudentToDo && payForCourseModalOpen && (
                <PayForCourseModal
                    open={payForCourseModalOpen}
                    targetStudentToDo={targetStudentToDo}
                    handleModalClose={handlePayForCourseModalClose}
                />
            )}
        </>
    );
};
