import { memo, useCallback, useEffect, useState } from "react";

import { Button, Modal } from "@material-ui/core";
import { RiCloseFill } from "react-icons/ri";

import { PublicStudentResponse } from "@/store/autogenApi";
import { useGetPublicUserQuery } from "@/store/hooks/users";
import { QueryLoadingWrapper } from "../QueryLoadingWrapper";

import styles from "./index.module.scss";
import { genderSet } from "@/utils/GenderSet";

import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import MailIcon from "@material-ui/icons/Mail";
import { ChatModal } from "../ChatModal";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    open: boolean;
    student: PublicStudentResponse;
    handleStudentInfoModalClose: () => void;
    handleModalTargetStudentChange?: (student: PublicStudentResponse | undefined) => void;
}

export const StudentInfoModal: React.VFC<Props> = memo(function StudentInfoModal(props) {
    const [modalHeight, setModalHeight] = useState<number>(0);
    const [chatModalOpen, setMessageModalOpen] = useState<boolean>(false);

    const userMode = useSelector((state: RootState) => state.jwt.userMode as string);
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);

    const userQueryState = useGetPublicUserQuery(props.student.userId);

    const changeModalHeight = useCallback(() => {
        const innerHeight = window.innerHeight;
        setModalHeight(innerHeight * 0.75);
    }, []);

    useEffect(() => {
        changeModalHeight();
        window.addEventListener("resize", () => {
            changeModalHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalHeight();
            });
        };
    }, []);

    const getBirthDate = useCallback((birthDateString: string) => {
        const birthDate = new Date(birthDateString);
        const year = birthDate.getFullYear();
        const month = birthDate.getMonth() + 1;
        const date = birthDate.getDate();
        return `${year}年${month}月${date}日`;
    }, []);

    const handleMessageModalOpen = useCallback(() => {
        setMessageModalOpen(true);
    }, [chatModalOpen]);

    const handleChatModalClose = useCallback(() => {
        setMessageModalOpen(false);
    }, []);

    const handleStudentInfoModalClose = useCallback(() => {
        props.handleStudentInfoModalClose();
        if (!chatModalOpen && props.handleModalTargetStudentChange) {
            props.handleModalTargetStudentChange(undefined);
        }
    }, [chatModalOpen]);

    return (
        <>
            <Modal open={props.open} onClose={handleStudentInfoModalClose}>
                <div className={styles.modalContents}>
                    <QueryLoadingWrapper {...userQueryState}>
                        {(user) => (
                            <>
                                <div className={styles.userInfoRelative}>
                                    <Button className={styles.closeButton} onClick={handleStudentInfoModalClose}>
                                        <RiCloseFill className={styles.closeIcon} />
                                    </Button>
                                    <AvatarFromS3
                                        url={props.student.iconImageUrl}
                                        objectKey={props.student.iconImageObjectKey}
                                        className={styles.modalAvatar}
                                    />
                                    <div className={styles.profile}>
                                        <span className={styles.nickname}>{props.student?.nickname}</span>
                                        さんのプロフィール
                                    </div>
                                    <ul className={styles.infoList} style={{ maxHeight: `${modalHeight}px` }}>
                                        <li className={styles.listItem}>
                                            <div className={styles.leftWrapper}>
                                                <div className={styles.titleWrapper}>
                                                    <div className={styles.title}>性別</div>
                                                </div>
                                            </div>
                                            <div className={styles.rightWrapper}>
                                                <div className={styles.value}>
                                                    {user.isGenderPublic ? genderSet[user.gender] : "非公開"}
                                                </div>
                                            </div>
                                        </li>
                                        <li className={styles.listItem}>
                                            <div className={styles.leftWrapper}>
                                                <div className={styles.titleWrapper}>
                                                    <div className={styles.title}>生年月日</div>
                                                </div>
                                            </div>
                                            <div className={styles.rightWrapper}>
                                                <div className={styles.value}>
                                                    {user.isBirthDatePublic
                                                        ? `${getBirthDate(user.birthDate)}`
                                                        : "非公開"}
                                                </div>
                                            </div>
                                        </li>
                                        <li className={styles.listItem}>
                                            <div className={styles.leftWrapper}>
                                                <div className={styles.titleWrapper}>
                                                    <div className={styles.title}>学年</div>
                                                </div>
                                            </div>
                                            <div className={styles.rightWrapper}>
                                                <div className={styles.value}>
                                                    {user.isGradePublic ? `${user.grade}` : "非公開"}
                                                </div>
                                            </div>
                                        </li>
                                        <li className={styles.listItem}>
                                            <div className={styles.leftWrapper}>
                                                <div className={styles.titleWrapper}>
                                                    <div className={styles.title}>都道府県</div>
                                                </div>
                                            </div>
                                            <div className={styles.rightWrapper}>
                                                <div className={styles.value}>
                                                    {user.isPrefecturePublic ? `${user.prefecture}` : "非公開"}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    {userMode === "teacher" && (
                                        <>
                                            <Button className={styles.messageButton} onClick={handleMessageModalOpen}>
                                                <MailIcon className={styles.mailIcon} />
                                                <div className={styles.message}>メッセージ</div>
                                            </Button>
                                            {chatModalOpen && (
                                                <ChatModal
                                                    studentId={props.student.studentId}
                                                    teacherId={teacherId}
                                                    handleClose={handleChatModalClose}
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </QueryLoadingWrapper>
                </div>
            </Modal>
        </>
    );
});
