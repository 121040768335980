import { autogenApi } from "./autogenApi";

// students
const PRIVATE_STUDENT_TAG = "PRIVATE_STUDENT" as const;
const PUBLIC_STUDENT_TAG = "PUBLIC_STUDENT" as const;
const REQUESTS_MADE_TAG = "REQUESTS_MADE" as const;
const RESERVED_CLASSES_TAG = "RESERVED_CLASSES" as const;
const CLASSES_COMPLETED_TAG = "CLASSES_COMPLETED" as const;
const ALL_FAVORITE_COURSES_TAG = "ALL_FAVORITE_SHORT_COURSES" as const;
const FAVORITE_COURSES_TAG = "FAVORITE_SHORT_COURSES" as const;
const COURSE_BROWSING_HISTORY_TAG = "COURSE_BROWSING_HISTORY" as const;
const PAST_COURSES_TAG = "PAST_COURSES" as const;
const STUDENT_CHECKED_ANNOUNCEMENT_IDS_TAG = "STUDENT_CHECKED_ANNOUNCEMENT_IDS" as const;
// reviews
const REVIEWS_BY_COURSE_ID_TAG = "REVIEWS" as const;
const REVIEWS_BY_STUDENT_ID_TAG = "REVIEWS" as const;
// studentToDos
const STUDENT_TO_DOS_TAG = "STUDENT_TO_DOS" as const;
// teacherToDos
const TEACHER_TO_DOS_TAG = "TEACHER_TO_DOS" as const;
// teachers
const PRIVATE_TEACHER_TAG = "PRIVATE_TEACHER" as const;
const PUBLIC_TEACHER_TAG = "PUBLIC_TEACHER" as const;
const REQUEST_BROWSING_HISTORY_TAG = "SHORT_REQUEST_BROWSING_HISTORY" as const;
const ALL_FAVORITE_REQUESTS_TAG = "ALL_FAVORITE_REQUESTS" as const;
const FAVORITE_REQUESTS_TAG = "FAVORITE_REQUESTS" as const;
const TEACHER_SEARCH_RESULT_TAG = "TEACHER_SEARCH_RESULT" as const;
const TEACHER_CHECKED_ANNOUNCEMENT_IDS_TAG = "TEACHER_CHECKED_ANNOUNCEMENT_IDS" as const;
const MY_COURSES_TAG = "MY_COURSES" as const;
const MY_PROPOSALS_TAG = "MY_PROPOSALS" as const;
// users
const PUBLIC_USER_TAG = "PUBLIC_USER" as const;
const PRIVATE_USER_TAG = "PRIVATE_USER" as const;
const PUSH_NOTIFICATIONS_SETTING_TAG = "PUSH_NOTIFICATIONS_SETTING" as const;
const EMAIL_NOTIFICATIONS_SETTING_TAG = "EMAIL_NOTIFICATIONS_SETTING" as const;
// courses
const COURSE_WITH_VALID_LESSONS_TAG = "COURSE_WITH_VALID_LESSONS" as const;
const COURSE_SEARCH_RESULT_TAG = "COURSE_SEARCH_RESULT" as const;
const COURSE_FAVORITE_COUNT_TAG = "COURSE_FAVORITE_COUNT" as const;
const COURSE_FOR_COURSE_MANAGEMENT_TAG = "COURSE_FOR_COURSE_MANAGEMENT" as const;
const COURSE_DETAILS_TAG = "COURSE_DETAILS" as const;
// draftCourse
const DRAFT_COURSE_TAG = "DRAFT_COURSE" as const;
const MY_DRAFT_COURSES_TAG = "MY_DRAFT_COURSES" as const;
// lessons
const STUDENT_LESSONS_TAG = "STUDENT_LESSONS" as const;
const TEACHER_LESSONS_TAG = "TEACHER_LESSONS" as const;
// publicRequests
const REQUEST_SEARCH_RESULT_TAG = "REQUEST_SEARCH_RESULT" as const;
const PUBLIC_REQUEST_DETAILS_TAG = "PUBLIC_REQUEST_DETAILS" as const;
// draftPublicRequests
const DRAFT_PUBLIC_REQUEST_TAG = "DRAFT_PUBLIC_REQUEST" as const;
const MY_DRAFT_PUBLIC_REQUESTS_TAG = "MY_DRAFT_PUBLIC_REQUESTS" as const;
// educationalBackgrounds
const UNIVERSITIES_TAG = "UNIVERSITIES" as const;
const FACULTIES_TAG = "FACULTIES" as const;
const DEPARTMENTS_TAG = "DEPARTMENTS" as const;
const EDUCATIONAL_BACKGROUNDS_TAG = "EDUCATIONAL_BACKGROUNDS" as const;
// point_records
const POINT_RECORD_HISTORY_TAG = "POINT_RECORD_HISTORY" as const;
// stripe
const PAYMENT_METHODS_TAG = "PAYMENT_METHODS" as const;
const DEFAULT_PAYMENT_METHOD_TAG = "DEFAULT_PAYMENT_METHOD" as const;
const BANK_ACCOUNT_LIST_TAG = "BANK_ACCOUNT_LIST" as const;
// subjects
const SUBJECTS_TAG = "SUBJECTS" as const;
// chats
const INDIVIDUAL_CHAT_TAG = "INDIVIDUAL_CHAT" as const;
const GROUP_CHAT_TAG = "GROUP_CHAT" as const;
const CHATS_TAG = "CHATS" as const;
// announcements
const ANNOUNCEMENTS_TAG = "ANNOUNCEMENTS" as const;
// proceedsRecords
const PROCEEDS_RECORDS_TAG = "PROCEEDS_RECORDS" as const;

const TAGS = [
    // students
    PRIVATE_STUDENT_TAG,
    PUBLIC_STUDENT_TAG,
    REQUESTS_MADE_TAG,
    FAVORITE_COURSES_TAG,
    COURSE_BROWSING_HISTORY_TAG,
    RESERVED_CLASSES_TAG,
    CLASSES_COMPLETED_TAG,
    FAVORITE_COURSES_TAG,
    FAVORITE_COURSES_TAG,
    COURSE_BROWSING_HISTORY_TAG,
    PAST_COURSES_TAG,
    ALL_FAVORITE_COURSES_TAG,
    STUDENT_CHECKED_ANNOUNCEMENT_IDS_TAG,
    // reviews
    REVIEWS_BY_COURSE_ID_TAG,
    REVIEWS_BY_STUDENT_ID_TAG,
    // studentToDos
    STUDENT_TO_DOS_TAG,
    // teacherToDos
    TEACHER_TO_DOS_TAG,
    // teachers
    PRIVATE_TEACHER_TAG,
    PUBLIC_TEACHER_TAG,
    ALL_FAVORITE_REQUESTS_TAG,
    FAVORITE_REQUESTS_TAG,
    REQUEST_BROWSING_HISTORY_TAG,
    FAVORITE_REQUESTS_TAG,
    MY_COURSES_TAG,
    MY_PROPOSALS_TAG,
    TEACHER_SEARCH_RESULT_TAG,
    TEACHER_CHECKED_ANNOUNCEMENT_IDS_TAG,
    // users,
    PUBLIC_USER_TAG,
    PRIVATE_USER_TAG,
    PUSH_NOTIFICATIONS_SETTING_TAG,
    EMAIL_NOTIFICATIONS_SETTING_TAG,
    // courses
    COURSE_WITH_VALID_LESSONS_TAG,
    COURSE_SEARCH_RESULT_TAG,
    COURSE_FAVORITE_COUNT_TAG,
    COURSE_FOR_COURSE_MANAGEMENT_TAG,
    COURSE_DETAILS_TAG,
    // draftCourse
    DRAFT_COURSE_TAG,
    MY_DRAFT_COURSES_TAG,
    // lessons
    STUDENT_LESSONS_TAG,
    TEACHER_LESSONS_TAG,
    // publicRequests
    REQUEST_SEARCH_RESULT_TAG,
    PUBLIC_REQUEST_DETAILS_TAG,
    // draftPublicRequests
    DRAFT_PUBLIC_REQUEST_TAG,
    MY_DRAFT_PUBLIC_REQUESTS_TAG,
    // educationalBackgrounds
    UNIVERSITIES_TAG,
    FACULTIES_TAG,
    DEPARTMENTS_TAG,
    EDUCATIONAL_BACKGROUNDS_TAG,
    // point_records
    POINT_RECORD_HISTORY_TAG,
    // stripe
    PAYMENT_METHODS_TAG,
    DEFAULT_PAYMENT_METHOD_TAG,
    BANK_ACCOUNT_LIST_TAG,
    // subjects
    SUBJECTS_TAG,
    // chats
    INDIVIDUAL_CHAT_TAG,
    GROUP_CHAT_TAG,
    CHATS_TAG,
    // announcements
    ANNOUNCEMENTS_TAG,
    // proceedsRecords
    PROCEEDS_RECORDS_TAG,
] as const;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// students
const REQUESTS_MADE_TAG_ID = "REQUESTS_MADE";
const ALL_FAVORITE_COURSES_TAG_ID = "ALL_FAVORITE_COURSES";
const FAVORITE_COURSES_TAG_ID = "FAVORITE_COURSES";
const COURSE_BROWSING_HISTORY_TAG_ID = "COURSE_BROWSING_HISTORY";
const RESERVED_CLASSES_TAG_ID = "RESERVED_CLASSES";
const CLASSES_COMPLETED_TAG_ID = "CLASSES_COMPLETED";
const FAVORITE_SHORT_COURSES_TAG_ID = "FAVORITE_SHORT_COURSES";
const FAVORITE_REGULAR_COURSES_TAG_ID = "FAVORITE_REGULAR_COURSES";
const SHORT_COURSE_BROWSING_HISTORY_TAG_ID = "SHORT_COURSE_BROWSING_HISTORY";
const REGULAR_COURSE_BROWSING_HISTORY_TAG_ID = "REGULAR_COURSE_BROWSING_HISTORY";
const PAST_COURSES_TAG_ID = "PAST_COURSES";
const STUDENT_CHECKED_ANNOUNCEMENT_IDS_TAG_ID = "STUDENT_CHECKED_ANNOUNCEMENT_IDS";
// reviews
const REVIEWS_BY_COURSE_ID_TAG_ID = "REVIEWS_BY_COURSE_ID";
const REVIEWS_BY_STUDENT_ID_TAG_ID = "REVIEWS_BY_STUDENT_ID";
// studentToDos
const STUDENT_TO_DOS_TAG_ID = "STUDENT_TO_DOS";
// teacherToDos
const TEACHER_TO_DOS_TAG_ID = "TEACHER_TO_DOS";
// teachers
const REQUEST_BROWSING_HISTORY_TAG_ID = "REGULAR_REQUEST_BROWSING_HISTORY";
const ALL_FAVORITE_REQUESTS_TAG_ID = "FAVORITE_REQUESTS";
const FAVORITE_REQUESTS_TAG_ID = "FAVORITE_REQUESTS";
const DRAFT_COURSES_TAG_ID = "DRAFT_COURSES";
const TEACHER_SEARCH_RESULT_TAG_ID = "TEACHER_SEARCH_RESULT";
const TEACHER_CHECKED_ANNOUNCEMENT_IDS_TAG_ID = "TEACHER_CHECKED_ANNOUNCEMENT_IDS";
const MY_COURSES_TAG_ID = "MY_COURSES";
const MY_PROPOSALS_TAG_ID = "MY_PROPOSALS";
// users,
const PUSH_NOTIFICATIONS_SETTING_TAG_ID = "PUSH_NOTIFICATIONS_SETTING";
const EMAIL_NOTIFICATIONS_SETTING_TAG_ID = "EMAIL_NOTIFICATIONS_SETTING";
// courses
const COURSE_SEARCH_RESULT_TAG_ID = "COURSE_SEARCH_RESULT";
const COURSE_FOR_COURSE_MANAGEMENT_TAG_ID = "COURSE_FOR_COURSE_MANAGEMENT";
const COURSE_DETAILS_TAG_ID = "COURSE_DETAILS";
// draftCourse
const MY_DRAFT_COURSES_TAG_ID = "MY_DRAFT_COURSES";
// lessons
const STUDENT_LESSONS_TAG_ID = "STUDENT_LESSONS";
const TEACHER_LESSONS_TAG_ID = "TEACHER_LESSONS";
// publicRequests
const REQUEST_SEARCH_RESULT_TAG_ID = "REQUEST_SEARCH_RESULT";
const PUBLIC_REQUEST_DETAILS_TAG_ID = "PUBLIC_REQUEST_DETAILS";
// draftPublicRequests
const MY_DRAFT_PUBLIC_REQUESTS_TAG_ID = "MY_DRAFT_PUBLIC_REQUESTS";
// educationalBackgrounds
const UNIVERSITIES_TAG_ID = "UNIVERSITIES";
const FACULTIES_TAG_ID = "FACULTIES";
const DEPARTMENTS_TAG_ID = "DEPARTMENTS";
const EDUCATIONAL_BACKGROUNDS_TAG_ID = "EDUCATIONAL_BACKGROUNDS";
// point_records
const POINT_RECORD_HISTORY_TAG_ID = "POINT_RECORD_HISTORY";
// stripe
const PAYMENT_METHODS_TAG_ID = "PAYMENT_METHODS";
const DEFAULT_PAYMENT_METHOD_TAG_ID = "DEFAULT_PAYMENT_METHOD";
const BANK_ACCOUNT_LIST_TAG_ID = "BANK_ACCOUNT_LIST";
// subjects
const SUBJECTS_TAG_ID = "SUBJECTS";
// chats
const INDIVIDUAL_CHAT_TAG_ID = "INDIVIDUAL_CHAT";
const CHATS_TAG_ID = "CHATS";
// announcements
const ANNOUNCEMENTS_TAG_ID = "ANNOUNCEMENTS";
// proceedsRecords
const PROCEEDS_RECORDS_TAG_ID = "PROCEEDS_RECORDS";

/**
 * getPublicTeacher
 * type: TEACHER
 * id: abcdef
 *
 * updateTeacher
 * type: TEACHER
 * id: abcdef
 */
export const defaultApi = autogenApi.enhanceEndpoints({
    addTagTypes: TAGS,
    // TODO: 適切にTagを設定し、cacheをうまく使う。
    endpoints: {
        // students
        updateStudent: {
            invalidatesTags: (_, __, args) => [
                { type: PRIVATE_STUDENT_TAG, id: args.updateStudentRequestBody.studentId },
            ],
        },
        getPrivateStudent: {
            providesTags: (_, __, args) => [{ type: PRIVATE_STUDENT_TAG, id: args.studentId }],
        },
        getPublicStudent: {
            providesTags: (_, __, args) => [{ type: PUBLIC_STUDENT_TAG, id: args.studentId }],
        },
        getMyPublicRequests: {
            providesTags: (_, __, ___) => [{ type: REQUESTS_MADE_TAG, id: REQUESTS_MADE_TAG_ID }],
        },
        updateFavoriteCourses: {
            invalidatesTags: (_, __, args) => [
                { type: FAVORITE_COURSES_TAG, id: FAVORITE_COURSES_TAG_ID },
                { type: ALL_FAVORITE_COURSES_TAG, id: ALL_FAVORITE_COURSES_TAG_ID },
                { type: COURSE_FAVORITE_COUNT_TAG, id: args.updateFavoriteCoursesRequestBody.courseId },
            ],
        },
        updateCourseBrowsingHistory: {
            invalidatesTags: (_, __, ___) => [
                { type: COURSE_BROWSING_HISTORY_TAG, id: COURSE_BROWSING_HISTORY_TAG_ID },
            ],
        },
        deleteStudent: {
            invalidatesTags: (_, __, args) => [
                { type: PRIVATE_STUDENT_TAG, id: args.studentId },
                { type: PUBLIC_STUDENT_TAG, id: args.studentId },
            ],
        },
        getReservedClasses: {
            providesTags: (_, __, ___) => [{ type: RESERVED_CLASSES_TAG, id: RESERVED_CLASSES_TAG_ID }],
        },
        getClassesCompleted: {
            providesTags: (_, __, ___) => [{ type: CLASSES_COMPLETED_TAG, id: CLASSES_COMPLETED_TAG_ID }],
        },
        getAllFavoriteCourses: {
            providesTags: (_, __, ___) => [{ type: ALL_FAVORITE_COURSES_TAG, id: ALL_FAVORITE_COURSES_TAG_ID }],
        },
        getFavoriteCourses: {
            providesTags: (_, __, ___) => [{ type: FAVORITE_COURSES_TAG, id: FAVORITE_COURSES_TAG_ID }],
        },
        getCourseBrowsingHistory: {
            providesTags: (_, __, ___) => [{ type: COURSE_BROWSING_HISTORY_TAG, id: COURSE_BROWSING_HISTORY_TAG_ID }],
        },
        getPastCourses: {
            providesTags: (_, __, ___) => [{ type: PAST_COURSES_TAG, id: PAST_COURSES_TAG_ID }],
        },
        getStudentCheckedAnnouncementIds: {
            providesTags: (_, __, ___) => [
                { type: STUDENT_CHECKED_ANNOUNCEMENT_IDS_TAG, id: STUDENT_CHECKED_ANNOUNCEMENT_IDS_TAG_ID },
            ],
        },
        updateStudentCheckedAnnouncements: {
            invalidatesTags: (_, __, ___) => [
                { type: STUDENT_CHECKED_ANNOUNCEMENT_IDS_TAG, id: STUDENT_CHECKED_ANNOUNCEMENT_IDS_TAG_ID },
            ],
        },

        // reviews
        getReviewsByCourseId: {
            providesTags: (_, __, ___) => [{ type: REVIEWS_BY_COURSE_ID_TAG, id: REVIEWS_BY_COURSE_ID_TAG_ID }],
        },
        getReviewsByStudentId: {
            providesTags: (_, __, ___) => [{ type: REVIEWS_BY_STUDENT_ID_TAG, id: REVIEWS_BY_STUDENT_ID_TAG_ID }],
        },
        createReview: {
            invalidatesTags: (_, __, ___) => [{ type: STUDENT_TO_DOS_TAG, id: STUDENT_TO_DOS_TAG_ID }],
        },

        //studentToDos
        completeStudentToDo: {
            invalidatesTags: (_, __, ___) => [{ type: STUDENT_TO_DOS_TAG, id: STUDENT_TO_DOS_TAG_ID }],
        },
        getStudentToDos: {
            providesTags: (_, __, ___) => [{ type: STUDENT_TO_DOS_TAG, id: STUDENT_TO_DOS_TAG_ID }],
        },

        // teacherToDos
        updateTeacherToDo: {
            invalidatesTags: (_, __, ___) => [{ type: TEACHER_TO_DOS_TAG, id: TEACHER_TO_DOS_TAG_ID }],
        },
        getTeacherToDos: {
            providesTags: (_, __, ___) => [{ type: TEACHER_TO_DOS_TAG, id: TEACHER_TO_DOS_TAG_ID }],
        },

        // teachers
        updateTeacher: {
            invalidatesTags: (_, __, args) => [
                { type: PRIVATE_TEACHER_TAG, id: args.updateTeacherRequestBody.teacherId },
            ],
        },
        getPrivateTeacher: {
            providesTags: (_, __, args) => [{ type: PRIVATE_TEACHER_TAG, id: args.teacherId }],
        },
        getPublicTeacher: {
            providesTags: (_, __, args) => [{ type: PUBLIC_TEACHER_TAG, id: args.teacherId }],
        },
        updateFavoritePublicRequests: {
            invalidatesTags: (_, __, ___) => [
                { type: ALL_FAVORITE_REQUESTS_TAG, id: ALL_FAVORITE_REQUESTS_TAG_ID },
                { type: FAVORITE_REQUESTS_TAG, id: FAVORITE_REQUESTS_TAG_ID },
            ],
        },
        updatePublicRequestBrowsingHistory: {
            invalidatesTags: (_, __, ___) => [
                { type: REQUEST_BROWSING_HISTORY_TAG, id: REQUEST_BROWSING_HISTORY_TAG_ID },
            ],
        },
        deleteTeacher: {
            invalidatesTags: (_, __, args) => [
                { type: PRIVATE_TEACHER_TAG, id: args.teacherId },
                { type: PUBLIC_TEACHER_TAG, id: args.teacherId },
            ],
        },
        getMyCoursesByTeacherId: {
            providesTags: (_, __, ___) => [{ type: MY_COURSES_TAG, id: MY_COURSES_TAG_ID }],
        },
        getCoursesOfTargetTeacher: {
            providesTags: (_, __, ___) => [{ type: MY_DRAFT_COURSES_TAG, id: MY_DRAFT_COURSES_TAG_ID }],
        },
        getMyProposalsByTeacherId: {
            providesTags: (_, __, ___) => [{ type: MY_PROPOSALS_TAG, id: MY_PROPOSALS_TAG_ID }],
        },
        getAllFavoritePublicRequests: {
            providesTags: (_, __, ___) => [{ type: ALL_FAVORITE_REQUESTS_TAG, id: ALL_FAVORITE_REQUESTS_TAG_ID }],
        },
        getFavoritePublicRequests: {
            providesTags: (_, __, ___) => [{ type: FAVORITE_REQUESTS_TAG, id: FAVORITE_REQUESTS_TAG_ID }],
        },
        getPublicRequestBrowsingHistory: {
            providesTags: (_, __, ___) => [{ type: REQUEST_BROWSING_HISTORY_TAG, id: REQUEST_BROWSING_HISTORY_TAG_ID }],
        },
        getTeacherSearchResults: {
            providesTags: (_, __, ___) => [{ type: TEACHER_SEARCH_RESULT_TAG, id: TEACHER_SEARCH_RESULT_TAG_ID }],
        },
        getTeacherCheckedAnnouncementIds: {
            providesTags: (_, __, ___) => [
                { type: TEACHER_CHECKED_ANNOUNCEMENT_IDS_TAG, id: TEACHER_CHECKED_ANNOUNCEMENT_IDS_TAG_ID },
            ],
        },

        // users
        getPublicUser: {
            providesTags: (_, __, args) => [{ type: PUBLIC_USER_TAG, id: args.userId }],
        },
        getPrivateUser: {
            providesTags: (_, __, args) => [{ type: PRIVATE_USER_TAG, id: args.userId }],
        },
        updateUser: {
            invalidatesTags: (_, __, args) => [
                { type: PUBLIC_USER_TAG, id: args.updateUserRequestBody.userId },
                { type: PRIVATE_USER_TAG, id: args.updateUserRequestBody.userId },
            ],
        },
        deleteUser: {
            invalidatesTags: (_, __, args) => [
                { type: PUBLIC_USER_TAG, id: args.userId },
                { type: PRIVATE_USER_TAG, id: args.userId },
            ],
        },
        getPushNotificationsSetting: {
            providesTags: (_, __, ___) => [
                { type: PUSH_NOTIFICATIONS_SETTING_TAG, id: PUSH_NOTIFICATIONS_SETTING_TAG_ID },
            ],
        },
        getEmailNotificationsSetting: {
            providesTags: (_, __, ___) => [
                { type: EMAIL_NOTIFICATIONS_SETTING_TAG, id: EMAIL_NOTIFICATIONS_SETTING_TAG_ID },
            ],
        },
        updateTeacherCheckedAnnouncements: {
            invalidatesTags: (_, __, ___) => [
                { type: TEACHER_CHECKED_ANNOUNCEMENT_IDS_TAG, id: TEACHER_CHECKED_ANNOUNCEMENT_IDS_TAG_ID },
            ],
        },

        // courses
        getCourseWithValidLessons: {
            providesTags: (_, __, args) => [{ type: COURSE_WITH_VALID_LESSONS_TAG, id: args.courseId }],
        },
        getCourseSearchResults: {
            providesTags: (_, __, ___) => [{ type: COURSE_SEARCH_RESULT_TAG, id: COURSE_SEARCH_RESULT_TAG_ID }],
        },
        getCourseFavoriteCount: {
            providesTags: (_, __, args) => [{ type: COURSE_FAVORITE_COUNT_TAG, id: args.courseId }],
        },
        getCourseForCourseManagement: {
            providesTags: (_, __, ___) => [
                { type: COURSE_FOR_COURSE_MANAGEMENT_TAG, id: COURSE_FOR_COURSE_MANAGEMENT_TAG_ID },
            ],
        },
        getCourseForCourseDetails: {
            providesTags: (_, __, args) => [{ type: COURSE_DETAILS_TAG, id: COURSE_DETAILS_TAG_ID }],
        },
        createCourse: {
            invalidatesTags: (_, __, ___) => [
                { type: TEACHER_LESSONS_TAG, id: TEACHER_LESSONS_TAG_ID },
                { type: COURSE_FOR_COURSE_MANAGEMENT_TAG, id: COURSE_FOR_COURSE_MANAGEMENT_TAG_ID },
                { type: COURSE_DETAILS_TAG, id: COURSE_DETAILS_TAG_ID },
                { type: MY_COURSES_TAG, id: MY_COURSES_TAG_ID },
            ],
        },
        updateCourse: {
            invalidatesTags: (_, __, ___) => [
                { type: TEACHER_LESSONS_TAG, id: TEACHER_LESSONS_TAG_ID },
                { type: COURSE_FOR_COURSE_MANAGEMENT_TAG, id: COURSE_FOR_COURSE_MANAGEMENT_TAG_ID },
                { type: COURSE_DETAILS_TAG, id: COURSE_DETAILS_TAG_ID },
                { type: MY_COURSES_TAG, id: MY_COURSES_TAG_ID },
            ],
        },

        // draftCourses
        getDraftCourse: {
            providesTags: (_, __, args) => [{ type: DRAFT_COURSE_TAG, id: args.draftCourseId }],
        },
        getMyDraftCourses: {
            providesTags: (_, __, ___) => [{ type: MY_DRAFT_COURSES_TAG, id: MY_DRAFT_COURSES_TAG_ID }],
        },
        deleteDraftCourse: {
            invalidatesTags: (_, __, args) => [
                { type: DRAFT_COURSE_TAG, id: args.draftCourseId },
                { type: MY_DRAFT_COURSES_TAG, id: MY_DRAFT_COURSES_TAG_ID },
            ],
        },
        createDraftCourse: {
            invalidatesTags: (_, __, ___) => [{ type: MY_DRAFT_COURSES_TAG, id: MY_DRAFT_COURSES_TAG_ID }],
        },
        updateDraftCourse: {
            invalidatesTags: (_, __, ___) => [{ type: MY_DRAFT_COURSES_TAG, id: MY_DRAFT_COURSES_TAG_ID }],
        },

        // classes
        withdrawFromClass: {
            invalidatesTags: (_, __, args) => [
                { type: PRIVATE_STUDENT_TAG, id: args.withdrawFromClassRequestBody.studentId },
                { type: RESERVED_CLASSES_TAG, id: RESERVED_CLASSES_TAG_ID },
            ],
        },
        reserveShortCourse: {
            invalidatesTags: (_, __, args) => [
                { type: PRIVATE_STUDENT_TAG, id: args.reserveShortCourseRequestBody.studentId },
                { type: STUDENT_TO_DOS_TAG, id: STUDENT_TO_DOS_TAG_ID },
                { type: RESERVED_CLASSES_TAG, id: RESERVED_CLASSES_TAG_ID },
                { type: STUDENT_LESSONS_TAG, id: STUDENT_LESSONS_TAG_ID },
                { type: ANNOUNCEMENTS_TAG, id: ANNOUNCEMENTS_TAG_ID },
            ],
        },
        reserveRegularCourse: {
            invalidatesTags: (_, __, args) => [
                { type: PRIVATE_STUDENT_TAG, id: args.reserveRegularCourseRequestBody.studentId },
                { type: RESERVED_CLASSES_TAG, id: RESERVED_CLASSES_TAG_ID },
                { type: STUDENT_LESSONS_TAG, id: STUDENT_LESSONS_TAG_ID },
                { type: ANNOUNCEMENTS_TAG, id: ANNOUNCEMENTS_TAG_ID },
            ],
        },
        reserveProposedShortCourse: {
            invalidatesTags: (_, __, args) => [
                { type: PRIVATE_STUDENT_TAG, id: args.reserveProposedShortCourseRequestBody.studentId },
                { type: STUDENT_TO_DOS_TAG, id: STUDENT_TO_DOS_TAG_ID },
                { type: RESERVED_CLASSES_TAG, id: RESERVED_CLASSES_TAG_ID },
                { type: STUDENT_LESSONS_TAG, id: STUDENT_LESSONS_TAG_ID },
                { type: ANNOUNCEMENTS_TAG, id: ANNOUNCEMENTS_TAG_ID },
            ],
        },
        reserveProposedRegularCourse: {
            invalidatesTags: (_, __, args) => [
                { type: PRIVATE_STUDENT_TAG, id: args.reserveProposedRegularCourseRequestBody.studentId },
                { type: RESERVED_CLASSES_TAG, id: RESERVED_CLASSES_TAG_ID },
                { type: STUDENT_LESSONS_TAG, id: STUDENT_LESSONS_TAG_ID },
                { type: ANNOUNCEMENTS_TAG, id: ANNOUNCEMENTS_TAG_ID },
            ],
        },
        closeApplication: {
            invalidatesTags: (_, __, args) => [
                { type: COURSE_WITH_VALID_LESSONS_TAG, id: args.closeApplicationRequestBody.courseId },
                { type: TEACHER_LESSONS_TAG, id: TEACHER_LESSONS_TAG_ID },
                { type: COURSE_FOR_COURSE_MANAGEMENT_TAG, id: COURSE_FOR_COURSE_MANAGEMENT_TAG_ID },
            ],
        },
        disapproveUpdateMonthlyFee: {
            invalidatesTags: [{ type: STUDENT_TO_DOS_TAG, id: STUDENT_TO_DOS_TAG_ID }],
        },
        approveUpdateMonthlyFee: {
            invalidatesTags: (_, __, ___) => [{ type: STUDENT_TO_DOS_TAG, id: STUDENT_TO_DOS_TAG_ID }],
        },
        disapproveTemporaryUpdateMonthlyFee: {
            invalidatesTags: (_, __, ___) => [{ type: STUDENT_TO_DOS_TAG, id: STUDENT_TO_DOS_TAG_ID }],
        },
        approveTemporaryUpdateMonthlyFee: {
            invalidatesTags: (_, __, ___) => [{ type: STUDENT_TO_DOS_TAG, id: STUDENT_TO_DOS_TAG_ID }],
        },
        reportToCompleteClass: {
            invalidatesTags: (_, __, ___) => [
                { type: TEACHER_LESSONS_TAG, id: TEACHER_LESSONS_TAG_ID },
                { type: COURSE_FOR_COURSE_MANAGEMENT_TAG, id: COURSE_FOR_COURSE_MANAGEMENT_TAG_ID },
            ],
        },
        updateMemo: {
            invalidatesTags: (_, __, ___) => [
                { type: COURSE_FOR_COURSE_MANAGEMENT_TAG, id: COURSE_FOR_COURSE_MANAGEMENT_TAG_ID },
            ],
        },
        updateClassName: {
            invalidatesTags: (_, __, ___) => [
                { type: COURSE_FOR_COURSE_MANAGEMENT_TAG, id: COURSE_FOR_COURSE_MANAGEMENT_TAG_ID },
            ],
        },

        // lessons
        getStudentLessons: {
            providesTags: (_, __, ___) => [{ type: STUDENT_LESSONS_TAG, id: STUDENT_LESSONS_TAG_ID }],
        },
        getTeacherLessons: {
            providesTags: (_, __, ___) => [{ type: TEACHER_LESSONS_TAG, id: TEACHER_LESSONS_TAG_ID }],
        },
        addLessonToClass: {
            invalidatesTags: (_, __, ___) => [
                { type: TEACHER_LESSONS_TAG, id: TEACHER_LESSONS_TAG_ID },
                { type: COURSE_FOR_COURSE_MANAGEMENT_TAG, id: COURSE_FOR_COURSE_MANAGEMENT_TAG_ID },
            ],
        },
        updateLesson: {
            invalidatesTags: (_, __, ___) => [
                { type: TEACHER_LESSONS_TAG, id: TEACHER_LESSONS_TAG_ID },
                { type: COURSE_FOR_COURSE_MANAGEMENT_TAG, id: COURSE_FOR_COURSE_MANAGEMENT_TAG_ID },
            ],
        },
        deleteLesson: {
            invalidatesTags: (_, __, ___) => [
                { type: TEACHER_LESSONS_TAG, id: TEACHER_LESSONS_TAG_ID },
                { type: COURSE_FOR_COURSE_MANAGEMENT_TAG, id: COURSE_FOR_COURSE_MANAGEMENT_TAG_ID },
            ],
        },
        addClass: {
            invalidatesTags: (_, __, ___) => [
                { type: TEACHER_LESSONS_TAG, id: TEACHER_LESSONS_TAG_ID },
                { type: COURSE_FOR_COURSE_MANAGEMENT_TAG, id: COURSE_FOR_COURSE_MANAGEMENT_TAG_ID },
                { type: MY_COURSES_TAG, id: MY_COURSES_TAG_ID },
            ],
        },

        // publicRequests
        getPublicRequest: {
            providesTags: (_, __, ____) => [{ type: PUBLIC_REQUEST_DETAILS_TAG, id: PUBLIC_REQUEST_DETAILS_TAG_ID }],
        },
        getPublicRequestSearchResults: {
            providesTags: (_, __, ___) => [{ type: REQUEST_SEARCH_RESULT_TAG, id: REQUEST_SEARCH_RESULT_TAG_ID }],
        },
        createProposal: {
            invalidatesTags: (_, __, ___) => [
                { type: PUBLIC_REQUEST_DETAILS_TAG, id: PUBLIC_REQUEST_DETAILS_TAG_ID },
                { type: MY_PROPOSALS_TAG, id: MY_PROPOSALS_TAG_ID },
            ],
        },
        updateProposal: {
            invalidatesTags: (_, __, ___) => [
                { type: PUBLIC_REQUEST_DETAILS_TAG, id: PUBLIC_REQUEST_DETAILS_TAG_ID },
                {
                    type: MY_PROPOSALS_TAG,
                    id: MY_PROPOSALS_TAG_ID,
                },
            ],
        },

        // draftPublicRequests
        // getDraftPublicRequest: {
        //     providesTags: (_, __, args) => [{ type: DRAFT_PUBLIC_REQUEST_TAG, id: args.draftPublicRequestId }],
        // },
        // getMyDraftPublicRequests: {
        //     providesTags: (_, __, ___) => [{ type: MY_DRAFT_PUBLIC_REQUESTS_TAG, id: MY_DRAFT_PUBLIC_REQUESTS_TAG_ID }],
        // },
        createDraftPublicRequest: {
            invalidatesTags: (_, __, ___) => [
                { type: MY_DRAFT_PUBLIC_REQUESTS_TAG, id: MY_DRAFT_PUBLIC_REQUESTS_TAG_ID },
            ],
        },
        updateDraftPublicRequest: {
            invalidatesTags: (_, __, ___) => [
                { type: MY_DRAFT_PUBLIC_REQUESTS_TAG, id: MY_DRAFT_PUBLIC_REQUESTS_TAG_ID },
            ],
        },

        // educational-backgrounds
        getEducationalBackgroundsByInitial: {
            providesTags: (_, __, ___) => [{ type: EDUCATIONAL_BACKGROUNDS_TAG, id: EDUCATIONAL_BACKGROUNDS_TAG_ID }],
        },

        // pointsHistory
        getPointsHistory: {
            providesTags: (_, __, ___) => [{ type: POINT_RECORD_HISTORY_TAG, id: POINT_RECORD_HISTORY_TAG_ID }],
        },

        // stripe
        createStandardAccount: {
            invalidatesTags: (_, __, args) => [{ type: PRIVATE_USER_TAG, id: args.userId }],
        },
        getPaymentMethods: {
            providesTags: (_, __, ___) => [{ type: PAYMENT_METHODS_TAG, id: PAYMENT_METHODS_TAG_ID }],
        },
        detachPaymentMethod: {
            invalidatesTags: (_, __, ___) => [
                { type: DEFAULT_PAYMENT_METHOD_TAG, id: DEFAULT_PAYMENT_METHOD_TAG_ID },
                { type: PAYMENT_METHODS_TAG, id: PAYMENT_METHODS_TAG_ID },
            ],
        },
        getDefaultPaymentMethod: {
            providesTags: (_, __, ___) => [{ type: DEFAULT_PAYMENT_METHOD_TAG, id: DEFAULT_PAYMENT_METHOD_TAG_ID }],
        },
        updateDefaultPaymentMethod: {
            invalidatesTags: (_, __, ___) => [{ type: DEFAULT_PAYMENT_METHOD_TAG, id: DEFAULT_PAYMENT_METHOD_TAG_ID }],
        },
        makeLatestCreditCardDefault: {
            invalidatesTags: (_, __, ___) => [
                { type: DEFAULT_PAYMENT_METHOD_TAG, id: DEFAULT_PAYMENT_METHOD_TAG_ID },
                { type: PAYMENT_METHODS_TAG, id: PAYMENT_METHODS_TAG_ID },
            ],
        },
        getBankAccountList: {
            providesTags: (_, __, ___) => [{ type: BANK_ACCOUNT_LIST_TAG, id: BANK_ACCOUNT_LIST_TAG_ID }],
        },
        createBankAccount: {
            invalidatesTags: (_, error, ___) => {
                if (error) return [];
                return [{ type: BANK_ACCOUNT_LIST_TAG, id: BANK_ACCOUNT_LIST_TAG_ID }];
            },
        },
        deleteBankAccount: {
            invalidatesTags: (_, error, ___) => {
                if (error) return [];
                return [{ type: BANK_ACCOUNT_LIST_TAG, id: BANK_ACCOUNT_LIST_TAG_ID }];
            },
        },
        updateDefaultBankAccount: {
            invalidatesTags: (_, error, ___) => {
                if (error) return [];
                return [{ type: BANK_ACCOUNT_LIST_TAG, id: BANK_ACCOUNT_LIST_TAG_ID }];
            },
        },

        // subjects
        getSubjects: {
            providesTags: (_, __, ___) => [{ type: SUBJECTS_TAG, id: SUBJECTS_TAG_ID }],
        },

        // zoom
        linkZoomAccount: {
            invalidatesTags: (_, __, args) => [
                { type: PRIVATE_TEACHER_TAG, id: args.linkZoomAccountRequestBody.teacherId },
            ],
        },

        // notificationsSetting
        updateNotificationsSetting: {
            invalidatesTags: (_, error, ___) => {
                if (error) return [];
                return [
                    { type: PUSH_NOTIFICATIONS_SETTING_TAG, id: PUSH_NOTIFICATIONS_SETTING_TAG_ID },
                    { type: EMAIL_NOTIFICATIONS_SETTING_TAG, id: EMAIL_NOTIFICATIONS_SETTING_TAG_ID },
                ];
            },
        },

        // privateRequest
        disapprovePrivateRequest: {
            invalidatesTags: (_, error, ___) => {
                if (error) return [];
                return [
                    { type: TEACHER_TO_DOS_TAG, id: TEACHER_TO_DOS_TAG_ID },
                    { type: ANNOUNCEMENTS_TAG, id: ANNOUNCEMENTS_TAG_ID },
                ];
            },
        },
        approvePrivateRequest: {
            invalidatesTags: (_, error, ___) => {
                if (error) return [];
                return [
                    { type: TEACHER_TO_DOS_TAG, id: TEACHER_TO_DOS_TAG_ID },
                    {
                        type: TEACHER_LESSONS_TAG,
                        id: TEACHER_LESSONS_TAG_ID,
                    },
                    { type: COURSE_FOR_COURSE_MANAGEMENT_TAG, id: COURSE_FOR_COURSE_MANAGEMENT_TAG_ID },
                ];
            },
        },
        createPrivateRequest: {
            invalidatesTags: (_, error, ___) => {
                if (error) return [];
                return [{ type: COURSE_DETAILS_TAG, id: COURSE_DETAILS_TAG_ID }];
            },
        },

        // invalidateTag
        invalidateTags: {
            invalidatesTags: (_, __, args) => {
                switch (args.targetAccountType) {
                    case "student":
                        return [
                            { type: STUDENT_TO_DOS_TAG, id: STUDENT_TO_DOS_TAG_ID },
                            { type: ANNOUNCEMENTS_TAG, id: ANNOUNCEMENTS_TAG_ID },
                            { type: COURSE_DETAILS_TAG, id: COURSE_DETAILS_TAG_ID },
                            { type: PUBLIC_REQUEST_DETAILS_TAG, id: PUBLIC_REQUEST_DETAILS_TAG_ID },
                            { type: RESERVED_CLASSES_TAG, id: RESERVED_CLASSES_TAG_ID },
                        ];
                    case "teacher":
                        return [
                            { type: TEACHER_TO_DOS_TAG, id: TEACHER_TO_DOS_TAG_ID },
                            { type: ANNOUNCEMENTS_TAG, id: ANNOUNCEMENTS_TAG_ID },
                            { type: COURSE_DETAILS_TAG, id: COURSE_DETAILS_TAG_ID },
                            { type: PUBLIC_REQUEST_DETAILS_TAG, id: PUBLIC_REQUEST_DETAILS_TAG_ID },
                        ];
                    default:
                        return [];
                }
            },
        },

        // messages
        createStudentMessage: {
            invalidatesTags: (_, __, args) => {
                if (args.createStudentMessageRequestBody.chatType === "individual") {
                    return [
                        { type: INDIVIDUAL_CHAT_TAG, id: INDIVIDUAL_CHAT_TAG_ID },
                        { type: CHATS_TAG, id: CHATS_TAG_ID },
                    ];
                } else {
                    return [
                        { type: GROUP_CHAT_TAG, id: args.createStudentMessageRequestBody.chatId },
                        { type: CHATS_TAG, id: CHATS_TAG_ID },
                    ];
                }
            },
        },
        createTeacherMessage: {
            invalidatesTags: (_, __, args) => {
                if (args.createTeacherMessageRequestBody.chatType === "individual") {
                    return [
                        { type: INDIVIDUAL_CHAT_TAG, id: INDIVIDUAL_CHAT_TAG_ID },
                        { type: CHATS_TAG, id: CHATS_TAG_ID },
                    ];
                } else {
                    return [
                        { type: GROUP_CHAT_TAG, id: args.createTeacherMessageRequestBody.chatId },
                        { type: CHATS_TAG, id: CHATS_TAG_ID },
                    ];
                }
            },
        },

        // chats
        getIndividualChat: {
            providesTags: (_, __, ___) => [{ type: INDIVIDUAL_CHAT_TAG, id: INDIVIDUAL_CHAT_TAG_ID }],
        },
        getGroupChat: {
            providesTags: (_, __, args) => [{ type: GROUP_CHAT_TAG, id: args.chatId }],
        },
        getStudentChats: {
            providesTags: (_, __, ___) => [{ type: CHATS_TAG, id: CHATS_TAG_ID }],
        },
        getTeacherChats: {
            providesTags: (_, __, ___) => [{ type: CHATS_TAG, id: CHATS_TAG_ID }],
        },

        // readStatuses
        updateReadStatus: {
            invalidatesTags: (_, __, ___) => [
                { type: INDIVIDUAL_CHAT_TAG, id: INDIVIDUAL_CHAT_TAG_ID },
                { type: GROUP_CHAT_TAG },
                { type: CHATS_TAG, id: CHATS_TAG_ID },
            ],
        },

        // baseSchedules
        updateBaseSchedules: {
            invalidatesTags: (_, error, ___) => {
                if (error) return [];
                return [
                    { type: COURSE_FOR_COURSE_MANAGEMENT_TAG, id: COURSE_FOR_COURSE_MANAGEMENT_TAG_ID },
                    { type: TEACHER_LESSONS_TAG, id: TEACHER_LESSONS_TAG_ID },
                ];
            },
        },

        // proceedsRecords
        payout: {
            invalidatesTags: (_, error, ___) => {
                if (error) return [];
                return [{ type: PROCEEDS_RECORDS_TAG, id: PROCEEDS_RECORDS_TAG_ID }];
            },
        },
    },
});
