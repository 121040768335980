import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { ClassResponse, LessonResponse, PrivateUserResponse } from "@/store/autogenApi";
import { useGetCourseForCourseDetailsQuery } from "@/store/hooks/courses";
import { CourseDetailsContents } from "../CourseDetailsContents";

interface Props {
    user: PrivateUserResponse | undefined;
    reservedClasses?: ClassResponse[];
}

export const CommonComponent: React.VFC<Props> = (props) => {
    const [otherCourseLessons, setOtherCourseLessons] = useState<LessonResponse[]>([]);
    const { id } = useParams<{ id: string }>();
    const courseQueryState = useGetCourseForCourseDetailsQuery(id);

    useEffect(() => {
        const fetchedAllLessons = props.reservedClasses
            ? props.reservedClasses.map((classResponse) => classResponse.lessons).flat()
            : [];
        setOtherCourseLessons(fetchedAllLessons);
    }, []);

    return (
        <QueryLoadingWrapper {...courseQueryState}>
            {(course) => (
                <CourseDetailsContents course={course} user={props.user} otherCourseLessons={otherCourseLessons} />
            )}
        </QueryLoadingWrapper>
    );
};
