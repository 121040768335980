import { ListItem, Select } from "@material-ui/core";
import { memo } from "react";
import { OrderBy } from "@/store/autogenApi";

import styles from "./index.module.scss";

interface Props {
    value: string;
    orderSets: OrderSet[];
    handleChange: (e: React.ChangeEvent<{ value: string }>) => void;
}

interface OrderSet {
    value: OrderBy;
    label: string;
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 200,
            width: "fit-content",
            overFlow: "scroll",
            color: "#333",
            "&:hover": {
                background: "#f00",
            },
        },
    },
    anchorOrigin: {
        vertical: "bottom" as number | "bottom" | "top" | "center",
        horizontal: "left" as number | "center" | "left" | "right",
    },
    getContentAnchorEl: null,
};

export const Sort: React.VFC<Props> = memo(function Sort(props) {
    const onMouseEnter = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.currentTarget.style.opacity = "0.7";
    };

    const onMouseLeave = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.currentTarget.style.opacity = "1";
    };
    return (
        <Select
            value={props.value}
            onChange={props.handleChange}
            className={styles.select}
            MenuProps={MenuProps}
            disableUnderline
        >
            {props.orderSets.map((orderSet) => (
                <ListItem
                    value={orderSet.value}
                    key={orderSet.value}
                    className={styles.listItem}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    {orderSet.label}
                </ListItem>
            ))}
        </Select>
    );
});
