import { Button, Modal } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { memo, useCallback, useEffect, useState } from "react";
import { TeacherToDoResponse } from "@/store/autogenApi";
import styles from "./index.module.scss";
import { ApplicationMessage } from "./ApplicationMessage";
import { ApproveModeContents } from "./ApproveModeContents";
import { TargetCourse } from "./TargetCourse";
import { DisapprovalModeContents } from "./DisapproveModeContents";
import { FirstDateDescription } from "./FirstDateDescription";
import { StudentInfo } from "./StudentInfo";
import { BaseSchedules } from "./BaseSchedules";
import { PricePerHour } from "./ApproveModeContents/PricePerHour";
import { TotalTimeInAMonth } from "./ApproveModeContents/TotalTimeInAMonth";
import { Estimate } from "./ApproveModeContents/Estimate";

interface Props {
    open: boolean;
    targetTeacherToDo: TeacherToDoResponse;
    handleApprovePrivateRequestModalClose: () => void;
}

export const ApprovePrivateRequestModal: React.VFC<Props> = memo(function ApprovePrivateRequestModal(props) {
    const [approvalMode, setApprovalMode] = useState<boolean>(false);
    const [disapprovalMode, setDisapprovalMode] = useState<boolean>(false);
    const [maxModalHeight, setMaxModalHeight] = useState<number>(0);
    const [modalContentsEl, setModalContentsEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        changeModalHeight();
        window.addEventListener("resize", () => {
            changeModalHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalHeight();
            });
        };
    }, []);

    const changeModalHeight = useCallback(() => {
        const innerHeight = window.innerHeight;
        setMaxModalHeight(innerHeight * 0.75);
    }, []);

    const modalContentsRef = useCallback((el: HTMLElement | null) => {
        setModalContentsEl(el);
    }, []);

    const scrollToTop = useCallback(() => {
        modalContentsEl?.scrollTo({ top: 0 });
    }, [modalContentsEl]);

    const handleApproveButtonClick = useCallback(() => {
        setApprovalMode(true);
        scrollToTop();
    }, [scrollToTop]);

    const handleBackButtonClick = useCallback(() => {
        setApprovalMode(false);
        setDisapprovalMode(false);
        scrollToTop();
    }, [scrollToTop]);

    const handleDisapproveButtonClick = useCallback(() => {
        setDisapprovalMode(true);
        scrollToTop();
    }, [scrollToTop]);

    const handleClose = useCallback(() => {
        props.handleApprovePrivateRequestModalClose();
        setApprovalMode(false);
        setDisapprovalMode(false);
    }, []);

    return (
        <Modal open={props.open} onClose={handleClose} className={styles.approvePrivateRequestModal}>
            <div className={styles.approvePrivateRequestModalContents} ref={modalContentsRef}>
                <div className={styles.modalHeader}>
                    <button className={styles.laterButton} onClick={handleClose}>
                        <CloseIcon />
                    </button>
                    <div className={styles.modalTitle}>
                        {approvalMode
                            ? "スケジュール申請の承認"
                            : disapprovalMode
                            ? "スケジュール申請の非承認"
                            : "スケジュール申請の詳細"}
                    </div>
                </div>
                {approvalMode && (
                    <ApproveModeContents
                        targetTeacherToDo={props.targetTeacherToDo}
                        maxHeight={maxModalHeight}
                        handleBackButtonClick={handleBackButtonClick}
                        handleClose={handleClose}
                    />
                )}
                {disapprovalMode && (
                    <DisapprovalModeContents
                        targetTeacherToDo={props.targetTeacherToDo}
                        maxHeight={maxModalHeight}
                        handleBackButtonClick={handleBackButtonClick}
                        handleClose={handleClose}
                    />
                )}
                {!approvalMode && !disapprovalMode && (
                    <>
                        <div className={styles.middleWrapper} style={{ maxHeight: maxModalHeight }}>
                            <div className={styles.targetCourseWrapper}>
                                <div className={styles.report}>
                                    定期講座に対してスケジュール申請が届きました。この申請を受け入れる場合は「必要項目を入力して承認」、そうでない場合は「理由を入力して非承認」をクリックしてください。
                                </div>
                                <ul className={styles.itemList}>
                                    <TargetCourse course={props.targetTeacherToDo.class?.course} />
                                    <StudentInfo student={props.targetTeacherToDo.student} />
                                    <BaseSchedules
                                        baseSchedules={
                                            props.targetTeacherToDo.class?.privateRequest?.baseSchedules ?? []
                                        }
                                    />
                                    <PricePerHour pricePerHour={props.targetTeacherToDo?.class?.pricePerHour ?? 0} />
                                    <TotalTimeInAMonth
                                        baseSchedules={
                                            props.targetTeacherToDo?.class?.privateRequest?.baseSchedules ?? []
                                        }
                                    />
                                    <Estimate class={props.targetTeacherToDo?.class} />
                                    <FirstDateDescription
                                        privateRequest={props.targetTeacherToDo.class?.privateRequest}
                                    />
                                    <ApplicationMessage
                                        privateRequest={props.targetTeacherToDo.class?.privateRequest}
                                    />
                                </ul>
                            </div>
                        </div>
                        <div className={styles.modalFooter}>
                            <Button className={styles.disapproveButton} onClick={handleDisapproveButtonClick}>
                                理由を入力して非承認
                            </Button>
                            <Button className={styles.approveButton} onClick={handleApproveButtonClick}>
                                必要項目を入力して承認
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
});
