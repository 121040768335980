import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";
import { RootState } from "@/ducks";
import { TermsOfServiceContents } from "@/components/TermsOfServiceContents";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { useEffect } from "react";
import { Navigation } from "@/components/Navigation";

const NAVIGATION_LIST: Navigation[] = [{ title: "利用規約", url: "/TermsOfService" }];

export const TermsOfService: React.VFC = () => {
    const userMode = useSelector((state: RootState) => state.jwt.userMode);

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    return (
        <>
            <div className={styles.termsOfServiceWrapper}>
                <h2 className={styles.title}>利用規約</h2>
                <TermsOfServiceContents />
                <Button component={Link} to={userMode === "teacher" ? "/" : "/"} className={styles.toHomeButton}>
                    ホームに戻る
                </Button>
            </div>
        </>
    );
};
