import { Button } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import styles from "@/pages/Teacher/Identification/index.module.scss";
import { memo } from "react";

interface Props {
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleDeleteButtonClick: () => void;
    temporaryUrl: string | undefined;
    isChecked: boolean;
    mandatory: boolean;
}

export const PictureUpload: React.VFC<Props> = memo(function PictureUpload(props) {
    return (
        <div className={styles.pictureUpload}>
            {!props.temporaryUrl && (
                <Button className={styles.selectButton}>
                    <label className={styles.photoLabel}>
                        ファイルを選択
                        <input
                            type="file"
                            accept="image/*"
                            onChange={props.handleChange}
                            className={styles.photoInput}
                        ></input>
                    </label>
                </Button>
            )}
            {props.temporaryUrl && (
                <div className={styles.imageWrapper}>
                    <img src={props.temporaryUrl} className={styles.image}></img>
                    <button className={styles.deleteButton} type="button" onClick={props.handleDeleteButtonClick}>
                        ×
                    </button>
                </div>
            )}
            {props.mandatory && (props.temporaryUrl == undefined || props.temporaryUrl == "") && props.isChecked && (
                <FormHelperText className={styles.helperText}>画像をアップロードしてください。</FormHelperText>
            )}
        </div>
    );
});
