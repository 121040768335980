import { Button, CircularProgress, TextField } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { memo, useCallback, useState } from "react";
import { useHistory } from "react-router";
import { ErrorMessage } from "@/components/ErrorMessage";

import styles from "@/pages/Common/SignIn/index.module.scss";

interface Props {
    handleSignInAgainButtonClick: () => void;
}

export const ForgotPassword: React.VFC<Props> = memo(function ForgetPassword(props) {
    const [email, setEmail] = useState<string>("");
    const [verificationCode, setVerificationCode] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [isProcessingForSending, setIsProcessingForSending] = useState<boolean>(false);
    const [isSentVerificationCode, setIsSentVerificationCode] = useState<boolean>(false);
    const [isProcessingForSubmitting, setIsProcessingForSubmitting] = useState<boolean>(false);
    const [isUpdatedPassword, setIsUpdatedPassword] = useState<boolean>(false);
    const [isResent, setIsResent] = useState<boolean>(false);
    const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [isValidPassword, setIsValidPassword] = useState(false);
    const [isTheSamePassword, setIsTheSamePassword] = useState(true);

    const history = useHistory();

    const regexp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.+-]*@[A-Za-z0-9_.-]+\.[A-Za-z0-9]+$/;
    const onChangeEmail = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setIsValidEmail(regexp.test(event.target.value));
    }, []);

    const ratz = /[a-z]/,
        rAtZ = /[A-Z]/,
        r0t9 = /[0-9]/;
    const onChangeVerificationCode = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setVerificationCode(value);
        setIsValidPassword(ratz.test(value) && rAtZ.test(value) && r0t9.test(value));
    }, []);

    const onChangeNewPassword = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(event.target.value);
    }, []);

    const handleSendVerificationCodeButtonClick = useCallback(async () => {
        setIsChecked(true);
        if (!isValidEmail) return;
        setIsProcessingForSending(true);
        try {
            await Auth.forgotPassword(email);
            setIsProcessingForSending(false);
            setIsSentVerificationCode(true);
        } catch (error) {
            if (error.name === "UsernameExistsException") {
                alert("このメールアドレスは既に登録されています。ログインしてください。");
                history.push("/SignIn");
            }
        }
    }, [email, isValidEmail]);

    const handleVerificationCodeSubmitButtonClick = useCallback(async () => {
        setIsProcessingForSubmitting(true);
        try {
            await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);
            setIsProcessingForSubmitting(false);
            setIsUpdatedPassword(true);
        } catch (error) {
            console.log(error);
        }
    }, [email, verificationCode, newPassword]);

    const handleResendVerificationCodeButtonClick = useCallback(async () => {
        setIsChecked(true);
        if (!isValidEmail) return;
        setIsProcessingForSending(true);
        try {
            await Auth.resendSignUp(email);
            setIsProcessingForSending(false);
            setIsSentVerificationCode(true);
            setIsResent(true);
        } catch (error) {
            console.log(error);
        }
    }, [email]);

    return (
        <>
            {isSentVerificationCode ? (
                <div className={styles.sentWrapper}>
                    {isUpdatedPassword ? (
                        <>
                            <div className={styles.updatedPassword}>パスワードが更新されました。</div>
                            <Button className={styles.signInButton} onClick={props.handleSignInAgainButtonClick}>
                                ログイン
                            </Button>
                        </>
                    ) : (
                        <>
                            <div className={styles.message}>
                                以下のメールアドレスに認証コードを{isResent ? "再送信" : "送信"}しました。
                            </div>
                            <div className={styles.email}>{email}</div>
                            <div className={styles.verificationCodeWrapper}>
                                <div className={styles.pleaseInput}>認証コードを入力してください。</div>
                                <TextField
                                    className={styles.verificationCodeInput}
                                    value={verificationCode}
                                    label={"認証コード"}
                                    onChange={onChangeVerificationCode}
                                />
                                <TextField
                                    className={styles.verificationCodeInput}
                                    value={newPassword}
                                    label={"新しいパスワード"}
                                    onChange={onChangeNewPassword}
                                />
                                <ErrorMessage
                                    content="パスワードは小文字、大文字、数字をそれぞれ1文字以上含む8文字以上で入力してください。"
                                    when={isChecked && !isValidPassword}
                                />
                                <ErrorMessage
                                    content="パスワードが一致しません。"
                                    when={isChecked && !isTheSamePassword}
                                />
                                <Button
                                    onClick={handleVerificationCodeSubmitButtonClick}
                                    className={styles.verificationCodeSubmitButton}
                                >
                                    {isProcessingForSubmitting ? (
                                        <CircularProgress className={styles.progress} />
                                    ) : (
                                        <div className={styles.confirm}>確定</div>
                                    )}
                                </Button>
                                <button
                                    onClick={handleResendVerificationCodeButtonClick}
                                    className={styles.resendButton}
                                >
                                    {isProcessingForSending ? (
                                        <CircularProgress className={styles.progress} />
                                    ) : (
                                        <div className={styles.resend}>認証コードを再送信</div>
                                    )}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <div className={styles.forgotPasswordWrapper}>
                    <TextField
                        value={email}
                        variant="outlined"
                        label={"メールアドレス"}
                        onChange={onChangeEmail}
                        className={styles.emailInput}
                    />
                    <ErrorMessage content="メールアドレスが正しくありません" when={isChecked && !isValidEmail} />
                    <Button onClick={handleSendVerificationCodeButtonClick} className={styles.submitButton}>
                        {isProcessingForSending ? (
                            <CircularProgress className={styles.progress} />
                        ) : (
                            <div className={styles.submit}>認証コードを送信</div>
                        )}
                    </Button>
                </div>
            )}
        </>
    );
});
