import { Modal, useMediaQuery } from "@material-ui/core";
import { memo, useCallback } from "react";
import styles from "./index.module.scss";
import { CourseResponse } from "@/store/autogenApi";
import { getLessonTime } from "@/utils/LessonUtils";
import { WhiteButton } from "@/components/Buttons/WhiteButton";
import { NavyButton } from "@/components/Buttons/NavyButton";
import { useHistory } from "react-router";
import { AiFillStar } from "react-icons/ai";
import { getSubject } from "@/utils/SubjectUtils";
import { getCourseType } from "@/utils/CourseUtils";
import defaultCourseImage from "@/images/defaultCourseImage.jpg";
import { ImageFromS3 } from "@/components/atoms/images/ImageFromS3";

interface Props {
    targetCourse: CourseResponse;
    handleCourseDetailsModalClose: () => void;
}

export const CourseDetailsModal: React.VFC<Props> = memo(function CourseDetailsModal(props: Props) {
    const history = useHistory();

    const mdUp = useMediaQuery("(min-width:960px)");

    const handleCourseDetailsButtonClick = useCallback(() => {
        props.handleCourseDetailsModalClose();
        history.push(`/CourseDetails/${props.targetCourse.courseId}`);
    }, [props.targetCourse]);

    const handleCourseManagementButtonClick = useCallback(() => {
        props.handleCourseDetailsModalClose();
        history.push(`/CourseManagement/${props.targetCourse.courseId}`);
    }, [props.targetCourse]);

    const getCourseScore = useCallback(() => {
        if (props.targetCourse?.reviews) {
            if (props.targetCourse?.reviews?.length === 0) {
                return 0;
            } else if (props.targetCourse?.reviews?.length === 1) {
                return props.targetCourse?.reviews[0].score;
            } else {
                const sum = props.targetCourse?.reviews.reduce((acc, cur) => acc + (cur.score as number), 0);
                return sum / props.targetCourse?.reviews.length;
            }
        } else {
            return 0;
        }
    }, [props.targetCourse]);

    return (
        <Modal open={true} onClose={props.handleCourseDetailsModalClose}>
            <div className={styles.courseDetailsModalContents}>
                <div className={styles.topFlex}>
                    <ImageFromS3
                        url={props.targetCourse.thumbnailUrl || defaultCourseImage}
                        objectKey={props.targetCourse.thumbnailObjectKey}
                        className={styles.thumbnail}
                    />
                    <div className={styles.flexRightWrapper}>
                        <div className={styles.topCourseContents}>
                            <div className={styles.courseType}>{getCourseType(props.targetCourse)}</div>
                            <div className={styles.ratingWrapper}>
                                <AiFillStar className={styles.star} />
                                <div className={styles.averageReviewScore}>{getCourseScore()}</div>
                                <div className={styles.totalAchievements}>({props.targetCourse?.reviews?.length})</div>
                            </div>
                        </div>
                        <div className={styles.courseDetailsTitle}>{props.targetCourse.title}</div>
                        <div className={styles.subjects}>
                            {props.targetCourse.subjects.map((subject) => (
                                <div className={styles.subject}>{getSubject(subject)}</div>
                            ))}
                        </div>
                        <div className={styles.classesInfo}>
                            {props.targetCourse.classes.length == 0
                                ? "日程なし"
                                : `日程：${props.targetCourse.classes.length}件あり`}
                        </div>
                        {props.targetCourse.courseDescription && (
                            <div className={styles.courseDescription}>{props.targetCourse.courseDescription}</div>
                        )}
                    </div>
                </div>
                <div className={styles.buttonsWrapper}>
                    <WhiteButton handleClick={handleCourseDetailsButtonClick} fontSize={mdUp ? 15 : 14}>
                        詳細
                    </WhiteButton>
                    <NavyButton handleClick={handleCourseManagementButtonClick} fontSize={mdUp ? 15 : 14}>
                        管理
                    </NavyButton>
                </div>
            </div>
        </Modal>
    );
});
