import { memo, useCallback, useEffect } from "react";
import { useState } from "react";

import queryString from "query-string";
import { useHistory } from "react-router";

import {
    HIGH_SCHOOL_SUBJECT_CONFIG,
    JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG,
    ELEMENTARY_SCHOOL_SUBJECT_CONFIG,
    SubjectConfig,
    UNIVERSITY_SUBJECT_CONFIG,
} from "@/SubjectConfig";
import { SearchResultComponent } from "@/pageComponents/Common/CourseSearchResults";
import { useGetCourseSearchResultsQuery } from "@/store/hooks/courses";
import { QueryLoadingWrapperOnlyPart } from "@/components/QueryLoadingWrapper/QueryLoadingWrapperPart";
import { CourseType, OrderBy } from "@/store/autogenApi";
import { RootState } from "@/ducks";
import { useSelector } from "react-redux";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { Navigation } from "@/components/Navigation";

export interface Props {
    query: queryString.ParsedQuery<string>;
}

export interface SubjectQueries {
    level: string;
    category: string;
    subCategory: string;
}

export interface EducationalBackGroundQueries {
    university: string;
    faculty: string;
    department: string;
}

const NAVIGATION_LIST: Navigation[] = [{ title: "講座検索", url: "/SearchResults?searchType=course" }];

export const CourseSearchResults: React.VFC<Props> = memo(function CourseSearchResults(props) {
    const [keyword, setKeyword] = useState<string>("");
    const [courseType, setCourseType] = useState<string>("");
    const [targetSubject, setTargetSubject] = useState<SubjectQueries>({
        level: "",
        category: "",
        subCategory: "",
    });
    const [rankNumber, setRank] = useState<number>(0);
    const [targetEducationalBackground, setTargetEducationalBackground] = useState<EducationalBackGroundQueries>({
        university: "",
        faculty: "",
        department: "",
    });
    const [currentEducationalBackground, setCurrentEducationalBackground] = useState<EducationalBackGroundQueries>({
        university: "",
        faculty: "",
        department: "",
    });
    const [orderBy, setOrderBy] = useState<string>("latest");
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [existCondition, setExistCondition] = useState<boolean>(false);

    const history = useHistory();

    const studentId = useSelector((state: RootState) => state.jwt.studentId);

    const courseSearchResultQueryState = useGetCourseSearchResultsQuery({
        keyword: typeof props.query.keyword === "string" ? props.query.keyword : undefined,
        courseType:
            typeof props.query.courseType === "string" && props.query.courseType !== ""
                ? (props.query.courseType as CourseType)
                : undefined,
        level: typeof props.query.level === "string" ? props.query.level : undefined,
        category: typeof props.query.category === "string" ? props.query.category : undefined,
        subCategory: typeof props.query.subCategory === "string" ? props.query.subCategory : undefined,
        rankNumber: typeof props.query.rankNumber === "string" ? props.query.rankNumber : undefined,
        university: typeof props.query.university === "string" ? props.query.university : undefined,
        faculty: typeof props.query.faculty === "string" ? props.query.faculty : undefined,
        department: typeof props.query.department === "string" ? props.query.department : undefined,
        orderBy: props.query.orderBy as OrderBy,
        studentId: studentId ?? undefined,
    });

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    useEffect(() => {
        setTargetEducationalBackground(currentEducationalBackground);
        if (currentEducationalBackground.university) {
            setIsConfirmed(true);
        } else {
            setIsConfirmed(false);
        }
    }, [currentEducationalBackground]);

    useEffect(() => {
        (async () => {
            setKeyword(props.query.keyword as string);
            setTargetSubject({
                level: props.query.level as string,
                category: props.query.category as string,
                subCategory: props.query.subCategory as string,
            });
            setRank(Number(props.query.rankNumber));
            setCurrentEducationalBackground({
                university: props.query.university as string,
                faculty: props.query.faculty as string,
                department: props.query.department as string,
            });
            const orderBy =
                !props.query.orderBy || props.query.orderBy === "" ? "latest" : (props.query.orderBy as string);
            setOrderBy(orderBy);
            setCourseType(props.query.courseType as string);
            if (props.query.pageNumber) {
                setPageNumber(Number(props.query.pageNumber));
            }
            if (
                props.query.keyword ||
                props.query.courseType ||
                props.query.level ||
                props.query.category ||
                props.query.subCategory ||
                props.query.rankNumber ||
                props.query.university ||
                props.query.faculty ||
                props.query.department
            ) {
                setExistCondition(true);
            } else {
                setExistCondition(false);
            }
        })();
    }, [history.location.search]);

    const getCourseTypeCondition = useCallback(() => {
        if (props.query.courseType === "regular") {
            return "定期";
        } else if (props.query.courseType === "short") {
            return "単発・短期";
        } else {
            return "";
        }
    }, [props.query.courseType]);

    const getSubjectCondition = useCallback(() => {
        let targetSubjectConfig: SubjectConfig;
        let level: string;
        if (props.query.level === "university") {
            targetSubjectConfig = UNIVERSITY_SUBJECT_CONFIG;
            level = "大学生・社会人";
        } else if (props.query.level === "highSchool") {
            targetSubjectConfig = HIGH_SCHOOL_SUBJECT_CONFIG;
            level = "高校生";
        } else if (props.query.level === "juniorHighSchool") {
            targetSubjectConfig = JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG;
            level = "中学生";
        } else if (props.query.level === "elementarySchool") {
            targetSubjectConfig = ELEMENTARY_SCHOOL_SUBJECT_CONFIG;
            level = "小学生";
        } else {
            return "";
        }
        if (!props.query.category || typeof props.query.category !== "string") return level;
        const category = targetSubjectConfig[props.query.category].label;
        const subCategories = targetSubjectConfig[props.query.category].sub;
        if (subCategories && props.query.subCategory && typeof props.query.subCategory === "string") {
            const subCategory = subCategories[props.query.subCategory].label;
            return `${level}-${category}-${subCategory}`;
        } else {
            return `${level}-${category}`;
        }
    }, [props.query.level, props.query.category, props.query.subCategory]);

    const getBelongingCondition = useCallback(() => {
        const university = currentEducationalBackground.university;
        const faculty = currentEducationalBackground.faculty;
        const department = currentEducationalBackground.department;
        let condition: string;
        if (faculty) {
            if (department) {
                return `${university} - ${faculty} - ${department}`;
            } else {
                return `${university} - ${faculty}`;
            }
        } else {
            return `${university}`;
        }
        return "";
    }, [
        currentEducationalBackground.university,
        currentEducationalBackground.faculty,
        currentEducationalBackground.department,
    ]);

    const getRankCondition = useCallback((rankNumber: number) => {
        if (rankNumber === 1) {
            return "レギュラー以上";
        } else if (rankNumber === 2) {
            return "ブロンズ以上";
        } else if (rankNumber === 3) {
            return "シルバー以上";
        } else if (rankNumber === 4) {
            return "ゴールド以上";
        } else if (rankNumber === 5) {
            return "プラチナ";
        } else {
            return "-";
        }
    }, []);

    const search = useCallback(() => {
        const keywordQueryParams = keyword ? `&keyword=${keyword}` : "";
        const courseTypeQueryParams = courseType ? `&courseType=${courseType}` : "";
        const levelQueryParams = targetSubject.level ? `&level=${targetSubject.level}` : "";
        const categoryQueryParams = targetSubject.category ? `&category=${targetSubject.category}` : "";
        const subCategoryQueryParams = targetSubject.subCategory ? `&subCategory=${targetSubject.subCategory}` : "";
        const rankNumberQueryParams = rankNumber ? `&rankNumber=${rankNumber}` : "";
        const universityQueryParams = targetEducationalBackground.university
            ? `&university=${targetEducationalBackground.university}`
            : "";
        const facultyQueryParams = targetEducationalBackground.faculty
            ? `&faculty=${targetEducationalBackground.faculty}`
            : "";
        const departmentQueryParams = targetEducationalBackground.department
            ? `&department=${targetEducationalBackground.department}`
            : "";
        const orderByQueryParams = orderBy ? `&orderBy=${orderBy}` : "";
        const queryParams = [
            keywordQueryParams,
            courseTypeQueryParams,
            levelQueryParams,
            categoryQueryParams,
            subCategoryQueryParams,
            rankNumberQueryParams,
            universityQueryParams,
            facultyQueryParams,
            departmentQueryParams,
            orderByQueryParams,
        ].join("");
        history.push(`/SearchResults?searchType=course${queryParams}`);
    }, [keyword, courseType, targetSubject, rankNumber, targetEducationalBackground]);

    const deleteKeywordCondition = useCallback(() => {
        const courseTypeQueryParams = props.query.courseType ? `&courseType=${props.query.courseType}` : "";
        const levelQueryParams = props.query.level ? `&level=${props.query.level}` : "";
        const categoryQueryParams = props.query.category ? `&category=${props.query.category}` : "";
        const subCategoryQueryParams = props.query.subCategory ? `&subCategory=${props.query.subCategory}` : "";
        const rankNumberQueryParams = props.query.rankNumber ? `&rankNumber=${props.query.rankNumber}` : "";
        const universityQueryParams = props.query.university ? `&university=${props.query.university}` : "";
        const facultyQueryParams = props.query.faculty ? `&faculty=${props.query.faculty}` : "";
        const departmentQueryParams = props.query.department ? `&department=${props.query.department}` : "";
        const orderByQueryParams = props.query.orderBy ? `&orderBy=${props.query.orderBy}` : "";
        const queryParams = [
            courseTypeQueryParams,
            levelQueryParams,
            categoryQueryParams,
            subCategoryQueryParams,
            rankNumberQueryParams,
            universityQueryParams,
            facultyQueryParams,
            departmentQueryParams,
            orderByQueryParams,
        ].join("");
        history.push(`/SearchResults?searchType=course${queryParams}`);
    }, [props.query]);

    const deleteCourseTypeCondition = useCallback(() => {
        const keywordQueryParams = props.query.keyword ? `&keyword=${props.query.keyword}` : "";
        const levelQueryParams = props.query.level ? `&level=${props.query.level}` : "";
        const categoryQueryParams = props.query.category ? `&category=${props.query.category}` : "";
        const subCategoryQueryParams = props.query.subCategory ? `&subCategory=${props.query.subCategory}` : "";
        const rankNumberQueryParams = !isNaN(Number(props.query.rankNumber))
            ? `&rankNumber=${Number(props.query.rankNumber)}`
            : "";
        const universityQueryParams = props.query.university ? `&university=${props.query.university}` : "";
        const facultyQueryParams = props.query.faculty ? `&faculty=${props.query.faculty}` : "";
        const departmentQueryParams = props.query.department ? `&department=${props.query.department}` : "";
        const orderByQueryParams = props.query.orderBy ? `&orderBy=${props.query.orderBy}` : "";
        const queryParams = [
            keywordQueryParams,
            levelQueryParams,
            categoryQueryParams,
            subCategoryQueryParams,
            rankNumberQueryParams,
            universityQueryParams,
            facultyQueryParams,
            departmentQueryParams,
            orderByQueryParams,
        ].join("");
        history.push(`/SearchResults?searchType=course${queryParams}`);
    }, [props.query]);

    const deleteSubjectCondition = useCallback(() => {
        const keywordQueryParams = props.query.keyword ? `&keyword=${props.query.keyword}` : "";
        const courseTypeQueryParams = props.query.courseType ? `&courseType=${props.query.courseType}` : "";
        const rankNumberQueryParams = !isNaN(Number(props.query.rankNumber))
            ? `&rankNumber=${Number(props.query.rankNumber)}`
            : "";
        const universityQueryParams = props.query.university ? `&university=${props.query.university}` : "";
        const facultyQueryParams = props.query.faculty ? `&faculty=${props.query.faculty}` : "";
        const departmentQueryParams = props.query.department ? `&department=${props.query.department}` : "";
        const orderByQueryParams = props.query.orderBy ? `&orderBy=${props.query.orderBy}` : "";
        const queryParams = [
            keywordQueryParams,
            courseTypeQueryParams,
            rankNumberQueryParams,
            universityQueryParams,
            facultyQueryParams,
            departmentQueryParams,
            orderByQueryParams,
        ].join("");
        history.push(`/SearchResults?searchType=course${queryParams}`);
    }, [props.query]);

    const deleteRankCondition = useCallback(() => {
        const keywordQueryParams = props.query.keyword ? `&keyword=${props.query.keyword}` : "";
        const courseTypeQueryParams = props.query.courseType ? `&courseType=${props.query.courseType}` : "";
        const levelQueryParams = props.query.level ? `&level=${props.query.level}` : "";
        const categoryQueryParams = props.query.category ? `&category=${props.query.category}` : "";
        const subCategoryQueryParams = props.query.subCategory ? `&subCategory=${props.query.subCategory}` : "";
        const universityQueryParams = props.query.university ? `&university=${props.query.university}` : "";
        const facultyQueryParams = props.query.faculty ? `&faculty=${props.query.faculty}` : "";
        const departmentQueryParams = props.query.department ? `&department=${props.query.department}` : "";
        const orderByQueryParams = props.query.orderBy ? `&orderBy=${props.query.orderBy}` : "";
        const queryParams = [
            keywordQueryParams,
            courseTypeQueryParams,
            levelQueryParams,
            categoryQueryParams,
            subCategoryQueryParams,
            universityQueryParams,
            facultyQueryParams,
            departmentQueryParams,
            orderByQueryParams,
        ].join("");
        history.push(`/SearchResults?searchType=course${queryParams}`);
    }, [props.query]);

    const deleteBelongingCondition = useCallback(() => {
        const keywordQueryParams = props.query.keyword ? `&keyword=${props.query.keyword}` : "";
        const courseTypeQueryParams = props.query.courseType ? `&courseType=${props.query.courseType}` : "";
        const levelQueryParams = props.query.level ? `&level=${props.query.level}` : "";
        const categoryQueryParams = props.query.category ? `&category=${props.query.category}` : "";
        const subCategoryQueryParams = props.query.subCategory ? `&subCategory=${props.query.subCategory}` : "";
        const rankNumberQueryParams = !isNaN(Number(props.query.rankNumber))
            ? `&rankNumber=${Number(props.query.rankNumber)}`
            : "";
        const orderByQueryParams = props.query.orderBy ? `&orderBy=${props.query.orderBy}` : "";
        const queryParams = [
            keywordQueryParams,
            courseTypeQueryParams,
            levelQueryParams,
            categoryQueryParams,
            subCategoryQueryParams,
            rankNumberQueryParams,
            orderByQueryParams,
        ].join("");
        history.push(`/SearchResults?searchType=course${queryParams}`);
    }, [props.query]);

    const handleClearButtonClick = useCallback(() => {
        history.push(`/SearchResults?searchType=course&orderBy=${props.query.orderBy}`);
    }, [props.query]);

    const handleOrderChange = useCallback(
        (
            e: React.ChangeEvent<{
                value: string;
            }>,
        ) => {
            const newOrder = e.target.value;
            history.push(
                `/SearchResults?searchType=course&keyword=${keyword}&courseType=${courseType}&level=${targetSubject.level}&category=${targetSubject.category}&subCategory=${targetSubject.subCategory}&rankNumber=${rankNumber}&university=${targetEducationalBackground.university}&faculty=${targetEducationalBackground.faculty}&department=${targetEducationalBackground.department}&orderBy=${newOrder}`,
            );
        },
        [],
    );

    return (
        <>
            <QueryLoadingWrapperOnlyPart {...courseSearchResultQueryState}>
                {(courses, isLoaded) => (
                    <SearchResultComponent
                        targetSubject={targetSubject}
                        setTargetSubject={setTargetSubject}
                        rankNumber={rankNumber}
                        query={props.query}
                        targetEducationalBackground={targetEducationalBackground}
                        isConfirmed={isConfirmed}
                        keyword={keyword}
                        pageNumber={pageNumber}
                        courseType={courseType}
                        orderBy={orderBy}
                        existCondition={existCondition}
                        setCourseType={setCourseType}
                        setPageNumber={setPageNumber}
                        setKeyword={setKeyword}
                        setIsConfirmed={setIsConfirmed}
                        setRank={setRank}
                        setTargetEducationalBackground={setTargetEducationalBackground}
                        search={search}
                        getCourseTypeCondition={getCourseTypeCondition}
                        getSubjectCondition={getSubjectCondition}
                        getRankCondition={getRankCondition}
                        getBelongingCondition={getBelongingCondition}
                        deleteKeywordCondition={deleteKeywordCondition}
                        deleteCourseTypeCondition={deleteCourseTypeCondition}
                        deleteSubjectCondition={deleteSubjectCondition}
                        deleteRankCondition={deleteRankCondition}
                        deleteBelongingCondition={deleteBelongingCondition}
                        handleClearButtonClick={handleClearButtonClick}
                        handleOrderChange={handleOrderChange}
                        searchResults={courses ?? []}
                        isLoaded={isLoaded}
                    />
                )}
            </QueryLoadingWrapperOnlyPart>
        </>
    );
});
