import { useState, useEffect, useRef, useCallback, memo } from "react";

import { Button } from "@material-ui/core";
import { RiCloseFill } from "react-icons/ri";
import { useSelector } from "react-redux";

import { ChatResponse, CreateStudentMessageRequestBody, CreateTeacherMessageRequestBody } from "@/store/autogenApi";
import { useUpdateReadStatusMutation } from "@/store/hooks/readStatuses";

import styles from "./index.module.scss";
import { RootState } from "@/ducks";
import { Socket, io } from "socket.io-client";
import { MessageInput } from "../MessageInput";
import { MessageList } from "../MessageList";
import { useUploadFiles } from "@/utils/UploadFiles";
import { UserInfoModal } from "../UserInfoModal";
import { useCreateStudentMessageMutation, useCreateTeacherMessageMutation } from "@/store/hooks/messages";

interface Props {
    handlePreviousChatChange: (chat: ChatResponse) => void;
    handleClose?: () => void;
    refetch: (() => void) | (() => void) | (() => void) | (() => void);
    isPrevious: boolean;
    chat: ChatResponse;
    socket: Socket | undefined;
}

// socketの関係で、open=falseの場合は親で非表示
export const ChatContents: React.VFC<Props> = memo(function ChatContents(props) {
    const [chatMessage, setChatMessage] = useState<string>("");
    const [modalHeight, setModalHeight] = useState<number>(0);
    const [temporaryObjectURLsList, setTemporaryObjectURLsList] = useState<string[][]>([]);
    const [userInfoModalOpen, setUserInfoModalOpen] = useState(false);
    const [isWaitingToRefetch, setIsWaitingToRefetch] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const messageListEl: React.MutableRefObject<HTMLElement | null | undefined> = useRef();

    const userMode = useSelector((state: RootState) => state.jwt.userMode);
    const studentId = useSelector((state: RootState) => state.jwt.studentId);
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId);

    const updateReadStatus = useUpdateReadStatusMutation();
    const createStudentMessage = useCreateStudentMessageMutation();
    const createTeacherMessage = useCreateTeacherMessageMutation();
    const uploadFiles = useUploadFiles();

    const isInManagementPage =
        location.pathname.includes("/CourseManagement/") || location.pathname.includes("/Class/");

    useEffect(() => {
        if (props.isPrevious) return;
        setIsProcessing(false);
    }, [props.chat, props.isPrevious]);

    useEffect(() => {
        props.handlePreviousChatChange(props.chat);
    }, [props.chat, props.handlePreviousChatChange]);

    const refetchChat = useCallback(
        async (isRead: boolean) => {
            if (!props.chat) {
                props.refetch();
                return;
            }
            if (!props.socket) {
                props.refetch();
                return;
            }
            const readStatus = props.chat.readStatuses.find((readStatus) =>
                userMode === "student" ? readStatus.studentId === studentId : readStatus.teacherId === teacherId,
            );
            if (!readStatus) {
                props.refetch();
                return;
            }
            await updateReadStatus({
                readStatusId: readStatus.readStatusId,
            });
            if (isRead) return;
            const roomId = props.chat.chatId;
            props.socket.emit("readMessage", {
                roomId,
            });
        },
        [props.chat, props.socket, props.refetch, userMode, studentId, teacherId, updateReadStatus],
    );

    useEffect(() => {
        if (!isWaitingToRefetch) return;
        if (isProcessing) return;
        (async () => {
            await refetchChat(true);
            setIsWaitingToRefetch(false);
        })();
    }, [isWaitingToRefetch, isProcessing, refetchChat]);

    useEffect(() => {
        (async () => {
            if (!props.socket) return;
            props.socket.on("joined", (payload) => {
                console.log("Joined: " + payload);
            });
            props.socket.on("messageWasCreated", async (payload) => {
                console.log("Created: " + payload);
                await refetchChat(false);
            });
            props.socket.on("messageWasOpened", async (payload) => {
                console.log("opened: " + payload);
                await refetchChat(false);
            });
            props.socket.on("messageWasRead", async (payload) => {
                console.log("read: " + payload);
                await refetchChat(true);
                if (isProcessing) {
                    setIsWaitingToRefetch(true);
                } else {
                    await refetchChat(true);
                }
            });
        })();
        if (!props.socket) return;
        return () => {
            (async () => {
                (props.socket as Socket).off("joined");
                (props.socket as Socket).off("messageWasCreated");
                (props.socket as Socket).off("messageWasOpened");
                (props.socket as Socket).off("messageWasRead");
            })();
        };
    }, [props.socket, refetchChat, props.refetch, isProcessing]);

    useEffect(() => {
        changeModalHeight();
        window.addEventListener("resize", () => {
            changeModalHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalHeight();
            });
        };
    }, []);

    useEffect(() => {
        const messageList = messageListEl.current;
        if (messageList) {
            const scrollHeight = messageList.scrollHeight;
            messageList.scrollTop = scrollHeight;
        }
    }, [messageListEl.current]);

    const handleMessageChange = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        const currentMessage = e.target.value as string;
        setChatMessage(currentMessage);
    }, []);

    const handleSendButtonClick = useCallback(async () => {
        if (!props.socket) return;
        setIsProcessing(true);
        if (userMode === "teacher") {
            const requestBody: CreateTeacherMessageRequestBody = {
                messageType: "text",
                chatId: props.chat.chatId,
                chatType: props.chat.chatType,
                teacherId: teacherId as string,
                text: chatMessage,
            };
            await createTeacherMessage({
                createTeacherMessageRequestBody: requestBody,
            });
        } else {
            const requestBody: CreateStudentMessageRequestBody = {
                messageType: "text",
                chatId: props.chat.chatId,
                chatType: props.chat.chatType,
                studentId: studentId as string,
                text: chatMessage,
            };
            await createStudentMessage({
                createStudentMessageRequestBody: requestBody,
            });
        }
        const roomId = props.chat.chatId;
        props.socket.emit("createdMessage", { roomId: roomId });
        setChatMessage("");
    }, [
        chatMessage,
        userMode,
        createTeacherMessage,
        createStudentMessage,
        props.socket,
        props.chat,
        teacherId,
        studentId,
    ]);

    const changeModalHeight = useCallback(() => {
        const innerHeight = window.innerHeight;
        setModalHeight(innerHeight * 0.75);
    }, []);

    const sliceFilesByNumber = useCallback((array: File[], number: number) => {
        const length = Math.ceil(array.length / number);
        return [...Array(length)].map((_, i) => array.slice(i * number, (i + 1) * number));
    }, []);

    const handleFilesChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            (async () => {
                try {
                    const files = e.target.files;
                    if (files) {
                        if (!props.socket) return;
                        const slicedFilesList = sliceFilesByNumber(Array.from(files), 10);
                        const newTemporaryObjectURLsList = slicedFilesList.map((files) =>
                            files.map((file) => URL.createObjectURL(file)),
                        );
                        setTemporaryObjectURLsList(newTemporaryObjectURLsList);
                        setIsProcessing(true);
                        await Promise.all(
                            slicedFilesList.map(async (files) => {
                                const basePath = `chats/${props.chat.chatId}/${
                                    userMode === "teacher" ? teacherId : studentId
                                }`;
                                const urls = await uploadFiles(files, basePath);
                                if (userMode === "teacher") {
                                    const requestBody: CreateTeacherMessageRequestBody = {
                                        messageType: "file",
                                        chatId: props.chat.chatId,
                                        chatType: props.chat.chatType,
                                        teacherId: teacherId as string,
                                        text: chatMessage,
                                        fileUrls: urls,
                                    };
                                    await createTeacherMessage({
                                        createTeacherMessageRequestBody: requestBody,
                                    });
                                } else {
                                    const requestBody: CreateStudentMessageRequestBody = {
                                        messageType: "file",
                                        chatId: props.chat.chatId,
                                        chatType: props.chat.chatType,
                                        studentId: studentId as string,
                                        text: chatMessage,
                                        fileUrls: urls,
                                    };
                                    await createStudentMessage({
                                        createStudentMessageRequestBody: requestBody,
                                    });
                                }
                                const roomId = props.chat.chatId;
                                (props.socket as Socket).emit("createdMessage", { roomId: roomId });
                            }),
                        );
                        setTemporaryObjectURLsList([]);
                    }
                } catch (e) {
                    console.log(e);
                    setTemporaryObjectURLsList([]);
                }
            })();
        },
        [
            sliceFilesByNumber,
            userMode,
            chatMessage,
            createTeacherMessage,
            createStudentMessage,
            props.socket,
            props.chat,
            teacherId,
            studentId,
        ],
    );

    const handleCreateMeetingButtonClick = useCallback(async () => {
        if (!props.socket) return;
        setIsProcessing(true);
        await createTeacherMessage({
            createTeacherMessageRequestBody: {
                messageType: "personalMeeting",
                chatId: props.chat.chatId,
                chatType: props.chat.chatType,
                teacherId: teacherId as string,
            },
        });
        const roomId = props.chat.chatId;
        props.socket.emit("createdMessage", { roomId: roomId });
    }, [props.socket, chatMessage, createTeacherMessage, props.chat, teacherId]);

    const handleIconButtonClick = useCallback(() => setUserInfoModalOpen(true), []);

    const handleUserInfoModalClose = useCallback(() => setUserInfoModalOpen(false), []);

    return (
        <div
            className={props.handleClose ? `${styles.chatContents} ${styles.chatModalContents}` : styles.chatContents}
            style={{
                height: `${modalHeight}px`,
                border: isInManagementPage ? "1px solid #CCC" : "none",
                margin: isInManagementPage ? "10px auto" : "0",
            }}
        >
            <div className={styles.chatModalHeader}>
                <div className={styles.relative}>
                    {props.chat.chatType === "individual" && (
                        <Button className={styles.closeButton} onClick={props.handleClose}>
                            <RiCloseFill className={styles.closeIcon} />
                        </Button>
                    )}
                    {props.chat.chatType === "individual" && (
                        <div className={styles.chatTitle}>
                            {userMode === "student"
                                ? `${props.chat.teachers[0].nickname}先生`
                                : `${props.chat.students[0].nickname}さん`}
                        </div>
                    )}
                </div>
            </div>
            <MessageList
                chat={props.chat}
                temporaryObjectURLsList={temporaryObjectURLsList}
                handleIconButtonClick={handleIconButtonClick}
            />
            <MessageInput
                message={chatMessage}
                isProcessing={isProcessing}
                handleFilesChange={handleFilesChange}
                handleMessageChange={handleMessageChange}
                handleSendButtonClick={handleSendButtonClick}
                handleCreateMeetingButtonClick={handleCreateMeetingButtonClick}
            />
            {userInfoModalOpen && (
                <UserInfoModal
                    openedTarget={userMode === "student" ? props.chat.teachers[0] : props.chat.students[0]}
                    zIndex={10000}
                    handleClose={handleUserInfoModalClose}
                />
            )}
        </div>
    );
});
