import { useCallback, useEffect, useState } from "react";

import { Button, Checkbox, createTheme, MuiThemeProvider } from "@material-ui/core";

import styles from "@/pages/Teacher/MyPage/index.module.scss";

import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import { DeleteConfirmationModal } from "./DeleteConfirmationModal";
import { useDeleteBankAccountMutation } from "@/store/hooks/stripe";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { ModifiedBankAccount } from "../BankAccountSettingContents";

interface Props {
    bankAccount: ModifiedBankAccount;
    defaultBankAccountId: string | null;
    handleBackButtonClick: () => void;
}

export const EditModeContents: React.VFC<Props> = (props) => {
    const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState<boolean>(false);
    const [isDetaching, setIsDetaching] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);
    const deleteBankAccount = useDeleteBankAccountMutation();

    const theme = createTheme({
        overrides: {
            MuiCheckbox: {
                root: {
                    "&:hover": {
                        backgroundColor: "#e1edfb",
                    },
                },
                colorSecondary: {
                    "&$checked": {
                        color: "#305077",
                    },
                    "&:hover": {
                        backgroundColor: "#e1edfb !important",
                    },
                },
            },
            MuiIconButton: {
                colorSecondary: {
                    color: "#305077",
                },
            },
        },
    });

    const handleDeleteConfirmModalOpen = useCallback(() => {
        setDeleteConfirmModalOpen(true);
    }, []);

    const handleDeleteConfirmModalClose = useCallback(() => {
        setDeleteConfirmModalOpen(false);
        if (isError) {
            props.handleBackButtonClick();
        }
    }, [isError]);

    const handleDeleteConfirmButtonClick = async (bankAccountId: string) => {
        setIsDetaching(true);
        const result = await deleteBankAccount({
            deleteBankAccountRequestBody: {
                teacherId: teacherId,
                bankAccountId: bankAccountId,
            },
        });
        if (!result.isSuccess) {
            setIsError(true);
        }
        setIsDetaching(false);
    };

    return (
        <div className={styles.bankAccountButton}>
            <MuiThemeProvider theme={theme}>
                <Checkbox
                    icon={<MdRadioButtonUnchecked />}
                    checkedIcon={<MdRadioButtonChecked />}
                    checked={props.bankAccount.isDefault}
                    disabled
                />
            </MuiThemeProvider>
            <div className={styles.bankAccountBody}>
                <div className={styles.bankAccountTopWrapper}>
                    <div className={styles.bankFullName}>{props.bankAccount.bankFullName ?? "〇〇銀行"}</div>
                    <div className={styles.branchName}>{props.bankAccount.branchName ?? "〇〇支店"}</div>
                </div>
                <div className={styles.accountNumber}>****{props.bankAccount.last3}</div>
                <div className={styles.accountHolderName}>{props.bankAccount.accountHolderName}</div>
            </div>
            {!props.bankAccount.isDefault && (
                <Button className={styles.deleteButton} onClick={handleDeleteConfirmModalOpen}>
                    削除
                </Button>
            )}
            <DeleteConfirmationModal
                modalOpen={deleteConfirmModalOpen}
                isDetaching={isDetaching}
                isError={isError}
                handleClose={handleDeleteConfirmModalClose}
                handleDeleteConfirmButtonClick={() => {
                    handleDeleteConfirmButtonClick(props.bankAccount.id);
                }}
            />
        </div>
    );
};
