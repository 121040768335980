import { Button, Checkbox, FormControlLabel } from "@material-ui/core";

import { SubjectDetailButton } from "./SubjectDetailButton";
import styles from "./index.module.scss";
import { memo, useCallback, useState } from "react";

interface Props {
    categoryKey: string;
    object: ObjectType;
    level: string;
    getIsSomeIncluded: (categoryKey: string) => boolean;
    getIsEveryIncluded: (categoryKey: string) => boolean;
    handleParentCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>, category: string) => void;
    handleChildrenCheckboxChange: (
        e: React.ChangeEvent<HTMLInputElement>,
        category: string,
        subCategory: string,
    ) => void;
    getIsSubCategoryKeyIncluded: (subjectDetailKey: string) => boolean;
}

interface ObjectType {
    label: string;
    sub?:
        | {
              [subject: string]: {
                  label: string;
                  icon: JSX.Element;
              };
          }
        | undefined;
    icon: JSX.Element;
}

const MIDDLE_KEYS = ["worldHistory", "english"];
const BOTTOM_ZERO_KEYS = [
    "japaneseHistory",
    "geography",
    "basicPhysics",
    "biology",
    "basicEarthScience",
    "earthScience",
    "science",
    "physics",
    "basicChemistry",
];

export const SubjectContents: React.VFC<Props> = memo(function SubjectContents(props) {
    const [targetCategory, setTargetCategory] = useState<string | undefined>(undefined);

    const handlePopoverOpen = useCallback((categoryKey: string) => {
        setTargetCategory(categoryKey);
    }, []);

    const handlePopoverClose = useCallback(() => {
        setTargetCategory(undefined);
    }, []);

    const getLeft = useCallback((subjectLabel: string) => {
        const left = 75 + 8 * (subjectLabel.length - 2);
        return left;
    }, []);

    return (
        <div className={styles.subjectWrapper} key={props.categoryKey} onMouseLeave={handlePopoverClose}>
            <Button
                className={styles.subjectButton}
                onMouseOver={() => {
                    handlePopoverOpen(props.categoryKey);
                }}
            >
                <FormControlLabel
                    label={
                        <>
                            {props.object.icon}
                            <span className={styles.title} style={{ marginRight: 0 }}>
                                {props.object.label}
                            </span>
                        </>
                    }
                    labelPlacement="start"
                    className={styles.formControlLabel}
                    control={
                        <Checkbox
                            checked={props.getIsEveryIncluded(props.categoryKey)}
                            indeterminate={
                                props.getIsSomeIncluded(props.categoryKey) &&
                                !props.getIsEveryIncluded(props.categoryKey)
                            }
                            onChange={(e) => {
                                props.handleParentCheckboxChange(e, props.categoryKey);
                            }}
                            className={styles.checkbox}
                        />
                    }
                />
            </Button>
            <div
                className={styles.tooltip}
                style={{
                    display: targetCategory === props.categoryKey && props.object.sub ? "block" : "none",
                    left: getLeft(props.object.label),
                    top: BOTTOM_ZERO_KEYS.includes(props.categoryKey)
                        ? "unset"
                        : MIDDLE_KEYS.includes(props.categoryKey)
                        ? "-100px"
                        : 0,
                    bottom: BOTTOM_ZERO_KEYS.includes(props.categoryKey) ? 0 : "unset",
                }}
                onMouseLeave={handlePopoverClose}
            >
                {props.object.sub &&
                    Object.entries(props.object?.sub).map(([subCategoryKey, subjectDetail]) => {
                        return (
                            <SubjectDetailButton
                                key={subCategoryKey}
                                level={props.level}
                                categoryKey={props.categoryKey}
                                subjectDetail={subjectDetail}
                                subCategoryKey={subCategoryKey}
                                handleChildrenCheckboxChange={props.handleChildrenCheckboxChange}
                                getIsSubCategoryKeyIncluded={props.getIsSubCategoryKeyIncluded}
                            />
                        );
                    })}
            </div>
        </div>
    );
});
