import { createTheme, MuiThemeProvider } from "@material-ui/core";
import { memo } from "react";
import { Switch as SwitchBase } from "@material-ui/core";
import styles from "./index.module.scss";

interface Props {
    checked: boolean;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const theme = createTheme({
    overrides: {
        MuiSwitch: {
            thumb: {
                backgroundColor: "#FAFAFA",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 5px",
            },
            switchBase: {},
            colorSecondary: {
                "&$checked": {
                    color: "#fafafa",
                    "& + .MuiSwitch-track": {
                        opacity: 1,
                        backgroundColor: "#305077 !important",
                    },
                },
            },
        },
    },
});

export const Switch: React.VFC<Props> = memo(function Switch(props) {
    return (
        <MuiThemeProvider theme={theme}>
            <div className={styles.switchWrapper}>
                <div className={styles.off}>OFF</div>
                <SwitchBase checked={props.checked} onChange={props.handleChange} className={styles.switch} />
                <div className={styles.on}>ON</div>
            </div>
        </MuiThemeProvider>
    );
});
