import React from "react";
import { useSelector } from "react-redux";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { PointsRecordResponse } from "@/store/autogenApi";
import { useGetPointsRecordHistoryQuery, useGetPrivateUserQuery } from "@/store/hooks/users";
import { WHAT_DAY_LIST } from "@/utils/WhatDayList";
import styles from "./index.module.scss";
import { MyPage } from ".";
import { RootState } from "@/ducks";
import { memo, useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { PageNumbers } from "@/components/PageNumbers";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { Navigation } from "@/components/Navigation";

const NAVIGATION_LIST: Navigation[] = [
    { title: "マイページ", url: "/Teacher/MyPage/Profile" },
    { title: "ポイント履歴", url: "/Teacher/MyPage/PointsRecordHistory" },
];

export const TeacherPointsRecordHistory: React.VFC = memo(function TeacherPointsRecordHistory() {
    const [pageNumber, setPageNumber] = useState<number>(1);

    const userId = useSelector((state: RootState) => state.jwt.userId as string);

    const userQuery = useGetPrivateUserQuery(userId);
    const pointsRecordHistoryQuery = useGetPointsRecordHistoryQuery(userId);

    const smallThresholdUp = useMediaQuery("(min-width:450px)");
    const mediumThresholdUp = useMediaQuery("(min-width:600px)");
    const largeThresholdUp = useMediaQuery("(min-width:1000px)");

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    const getContent = useCallback((pointsRecord: PointsRecordResponse) => {
        switch (pointsRecord.type) {
            case "payment":
                return `講座「${pointsRecord.class?.course?.title}」に対するポイント利用`;
            case "campaign":
                return `キャンペーンによるポイント付与`;
            case "fromAdmin":
                return `運営事務局からのポイント付与`;
            default:
                return "";
        }
    }, []);

    const getTime = useCallback((createdAt: Date | undefined) => {
        if (createdAt) {
            const modifiedCreatedAt = new Date(createdAt);
            const year = modifiedCreatedAt.getFullYear();
            const month = modifiedCreatedAt.getMonth() + 1;
            const date = modifiedCreatedAt.getDate();
            const day = WHAT_DAY_LIST[modifiedCreatedAt.getDay()];
            const hour = ("00" + modifiedCreatedAt.getHours()).slice(-2);
            const minute = ("00" + modifiedCreatedAt.getMinutes()).slice(-2);
            return `${year}/${month}/${date}(${day}) ${hour}:${minute}`;
        }
    }, []);

    const thresholdNumber = largeThresholdUp ? 10 : mediumThresholdUp ? 8 : smallThresholdUp ? 6 : 4;
    return (
        <MyPage title="ポイント履歴" activeTab="pointsRecordHistory">
            <div className={styles.pointsRecordHistoryWrapper}>
                <QueryLoadingWrapper {...pointsRecordHistoryQuery}>
                    {(pointsRecordHistory) => (
                        <>
                            <div className={styles.currentPointsWrapper}>
                                <div className={styles.currentPointsTitle}>現在のポイント残高</div>
                                <QueryLoadingWrapper {...userQuery}>
                                    {(user) => <div className={styles.currentPoints}>{user?.pointsBalance}pt</div>}
                                </QueryLoadingWrapper>
                                {pointsRecordHistory.length > 0 && (
                                    <div className={styles.currentTarget}>
                                        {pointsRecordHistory.length}件中 {(pageNumber - 1) * 20 + 1} ~{" "}
                                        {pageNumber * 20 < pointsRecordHistory.length
                                            ? pageNumber * 20
                                            : pointsRecordHistory.length}
                                        件 を表示
                                    </div>
                                )}
                            </div>
                            {pointsRecordHistory.length === 0 ? (
                                <div className={styles.emptyPointsRecord}>ポイント履歴はありません。</div>
                            ) : (
                                <ul className={styles.pointsRecordHistory}>
                                    <li className={styles.topPointsRecord}>
                                        <div className={styles.topPointsRecordItemDate}>日時</div>
                                        <div className={styles.topPointsRecordItemContent}>内容</div>
                                        <div className={styles.topPointsRecordItemPoint}>増減</div>
                                    </li>
                                    {pointsRecordHistory
                                        .slice((pageNumber - 1) * 20, (pageNumber - 1) * 20 + 20)
                                        .map((pointsRecord) => {
                                            return (
                                                <li key={pointsRecord.pointsRecordId} className={styles.pointsRecord}>
                                                    <div className={styles.pointsRecordItemDate}>
                                                        {getTime(new Date(pointsRecord.createdAt))}
                                                    </div>
                                                    <div className={styles.pointsRecordItemContent}>
                                                        {getContent(pointsRecord)}
                                                    </div>
                                                    <div className={styles.pointsRecordItemPoint}>
                                                        {typeof pointsRecord.amount === "number" &&
                                                        pointsRecord.amount > 0
                                                            ? `+${pointsRecord.amount}`
                                                            : pointsRecord.amount}
                                                        pt
                                                    </div>
                                                </li>
                                            );
                                        })}
                                </ul>
                            )}
                            {20 < pointsRecordHistory.length && (
                                <PageNumbers
                                    pageNumber={pageNumber}
                                    setPageNumber={setPageNumber}
                                    items={pointsRecordHistory}
                                    itemsPerPage={20}
                                    thresholdNumber={thresholdNumber}
                                />
                            )}
                        </>
                    )}
                </QueryLoadingWrapper>
            </div>
        </MyPage>
    );
});
