import { JwtRequestBody } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions } from "./utils/types";

export const useGetDataFromJWTQuery = (requestBody: string) => {
    const result = defaultApi.endpoints.getDataFromJwt.useQuery({
        token: requestBody,
    });
    return useQueryResult(result, (response: JwtRequestBody) => response);
};

export const useCreateJWTMutation = (options?: UseMutationOptions) => {
    const [createJWT] = defaultApi.endpoints.createJwt.useMutation();
    return useMutationFunctionWrapper(createJWT, options?.errorMessages);
};
