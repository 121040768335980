import { MdArrowForwardIos } from "react-icons/md";
import styles from "@/pages/Student/Payment/index.module.scss";

import VISA from "@/images/VISA.jpg";
import MasterCard from "@/images/Mastercard.jpg";
import JCB from "@/images/JCB.jpg";
import AMEX from "@/images/AMEX.jpg";
import DinersClub from "@/images/DinersClub.jpg";
import Discover from "@/images/DISCOVER.jpg";
import { useCallback, useEffect, useState } from "react";
import { ClassResponse, LessonResponse } from "@/store/autogenApi";
import { Button } from "@material-ui/core";
import { PaymentMethodModal } from "./PaymentMethodModal";
import { useHistory } from "react-router";

import defaultCourseImage from "@/images/defaultCourseImage.jpg";
import { getBaseScheduleInfo } from "@/utils/BaseScheduleUtils";
import { ErrorMessage } from "@/components/ErrorMessage";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { dateToOnlyDateString } from "@/utils/DateUtils";
import { ImageFromS3 } from "@/components/atoms/images/ImageFromS3";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    class: ClassResponse;
    pointsBalance: number;
    points: number;
    defaultPaymentMethod: any | null;
    targetPaymentMethod: any | undefined;
    isChecked: boolean;
    firstDate: Date | undefined;
    price: number | undefined;
    setTargetPaymentMethod: React.Dispatch<React.SetStateAction<any | undefined>>;
    setPoints: React.Dispatch<React.SetStateAction<number>>;
    handleCheckButtonClick: () => void;
}

export const PaymentInfoInputStep: React.VFC<Props> = (props) => {
    const [paymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);

    const getSrc = useCallback((brand: string) => {
        if (brand === "visa") return VISA;
        if (brand === "amex") return AMEX;
        if (brand === "mastercard") return MasterCard;
        if (brand === "jcb") return JCB;
        if (brand === "discover") return Discover;
        if (brand === "diners") return DinersClub;
    }, []);

    const handlePaymentMethodButtonClick = useCallback(() => {
        setPaymentMethodModalOpen(true);
    }, []);

    const handlePaymentMethodModalClose = useCallback(() => {
        setPaymentMethodModalOpen(false);
    }, []);

    const getTime = useCallback((lesson: LessonResponse) => {
        const startTime = lesson.startTime ? new Date(lesson.startTime) : undefined;
        const endTime = lesson.endTime ? new Date(lesson.endTime) : undefined;
        if (startTime && endTime) {
            const startYear = startTime.getFullYear();
            const startMonth = startTime.getMonth() + 1;
            const startDay = ("00" + startTime.getDate()).slice(-2);
            const startHour = ("00" + startTime.getHours()).slice(-2);
            const startMinute = ("00" + startTime.getMinutes()).slice(-2);
            const finishHour = ("00" + endTime.getHours()).slice(-2);
            const finishMinute = ("00" + endTime.getMinutes()).slice(-2);
            return `${startYear}/${startMonth}/${startDay} ${startHour}:${startMinute} - ${finishHour}:${finishMinute}`;
        }
    }, []);

    const getCourseType = useCallback(() => {
        if (props.class.course?.courseType === "short") return "単発・短期講座";
        if (props.class.course?.courseType === "regular") return "定期講座";
    }, [props.class.course?.courseType]);

    const history = useHistory();
    const handleBackButtonClick = useCallback(() => {
        history.goBack();
    }, []);

    return (
        <div className={styles.paymentInfoInputContentsWrapper}>
            <button className={styles.backButton} onClick={handleBackButtonClick}>
                <div className={styles.back}>戻る</div>
            </button>
            {props.price != undefined && props.price > 0 && (
                <div className={styles.paymentInfoWrapper}>
                    <div className={styles.paymentInfoTitle}>支払い情報の入力</div>
                    <div className={styles.courseAndTeacherWrapper}>
                        <div className={styles.targetCourse}>
                            <ImageFromS3
                                url={props.class.course.thumbnailUrl}
                                objectKey={props.class.course.thumbnailObjectKey}
                                className={styles.thumbnail}
                            />
                            <div className={styles.targetCourseRightWrapper}>
                                <div className={styles.courseType}>{getCourseType()}</div>
                                <div className={styles.courseTitle}>{props.class.course?.title}</div>
                                <div className={styles.howMuchWrapper}>
                                    <div className={styles.price}>
                                        ¥{new Intl.NumberFormat("ja-JP").format(Math.floor(props.price))}
                                    </div>
                                    <div className={styles.tax}>{`（税込・${
                                        props.class.course?.courseType === "short" ? "一括" : "月額"
                                    })`}</div>
                                </div>
                                <div className={styles.schedulesWrapper}>
                                    <div className={styles.schedulesTitle}>
                                        {props.class.course?.courseType === "short" ? "開催日時:" : "基本スケジュール:"}
                                    </div>
                                    <ul className={styles.lessons}>
                                        {props.class.course?.courseType === "short"
                                            ? props.class.lessons.map((lesson, idx) => {
                                                  return (
                                                      <li key={lesson.lessonId} className={styles.lesson}>
                                                          {props.class.lessons.length > 1 && (
                                                              <div className={styles.idx}>{idx + 1}日目</div>
                                                          )}
                                                          <div className={styles.time}>{getTime(lesson)}</div>
                                                      </li>
                                                  );
                                              })
                                            : props.class.privateRequest?.baseSchedules?.map((baseSchedule) => {
                                                  return (
                                                      <li key={baseSchedule.baseScheduleId} className={styles.lesson}>
                                                          {getBaseScheduleInfo(baseSchedule)}
                                                      </li>
                                                  );
                                              })}
                                    </ul>
                                </div>
                                {props.class.course?.courseType === "regular" && props.firstDate && (
                                    <div className={styles.firstDateWrapper}>
                                        <div className={styles.firstDateTitle}>初回授業日:</div>
                                        <div className={styles.firstDate}>{dateToOnlyDateString(props.firstDate)}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.teacherWrapper}>
                            <div className={styles.teacherTitle}>担当の先生</div>
                            <div className={styles.teacherInfo}>
                                <AvatarFromS3
                                    className={styles.avatar}
                                    url={props.class.course.teacher.iconImageUrl}
                                    objectKey={props.class.course.teacher.iconImageObjectKey}
                                />
                                <div className={styles.nickname}>{props.class.course?.teacher?.nickname}先生</div>
                            </div>
                        </div>
                    </div>
                    {props.targetPaymentMethod ? (
                        <>
                            <button className={styles.paymentMethodWrapper} onClick={handlePaymentMethodButtonClick}>
                                <div className={styles.paymentMethodTitle}>支払い方法</div>
                                <div className={styles.defaultPaymentMethodButton}>
                                    {props.targetPaymentMethod?.type === "card" && (
                                        <div className={styles.cardWrapper}>
                                            <img
                                                src={getSrc(props.targetPaymentMethod?.card?.brand)}
                                                alt=""
                                                className={styles.brandImage}
                                            />
                                            <div className={styles.rightWrapper}>
                                                <div
                                                    className={styles.cardNumber}
                                                >{`**** **** **** ${props.targetPaymentMethod?.card?.last4}`}</div>
                                                <div className={styles.exp}>
                                                    {props.targetPaymentMethod?.card?.exp_month}/
                                                    {props.targetPaymentMethod?.card?.exp_year}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <MdArrowForwardIos className={styles.arrowIcon} />
                                </div>
                            </button>
                            <PaymentMethodModal
                                paymentMethodModalOpen={paymentMethodModalOpen}
                                targetPaymentMethod={props.targetPaymentMethod}
                                defaultPaymentMethod={props.defaultPaymentMethod}
                                setTargetPaymentMethod={props.setTargetPaymentMethod}
                                handlePaymentMethodModalClose={handlePaymentMethodModalClose}
                            />
                        </>
                    ) : (
                        <div className={styles.emptyPaymentMethodWrapper}>
                            <div className={styles.paymentMethodTitle}>支払い方法</div>
                            <button className={styles.addPaymentMethodButton} onClick={handlePaymentMethodButtonClick}>
                                <div className={styles.plus}>支払い方法を選択</div>
                            </button>
                            <PaymentMethodModal
                                paymentMethodModalOpen={paymentMethodModalOpen}
                                targetPaymentMethod={props.targetPaymentMethod}
                                defaultPaymentMethod={props.defaultPaymentMethod}
                                setTargetPaymentMethod={props.setTargetPaymentMethod}
                                handlePaymentMethodModalClose={handlePaymentMethodModalClose}
                            />
                            <ErrorMessage
                                when={props.isChecked && !props.targetPaymentMethod}
                                content="支払い方法を選択してください。"
                            />
                        </div>
                    )}
                    <Button className={styles.checkButton} onClick={props.handleCheckButtonClick}>
                        確認
                    </Button>
                </div>
            )}
        </div>
    );
};
