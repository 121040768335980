import { memo } from "react";
import styles from "./index.module.scss";
import { WHAT_DAY_LIST } from "@/utils/WhatDayList";
import { getIsTheSameDay } from "@/utils/DateUtils";

interface Props {
    getLaneDate: (laneIndex: number) => Date;
}

export const LessonCalendarOfWeekHeadings: React.VFC<Props> = memo((props) => {
    return (
        <div className={styles.lessonCalendarLaneHeadings}>
            <div className={styles.lessonCalendarLaneHeadingLeft} />
            {[...Array(7)].map((_, i) => {
                const date = props.getLaneDate(i);
                const isToday = getIsTheSameDay(date, new Date());
                return (
                    <div className={styles.lessonCalendarLaneHeading} key={i}>
                        <div
                            className={styles.lessonCalendarLaneHeadingDay}
                            style={{ color: isToday ? "#305077" : "#333", fontWeight: isToday ? "bold" : "normal" }}
                        >
                            {WHAT_DAY_LIST[i]}
                        </div>
                        <div
                            className={styles.lessonCalendarLaneHeadingDate}
                            style={{
                                backgroundColor: isToday ? "#305077" : "transparent",
                                color: isToday ? "#FFF" : "#333",
                            }}
                        >
                            {date.getDate()}
                        </div>
                    </div>
                );
            })}
            <div className={styles.lessonCalendarLaneHeadingRight} />
        </div>
    );
});
