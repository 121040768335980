import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { RootState } from "@/ducks";
import { PrivateUserResponse } from "@/store/autogenApi";
import { useGetReservedClassesQuery } from "@/store/hooks/students";
import { CommonComponent } from "./Common";

interface Props {
    user: PrivateUserResponse;
}

export const ForStudentComponent: React.VFC<Props> = (props) => {
    const { id } = useParams<{ id: string }>();
    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);

    const reservedClassesQueryState = useGetReservedClassesQuery(studentId);

    return (
        <QueryLoadingWrapper {...reservedClassesQueryState}>
            {(reservedClasses) => <CommonComponent user={props.user} reservedClasses={reservedClasses} />}
        </QueryLoadingWrapper>
    );
};
