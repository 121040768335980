import { Bold } from "@/components/atoms/Bold";
import styles from "@/pages/Common/LP/index.module.scss";
import { Button } from "@material-ui/core";
import { useMediaQuery } from "@mui/material";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

export const PostScript: React.VFC = () => {
    const mdUp = useMediaQuery("(min-width:960px)");
    const history = useHistory();
    const handleSignUpButtonClick = useCallback(() => {
        history.push("/SignUp?type=student");
    }, []);
    return (
        <div className={styles.postScriptWrapper}>
            <div className={styles.postScriptTitle}>最後に</div>
            <div className={styles.postScript}>
                Trailのご説明をここまで読んでくださってありがとうございます。
                <br />
                <br />
                今回は、Trailをあなたに利用していただいて、{mdUp && <br />}
                最高の先生とともに成績UP、受験の合格に向かってほしい{mdUp && <br />}という思いを熱くお伝えしました。
                <br />
                <br />
                テレビCMで見るような大手の塾などではありませんので、{mdUp && <br />}
                あなたも少なからずTrailを利用することに不安があるかもしれません。
                <br />
                <br />
                ただ、1つ言えるのは成績を上げるのは
                <Bold text="孤独な自分との戦い" />
                だということです。
                <br />
                ときにはどう進めば良いのかわからなくなることもあるでしょう。
                <br />
                そんなときに
                <Bold text="道しるべとなってくれる頼れる先生" />
                がいることがどれだけ心強いことか。
                <br />
                <br />
                Trailに登録して、ぜひそんな素敵な先生を見つけてください。
            </div>
            <Button className={styles.signUpButton} onClick={handleSignUpButtonClick}>
                新規ユーザー登録へ（無料）
            </Button>
        </div>
    );
};
