import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    FormControlLabel,
    Hidden,
} from "@material-ui/core";
import { VscTriangleDown } from "react-icons/vsc";

import { SubjectConfig } from "@/SubjectConfig";
import { SubjectContents } from "./SubjectContents";
import { SubjectDetailButton } from "./SubjectDetailButton";
import styles from "./index.module.scss";
import { memo, useCallback, useState } from "react";
import { Level, NominalTypingSubjectString, SubjectResponse } from "@/store/autogenApi";

interface Props {
    level: string;
    subjectConfig: SubjectConfig;
    allSubjects: SubjectResponse[];
    baseSubjects: SubjectResponse[];
    subjects: SubjectResponse[];
    setSubjects: React.Dispatch<React.SetStateAction<SubjectResponse[]>>;
}

export const SchoolCategory: React.VFC<Props> = memo(function SchoolCategory(props) {
    const [expanded, setExpanded] = useState<string | false>("panel1");

    const handleChange = (panel: string) => (event: React.ChangeEvent<unknown>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    const getTargetSubjectId = useCallback(
        (category: string, subCategory: string | undefined) => {
            const targetSubject = props.allSubjects.find(
                (subject) =>
                    subject.level === props.level &&
                    subject.category === category &&
                    subject.subCategory === subCategory,
            );
            return targetSubject ? targetSubject.subjectId : ("" as NominalTypingSubjectString);
        },
        [props.allSubjects, props.level],
    );

    const getIsEveryIncluded = useCallback(
        (categoryKey: string) => {
            const sub = props.subjectConfig[categoryKey as keyof SubjectConfig].sub;
            if (sub) {
                const detailArray = Object.keys(sub);
                const favoriteSubCategories = props.subjects
                    .filter((subject) => subject.level === props.level && subject.category === categoryKey)
                    .map((subject) => subject.subCategory);
                const result = detailArray.every((detail) => favoriteSubCategories.includes(detail));
                return result;
            } else {
                const result = props.subjects.some(
                    (subject) => subject.level === props.level && subject.category === categoryKey,
                );
                return result;
            }
        },
        [props.subjects],
    );

    const getIsSomeIncluded = useCallback(
        (categoryKey: string) => {
            const sub = props.subjectConfig[categoryKey as keyof SubjectConfig].sub;
            if (sub) {
                const detailArray = Object.keys(sub);
                const favoriteSubCategories = props.subjects
                    .filter((subject) => subject.level === props.level && subject.category === categoryKey)
                    .map((subject) => subject.subCategory);
                const result = detailArray.some((detail) => favoriteSubCategories.includes(detail));
                return result;
            } else {
                return false;
            }
        },
        [props.subjects],
    );

    const handleChildrenCheckboxChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, category: string, subCategory: string) => {
            const checked = e.target.checked;
            if (checked) {
                const subject: SubjectResponse = {
                    subjectId: getTargetSubjectId(category, subCategory),
                    level: props.level as Level,
                    category: category,
                    subCategory: subCategory,
                };
                props.setSubjects([...props.baseSubjects, subject]);
            } else {
                const fixedSubjects = props.baseSubjects.filter(
                    (subject) =>
                        !(
                            subject.level === props.level &&
                            subject.category === category &&
                            subject.subCategory === subCategory
                        ),
                );
                props.setSubjects(fixedSubjects);
            }
        },
        [props.baseSubjects, props.level, props.setSubjects],
    );

    const handleParentCheckboxChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, category: string) => {
            const checked = e.target.checked;
            if (checked) {
                const targetSubjects = props.baseSubjects.map((subject) => subject);
                Object.entries(props.subjectConfig).forEach(([categoryKey, object1]) => {
                    if (category === categoryKey) {
                        const sub = object1.sub;
                        if (sub) {
                            Object.entries(sub).forEach(([subCategoryKey, object2]) => {
                                object2;
                                const condition = !props.subjects.some(
                                    (subject) =>
                                        subject.level === props.level &&
                                        subject.category === categoryKey &&
                                        subject.subCategory === subCategoryKey,
                                );
                                if (condition) {
                                    const newSubject: SubjectResponse = {
                                        subjectId: getTargetSubjectId(categoryKey, subCategoryKey),
                                        level: props.level as Level,
                                        category: categoryKey,
                                        subCategory: subCategoryKey,
                                    };
                                    targetSubjects.push(newSubject);
                                }
                            });
                        } else {
                            const condition = !targetSubjects.some(
                                (subject) =>
                                    subject.level === props.level &&
                                    subject.category === categoryKey &&
                                    subject.subCategory === undefined,
                            );
                            if (condition) {
                                const newSubject: SubjectResponse = {
                                    subjectId: getTargetSubjectId(categoryKey, undefined),
                                    level: props.level as Level,
                                    category: categoryKey,
                                    subCategory: undefined,
                                };
                                targetSubjects.push(newSubject);
                            }
                        }
                    }
                });
                props.setSubjects(targetSubjects);
            } else {
                const targetSubjects = props.baseSubjects.filter(
                    (subject) => !(subject.level === props.level && subject.category === category),
                );
                props.setSubjects(targetSubjects);
            }
        },
        [props.baseSubjects, props.level, props.setSubjects],
    );

    const getIsSubCategoryKeyIncluded = useCallback(
        (subCategoryKey: string) => {
            const result = props.subjects.some(
                (subject) => subject.level === props.level && subject.subCategory === subCategoryKey,
            );
            return result;
        },
        [props.subjects],
    );

    const japaneseSchoolName = useCallback((level: string) => {
        if (level === "university") {
            return "大学生・社会人";
        }
        if (level === "highSchool") {
            return "高校生";
        }
        if (level === "juniorHighSchool") {
            return "中学生";
        }
        if (level === "elementarySchool") {
            return "小学生";
        }
    }, []);

    return (
        <div className={styles.category} style={{ flex: japaneseSchoolName(props.level) === "高校生" ? 2 : 1 }}>
            {/* {タブレット・スマホ} */}
            <Hidden mdUp>
                <Accordion className={styles.bigAccordion}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={styles.bigAccordionSummary}
                    >
                        <div className={styles.categoryTitle}>{japaneseSchoolName(props.level)}</div>
                        <VscTriangleDown className={styles.bigTriangle} />
                    </AccordionSummary>
                    <AccordionDetails className={styles.bigAccordionDetails}>
                        <div
                            className={styles.subjectList}
                            style={{ display: props.level === "highSchool" ? "flex" : "block" }}
                            // style={{ columnCount: japaneseSchoolName(props.level) === "高校" ? 2 : 1 }}
                        >
                            {props.level === "highSchool" && (
                                <>
                                    <Box component="div" sx={{ display: { xs: "none", sm: "flex", md: "none" } }}>
                                        {/* {タブレット} */}
                                        <div className={styles.leftSubjects}>
                                            {Object.entries(props.subjectConfig).map(([categoryKey, object], idx) => {
                                                return (
                                                    <div key={categoryKey}>
                                                        {idx < Object.keys(props.subjectConfig).length / 2 && (
                                                            <div className={styles.accordionWrapper}>
                                                                <Checkbox
                                                                    checked={getIsEveryIncluded(categoryKey)}
                                                                    indeterminate={
                                                                        getIsSomeIncluded(categoryKey) &&
                                                                        !getIsEveryIncluded(categoryKey)
                                                                    }
                                                                    onChange={(e) => {
                                                                        handleParentCheckboxChange(e, categoryKey);
                                                                    }}
                                                                    className={styles.checkbox}
                                                                />
                                                                <Accordion
                                                                    square
                                                                    expanded={expanded === categoryKey}
                                                                    onChange={handleChange(categoryKey)}
                                                                    className={styles.accordion}
                                                                    style={{
                                                                        margin: "0",
                                                                        breakBefore:
                                                                            categoryKey === "physics"
                                                                                ? "column"
                                                                                : "auto",
                                                                        boxShadow: "none",
                                                                    }}
                                                                >
                                                                    <AccordionSummary
                                                                        aria-controls="panel1d-content"
                                                                        id="panel1d-header"
                                                                        style={{}}
                                                                        className={styles.accordionSummary}
                                                                    >
                                                                        {object.icon}
                                                                        <span className={styles.label}>
                                                                            {object.label}
                                                                        </span>
                                                                        <VscTriangleDown className={styles.triangle} />
                                                                    </AccordionSummary>
                                                                    <AccordionDetails
                                                                        style={{
                                                                            display: "block",
                                                                            padding: "0",
                                                                            backgroundColor: "#EEE",
                                                                        }}
                                                                        className={styles.accordionDetails}
                                                                    >
                                                                        {object.sub &&
                                                                            Object.entries(object?.sub).map(
                                                                                ([subCategoryKey, subjectDetail]) => {
                                                                                    return (
                                                                                        <div key={subCategoryKey}>
                                                                                            <SubjectDetailButton
                                                                                                level={props.level}
                                                                                                categoryKey={
                                                                                                    categoryKey
                                                                                                }
                                                                                                subjectDetail={
                                                                                                    subjectDetail
                                                                                                }
                                                                                                subCategoryKey={
                                                                                                    subCategoryKey
                                                                                                }
                                                                                                mobile={true}
                                                                                                handleChildrenCheckboxChange={
                                                                                                    handleChildrenCheckboxChange
                                                                                                }
                                                                                                getIsSubCategoryKeyIncluded={
                                                                                                    getIsSubCategoryKeyIncluded
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    );
                                                                                },
                                                                            )}
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className={styles.rightSubjects}>
                                            {Object.entries(props.subjectConfig).map(([categoryKey, object], idx) => {
                                                return (
                                                    <div key={categoryKey}>
                                                        {idx >= Object.keys(props.subjectConfig).length / 2 && (
                                                            <div className={styles.accordionWrapper}>
                                                                <Checkbox
                                                                    checked={getIsEveryIncluded(categoryKey)}
                                                                    indeterminate={
                                                                        getIsSomeIncluded(categoryKey) &&
                                                                        !getIsEveryIncluded(categoryKey)
                                                                    }
                                                                    onChange={(e) => {
                                                                        handleParentCheckboxChange(e, categoryKey);
                                                                    }}
                                                                    className={styles.checkbox}
                                                                />
                                                                <Accordion
                                                                    square
                                                                    expanded={expanded === categoryKey}
                                                                    onChange={handleChange(categoryKey)}
                                                                    className={styles.accordion}
                                                                    style={{
                                                                        margin: "0",
                                                                        breakBefore:
                                                                            categoryKey === "physics"
                                                                                ? "column"
                                                                                : "auto",
                                                                        boxShadow: "none",
                                                                    }}
                                                                >
                                                                    <AccordionSummary
                                                                        aria-controls="panel1d-content"
                                                                        id="panel1d-header"
                                                                        style={{}}
                                                                        className={styles.accordionSummary}
                                                                    >
                                                                        {object.icon}
                                                                        <span className={styles.label}>
                                                                            {object.label}
                                                                        </span>
                                                                        <VscTriangleDown className={styles.triangle} />
                                                                    </AccordionSummary>
                                                                    <AccordionDetails
                                                                        style={{
                                                                            display: "block",
                                                                            padding: "0",
                                                                            backgroundColor: "#EEE",
                                                                        }}
                                                                        className={styles.accordionDetails}
                                                                    >
                                                                        {object.sub &&
                                                                            Object.entries(object?.sub).map(
                                                                                ([subCategoryKey, subjectDetail]) => {
                                                                                    return (
                                                                                        <div key={subCategoryKey}>
                                                                                            <SubjectDetailButton
                                                                                                level={props.level}
                                                                                                categoryKey={
                                                                                                    categoryKey
                                                                                                }
                                                                                                subjectDetail={
                                                                                                    subjectDetail
                                                                                                }
                                                                                                subCategoryKey={
                                                                                                    subCategoryKey
                                                                                                }
                                                                                                mobile={true}
                                                                                                handleChildrenCheckboxChange={
                                                                                                    handleChildrenCheckboxChange
                                                                                                }
                                                                                                getIsSubCategoryKeyIncluded={
                                                                                                    getIsSubCategoryKeyIncluded
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    );
                                                                                },
                                                                            )}
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </Box>
                                    {/* スマホ */}
                                    <Hidden smUp>
                                        <div>
                                            {Object.entries(props.subjectConfig).map(([categoryKey, object]) => {
                                                return (
                                                    <div className={styles.accordionWrapper} key={categoryKey}>
                                                        <Checkbox
                                                            checked={getIsEveryIncluded(categoryKey)}
                                                            indeterminate={
                                                                getIsSomeIncluded(categoryKey) &&
                                                                !getIsEveryIncluded(categoryKey)
                                                            }
                                                            onChange={(e) => {
                                                                handleParentCheckboxChange(e, categoryKey);
                                                            }}
                                                            className={styles.checkbox}
                                                        />
                                                        <Accordion
                                                            square
                                                            expanded={expanded === categoryKey}
                                                            onChange={handleChange(categoryKey)}
                                                            className={styles.accordion}
                                                            style={{
                                                                margin: "0",
                                                                breakBefore:
                                                                    categoryKey === "physics" ? "column" : "auto",
                                                                boxShadow: "none",
                                                            }}
                                                        >
                                                            <AccordionSummary
                                                                aria-controls="panel1d-content"
                                                                id="panel1d-header"
                                                                style={{}}
                                                                className={styles.accordionSummary}
                                                            >
                                                                {object.icon}
                                                                <span className={styles.label}>{object.label}</span>
                                                                <VscTriangleDown className={styles.triangle} />
                                                            </AccordionSummary>
                                                            <AccordionDetails
                                                                style={{
                                                                    display: "block",
                                                                    padding: "0",
                                                                    backgroundColor: "#EEE",
                                                                }}
                                                                className={styles.accordionDetails}
                                                            >
                                                                {object.sub &&
                                                                    Object.entries(object?.sub).map(
                                                                        ([subCategoryKey, subjectDetail]) => {
                                                                            return (
                                                                                <div key={subCategoryKey}>
                                                                                    <SubjectDetailButton
                                                                                        level={props.level}
                                                                                        categoryKey={categoryKey}
                                                                                        subjectDetail={subjectDetail}
                                                                                        subCategoryKey={subCategoryKey}
                                                                                        mobile={true}
                                                                                        handleChildrenCheckboxChange={
                                                                                            handleChildrenCheckboxChange
                                                                                        }
                                                                                        getIsSubCategoryKeyIncluded={
                                                                                            getIsSubCategoryKeyIncluded
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        },
                                                                    )}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </Hidden>
                                </>
                            )}
                            {props.level !== "highSchool" &&
                                Object.entries(props.subjectConfig).map(([categoryKey, object]) => {
                                    return (
                                        <div className={styles.accordionWrapper} key={categoryKey}>
                                            {object.sub && (
                                                <Checkbox
                                                    checked={getIsEveryIncluded(categoryKey)}
                                                    indeterminate={
                                                        getIsSomeIncluded(categoryKey) &&
                                                        !getIsEveryIncluded(categoryKey)
                                                    }
                                                    onChange={(e) => {
                                                        handleParentCheckboxChange(e, categoryKey);
                                                    }}
                                                    className={styles.checkbox}
                                                />
                                            )}
                                            <Accordion
                                                square
                                                expanded={expanded === categoryKey}
                                                onChange={handleChange(categoryKey)}
                                                className={styles.accordion}
                                                style={{
                                                    margin: "0",
                                                    breakBefore: categoryKey === "physics" ? "column" : "auto",
                                                    boxShadow: "none",
                                                }}
                                            >
                                                <AccordionSummary
                                                    aria-controls="panel1d-content"
                                                    id="panel1d-header"
                                                    style={{
                                                        // paddingRight: props.level === "elementarySchool" ? "20px" : 0,
                                                        marginLeft: object.sub ? "25px" : 0,
                                                    }}
                                                    className={styles.accordionSummary}
                                                >
                                                    {object.sub ? (
                                                        <>
                                                            {object.icon}
                                                            <span className={styles.label}>{object.label}</span>
                                                            {object.sub && (
                                                                <VscTriangleDown className={styles.triangle} />
                                                            )}
                                                        </>
                                                    ) : (
                                                        <FormControlLabel
                                                            label={
                                                                <div
                                                                    className={styles.flexLabel}
                                                                    style={{ marginLeft: "4px" }}
                                                                >
                                                                    {object.icon}
                                                                    <span className={styles.label}>{object.label}</span>
                                                                </div>
                                                            }
                                                            labelPlacement="end"
                                                            className={styles.formControlLabel}
                                                            control={
                                                                <Checkbox
                                                                    checked={getIsEveryIncluded(categoryKey)}
                                                                    indeterminate={
                                                                        getIsSomeIncluded(categoryKey) &&
                                                                        !getIsEveryIncluded(categoryKey)
                                                                    }
                                                                    onChange={(e) => {
                                                                        handleParentCheckboxChange(e, categoryKey);
                                                                    }}
                                                                    className={styles.checkbox}
                                                                />
                                                            }
                                                        />
                                                    )}
                                                </AccordionSummary>
                                                <AccordionDetails
                                                    style={{
                                                        display: "block",
                                                        padding: "0",
                                                        backgroundColor: "#EEE",
                                                    }}
                                                    className={styles.accordionDetails}
                                                >
                                                    {object.sub &&
                                                        Object.entries(object?.sub).map(
                                                            ([subCategoryKey, subjectDetail]) => {
                                                                return (
                                                                    <div key={subCategoryKey}>
                                                                        <SubjectDetailButton
                                                                            level={props.level}
                                                                            categoryKey={categoryKey}
                                                                            subjectDetail={subjectDetail}
                                                                            subCategoryKey={subCategoryKey}
                                                                            mobile={true}
                                                                            handleChildrenCheckboxChange={
                                                                                handleChildrenCheckboxChange
                                                                            }
                                                                            getIsSubCategoryKeyIncluded={
                                                                                getIsSubCategoryKeyIncluded
                                                                            }
                                                                        />
                                                                    </div>
                                                                );
                                                            },
                                                        )}
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    );
                                })}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </Hidden>
            {/* PC */}
            <Hidden smDown>
                <div className={styles.categoryTitle}>{japaneseSchoolName(props.level)}</div>
                <div
                    className={styles.subjectList}
                    style={{ display: props.level === "highSchool" ? "flex" : "block" }}
                    // style={{ columnCount: japaneseSchoolName(props.level) === "高校" ? 2 : 1 }}
                >
                    {props.level === "highSchool" && (
                        <>
                            {/*  PC版 */}
                            <div className={styles.leftSubjects}>
                                {Object.entries(props.subjectConfig).map(([categoryKey, object], idx) => {
                                    return (
                                        <div key={categoryKey}>
                                            {idx < Object.keys(props.subjectConfig).length / 2 && (
                                                <SubjectContents
                                                    object={object}
                                                    categoryKey={categoryKey}
                                                    level={props.level}
                                                    handleParentCheckboxChange={handleParentCheckboxChange}
                                                    handleChildrenCheckboxChange={handleChildrenCheckboxChange}
                                                    getIsSomeIncluded={getIsSomeIncluded}
                                                    getIsEveryIncluded={getIsEveryIncluded}
                                                    getIsSubCategoryKeyIncluded={getIsSubCategoryKeyIncluded}
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className={styles.rightSubjects}>
                                {Object.entries(props.subjectConfig).map(([categoryKey, object], idx) => {
                                    return (
                                        <div key={categoryKey}>
                                            {idx >= Object.keys(props.subjectConfig).length / 2 && (
                                                <SubjectContents
                                                    object={object}
                                                    categoryKey={categoryKey}
                                                    level={props.level}
                                                    handleParentCheckboxChange={handleParentCheckboxChange}
                                                    handleChildrenCheckboxChange={handleChildrenCheckboxChange}
                                                    getIsSomeIncluded={getIsSomeIncluded}
                                                    getIsEveryIncluded={getIsEveryIncluded}
                                                    getIsSubCategoryKeyIncluded={getIsSubCategoryKeyIncluded}
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                    {Object.entries(props.subjectConfig).map(([categoryKey, object]) => {
                        return (
                            <div key={categoryKey}>
                                {props.level !== "highSchool" && (
                                    <SubjectContents
                                        key={categoryKey}
                                        object={object}
                                        categoryKey={categoryKey}
                                        level={props.level}
                                        handleParentCheckboxChange={handleParentCheckboxChange}
                                        handleChildrenCheckboxChange={handleChildrenCheckboxChange}
                                        getIsSomeIncluded={getIsSomeIncluded}
                                        getIsEveryIncluded={getIsEveryIncluded}
                                        getIsSubCategoryKeyIncluded={getIsSubCategoryKeyIncluded}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            </Hidden>
        </div>
    );
});
