import { memo, useCallback, useState } from "react";
import { SubjectsComponent } from "@/components/SubjectsComponent";
import { Mandatory } from "@/components/Tag/Mandatory";
import styles from "@/pages/Teacher/InfoInput/index.module.scss";
import { SubjectResponse } from "@/store/autogenApi";
import {
    ELEMENTARY_SCHOOL_SUBJECT_CONFIG,
    HIGH_SCHOOL_SUBJECT_CONFIG,
    JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG,
    UNIVERSITY_SUBJECT_CONFIG,
} from "@/SubjectConfig";

interface Props {
    open?: boolean;
    checked: boolean;
    teachableSubjects: SubjectResponse[];
    setTeachableSubjects: React.Dispatch<React.SetStateAction<SubjectResponse[]>>;
}

export interface Open {
    level: string;
    subject: string;
}

export const TeachableSubjectsComponent: React.VFC<Props> = memo(function TeachableSubjectsComponent(props) {
    const getConfig = useCallback((level: string) => {
        if (level === "university") {
            return UNIVERSITY_SUBJECT_CONFIG;
        } else if (level === "highSchool") {
            return HIGH_SCHOOL_SUBJECT_CONFIG;
        } else if (level === "juniorHighSchool") {
            return JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG;
        } else if (level === "elementarySchool") {
            return ELEMENTARY_SCHOOL_SUBJECT_CONFIG;
        } else {
            return "";
        }
    }, []);

    const getSchool = useCallback((school: string) => {
        if (school === "university") {
            return "大学・社会人等";
        } else if (school === "highSchool") {
            return "高校";
        } else if (school === "juniorHighSchool") {
            return "中学校";
        } else {
            return "小学校";
        }
    }, []);

    return (
        <div className={styles.inputItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitleWrapper}>
                    <div className={styles.itemTitle}>指導可能分野（複数選択可）</div>
                    {!props.open && <Mandatory />}
                </div>
            </div>
            <div className={styles.rightWrapper} style={{ padding: "0" }}>
                {props.open ? (
                    <div className={styles.openTrue}>
                        {props.teachableSubjects.length > 0 ? (
                            <ul className={styles.openSubjectList}>
                                {props.teachableSubjects.map((subject, idx) => {
                                    const config = subject.level ? getConfig(subject.level) : "";
                                    if (config) {
                                        const subjectInfo = subject.category ? config[subject.category] : "";
                                        const subCategoryKey = subject.subCategory;
                                        if (!subjectInfo) return;
                                        if (!subjectInfo.sub || !subCategoryKey) {
                                            return (
                                                <li key={idx} className={styles.openListItem}>
                                                    {subject.level && getSchool(subject.level)} - {subjectInfo.label}
                                                </li>
                                            );
                                        }
                                        return (
                                            <li key={idx} className={styles.openListItem}>
                                                {subject.level && getSchool(subject.level)} - {subjectInfo.label} -{" "}
                                                {subjectInfo.sub[subCategoryKey].label}
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                        ) : (
                            <div className={styles.emptySubjects}>選択なし</div>
                        )}
                    </div>
                ) : (
                    <SubjectsComponent
                        checked={props.checked}
                        subjects={props.teachableSubjects}
                        setSubjects={props.setTeachableSubjects}
                    />
                )}
            </div>
        </div>
    );
});
