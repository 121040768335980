import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { memo, useCallback } from "react";
import { Mandatory } from "@/components/Tag/Mandatory";
import styles from "@/pages/Teacher/MyPage/index.module.scss";
import { None } from "../Errors/None";

interface Props {
    value: string | undefined;
    checked: boolean;
    edit: boolean;
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

export const GenderComponent: React.VFC<Props> = memo(function Gender(props) {
    const getJapaneseGender = useCallback(() => {
        switch (props.value) {
            case "male":
                return "男性";
            case "female":
                return "女性";
            case "other":
                return "その他";
            case "noAnswer":
                return "回答しない";
        }
    }, [props.value]);
    return (
        <>
            {props.edit ? (
                <li className={styles.editListItem}>
                    <div className={styles.leftWrapper}>
                        <div className={styles.titleWrapper}>
                            <div className={styles.title}>性別</div>
                            <Mandatory />
                        </div>
                    </div>
                    <div className={styles.rightWrapper}>
                        <Select
                            variant="outlined"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="gender"
                            value={props.value}
                            onChange={props.handleChange}
                            className={styles.select}
                        >
                            <MenuItem value="male">男性</MenuItem>
                            <MenuItem value="female">女性</MenuItem>
                            <MenuItem value="other">その他</MenuItem>
                            <MenuItem value="noAnswer">回答しない</MenuItem>
                        </Select>
                        <None value={props.value} checked={props.checked} />
                    </div>
                </li>
            ) : (
                <li className={styles.profileItem} style={{ borderBottom: props.edit ? "" : "#305077 1px solid" }}>
                    <div className={styles.profileTitle}>性別</div>
                    <div className={styles.profileSpace}></div>
                    <div className={styles.profileValue}>{getJapaneseGender()}</div>
                </li>
            )}
        </>
    );
});
