import { memo, useCallback } from "react";
import { MuiThemeProvider, ThemeOptions, createTheme } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DefaultTheme } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import jaLocale from "date-fns/locale/ja";

interface Props {
    date: Date | undefined;
    handleDateChange: (date: Date) => void;
    getIsDisabledDate?: (date: MaterialUiPickersDate) => boolean;
}

const materialTheme: Partial<DefaultTheme> | ((outerTheme: DefaultTheme) => DefaultTheme) = createTheme({
    overrides: {
        MuiFormControl: {
            marginNormal: {
                margin: 0,
            },
        },
        MuiInput: {
            underline: {
                borderBottom: "none",
            },
        },
        MuiPickersDatePickerRoot: {
            toolbar: {
                backgroundColor: "#305077",
            },
        },
        MuiPickersDay: {
            daySelected: {
                backgroundColor: "#305077",
                "&:hover": {
                    backgroundColor: "#305077",
                },
            },
        },
        MuiButton: {
            textPrimary: {
                color: "#305077",
            },
        },
    },
} as ThemeOptions);

class ExtendedUtils extends DateFnsUtils {
    getCalendarHeaderText(date: Date) {
        return format(date, "yyyy MMM", { locale: this.locale });
    }
    getDatePickerHeaderText(date: Date) {
        return format(date, "MMMd日", { locale: this.locale });
    }
}

export const DatePicker: React.VFC<Props> = memo((props) => {
    const getIsDisabledDate = useCallback((date: MaterialUiPickersDate) => {
        if (!date) return true;
        const fixedDate = date as any as Date;
        const today = new Date();
        const isPast = today.getTime() >= fixedDate.getTime();
        return isPast;
    }, []);
    return (
        <MuiThemeProvider theme={materialTheme}>
            <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
                <KeyboardDatePicker
                    margin="normal"
                    label=""
                    format="MM月dd日(EEE)"
                    value={props.date ?? null}
                    onChange={(date: MaterialUiPickersDate) => {
                        props.handleDateChange(date as any as Date);
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    okLabel="決定"
                    cancelLabel="キャンセル"
                    InputProps={{ readOnly: true, disableUnderline: true }}
                    shouldDisableDate={props.getIsDisabledDate ?? getIsDisabledDate}
                    placeholder="日付を選択"
                />
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    );
});
