import { memo, useCallback, useEffect } from "react";
import { MenuItem } from "@material-ui/core";
import styles from "@/pages/Teacher/CreateCourse/index.module.scss";
import { Mandatory } from "@/components/Tag/Mandatory";
import { ErrorMessage } from "@/components/ErrorMessage";
import { StyledSelect } from "@/components/StyledSelect";

interface Props {
    minParticipants: number;
    maxParticipants: number;
    isChecked: boolean;
    open?: boolean;
    validation: boolean;
    existStudent?: boolean;
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

export const MinNumber: React.VFC<Props> = memo(function MinNumber(props) {
    const fixPlaceholder = useCallback(() => {
        const element: HTMLInputElement | null = document.getElementById("remarksTextArea") as HTMLInputElement;
        if (element) {
            element.placeholder = element.placeholder.replace(/\\n/g, "\n");
        }
    }, []);

    useEffect(() => {
        fixPlaceholder();
    }, []);

    return (
        <li className={styles.listItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>開講に必要な人数</div>
                    {!props.open && <Mandatory />}
                </div>
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>{props.minParticipants}人</div>
                ) : (
                    <>
                        {props.existStudent ? (
                            <div className={styles.existStudentWrapper}>
                                <div className={styles.existStudent}>
                                    既に参加者がいるため、この項目は変更できません。
                                </div>
                                <div className={styles.existingValue}>{props.minParticipants}人</div>
                            </div>
                        ) : (
                            <>
                                <div className={styles.selectWrapper}>
                                    <StyledSelect
                                        name="minParticipants"
                                        value={props.minParticipants ? props.minParticipants : 0}
                                        handleChange={props.handleChange}
                                    >
                                        <MenuItem key={0} value={0} className={`${styles.numberItem} ${styles.zero}`} />
                                        {[...Array(10)].map((_, i) => (
                                            <MenuItem key={i + 1} value={i + 1} className={styles.numberItem}>
                                                {i + 1}
                                            </MenuItem>
                                        ))}
                                    </StyledSelect>
                                    人
                                </div>
                                <ErrorMessage
                                    content="この項目は必須です。選択してください。"
                                    when={props.isChecked && !props.validation}
                                />
                                <ErrorMessage
                                    content="最大募集人数以下にしてください。"
                                    when={props.isChecked && props.maxParticipants < props.minParticipants}
                                />
                                <div className={styles.description}>
                                    ※募集締め切り時にこの人数が集まっていない場合、開講されません。
                                    <br />
                                    1対1で授業を行いたい場合は「1」を選択してください。
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </li>
    );
});
