import { Button } from "@material-ui/core";
import { BsCheckCircleFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "@/ducks";

import styles from "@/pages/Common/CourseDetails/index.module.scss";
import { UserType } from "@/protectedRouter/ProtectedRouterConfig";
import { ClassResponse, PrivateRequestResponse } from "@/store/autogenApi";
import { PrivateRequestApplication } from "../../PrivateRequestApplication";
import { Favorite } from "../Favorite";

interface Props {
    frequency?: string;
    courseId: string;
    title?: string;
    isFavorite: boolean;
    isBeforeDeadline: boolean;
    isHostTeacher: boolean;
    userMode: UserType;
    addScheduleModalOpen: boolean;
    classes: ClassResponse[];
    fixedContentsEl: React.MutableRefObject<HTMLElement | null>;
    privateRequestModalOpen: boolean;
    courseTeacherId: string;
    isReservationButtonClicked: boolean;
    privateRequest: PrivateRequestResponse | undefined;
    thresholdOver: boolean;
    activeClass: ClassResponse | undefined;
    reservedClass: ClassResponse | undefined;
    getJapaneseDay: (idx: number) => string;
    getDiffTime: (date: Date) => string;
    handleReservationButtonClick: (classId: string) => void;
    handleAddScheduleModalClose: () => void;
    handleAddScheduleModalOpenButtonClick: () => void;
    handleClassButtonClick: (classId: string) => void;
    handlePrivateRequestModalClose: () => void;
    handleSignUpModalOpen: () => void;
    initializeReservationButtonClicked: () => void;
}

export const PCContents: React.VFC<Props> = (props) => {
    const studentId = useSelector((state: RootState) => state.jwt.studentId);
    return (
        <div
            className={props.thresholdOver ? `${styles.fixedContents} ${styles.aaa}` : styles.fixedContents}
            ref={(node: HTMLElement | null) => {
                props.fixedContentsEl.current = node;
            }}
            id="fixedContents"
        >
            <div className={styles.reservationWrapper}>
                {props.isHostTeacher ? (
                    <div className={styles.yourWrapper}>
                        <div className={styles.yourCourse}>あなたが作成した講座です。</div>
                        <Button
                            className={styles.courseManagementButton}
                            component={Link}
                            to={`/CourseManagement/${props.courseId}`}
                        >
                            講座管理
                        </Button>
                    </div>
                ) : (
                    <>
                        {props.reservedClass ? (
                            <>
                                <div
                                    className={styles.reservedWrapper}
                                    style={{ marginTop: props.activeClass ? "20px" : "0" }}
                                >
                                    <BsCheckCircleFill className={styles.checkIcon} />
                                    <div className={styles.reserved}>依頼済み</div>
                                </div>
                                <Button
                                    className={styles.classButton}
                                    variant="contained"
                                    onClick={() => {
                                        if (!props.reservedClass) return;
                                        props.handleClassButtonClick(props.reservedClass.classId);
                                    }}
                                >
                                    授業管理
                                </Button>
                            </>
                        ) : (
                            <>
                                {props.activeClass ? (
                                    <>
                                        {props.activeClass?.status === "recruiting" &&
                                            props.activeClass.applyingDeadline && (
                                                <div className={styles.deadlineWrapper}>
                                                    <div className={styles.deadlineTitle}>締め切りまでの残り時間</div>
                                                    <div className={styles.deadline}>
                                                        {props.getDiffTime(
                                                            new Date(props.activeClass.applyingDeadline),
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        {props.activeClass?.pricePerHour && (
                                            <div className={styles.priceWrapper}>
                                                <div className={styles.priceTitle}>価格</div>
                                                <div className={styles.price}>{`￥${new Intl.NumberFormat(
                                                    "ja-JP",
                                                ).format(props.activeClass.pricePerHour)}`}</div>
                                            </div>
                                        )}
                                        {props.userMode !== "teacher" && (
                                            <PrivateRequestApplication
                                                privateRequest={props.privateRequest}
                                                classId={props.activeClass.classId}
                                                handleReservationButtonClick={props.handleReservationButtonClick}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <div className={styles.noOpeningForStudent}>現在、受講者の募集はありません。</div>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
            <Favorite
                userMode={props.userMode}
                isReservationButtonClicked={props.isReservationButtonClicked}
                handleSignUpModalOpen={props.handleSignUpModalOpen}
                initializeReservationButtonClicked={props.initializeReservationButtonClicked}
            />
        </div>
    );
};
