import {
    Button,
    // Button,
    Modal,
    // TextField
} from "@material-ui/core";

import styles from "@/pages/Common/Inquiry/index.module.scss";
import { InquiryFormItem } from "./InquiryFormItem";
import { Processing } from "@/components/Processing";

// import googleButton from "./images/googleButton.png";

interface Props {
    open: boolean;
    formElement: HTMLFormElement;
    isProcessing: boolean;
    handleClose: () => void;
    handleSubmitButtonClick: () => void;
}

export const InquiryConfirmationModal: React.VFC<Props> = (props) => {
    return (
        <Modal open={props.open} onClose={props.handleClose}>
            <div className={styles.inquiryConfirmationModalContents}>
                <div className={styles.message}>以下の内容で送信してよろしいですか？</div>
                <ul className={styles.items}>
                    <InquiryFormItem
                        label="お問い合わせ種別"
                        value={props.formElement.inquiryType.value}
                        borderBottom
                    />
                    <InquiryFormItem label="お名前" value={props.formElement.userName.value} borderBottom />
                    <InquiryFormItem label="メールアドレス" value={props.formElement.userEmail.value} borderBottom />
                    <InquiryFormItem label="メッセージ" value={props.formElement.message.value} />
                </ul>
                <div className={styles.buttonsWrapper}>
                    <Button className={styles.backButton} onClick={props.handleClose}>
                        閉じる
                    </Button>
                    <Button className={styles.chargeButton} onClick={props.handleSubmitButtonClick}>
                        {props.isProcessing ? <Processing /> : "送信"}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
