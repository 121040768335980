import styles from "@/pages/Common/AboutRequest/index.module.scss";
import { PublicRequestResponse } from "@/store/autogenApi";

import { RequestItem } from "./RequestItem";

interface Props {
    requests: PublicRequestResponse[];
}

export const ExamplesContents: React.VFC<Props> = (props) => {
    const settings = {
        arrows: false,
        dots: true,
        infinite: true,
        speed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        swipeToSlide: true,
        autoplaySpeed: 10000,
    };
    return (
        <div className={styles.reviewsWrapper}>
            <div className={styles.reviewsTitle}>講座リクエストの実例をチェック！</div>
            <div className={styles.sliderWrapper}>
                {/* <Slider {...settings} className={styles.slider}>
                    {props.requests.map((publicRequest, idx) => {
                        return <RequestItem publicRequest={publicRequest} key={idx} />;
                    })}
                </Slider> */}
            </div>
        </div>
    );
};
