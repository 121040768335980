import { useGetPresignedUrlForGet } from "@/store/hooks/files";

interface Props {
    objectKey: string;
    children: (url: string) => React.ReactNode;
}

export const S3ImageLoadingWrapper: React.VFC<Props> = (props) => {
    const preSignedUrlQueryState = useGetPresignedUrlForGet(props.objectKey);
    return preSignedUrlQueryState.isSuccess && preSignedUrlQueryState.data !== undefined ? (
        <>{props.children(preSignedUrlQueryState.data.preSignedUrl)}</>
    ) : null;
};
