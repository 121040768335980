import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { memo } from "react";

import styles from "./index.module.scss";

interface Props {
    level: string;
    subjectDetail: SubjectDetail;
    subCategoryKey: string;
    categoryKey: string;
    mobile?: boolean;
    handleChildrenCheckboxChange: (
        e: React.ChangeEvent<HTMLInputElement>,
        category: string,
        subCategory: string,
    ) => void;
    getIsSubCategoryKeyIncluded: (subCategoryKey: string) => boolean;
}

interface SubjectDetail {
    label: string;
    icon: JSX.Element;
}

export const SubjectDetailButton: React.VFC<Props> = memo(function SubjectDetailsButton(props) {
    return (
        <Button
            key={props.subCategoryKey}
            className={styles.subjectDetailButton}
            style={{
                fontWeight: "bold",
            }}
        >
            <FormControlLabel
                label={
                    <div className={styles.flexLabel}>
                        {props.subjectDetail.icon}
                        <div className={styles.title} style={{ marginLeft: "10px", textAlign: "left" }}>
                            {props.subjectDetail.label}
                        </div>
                    </div>
                }
                labelPlacement={props.mobile ? "end" : "start"}
                className={styles.formControlLabel}
                control={
                    <Checkbox
                        checked={props.getIsSubCategoryKeyIncluded(props.subCategoryKey)}
                        onChange={(e) => {
                            props.handleChildrenCheckboxChange(e, props.categoryKey, props.subCategoryKey);
                        }}
                        className={styles.checkbox}
                    />
                }
            />
        </Button>
    );
});
