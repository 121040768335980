import { memo } from "react";
import styles from "./index.module.scss";
import { ImageFromS3 } from "@/components/atoms/images/ImageFromS3";

interface Props {
    url?: string;
    objectKey?: string;
    handleClick?: () => void;
}

export const SlideContents: React.VFC<Props> = memo(function SlideContents(props) {
    return (
        <div className={styles.mainImageWrapper}>
            <ImageFromS3 url={props.url} objectKey={props.objectKey} className={styles.image} />
        </div>
    );
});
