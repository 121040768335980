import { useSelector } from "react-redux";
import { RootState } from "@/ducks";

import { useGetTeacherToDosByTeacherIdQuery } from "@/store/hooks/teacherToDos";
import { QueryLoadingWrapper } from "../../../QueryLoadingWrapper";
import { ToDosTooltip } from "../ToDosTooltip";

import { TeacherToDoListContents } from "./TeacherToDoListContents";

export const TeacherToDoList: React.VFC = () => {
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId) as string;
    const teacherToDosQuery = useGetTeacherToDosByTeacherIdQuery(teacherId);

    return (
        <QueryLoadingWrapper
            {...teacherToDosQuery}
            loadingComponent={
                <ToDosTooltip
                    open={false}
                    title={<></>}
                    badgeContent={0}
                    handleTooltipOpen={() => {}}
                    handleTooltipClose={() => {}}
                    handleCheckButtonClick={() => {}}
                />
            }
        >
            {(teacherToDos) => <TeacherToDoListContents teacherToDos={teacherToDos} teacherId={teacherId} />}
        </QueryLoadingWrapper>
    );
};
