import { useState, useEffect, memo } from "react";
import { Button } from "@material-ui/core";
import styles from "@/pages/Common/CourseDetails/index.module.scss";

interface Props {
    teachingMaterial: string;
}

export const TeachingMaterial: React.VFC<Props> = memo(function TeachingMaterial(props) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOver, setIsOver] = useState<boolean>(false);

    const showMore = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const teachingMaterialNode = document.getElementById("teachingMaterial");
        const scrollHeight = teachingMaterialNode?.scrollHeight;
        if (scrollHeight && scrollHeight > 200) {
            setIsOver(true);
        }
    }, [props.teachingMaterial]);

    return (
        <div className={styles.itemWrapper}>
            <div className={styles.itemHeadingWrapper}>
                <span className={styles.itemHeading}>教材・必要な準備</span>
            </div>
            <div
                className={styles.item}
                style={{ overflowY: isOpen ? "auto" : "hidden", maxHeight: isOpen ? "none" : "200px" }}
                id="teachingMaterial"
            >
                {props.teachingMaterial}
            </div>
            {isOver && (
                <div className={styles.moreButtonWrapper} style={{ position: isOpen ? "inherit" : "absolute" }}>
                    <Button className={styles.moreButton} onClick={showMore}>
                        {isOpen ? "閉じる" : "すべて見る"}
                    </Button>
                </div>
            )}
        </div>
    );
});
