import styles from "@/pages/Common/AboutStudent/index.module.scss";

interface Props {
    children: React.ReactNode;
    hidden: boolean;
}

export const TabPanel: React.VFC<Props> = (props) => {
    if (props.hidden) return <></>;
    return (
        <div role="tabpanel" className={styles.tabPanel}>
            {<div className={styles.tabPanelContents}>{props.children}</div>}
        </div>
    );
};
