export interface ExternalService {
    serviceName: string;
    serviceLink: string;
    destinationName: string;
    destinationLink: string;
    infoExamples: string[];
    privacyPolicy: string;
    optOutText?: string;
    optOutNormalLink?: string;
    optOutRouterLink?: string;
    optOutAdditionalText?: string;
    omittable?: boolean;
}

export const ANALYTICS_SERVICES: ExternalService[] = [
    {
        serviceName: "Google Analytics",
        serviceLink: "https://marketingplatform.google.com/intl/ja/about/analytics/",
        destinationName: "Google LLC",
        destinationLink: "https://about.google/",
        infoExamples: [
            "クライアントID",
            "IPアドレス",
            "閲覧ページURL",
            "リファラー",
            "デバイスタイプ",
            "オペレーティングシステム",
            "ブラウザタイプ",
            "利用言語",
            "画像解像度",
        ],
        privacyPolicy: "https://policies.google.com/privacy",
        optOutText: "リンク",
        optOutNormalLink: "https://tools.google.com/dlpage/gaoptout/",
        omittable: true,
    },
    {
        serviceName: "Google Search Console",
        serviceLink: "https://search.google.com/search-console/about?hl=ja",
        destinationName: "Google LLC",
        destinationLink: "https://about.google/",
        infoExamples: [
            "クライアントID",
            "サイト利用者の当社ウェブサイトでの行動履歴",
            "サイト利用者の当社ウェブサイトの閲覧履歴",
            "サイト利用者の属性情報",
            "サイト利用者の位置情報",
        ],
        privacyPolicy: "https://policies.google.com/privacy",
        optOutText: "リンク",
        optOutNormalLink: "https://tools.google.com/dlpage/gaoptout/",
        omittable: true,
    },
];
