import { Checkbox, createTheme, MuiThemeProvider } from "@material-ui/core";
import { memo } from "react";

import styles from "./index.module.scss";

interface Props {
    title: string;
    checked: boolean;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const theme = createTheme({
    overrides: {
        MuiCheckbox: {
            root: {
                padding: "5px",
                "&:hover": {
                    backgroundColor: "#e1edfb",
                },
            },
            colorSecondary: {
                "&$checked": {
                    color: "#305077",
                },
                "&:hover": {
                    backgroundColor: "#e1edfb !important",
                },
            },
        },
        MuiIconButton: {
            colorSecondary: {
                color: "#305077",
            },
        },
        MuiSwitch: {
            switchBase: {},
            colorSecondary: {
                "&$checked": {
                    color: "#fafafa",
                    "& + .MuiSwitch-track": {
                        opacity: 1,
                        backgroundColor: "#305077 !important",
                    },
                },
            },
        },
    },
});

export const NotificationsItem: React.VFC<Props> = memo(function NotificationsItem(props) {
    return (
        <MuiThemeProvider theme={theme}>
            <div className={styles.items}>
                <div className={styles.item}>
                    <Checkbox
                        checked={props.checked}
                        onChange={props.handleChange}
                        size="small"
                        className={styles.checkbox}
                    />
                    <div className={styles.itemTitle}>{props.title}</div>
                </div>
            </div>
        </MuiThemeProvider>
    );
});
