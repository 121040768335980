import { createTheme, MuiThemeProvider, TextareaAutosize, TextField } from "@material-ui/core";
import { memo } from "react";
import { ErrorMessage } from "@/components/ErrorMessage";

import styles from "./index.module.scss";

interface Props {
    isRequestChecked: boolean;
    value: string;
    handleChange?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

export const LastDateDescription: React.VFC<Props> = memo(function LastDateDescription(props) {
    const theme = createTheme({
        overrides: {
            MuiOutlinedInput: {
                input: {
                    padding: "10px",
                    color: "#305077",
                    fontSize: "14px",
                    "@media (max-width: 600px)": {
                        fontSize: "12px",
                    },
                    "&::placeholder": {
                        color: "#305077",
                        fontSize: "14px",
                        "@media (max-width: 600px)": {
                            fontSize: "12px",
                        },
                    },
                },
            },
            MuiFormControl: {
                marginNormal: {
                    marginTop: "0",
                    marginBottom: "0",
                },
            },
        },
    });

    return (
        <MuiThemeProvider theme={theme}>
            <div className={styles.textAreaWrapper}>
                <TextareaAutosize
                    className={styles.textArea}
                    value={props.value}
                    placeholder={`(任意・500文字以内)\n\n例）\n受験が終わる〇〇〇〇年3月頃まで授業をお願いします。\n`}
                    onChange={props.handleChange}
                    maxRows={7}
                    minRows={7}
                />
                <div className={styles.count}>{props.value.length}/500文字</div>
                <ErrorMessage
                    content="入力する場合、500文字以内で入力してください。"
                    when={props.isRequestChecked && props.value.length > 500}
                />
            </div>
        </MuiThemeProvider>
    );
});
