import { Button } from "@material-ui/core";
import { memo, useCallback, useState } from "react";
import { RiMessage2Line } from "react-icons/ri";
// import { MessagesModal } from "@/components/ChatModal";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import styles from "@/pages/Common/CourseDetails/index.module.scss";
import { PublicTeacherResponse } from "@/store/autogenApi";
import { useGetPublicStudentQuery } from "@/store/hooks/students";
import { ChatModal } from "@/components/ChatModal";

interface Props {
    studentId: string;
    teacher: PublicTeacherResponse;
}

export const MessageComponent: React.VFC<Props> = memo(function MessageComponent(props) {
    const [open, setOpen] = useState<boolean>(false);
    const publicStudentQueryState = useGetPublicStudentQuery(props.studentId);
    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);
    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);
    return (
        <>
            <Button className={styles.messageButton} onClick={handleOpen}>
                <RiMessage2Line className={styles.messageIcon} />
                <div className={styles.message}>チャット</div>
            </Button>
            <QueryLoadingWrapper {...publicStudentQueryState}>
                {(publicStudent) => (
                    <>
                        {open && (
                            <ChatModal
                                handleClose={handleClose}
                                teacherId={props.teacher.teacherId}
                                studentId={publicStudent.studentId}
                            />
                        )}
                    </>
                )}
            </QueryLoadingWrapper>
        </>
    );
});
