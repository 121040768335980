import { memo } from "react";

import styles from "../index.module.scss";
import { EmailSetting } from "../Target/EmailSetting";
import { PasswordSetting } from "../Target/PasswordSetting";

interface Props {}

export const AccountSetting: React.VFC<Props> = memo(function AccountSetting(props) {
    return (
        <div className={styles.settingTargetsWrapper}>
            <div className={styles.settingTargetsTitle}>アカウント情報</div>
            <div className={styles.settingTargets}>
                <EmailSetting />
                <PasswordSetting />
            </div>
        </div>
    );
});
