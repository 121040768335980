import React from "react";
import { Avatar } from "@material-ui/core";
import { AvatarFromObjectKey } from "./AvatarFromObjectKey";

interface Props {
    url?: string;
    objectKey?: string;
    className?: string;
    title?: string;
    ref?: (node: HTMLImageElement | null) => void;
}

export const AvatarFromS3: React.VFC<Props> = ({ url, objectKey, className, title, ref }) => {
    return url ? (
        <Avatar src={url} className={className} ref={ref} title={title} alt="プロフィール画像" />
    ) : objectKey ? (
        <AvatarFromObjectKey objectKey={objectKey} className={className} title={title} ref={ref} />
    ) : (
        <Avatar className={className} ref={ref} title={title} alt="プロフィール画像（デフォルト）" />
    );
};
