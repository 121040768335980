import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import styles from "./index.module.scss";

import { DraftPublicRequestResponse } from "@/store/autogenApi";
import { getPrice, getStatus } from "@/utils/DraftPublicRequestUtils";

interface Props {
    draftPublicRequest?: DraftPublicRequestResponse;
    isHidden?: boolean;
    isFlex?: boolean;
    handleDraftPublicRequestCardClick: (draftPublicRequest: DraftPublicRequestResponse) => void;
}

export const MyDraftPublicRequestCard: React.VFC<Props> = (props) => {
    if (!props.draftPublicRequest) {
        return (
            <Card
                className={styles.card}
                style={{
                    visibility: props.isHidden ? "hidden" : "visible",
                    flex: props.isFlex ? 1 : "none",
                    width: props.isFlex ? "auto" : "150px",
                }}
            />
        );
    }
    return (
        <Card
            className={styles.card}
            style={{
                visibility: props.isHidden ? "hidden" : "visible",
                flex: props.isFlex ? 1 : "none",
                width: props.isFlex ? "auto" : "150px",
            }}
            onClick={() => {
                props.handleDraftPublicRequestCardClick(props.draftPublicRequest as DraftPublicRequestResponse);
            }}
        >
            <CardContent className={styles.cardContents}>
                <Typography className={styles.title}>{props.draftPublicRequest?.title || "未設定"}</Typography>
                <div className={styles.priceAndHowLong}>
                    <div className={styles.priceWrapper}>
                        <Typography className={styles.price}>
                            <div className={styles.strong}>
                                {getPrice(props.draftPublicRequest as DraftPublicRequestResponse)}
                            </div>
                            /時間
                        </Typography>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};
