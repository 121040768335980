import { Button, useMediaQuery } from "@material-ui/core";
import { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "@/ducks";

import styles from "./index.module.scss";

interface Props {
    howManySubjects: number;
    idx: number;
    level: string;
    category: string;
    subjectDetail: SubjectDetail;
    subCategory: string;
}

interface SubjectDetail {
    label: string;
    icon: JSX.Element;
}

export const SubjectDetailButton: React.VFC<Props> = memo(function SubjectDetailButton(props) {
    const history = useHistory();
    const userMode = useSelector((state: RootState) => state.jwt.userMode);

    const smUp = useMediaQuery("(min-width:960px)");

    const search = useCallback(() => {
        if (userMode === "teacher") {
            history.push(
                `/SearchResults?searchType=publicRequest&keyword=&courseType=&level=${props.level}&category=${props.category}&subCategory=${props.subCategory}&rankNumber=&university=&faculty=&department=&orderBy=latest`,
            );
        } else {
            history.push(
                `/SearchResults?searchType=course&keyword=&courseType=&level=${props.level}&category=${props.category}&subCategory=${props.subCategory}&rankNumber=&university=&faculty=&department=&orderBy=latest`,
            );
        }
    }, [history, props.level, props.category, props.subCategory, userMode]);

    const subjectDetailButtonStyle = {
        display: "block",
    };

    return (
        <Button
            key={props.subCategory}
            onClick={search}
            className={styles.subjectDetailButton}
            style={{
                ...subjectDetailButtonStyle,
                fontWeight: "bold",
            }}
        >
            {props.subjectDetail.icon}
            <div className={styles.title}>{props.subjectDetail.label}</div>
        </Button>
    );
});
