import { memo, useCallback, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Level } from "@/store/autogenApi";
import { SubjectConfig } from "@/SubjectConfig";
import { SubjectContents } from "./SubjectContents";
import {
    UNIVERSITY_SUBJECT_CONFIG,
    HIGH_SCHOOL_SUBJECT_CONFIG,
    JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG,
    ELEMENTARY_SCHOOL_SUBJECT_CONFIG,
} from "@/SubjectConfig";
import { StyledSelect } from "../../StyledSelect";
import { MenuItem } from "@material-ui/core";

interface Props {
    mode: "student" | "teacher";
}

export const PCSearchBySubject: React.VFC<Props> = memo(function PCSearchBySubject(props) {
    const [level, setLevel] = useState<Level>("highSchool");
    const [subjectConfig, setSubjectConfig] = useState<SubjectConfig | undefined>(undefined);

    useEffect(() => {
        if (level === "university") {
            setSubjectConfig(UNIVERSITY_SUBJECT_CONFIG);
        }
        if (level === "highSchool") {
            setSubjectConfig(HIGH_SCHOOL_SUBJECT_CONFIG);
        }
        if (level === "juniorHighSchool") {
            setSubjectConfig(JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG);
        }
        if (level === "elementarySchool") {
            setSubjectConfig(ELEMENTARY_SCHOOL_SUBJECT_CONFIG);
        }
    }, [level]);

    const handleLevelChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
        setLevel(event.target.value as Level);
    }, []);

    return (
        <div className={styles.PCSearchBySubject}>
            <div className={styles.searchBySubjectTitle}>
                分野から{props.mode === "teacher" ? "講座リクエスト" : "講座"}検索
            </div>
            <StyledSelect
                name="subject"
                value={level}
                handleChange={handleLevelChange}
                style={{ width: "fit-content", marginTop: "10px", backgroundColor: "white" }}
                isBold
            >
                <MenuItem value="university">大学生・社会人</MenuItem>
                <MenuItem value="highSchool">高校生</MenuItem>
                <MenuItem value="juniorHighSchool">中学生</MenuItem>
                <MenuItem value="elementarySchool">小学生</MenuItem>
            </StyledSelect>
            {level && (
                <>
                    {subjectConfig && (
                        <div className={styles.subjectList}>
                            <div className={styles.subjectsBlock}>
                                {Object.entries(subjectConfig).map(([categoryKey, object], idx) => {
                                    return (
                                        <>
                                            {idx < Object.keys(subjectConfig).length / 2 && (
                                                <SubjectContents
                                                    key={categoryKey}
                                                    object={object}
                                                    categoryKey={categoryKey}
                                                    level={level}
                                                    mode={props.mode}
                                                />
                                            )}
                                        </>
                                    );
                                })}
                            </div>
                            <div className={styles.subjectsBlock}>
                                {Object.entries(subjectConfig).map(([categoryKey, object], idx) => {
                                    return (
                                        <>
                                            {idx >= Object.keys(subjectConfig).length / 2 && (
                                                <SubjectContents
                                                    key={categoryKey}
                                                    object={object}
                                                    categoryKey={categoryKey}
                                                    level={level}
                                                    mode={props.mode}
                                                />
                                            )}
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
});
