import { FAQDetailsBase } from "../../FAQDetailsBase";

export const ChangeCourseFee: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="講座料金を変更したい"
            text="講座料金の変更は講座管理ページ各クラス詳細の「授業料→更新」から可能です。なお、参加者がいる場合、講座料金の変更はできません。"
        />
    );
};
