import { useHistory } from "react-router-dom";

import styles from "./index.module.scss";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Navigation, Pagination, Autoplay, Virtual, Mousewheel, Keyboard, EffectFade } from "swiper";

import "./index.module.scss";
import "./swiper.css";
import { memo, useCallback, useEffect } from "react";
import { SlideContents } from "./SlideContents";
import { useGetBannersQuery } from "@/store/hooks/banners";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { BannerTargetType, DeviceType } from "@/store/autogenApi";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { useMediaQuery } from "@material-ui/core";

interface Props {
    // carouselWrapperRef: (el: HTMLElement | null) => void;
}

const SWIPER_PROPS = {
    speed: 1000,
    autoplay: {
        delay: 8000,
        disableOnInteraction: false,
    },
    effect: "fade" as any,
    pagination: {
        clickable: true,
    },
    navigation: true,
    slidesPerView: 1,
    spaceBetween: 10,
    loop: true,
    modules: [Navigation, Autoplay, Pagination, EffectFade],
};

export const TopImageCarousel: React.VFC<Props> = memo(function TopImageCarousel(props) {
    useEffect(() => {
        SwiperCore.use([Navigation, Pagination, Autoplay, Virtual, Mousewheel, Keyboard, EffectFade]);
    }, []);

    const smUp = useMediaQuery("(min-width:600px)");
    const mdUp = useMediaQuery("(min-width:960px)");

    const deviceType = smUp ? (mdUp ? "PC" : "tablet") : "SP";

    const userMode = useSelector((state: RootState) => state.jwt.userMode);

    const bannersQueryState = useGetBannersQuery(userMode as BannerTargetType, deviceType as DeviceType);

    const history = useHistory();

    const handleSlideClick = useCallback((linkUrl: string | undefined) => {
        if (!linkUrl) return;
        if (linkUrl.startsWith("http")) {
            window.open(linkUrl, "_blank");
            return;
        }
        history.push(linkUrl);
    }, []);

    return (
        <div className={styles.carouselWrapper}>
            <QueryLoadingWrapper {...bannersQueryState}>
                {(banners) => (
                    <Swiper {...SWIPER_PROPS} className={styles.swiper}>
                        {banners.map((banner) => (
                            <SwiperSlide key={banner.bannerId}>
                                <SlideContents
                                    url={banner.imageUrl}
                                    objectKey={banner.bannerObjectKey}
                                    handleClick={() => {
                                        handleSlideClick(banner.linkUrl);
                                    }}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
            </QueryLoadingWrapper>
        </div>
    );
});
