import { OverWrapper } from "@/components/OverWrapper";
import styles from "@/pages/Common/LP/index.module.scss";

export const Soon: React.VFC = () => {
    return (
        <OverWrapper style={{ backgroundColor: "#FFF", borderBottom: "1px solid #999" }}>
            <div className={styles.soonWrapper}>
                <div className={styles.soonTitle}>Trailなら先生がすぐに見つかる</div>
                <div className={styles.blocks}>
                    <div className={styles.block}>
                        新規登録は
                        <br />
                        <span className={styles.strong}>たった30秒</span>
                        <br />
                        で完了！
                    </div>
                    <div className={styles.block}>
                        <span className={styles.strong}>最短その日</span>
                        <br />
                        のうちに生徒さん
                        <br />
                        とマッチング！
                    </div>
                </div>
            </div>
        </OverWrapper>
    );
};
