import { ReviewResponse } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions } from "./utils/types";

export const useGetReviewsByCourseIdQuery = (courseId: string) => {
    const result = defaultApi.endpoints.getReviewsByCourseId.useQuery({ courseId: courseId });
    return useQueryResult(result, (response: ReviewResponse[]) => response);
};

export const useGetReviewsByStudentIdQuery = (studentId: string) => {
    const result = defaultApi.endpoints.getReviewsByStudentId.useQuery({ studentId: studentId });
    return useQueryResult(result, (response: ReviewResponse[]) => response);
};

export const useGetLatestReviewsQuery = () => {
    const result = defaultApi.endpoints.getLatestReviews.useQuery();
    return useQueryResult(result, (response: ReviewResponse[]) => response);
};

export const useCreateReviewMutation = (options?: UseMutationOptions) => {
    const [createReview] = defaultApi.endpoints.createReview.useMutation();
    return useMutationFunctionWrapper(createReview, options?.errorMessages);
};
