import { MyPage } from ".";
import { memo, useCallback, useEffect, useState } from "react";

import { StudentChatsContents } from "@/pageComponents/Common/MyPage/Chats/StudentChatsContents";
import styles from "@/pageComponents/Common/MyPage/Chats/index.module.scss";

import { SocketInfo } from "@/pageComponents/Common/MyPage/Chats/ChatsContents";
import { io } from "socket.io-client";
import { ChatResponse } from "@/store/autogenApi";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { Navigation } from "@/components/Navigation";

const NAVIGATION_LIST: Navigation[] = [
    { title: "マイページ", url: "/MyPage/Profile" },
    { title: "メッセージ", url: "/MyPage/Chats" },
];
import { getApiUrl } from "@/store/emptyApi";

export const ChatsComponent: React.VFC = memo(function ChatsComponent() {
    const [socketInfos, setSocketInfos] = useState<SocketInfo[]>([]);
    const apiUrl = getApiUrl();

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    useEffect(() => {
        if (socketInfos.length === 0) return;
        return () => {
            const disconnectPromise = socketInfos.map((socketInfo) => {
                console.log("disconnect");
                socketInfo.socket.disconnect();
            });
            Promise.all(disconnectPromise);
        };
    }, [socketInfos]);

    const handleSocketInfosChange = useCallback(
        (chats: ChatResponse[]) => {
            const newSocketInfos = chats.map((chat) => {
                const chatId = chat.chatId;
                const roomId = chatId;
                console.log("Connecting..");
                const socket = io(apiUrl, { query: { roomId } });
                return { socket, chatId } as SocketInfo;
            });
            if (newSocketInfos.length === 0) return;
            setSocketInfos(newSocketInfos);
        },
        [apiUrl],
    );

    return (
        <MyPage title="チャット一覧" activeTab="chats">
            <div className={styles.messagesContentsWrapper}>
                <StudentChatsContents socketInfos={socketInfos} handleSocketInfosChange={handleSocketInfosChange} />
            </div>
        </MyPage>
    );
});
