import { memo, useCallback } from "react";

import styles from "../index.module.scss";
import { ListItem } from ".";
import { ErrorMessage } from "@/components/ErrorMessage";
import { DatePicker } from "@/components/DatePicker";

interface Props {
    startDate: Date | null;
    isChecked: boolean;
    isConfirmMode?: boolean;
    validation: boolean;
    handleChange: (date: Date | null) => void;
}

export const StartDate: React.VFC<Props> = memo(function StartDate(props) {
    const getDate = useCallback(() => {
        if (props.startDate) {
            const time = new Date(props.startDate);
            const year = time.getFullYear();
            const month = time.getMonth() + 1;
            const date = time.getDate();
            const result = `${year}年${month}月${date}日`;
            return result;
        } else {
            return "";
        }
    }, [props.startDate]);

    return (
        <ListItem title="講座開始日" isConfirmMode={!!props.isConfirmMode}>
            <>
                {props.isConfirmMode ? (
                    <div className={styles.isConfirmModeTrue}>{getDate()}</div>
                ) : (
                    <>
                        <div className={styles.startDateWrapper}>
                            <DatePicker date={props.startDate ?? undefined} handleDateChange={props.handleChange} />
                        </div>
                        <ErrorMessage
                            content="この項目は必須です。有効な日付を入力してください。"
                            when={props.isChecked && !props.validation}
                            style={{ marginTop: "5px" }}
                        />
                    </>
                )}
            </>
        </ListItem>
    );
});
