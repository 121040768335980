import { Button, useMediaQuery } from "@material-ui/core";
import { BsCheckCircleFill } from "react-icons/bs";

import styles from "@/pages/Common/CourseDetails/index.module.scss";
import { UserType } from "@/protectedRouter/ProtectedRouterConfig";
import { ClassResponse } from "@/store/autogenApi";

interface Props {
    // shortCourse用加工済み
    activeClasses: ClassResponse[];
    courseType: string;
    studentId: string | null | undefined;
    userMode: UserType;
    isHostTeacher: boolean;
    getDiffTime: (deadline: Date) => string;
    getJapaneseDay: (dayIdx: number) => string;
    handleReservationButtonClick: (classId: string) => void;
    handleClassButtonClick: (classId: string) => void;
}

export const ShortCourseContents: React.VFC<Props> = (props) => {
    const smUp = useMediaQuery("(min-width:600px)");
    return (
        <div className={styles.dateWrapper}>
            <div className={styles.dateHeadingWrapper}>
                <span className={styles.dateHeading}>開講日時</span>
            </div>
            {props.activeClasses.length > 0 ? (
                <>
                    <ul className={styles.dateList}>
                        {props.activeClasses.map((classResponse, idx1) => {
                            const lessons = classResponse.lessons;
                            if (lessons) {
                                const lastEndTime = lessons[lessons?.length - 1].endTime;
                                const now = new Date();
                                if (lessons && lastEndTime && new Date(lastEndTime).getTime() > now.getTime()) {
                                    return (
                                        <li className={styles.dateItem} key={idx1}>
                                            <div className={styles.leftWrapper}>
                                                <div className={styles.period}>
                                                    {lessons.map((lesson, idx) => {
                                                        const schedule = lesson;
                                                        if (idx === 0 && schedule && schedule.startTime) {
                                                            return `${
                                                                new Date(schedule.startTime).getMonth() + 1
                                                            }月${new Date(
                                                                schedule.startTime,
                                                            ).getDate()}日(${props.getJapaneseDay(
                                                                new Date(schedule.startTime).getDay(),
                                                            )}) ${lessons.length > 1 ? "- " : ""}`;
                                                        } else if (
                                                            lessons?.length &&
                                                            idx === lessons?.length - 1 &&
                                                            schedule &&
                                                            schedule.endTime
                                                        ) {
                                                            return `${
                                                                new Date(schedule.endTime).getMonth() + 1
                                                            }月${new Date(
                                                                schedule.endTime,
                                                            ).getDate()}日(${props.getJapaneseDay(
                                                                new Date(schedule.endTime).getDay(),
                                                            )})`;
                                                        } else {
                                                            return "";
                                                        }
                                                    })}
                                                    {`（全${lessons.length}回）`}
                                                </div>
                                                {props.activeClasses && (
                                                    <ul className={styles.shortScheduleList}>
                                                        {lessons.map((lesson, idx2) => {
                                                            if (lesson && lesson.startTime && lesson.endTime) {
                                                                const startTime = new Date(lesson.startTime);
                                                                const endTime = new Date(lesson.endTime);
                                                                return (
                                                                    <li
                                                                        key={idx2}
                                                                        className={
                                                                            lessons && lessons.length === 1
                                                                                ? styles.singleShortScheduleItem
                                                                                : styles.shortScheduleItem
                                                                        }
                                                                    >
                                                                        {lessons && lessons.length > 1 && (
                                                                            <span className={styles.idx}>{`${
                                                                                idx2 + 1
                                                                            }日目`}</span>
                                                                        )}
                                                                        <div className={styles.schedule}>
                                                                            <div className={styles.date}>
                                                                                {`${
                                                                                    startTime.getMonth() + 1
                                                                                }月${startTime.getDate()}日(${props.getJapaneseDay(
                                                                                    startTime.getDay(),
                                                                                )})`}
                                                                            </div>
                                                                            <div className={styles.time}>
                                                                                {`${startTime.getHours()}:${(
                                                                                    "00" + startTime.getMinutes()
                                                                                ).slice(-2)} - ${endTime.getHours()}:${(
                                                                                    "00" + endTime.getMinutes()
                                                                                ).slice(-2)}`}
                                                                            </div>
                                                                        </div>
                                                                        {/* {((lesson.pointsRecordHistory &&
                                                                                props.studentId &&
                                                                                lesson.pointsRecordHistory.some(
                                                                                    (pointsRecordHistory) =>
                                                                                        pointsRecordHistory.studentId ===
                                                                                        props.studentId,
                                                                                )) ||
                                                                                props.isHostTeacher) && (
                                                                                <a
                                                                                    className={styles.roomUrl}
                                                                                    rel="noreferrer"
                                                                                    target="_blank"
                                                                                    href={lesson.roomUrl}
                                                                                >
                                                                                    授業へ進む
                                                                                </a>
                                                                            )} */}
                                                                    </li>
                                                                );
                                                            }
                                                        })}
                                                    </ul>
                                                )}
                                                <div className={styles.bottomWrapper}>
                                                    <div className={styles.diffTimeWrapper}>
                                                        <span className={styles.diffTimeTitle}>
                                                            締め切りまでの残り：
                                                        </span>
                                                        <span className={styles.diffTime}>
                                                            {classResponse.applyingDeadline
                                                                ? props.getDiffTime(
                                                                      new Date(classResponse.applyingDeadline),
                                                                  )
                                                                : ""}
                                                        </span>
                                                    </div>
                                                    <div className={styles.theNumberOfParticipants}>
                                                        参加人数：
                                                        <span className={styles.number}>
                                                            {classResponse.participants
                                                                ? classResponse.participants.length
                                                                : 0}
                                                            /{classResponse.maxParticipants}人
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.flexGrow}></div>
                                            {classResponse.pricePerPayment && (
                                                <div className={styles.priceWrapper}>
                                                    <div className={styles.price}>
                                                        ￥
                                                        {new Intl.NumberFormat("ja-JP").format(
                                                            Math.floor(classResponse.pricePerPayment),
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            {props.userMode != "teacher" && (
                                                <>
                                                    {props.studentId &&
                                                    classResponse.participants
                                                        ?.map((student) => student.studentId.toString())
                                                        .includes(props.studentId) ? (
                                                        <div className={styles.verticalWrapper}>
                                                            <div className={styles.reservedWrapper}>
                                                                <BsCheckCircleFill className={styles.checkIcon} />
                                                                <div className={styles.reserved}>予約済み</div>
                                                            </div>
                                                            <Button
                                                                className={styles.classButton}
                                                                variant="contained"
                                                                onClick={() => {
                                                                    classResponse.classId &&
                                                                        props.handleClassButtonClick(
                                                                            classResponse.classId,
                                                                        );
                                                                }}
                                                            >
                                                                授業管理
                                                            </Button>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {classResponse.maxParticipants &&
                                                            classResponse.maxParticipants <=
                                                                classResponse.participants.length ? (
                                                                <div className={styles.limitMessage}>
                                                                    定員に達しました。
                                                                </div>
                                                            ) : (
                                                                <Button
                                                                    className={styles.reservationButton}
                                                                    variant="contained"
                                                                    onClick={() => {
                                                                        props.handleReservationButtonClick(
                                                                            classResponse.classId,
                                                                        );
                                                                    }}
                                                                >
                                                                    予約へ
                                                                    {!smUp && <br />}
                                                                    進む
                                                                </Button>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </li>
                                    );
                                }
                            }
                        })}
                    </ul>
                </>
            ) : (
                <div className={styles.scheduleIsNothing}>現在、受講者募集中のクラスはありません。</div>
            )}
        </div>
    );
};
