import styles from "@/pages/Common/LP/index.module.scss";
import { AboutStudentTopContents } from "./Top";
import { WhatsTrail } from "./WhatsTrail";
import { EasyToStart } from "./EasyToStart";
import { ThreeStepsSignUp } from "./ThreeStepsSignUp";
import { Achievements } from "./Achievements";
import { Background } from "./Background";
import { PostScript } from "./PostScript";
import backgroundImage from "./img/background.jpg";
import { OverWrapper } from "@/components/OverWrapper";
import { Teachers } from "./Teachers";
import { useMediaQuery } from "@mui/material";

export const AboutTrailContents: React.VFC = () => {
    const smUp = useMediaQuery("(min-width:600px)");
    const mdUp = useMediaQuery("(min-width:960px)");
    return (
        <OverWrapper
            className={styles.aboutContentsWrapper}
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundRepeat: "repeat",
                backgroundPosition: "center",
                margin: mdUp ? "0 auto" : smUp ? "0 -16px" : "0 -10px",
                padding: mdUp ? "0 100px 30px" : smUp ? "0 16px" : "0 10px",
                width: mdUp ? "calc(100% - 200px)" : "100%",
            }}
        >
            <AboutStudentTopContents />
            <WhatsTrail />
            <Teachers />
            <EasyToStart />
            <Background />
            <ThreeStepsSignUp />
            <Achievements />
            {/* <Soon /> */}
            <PostScript />
        </OverWrapper>
    );
};
