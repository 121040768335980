import { memo } from "react";
import styles from "./index.module.scss";

interface Props {
    children: React.ReactNode;
    hidden: boolean;
}

export const TabPanel: React.VFC<Props> = memo(function TabPanel(props) {
    return (
        <>
            {!props.hidden && (
                <div role="tabpanel" style={{ display: props.hidden ? "none" : "block" }} className={styles.tabPanel}>
                    {props.children}
                </div>
            )}
        </>
    );
});
