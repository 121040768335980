import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Amplify, Auth } from "aws-amplify";
import { useGetConfig } from "@/store/hooks/config";

type CurrentCognitoUserContextType = {
    currentCognitoUser: CognitoUser | undefined;
    setCurrentCognitoUser: (cognitoUser?: CognitoUser) => void;
};

export const CurrentCognitoUserContext = createContext<CurrentCognitoUserContextType>({
    currentCognitoUser: undefined,
    setCurrentCognitoUser: () => undefined,
});

// このhookを介して、サインイン中のログイン情報を取得することができる。
// currentCognitoUser.username に userId (CognitoId) が入っている
export const useCognitoUser = (): CognitoUser | undefined => {
    const { currentCognitoUser } = useContext(CurrentCognitoUserContext);
    return currentCognitoUser;
};

interface Props {
    children: React.ReactNode;
}

export const CognitoUserAuthenticator: React.VFC<Props> = ({ children }) => {
    const [currentCognitoUserRef, setCurrentCognitoUserRef] = useState<{ ref?: CognitoUser }>();
    const setCurrentCognitoUser = useCallback(
        (cognitoUser?: CognitoUser) => {
            setCurrentCognitoUserRef({ ref: cognitoUser });
        },
        [setCurrentCognitoUserRef],
    );
    const currentCognitoUser = useMemo(() => currentCognitoUserRef?.ref, [currentCognitoUserRef]);
    const [cognitoUserIsFetched, setCognitoUserIsFetched] = useState<boolean>(false);
    // const env = getConfig()
    const [getConfig] = useGetConfig();
    useEffect(() => {
        (async () => {
            try {
                const result = await getConfig();
                if (result.isSuccess) {
                    console.log(result.data);
                    Amplify.configure({
                        Auth: {
                            userPoolId: result.data.cognito.userPoolId,
                            userPoolWebClientId: result.data.cognito.webClientId,
                            region: result.data.cognito.region,
                        },
                    });
                    setCurrentCognitoUser(await Auth.currentAuthenticatedUser());
                }
            } catch (e) {
                console.log(e);
            } finally {
                setCognitoUserIsFetched(true);
            }
        })();
    }, [setCurrentCognitoUser, setCognitoUserIsFetched]);

    // if (!cognitoUserIsFetched) {
    //   return null;
    // }

    const signOut = () => {
        Auth.signOut();
    };
    // 一旦、ログインをしていない状態であれば、childrenを表示しないようにしている。
    // このロジックは使いやすいように、自由にいじってもらって構いません。
    return (
        <CurrentCognitoUserContext.Provider value={{ currentCognitoUser, setCurrentCognitoUser }}>
            {currentCognitoUser ? (
                <>{children}</>
            ) : (
                // <div>
                //     <Button style={{ zIndex: 100000 }} onClick={signOut}>
                //         サインアウト
                //     </Button>
                //     {children}
                // </div>
                // <Switch>
                //     <Route exact path={"/signup"}>
                //         {/* サインアップ用ページ */}
                //         <SignUp />
                //     </Route>
                //     <Route exact path={"/confirm-signup"}>
                //         {/* サインアップ後にメールで受け取る認証コードを入力するページ */}
                //         <ConfirmSignUp />
                //     </Route>
                //     <Route exact path={"/signin"}>
                //         {/* サインインページ */}
                //         <SignIn />
                //     </Route>
                //     <Route exact path={"/forgot-password"}>
                //         {/* パスワードを忘れたときのパスワード再設定用の認証コードを送信するページ */}
                //         <ForgotPassword />
                //     </Route>
                //     <Route exact path={"/forgot-password-submit"}>
                //         {/* パスワードを忘れたときの認証コードと新しいパスワードを入力するページ */}
                //         <ForgotPasswordSubmit />
                //     </Route>
                // </Switch>
                <>{children}</>
            )}
        </CurrentCognitoUserContext.Provider>
    );
};
