import { FAQConfig } from "@/FAQConfig";

export const PROHIBITED_ACTION_CONFIGS: FAQConfig[] = [
    {
        title: "Trailを利用せずに受講予約を受け付ける行為",
        text: "Trail以外の手段を用いて先生と生徒が直接取引するなど弊社への手数料支払いを回避するような行為は利用規約違反となります。",
    },
    {
        title: "他サービスや商品の宣伝・勧誘行為",
        text: "講座の中で講座の内容に関連のないサービスや商品の宣伝や商品の購入を促す行為は利用規約違反となります。また、自社のサービスや教室の広告目的での掲載・外部サイトへの誘導行為も利用規約依願となります。",
    },
    {
        title: "不正に講座実績を作る行為",
        text: "先生が生徒になりすまし、大量に予約やレビューを獲得することで不正に講座の実績を作る行為は利用規約違反となります。",
    },
];
