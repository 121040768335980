import { TextField } from "@material-ui/core";
import { memo } from "react";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Mandatory } from "@/components/Tag/Mandatory";

import styles from "@/pages/Teacher/IdentificationForStripe/index.module.scss";

interface Props {
    checked: boolean;
    line1: string;
    handleLine1Change: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const Line1: React.VFC<Props> = memo(function Line1(props) {
    return (
        <div className={styles.formItem} style={{ borderBottom: "1px solid #CCC" }}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitle}>番地</div>
                <Mandatory />
            </div>
            <div className={styles.rightWrapper}>
                <TextField
                    variant="outlined"
                    placeholder="〇〇-〇〇"
                    className={styles.textField}
                    value={props.line1}
                    onChange={props.handleLine1Change}
                />
                <ErrorMessage content="番地を入力してください。" when={props.checked && props.line1.length === 0} />
            </div>
        </div>
    );
});
