import { ChatResponse } from "@/store/autogenApi";

export const getStudentHowManyUnreadMessages = (chats: ChatResponse[], studentId: string) => {
    const lengthList = chats.map((chat) => {
        const targetReadStatus = chat.readStatuses.find((readStatus) => readStatus.studentId === studentId);
        const unreadLength = chat.messages
            .filter((message) => message.student?.studentId !== studentId)
            ?.map((message) => {
                if (targetReadStatus == undefined) return 0 as number;
                if (message.createdAt <= (targetReadStatus?.updatedAt || targetReadStatus?.createdAt)) {
                    return 0 as number;
                } else {
                    return 1 as number;
                }
            })
            .reduce((prevValue, currentValue) => {
                return ((prevValue as number) + currentValue) as number;
            }, 0);
        return unreadLength;
    });
    const howManyUnreadMessages = lengthList.reduce((prevValue, currentValue) => {
        return ((prevValue as number) + currentValue) as number;
    }, 0);
    return howManyUnreadMessages;
};

export const getTeacherHowManyUnreadMessages = (chats: ChatResponse[], teacherId: string) => {
    const lengthList = chats.map((chat) => {
        const targetReadStatus = chat.readStatuses.find((readStatus) => readStatus.teacherId === teacherId);
        const unreadLength = chat.messages
            .filter((message) => message.teacher?.teacherId !== teacherId)
            ?.map((message) => {
                if (targetReadStatus == undefined) return 0 as number;
                if (message.createdAt <= (targetReadStatus?.updatedAt || targetReadStatus?.createdAt)) {
                    return 0 as number;
                } else {
                    return 1 as number;
                }
            })
            .reduce((prevValue, currentValue) => {
                return ((prevValue as number) + currentValue) as number;
            }, 0);
        return unreadLength;
    });
    const howManyUnreadMessages = lengthList.reduce((prevValue, currentValue) => {
        return ((prevValue as number) + currentValue) as number;
    }, 0);
    return howManyUnreadMessages;
};
