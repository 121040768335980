import { useEffect, useState } from "react";

import { Button, useMediaQuery } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Link } from "react-router-dom";

import styles from "./index.module.scss";

import { RepetitionCard } from "./RepetitionCard";
import { PublicRequestResponse } from "@/store/autogenApi";
import { LoadingProgress } from "../LoadingProgress";

interface Props {
    title: string;
    destination: string;
    requests: PublicRequestResponse[];
    isLoaded: boolean;
    repetitionNumber: number | undefined;
    hasTwoLines?: boolean;
}

export const RequestCardsBase: React.VFC<Props> = (props) => {
    return (
        <div className={styles.requestCardsBase}>
            <div className={styles.headingAndButton}>
                <div className={styles.heading}>{props.title}</div>
                {props.requests.length > 0 && (
                    <Button className={styles.moreButton} component={Link} to={props.destination}>
                        <span className={styles.text}>もっと見る</span>
                        <ArrowForwardIosIcon className={styles.icon} />
                    </Button>
                )}
            </div>
            <LoadingProgress loadingComplete={props.isLoaded}>
                <RepetitionCard
                    title={props.title}
                    requests={props.requests}
                    repetition={props.repetitionNumber ?? 0}
                    isFlex={!!props.repetitionNumber}
                    hasTwoLines={props.hasTwoLines}
                />
            </LoadingProgress>
        </div>
    );
};
