import { useCallback, useState } from "react";

import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { logout } from "@/actions/logging";
import { PrivateUserResponse } from "@/store/autogenApi";
import { useDeleteStudentMutation } from "@/store/hooks/students";
import { useDeleteTeacherMutation } from "@/store/hooks/teachers";
import { useDeleteUserMutation } from "@/store/hooks/users";

import styles from "@/pages/Common/Withdrawal/index.module.scss";
import { Auth } from "aws-amplify";
import { Processing } from "@/components/Processing";

interface Props {
    user: PrivateUserResponse;
}

export const WithdrawalContents: React.VFC<Props> = (props) => {
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const deleteStudent = useDeleteStudentMutation();
    const deleteTeacher = useDeleteTeacherMutation();
    const deleteUser = useDeleteUserMutation();

    const history = useHistory();
    const backButtonClick = () => {
        history.goBack();
    };
    const dispatch = useDispatch();
    const handleWithdrawButtonClick = useCallback(async () => {
        setIsProcessing(true);
        try {
            if (props.user?.studentId) {
                await deleteStudent({
                    studentId: props.user.studentId,
                });
            }
            if (props.user?.teacherId) {
                await deleteTeacher({
                    teacherId: props.user.teacherId,
                });
            }
            if (props.user?.userId) {
                await Auth.deleteUser();
                await deleteUser({
                    userId: props.user.userId,
                });
            }
            Auth.signOut();
            localStorage.clear();
            dispatch(logout());
            history.push("/CompletedWithdrawal");
            setIsProcessing(false);
        } catch (e) {
            console.error(e);
            setIsProcessing(false);
        }
    }, [props.user, deleteStudent, deleteTeacher, deleteUser]);
    return (
        <div className={styles.withdrawalWrapper}>
            <div className={styles.withdrawalContents}>
                <div className={styles.withdrawalTitle}>退会</div>
                <div className={styles.notice}>
                    退会をすると、ご登録のメールアドレスに紐づけられた全てのデータが削除され、以降データの復旧はできません。本当に退会してもよろしいですか？
                </div>
                <div className={styles.buttonsWrapper}>
                    <Button className={styles.backButton} onClick={backButtonClick}>
                        戻る
                    </Button>
                    <Button className={styles.withdrawButton} onClick={handleWithdrawButtonClick}>
                        {isProcessing ? <Processing /> : "退会する"}
                    </Button>
                </div>
            </div>
        </div>
    );
};
