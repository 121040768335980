import { memo } from "react";
import styles from "@/pages/Teacher/Identification/index.module.scss";
import { PictureUpload } from "../PictureUpload";

interface Props {
    handleStudentCardChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleStudentCardDeleteButtonClick: () => void;
    temporaryUrl: string | undefined;
    isChecked: boolean;
}

export const StudentCard: React.VFC<Props> = memo(function StudentCard(props) {
    return (
        <div className={styles.formItem} style={{ borderBottom: "1px solid #CCC" }}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitle}>学生証</div>
                <div className={styles.optional}>任意</div>
            </div>
            <div className={styles.rightWrapper}>
                <PictureUpload
                    handleChange={props.handleStudentCardChange}
                    handleDeleteButtonClick={props.handleStudentCardDeleteButtonClick}
                    temporaryUrl={props.temporaryUrl}
                    isChecked={props.isChecked}
                    mandatory={false}
                />
            </div>
        </div>
    );
});
