import { memo } from "react";
import { Box } from "@mui/material";
import { ClickableHandIcon } from "@/globalIcons";

interface Props {
    handleClick: () => void;
}

export const ClickableButton: React.VFC<Props> = memo(function ClickableButton(props) {
    return (
        <Box
            sx={{
                color: "#305077",
                backgroundColor: "#FFF",
                width: "30px",
                height: "30px",
                borderRadius: "4px",
                border: "1px solid #305077",
                fontSize: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                bottom: "0",
                right: "0",
                cursor: "pointer",
                "&:hover": {
                    opacity: 0.7,
                },
            }}
            onClick={props.handleClick}
        >
            <ClickableHandIcon />
        </Box>
    );
});
