import { memo } from "react";
import { Mandatory } from "@/components/Tag/Mandatory";

import styles from "./index.module.scss";
import { Optional } from "@/components/Tag/Optional";

interface Props {
    title: string;
    children: React.ReactNode;
    borderBottom?: boolean;
    isMandatory?: boolean;
    isOptional?: boolean;
}

export const Item: React.VFC<Props> = memo(function Item(props) {
    return (
        <div className={styles.item} style={{ borderBottom: props.borderBottom ? "1px solid #CCC" : "none" }}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitle}>{props.title}</div>
                {props.isMandatory && <Mandatory />}
                {props.isOptional && <Optional />}
            </div>
            <div className={styles.rightWrapper}>{props.children}</div>
        </div>
    );
});
