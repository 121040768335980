import { Button, Modal } from "@material-ui/core";
import { memo, useCallback } from "react";

import styles from "@/pages/Common/PublicRequestDetails/index.module.scss";
import { LeftTime } from "../index";

interface Props {
    leftTime: LeftTime | undefined;
    leftTimeModalOpen: boolean;
    handleLeftTimeModalClose: () => void;
}

export const LeftTimeModal: React.VFC<Props> = memo(function LeftTimeModal(props) {
    const getDetailLeftTime = useCallback(() => {
        const diffDate = props.leftTime?.date;
        const diffHours = props.leftTime?.hours;
        const diffMinutes = props.leftTime?.minutes;
        const diffSeconds = props.leftTime?.seconds;
        if (
            typeof diffDate === "number" &&
            typeof diffHours === "number" &&
            typeof diffMinutes === "number" &&
            typeof diffSeconds === "number"
        ) {
            if (diffDate >= 1) {
                const result = `${diffDate}日${("0" + diffHours).slice(-2)}時間${("0" + diffMinutes).slice(-2)}日${(
                    "0" + diffSeconds
                ).slice(-2)}秒`;
                return result;
            } else if (diffDate === 0) {
                if (diffHours >= 1) {
                    const result = `${("0" + diffHours).slice(-2)}時間${("0" + diffMinutes).slice(-2)}分${(
                        "0" + diffSeconds
                    ).slice(-2)}秒`;
                    return result;
                } else if (diffHours === 0) {
                    if (diffMinutes >= 1) {
                        const result = `${("0" + diffMinutes).slice(-2)}分${("0" + diffSeconds).slice(-2)}秒`;
                        return result;
                    } else if (diffMinutes === 0) {
                        if (diffSeconds > 0) {
                            const result = `${("0" + diffSeconds).slice(-2)}秒`;
                            return result;
                        } else {
                            return "募集期間終了";
                        }
                    } else {
                        return "募集期間終了";
                    }
                } else {
                    return "募集期間終了";
                }
            } else {
                return "募集期間終了";
            }
        } else {
            return "";
        }
    }, [props.leftTime]);

    return (
        <Modal open={props.leftTimeModalOpen} onClose={props.handleLeftTimeModalClose} className={styles.modal}>
            <div className={styles.leftTimeModalContents}>
                <div className={styles.leftTimeMessage}>
                    残り時間は目安であり、通信環境やシステム状況などから誤差が生じる場合がありますので、余裕を持った提案を送ってください。
                </div>
                <ul className={styles.infoList}>
                    <li className={styles.listItem}>
                        <div className={styles.leftWrapper}>
                            <div className={styles.titleWrapper}>
                                <div className={styles.title}>残り時間</div>
                            </div>
                        </div>
                        <div className={styles.rightWrapper}>
                            <div className={styles.value}>{getDetailLeftTime()}</div>
                        </div>
                    </li>
                </ul>
                <Button className={styles.backButton} onClick={props.handleLeftTimeModalClose}>
                    戻る
                </Button>
            </div>
        </Modal>
    );
});
