import { FAQDetailsBase } from "../../FAQDetailsBase";

import { CHECK_RESERVATION_CONFIGS } from "./CheckReservationConfigs";

export const CheckReservation: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="予約状況を確認する"
            text="このページでは、予約状況を確認する方法についてご説明します。予約状況を確認するには以下の手順で行うことができます。"
            FAQConfigs={CHECK_RESERVATION_CONFIGS}
        />
    );
};
