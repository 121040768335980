import { Button, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";

import trailImage from "@/images/logo-v2-with-trail.jpg";
import styles from "@/pages/Common/AboutStudent/index.module.scss";

import bagImage from "./img/bag.jpg";
import guyImage from "./img/guy.jpg";
import lightBulbImage from "./img/light_bulbs.jpg";
import studentImage from "./img/student.jpg";
import { OverWrapper } from "@/components/OverWrapper";

interface Props {
    userMode: string;
}

export const TrailStudent: React.VFC<Props> = (props) => {
    return (
        <>
            {/* 内容は仮のもの．後で変更する */}
            <OverWrapper style={{ borderBottom: "1px solid #305077" }}>
                <div className={styles.tabTitle}>Trail開設の背景</div>
            </OverWrapper>
            <div className={styles.flexColumn}>
                <div className={`${styles.messageWrapper}`} id="target1">
                    <div className={styles.messages}>
                        <p className={styles.message}>
                            多くの方々に支えられてリリースされた、生徒と先生を繋ぐ
                            <br />
                            <span className={styles.strong}>【学び】のフリマアプリ Trail</span>
                            <br />
                            ですが、その背景にあったのは
                            <span className={styles.strong}>今の塾業界に対する違和感</span>
                            でした。
                        </p>
                    </div>
                    <img src={trailImage} alt="" className={styles.image} style={{ boxShadow: "none" }} />
                </div>
                <div className={`${styles.messageWrapper}`} id="target2">
                    <Hidden xsDown>
                        <img src={guyImage} alt="" className={styles.image} />
                    </Hidden>
                    <div className={styles.messages}>
                        <p className={styles.message}>
                            塾と聞くと、多くの親御さんが子どもの学習をサポートし成績を上げてくれる場所として、安心して利用していることでしょう。
                        </p>
                        <p className={styles.message}>
                            しかし、本当に塾は子どもたちにとって最適な学習環境なのでしょうか...？
                        </p>
                    </div>
                    <Hidden smUp>
                        <img src={studentImage} alt="" className={styles.image} />
                    </Hidden>
                </div>
                <div className={`${styles.messageWrapper}`} id="target3">
                    <div className={styles.messages}>
                        <p className={styles.message}>
                            特に個別指導塾では、入塾を希望すると、その塾にいた空いている先生が担当で割り当てられることになります。
                        </p>
                        <p className={styles.message}>
                            人生の分岐点である「受験」を任せる先生。そんな選び方をしていいのでしょうか？
                        </p>
                        <p className={styles.message}>
                            しかしながら、その他に選択肢がないため、多くの方がそのような状況を受け入れている、というよりそこに対して疑問も抱けないのが現状です。
                        </p>
                    </div>
                    <img src={bagImage} alt="" className={styles.image} />
                </div>
                <div className={`${styles.messageWrapper}`} id="target2">
                    <Hidden xsDown>
                        <img src={lightBulbImage} alt="" className={styles.image} />
                    </Hidden>
                    <div className={styles.messages}>
                        <p className={styles.message}>これらのことから、私たちは思いました。</p>
                        <p className={styles.message}>生徒と先生を繋ぐ自由なプラットフォームがあるべきだ、と。</p>
                        <p className={styles.message}>
                            それがないからこそ、先生との相性が合わなくても塾を続けるしかなく、高い授業料を払っているのに成績が伸びないという悲劇が生まれてしまう。
                        </p>
                        <p className={styles.message}>であるならば、そのような環境を創出しよう。</p>

                        <p className={styles.message}>
                            そういう思いから生まれたのがこの
                            <span className={styles.strong}>「Trail」</span>
                            なのです。
                        </p>
                    </div>
                    <Hidden smUp>
                        <img src={lightBulbImage} alt="" className={styles.image} />
                    </Hidden>
                </div>
            </div>
            <div className={`${styles.bottomMessages}`} id="target4">
                <p className={styles.bottomMessage}>
                    <span className={styles.strong}>お子さんの未来を託す先生は「あなたが」選ぶ</span>
                </p>
                <p className={styles.bottomMessage}>Trailでそのコンセプトを体験をしていただけることを願っています。</p>
            </div>
            {props.userMode === "userMode" && (
                <Button component={Link} to="/SignUp/ForStudent" className={styles.signUpButton}>
                    先生登録
                </Button>
            )}
        </>
    );
};
