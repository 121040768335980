import { memo, useCallback, useEffect } from "react";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import styles from "@/pages/Student/CreatePublicRequest/index.module.scss";
import { Mandatory } from "@/components/Tag/Mandatory";

interface Props {
    value: string;
    checked: boolean;
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    open?: boolean;
    courseType: string;
    validation: boolean;
}

export const ScheduleDescription: React.VFC<Props> = memo(function ScheduleDescription(props) {
    const fixPlaceholder = useCallback(() => {
        const element: HTMLInputElement | null = document.getElementById(
            "scheduleDescriptionTextArea",
        ) as HTMLInputElement;
        if (element) {
            element.placeholder = element.placeholder.replace(/\\n/g, "\n");
        }
    }, []);

    useEffect(() => {
        fixPlaceholder();
    }, []);

    const getExample = useCallback(() => {
        if (props.courseType === "short") {
            return "例）\n①○月○日○時〜\n②○月○日○時〜\n③○月○日○時〜\n④○月○日○時〜\nのうち2日・各90分でお願いします。";
        } else if (props.courseType === "regular") {
            return "例）毎週○曜日○時〜○時でお願いします。";
        }
    }, [props.courseType]);

    return (
        <li className={styles.listItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>日程</div>
                    {!props.open && <Mandatory />}
                </div>
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>{props.value}</div>
                ) : (
                    <>
                        <TextareaAutosize
                            required
                            placeholder={`日程(必須500字以内)\n\n${getExample()}`}
                            className={styles.textArea}
                            name="scheduleDescription"
                            onChange={props.handleChange}
                            minRows={5}
                            id="scheduleDescriptionTextArea"
                            value={props.value}
                        />
                        {props.checked && !props.validation && (
                            <div className={styles.helperText}>この項目は必須です。500字以内で入力してください。</div>
                        )}
                    </>
                )}
            </div>
        </li>
    );
});
