import { useCallback, useEffect, useState } from "react";

import { Button, MenuItem } from "@material-ui/core";

import styles from "@/pages/Teacher/MyPage/index.module.scss";

import { StyledSelect } from "./template/StyledSelect";
import { StyledTextField } from "./template//StyledTextField";
import { useCreateBankAccountMutation } from "@/store/hooks/stripe";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { ErrorMessage } from "@/components/ErrorMessage";
import { zenkakuToHankaku } from "@/utils/ZenkakuToHankaku";
import { Processing } from "@/components/Processing";
import { BankInfo } from "@/store/autogenApi";
import { NavyButton } from "@/components/Buttons/NavyButton";

const DUMMY_ACCOUNT_NUMBER = "0001234";
const DUMMY_ROUTING_NUMBER = "1100000";

interface Props {
    bankInfoList: BankInfo[];
    handleBackButtonClick: () => void;
}

export const BankAccountRegistration: React.VFC<Props> = (props) => {
    const [bankFullNameList, setBankFullNameList] = useState<string[]>([]);
    const [branchNameList, setBranchNameList] = useState<string[]>([]);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [bankFullName, setBankFullName] = useState<string | undefined>(undefined);
    const [branchName, setBranchName] = useState<string | undefined>(undefined);
    const [accountNumber, setAccountNumber] = useState<string | undefined>(undefined);
    const [accountHolderName, setAccountHolderName] = useState<string | undefined>(undefined);
    const [checked, setChecked] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);

    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);
    const createBankAccount = useCreateBankAccountMutation();

    useEffect(() => {
        if (props.bankInfoList.length > 0) {
            const sortedBankNameList = [...props.bankInfoList]
                .sort((a, b) => {
                    if (a.bankShortNameKana < b.bankShortNameKana) return -1;
                    if (a.bankShortNameKana > b.bankShortNameKana) return 1;
                    return 0;
                })
                .map((bankInfo) => bankInfo.bankFullName);
            const uniqueSortedBankNameList = [...new Set(sortedBankNameList)];
            setBankFullNameList(uniqueSortedBankNameList);
        }
    }, [props.bankInfoList]);

    useEffect(() => {
        if (bankFullName) {
            const sortedBranchNameList = [...props.bankInfoList]
                .filter((bankInfo) => bankInfo.bankFullName === bankFullName)
                .sort((a, b) => {
                    if (a.branchNameKana < b.branchNameKana) return -1;
                    if (a.branchNameKana > b.branchNameKana) return 1;
                    return 0;
                })
                .map((bankInfo) => bankInfo.branchName);
            const uniqueSortedBranchNameList = [...new Set(sortedBranchNameList)];
            setBranchNameList(uniqueSortedBranchNameList);
        }
    }, [bankFullName]);

    useEffect(() => {
        if (
            bankFullName &&
            branchName &&
            accountNumber &&
            getIsOnlyNumber() &&
            accountHolderName &&
            getIsOnlyAlphabet()
        ) {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }
    }, [bankFullName, branchName, accountNumber, accountHolderName]);

    const handleBankFullNameChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
        if (event.target.value === "choose") {
            setBankFullName(undefined);
        }
        setBankFullName(event.target.value as string);
    }, []);

    const handleBranchNameChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
        if (event.target.value === "choose") {
            setBranchName(undefined);
        }
        setBranchName(event.target.value as string);
    }, []);

    const handleAccountNumberChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
        const value = zenkakuToHankaku(event.target.value as string);
        setAccountNumber(value);
    }, []);

    const handleAccountHolderNameChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
        setAccountHolderName(event.target.value as string);
    }, []);

    const handleRegisterButtonClick = useCallback(async () => {
        setChecked(true);
        if (!canSubmit) return;
        if (bankFullName && branchName && accountNumber && accountHolderName) {
            setIsCreating(true);
            const targetBankInfo = props.bankInfoList.find((bankInfo) => {
                return bankInfo.bankFullName === bankFullName && bankInfo.branchName === branchName;
            });
            if (!targetBankInfo) return;
            const japanPostCode = targetBankInfo.japanPostCode !== "0" ? targetBankInfo.japanPostCode : "";
            const routingNumber = targetBankInfo.bankCode + targetBankInfo.branchCode + japanPostCode;
            const isProductionEnv = window.location.host === "trail-int.com";
            const result = await createBankAccount({
                createBankAccountRequestBody: {
                    teacherId: teacherId,
                    country: "JP",
                    currency: "JPY",
                    accountHolderName: accountHolderName,
                    accountHolderType: "individual",
                    accountNumber: isProductionEnv ? accountNumber : DUMMY_ACCOUNT_NUMBER,
                    routingNumber: isProductionEnv ? routingNumber : DUMMY_ROUTING_NUMBER,
                },
            });
            if (!result.isSuccess) setIsError(true);
            setIsCreating(false);
        }
    }, [bankFullName, branchName, accountNumber, accountHolderName, canSubmit]);

    const getIsOnlyAlphabet = useCallback(() => {
        if (!accountHolderName) return false;
        const regex = /^[a-zA-Z]+ [a-zA-Z]+$/;
        return regex.test(accountHolderName);
    }, [accountHolderName]);

    const getIsOnlyNumber = useCallback(() => {
        if (!accountNumber) return false;
        const modifiedAccountNumber = zenkakuToHankaku(accountNumber);
        const regex = /^[0-9]+$/;
        return regex.test(modifiedAccountNumber);
    }, [accountNumber]);

    return (
        <div className={styles.bankAccountRegistrationWrapper}>
            {isError ? (
                <div className={styles.errorWrapper}>
                    <div className={styles.errorMessage}>処理中にエラーが発生しました。</div>
                    <Button className={styles.errorBackButton} onClick={props.handleBackButtonClick}>
                        戻る
                    </Button>
                </div>
            ) : (
                <>
                    <button onClick={props.handleBackButtonClick} className={styles.backButton}>
                        戻る
                    </button>
                    <div className={styles.bankAccountRegistrationTitle}>銀行口座登録</div>
                    <div className={styles.inputWrapper}>
                        <div className={styles.bankNameWrapper}>
                            <div className={styles.bankName}>銀行名</div>
                            <StyledSelect handleChange={handleBankFullNameChange}>
                                <MenuItem value="choose" key="" className={styles.option}>
                                    銀行名を選択
                                </MenuItem>
                                {bankFullNameList.map((bankFullName, idx) => {
                                    return (
                                        <MenuItem value={bankFullName} key={idx} className={styles.option}>
                                            {bankFullName}
                                        </MenuItem>
                                    );
                                })}
                            </StyledSelect>
                            <ErrorMessage content="選択してください" when={checked && !bankFullName} />
                        </div>
                        <div className={styles.branchNameWrapper}>
                            <div className={styles.branchName}>支店名</div>
                            <StyledSelect handleChange={handleBranchNameChange}>
                                <MenuItem value="choose" key="" className={styles.option}>
                                    支店名を選択
                                </MenuItem>
                                {branchNameList.map((branchName, idx) => {
                                    return (
                                        <MenuItem value={branchName} key={idx} className={styles.option}>
                                            {branchName === "本店" ? "本店" : branchName + "支店"}
                                        </MenuItem>
                                    );
                                })}
                            </StyledSelect>
                            <ErrorMessage content="選択してください" when={checked && !branchName} />
                        </div>
                        <div className={styles.accountNumberWrapper}>
                            <div className={styles.accountNumber}>口座番号</div>
                            <StyledTextField placeholder="例)01234567" handleChange={handleAccountNumberChange} />
                            <ErrorMessage content="数字を入力してください" when={checked && !getIsOnlyNumber()} />
                        </div>
                        <div className={styles.accountHolderNameWrapper}>
                            <div className={styles.accountHolderName}>口座名義</div>
                            <StyledTextField
                                placeholder="例)TARO YAMADA"
                                handleChange={handleAccountHolderNameChange}
                            />
                            <ErrorMessage
                                content="大文字・小文字のアルファベットと半角スペースで入力してください。"
                                when={checked && !getIsOnlyAlphabet()}
                            />
                        </div>
                    </div>
                    <NavyButton
                        isLoading={isCreating}
                        handleClick={handleRegisterButtonClick}
                        className={styles.registerButton}
                    >
                        登録
                    </NavyButton>
                </>
            )}
        </div>
    );
};
