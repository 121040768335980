import { useEffect, useState } from "react";

import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

import styles from "@/pages/Common/TeacherDetails/index.module.scss";
import { CourseResponse, PublicTeacherResponse, PublicUserResponse, ReviewResponse } from "@/store/autogenApi";

import { Courses } from "./Courses/index";
import { ReviewsComponent } from "./Reviews";
import { Top } from "./Top/index";
import { TabContents } from "./TabContents";

import queryString from "query-string";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { OverWrapper } from "@/components/OverWrapper";
import { useMediaQuery } from "@material-ui/core";

interface Props {
    teacher: PublicTeacherResponse;
    user: PublicUserResponse;
    courses: CourseResponse[];
    tabsWrapperRef: (headerEl: HTMLElement | null) => void;
}

export const TabsComponent: React.VFC<Props> = (props) => {
    const query = queryString.parse(window.location.search);
    const [value, setValue] = useState(0);
    const [reviews, setReviews] = useState<ReviewResponse[]>([]);

    const mdUp = useMediaQuery("(min-width:960px)");

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList([
            {
                title: "先生検索",
                url: "/SearchResults?searchType=teacher",
            },
            {
                title: `${props.teacher.nickname}先生`,
                url: `/TeacherDetails/${props.teacher.teacherId}`,
            },
        ]);
    }, [props.teacher]);

    useEffect(() => {
        if (query.tab === "top") {
            setValue(0);
        } else if (query.tab === "courses") {
            setValue(1);
        } else if (query.tab === "reviews") {
            setValue(2);
        }
    }, []);

    useEffect(() => {
        const fetchedReviews = props.courses
            .map((course) => course.reviews)
            .flat()
            .sort((a, b) => {
                if (a?.createdAt && b?.createdAt) {
                    if (a.createdAt < b.createdAt) {
                        return 1;
                    } else if (a.createdAt > b.createdAt) {
                        return -1;
                    } else {
                        return 0;
                    }
                } else {
                    return 0;
                }
            });
        const filteredReviews = fetchedReviews.filter((review): review is ReviewResponse => review !== undefined);
        setReviews(filteredReviews);
    }, [props.courses]);

    const handleChange = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
        setValue(newValue);
    };

    const handleMoreButtonClick = (number: number) => {
        setValue(number);
        window.scroll({ top: 500, behavior: "smooth" });
    };

    const theme = createTheme({
        overrides: {
            MuiTabs: {
                root: {
                    backgroundColor: "#FFF",
                    color: "#305077",
                    paddingLeft: 0,
                    boxShadow: "none !important",
                },
                indicator: {
                    backgroundColor: "#305077",
                },
            },
            MuiTab: {
                root: {
                    fontWeight: "bold",
                    "&$selected": {
                        color: "#305077",
                        "&:hover": {
                            color: "#305077",
                        },
                    },
                },
            },
        },
    });

    return (
        <div className={styles.tabsWrapper} ref={props.tabsWrapperRef}>
            <MuiThemeProvider theme={theme}>
                <AppBar
                    position="static"
                    style={{
                        boxShadow: "0 3px 5px #BBB !important;",
                        backgroundColor: "#FFF",
                        borderBottom: "1px solid #DDD",
                    }}
                >
                    <Tabs value={value} onChange={handleChange} className={styles.tabs}>
                        <Tab label="TOP" />
                        <Tab label="講座" />
                        <Tab label="レビュー" />
                    </Tabs>
                </AppBar>
                <TabContents value={value} index={0}>
                    <Top teacher={props.teacher} user={props.user} handleMoreButtonClick={handleMoreButtonClick} />
                </TabContents>
                <TabContents value={value} index={1}>
                    <Courses
                        shortLessons={props.courses.filter((course) => course.courseType === "short")}
                        regularCourses={props.courses.filter((course) => course.courseType === "regular")}
                    />
                </TabContents>
                <TabContents value={value} index={2}>
                    <ReviewsComponent reviews={reviews} />
                </TabContents>
            </MuiThemeProvider>
        </div>
    );
};
