import { Button, useMediaQuery } from "@material-ui/core";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Processing } from "@/components/Processing";
import { RootState } from "@/ducks";
import styles from "@/pages/Student/Payment/index.module.scss";
import { ProposalResponse } from "@/store/autogenApi";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import { PointsModal } from "./PointsModal";
import VISA from "@/images/VISA.jpg";
import MasterCard from "@/images/Mastercard.jpg";
import JCB from "@/images/JCB.jpg";
import AMEX from "@/images/AMEX.jpg";
import DinersClub from "@/images/DinersClub.jpg";
import Discover from "@/images/DISCOVER.jpg";
import {
    useReserveProposedShortCourseMutation,
    useReserveProposedRegularCourseMutation,
} from "@/store/hooks/proposals";

interface Props {
    defaultPaymentMethod: any | null;
    proposal: ProposalResponse;
    pointsBalance: number;
    setHasOtherError: Dispatch<SetStateAction<boolean>>;
    setHasUpdatedError: Dispatch<SetStateAction<boolean>>;
    targetPaymentMethod: any | undefined;
    handleBackButtonClick: () => void;
}

export const ConfirmStepContents: React.VFC<Props> = (props) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const [pointsModalOpen, setPointsModalOpen] = useState(false);
    const [points, setPoints] = useState(0);

    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);

    const thresholdOver = useMediaQuery("(min-width:550px)");

    const history = useHistory();

    const reserveProposedShortCourse = useReserveProposedShortCourseMutation();
    const reserveProposedRegularCourse = useReserveProposedRegularCourseMutation();

    const handleBuyButtonClick = useCallback(async () => {
        if (!props.targetPaymentMethod) return;
        setIsProcessing(true);
        const pricePerPayment = props.proposal.class.pricePerPayment;
        if (!pricePerPayment) return;
        if (props.proposal.courseType === "short") {
            const { isSuccess } = await reserveProposedShortCourse({
                reserveProposedShortCourseRequestBody: {
                    paymentMethodId: props.targetPaymentMethod?.id as string,
                    paymentMethodType: props.targetPaymentMethod?.type as string,
                    amount: pricePerPayment,
                    pointsAmount: points,
                    studentId,
                    proposalId: props.proposal.proposalId,
                },
            });
            window.scrollTo(0, 0);
            if (isSuccess) {
                history.push("/ThanksPayment?courseType=short");
            } else {
                props.setHasOtherError(true);
            }
            setIsProcessing(false);
        } else {
            const { isSuccess, errorMessage } = await reserveProposedRegularCourse({
                reserveProposedRegularCourseRequestBody: {
                    amount: pricePerPayment,
                    pointsAmount: points,
                    studentId,
                    proposalId: props.proposal.proposalId,
                    paymentMethodId: props.targetPaymentMethod?.id as string,
                },
            });
            window.scrollTo(0, 0);
            if (isSuccess) {
                history.push("/ThanksPayment?courseType=regular");
            } else if (errorMessage && errorMessage.includes("course has been updated")) {
                props.setHasUpdatedError(true);
            } else {
                props.setHasOtherError(true);
            }
            setIsProcessing(false);
        }
    }, [props.proposal, studentId, points, props.targetPaymentMethod]);

    const handlePointsModalClose = useCallback(() => {
        setPointsModalOpen(false);
    }, []);

    const handleUsePointsButtonClick = useCallback(() => {
        setPointsModalOpen(true);
    }, []);

    const getSrc = (brand: string) => {
        if (brand === "visa") return VISA;
        if (brand === "amex") return AMEX;
        if (brand === "mastercard") return MasterCard;
        if (brand === "jcb") return JCB;
        if (brand === "discover") return Discover;
        if (brand === "diners") return DinersClub;
    };

    return (
        <div className={styles.confirmStepContentsWrapper}>
            <button className={styles.backButton} onClick={props.handleBackButtonClick}>
                <MdArrowBackIosNew className={styles.backIcon} />
                <div className={styles.back}>戻る</div>
            </button>
            <div className={styles.confirmStepTitle}>支払い内容の確認</div>
            <div className={styles.defaultPaymentMethodButton}>
                <div className={styles.paymentMethodTitle}>支払い方法</div>
                {props.targetPaymentMethod?.type === "card" && (
                    <div className={styles.cardWrapper}>
                        <div className={styles.cardTitle}>クレジットカード</div>
                        <img src={getSrc(props.targetPaymentMethod.card.brand)} alt="" className={styles.brandImage} />
                        <div className={styles.rightWrapper}>
                            <div
                                className={styles.cardNumber}
                            >{`**** **** **** ${props.targetPaymentMethod.card.last4}`}</div>
                            <div className={styles.exp}>
                                {props.targetPaymentMethod.card.exp_month}/{props.targetPaymentMethod.card.exp_year}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <button
                className={styles.usePointsButton}
                style={{ pointerEvents: props.pointsBalance === 0 ? "none" : "auto" }}
                onClick={handleUsePointsButtonClick}
            >
                <div className={styles.usePointsLeftWrapper}>
                    <div className={styles.pointsTitle}>
                        Trailポイントを使用{thresholdOver ? "" : <br />}
                        {`（1pt = 1円${
                            props.proposal.publicRequest?.courseType === "regular" ? "・初月のみ適用可" : ""
                        }）`}
                    </div>
                    <div className={styles.pointsBalance}>保有ポイント:{props.pointsBalance}pt</div>
                </div>
                <div className={styles.usePointsRightWrapper}>
                    {props.pointsBalance === 0 ? (
                        <div className={styles.emptyPoints}>ポイントがありません</div>
                    ) : (
                        <div className={styles.howMuchPoints}>{points}pt</div>
                    )}
                    <MdArrowForwardIos
                        className={styles.arrowIcon}
                        style={{ color: props.pointsBalance === 0 ? "#CCC" : "#333" }}
                    />
                </div>
            </button>
            <PointsModal
                pointsModalOpen={pointsModalOpen}
                pointsBalance={props.pointsBalance}
                setPoints={setPoints}
                handleClose={handlePointsModalClose}
            />
            <div className={styles.monthlyFeeWrapper}>
                <div className={styles.defaultMonthlyFeeWrapper}>
                    <div className={styles.monthlyFeeTitle}>料金</div>
                    {props.proposal.courseType === "short" ? (
                        <div className={styles.monthlyFee}>{`¥${new Intl.NumberFormat("ja-JP").format(
                            props.proposal.class.pricePerPayment - points,
                        )}`}</div>
                    ) : (
                        <div className={styles.monthlyFee}>{`¥${new Intl.NumberFormat("ja-JP").format(
                            props.proposal.class.pricePerPayment,
                        )}`}</div>
                    )}
                    {props.proposal.courseType === "short" ? (
                        <div className={styles.calculation}>
                            {`(¥${new Intl.NumberFormat("ja-JP").format(
                                props.proposal.class.pricePerPayment,
                            )} - ${points}pt利用)`}
                        </div>
                    ) : (
                        <div className={styles.perMonth}>/月</div>
                    )}
                </div>
                {props.proposal.courseType === "regular" && points > 0 && (
                    <div className={styles.firstMonthWrapper}>
                        {`初月のみ ¥${new Intl.NumberFormat("ja-JP").format(
                            props.proposal.class.pricePerPayment - points,
                        )}(¥${new Intl.NumberFormat("ja-JP").format(
                            props.proposal.class.pricePerPayment,
                        )} - ${points}pt利用)`}
                    </div>
                )}
            </div>
            <div className={styles.noticeWrapper}>
                <Link className={styles.noticeLink} to="/TermsOfService" target="_blank">
                    利用規約
                </Link>
                および
                <Link className={styles.noticeLink} to="/PrivacyPolicy" target="_blank">
                    プライバシーポリシー
                </Link>
                に同意の上、ご購入ください。
            </div>
            <Button className={styles.buyButton} onClick={handleBuyButtonClick}>
                {isProcessing ? <Processing /> : "購入する"}
            </Button>
        </div>
    );
};
