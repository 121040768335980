import { memo, useCallback } from "react";
import styles from "./index.module.scss";
import { StyledSelect } from "../StyledSelect";
import { ListItem, Select } from "@material-ui/core";

interface Props {
    lessonCalendarStartDate: Date;
    lessonCalendarUnit: "week" | "month";
    handleLessonCalendarStartDateChange: (lessonCalendarStartDate: Date) => void;
    handleUnitChange: (value: "week" | "month") => void;
}

export const LessonCalendarTop: React.VFC<Props> = memo((props) => {
    const handlePreviousWeekButtonClick = useCallback(() => {
        const newLessonCalendarStartDate = new Date(props.lessonCalendarStartDate);
        newLessonCalendarStartDate.setDate(newLessonCalendarStartDate.getDate() - 7);
        props.handleLessonCalendarStartDateChange(newLessonCalendarStartDate);
    }, [props.handleLessonCalendarStartDateChange, props.lessonCalendarStartDate]);

    const handleNextWeekButtonClick = useCallback(() => {
        const newLessonCalendarStartDate = new Date(props.lessonCalendarStartDate);
        newLessonCalendarStartDate.setDate(newLessonCalendarStartDate.getDate() + 7);
        props.handleLessonCalendarStartDateChange(newLessonCalendarStartDate);
    }, [props.handleLessonCalendarStartDateChange, props.lessonCalendarStartDate]);

    const handlePreviousMonthButtonClick = useCallback(() => {
        const newLessonCalendarStartDate = new Date(props.lessonCalendarStartDate);
        newLessonCalendarStartDate.setMonth(newLessonCalendarStartDate.getMonth() - 1);
        props.handleLessonCalendarStartDateChange(newLessonCalendarStartDate);
    }, [props.handleLessonCalendarStartDateChange, props.lessonCalendarStartDate]);

    const handleNextMonthButtonClick = useCallback(() => {
        const newLessonCalendarStartDate = new Date(props.lessonCalendarStartDate);
        newLessonCalendarStartDate.setMonth(newLessonCalendarStartDate.getMonth() + 1);
        props.handleLessonCalendarStartDateChange(newLessonCalendarStartDate);
    }, [props.handleLessonCalendarStartDateChange, props.lessonCalendarStartDate]);

    return (
        <div className={styles.lessonCalendarTopWrapper}>
            <div className={styles.lessonCalendarTarget}>
                {props.lessonCalendarStartDate.getFullYear()}年{props.lessonCalendarStartDate.getMonth() + 1}月
            </div>
            {props.lessonCalendarUnit === "month" ? (
                <div className={styles.lessonCalendarButtons}>
                    <div className={styles.previousButtonWrapper}>
                        <button className={styles.previousButton} onClick={handlePreviousMonthButtonClick}>
                            前月
                        </button>
                    </div>
                    <div className={styles.nextButtonWrapper}>
                        <button className={styles.nextButton} onClick={handleNextMonthButtonClick}>
                            次月
                        </button>
                    </div>
                </div>
            ) : (
                <div className={styles.lessonCalendarButtons}>
                    <div className={styles.previousButtonWrapper}>
                        <button className={styles.previousButton} onClick={handlePreviousWeekButtonClick}>
                            前週
                        </button>
                    </div>
                    <div className={styles.nextButtonWrapper}>
                        <button className={styles.nextButton} onClick={handleNextWeekButtonClick}>
                            次週
                        </button>
                    </div>
                </div>
            )}
            <div className={styles.selectWrapper}>
                <StyledSelect
                    name="lessonCalendarUnit"
                    value={props.lessonCalendarUnit}
                    isBold
                    smFontSize="14px"
                    handleChange={(e) => {
                        props.handleUnitChange(e.target.value as "week" | "month");
                    }}
                >
                    <ListItem
                        value="month"
                        className={styles.hourListItem}
                        style={{
                            cursor: "pointer",
                        }}
                    >
                        月
                    </ListItem>
                    <ListItem
                        value="week"
                        className={styles.hourListItem}
                        style={{
                            cursor: "pointer",
                        }}
                    >
                        週
                    </ListItem>
                </StyledSelect>
            </div>
        </div>
    );
});
