import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditIcon from "@material-ui/icons/Edit";
import { memo, useCallback, useEffect, useState } from "react";
import { GiTeacher } from "react-icons/gi";
import { TiArrowBack } from "react-icons/ti";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { ToastContents } from "@/components/Toast";
import { FileInfo } from "@/components/TrimmingModal";
import styles from "@/pages/Teacher/MyPage/index.module.scss";
import {
    Gender,
    PrivateTeacherResponse,
    PrivateUserResponse,
    SubjectResponse,
    UpdateTeacherRequestBody,
    UpdateUserRequestBody,
} from "@/store/autogenApi";
import { useUpdateTeacherMutation } from "@/store/hooks/teachers";
import { useUpdateUserMutation } from "@/store/hooks/users";
import { useUploadFiles } from "@/utils/UploadFiles";

import { BackgroundImageWrapper } from "./BackgroundImageWrapper";
import { IconImageWrapper } from "./IconImageWrapper";
import { BirthDate } from "./Items/BirthDate";
import { GenderComponent } from "./Items/Gender";
import { NickName } from "./Items/NickName";
import { Profession } from "./Items/Profession";
import { Prefecture } from "./Items/Prefecture";
import { TeachableSubjects } from "./Items/TeachableSubjects";
import { CatchCopy } from "./Items/CatchCopy";
import { BackgroundDescription } from "./Items/BackgroundDescription";
import { ContentDescription } from "./Items/ContentDescription";
import { dateToString } from "@/utils/DateUtils";
import { config } from "./Config";

interface Props {
    edit: boolean;
    existingTeacher: PrivateTeacherResponse;
    existingUser: PrivateUserResponse;
    setEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Contents: React.VFC<Props> = memo(function Contents(props) {
    const [iconImageFileInfo, setIconImageFileInfo] = useState<FileInfo | undefined>(undefined);
    const [backgroundImageFileInfo, setBackgroundImageFileInfo] = useState<FileInfo | undefined>(undefined);
    const [checked, setChecked] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [nickname, setNickname] = useState<string | undefined>(undefined);
    const [gender, setGender] = useState<Gender | undefined>(undefined);
    const [birthDate, setBirthDate] = useState<Date | undefined>(undefined);
    const [profession, setProfession] = useState<string | undefined>(undefined);
    const [prefecture, setPrefecture] = useState<string | undefined>(undefined);
    const [teachableSubjects, setTeachableSubjects] = useState<SubjectResponse[]>([]);
    const [catchCopy, setCatchCopy] = useState<string | undefined>(undefined);
    const [backgroundDescription, setBackgroundDescription] = useState<string | undefined>(undefined);
    const [contentDescription, setContentDescription] = useState<string | undefined>(undefined);

    const updateUserMutationState = useUpdateUserMutation();
    const updateTeacherMutationState = useUpdateTeacherMutation();
    const uploadFiles = useUploadFiles();

    useEffect(() => {
        setNickname(props.existingTeacher.nickname);
        setTeachableSubjects(props.existingTeacher.teachableSubjects as SubjectResponse[]);
        setCatchCopy(props.existingTeacher.catchCopy);
        setBackgroundDescription(props.existingTeacher.backgroundDescription);
        setContentDescription(props.existingTeacher.contentDescription);
        setTeachableSubjects(props.existingTeacher.teachableSubjects as SubjectResponse[]);
        setIconImageFileInfo({
            url: props.existingTeacher.iconImageUrl,
            file: undefined,
        });
        setBackgroundImageFileInfo({
            url: props.existingTeacher.backgroundImageUrl,
            file: undefined,
        });
    }, [props.existingTeacher]);

    useEffect(() => {
        setGender(props.existingUser.gender);
        setBirthDate(new Date(props.existingUser.birthDate));
        setProfession(props.existingUser.grade);
        setPrefecture(props.existingUser.prefecture);
    }, [props.existingUser]);

    const handleNicknameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setNickname(event.target.value);
    }, []);

    const handleGenderChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setGender(event.target.value as Gender);
    }, []);

    const handleBirthDateChange = useCallback((date: Date) => {
        setBirthDate(date);
    }, []);

    const handleProfessionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setProfession(event.target.value);
    }, []);

    const handlePrefectureChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setPrefecture(event.target.value);
    }, []);

    const handleCatchCopyChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCatchCopy(event.target.value);
    }, []);

    const handleBackgroundDescriptionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setBackgroundDescription(event.target.value);
    }, []);

    const handleContentDescriptionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setContentDescription(event.target.value);
    }, []);

    // const deletePhoto = () => {
    //     setFileInfo({});
    // };

    const confirm = useCallback(async () => {
        setIsProcessing(true);
        const iconImageFiles = iconImageFileInfo?.file ? [iconImageFileInfo.file] : [];
        const iconImageUrls = await uploadFiles(iconImageFiles, `users/${props.existingUser.userId}/teacher/iconImage`);
        const backGroundImageFiles = backgroundImageFileInfo?.file ? [backgroundImageFileInfo.file] : [];
        const backgroundImageUrls = await uploadFiles(
            backGroundImageFiles,
            `users/${props.existingUser.userId}/teacher/backgroundImage`,
        );
        const updateUserRequestBody: UpdateUserRequestBody = {
            userId: props.existingUser.userId,
            grade: profession,
            gender,
            prefecture,
            birthDate: birthDate ? dateToString(birthDate) : undefined,
        };
        await updateUserMutationState({
            updateUserRequestBody: updateUserRequestBody,
        });
        const updateTeacherRequestBody: UpdateTeacherRequestBody = {
            teacherId: props.existingTeacher.teacherId,
            nickname,
            catchCopy,
            backgroundDescription,
            contentDescription,
            iconImageUrl: iconImageUrls[0] === "" ? undefined : iconImageUrls[0],
            backgroundImageUrl: backgroundImageUrls[0] === "" ? undefined : backgroundImageUrls[0],
            teachableSubjectIds: teachableSubjects.map((subject) => subject.subjectId),
        };
        await updateTeacherMutationState({
            updateTeacherRequestBody: updateTeacherRequestBody,
        });
        toast(<ToastContents title="先生プロフィール更新完了" isCompleted />);
        setIsProcessing(false);
        props.setEdit(false);
        scrollTo(0, 0);
    }, [
        teachableSubjects,
        iconImageFileInfo,
        backgroundImageFileInfo,
        props.existingUser.userId,
        props.existingTeacher.teacherId,
        nickname,
        catchCopy,
        backgroundDescription,
        contentDescription,
        profession,
        gender,
        prefecture,
        birthDate,
        props.setEdit,
        updateUserMutationState,
        updateTeacherMutationState,
    ]);

    const editFunc = useCallback(() => {
        if (props.edit) {
            const targetTeacherParams = {
                nickname,
                catchCopy,
                backgroundDescription,
                contentDescription,
            };
            const targetUserParams = {
                gender,
                birthDate: birthDate ? dateToString(birthDate) : undefined,
                grade: profession,
                prefecture,
            };
            const teacherLimitValidation = Object.entries(targetTeacherParams).every(([key, value]) => {
                const limitNumber = config[key as keyof UpdateTeacherRequestBody];
                if (limitNumber && value) {
                    return 0 < value.length && value.length <= limitNumber;
                } else {
                    return false;
                }
            });
            const userLimitValidation = Object.entries(targetUserParams).every(([key, value]) => {
                const limitNumber = config[key as keyof UpdateUserRequestBody];
                if (limitNumber && value && typeof value == "string") {
                    return 0 < value.length && value.length <= limitNumber;
                } else if (value) {
                    return true;
                } else {
                    return false;
                }
            });
            if (teacherLimitValidation && userLimitValidation) {
                confirm();
            } else {
                setChecked(true);
                window.scrollTo(0, 0);
            }
        } else {
            props.setEdit(true);
        }
    }, [
        props.edit,
        props.setEdit,
        confirm,
        nickname,
        catchCopy,
        backgroundDescription,
        contentDescription,
        gender,
        birthDate,
        profession,
        prefecture,
    ]);

    const history = useHistory();
    const handleTeacherDetailsButtonClick = useCallback(() => {
        history.push(`/TeacherDetails/${props.existingTeacher.teacherId}`);
    }, [history, props.existingTeacher.teacherId]);

    const handleBackButtonClick = useCallback(() => {
        setIconImageFileInfo(undefined);
        setBackgroundImageFileInfo(undefined);
        props.setEdit(false);
    }, []);

    return (
        <div className={styles.accountContents}>
            <div className={styles.centerDiv} style={{ margin: props.edit ? "0 auto" : "0 auto" }}>
                <div className={styles.infoWrapper} style={{ display: props.edit ? "block" : "flex" }}>
                    <div className={styles.profileWrapper}>
                        {props.edit && (
                            <>
                                <Button className={styles.backButton} onClick={handleBackButtonClick}>
                                    <div className={styles.backDiv}>
                                        <TiArrowBack className={styles.backIcon} />
                                        <span className={styles.text}>戻る</span>
                                    </div>
                                </Button>
                                <div className={styles.editTitle}>プロフィール編集</div>
                                {checked && (
                                    <div className={styles.error}>
                                        入力に不備があります。修正してもう一度「確定」を押してください。
                                    </div>
                                )}
                            </>
                        )}
                        <ul
                            className={styles.profileList}
                            style={{
                                border: props.edit ? "1px solid #CCC" : "none",
                                boxShadow: props.edit ? "rgba(0, 0, 0, 0.1) 0px 4px 12px" : "none",
                            }}
                        >
                            {props.edit && (
                                <>
                                    <IconImageWrapper
                                        educationalBackground={props.existingTeacher.educationalBackground}
                                        identificationStatus={props.existingTeacher.identificationStatus}
                                        isEducationalBackgroundIdentified={
                                            props.existingTeacher.isEducationalBackgroundVerified
                                        }
                                        zoomAccountStatus={props.existingTeacher.zoomAccountStatus}
                                        edit={props.edit}
                                        checked={checked}
                                        iconImageFileInfo={iconImageFileInfo}
                                        existingIconImageUrl={props.existingTeacher.iconImageUrl}
                                        existingIconImageObjectKey={props.existingTeacher.iconImageObjectKey}
                                        setIconImageFileInfo={setIconImageFileInfo}
                                        courseLength={0}
                                    />
                                    <BackgroundImageWrapper
                                        edit={props.edit}
                                        checked={checked}
                                        backgroundImageFileInfo={backgroundImageFileInfo}
                                        existingBackgroundImageUrl={props.existingTeacher.backgroundImageUrl}
                                        existingBackgroundImageObjectKey={
                                            props.existingTeacher.backgroundImageObjectKey
                                        }
                                        setBackgroundImageFileInfo={setBackgroundImageFileInfo}
                                    />
                                </>
                            )}
                            <NickName
                                handleChange={handleNicknameChange}
                                checked={checked}
                                edit={props.edit}
                                value={nickname}
                            />
                            <GenderComponent
                                handleChange={handleGenderChange}
                                checked={checked}
                                edit={props.edit}
                                value={gender}
                            />
                            <BirthDate
                                handleBirthDateChange={handleBirthDateChange}
                                checked={checked}
                                edit={props.edit}
                                birthDate={birthDate}
                            />
                            <Profession
                                handleChange={handleProfessionChange}
                                checked={checked}
                                edit={props.edit}
                                value={profession}
                            />
                            <Prefecture
                                handleChange={handlePrefectureChange}
                                checked={checked}
                                edit={props.edit}
                                value={prefecture}
                            />
                            {props.edit && (
                                <>
                                    <TeachableSubjects
                                        checked={checked}
                                        edit={props.edit}
                                        teachableSubjects={teachableSubjects}
                                        setTeachableSubjects={setTeachableSubjects}
                                    />
                                    <CatchCopy
                                        handleChange={handleCatchCopyChange}
                                        checked={checked}
                                        edit={props.edit}
                                        value={catchCopy}
                                    />
                                    <BackgroundDescription
                                        handleChange={handleBackgroundDescriptionChange}
                                        checked={checked}
                                        edit={props.edit}
                                        value={backgroundDescription}
                                    />
                                    <ContentDescription
                                        handleChange={handleContentDescriptionChange}
                                        checked={checked}
                                        edit={props.edit}
                                        value={contentDescription}
                                    />
                                </>
                            )}
                        </ul>
                    </div>
                    {!props.edit && (
                        <>
                            <IconImageWrapper
                                educationalBackground={props.existingTeacher.educationalBackground}
                                identificationStatus={props.existingTeacher.identificationStatus}
                                isEducationalBackgroundIdentified={
                                    props.existingTeacher.isEducationalBackgroundVerified
                                }
                                zoomAccountStatus={props.existingTeacher.zoomAccountStatus}
                                edit={props.edit}
                                checked={checked}
                                iconImageFileInfo={iconImageFileInfo}
                                existingIconImageUrl={props.existingTeacher.iconImageUrl}
                                existingIconImageObjectKey={props.existingTeacher.iconImageObjectKey}
                                courseLength={0}
                            />
                        </>
                    )}
                </div>
                {!props.edit && (
                    <div className={styles.middleWrapper}>
                        <ul
                            className={styles.profileList}
                            style={{
                                border: props.edit ? "1px solid #CCC" : "none",
                                boxShadow: props.edit ? "rgba(0, 0, 0, 0.1) 0px 4px 12px" : "none",
                            }}
                        >
                            <TeachableSubjects
                                checked={checked}
                                edit={props.edit}
                                teachableSubjects={teachableSubjects}
                                setTeachableSubjects={setTeachableSubjects}
                            />
                            <CatchCopy
                                handleChange={handleCatchCopyChange}
                                checked={checked}
                                edit={props.edit}
                                value={catchCopy}
                            />
                            <BackgroundDescription
                                handleChange={handleBackgroundDescriptionChange}
                                checked={checked}
                                edit={props.edit}
                                value={backgroundDescription}
                            />
                            <ContentDescription
                                handleChange={handleContentDescriptionChange}
                                checked={checked}
                                edit={props.edit}
                                value={contentDescription}
                            />
                        </ul>
                    </div>
                )}
                <Button
                    className={styles.editButton}
                    onClick={editFunc}
                    style={{
                        paddingRight: props.edit ? "20px" : "",
                        paddingLeft: props.edit ? "20px" : "",
                    }}
                >
                    {props.edit ? (
                        isProcessing ? (
                            <CircularProgress className={styles.circle} size={24} />
                        ) : (
                            "確定"
                        )
                    ) : (
                        <div className={styles.editDiv}>
                            <EditIcon className={styles.editIcon} />
                            プロフィールを編集する
                        </div>
                    )}
                </Button>
                {!props.edit && (
                    <Button className={styles.teacherDetailsButton} onClick={handleTeacherDetailsButtonClick}>
                        <GiTeacher className={styles.exitIcon} />
                        <div className={styles.checkTeacherDetails}>先生詳細ページを確認する</div>
                    </Button>
                )}
            </div>
        </div>
    );
});
