import { memo } from "react";

import styles from "./index.module.scss";
import { ButtonPair } from "@/components/ButtonPair";
import { DraftCourseResponse } from "@/store/autogenApi";
import { FormItem } from "@/components/FormItem";
import { getCourseType } from "@/utils/DraftCourseUtils";

interface Props {
    existCourse: boolean;
    draftCourse: DraftCourseResponse | undefined;
    handleDiscardDraftButtonClick: () => void;
    handleUseDraftButtonClick: (draftCourse: DraftCourseResponse | undefined) => void;
}

export const UseDraftCourseModalContents: React.VFC<Props> = memo(function UseDraftCourseModalContents(props) {
    return (
        <div className={styles.useDraftModalContents}>
            <div className={styles.noticeMessage}>
                下記の内容の下書きがあります。これを使用して講座{props.existCourse ? "編集" : "作成"}を再開しますか？
            </div>
            <ul className={styles.itemList}>
                <FormItem
                    itemTitle="講座形態"
                    itemDescription={props.draftCourse ? getCourseType(props.draftCourse) : "-"}
                    isFirstItem
                />
                <FormItem
                    itemTitle="タイトル"
                    itemDescription={props.draftCourse?.title === "" ? "-" : props.draftCourse?.title}
                />
                <FormItem itemTitle="科目">
                    {props.draftCourse?.subjects || props.draftCourse?.subjects.length === 0 ? (
                        "-"
                    ) : (
                        <ul className={styles.subjects}>
                            {props.draftCourse?.subjects.map((subject) => {
                                return <li className={styles.subject}>{subject}</li>;
                            })}
                        </ul>
                    )}
                </FormItem>
                <FormItem
                    itemTitle="講座内容の詳細"
                    itemDescription={
                        props.draftCourse?.courseDescription === "" ? "-" : props.draftCourse?.courseDescription
                    }
                />
                <FormItem
                    itemTitle="教材・必要な準備"
                    itemDescription={
                        props.draftCourse?.teachingMaterial === "" ? "-" : props.draftCourse?.teachingMaterial
                    }
                />
                <FormItem
                    itemTitle="こんな人におすすめ"
                    itemDescription={
                        props.draftCourse?.targetDescription === "" ? "-" : props.draftCourse?.targetDescription
                    }
                />
                <FormItem itemTitle="関連画像">
                    {!props.draftCourse?.thumbnailUrl ? (
                        "-"
                    ) : (
                        <img src={props.draftCourse.thumbnailUrl} className={styles.thumbnail} />
                    )}
                </FormItem>
                <FormItem
                    itemTitle="備考"
                    itemDescription={
                        props.draftCourse?.additionalDescription === "" ? "-" : props.draftCourse?.additionalDescription
                    }
                />
            </ul>
            <ButtonPair
                pairType={1}
                leftButtonContents="下書きを破棄"
                rightButtonContents="下書きを使用"
                handleLeftButtonClick={props.handleDiscardDraftButtonClick}
                handleRightButtonClick={() => {
                    props.handleUseDraftButtonClick(props.draftCourse);
                }}
            />
        </div>
    );
});
