import { useGetAddressInfoListQuery } from "@/store/hooks/csv";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { IdentificationForStripeComponent } from "@/pageComponents/Teacher/IdentificationForStripe";
import { memo } from "react";

interface Props {
    activeStep: number;
    nextStep: () => void;
}

export const IdentificationForStripe: React.VFC<Props> = memo(function IdentificationForStripe(props) {
    const addressInfoListQueryState = useGetAddressInfoListQuery();

    return (
        <QueryLoadingWrapper {...addressInfoListQueryState}>
            {(addressInfoList) => <IdentificationForStripeComponent addressInfoList={addressInfoList} />}
        </QueryLoadingWrapper>
    );
});
