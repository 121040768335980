import { memo, useCallback, useEffect } from "react";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import styles from "@/pages/Student/CreatePublicRequest/index.module.scss";
import { Mandatory } from "@/components/Tag/Mandatory";

interface Props {
    value: string;
    checked: boolean;
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    open?: boolean;
    validation: boolean;
}

export const StartDateDescription: React.VFC<Props> = memo(function StartDateDescription(props) {
    const fixPlaceholder = useCallback(() => {
        const element: HTMLInputElement | null = document.getElementById(
            "firstDateDescriptionTextArea",
        ) as HTMLInputElement;
        if (element) {
            element.placeholder = element.placeholder.replace(/\\n/g, "\n");
        }
    }, []);

    useEffect(() => {
        fixPlaceholder();
    }, []);

    return (
        <li className={styles.listItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>講座開始希望日</div>
                    {!props.open && <Mandatory />}
                </div>
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>{props.value}</div>
                ) : (
                    <>
                        <TextareaAutosize
                            required
                            placeholder={`講座開始希望日(必須300字以内)\n\n○月○日か○月○日でお願いします。`}
                            className={styles.textArea}
                            name="firstDateDescription"
                            onChange={props.handleChange}
                            minRows={5}
                            id="firstDateDescriptionTextArea"
                            value={props.value}
                        />
                        {props.checked && !props.validation && (
                            <div className={styles.helperText}>この項目は必須です。300字以内で入力してください。</div>
                        )}
                    </>
                )}
            </div>
        </li>
    );
});
