import { useSelector } from "react-redux";
import { RootState } from "@/ducks";

import { QueryLoadingWrapper } from "../../../QueryLoadingWrapper";

import { StudentAnnouncementListContents } from "./StudentAnnouncementListContents";

import { AnnouncementsTooltip } from "../AnnouncementsTooltip";
import { useGetStudentCheckedAnnouncementIdsQuery } from "@/store/hooks/students";
import { useGetStudentAnnouncementsQuery } from "@/store/hooks/announcements";

export const StudentAnnouncementList: React.VFC = () => {
    const studentId = useSelector((state: RootState) => state.jwt.studentId) as string;
    const studentCheckedAnnouncementIdsQueryState = useGetStudentCheckedAnnouncementIdsQuery(studentId);
    const studentAnnouncementsQueryState = useGetStudentAnnouncementsQuery(studentId);

    return (
        <QueryLoadingWrapper
            {...studentAnnouncementsQueryState}
            loadingComponent={
                <AnnouncementsTooltip
                    open={false}
                    title={<></>}
                    badgeContent={0}
                    handleCheckButtonClick={() => {}}
                    handleTooltipOpen={() => {}}
                    handleTooltipClose={() => {}}
                />
            }
        >
            {(announcements) => (
                <QueryLoadingWrapper
                    {...studentCheckedAnnouncementIdsQueryState}
                    loadingComponent={
                        <AnnouncementsTooltip
                            open={false}
                            title={<></>}
                            badgeContent={0}
                            handleCheckButtonClick={() => {}}
                            handleTooltipOpen={() => {}}
                            handleTooltipClose={() => {}}
                        />
                    }
                >
                    {(checkedAnnouncementIds) => {
                        const unreadAnnouncements = announcements.filter(
                            (announcement) => !checkedAnnouncementIds.includes(announcement.announcementId),
                        );
                        return <StudentAnnouncementListContents announcements={unreadAnnouncements} />;
                    }}
                </QueryLoadingWrapper>
            )}
        </QueryLoadingWrapper>
    );
};
