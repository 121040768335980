import React, { memo } from "react";

import styles from "./index.module.scss";

interface Props {
    className?: string;
    style: React.CSSProperties;
    children: React.ReactNode;
}

export const OverWrapper: React.VFC<Props> = memo(function OverWrapper(props) {
    return (
        <div className={`${styles.overWrapper} ${props.className}`} style={props.style}>
            <div className={styles.centeredContents}>{props.children}</div>
        </div>
    );
});
