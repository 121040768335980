import { useSelector } from "react-redux";

import { MyPage } from ".";
import { RootState } from "@/ducks";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { useGetBankAccountListQuery, useGetStripeAccountQuery } from "@/store/hooks/stripe";
import { ApplicationForTransferContents } from "@/pageComponents/Teacher/MyPage/ApplicationForTransfer/ApplicationForTransferContents";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { useEffect } from "react";
import { Navigation } from "@/components/Navigation";
import { useGetProceedsRecordsQuery } from "@/store/hooks/proceedsRecords";
import { useGetPublicTeacherQuery } from "@/store/hooks/teachers";

const NAVIGATION_LIST: Navigation[] = [
    { title: "マイページ", url: "/Teacher/MyPage/Profile" },
    { title: "振込申請", url: "/Teacher/MyPage/ApplicationForTransfer" },
];

export const ApplicationForTransfer: React.VFC = () => {
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);

    const proceedsRecordsQueryState = useGetProceedsRecordsQuery(teacherId);
    const bankAccountListQueryState = useGetBankAccountListQuery(teacherId);
    const stripeAccountQueryState = useGetStripeAccountQuery(teacherId);
    const teacherQueryState = useGetPublicTeacherQuery(teacherId);

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    return (
        <MyPage title="振込申請" activeTab="applicationForTransfer">
            <QueryLoadingWrapper {...proceedsRecordsQueryState}>
                {(proceedsRecords) => {
                    const balance = proceedsRecords.reduce((acc, cur) => acc + cur.amount, 0);
                    return (
                        <QueryLoadingWrapper {...bankAccountListQueryState}>
                            {(bankAccountList) => (
                                <QueryLoadingWrapper {...stripeAccountQueryState}>
                                    {(stripeAccount) => (
                                        <QueryLoadingWrapper {...teacherQueryState}>
                                            {(teacher) => (
                                                <ApplicationForTransferContents
                                                    teacher={teacher}
                                                    balance={balance}
                                                    bankAccountList={bankAccountList}
                                                    stripeAccount={stripeAccount}
                                                />
                                            )}
                                        </QueryLoadingWrapper>
                                    )}
                                </QueryLoadingWrapper>
                            )}
                        </QueryLoadingWrapper>
                    );
                }}
            </QueryLoadingWrapper>
        </MyPage>
    );
};
