import { memo, useCallback, useEffect, useState } from "react";

import { Modal } from "@material-ui/core";

import styles from "./index.module.scss";
import { RiCloseFill } from "react-icons/ri";
import { PublicStudentResponse, PublicTeacherResponse } from "@/store/autogenApi";
import { TeacherInfo } from "./TeacherInfo";
import { StudentInfo } from "./StudentInfo";
import { QueryLoadingWrapper } from "../QueryLoadingWrapper";
import { useGetPublicUserQuery } from "@/store/hooks/users";

interface Props {
    openedTarget: PublicStudentResponse | PublicTeacherResponse;
    hideBackdrop?: boolean;
    isChatVisible?: boolean;
    zIndex: number;
    handleClose: () => void;
}

export const UserInfoModal: React.VFC<Props> = memo(function UserInfoModal(props) {
    const [modalHeight, setModalHeight] = useState<number>(0);
    const [chatModalOpen, setChatModalOpen] = useState<boolean>(false);

    const changeModalHeight = useCallback(() => {
        const innerHeight = window.innerHeight;
        setModalHeight(innerHeight * 0.75);
    }, []);

    useEffect(() => {
        changeModalHeight();
        window.addEventListener("resize", () => {
            changeModalHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalHeight();
            });
        };
    }, []);
    const userQueryState = useGetPublicUserQuery(props.openedTarget.userId);

    const isStudent = useCallback((): boolean => {
        const target = props.openedTarget as PublicStudentResponse;
        return target?.studentId != undefined;
    }, [props.openedTarget]);

    const handleChatModalOpen = useCallback(() => {
        setChatModalOpen(true);
    }, []);

    const handleChatModalClose = useCallback(() => {
        setChatModalOpen(false);
    }, []);

    return (
        // user取得の関係で、呼び出し元でopenを制御する
        <Modal
            open={true}
            // なぜかzIndexはstyleで指定しないとおかしくなる
            style={{ height: modalHeight, zIndex: props.zIndex }}
            onClose={props.handleClose}
            hideBackdrop={props.hideBackdrop}
        >
            <div className={styles.modalContents}>
                <button onClick={props.handleClose} className={styles.closeButton}>
                    <RiCloseFill className={styles.closeIcon} />
                </button>
                <QueryLoadingWrapper {...userQueryState}>
                    {(user) => (
                        <>
                            {isStudent() ? (
                                <StudentInfo
                                    user={user}
                                    student={props.openedTarget as PublicStudentResponse}
                                    modalHeight={modalHeight}
                                    isChatVisible={props.isChatVisible}
                                    chatModalOpen={chatModalOpen}
                                    handleChatModalOpen={handleChatModalOpen}
                                    handleChatModalClose={handleChatModalClose}
                                />
                            ) : (
                                <TeacherInfo
                                    user={user}
                                    teacher={props.openedTarget as PublicTeacherResponse}
                                    modalHeight={modalHeight}
                                    chatModalOpen={chatModalOpen}
                                    isChatVisible={props.isChatVisible}
                                    handleChatModalOpen={handleChatModalOpen}
                                    handleChatModalClose={handleChatModalClose}
                                />
                            )}
                        </>
                    )}
                </QueryLoadingWrapper>
            </div>
        </Modal>
    );
});
