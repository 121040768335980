import styles from "./index.module.scss";
import { Button } from "@material-ui/core";
import { FileIcon } from "./FileIcon";
import { memo, useCallback } from "react";
import { ImageFromS3 } from "@/components/atoms/images/ImageFromS3";

interface Props {
    fileUrl: string;
    fileObjectKey?: string;
    idx: number;
}

export const FileContents: React.VFC<Props> = memo(function FileContents(props) {
    const apngRegex = /.apng$/;
    const avifRegex = /.avif$/;
    const gifRegex = /.gif$/;
    const jpgRegex = /.jpg$/;
    const jpegRegex = /.jpeg$/;
    const jfifRegex = /.jfif$/;
    const pjpegRegex = /.pjpeg$/;
    const pjpRegex = /.pjp$/;
    const pngRegex = /.png$/;
    const svgRegex = /.svg$/;
    const webpRegex = /.webp$/;
    const bmpRegex = /.bmp$/;
    const icoRegex = /.ico$/;
    const curRegex = /.cur$/;
    const tifRegex = /.tif$/;
    const tiffRegex = /.tiff$/;

    const getIsImage = useCallback(() => {
        return (
            apngRegex.test(props.fileUrl) ||
            avifRegex.test(props.fileUrl) ||
            gifRegex.test(props.fileUrl) ||
            jpgRegex.test(props.fileUrl) ||
            jpegRegex.test(props.fileUrl) ||
            jfifRegex.test(props.fileUrl) ||
            pjpegRegex.test(props.fileUrl) ||
            pjpRegex.test(props.fileUrl) ||
            pngRegex.test(props.fileUrl) ||
            svgRegex.test(props.fileUrl) ||
            webpRegex.test(props.fileUrl) ||
            bmpRegex.test(props.fileUrl) ||
            icoRegex.test(props.fileUrl) ||
            curRegex.test(props.fileUrl) ||
            tifRegex.test(props.fileUrl) ||
            tiffRegex.test(props.fileUrl)
        );
    }, [props.fileUrl]);

    const getFileNameBody = useCallback(() => {
        const fileObjectKeyArray = props.fileUrl.split("/");
        const fileName = fileObjectKeyArray[fileObjectKeyArray.length - 1];
        const fileNameBody = fileName.split(".").slice(0, -1).join(".");
        return fileNameBody;
    }, [props.fileUrl]);

    const getFileExtension = useCallback(() => {
        const fileObjectKeyArray = props.fileUrl.split("/");
        const fileName = fileObjectKeyArray[fileObjectKeyArray.length - 1];
        const fileExtension = fileName.split(".");
        if (fileExtension.length > 1) {
            return `.${fileExtension[fileExtension.length - 1]}`;
        }
        return "";
    }, [props.fileUrl]);

    return (
        <>
            {getIsImage() ? (
                <li key={props.fileUrl} className={styles.file}>
                    <ImageFromS3 url={props.fileUrl} objectKey={props.fileObjectKey} className={styles.image} />
                </li>
            ) : (
                <li key={props.fileUrl} className={styles.file}>
                    <Button href={props.fileUrl} target="_blank" className={styles.downloadButton}>
                        <FileIcon fileUrl={props.fileUrl} />
                        <div className={styles.fileNameBody}>{getFileNameBody()}</div>
                        <div className={styles.fileExtension}>{getFileExtension()}</div>
                    </Button>
                </li>
            )}
        </>
    );
});
