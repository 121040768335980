import { memo, useCallback, useEffect, useRef, useState } from "react";
import { MenuItem } from "@material-ui/core";
import styles from "./index.module.scss";
import { WHAT_DAY_LIST } from "@/utils/WhatDayList";
import { getFullDate } from "@/utils/GetFullDate";
import { StyledSelect } from "@/components/StyledSelect";
import { ListItem } from "../ListItem";
import { ErrorMessage } from "@/components/ErrorMessage";

interface Props {
    applicationPeriod?: number;
    courseType?: string;
    isChecked: boolean;
    isConfirmMode?: boolean;
    validation: boolean;
    handleChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
}

export const ApplicationPeriod: React.VFC<Props> = memo(function ApplicationPeriod(props) {
    const [deadline, setDeadline] = useState<Date | undefined>(undefined);
    const intervalRef = useRef<any>(null);

    const checkDeadline = useCallback(() => {
        if (!props.applicationPeriod) return;
        const newDeadline = new Date();
        newDeadline.setDate(newDeadline.getDate() + props.applicationPeriod);
        intervalRef.current = setInterval(() => {
            setDeadline(newDeadline);
        }, 1000);
    }, [props.applicationPeriod]);

    useEffect(() => {
        checkDeadline();
        return () => {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        };
    }, [checkDeadline]);

    const getDeadline = useCallback(() => {
        if (props.applicationPeriod) {
            const deadline = new Date();
            deadline.setDate(deadline.getDate() + props.applicationPeriod);
            const formattedDeadline = `${deadline.getFullYear()}年${deadline.getMonth() + 1}月${deadline.getDate()}日(${
                WHAT_DAY_LIST[deadline.getDay()]
            }) ${deadline.getHours()}:${("00" + deadline.getMinutes()).slice(-2)}`;
            return formattedDeadline;
        }
    }, [props.applicationPeriod]);

    return (
        <ListItem
            isConfirmMode={props.isConfirmMode}
            title="募集期間"
            borderTop
            inputModeContents={
                <>
                    <div className={styles.selectWrapper}>
                        <StyledSelect
                            name="applicationPeriod"
                            value={props.applicationPeriod ? props.applicationPeriod : 0}
                            handleChange={props.handleChange}
                        >
                            <MenuItem key={0} value={0} className={`${styles.numberItem} ${styles.zero}`} />
                            {[...Array(10)].map((_, i) => (
                                <MenuItem key={i} value={i + 1} className={styles.numberItem}>
                                    {i + 1}
                                </MenuItem>
                            ))}
                        </StyledSelect>
                        日間
                    </div>
                    {deadline != undefined && (
                        <>
                            <div className={styles.deadline}>{`締め切り時刻：${getFullDate(deadline)}`}</div>
                            <div
                                className={styles.deadline}
                            >{`※締め切り時刻は目安であり、通信環境やシステム状況などから、誤差が生じる場合がありますことを予めご理解ください。`}</div>
                        </>
                    )}
                    <ErrorMessage
                        when={props.isChecked && !props.validation}
                        content="この項目は必須です。選択してください。"
                    />
                </>
            }
            confirmModeContents={
                <>
                    <div>{props.applicationPeriod}日間</div>
                    <div>締め切り時刻：{getFullDate(deadline)}</div>
                </>
            }
        />
    );
});
