import TextField from "@material-ui/core/TextField";
import { memo, useCallback, useState } from "react";
import { Mandatory } from "@/components/Tag/Mandatory";

import styles from "@/pages/Teacher/CreateCourse/index.module.scss";
import { CourseType, CreateLessonRequestParams } from "@/store/autogenApi";
import { getHowMuchTime, getPricePerPaymentForShortCourse } from "@/utils/ClassUtils";
import { AboutFeeModal } from "@/components/AboutFeeModal";
import { PlainButton } from "@/components/PlainButton";

interface Props {
    open?: boolean;
    courseType: CourseType;
    howManyDays: number;
    isChecked: boolean;
    pricePerHour: number;
    lessons: CreateLessonRequestParams[];
    priceRange: number[];
    validation: boolean;
    existStudent?: boolean;
    handleChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
}

export const PricePerHour: React.VFC<Props> = memo(function PricePerHour(props) {
    const [aboutFeeModalOpen, setAboutFeeModalOpen] = useState<boolean>(false);
    const handleClick = useCallback(() => {
        setAboutFeeModalOpen(true);
    }, []);
    const handleClose = useCallback(() => {
        setAboutFeeModalOpen(false);
    }, []);
    return (
        <li className={styles.listItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>時間単価：</div>
                    {!props.open && <Mandatory />}
                </div>
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>
                        <div className={styles.perHour}>
                            <div className={styles.price}>
                                ￥
                                {typeof props.pricePerHour === "number" &&
                                    new Intl.NumberFormat("ja-JP").format(Math.floor(props.pricePerHour))}
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        {props.existStudent ? (
                            <div className={styles.existStudentWrapper}>
                                <div className={styles.existStudent}>
                                    既に参加者がいるため、この項目は変更できません。
                                </div>
                                <div className={styles.existingValue}>
                                    時間単価： :¥
                                    {typeof props.pricePerHour === "number" &&
                                        new Intl.NumberFormat("ja-JP").format(Math.floor(props.pricePerHour))}
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className={styles.priceWrapper}>
                                    <div className={styles.perHourWrapper}>
                                        {/* <div className={styles.textWrapper}>
                                            <div className={styles.priceTitle}>
                                                {props.courseType === "short"
                                                    ? `1日程(${props.howManyDays}日間の授業)合計で`
                                                    : "1時間あたり"}
                                            </div>
                                            <div className={styles.range}>（￥1000 ~ ￥100,000）</div>
                                        </div> */}
                                        <div className={styles.price}>
                                            <span className={styles.yen}>￥</span>
                                            <TextField
                                                variant="outlined"
                                                required
                                                placeholder="0"
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                className={styles.textField}
                                                onChange={props.handleChange}
                                                onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                                                type="number"
                                                value={props.pricePerHour ? props.pricePerHour : ""}
                                            />
                                            <div className={styles.limit}>{`(${new Intl.NumberFormat("ja-JP").format(
                                                Math.floor(props.priceRange[0]),
                                            )}~${new Intl.NumberFormat("ja-JP").format(
                                                Math.floor(props.priceRange[1]),
                                            )})`}</div>
                                        </div>
                                    </div>
                                </div>
                                {props.isChecked && !props.validation && (
                                    <div className={styles.helperText}>
                                        この項目は必須です。{props.priceRange[0]}以上{props.priceRange[0]}
                                        以下で選択してください。
                                    </div>
                                )}
                                <div className={styles.priceInfoWrapper}>
                                    <div className={styles.howManyMinutesWrapper}>
                                        <div className={styles.howManyMinutesTitle}>合計授業時間：</div>
                                        <div className={styles.howManyMinutes}>{getHowMuchTime(props.lessons)}</div>
                                    </div>
                                    <div className={styles.pricePerPaymentWrapper}>
                                        <div className={styles.pricePerPaymentTitle}>講座全体で：</div>
                                        <div className={styles.pricePerPayment}>
                                            {`¥${new Intl.NumberFormat("ja-JP").format(
                                                getPricePerPaymentForShortCourse(props.pricePerHour, props.lessons),
                                            )}`}
                                        </div>
                                    </div>
                                </div>
                                <PlainButton
                                    text="手数料について確認"
                                    handleClick={handleClick}
                                    className={styles.aboutFeeButton}
                                />
                                <AboutFeeModal open={aboutFeeModalOpen} handleClose={handleClose} />
                            </>
                        )}
                    </>
                )}
            </div>
        </li>
    );
});
