import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import styles from "./index.module.scss";

import defaultCourseImage from "@/images/defaultCourseImage.jpg";
import { ClassResponse } from "@/store/autogenApi";
import { useCallback } from "react";
import { getLessonTime } from "@/utils/LessonUtils";
import { ImageFromS3 } from "@/components/atoms/images/ImageFromS3";

interface Props {
    class?: ClassResponse;
    isHidden?: boolean;
    isFlex?: boolean;
    handleCourseCardClick: (lesson: ClassResponse) => void;
}

export const ReservedCourseCard: React.VFC<Props> = (props) => {
    const getNextLessonTime = useCallback(() => {
        const nextLesson = props.class?.lessons.find((lesson) => new Date(lesson.endTime) > new Date());
        if (nextLesson == undefined) return "なし";
        return getLessonTime(nextLesson);
    }, [props.class]);
    return (
        <Card
            className={styles.card}
            style={{
                visibility: props.isHidden ? "hidden" : "visible",
                flex: props.isFlex ? 1 : "none",
                width: props.isFlex ? "auto" : "150px",
            }}
            onClick={() => {
                if (!props.class) return;
                props.handleCourseCardClick(props.class);
            }}
        >
            <ImageFromS3
                url={props.class?.course?.thumbnailUrl || defaultCourseImage}
                objectKey={props.class?.course?.thumbnailObjectKey}
                className={styles.media}
            />
            <CardContent className={styles.cardContents}>
                <Typography variant="body2" color="textSecondary" component="div" className={styles.title}>
                    {props.class?.course?.title}
                </Typography>
                <div className={styles.nextLessonTime}>次回：{getNextLessonTime()}</div>
            </CardContent>
        </Card>
    );
};
