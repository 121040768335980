import { ListItem } from "./ListItem";

import styles from "./index.module.scss";
import { PublicTeacherResponse } from "@/store/autogenApi";
import { TeacherDetailsButton } from "../../TeacherDetailsButton";

interface Props {
    teacher: PublicTeacherResponse | undefined;
}

export const TeacherInfo: React.VFC<Props> = (props) => {
    return (
        <ListItem title="担当の先生" borderBottom>
            <>{props.teacher && <TeacherDetailsButton teacher={props.teacher} />}</>
        </ListItem>
    );
};
