import { DraftCourseResponse } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions } from "./utils/types";

export const useGetMyDraftCoursesQuery = (teacherId: string) => {
    const result = defaultApi.endpoints.getMyDraftCourses.useQuery({ teacherId: teacherId });
    return useQueryResult(result, (response: DraftCourseResponse[]) => response);
};

export const useGetDraftCourseQuery = (draftCourseId: string) => {
    const result = defaultApi.endpoints.getDraftCourse.useQuery({ draftCourseId: draftCourseId });
    return useQueryResult(result, (response: DraftCourseResponse) => response);
};

export const useCreateDraftCourseMutation = (options?: UseMutationOptions) => {
    const [createDraftCourse] = defaultApi.endpoints.createDraftCourse.useMutation();
    return useMutationFunctionWrapper(createDraftCourse, options?.errorMessages);
};

export const useUpdateDraftCourseMutation = (options?: UseMutationOptions) => {
    const [updateDraftCourse] = defaultApi.endpoints.updateDraftCourse.useMutation();
    return useMutationFunctionWrapper(updateDraftCourse, options?.errorMessages);
};

export const useDeleteDraftCourseMutation = (options?: UseMutationOptions) => {
    const [deleteDraftCourse] = defaultApi.endpoints.deleteDraftCourse.useMutation();
    return useMutationFunctionWrapper(deleteDraftCourse, options?.errorMessages);
};
