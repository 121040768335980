import { useState, useEffect, memo, useCallback } from "react";
import { Button } from "@material-ui/core";
import {
    HIGH_SCHOOL_SUBJECT_CONFIG,
    JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG,
    ELEMENTARY_SCHOOL_SUBJECT_CONFIG,
    UNIVERSITY_SUBJECT_CONFIG,
} from "@/SubjectConfig";
import styles from "@/pages/Common/CourseDetails/index.module.scss";
import { SubjectResponse } from "@/store/autogenApi";
import { useHistory } from "react-router";

interface Props {
    subjects: SubjectResponse[];
}

export const Subjects: React.VFC<Props> = memo(function (props) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOver, setIsOver] = useState<boolean>(false);

    const showMore = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const flowNode = document.getElementById("subjects");
        const scrollHeight = flowNode?.scrollHeight;
        if (scrollHeight && scrollHeight > 200) {
            setIsOver(true);
        }
    }, [props.subjects]);

    const getConfig = useCallback((level?: string) => {
        switch (level) {
            case "university":
                return UNIVERSITY_SUBJECT_CONFIG;
            case "highSchool":
                return HIGH_SCHOOL_SUBJECT_CONFIG;
            case "juniorHighSchool":
                return JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG;
            case "elementarySchool":
                return ELEMENTARY_SCHOOL_SUBJECT_CONFIG;
        }
    }, []);

    const getLevelName = useCallback((level?: string) => {
        switch (level) {
            case "university":
                return "大学";
            case "highSchool":
                return "高校";
            case "juniorHighSchool":
                return "中学校";
            case "elementarySchool":
                return "小学校";
        }
    }, []);

    const getSubjectString = useCallback(
        (subject: SubjectResponse) => {
            const config = getConfig(subject.level);
            const schoolName = getLevelName(subject.level);
            if (config && schoolName && subject.category) {
                const categoryObject = config[subject.category];
                const categoryName = categoryObject.label;
                const subCategoryName =
                    categoryObject.sub && subject.subCategory
                        ? categoryObject.sub[subject.subCategory].label
                        : undefined;
                if (subCategoryName) {
                    return `${schoolName} - ${categoryName} - ${subCategoryName}`;
                } else {
                    return `${schoolName} - ${categoryName}`;
                }
            }
        },
        [getConfig, getLevelName],
    );

    const history = useHistory();

    const handleSubjectButtonClick = useCallback((subject: SubjectResponse) => {
        const { level, category, subCategory } = subject;
        history.push(`/SearchResults?searchType=course&level=${level}&category=${category}&subCategory=${subCategory}`);
    }, []);

    return (
        <div className={styles.itemWrapper}>
            <div className={styles.itemHeadingWrapper}>
                <span className={styles.itemHeading}>科目</span>
            </div>
            <div
                className={styles.subjects}
                style={{ overflowY: isOpen ? "auto" : "hidden", maxHeight: isOpen ? "none" : "200px" }}
                id="subjects"
            >
                {props.subjects.map((subject) => (
                    <button
                        className={styles.subject}
                        key={subject.subjectId}
                        onClick={() => {
                            handleSubjectButtonClick(subject);
                        }}
                    >
                        {getSubjectString(subject)}
                    </button>
                ))}
            </div>
            {isOver && (
                <div className={styles.moreButtonWrapper} style={{ position: isOpen ? "inherit" : "absolute" }}>
                    <Button className={styles.moreButton} onClick={showMore}>
                        {isOpen ? "閉じる" : "すべて見る"}
                    </Button>
                </div>
            )}
        </div>
    );
});
