import { Modal } from "@material-ui/core";
import React, { memo, useCallback, useEffect, useState } from "react";
import styles from "./index.module.scss";
import {
    CreateBaseScheduleParams,
    CreateClassRequestBody,
    CreateLessonRequestParams,
    CreateProposalRequestBody,
    LessonResponse,
    ProposalResponse,
    UpdateProposalRequestBody,
} from "@/store/autogenApi";
import { dateToString } from "@/utils/DateUtils";
import { zenkakuToHankaku } from "@/utils/ZenkakuToHankaku";
import { ProposalModalContents } from "./ProposalModalContents";
import { getExistLessonConflict, getExistLessonConflictWithinLessons } from "@/utils/LessonUtils";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { toast } from "react-toastify";
import { ToastContents } from "@/components/Toast";
import { useCreateProposalMutation, useUpdateProposalMutation } from "@/store/hooks/proposals";
import { getPricePerPaymentForShortCourse } from "@/utils/ClassUtils";
import { getPricePerMonth } from "@/utils/BaseScheduleUtils";

interface Props {
    courseType: string;
    publicRequestId: string;
    studentId: string;
    proposalModalOpen: boolean;
    existingLessons: LessonResponse[];
    targetProposal: ProposalResponse | undefined;
    handleProposalModalClose: () => void;
}

export const ProposalModal: React.VFC<Props> = memo(function ProposalModal(props) {
    const [message, setMessage] = useState<string>("");
    const [pricePerHour, setPricePerHour] = useState<number>(0);
    const [pricePerPayment, setPricePerPayment] = useState<number | undefined>(undefined);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [proposalModalStep, setProposalModalStep] = useState<number>(1);
    const [lessons, setLessons] = useState<CreateLessonRequestParams[]>([]);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [baseSchedules, setBaseSchedules] = useState<CreateBaseScheduleParams[]>([]);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [conflictWithinLessonsValidation, setConflictWithinLessonsValidation] = useState<boolean>(false);
    const [conflictWithOtherCourseLessonValidation, setConflictWithOtherCourseLessonValidation] =
        useState<boolean>(false);

    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);

    const createProposal = useCreateProposalMutation();
    const updateProposal = useUpdateProposalMutation();

    useEffect(() => {
        if (props.courseType === "regular") {
            const calculatedPricePerPayment = getPricePerMonth(baseSchedules, pricePerHour);
            setPricePerPayment(calculatedPricePerPayment);
        } else {
            const calculatedPricePerPayment = getPricePerPaymentForShortCourse(pricePerHour, lessons);
            setPricePerPayment(calculatedPricePerPayment);
        }
    }, [props.courseType, pricePerHour, lessons, baseSchedules]);

    useEffect(() => {
        if (props.courseType === "short") {
            const existLessonConflictWithinLessons = getExistLessonConflictWithinLessons(lessons);
            setConflictWithinLessonsValidation(!existLessonConflictWithinLessons);
            const existLessonConflictWithOtherCourse = getExistLessonConflict(lessons, props.existingLessons);
            setConflictWithOtherCourseLessonValidation(!existLessonConflictWithOtherCourse);
        }
    }, [props.existingLessons, lessons, props.courseType]);

    useEffect(() => {
        if (!props.targetProposal) return;
        setMessage(props.targetProposal.content);
        const targetClass = props.targetProposal.class;
        if (!targetClass) return;
        setPricePerHour(targetClass.pricePerHour);
        if (props.courseType === "short") {
            if (!targetClass?.lessons) return;
            const modifiedLessons = targetClass.lessons.map((lesson) => ({
                startTime: lesson.startTime,
                endTime: lesson.endTime,
            }));
            setLessons(modifiedLessons);
        } else {
            if (!props.targetProposal.startDate || !props.targetProposal.baseSchedules) return;
            setStartDate(new Date(props.targetProposal.startDate));
            const baseSchedules = props.targetProposal.baseSchedules.map((baseSchedule) => {
                const baseScheduleParams: CreateBaseScheduleParams = {
                    isActive: false,
                    howManyMinutes: baseSchedule.howManyMinutes,
                    startMinute: baseSchedule.startMinute,
                    startHour: baseSchedule.startHour,
                    dayOfWeekIndexes: baseSchedule.dayOfWeekIndexes,
                    howManyTimes: baseSchedule.howManyTimes,
                    repetitionUnit: baseSchedule.repetitionUnit,
                    repetitionUnitNumber: baseSchedule.repetitionUnitNumber,
                };
                return baseScheduleParams;
            });
            setBaseSchedules(baseSchedules);
        }
    }, [props.targetProposal, props.courseType]);

    const handlePricePerHourChange = useCallback((e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const pricePerHour = e.target.value as number;
        const fixedPricePerHour = zenkakuToHankaku(pricePerHour.toString());
        const result = Number(fixedPricePerHour);
        setPricePerHour(result);
    }, []);
    const getPricePerHourValidation = useCallback(() => {
        if (100 <= pricePerPayment && pricePerPayment <= 100000) {
            return true;
        } else {
            return false;
        }
    }, [pricePerPayment]);

    const handleMessageChange = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        const message = e.target.value as string;
        setMessage(message);
    }, []);
    const getMessageValidation = useCallback(() => {
        if (10 <= message.length && message.length <= 500) {
            return true;
        } else {
            return false;
        }
    }, [message]);

    const getUnit = useCallback(() => {
        if (props.courseType === "short") {
            return "講座全体で";
        } else if (props.courseType === "regular") {
            return "1時間あたり";
        }
    }, [props.courseType]);

    const handleProposalModalClose = useCallback(() => {
        props.handleProposalModalClose();
        setProposalModalStep(1);
    }, [props.handleProposalModalClose]);

    const handleConfirmButtonClick = useCallback(() => {
        (async () => {
            if (teacherId && pricePerPayment) {
                setIsProcessing(true);
                if (props.targetProposal) {
                    let updateProposalRequestBody: UpdateProposalRequestBody;
                    if (props.courseType === "short") {
                        const createClassesParams: CreateClassRequestBody = {
                            lessons,
                            pricePerHour,
                            pricePerPayment,
                        };
                        updateProposalRequestBody = {
                            proposalId: props.targetProposal.proposalId,
                            teacherId,
                            publicRequestId: props.publicRequestId,
                            content: message,
                            createClassParams: createClassesParams,
                        };
                    } else {
                        if (!startDate) return;
                        const createClassesParams: CreateClassRequestBody = {
                            pricePerHour,
                            pricePerPayment,
                        };
                        updateProposalRequestBody = {
                            proposalId: props.targetProposal.proposalId,
                            teacherId,
                            publicRequestId: props.publicRequestId,
                            content: message,
                            startDate: dateToString(startDate),
                            createClassParams: createClassesParams,
                            createBaseScheduleParamsList: baseSchedules,
                        };
                    }
                    const { isSuccess } = await updateProposal({
                        updateProposalRequestBody: updateProposalRequestBody,
                    });
                    setIsProcessing(false);
                    setProposalModalStep(1);
                    handleProposalModalClose();
                    if (isSuccess) {
                        toast(<ToastContents title="提案更新完了" isCompleted />);
                    } else {
                        toast(<ToastContents title="提案更新失敗" isFailed />);
                    }
                } else {
                    let createProposalRequestBody: CreateProposalRequestBody;
                    if (props.courseType === "short") {
                        const createClassesParams: CreateClassRequestBody = {
                            lessons,
                            pricePerHour,
                            pricePerPayment,
                        };
                        createProposalRequestBody = {
                            teacherId,
                            publicRequestId: props.publicRequestId,
                            content: message,
                            createClassParams: createClassesParams,
                        };
                    } else {
                        if (!startDate) return;
                        const createClassesParams: CreateClassRequestBody = {
                            pricePerHour,
                            pricePerPayment,
                        };
                        createProposalRequestBody = {
                            teacherId,
                            publicRequestId: props.publicRequestId,
                            content: message,
                            startDate: dateToString(startDate),
                            createClassParams: createClassesParams,
                            createBaseScheduleParamsList: baseSchedules,
                        };
                    }
                    const { isSuccess } = await createProposal({
                        createProposalRequestBody: createProposalRequestBody,
                    });
                    setIsProcessing(false);
                    setProposalModalStep(1);
                    handleProposalModalClose();
                    if (isSuccess) {
                        toast(<ToastContents title="提案作成完了" isCompleted />);
                    } else {
                        toast(<ToastContents title="提案作成失敗" isFailed />);
                    }
                }
            }
        })();
    }, [
        props.targetProposal,
        teacherId,
        pricePerPayment,
        message,
        props.publicRequestId,
        lessons,
        startDate,
        baseSchedules,
        props.courseType,
        handleProposalModalClose,
    ]);

    const handleStartDateChange = useCallback((date: Date | null) => {
        if (date) {
            const newDate = new Date(date);
            newDate.setHours(0);
            newDate.setMinutes(0);
            newDate.setSeconds(0);
            newDate.setMilliseconds(0);
            setStartDate(newDate);
        }
    }, []);
    const getStartDateValidation = useCallback(() => {
        if (startDate) {
            if (startDate.getTime() >= new Date().getTime()) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }, [startDate]);

    const handleNextButtonClick = useCallback(() => {
        setIsChecked(true);
        const pricePerPaymentValidation = getPricePerHourValidation();
        const messageValidation = getMessageValidation();
        const commonTargetList = [pricePerPaymentValidation, messageValidation];
        if (props.courseType === "short") {
            const lessonsLengthValidation = lessons.length > 0;
            const targetList = [
                ...commonTargetList,
                lessonsLengthValidation,
                conflictWithinLessonsValidation,
                conflictWithOtherCourseLessonValidation,
            ];
            const isValid = targetList.every((validation) => validation);
            if (isValid) {
                setProposalModalStep(2);
            }
        } else {
            const startDateValidation = getStartDateValidation();
            const baseSchedulesValidation = baseSchedules.length > 0;
            const targetList = [...commonTargetList, startDateValidation, baseSchedulesValidation];
            const isValid = targetList.every((validation) => validation);
            if (isValid) {
                setProposalModalStep(2);
            }
        }
    }, [
        baseSchedules,
        props.existingLessons,
        lessons,
        props.courseType,
        conflictWithinLessonsValidation,
        conflictWithOtherCourseLessonValidation,
        getPricePerHourValidation,
        getMessageValidation,
        getStartDateValidation,
    ]);

    const handleLessonsChange = useCallback((newLessons: CreateLessonRequestParams[]) => {
        setLessons(newLessons);
    }, []);

    const handleBaseScheduleDeleteButtonClick = useCallback(
        (idx: number) => {
            const newBaseSchedules = baseSchedules.filter((_, index) => index !== idx);
            setBaseSchedules(newBaseSchedules);
        },
        [baseSchedules],
    );

    const handleBaseSchedulesChange = useCallback((newBaseSchedules: CreateBaseScheduleParams[]) => {
        setBaseSchedules(newBaseSchedules);
    }, []);

    return (
        <Modal open={props.proposalModalOpen} onClose={handleProposalModalClose} className={styles.modal}>
            <ProposalModalContents
                isChecked={isChecked}
                courseType={props.courseType}
                startDate={startDate}
                startDateValidation={getStartDateValidation()}
                message={message}
                messageValidation={getMessageValidation()}
                pricePerHour={pricePerHour}
                pricePerPayment={pricePerPayment}
                pricePerHourValidation={getPricePerHourValidation()}
                isProcessing={isProcessing}
                isCompleted={isCompleted}
                hasError={hasError}
                lessons={lessons}
                existingLessons={props.existingLessons}
                baseSchedules={baseSchedules}
                targetProposal={props.targetProposal}
                handleConfirmButtonClick={handleConfirmButtonClick}
                getUnit={getUnit}
                handleBaseScheduleDeleteButtonClick={handleBaseScheduleDeleteButtonClick}
                handleBaseSchedulesChange={handleBaseSchedulesChange}
                handleLessonsChange={handleLessonsChange}
                handleMessageChange={handleMessageChange}
                handlePricePerHourChange={handlePricePerHourChange}
                proposalModalStep={proposalModalStep}
                lessonsDuplicateValidation={conflictWithinLessonsValidation}
                otherCourseLessonDuplicateValidation={conflictWithOtherCourseLessonValidation}
                handleClose={handleProposalModalClose}
                handleNextButtonClick={handleNextButtonClick}
                setProposalModalStep={setProposalModalStep}
                handleStartDateChange={handleStartDateChange}
            />
        </Modal>
    );
});
