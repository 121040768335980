import { memo, useCallback, useState } from "react";
import { ChatResponse, PublicStudentResponse, PublicTeacherResponse } from "@/store/autogenApi";

import styles from "./index.module.scss";

import { ChatInfo, SocketInfo } from "./ChatsContents";
import { TargetMessagesHeader } from "./TargetMessagesComponent/TargetMessagesHeader";
import { MessageInput } from "@/components/MessageInput";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { MessageList } from "@/components/MessageList";
import { UserInfoModal } from "@/components/UserInfoModal";
import { useCreateStudentMessageMutation, useCreateTeacherMessageMutation } from "@/store/hooks/messages";
import { useUploadFiles } from "@/utils/UploadFiles";

interface Props {
    chatInfos: ChatInfo[];
    openedChatInfo: ChatInfo | undefined;
    temporaryObjectURLsList: string[][];
    socketInfos: SocketInfo[];
    isProcessing: boolean;
    handleIsProcessingChange: (isProcessing: boolean) => void;
    clearChatMessage: (chatId: string) => void;
    handleBackButtonClick: () => void;
    handleChatMessageChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    handleTemporaryObjectURLsListChange: (urlsList: string[][]) => void;
}

export const TargetMessages: React.VFC<Props> = memo(function TargetMessages(props) {
    const [openedTarget, setOpenedTarget] = useState<PublicStudentResponse | PublicTeacherResponse | undefined>(
        undefined,
    );

    const userMode = useSelector((state: RootState) => state.jwt.userMode);
    const userId = useSelector((state: RootState) => state.jwt.userId as string);
    const currentStudentId = useSelector((state: RootState) => state.jwt.studentId as string);
    const currentTeacherId = useSelector((state: RootState) => state.jwt.teacherId as string);

    const createStudentMessage = useCreateStudentMessageMutation();
    const createTeacherMessage = useCreateTeacherMessageMutation();
    const uploadFiles = useUploadFiles();

    const getTargetMessage = useCallback(() => {
        if (props.openedChatInfo === undefined) return "";
        const targetChatInfo = props.chatInfos.find(
            (chatInfo) => chatInfo.chatId === (props.openedChatInfo as ChatInfo).chatId,
        );
        if (targetChatInfo === undefined) return "";
        return targetChatInfo.chatMessage;
    }, [props.chatInfos, props.openedChatInfo]);

    const handleSendButtonClick = useCallback(async () => {
        if (!props.openedChatInfo) return;
        props.handleIsProcessingChange(true);
        props.clearChatMessage(props.openedChatInfo.chatId);
        const socket = props.socketInfos.find(
            (socketInfo) => socketInfo.chatId === (props.openedChatInfo as ChatInfo).chatId,
        )?.socket;
        if (!socket) return;
        if (userMode === "student") {
            await createStudentMessage({
                createStudentMessageRequestBody: {
                    messageType: "text",
                    studentId: currentStudentId,
                    chatId: props.openedChatInfo.chatId,
                    chatType: props.openedChatInfo.chatType,
                    text: props.chatInfos.find((chatInfo) => chatInfo.chatId === props.openedChatInfo?.chatId)
                        ?.chatMessage,
                },
            });
        } else if (userMode === "teacher") {
            await createTeacherMessage({
                createTeacherMessageRequestBody: {
                    messageType: "text",
                    teacherId: currentTeacherId,
                    chatId: props.openedChatInfo.chatId,
                    chatType: props.openedChatInfo.chatType,
                    text: props.chatInfos.find((chatInfo) => chatInfo.chatId === props.openedChatInfo?.chatId)
                        ?.chatMessage,
                },
            });
        }
        const roomId = props.openedChatInfo.chatId;
        socket.emit("createdMessage", roomId);
    }, [
        props.openedChatInfo,
        props.chatInfos,
        currentStudentId,
        createStudentMessage,
        createTeacherMessage,
        userMode,
        currentTeacherId,
    ]);

    const sliceFilesByNumber = useCallback((array: File[], number: number) => {
        const length = Math.ceil(array.length / number);
        return [...Array(length)].map((_, i) => array.slice(i * number, (i + 1) * number));
    }, []);

    const handleImagesChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!props.openedChatInfo) return;
            (async () => {
                try {
                    const files = e.target.files;
                    if (!files) return;
                    const socket = props.socketInfos.find(
                        (socketInfo) => socketInfo.chatId === props.openedChatInfo?.chatId,
                    )?.socket;
                    if (!socket) return;
                    const slicedFilesList = sliceFilesByNumber(Array.from(files), 10);
                    const newTemporaryObjectURLsList = slicedFilesList.map((files) =>
                        files.map((file) => URL.createObjectURL(file)),
                    );
                    props.handleTemporaryObjectURLsListChange(newTemporaryObjectURLsList);
                    if (!props.openedChatInfo) return;
                    for (const files of slicedFilesList) {
                        const urls = await uploadFiles(files, `chats/${props.openedChatInfo?.chatId}/messages/`);
                        if (userMode === "student") {
                            await createStudentMessage({
                                createStudentMessageRequestBody: {
                                    messageType: "file",
                                    studentId: currentStudentId,
                                    chatId: props.openedChatInfo.chatId,
                                    chatType: props.openedChatInfo.chatType,
                                    fileUrls: urls,
                                },
                            });
                        } else {
                            await createTeacherMessage({
                                createTeacherMessageRequestBody: {
                                    messageType: "file",
                                    teacherId: currentTeacherId,
                                    chatId: props.openedChatInfo.chatId,
                                    chatType: props.openedChatInfo.chatType,
                                    fileUrls: urls,
                                },
                            });
                        }
                        const roomId = props.openedChatInfo.chatId;
                        socket.emit("createdMessage", roomId);
                        props.handleTemporaryObjectURLsListChange([]);
                    }
                } catch (error) {
                    alert(error.message);
                    props.handleTemporaryObjectURLsListChange([]);
                }
            })();
        },
        [
            currentStudentId,
            createStudentMessage,
            sliceFilesByNumber,
            props.openedChatInfo,
            props.chatInfos,
            props.socketInfos,
            uploadFiles,
            props.handleTemporaryObjectURLsListChange,
            userMode,
            currentTeacherId,
            createTeacherMessage,
        ],
    );

    const handleCreateMeetingButtonClick = useCallback(async () => {
        if (!props.openedChatInfo) return;
        props.handleIsProcessingChange(true);
        const socket = props.socketInfos.find(
            (socketInfo) => socketInfo.chatId === props.openedChatInfo?.chatId,
        )?.socket;
        if (!socket) return;
        await createTeacherMessage({
            createTeacherMessageRequestBody: {
                messageType: "personalMeeting",
                teacherId: currentTeacherId,
                chatId: props.openedChatInfo.chatId,
                chatType: props.openedChatInfo.chatType,
            },
        });
        const roomId = props.openedChatInfo.chatId;
        socket.emit("createdMessage", roomId);
    }, [
        props.openedChatInfo,
        props.chatInfos,
        createTeacherMessage,
        userMode,
        currentTeacherId,
        props.socketInfos,
        props.handleIsProcessingChange,
    ]);

    const handleIconButtonClick = useCallback((target: PublicStudentResponse | PublicTeacherResponse) => {
        setOpenedTarget(target);
    }, []);

    const handleModalClose = useCallback(() => {
        setOpenedTarget(undefined);
    }, []);

    const getChat = useCallback(() => {
        const { chatMessage, ...chat } = props.openedChatInfo as ChatInfo;
        return chat as ChatResponse;
    }, [props.openedChatInfo]);

    return (
        <div className={styles.targetMessages}>
            {props.openedChatInfo !== undefined && (
                <>
                    <TargetMessagesHeader
                        openedChatInfo={props.openedChatInfo}
                        userMode={userMode}
                        handleBackButtonClick={props.handleBackButtonClick}
                    />
                    <MessageList
                        chat={getChat()}
                        temporaryObjectURLsList={props.temporaryObjectURLsList}
                        handleIconButtonClick={handleIconButtonClick}
                    />
                    <MessageInput
                        isProcessing={props.isProcessing}
                        message={getTargetMessage()}
                        handleMessageChange={props.handleChatMessageChange}
                        handleFilesChange={handleImagesChange}
                        handleSendButtonClick={handleSendButtonClick}
                        handleCreateMeetingButtonClick={handleCreateMeetingButtonClick}
                    />
                </>
            )}
            {openedTarget && <UserInfoModal openedTarget={openedTarget} zIndex={1000} handleClose={handleModalClose} />}
        </div>
    );
});
