import { memo, useCallback } from "react";

import styles from "./index.module.scss";

import { ChatItemButton } from "./ChatItemButton";
import { ChatInfo } from "./ChatsContents";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { WHAT_DAY_LIST } from "@/utils/WhatDayList";
import { getAMOrPM } from "@/utils/GetAMOrPM";
import { getOnlyTime } from "@/utils/GetOnlyTime";

interface Props {
    chatInfos: ChatInfo[];
    openedChatInfo: ChatInfo | undefined;
    handleListButtonClick: (chatInfo: ChatInfo) => void;
}

export const ChatList: React.VFC<Props> = memo(function MessagesList(props) {
    const userMode = useSelector((state: RootState) => state.jwt.userMode);
    const studentId = useSelector((state: RootState) => state.jwt.studentId);
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId);
    const getPointerEventsValue = useCallback(
        (chatInfo: ChatInfo) => {
            return props.openedChatInfo?.chatId === chatInfo.chatId ? "none" : "auto";
        },
        [props.openedChatInfo],
    );
    const getTime = useCallback((createdAt: Date) => {
        const currentTime = new Date();
        const fixedTime = new Date(createdAt);
        const diffTimeByMillisecond = currentTime.getTime() - fixedTime.getTime();
        const diffYear = currentTime.getFullYear() - fixedTime.getFullYear();
        const withinAWWeek = diffTimeByMillisecond < 1000 * 60 * 60 * 24 * 7;
        const isToDay = currentTime.getDate() === fixedTime.getDate();
        if (diffYear !== 0) {
            return `${fixedTime.getFullYear()}.${fixedTime.getMonth() + 1}.${fixedTime.getDate()}`;
        }
        if (!withinAWWeek) {
            return `${fixedTime.getMonth() + 1}.${fixedTime.getDate()}`;
        }
        if (isToDay) {
            return `${getAMOrPM(fixedTime)} ${getOnlyTime(fixedTime)}`;
        }
        if (Math.floor(diffTimeByMillisecond / (1000 * 60 * 60 * 24)) === 1) {
            return `昨日`;
        }
        const day = WHAT_DAY_LIST[fixedTime.getDay()];
        return `${day}曜日`;
    }, []);
    return (
        <div className={styles.messagesListWrapper}>
            {props.chatInfos.length === 0 ? (
                <div className={styles.emptyMessage}>やり取りがまだありません。</div>
            ) : (
                props.chatInfos.map((chatInfo, idx) => {
                    const targetReadStatus = chatInfo.readStatuses.find((readStatus) => {
                        if (userMode === "student") {
                            return readStatus.studentId === studentId;
                        } else {
                            return readStatus.teacherId === teacherId;
                        }
                    });
                    const unreadLength = chatInfo.messages
                        .filter((message) =>
                            userMode === "student"
                                ? message.student?.studentId !== studentId
                                : message.teacher?.teacherId !== teacherId,
                        )
                        ?.map((message) => {
                            if (targetReadStatus == undefined) return 0 as number;
                            if (message.createdAt <= (targetReadStatus?.updatedAt || targetReadStatus?.createdAt)) {
                                return 0 as number;
                            } else {
                                return 1 as number;
                            }
                        })
                        .reduce((prevValue, currentValue) => {
                            return ((prevValue as number) + currentValue) as number;
                        }, 0);
                    const latestMessage =
                        chatInfo.messages.length > 0 ? chatInfo.messages[chatInfo.messages.length - 1] : undefined;
                    return (
                        <ChatItemButton
                            key={idx}
                            handleChatItemButtonClick={() => {
                                props.handleListButtonClick(chatInfo);
                            }}
                            latestMessage={latestMessage}
                            chatInfo={chatInfo}
                            pointerEventsValue={getPointerEventsValue(chatInfo)}
                            userMode={userMode}
                            time={latestMessage ? getTime(new Date(latestMessage.createdAt)) : ""}
                            unreadLength={unreadLength}
                            isTarget={props.openedChatInfo?.chatId == chatInfo.chatId}
                        />
                    );
                })
            )}
        </div>
    );
});
