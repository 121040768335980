import { BannerResponse, BannerTargetType, DeviceType } from "../autogenApi";
import { defaultApi } from "../defaultApi";
import { useQueryResult } from "./utils/parseResult";

export const useGetBannersQuery = (targetType?: BannerTargetType, deviceType?: DeviceType) => {
    const result = defaultApi.endpoints.getBanners.useQuery({
        targetType,
        deviceType,
    });
    return useQueryResult(result, (response: BannerResponse[]) => response);
};
