import { createTheme, useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { CourseResponse, CourseType, DraftCourseResponse } from "@/store/autogenApi";
import { RootState } from "@/ducks";
import { memo, useCallback, useEffect, useState } from "react";
import { CourseDetailsModal } from "./CourseDetailsModal";
import { MyDraftCoursesTabContents } from "./MyDraftCoursesTabContents";
import { TabsTemplate } from "@/components/TabsTemplate";
import { getTabInfoList } from "@/utils/DraftCourseUtils";

interface Props {
    draftCourses: DraftCourseResponse[];
}

const COURSE_TYPES: CourseType[] = ["regular", "short"];

export const MyDraftCoursesContents: React.VFC<Props> = memo(function MyCoursesContents(props) {
    const [courseType, setCourseType] = useState<CourseType>("regular");
    const [targetDraftCourse, setTargetDraftCourse] = useState<DraftCourseResponse | undefined>(undefined);

    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);

    const sm = useMediaQuery("(min-width:600px)");
    const md = useMediaQuery("(min-width:960px)");
    const lg = useMediaQuery("(min-width:1280px)");
    const xl = useMediaQuery("(min-width:1536px)");

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const courseTypeQueryParams = searchParams.get("courseType");

    useEffect(() => {
        if (courseTypeQueryParams) {
            setCourseType(courseTypeQueryParams as CourseType);
        }
    }, [courseTypeQueryParams]);

    const history = useHistory();

    const handleTabChange = useCallback((event: React.ChangeEvent<Record<string, unknown>>, value: string) => {
        console.log(value);
        switch (value) {
            case "regular":
                history.push("/Teacher/MyPage/MyDraftCourses?courseType=regular");
                break;
            case "short":
                history.push("/Teacher/MyPage/MyDraftCourses?courseType=short");
                break;
        }
    }, []);

    const handleDraftCourseCardClick = useCallback((draftCourse: DraftCourseResponse) => {
        setTargetDraftCourse(draftCourse);
    }, []);

    const handleCourseDetailsModalClose = useCallback(() => {
        setTargetDraftCourse(undefined);
    }, []);

    return (
        <>
            <TabsTemplate
                targetTabValue={courseType}
                tabInfoList={getTabInfoList(props.draftCourses)}
                handleTabChange={handleTabChange}
            >
                {COURSE_TYPES.map((targetCourseType) => (
                    <MyDraftCoursesTabContents
                        visible={targetCourseType === courseType}
                        draftCourses={props.draftCourses.filter(
                            (draftCourse) => draftCourse.courseType === targetCourseType,
                        )}
                        repetition={xl ? 6 : lg ? 6 : md ? 6 : sm ? 5 : 3}
                        handleDraftCourseCardClick={handleDraftCourseCardClick}
                    />
                ))}
            </TabsTemplate>
            {targetDraftCourse && (
                <CourseDetailsModal
                    targetDraftCourse={targetDraftCourse}
                    handleCourseDetailsModalClose={handleCourseDetailsModalClose}
                />
            )}
        </>
    );
});
