import { FAQDetailsBase } from "../../FAQDetailsBase";

import { REGISTER_AS_TEACHER_CONFIGS } from "./SignUpAsTeacherConfigs";

export const SignUpAsTeacher: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="先生として新規登録する"
            text="このページでは、先生として新規登録する方法についてご説明します。登録には以下の2つの方法がご利用いただけます。"
            FAQConfigs={REGISTER_AS_TEACHER_CONFIGS}
            list={["メールアドレスで新規登録"]}
        />
    );
};
