import { NewsRecordResponse, NewsTargetType } from "../autogenApi";
import { defaultApi } from "../defaultApi";
import { useQueryResult } from "./utils/parseResult";

export const useGetNewsRecordsQuery = (targetType?: NewsTargetType) => {
    const result = defaultApi.endpoints.getNewsRecords.useQuery({
        targetType,
    });
    return useQueryResult(result, (response: NewsRecordResponse[]) => response);
};

export const useGetNewsRecordQuery = (newsRecordId: string) => {
    const result = defaultApi.endpoints.getNewsRecord.useQuery({ newsRecordId });
    return useQueryResult(result, (response: NewsRecordResponse) => response);
};
