import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import styles from "./index.module.scss";

import defaultCourseImage from "@/images/defaultCourseImage.jpg";
import { CourseResponse } from "@/store/autogenApi";
import { useCallback } from "react";
import { getLessonTime } from "@/utils/LessonUtils";
import { ImageFromS3 } from "@/components/atoms/images/ImageFromS3";

interface Props {
    course?: CourseResponse;
    isHidden?: boolean;
    isFlex?: boolean;
    handleCourseCardClick: (course: CourseResponse) => void;
}

export const MyCourseCard: React.VFC<Props> = (props) => {
    const getNextLessonTime = useCallback(() => {
        const nextLesson = props.course?.classes
            .map((classResponse) => classResponse.lessons)
            .flat()
            .find((lesson) => new Date(lesson.endTime) > new Date());
        if (nextLesson == undefined) return "なし";
        return getLessonTime(nextLesson);
    }, [props.course]);

    const getStatus = useCallback(() => {
        const existStudents = props.course?.classes.some((classResponse) => classResponse.participants.length > 0);
        const existLessons = props.course?.classes.some((classResponse) => {
            return classResponse.lessons.some((lesson) => new Date(lesson.endTime) > new Date());
        });
        const isAvailable = existStudents && existLessons;
        const isDraft = props.course?.draftCourse ? true : false;
        const isRecruiting = props.course?.classes.some((classResponse) => {
            if (!classResponse.applyingDeadline) return false;
            return new Date() < new Date(classResponse.applyingDeadline);
        });
        if (isAvailable && isDraft) return "開講中・下書きあり";
        if (isAvailable && !isDraft) return "開講中";
        if (isDraft && isRecruiting) return "募集中・下書きあり";
        if (!isDraft && isRecruiting) return "募集中";
        if (isDraft) return "下書きあり";
        return "停止中";
    }, [props.course]);

    return (
        <Card
            className={styles.card}
            style={{
                visibility: props.isHidden ? "hidden" : "visible",
                flex: props.isFlex ? 1 : "none",
                width: props.isFlex ? "auto" : "150px",
            }}
            onClick={() => {
                if (!props.course) return;
                props.handleCourseCardClick(props.course);
            }}
        >
            <div className={styles.mediaWrapper}>
                <ImageFromS3
                    className={styles.media}
                    url={props.course?.thumbnailUrl ?? defaultCourseImage}
                    objectKey={props.course?.thumbnailObjectKey}
                />
                <div className={styles.status}>{getStatus()}</div>
            </div>
            <CardContent className={styles.cardContents}>
                <Typography variant="body2" color="textSecondary" component="div" className={styles.title}>
                    {props.course?.title}
                </Typography>
                <div className={styles.nextLessonTime}>次回：{getNextLessonTime()}</div>
            </CardContent>
        </Card>
    );
};
