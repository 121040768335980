import styles from "./index.module.scss";
import { FileContents } from "./FileContents";
import { memo } from "react";

interface Props {
    fileUrls: string[];
    fileObjectKeys: string[];
    textAlignRight?: boolean;
}

export const FilesContents: React.VFC<Props> = memo(function FilesContents(props) {
    return (
        <ul className={styles.files} style={props.textAlignRight ? { textAlign: "right" } : {}}>
            {props.fileUrls.map((fileUrl, idx) => {
                return <FileContents fileUrl={fileUrl} fileObjectKey={props.fileObjectKeys[idx]} idx={idx} />;
            })}
        </ul>
    );
});
