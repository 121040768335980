import { memo } from "react";
import { Mandatory } from "@/components/Tag/Mandatory";
import styles from "@/pages/Teacher/InfoInput/index.module.scss";
import { None } from "../Errors/None";
import { Over } from "../Errors/Over";

import { StyledTextField } from "./StyledTextField";

interface Props {
    handleChange?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    open?: boolean;
    value: string;
    checked: boolean;
    upperLimit?: number;
}

export const CatchCopy: React.VFC<Props> = memo(function CatchCopy(props) {
    return (
        <div className={styles.inputItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitleWrapper}>
                    <span className={styles.itemTitle}>キャッチコピー</span>
                </div>
                {!props.open && <Mandatory />}
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>{props.value}</div>
                ) : (
                    <>
                        <StyledTextField
                            name="catchCopy"
                            placeholder="20文字以内 / 例）わかりやすさなら誰にも負けません！！"
                            handleChange={props.handleChange}
                            value={props.value}
                        />
                        {props.checked && props.upperLimit && (
                            <Over value={props.value} upperLimit={props.upperLimit} />
                        )}
                        {props.checked && <None value={props.value} />}
                    </>
                )}
            </div>
        </div>
    );
});
