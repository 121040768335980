import styles from "./index.module.scss";
import { FileContents } from "./FileContents";
import { memo, useRef } from "react";

interface Props {
    fileUrls: string[];
    messagesEl: HTMLElement | null;
}

export const UploadingFilesContents: React.VFC<Props> = memo(function UploadingFilesContents(props) {
    const filesElementRef = useRef<HTMLUListElement | null>(null);
    return (
        <ul
            className={styles.files}
            ref={(node) => {
                filesElementRef.current = node;
            }}
        >
            {props.fileUrls.map((fileUrl, idx) => {
                return <FileContents fileUrl={fileUrl} idx={idx} messagesEl={props.messagesEl} />;
            })}
        </ul>
    );
});
