import { actionType } from "@/actions/logging";
import { UserType } from "@/protectedRouter/ProtectedRouterConfig";
import { JwtRequestBody } from "@/store/autogenApi";

export type stateType = {
    login: boolean;
    userMode: UserType;
    userId: string | null;
    studentId: string | null;
    teacherId: string | null;
};

const myEscape = (str: string) => {
    return str.replace(/[^a-zA-Z0-9@*_+\-./]/g, function (m) {
        const code = m.charCodeAt(0);
        if (code <= 0xff) {
            return "%" + ("00" + code.toString(16)).slice(-2).toUpperCase();
        } else {
            return "%u" + ("0000" + code.toString(16)).slice(-4).toUpperCase();
        }
    });
};

export const decodeJwt = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    return JSON.parse(decodeURIComponent(myEscape(window.atob(base64))));
};

const reducer = (state: stateType = initialState(), action: actionType): stateType => {
    switch (action.type) {
        case "login":
            return {
                login: true,
                userMode: action.payload.userMode,
                userId: action.payload.userId,
                studentId: action.payload.studentId,
                teacherId: action.payload.teacherId,
            };
        case "transition":
            return {
                login: false,
                userMode: "anonymous",
                userId: action.payload.userId,
                studentId: null,
                teacherId: null,
            };
        case "logout":
            return {
                login: false,
                userMode: "anonymous",
                userId: null,
                studentId: null,
                teacherId: null,
            };
        default:
            return state;
    }
};

const initialState = (): stateType => {
    const token = localStorage.getItem("token");
    if (token === null) {
        return {
            login: false,
            userMode: "anonymous",
            userId: null,
            studentId: null,
            teacherId: null,
        };
    }
    const jwtRequestBody = decodeJwt(token) as JwtRequestBody;
    const userMode = jwtRequestBody.userMode;
    if (userMode == "anonymous") {
        return {
            login: false,
            userMode: userMode,
            userId: jwtRequestBody.userId,
            studentId: null,
            teacherId: null,
        };
    } else {
        return {
            login: true,
            userMode: userMode as UserType,
            userId: jwtRequestBody.userId,
            studentId: jwtRequestBody.studentId,
            teacherId: jwtRequestBody.teacherId,
        };
    }
};

export { reducer, initialState };
