import { FaChalkboardTeacher, FaPencilAlt, FaSchool } from "react-icons/fa";

import styles from "@/pages/Common/AboutRequest/index.module.scss";

export const RegularCourse: React.VFC = () => {
    return (
        <div className={styles.courseTypeWrapper}>
            <div className={styles.titleWrapper}>
                <div className={styles.outline}>信頼できる先生と共に乗り越える</div>
                <div className={styles.courseTypeTitle}>定期講座</div>
                <div className={styles.descriptionWrapper}>
                    <div className={styles.description}>週2回固定曜日になど、継続的な授業を行うのが定期講座です。</div>
                    <div className={styles.description}>一般的な家庭教師に近い感覚で受講することができます。</div>
                </div>
            </div>
            <div className={styles.recommendWrapper}>
                <div className={styles.recommendTitle}>こんな方におすすめ</div>
                <ul className={styles.recommendList}>
                    <li className={styles.recommendItem}>
                        <FaChalkboardTeacher className={styles.icon} />
                        <div className={styles.description}>信頼できる先生に長期的に教えてもらいたい。</div>
                    </li>
                    <li className={styles.recommendItem}>
                        <FaSchool className={styles.icon} />
                        <div className={styles.description}>毎週学校の授業の補修がしたい。</div>
                    </li>
                    <li className={styles.recommendItem}>
                        <FaPencilAlt className={styles.icon} />
                        <div className={styles.description}>受験に向けて成績を管理してほしい。</div>
                    </li>
                </ul>
            </div>
        </div>
    );
};
