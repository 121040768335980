import { memo, useCallback, useEffect, useState } from "react";
import { QueryLoadingWrapper } from "../QueryLoadingWrapper";
import { ChatContents } from "../ChatContents";
import { useGetIndividualChatQueryState } from "@/store/hooks/chats";
import { CircularProgress, Modal } from "@material-ui/core";
import { ChatResponse } from "@/store/autogenApi";
import { io } from "socket.io-client";
import { Socket } from "socket.io-client";
import styles from "../ChatContents/index.module.scss";
import { getApiUrl } from "@/store/emptyApi";

interface Props {
    // openは親コンポーネントで管理する
    handleClose: () => void;
    studentId: string;
    teacherId: string;
}

export const ChatModal: React.VFC<Props> = memo(function ChatModal(props) {
    const [previousChat, setPreviousChat] = useState<ChatResponse | undefined>(undefined);
    const [socket, setSocket] = useState<Socket | undefined>(undefined);
    const apiUrl = getApiUrl();

    const chatQueryState = useGetIndividualChatQueryState(props.studentId, props.teacherId);

    useEffect(() => {
        const chatId = props.studentId + props.teacherId;
        const roomId = chatId;
        console.log("Connecting..");
        const newSocket = io(apiUrl, { query: { roomId } });
        setSocket(newSocket);
    }, [props.studentId, props.teacherId, apiUrl]);

    useEffect(() => {
        if (!socket) return;
        return () => {
            console.log("disconnect");
            socket.disconnect();
        };
    }, [socket]);

    const handlePreviousChatChange = useCallback((chat: ChatResponse) => {
        setPreviousChat(chat);
    }, []);

    return (
        <Modal
            open={true}
            onClose={() => {
                props.handleClose();
            }}
        >
            <QueryLoadingWrapper
                {...chatQueryState}
                loadingComponent={
                    previousChat ? (
                        <ChatContents
                            socket={socket}
                            chat={previousChat}
                            isPrevious
                            refetch={chatQueryState.refetch}
                            handleClose={props.handleClose}
                            handlePreviousChatChange={handlePreviousChatChange}
                        />
                    ) : (
                        <div className={styles.chatContents}>
                            <div className={styles.circularProgress}>
                                <CircularProgress />
                            </div>
                        </div>
                    )
                }
            >
                {(chat) => (
                    <ChatContents
                        socket={socket}
                        chat={chat}
                        isPrevious={false}
                        refetch={chatQueryState.refetch}
                        handleClose={props.handleClose}
                        handlePreviousChatChange={handlePreviousChatChange}
                    />
                )}
            </QueryLoadingWrapper>
        </Modal>
    );
});
