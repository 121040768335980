import { createTheme, MuiThemeProvider, Tab, Tabs, useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";

import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { useGetCourseBrowsingHistoryQuery } from "@/store/hooks/students";

import { MyPage } from ".";
import { RootState } from "@/ducks";

import styles from "./index.module.scss";
import { useCallback, useEffect, useState } from "react";
import { CourseResponse, CourseType } from "@/store/autogenApi";
import { TabContents } from "@/pageComponents/Student/MyPage/TabContents";

import queryString from "query-string";
import { useHistory } from "react-router";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { Navigation } from "@/components/Navigation";

const theme = createTheme({
    overrides: {
        MuiTabs: {
            root: {
                color: "#305077",
                paddingLeft: 0,
                boxShadow: "none !important",
            },
            indicator: {
                backgroundColor: "#305077",
            },
        },
        MuiTab: {
            root: {
                opacity: 0.7,
                fontWeight: "bold",
                "&$selected": {
                    opacity: 1,
                    pointerEvents: "none",
                    color: "#305077",
                    "&:hover": {
                        color: "#305077",
                    },
                },
            },
        },
    },
});

const COURSE_TYPES: CourseType[] = ["regular", "short"];

const NAVIGATION_LIST: Navigation[] = [
    { title: "マイページ", url: "/MyPage/Profile" },
    { title: "最近チェックした講座", url: "/MyPage/History" },
];

export const History: React.VFC = () => {
    const [courseType, setCourseType] = useState<CourseType>("regular");

    const query = queryString.parse(window.location.search);

    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);

    const courseBrowsingHistoryQueryState = useGetCourseBrowsingHistoryQuery(studentId);

    const history = useHistory();

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    useEffect(() => {
        if (query.courseType) {
            setCourseType(query.courseType as CourseType);
        }
    }, [query]);

    const sm = useMediaQuery("(min-width:600px)");
    const md = useMediaQuery("(min-width:960px)");
    const lg = useMediaQuery("(min-width:1280px)");
    const xl = useMediaQuery("(min-width:1536px)");

    const getLabel = useCallback((courseType: CourseType, courses: CourseResponse[]) => {
        const targetCourses = courses.filter((course) => course.courseType === courseType);
        switch (courseType) {
            case "regular":
                return `定期(${targetCourses.length})`;
            case "short":
                return `単発・短期(${targetCourses.length})`;
        }
    }, []);

    const handleChange = useCallback((event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
        switch (newValue) {
            case 0:
                history.push("/MyPage/History?courseType=regular");
                break;
            case 1:
                history.push("/MyPage/History?courseType=short");
                break;
        }
    }, []);

    return (
        <MyPage title="最近チェックした講座" activeTab="history">
            <QueryLoadingWrapper {...courseBrowsingHistoryQueryState}>
                {(courses) => (
                    <div className={styles.coursesWrapper}>
                        <div className={styles.tabsWrapper}>
                            <MuiThemeProvider theme={theme}>
                                <Tabs
                                    value={COURSE_TYPES.indexOf(courseType)}
                                    onChange={handleChange}
                                    aria-label="simple tabs example"
                                    className={styles.tabs}
                                >
                                    {COURSE_TYPES.map((courseType) => (
                                        <Tab label={getLabel(courseType, courses)} />
                                    ))}
                                </Tabs>
                            </MuiThemeProvider>
                        </div>
                        <div className={styles.tabContentsWrapper}>
                            {COURSE_TYPES.map((targetCourseType) => (
                                <TabContents
                                    visible={targetCourseType === courseType}
                                    courses={courses.filter((course) => course.courseType === targetCourseType)}
                                    repetition={xl ? 6 : lg ? 6 : md ? 6 : sm ? 5 : 3}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </QueryLoadingWrapper>
        </MyPage>
    );
};
