import { Auth } from "aws-amplify";

export const getAuthorization = async (): Promise<string | undefined> => {
    try {   
        const session = await Auth.currentSession();
        if (session) {
            return session.getIdToken().getJwtToken();
        }
    } catch (e) {
        console.log(e);
    }
};

// TODO: ユーザー認証についてもう一度検討
