import { useState, useEffect, memo, useCallback } from "react";

import { Button } from "@material-ui/core";
import { GiTeacher } from "react-icons/gi";

import styles from "@/pages/Common/PublicRequestDetails/index.module.scss";

interface Props {
    description?: string;
}

export const RequestDescription: React.VFC<Props> = memo((props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOver, setIsOver] = useState<boolean>(false);

    const [currentRequestDescriptionEl, setCurrentRequestDescriptionEl] = useState<HTMLElement | null>(null);

    const requestDescriptionRef = useCallback((BodyEl: HTMLElement | null) => {
        setCurrentRequestDescriptionEl(BodyEl);
    }, []);

    const showMore = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (!currentRequestDescriptionEl) return;
        const scrollHeight = currentRequestDescriptionEl.scrollHeight;
        if (scrollHeight && scrollHeight > 200) {
            setIsOver(true);
        }
    }, [currentRequestDescriptionEl]);

    return (
        <div className={styles.detailWrapper}>
            <div className={styles.detailHeadingWrapper}>
                <span className={styles.detailHeading}>希望する講座内容</span>
            </div>
            <div
                className={styles.detail}
                style={{ overflowY: isOpen ? "auto" : "hidden", maxHeight: isOpen ? "none" : "200px" }}
                ref={requestDescriptionRef}
            >
                {props.description}
            </div>
            {isOver && (
                <div className={styles.buttonWrapper} style={{ position: isOpen ? "inherit" : "absolute" }}>
                    <Button className={styles.detailButton} onClick={showMore}>
                        {isOpen ? "閉じる" : "すべて見る"}
                    </Button>
                </div>
            )}
        </div>
    );
});
