import { FAQDetailsBase } from "../../FAQDetailsBase";

export const AboutReview: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="レビューとは"
            text="レビューとは、講座を受講した生徒による講座の評価のことです。レビューは、新たに受講を検討している他の生徒の参考となり。先生の評価にも反映されます。なお、レビュー投稿のルールについては、レビュー投稿ガイドラインをご確認ください。"
            urlInfos={[
                {
                    url: "/ReviewSubmissionGuidelines",
                    word: "レビュー投稿ガイドライン",
                },
            ]}
        />
    );
};
