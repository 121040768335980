import { useCallback, useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { AnnouncementResponse } from "@/store/autogenApi";
import { useUpdateStudentCheckedAnnouncementsMutation } from "@/store/hooks/students";
import styles from "../../index.module.scss";
import { AnnouncementsTooltip } from "../AnnouncementsTooltip";
import trailIcon from "@/images/logo-v2.jpg";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    announcements: AnnouncementResponse[];
}

export const StudentAnnouncementListContents: React.VFC<Props> = (props) => {
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const [evaluationModalOpen, setEvaluationModalOpen] = useState<boolean>(false);
    const [targetStudentAnnouncement, setTargetStudentAnnouncement] = useState<AnnouncementResponse | undefined>(
        undefined,
    );
    const [payForCourseModalOpen, setPayForCourseModalOpen] = useState<boolean>(false);
    const [maxModalHeight, setMaxModalHeight] = useState<number>(0);

    useEffect(() => {
        changeModalHeight();
        window.addEventListener("resize", () => {
            changeModalHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalHeight();
            });
        };
    }, []);

    const changeModalHeight = useCallback(() => {
        const innerHeight = window.innerHeight;
        setMaxModalHeight(innerHeight * 0.75);
    }, []);

    const studentId = useSelector((state: RootState) => state.jwt.studentId) as string;

    const updateStudentCheckedAnnouncements = useUpdateStudentCheckedAnnouncementsMutation();

    const getDiffTime = (createdAt: Date) => {
        const currentTime = new Date();
        const fixedTime = new Date(createdAt);
        const diffTimeByMillisecond = currentTime.getTime() - fixedTime.getTime();
        const diffDate = Math.floor(diffTimeByMillisecond / (1000 * 60 * 60 * 24));
        const diffHours = Math.floor(diffTimeByMillisecond / (1000 * 60 * 60)) - diffDate * 24;
        const diffMinutes = Math.floor(diffTimeByMillisecond / (1000 * 60)) - diffHours * 60;
        if (diffDate === 0) {
            if (diffHours === 0) {
                if (diffMinutes === 0) {
                    return "ちょうど今";
                } else {
                    return diffMinutes + "分前";
                }
            } else {
                return diffHours + "時間前";
            }
        } else {
            return diffDate + "日前";
        }
    };

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    };

    const handleCheckButtonClick = () => {
        if (tooltipOpen) {
            setTooltipOpen(false);
        } else {
            setTooltipOpen(true);
        }
    };

    const handleEvaluationModalClose = () => {
        setEvaluationModalOpen(false);
    };

    const handlePayForCourseModalClose = () => {
        setPayForCourseModalOpen(false);
    };

    const history = useHistory();

    const handleStudentAnnouncementClick = useCallback(
        (announcement: AnnouncementResponse) => {
            (async () => {
                setTooltipOpen(false);
                if (announcement.body) {
                    history.push(`/MyPage/Announcements/${announcement.announcementId}`);
                } else if (announcement.linkTo) {
                    history.push(announcement.linkTo);
                }
                await updateStudentCheckedAnnouncements({
                    studentId: studentId,
                    announcementId: announcement.announcementId,
                });
            })();
        },
        [history, studentId, updateStudentCheckedAnnouncements],
    );

    // const handleRatingChange = (newValue: number) => {
    //     setRating(newValue);
    // };

    // const handleCommentChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    //     const value = e.target.value as string;
    //     setComment(value);
    // };

    const getAnnouncementRequest = (studentAnnouncement: AnnouncementResponse) => {
        // if (studentAnnouncement.announcementType === "checkSchedule") {
        //     return "確認してください";
        // } else if (studentAnnouncement.announcementType === "overallEvaluation") {
        //     return "総合評価を行ってください";
        // } else if (studentAnnouncement.announcementType === "regularEvaluation") {
        //     return "対象期間の授業評価を行ってください";
        // } else if (studentAnnouncement.announcementType === "acceptReport") {
        //     return "対象の授業報告を承認してください";
        // } else if (studentAnnouncement.announcementType === "payForCourse") {
        //     return "支払い情報を入力してください";
        // }
    };

    return (
        <>
            <AnnouncementsTooltip
                open={tooltipOpen}
                badgeContent={props.announcements.length}
                handleTooltipClose={handleTooltipClose}
                handleTooltipOpen={handleTooltipOpen}
                handleCheckButtonClick={handleCheckButtonClick}
                title={
                    <div className={styles.announcementTooltipContents}>
                        <div className={styles.announcementListTitle}>お知らせ</div>
                        {props.announcements.length ? (
                            <ul className={styles.announcements} style={{ maxHeight: maxModalHeight }}>
                                {props.announcements.map((announcement, idx) => {
                                    return (
                                        <li className={styles.announcement}>
                                            <Button
                                                key={announcement.announcementId}
                                                className={styles.announcementButton}
                                                onClick={() => {
                                                    handleStudentAnnouncementClick(announcement);
                                                }}
                                                style={{ borderTop: idx === 0 ? "none" : "1px solid #CCC" }}
                                            >
                                                <AvatarFromS3
                                                    url={announcement.iconImageUrl ?? trailIcon}
                                                    objectKey={announcement.iconImageObjectKey}
                                                    className={styles.avatar}
                                                />
                                                <div className={styles.rightWrapper}>
                                                    <div className={styles.outline}>{announcement.title}</div>
                                                    <div className={styles.diffTime}>
                                                        {announcement.createdAt &&
                                                            getDiffTime(new Date(announcement.createdAt))}
                                                    </div>
                                                </div>
                                            </Button>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            <div className={styles.nothing}>新しいお知らせはありません。</div>
                        )}
                    </div>
                }
            />
        </>
    );
};
