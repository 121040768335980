import FormHelperText from "@material-ui/core/FormHelperText";
import { memo } from "react";

import styles from "@/pages/Student/MyPage/index.module.scss";

interface Props {
    value: string;
    upperLimit: number;
}

export const Over: React.VFC<Props> = memo(function Over(props) {
    return (
        <>
            {props.value && props.value.length > props.upperLimit && (
                <FormHelperText className={styles.helperText}>
                    {props.upperLimit}文字以下で入力してください。
                </FormHelperText>
            )}
        </>
    );
});
