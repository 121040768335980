import { QueryLoadingWrapperOnlyPart } from "@/components/QueryLoadingWrapper/QueryLoadingWrapperPart";
import { useGetLatestPublicRequestsForShortCourseQuery } from "@/store/hooks/publicRequests";

import { RequestCardsBase } from "@/components/RequestCardsBase";

interface Props {
    repetitionNumber: number | undefined;
}

export const LatestRequestsForShortCourse: React.VFC<Props> = (props) => {
    const latestRequestsForShortCourseQuery = useGetLatestPublicRequestsForShortCourseQuery(15);
    return (
        <QueryLoadingWrapperOnlyPart {...latestRequestsForShortCourseQuery}>
            {(requests, isLoaded) => (
                <RequestCardsBase
                    title="新着単発・短期講座リクエスト"
                    requests={requests ?? []}
                    destination="/SearchResults?searchType=publicRequest&keyword=&courseType=short&level=&category=&subCategory=&rankNumber=&university=&faculty=&department="
                    isLoaded={isLoaded}
                    repetitionNumber={props.repetitionNumber}
                    hasTwoLines
                />
            )}
        </QueryLoadingWrapperOnlyPart>
    );
};
