import { Button, Popover } from "@material-ui/core";

import { CreateBaseScheduleParams, RepetitionUnit } from "@/store/autogenApi";

import styles from "./index.module.scss";
import { memo, useCallback } from "react";
import { WHAT_DAY_LIST } from "@/utils/WhatDayList";
import { ErrorMessage } from "../ErrorMessage";

interface Props {
    open: boolean;
    isNewBaseScheduleChecked: boolean;
    newBaseSchedule: Partial<CreateBaseScheduleParams> | undefined;
    anchorEl: HTMLButtonElement | null;
    daysValidation: boolean;
    timeValidation: boolean;
    repetitionValidation: boolean;
    handleClose: () => void;
    handleStartTimeChange: (hour: number, minute: number) => void;
    handleHowManyMinutesChange: (howManyMinutes: number) => void;
    handleDayChange: (
        e: React.ChangeEvent<{
            checked: boolean;
        }>,
        dayIdx: number,
    ) => void;
    handleRepetitionUnitNumberChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleRepetitionUnitChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleHowManyTimesChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    confirmBaseSchedule: () => void;
}

const START_TIMES = [...Array(96)].map((_, idx) => {
    const hour = ("00" + Math.floor(idx / 4)).slice(-2);
    const minute = idx % 4 === 0 ? "00" : idx % 4 === 1 ? "15" : idx % 4 === 2 ? "30" : "45";
    return `${hour}:${minute}`;
});

const MINUTES_INTERVAL = 15;
const TOTAL_MINUTES = 12 * 60; // 12時間
const COURSE_TIME_LIST_IN_15_MINITE_INTERVALS = [...Array(TOTAL_MINUTES / MINUTES_INTERVAL + 1)].map(
    (_, idx) => idx * MINUTES_INTERVAL,
);

export const BaseSchedulePopover: React.VFC<Props> = memo(function WhichTimePopover(props) {
    const getEndTimes = useCallback(() => {
        return START_TIMES.filter((startTime, idx) => {
            if (!props.newBaseSchedule) return false;
            if (props.newBaseSchedule.startHour == undefined) return false;
            if (props.newBaseSchedule.startMinute == undefined) return false;
            const [hour, minute] = startTime.split(":").map((str) => parseInt(str));
            const diffTime =
                hour * 60 + minute - (props.newBaseSchedule.startHour * 60 + props.newBaseSchedule.startMinute);
            if (0 <= diffTime && diffTime <= 90) return true;
            const isAfter =
                hour * 60 + minute >= props.newBaseSchedule.startHour * 60 + props.newBaseSchedule.startMinute;
            const isDivisibleBy30 = diffTime % 30 === 0;
            return isAfter && isDivisibleBy30;
        });
    }, [props.newBaseSchedule]);

    const handleStartTimeChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            const value = e.target.value;
            const [hour, minute] = value.split(":").map((str) => parseInt(str));
            props.handleStartTimeChange(hour, minute);
        },
        [props.handleStartTimeChange],
    );

    const handleEndTimeChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            const value = e.target.value;
            const [hour, minute] = value.split(":").map((str) => parseInt(str));
            const startTimeHour = props.newBaseSchedule?.startHour ?? 0;
            const startTimeMinute = props.newBaseSchedule?.startMinute ?? 0;
            const howManyMinutes = (hour - startTimeHour) * 60 + (minute - startTimeMinute);
            props.handleHowManyMinutesChange(howManyMinutes);
        },
        [props.handleHowManyMinutesChange],
    );

    const handleHowManyMinutesChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            const value = e.target.value;
            const howManyMinutes = parseInt(value);
            props.handleHowManyMinutesChange(howManyMinutes);
        },
        [props.handleHowManyMinutesChange],
    );

    const getValidRepetitionUnitNumberList = useCallback(() => {
        if (!props.newBaseSchedule) return [1];
        const repetitionUnitNumber = props.newBaseSchedule.repetitionUnitNumber ?? 1;
        const repetitionUnit = props.newBaseSchedule.repetitionUnit ?? "week";
        switch (repetitionUnit) {
            case "day":
                return [...Array(6)].map((_, i) => i + 1);
            case "week":
                return [...Array(4)].map((_, i) => i + 1);
            case "month":
                return [1];
        }
    }, [props.newBaseSchedule]);

    const getValidHowManyTimesList = useCallback(() => {
        if (!props.newBaseSchedule) return [1];
        const repetitionUnitNumber = props.newBaseSchedule.repetitionUnitNumber ?? 1;
        const repetitionUnit = props.newBaseSchedule.repetitionUnit ?? "week";
        const dayArrayBaseNumber = repetitionUnitNumber === 1 ? 1 : repetitionUnitNumber - 1;
        const weekArrayBaseNumber = repetitionUnitNumber * 7 - 1;
        switch (repetitionUnit) {
            case "day":
                return [...Array(dayArrayBaseNumber)].map((_, i) => i + 1);
            case "week":
                return [...Array(weekArrayBaseNumber)].map((_, i) => i + 1);
            case "month":
                return [...Array(27)].map((_, i) => i + 1);
        }
    }, [props.newBaseSchedule]);

    const getDiffTime = useCallback(
        (endTime: string) => {
            const [hour, minute] = endTime.split(":").map((str) => parseInt(str));
            const startTimeHour = props.newBaseSchedule?.startHour ?? 0;
            const startTimeMinute = props.newBaseSchedule?.startMinute ?? 0;
            const howManyMinutes = (hour - startTimeHour) * 60 + (minute - startTimeMinute);
            if (howManyMinutes < 60) return `${howManyMinutes}分`;
            if (howManyMinutes % 60 === 0) return `${howManyMinutes / 60}時間`;
            return `${Math.floor(howManyMinutes / 60)}時間${howManyMinutes % 60}分`;
        },
        [props.newBaseSchedule],
    );

    const getStartTime = useCallback(() => {
        if (!props.newBaseSchedule) return "0:00";
        const hour = props.newBaseSchedule.startHour ?? 0;
        const minute = props.newBaseSchedule.startMinute ?? 0;
        const hourStr = ("00" + hour).slice(-2);
        const minuteStr = ("00" + minute).slice(-2);
        return `${hourStr}:${minuteStr}`;
    }, [props.newBaseSchedule]);

    const getEndTime = useCallback(() => {
        if (!props.newBaseSchedule) return "0:00";
        const startHour = props.newBaseSchedule.startHour ?? 0;
        const startMinute = props.newBaseSchedule.startMinute ?? 0;
        const howManyMinutes = props.newBaseSchedule.howManyMinutes ?? 0;
        const hour = Math.floor((startHour * 60 + startMinute + howManyMinutes) / 60);
        const minute = (startHour * 60 + startMinute + howManyMinutes) % 60;
        const hourStr = ("00" + hour).slice(-2);
        const minuteStr = ("00" + minute).slice(-2);
        return `${hourStr}:${minuteStr}`;
    }, [props.newBaseSchedule]);

    return (
        <Popover
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.handleClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            className={styles.timePopOver}
        >
            <div className={styles.popOverContents}>
                <div className={styles.intervalWrapper}>
                    <div className={styles.intervalTitle}>頻度</div>
                    <div className={styles.intervalFlex}>
                        <select
                            className={styles.repetitionUnitNumber}
                            defaultValue={1}
                            value={props.newBaseSchedule?.repetitionUnitNumber}
                            onChange={props.handleRepetitionUnitNumberChange}
                        >
                            {getValidRepetitionUnitNumberList().map((num) => (
                                <option value={num}>{num}</option>
                            ))}
                        </select>
                        <select
                            className={styles.repetitionUnit}
                            defaultValue="week"
                            value={props.newBaseSchedule?.repetitionUnit}
                            onChange={props.handleRepetitionUnitChange}
                        >
                            <option value="day">日</option>
                            <option value="week">週間</option>
                            <option value="month">か月</option>
                        </select>
                        に
                        <select
                            className={styles.howManyTimes}
                            defaultValue={1}
                            value={props.newBaseSchedule?.howManyTimes}
                            onChange={props.handleHowManyTimesChange}
                        >
                            {getValidHowManyTimesList().map((num) => (
                                <option value={num}>{num}</option>
                            ))}
                        </select>
                        回
                    </div>
                    <ErrorMessage
                        when={props.isNewBaseScheduleChecked && !props.repetitionValidation}
                        content="有効な頻度を選択してください"
                    />
                </div>
                {props.newBaseSchedule?.repetitionUnitNumber === 1 &&
                    props.newBaseSchedule?.repetitionUnit === "week" && (
                        <div className={styles.daysWrapper}>
                            <div className={styles.daysTitle}>曜日</div>
                            <div className={styles.days}>
                                {[...Array(7)].map((_, idx) => (
                                    <div className={styles.day}>
                                        <input
                                            type="checkbox"
                                            id={`dayCheckbox${idx}`}
                                            className={styles.dayInput}
                                            checked={props.newBaseSchedule?.dayOfWeekIndexes?.includes(idx) ?? false}
                                            onChange={(e) => props.handleDayChange(e, idx)}
                                        />
                                        <label htmlFor={`dayCheckbox${idx}`} className={styles.dayLabel}>
                                            {WHAT_DAY_LIST[idx]}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <ErrorMessage
                                when={props.isNewBaseScheduleChecked && !props.daysValidation}
                                content="曜日は「頻度」で選んだ回数分選択してください"
                            />
                        </div>
                    )}
                {props.newBaseSchedule?.repetitionUnit === "week" &&
                props.newBaseSchedule.repetitionUnitNumber === 1 ? (
                    <div className={styles.timeWrapper}>
                        <div className={styles.timeTitle}>時間</div>
                        <div className={styles.timeFlex}>
                            <select
                                className={styles.timeSelect}
                                onChange={handleStartTimeChange}
                                defaultValue="00:00"
                                value={getStartTime()}
                            >
                                {START_TIMES.map((time) => (
                                    <option className={styles.time} value={time}>
                                        {time}
                                    </option>
                                ))}
                            </select>
                            〜
                            <select
                                className={styles.timeSelect}
                                onChange={handleEndTimeChange}
                                defaultValue="00:00"
                                value={getEndTime()}
                            >
                                {getEndTimes().map((time) => (
                                    <option className={styles.time} value={time}>
                                        {time} ({getDiffTime(time)})
                                    </option>
                                ))}
                            </select>
                        </div>
                        <ErrorMessage
                            when={props.isNewBaseScheduleChecked && !props.timeValidation}
                            content="有効な時間を選択してください"
                        />
                    </div>
                ) : (
                    <div className={styles.timeWrapper}>
                        <div className={styles.timeTitle}>時間</div>
                        <div className={styles.timeFlex}>
                            各
                            <select
                                className={styles.minutesSelect}
                                onChange={handleHowManyMinutesChange}
                                defaultValue="0"
                                value={props.newBaseSchedule?.howManyMinutes}
                            >
                                {COURSE_TIME_LIST_IN_15_MINITE_INTERVALS.map((minutes) => (
                                    <option className={styles.time} value={minutes}>
                                        {minutes}
                                    </option>
                                ))}
                            </select>
                            分
                        </div>
                        <ErrorMessage
                            when={props.isNewBaseScheduleChecked && !props.timeValidation}
                            content="有効な時間を選択してください"
                        />
                    </div>
                )}
                <div className={styles.scheduleButtonsWrapper}>
                    <Button className={styles.scheduleConfirmButton} onClick={props.confirmBaseSchedule}>
                        確定
                    </Button>
                </div>
            </div>
        </Popover>
    );
});
