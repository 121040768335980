import { memo, useCallback, useEffect, useState } from "react";
import { ClassResponse } from "@/store/autogenApi";
import styles from "./index.module.scss";
import { GroupChat } from "@/components/GroupChat";
import { useHistory, useLocation } from "react-router";
import { LessonCalendarContents } from "@/components/LessonCalendar";
import { useGetStudentLessonsQuery } from "@/store/hooks/lessons";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { TabInfo, TabsTemplate } from "@/components/TabsTemplate";
import { TabPanel } from "@/components/TabPanel";
import { OverTabs } from "@/components/OverTabs";
import { PlainButton } from "@/components/PlainButton";
import { ClassInfo } from "./ClassInfo";
import { Tabs, useMediaQuery } from "@material-ui/core";

interface Props {
    class: ClassResponse;
    disableToJoin: boolean;
}

interface TabPanelsProps {
    targetTabValue: string;
    class: ClassResponse;
    disableToJoin: boolean;
}

const TAB_INFO_LIST: TabInfo[] = [
    { value: "classInfo", label: "クラス情報" },
    { value: "lessonCalendar", label: "授業カレンダー" },
    { value: "chat", label: "グループチャット" },
];

export const MainContents: React.VFC<Props> = memo(function MainContents(props) {
    const [targetTabValue, setTargetTabValue] = useState<string>("classInfo");

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const tagQueryParams = searchParams.get("tag");

    const mdUp = useMediaQuery("(min-width:960px)");

    const history = useHistory();

    useEffect(() => {
        if (!tagQueryParams) return;
        setTargetTabValue("lessonCalendar");
    }, [tagQueryParams]);

    const handleFAQButtonClick = useCallback(() => {
        history.push("/FAQ");
    }, []);

    const handleContactButtonClick = useCallback(() => {
        history.push("/Inquiry");
    }, []);

    const handleTabChange = useCallback((e: React.ChangeEvent<Record<string, unknown>>, newValue: string) => {
        setTargetTabValue(newValue);
    }, []);

    return (
        <div className={styles.targetClassWrapper}>
            <div className={styles.mainWrapper}>
                {mdUp ? (
                    <TabsTemplate
                        tabInfoList={TAB_INFO_LIST}
                        targetTabValue={targetTabValue}
                        handleTabChange={handleTabChange}
                        style={{ borderRadius: "4px 4px 0 0 !important" }}
                    >
                        <TabPanels
                            targetTabValue={targetTabValue}
                            class={props.class}
                            disableToJoin={props.disableToJoin}
                        />
                    </TabsTemplate>
                ) : (
                    <OverTabs
                        tabInfoList={TAB_INFO_LIST}
                        targetTabValue={targetTabValue}
                        handleTabChange={handleTabChange}
                    >
                        <TabPanels
                            targetTabValue={targetTabValue}
                            class={props.class}
                            disableToJoin={props.disableToJoin}
                        />
                    </OverTabs>
                )}
            </div>
            <div className={styles.bottomButtonsWrapper}>
                <PlainButton text="よくある質問を確認する" handleClick={handleFAQButtonClick} />
                ｜
                <PlainButton text="運営に問い合わせる" handleClick={handleContactButtonClick} />
            </div>
        </div>
    );
});

const TabPanels: React.VFC<TabPanelsProps> = memo(function Tabs(props) {
    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);

    const studentLessonsQueryState = useGetStudentLessonsQuery(studentId);
    return (
        <>
            <TabPanel hidden={props.targetTabValue !== "classInfo"}>
                <ClassInfo classResponse={props.class} disableToJoin={props.disableToJoin} />
            </TabPanel>
            <TabPanel hidden={props.targetTabValue !== "lessonCalendar"}>
                <QueryLoadingWrapper {...studentLessonsQueryState}>
                    {(lessons) => <LessonCalendarContents lessons={lessons} isDraggable={false} />}
                </QueryLoadingWrapper>
            </TabPanel>
            <TabPanel hidden={props.targetTabValue !== "chat"}>
                <div className={styles.groupChatWrapper}>
                    <GroupChat createdAt={new Date(props.class.createdAt)} class={props.class} />
                </div>
            </TabPanel>
        </>
    );
});
