import { Button } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import styles from "./index.module.scss";
import { useGetNewsRecordQuery } from "@/store/hooks/newsRecords";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { NewsContents } from "./NewsContents";
import { memo } from "react";

export const News: React.VFC = memo(function News() {
    const { newsRecordId } = useParams<{ newsRecordId: string }>();

    const newsRecordQueryState = useGetNewsRecordQuery(newsRecordId);

    return (
        <>
            <div className={styles.newsWrapper}>
                <QueryLoadingWrapper {...newsRecordQueryState}>
                    {(newsRecord) => <NewsContents newsRecord={newsRecord} />}
                </QueryLoadingWrapper>
                <Button component={Link} to="/NewsList" className={styles.toHomeButton}>
                    ニュース一覧へ
                </Button>
            </div>
        </>
    );
});
