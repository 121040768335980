import { memo, useCallback, useEffect, useState } from "react";

import { Modal } from "@material-ui/core";
import styles from "./index.module.scss";
import BronzeBadge from "@/images/B.png";
import GoldBadge from "@/images/G.png";
import PlatinumBadge from "@/images/P.png";
import RegularBadge from "@/images/R.png";
import SilverBadge from "@/images/S.png";
import { CloseIcon } from "@/globalIcons";

interface Props {
    open: boolean;
    handleClose: () => void;
}

export const AboutFeeModal: React.VFC<Props> = memo(function AboutFeeModal(props) {
    const [modalHeight, setModalHeight] = useState<number>(0);

    const changeModalHeight = useCallback(() => {
        const innerHeight = window.innerHeight;
        setModalHeight(innerHeight * 0.75);
    }, []);

    useEffect(() => {
        changeModalHeight();
        window.addEventListener("resize", () => {
            changeModalHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalHeight();
            });
        };
    }, []);

    return (
        <>
            <Modal open={props.open} onClose={props.handleClose}>
                <div className={styles.modalContents} style={{ maxHeight: modalHeight }}>
                    <button className={styles.closeButton} onClick={props.handleClose}>
                        <CloseIcon className={styles.closeIcon} />
                    </button>
                    <div className={styles.feeTitle}>手数料</div>
                    <div className={styles.feeDescription}>
                        授業料は「売上金」としてアカウントに付与されます。
                        <br />
                        「売上金」を先生ご自身の銀行口座に対して振込申請する際に手数料が発生します。
                        <br />
                        手数料は以下の表のように先生のランクに応じて算出されます。
                        （先生のランクは月初に実績に応じて自動的に決定されます。）
                        <br />
                    </div>
                    <table className={styles.feeTable}>
                        <tbody>
                            <tr className={styles.tableRow}>
                                <th className={styles.tableHeader}>ランク</th>
                                <td className={styles.tableData}>-</td>
                                <td className={styles.tableData}>レギュラー</td>
                                <td className={styles.tableData}>ブロンズ</td>
                                <td className={styles.tableData}>シルバー</td>
                                <td className={styles.tableData}>ゴールド</td>
                                <td className={styles.tableData}>プラチナ</td>
                            </tr>
                            <tr className={styles.tableRow}>
                                <th className={styles.tableHeader}>バッジ</th>
                                <td className={styles.tableData}>-</td>
                                <td className={styles.tableData}>
                                    <img src={RegularBadge} alt="" className={styles.badgeImage} />
                                </td>
                                <td className={styles.tableData}>
                                    <img src={BronzeBadge} alt="" className={styles.badgeImage} />
                                </td>
                                <td className={styles.tableData}>
                                    <img src={SilverBadge} alt="" className={styles.badgeImage} />
                                </td>
                                <td className={styles.tableData}>
                                    <img src={GoldBadge} alt="" className={styles.badgeImage} />
                                </td>
                                <td className={styles.tableData}>
                                    <img src={PlatinumBadge} alt="" className={styles.badgeImage} />
                                </td>
                            </tr>
                            <tr className={styles.tableRow}>
                                <th className={styles.tableHeader}>累計授業数</th>
                                <td className={styles.tableData}>-</td>
                                <td className={styles.tableData}>1以上</td>
                                <td className={styles.tableData}>10以上</td>
                                <td className={styles.tableData}>30以上</td>
                                <td className={styles.tableData}>50以上</td>
                                <td className={styles.tableData}>100以上</td>
                            </tr>
                            <tr className={styles.tableRow}>
                                <th className={styles.tableHeader}>直近3か月の売上金</th>
                                <td className={styles.tableData}>-</td>
                                <td className={styles.tableData}>-</td>
                                <td className={styles.tableData}>¥30,000以上</td>
                                <td className={styles.tableData}>¥50,000以上</td>
                                <td className={styles.tableData}>¥100,000以上</td>
                                <td className={styles.tableData}>¥200,000以上</td>
                            </tr>
                            <tr className={styles.tableRow}>
                                <th className={styles.tableHeader}>レビューの平均点</th>
                                <td className={styles.tableData}>-</td>
                                <td className={styles.tableData}>-</td>
                                <td className={styles.tableData}>3.0以上</td>
                                <td className={styles.tableData}>3.5以上</td>
                                <td className={styles.tableData}>4.0以上</td>
                                <td className={styles.tableData}>4.5以上</td>
                            </tr>
                            <tr className={styles.tableRow}>
                                <th className={styles.tableHeader}>先生の授業手数料</th>
                                <td className={styles.tableData}>30%</td>
                                <td className={styles.tableData}>28%</td>
                                <td className={styles.tableData}>26%</td>
                                <td className={styles.tableData}>24%</td>
                                <td className={styles.tableData}>22%</td>
                                <td className={styles.tableData}>20%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Modal>
        </>
    );
});
