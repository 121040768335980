import { Button, Hidden } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import styles from "./index.module.scss";
import { CreateLessonRequestParams, LessonResponse } from "@/store/autogenApi";
import { WHAT_DAY_LIST } from "@/utils/WhatDayList";
import { memo, useCallback, useState } from "react";
import { ErrorMessage } from "@/components/ErrorMessage";
import { BiTimeFive } from "react-icons/bi";
import { DeadlinePopover } from "@/components/DeadlinePopover";
import { DatePicker } from "@/components/DatePicker";
import { LessonCalendarButton } from "@/components/LessonCalendarButton";
import { HourAndMinute } from "@/utils/DateUtils";
import { ListItem } from "../ListItem";

interface Props {
    isChecked: boolean;
    isConfirmMode: boolean;
    confirmModalOpen?: boolean;
    deadlineValidation: boolean;
    otherCourseScheduleValidation: boolean;
    lengthValidation: boolean;
    isNotDuplicateValidation: boolean;
    existingLessons: LessonResponse[];
    lessons: CreateLessonRequestParams[];
    applyingDeadline: Date;
    handleLessonsChange: (newLessons: CreateLessonRequestParams[]) => void;
    handleApplyingDeadlineChange: (newApplyingDeadline: Date) => void;
}

export interface StartAndFinishDate {
    startDate: Date;
    finishDate: Date;
}

export const ShortCourseSchedules: React.VFC<Props> = memo(function ShortCourseSchedules(props) {
    const [deadlineAnchorEl, setDeadlineAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [newDeadlineTime, setNewDeadlineTime] = useState<HourAndMinute>({
        hour: null,
        minute: null,
    });

    const deadlinePopoverOpen = Boolean(deadlineAnchorEl);

    const handleDeadlineDateChange = useCallback(
        (newDate: Date) => {
            if (!props.applyingDeadline) return;
            const newDeadline = new Date(props.applyingDeadline);
            newDeadline.setFullYear(newDate.getFullYear());
            newDeadline.setMonth(newDate.getMonth());
            newDeadline.setDate(newDate.getDate());
            props.handleApplyingDeadlineChange(newDeadline);
        },
        [props.applyingDeadline, props.handleApplyingDeadlineChange],
    );

    const getJapanesePeriod = useCallback((startTime: Date, endTime: Date) => {
        const month = startTime.getMonth() + 1;
        const date = startTime.getDate();
        const startHour = startTime.getHours();
        const startMinute = startTime.getMinutes();
        const finishHour = endTime.getHours();
        const finishMinute = endTime.getMinutes();
        const fixedMonth = ("00" + month).slice(-2);
        const fixedDate = ("00" + date).slice(-2);
        const fixedStartHour = ("00" + startHour).slice(-2);
        const fixedStartMinute = ("00" + startMinute).slice(-2);
        const fixedFinishHour = ("00" + finishHour).slice(-2);
        const fixedFinishMinute = ("00" + finishMinute).slice(-2);
        const dayIdx = startTime.getDay();
        const result = `${fixedMonth}月${fixedDate}日(${WHAT_DAY_LIST[dayIdx]}) ${fixedStartHour}:${fixedStartMinute} ~ ${fixedFinishHour}:${fixedFinishMinute}`;
        return result;
    }, []);

    const getIsDisabledDeadline = useCallback((date: MaterialUiPickersDate) => {
        if (!date) return true;
        const fixedDate = date as any as Date;
        const midnight = new Date();
        midnight.setHours(0, 0, 0, 0);
        return fixedDate < midnight;
    }, []);

    const getApplicationPeriod = useCallback((date: Date) => {
        const now = new Date();
        let diffTime = date.getTime() - now.getTime();
        const diffDate = Math.floor(diffTime / (1000 * 24 * 60 * 60));
        diffTime -= diffDate * 1000 * 24 * 60 * 60;
        const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
        return `${diffDate}日と${diffHours}時間`;
    }, []);

    const getJapaneseTime = useCallback((value: Date) => {
        const month = value.getMonth() + 1;
        const date = value.getDate();
        const hour = value.getHours();
        const minute = value.getMinutes();
        const fixedMonth = ("00" + month).slice(-2);
        const fixedDate = ("00" + date).slice(-2);
        const fixedStartHour = ("00" + hour).slice(-2);
        const fixedStartMinute = ("00" + minute).slice(-2);
        const dayIdx = value.getDay();
        const result = `${fixedMonth}月${fixedDate}日(${WHAT_DAY_LIST[dayIdx]}) ${fixedStartHour}:${fixedStartMinute}`;
        return result;
    }, []);

    const handleTimeButtonForDeadlineClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setDeadlineAnchorEl(event.currentTarget);
            const applyingDeadline = props.applyingDeadline;
            setNewDeadlineTime({
                hour: applyingDeadline ? new Date(applyingDeadline).getHours() : null,
                minute: applyingDeadline ? new Date(applyingDeadline).getMinutes() : null,
            });
        },
        [props.applyingDeadline],
    );

    const handleDeadlinePopoverClose = useCallback(() => {
        setDeadlineAnchorEl(null);
    }, []);

    const confirmDeadlineTime = useCallback(() => {
        const applyingDeadline = new Date(props.applyingDeadline);
        if (typeof newDeadlineTime.hour === "number") {
            applyingDeadline.setHours(newDeadlineTime.hour);
        }
        if (typeof newDeadlineTime.minute === "number") {
            applyingDeadline.setMinutes(newDeadlineTime.minute);
        }
        props.handleApplyingDeadlineChange(applyingDeadline);
        handleDeadlinePopoverClose();
    }, [newDeadlineTime, props.applyingDeadline, props.handleApplyingDeadlineChange, handleDeadlinePopoverClose]);

    const handleDeadlineTimeChange = useCallback(
        (e: React.ChangeEvent<{ name: string; value: number }>) => {
            if (e.target.name === "hour") {
                setNewDeadlineTime({ ...newDeadlineTime, hour: e.target.value });
            } else {
                setNewDeadlineTime({ ...newDeadlineTime, minute: e.target.value });
            }
        },
        [newDeadlineTime],
    );

    return (
        <ListItem
            isConfirmMode={props.isConfirmMode}
            title="日程"
            hasSpecialRightWrapper
            inputModeContents={
                <>
                    <div className={styles.lessonCalendarWrapper}>
                        <LessonCalendarButton
                            isChecked={props.isChecked}
                            lessons={props.lessons}
                            existingLessons={props.existingLessons}
                            lessonsDuplicateValidation={props.isNotDuplicateValidation}
                            otherCourseLessonDuplicateValidation={props.otherCourseScheduleValidation}
                            handleLessonsChange={props.handleLessonsChange}
                        />
                        <ErrorMessage
                            content="他の講座日程と被っているため、この日程では登録できません。"
                            when={props.isChecked && !props.otherCourseScheduleValidation}
                            style={{ textAlign: "left", width: "100%", marginTop: "10px" }}
                        />
                    </div>
                    <ErrorMessage
                        content="この項目は必須です。少なくとも1つの日程を入力してください。"
                        when={props.isChecked && !props.lengthValidation}
                        style={{ textAlign: "left", width: "100%", marginTop: "10px" }}
                    />
                    <div className={styles.deadlineWrapper}>
                        <div className={styles.deadlineFlexWrapper}>
                            <div className={styles.deadlineTitle}>募集締め切り：</div>
                            <div className={styles.deadlineInnerDiv}>
                                <div className={styles.dateAndTimeWrapper}>
                                    <DatePicker
                                        date={props.applyingDeadline}
                                        handleDateChange={handleDeadlineDateChange}
                                        getIsDisabledDate={getIsDisabledDeadline}
                                    />
                                    <Button
                                        className={styles.timeButton}
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                            handleTimeButtonForDeadlineClick(e);
                                        }}
                                    >
                                        <div className={styles.selectedTimesWrapper}>
                                            <div className={styles.selectedTime}>
                                                <span className={styles.whichTime}>
                                                    {(
                                                        "0" +
                                                        (props.applyingDeadline
                                                            ? new Date(props.applyingDeadline).getHours()
                                                            : 0)
                                                    ).slice(-2)}
                                                </span>
                                                <span className={styles.colon}>:</span>
                                                <span className={styles.whichTime}>
                                                    {(
                                                        "0" +
                                                        (props.applyingDeadline
                                                            ? new Date(props.applyingDeadline).getMinutes()
                                                            : 0)
                                                    ).slice(-2)}
                                                </span>
                                            </div>
                                        </div>
                                        <Hidden smDown>
                                            <BiTimeFive className={styles.timeIcon} />
                                        </Hidden>
                                    </Button>
                                    <DeadlinePopover
                                        open={deadlinePopoverOpen}
                                        anchorEl={deadlineAnchorEl}
                                        newDeadlineTime={newDeadlineTime}
                                        confirmDeadlineTime={confirmDeadlineTime}
                                        handleClose={handleDeadlinePopoverClose}
                                        handleDeadlineTimeChange={handleDeadlineTimeChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {props.deadlineValidation && props.applyingDeadline && (
                            <div className={styles.applicationPeriod}>
                                {`(残り${getApplicationPeriod(new Date(props.applyingDeadline))})`}
                            </div>
                        )}
                        {props.lessons.length > 0 && (
                            <ErrorMessage
                                content="締め切り日時は、初回授業日時より前になるように設定してください。"
                                when={!props.deadlineValidation}
                                style={{ textAlign: "left", width: "100%", marginTop: "10px" }}
                            />
                        )}
                    </div>
                </>
            }
            confirmModeContents={
                <div className={styles.schedulesWrapper}>
                    <ul className={styles.schedules}>
                        {props.lessons?.map((lesson, idx2) => {
                            return (
                                <li className={styles.schedule} key={idx2}>
                                    ・
                                    {lesson.startTime &&
                                        lesson.endTime &&
                                        getJapanesePeriod(new Date(lesson.startTime), new Date(lesson.endTime))}
                                </li>
                            );
                        })}
                    </ul>
                    {props.applyingDeadline && (
                        <div className={styles.applyingDeadlineWrapper}>
                            <div className={styles.applyingDeadlineTitle}>
                                {`募集締め切り：${getJapaneseTime(new Date(props.applyingDeadline))}`}
                            </div>
                            <div className={styles.applyingDeadline}>
                                {`(残り${getApplicationPeriod(new Date(props.applyingDeadline))})`}
                            </div>
                        </div>
                    )}
                </div>
            }
        />
    );
});
