import { memo, useCallback } from "react";
import { RiMoneyCnyCircleFill } from "react-icons/ri";

import styles from "@/pages/Common/PublicRequestDetails/index.module.scss";

interface Props {
    minPrice?: number;
    maxPrice?: number;
    courseType?: string;
}

export const Price: React.VFC<Props> = memo(function Price(props) {
    const getUnit = useCallback(() => {
        if (props.courseType === "short") return "講座全体で";
        if (props.courseType === "regular") return "1時間あたり";
    }, [props.courseType]);
    return (
        <div className={styles.detailWrapper}>
            <div className={styles.detailHeadingWrapper}>
                <span className={styles.detailHeading}>予算</span>
            </div>
            <div className={styles.detail} id="detail">
                {`${getUnit()}：¥${props.minPrice} ~ ¥${props.maxPrice}`}
            </div>
        </div>
    );
});
