import { memo } from "react";
import { Button } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PeopleIcon from "@material-ui/icons/People";
import { GiTeacher } from "react-icons/gi";
import { Link } from "react-router-dom";
import styles from "@/pages/Common/CourseDetails/index.module.scss";
import { UserType } from "@/protectedRouter/ProtectedRouterConfig";
import { PublicTeacherResponse } from "@/store/autogenApi";
import { MessageComponent } from "./MessageComponent";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    studentId: string | null | undefined;
    teacher: PublicTeacherResponse;
    userMode: UserType;
}

export const TeacherInfo: React.VFC<Props> = memo(function TeacherInfo(props) {
    return (
        <div className={styles.teacherInfo}>
            <div className={styles.teacherHeadingWrapper}>
                <div className={styles.teacherHeading}>担当する先生</div>
            </div>
            <div className={styles.mainTeacherInfo}>
                <div className={styles.leftWrapper}>
                    <AvatarFromS3
                        className={styles.avatar}
                        url={props.teacher.iconImageUrl}
                        objectKey={props.teacher.iconImageObjectKey}
                    />
                    <div className={styles.nickname}>{props.teacher.nickname}先生</div>
                    {props.teacher.identificationStatus === "approved" && (
                        <div className={styles.identificationWrapper}>
                            <CheckCircleIcon className={styles.check} />
                            <span className={styles.identification}>本人確認済み</span>
                        </div>
                    )}
                    <div className={styles.theNumberOfTimesTaughtWrapper}>
                        <GiTeacher className={styles.icon} />
                        <span className={styles.theNumberOfTimesTaught}>
                            総開講回数：{props.teacher.totalAchievements}人
                        </span>
                    </div>
                    <div className={styles.theNumberOfStudentsWrapper}>
                        <PeopleIcon className={styles.icon} />
                        <span className={styles.theNumberOfStudents}>
                            総受講人数：
                            {props.teacher.totalAchievements ? props.teacher.totalAchievements : 0}人
                        </span>
                    </div>
                    <div className={styles.buttonsWrapper}>
                        <Button
                            variant="contained"
                            className={styles.teacherDetailButton}
                            component={Link}
                            to={`/TeacherDetails/${props.teacher.teacherId}`}
                        >
                            先生の詳細をみる
                        </Button>
                        {props.userMode === "student" && props.studentId && (
                            <MessageComponent studentId={props.studentId} teacher={props.teacher} />
                        )}
                    </div>
                </div>
                <div className={styles.rightWrapper}>
                    <div className={styles.catchCopy}>{props.teacher.catchCopy}</div>
                    <div className={styles.teacherDescription}>{props.teacher.contentDescription}</div>
                </div>
            </div>
        </div>
    );
});
