import { Button } from "@material-ui/core";
import { memo } from "react";

import styles from "./index.module.scss";

interface Props {
    errorMessage: string;
    buttonContent: string;
    handleButtonClick: () => void;
}

export const IsError: React.VFC<Props> = memo(function IsError(props) {
    return (
        <div className={styles.errorWrapper}>
            <div className={styles.error}>{props.errorMessage}</div>
            <Button className={styles.button} onClick={props.handleButtonClick}>
                {props.buttonContent}
            </Button>
        </div>
    );
});
