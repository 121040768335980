import { Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { RealName } from "@/pages/Teacher/Identification";
import styles from "@/pages/Teacher/Identification/index.module.scss";
import { memo } from "react";

interface Props {
    idCardTemporaryUrl: string | undefined;
    studentCardTemporaryUrl: string | undefined;
    confirmModalOpen: boolean;
    realName: RealName;
    modalHeight: number;
    handleClose: () => void;
    handleConfirmButtonClick: () => void;
}

export const IdentificationModal: React.VFC<Props> = memo(function IdentificationModal(props) {
    return (
        <>
            <Modal
                open={props.confirmModalOpen}
                onClose={props.handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={styles.modal}
            >
                <div className={styles.identificationModalContents} style={{ maxHeight: props.modalHeight }}>
                    {/* <div className={styles.topMessage}>以下の内容で提出してよろしいですか？</div> */}
                    <div className={styles.confirmList}>
                        <div className={styles.confirmItem} style={{ borderBottom: "1px solid #CCC" }}>
                            <div className={styles.leftWrapper}>
                                <div className={styles.itemTitle}>本名</div>
                            </div>
                            <div className={styles.rightWrapper}>
                                <div className={styles.realName}>
                                    <div className={styles.item}>{props.realName.firstName}</div>
                                    <div className={styles.item}>{props.realName.lastName}</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.confirmItem} style={{ borderBottom: "1px solid #CCC" }}>
                            <div className={styles.leftWrapper}>
                                <div className={styles.itemTitle}>身分証</div>
                            </div>
                            <div className={styles.rightWrapper}>
                                <img src={props.idCardTemporaryUrl} className={styles.image} />
                            </div>
                        </div>
                        <div className={styles.confirmItem}>
                            <div className={styles.leftWrapper}>
                                <div className={styles.itemTitle}>学生証</div>
                            </div>
                            <div className={styles.rightWrapper}>
                                {props.studentCardTemporaryUrl ? (
                                    <img src={props.studentCardTemporaryUrl} className={styles.image} />
                                ) : (
                                    <div className={styles.nothing}>なし</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles.bottomMessage}>この内容でよろしければ「確定」を押してください。</div>
                    <div className={styles.buttonsWrapper}>
                        <Button className={styles.backButton} variant="contained" onClick={props.handleClose}>
                            戻る
                        </Button>
                        <Button
                            className={styles.confirmButton}
                            variant="contained"
                            onClick={() => {
                                props.handleClose();
                                props.handleConfirmButtonClick();
                            }}
                        >
                            確定
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
});
