import styles from "./index.module.scss";
import { NavyButton } from "@/components/Buttons/NavyButton";
import { useHistory } from "react-router";
import { memo, useCallback, useEffect } from "react";
import { ANALYTICS_SERVICES } from "./externalService";
import { ServicesBlock } from "./ServicesBlock";
import { Navigation } from "@/components/Navigation";
import { useNavigation } from "@/components/Navigation/NavigationContext";

const NAVIGATION_LIST: Navigation[] = [{ title: "情報の外部送信について", url: "/ExternalTransmission" }];

export const ExternalTransmission: React.VFC = memo(function ExternalTransmission() {
    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    const history = useHistory();
    const handleBackButtonClick = useCallback(() => {
        history.goBack();
    }, []);
    return (
        <div className={styles.externalTransmissionWrapper}>
            <h2 className={styles.title}>情報の外部送信について</h2>
            <div className={styles.description}>
                当サイトでは、外部サービスによってご利用者様のサイト閲覧情報などのデータが収集されます。ご利用者様のプライバシーに関する情報であるため、外部サービスの一覧および、各サービスが収集するデータの詳細、利用目的、停止方法等について以下にお知らせします。
                各外部サービスにおいて、ご利用者様の情報などがどのように扱われるかについては、各外部サービスのプライバシーポリシーをご確認ください。
                お使いのブラウザにおいて、これらのサービスを無効化したい場合は、それぞれの無効化（オプトアウト）ページをご確認ください。ページが存在しない場合は、お使いのブラウザの設定を変更し、Cookie(WebサイトがスマホやPCの中に保存する情報)を無効にすることで外部送信を防げることがあります。（当サイトでの動作が正常に行えなくなる可能性があります。）
            </div>
            <div className={styles.servicesWrapper}>
                <div className={styles.servicesTitle}>外部サービス一覧</div>
                <ServicesBlock
                    servicesBlockTitle="アクセス解析"
                    servicesBlockDescription="ご利用者様のアクセスを分析し、サイト内の移動経路、検索キーワード、利用環境やアクセスした国・地域を解析することで、利便性の向上やコンテンツの最適化、広告の効果測定などを行うために下記のサービスにご利用者様のウェブサイト閲覧情報などを送信しています。"
                    services={ANALYTICS_SERVICES}
                />
            </div>
            <NavyButton handleClick={handleBackButtonClick}>戻る</NavyButton>
        </div>
    );
});
