import { FAQDetailsBase } from "../../FAQDetailsBase";

import { TAX_RETURN_CONFIGS } from "./TaxReturn";

export const TaxReturn: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="確定申告についてよくある質問"
            text="Trailでの収入について確定申告に関わる質問をまとめました。"
            toc={[
                {
                    title: "確定申告とは？",
                },
            ]}
            FAQConfigs={TAX_RETURN_CONFIGS}
        />
    );
};
