import { useMediaQuery } from "@material-ui/core";
import { memo, useEffect, useState } from "react";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { RequestCardsBase } from "@/components/RequestCardsBase";
import { SubjectResponse } from "@/store/autogenApi";
import { useGetPublicRequestSearchResultsQuery } from "@/store/hooks/publicRequests";
import { getSubject } from "@/utils/SubjectUtils";

interface Props {
    subject: SubjectResponse;
}

export const RequestsWithTargetSubject: React.VFC<Props> = memo(function RequestsWithTargetSubject(props) {
    const [repetitionNumber, setRepetitionNumber] = useState<number | undefined>(undefined);
    const requestsQueryState = useGetPublicRequestSearchResultsQuery({
        level: props.subject.level,
        category: props.subject.category,
        subCategory: props.subject.subCategory,
        take: 5,
        orderBy: "latest",
    });

    const threshold1 = useMediaQuery("(min-width:960px)");
    const threshold2 = useMediaQuery("(min-width:1120px)");
    const threshold3 = useMediaQuery("(min-width:1280px)");

    useEffect(() => {
        if (!threshold1) {
            setRepetitionNumber(undefined);
            return;
        }
        if (!threshold2) {
            setRepetitionNumber(6);
            return;
        }
        if (!threshold3) {
            setRepetitionNumber(7);
            return;
        }
        setRepetitionNumber(8);
    }, [threshold1, threshold2, threshold3]);
    return (
        <QueryLoadingWrapper {...requestsQueryState}>
            {(requests) => (
                <>
                    <RequestCardsBase
                        title={`「${getSubject(props.subject)}」の単発・短期講座リクエスト`}
                        requests={requests.filter((publicRequest) => publicRequest.courseType === "short")}
                        destination=""
                        isLoaded={true}
                        repetitionNumber={repetitionNumber}
                    />
                    <RequestCardsBase
                        title={`「${getSubject(props.subject)}」の定期講座リクエスト`}
                        requests={requests.filter((publicRequest) => publicRequest.courseType === "regular")}
                        destination=""
                        isLoaded={true}
                        repetitionNumber={repetitionNumber}
                    />
                </>
            )}
        </QueryLoadingWrapper>
    );
});
