import {
    Button,
    CircularProgress,
    // Button,
    Modal,
    // TextField
} from "@material-ui/core";

import styles from "@/pages/Teacher/MyPage/index.module.scss";

// import googleButton from "./images/googleButton.png";

interface Props {
    modalOpen: boolean;
    isDetaching: boolean;
    isError: boolean;
    handleClose: () => void;
    handleDeleteConfirmButtonClick: () => void;
}

export const DeleteConfirmationModal: React.VFC<Props> = (props) => {
    return (
        <Modal open={props.modalOpen} onClose={props.handleClose} className={styles.deleteConfirmationModal}>
            <div className={styles.deleteConfirmationModalContents}>
                {props.isError ? (
                    <div className={styles.errorWrapper}>
                        <div className={styles.errorMessage}>処理中にエラーが発生しました。</div>
                        <Button className={styles.errorBackButton} onClick={props.handleClose}>
                            戻る
                        </Button>
                    </div>
                ) : (
                    <>
                        <div className={styles.confirmMessage}>この項目を削除してもよろしいですか？</div>
                        <div className={styles.buttonsWrapper}>
                            <Button className={styles.backButton} onClick={props.handleClose}>
                                キャンセル
                            </Button>
                            <Button className={styles.deleteButton} onClick={props.handleDeleteConfirmButtonClick}>
                                {props.isDetaching ? (
                                    <CircularProgress className={styles.isDetaching} />
                                ) : (
                                    <div className={styles.detach}>削除</div>
                                )}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};
