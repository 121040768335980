import { FAQDetailsBase } from "../../FAQDetailsBase";

import { TEACHER_VERIFICATION_CONFIGS } from "./TeacherVerificationConfigs";

export const TeacherVerification: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="先生の本人確認について"
            text="このページでは、先生の本人確認についてご説明します。先生の本人確認は必須ではありませんが、本人確認を行うことで、先生のプロフィールに「本人確認済み」というアイコンが表示され、より信頼性の高い先生として評価されることができます。本人確認は以下の手順で行うことができます。"
            FAQConfigs={TEACHER_VERIFICATION_CONFIGS}
        />
    );
};
