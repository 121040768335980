import { PrivateRequestResponse } from "@/store/autogenApi";
import { ListItem } from "./ListItem";

interface Props {
    confirmedMonthlyFee: number | undefined;
    borderBottom?: boolean;
}

export const MonthlyFee: React.VFC<Props> = (props) => {
    return (
        <ListItem title="月額料金" borderBottom={props.borderBottom}>
            <>
                {props.confirmedMonthlyFee
                    ? `¥${new Intl.NumberFormat("ja-JP").format(props.confirmedMonthlyFee)}`
                    : `不正な値が入力されています。お手数をおかけし申し訳ありませんが、運営にお問い合わせください。`}
            </>
        </ListItem>
    );
};
