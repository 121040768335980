import FormHelperText from "@material-ui/core/FormHelperText";
import { memo } from "react";

import styles from "@/pages/Teacher/MyPage/index.module.scss";

interface Props {
    value: string | undefined;
    checked: boolean;
}

export const None: React.VFC<Props> = memo(function None(props) {
    return (
        <>
            {!props.value && props.checked && (
                <FormHelperText className={styles.helperText}>入力してください。</FormHelperText>
            )}
        </>
    );
});
