import { memo } from "react";
import { useGetEducationalBackgroundsQuery } from "@/store/hooks/educationalBackgrounds";
import { UniversitiesSearchContents } from "./UniversitiesSearchContents";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { EducationalBackGroundQueries } from "@/pages/Common/CourseSearchResults";

interface Props {
    initial: string;
    targetEducationalBackground: EducationalBackGroundQueries;
    handleIsConfirmedChange: () => void;
    handleBelongingNarrowDownButtonClick: () => void;
    setTargetEducationalBackground: React.Dispatch<React.SetStateAction<EducationalBackGroundQueries>>;
}

export const UniversitiesSearch: React.VFC<Props> = memo(function UniversitiesSearch(props) {
    const educationalBackgroundsQueryState = useGetEducationalBackgroundsQuery(props.initial);

    return (
        <QueryLoadingWrapper {...educationalBackgroundsQueryState}>
            {(educationalBackgrounds) => (
                <UniversitiesSearchContents
                    educationalBackgrounds={educationalBackgrounds}
                    targetEducationalBackground={props.targetEducationalBackground}
                    handleIsConfirmedChange={props.handleIsConfirmedChange}
                    handleBelongingNarrowDownButtonClick={props.handleBelongingNarrowDownButtonClick}
                    setTargetEducationalBackground={props.setTargetEducationalBackground}
                />
            )}
        </QueryLoadingWrapper>
    );
});
