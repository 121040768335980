import { useEffect, useState } from "react";

import { useMediaQuery } from "@material-ui/core";

import { CourseCard } from "@/components/CourseCard";
import styles from "@/pages/Common/TeacherDetails/index.module.scss";
import { CourseResponse } from "@/store/autogenApi";

interface Props {
    shortLessons: CourseResponse[];
    regularCourses: CourseResponse[];
}

export const Courses: React.VFC<Props> = (props) => {
    const [repetitionNumber, setRepetitionNumber] = useState<number>(0);

    const xs = useMediaQuery("(max-width:599px)");
    const sm = useMediaQuery("(max-width:959px)");

    useEffect(() => {
        if (xs) {
            setRepetitionNumber(3);
        } else if (sm) {
            setRepetitionNumber(4);
        } else {
            setRepetitionNumber(5);
        }
    }, [xs, sm]);

    return (
        <div className={styles.coursesTabContentsWrapper}>
            <div className={styles.coursesWrapper}>
                <h2 className={styles.coursesTitle}>単発・短期講座</h2>
                <ul className={styles.cardsWrapper} id="courses">
                    {repetitionNumber && (
                        <>
                            {props.shortLessons && props.shortLessons.length > 0 ? (
                                [...Array(Math.ceil(props.shortLessons.length / repetitionNumber))].map((_, idx1) => {
                                    const shortage = repetitionNumber * (idx1 + 1) - props.shortLessons.length;
                                    return (
                                        <div className={styles.cards} key={idx1}>
                                            {props.shortLessons
                                                .filter(
                                                    (_, idx2) =>
                                                        idx1 * repetitionNumber <= idx2 &&
                                                        idx2 < (idx1 + 1) * repetitionNumber,
                                                )
                                                .map((course) => (
                                                    <CourseCard key={course.courseId} course={course} />
                                                ))}
                                            {shortage > 0 &&
                                                [...Array(shortage)].map((_, idx) => {
                                                    return (
                                                        <div
                                                            className={styles.card}
                                                            key={idx}
                                                            style={{ border: "none" }}
                                                        />
                                                    );
                                                })}
                                        </div>
                                    );
                                })
                            ) : (
                                <div className={styles.emptyCourse}>対象の講座はありません</div>
                            )}
                        </>
                    )}
                    {/* {courses?.map((course) => {
                    return (
                        <>
                            <CourseCard course={course} detail={detail} />
                        </>
                    );
                })} */}
                </ul>
            </div>
            <div className={styles.coursesWrapper}>
                <h2 className={styles.coursesTitle}>定期講座</h2>
                <ul className={styles.cardsWrapper} id="courses">
                    {repetitionNumber && (
                        <>
                            {props.regularCourses && props.regularCourses.length > 0 ? (
                                [...Array(Math.ceil(props.regularCourses.length / repetitionNumber))].map((_, idx1) => {
                                    const shortage = repetitionNumber * (idx1 + 1) - props.regularCourses.length;
                                    return (
                                        <div className={styles.cards} key={idx1}>
                                            {props.regularCourses
                                                .filter(
                                                    (_, idx2) =>
                                                        idx1 * repetitionNumber <= idx2 &&
                                                        idx2 < (idx1 + 1) * repetitionNumber,
                                                )
                                                .map((course) => (
                                                    <CourseCard key={course.courseId} course={course} />
                                                ))}
                                            {shortage > 0 &&
                                                [...Array(shortage)].map((_, idx) => <CourseCard key={idx} isHidden />)}
                                        </div>
                                    );
                                })
                            ) : (
                                <div className={styles.emptyCourse}>対象の講座はありません</div>
                            )}
                        </>
                    )}
                    {/* {courses?.map((course) => {
                    return (
                        <>
                            <CourseCard course={course} detail={detail} />
                        </>
                    );
                })} */}
                </ul>
            </div>
        </div>
    );
};
