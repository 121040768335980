import { useSelector } from "react-redux";
import { MyPage } from ".";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { RootState } from "@/ducks";
import { BankAccountSettingContents } from "@/pageComponents/Teacher/MyPage/BankAccountSetting/BankAccountSettingContents";
import { useGetBankInfoListQuery } from "@/store/hooks/bankInfo";
import { useGetBankAccountListQuery } from "@/store/hooks/stripe";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { useEffect } from "react";
import { Navigation } from "@/components/Navigation";

const NAVIGATION_LIST: Navigation[] = [
    { title: "マイページ", url: "/Teacher/MyPage/Profile" },
    { title: "振込用銀行口座設定", url: "/Teacher/MyPage/BankAccountSetting" },
];

export const BankAccountSetting: React.VFC = () => {
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);
    const bankInfoListQueryState = useGetBankInfoListQuery();
    const bankAccountListQueryState = useGetBankAccountListQuery(teacherId);

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    return (
        <MyPage title="振込用銀行口座設定" activeTab="bankAccountSetting">
            <QueryLoadingWrapper {...bankInfoListQueryState}>
                {(bankInfoList) => (
                    <QueryLoadingWrapper {...bankAccountListQueryState}>
                        {(bankAccountList) => (
                            <BankAccountSettingContents bankAccountList={bankAccountList} bankInfoList={bankInfoList} />
                        )}
                    </QueryLoadingWrapper>
                )}
            </QueryLoadingWrapper>
        </MyPage>
    );
};
