import { memo } from "react";
import { PublicTeacherResponse } from "@/store/autogenApi";
import { QueryLoadingWrapper } from "../QueryLoadingWrapper";
import { useGetPublicUserQuery } from "@/store/hooks/users";
import { TeacherDetailInfo } from "../TeacherDetailInfo";
import { StyledModal } from "../StyledModal";
import { Box } from "@mui/material";

interface Props {
    open: boolean;
    teacher: PublicTeacherResponse;
    handleClose: () => void;
}

export const TeacherDetailInfoModal: React.VFC<Props> = memo(function TeacherDetailInfoModal(props) {
    if (!props.open) return null;

    const userQueryState = useGetPublicUserQuery(props.teacher.userId);

    return (
        <StyledModal
            open={props.open}
            existCloseButton
            autoResize
            style={{ padding: "0 20px" }}
            onClose={props.handleClose}
        >
            <QueryLoadingWrapper {...userQueryState}>
                {(user) => (
                    <Box sx={{ margin: "20px 0" }}>
                        <TeacherDetailInfo
                            teacher={props.teacher}
                            user={user}
                            disableToChangeLocation={true}
                            handleClose={props.handleClose}
                        />
                    </Box>
                )}
            </QueryLoadingWrapper>
        </StyledModal>
    );
});
