import { useCallback, useEffect, useState } from "react";

import { Button } from "@material-ui/core";

import styles from "@/pages/Teacher/InfoInput/index.module.scss";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { useGetEducationalBackgroundsQuery } from "@/store/hooks/educationalBackgrounds";
import { EducationalBackgroundResponse } from "@/store/autogenApi";

interface Props {
    initial: string;
    handleUniversityButtonClick: (university: string, educationalBackgrounds: EducationalBackgroundResponse[]) => void;
}

export const Universities: React.VFC<Props> = (props) => {
    const [modalHeight, setModalHeight] = useState<number>(0);

    const educationalBackgroundsQueryState = useGetEducationalBackgroundsQuery(props.initial);

    const changeModalHeight = useCallback(() => {
        const innerHeight = window.innerHeight;
        setModalHeight(innerHeight * 0.75);
    }, []);

    useEffect(() => {
        changeModalHeight();
        window.addEventListener("resize", () => {
            changeModalHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalHeight();
            });
        };
    }, []);

    return (
        <QueryLoadingWrapper {...educationalBackgroundsQueryState}>
            {(educationalBackgrounds) => {
                //重複を削除
                const universities = educationalBackgrounds.map(
                    (educationalBackground) => educationalBackground.university,
                );
                const filteredUniversities = [...new Set(universities)];
                return (
                    <div className={styles.universities} style={{ maxHeight: `${modalHeight}px` }}>
                        {filteredUniversities.map((university) => {
                            return (
                                <Button
                                    className={styles.universityButton}
                                    onClick={() => {
                                        props.handleUniversityButtonClick(university, educationalBackgrounds);
                                    }}
                                >
                                    {university}
                                </Button>
                            );
                        })}
                    </div>
                );
            }}
        </QueryLoadingWrapper>
    );
};
