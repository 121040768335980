import { Button } from "@material-ui/core";
import { memo } from "react";

import { FileInfo } from "@/components/TrimmingModal";
import styles from "@/pages/Teacher/InfoInput/index.module.scss";
import { EducationalBackgroundResponse, SubjectResponse } from "@/store/autogenApi";

import { teacherConfig } from "./Config";
import { InfoInputModal } from "./InfoInputModal";
import { Background } from "./Items/Background";
import { BackgroundImage } from "./Items/BackgroundImage";
import { CatchCopy } from "./Items/CatchCopy";
import { ContentDescription } from "./Items/ContentDescription";
import { EducationalBackground } from "./Items/EducationalBackground";
import { IconImage } from "./Items/IconImage";
import { NickName } from "./Items/NickName";
import { TeachableSubjectsComponent } from "./Items/TeachableSubjects";

interface Props {
    checked: boolean;
    confirmModalOpen: boolean;
    iconImageFileInfo?: FileInfo;
    backgroundImageFileInfo?: FileInfo;
    teachableSubjects: SubjectResponse[];
    nickname: string;
    catchCopy: string;
    backgroundDescription: string;
    contentDescription: string;
    educationalBackground: EducationalBackgroundResponse;
    noUniversityBoxChecked: boolean;
    isProcessing: boolean;
    setTeachableSubjects: React.Dispatch<React.SetStateAction<SubjectResponse[]>>;
    setIconImageFileInfo: React.Dispatch<React.SetStateAction<FileInfo>>;
    setBackgroundImageFileInfo: React.Dispatch<React.SetStateAction<FileInfo>>;
    setEducationalBackground: React.Dispatch<React.SetStateAction<EducationalBackgroundResponse>>;
    handleOpen: () => void;
    handleClose: () => void;
    confirm: () => Promise<void>;
    handleNicknameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleCatchCopyChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleBackgroundDescriptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleContentDescriptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleNoUniversityCheckChange: (checked: boolean) => void;
}

export const FormContents: React.VFC<Props> = memo(function FormContents(props) {
    return (
        <div className={styles.infoInputWrapper}>
            <div className={styles.infoInputTitle}>先生プロフィール入力</div>
            <div className={styles.description}>
                後ほどマイページから編集も可能なので、後でゆっくり考えたい場合は最低限の情報だけ入力しましょう！
            </div>
            <div className={styles.inputList}>
                <NickName
                    handleChange={props.handleNicknameChange}
                    value={props.nickname}
                    checked={props.checked}
                    upperLimit={teacherConfig.nickname}
                />
                <TeachableSubjectsComponent
                    checked={props.checked}
                    teachableSubjects={props.teachableSubjects}
                    setTeachableSubjects={props.setTeachableSubjects}
                />
                <CatchCopy
                    handleChange={props.handleCatchCopyChange}
                    value={props.catchCopy}
                    checked={props.checked}
                    upperLimit={teacherConfig.catchCopy}
                />
                <Background
                    handleChange={props.handleBackgroundDescriptionChange}
                    value={props.backgroundDescription}
                    checked={props.checked}
                    upperLimit={teacherConfig.backgroundDescription}
                />
                <ContentDescription
                    handleChange={props.handleContentDescriptionChange}
                    value={props.contentDescription}
                    checked={props.checked}
                    upperLimit={teacherConfig.contentDescription}
                />
                <EducationalBackground
                    checked={props.checked}
                    educationalBackground={props.educationalBackground}
                    setEducationalBackground={props.setEducationalBackground}
                    noUniversityBoxChecked={props.noUniversityBoxChecked}
                    handleNoUniversityCheckChange={props.handleNoUniversityCheckChange}
                />
                <IconImage
                    setFileInfo={props.setIconImageFileInfo}
                    iconImageFileInfo={props.iconImageFileInfo}
                    checked={props.checked}
                />
                <BackgroundImage
                    setFileInfo={props.setBackgroundImageFileInfo}
                    checked={props.checked}
                    backgroundImageFileInfo={props.backgroundImageFileInfo}
                />
            </div>
            <Button className={styles.checkButton} variant="contained" onClick={props.handleOpen}>
                確認
            </Button>
            <InfoInputModal
                iconImageFileInfo={props.iconImageFileInfo}
                backgroundImageFileInfo={props.backgroundImageFileInfo}
                checked={props.checked}
                confirmModalOpen={props.confirmModalOpen}
                teachableSubjects={props.teachableSubjects}
                nickname={props.nickname}
                catchCopy={props.catchCopy}
                backgroundDescription={props.backgroundDescription}
                contentDescription={props.contentDescription}
                isProcessing={props.isProcessing}
                setTeachableSubjects={props.setTeachableSubjects}
                handleClose={props.handleClose}
                confirm={props.confirm}
            />
        </div>
    );
});
