import { MyPage } from ".";
import { memo, useEffect } from "react";
import { SettingContents } from "@/pageComponents/Common/MyPage/Setting/SettingContents";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { Navigation } from "@/components/Navigation";

const NAVIGATION_LIST: Navigation[] = [
    { title: "マイページ", url: "/Teacher/MyPage/Profile" },
    { title: "設定", url: "/Teacher/MyPage/Setting" },
];

export const TeacherSetting: React.VFC = memo(function TeacherNotice() {
    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    return (
        <MyPage title="設定" activeTab="setting">
            <SettingContents />
        </MyPage>
    );
});
