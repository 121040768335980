import { FAQConfig } from "@/FAQConfig";

export const SEARCH_COURSE_CONFIGS: FAQConfig[] = [
    {
        title: "キーワードを入力",
        text: "画面上部の検索窓にキーワードを入力し、確定するか虫眼鏡ボタンを押します。",
    },
    {
        title: "検索結果の表示",
        text: "下の画像のように検索結果が表示されます。",
    },
    {
        title: "絞り込む",
        text: "画面左にある絞り込み条件で、検索結果を絞り込むことができます。",
    },
];
