import { Button, Drawer } from "@material-ui/core";
import { memo, MutableRefObject, useCallback, useRef } from "react";
import { EducationalBackGroundQueries, SubjectQueries } from "@/pages/Common/CourseSearchResults";

import styles from "./index.module.scss";
import { BelongingConditions } from "./BelongingConditions";
import { KeywordConditions } from "./KeywordConditions";
import { RankConditions } from "./RankConditions";
import { SubjectConditions } from "./SubjectConditions";
import { useMediaQuery } from "@mui/material";
import { CourseTypeConditions } from "./CourseTypeConditions";

interface Props {
    drawerOpen?: boolean;
    courseType?: string;
    rankNumber?: number;
    targetSubject: SubjectQueries;
    targetEducationalBackground?: EducationalBackGroundQueries;
    isConfirmed?: boolean;
    keyword: string;
    searchResultPageEl: MutableRefObject<HTMLElement | null | undefined>;
    setKeyword: React.Dispatch<React.SetStateAction<string>>;
    setCourseType?: React.Dispatch<React.SetStateAction<string>>;
    setIsConfirmed?: React.Dispatch<React.SetStateAction<boolean>>;
    search: () => void;
    setTargetSubject: React.Dispatch<React.SetStateAction<SubjectQueries>>;
    setRank?: React.Dispatch<React.SetStateAction<number>>;
    setTargetEducationalBackground?: React.Dispatch<React.SetStateAction<EducationalBackGroundQueries>>;
    getCourseTypeCondition?: (courseType: string) => string;
    getRankCondition?: (
        rankNumber: number,
    ) => "レギュラー以上" | "ブロンズ以上" | "シルバー以上" | "ゴールド以上" | "プラチナ" | "-" | undefined;
    handleClearButtonClick: () => void;
    handleClose?: () => void;
}

export const NarrowDown: React.VFC<Props> = memo(function NarrowDown(props) {
    const narrowDownEl: React.MutableRefObject<HTMLElement | null | undefined> = useRef();

    const narrowDownRef = useCallback((node: HTMLElement | null) => {
        narrowDownEl.current = node;
    }, []);

    const mdUp = useMediaQuery("(min-width:960px)");

    const handleSearchButtonClick = useCallback(() => {
        if (!props.handleClose) return;
        props.search();
        props.handleClose();
    }, [props.search]);

    const checkNarrowDownHeight = useCallback(() => {
        if (!props.searchResultPageEl.current || !narrowDownEl.current) return;
        const searchResultPageHeight = props.searchResultPageEl.current.offsetHeight;
        const narrowDownHeight = narrowDownEl.current.offsetHeight;
        if (narrowDownHeight <= searchResultPageHeight) {
            props.searchResultPageEl.current.style.minHeight = `${narrowDownHeight + 100}px`;
        } else if (searchResultPageHeight <= narrowDownHeight + 150) {
            props.searchResultPageEl.current.style.minHeight = `${narrowDownHeight + 50}px`;
        } else {
            props.searchResultPageEl.current.style.minHeight = `fit-content`;
        }
    }, [props.searchResultPageEl.current, narrowDownEl.current]);

    const handleClearButtonClick = useCallback(() => {
        props.setKeyword("");
        props.setCourseType && props.setCourseType("");
        props.setTargetSubject({ level: "", category: "", subCategory: "" });
        props.setRank && props.setRank(0);
        props.setTargetEducationalBackground &&
            props.setTargetEducationalBackground({ university: "", faculty: "", department: "" });
        props.setIsConfirmed && props.setIsConfirmed(false);
        props.handleClearButtonClick();
    }, [
        props.setKeyword,
        props.setCourseType,
        props.setTargetSubject,
        props.setRank,
        props.setTargetEducationalBackground,
        props.setIsConfirmed,
        props.handleClearButtonClick,
    ]);
    if (props.drawerOpen === undefined) {
        return (
            <div className={styles.narrowDown} ref={narrowDownRef} style={{ opacity: 1 }}>
                <div className={styles.titleAndClearWrapper}>
                    <div className={styles.narrowDownTitle}>絞り込み</div>
                    <div className={styles.flex} />
                    <Button className={styles.clearButton} onClick={handleClearButtonClick}>
                        クリア
                    </Button>
                </div>
                <div className={styles.allConditions}>
                    <KeywordConditions
                        keyword={props.keyword}
                        setKeyword={props.setKeyword}
                        checkNarrowDownHeight={checkNarrowDownHeight}
                    />
                    {props.setCourseType != undefined && props.getCourseTypeCondition != undefined && (
                        <CourseTypeConditions
                            courseType={props.courseType}
                            setCourseType={props.setCourseType}
                            getCourseTypeCondition={props.getCourseTypeCondition}
                            checkNarrowDownHeight={checkNarrowDownHeight}
                        />
                    )}
                    <SubjectConditions
                        targetSubject={props.targetSubject}
                        setTargetSubject={props.setTargetSubject}
                        checkNarrowDownHeight={checkNarrowDownHeight}
                    />
                    {props.rankNumber != undefined && props.setRank != undefined && props.getRankCondition && (
                        <RankConditions
                            rankNumber={props.rankNumber}
                            setRank={props.setRank}
                            getRankCondition={props.getRankCondition}
                            checkNarrowDownHeight={checkNarrowDownHeight}
                        />
                    )}
                    {props.targetEducationalBackground &&
                        props.setTargetEducationalBackground &&
                        props.isConfirmed != undefined &&
                        props.setIsConfirmed && (
                            <BelongingConditions
                                targetEducationalBackground={props.targetEducationalBackground}
                                isConfirmed={props.isConfirmed}
                                setIsConfirmed={props.setIsConfirmed}
                                setTargetEducationalBackground={props.setTargetEducationalBackground}
                                search={props.search}
                                checkNarrowDownHeight={checkNarrowDownHeight}
                            />
                        )}
                </div>
                <Button className={styles.searchButton} onClick={props.search}>
                    絞り込む
                </Button>
            </div>
        );
    } else {
        return (
            <Drawer open={props.drawerOpen} onClose={props.handleClose} anchor="right">
                <div className={styles.drawerContents}>
                    <div className={styles.titleAndClearWrapper}>
                        <div className={styles.narrowDownTitle}>絞り込み</div>
                        <div className={styles.flex} />
                        <Button className={styles.clearButton} onClick={handleClearButtonClick}>
                            クリア
                        </Button>
                    </div>
                    <div className={styles.allConditions}>
                        <KeywordConditions
                            keyword={props.keyword}
                            setKeyword={props.setKeyword}
                            checkNarrowDownHeight={checkNarrowDownHeight}
                        />
                        {props.setCourseType != undefined && props.getCourseTypeCondition != undefined && (
                            <CourseTypeConditions
                                courseType={props.courseType}
                                setCourseType={props.setCourseType}
                                getCourseTypeCondition={props.getCourseTypeCondition}
                                checkNarrowDownHeight={checkNarrowDownHeight}
                            />
                        )}
                        <SubjectConditions
                            targetSubject={props.targetSubject}
                            setTargetSubject={props.setTargetSubject}
                            checkNarrowDownHeight={checkNarrowDownHeight}
                        />
                        {props.rankNumber != undefined && props.setRank != undefined && props.getRankCondition && (
                            <RankConditions
                                rankNumber={props.rankNumber}
                                setRank={props.setRank}
                                getRankCondition={props.getRankCondition}
                                checkNarrowDownHeight={checkNarrowDownHeight}
                            />
                        )}
                        {props.targetEducationalBackground &&
                            props.setTargetEducationalBackground &&
                            props.isConfirmed != undefined &&
                            props.setIsConfirmed && (
                                <BelongingConditions
                                    targetEducationalBackground={props.targetEducationalBackground}
                                    isConfirmed={props.isConfirmed}
                                    setIsConfirmed={props.setIsConfirmed}
                                    setTargetEducationalBackground={props.setTargetEducationalBackground}
                                    search={props.search}
                                    checkNarrowDownHeight={checkNarrowDownHeight}
                                />
                            )}
                    </div>
                    <Button className={styles.searchButton} variant="contained" onClick={handleSearchButtonClick}>
                        検索する
                    </Button>
                </div>
            </Drawer>
        );
    }
});
