import { FAQConfig } from "@/FAQConfig";

export const COURSE_TYPE_CONFIGS: FAQConfig[] = [
    {
        title: "単発・短期講座",
        text: "単発・短期講座とは、単発・短期間で集中的に数回（単発も可）授業を行う講座のことで、講座タイトルの例としては「〇〇大対策数学」や「日本史・鎌倉時代を極める」などピンポイントなものが挙げられます。",
    },
    {
        title: "定期講座",
        text: "定期講座とは、毎週決まった時間に数ヶ月~数年授業を行う講座のことで、一般の家庭教師をイメージしていただけるとわかりやすいかと思います。",
    },
];
