import { memo, useCallback, useEffect, useState } from "react";

import { FormHelperText } from "@material-ui/core";
import styles from "../../userInfoInput.module.scss";
import { Mandatory } from "@/components/Tag/Mandatory";
import { IsPublicRadioButtons } from "@/components/IsPublicRadioButtons";
import { DefaultSelect } from "@/components/DefaultSelect";

interface Props {
    handleBirthDateChange?: (birthDate: Date) => void;
    open?: boolean;
    birthDate: Date | undefined;
    checked: boolean;
    isPublic: boolean;
    handleIsPublicChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const BirthDate: React.VFC<Props> = memo(function BirthDate(props) {
    const [year, setYear] = useState<number | undefined>(undefined);
    const [month, setMonth] = useState<number | undefined>(undefined);
    const [date, setDate] = useState<number | undefined>(undefined);

    const YEAR_LIST = [...Array(123)].map((_, i) => i + 1900);
    const MONTH_LIST = [...Array(12)].map((_, i) => i + 1);
    const DATE_LIST = [...Array(31)].map((_, i) => i + 1);

    useEffect(() => {
        if (!props.birthDate) return;
        const modifiedBirthDate = new Date(props.birthDate);
        if (
            (!year && !month && !date) ||
            (year &&
                month &&
                date &&
                (modifiedBirthDate.getFullYear() !== year ||
                    modifiedBirthDate.getMonth() !== month - 1 ||
                    modifiedBirthDate.getDate() !== date))
        ) {
            setYear(modifiedBirthDate.getFullYear());
            setMonth(modifiedBirthDate.getMonth() + 1);
            setDate(modifiedBirthDate.getDate());
        }
    }, [props.birthDate]);

    useEffect(() => {
        if (year && month && date) {
            const modifiedBirthDate = new Date(year, month - 1, date);
            props.handleBirthDateChange && props.handleBirthDateChange(modifiedBirthDate);
        }
    }, [year, month, date, props.handleBirthDateChange]);

    const handleYearChange = useCallback((e: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
        const value = e.target.value as number;
        setYear(value ?? undefined);
    }, []);
    const handleMonthChange = useCallback((e: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
        const value = e.target.value as number;
        setMonth(value ?? undefined);
    }, []);
    const handleDateChange = useCallback((e: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
        const value = e.target.value as number;
        setDate(value ?? undefined);
    }, []);

    const getConfirmedBirthDate = useCallback(() => {
        if (props.birthDate) {
            return `${props.birthDate.getFullYear()}年${
                props.birthDate.getMonth() + 1
            }月${props.birthDate.getDate()}日`;
        }
    }, [props.birthDate]);

    return (
        <div className={styles.inputItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitleWrapper}>
                    <span className={styles.itemTitle}>生年月日</span>
                </div>
                {!props.open && <Mandatory />}
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>{`${getConfirmedBirthDate()} - ${
                        props.isPublic ? "公開" : "非公開"
                    }`}</div>
                ) : (
                    <>
                        <div className={styles.notice}>生年月日は年齢を表示するために用いられます。</div>
                        <div className={styles.dateInfoWrapper}>
                            <div className={styles.dateItemWrapper}>
                                <DefaultSelect
                                    name="year"
                                    value={year ?? 0}
                                    handleChange={handleYearChange}
                                    options={[0, ...YEAR_LIST].map((year) => ({
                                        value: year,
                                        label: year === 0 ? "" : String(year),
                                    }))}
                                />
                                <div className={styles.dateItemTitle}>年</div>
                            </div>
                            <div className={styles.dateItemWrapper}>
                                <DefaultSelect
                                    name="month"
                                    value={month ?? 0}
                                    handleChange={handleMonthChange}
                                    options={[0, ...MONTH_LIST].map((month) => ({
                                        value: month,
                                        label: month === 0 ? "" : String(month),
                                    }))}
                                />
                                <div className={styles.dateItemTitle}>月</div>
                            </div>
                            <div className={styles.dateItemWrapper}>
                                <DefaultSelect
                                    name="date"
                                    value={date ?? 0}
                                    handleChange={handleDateChange}
                                    options={[0, ...DATE_LIST].map((date) => ({
                                        value: date,
                                        label: date === 0 ? "" : String(date),
                                    }))}
                                />
                                <div className={styles.dateItemTitle}>日</div>
                            </div>
                        </div>
                        {props.checked && (!year || !month || !date) && (
                            <FormHelperText className={styles.helperText}>入力してください。</FormHelperText>
                        )}
                        <IsPublicRadioButtons
                            isPublic={props.isPublic}
                            handleIsPublicChange={props.handleIsPublicChange}
                        />
                    </>
                )}
            </div>
        </div>
    );
});
