import { memo, useCallback, useEffect, useState } from "react";

import { Button, Checkbox, FormControlLabel, FormGroup, FormHelperText } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

import styles from "@/pages/Teacher/InfoInput/index.module.scss";

import { UniversitiesSearchModal } from "./UniversitiesSearchModal";
import { EducationalBackgroundResponse } from "@/store/autogenApi";

interface Props {
    checked: boolean;
    educationalBackground: Belonging;
    noUniversityBoxChecked: boolean;
    handleNoUniversityCheckChange?: (checked: boolean) => void;
    setEducationalBackground: React.Dispatch<React.SetStateAction<Belonging>>;
    getBelongingCondition: () => string;
}

export interface Belonging {
    educationalBackgroundId?: string;
    university?: string;
    faculty?: string;
    department?: string;
    initial?: string;
    pronunciation?: string;
}

export const BelongingConditions: React.VFC<Props> = memo(function BelongingConditions(props) {
    const [educationalBackgrounds, setEducationalBackgrounds] = useState<Belonging[]>([]);
    const [universities, setUniversities] = useState<string[]>([]);
    const [faculties, setFaculties] = useState<string[]>([]);
    const [departments, setDepartments] = useState<string[]>([]);
    const [UniversitiesSearchModalOpen, setUniversitiesSearchModalOpen] = useState<boolean>(false);
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

    const handleFacultyChange = useCallback(
        (
            e: React.ChangeEvent<{
                value: unknown;
            }>,
        ) => {
            const faculty = e.target.value as string;
            props.setEducationalBackground({ ...props.educationalBackground, faculty: faculty, department: "" });
            const targetDepartment = educationalBackgrounds
                .filter(
                    (targetEducationalBackground) =>
                        targetEducationalBackground.university &&
                        props.educationalBackground.university &&
                        targetEducationalBackground.university === props.educationalBackground.university &&
                        targetEducationalBackground.faculty &&
                        targetEducationalBackground.faculty === faculty,
                )
                .map((educationalBackground) => educationalBackground.department) as string[];
            setDepartments(targetDepartment);
        },
        [educationalBackgrounds, props.educationalBackground],
    );

    const handleDepartmentChange = useCallback(
        (
            e: React.ChangeEvent<{
                value: unknown;
            }>,
        ) => {
            const department = e.target.value as string;
            props.setEducationalBackground({ ...props.educationalBackground, department: department });
        },
        [props.educationalBackground],
    );

    const handleJapaneseSyllabaryButtonClick = useCallback(() => {
        setUniversitiesSearchModalOpen(true);
    }, []);

    const handleUniversitiesSearchModalClose = useCallback(() => {
        setUniversitiesSearchModalOpen(false);
        setUniversities([]);
        setFaculties([]);
        setDepartments([]);
        props.setEducationalBackground({ university: "", faculty: "", department: "" });
    }, [props.setEducationalBackground]);

    const handleBackButtonClick = useCallback(() => {
        setUniversities([]);
        props.setEducationalBackground({ university: "", faculty: "", department: "" });
    }, [props.setEducationalBackground]);

    const handleUniversityButtonClick = useCallback(
        (university: string, backgrounds: EducationalBackgroundResponse[]) => {
            setEducationalBackgrounds(backgrounds);
            props.setEducationalBackground({ university: university, faculty: "", department: "" });
            const targetFaculties = backgrounds
                .filter(
                    (educationalBackground) =>
                        educationalBackground.university && educationalBackground.university === university,
                )
                .map((educationalBackground) => educationalBackground.faculty);
            const set = new Set(targetFaculties);
            const uniqueFaculties = Array.from(set) as string[];
            uniqueFaculties.sort();
            setFaculties(uniqueFaculties);
        },
        [props.setEducationalBackground],
    );

    const handleBelongingNarrowDownButtonClick = useCallback(() => {
        setUniversitiesSearchModalOpen(false);
    }, []);

    const handleIsConfirmedChange = useCallback(() => {
        setIsConfirmed(!isConfirmed);
        setUniversitiesSearchModalOpen(false);
        const targetEducationalBackground = educationalBackgrounds.find(
            (educationalBackground) =>
                educationalBackground.university === props.educationalBackground.university &&
                (educationalBackground.faculty
                    ? educationalBackground.faculty === props.educationalBackground.faculty
                    : true) &&
                (educationalBackground.department
                    ? educationalBackground.department === props.educationalBackground.department
                    : true),
        );
        if (targetEducationalBackground) {
            props.setEducationalBackground({
                educationalBackgroundId: targetEducationalBackground.educationalBackgroundId,
                university: targetEducationalBackground.university,
                faculty: targetEducationalBackground.faculty,
                department: targetEducationalBackground.department,
            });
        }
    }, [isConfirmed, educationalBackgrounds, props.educationalBackground]);

    const handleClearButtonClick = useCallback(() => {
        setIsConfirmed(false);
        props.setEducationalBackground({ university: "", faculty: "", department: "" });
    }, [props.setEducationalBackground]);

    return (
        <>
            <div className={styles.educationalBackgroundConditionWrapper}>
                {isConfirmed ? (
                    <div className={styles.confirmedConditionWrapper}>
                        <div className={styles.confirmedCondition}>{props.getBelongingCondition()}</div>
                        <Button className={styles.conditionClearButton} onClick={handleClearButtonClick}>
                            <ClearIcon className={styles.clearIcon} />
                        </Button>
                    </div>
                ) : (
                    <>
                        <div className={styles.message}>
                            下のボタンをクリックして、大学情報を入力してください。該当するものがない場合は「該当なし」にチェックをつけてください。大学在学中または卒業しているが該当する情報がない場合は、「該当なし」にチェックをつけた上で後ほど運営までお問い合わせいただけますと幸いです。
                        </div>
                        <Button
                            className={styles.searchByJapaneseSyllabaryButton}
                            onClick={handleJapaneseSyllabaryButtonClick}
                            variant="contained"
                        >
                            50音から大学を探す
                        </Button>
                        <UniversitiesSearchModal
                            open={UniversitiesSearchModalOpen}
                            universities={universities}
                            faculties={faculties}
                            departments={departments}
                            educationalBackground={props.educationalBackground}
                            isConfirmed={isConfirmed}
                            setEducationalBackground={props.setEducationalBackground}
                            handleClose={handleUniversitiesSearchModalClose}
                            handleFacultyChange={handleFacultyChange}
                            handleDepartmentChange={handleDepartmentChange}
                            handleBackButtonClick={handleBackButtonClick}
                            handleUniversityButtonClick={handleUniversityButtonClick}
                            handleIsConfirmedChange={handleIsConfirmedChange}
                            handleBelongingNarrowDownButtonClick={handleBelongingNarrowDownButtonClick}
                            handleNoUniversityCheckChange={props.handleNoUniversityCheckChange}
                        />
                        <FormGroup style={{ width: "fit-content" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={props.noUniversityBoxChecked}
                                        onChange={(e) => props.handleNoUniversityCheckChange(e.target.checked)}
                                        inputProps={{ "aria-label": "controlled" }}
                                        size="medium"
                                    />
                                }
                                label={
                                    <div style={{ color: "#305077", fontSize: "12px", fontWeight: "bold" }}>
                                        該当なし
                                    </div>
                                }
                            />
                        </FormGroup>
                    </>
                )}
            </div>
            {props.checked && !props.educationalBackground.university && !props.noUniversityBoxChecked && (
                <FormHelperText className={styles.helperText}>入力してください。</FormHelperText>
            )}
        </>
    );
});
