import { RiDeleteBin6Line, RiCloseFill, RiDraftLine } from "react-icons/ri";
import { BiEdit, BiLink, BiPlusCircle } from "react-icons/bi";
import { MdInfoOutline, MdOutlineLightbulb } from "react-icons/md";
import { GrStatusInfo } from "react-icons/gr";
import { HiOutlineCurrencyYen } from "react-icons/hi";
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc";
import { TbHandClick } from "react-icons/tb";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SchoolMUIIcon from "@material-ui/icons/School";

export const DeleteIcon = RiDeleteBin6Line;
export const EditIcon = BiEdit;
export const CloseIcon = RiCloseFill;
export const InfoIcon = MdInfoOutline;
export const LinkIcon = BiLink;
export const StatusIcon = GrStatusInfo;
export const FeeIcon = HiOutlineCurrencyYen;
export const TriangleDownIcon = VscTriangleDown;
export const TriangleUpIcon = VscTriangleUp;
export const PlusIcon = BiPlusCircle;
export const DraftIcon = RiDraftLine;
export const LightBulbIcon = MdOutlineLightbulb;
export const ArrowIcon = ArrowForwardIosIcon;
export const SchoolIcon = SchoolMUIIcon;
export const ClickableHandIcon = TbHandClick;
