import { ListItem } from "../ListItem";

import styles from "../index.module.scss";

interface Props {
    pricePerHour: number;
    isConfirmed?: boolean;
}

export const PricePerHour: React.VFC<Props> = (props) => {
    return (
        <ListItem title="時間単価" borderBottom>
            <div className={styles.pricePerHour}>¥{new Intl.NumberFormat("ja-JP").format(props.pricePerHour)}</div>
        </ListItem>
    );
};
