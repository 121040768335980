import { memo, useCallback, useState } from "react";
import { Optional } from "@/components/Tag/Optional";

import { FileInfo, TrimmingModal } from "@/components/TrimmingModal";
import styles from "@/pages/Teacher/CreateCourse/index.module.scss";

interface Props {
    open?: boolean;
    deleteImage: (number: number) => void;
    setFileInfoList: React.Dispatch<React.SetStateAction<FileInfo[]>>;
    fileInfoList: FileInfo[];
    validation: boolean;
}

export const PictureUpload: React.VFC<Props> = memo(function PictureUpload(props) {
    const [trimmingModalOpen, setTrimmingModalOpen] = useState(false);
    const [targetFile, setTargetFile] = useState<File | undefined>(undefined);
    const selectImg = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            setTargetFile(file);
            handleTrimmingModalOpen();
        }
    }, []);
    const handleTrimmingModalClose = useCallback(() => {
        setTrimmingModalOpen(false);
    }, []);
    const handleTrimmingModalOpen = useCallback(() => {
        setTrimmingModalOpen(true);
    }, []);
    return (
        <>
            <li className={styles.listItem}>
                <div className={styles.leftWrapper}>
                    <div className={styles.titleWrapper}>
                        <div className={styles.title}>関連画像</div>
                        {!props.open && <Optional />}
                    </div>
                </div>
                <div className={styles.rightWrapper}>
                    {props.open ? (
                        <div className={styles.openTrue}>
                            {props.fileInfoList.length === 0 ? (
                                "-"
                            ) : (
                                <div className={styles.imagesWrapper}>
                                    {[...Array(Math.ceil(props.fileInfoList.length / 3))].map((_, rowIdx) => {
                                        return (
                                            <div className={styles.imageFlexWrapper}>
                                                {props.fileInfoList
                                                    .filter((_, idx) => Math.floor(idx / 3) == rowIdx)
                                                    .map((fileInfo, idx) => {
                                                        return (
                                                            <div className={styles.imageWrapper} key={idx}>
                                                                <img src={fileInfo.url} className={styles.image} />
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className={styles.pictureUpload}>
                            <div className={styles.messageWrapper}>
                                {props.fileInfoList.length >= 6 ? (
                                    <p className={styles.message}>
                                        別の画像をアップロードしたい場合はいずれかの画像を削除してください。
                                    </p>
                                ) : (
                                    <>
                                        {props.fileInfoList.length <= 4 && (
                                            <p className={styles.message}>
                                                {`講座に関連する画像をあと${
                                                    6 - props.fileInfoList.length
                                                }枚アップロードできます。`}
                                            </p>
                                        )}
                                        <p className={styles.message}>
                                            1枚もアップロードしない場合、デフォルト画像が適用されます。
                                        </p>
                                        <label className={styles.fileInputLabel} htmlFor="fileInput">
                                            画像をアップロード
                                        </label>
                                        <input
                                            id="fileInput"
                                            type="file"
                                            accept="image/*"
                                            className={styles.fileInput}
                                            onChange={selectImg}
                                            onClick={(e) => {
                                                e.currentTarget.value = "";
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                            <TrimmingModal
                                open={trimmingModalOpen}
                                handleClose={handleTrimmingModalClose}
                                setFileInfoList={props.setFileInfoList}
                                fileInfoList={props.fileInfoList}
                                targetFile={targetFile}
                                aspect={3 / 2}
                                shape={"rect"}
                            />
                            <div className={styles.imagesWrapper}>
                                {[...Array(Math.ceil(props.fileInfoList.length / 3))].map((_, rowIdx) => {
                                    return (
                                        <div className={styles.imageFlexWrapper}>
                                            {props.fileInfoList
                                                .filter((_, idx) => Math.floor(idx / 3) == rowIdx)
                                                .map((fileInfo, idx) => {
                                                    return (
                                                        <div className={styles.imageWrapper} key={idx}>
                                                            <img src={fileInfo.url} className={styles.image} />
                                                            <button
                                                                className={styles.imageDeleteButton}
                                                                type="button"
                                                                onClick={() => props.deleteImage(idx)}
                                                            >
                                                                ×
                                                            </button>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </li>
        </>
    );
});
