import styles from "@/pages/Common/AboutRequest/index.module.scss";

export const Flow: React.VFC = () => {
    return (
        <div className={styles.strengthListWrapper}>
            <div className={styles.strengthListTitle}>講座リクエスト作成フロー</div>
            <div className={styles.strengthList}>
                <div className={styles.strength}>
                    <div className={styles.textWrapper}>
                        <div className={styles.strengthTitle}>①講座リクエスト作成画面への移動</div>
                        <div className={styles.descriptionWrapper}>
                            <div className={styles.description}>
                                本ページ下部あるいはメニューの中にある「講座リクエスト作成」から講座リクエスト作成画面へと移動します。
                            </div>
                        </div>
                    </div>
                    <img src={""} alt="" className={styles.image} />
                </div>
                <div className={styles.strength}>
                    <div className={styles.textWrapper}>
                        <div className={styles.strengthTitle}>②講座形態の選択</div>
                        <div className={styles.descriptionWrapper}>
                            <div className={styles.description}>
                                単発・短期的でピンポイントな講座を希望する場合には「単発・短期講座」、長期的・継続的な講座（オンライン家庭教師）を希望する場合には「定期講座」をクリックしてください。
                            </div>
                        </div>
                    </div>
                    <img src={""} alt="" className={styles.image} />
                </div>
                <div className={styles.strength}>
                    <div className={styles.textWrapper}>
                        <div className={styles.strengthTitle}>③必要事項の入力</div>
                        <div className={styles.descriptionWrapper}>
                            <div className={styles.description}>
                                選択した講座形態に合わせたフォームが表示されるので、必要事項を入力してください。
                            </div>
                            <div className={styles.description}>入力が終わったら「確認」ボタンを押してください。</div>
                            <div className={styles.description}>
                                入力内容に不備がある場合、エラーが表示されるので、該当箇所を修正してください。
                            </div>
                        </div>
                    </div>
                    <img src={""} alt="" className={styles.image} />
                </div>
                <div className={styles.strength}>
                    <div className={styles.textWrapper}>
                        <div className={styles.strengthTitle}>④入力内容の確認</div>
                        <div className={styles.descriptionWrapper}>
                            <div className={styles.description}>
                                「確認」ボタンを押下した後、入力内容の一覧が表示されるので、それらが正しいかどうかを確認してください。
                            </div>
                            <div className={styles.description}>
                                内容に問題がなければ「確定」ボタンを押してください。
                            </div>
                        </div>
                    </div>
                    <img src={""} alt="" className={styles.image} />
                </div>
                <div className={styles.strength}>
                    <div className={styles.textWrapper}>
                        <div className={styles.strengthTitle}>⑤講座リクエスト作成完了</div>
                        <div className={styles.descriptionWrapper}>
                            <div className={styles.description}>講座リクエストが作成されました。</div>
                            <div className={styles.description}>先生の応募を楽しみに待ちましょう。</div>
                        </div>
                    </div>
                    <img src={""} alt="" className={styles.image} />
                </div>
            </div>
        </div>
    );
};
