import { memo, useCallback, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { SignUpComponent } from "@/pageComponents/Common/SignUp";
import { useLocation } from "react-router";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { Navigation } from "@/components/Navigation";

type userCommonDataType = {
    nickname: string;
    gender: string;
    birthDate: number;
    prefecture: string;
    iconImageObjectKey: string;
    backgroundImageObjectKey: string;
    inputComplete: boolean;
};

type userStudentUniqueDataType = {
    grade: string;
    browsingHistory: string[];
    likedCourses: string[];
    requests: string[];
    takenLessons: string[];
    isDisclosed: boolean;
};

type userTeacherUniqueDataType = {
    profession: string;
    background: string;
    catchCopy: string;
    profile: string;
    lessons: string[];
};

export type userStudentDataType = userCommonDataType & userStudentUniqueDataType;
export type userTeacherDataType = userCommonDataType & userTeacherUniqueDataType;

const NAVIGATION_LIST: Navigation[] = [
    {
        title: "新規登録",
        url: "/SignUp",
    },
];

export const SignUp: React.VFC = memo(function SignUp() {
    const [signUpMode, setSignUpMode] = useState<string>("");
    const [byEmail, setByEmail] = useState(false);
    const [userId, setUserId] = useState<string | undefined>(undefined);
    const [isSentVerificationCode, setIsSentVerificationCode] = useState<boolean>(false);

    const query = new URLSearchParams(useLocation().search);
    const type = query.get("type");

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    useEffect(() => {
        // typeがteacherかstudentかでsignUpModeを変更
        if (type === "teacher") {
            setSignUpMode("teacher");
        } else if (type === "student") {
            setSignUpMode("student");
        }
        // 現在Emailでの登録のみ可能なので、byEmailをtrueにする
        setByEmail(true);
    }, [type]);

    const handleSignUpAsStudentButtonClick = useCallback(() => {
        setSignUpMode("student");
    }, []);

    const handleSignUpAsTeacherButtonClick = useCallback(() => {
        setSignUpMode("teacher");
    }, []);

    const handleEmailButtonClick = useCallback(() => {
        setByEmail(true);
    }, []);

    const handleGoogleButtonClick = useCallback(async (forStudent: boolean) => {
        // const provider = new firebase.auth.GoogleAuthProvider();
        // await firebase.auth().signInWithPopup(provider);
        // const currentUser = firebase.auth().currentUser;
        // const userId = "";
        // setUserId(userId);
    }, []);

    const handleClose = useCallback(() => {
        setSignUpMode("");
        setByEmail(false);
        setIsSentVerificationCode(false);
        if (isSentVerificationCode && userId) {
            Auth.deleteUser();
        }
    }, [isSentVerificationCode, userId]);

    return (
        <SignUpComponent
            signUpMode={signUpMode}
            byEmail={byEmail}
            userId={userId}
            isSentVerificationCode={isSentVerificationCode}
            setIsSentVerificationCode={setIsSentVerificationCode}
            setUserId={setUserId}
            handleClose={handleClose}
            handleGoogleButtonClick={handleGoogleButtonClick}
            handleEmailButtonClick={handleEmailButtonClick}
            handleSignUpAsStudentButtonClick={handleSignUpAsStudentButtonClick}
            handleSignUpAsTeacherButtonClick={handleSignUpAsTeacherButtonClick}
            setByEmail={setByEmail}
            setSignUpMode={setSignUpMode}
        />
    );
});
