import styles from "../index.module.scss";

import { memo } from "react";
import { ErrorMessage } from "@/components/ErrorMessage";
import { ButtonPair } from "@/components/ButtonPair";
import { Mandatory } from "@/components/Tag/Mandatory";
import { DatePickerButton } from "@/components/DatePickerButton";
import { Link } from "react-router-dom";
import { MAX_PRICE, MIN_PRICE } from "@/constants";

interface Props {
    isChecked: boolean;
    newPricePerPayment: number | undefined;
    priceValidation: boolean;
    startDate: Date | undefined;
    handleStartDateChange: (newStartDate: Date | undefined) => void;
    handleModalClose: () => void;
    handleCheckButtonClick: () => void;
    handleNewPricePerPaymentChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
}

export const NormalUpdateContents: React.VFC<Props> = memo(function NormalUpdateContents(props) {
    return (
        <div className={styles.modeContents}>
            <div className={styles.messageWrapper}>
                <p className={styles.message}>
                    今までと異なる開始日を選んだ（今までは1日始まりだったが、今回の更新で16日始まりになった、など）場合には、日割り計算が適用されます。日割り計算の詳細は
                    <Link to="">こちら</Link>
                </p>
            </div>
            <ul className={styles.inputList}>
                <li className={styles.inputListItem}>
                    <div className={styles.itemTitleWrapper}>
                        <div className={styles.itemTitle}>新しい月額</div>
                        <Mandatory />
                    </div>
                    <div className={styles.itemContents}>
                        <div className={styles.priceInputWrapper}>
                            <span className={styles.yen}>￥</span>
                            <input
                                required
                                placeholder="0"
                                className={styles.priceInput}
                                onChange={props.handleNewPricePerPaymentChange}
                                type="number"
                                value={props.newPricePerPayment}
                            />
                            <div className={styles.unit}>/月</div>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <ErrorMessage
                                content={`この項目は必須です。${MIN_PRICE}~${MAX_PRICE}の範囲で入力してください。`}
                                when={props.isChecked && !props.priceValidation}
                            />
                        </div>
                    </div>
                </li>
                <li className={`${styles.inputListItem} ${styles.borderTop}`}>
                    <div className={styles.itemTitleWrapper}>
                        <div className={styles.itemTitle}>新しい月額の開始日</div>
                        <Mandatory />
                    </div>
                    <div className={styles.itemContents}>
                        <DatePickerButton date={props.startDate} handleDateChange={props.handleStartDateChange} />
                        <div style={{ marginTop: "10px" }}>
                            <ErrorMessage
                                content="この項目は必須です。入力してください。"
                                when={props.isChecked && !props.startDate}
                            />
                        </div>
                    </div>
                </li>
            </ul>
            <ButtonPair
                pairType={1}
                leftButtonContents="キャンセル"
                rightButtonContents="確認"
                handleLeftButtonClick={props.handleModalClose}
                handleRightButtonClick={props.handleCheckButtonClick}
            />
        </div>
    );
});
