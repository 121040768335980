import { DraftCourseResponse } from "@/store/autogenApi";

export const getCourseType = (course: DraftCourseResponse) => {
    if (course.courseType === "short") {
        return "単発・短期";
    } else {
        return "定期";
    }
};

export const getTabInfoList = (courses: DraftCourseResponse[]) => {
    let shortCount = 0;
    let regularCount = 0;
    courses.forEach((course) => {
        if (course.courseType === "short") {
            shortCount++;
        } else {
            regularCount++;
        }
    });
    return [
        { value: "regular", label: `定期(${regularCount})` },
        { value: "short", label: `単発・短期(${shortCount})` },
    ];
};
