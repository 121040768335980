import { FAQDetailsBase } from "../../FAQDetailsBase";

export const SupportTime: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="サポート対応時間はいつですか？"
            text="Trailでは、毎日24時間運営への連絡が可能です。通常24時間以内にご連絡いたしますが、混雑状況により回答までに時間がかかる場合がございます。"
        />
    );
};
