import { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { RootState } from "@/ducks";
import { UpdateFavoritePublicRequestsRequestBody } from "@/store/autogenApi";
import { useGetPublicRequestFavoriteCountQuery } from "@/store/hooks/publicRequests";
import { useUpdateFavoriteRequestsMutation } from "@/store/hooks/teachers";
import { FavoriteContents } from "./FavoriteContents";

interface Props {
    userMode: string;
    isFavorite: boolean;
}

export const CommonComponent: React.VFC<Props> = memo(function CommonComponent(props) {
    const [isFavorite, setIsFavorite] = useState<boolean>(false);

    const { id } = useParams<{ id: string }>();
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);
    const favoriteCountQuery = useGetPublicRequestFavoriteCountQuery(id);
    const updateFavoriteRequests = useUpdateFavoriteRequestsMutation();

    useEffect(() => {
        setIsFavorite(props.isFavorite);
    }, [props.isFavorite]);

    const handleFavoriteButtonClick = useCallback(async () => {
        if (props.userMode === "teacher") {
            const requestBody: UpdateFavoritePublicRequestsRequestBody = {
                teacherId: teacherId,
                publicRequestId: id,
            };
            const result = await updateFavoriteRequests({
                updateFavoritePublicRequestsRequestBody: requestBody,
            });
            if (result.isSuccess) {
                const newIsFavorite = result.data;
                setIsFavorite(newIsFavorite);
                favoriteCountQuery.refetch();
            }
        }
    }, [props.userMode, teacherId, id, updateFavoriteRequests]);
    return (
        <QueryLoadingWrapper {...favoriteCountQuery}>
            {(favoriteCount) => {
                return (
                    <FavoriteContents
                        userMode={props.userMode}
                        favoriteCount={favoriteCount}
                        isFavorite={isFavorite}
                        handleFavoriteButtonClick={handleFavoriteButtonClick}
                    />
                );
            }}
        </QueryLoadingWrapper>
    );
});
