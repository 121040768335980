import { Button, Modal, TextField, TextareaAutosize } from "@material-ui/core";

import styles from "./index.module.scss";
import { memo, useCallback, useEffect, useState } from "react";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Processing } from "@/components/Processing";
import { ClassResponse } from "@/store/autogenApi";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { ToastContents } from "@/components/Toast";
import { useUpdateClassNameMutation } from "@/store/hooks/classes";

interface Props {
    targetClass: ClassResponse;
    modalOpen: boolean;
    handleModalClose: () => void;
}

export const UpdateClassNameModal: React.VFC<Props> = memo(function UpdateClassNameModal(props) {
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [classNameString, setClassNameString] = useState<string>("");
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isConfirmMode, setIsConfirmMode] = useState<boolean>(false);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);

    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);

    const updateClassName = useUpdateClassNameMutation();

    useEffect(() => {
        if (!props.targetClass.className) return;
        setClassNameString(props.targetClass.className);
    }, [props.targetClass]);

    const handleClassNameStringChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setClassNameString(e.target.value);
    }, []);

    const handleCheckButtonClick = useCallback(() => {
        setIsChecked(true);
        if (classNameString.length > 30) return;
        setIsConfirmMode(true);
    }, [classNameString]);

    const handleConfirmButtonClick = useCallback(async () => {
        setIsProcessing(true);
        const { isSuccess } = await updateClassName({
            updateClassNameRequestBody: {
                classId: props.targetClass.classId,
                className: classNameString,
            },
        });
        setIsProcessing(false);
        if (isSuccess) {
            setIsCompleted(true);
            toast(<ToastContents title="クラス名更新完了" isCompleted />);
        } else {
            toast(<ToastContents title="クラス名更新失敗" isFailed />);
        }
    }, [studentId, classNameString, updateClassName]);

    const history = useHistory();

    const handleModalClose = useCallback(() => {
        if (isProcessing) return;
        if (isCompleted) {
            history.push(`/MyPage/ReservedCourses?courseType=${props.targetClass.course?.courseType}`);
        }
        setIsChecked(false);
        setClassNameString("");
        setIsProcessing(false);
        setIsConfirmMode(false);
        setIsCompleted(false);
        props.handleModalClose();
    }, [isProcessing, isCompleted, props.targetClass, props.handleModalClose]);

    const handleBackButtonClick = useCallback(() => {
        setIsConfirmMode(false);
    }, []);

    return (
        <Modal open={props.modalOpen} onClose={handleModalClose}>
            <div className={styles.updateClassNameModalContents}>
                {isConfirmMode ? (
                    <div className={styles.confirmModeContents}>
                        <div className={styles.updateClassNameNotice}>
                            下記の内容でクラス名を更新します。よろしいですか？
                        </div>
                        <div className={styles.className}>{classNameString}</div>
                        <div className={styles.buttonsWrapper}>
                            <Button className={styles.backButton} onClick={handleBackButtonClick}>
                                戻る
                            </Button>
                            <Button className={styles.confirmButton} onClick={handleConfirmButtonClick}>
                                {isProcessing ? <Processing /> : "確定"}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className={styles.inputModeContents}>
                        <div className={styles.updateClassNameTitle}>クラス名更新</div>
                        <div className={styles.classNameWrapper}>
                            <input
                                className={styles.classNameInput}
                                value={classNameString}
                                onChange={handleClassNameStringChange}
                                placeholder="例）〇〇さん専用"
                            />
                            <div className={styles.count}>{classNameString.length}/30文字</div>
                            <ErrorMessage
                                content="30字以下で入力してください。"
                                when={isChecked && classNameString.length > 30}
                            />
                        </div>
                        <div className={styles.buttonsWrapper}>
                            <Button className={styles.closeButton} onClick={handleModalClose}>
                                閉じる
                            </Button>
                            <Button className={styles.checkButton} onClick={handleCheckButtonClick}>
                                確認
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
});
