import { useMediaQuery } from "@material-ui/core";
import { useParams } from "react-router";
import { CourseCardsBase } from "@/components/CourseCardsBase";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { useGetMyCoursesQuery } from "@/store/hooks/courses";
import { memo } from "react";

interface Props {
    teacherId: string;
    repetitionNumber?: number;
}

export const OtherCourses: React.VFC<Props> = memo(function OtherCourses(props) {
    const coursesQueryState = useGetMyCoursesQuery(props.teacherId);
    const { id } = useParams<{ id: string }>();

    const mdUp = useMediaQuery("(min-width:960px)");

    return (
        <QueryLoadingWrapper {...coursesQueryState}>
            {(courses) => {
                const filteredCourses = courses.filter((course) => course.courseId !== id);
                return (
                    <>
                        <CourseCardsBase
                            title="この先生の定期講座"
                            courses={filteredCourses.filter(
                                (course) => course.courseType === "regular" && course.classes.length > 0,
                            )}
                            destination={`/TeacherDetails/${props.teacherId}?tab=courses`}
                            isLoaded={true}
                            repetitionNumber={props.repetitionNumber}
                        />
                        <CourseCardsBase
                            title="この先生の単発・短期講座"
                            courses={filteredCourses.filter(
                                (course) => course.courseType === "short" && course.classes.length > 0,
                            )}
                            destination={`/TeacherDetails/${props.teacherId}?tab=courses`}
                            isLoaded={true}
                            repetitionNumber={props.repetitionNumber}
                        />
                    </>
                );
            }}
        </QueryLoadingWrapper>
    );
});
