import React from "react";
import { ImageFromObjectKey } from "./ImageFromObjectKey";

interface Props {
    url?: string;
    objectKey?: string;
    defaultUrl?: string;
    className?: string;
    ref?: (node: HTMLImageElement | null) => void;
}

export const ImageFromS3: React.VFC<Props> = ({ url, objectKey, defaultUrl, className, ref }) => {
    return url ? (
        <img src={url} className={className} ref={ref} alt="画像" />
    ) : objectKey ? (
        <ImageFromObjectKey objectKey={objectKey} className={className} ref={ref} />
    ) : (
        <img src={defaultUrl} className={className} ref={ref} alt="画像（デフォルト）" />
    );
};
