import MenuBookIcon from "@material-ui/icons/MenuBook";

import {
    HIGH_SCHOOL_SUBJECT_CONFIG,
    JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG,
    ELEMENTARY_SCHOOL_SUBJECT_CONFIG,
    SubjectConfig,
} from "@/SubjectConfig";
import styles from "@/pages/Common/PublicRequestDetails/index.module.scss";
import { SubjectResponse } from "@/store/autogenApi";
import { memo, useCallback } from "react";
import { useHistory } from "react-router";

interface Props {
    subjects: SubjectResponse[];
}

export const SubjectComponent: React.VFC<Props> = memo(function SubjectComponent(props) {
    const getSubjectName = useCallback((subject: SubjectResponse) => {
        const levelKey = subject.level;
        const categoryKey = subject.category as string;
        const subCategoryKey = subject.subCategory;
        let subjectConfig: SubjectConfig;
        let level: string;
        if (levelKey === "highSchool") {
            subjectConfig = HIGH_SCHOOL_SUBJECT_CONFIG;
            level = "高校";
        } else if (levelKey === "juniorHighSchool") {
            subjectConfig = JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG;
            level = "中学校";
        } else {
            subjectConfig = ELEMENTARY_SCHOOL_SUBJECT_CONFIG;
            level = "小学校";
        }
        const category = subjectConfig[categoryKey].label;
        const subCategories = subjectConfig[categoryKey].sub;
        if (subCategories && subCategoryKey) {
            const subCategory = subCategories[subCategoryKey].label;
            return `${level}-${category}-${subCategory}`;
        } else {
            return `${level}-${category}`;
        }
    }, []);

    const history = useHistory();

    const handleSubjectButtonClick = useCallback((subject: SubjectResponse) => {
        const { level, category, subCategory } = subject;
        history.push(
            `/SearchResults?searchType=publicRequest&level=${level}&category=${category}&subCategory=${subCategory}`,
        );
    }, []);

    return (
        <div className={styles.detailWrapper}>
            <div className={styles.detailHeadingWrapper}>
                <span className={styles.detailHeading}>科目</span>
            </div>
            <div className={styles.detail} id="detail">
                <div className={styles.subjects}>
                    {props.subjects.map((subject, idx) => {
                        return (
                            <button
                                className={styles.subject}
                                id="detail"
                                key={idx}
                                onClick={() => {
                                    handleSubjectButtonClick(subject);
                                }}
                            >
                                {getSubjectName(subject)}
                            </button>
                        );
                    })}
                </div>
            </div>
        </div>
    );
});
