import { CircularProgress } from "@material-ui/core";

import styles from "./index.module.scss";

interface Props {
    children: React.ReactNode;
    loadingComplete: boolean;
}

export const LoadingProgress: React.VFC<Props> = (props) => {
    if (props.loadingComplete) {
        return <>{props.children}</>;
    } else {
        return <CircularProgress className={styles.progress} />;
    }
};
