import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { memo } from "react";

import { Mandatory } from "@/components/Tag/Mandatory";
import styles from "@/pages/Student/MyPage/index.module.scss";
import { None } from "../Errors/None";

import { PREFECTURE_LIST } from "./prefectureList";

interface Props {
    value: string;
    checked: boolean;
    edit: boolean;
    isPublic: boolean;
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

export const Prefecture: React.VFC<Props> = memo(function Prefecture(props) {
    return (
        <>
            {props.edit ? (
                <>
                    <li className={styles.editListItem} style={{ borderBottom: props.edit ? "" : "#305077 1px solid" }}>
                        <div className={styles.leftWrapper}>
                            <div className={styles.titleWrapper}>
                                <div className={styles.title}>お住まいの都道府県</div>
                                <Mandatory />
                            </div>
                        </div>
                        <div className={styles.rightWrapper}>
                            <Select
                                variant="outlined"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="prefecture"
                                value={props.value}
                                onChange={props.handleChange}
                                className={styles.select}
                            >
                                {PREFECTURE_LIST.map((prefecture, index) => (
                                    <MenuItem key={index} value={prefecture}>
                                        {prefecture}
                                    </MenuItem>
                                ))}
                            </Select>
                            <None value={props.value} checked={props.checked} />
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="isPrefecturePublic"
                                    onChange={props.handleChange}
                                    value={props.isPublic ? "public" : "private"}
                                >
                                    <FormControlLabel
                                        value="public"
                                        control={<Radio />}
                                        label="公開"
                                        className={styles.radioButtonLabel}
                                    />
                                    <FormControlLabel
                                        value="private"
                                        control={<Radio />}
                                        label="非公開"
                                        className={styles.radioButtonLabel}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </li>
                </>
            ) : (
                <li className={styles.profileItem} style={{ borderBottom: props.edit ? "" : "#305077 1px solid" }}>
                    <div className={styles.profileTitleWrapper}>
                        <div className={styles.profileTitle}>都道府県</div>
                        <div className={styles.isPublic}>{props.isPublic ? "公開" : "非公開"}</div>
                    </div>
                    <div className={styles.profileSpace}></div>
                    <div className={styles.profileValue}>{props.value}</div>
                </li>
            )}
        </>
    );
});
