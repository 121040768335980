import { memo } from "react";
import { SubjectsComponent } from "@/components/SubjectsComponent";
import { Optional } from "@/components/Tag/Optional";
import styles from "@/pages/Student/MyPage/index.module.scss";
import { SubjectResponse } from "@/store/autogenApi";
import { getSubject } from "@/utils/SubjectUtils";

interface Props {
    checked: boolean;
    edit: boolean;
    favoriteSubjects: SubjectResponse[];
    setFavoriteSubjects: React.Dispatch<React.SetStateAction<SubjectResponse[]>>;
}

export const FavoriteSubjects: React.VFC<Props> = memo(function FavoriteSubjects(props) {
    return (
        <>
            {props.edit ? (
                <li className={styles.editListItem} style={{ borderBottom: "none" }}>
                    <div className={styles.leftWrapper}>
                        <div className={styles.titleWrapper}>
                            <div className={styles.title}>興味のある分野</div>
                            <Optional />
                        </div>
                    </div>
                    <div
                        className={styles.rightWrapper}
                        style={{ paddingRight: 0, paddingLeft: 0, width: "calc(100% - 30px)" }}
                    >
                        <SubjectsComponent
                            checked={props.checked}
                            subjects={props.favoriteSubjects}
                            setSubjects={props.setFavoriteSubjects}
                        />
                    </div>
                </li>
            ) : (
                <li className={styles.profileItem} style={{ borderBottom: "#305077 1px solid" }}>
                    <div className={styles.profileTitleWrapper}>
                        <div className={styles.profileTitle}>興味のある分野</div>
                    </div>
                    <div className={styles.profileSpace}></div>
                    <div className={styles.profileValue}>
                        {props.favoriteSubjects && props.favoriteSubjects.length > 0 ? (
                            <ul className={styles.favoriteSubjects}>
                                {props.favoriteSubjects.map((subject) => {
                                    return (
                                        <li key={subject.subjectId} className={styles.favoriteSubject}>
                                            {getSubject(subject)}
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            <>なし</>
                        )}
                    </div>
                </li>
            )}
        </>
    );
});
