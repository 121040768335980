import { memo, useCallback, useEffect, useRef, useState } from "react";

import { MenuItem } from "@material-ui/core";

import styles from "@/pages/Student/CreatePublicRequest/index.module.scss";
import { WHAT_DAY_LIST } from "@/utils/WhatDayList";

import { StyledSelect } from "./StyledSelect";
import { Mandatory } from "@/components/Tag/Mandatory";

import { getFullDate } from "@/utils/GetFullDate";
import { DefaultSelect } from "@/components/DefaultSelect";

interface Props {
    applicationPeriod: number;
    courseType?: string;
    checked: boolean;
    open?: boolean;
    validation: boolean;
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

interface LeftTime {
    date: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export const ApplicationPeriod: React.VFC<Props> = memo(function ApplicationPeriod(props) {
    const [deadline, setDeadline] = useState<Date | undefined>(undefined);
    const intervalRef = useRef<any>(null);

    const checkDeadline = useCallback(() => {
        if (props.applicationPeriod === 0) return;
        const newDeadline = new Date();
        newDeadline.setDate(newDeadline.getDate() + props.applicationPeriod);
        intervalRef.current = setInterval(() => {
            setDeadline(newDeadline);
        }, 1000);
    }, [props.applicationPeriod]);

    useEffect(() => {
        checkDeadline();
        return () => {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        };
    }, [checkDeadline]);
    const fixPlaceholder = useCallback(() => {
        const element: HTMLInputElement | null = document.getElementById("remarksTextArea") as HTMLInputElement;
        if (element) {
            element.placeholder = element.placeholder.replace(/\\n/g, "\n");
        }
    }, []);

    useEffect(() => {
        fixPlaceholder();
    }, []);

    return (
        <li className={styles.listItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>募集期間</div>
                    {!props.open && <Mandatory />}
                </div>
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <>
                        <div className={styles.openTrue}>{props.applicationPeriod}日間</div>
                        <div className={styles.openTrue}>締め切り時刻：{getFullDate(deadline)}</div>
                    </>
                ) : (
                    <>
                        <div className={styles.selectWrapper}>
                            <DefaultSelect
                                name="applicationPeriod"
                                value={props.applicationPeriod ? props.applicationPeriod : 0}
                                handleChange={props.handleChange}
                                options={[{ label: "", value: 0 }].concat(
                                    [...Array(10)].map((_, i) => ({
                                        label: String(i + 1),
                                        value: i + 1,
                                    })),
                                )}
                            />
                            日間
                        </div>
                        {deadline != undefined && (
                            <>
                                <div className={styles.deadline}>{`締め切り時刻：${getFullDate(deadline)}`}</div>
                                <div
                                    className={styles.deadline}
                                >{`※締め切り時刻は目安であり、通信環境やシステム状況などから、誤差が生じる場合がありますことを予めご理解ください。`}</div>
                            </>
                        )}
                        {props.courseType === "short" && props.checked && !props.validation && (
                            <div className={styles.helperText}>
                                この項目は必須です。候補日程の最初の日の前日よりも締め切り日が前になるように選択してください。
                            </div>
                        )}
                        {props.courseType === "regular" && props.checked && !props.validation && (
                            <div className={styles.helperText}>この項目は必須です。選択してください。</div>
                        )}
                    </>
                )}
            </div>
        </li>
    );
});
