import { createRef, memo, useCallback, useRef } from "react";
import { useState, useEffect } from "react";

import { Button, Hidden, useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { LoadingProgress } from "@/components/LoadingProgress";
import { AdditionalDescription } from "./AdditionalDescription";
import { BaseRequestComponent } from "./BaseRequestComponent";
import { CourseDetail } from "./CourseDetail";
import { CourseSchedules } from "./CourseSchedules";
import { FixedContents } from "./FixedContents";
import { PastInfo } from "./PastInfo";
import { PleaseSignUpModal } from "@/components/PleaseSignUpModal";
import { ReviewsComponent } from "./ReviewsComponent";
import { Subjects } from "./Subjects";
import { Target } from "./Target";
import { TeacherInfo } from "./TeacherInfo";
import styles from "@/pages/Common/CourseDetails/index.module.scss";
import "@/pages/Common/CourseDetails/animation.css";
import {
    CourseResponse,
    ClassResponse,
    PrivateUserResponse,
    UpdateCourseBrowsingHistoryRequestBody,
    LessonResponse,
} from "@/store/autogenApi";
import { useUpdateCourseBrowsingHistoryMutation } from "@/store/hooks/students";
import { WHAT_DAY_LIST } from "@/utils/WhatDayList";
import { RootState } from "@/ducks";
import { DuplicateModal } from "./DuplicateModal";
import { TeachingMaterial } from "./TeachingMaterial";
import { PrivateRequestModal } from "./PrivateRequestModal";
import { OtherCourses } from "./RelatedCourses/OtherCourses";
import { CoursesWithTheSameSubjects } from "./RelatedCourses/CoursesWithTheSameSubjects";
import defaultCourseImage from "@/images/defaultCourseImage.jpg";
import { getExistLessonConflict } from "@/utils/LessonUtils";
import { getLevelAndCategory } from "@/utils/SubjectUtils";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { ImageFromS3 } from "@/components/atoms/images/ImageFromS3";
import { S3ImageLoadingWrapper } from "@/components/features/S3ImageLoadingWrapper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination, Autoplay, EffectFade } from "swiper";
import { SlideContents } from "./SliderContents";

import "./SliderContents/swiper.css";

interface Props {
    course: CourseResponse;
    user: PrivateUserResponse | undefined;
    otherCourseLessons: LessonResponse[];
}

const SWIPER_PROPS = {
    speed: 1000,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
    effect: "fade" as any,
    pagination: {
        clickable: true,
    },
    navigation: true,
    slidesPerView: 1,
    spaceBetween: 10,
    loop: true,
    modules: [Navigation, Autoplay, Pagination, EffectFade],
};

export const CourseDetailsContents: React.VFC<Props> = memo(function CourseDetailsContents(props) {
    const [isFavorite, setIsFavorite] = useState<boolean>(false);
    const [howMuchLackOfPoints, setHowMuchLackOfPoints] = useState<number>(0);
    const [signUpModalOpen, setSignUpModalOpen] = useState<boolean>(false);
    const [isHostTeacher, setIsHostTeacher] = useState<boolean>(false);
    const [reservationComplete, setReservationComplete] = useState<boolean>(false);
    const [activeClasses, setActiveClasses] = useState<ClassResponse[]>([]);
    const [targetClass, setTargetClass] = useState<ClassResponse | undefined>(undefined);
    // const [howManyHours, setHowManyHours] = useState<number>(0);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [duplicateModalOpen, setDuplicateModalOpen] = useState<boolean>(false);
    const [privateRequestModalOpen, setPrivateRequestModalOpen] = useState<boolean>(false);
    const [isReservationButtonClicked, setIsReservationButtonClicked] = useState<boolean>(false);
    const [thresholdOver, setThresholdOver] = useState<boolean>(false);
    const [mainImageIdx, setMainImageIdx] = useState<number>(0);
    const [mainImageEl, setMainImageEl] = useState<HTMLElement | null>(null);

    const [repetitionNumber, setRepetitionNumber] = useState<number | undefined>(undefined);

    const threshold1 = useMediaQuery("(min-width:960px)");
    const threshold2 = useMediaQuery("(min-width:1120px)");
    const threshold3 = useMediaQuery("(min-width:1280px)");

    const fixedContentsEl: React.MutableRefObject<HTMLElement | null> = useRef(null);
    const courseDetailsContentsWrapperEl: React.MutableRefObject<HTMLElement | null> = useRef(null);

    const userMode = useSelector((state: RootState) => state.jwt.userMode);

    const smUp = useMediaQuery("(min-width:600px)", { noSsr: true });
    const mdUp = useMediaQuery("(min-width:960px)", { noSsr: true });
    const lgUp = useMediaQuery("(min-width:1280px)", { noSsr: true });
    const exLgUp = useMediaQuery("(min-width:1360px)", { noSsr: true });

    const updateCourseBrowsingHistory = useUpdateCourseBrowsingHistoryMutation();

    const mainImageRef = useCallback((el: HTMLElement | null) => {
        setMainImageEl(el);
    }, []);

    const intervalRef = useRef<any>(null);

    const changeMainImage = useCallback(() => {
        if (!mainImageEl) return;
        mainImageEl.classList.add("fade-in");
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
            if (!mainImageEl) return;
            const urlsLength = props.course.imageUrls.length;
            if (mainImageIdx == urlsLength - 1) {
                setMainImageIdx(0);
                return;
            }
            setMainImageIdx((prev) => prev + 1);
        }, 10000);
    }, [mainImageIdx, props.course, mainImageEl, intervalRef]);

    const disableMainImageAnimation = useCallback(() => {
        if (!mainImageEl) return;
        mainImageEl.classList.remove("fade-in");
        clearInterval(intervalRef.current);
        intervalRef.current = null;
    }, [mainImageEl, intervalRef]);

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        const firstSubject = props.course.subjects[0];
        if (!firstSubject || !props.course.title) {
            updateNavigationList([
                {
                    title: "講座検索",
                    url: `/SearchResults?searchType=course`,
                },
            ]);
            return;
        }
        updateNavigationList([
            {
                title: "講座検索",
                url: `/SearchResults?searchType=course`,
            },
            {
                title: getLevelAndCategory(firstSubject),
                url: `/SearchResults?searchType=course&level=${firstSubject.level}&category=${firstSubject.category}`,
            },
            {
                title: props.course.title,
                url: `/CourseDetails/${props.course.courseId}`,
            },
        ]);
    }, [props.course]);

    useEffect(() => {
        if (props.course.imageObjectKeys.length <= 1) return;
        changeMainImage();
        return () => {
            disableMainImageAnimation();
        };
    }, [changeMainImage, disableMainImageAnimation, props.course]);

    const fixedContentsFade = useCallback(() => {
        const scrollingElement = document.scrollingElement;
        const header = document.getElementById("header");
        const footer = document.getElementById("footer");
        const contents = document.getElementById("contents");
        const courseDetailsContentsWrapper = courseDetailsContentsWrapperEl.current;
        if (
            scrollingElement &&
            fixedContentsEl.current &&
            header &&
            footer &&
            contents &&
            courseDetailsContentsWrapper
        ) {
            // const footerHeight = footer.scrollHeight;
            let threshold: number;
            if (!smUp) {
                const styles = window.getComputedStyle(courseDetailsContentsWrapper);
                const marginTop = Number(styles.marginTop.replace("px", ""));
                const marginBottom = Number(styles.marginBottom.replace("px", ""));
                const totalHeight = courseDetailsContentsWrapper.clientHeight + marginTop + marginBottom;
                const navigationWrapperHeight = 28;
                threshold =
                    totalHeight +
                    header.offsetHeight +
                    navigationWrapperHeight +
                    fixedContentsEl.current.clientHeight -
                    window.innerHeight;
                if (scrollingElement.scrollTop > threshold) {
                    fixedContentsEl.current.style.opacity = "0";
                    fixedContentsEl.current.style.pointerEvents = "none";
                } else {
                    fixedContentsEl.current.style.opacity = "1";
                    fixedContentsEl.current.style.pointerEvents = "auto";
                }
            }
        }
    }, [smUp, mdUp, lgUp, exLgUp, fixedContentsEl.current, courseDetailsContentsWrapperEl.current]);

    useEffect(() => {
        if (!threshold1) {
            setRepetitionNumber(undefined);
            return;
        }
        if (!threshold2) {
            setRepetitionNumber(6);
            return;
        }
        if (!threshold3) {
            setRepetitionNumber(7);
            return;
        }
        setRepetitionNumber(8);
    }, [threshold1, threshold2, threshold3]);

    useEffect(() => {
        if (props.course.courseType === "regular") return;
        const classResponse = props.course.classes.find((classResponse) => classResponse.status === "recruiting");
        if (!classResponse) return;
        setTargetClass(classResponse);
    }, [props.course]);

    // useEffect(() => {
    //     const fixedContents = fixedContentsEl.current;
    //     const courseDetailsContentsWrapper = fixedContentsEl.current;
    //     if (fixedContents && courseDetailsContentsWrapper) {
    //         const scrollHeight = fixedContents.scrollHeight;
    //         fixedContents.scrollTop = scrollHeight;
    //     }
    // }, [fixedContentsEl.current, courseDetailsContentsWrapperEl.current]);

    useEffect(() => {
        fixedContentsFade();
        window.addEventListener("scroll", () => {
            fixedContentsFade();
        });
        return () => {
            window.removeEventListener("scroll", () => {
                fixedContentsFade();
            });
        };
    }, [fixedContentsFade]);

    useEffect(() => {
        const targetClass = activeClasses[0];
        // let totalHours = 0;
        // targetClass?.lessons?.forEach((lesson) => {
        //     if (lesson.startTime && lesson.endTime) {
        //         const startTime = new Date(lesson.startTime);
        //         const endTime = new Date(lesson.endTime);
        //         const diffTime = endTime.getTime() - startTime.getTime();
        //         const hours = diffTime / (1000 * 60 * 60);
        //         totalHours += hours;
        //     }
        // });
        // setHowManyHours(totalHours);
        for (let i = 0; i < activeClasses.length; i++) {
            // const targetLessons = activeClasses[i];
            const targetApplyingDeadline = targetClass.applyingDeadline;
            const now = new Date();
            if (targetApplyingDeadline && new Date(targetApplyingDeadline).getTime() > now.getTime()) {
                const newTargetClass = activeClasses[i];
                setTargetClass(newTargetClass);
                break;
            }
        }
    }, [activeClasses]);

    const reviewsRef = createRef<HTMLDivElement>();

    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        (async () => {
            setIsLoaded(true);
            window.scrollTo(0, 0);
            if (userMode === "student") {
                const requestBody: UpdateCourseBrowsingHistoryRequestBody = {
                    studentId: props.user?.studentId as string,
                    courseId: id,
                };
                await updateCourseBrowsingHistory({
                    updateCourseBrowsingHistoryRequestBody: requestBody,
                });
            }
            if (
                props.course.teacher?.teacherId &&
                props.user?.teacherId &&
                props.course.teacher?.teacherId === props.user?.teacherId
            ) {
                setIsHostTeacher(true);
            }
            if (props.course.classes) {
                const newActiveClasses = props.course.classes.filter(
                    (classResponse) =>
                        classResponse.status === "recruiting" &&
                        classResponse.applyingDeadline &&
                        new Date(classResponse.applyingDeadline).getTime() > new Date().getTime(),
                );
                setActiveClasses(newActiveClasses);
            }
        })();
    }, [props.course, props.user, userMode, id]);

    const checkOtherCourseSchedules = useCallback(() => {
        const targetLessons = targetClass?.lessons;
        if (!targetLessons) return false;
        const isDuplicate = getExistLessonConflict(targetLessons, props.otherCourseLessons);
        const isNotDuplicate = !isDuplicate;
        return isNotDuplicate;
    }, [props.otherCourseLessons, targetClass, getExistLessonConflict]);

    const handleSignUpModalClose = () => {
        setSignUpModalOpen(false);
    };

    const history = useHistory();

    const handleReservationButtonClick = useCallback(
        (classId: string) => {
            if (!checkOtherCourseSchedules()) {
                setDuplicateModalOpen(true);
                return;
            }
            if (userMode === "student") {
                if (props.course.courseType === "short") {
                    history.push(`/Payment/${classId}`);
                } else {
                    handlePrivateRequestModalOpen();
                }
                setIsReservationButtonClicked(true);
            } else {
                handleSignUpModalOpen();
            }
        },
        [userMode, checkOtherCourseSchedules, props.course.courseType],
    );

    const handleSignUpModalOpen = useCallback(() => {
        setSignUpModalOpen(true);
    }, []);

    const handlePrivateRequestModalOpen = useCallback(() => {
        setPrivateRequestModalOpen(true);
    }, []);

    const handlePrivateRequestModalClose = useCallback(() => {
        setPrivateRequestModalOpen(false);
    }, []);

    const handleChargeButtonClick = useCallback(() => {
        if (userMode === "student") {
            if (howMuchLackOfPoints && typeof props.user?.pointsBalance === "number") {
                const necessaryPoints = howMuchLackOfPoints + props.user?.pointsBalance;
                history.push(`/PointsCharge?necessaryPoints=${necessaryPoints}`);
            }
        }
    }, []);

    const getJapaneseDay = useCallback((dayIdx: number) => {
        return WHAT_DAY_LIST[dayIdx];
    }, []);

    const handleToCourseDetailsButtonClick = useCallback(() => {
        location.reload();
    }, []);

    const getCourseType = useCallback(() => {
        if (props.course.courseType === "short") {
            return "単発・短期講座";
        } else {
            return "定期講座";
        }
    }, []);

    const getUnit = useCallback(() => {
        if (props.course.courseType === "short") {
            return "予約";
        } else {
            return "依頼";
        }
    }, []);

    const handleClassButtonClick = useCallback((classId: string) => {
        history.push(`/Class/${classId}`);
    }, []);

    const handleDuplicateModalClose = useCallback(() => {
        setDuplicateModalOpen(false);
    }, []);

    const initializeReservationButtonClicked = useCallback(() => {
        setIsReservationButtonClicked(false);
    }, []);

    const handleSmallImageClick = useCallback(
        (index: number) => {
            disableMainImageAnimation();
            setMainImageIdx(index);
        },
        [disableMainImageAnimation],
    );

    const handleTargetClassChange = useCallback(
        (classId: string | undefined) => {
            const newTargetClass = activeClasses.find((classResponse) => classResponse.classId === classId);
            if (newTargetClass) {
                setTargetClass(newTargetClass);
            }
        },
        [activeClasses],
    );

    return (
        <div
            className={styles.courseDetailsContentsWrapper}
            ref={(node: HTMLElement | null) => {
                courseDetailsContentsWrapperEl.current = node;
            }}
        >
            <div className={styles.courseDetailsContents}>
                {reservationComplete ? (
                    <div className={styles.reservationCompleteWrapper}>
                        <div className={styles.reservationCompleteMessage}>{getUnit()}が完了しました。</div>
                        <Button className={styles.toCourseDetailsButton} onClick={handleToCourseDetailsButtonClick}>
                            講座詳細ページに戻る
                        </Button>
                    </div>
                ) : (
                    <>
                        <div
                            className={`${styles.mainWrapper}`}
                            style={{ paddingRight: props.course.publicRequest?.publicRequestId && "0" }}
                            id="contents"
                        >
                            <LoadingProgress loadingComplete={isLoaded}>
                                <>
                                    {props.course.publicRequest ? (
                                        <BaseRequestComponent publicRequest={props.course.publicRequest} />
                                    ) : (
                                        <>
                                            <div className={styles.titleWrapper}>
                                                <div className={styles.courseType}>{getCourseType()}</div>
                                                <div className={styles.title}>{props.course.title}</div>
                                            </div>
                                            <div className={styles.manyContentsWrapper}>
                                                {props.course.reviews && (
                                                    <PastInfo
                                                        reviews={props.course.reviews}
                                                        totalPastCourseParticipants={0}
                                                        reviewsRef={reviewsRef}
                                                    />
                                                )}
                                                <Hidden smUp>
                                                    <div className={styles.imagesWrapper}>
                                                        {(props.course.imageUrls[mainImageIdx] ||
                                                            props.course.imageObjectKeys[mainImageIdx]) && (
                                                            <ImageFromS3
                                                                url={props.course.imageUrls[mainImageIdx]}
                                                                objectKey={props.course.imageObjectKeys[mainImageIdx]}
                                                                className={styles.bigImage}
                                                                ref={mainImageRef}
                                                            />
                                                        )}
                                                        <div className={styles.smallImages}>
                                                            {[...Array(6)].map((_, idx) => {
                                                                const objectKey = props.course.imageObjectKeys[idx];
                                                                if (!objectKey) return null;
                                                                return (
                                                                    <div className={styles.smallImageWrapper}>
                                                                        <S3ImageLoadingWrapper objectKey={objectKey}>
                                                                            {(url) => (
                                                                                <button
                                                                                    className={styles.smallImage}
                                                                                    style={
                                                                                        mainImageIdx === idx
                                                                                            ? {
                                                                                                  opacity: 1,
                                                                                                  backgroundImage: url
                                                                                                      ? `url(${url})`
                                                                                                      : "none",
                                                                                                  pointerEvents: url
                                                                                                      ? "auto"
                                                                                                      : "none",
                                                                                              }
                                                                                            : {
                                                                                                  backgroundImage: url
                                                                                                      ? `url(${url})`
                                                                                                      : "none",
                                                                                                  pointerEvents: url
                                                                                                      ? "auto"
                                                                                                      : "none",
                                                                                              }
                                                                                    }
                                                                                    onClick={() =>
                                                                                        handleSmallImageClick(idx)
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </S3ImageLoadingWrapper>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </Hidden>
                                                <Hidden xsDown>
                                                    <div className={styles.sliderWrapper}>
                                                        {props.course.imageUrls && props.course.imageUrls.length > 0 ? (
                                                            <Swiper {...SWIPER_PROPS} className={styles.swiper}>
                                                                {props.course.imageUrls.map((imageUrl, idx) => (
                                                                    <SwiperSlide key={imageUrl}>
                                                                        <SlideContents
                                                                            url={imageUrl}
                                                                            objectKey={
                                                                                props.course.imageObjectKeys[idx]
                                                                            }
                                                                        />
                                                                    </SwiperSlide>
                                                                ))}
                                                            </Swiper>
                                                        ) : (
                                                            <div
                                                                className={styles.defaultCourseImage}
                                                                style={{
                                                                    backgroundImage: `url(${defaultCourseImage})`,
                                                                }}
                                                                ref={mainImageRef}
                                                            />
                                                        )}
                                                    </div>
                                                </Hidden>
                                            </div>
                                        </>
                                    )}
                                    {props.course.courseType && props.course.classes && props.course.teacher && (
                                        <CourseSchedules
                                            activeClasses={activeClasses}
                                            availableTimes={props.course.availableTimes}
                                            activeClassForRegularCourse={targetClass}
                                            courseType={props.course.courseType}
                                            studentId={props.user?.studentId}
                                            userMode={userMode}
                                            courseId={id}
                                            isHostTeacher={isHostTeacher}
                                            privateRequest={props.course.privateRequest}
                                            courseTeacherId={props.course.teacher.teacherId}
                                            privateRequestModalOpen={privateRequestModalOpen}
                                            getJapaneseDay={getJapaneseDay}
                                            handleReservationButtonClick={handleReservationButtonClick}
                                            handleClassButtonClick={handleClassButtonClick}
                                            handlePrivateRequestModalClose={handlePrivateRequestModalClose}
                                        />
                                    )}
                                    {props.course.subjects && <Subjects subjects={props.course.subjects} />}
                                    {props.course.courseDescription && (
                                        <CourseDetail detail={props.course.courseDescription} />
                                    )}
                                    {props.course.targetDescription && (
                                        <Target target={props.course.targetDescription} />
                                    )}
                                    {props.course.teachingMaterial && (
                                        <TeachingMaterial teachingMaterial={props.course.teachingMaterial} />
                                    )}
                                    {props.course.teacher && (
                                        <TeacherInfo
                                            studentId={props.user?.studentId}
                                            teacher={props.course.teacher}
                                            userMode={userMode}
                                        />
                                    )}
                                    {props.course.reviews && props.course.reviews.length > 0 && (
                                        <ReviewsComponent
                                            reviews={props.course.reviews}
                                            reviewsRef={reviewsRef}
                                            courseId={id}
                                        />
                                    )}
                                    {props.course.additionalDescription && (
                                        <AdditionalDescription
                                            additionalDescription={props.course.additionalDescription}
                                        />
                                    )}
                                </>
                            </LoadingProgress>
                        </div>
                        {isLoaded && (
                            <>
                                {!props.course.publicRequest && (
                                    <FixedContents
                                        id={id}
                                        studentId={props.user?.studentId}
                                        course={props.course}
                                        userMode={userMode}
                                        howMuchLackOfPoints={howMuchLackOfPoints}
                                        isFavorite={isFavorite}
                                        isHostTeacher={isHostTeacher}
                                        fixedContentsEl={fixedContentsEl}
                                        targetClass={targetClass}
                                        activeClasses={activeClasses}
                                        privateRequestModalOpen={privateRequestModalOpen}
                                        isReservationButtonClicked={isReservationButtonClicked}
                                        thresholdOver={thresholdOver}
                                        handleTargetClassChange={handleTargetClassChange}
                                        handleChargeButtonClick={handleChargeButtonClick}
                                        handleReservationButtonClick={handleReservationButtonClick}
                                        handleClassButtonClick={handleClassButtonClick}
                                        handlePrivateRequestModalClose={handlePrivateRequestModalClose}
                                        handleSignUpModalOpen={handleSignUpModalOpen}
                                        initializeReservationButtonClicked={initializeReservationButtonClicked}
                                    />
                                )}
                                <PleaseSignUpModal
                                    userMode={userMode}
                                    modalOpen={signUpModalOpen}
                                    actionTitle="講座を予約する"
                                    handleModalClose={handleSignUpModalClose}
                                />
                                <DuplicateModal
                                    duplicateModalOpen={duplicateModalOpen}
                                    handleDuplicateModalClose={handleDuplicateModalClose}
                                />
                                {userMode === "student" && props.course.teacher && targetClass && (
                                    <PrivateRequestModal
                                        classId={targetClass.classId}
                                        price={targetClass?.pricePerHour as number}
                                        courseTeacherId={props.course.teacher.teacherId}
                                        privateRequestModalOpen={privateRequestModalOpen}
                                        handlePrivateRequestModalClose={handlePrivateRequestModalClose}
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
            <div className={styles.courses}>
                {props.course.teacher && (
                    <OtherCourses teacherId={props.course.teacher.teacherId} repetitionNumber={repetitionNumber} />
                )}
                <CoursesWithTheSameSubjects subjects={props.course.subjects} repetitionNumber={repetitionNumber} />
            </div>
        </div>
    );
});
