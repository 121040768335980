import { Button } from "@material-ui/core";
import { useHistory } from "react-router";

import { CourseTypes } from "@/pageComponents/Common/AboutRequest/CourseTypes";
import { Examples } from "@/pageComponents/Common/AboutRequest/Examples";
import { Flow } from "@/pageComponents/Common/AboutRequest/Flow";
import styles from "@/pages/Common/AboutRequest/index.module.scss";

export const AboutRequest: React.VFC = () => {
    const history = useHistory();
    const handleCreatePublicRequestButtonClick = () => {
        history.push("/CreatePublicRequest");
    };
    const handleHomeButtonClick = () => {
        history.push("/");
    };
    return (
        <>
            <div className={styles.aboutRequestWrapper}>
                <div className={styles.aboutRequestTitleWrapper}>講座リクエストとは？</div>
                <div className={styles.topDescriptionWrapper}>
                    <div className={styles.description}>
                        講座リクエストとは、生徒さんが自分の要望に合う講座を提供してくれる先生を募集することができる機能です。
                    </div>
                    <div className={styles.description}>
                        科目や授業内容はもちろんのこと、時間や価格帯まで自由に設定可能！
                    </div>
                    <div className={styles.description}>信頼できる先生を自分の手で見つけましょう。</div>
                </div>
                <CourseTypes />
                <Examples />
                <Flow />
                <div className={styles.createPublicRequestButtonWrapper}>
                    <Button className={styles.homeButton} onClick={handleHomeButtonClick}>
                        ホームに戻る
                    </Button>
                    <Button className={styles.createPublicRequestButton} onClick={handleCreatePublicRequestButtonClick}>
                        講座リクエスト作成
                    </Button>
                </div>
            </div>
        </>
    );
};
