import { FAQConfig } from "@/FAQConfig";

export const MONTHLY_FEE_CONFIGS: FAQConfig[] = [
    {
        title: "月額料金の目安の算出方法",
        text: "算出方法は以下の通りです。",
        contents: [
            {
                title: "月額料金の目安 = 時間単価×1週間の合計授業時間×1カ月の平均週数",
                text: "端数は切り上げています。",
            },
            {
                title: "1カ月の平均週数=365/12",
                text: "",
            },
        ],
    },
];
