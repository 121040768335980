import { memo, useEffect, useState } from "react";

import { useMediaQuery } from "@material-ui/core";

import { UserType } from "@/protectedRouter/ProtectedRouterConfig";
import { CourseResponse, ClassResponse, PrivateRequestResponse } from "@/store/autogenApi";
import { WHAT_DAY_LIST } from "@/utils/WhatDayList";

import { RegularCourseContents } from "./RegularCourseContents";
import { ShortCourseContents } from "./ShortCourseContents";

interface Props {
    id: string;
    studentId: string | null | undefined;
    howMuchLackOfPoints: number;
    isFavorite: boolean;
    isHostTeacher: boolean;
    course: CourseResponse;
    userMode: UserType;
    targetClass: ClassResponse | undefined;
    activeClasses: ClassResponse[];
    fixedContentsEl: React.MutableRefObject<HTMLElement | null>;
    privateRequestModalOpen: boolean;
    isReservationButtonClicked: boolean;
    thresholdOver: boolean;
    handleChargeButtonClick: () => void;
    handleReservationButtonClick: (classId: string) => void;
    handleTargetClassChange: (classId: string | undefined) => void;
    handleClassButtonClick: (classId: string) => void;
    handlePrivateRequestModalClose: () => void;
    handleSignUpModalOpen: () => void;
    initializeReservationButtonClicked: () => void;
}

export const FixedContents: React.VFC<Props> = memo(function FixedContents(props) {
    const [addScheduleModalOpen, setAddScheduleModalOpen] = useState<boolean>(false);
    const [totalNecessaryPoints, setTotalNecessaryPoints] = useState<number>(0);
    const [formula, setFormula] = useState<string>("");

    useEffect(() => {
        if (props.targetClass && props.targetClass.lessons && props.targetClass.pricePerHour) {
            const totalMinutes = props.targetClass.lessons
                .map((lesson) => {
                    if (lesson.startTime && lesson.endTime) {
                        const startTime = new Date(lesson.startTime);
                        const endTime = new Date(lesson.endTime);
                        const diffMinutes = (endTime.getTime() - startTime.getTime()) / (1000 * 60);
                        return diffMinutes;
                    } else {
                        return 0;
                    }
                })
                .reduce((a, b) => a + b, 0);
            const hour = Math.floor(totalMinutes / 60);
            const minute = ("00" + (totalMinutes - hour * 60)).slice(-2);
            const calculatedNecessaryPoints = (totalMinutes * props.targetClass.pricePerHour) / 60;
            setTotalNecessaryPoints(calculatedNecessaryPoints);
            const generatedFormula = `${new Intl.NumberFormat("ja-JP").format(
                Math.floor(props.targetClass.pricePerHour),
            )}pt/時間 × ${hour}時間${minute}分`;
            setFormula(generatedFormula);
        }
    }, [props.targetClass]);

    const handleAddScheduleModalOpenButtonClick = () => {
        setAddScheduleModalOpen(true);
    };

    const handleAddScheduleModalClose = () => {
        setAddScheduleModalOpen(false);
    };

    const getJapaneseDay = (dayIdx: number) => {
        return WHAT_DAY_LIST[dayIdx];
    };

    const getDiffTime = (deadline: Date) => {
        const fixedDeadline = new Date(deadline);
        const now = new Date();
        if (fixedDeadline.getTime() <= now.getTime()) {
            return "";
        } else {
            const diffTime = fixedDeadline.getTime() - now.getTime();
            const dateNumber = 1000 * 24 * 60 * 60;
            const diffDate = Math.floor(diffTime / dateNumber);
            const diffHour = Math.floor((diffTime - dateNumber * diffDate) / (dateNumber / 24));
            if (diffDate === 0) {
                const diffMinute = Math.floor(
                    (diffTime - dateNumber * diffDate - (dateNumber / 24) * diffHour) / (dateNumber / (24 * 60)),
                );
                if (diffHour === 0) {
                    return `${diffMinute}分`;
                } else {
                    return `${diffHour}時間${diffMinute}分`;
                }
            } else {
                return `${diffDate}日と${diffHour}時間`;
            }
        }
    };

    const smUp = useMediaQuery("(min-width:600px)");

    return (
        <>
            {props.course.courseType === "short" && props.course.classes && (
                <ShortCourseContents
                    smUp={smUp}
                    studentId={props.studentId}
                    courseId={props.id}
                    title={props.course.title}
                    targetClass={props.targetClass}
                    howMuchLackOfPoints={props.howMuchLackOfPoints}
                    activeClasses={props.activeClasses}
                    isFavorite={props.isFavorite}
                    isHostTeacher={props.isHostTeacher}
                    fixedContentsEl={props.fixedContentsEl}
                    userMode={props.userMode}
                    addScheduleModalOpen={addScheduleModalOpen}
                    totalNecessaryPoints={totalNecessaryPoints}
                    formula={formula}
                    isReservationButtonClicked={props.isReservationButtonClicked}
                    thresholdOver={props.thresholdOver}
                    getJapaneseDay={getJapaneseDay}
                    getDiffTime={getDiffTime}
                    handleTargetClassChange={props.handleTargetClassChange}
                    handleChargeButtonClick={props.handleChargeButtonClick}
                    handleReservationButtonClick={props.handleReservationButtonClick}
                    handleAddScheduleModalOpenButtonClick={handleAddScheduleModalOpenButtonClick}
                    handleAddScheduleModalClose={handleAddScheduleModalClose}
                    handleClassButtonClick={props.handleClassButtonClick}
                    handleSignUpModalOpen={props.handleSignUpModalOpen}
                    initializeReservationButtonClicked={props.initializeReservationButtonClicked}
                />
            )}
            {props.course.courseType === "regular" && props.course.classes && props.course.teacher && (
                <RegularCourseContents
                    studentId={props.studentId}
                    course={props.course}
                    isFavorite={props.isFavorite}
                    isHostTeacher={props.isHostTeacher}
                    fixedContentsEl={props.fixedContentsEl}
                    userMode={props.userMode}
                    addScheduleModalOpen={addScheduleModalOpen}
                    privateRequestModalOpen={props.privateRequestModalOpen}
                    courseTeacherId={props.course.teacher.teacherId}
                    isReservationButtonClicked={props.isReservationButtonClicked}
                    thresholdOver={props.thresholdOver}
                    getJapaneseDay={getJapaneseDay}
                    getDiffTime={getDiffTime}
                    handleReservationButtonClick={props.handleReservationButtonClick}
                    handleAddScheduleModalOpenButtonClick={handleAddScheduleModalOpenButtonClick}
                    handleAddScheduleModalClose={handleAddScheduleModalClose}
                    handleClassButtonClick={props.handleClassButtonClick}
                    handlePrivateRequestModalClose={props.handlePrivateRequestModalClose}
                    handleSignUpModalOpen={props.handleSignUpModalOpen}
                    initializeReservationButtonClicked={props.initializeReservationButtonClicked}
                />
            )}
        </>
    );
});
