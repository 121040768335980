export const INQUIRY_LIST = [
    "ご要望・ご意見",
    "設定機能に関するお問い合わせ",
    "機能に関するお問い合わせ",
    "招待/退会に関するお問い合わせ",
    "バグ・エラーの報告",
    "広告掲載に関するお問い合わせ",
    "Zoom連携に関するお問い合わせ",
    "その他お問い合わせ",
];
