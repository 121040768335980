import { MuiThemeProvider, Tab, Tabs, createTheme } from "@material-ui/core";

import styles from "./index.module.scss";

import { memo } from "react";

interface Props {
    tabInfoList: TabInfo[];
    targetTabValue: string;
    handleTabChange: (e: React.ChangeEvent<Record<string, unknown>>, newValue: string) => void;
    children: React.ReactNode;
    style?: React.CSSProperties;
}

const theme = createTheme({
    overrides: {
        MuiTabs: {
            root: {
                color: "#305077",
                paddingLeft: 0,
                boxShadow: "none !important",
            },
            indicator: {
                backgroundColor: "#305077",
            },
        },
        MuiTab: {
            root: {
                opacity: 0.7,
                fontWeight: "bold",
                "&$selected": {
                    opacity: 1,
                    pointerEvents: "none",
                    color: "#305077",
                    "&:hover": {
                        color: "#305077",
                    },
                },
            },
        },
    },
});

export interface TabInfo {
    value: string;
    label: string;
}

export const TabsTemplate: React.VFC<Props> = memo(function MyCoursesContents(props) {
    return (
        <div className={styles.tabsTemplate}>
            <div className={styles.tabsWrapper} style={props.style}>
                <MuiThemeProvider theme={theme}>
                    <Tabs value={props.targetTabValue} onChange={props.handleTabChange} className={styles.tabs}>
                        {props.tabInfoList.map((tabInfo) => (
                            <Tab label={tabInfo.label} value={tabInfo.value} className={styles.tab} />
                        ))}
                    </Tabs>
                </MuiThemeProvider>
            </div>
            <div className={styles.tabContentsWrapper}>{props.children}</div>
        </div>
    );
});
