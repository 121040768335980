import { memo, useCallback } from "react";
import { Modal } from "@material-ui/core";
import { LessonResponse } from "@/store/autogenApi";
import styles from "./index.module.scss";
import { WhiteButton } from "@/components/Buttons/WhiteButton";
import { NavyButton } from "@/components/Buttons/NavyButton";
import { getLessonTime } from "@/utils/LessonUtils";

interface Props {
    joinMeetingModalOpen: boolean;
    targetLesson: LessonResponse;
    handleJoinMeetingModalClose: () => void;
}

export const JoinMeetingModal: React.VFC<Props> = memo(function JoinMeetingModal(props) {
    const handleCancelButtonClick = useCallback(() => {
        props.handleJoinMeetingModalClose();
    }, [props.handleJoinMeetingModalClose]);

    const handleJoinButtonClick = useCallback(() => {
        props.handleJoinMeetingModalClose();
        window.open(props.targetLesson.joinUrl, "_blank");
    }, []);
    return (
        <Modal open={props.joinMeetingModalOpen} onClose={props.handleJoinMeetingModalClose}>
            <div className={styles.joinMeetingModalContents}>
                <div className={styles.lessonTime}>{getLessonTime(props.targetLesson)}</div>
                <div className={styles.confirmDescription}>この授業に参加します。よろしいですか？</div>
                <div className={styles.buttonsWrapper}>
                    <WhiteButton isFlex handleClick={handleCancelButtonClick}>
                        キャンセル
                    </WhiteButton>
                    <NavyButton isFlex handleClick={handleJoinButtonClick}>
                        参加
                    </NavyButton>
                </div>
            </div>
        </Modal>
    );
});
