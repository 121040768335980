import { memo, useCallback } from "react";
import { Mandatory } from "@/components/Tag/Mandatory";
import styles from "@/pages/Teacher/InfoInput/index.module.scss";
import { EducationalBackgroundResponse } from "@/store/autogenApi";
import { BelongingConditions } from "../BelongingConditions";

interface Props {
    open?: boolean;
    checked: boolean;
    educationalBackground: EducationalBackgroundResponse;
    noUniversityBoxChecked: boolean;
    setEducationalBackground: React.Dispatch<React.SetStateAction<EducationalBackgroundResponse>>;
    handleNoUniversityCheckChange?: (checked: boolean) => void;
}

export const EducationalBackground: React.VFC<Props> = memo(function EducationalBackground(props) {
    const getBelongingCondition = useCallback(() => {
        const university = props.educationalBackground.university;
        if (university) {
            const faculty = props.educationalBackground.faculty;
            if (faculty) {
                const department = props.educationalBackground.department;
                if (department) {
                    return `${university}-${faculty}-${department}`;
                } else {
                    return `${university}-${faculty}`;
                }
            } else {
                return university;
            }
        } else {
            return "該当なし";
        }
    }, [props.educationalBackground]);
    return (
        <>
            <div className={styles.inputItem}>
                <div className={styles.leftWrapper}>
                    <div className={styles.itemTitleWrapper}>
                        <span className={styles.itemTitle}>在・卒大学</span>
                    </div>
                    {!props.open && <Mandatory />}
                </div>
                <div className={styles.rightWrapper}>
                    {props.open ? (
                        <div className={styles.openTrue}>{getBelongingCondition()}</div>
                    ) : (
                        <BelongingConditions
                            checked={props.checked}
                            educationalBackground={props.educationalBackground}
                            noUniversityBoxChecked={props.noUniversityBoxChecked}
                            setEducationalBackground={props.setEducationalBackground}
                            getBelongingCondition={getBelongingCondition}
                            handleNoUniversityCheckChange={props.handleNoUniversityCheckChange}
                        />
                    )}
                </div>
            </div>
        </>
    );
});
