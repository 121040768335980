import { memo, useCallback } from "react";
import { useState } from "react";

import { useSelector } from "react-redux";

import { ChatResponse } from "@/store/autogenApi";
import { RootState } from "@/ducks";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { ChatsContents, SocketInfo } from "./ChatsContents";
import { CircularProgress, useMediaQuery } from "@material-ui/core";

import styles from "./index.module.scss";
import { useGetTeacherChatsQueryState } from "@/store/hooks/chats";

interface Props {
    socketInfos: SocketInfo[];
    handleSocketInfosChange: (chats: ChatResponse[]) => void;
}

export const TeacherChatsContents: React.VFC<Props> = memo(function TeacherChatsContents(props) {
    const [previousChats, setPreviousChats] = useState<ChatResponse[]>([]);
    const [openedChatId, setOpenedChatId] = useState<string | undefined>(undefined);
    const [isProcessing, setIsProcessing] = useState(false);

    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);
    const teacherChatsQueryState = useGetTeacherChatsQueryState(teacherId);

    const mdUp = useMediaQuery("(min-width:960px)");

    const handleOpenedChatIdChange = useCallback((chatId: string | undefined) => {
        setOpenedChatId(chatId);
    }, []);

    const handlePreviousChatsChange = useCallback((targetChats: ChatResponse[]) => {
        setPreviousChats(targetChats);
    }, []);

    const handleIsProcessingChange = useCallback((isProcessing: boolean) => {
        setIsProcessing(isProcessing);
    }, []);

    return (
        <QueryLoadingWrapper
            {...teacherChatsQueryState}
            loadingComponent={
                previousChats.length === 0 ? (
                    <>
                        <div className={styles.messagesListWrapper}>
                            <div className={styles.circularProgress} style={{ left: mdUp ? "25%" : "50%" }}>
                                <CircularProgress />
                            </div>
                        </div>
                        <div className={styles.targetMessages} />
                    </>
                ) : (
                    <ChatsContents
                        isPrevious={true}
                        chats={previousChats}
                        previousChats={previousChats}
                        socketInfos={props.socketInfos}
                        openedChatId={openedChatId}
                        isProcessing={isProcessing}
                        handleIsProcessingChange={handleIsProcessingChange}
                        handlePreviousChatsChange={handlePreviousChatsChange}
                        handleSocketInfosChange={props.handleSocketInfosChange}
                        handleOpenedChatIdChange={handleOpenedChatIdChange}
                        refetch={teacherChatsQueryState.refetch}
                    />
                )
            }
        >
            {(chats) => (
                <ChatsContents
                    isPrevious={false}
                    chats={chats}
                    previousChats={previousChats}
                    socketInfos={props.socketInfos}
                    openedChatId={openedChatId}
                    isProcessing={isProcessing}
                    handleIsProcessingChange={handleIsProcessingChange}
                    handlePreviousChatsChange={handlePreviousChatsChange}
                    handleSocketInfosChange={props.handleSocketInfosChange}
                    handleOpenedChatIdChange={handleOpenedChatIdChange}
                    refetch={teacherChatsQueryState.refetch}
                />
            )}
        </QueryLoadingWrapper>
    );
});
