import { FAQConfig } from "@/FAQConfig";

export const MESSAGE_CONFIGS: FAQConfig[] = [
    {
        title: "グループチャット",
        text: "グループチャットは、日程ごとに設けられた、対象の講座の連絡など、講座予約後に講座に関するやりとりを行う場です。ここでのやりとりは他の参加者がいる場合、全員見ることができます。",
    },
    {
        title: "メッセージ",
        text: "メッセージは、先生と1対1でやりとりを行う場です。予約前の質問や他の参加者には見られたくない連絡など、個人的なやりとりを行います。",
    },
];
