import { Avatar, Button } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { useHistory, useParams } from "react-router";

import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import boy from "@/images/boy.jpg";
import girl from "@/images/girl.jpg";
import undefined from "@/images/undefined.jpg";
import { ReviewResponse } from "@/store/autogenApi";
import { useGetReviewsByCourseIdQuery } from "@/store/hooks/reviews";

import styles from "./index.module.scss";

export const Reviews: React.VFC = () => {
    const { courseId } = useParams<{ courseId: string }>();
    const reviewsQueryState = useGetReviewsByCourseIdQuery(courseId);
    const getSrc = (review: ReviewResponse) => {
        if (review.anonymous) {
            if (review.user?.gender === "male") {
                return boy;
            } else if (review.user?.gender === "female") {
                return girl;
            } else {
                return undefined;
            }
        } else {
            return review.student?.iconImageObjectKey;
        }
    };
    const history = useHistory();
    const handleCourseDetailsButtonClick = () => {
        history.push(`/CourseDetails/${courseId}`);
    };
    return (
        <div className={styles.reviewsBackgroundWrapper}>
            <QueryLoadingWrapper {...reviewsQueryState}>
                {(reviews) => (
                    <div className={styles.reviewsWrapper}>
                        <div className={styles.reviewsTitle}>
                            【{reviews[0]?.course?.title}】に対する評価一覧({reviews.length}件)
                        </div>
                        <ul className={styles.reviews}>
                            {reviews.map((review) => {
                                return (
                                    <li className={styles.review} key={review.reviewId}>
                                        <div className={styles.reviewerInfo}>
                                            {review.anonymous ? (
                                                <>
                                                    <Avatar className={styles.avatar} src={getSrc(review)} />
                                                    <div className={styles.gradeAndGender}>
                                                        {review.user?.grade}-{review.user?.gender}
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <Avatar className={styles.avatar} src={getSrc(review)} />
                                                    <div className={styles.gradeAndGender}>
                                                        {review.student?.nickname}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className={styles.reviewInfo}>
                                            <div className={styles.contentsWithFlexGrow}>
                                                <div className={styles.reviewContents}>
                                                    <Rating
                                                        name="half-rating-read"
                                                        defaultValue={review.score}
                                                        precision={0.5}
                                                        readOnly
                                                        className={styles.rating}
                                                        size={"small"}
                                                    />
                                                    <div className={styles.reviewTitle}>{review.title}</div>
                                                    <div className={styles.reviewText}>{review.comment}</div>
                                                </div>
                                                <div className={styles.flexGrow} />
                                            </div>
                                            <div
                                                className={styles.month}
                                            >{`評価対象期間：${review.evaluationPeriod}`}</div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                        <Button className={styles.courseDetailsButton} onClick={handleCourseDetailsButtonClick}>
                            講座詳細へ
                        </Button>
                    </div>
                )}
            </QueryLoadingWrapper>
        </div>
    );
};
