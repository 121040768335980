import { memo, useCallback, useState } from "react";
import { CourseType, ClassResponse } from "@/store/autogenApi";

import styles from "./index.module.scss";

import { getDaysAndTime } from "@/utils/BaseScheduleUtils";
import { getLessonTime, getNextLessonTimeFromClass } from "@/utils/LessonUtils";
import { getStatus } from "@/utils/ClassUtils";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { PlainButton } from "@/components/PlainButton";
import { TriangleDownIcon, TriangleUpIcon } from "@/globalIcons";
import { UpsertModal } from "./UpsertModal";
import { useSelector } from "react-redux";
import { useGetTeacherLessonsQuery } from "@/store/hooks/lessons";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { RootState } from "@/ducks";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    classes: ClassResponse[];
    courseType: CourseType;
    handleTargetClassChange: (classResponse: ClassResponse | undefined) => void;
}

export const ClassList: React.VFC<Props> = memo(function ClassList(props) {
    const [isUpsertModalOpened, setIsUpsertModalOpened] = useState(false);

    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);

    const existingLessonsQueryState = useGetTeacherLessonsQuery(teacherId);

    const handleAddButtonClick = useCallback(() => {
        setIsUpsertModalOpened(true);
    }, []);

    const handleUpsertModalClose = useCallback(() => {
        setIsUpsertModalOpened(false);
    }, []);

    return (
        <div className={styles.classesWrapper}>
            <div className={styles.classesTitleWrapper}>
                <AiOutlineUnorderedList className={styles.listIcon} />
                <div className={styles.classesTitle}>クラス一覧</div>
            </div>
            <ul className={styles.classes}>
                <li className={`${styles.class} ${styles.classTitle}`} key={0}>
                    <div className={styles.participantsWrapper}>
                        <div className={styles.participantsTitle}>参加者</div>
                    </div>
                    <div className={styles.statusWrapper}>
                        <div className={styles.statusTitle}>状態</div>
                    </div>
                    {props.courseType === "short" && (
                        <div className={styles.lessonsWrapper}>
                            <div className={styles.lessonsTitle}>授業日時</div>
                        </div>
                    )}
                    {props.courseType === "regular" && (
                        <>
                            <div className={styles.baseSchedulesWrapper}>
                                <div className={styles.baseSchedulesTitle}>基本スケジュール</div>
                            </div>
                            <div className={styles.nextLessonWrapper}>
                                <div className={styles.nextLesson}>次回授業</div>
                            </div>
                        </>
                    )}
                </li>
                {props.classes?.map((classResponse, idx) => {
                    return (
                        <li
                            className={styles.class}
                            key={idx + 1}
                            style={{ borderTop: "1px solid #CCC" }}
                            onClick={() => {
                                props.handleTargetClassChange(classResponse);
                            }}
                        >
                            <div className={styles.participantsWrapper}>
                                {classResponse.participants?.length === 0 ? (
                                    <div className={styles.noParticipants}>なし</div>
                                ) : (
                                    classResponse.participants?.map((participant) => {
                                        return (
                                            <AvatarFromS3
                                                key={participant.studentId}
                                                url={participant.iconImageUrl}
                                                objectKey={participant.iconImageObjectKey}
                                                className={styles.participantAvatar}
                                            />
                                        );
                                    })
                                )}
                            </div>
                            <div className={styles.statusWrapper}>
                                <div className={styles.status}>{getStatus(classResponse)}</div>
                            </div>
                            {props.courseType === "short" && (
                                <div className={styles.lessonsWrapper}>
                                    {classResponse.lessons?.length === 0 ? (
                                        <div className={styles.emptyLessons}>授業予定なし</div>
                                    ) : (
                                        <ul className={styles.lessons}>
                                            {classResponse.lessons?.map((lesson, idx) => (
                                                <li className={styles.lesson}>{`${idx + 1}回目: ${getLessonTime(
                                                    lesson,
                                                )}`}</li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            )}
                            {props.courseType === "regular" && (
                                <>
                                    <div className={styles.baseSchedulesWrapper}>
                                        {classResponse.baseSchedules && classResponse.baseSchedules.length > 0 ? (
                                            <div className={styles.baseSchedules}>
                                                {classResponse.baseSchedules.map((baseSchedule) => (
                                                    <div className={styles.dayTime} key={baseSchedule.baseScheduleId}>
                                                        {getDaysAndTime(baseSchedule)}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className={styles.baseSchedulesWrapper}>
                                                <div className={styles.noBaseSchedules}>なし</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles.nextLessonWrapper}>
                                        <div className={styles.nextLesson}>
                                            {getNextLessonTimeFromClass(classResponse)}
                                        </div>
                                    </div>
                                </>
                            )}
                        </li>
                    );
                })}
            </ul>
            <div className={styles.addButtonWrapper}>
                <PlainButton noHorizontalPadding isReverse text="クラスを追加" handleClick={handleAddButtonClick} />
            </div>
            <QueryLoadingWrapper {...existingLessonsQueryState}>
                {(existingLessons) => (
                    <>
                        {/* 閉じたときに初期化 */}
                        {isUpsertModalOpened && (
                            <UpsertModal
                                isUpsertModalOpened={isUpsertModalOpened}
                                courseType={props.courseType}
                                existingLessons={existingLessons}
                                handleUpsertModalClose={handleUpsertModalClose}
                            />
                        )}
                    </>
                )}
            </QueryLoadingWrapper>
        </div>
    );
});
