import { CreateTeacherRequestBody } from "@/store/autogenApi";

type TeacherConfig = Partial<Record<keyof CreateTeacherRequestBody, number>>;

export const teacherConfig: TeacherConfig = {
    nickname: 30,
    catchCopy: 20,
    backgroundDescription: 2000,
    contentDescription: 2000,
};
