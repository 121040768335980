import { FAQDetailsBase } from "../../FAQDetailsBase";

export const Contact: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="Trailに関するお問い合わせ"
            text="Trailに関するお問い合わせは、下記よりお願いいたします。"
            urlInfos={[
                {
                    url: "/Inquiry",
                    word: "お問い合わせ",
                },
            ]}
        />
    );
};
