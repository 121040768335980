import { useState, useEffect, memo } from "react";
import { Button } from "@material-ui/core";
import styles from "@/pages/Common/CourseDetails/index.module.scss";

interface Props {
    detail: string;
}

export const CourseDetail: React.VFC<Props> = memo(function CourseDetail({ detail }) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOver, setIsOver] = useState<boolean>(false);

    const showMore = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const detailNode = document.getElementById("detail");
        const scrollHeight = detailNode?.scrollHeight;
        if (scrollHeight && scrollHeight > 200) {
            setIsOver(true);
        }
    }, [detail]);

    return (
        <div className={styles.itemWrapper}>
            <div className={styles.itemHeadingWrapper}>
                <span className={styles.itemHeading}>こんなことを学べます</span>
            </div>
            <div
                className={styles.item}
                style={{ overflowY: isOpen ? "auto" : "hidden", maxHeight: isOpen ? "none" : "200px" }}
                id="detail"
            >
                {detail}
            </div>
            {isOver && (
                <div className={styles.moreButtonWrapper} style={{ position: isOpen ? "inherit" : "absolute" }}>
                    <Button className={styles.moreButton} onClick={showMore}>
                        {isOpen ? "閉じる" : "すべて見る"}
                    </Button>
                </div>
            )}
        </div>
    );
});
