import { FAQDetailsBase } from "../../FAQDetailsBase";

import { SEARCH_COURSE_CONFIGS } from "./SearchCourseConfigs";

export const SearchCourse: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="講座を探す"
            text="このページでは、講座を探す手順についてご説明します"
            FAQConfigs={SEARCH_COURSE_CONFIGS}
        />
    );
};
