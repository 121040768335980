import { useSelector } from "react-redux";

import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";

import { MyPage } from ".";
import { RootState } from "@/ducks";
import { useGetTeacherLessonsQuery } from "@/store/hooks/lessons";
import { LessonCalendarContents } from "@/components/LessonCalendar";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { useEffect } from "react";
import { Navigation } from "@/components/Navigation";

const NAVIGATION_LIST: Navigation[] = [
    { title: "マイページ", url: "/Teacher/MyPage/Profile" },
    { title: "授業カレンダー", url: "/Teacher/MyPage/LessonCalendar" },
];

export const TeacherLessonCalendar: React.VFC = () => {
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);

    const lessonsQuery = useGetTeacherLessonsQuery(teacherId);

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    return (
        <MyPage title="授業カレンダー" activeTab="lessonCalendar">
            <QueryLoadingWrapper {...lessonsQuery}>
                {(lessons) => <LessonCalendarContents lessons={lessons} isDraggable={false} />}
            </QueryLoadingWrapper>
        </MyPage>
    );
};
