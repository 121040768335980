import { memo } from "react";
import { Mandatory } from "@/components/Tag/Mandatory";
import styles from "@/pages/Teacher/IdentificationForStripe/index.module.scss";
import { PictureUpload } from "../PictureUpload";

interface Props {
    url: string;
    addPhoto: (e: React.ChangeEvent<HTMLInputElement>) => void;
    deletePhoto: (buttonName: string) => void;
    checked: boolean;
}

export const IdCard: React.VFC<Props> = memo(function IdCard(props) {
    return (
        <div className={styles.formItem} style={{ borderBottom: "none" }}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitle}>身分証</div>
                <Mandatory />
            </div>
            <div className={styles.rightWrapper}>
                <section className={styles.message}>※提示可能な本人確認書類 </section>
                <ol className={styles.smallItems}>
                    <li className={styles.smallItem}>a) 運転免許証</li>
                    <li className={styles.smallItem}>b) パスポート</li>
                    <li className={styles.smallItem}>c) マイナンバーカード（顔写真付き）</li>
                    <li className={styles.smallItem}>d) 住民票</li>
                    <li className={styles.smallItem}>e) 在留カード</li>
                    <li className={styles.smallItem}>f) 特別永住者証明書</li>
                </ol>
                <PictureUpload
                    addPhoto={props.addPhoto}
                    deletePhoto={props.deletePhoto}
                    url={props.url}
                    name="idCard"
                    checked={props.checked}
                    mandatory
                />
            </div>
        </div>
    );
});
