export const GRADE_LIST_FOR_STUDENT = [
    "小学1年",
    "小学2年",
    "小学3年",
    "小学4年",
    "小学5年",
    "小学6年",
    "中学1年",
    "中学2年",
    "中学3年",
    "高校1年",
    "高校2年",
    "高校3年",
    "大学1年",
    "大学2年",
    "大学3年",
    "大学4年",
    "大学5年",
    "大学6年",
    "大学院1年",
    "大学院2年",
    "大学院3年",
    "社会人",
    "その他",
];
