import { useSelector } from "react-redux";

import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { useGetMyDraftCoursesQuery } from "@/store/hooks/draftCourses";

import { MyDraftCoursesContents } from "@/pageComponents/Teacher/MyPage/MyDraftCourses";

import { MyPage } from ".";
import { RootState } from "@/ducks";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { useEffect } from "react";
import { Navigation } from "@/components/Navigation";

const NAVIGATION_LIST: Navigation[] = [
    { title: "マイページ", url: "/Teacher/MyPage/Profile" },
    { title: "下書き講座一覧", url: "/Teacher/MyPage/MyDraftCourses" },
];

export const MyDraftCourses: React.VFC = () => {
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);

    const draftCoursesQueryState = useGetMyDraftCoursesQuery(teacherId);

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    return (
        <MyPage title="下書き講座一覧" activeTab="myDraftCourses">
            <QueryLoadingWrapper {...draftCoursesQueryState}>
                {(draftCourses) => <MyDraftCoursesContents draftCourses={draftCourses} />}
            </QueryLoadingWrapper>
        </MyPage>
    );
};
