import { FAQDetailsBase } from "../../FAQDetailsBase";

export const ReleaseInfo202404: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="2024年4月リリース情報"
            text="【学び】のフリマアプリ Trailは、2024年4月23日にリリースされました。利用者の皆様により良いサービスを提供するため、様々な機能を追加していきますので、今後ともよろしくお願いいたします。"
        />
    );
};
