import { MenuItem, Select } from "@material-ui/core";
import { memo, useCallback } from "react";
import { Mandatory } from "@/components/Tag/Mandatory";

import styles from "@/pages/Student/CreatePublicRequest/index.module.scss";
import { PriceRange } from "../Functional/Common";
import { DefaultSelect } from "@/components/DefaultSelect";

interface Props {
    open?: boolean;
    courseType: string;
    checked: boolean;
    minPrice: number;
    maxPrice: number;
    validation: boolean;
    handlePriceChange: (range: PriceRange) => void;
}

export const PriceComponent: React.VFC<Props> = memo(function PriceComponent(props) {
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 200,
                width: "fit-content",
                overFlow: "scroll",
                color: "#305077",
            },
        },
        anchorOrigin: {
            vertical: "bottom" as number | "bottom" | "top" | "center",
            horizontal: "left" as number | "center" | "left" | "right",
        },
        getContentAnchorEl: null,
    };

    const getUnit = useCallback(() => {
        if (props.courseType === "short") {
            return "講座全体で";
        } else if (props.courseType === "regular") {
            return "1時間あたり";
        }
    }, [props.courseType]);

    const getPrice = useCallback((idx: number) => {
        const priceRange = PRICE_RANGE_ARRAY[idx];
        return priceRange.map((value) => value.toString());
    }, []);

    const PRICE_RANGE_ARRAY = [
        [2000, 2500],
        [2500, 3000],
        [3000, 4000],
        [4000, 5000],
        [5000, 6000],
        [6000, 7000],
        [7000, 10000],
        [10000, 15000],
        [15000, 20000],
        [20000, 25000],
        [25000, 30000],
        [30000, 35000],
        [35000, 40000],
        [40000, 45000],
        [45000, 50000],
        [50000, 55000],
        [55000, 60000],
        [60000, 65000],
        [65000, 70000],
        [70000, 75000],
        [75000, 80000],
        [80000, 85000],
        [85000, 90000],
        [90000, 95000],
        [95000, 100000],
    ];

    const getPriceRange = useCallback((idx: number) => {
        const priceRange = `¥${PRICE_RANGE_ARRAY[idx][0]} ~ ¥${PRICE_RANGE_ARRAY[idx][1]}`;
        return priceRange;
    }, []);

    const handleChange = useCallback(
        (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
            const rangeString = e.target.value as string;
            const array = JSON.parse(`[${rangeString}]`) as number[];
            const priceRange = {
                min: array[0],
                max: array[1],
            };
            props.handlePriceChange(priceRange);
        },
        [props.handlePriceChange],
    );

    return (
        <li className={styles.listItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>{`希望価格(${getUnit()})`}</div>
                    {!props.open && <Mandatory />}
                </div>
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>
                        <div className={styles.perHour}>
                            <div className={styles.price}>￥{props.minPrice}</div>
                            <div className={styles.price}>~</div>
                            <div className={styles.price}>￥{props.maxPrice}</div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className={styles.priceWrapper}>
                            <div className={styles.price}>
                                <div className={styles.selectWrapper}>
                                    <DefaultSelect
                                        name="applicationPeriod"
                                        value={[props.minPrice, props.maxPrice].toString()}
                                        handleChange={handleChange}
                                        options={[
                                            {
                                                value: "0,0",
                                                label: "",
                                            },
                                        ].concat(
                                            PRICE_RANGE_ARRAY.map((priceRange, i) => ({
                                                value: priceRange.toString(),
                                                label: `¥${priceRange[0]} ~ ¥${priceRange[1]}`,
                                            })),
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        {props.checked && !props.validation && (
                            <div className={styles.helperText}>この項目は必須です。選択してください。</div>
                        )}
                    </>
                )}
            </div>
        </li>
    );
});
