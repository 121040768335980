import { CreateLessonRequestParams, LessonResponse, UpdateLessonRequestBody, ClassResponse } from "@/store/autogenApi";
import { WHAT_DAY_LIST } from "./WhatDayList";

export const getLessonTime = (lesson: LessonResponse | CreateLessonRequestParams) => {
    const startTime = lesson.startTime;
    const endTime = lesson.endTime;
    if (startTime && endTime) {
        const modifiedStartTime = new Date(startTime);
        const modifiedEndTime = new Date(endTime);
        const month = modifiedStartTime.getMonth() + 1;
        const date = modifiedStartTime.getDate();
        const day = WHAT_DAY_LIST[modifiedStartTime.getDay()];
        const startHour = ("00" + modifiedStartTime.getHours()).slice(-2);
        const startMinute = ("00" + modifiedStartTime.getMinutes()).slice(-2);
        const finishHour = ("00" + modifiedEndTime.getHours()).slice(-2);
        const finishMinute = ("00" + modifiedEndTime.getMinutes()).slice(-2);
        return `${month}月${date}日(${day}) ${startHour}:${startMinute}~${finishHour}:${finishMinute}`;
    }
};

export const getLessonTimeOnly = (lesson: LessonResponse | CreateLessonRequestParams) => {
    const startTime = lesson.startTime;
    const endTime = lesson.endTime;
    if (startTime && endTime) {
        const modifiedStartTime = new Date(startTime);
        const modifiedEndTime = new Date(endTime);
        const startHour = ("00" + modifiedStartTime.getHours()).slice(-2);
        const startMinute = ("00" + modifiedStartTime.getMinutes()).slice(-2);
        const finishHour = ("00" + modifiedEndTime.getHours()).slice(-2);
        const finishMinute = ("00" + modifiedEndTime.getMinutes()).slice(-2);
        return `${startHour}:${startMinute} ~ ${finishHour}:${finishMinute}`;
    }
};

export const getNextLessonTimeFromClass = (classResponse: ClassResponse | undefined) => {
    if (!classResponse) return "授業予定なし";
    const now = new Date();
    const nextLesson = classResponse.lessons?.find((lesson) => {
        const endTime = new Date(lesson.endTime);
        return endTime > now;
    });
    if (!nextLesson) return "授業予定なし";
    const startTime = new Date(nextLesson.startTime);
    const endTime = new Date(nextLesson.endTime);
    const startMonth = ("00" + (startTime.getMonth() + 1)).slice(-2);
    const startDate = ("00" + startTime.getDate()).slice(-2);
    const startDay = WHAT_DAY_LIST[startTime.getDay()];
    const startHour = ("00" + startTime.getHours()).slice(-2);
    const startMinute = ("00" + startTime.getMinutes()).slice(-2);
    const finishHour = ("00" + endTime.getHours()).slice(-2);
    const finishMinute = ("00" + endTime.getMinutes()).slice(-2);
    return `${startMonth}/${startDate}(${startDay}) ${startHour}:${startMinute}~${finishHour}:${finishMinute}`;
};

export const getNextLessonFromClass = (classResponse: ClassResponse | undefined): LessonResponse | undefined => {
    if (!classResponse) return undefined;
    const now = new Date();
    const nextLesson = classResponse.lessons?.find((lesson) => {
        const endTime = new Date(lesson.endTime);
        return endTime > now;
    });
    if (!nextLesson) return undefined;
    return nextLesson;
};

export const getLessonPeriod = (startTime: Date | undefined, endTime: Date | undefined) => {
    if (!startTime || !endTime) return "";
    const startMonth = ("00" + (startTime.getMonth() + 1)).slice(-2);
    const startDate = ("00" + startTime.getDate()).slice(-2);
    const startDay = WHAT_DAY_LIST[startTime.getDay()];
    const startHour = ("00" + startTime.getHours()).slice(-2);
    const startMinute = ("00" + startTime.getMinutes()).slice(-2);
    const finishHour = ("00" + endTime.getHours()).slice(-2);
    const finishMinute = ("00" + endTime.getMinutes()).slice(-2);
    return `${startMonth}/${startDate}(${startDay}) ${startHour}:${startMinute}~${finishHour}:${finishMinute}`;
};

export const getExistLessonConflict = (
    targetLessons: (CreateLessonRequestParams | UpdateLessonRequestBody)[],
    existingLessons: (LessonResponse | CreateLessonRequestParams)[],
) => {
    // 授業時間に被りがあるかどうかを判定する
    const exist = targetLessons.some((targetLesson) => {
        const targetStartTime = new Date(targetLesson.startTime);
        const targetEndTime = new Date(targetLesson.endTime);
        const targetExist = existingLessons.some((existLesson) => {
            const existingStartTime = new Date(existLesson.startTime);
            const existingEndTime = new Date(existLesson.endTime);
            return (
                // 開始時間が既存の授業時間内にあるか
                (existingStartTime <= targetStartTime && targetStartTime < existingEndTime) ||
                // 終了時間が既存の授業時間内にあるか
                (existingStartTime < targetEndTime && targetEndTime <= existingEndTime) ||
                // 既存の授業時間が新規授業時間内にあるか
                (targetStartTime <= existingStartTime && existingEndTime <= targetEndTime)
            );
        });
        return targetExist;
    });
    return exist;
};

export const getExistLessonConflictWithinLessons = (lessons: LessonResponse[] | CreateLessonRequestParams[]) => {
    // 授業時間に被りがあるかどうかを判定する
    const exist = lessons.some((targetLesson, idx1) => {
        const targetStartTime = new Date(targetLesson.startTime);
        const targetEndTime = new Date(targetLesson.endTime);
        const targetExist = lessons.some((existLesson, idx2) => {
            if (idx1 === idx2) return false;
            const existingStartTime = new Date(existLesson.startTime);
            const existingEndTime = new Date(existLesson.endTime);
            return (
                // 開始時間が既存の授業時間内にあるか
                (existingStartTime <= targetStartTime && targetStartTime <= existingEndTime) ||
                // 終了時間が既存の授業時間内にあるか
                (existingStartTime <= targetEndTime && targetEndTime <= existingEndTime) ||
                // 既存の授業時間が新規授業時間内にあるか
                (targetStartTime <= existingStartTime && existingEndTime <= targetEndTime)
            );
        });
        return targetExist;
    });
    return exist;
};

export const getSortedAscLessons = (lessons: LessonResponse[] | CreateLessonRequestParams[]) => {
    // 授業時間の昇順にソートする
    // 元の配列を変更しないようにスプレッド構文でコピーする
    const sortedLessons = [...lessons];
    sortedLessons.sort((a, b) => {
        const aStartTime = new Date(a.startTime);
        const bStartTime = new Date(b.startTime);
        return aStartTime.getTime() - bStartTime.getTime();
    });
    return sortedLessons;
};
