import { FAQConfig } from "@/FAQConfig";

export const POST_REVIEW_CONFIGS: FAQConfig[] = [
    {
        title: "やることリストへの追加",
        text: "全授業終了後に。やることリストへ「レビューの投稿」が追加されるので、これを押します。",
    },
    {
        title: "レビュー内容の入力",
        text: "入力フォームが現れるので、レビュー内容を入力し、「確認」ボタンを押します。",
    },
    {
        title: "確定",
        text: "内容を確認し、よろしければ「確定」ボタンを押します。これでレビューの投稿が完了します。",
    },
];
