import { Button, useMediaQuery } from "@material-ui/core";
import { memo } from "react";
import styles from "@/pages/Common/PublicRequestDetails/index.module.scss";
import { UserType } from "@/protectedRouter/ProtectedRouterConfig";
import { PrivateUserResponse, ProposalResponse, PublicStudentResponse, ZoomAccountStatus } from "@/store/autogenApi";
import { LeftTime } from "./index";
import { Favorite } from "./Favorite";
import { LeftTimeModal } from "./LeftTimeModal";
import { PCTeacherContents } from "./PCTeacherContents";

interface Props {
    user: PrivateUserResponse;
    proposals: ProposalResponse[];
    leftTime: LeftTime | undefined;
    userMode: UserType;
    publicRequestId: string;
    student: PublicStudentResponse;
    leftTimeModalOpen: boolean;
    proposalModalOpen: boolean;
    checked: boolean;
    chatModalOpen: boolean;
    courseType: string;
    applyingDeadline: Date;
    fixedContentsEl: React.MutableRefObject<HTMLElement | null>;
    myProposalModalOpen: boolean;
    title: string;
    appliedModalOpen: boolean;
    isWithdrawalMode: boolean;
    targetProposal: ProposalResponse | undefined;
    pleaseConnectZoomModalOpen: boolean;
    handleUpdateProposalButtonClick: (proposal: ProposalResponse) => void;
    handleAppliedModalClose: () => void;
    handleAppliedModalOpen: () => void;
    handleMyProposalModalClose: () => void;
    handleMyProposalModalOpen: () => void;
    handleLeftTimeModalClose: () => void;
    handleLeftTimeModalOpen: () => void;
    handleChatModalOpen: (teacherId: string) => void;
    handleChatModalClose: () => void;
    getLeftTime: () => string;
    getDetailLeftTime: () => string;
    setChecked: React.Dispatch<React.SetStateAction<boolean>>;
    handleEditButtonClick: (params: string) => void;
    handleCourseManagementButtonClick: (proposal: ProposalResponse) => void;
    handleWithdrawButtonClick: () => void;
    handleConfirmWithdrawButtonClick: (proposalId: string) => void;
    handleProposalModalOpen: (zoomAccountStatus: ZoomAccountStatus) => void;
    handleProposalModalClose: () => void;
    handlePleaseLinkZoomModalClose: () => void;
}

export const PCContents: React.VFC<Props> = memo(function PCContents(props) {
    const upperThanSmall = useMediaQuery("(min-width:600px)");
    return (
        <div
            className={styles.fixedContents}
            id="fixedContents"
            ref={(node: HTMLElement | null) => {
                props.fixedContentsEl.current = node;
            }}
        >
            <div
                className={styles.reservationWrapper}
                style={{
                    borderRight: upperThanSmall ? "" : props.userMode === "student" ? "none" : "1px solid #999",
                }}
            >
                <div className={styles.howManyTeacherAndTime}>
                    <div className={styles.howManyTeacherWrapper}>
                        <div className={styles.howManyTeacherTitle}>提案件数</div>
                        <div className={styles.howManyTeacherValue}>{props.proposals?.length}件</div>
                    </div>
                    <div className={styles.timeWrapper}>
                        <div className={styles.timeTitle}>締め切りまでの残り時間</div>
                        <div className={styles.timeValue}>
                            {props.getLeftTime()}
                            <Button className={styles.DetailLeftTimeButton} onClick={props.handleLeftTimeModalOpen}>
                                （<span className={styles.text}>残り時間の詳細</span>）
                            </Button>
                        </div>
                        <LeftTimeModal
                            leftTime={props.leftTime}
                            leftTimeModalOpen={props.leftTimeModalOpen}
                            handleLeftTimeModalClose={props.handleLeftTimeModalClose}
                        />
                    </div>
                </div>
                {props.userMode == "teacher" && (
                    <PCTeacherContents
                        user={props.user}
                        proposals={props.proposals}
                        leftTime={props.leftTime}
                        publicRequestId={props.publicRequestId}
                        student={props.student}
                        proposalModalOpen={props.proposalModalOpen}
                        courseType={props.courseType}
                        applyingDeadline={props.applyingDeadline}
                        myProposalModalOpen={props.myProposalModalOpen}
                        isWithdrawalMode={props.isWithdrawalMode}
                        targetProposal={props.targetProposal}
                        pleaseConnectZoomModalOpen={props.pleaseConnectZoomModalOpen}
                        chatModalOpen={props.chatModalOpen}
                        handleUpdateProposalButtonClick={props.handleUpdateProposalButtonClick}
                        handleMyProposalModalClose={props.handleMyProposalModalClose}
                        handleMyProposalModalOpen={props.handleMyProposalModalOpen}
                        handleChatModalOpen={props.handleChatModalOpen}
                        handleChatModalClose={props.handleChatModalClose}
                        handleCourseManagementButtonClick={props.handleCourseManagementButtonClick}
                        handleWithdrawButtonClick={props.handleWithdrawButtonClick}
                        handleConfirmWithdrawButtonClick={props.handleConfirmWithdrawButtonClick}
                        handleProposalModalOpen={props.handleProposalModalOpen}
                        handleProposalModalClose={props.handleProposalModalClose}
                        handlePleaseLinkZoomModalClose={props.handlePleaseLinkZoomModalClose}
                    />
                )}
                {props.userMode === "student" && props.user.studentId === props.student.studentId && (
                    <div className={styles.editWrapper}>
                        {props.proposals.length === 0 ? (
                            <div className={styles.canEditWrapper}>
                                <div className={styles.canEdit}>提案がまだ届いていないため、編集が可能です。</div>
                                <Button
                                    className={styles.editButton}
                                    onClick={() => {
                                        props.handleEditButtonClick(props.publicRequestId);
                                    }}
                                >
                                    編集する
                                </Button>
                            </div>
                        ) : (
                            <div className={styles.cannotEditWrapper}>
                                <div className={styles.cannotEdit}>
                                    提案が既に届いているため、編集を行うことはできません。
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <Favorite userMode={props.userMode} publicRequestId={props.publicRequestId} />
        </div>
    );
});
