import { Avatar } from "@material-ui/core";
import { PublicStudentResponse } from "@/store/autogenApi";
import { ListItem } from "./ListItem";

import styles from "./index.module.scss";
import { StudentDetailsButton } from "../../../../StudentDetailsButton";

interface Props {
    student: PublicStudentResponse | undefined;
}

export const StudentInfo: React.VFC<Props> = (props) => {
    return (
        <ListItem title="担当する生徒" borderBottom>
            <>{props.student && <StudentDetailsButton student={props.student} />}</>
        </ListItem>
    );
};
