import { FAQDetailsBase } from "../../FAQDetailsBase";

import { COURSE_TYPE_CONFIGS } from "./CourseType";

export const CourseType: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="Trailではどのような講座を開催することができますか？"
            text="Trailでは講座内容・日時・金額について先生自身が自由に設定することができます。あなたの得意や都合に合わせて講座を作成しましょう。なお、講座の種類は以下の2種類が存在します。用途にあった講座の種類を選択してください。"
            list={["単発・短期講座", "定期講座"]}
            FAQConfigs={COURSE_TYPE_CONFIGS}
        />
    );
};
