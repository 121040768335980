import { Button } from "@material-ui/core";
import { memo, useEffect, useState } from "react";
import { CourseResponse, PublicTeacherResponse, StudentToDoResponse } from "@/store/autogenApi";
import styles from "../index.module.scss";
import { TargetCourse } from "./TargetCourse";
import { Period } from "./Period";
import { TeacherInfo } from "./TeacherInfo";
import { MonthlyFee } from "./MonthlyFee";
import { StartDate } from "./StartDate";

interface Props {
    maxModalHeight: number;
    targetStudentToDo: StudentToDoResponse;
    handleApproveButtonClick: () => void;
    handleDisapproveButtonClick: () => void;
}

export const UpdateFeeInfo: React.VFC<Props> = memo(function UpdateFeeInfo(props) {
    const [course, setCourse] = useState<CourseResponse | undefined>(undefined);
    const [teacher, setTeacher] = useState<PublicTeacherResponse | undefined>(undefined);
    const [monthlyFee, setMonthlyFee] = useState<number>(0);
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);

    useEffect(() => {
        if (props.targetStudentToDo.toDoType === "approveUpdateMonthlyFee") {
            const subscription = props.targetStudentToDo.subscription;
            if (!subscription) return;
            if (!subscription.newStartDate) return;
            setCourse(subscription.class?.course);
            setTeacher(props.targetStudentToDo.teacher);
            setMonthlyFee(subscription.askingAmount ?? 0);
            setStartDate(new Date(subscription.newStartDate));
        } else if (props.targetStudentToDo.toDoType === "approveTemporaryUpdateMonthlyFee") {
            const subscriptionSchedule = props.targetStudentToDo.subscriptionSchedule;
            if (!subscriptionSchedule) return;
            const subscription = subscriptionSchedule.subscription;
            if (!subscription) return;
            setCourse(subscription.class?.course);
            setTeacher(props.targetStudentToDo.teacher);
            setMonthlyFee(subscriptionSchedule.amount ?? 0);
            setStartDate(new Date(subscriptionSchedule.startDate));
            setEndDate(new Date(subscriptionSchedule.endDate));
        }
    }, [props.targetStudentToDo]);

    return (
        <>
            <div className={styles.middleWrapper} style={{ maxHeight: props.maxModalHeight }}>
                <div className={styles.targetCourseWrapper}>
                    <div className={styles.report}>
                        受講中の講座に対して月額更新申請が届きました。この申請を受け入れる場合は「承認」、そうでない場合は「理由を入力して非承認」をクリックしてください。それぞれ確認ステップに進みます。
                    </div>
                    <ul className={styles.itemList}>
                        <TargetCourse course={course} />
                        <TeacherInfo teacher={teacher} />
                        <MonthlyFee
                            monthlyFee={monthlyFee}
                            borderBottom={["approveUpdateMonthlyFee", "approveTemporaryUpdateMonthlyFee"].includes(
                                props.targetStudentToDo.toDoType,
                            )}
                        />
                        {props.targetStudentToDo.toDoType === "approveUpdateMonthlyFee" && (
                            <StartDate startDate={startDate} />
                        )}
                        {props.targetStudentToDo.toDoType === "approveTemporaryUpdateMonthlyFee" && (
                            <Period startDate={startDate} endDate={endDate} />
                        )}
                    </ul>
                </div>
            </div>
            <div className={styles.modalFooter}>
                <Button className={styles.disapproveButton} onClick={props.handleDisapproveButtonClick}>
                    非承認
                </Button>
                <Button className={styles.approveButton} onClick={props.handleApproveButtonClick}>
                    承認
                </Button>
            </div>
        </>
    );
});
