import { useEffect } from "react";

import { useCreateStandardAccountMutation } from "@/store/hooks/stripe";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";

export const CreateStripeAccount: React.VFC = () => {
    const userId = useSelector((state: RootState) => state.jwt.userId as string);

    const createStandardAccount = useCreateStandardAccountMutation();
    useEffect(() => {
        (async () => {
            const url = (await createStandardAccount({ userId })).data;
            if (url) {
                window.location.href = url;
            }
        })();
    }, []);
    return <div>Create Standard Account!</div>;
};
