import { FAQDetailsBase } from "../../FAQDetailsBase";

import { ABOUT_TEACHER_DETAILS_PAGE_CONFIGS } from "./SignUpAsTeacherConfigs";

export const AboutTeacherDetailsPage: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="先生詳細ページについて"
            text="先生詳細ページは、先生のプロフィールや講座情報を確認することができるページです。先生の情報を編集する場合は以下の手順で行うことができます。"
            FAQConfigs={ABOUT_TEACHER_DETAILS_PAGE_CONFIGS}
        />
    );
};
