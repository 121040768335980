import { useState, useEffect, memo, useCallback } from "react";

import { Button } from "@material-ui/core";

import styles from "@/pages/Common/PublicRequestDetails/index.module.scss";

interface Props {
    scheduleDescription: string | undefined;
}

export const ScheduleDescription: React.VFC<Props> = memo(function ScheduleDescription(props) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOver, setIsOver] = useState<boolean>(false);

    const [currentScheduleDescriptionEl, setCurrentScheduleDescriptionEl] = useState<HTMLElement | null>(null);

    const scheduleDescriptionRef = useCallback((BodyEl: HTMLElement | null) => {
        setCurrentScheduleDescriptionEl(BodyEl);
    }, []);

    const showMore = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (!currentScheduleDescriptionEl) return;
        const scrollHeight = currentScheduleDescriptionEl.scrollHeight;
        if (scrollHeight && scrollHeight > 200) {
            setIsOver(true);
        }
    }, [currentScheduleDescriptionEl]);

    return (
        <div className={styles.detailWrapper}>
            <div className={styles.detailHeadingWrapper}>
                <span className={styles.detailHeading}>希望日程</span>
            </div>
            <div
                className={styles.detail}
                style={{ overflowY: isOpen ? "auto" : "hidden", maxHeight: isOpen ? "none" : "200px" }}
                ref={scheduleDescriptionRef}
            >
                {props.scheduleDescription}
            </div>
            {isOver && (
                <div className={styles.buttonWrapper} style={{ position: isOpen ? "inherit" : "absolute" }}>
                    <Button className={styles.detailButton} onClick={showMore}>
                        {isOpen ? "閉じる" : "すべて見る"}
                    </Button>
                </div>
            )}
        </div>
    );
});
