import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { useGetLatestPublicRequestsForShortCourseQuery } from "@/store/hooks/publicRequests";

import { ExamplesContents } from "./ExamplesContents";

export const Examples: React.VFC = () => {
    const requestsQuery = useGetLatestPublicRequestsForShortCourseQuery(5);

    return (
        <QueryLoadingWrapper {...requestsQuery}>
            {(requests) => requests.length > 0 && <ExamplesContents requests={requests} />}
        </QueryLoadingWrapper>
    );
};
