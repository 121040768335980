import { memo } from "react";
import { Mandatory } from "@/components/Tag/Mandatory";
import styles from "@/pages/Student/CreatePublicRequest/index.module.scss";
interface Props {
    handleChange: (
        e: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>,
    ) => void;
    checked: boolean;
    value: string | undefined;
    open?: boolean;
    validation: boolean;
}

export const Title: React.VFC<Props> = memo(function Title(props) {
    return (
        <li className={styles.listItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>タイトル</div>
                    {!props.open && <Mandatory />}
                </div>
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>{props.value}</div>
                ) : (
                    <>
                        <input
                            type="text"
                            placeholder="タイトル(必須30字以内)"
                            className={styles.input}
                            onChange={props.handleChange}
                            name="title"
                            autoComplete="off"
                            value={props.value}
                        />
                        {props.checked && !props.validation && (
                            <div className={styles.helperText}>この項目は必須です。30字以内で入力してください。</div>
                        )}
                    </>
                )}
            </div>
        </li>
    );
});
