import { WHAT_DAY_LIST } from "./WhatDayList";

export interface HourAndMinute {
    hour: number | null;
    minute: number | null;
}

export const dateToString = (date: Date): string => {
    return date.toISOString();
};

export const dateToOnlyDateString = (date: Date | undefined): string => {
    if (!date) return "";
    const year = date.getFullYear();
    const month = ("00" + (date.getMonth() + 1)).slice(-2);
    const day = ("00" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
};

export const HOW_MANY_DAYS_IN_A_MONTH = 365 / 12;

export const getTime = (target: string | Date | undefined): string => {
    if (!target) return "";
    const date = new Date(target);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const whatDay = WHAT_DAY_LIST[date.getDay()];
    const hour = ("00" + date.getHours()).slice(-2);
    const minute = ("00" + date.getMinutes()).slice(-2);
    return `${month}月${day}日(${whatDay}) ${hour}:${minute}`;
};

export const getOnlyDate = (target: string | Date | undefined): string => {
    if (!target) return "";
    const date = new Date(target);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const whatDay = WHAT_DAY_LIST[date.getDay()];
    return `${month}月${day}日(${whatDay})`;
};

export const getIsTheSameDay = (date1: Date, date2: Date): boolean => {
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth();
    const day1 = date1.getDate();
    const year2 = date2.getFullYear();
    const month2 = date2.getMonth();
    const day2 = date2.getDate();
    return year1 === year2 && month1 === month2 && day1 === day2;
};

export const getPeriod = (date1: Date | undefined, date2: Date | undefined): string => {
    if (!date1 || !date2) return "";
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth() + 1;
    const day1 = date1.getDate();
    const year2 = date2.getFullYear();
    const month2 = date2.getMonth() + 1;
    const day2 = date2.getDate();
    return `${year1}年${month1}月${day1}日〜${year2}年${month2}月${day2}日`;
};
