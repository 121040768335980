import { memo, useCallback, useEffect, useRef, useState } from "react";

import { Hidden, useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import { LatestRegularCourses } from "@/pageComponents/Student/TopPage/CourseCards/LatestRegularCourses";
import { LatestShortCourses } from "@/pageComponents/Student/TopPage/CourseCards/LatestShortCourses";
import { SearchBySubject } from "@/components/SearchBySubject";
import styles from "./student.module.scss";
import { RootState } from "@/ducks";
import { LatestTeachers } from "@/pageComponents/Student/TopPage/TeacherCards/LatestTeachers";
import { NewsList } from "@/pageComponents/Common/TopPage/NewsList";
import { TopImageCarousel } from "@/pageComponents/Common/TopPage/TopImageCarousel";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { useHistory } from "react-router";
export const StudentTopPage: React.VFC = memo(function StudentTopPage() {
    const topPageContentsEl: React.MutableRefObject<HTMLElement | null | undefined> = useRef();

    const studentId = useSelector((state: RootState) => state.jwt.studentId);
    const userMode = useSelector((state: RootState) => state.jwt.userMode);
    const [articlesCarouselWrapperEl, setArticlesCarouselWrapperEl] = useState<HTMLElement | null>(null);
    const [repetitionNumber, setRepetitionNumber] = useState<number | undefined>(undefined);

    const threshold1 = useMediaQuery("(min-width:960px)");
    const threshold2 = useMediaQuery("(min-width:1120px)");
    const threshold3 = useMediaQuery("(min-width:1280px)");

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList([]);
    }, []);

    useEffect(() => {
        if (!threshold1) {
            setRepetitionNumber(undefined);
            return;
        }
        if (!threshold2) {
            setRepetitionNumber(14);
            return;
        }
        if (!threshold3) {
            setRepetitionNumber(14);
            return;
        }
        setRepetitionNumber(14);
    }, [threshold1, threshold2, threshold3]);

    const articlesCarouselWrapperRef = useCallback((el: HTMLElement | null) => {
        setArticlesCarouselWrapperEl(el);
    }, []);

    const history = useHistory();
    return (
        <div
            className={styles.studentTopPageContents}
            ref={(node: HTMLElement | null) => {
                topPageContentsEl.current = node;
            }}
        >
            <TopImageCarousel />
            <Hidden smDown>
                <div className={styles.contentsWrapper}>
                    <LatestTeachers repetitionNumber={repetitionNumber} />
                    {/* {userMode == "student" && (
                        <>
                            <RegularCourseHistory repetitionNumber={repetitionNumber} />
                            <ShortCourseHistory repetitionNumber={repetitionNumber} />
                        </>
                    )} */}
                    <LatestRegularCourses repetitionNumber={repetitionNumber} />
                    <LatestShortCourses repetitionNumber={repetitionNumber} />
                    {/* <ApproachingDeadlineRegularCourses repetitionNumber={repetitionNumber} />
                    <ApproachingDeadlineShortCourses repetitionNumber={repetitionNumber} /> */}
                    {/* {userMode == "student" && (
                        <>
                            <RecommendedRegularCourses repetitionNumber={repetitionNumber} />
                            <RecommendedShortCourses repetitionNumber={repetitionNumber} />
                        </>
                    )} */}
                    <SearchBySubject mode="student" />
                    <NewsList />
                </div>
            </Hidden>
            {/* スマホ・タブレット版 */}
            <Hidden mdUp>
                <div className={styles.contentsWrapper}>
                    <LatestTeachers repetitionNumber={repetitionNumber} />
                    {/* {userMode == "student" && (
                        <>
                            <ShortCourseHistory repetitionNumber={repetitionNumber} />
                            <RegularCourseHistory repetitionNumber={repetitionNumber} />
                        </>
                    )} */}
                    <LatestRegularCourses repetitionNumber={repetitionNumber} />
                    <LatestShortCourses repetitionNumber={repetitionNumber} />
                    {/* <ApproachingDeadlineRegularCourses repetitionNumber={repetitionNumber} />
                    <ApproachingDeadlineShortCourses repetitionNumber={repetitionNumber} /> */}
                    {/* {userMode == "student" && (
                        <>
                            <RecommendedRegularCourses repetitionNumber={repetitionNumber} />
                            <RecommendedShortCourses repetitionNumber={repetitionNumber} />
                        </>
                    )} */}
                    <SearchBySubject mode="student" />
                    <NewsList />
                </div>
            </Hidden>
        </div>
    );
});
