import { memo, useEffect, useState } from "react";
import styles from "../index.module.scss";
import { CourseType, ClassResponse, LessonResponse } from "@/store/autogenApi";
import { getNextLessonFromClass, getNextLessonTimeFromClass } from "@/utils/LessonUtils";

interface Props {
    courseType: CourseType;
    class: ClassResponse;
    disableToJoin: boolean;
}

export const NextLessonInfo: React.VFC<Props> = memo(function NextLessonInfo(props) {
    const [nextLesson, nextLessonTime] = useState<LessonResponse | undefined>(undefined);

    useEffect(() => {
        if (!props.class) return;
        const newNextLesson = getNextLessonFromClass(props.class);
        nextLessonTime(newNextLesson);
    }, [props.class]);

    return (
        <div className={styles.nextLessonInfoWrapper}>
            <div className={styles.nextLessonInfoTitle}>次回授業情報</div>
            <div className={styles.nextLessonInfoContents}>
                <div className={styles.timeWrapper}>
                    <div className={styles.timeTitle}>日時：</div>
                    <div className={styles.time}>{getNextLessonTimeFromClass(props.class)}</div>
                    {nextLesson && !props.disableToJoin && (
                        <a className={styles.start} href={nextLesson.joinUrl} target="_blank">
                            参加
                        </a>
                    )}
                </div>
                <div className={styles.memoWrapper}>
                    <div className={styles.memoTitle}>メモ：</div>
                    <div className={styles.memo}>{props.class.memo ?? "なし"}</div>
                </div>
            </div>
        </div>
    );
});
