import { memo, useCallback, useEffect, useState } from "react";

import {
    Button,
    CircularProgress,
    createTheme,
    Modal,
    MuiThemeProvider,
    Switch,
    TextareaAutosize,
    useMediaQuery,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";

import { CreateReviewRequestBody, StudentToDoResponse } from "@/store/autogenApi";
import { useCreateReviewMutation } from "@/store/hooks/reviews";

import styles from "./index.module.scss";
import { FormItem } from "../FormItem";
import { Form } from "../Form";
import { ErrorMessage } from "../ErrorMessage";
import { CloseIcon } from "@/globalIcons";
import { ButtonPair } from "../ButtonPair";
import { toast } from "react-toastify";
import { ToastContents } from "../Toast";
import { Processing } from "../Processing";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    activeStudentToDo: StudentToDoResponse;
    open: boolean;
    handleModalClose: () => void;
}

const theme = createTheme({
    overrides: {
        MuiSwitch: {
            switchBase: {},
            colorSecondary: {
                "&$checked": {
                    color: "#fafafa",
                    "& + .MuiSwitch-track": {
                        opacity: 1,
                        backgroundColor: "#305077",
                    },
                },
            },
        },
    },
});

export const EvaluationModal: React.VFC<Props> = memo(function EvaluationModal(props) {
    const [rating, setRating] = useState<number>(0);
    const [title, setTitle] = useState<string>("");
    const [comment, setComment] = useState<string>("");
    const [complete, setComplete] = useState<boolean>(false);
    const [switchChecked, setSwitchChecked] = useState(false);
    const [validationChecked, setValidationChecked] = useState(false);
    const [maxModalHeight, setMaxModalHeight] = useState<number>(0);
    const [isConfirmMode, setIsConfirmMode] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const createReview = useCreateReviewMutation();

    useEffect(() => {
        changeModalHeight();
        window.addEventListener("resize", () => {
            changeModalHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalHeight();
            });
        };
    }, []);

    const handleSwitchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSwitchChecked(event.target.checked);
    }, []);

    const handleChangeRating = useCallback((newValue: number) => {
        setRating(newValue);
    }, []);

    const handleTitleChange = useCallback((e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const value = e.target.value as string;
        setTitle(value);
    }, []);

    const handleCommentChange = useCallback((e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const value = e.target.value as string;
        setComment(value);
    }, []);

    const handleBackButtonClick = useCallback(() => {
        setIsConfirmMode(false);
    }, []);

    const handleConfirmButtonClick = useCallback(() => {
        setValidationChecked(true);
        if (title.length === 0) {
            return;
        }
        if (rating === 0) {
            return;
        }
        if (props.activeStudentToDo.toDoType === "overallEvaluation" && comment.length === 0) {
            return;
        }
        setIsConfirmMode(true);
    }, [title, rating, comment, props.activeStudentToDo.toDoType]);

    const handleSubmitButtonClick = useCallback(() => {
        (async () => {
            setIsProcessing(true);
            const { isSuccess } = await createReview({
                createReviewRequestBody: {
                    courseId: props.activeStudentToDo.class?.course?.courseId as string,
                    classId: props.activeStudentToDo.class?.classId as string,
                    studentId: props.activeStudentToDo.studentId,
                    teacherId: props.activeStudentToDo.teacher?.teacherId as string,
                    title: title,
                    score: rating,
                    comment: comment,
                    anonymous: switchChecked,
                    studentToDoId: props.activeStudentToDo.studentToDoId,
                    evaluationPeriod: props.activeStudentToDo.evaluationPeriod ?? "全期間",
                },
            });
            setIsProcessing(false);
            if (isSuccess) {
                toast(<ToastContents title={`評価送信完了`} isCompleted />);
            } else {
                toast(<ToastContents title={`評価送信失敗`} isFailed />);
            }
            props.handleModalClose();
        })();
    }, [title, rating, comment, switchChecked, props.activeStudentToDo, createReview, props.handleModalClose]);

    const changeModalHeight = useCallback(() => {
        const innerHeight = window.innerHeight;
        setMaxModalHeight(innerHeight * 0.75);
    }, []);

    const sm = useMediaQuery("(max-width:600px)");

    return (
        <Modal open={props.open} onClose={props.handleModalClose} className={styles.evaluationModal}>
            <div className={styles.evaluationModalContents} style={{ maxHeight: maxModalHeight }}>
                {complete ? (
                    <>
                        <div className={styles.completeMessage}>評価が完了しました。</div>
                        <div className={styles.closeButtonWrapper}>
                            <Button className={styles.closeButton} onClick={props.handleModalClose}>
                                閉じる
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.modalHeader}>
                            <div className={styles.modalHeaderContents}>
                                <button className={styles.closeButton} onClick={props.handleModalClose}>
                                    <CloseIcon />
                                </button>
                                <div className={styles.modalTitle}>授業の評価</div>
                            </div>
                        </div>
                        <div className={styles.middleWrapper} style={{ maxHeight: maxModalHeight - 200 }}>
                            {isConfirmMode ? (
                                <div className={styles.confirmModeContents}>
                                    <Form formDescription="下記の内容で評価を送信します。よろしいですか？">
                                        <FormItem
                                            itemTitle="匿名評価"
                                            isFirstItem
                                            itemDescription={switchChecked ? "ON" : "OFF"}
                                        />
                                        <FormItem itemTitle="評価のタイトル" itemDescription={title} />
                                        <FormItem itemTitle="評価点数">
                                            <Rating
                                                className={styles.rating}
                                                size={sm ? "small" : "medium"}
                                                value={rating}
                                                readOnly
                                            />
                                        </FormItem>
                                        <FormItem itemTitle="評価コメント" itemDescription={comment ?? "なし"} />
                                    </Form>
                                </div>
                            ) : (
                                <div className={styles.inputModeContents}>
                                    <Form formTitle="対象の授業">
                                        <FormItem
                                            itemTitle="講座名"
                                            itemDescription={props.activeStudentToDo.class?.course?.title}
                                            isFirstItem
                                        />
                                        <FormItem itemTitle="担当の先生">
                                            <div className={styles.targetLessonTeacher}>
                                                <AvatarFromS3
                                                    url={props.activeStudentToDo.teacher.iconImageUrl}
                                                    objectKey={props.activeStudentToDo.teacher.iconImageObjectKey}
                                                    className={styles.targetLessonTeacherIcon}
                                                />
                                                <div className={styles.targetLessonTeacherName}>
                                                    {props.activeStudentToDo.teacher?.nickname}先生
                                                </div>
                                            </div>
                                        </FormItem>
                                        <FormItem
                                            itemTitle="評価対象期間"
                                            itemDescription={props.activeStudentToDo.evaluationPeriod ?? "全期間"}
                                        />
                                    </Form>
                                    <Form
                                        formDescription={`${
                                            props.activeStudentToDo.toDoType === "regularEvaluation" ? "先月の" : ""
                                        }
                                授業に対する${
                                    props.activeStudentToDo.toDoType === "overallEvaluation" ? "総合" : ""
                                }評価をしてください。`}
                                    >
                                        <FormItem
                                            itemTitle="匿名評価"
                                            isMandatory
                                            itemDescription="匿名評価をONにするとニックネームとアイコンが非公開になります。"
                                            isFirstItem
                                        >
                                            <div className={styles.switchWrapper}>
                                                <div className={styles.switchText}>OFF</div>
                                                <MuiThemeProvider theme={theme}>
                                                    <Switch checked={switchChecked} onChange={handleSwitchChange} />
                                                </MuiThemeProvider>
                                                <div className={styles.switchText}>ON</div>
                                            </div>
                                        </FormItem>
                                        <FormItem itemTitle="評価のタイトル" isMandatory>
                                            <TextareaAutosize
                                                required
                                                placeholder={`タイトルを入力してください\n\n例) 物理の本質から理解できる講座`}
                                                className={styles.reviewTitleInput}
                                                name="comment"
                                                onChange={handleTitleChange}
                                                minRows={4}
                                                maxRows={15}
                                            />
                                            <ErrorMessage
                                                when={validationChecked && title.length === 0}
                                                content="タイトルを入力してください"
                                                style={{ padding: "0 10px" }}
                                            />
                                        </FormItem>
                                        <FormItem itemTitle="評価点数" isMandatory>
                                            <Rating
                                                className={styles.rating}
                                                onChange={(event, newValue) => {
                                                    newValue && handleChangeRating(newValue);
                                                }}
                                                value={rating}
                                                size={sm ? "small" : "medium"}
                                            />
                                            <ErrorMessage
                                                when={validationChecked && rating === 0}
                                                content="点数を入力してください"
                                                style={{ padding: "0 10px" }}
                                            />
                                        </FormItem>
                                        <FormItem
                                            itemTitle="評価コメント"
                                            isMandatory={
                                                props.activeStudentToDo.toDoType === "overallEvaluation"
                                                    ? true
                                                    : props.activeStudentToDo.toDoType === "regularEvaluation"
                                                    ? false
                                                    : undefined
                                            }
                                        >
                                            <TextareaAutosize
                                                required
                                                placeholder={`コメントを入力してください。\n\n例) この講座は〇〇で、〇〇に対しての苦手意識を克服することができました。具体的には、...`}
                                                className={styles.comment}
                                                name="comment"
                                                onChange={handleCommentChange}
                                                minRows={6}
                                                maxRows={15}
                                                style={
                                                    validationChecked && comment.length === 0
                                                        ? { borderBottom: "1px solid #CCC" }
                                                        : {}
                                                }
                                            />
                                            <ErrorMessage
                                                when={
                                                    validationChecked &&
                                                    comment.length === 0 &&
                                                    props.activeStudentToDo.toDoType === "overallEvaluation"
                                                }
                                                content="コメントを入力してください"
                                                style={{ padding: "0 10px" }}
                                            />
                                        </FormItem>
                                    </Form>
                                </div>
                            )}
                        </div>
                        <div className={styles.modalFooter}>
                            {isConfirmMode ? (
                                <ButtonPair
                                    pairType={1}
                                    leftButtonContents="戻る"
                                    rightButtonContents={isProcessing ? <Processing /> : "送信"}
                                    handleLeftButtonClick={handleBackButtonClick}
                                    handleRightButtonClick={handleSubmitButtonClick}
                                />
                            ) : (
                                <ButtonPair
                                    pairType={1}
                                    leftButtonContents="閉じる"
                                    rightButtonContents="確認"
                                    handleLeftButtonClick={props.handleModalClose}
                                    handleRightButtonClick={handleConfirmButtonClick}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
});
