import { useCallback, useState } from "react";

import { AppBar, Hidden } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Features } from "./Features";
import { Functions } from "./Functions";
import { TabPanel } from "./TabPanel";
import { TrailStudent } from "./TrailStudent";
import { OverWrapper } from "@/components/OverWrapper";
import useLocationChange from "@/components/UseLocationChange";
import { useHistory } from "react-router";
import { NavyButton } from "@/components/Buttons/NavyButton";
import styles from "@/pages/Common/AboutStudent/index.module.scss";

interface Props {
    userMode: string;
}

const theme = createTheme({
    overrides: {
        MuiTabs: {
            root: {
                color: "#305077",
            },
            indicator: {
                backgroundColor: "#305077",
            },
        },
        MuiTab: {
            root: {
                backgroundColor: "#F4F4F4",
                color: "#305077",
                fontWeight: "bold",
                "&:hover": {
                    backgroundColor: "#FFF",
                    color: "#305077",
                },
                "@media (max-width: 960px)": {
                    minWidth: "120px",
                },
                "@media (max-width: 600px)": {
                    minWidth: "80px",
                },
                "&$selected": {
                    backgroundColor: "#FFF",
                    color: "#305077",
                    "&:hover": {
                        backgroundColor: "#FFF",
                        color: "#305077",
                    },
                },
            },
            textColorInherit: {
                opacity: 1,
            },
        },
    },
});

export const TabsForAboutStudent: React.VFC<Props> = (props) => {
    const [tab, setTab] = useState<string>("background");

    useLocationChange((location) => {
        const tabQuery = new URLSearchParams(location.search).get("tab");
        if (!tabQuery) return;
        setTab(tabQuery);
    });

    const history = useHistory();
    const handleChange = (event: React.ChangeEvent<Record<string, never>>, newValue: string) => {
        history.push(`/AboutStudent?tab=${newValue}`);
    };

    const handleSignUpButtonClick = useCallback(() => {
        history.push("/SignUp");
    }, []);

    return (
        <MuiThemeProvider theme={theme}>
            {/* SP表示 */}
            <Hidden mdUp>
                <div style={{ marginTop: "20px" }}>
                    <TabPanel hidden={false}>
                        <>
                            <TrailStudent userMode={props.userMode} />
                            <Features userMode={props.userMode} />
                            <Functions userMode={props.userMode} />
                        </>
                    </TabPanel>
                    {props.userMode === "anonymous" && (
                        <div className={styles.footerButtonWrapper}>
                            <NavyButton handleClick={handleSignUpButtonClick}>今すぐ登録！</NavyButton>
                        </div>
                    )}
                </div>
            </Hidden>
            {/* PC表示 */}
            <Hidden smDown>
                <div>
                    <OverWrapper style={{ backgroundColor: "#F4F4F4" }}>
                        <AppBar position="static" style={{ backgroundColor: "#F4F4F4", boxShadow: "none" }}>
                            <Tabs
                                value={tab}
                                onChange={handleChange}
                                aria-label="Tabs for About Student"
                                style={{ margin: "0 auto" }}
                            >
                                <Tab
                                    label="Trail開設の背景"
                                    value={"background"}
                                    style={{
                                        backgroundColor: tab === "background" ? "#FFF" : "#F4F4F4",
                                        textTransform: "none",
                                    }}
                                />
                                <Tab
                                    label="特徴"
                                    value="features"
                                    style={{ backgroundColor: tab === "features" ? "#FFF" : "#F4F4F4" }}
                                />
                                <Tab
                                    label="機能"
                                    value="functions"
                                    style={{ backgroundColor: tab === "functions" ? "#FFF" : "#F4F4F4" }}
                                />
                            </Tabs>
                        </AppBar>
                    </OverWrapper>
                    <TabPanel hidden={tab !== "background"}>
                        <TrailStudent userMode={props.userMode} />
                    </TabPanel>
                    <TabPanel hidden={tab !== "features"}>
                        <Features userMode={props.userMode} />
                    </TabPanel>
                    <TabPanel hidden={tab !== "functions"}>
                        <Functions userMode={props.userMode} />
                    </TabPanel>
                </div>
            </Hidden>
        </MuiThemeProvider>
    );
};
