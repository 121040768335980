import { useState } from "react";

import { Badge, Button, makeStyles, useMediaQuery } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import MailIcon from "@material-ui/icons/Mail";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { login } from "@/actions/logging";
import { StudentToDoList } from "../ToDoList/StudentToDoList";
import styles from "../index.module.scss";

import { PleaseTeacherSignUpModal } from "./PleaseTeacherSignUpModal";
import { Profile } from "./Profile";
import { RootState } from "@/ducks";
import { useGetPrivateStudentQuery, useGetStudentCheckedAnnouncementIdsQuery } from "@/store/hooks/students";
import { useGetPrivateUserQuery } from "@/store/hooks/users";
import { QueryLoadingWrapper } from "../../QueryLoadingWrapper";
import { StudentAnnouncementList } from "../AnnouncementList/StudentAnnouncementList";
import { useGetStudentChatsQueryState } from "@/store/hooks/chats";
import { getStudentHowManyUnreadMessages } from "@/utils/ChatUtils";

export const ForStudent: React.VFC = () => {
    const [open, setOpen] = useState(false);

    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId);
    const userId = useSelector((state: RootState) => state.jwt.userId as string);

    const studentQueryState = useGetPrivateStudentQuery(studentId);
    const userQueryState = useGetPrivateUserQuery(userId);
    const studentChatsQueryState = useGetStudentChatsQueryState(studentId);
    const checkedAnnouncementIdsQueryState = useGetStudentCheckedAnnouncementIdsQuery(studentId);

    const sm = useMediaQuery("(min-width:600px)");
    const smallerThanThreshold = useMediaQuery("(max-width:639px)");

    const useStyles = makeStyles(() => ({
        navbar: {
            height: 32,
            minHeight: 32,
        },
        badge: {
            color: "#FFF",
            backgroundColor: "#C09547",
        },
    }));

    const style = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const handleSwitchButtonClick = () => {
        if (teacherId) {
            dispatch(login("teacher", userId, studentId, teacherId));
            history.push("/");
            history.push;
        } else {
            setOpen(true);
        }
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={styles.forStudent}>
            <PleaseTeacherSignUpModal open={open} handleClose={handleClose} />
            {!(sm && smallerThanThreshold) && (
                <Button
                    color="inherit"
                    className={`${styles.iconButton} ${styles.xsHidden}`}
                    component={Link}
                    to="/MyPage/Favorite"
                >
                    <FavoriteIcon className={styles.icon} />
                </Button>
            )}
            <Button color="inherit" className={styles.iconButton} component={Link} to="/MyPage/Chats">
                <QueryLoadingWrapper
                    {...studentChatsQueryState}
                    loadingComponent={
                        <Badge badgeContent={0} classes={{ badge: style.badge }} overlap="rectangular">
                            <MailIcon className={styles.icon} />
                        </Badge>
                    }
                >
                    {(chats) => {
                        const howManyUnreadMessages = getStudentHowManyUnreadMessages(chats, studentId);
                        return (
                            <Badge
                                badgeContent={howManyUnreadMessages}
                                classes={{ badge: style.badge }}
                                overlap="rectangular"
                            >
                                <MailIcon className={styles.icon} />
                            </Badge>
                        );
                    }}
                </QueryLoadingWrapper>
            </Button>
            <StudentAnnouncementList />
            <StudentToDoList />
            <QueryLoadingWrapper {...studentQueryState}>
                {(student) => (
                    <QueryLoadingWrapper {...userQueryState}>
                        {(user) => (
                            <>
                                {user && (
                                    <Profile
                                        student={student}
                                        user={user}
                                        handleSwitchButtonClick={handleSwitchButtonClick}
                                    />
                                )}
                            </>
                        )}
                    </QueryLoadingWrapper>
                )}
            </QueryLoadingWrapper>
        </div>
    );
};
