import { Button } from "@material-ui/core";
import styles from "./index.module.scss";
import { CreateLessonRequestParams, LessonResponse } from "@/store/autogenApi";

import { memo, useCallback, useState } from "react";
import { LessonCalendarContents } from "../LessonCalendar";
import { getLessonTime, getSortedAscLessons } from "@/utils/LessonUtils";
import { StyledModal } from "../StyledModal";
import { ErrorMessage } from "../ErrorMessage";
import { DeleteIcon, EditIcon } from "@/globalIcons";
import { startOfWeek } from "date-fns";
import { PlainButton } from "../PlainButton";
interface Props {
    onlyView?: boolean;
    lessons: CreateLessonRequestParams[];
    existingLessons: LessonResponse[];
    isChecked?: boolean;
    isPlain?: boolean;
    style?: React.CSSProperties;
    lessonsDuplicateValidation?: boolean;
    otherCourseLessonDuplicateValidation?: boolean;
    handleLessonsChange?: (newLessons: CreateLessonRequestParams[]) => void;
}

export const LessonCalendarButton: React.VFC<Props> = memo(function ShortCourse(props) {
    const [targetNewLessonIdx, setTargetNewLessonIdx] = useState<number | undefined>(undefined);
    const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
    const [targetStartDate, setTargetStartDate] = useState<Date | undefined>(undefined);
    const [targetUnit, setTargetUnit] = useState<"week" | "month" | undefined>(undefined);
    const [maxModalContentsHeight, setMaxModalContentsHeight] = useState<number | undefined>(undefined);

    const createNewLessonFromCreateCourse = useCallback(
        (lesson: CreateLessonRequestParams) => {
            if (!props.handleLessonsChange) return;
            const newLessons = [...props.lessons, lesson];
            const newSortedLessons = getSortedAscLessons(newLessons);
            props.handleLessonsChange(newSortedLessons);
        },
        [props.lessons, props.handleLessonsChange],
    );

    const updateNewLessonFromCreateCourse = useCallback(
        (lesson: CreateLessonRequestParams) => {
            if (!props.handleLessonsChange) return;
            const newLessons = [...(props.lessons ?? [])];
            newLessons[targetNewLessonIdx ?? 0] = lesson;
            const newSortedLessons = getSortedAscLessons(newLessons);
            props.handleLessonsChange(newSortedLessons);
        },
        [targetNewLessonIdx, props.lessons, props.handleLessonsChange],
    );

    const deleteNewLessonFromCreateCourse = useCallback(() => {
        if (!props.handleLessonsChange) return;
        if (targetNewLessonIdx === undefined) return;
        const newLessons = [...props.lessons];
        newLessons.splice(targetNewLessonIdx, 1);
        const newSortedLessons = getSortedAscLessons(newLessons);
        props.handleLessonsChange(newSortedLessons);
    }, [targetNewLessonIdx, props.lessons, props.handleLessonsChange]);

    const handleCalendarOpen = useCallback(() => {
        setIsCalendarOpen(true);
    }, []);

    const handleCalendarClose = useCallback(() => {
        setIsCalendarOpen(false);
        setTargetStartDate(undefined);
        setTargetUnit(undefined);
    }, []);

    const handleTargetNewLessonIdxChange = useCallback((idx: number | undefined) => {
        setTargetNewLessonIdx(idx);
    }, []);

    const handleEditButtonClick = useCallback(
        (idx: number) => {
            const targetLesson = props.lessons[idx];
            if (!targetLesson) return;
            const startTime = new Date(targetLesson.startTime);
            const startDate = startOfWeek(startTime);
            setTargetStartDate(startDate);
            setTargetUnit("week");
            handleCalendarOpen();
        },
        [props.lessons, handleCalendarOpen],
    );

    const handleDeleteButtonClick = useCallback(
        (idx: number) => {
            if (!props.handleLessonsChange) return;
            const newLessons = [...props.lessons];
            newLessons.splice(idx, 1);
            props.handleLessonsChange(newLessons);
        },
        [props.handleLessonsChange, props.lessons],
    );

    const handleMaxModalContentsHeightChange = useCallback((height: number) => {
        setMaxModalContentsHeight(height);
    }, []);

    return (
        <>
            {!props.onlyView && props.lessons.length === 0 && (
                <div className={styles.lessonCalendarDescription}>
                    「授業を追加」から必要な分だけ授業時間を追加してください。
                </div>
            )}
            {!props.onlyView && props.lessons.length > 0 && (
                <ul className={styles.lessons}>
                    {props.lessons.map((lesson, idx) => {
                        return (
                            <li
                                className={styles.lesson}
                                key={idx}
                                style={{
                                    marginBottom: idx + 1 === props.lessons.length ? 0 : "5px",
                                }}
                            >
                                <div className={styles.lessonTime}>{`${idx + 1}回目： ${getLessonTime(lesson)}`}</div>
                                <button
                                    className={styles.editButton}
                                    onClick={() => {
                                        handleEditButtonClick(idx);
                                    }}
                                >
                                    <EditIcon className={styles.editIcon} />
                                </button>
                                <button
                                    className={styles.deleteButton}
                                    onClick={() => {
                                        handleDeleteButtonClick(idx);
                                    }}
                                >
                                    <DeleteIcon className={styles.deleteIcon} />
                                </button>
                            </li>
                        );
                    })}
                </ul>
            )}
            <ErrorMessage
                content="授業時間が被らないようにしてください。"
                when={!!props.isChecked && props.lessonsDuplicateValidation === false}
                style={{ textAlign: "left", width: "100%", marginTop: "10px" }}
            />
            <ErrorMessage
                content="他の講座日程と被っているため、この日程では登録できません。"
                when={!!props.isChecked && props.otherCourseLessonDuplicateValidation === false}
                style={{ textAlign: "left", width: "100%" }}
            />
            <ErrorMessage
                content="過去の日時を登録することはできません。"
                when={
                    !!props.isChecked &&
                    !props.lessons.every((lesson) => new Date().getTime() < new Date(lesson.startTime).getTime())
                }
                style={{ textAlign: "left", width: "100%" }}
            />
            {props.onlyView ? (
                <PlainButton
                    handleClick={handleCalendarOpen}
                    style={{ marginTop: props.onlyView ? "0" : "10px", ...props.style }}
                    text={props.onlyView ? "予定を確認" : "授業を追加"}
                />
            ) : (
                <Button
                    className={props.isPlain ? styles.calendarPlainButton : styles.calendarButton}
                    onClick={handleCalendarOpen}
                    style={{ marginTop: props.onlyView ? "0" : "10px", ...props.style }}
                >
                    <div className={styles.calendarButtonLabel}>{props.onlyView ? "予定を確認" : "授業を追加"}</div>
                </Button>
            )}
            <StyledModal
                open={isCalendarOpen}
                onClose={handleCalendarClose}
                existCloseButton
                autoResize
                style={{ overflow: "hidden" }}
                handleMaxModalContentsHeight={handleMaxModalContentsHeightChange}
            >
                <div className={styles.lessonCalendarContentsWrapper}>
                    <LessonCalendarContents
                        lessons={[...props.lessons, ...props.existingLessons]}
                        targetNewLessonIdx={targetNewLessonIdx}
                        isDraggable={!props.onlyView}
                        targetStartDate={targetStartDate}
                        targetUnit={targetUnit}
                        maxModalContentsHeight={maxModalContentsHeight}
                        createNewLessonFromCreateCourse={createNewLessonFromCreateCourse}
                        updateNewLessonFromCreateCourse={updateNewLessonFromCreateCourse}
                        deleteNewLessonFromCreateCourse={deleteNewLessonFromCreateCourse}
                        handleTargetNewLessonIdxChange={handleTargetNewLessonIdxChange}
                        handleCalendarClose={handleCalendarClose}
                    />
                </div>
            </StyledModal>
        </>
    );
});
