import { memo } from "react";
import { SubjectResponse } from "@/store/autogenApi";
import { RequestsWithTargetSubject } from "./RequestsWithTargetSubject";

interface Props {
    subjects: SubjectResponse[];
}

export const RequestsWithTheSameSubjects: React.VFC<Props> = memo(function RequestsWithTheSameSubjects(props) {
    return (
        <>
            {props.subjects.slice(0, 3).map((subject) => {
                return <RequestsWithTargetSubject subject={subject} />;
            })}
        </>
    );
});
