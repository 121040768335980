import { Button, CircularProgress, List } from "@material-ui/core";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FavoriteIcon from "@material-ui/icons/Favorite";
import HistoryIcon from "@material-ui/icons/History";
import NotificationIcon from "@material-ui/icons/Notifications";
import MessageIcon from "@material-ui/icons/Mail";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { AiFillSetting, AiOutlineBank } from "react-icons/ai";
import { BiCoinStack } from "react-icons/bi";
import { MdCreate } from "react-icons/md";
import { RiFileList2Line, RiMoneyCnyBoxLine } from "react-icons/ri";
import { VscArrowSwap } from "react-icons/vsc";
import { LinkButtonTemplate } from "../LinkButtonTemplate";
import styles from "../index.module.scss";
import { PrivateTeacherResponse, PrivateUserResponse } from "@/store/autogenApi";
import { DraftIcon, LightBulbIcon } from "@/globalIcons";
import { TbPigMoney } from "react-icons/tb";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    handleLinkButtonClick: (params: string) => void;
    handleSignOutButtonClick: () => void;
    handleSwitchButtonClick: () => void;
    handleCreateCourseButtonClick: () => void;
    isProcessingForSignOut: boolean;
    teacher: PrivateTeacherResponse;
    user: PrivateUserResponse;
}

export const MenuContents: React.VFC<Props> = (props) => {
    return (
        <div className={styles.menuContentsWrapper}>
            <div className={styles.userInfo}>
                <AvatarFromS3
                    url={props.teacher.iconImageUrl}
                    objectKey={props.teacher.iconImageObjectKey}
                    className={styles.avatar}
                />
                <div className={styles.nickname}>{props.teacher?.nickname}</div>
                <div className={styles.pointsWrapper}>
                    <div className={styles.pointsHeld}>
                        現在の売上金：¥{new Intl.NumberFormat("ja-JP").format(props.teacher.totalProceeds)}
                    </div>
                </div>
            </div>
            <Button className={styles.createCourseButton} onClick={props.handleCreateCourseButtonClick}>
                <MdCreate className={styles.courseIcon} />
                <div className={styles.createCourse}>講座を作成</div>
            </Button>
            <List className={styles.menuList}>
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick(`/Teacher/MyPage/Profile`);
                    }}
                    itemTitle="プロフィール"
                    IconJSX={<AssignmentIndIcon className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/Teacher/MyPage/History");
                    }}
                    itemTitle="最近チェックした講座リクエスト"
                    IconJSX={<HistoryIcon className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/Teacher/MyPage/Favorite");
                    }}
                    itemTitle="気になる講座リクエスト"
                    IconJSX={<FavoriteIcon className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/Teacher/MyPage/Announcements");
                    }}
                    itemTitle="お知らせ"
                    IconJSX={<NotificationIcon className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/Teacher/MyPage/Chats");
                    }}
                    itemTitle="メッセージ"
                    IconJSX={<MessageIcon className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/Teacher/MyPage/MyCourses");
                    }}
                    itemTitle="My講座一覧"
                    IconJSX={<RiFileList2Line className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/Teacher/MyPage/MyDraftCourses");
                    }}
                    itemTitle="下書き講座一覧"
                    IconJSX={<DraftIcon className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/Teacher/MyPage/MyProposals");
                    }}
                    itemTitle="提案一覧"
                    IconJSX={<LightBulbIcon className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/Teacher/MyPage/LessonCalendar");
                    }}
                    itemTitle="授業カレンダー"
                    IconJSX={<CalendarMonthIcon className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/Teacher/MyPage/BankAccountSetting");
                    }}
                    itemTitle="振込用銀行口座設定"
                    IconJSX={<AiOutlineBank className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/Teacher/MyPage/ApplicationForTransfer");
                    }}
                    itemTitle="振込申請"
                    IconJSX={<RiMoneyCnyBoxLine className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/Teacher/MyPage/ProceedsRecords");
                    }}
                    itemTitle="売上金履歴"
                    IconJSX={<TbPigMoney className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/Teacher/MyPage/PointsRecordHistory");
                    }}
                    itemTitle="ポイント履歴"
                    IconJSX={<BiCoinStack className={styles.icon} />}
                    borderBottom
                />
                <LinkButtonTemplate
                    handleClick={() => {
                        props.handleLinkButtonClick("/Teacher/MyPage/Setting");
                    }}
                    itemTitle="設定"
                    IconJSX={<AiFillSetting className={styles.icon} />}
                    borderBottom
                />
            </List>
            <div className={styles.accountWrapper}>
                <Button className={styles.switchToStudentAccountButton} onClick={props.handleSwitchButtonClick}>
                    <VscArrowSwap className={styles.switchIcon} />
                    <div className={styles.switchToStudentAccount}>生徒アカウントへ切替</div>
                </Button>
                <Button className={styles.logOutButton} onClick={props.handleSignOutButtonClick}>
                    {props.isProcessingForSignOut ? (
                        <CircularProgress className={styles.progress} />
                    ) : (
                        <div className={styles.logOutWrapper}>
                            <ExitToAppIcon className={styles.exitIcon} />
                            <div className={styles.logOut}>ログアウト</div>
                        </div>
                    )}
                </Button>
            </div>
        </div>
    );
};
