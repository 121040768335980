import React, { JSXElementConstructor, ReactElement, memo, useCallback, useEffect, useState } from "react";

import { Hidden } from "@material-ui/core";
import { useSelector } from "react-redux";

import { MenuBar } from "@/pageComponents/Student/MyPage/MenuBar";

import styles from "./index.module.scss";
import { RootState } from "@/ducks";

interface Props {
    title: string;
    activeTab: string;
    setDetailIdx?: (idx: number | undefined) => void;
    setUpdated?: (value: boolean) => void;
    handleBackButtonClick?: () => void;
    children: ReactElement<any, string | JSXElementConstructor<any>>;
}

export const MyPage: React.VFC<Props> = memo(function MyPage(props) {
    const [currentMyPageBodyEl, setMyPageBodyEl] = useState<HTMLElement | null>(null);
    const [myPageHeight, setMyPageHeight] = useState<number>(0);

    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);

    const myPageBodyRef = useCallback((headerEl: HTMLElement | null) => {
        setMyPageBodyEl(headerEl);
    }, []);

    const changeMyPageBodyHeight = useCallback(() => {
        const body = document.getElementById("body");
        if (!body || !currentMyPageBodyEl) return;
        const bodyMinHeight = parseInt(body.style.minHeight);
        // 調整用
        const delta = 50;
        setMyPageHeight(bodyMinHeight - delta);
    }, [currentMyPageBodyEl]);

    useEffect(() => {
        changeMyPageBodyHeight();
        window.addEventListener("resize", changeMyPageBodyHeight);
        return () => {
            window.removeEventListener("resize", changeMyPageBodyHeight);
        };
    }, [changeMyPageBodyHeight]);

    const cloneChildren = React.cloneElement(props.children, {
        style: {
            height: `500px`,
        },
    });

    const isInChats = props.activeTab === "chats";

    const isInCardPage = [
        "favorite",
        "history",
        "draftPublicRequests",
        "courseRequests",
        "pastCourses",
        "reservedCourses",
    ].includes(props.activeTab);

    return (
        <div className={styles.myPageBody} ref={myPageBodyRef} style={{ minHeight: `${myPageHeight}px` }}>
            <div className={styles.header}>
                <div className={styles.title}>{props.title}</div>
            </div>
            <div className={styles.contentsWrapper}>
                <Hidden smDown>
                    <MenuBar
                        fontSize="medium"
                        activeTab={props.activeTab}
                        setDetailIdx={props.setDetailIdx}
                        setUpdated={props.setUpdated}
                        handleBackButtonClick={props.handleBackButtonClick}
                    />
                </Hidden>
                <div
                    className={styles.mainContents}
                    style={isInCardPage ? { backgroundColor: "transparent", boxShadow: "none" } : {}}
                >
                    {isInChats ? cloneChildren : props.children}
                </div>
                <Hidden mdUp>
                    <MenuBar
                        fontSize="small"
                        activeTab={props.activeTab}
                        setDetailIdx={props.setDetailIdx}
                        setUpdated={props.setUpdated}
                        handleBackButtonClick={props.handleBackButtonClick}
                    />
                </Hidden>
            </div>
        </div>
    );
});
