import { memo, useCallback, useEffect, useState } from "react";
import styles from "../index.module.scss";
import { CreateLessonRequestParams, LessonResponse } from "@/store/autogenApi";
import { getIsTheSameDay } from "@/utils/DateUtils";
import { LessonTimeBlocks } from "./LessonTimeBlocks";
import { DraggableArea } from "./DraggableArea";
import { NewLessonPopover } from "./NewLessonPopover";
import { LessonModal } from "./LessonModal";
import { CourseDetailsModal } from "./CourseDetailsModal";
import { useParams } from "react-router";
import { startOfWeek } from "date-fns";
import { useMediaQuery } from "@material-ui/core";

interface Props {
    lessons: (LessonResponse | CreateLessonRequestParams)[];
    lessonCalendarStartDate: Date;
    targetNewLessonIdx?: number;
    isDraggable: boolean;
    maxModalContentsHeight?: number | undefined;
    getLaneDate: (idx: number) => Date;
    handleLessonCalendarStartDateChange: (lessonCalendarStartDate: Date) => void;
    createNewLessonFromCreateCourse?: (lesson: CreateLessonRequestParams) => void;
    updateNewLessonFromCreateCourse?: (lesson: CreateLessonRequestParams) => void;
    deleteNewLessonFromCreateCourse?: () => void;
    handleTargetNewLessonIdxChange?: (idx: number | undefined) => void;
    handleCalendarClose?: () => void;
}

export const LessonCalendarOfWeekLanes: React.VFC<Props> = memo((props) => {
    const [lessonCalendarLanesEl, setLessonCalendarLanesEl] = useState<null | HTMLElement>(null);

    // 1日を96分割した時間のうち、授業がある時間帯のインデックス
    const [newLessonStartYIdx, setNewLessonStartYIdx] = useState<number | undefined>(undefined);
    const [newLessonEndYIdx, setNewLessonEndYIdx] = useState<number | undefined>(undefined);
    // 曜日のインデックス
    const [newLessonXIdx, setNewLessonXIdx] = useState<number | undefined>(undefined);

    const [newLessonPopoverOpen, setNewLessonPopoverOpen] = useState<boolean>(false);
    const [newLessonAnchorEl, setNewLessonAnchorEl] = useState<HTMLElement | null>(null);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    // newLessonYIdxを変換した時間
    // const [startHour, setStartHour] = useState<number | undefined>(undefined);
    // const [startMinute, setStartMinute] = useState<number | undefined>(undefined);
    // const [endHour, setEndHour] = useState<number | undefined>(undefined);
    // const [endMinute, setEndMinute] = useState<number | undefined>(undefined);
    const [newLessonStartTime, setNewLessonStartTime] = useState<Date | undefined>(undefined);
    const [newLessonEndTime, setNewLessonEndTime] = useState<Date | undefined>(undefined);

    const [lessonModalOpen, setLessonModalOpen] = useState<boolean>(false);
    const [courseDetailsModalOpen, setCourseDetailsModalOpen] = useState<boolean>(false);
    const [targetLesson, setTargetLesson] = useState<LessonResponse | undefined>(undefined);
    const [isDeleteMode, setIsDeleteMode] = useState<boolean>(false);
    // 新規授業更新中かどうか
    const [isUpdatingTemporaryLesson, setIsUpdatingTemporaryLesson] = useState<boolean>(false);
    const [isClickedValidBlock, setIsClickedValidBlock] = useState<boolean>(false);
    const [lanesHeight, setLanesHeight] = useState<number | undefined>(undefined);

    const isInMyPage = location.pathname.includes("/MyPage/");
    const isInClass = location.pathname.includes("/Class/");

    const { classId } = useParams<{ classId?: string }>();

    const smUp = useMediaQuery("(min-width:600px)");

    useEffect(() => {
        if (!props.maxModalContentsHeight) return;
        // TopContents・間のmargin・Headings・下の余白の高さを引いたものをlanesHeightとする
        const bottom = smUp ? 30 : 20;
        setLanesHeight(props.maxModalContentsHeight - (50 + 10 + 58 + bottom));
    }, [props.maxModalContentsHeight, smUp]);

    // idxを時間に変換
    useEffect(() => {
        if (newLessonXIdx == undefined) return;
        const targetDate = new Date(props.lessonCalendarStartDate);
        targetDate.setDate(targetDate.getDate() + newLessonXIdx);
        if (newLessonStartYIdx == undefined || newLessonEndYIdx == undefined) return;
        const start = new Date(targetDate);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);
        start.setMinutes(start.getMinutes() + 15 * newLessonStartYIdx);
        const end = new Date(targetDate);
        end.setHours(0);
        end.setMinutes(0);
        end.setSeconds(0);
        end.setMilliseconds(0);
        end.setMinutes(end.getMinutes() + 15 * newLessonEndYIdx);
        setNewLessonStartTime(start);
        setNewLessonEndTime(end);
    }, [newLessonXIdx, newLessonStartYIdx, newLessonEndYIdx, props.lessonCalendarStartDate]);

    useEffect(() => {
        if (!lessonCalendarLanesEl) return;
        const lessonCalendarLanesElHeight = lessonCalendarLanesEl.clientHeight;
        // 今週の授業がいい感じに見えるようにする
        // まず、今週の授業のうち最も早い開始時刻の授業を探す
        // 日付が異なるので、開始時刻のみを比較する
        if (props.lessons.length === 0) return;
        const earliestLesson = props.lessons.reduce((prev, current) => {
            const prevStartTime = new Date(prev.startTime);
            const currentStartTime = new Date(current.startTime);
            if (prevStartTime.getHours() < currentStartTime.getHours()) {
                return prev;
            } else if (prevStartTime.getHours() > currentStartTime.getHours()) {
                return current;
            } else {
                if (prevStartTime.getMinutes() < currentStartTime.getMinutes()) {
                    return prev;
                } else {
                    return current;
                }
            }
        });
        // idxは0から始まるので、開始時刻の時間を4倍して、分を15で割る
        const earliestLessonStartTimeIdx =
            new Date(earliestLesson.startTime).getHours() * 4 +
            Math.floor(new Date(earliestLesson.startTime).getMinutes() / 15);
        // 親要素
        const lessonCalendarLanesWrapperEl = lessonCalendarLanesEl.parentElement;
        if (!lessonCalendarLanesWrapperEl) return;
        // 全体を96としていい感じにスクロール
        // 上は余分に30分（2）だけ空ける
        lessonCalendarLanesWrapperEl.scrollTo(0, (earliestLessonStartTimeIdx - 2) * (lessonCalendarLanesElHeight / 96));
    }, [lessonCalendarLanesEl, props.lessons]);

    const lessonCalendarLanesRef = useCallback((el: HTMLElement | null) => {
        setLessonCalendarLanesEl(el);
    }, []);

    const handleNewLessonStartYIdxChange = useCallback((idx: number | undefined) => {
        setNewLessonStartYIdx(idx);
    }, []);

    const handleNewLessonEndYIdxChange = useCallback((idx: number | undefined) => {
        setNewLessonEndYIdx(idx);
    }, []);

    const handleNewLessonXIdxChange = useCallback((idx: number | undefined) => {
        setNewLessonXIdx(idx);
    }, []);

    const handleNewLessonPopoverClose = useCallback(() => {
        setNewLessonPopoverOpen(false);
        setNewLessonAnchorEl(null);
        setNewLessonXIdx(undefined);
        setNewLessonStartYIdx(undefined);
        setNewLessonEndYIdx(undefined);
        setIsUpdatingTemporaryLesson(false);
        setIsDeleteMode(false);
        setIsClickedValidBlock(false);
        // 既存授業のターゲットを外す
        setTargetLesson(undefined);
        if (!props.handleTargetNewLessonIdxChange) return;
        // 新規授業のターゲットを外す
        props.handleTargetNewLessonIdxChange(undefined);
    }, []);

    const handleNewLessonPopoverOpen = useCallback(() => {
        setNewLessonPopoverOpen(true);
    }, []);

    const handleNewLessonAnchorElChange = useCallback((el: HTMLElement) => {
        setNewLessonAnchorEl(el);
    }, []);

    const handleIsDraggingChange = useCallback((isDragging: boolean) => {
        setIsDragging(isDragging);
    }, []);

    const handleStartHourChange = useCallback((e: React.ChangeEvent<{ value: number }>) => {
        const newStartHour = e.target.value;
        setNewLessonStartTime((prev) => {
            if (!prev) return undefined;
            const newStartTime = new Date(prev);
            newStartTime.setHours(newStartHour);
            return newStartTime;
        });
    }, []);

    const handleStartMinuteChange = useCallback((e: React.ChangeEvent<{ value: number }>) => {
        const newStartMinute = e.target.value;
        setNewLessonStartTime((prev) => {
            if (!prev) return undefined;
            const newStartTime = new Date(prev);
            newStartTime.setMinutes(newStartMinute);
            return newStartTime;
        });
    }, []);

    const handleEndHourChange = useCallback((e: React.ChangeEvent<{ value: number }>) => {
        const newEndHour = e.target.value;
        setNewLessonEndTime((prev) => {
            if (!prev) return undefined;
            const newEndTime = new Date(prev);
            newEndTime.setHours(newEndHour);
            return newEndTime;
        });
    }, []);

    const handleEndMinuteChange = useCallback((e: React.ChangeEvent<{ value: number }>) => {
        const newEndMinute = e.target.value;
        setNewLessonEndTime((prev) => {
            if (!prev) return undefined;
            const newEndTime = new Date(prev);
            newEndTime.setMinutes(newEndMinute);
            return newEndTime;
        });
    }, []);

    const handleNewLessonOnlyDateChange = useCallback(
        (date: Date | null) => {
            if (!date) return;
            if (!newLessonStartTime || !newLessonEndTime) return;
            const newDate = new Date(date);
            const newStartTime = new Date(newLessonStartTime);
            const newEndTime = new Date(newLessonEndTime);
            newStartTime.setFullYear(newDate.getFullYear());
            newStartTime.setMonth(newDate.getMonth());
            newStartTime.setDate(newDate.getDate());
            newEndTime.setFullYear(newDate.getFullYear());
            newEndTime.setMonth(newDate.getMonth());
            newEndTime.setDate(newDate.getDate());
            setNewLessonStartTime(newStartTime);
            setNewLessonEndTime(newEndTime);
            props.handleLessonCalendarStartDateChange(startOfWeek(newDate));
            setNewLessonXIdx(newDate.getDay());
            const startYIdx =
                new Date(newStartTime).getHours() * 4 + Math.floor(new Date(newStartTime).getMinutes() / 15);
            const endYIdx = new Date(newEndTime).getHours() * 4 + Math.floor(new Date(newEndTime).getMinutes() / 15);
            setNewLessonStartYIdx(startYIdx);
            setNewLessonEndYIdx(endYIdx);
        },
        [props.handleLessonCalendarStartDateChange, startOfWeek, newLessonStartTime, newLessonEndTime],
    );

    const getNewLessonTimeString = useCallback(() => {
        if (!newLessonStartTime || !newLessonEndTime) return "";
        const startHour = newLessonStartTime.getHours();
        const startMinute = newLessonStartTime.getMinutes();
        const endHour = newLessonEndTime.getHours();
        const endMinute = newLessonEndTime.getMinutes();
        const startHourString = ("00" + startHour).slice(-2);
        const startMinuteString = ("00" + startMinute).slice(-2);
        const endHourString = ("00" + endHour).slice(-2);
        const endMinuteString = ("00" + endMinute).slice(-2);
        return `${startHourString}:${startMinuteString} ~ ${endHourString}:${endMinuteString}`;
    }, [newLessonStartTime, newLessonEndTime]);

    const handleLessonModalOpen = useCallback(() => {
        setLessonModalOpen(true);
    }, []);

    const handleLessonModalClose = useCallback(() => {
        setLessonModalOpen(false);
        handleNewLessonPopoverClose();
        setIsDeleteMode(false);
    }, [handleNewLessonPopoverClose]);

    const handleDeleteButtonClick = useCallback(() => {
        if (props.deleteNewLessonFromCreateCourse) {
            props.deleteNewLessonFromCreateCourse();
            handleNewLessonPopoverClose();
            return;
        }
        setIsDeleteMode(true);
        handleLessonModalOpen();
    }, [handleNewLessonPopoverClose, handleLessonModalOpen, props.deleteNewLessonFromCreateCourse]);

    const getNewLessonIdx = useCallback(
        (lesson: CreateLessonRequestParams) => {
            const newLessons = props.lessons.filter((lesson) => !lesson.hasOwnProperty("lessonId"));
            const newLessonIdx = newLessons.findIndex(
                (newLesson) => newLesson.startTime == lesson.startTime && newLesson.endTime == lesson.endTime,
            );
            return newLessonIdx;
        },
        [props.lessons],
    );

    // 新規授業のターゲットを変更した時に呼ばれる
    const handleTargetNewLessonIdxChange = useCallback(
        (lesson: CreateLessonRequestParams) => {
            if (!props.handleTargetNewLessonIdxChange) return;
            const newLessonIdx = getNewLessonIdx(lesson);
            props.handleTargetNewLessonIdxChange(newLessonIdx);
            setIsUpdatingTemporaryLesson(true);
        },
        [props.lessons, props.handleTargetNewLessonIdxChange, getNewLessonIdx],
    );

    // ブロックをクリックしたときの処理
    const handleValidBlockClick = useCallback(
        (lesson: LessonResponse | CreateLessonRequestParams, idx: number, el: HTMLElement) => {
            setIsClickedValidBlock(true);
            const startTime = new Date(lesson.startTime);
            const endTime = new Date(lesson.endTime);
            setNewLessonStartTime(startTime);
            setNewLessonEndTime(endTime);
            setNewLessonXIdx(idx);
            // 新規の青いブロックをクリックした時
            if (!lesson.hasOwnProperty("lessonId")) {
                handleTargetNewLessonIdxChange(lesson);
                setNewLessonPopoverOpen(true);
                setNewLessonAnchorEl(el);
                return;
            }
            const lessonResponse = lesson as LessonResponse;
            setTargetLesson(lessonResponse);
            setCourseDetailsModalOpen(true);
            return;
            // const isTargetClass = lessonResponse.class?.classId == classId;
            // // 操作不能なグレーまたは青のブロックをクリックした時
            // if (isInMyPage || isInClass || !isTargetClass) {
            //     setTargetLesson(lessonResponse);
            //     setCourseDetailsModalOpen(true);
            //     return;
            // }
            // // 既存の青いブロック（操作可能）をクリックした時
            // setTargetLesson(lessonResponse);
            // setNewLessonPopoverOpen(true);
            // setNewLessonAnchorEl(el);
        },
        [isInMyPage, isInClass, classId, handleTargetNewLessonIdxChange],
    );

    const handleCourseDetailsModalClose = useCallback(() => {
        setTargetLesson(undefined);
        setCourseDetailsModalOpen(false);
    }, []);

    const handleIsDeleteModeChange = useCallback((value: boolean) => {
        setIsDeleteMode(value);
    }, []);

    const handleTargetLessonChange = useCallback((lesson: LessonResponse | undefined) => {
        setTargetLesson(lesson);
    }, []);

    const handleEditButtonClick = useCallback(() => {
        if (!targetLesson) return;
        setCourseDetailsModalOpen(false);
        const targetEl = document.getElementById(`lessonTimeBlock-${targetLesson.lessonId}`);
        setNewLessonPopoverOpen(true);
        setNewLessonAnchorEl(targetEl);
    }, [targetLesson]);

    return (
        <div className={styles.lessonCalendarOfWeekLanes} style={lanesHeight ? { maxHeight: `${lanesHeight}px` } : {}}>
            <div className={styles.lessonCalendarLanesLeft}>
                {[...Array(24)].map((_, j) => {
                    return (
                        <div
                            className={styles.lessonCalendarLanesLeftContent}
                            key={j}
                            style={{ borderBottom: j == 23 ? "none" : "1px solid #DDD" }}
                        >
                            {j != 23 && <div className={styles.hour}>{j + 1}:00</div>}
                        </div>
                    );
                })}
            </div>
            <div className={styles.lessonCalendarLanes} ref={lessonCalendarLanesRef}>
                {[...Array(7)].map((_, i) => {
                    const date = props.getLaneDate(i);
                    const filteredLessons = props.lessons.filter((lesson) =>
                        getIsTheSameDay(new Date(lesson.startTime), date),
                    );
                    return (
                        <div className={styles.lessonCalendarLane} key={i}>
                            {[...Array(96)].map((_, j) => {
                                return (
                                    <div
                                        className={styles.lessonCalendarLaneContent}
                                        key={j}
                                        style={{
                                            borderBottom: j !== 0 && (j + 1) % 4 === 0 ? "1px solid #DDD" : "none",
                                        }}
                                    ></div>
                                );
                            })}
                            <LessonTimeBlocks
                                filteredLessons={filteredLessons}
                                newLessonStartYIdx={newLessonStartYIdx}
                                newLessonEndYIdx={newLessonEndYIdx}
                                newLessonXIdx={newLessonXIdx}
                                targetDayOfWeekIdx={i}
                                isDragging={isDragging}
                                lessonCalendarLanesEl={lessonCalendarLanesEl}
                                targetLesson={targetLesson}
                                isUpdatingTemporaryLesson={isUpdatingTemporaryLesson}
                                isClickedValidBlock={isClickedValidBlock}
                                targetNewLessonIdx={props.targetNewLessonIdx}
                                newLessonTimeString={`${getNewLessonTimeString()}`}
                                handleNewLessonAnchorElChange={handleNewLessonAnchorElChange}
                                getLaneDate={props.getLaneDate}
                                handleValidBlockClick={handleValidBlockClick}
                                handleNewLessonStartYIdxChange={handleNewLessonStartYIdxChange}
                                handleNewLessonEndYIdxChange={handleNewLessonEndYIdxChange}
                                handleNewLessonXIdxChange={handleNewLessonXIdxChange}
                                handleNewLessonPopoverOpen={handleNewLessonPopoverOpen}
                                handleTargetLessonChange={handleTargetLessonChange}
                                handleTargetNewLessonIdxChange={handleTargetNewLessonIdxChange}
                                getNewLessonIdx={getNewLessonIdx}
                            />
                            {!isInMyPage && (
                                <DraggableArea
                                    lessons={props.lessons}
                                    lessonCalendarLanesEl={lessonCalendarLanesEl}
                                    targetDayOfWeekIdx={i}
                                    newLessonStartYIdx={newLessonStartYIdx}
                                    newLessonEndYIdx={newLessonEndYIdx}
                                    enableToUpsertLesson={props.isDraggable}
                                    handleNewLessonStartYIdxChange={handleNewLessonStartYIdxChange}
                                    handleNewLessonEndYIdxChange={handleNewLessonEndYIdxChange}
                                    handleNewLessonXIdxChange={handleNewLessonXIdxChange}
                                    handleNewLessonPopoverOpen={handleNewLessonPopoverOpen}
                                    handleIsDraggingChange={handleIsDraggingChange}
                                />
                            )}
                        </div>
                    );
                })}
                <NewLessonPopover
                    open={newLessonPopoverOpen}
                    anchorEl={newLessonAnchorEl}
                    newLessonStartTime={newLessonStartTime}
                    newLessonEndTime={newLessonEndTime}
                    newLessonXIdx={newLessonXIdx}
                    targetLessonId={targetLesson?.lessonId}
                    isUpdatingTemporaryLesson={isUpdatingTemporaryLesson}
                    isDraggable={props.isDraggable}
                    handleDeleteButtonClick={handleDeleteButtonClick}
                    handlePopoverClose={handleNewLessonPopoverClose}
                    handleStartHourChange={handleStartHourChange}
                    handleStartMinuteChange={handleStartMinuteChange}
                    handleEndHourChange={handleEndHourChange}
                    handleEndMinuteChange={handleEndMinuteChange}
                    handleNewLessonOnlyDateChange={handleNewLessonOnlyDateChange}
                    handleLessonModalOpen={handleLessonModalOpen}
                    handleIsDeleteModeChange={handleIsDeleteModeChange}
                    handleCalendarClose={props.handleCalendarClose}
                    createNewLessonFromCreateCourse={props.createNewLessonFromCreateCourse}
                    updateNewLessonFromCreateCourse={props.updateNewLessonFromCreateCourse}
                    deleteNewLessonFromCreateCourse={props.deleteNewLessonFromCreateCourse}
                />
                <LessonModal
                    modalOpen={lessonModalOpen}
                    startHour={newLessonStartTime?.getHours()}
                    startMinute={newLessonStartTime?.getMinutes()}
                    endHour={newLessonEndTime?.getHours()}
                    endMinute={newLessonEndTime?.getMinutes()}
                    newLessonXIdx={newLessonXIdx}
                    targetLesson={targetLesson}
                    isDeleteMode={isDeleteMode}
                    lessonCalendarStartDate={props.lessonCalendarStartDate}
                    lessons={props.lessons}
                    handleLessonModalClose={handleLessonModalClose}
                />
                {targetLesson && (
                    <CourseDetailsModal
                        modalOpen={courseDetailsModalOpen}
                        targetLesson={targetLesson}
                        handleCourseDetailsModalClose={handleCourseDetailsModalClose}
                        handleEditButtonClick={handleEditButtonClick}
                    />
                )}
            </div>
            <div className={styles.lessonCalendarLanesRight}>
                {[...Array(24)].map((_, j) => {
                    return <div className={styles.lessonCalendarLanesRightContent} key={j}></div>;
                })}
            </div>
        </div>
    );
});
