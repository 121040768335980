import { Box, CircularProgress } from "@material-ui/core";

import { QueryState } from "@/store/hooks/utils/parseResult";

import styles from "./index.module.scss";

type Props<T> = QueryState<T> & {
    isHidden?: boolean;
    children: (data: T) => React.ReactNode;
    loadingComponent?: JSX.Element;
    loadingClassName?: string;
};

export const QueryLoadingWrapper = <T,>(props: Props<T>) => {
    if (props.isSuccess && !props.isFetching) {
        return <>{props.children(props.data)}</>;
    }
    if (props.isHidden) {
        return null;
    }
    if (props.loadingComponent) {
        return props.loadingComponent;
    }
    return (
        <div className={styles.queryLoadingWrapper}>
            <CircularProgress className={`${styles.circularProgress} ${props.loadingClassName}`} />
        </div>
    );
};
