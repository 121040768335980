import { TeacherToDoResponse } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions } from "./utils/types";

export const useGetTeacherToDosByTeacherIdQuery = (teacherId: string) => {
    const result = defaultApi.endpoints.getTeacherToDos.useQuery({ teacherId: teacherId });
    return useQueryResult(result, (response: TeacherToDoResponse[]) => response);
};

export const useCreateTeacherToDoMutation = (options?: UseMutationOptions) => {
    const [createTeacherToDo] = defaultApi.endpoints.createTeacherToDo.useMutation();
    return useMutationFunctionWrapper(createTeacherToDo, options?.errorMessages);
};

export const useUpdateTeacherToDoMutation = (options?: UseMutationOptions) => {
    const [updateTeacherToDo] = defaultApi.endpoints.updateTeacherToDo.useMutation();
    return useMutationFunctionWrapper(updateTeacherToDo, options?.errorMessages);
};
