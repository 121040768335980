import { Button, CircularProgress, useMediaQuery } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { memo } from "react";
import { AiFillBook, AiFillHome } from "react-icons/ai";
import { Link } from "react-router-dom";

import styles from "@/pages/Student/CreatePublicRequest/index.module.scss";
import { CourseType, SubjectResponse } from "@/store/autogenApi";
import { PriceRange } from "./Functional/Common";

import { ApplicationPeriod } from "./Items/ApplicationPeriod";
import { CourseTypeItem } from "./Items/CourseType";
import { Detail } from "./Items/Detail";
import { PriceComponent } from "./Items/Price";
import { Remarks } from "./Items/Remarks";
import { ScheduleDescription } from "./Items/ScheduleDescription";
import { StartDateDescription } from "./Items/StartDateDescription";
import { Subjects } from "./Items/Subjects";
import { Title } from "./Items/Title";
import { ConfigKey } from "./LimitConfig";
import { WhiteButton } from "@/components/Buttons/WhiteButton";
import { NavyButton } from "@/components/Buttons/NavyButton";

interface Props {
    open: boolean;
    checked: boolean;
    complete: boolean;
    hasError: boolean;
    currentRequestId: string;
    newRequestId: string | undefined;
    subjects: SubjectResponse[];
    modalMaxHeight: number;
    applicationPeriod: number;
    howManyErrors: number;
    processing: boolean;
    minPrice: number;
    maxPrice: number;
    courseType: CourseType | undefined;
    title: string;
    scheduleDescription: string;
    publicRequestDescription: string;
    additionalDescription: string;
    firstDateDescription: string;
    isTemporarySaved: boolean;
    getIsValid: (key: ConfigKey, value: number) => boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSubjects: React.Dispatch<React.SetStateAction<SubjectResponse[]>>;
    handleConfirmButtonClick: () => Promise<void>;
    handleDraftButtonClick: () => Promise<void>;
    handleCheckButtonClick: () => void;
    handleApplicationPeriodChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handlePriceChange: (range: PriceRange) => void;
    handleCourseTypeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleScheduleDescriptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleRequestDescriptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleAdditionalDescriptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleStartDateDescriptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RequestContents: React.VFC<Props> = memo(function RequestContents(props) {
    const md = useMediaQuery("(min-width:960px)");

    return (
        <div className={styles.pageBody}>
            <div className={styles.createPublicRequestWrapper}>
                <div className={styles.contents}>
                    {props.hasError ? (
                        <div className={styles.hasErrorWrapper}>
                            <div className={styles.messageWrapper}>
                                <p className={styles.message}>講座リクエストの作成処理中にエラーが発生しました。</p>
                            </div>
                            <div className={styles.buttonsWrapper}>
                                <Button component={Link} to="/" className={styles.toHomeButton}>
                                    <AiFillHome className={styles.homeIcon} />
                                    <div className={styles.text}>ホームに戻る</div>
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <>
                            {props.complete ? (
                                <div className={styles.completeWrapper}>
                                    {props.isTemporarySaved ? (
                                        <>
                                            <div className={styles.messageWrapper}>
                                                <p className={styles.message}>
                                                    講座リクエストが下書きとして保存されました！
                                                </p>
                                            </div>
                                            <div className={styles.buttonsWrapper}>
                                                <Button component={Link} to="/" className={styles.toHomeButton}>
                                                    <AiFillHome className={styles.homeIcon} />
                                                    <div className={styles.text}>ホームに戻る</div>
                                                </Button>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className={styles.messageWrapper}>
                                                <p className={styles.message}>
                                                    講座リクエストが{props.currentRequestId ? "更新" : "作成"}
                                                    されました！
                                                </p>
                                                <p className={styles.message}>先生からの提案をお待ちください。</p>
                                            </div>
                                            <div className={styles.buttonsWrapper}>
                                                <Button component={Link} to="/" className={styles.toHomeButton}>
                                                    <AiFillHome className={styles.homeIcon} />
                                                    <div className={styles.text}>ホームに戻る</div>
                                                </Button>
                                                <Button
                                                    component={Link}
                                                    to={`/PublicRequestDetails/${props.newRequestId}`}
                                                    className={styles.toCourseDetailsButton}
                                                >
                                                    <AiFillBook className={styles.bookIcon} />
                                                    <div className={styles.text}>講座リクエストを確認する</div>
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <div className={styles.createPublicRequestTitle}>
                                        講座リクエスト{props.currentRequestId ? "編集" : "作成"}
                                    </div>
                                    {props.checked && props.courseType && props.howManyErrors !== 0 && (
                                        <div className={styles.errors}>
                                            入力内容に不備があります。修正後、もう一度「確認」を押してください。
                                        </div>
                                    )}
                                    {props.currentRequestId && !props.courseType ? (
                                        <CircularProgress />
                                    ) : (
                                        <>
                                            <ul className={styles.inputList}>
                                                <CourseTypeItem
                                                    checked={props.checked}
                                                    validation={props.courseType != undefined}
                                                    courseType={props.courseType}
                                                    handleChange={props.handleCourseTypeChange}
                                                />
                                                {props.courseType && (
                                                    <>
                                                        <Title
                                                            checked={props.checked}
                                                            value={props.title}
                                                            validation={props.getIsValid("title", props.title.length)}
                                                            handleChange={props.handleTitleChange}
                                                        />
                                                        {props.subjects && (
                                                            <Subjects
                                                                checked={props.checked}
                                                                subjects={props.subjects}
                                                                validation={props.subjects.length !== 0}
                                                                courseType={props.courseType}
                                                                setSubjects={props.setSubjects}
                                                            />
                                                        )}
                                                        <Detail
                                                            checked={props.checked}
                                                            value={props.publicRequestDescription as string}
                                                            validation={props.getIsValid(
                                                                "publicRequestDescription",
                                                                props.publicRequestDescription.length,
                                                            )}
                                                            courseType={props.courseType}
                                                            handleChange={props.handleRequestDescriptionChange}
                                                        />
                                                        <ScheduleDescription
                                                            handleChange={props.handleScheduleDescriptionChange}
                                                            checked={props.checked}
                                                            value={props.scheduleDescription as string}
                                                            validation={props.getIsValid(
                                                                "scheduleDescription",
                                                                props.scheduleDescription.length,
                                                            )}
                                                            courseType={props.courseType}
                                                        />
                                                        {props.courseType === "regular" && (
                                                            <StartDateDescription
                                                                handleChange={props.handleStartDateDescriptionChange}
                                                                checked={props.checked}
                                                                value={props.firstDateDescription as string}
                                                                validation={props.getIsValid(
                                                                    "firstDateDescription",
                                                                    props.firstDateDescription.length,
                                                                )}
                                                            />
                                                        )}
                                                        <ApplicationPeriod
                                                            applicationPeriod={props.applicationPeriod}
                                                            courseType={props.courseType}
                                                            validation={props.getIsValid(
                                                                "applicationPeriod",
                                                                props.applicationPeriod,
                                                            )}
                                                            checked={props.checked}
                                                            handleChange={props.handleApplicationPeriodChange}
                                                        />
                                                        <PriceComponent
                                                            checked={props.checked}
                                                            courseType={props.courseType}
                                                            minPrice={props.minPrice}
                                                            maxPrice={props.maxPrice}
                                                            validation={
                                                                0 < props.minPrice && props.minPrice < props.maxPrice
                                                            }
                                                            handlePriceChange={props.handlePriceChange}
                                                        />
                                                        <Remarks
                                                            handleChange={props.handleAdditionalDescriptionChange}
                                                            checked={props.checked}
                                                            value={props.additionalDescription as string}
                                                            validation={
                                                                props.additionalDescription === "" ||
                                                                props.getIsValid(
                                                                    "additionalDescription",
                                                                    props.additionalDescription.length,
                                                                )
                                                            }
                                                            courseType={props.courseType}
                                                        />
                                                    </>
                                                )}
                                            </ul>
                                            <div className={styles.temporarySaveAndCheckButtonsWrapper}>
                                                <Button
                                                    className={styles.temporarySaveButton}
                                                    onClick={() => {
                                                        (async () => {
                                                            await props.handleDraftButtonClick();
                                                        })();
                                                    }}
                                                >
                                                    下書きとして保存
                                                </Button>
                                                <Button
                                                    className={styles.checkButton}
                                                    onClick={() => {
                                                        props.handleCheckButtonClick();
                                                    }}
                                                >
                                                    確認
                                                </Button>
                                            </div>
                                            <Modal
                                                open={props.open}
                                                onClose={() => {
                                                    props.setOpen(false);
                                                }}
                                                aria-labelledby="simple-modal-title"
                                                aria-describedby="simple-modal-description"
                                                className={styles.noticeModal}
                                            >
                                                <div
                                                    className={styles.modalContents}
                                                    style={{ maxHeight: `${props.modalMaxHeight}px` }}
                                                >
                                                    <div className={styles.message}>
                                                        {props.currentRequestId
                                                            ? "以下のように講座リクエストの内容を更新します。よろしいですか？"
                                                            : "以下の内容で講座リクエストを作成します。よろしいですか？"}
                                                    </div>
                                                    <ul className={styles.inputList}>
                                                        <CourseTypeItem
                                                            open={true}
                                                            checked={props.checked}
                                                            courseType={props.courseType}
                                                            validation={true}
                                                            handleChange={props.handleCourseTypeChange}
                                                        />
                                                        {props.courseType && (
                                                            <>
                                                                <Title
                                                                    checked={props.checked}
                                                                    value={props.title}
                                                                    open={true}
                                                                    validation={true}
                                                                    handleChange={props.handleTitleChange}
                                                                />
                                                                {props.subjects && (
                                                                    <Subjects
                                                                        open={true}
                                                                        validation={true}
                                                                        checked={props.checked}
                                                                        subjects={props.subjects}
                                                                        courseType={props.courseType}
                                                                        setSubjects={props.setSubjects}
                                                                    />
                                                                )}
                                                                <Detail
                                                                    open={true}
                                                                    validation={true}
                                                                    checked={props.checked}
                                                                    value={props.publicRequestDescription as string}
                                                                    courseType={props.courseType}
                                                                    handleChange={props.handleRequestDescriptionChange}
                                                                />
                                                                <ScheduleDescription
                                                                    open={true}
                                                                    validation={true}
                                                                    handleChange={props.handleScheduleDescriptionChange}
                                                                    checked={props.checked}
                                                                    value={props.scheduleDescription as string}
                                                                    courseType={props.courseType}
                                                                />
                                                                {props.courseType === "regular" && (
                                                                    <StartDateDescription
                                                                        open
                                                                        handleChange={
                                                                            props.handleStartDateDescriptionChange
                                                                        }
                                                                        checked={props.checked}
                                                                        value={props.firstDateDescription as string}
                                                                        validation={true}
                                                                    />
                                                                )}
                                                                <ApplicationPeriod
                                                                    open={true}
                                                                    validation={true}
                                                                    applicationPeriod={props.applicationPeriod}
                                                                    courseType={props.courseType}
                                                                    checked={props.checked}
                                                                    handleChange={props.handleApplicationPeriodChange}
                                                                />
                                                                <PriceComponent
                                                                    open={true}
                                                                    checked={props.checked}
                                                                    courseType={props.courseType}
                                                                    minPrice={props.minPrice}
                                                                    maxPrice={props.maxPrice}
                                                                    validation={true}
                                                                    handlePriceChange={props.handlePriceChange}
                                                                />
                                                                <Remarks
                                                                    open={true}
                                                                    validation={true}
                                                                    checked={props.checked}
                                                                    value={props.additionalDescription as string}
                                                                    courseType={props.courseType}
                                                                    handleChange={
                                                                        props.handleAdditionalDescriptionChange
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    </ul>
                                                    <div className={styles.buttonsWrapper}>
                                                        <WhiteButton
                                                            handleClick={() => {
                                                                props.setOpen(false);
                                                            }}
                                                        >
                                                            戻る
                                                        </WhiteButton>
                                                        <NavyButton
                                                            handleClick={() => {
                                                                (async () => {
                                                                    await props.handleConfirmButtonClick();
                                                                })();
                                                            }}
                                                        >
                                                            {props.processing ? (
                                                                <CircularProgress
                                                                    className={styles.processing}
                                                                    size={md ? 30 : 24}
                                                                />
                                                            ) : (
                                                                "確定"
                                                            )}
                                                        </NavyButton>
                                                    </div>
                                                </div>
                                            </Modal>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
});
