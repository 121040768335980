import { AnnouncementResponse } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useQueryResult } from "./utils/parseResult";

export const useGetStudentAnnouncementsQuery = (studentId: string) => {
    const result = defaultApi.endpoints.getStudentAnnouncements.useQuery({ studentId: studentId });
    return useQueryResult(result, (response: AnnouncementResponse[]) => response);
};

export const useGetTeacherAnnouncementsQuery = (teacherId: string) => {
    const result = defaultApi.endpoints.getTeacherAnnouncements.useQuery({ teacherId: teacherId });
    return useQueryResult(result, (response: AnnouncementResponse[]) => response);
};
