import { Button } from "@material-ui/core";
import styles from "@/pages/Common/AboutStudent/index.module.scss";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { useCallback, useState } from "react";

export const MatchingTypes: React.VFC = () => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const userMode = useSelector((state: RootState) => state.jwt.userMode);

    const studentId = useSelector((state: RootState) => state.jwt.studentId);

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const history = useHistory();

    const handleCreatePublicRequestButtonClick = useCallback(() => {
        history.push("/CreatePublicRequest");
    }, []);

    const handleSearchCourseButtonClick = useCallback(() => {
        history.push("/SearchResults?searchType=course");
    }, []);

    return (
        <div className={styles.matchingTypeListWrapper}>
            <div className={styles.matchingTypeList}>
                <div className={styles.matchingType}>
                    <div className={styles.textWrapper}>
                        <div className={styles.matchingTypeTitle}>①講座リクエストを作成して先生を募集</div>
                        <div className={styles.descriptionWrapper}>
                            <div className={styles.description}>
                                Trailでは講座リクエストを作成して、自分のニーズを満たしてくれる先生を募集することができます。
                            </div>
                            <div className={styles.description}>
                                例えば「古典文法をイチから教えてほしい」「学校の数学を定期的にフォローしてほしい」など、あなたの希望に合わせて講座リクエストを作成します。
                            </div>
                            <div className={styles.description}>
                                しかも料金は予算に合わせて自由に設定可能！講座リクエストに対して集まった先生からの提案の中から比較検討して、最高の先生と繋がりましょう！
                            </div>
                        </div>
                        <div className={styles.stepsWrapper}>
                            <div className={styles.stepsTitle}>簡単4STEP</div>
                            <ol className={styles.steps}>
                                <li className={styles.step}>フォーム入力して講座リクエストを作成</li>
                                <li className={styles.step}>先生からの提案を待つ</li>
                                <li className={styles.step}>提案してくれた先生の中から先生を選ぶ</li>
                                <li className={styles.step}>選んだ先生と授業をする</li>
                            </ol>
                        </div>
                    </div>
                    <Button className={styles.button} onClick={handleCreatePublicRequestButtonClick}>
                        講座リクエストを作成
                    </Button>
                </div>
                <div className={styles.matchingType}>
                    <div className={styles.textWrapper}>
                        <div className={styles.matchingTypeTitle}>②先生が既に公開している講座を受講</div>
                        <div className={styles.descriptionWrapper}>
                            <div className={styles.description}>
                                Trailでは各先生が自分の強みを活かした独自の講座を公開しています。
                            </div>
                            <div className={styles.description}>
                                検索機能もあるので、興味のある講座を探してみましょう！
                            </div>
                            <div className={styles.description}>
                                講座の例：「現役名大生が学校の授業をフォローします！」「【古文嫌いから大得意へ】古典文法徹底攻略」
                            </div>
                        </div>
                    </div>
                    <Button className={styles.button} onClick={handleSearchCourseButtonClick}>
                        講座を検索
                    </Button>
                </div>
            </div>
        </div>
    );
};
