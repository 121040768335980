import { memo, useCallback, useState } from "react";

import { Button } from "@material-ui/core";

import styles from "./index.module.scss";

import defaultCourseImage from "@/images/defaultCourseImage.jpg";
import { CourseResponse, ClassResponse } from "@/store/autogenApi";
import { useHistory } from "react-router";
import { OverWrapper } from "@/components/OverWrapper";
import { AiOutlineRollback } from "react-icons/ai";
import { NextLessonInfo } from "./NextLessonInfo";
import { PlainButton } from "@/components/PlainButton";
import { UpdateClassNameModal } from "./UpdateClassNameModal";
import { ImageFromS3 } from "@/components/atoms/images/ImageFromS3";

interface Props {
    course: CourseResponse;
    targetClass: ClassResponse | undefined;
    handleBackButtonClick: () => void;
}

export const ManagementTopContents: React.VFC<Props> = memo(function ManagementTopContents(props) {
    const [updateClassNameModalOpen, setUpdateClassNameModalOpen] = useState<boolean>(false);

    const history = useHistory();

    const handleDetailsButtonClick = useCallback(() => {
        if (props.course.publicRequest) {
            history.push(`/PublicRequestDetails/${props.course.publicRequest.publicRequestId}`);
        } else {
            history.push(`/CourseDetails/${props.course.courseId}`);
        }
    }, [props.course]);

    const handleCourseEditButtonClick = useCallback(() => {
        const draftCourseQuery = props.course.draftCourse
            ? `?draftCourseId=${props.course.draftCourse.draftCourseId}`
            : "";
        history.push(`/CreateCourse/${props.course.courseId}${draftCourseQuery}`);
    }, [props.course]);

    const getTitle = useCallback(() => {
        if (props.targetClass) {
            return `${props.targetClass.className}(${props.course.title} )`;
        } else {
            return props.course.title;
        }
    }, [props.targetClass, props.course]);

    const handleUpdateClassNameButtonClick = useCallback(() => {
        setUpdateClassNameModalOpen(true);
    }, []);

    const handleUpdateClassNameModalClose = useCallback(() => {
        setUpdateClassNameModalOpen(false);
    }, []);

    return (
        <OverWrapper style={{ backgroundColor: "#F1F1F1" }}>
            <div className={styles.managementTopContents}>
                <div className={styles.basicInfoWrapper}>
                    <div className={styles.imageAndCommonWrapper}>
                        {!props.course.publicRequest && (
                            <ImageFromS3
                                url={props.course.thumbnailUrl ?? defaultCourseImage}
                                objectKey={props.course.thumbnailObjectKey}
                                className={styles.courseBaseImage}
                            />
                        )}
                        <div className={styles.commonWrapper}>
                            <div className={styles.courseType}>
                                {props.course.courseType === "short" ? "単発・短期講座" : "定期講座"}
                            </div>
                            <div className={styles.titleWrapper}>
                                <div className={styles.title}>{getTitle()}</div>
                                {props.targetClass && (
                                    <>
                                        <PlainButton text="変更" handleClick={handleUpdateClassNameButtonClick} />
                                        <UpdateClassNameModal
                                            targetClass={props.targetClass}
                                            modalOpen={updateClassNameModalOpen}
                                            handleModalClose={handleUpdateClassNameModalClose}
                                        />
                                    </>
                                )}
                            </div>
                            {!props.targetClass && (
                                <div className={styles.buttonsWrapper}>
                                    <Button onClick={handleDetailsButtonClick} className={styles.courseDetailsButton}>
                                        詳細
                                    </Button>
                                    {!props.course.publicRequest && (
                                        <Button
                                            onClick={handleCourseEditButtonClick}
                                            className={styles.courseBaseEditButton}
                                        >
                                            基本情報を編集
                                        </Button>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    {props.targetClass && (
                        <Button className={styles.backToClassesButton} onClick={props.handleBackButtonClick}>
                            <AiOutlineRollback className={styles.backIcon} />
                            <div className={styles.backToClasses}>クラス一覧に戻る</div>
                        </Button>
                    )}
                </div>
                {props.targetClass && (
                    <NextLessonInfo
                        courseId={props.course.courseId}
                        courseType={props.course.courseType}
                        targetClass={props.targetClass}
                    />
                )}
            </div>
        </OverWrapper>
    );
});
