import { memo, useCallback, useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";

import styles from "./index.module.scss";
import { ACCEPTED_MIME_TYPES } from "../FilesContents/AcceptedMIMETypes";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { Button, CircularProgress, TextareaAutosize } from "@material-ui/core";
import { TbBrandZoom } from "react-icons/tb";
import { PersonalMeetingModal } from "../PersonalMeetingModal";
import { RiSendPlane2Fill } from "react-icons/ri";

interface Props {
    isProcessing: boolean;
    message: string;
    handleFilesChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleMessageChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    handleSendButtonClick: () => Promise<void>;
    handleCreateMeetingButtonClick: () => Promise<void>;
}

export const MessageInput: React.VFC<Props> = memo(function MessageInput(props) {
    const [personalMeetingModalOpen, setPersonalMeetingModalOpen] = useState<boolean>(false);
    const userMode = useSelector((state: RootState) => state.jwt.userMode as string);

    useEffect(() => {
        if (!props.isProcessing) {
            setPersonalMeetingModalOpen(false);
        }
    }, [props.isProcessing]);

    const handlePersonalMeetingModalOpen = useCallback(() => {
        setPersonalMeetingModalOpen(true);
    }, []);
    const handlePersonalMeetingModalClose = useCallback(() => {
        setPersonalMeetingModalOpen(false);
    }, []);
    const handleCreateMeetingButtonClick = useCallback(async () => {
        await props.handleCreateMeetingButtonClick();
    }, [props.handleCreateMeetingButtonClick]);

    return (
        <div className={styles.messageInputWrapper}>
            <label className={styles.fileInputLabel} htmlFor="fileInput">
                <AiOutlinePlus className={styles.plusIcon}></AiOutlinePlus>
            </label>
            <input
                id="fileInput"
                type="file"
                accept={ACCEPTED_MIME_TYPES.join(",")}
                className={styles.fileInput}
                onClick={(e) => {
                    e.currentTarget.value = "";
                }}
                onChange={props.handleFilesChange}
                multiple
            />
            {userMode === "teacher" && (
                <>
                    <Button className={styles.createMeetingButton} onClick={handlePersonalMeetingModalOpen}>
                        <TbBrandZoom className={styles.zoomIcon} />
                    </Button>
                    {personalMeetingModalOpen !== undefined && (
                        <PersonalMeetingModal
                            open={personalMeetingModalOpen}
                            isCreating={props.isProcessing}
                            handleClose={handlePersonalMeetingModalClose}
                            handleCreateMeetingButtonClick={handleCreateMeetingButtonClick}
                        />
                    )}
                </>
            )}
            <TextareaAutosize
                required
                placeholder="メッセージを入力"
                className={styles.messageInput}
                name="messageInput"
                value={props.message}
                onChange={props.handleMessageChange}
                minRows={1}
                maxRows={15}
            />
            <Button
                className={styles.sendButton}
                style={{ pointerEvents: props.message === "" || props.isProcessing ? "none" : "auto" }}
                onClick={props.handleSendButtonClick}
            >
                {props.isProcessing && !personalMeetingModalOpen ? (
                    <CircularProgress className={styles.circularProgress} />
                ) : (
                    <RiSendPlane2Fill
                        className={styles.sendIcon}
                        style={{ color: props.message === "" ? "#CCC" : "#305077" }}
                    />
                )}
            </Button>
        </div>
    );
});
