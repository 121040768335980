import { useState, useEffect, memo, useCallback } from "react";

import { Button } from "@material-ui/core";
import { BiTime } from "react-icons/bi";

import styles from "@/pages/Common/PublicRequestDetails/index.module.scss";
import { WHAT_DAY_LIST } from "@/utils/WhatDayList";

interface Props {
    deadline?: Date;
}

export const Deadline: React.VFC<Props> = memo((props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOver, setIsOver] = useState<boolean>(false);

    const showMore = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    useEffect(() => {
        const detailNode = document.getElementById("detail");
        const scrollHeight = detailNode?.scrollHeight;
        if (scrollHeight && scrollHeight > 200) {
            setIsOver(true);
        }
    }, [props.deadline]);

    const getTime = useCallback(() => {
        if (props.deadline) {
            const fixedData = new Date(props.deadline);
            const month = fixedData.getMonth() + 1;
            const date = fixedData.getDate();
            const hour = ("0" + fixedData.getHours()).slice(-2);
            const minute = ("0" + fixedData.getMinutes()).slice(-2);
            const day = WHAT_DAY_LIST[fixedData.getDay()];
            const result = `${month}月${date}日(${day}) ${hour}:${minute}`;
            return result;
        } else {
            return "";
        }
    }, [props.deadline]);

    return (
        <div className={styles.detailWrapper}>
            <div className={styles.detailHeadingWrapper}>
                <span className={styles.detailHeading}>募集締め切り日時</span>
            </div>
            <div
                className={styles.detail}
                style={{ overflowY: isOpen ? "auto" : "hidden", maxHeight: isOpen ? "none" : "200px" }}
                id="detail"
            >
                <div>{props.deadline && getTime()}</div>
            </div>
            {isOver && (
                <div className={styles.buttonWrapper} style={{ position: isOpen ? "inherit" : "absolute" }}>
                    <Button className={styles.detailButton} onClick={showMore}>
                        {isOpen ? "閉じる" : "すべて見る"}
                    </Button>
                </div>
            )}
        </div>
    );
});
