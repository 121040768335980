import styles from "./index.module.scss";
import { memo } from "react";

interface Props {
    className?: string;
}

export const TermsOfServiceContents: React.VFC<Props> = memo(function TermsOfServiceContents(props) {
    return (
        <>
            <section className={`${styles.termsOfService} ${props.className}`}>
                <section className={styles.sentence}>
                    本利用規約 (以下「本規約」といいます。)には、株式会社オノレ
                    (以下「当社」といいます。)の提供するサービスのご利用にあたり、登録ユーザーの皆様に遵守していただかなければならない事項及び当社と登録ユーザーの皆様との間の権利義務関係が定められております。当該サービスを登録ユーザーとしてご利用になる方は、本規約に同意する前に、必ず全文お読み下さいますようお願いいたします。
                </section>
                <section className={styles.chapterWrapper}>
                    <h3 className={styles.heading}>第1章 総則</h3>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第1条 適用</h4>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1. 本規約は、本サービス (第2条に定義)の利用に関する当社と登録ユーザー
                                (第2条に定義)との間の権利義務関係を定めることを目的とし、登録ユーザーと当社の間の本サービスの利用に関わる一切の関係に適用されます。
                            </li>
                            <li className={styles.item}>
                                2. 登録ユーザーには生徒 (第2条に定義)としての登録ユーザーと、先生
                                (第2条に定義)としての登録ユーザーが存在します。本規約の第1章及び第4章の規定は全ての登録ユーザーに適用されますが、第2章は生徒としての権利義務にのみ、第3章は先生としての権利義務にのみ適用されるものとします。なお、生徒と先生の両方の立場において本サービスを利用することも可能であり、その場合は第2章及び第3章の双方の規定が適用されます。
                            </li>
                            <li className={styles.item}>
                                3.
                                本サービスは、生徒が興味をもった授業を探して受講予約をするか、授業リクエストを作成することで先生がそれに提案をすることで、生徒と先生とのオンラインマッチングを行うプラットフォームを提供するものであり、当社は、先生と生徒間の問題に関し、一切の責任を負わないものとします。
                            </li>
                            <li className={styles.item}>
                                4. 当社が当社ウェブサイト
                                (第2条に定義)上で随時掲載する本サービスに関するルール、諸規定等は本規約の一部を構成するものとします。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第2条 定義</h4>
                        <section className={styles.description}>
                            本規約において使用する以下の用語は各々以下に定める意味を有するものとします。
                        </section>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                (1)
                                「講座ページ」とは、本サービス上において先生が提供する講座の内容を記載したウェブページのことを意味します。
                            </li>
                            <li className={styles.item}>
                                (2)
                                「講座」とは、先生が講座ページにて宣言した内容で受講契約を結んだ生徒を集め、講座を執り行う場を意味します。
                            </li>
                            <li className={styles.item}>
                                (3) 「受講契約」とは、第7条第2項に定義する「受講契約」を意味するものとします。
                            </li>
                            <li className={styles.item}>
                                (4) 「受講料」とは、第7条第3項に定義する「受講料」を意味するものとします。
                            </li>
                            <li className={styles.item}></li>
                            <li className={styles.item}>
                                (5)
                                「生徒」とは、生徒として本サービスに掲載されている講座を受けることを目的として利用している登録ユーザーを意味します。
                            </li>
                            <li className={styles.item}>
                                (6) 「先生」とは、先生として講座によるサービスを提供する登録ユーザーを意味します。
                            </li>
                            <li className={styles.item}>
                                (7) 「知的財産権」とは、著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権
                                (それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。)を意味します。
                            </li>
                            <li className={styles.item}>
                                (8)
                                「手数料」とは、先生が本サービスの対価として当社に支払う手数料を意味するものとします。
                            </li>
                            <li className={styles.item}>
                                (9)
                                「当社ウェブサイト」とは、そのドメインが「trail-int.com/」である当社が運営するウェブサイト
                                (理由の如何を問わず当社のウェブサイトのドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含みます。)を意味します。
                            </li>
                            <li className={styles.item}>
                                (10) 「登録希望者」とは、第3条において定義された「登録希望者」を意味します。
                            </li>
                            <li className={styles.item}>
                                (11) 「登録情報」とは、第3条において定義された「登録情報」を意味します。
                            </li>
                            <li className={styles.item}>
                                (12)
                                「登録ユーザー」とは、第3条に基づき本サービスの利用者としての登録がなされた個人又は法人を意味します。
                            </li>
                            <li className={styles.item}>
                                (13) 「ポイント」とは、第13条第1項に定義する「ポイント」を意味するものとします。
                            </li>
                            <li className={styles.item}>
                                (14)
                                「本サービス」とは、生徒が興味をもった授業を探して受講予約をするか、授業リクエストを作成することで先生がそれに提案をすることで、生徒と先生とのオンラインマッチングを行うTrailという名称のサービス
                                (理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含みます。)を意味します。
                            </li>
                            <li className={styles.item}>
                                (15)「講座リクエスト」とは、生徒が不特定多数の先生を対象として希望する内容のサービスを提供してくれる先生を募集することを意味します。
                            </li>
                            <li className={styles.item}>
                                (16)
                                「講座リクエストページ」とは、本サービス上において生徒が作成する講座リクエストの内容を記載したウェブページのことを意味します。
                            </li>
                            <li className={styles.item}>
                                (17)
                                「利用契約」とは、第3条第5項に基づき当社と登録ユーザーの間で成立する、本規約及びそれに付随して適用されるその他一切の規則又は規約等
                                (「Trail講座受講ガイドライン」、「Trail先生ガイドライン」、「ご利用ガイド」、「Trailレビュー投稿ガイドライン」及びプライバシーポリシーを含みますが、これらに限りません)の諸規定に従った本サービスの利用規約の総称を意味します。
                            </li>
                            <li className={styles.item}>
                                (18)
                                「連絡」とは、本サービス上の関係において生徒と先生の間で行われるコミュニケーション行為全般を意味します。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第3条 登録</h4>
                        <section className={styles.description}></section>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1. 本サービスの利用を希望する者
                                (以下「登録希望者」といいます。)は、本規約及び当社が定める各ガイドラインを遵守することに同意し、かつ、プライバシーポリシーが適用されることに同意したものとみなします。また、当社が定める一定の情報
                                (以下「登録情報」といいます。)を当社が定める方法で当社に提供することにより本サービスの利用の登録を申請することができます。
                            </li>
                            <li className={styles.item}>
                                2.
                                登録の申請は必ず本サービスを利用する個人又は法人自身が行わなければならず、原則として代理人による登録申請は認められません。また、登録希望者は、登録の申請にあたり、真実、正確かつ最新の情報を当社に提供しなければなりません。
                            </li>
                            <li className={styles.item}>
                                3.
                                本サービスを利用する登録ユーザーは、1つのアカウントを利用するものとし、複数のアカウントを登録及び保有してはならないものとします。
                            </li>
                            <li className={styles.item}>
                                4.
                                当社は、当社の基準に従って、登録希望者の登録の可否を判断し、当社が登録を認める場合にはその旨を登録希望者に通知し、この通知により登録希望者の登録ユーザーとしての登録は完了したものとします。
                            </li>
                            <li className={styles.item}>
                                5.
                                前項に定める登録の完了時に、本規約の諸規定に従った本サービスの利用契約が登録ユーザーと当社の間に成立し、登録ユーザーは本サービスを当社の定める方法で利用することができるようになります。
                            </li>
                            <li className={styles.item}>
                                6.
                                本サービスの利用にあたり、当社は登録ユーザーに対して当社所定の方法により本人確認手続きを依頼する場合があります。本人確認手続きが完了しない又は本人確認手続きが非承認とされた場合には、当社は当該登録ユーザーの本サービスの利用の一部又は全部を停止、又は登録ユーザーとしての登録を取り消すことができます。当社は、本項の停止又は登録取消により登録ユーザーに発生した損害について一切の責任を負わないものとします。
                            </li>
                            <li className={styles.item}>
                                7. 先生向けの支払処理サービスは、決済サービス
                                <a href="https://stripe.com/jp" target="_blank">
                                    Stripe
                                </a>
                                が提供し、
                                <a href="https://stripe.com/connect-account/legal/full" target="_blank">
                                    Stripe Connectアカウント契約
                                </a>
                                （
                                <a href="https://stripe.com/legal" target="_blank">
                                    Stripe利用規約
                                </a>
                                を含み、総称して「Stripeサービス契約」といいます。）に従うものとします。本利用規約への同意により、お客様はStripeサービス契約（随時Stripeにより修正されることがあり、その場合には修正されたものを含みます。）に拘束されることに同意するものとします。
                                Stripeを通じた支払処理サービスを利用するための条件として、お客様は、当社に対してお客様及びお客様の事業に関する正確かつ完全な情報を提供することに同意し、当社が当該情報及びStripeが提供する支払処理サービスのお客様による使用に関連する取引情報を共有することを認めるものとします。
                            </li>
                            <li className={styles.item}>
                                8.
                                当社は、第1項に基づき登録を申請した者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。
                                <ol className={styles.smallItems}>
                                    <li className={styles.smallItem}>
                                        (1) 本規約に違反するおそれがあると当社が判断した場合
                                    </li>
                                    <li className={styles.smallItem}>
                                        (2)
                                        当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
                                    </li>
                                    <li className={styles.smallItem}></li>
                                    <li className={styles.smallItem}></li>
                                    <li className={styles.smallItem}></li>
                                    <li className={styles.smallItem}></li>
                                </ol>
                            </li>
                            <li className={styles.item}>(3) 過去に本サービスの利用の登録を取り消された者である場合</li>
                            <li className={styles.item}>
                                (4)
                                未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人､保佐人又は補助人の同意等を得ていなかった場合
                            </li>
                            <li className={styles.item}>
                                (5) 反社会的勢力等
                                (暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。)である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合
                            </li>
                            <li className={styles.item}>(6) その他、当社が登録を適当でないと判断した場合</li>
                        </ol>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第4条 登録情報の変更</h4>
                        <section className={styles.description}>
                            登録ユーザーは、登録情報に変更があった場合は、遅滞なく、当社の定める方法により、当該変更事項を当社に通知し、当社から要求された資料を提出するものとします。
                        </section>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第5条 パスワード及びユーザーIDの管理</h4>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1.
                                登録ユーザーは、自己の責任において、パスワード及びユーザーIDを管理及び保管するものとし、これを第三者に利用させたり、貸与、譲渡、名義変更、売買等をしてはならないものとします。
                            </li>
                            <li className={styles.item}>
                                2.
                                パスワード又はユーザーIDの管理不十分、使用上の過誤、第三者の使用等による損害の責任は登録ユーザーが負うものとし、当社は一切の責任を負いません。
                            </li>
                            <li className={styles.item}>
                                3.
                                登録ユーザーは、パスワード又はユーザーIDが盗まれたり、第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第6条 プロフィールの公開</h4>
                        <section className={styles.description}>
                            登録ユーザーは、自らのプロフィールについて、「公開」に設定した箇所については、第三者
                            (登録ユーザーを含みますが、これに限られません。)に公開がなされること及び本サービスにおいては、デフォルトで全てのプロフィールが公開される設定となっていることに予め同意するものとします。当社は、本項の公開により登録ユーザーに発生した損害について一切の責任を負わないものとします。
                        </section>
                    </section>
                </section>
                <section className={styles.chapterWrapper}>
                    <h3 className={styles.heading}>第2章 生徒</h3>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第7条 講座の受講</h4>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1.
                                生徒は、事前に講座料金に対応するポイントを支払うことにより、講座を予約することができます。
                            </li>
                            <li className={styles.item}>
                                2.
                                前項の予約手続が完了した場合、生徒に対し予約完了の通知が行われるものとし、当該通知が生徒に到達した時点で、先生と生徒の間に生徒が予約した講座の受講に関する契約
                                (以下「受講契約」といいます。)が成立するものとします。但し、生徒の責に帰すべき事由により当該通知が到達しなかった場合には、単発・短期到達すべき時点において当該通知が到達したものとみなして受講契約が成立するものとします。
                            </li>
                            <li className={styles.item}>
                                3. 受講料は、各先生が独自に設定し、生徒の合意の上で支払いが行われるものとします。
                                受講料はクレジットカード払いとなります。
                            </li>
                            <li className={styles.item}></li>
                        </ol>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第8条 講座のキャンセル及び異議の申し出 </h4>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1.
                                生徒は、予約締切までは予約をキャンセルすることができるものとし、キャンセルされた講座に関する受講契約は取り消され、全額返金されるものとします。予約締切日を経過した場合には、生徒は予約をキャンセルすることはできないものとします。
                            </li>
                            <li className={styles.item}>
                                2.
                                生徒は、講座が開催されてから1週間以内に限り、講座の提供についての異議を当社に対して述べることができるものとします
                                (以下、本条において当該期間を「異議申出期間」といいます。)。生徒がかかる異議を述べた場合において、先生が講座の提供を怠っていた等、当社が生徒の異議に理由があると認める場合には、生徒が支払った受講料を全額返還します。異議申出期間中に生徒から異議が述べられなかった場合には、先生が適切な講座の役務提供を行ったものとみなし、以後生徒は異議を述べることはできないものとします。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第9条 ポイント</h4>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1. 当社は、受講料として利用することができるTrailポイント
                                (以下、単に「ポイント」といいます。)を発行する場合があります。ポイントは有償で発行する場合及び無償で発行する場合があります。ポイントの価格、付与の条件その他の内容については、当社が個別に決定するものとします。
                            </li>
                            <li className={styles.item}>
                                2.
                                ポイントは返金することができないものとし、生徒はいかなる場合でも当社に対し、払い戻しを請求することはできないものとします。また、生徒は、ポイントを第三者に利用させたり、貸与、譲渡、名義変更、売買等をしてはならないものとします。
                            </li>
                            <li className={styles.item}>
                                3.
                                ポイントの具体的な使用期限については個々のポイント発行の際に当社が決定するものとします。なお、有償で発行するポイントの使用期限についてはいかなる場合でも180日を超えることはないものとします。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第10条 生徒の義務等</h4>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1.
                                生徒は、先生の合理的な指示に従って、自らが予約した講座等に誠実に参加しなければならないものとします。
                            </li>
                            <li className={styles.item}>
                                2.
                                生徒は、当社から講座等に関する報告を求める旨の合理的な請求があった場合には、当該請求に従って回答するものとします。
                            </li>
                            <li className={styles.item}>
                                3.
                                生徒は、先生が講座等を提供するために必要な範囲で、当社から先生に対して登録情報の全部又は一部が提供される場合があることに予め同意し、これについて一切の異議を述べないものとします。
                            </li>
                            <li className={styles.item}>
                                4.
                                生徒は、先生が特に許可した場合において、自らの子供その他の第三者と共に講座等の提供を受ける場合、当該第三者が先生の指示に従って講座等を受講するよう適切な措置をとるものとし、かつ、当該第三者の行為について一切の責任を負うものとします。
                            </li>
                        </ol>
                    </section>
                </section>
                <section className={styles.chapterWrapper}>
                    <h3 className={styles.heading}>第3章 先生</h3>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第11条 講座の提供</h4>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1. 先生は、当社の定めるところに従い、講座ページを作成することができるものとします。
                            </li>
                            <li className={styles.item}>
                                2.
                                前項の講座ページの作成後、第7条第2項に従って、受講契約が成立した場合には、先生は、生徒に対し、生徒が予約した講座ページの内容に従って講座を提供する義務を負うものとします。
                            </li>
                            <li className={styles.item}>
                                3.
                                先生は、講座の開催日時を公開した後は、当社の承諾なく講座をキャンセルすることはできないものとします。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第12条 受講料及び手数料</h4>
                        <section className={styles.description}></section>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1.
                                先生が当社に対して振込申請時に支払う手数料は、月初に定められる先生ランクに応じた割合とします。かかる手数料は、消費税相当額を含む金額を意味するものとします。
                            </li>
                            <li className={styles.item}>
                                2.
                                先生は、当社に対し、生徒から支払われる受講料を代理受領する権限を付与するものとし、生徒から直接料金を受領してはならないものとします。
                            </li>
                            <li className={styles.item}>
                                3.料金の支払時期
                                講座においては、先生は、先生が講座の内容として定めたサービスの提供を完了し、生徒からの評価が完了した場合にのみ、当社が定める手続に従って、前項に基づき当社が代理受領した受講料の支払いを受けることができるものとします。なお、定期講座に関しては、毎月の定期評価の後に支払いが行われるものとします。
                            </li>
                            <li className={styles.item}>
                                4.先生は、かかる支払に際し、第1項に定める手数料及びそれにかかる消費税相当額が控除されることに同意します。当該料金には、利息は発生しないものとします。振込手数料その他支払に必要な費用は当社の負担とします。但し、先生が支払を受ける料金の額が、別途当社が定める基準額を下回る場合には、先生は別途当社が定める管理手数料を負担するものとし、この場合管理手数料相当額が支払われる料金から控除されることには予め同意するものとします。
                            </li>
                            <li className={styles.item}>
                                5.
                                当社が第3項の講座等における異議の申し出が正当と判断した場合、若しくは講座等において法令又は利用規約違反があると判断した場合は、当社から生徒へ料金を返金することができるものとします。この場合、先生は当社に対して料金の請求は出来ないものとし、既に料金が先生に振り込まれている場合は、当社へ返金する義務を負うものとします。また、当社は利用規約違反又は講座等における異議の申し出の正当性の調査の完了までに必要な期間、先生への料金の支払いを保留することができるものとします。
                            </li>
                            <li className={styles.item}>
                                6.
                                当社のシステム及び施策を利用し、不当に売上又は何かしらの利益を取得する行為があったと当社が判断した場合、当社の調査結果をもとに損害額相当を請求すること、先生はその請求額を当社に返納することに同意するものとします。なお、その時点で当社から先生への未払いの料金がある場合、当社の判断にて当該損害相当額及び支払い済みの料金と未払料金とを対当額で相殺できるものとします。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第13条 先生の注意事項</h4>
                        <section className={styles.description}></section>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1.
                                先生は、講座等のページの作成及び講座等の提供に当たっては、当社が別途定めるガイドラインを遵守しなければならないものとします。当社は、講座等のページが当該ガイドラインに違反している場合、その他当社が必要と判断した場合には当該講座等のページを削除することができるものとします。当社は、当該削除により先生に発生した損害について一切の責任を負わないものとします。
                            </li>
                            <li className={styles.item}>
                                2.
                                先生は、前項のガイドラインを遵守することを条件として、自由な内容の講座等を開設することができますが、提供する講座等において以下の行為については行わないものとします。先生が本サービスに相応しくない行為を行ったものと当社が判断した場合、当社はその事実を本サービス上で公表することができるものとし、先生はこれに一切の異議を申し立てないものとします。当社は、公表によって先生に発生した損害について一切の責任を負わないものとします。
                                <ol className={styles.smallItems}>
                                    <li className={styles.smallItem}>(1) 犯罪行為及びその他の他人に迷惑となる行為 </li>
                                    <li className={styles.smallItem}>
                                        (2) 告知内容と著しく異なる内容で講座等を提供する行為
                                    </li>
                                    <li className={styles.smallItem}>(3) 教える行為から逸脱する行為</li>
                                    <li className={styles.smallItem}>(4) 別の営利目的や宗教への勧誘を目的とした行為</li>
                                    <li className={styles.smallItem}>
                                        (5) 生徒の個人としての人権やその他の権利を侵害するような行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        (6) その他本サービスで提供する講座等で行われるものとして相応しくない行為
                                    </li>
                                </ol>
                            </li>
                            <li className={styles.item}>
                                3.
                                先生は、自ら講座等を提供しなければならず、第三者に再委託してはならないものとします。先生は、講座について一切の責任を負うものとします。
                            </li>
                            <li className={styles.item}>
                                4.
                                先生は、生徒に対し、先生が講座等の内容として定めたサービスの提供を行わなければならないものとします。
                            </li>
                            <li className={styles.item}>
                                5.
                                先生は、当社からの指示がある場合には、講座等の内容その他当社が指定する事項について、当社が求める時期、内容及び様式により報告しなければならないものとします。
                            </li>
                            <li className={styles.item}>
                                6.
                                講座ページ掲載の順序を含む本サービス及び当社ウェブサイトのレイアウトについては、当社の裁量によって決定するものとし、先生は、これについて一切の異議を申し立てることはできないものとします。
                            </li>
                            <li className={styles.item}>
                                7.
                                当社は、本サービス上又は外部のサービス上において特定の講座等のページを紹介することがありますが、紹介は当社の裁量によって決定するものとし、先生は特定の講座等のページを掲載するよう請求することはできないものとします。
                            </li>
                            <li className={styles.item}>
                                8.
                                先生は、特定商取引に関する法律その他自らに適用のある法律を遵守しなければならないものとします。先生は、当社が生徒から特定商取引に関する法律に基づく情報の提供の依頼を受けた場合、当社がかかる情報を生徒に提供することができることに予め同意するものとします。
                            </li>
                            <li className={styles.item}>
                                9.
                                特定商取引に関する法律で定められた特定継続的役務提供に該当する講座等は、本サービス上で開催してはならないものとします。
                            </li>
                            <li className={styles.item}>
                                10.生徒に源泉徴収の義務が生じる取引を先生が行う場合は、当該取引に関わる必要事項を先生の責任で確認することとし、料金は源泉徴収後の金額とし、支払明細書等その他必要な税務上の対応については、先生の費用と責任により自ら行うものとします。
                            </li>
                        </ol>
                    </section>
                </section>
                <section className={styles.chapterWrapper}>
                    <h3 className={styles.heading}>第4章 共通事項</h3>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第14条 本サービスの利用</h4>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1.
                                登録ユーザーは、利用契約の有効期間中、本規約の目的の範囲内でかつ本規約に違反しない範囲内で、当社の定める方法に従い、本サービスを利用することができます。
                            </li>
                            <li className={styles.item}>
                                2.
                                本サービスの提供を受けるために必要な、コンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、登録ユーザーの費用と責任において行うものとします。
                            </li>
                            <li className={styles.item}>
                                3.
                                登録ユーザーは自己の本サービスの利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第15条 禁止行為</h4>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1.
                                登録ユーザーは、本サービスの利用、講座等の提供又は参加において、以下の各号のいずれかに該当する行為をしてはなりません。
                                <ol className={styles.smallItems}>
                                    <li className={styles.smallItem}>
                                        (1)
                                        当社、又は他の登録ユーザー、第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為
                                        (かかる侵害を直接又は間接に惹起する行為を含みます。)
                                    </li>
                                    <li className={styles.smallItem}>
                                        (2)
                                        犯罪予告、犯罪の指南等、犯罪を引き起こすおそれのある行為、その他犯罪行為に関連する行為又は公序良俗に反する行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        (3) ポイントを第三者に利用させたり、貸与、譲渡、名義変更、売買等する行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        (4) 受講実績がない等、不正に料金又はポイントを取得する行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        (5) 猥褻な情報又は青少年に有害な情報を送信する行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        (6) 異性交際を目的として本サービスを利用する行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        (7) 法令又は当社若しくは登録ユーザーが所属する業界団体の内部規則に違反する行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        (8)
                                        コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        (9)
                                        不正アクセス行為の禁止等に関する法律に違反する行為、電子計算機損壊等業務妨害罪
                                        (刑法第234条の2)に該当する行為をはじめ、当社、又は他の登録ユーザー、第三者のコンピューターに対して不正な操作を行う行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        (10)
                                        人種、民族、信条、性別、社会的身分、居住場所、身体的特徴、病歴、教育、財産及び収入等を根拠にする差別的表現行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        (11)
                                        倫理的に問題がある低俗、有害、下品な行為、他人に嫌悪感を与える内容の情報を開示する行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        (12) ポルノ、売春、風俗営業、これらに関連する内容の情報を開示する行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        (13)
                                        迷惑行為、嫌がらせ行為、誹謗中傷行為、その他当社、又は他の登録ユーザー、第三者に精神的被害・経済的被害を与える可能性のある行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        (14)
                                        広告又は第20条の料金以外の営利を目的として、講座等のページを作成する行為及びリンクを設置する行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        (15)
                                        先生としての講座等の開催及び生徒としての講座等の受講以外の目的で行う営業・勧誘或いはそれらと見なされる連絡行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        (16) 他者 (実在しない者を含む。)になりすまして本サービスを利用する行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        (17)
                                        ロボットその他のデータマイニング、データ収集ツールを用いると手動によるとを問わず、当社の事前の書面による承諾を得ることなく、本サービス上のコンテンツ又はデータを収集し又は複製する行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        (18)
                                        当社の事前の書面による承諾を得ることなく、他の登録ユーザーの情報その他データベース上の情報、本サービスに掲載された情報を取得することを目的とする一切の行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        (19) 本サービスに関し利用しうる情報を改ざんする行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        (20) 当社が定める一定のデータ容量以上のデータを本サービスを通じて送信する行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        (21) 当社による本サービスの運営を妨害するおそれのある行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        (22)
                                        当社又は本サービスと同一又は類似の名称をその用途、手段にかかわらず、使用する一切の行為
                                    </li>
                                    <li className={styles.smallItem}>(23) その他、当社が不適切と判断する行為 </li>
                                </ol>
                            </li>
                            <li className={styles.item}>
                                2.
                                当社は、本サービスにおける登録ユーザーによる情報の送信行為が前項各号のいずれかに該当し、又は該当するおそれがあると当社が判断した場合には、登録ユーザーに事前に通知することなく、当該情報の全部又は一部を削除及び利用停止することができるものとします。当社は、本項に基づき当社が行った措置に基づき登録ユーザーに生じた損害について一切の責任を負いません。
                            </li>
                            <li className={styles.item}>
                                3.
                                登録ユーザーは当社が犯罪行為の防止、青少年の保護、登録ユーザーの保護、健全なサービスを運営する目的で、本サービス上での登録ユーザー間のメッセージ内容を閲覧、削除することに同意するものとします。ただし、当社としてメッセージ内容の監視、巡回する義務を負うものではありません。また、当社はメッセージ内容の監視、巡回した内容につき当該対応の結果について、対応の内容及び理由を登録ユーザーに対して返答する義務を負わず、当該対応の結果、登録ユーザーに生じた損害について一切の責任を負いません。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第16条 本サービスの停止等</h4>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1.
                                当社は、以下のいずれかに該当する場合には、登録ユーザーに事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。
                                <ol className={styles.smallItems}>
                                    <li className={styles.smallItem}>
                                        (1)
                                        本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合
                                    </li>
                                    <li className={styles.smallItem}>
                                        (2) コンピューター、通信回線等が事故により停止した場合
                                    </li>
                                    <li className={styles.smallItem}>
                                        (3) 火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
                                    </li>
                                    <li className={styles.smallItem}>
                                        (4) その他、当社が停止又は中断を必要と判断した場合
                                    </li>
                                </ol>
                            </li>
                            <li className={styles.item}>
                                2.
                                当社は、当社の都合により、本サービスの提供を終了することができます。この場合、当社は登録ユーザーに事前に通知するものとします。
                            </li>
                            <li className={styles.item}>
                                3.
                                当社は、本条に基づき当社が行った措置に基づき登録ユーザーに生じた損害について一切の責任を負いません。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第17条 情報の保存</h4>
                        <section className={styles.description}>
                            当社は、登録ユーザーが送受信したメッセージその他の情報を運営上一定期間保存していた場合であっても、かかる情報を保存する義務を負うものではなく、当社はいつでもこれらの情報を削除できるものとします。なお、当社は本条に基づき当社が行った措置に基づき登録ユーザーに生じた損害について一切の責任を負いません。
                        </section>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第18条 ダウンロード等についての注意事項</h4>
                        <section className={styles.description}>
                            登録ユーザーは、本サービスの利用開始に際し又は本サービスの利用中に、当社ウェブサイトからのダウンロードその他の方法によりソフトウェア等を登録ユーザーのコンピューター等にインストールする場合には、登録ユーザーが保有する情報の消滅若しくは改変又は機器の故障、損傷等が生じないよう十分な注意を払うものとし、当社は登録ユーザーに発生したかかる損害について一切責任を負わないものとします。
                        </section>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第19条 権利帰属</h4>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1.
                                当社ウェブサイト及び本サービスに関する所有権及び知的財産権は全て当社又は当社にライセンスを許諾している者に帰属しており、本規約に定める登録に基づく本サービスの利用許諾は、当社ウェブサイト又は本サービスに関する当社又は当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。登録ユーザーは、いかなる理由によっても当社又は当社にライセンスを許諾している者の知的財産権を侵害するおそれのある行為
                                (逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これに限定されません。)をしないものとします。
                            </li>
                            <li className={styles.item}>
                                2.
                                当社ウェブサイト又は本サービスにおいて、登録ユーザーが投稿その他送信を行った文章、画像、動画その他のデータについては、当社において、無償で自由に利用
                                (複製、複写、改変、第三者への再許諾その他のあらゆる利用を含みます。)することができるものとします。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第20条 登録取消等</h4>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1.
                                当社は、登録ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、当該登録ユーザーについて本サービスの利用を一時的に停止し、又は登録ユーザーとしての登録を取り消すことができます。
                                <ol className={styles.smallItems}>
                                    <li className={styles.smallItem}>
                                        (1) 本規約及び当社が定める各ガイドラインのいずれかの条項に違反した場合
                                    </li>
                                    <li className={styles.smallItem}>
                                        (2) 登録情報に虚偽の事実があることが判明した場合
                                    </li>
                                    <li className={styles.smallItem}>
                                        (3)
                                        当社、他の登録ユーザー、第三者に損害を生じさせるおそれのある目的又は方法で本サービスを利用した、又は利用しようとした場合
                                    </li>
                                    <li className={styles.smallItem}>
                                        (4) 手段の如何を問わず、本サービスの運営を妨害した場合
                                    </li>
                                    <li className={styles.smallItem}>
                                        (5)
                                        支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
                                    </li>
                                    <li className={styles.smallItem}>
                                        (6)
                                        自ら振出し、若しくは引受けた手形若しくは小切手につき、不渡りの処分を受けた場合、又は手形交換所の取引停止処分その他これに類する措置を受けたとき
                                    </li>
                                    <li className={styles.smallItem}>
                                        (7) 差押、仮差押、仮処分、強制執行又は競売の申立てがあった場合
                                    </li>
                                    <li className={styles.smallItem}>(8) 租税公課の滞納処分を受けた場合</li>
                                    <li className={styles.smallItem}>
                                        (9) 死亡した場合又は後見開始、保佐開始若しくは補助開始の審判を受けた場合
                                    </li>
                                    <li className={styles.smallItem}>
                                        (10) 1年以上本サービスの利用がなく、当社からの連絡に対して応答がない場合
                                    </li>
                                    <li className={styles.smallItem}>(11) 第3条第7項各号に該当する場合</li>
                                    <li className={styles.smallItem}>
                                        (12) その他、当社が登録ユーザーとしての登録の継続を適当でないと判断した場合
                                    </li>
                                </ol>
                            </li>
                            <li className={styles.item}>
                                2.
                                前項各号のいずれかの事由に該当した場合、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
                            </li>
                            <li className={styles.item}>
                                3.
                                当社は、本条に基づき当社が行った行為により登録ユーザーに生じた損害について一切の責任を負いません。
                            </li>
                            <li className={styles.item}>
                                4.
                                登録ユーザーは、当社所定の方法で当社に通知し、当社の承諾を得ることにより、自己の登録ユーザーとしての登録を取り消すことができます。
                            </li>
                            <li className={styles.item}>
                                5.
                                本条に基づき登録ユーザーの登録が取り消された場合、登録ユーザーは、当社の指示に基づき、当社から提供を受けた本サービスに関連するソフトウェア、マニュアルその他の物につき、返還、廃棄その他の処分を行うものとします。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第21条 保証の否認及び免責</h4>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1.
                                当社は、先生の提供する講座等の適法性、正確性、適切性、真実性、信頼性、有用性その他一切の事項につき如何なる保証も行うものではなく、先生と生徒の間の問題について一切の責任を負いません。さらに、登録ユーザーが当社から直接又は間接に本サービス又は他の登録ユーザーに関する情報を得た場合であっても、当社は登録ユーザーに対し本規約において規定されている内容を超えて如何なる保証も行うものではありません。
                            </li>
                            <li className={styles.item}>
                                2.
                                先生による講座等の提供、生徒による講座等への参加は、いずれも登録ユーザー自身の責任により行われるものとし、当社はこれを監督し、又はその他講座等が適切に開催されること等につき一切の責任を負わないものとします。講座等の提供又は講座等への参加に関するすべての費用、危険等については、登録ユーザー自身が責任を負うものとし、当社はこれらにつき一切の責任を負わないものとします。
                            </li>
                            <li className={styles.item}>
                                3.
                                登録ユーザーは、本サービスを利用することが、登録ユーザーに適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、登録ユーザーによる本サービスの利用が、登録ユーザーに適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。
                            </li>
                            <li className={styles.item}>
                                4.
                                本サービス又は当社ウェブサイトに関連して登録ユーザーと他の登録ユーザー、第三者との間において生じた取引、連絡、紛争等については、登録ユーザーの責任において処理及び解決するものとし、当社はかかる事項について一切責任を負いません。
                            </li>
                            <li className={styles.item}>
                                5.
                                当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、登録ユーザーのメッセージ又は情報の削除又は消失､登録ユーザーの登録の取消、本サービスの利用によるデータの消失又は機器の故障若しくは損傷、その他本サービスに関連して登録ユーザーが被った損害につき、賠償する責任を一切負わないものとします。
                            </li>
                            <li className={styles.item}>
                                6.
                                当社ウェブサイトから他のウェブサイトへのリンク又は他のウェブサイトから当社ウェブサイトへのリンクが提供されている場合でも、当社は、当社ウェブサイト以外のウェブサイト及びそこから得られる情報に関して如何なる理由に基づいても一切の責任を負わないものとします。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第22条 紛争処理及び損害賠償 </h4>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1.
                                登録ユーザーは、本規約に違反することにより、又は本サービスの利用に関連して当社に損害を与えた場合、当社に対しその損害を賠償しなければなりません。
                            </li>
                            <li className={styles.item}>
                                2.
                                登録ユーザーが、本サービスに関連して他の登録ユーザー、第三者からクレームを受け又はそれらの者との間で紛争を生じた場合には、直ちにその内容を当社に通知するとともに、登録ユーザーの費用と責任において当該クレーム又は紛争を処理し、当社からの要請に基づき、その経過及び結果を当社に報告するものとします。
                            </li>
                            <li className={styles.item}>
                                3.
                                登録ユーザーによる本サービスの利用に関連して、当社が、他の登録ユーザー、第三者から権利侵害その他の理由により何らかの請求を受けた場合は、登録ユーザーは当該請求に基づき当社が当該第三者に支払を余儀なくされた金額を賠償しなければなりません。
                            </li>
                            <li className={styles.item}>
                                4.
                                当社は、本サービスに関連して登録ユーザーが被った損害について、一切賠償の責任を負いません。なお、消費者契約法の適用その他の理由により、本項その他当社の損害賠償責任を免責する規定にかかわらず当社が登録ユーザーに対して損害賠償責任を負う場合においても、当社の賠償責任は、下記の金額を上限とします。
                                <ol className={styles.smallItems}>
                                    <li className={styles.smallItem}>
                                        (1) 登録ユーザーが生徒としての立場で損害を被った場合：
                                        損害の事由が生じた時点から遡って過去6ヶ月の期間に当社が生徒から現実に受領した受講料又は月額料金の総額
                                    </li>
                                    <li className={styles.smallItem}>
                                        (2) 登録ユーザーが先生としての立場で損害を被った場合：
                                        損害の事由が生じた時点から遡って過去6ヶ月の期間に先生が当社から現実に受領した受講料又は月額料金の総額
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第23条 秘密保持</h4>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1.
                                本規約において「秘密情報」とは、利用契約又は本サービスに関連して、登録ユーザーが、当社より書面、口頭若しくは記録媒体等により提供若しくは開示されたか、又は知り得た、当社の技術、営業、業務、財務、組織、その他の事項に関する全ての情報を意味します。但し、(1)当社から提供若しくは開示がなされたとき又は知得したときに、既に一般に公知となっていた、又は既に知得していたもの、(2)当社から提供若しくは開示又は知得した後、自己の責めに帰せざる事由により刊行物その他により公知となったもの、(3)提供又は開示の権限のある第三者から秘密保持義務を負わされることなく適法に取得したもの、(4)秘密情報によることなく単独で開発したもの、(5)当社から秘密保持の必要なき旨書面で確認されたものについては、秘密情報から除外するものとします。
                            </li>
                            <li className={styles.item}>
                                2.
                                登録ユーザーは、秘密情報を本サービスの利用の目的のみに利用するとともに、当社の書面による承諾なしに第三者に当社の秘密情報を提供、開示又は漏洩しないものとします。
                            </li>
                            <li className={styles.item}>
                                3.
                                第2項の定めに拘わらず、登録ユーザーは、法律、裁判所又は政府機関の命令、要求又は要請に基づき、秘密情報を開示することができます。但し、当該命令、要求又は要請があった場合、速やかにその旨を当社に通知しなければなりません。
                            </li>
                            <li className={styles.item}>
                                4.
                                登録ユーザーは、秘密情報を記載した文書又は磁気記録媒体等を複製する場合には、事前に当社の書面による承諾を得ることとし、複製物の管理については第2項に準じて厳重に行うものとします。
                            </li>
                            <li className={styles.item}>
                                5.
                                登録ユーザーは、当社から求められた場合にはいつでも、遅滞なく、当社の指示に従い、秘密情報並びに秘密情報を記載又は包含した書面その他の記録媒体物及びその全ての複製物を返却又は廃棄しなければなりません。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第24条 有効期間</h4>
                        <section className={styles.description}>
                            利用契約は、登録ユーザーについて第3条に基づく登録が完了した日に効力を生じ、当該登録ユーザーの登録が取り消された日又は本サービスの提供が終了した日のいずれか早い日まで、当社と登録ユーザーとの間で有効に存続するものとします。
                        </section>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第25条 本規約等の変更 </h4>
                        <ol className={styles.items}>
                            <li className={styles.item}>1. 当社は、本サービスの内容を自由に変更できるものとします。</li>
                            <li className={styles.item}>
                                2. 当社は、本規約
                                (当社ウェブサイトに掲載する本サービスに関するルール、諸規定等を含みます。以下本項において同じ。)を変更できるものとします。当社は、本規約を変更した場合には、登録ユーザーに当該変更内容を通知するものとし、当該変更内容の通知後、登録ユーザーが本サービスを利用した場合又は当社の定める期間内に登録取消の手続をとらなかった場合には、登録ユーザーは、本規約の変更に同意したものとみなします。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第26条 連絡/通知</h4>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1.
                                本規約の変更に関する通知その他当社から登録ユーザーに対する連絡又は通知は、当社の定める方法で行うものとします。
                            </li>
                            <li className={styles.item}>
                                2.
                                本サービスで表示する内容に関してプライバシー侵害・名誉毀損等に関する問題がある場合、本規約に他人が違反しているのを発見した場合、その他当社に対する問い合わせが必要な場合は、以下のメールアドレス宛にご連絡ください。
                                メールアドレス： support@trail-official.com
                            </li>
                        </ol>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第27条 本規約の譲渡等</h4>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1.
                                登録ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
                            </li>
                            <li className={styles.item}>
                                2.
                                当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びに登録ユーザーの登録情報その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、登録ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、単発・短期の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第28条 完全合意</h4>
                        <section className={styles.description}>
                            本規約は、本規約に含まれる事項に関する当社と登録ユーザーとの完全な合意を構成し、口頭又は書面を問わず、本規約に含まれる事項に関する当社と登録ユーザーとの事前の合意、表明及び了解に優先します。
                        </section>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第29条 分離可能性</h4>
                        <section className={styles.description}>
                            本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社及び登録ユーザーは、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。
                        </section>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第30条 存続規定</h4>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1.
                                第1条第3項、第5条第2項、第6条、第10条第3項及び第4項、第13条第1項から第3項まで並びに第10項、第15条第2項及び第3項、第16条第3項、第17条から第19条まで、第20条第2項、第3項及び第5項、第21条から第23条まで並びに第27条から第31条までの規定は利用契約の終了後も有効に存続するものとします。但し、第19条については、利用契約終了後5年間に限り存続するものとします。
                            </li>
                            <li className={styles.item}>
                                2.
                                本契約終了後も、既に生徒が予約を完了した講座については、当該講座に関して利用契約が有効に存続し、その範囲で本規約の規定が適用されるものとします。
                            </li>
                        </ol>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第31条 準拠法及び管轄裁判所</h4>
                        <section className={styles.description}>
                            本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、名古屋地方裁判所を第一審の専属的合意管轄裁判所とします。
                        </section>
                    </section>
                    <section className={styles.section}>
                        <h4 className={styles.smallHeading}>第32条 協議解決</h4>
                        <section className={styles.description}>
                            当社及び登録ユーザーは、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
                        </section>
                    </section>
                </section>
                <section className={styles.date}>2024年4月12日制定</section>
            </section>
        </>
    );
});
