import { FAQConfig } from "@/FAQConfig";

export const CHECK_RESERVATION_CONFIGS: FAQConfig[] = [
    {
        title: "My講座一覧ページへのアクセス",
        text: "メニューバーの「My講座一覧」をクリックすると、My講座一覧ページに移動します。",
    },
    {
        title: "講座管理ページへのアクセス",
        text: "My講座一覧ページで対象の講座の「講座管理」をクリックすると、講座管理ページに移動します。",
    },
    {
        title: "予約状況の確認",
        text: "講座管理ページに参加者が記載されています。",
    },
];
