import { useMediaQuery } from "@material-ui/core";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { RootState } from "@/ducks";
import { CommonComponent } from "@/pageComponents/Common/CourseDetails/Functional/Common";
import { ForStudentComponent } from "@/pageComponents/Common/CourseDetails/Functional/ForStudent";
import { PrivateUserResponse } from "@/store/autogenApi";
import { useGetPrivateUserQuery } from "@/store/hooks/users";

export const CourseDetails: React.VFC = () => {
    const userId = useSelector((state: RootState) => state.jwt.userId as string);
    const userMode = useSelector((state: RootState) => state.jwt.userMode as string);

    const userQueryState = useGetPrivateUserQuery(userId);

    if (userMode === "student") {
        return (
            <QueryLoadingWrapper {...userQueryState}>
                {(user) => <ForStudentComponent user={user as PrivateUserResponse} />}
            </QueryLoadingWrapper>
        );
    } else {
        return (
            <QueryLoadingWrapper {...userQueryState}>
                {(user) => <CommonComponent user={user ?? undefined} />}
            </QueryLoadingWrapper>
        );
    }
};
