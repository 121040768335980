import { memo } from "react";
import styles from "./index.module.scss";

interface Props {
    text: string;
}

export const Bold: React.VFC<Props> = memo(function Bold(props) {
    return <span className={styles.bold}>{props.text}</span>;
});
