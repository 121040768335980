import { IsSuccess } from "@/components/IsSuccess";
import styles from "./index.module.scss";
import { useHistory, useLocation } from "react-router";
import { useCallback } from "react";

export const ThanksPayment: React.VFC = () => {
    const history = useHistory();
    const query = new URLSearchParams(useLocation().search);
    const courseType = query.get("courseType");

    const handleCourseButtonClick = useCallback(() => {
        history.push(`/MyPage/ReservedCourses?courseType=${courseType}`);
    }, [courseType]);

    return (
        <div className={styles.paymentWrapper}>
            <div className={styles.paymentContentsWrapper}>
                <IsSuccess
                    successMessage="講座の購入が完了しました。講座の開催をお待ちください。"
                    buttonContent="講座を確認"
                    handleButtonClick={handleCourseButtonClick}
                />
            </div>
        </div>
    );
};
