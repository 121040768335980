import { useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import { CourseResponse, CourseType, ClassResponse } from "@/store/autogenApi";
import { RootState } from "@/ducks";
import { memo, useCallback, useEffect, useState } from "react";
import { CourseDetailsModal } from "./CourseDetailsModal";
import { ReservedCoursesTabContents } from "./ReservedCoursesTabContents";
import { TabsTemplate } from "@/components/TabsTemplate";
import { getTabInfoList } from "@/utils/ClassUtils";

interface Props {
    classes: ClassResponse[];
}

const COURSE_TYPES: CourseType[] = ["regular", "short"];

export const ReservedCoursesContents: React.VFC<Props> = memo(function ReservedCoursesContents(props) {
    const [courseType, setCourseType] = useState<CourseType>("regular");
    const [targetClass, setTargetClass] = useState<ClassResponse | undefined>(undefined);

    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);

    const sm = useMediaQuery("(min-width:600px)");
    const md = useMediaQuery("(min-width:960px)");
    const lg = useMediaQuery("(min-width:1280px)");
    const xl = useMediaQuery("(min-width:1536px)");

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const courseTypeQueryParams = searchParams.get("courseType");

    useEffect(() => {
        if (courseTypeQueryParams) {
            setCourseType(courseTypeQueryParams as CourseType);
        }
    }, [courseTypeQueryParams]);

    const history = useHistory();

    const handleTabChange = useCallback((event: React.ChangeEvent<Record<string, unknown>>, value: string) => {
        switch (value) {
            case "regular":
                history.push("/MyPage/ReservedCourses?courseType=regular");
                break;
            case "short":
                history.push("/MyPage/ReservedCourses?courseType=short");
                break;
        }
    }, []);

    const handleCourseCardClick = useCallback((classResponse: ClassResponse) => {
        setTargetClass(classResponse);
    }, []);

    const handleCourseDetailsModalClose = useCallback(() => {
        setTargetClass(undefined);
    }, []);

    return (
        <>
            <TabsTemplate
                targetTabValue={courseType}
                tabInfoList={getTabInfoList(props.classes)}
                handleTabChange={handleTabChange}
            >
                {COURSE_TYPES.map((targetCourseType) => (
                    <ReservedCoursesTabContents
                        visible={targetCourseType === courseType}
                        classes={props.classes.filter(
                            (classResponse) => classResponse.course?.courseType === targetCourseType,
                        )}
                        repetition={xl ? 6 : lg ? 6 : md ? 6 : sm ? 5 : 3}
                        handleCourseCardClick={handleCourseCardClick}
                    />
                ))}
            </TabsTemplate>
            {targetClass && (
                <CourseDetailsModal
                    targetClass={targetClass}
                    handleCourseDetailsModalClose={handleCourseDetailsModalClose}
                />
            )}
        </>
    );
});
