import { memo, useCallback } from "react";

import styles from "./index.module.scss";

import { PublicTeacherResponse, PublicUserResponse } from "@/store/autogenApi";
import { Button } from "@material-ui/core";
import { genderSet } from "@/utils/GenderSet";
import { ListItem } from "./ListItem";
import { getSubject } from "@/utils/SubjectUtils";
import { useHistory } from "react-router";
import { ChatModal } from "../ChatModal";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    user: PublicUserResponse;
    teacher: PublicTeacherResponse;
    modalHeight: number;
    isChatVisible?: boolean;
    chatModalOpen?: boolean;
    handleChatModalOpen?: () => void;
    handleChatModalClose?: () => void;
}

export const TeacherInfo: React.VFC<Props> = memo(function TeacherInfo(props) {
    const userMode = useSelector((state: RootState) => state.jwt.userMode as string);
    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);
    const getBirthDate = useCallback((birthDateString: string) => {
        const birthDate = new Date(birthDateString);
        const year = birthDate.getFullYear();
        const month = birthDate.getMonth() + 1;
        const date = birthDate.getDate();
        return `${year}年${month}月${date}日`;
    }, []);

    const history = useHistory();
    const handleDetailsButtonClick = useCallback(() => {
        window.open(`/TeacherDetails/${props.teacher.teacherId}`);
    }, [history, props.teacher.teacherId]);

    return (
        <div className={styles.teacherInfoWrapper}>
            <AvatarFromS3
                url={props.teacher.iconImageUrl}
                objectKey={props.teacher.iconImageObjectKey}
                className={styles.modalAvatar}
            />
            <div className={styles.profile}>
                <span className={styles.nickname}>{props.teacher?.nickname}</span>
                先生のプロフィール
            </div>
            <ul className={styles.infoList} style={{ maxHeight: `${props.modalHeight - 250}px` }}>
                <ListItem title="性別" value={genderSet[props.user.gender]} />
                <ListItem title="生年月日" value={getBirthDate(props.user.birthDate)} />
                <ListItem title="職業・学年" value={props.user.grade} />
                <ListItem title="都道府県" value={props.user.prefecture} />
                <ListItem title="指導可能科目">
                    <ul className={styles.subjectList}>
                        {props.teacher.teachableSubjects?.map((subject, index) => (
                            <li key={index} className={styles.subject}>
                                {getSubject(subject)}
                            </li>
                        ))}
                    </ul>
                </ListItem>
                <ListItem title="キャッチコピー" value={props.teacher.catchCopy} />
                <ListItem title="こんなこと教えます" value={props.teacher.contentDescription} isLastItem />
            </ul>
            <Button className={styles.detailsButton} onClick={handleDetailsButtonClick}>
                先生詳細
            </Button>
            {props.isChatVisible && userMode === "student" && props.handleChatModalClose && (
                <>
                    <Button className={styles.individualChatButton} onClick={props.handleChatModalOpen}>
                        個人チャット
                    </Button>
                    {props.chatModalOpen && (
                        <ChatModal
                            studentId={studentId}
                            teacherId={props.teacher.teacherId}
                            handleClose={props.handleChatModalClose as () => void}
                        />
                    )}
                </>
            )}
        </div>
    );
});
