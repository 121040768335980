import {
    AvailableTimeResponse,
    CreateAvailableTimeRequestParams,
    UpdateAvailableTimeRequestBody,
} from "../../src/store/autogenApi";
import { WHAT_DAY_LIST } from "./WhatDayList";

export const getWhatDays = (availableTimes: AvailableTimeResponse[]) => {
    const whatDaysList = availableTimes
        .filter((availableTime) => {
            const startHour = availableTime.startHour;
            const startMinute = availableTime.startMinute;
            const endHour = availableTime.endHour;
            const endMinute = availableTime.endMinute;
            const result =
                startHour != undefined && startMinute != undefined && endHour != undefined && endMinute != undefined;
            return result;
        })
        .map((availableTime) => WHAT_DAY_LIST[availableTime.dayOfWeekIndex]);
    const whatDays = whatDaysList.join("・");
    return whatDays;
};

const isAvailableTimeResponse = (value: any): value is AvailableTimeResponse => {
    return Boolean(value.dayOfWeek);
};

export const getOnlyDay = (
    availableTime: CreateAvailableTimeRequestParams | UpdateAvailableTimeRequestBody | AvailableTimeResponse,
) => {
    if (isAvailableTimeResponse(availableTime)) {
        const dayIdx = availableTime.dayOfWeekIndex;
        const day = WHAT_DAY_LIST[dayIdx];
        return day;
    }
    const dayIdx = availableTime.dayOfWeekIndex;
    const day = WHAT_DAY_LIST[dayIdx];
    return day;
};

export const getDayAndTime = (
    availableTime: CreateAvailableTimeRequestParams | UpdateAvailableTimeRequestBody | AvailableTimeResponse,
): string => {
    let day = "";
    if (isAvailableTimeResponse(availableTime)) {
        const dayIdx = availableTime.dayOfWeekIndex;
        day = WHAT_DAY_LIST[dayIdx];
    } else {
        const dayIdx = availableTime.dayOfWeekIndex;
        day = WHAT_DAY_LIST[dayIdx];
    }
    if (
        availableTime.startHour == undefined ||
        availableTime.startMinute == undefined ||
        availableTime.endHour == undefined ||
        availableTime.endMinute == undefined
    ) {
        return `${day}： 空きなし`;
    }
    const startHour = availableTime.startHour;
    const startMinute = availableTime.startMinute;
    const endHour = availableTime.endHour;
    const endMinute = availableTime.endMinute;
    const formattedStartHour = ("00" + startHour).slice(-2);
    const formattedStartMinute = ("00" + startMinute).slice(-2);
    const formattedEndHour = ("00" + endHour).slice(-2);
    const formattedEndMinute = ("00" + endMinute).slice(-2);
    const time = `${formattedStartHour}:${formattedStartMinute}〜${formattedEndHour}:${formattedEndMinute}`;

    return `${day}： ${time}`;
};

export const getIsAvailableTimeValid = (availableTimes: Partial<CreateAvailableTimeRequestParams>[]) => {
    const result = availableTimes.every((availableTime) => {
        if (
            availableTime.startHour != undefined &&
            availableTime.startMinute != undefined &&
            availableTime.endHour != undefined &&
            availableTime.endMinute != undefined &&
            availableTime.dayOfWeekIndex != undefined
        ) {
            return true;
        } else {
            return false;
        }
    });
    return result;
};
