import { FormHelperText, TextareaAutosize } from "@material-ui/core";
import { memo, useCallback } from "react";
import { ListItem } from ".";
import { ErrorMessage } from "@/components/ErrorMessage";
import styles from "../index.module.scss";

interface Props {
    message: string;
    isChecked: boolean;
    isConfirmMode?: boolean;
    validation: boolean;
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

export const Message: React.VFC<Props> = memo(function Message(props) {
    return (
        <ListItem title="提案メッセージ" isConfirmMode={!!props.isConfirmMode}>
            <>
                {props.isConfirmMode ? (
                    <div className={styles.isConfirmModeTrue}>{props.message}</div>
                ) : (
                    <>
                        {/* <div className={styles.description}>
                            生徒さんに送る最初のメッセージです。
                            <br />
                            他の先生に負けないように魅力的なメッセージを作成しましょう。
                        </div> */}
                        <TextareaAutosize
                            value={props.message}
                            placeholder="(10字以上300字以下)&#13;&#10;例）&#13;&#10;〇〇大学3年のトレ田イル道と言います！&#13;&#10;数学が苦手とのことですが、実は自分も元々数学が苦手でなんとかそれを克服したので、きっと〇〇さんの力になれると思います。&#13;&#10;..."
                            className={styles.textArea}
                            onChange={props.handleChange}
                            minRows={8}
                            maxRows={8}
                            id="messageTextArea"
                        />
                        <div className={styles.messageLength}>{props.message.length}/500</div>
                        <ErrorMessage
                            content="10字以上500文字以下で入力してください。"
                            when={(props.isChecked && props.message.length < 10) || props.message.length > 500}
                        />
                    </>
                )}
            </>
        </ListItem>
    );
});
