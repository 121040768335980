import { Button, CircularProgress } from "@material-ui/core";
import { memo, useCallback, useContext, useState } from "react";

import styles from "@/pages/Common/SignIn/index.module.scss";
import { ForgotPassword } from "./ForgotPassword";

import { Auth } from "aws-amplify";
import { CurrentCognitoUserContext } from "@/components/Authentication";
import { useHistory } from "react-router";
import { TextInput } from "@/components/TextInput";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Password } from "@/components/Password";

interface Props {
    handleClose: () => void;
    handleUserIdChange: (value: string) => void;
    handleIsProcessingForSignInChange: (value: boolean) => void;
    isProcessingForSignIn: boolean;
    forStudent: boolean;
    signInModeInJapanese: string;
}

export const SignInByEmail: React.VFC<Props> = memo(function SignInByEmail(props) {
    const [forgotPassword, setForgotPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);

    const history = useHistory();

    const handleForgetPasswordButtonClick = useCallback(() => {
        setForgotPassword(true);
    }, []);
    const handleSignInAgainButtonClick = useCallback(() => {
        setForgotPassword(false);
    }, []);

    const regexp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.+-]*@[A-Za-z0-9_.-]+\.[A-Za-z0-9]+$/;
    const handleEmailChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
            setIsEmailValid(regexp.test(event.target.value));
        },
        [regexp],
    );

    const ratz = /[a-z]/,
        rAtZ = /[A-Z]/,
        r0t9 = /[0-9]/;
    const handlePasswordChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            setPassword(value);
            const result = ratz.test(value) && rAtZ.test(value) && r0t9.test(value) && value.length >= 8;
            setIsPasswordValid(result);
        },
        [ratz, rAtZ, r0t9],
    );

    const { setCurrentCognitoUser } = useContext(CurrentCognitoUserContext);

    const handleEmailSignInButtonClick = useCallback(async () => {
        setIsChecked(true);
        if (!isEmailValid || !isPasswordValid) return;
        try {
            props.handleIsProcessingForSignInChange(true);
            const cognitoUser = await Auth.signIn({
                username: email,
                password,
            });
            setCurrentCognitoUser(cognitoUser);
            props.handleUserIdChange(cognitoUser.username);
            // 続きの処理はSignInProcessで行うのでProcessingForSignInはfalseにしない
        } catch (error) {
            props.handleIsProcessingForSignInChange(false);
            if (["NotAuthorizedException", "UserNotFoundException"].includes(error.name)) {
                alert("メールアドレスが登録されていないか、パスワードが間違っています。");
            }
        }
    }, [
        email,
        password,
        isEmailValid,
        isPasswordValid,
        setCurrentCognitoUser,
        history,
        props.handleIsProcessingForSignInChange,
        props.handleUserIdChange,
    ]);
    return (
        <div className={styles.emailSignInWrapper}>
            {forgotPassword ? (
                <ForgotPassword handleSignInAgainButtonClick={handleSignInAgainButtonClick} />
            ) : (
                <>
                    <div className={styles.emailSignInTitle}>メールアドレスでログイン</div>
                    <div className={styles.inputWrapper}>
                        <TextInput label="メールアドレス" value={email} handleChange={handleEmailChange} />
                        <ErrorMessage
                            content="メールアドレスが正しくありません。"
                            when={isChecked && !isEmailValid}
                            style={{ marginBottom: "5px" }}
                        />
                        <Password label="パスワード" value={password} handleChange={handlePasswordChange} />
                        <ErrorMessage
                            content="パスワードは小文字、大文字、数字をそれぞれ1文字以上含む8文字以上で入力してください。"
                            when={isChecked && !isPasswordValid}
                            style={{ marginBottom: "5px" }}
                        />
                    </div>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleEmailSignInButtonClick}
                        className={styles.emailSignInButton}
                    >
                        {props.isProcessingForSignIn ? (
                            <CircularProgress className={styles.processing} />
                        ) : (
                            <div className={styles.emailSignIn}>ログイン</div>
                        )}
                    </Button>
                    <button className={styles.forgetPasswordButton} onClick={handleForgetPasswordButtonClick}>
                        パスワードをお忘れの方はこちら
                    </button>
                </>
            )}
        </div>
    );
});
