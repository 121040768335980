import styles from "@/pages/Common/LP/index.module.scss";
import { AboutTeacherTopContents } from "./Top";
import { WhatsTrail } from "./WhatsTrail";
import { EasyToStart } from "./EasyToStart";
import { ThreeStepsSignUp } from "./ThreeStepsSignUp";
import { Achievements } from "./Achievements";
import { Background } from "./Background";
import { Soon } from "./Soon";
import { PostScript } from "./PostScript";

export const AboutTeacherContents: React.VFC = () => {
    return (
        <div className={styles.aboutContentsWrapper}>
            <AboutTeacherTopContents />
            <WhatsTrail />
            <EasyToStart />
            <Achievements />
            <ThreeStepsSignUp />
            <Background />
            <Soon />
            <PostScript />
        </div>
    );
};
