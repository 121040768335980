import { FAQDetailsBase } from "../../FAQDetailsBase";

import { SERVICE_OF_TRAIL_CONFIGS } from "./ServiceOfTrailConfigs";

export const ServiceOfTrail: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="Trailとはどのようなサービスですか？"
            text="Trailは、【学び】のフリマアプリです！Trailでは、学生が自分の興味や目標に合わせて、自分のペースで自分が選んだ信頼できる先生から学ぶことができます。また、従来のオンライン家庭教師のような使い方も圧倒的低価格で可能です。Trailでは2種類の方法で先生・講座との巡り合いをサポートしています。"
            FAQConfigs={SERVICE_OF_TRAIL_CONFIGS}
            list={["講座検索", "講座リクエスト"]}
        />
    );
};
