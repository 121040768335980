import { memo } from "react";
import { ListItem } from "./ListItem";

import styles from "../index.module.scss";

interface Props {
    monthlyFee: number;
    isConfirmed?: boolean;
    borderBottom?: boolean;
}

export const MonthlyFee: React.VFC<Props> = memo(function MonthlyFee(props) {
    return (
        <ListItem title="月額" borderBottom={props.borderBottom}>
            <div className={styles.monthlyFee}>¥{new Intl.NumberFormat("ja-JP").format(props.monthlyFee)}</div>
        </ListItem>
    );
});
