import { Button } from "@material-ui/core";
import React, { memo, useCallback } from "react";
import { useSelector } from "react-redux";

import styles from "./index.module.scss";
import { RootState } from "@/ducks";
import { PrivateRequestResponse } from "@/store/autogenApi";
import { useHistory } from "react-router";

interface Props {
    privateRequest: PrivateRequestResponse | undefined;
    classId: string;
    handleReservationButtonClick: (classId: string) => void;
}

export const PrivateRequestApplication: React.VFC<Props> = memo(function PrivateRequestApplication(props) {
    const studentId = useSelector((state: RootState) => state.jwt.studentId);
    const history = useHistory();

    const handlePaymentButtonClick = useCallback(() => {
        history.push(`/Payment/${props.classId}`);
    }, []);

    if (props.privateRequest === undefined) {
        return (
            <Button
                className={styles.privateRequestButton}
                onClick={() => {
                    props.handleReservationButtonClick(props.classId);
                }}
            >
                スケジュール申請
            </Button>
        );
    }

    if (props.privateRequest.student?.studentId === studentId) {
        if (!props.privateRequest.isApproved) {
            return <div className={styles.message}>スケジュールを申請中です。申請の承認をお待ちください。</div>;
        }
        if (props.privateRequest.isApproved) {
            return (
                <div className={styles.paymentWrapper}>
                    <div className={styles.message}>
                        あなたのスケジュール申請が承認されました。初回授業までに支払いを行ってください。
                    </div>
                    <Button className={styles.paymentButton} onClick={handlePaymentButtonClick}>
                        支払いへ
                    </Button>
                </div>
            );
        }
    }

    if (props.privateRequest.student?.studentId !== studentId) {
        return (
            <div className={styles.message}>
                他の生徒さんがスケジュール申請中です。それが完了するまで待つか他の講座を探してください。
            </div>
        );
    }
    return null;
});
