import { memo } from "react";
import { PushNotificationsSettingResponse } from "@/store/autogenApi";
import { Switch } from "../Switch";
import styles from "../index.module.scss";
import { CircularProgress } from "@material-ui/core";
import { NotificationsItem } from "../NotificationsItem";

interface Props {
    isProcessing: boolean;
    allowPushNotifications: boolean;
    message: boolean;
    favorite: boolean;
    information: boolean;
    recommendation: boolean;
    maintenance: boolean;
    review: boolean;
    newCourse: boolean;
    newRequest: boolean;
    pushNotificationsSetting: PushNotificationsSettingResponse;
    handleAllowPushNotificationsChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    getIsEveryNotificationsItemTheSame: () => boolean;
    setIsEdited: React.Dispatch<React.SetStateAction<boolean>>;
    handleMessageChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleFavoriteChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleInformationChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleRecommendationChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleMaintenanceChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleReviewChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleNewCourseChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleNewRequestChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const PushNotificationsSetting: React.VFC<Props> = memo(function PushNotificationsSetting(props) {
    return (
        <div className={`${styles.notificationsWrapper}`}>
            {Notification.permission === "denied" && (
                <div className={styles.denied}>
                    お使いのブラウザはTrailからの通知をブロックしています。プッシュ通知を有効にしたい場合は、ブラウザの通知設定からブロックを解除してください。
                </div>
            )}
            <div className={styles.notificationsGridWrapper}>
                <div className={`${styles.notificationsTitle} ${styles.targetTitle}`}>プッシュ通知</div>
                {props.isProcessing ? (
                    <>
                        <CircularProgress className={styles.circularProgress} />
                    </>
                ) : (
                    <>
                        <Switch
                            checked={props.allowPushNotifications}
                            handleChange={props.handleAllowPushNotificationsChange}
                        />
                        {props.allowPushNotifications && (
                            <div className={styles.notificationsItems}>
                                <NotificationsItem
                                    title="メッセージ"
                                    checked={props.message}
                                    handleChange={props.handleMessageChange}
                                />
                                <NotificationsItem
                                    title="お気に入り"
                                    checked={props.favorite}
                                    handleChange={props.handleFavoriteChange}
                                />
                                <NotificationsItem
                                    title="お知らせ"
                                    checked={props.information}
                                    handleChange={props.handleInformationChange}
                                />
                                <NotificationsItem
                                    title="おすすめ"
                                    checked={props.recommendation}
                                    handleChange={props.handleRecommendationChange}
                                />
                                <NotificationsItem
                                    title="メンテナンス"
                                    checked={props.maintenance}
                                    handleChange={props.handleMaintenanceChange}
                                />
                                <NotificationsItem
                                    title="レビュー"
                                    checked={props.review}
                                    handleChange={props.handleReviewChange}
                                />
                                <NotificationsItem
                                    title="新着講座"
                                    checked={props.newCourse}
                                    handleChange={props.handleNewCourseChange}
                                />
                                <NotificationsItem
                                    title="新着講座リクエスト"
                                    checked={props.newRequest}
                                    handleChange={props.handleNewRequestChange}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
});
