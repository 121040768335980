import { useCallback, useEffect, useRef, useState } from "react";

import { Button, Hidden, useMediaQuery } from "@material-ui/core";
import queryString from "query-string";
import { RiFocus3Line } from "react-icons/ri";

import { LoadingProgress } from "@/components/LoadingProgress";
import { Subject } from "@/pages/Common/RequestSearchResults";
import styles from "@/pages/Common/RequestSearchResults/index.module.scss";
import { CourseType, OrderBy, PublicRequestResponse } from "@/store/autogenApi";

import { NarrowDown } from "@/components/NarrowDown";
import { RequestCard } from "@/components/RequestCard";
import { PageNumbers } from "@/components/PageNumbers";
import { Sort } from "@/components/Sort";

interface Props {
    keyword: string;
    courseType: string;
    subject: Subject;
    query: queryString.ParsedQuery<string>;
    requests: PublicRequestResponse[];
    isLoaded: boolean;
    pageNumber: number;
    orderBy: OrderBy;
    existCondition: boolean;
    setPageNumber: React.Dispatch<React.SetStateAction<number>>;
    setKeyword: React.Dispatch<React.SetStateAction<string>>;
    setCourseType: React.Dispatch<React.SetStateAction<string>>;
    setSubject: React.Dispatch<React.SetStateAction<Subject>>;
    search: () => void;
    getSubjectCondition: () => string;
    getCourseTypeCondition: () => string;
    deleteKeywordCondition: () => void;
    deleteCourseTypeCondition: () => void;
    deleteSubjectCondition: () => void;
    handleClearButtonClick: () => void;
    handleOrderByChange: (
        e: React.ChangeEvent<{
            value: string;
        }>,
    ) => void;
}

export const RequestSearchResultsComponent: React.VFC<Props> = (props) => {
    const [repetitionNumber, setRepetitionNumber] = useState<number>(0);
    const [isOver, setIsOver] = useState<boolean>(false);
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    const searchResultPageEl: React.MutableRefObject<HTMLElement | null | undefined> = useRef();

    const searchResultPageRef = useCallback((node: HTMLElement | null) => {
        searchResultPageEl.current = node;
    }, []);

    // useEffect(() => {
    //     const scrollWidth = searchResultPageEl.current?.scrollWidth;
    //     console.log(scrollWidth);
    //     if (scrollWidth && scrollWidth === 1200) {
    //         setIsOver(true);
    //     }
    // }, [searchResultPageEl]);

    useEffect(() => {
        changeIsOver();
        window.addEventListener("resize", () => {
            changeIsOver();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeIsOver();
            });
        };
    }, []);

    const changeIsOver = () => {
        const scrollWidth = searchResultPageEl.current?.scrollWidth;
        if (scrollWidth && scrollWidth === 1200 && !isOver) {
            setIsOver(true);
        } else if (scrollWidth && scrollWidth < 1200 && isOver) {
            setIsOver(false);
        }
    };

    const xsUp = useMediaQuery("(min-width:600px)");
    const mdUp = useMediaQuery("(min-width:1200px)");

    const smallThresholdUp = useMediaQuery("(min-width:600px)");
    const largeThresholdUp = useMediaQuery("(min-width:1000px)");

    useEffect(() => {
        if (!xsUp) {
            setRepetitionNumber(3);
            return;
        }
        if (!mdUp) {
            setRepetitionNumber(5);
            return;
        }
        setRepetitionNumber(6);
    }, [xsUp, mdUp]);

    const handleNarrowDownButtonClick = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const getConditionsCount = () => {
        let count = 0;
        if (props.subject.level) {
            count++;
        }
        return count;
    };

    const thresholdNumber = largeThresholdUp ? 10 : smallThresholdUp ? 8 : 6;

    return (
        <div className={styles.searchResultPage} ref={searchResultPageRef}>
            <Hidden smDown>
                <NarrowDown
                    keyword={props.keyword}
                    courseType={props.courseType}
                    targetSubject={props.subject}
                    searchResultPageEl={searchResultPageEl}
                    setKeyword={props.setKeyword}
                    setCourseType={props.setCourseType}
                    search={props.search}
                    setTargetSubject={props.setSubject}
                    getCourseTypeCondition={props.getCourseTypeCondition}
                    handleClearButtonClick={props.handleClearButtonClick}
                />
            </Hidden>
            <div className={styles.searchResultsWrapper}>
                <div className={styles.searchResultsContents}>
                    <div className={styles.searchResultsMiddleWrapper}>
                        <div
                            className={styles.searchResultsInfoWrapper}
                            style={{ alignItems: props.existCondition ? "flex-end" : "flex-start" }}
                        >
                            {props.existCondition ? (
                                <div className={styles.searchConditionsWrapper}>
                                    {props.query.keyword && (
                                        <div className={styles.condition}>
                                            <div className={styles.title}>キーワード：</div>
                                            <div className={styles.conditionContents}>{props.query.keyword}</div>
                                            <Button
                                                className={styles.conditionDeleteButton}
                                                onClick={props.deleteKeywordCondition}
                                            >
                                                ×
                                            </Button>
                                        </div>
                                    )}
                                    {props.query.courseType && (
                                        <div className={styles.condition}>
                                            <div className={styles.title}>講座リクエストの種類：</div>
                                            <div className={styles.conditionContents}>
                                                {props.getCourseTypeCondition()}
                                            </div>
                                            <Button
                                                className={styles.conditionDeleteButton}
                                                onClick={props.deleteCourseTypeCondition}
                                            >
                                                ×
                                            </Button>
                                        </div>
                                    )}
                                    {props.query.level && (
                                        <div className={styles.condition}>
                                            <div className={styles.title}>科目：</div>
                                            <div className={styles.conditionContents}>
                                                {props.getSubjectCondition()}
                                            </div>
                                            <Button
                                                className={styles.conditionDeleteButton}
                                                onClick={props.deleteSubjectCondition}
                                            >
                                                ×
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className={styles.searchResultTitle}>全ての講座リクエスト</div>
                            )}

                            <div className={styles.searchResultsInfoRightWrapper}>
                                <Sort
                                    value={props.orderBy}
                                    orderSets={[{ value: "latest", label: "新着順" }]}
                                    handleChange={props.handleOrderByChange}
                                />
                                {props.isLoaded && props.requests.length > 0 && (
                                    <div className={styles.howManySearchResults}>
                                        {props.requests.length}件中 {(props.pageNumber - 1) * 50 + 1} ~{" "}
                                        {props.pageNumber * 50 < props.requests.length
                                            ? props.pageNumber * 50
                                            : props.requests.length}
                                        件 を表示
                                    </div>
                                )}
                            </div>
                        </div>
                        <Hidden mdUp>
                            <div className={styles.narrowDownButtonWrapper}>
                                <Button className={styles.narrowDownButton} onClick={handleNarrowDownButtonClick}>
                                    <RiFocus3Line className={styles.icon} />
                                    <div className={styles.narrowDownTitle}>絞り込み({getConditionsCount()})</div>
                                </Button>
                                <NarrowDown
                                    drawerOpen={drawerOpen}
                                    keyword={props.keyword}
                                    courseType={props.courseType}
                                    searchResultPageEl={searchResultPageEl}
                                    setKeyword={props.setKeyword}
                                    setCourseType={props.setCourseType}
                                    search={props.search}
                                    targetSubject={props.subject}
                                    setTargetSubject={props.setSubject}
                                    handleClose={handleDrawerClose}
                                    handleClearButtonClick={props.handleClearButtonClick}
                                    getCourseTypeCondition={props.getCourseTypeCondition}
                                />
                            </div>
                        </Hidden>
                    </div>
                    <LoadingProgress loadingComplete={props.isLoaded}>
                        <>
                            {repetitionNumber && (
                                <div className={styles.cardsWrapper}>
                                    {props.requests.length === 0 ? (
                                        <div className={styles.emptyRequests}>該当する講座リクエストはありません。</div>
                                    ) : (
                                        <>
                                            {[
                                                ...Array(
                                                    Math.ceil(
                                                        props.requests.slice(
                                                            (props.pageNumber - 1) * 50,
                                                            (props.pageNumber - 1) * 50 + 50,
                                                        ).length / repetitionNumber,
                                                    ),
                                                ),
                                            ].map((_, idx1, self) => {
                                                const shortage =
                                                    (repetitionNumber -
                                                        (props.requests.slice(
                                                            (props.pageNumber - 1) * 50,
                                                            (props.pageNumber - 1) * 50 + 50,
                                                        ).length %
                                                            repetitionNumber)) %
                                                    repetitionNumber;
                                                return (
                                                    <div className={styles.cards} key={idx1}>
                                                        {props.requests
                                                            .slice(
                                                                (props.pageNumber - 1) * 50,
                                                                (props.pageNumber - 1) * 50 + 50,
                                                            )
                                                            .filter(
                                                                (_, idx2) =>
                                                                    idx1 * repetitionNumber <= idx2 &&
                                                                    idx2 < (idx1 + 1) * repetitionNumber,
                                                            )
                                                            .map((searchResult) => (
                                                                <RequestCard
                                                                    key={searchResult.publicRequestId}
                                                                    publicRequest={searchResult}
                                                                    isFlex
                                                                />
                                                            ))}
                                                        {idx1 === self.length - 1 &&
                                                            [...Array(shortage)].map((_, idx) => {
                                                                return <RequestCard key={idx} isHidden isFlex />;
                                                            })}
                                                    </div>
                                                );
                                            })}
                                        </>
                                    )}
                                </div>
                            )}
                            <PageNumbers
                                pageNumber={props.pageNumber}
                                setPageNumber={props.setPageNumber}
                                items={props.requests}
                                itemsPerPage={50}
                                thresholdNumber={thresholdNumber}
                            />
                        </>
                    </LoadingProgress>
                </div>
            </div>
        </div>
    );
};
