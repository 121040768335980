import { memo, useCallback, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { CreateLessonRequestParams, LessonResponse } from "@/store/autogenApi";
import { startOfWeek } from "date-fns";
import { LessonCalendarOfWeekHeadings } from "./LessonCalendarOfWeek/LessonCalendarHeadings";
import { LessonCalendarOfWeekLanes } from "./LessonCalendarOfWeek/LessonCalendarLanes";
import { LessonCalendarTop } from "./LessonCalendarTop";
import { useLocation } from "react-router";
import { LessonCalendarOfMonth } from "./LessonCalendarOfMonth";
import { useMediaQuery } from "@material-ui/core";

interface Props {
    lessons: (LessonResponse | CreateLessonRequestParams)[];
    // 下記はcreateCourse用
    targetNewLessonIdx?: number | undefined;
    isDraggable: boolean;
    targetStartDate?: Date;
    targetUnit?: "week" | "month";
    maxModalContentsHeight?: number | undefined;
    createNewLessonFromCreateCourse?: (lesson: CreateLessonRequestParams) => void;
    updateNewLessonFromCreateCourse?: (lesson: CreateLessonRequestParams) => void;
    deleteNewLessonFromCreateCourse?: () => void;
    handleTargetNewLessonIdxChange?: (idx: number | undefined) => void;
    handleCalendarClose?: () => void;
}

export const LessonCalendarContents: React.VFC<Props> = memo((props) => {
    const [lessonCalendarUnit, setLessonCalendarUnit] = useState<"week" | "month">("month");
    const [lessonCalendarStartDate, setLessonCalendarStartDate] = useState<Date>(startOfWeek(new Date()));

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const startQueryParams = searchParams.get("start");

    const mdUp = useMediaQuery("(min-width:960px)");

    useEffect(() => {
        if (!startQueryParams) return;
        const lessonCalendarStartDate = new Date(startQueryParams);
        setLessonCalendarStartDate(startOfWeek(lessonCalendarStartDate));
    }, [startQueryParams]);

    useEffect(() => {
        if (!props.targetStartDate) return;
        setLessonCalendarStartDate(startOfWeek(props.targetStartDate));
    }, [props.targetStartDate]);

    useEffect(() => {
        if (!props.targetUnit) return;
        setLessonCalendarUnit(props.targetUnit);
    }, [props.targetUnit]);

    const getLaneDate = useCallback(
        (idx) => {
            const date = new Date(lessonCalendarStartDate);
            date.setDate(lessonCalendarStartDate.getDate() + idx);
            return date;
        },
        [lessonCalendarStartDate],
    );

    const handleLessonCalendarStartDateChange = useCallback((lessonCalendarStartDate: Date) => {
        setLessonCalendarStartDate(lessonCalendarStartDate);
    }, []);

    const handleUnitChange = useCallback((value: "week" | "month") => {
        setLessonCalendarUnit(value);
    }, []);

    return (
        <div className={styles.lessonCalendarWrapper}>
            {props.isDraggable && lessonCalendarUnit === "month" && (
                <div className={styles.monthMessage}>授業を追加・編集したい週を選択してください。</div>
            )}
            {props.isDraggable && lessonCalendarUnit === "week" && (
                <div className={styles.weekMessage}>
                    授業を追加・編集したい時間を{mdUp ? "ドラッグ" : "クリック"}してください。
                </div>
            )}
            <LessonCalendarTop
                lessonCalendarStartDate={lessonCalendarStartDate}
                lessonCalendarUnit={lessonCalendarUnit}
                handleLessonCalendarStartDateChange={handleLessonCalendarStartDateChange}
                handleUnitChange={handleUnitChange}
            />
            {lessonCalendarUnit === "month" ? (
                <LessonCalendarOfMonth
                    lessonCalendarStartDate={lessonCalendarStartDate}
                    lessons={props.lessons}
                    handleLessonCalendarStartDateChange={handleLessonCalendarStartDateChange}
                    handleUnitChange={handleUnitChange}
                />
            ) : (
                <>
                    <LessonCalendarOfWeekHeadings getLaneDate={getLaneDate} />
                    <LessonCalendarOfWeekLanes
                        lessons={props.lessons}
                        lessonCalendarStartDate={lessonCalendarStartDate}
                        targetNewLessonIdx={props.targetNewLessonIdx}
                        isDraggable={props.isDraggable}
                        maxModalContentsHeight={props.maxModalContentsHeight}
                        getLaneDate={getLaneDate}
                        handleLessonCalendarStartDateChange={handleLessonCalendarStartDateChange}
                        createNewLessonFromCreateCourse={props.createNewLessonFromCreateCourse}
                        updateNewLessonFromCreateCourse={props.updateNewLessonFromCreateCourse}
                        deleteNewLessonFromCreateCourse={props.deleteNewLessonFromCreateCourse}
                        handleTargetNewLessonIdxChange={props.handleTargetNewLessonIdxChange}
                        handleCalendarClose={props.handleCalendarClose}
                    />
                </>
            )}
        </div>
    );
});
