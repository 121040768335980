import { memo, useCallback, useEffect, useState } from "react";

import { Button } from "@material-ui/core";
import { useHistory, useParams } from "react-router";

import { CourseResponse, ClassResponse } from "@/store/autogenApi";

import styles from "@/pages/Teacher/CourseManagement/index.module.scss";

import { ClassList } from "./ClassList";
import { TargetClass } from "./TargetClass";
import { ManagementTopContents } from "./ManagementTopContents";
import { Navigation, NavigationComponent } from "@/components/Navigation";
import { useNavigation } from "@/components/Navigation/NavigationContext";

interface Props {
    course: CourseResponse;
}

export const CourseManagementContents: React.VFC<Props> = memo(function ShortCourseContents(props) {
    const [targetClass, setTargetClass] = useState<ClassResponse | undefined>(undefined);

    const { classId } = useParams<{ courseId: string; classId?: string }>();

    const history = useHistory();

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        const { title, courseType } = props.course;
        if (!title) return;
        const navigationList = [
            { title: "My講座一覧", url: `/Teacher/MyPage/MyCourses/?courseType=${courseType}` },
            {
                title: title,
                url: `/CourseManagement/${props.course.courseId}`,
            },
        ];
        updateNavigationList(navigationList);
    }, [props.course]);

    useEffect(() => {
        const foundClass = props.course.classes?.find((classResponse) => classResponse.classId === classId);
        if (!foundClass) return;
        setTargetClass(foundClass);
    }, [classId, props.course.classes]);

    const handleBackCourseManagementButtonClick = useCallback(() => {
        window.location.reload();
    }, []);

    const handleTargetClassChange = useCallback((classResponse: ClassResponse | undefined) => {
        setTargetClass(classResponse);
        if (!classResponse) {
            history.push(`/CourseManagement/${props.course.courseId}`);
            return;
        }
        history.push(`/CourseManagement/${props.course.courseId}/${classResponse.classId}`);
    }, []);

    const handleBackButtonClick = useCallback(() => {
        setTargetClass(undefined);
        history.push(`/CourseManagement/${props.course.courseId}`);
    }, [props.course.courseId]);

    return (
        <div className={styles.courseManagementContents}>
            <ManagementTopContents
                course={props.course}
                targetClass={targetClass}
                handleBackButtonClick={handleBackButtonClick}
            />
            {targetClass ? (
                <TargetClass
                    course={props.course}
                    targetClass={targetClass}
                    handleTargetClassChange={handleTargetClassChange}
                />
            ) : (
                <ClassList
                    classes={props.course.classes}
                    courseType={props.course.courseType}
                    handleTargetClassChange={handleTargetClassChange}
                />
            )}
        </div>
    );
});
