import { memo, useCallback, useEffect } from "react";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import styles from "@/pages/Student/CreatePublicRequest/index.module.scss";
import { Mandatory } from "@/components/Tag/Mandatory";

interface Props {
    value: string;
    checked: boolean;
    validation: boolean;
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    open?: boolean;
    courseType: string;
}

export const Detail: React.VFC<Props> = memo(function Detail(props) {
    const fixPlaceholder = useCallback(() => {
        const element: HTMLInputElement | null = document.getElementById("descriptionTextArea") as HTMLInputElement;
        if (element) {
            element.placeholder = element.placeholder.replace(/\\n/g, "\n");
        }
    }, []);
    useEffect(() => {
        fixPlaceholder();
    }, []);

    const getExample = useCallback(() => {
        if (props.courseType === "short") {
            return "例）数学の期末試験が再来週にあるのですが、二次関数が全く理解できていないのでそこを教えていただきたいです。...";
        } else if (props.courseType === "regular") {
            return "例）中学2年の息子の母です。高校受験に向けてオンライン家庭教師をお願いしたいです。具体的には、...";
        }
    }, [props.courseType]);

    return (
        <li className={styles.listItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>講座リクエストの詳細</div>
                    {!props.open && <Mandatory />}
                </div>
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>{props.value}</div>
                ) : (
                    <>
                        <TextareaAutosize
                            required
                            placeholder={`講座リクエストの詳細(必須1,000字以内)\n\n${getExample()}`}
                            className={styles.textArea}
                            name="requestDescription"
                            onChange={props.handleChange}
                            minRows={7}
                            id="descriptionTextArea"
                            value={props.value}
                        />
                        {props.checked && !props.validation && (
                            <div className={styles.helperText}>この項目は必須です。1000字以内で入力してください。</div>
                        )}
                    </>
                )}
            </div>
        </li>
    );
});
