import { memo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { RootState } from "@/ducks";
import { useGetAllFavoriteRequestsQuery } from "@/store/hooks/teachers";
import { CommonComponent } from "./CommonComponent";
import { FavoriteContents } from "./FavoriteContents";

interface Props {
    userMode: string;
}

export const ForTeacherComponent: React.VFC<Props> = memo(function ForStudentComponent(props) {
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);
    const { id } = useParams<{ id: string }>();
    const favoriteRequestsQueryState = useGetAllFavoriteRequestsQuery(teacherId);
    return (
        <QueryLoadingWrapper {...favoriteRequestsQueryState}>
            {(favoriteRequests) => (
                <CommonComponent
                    userMode={props.userMode}
                    isFavorite={favoriteRequests.some((publicRequest) => publicRequest.publicRequestId === id)}
                />
            )}
        </QueryLoadingWrapper>
    );
});
