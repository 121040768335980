import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuthorization } from "@/utils/authentication";
import { useMemo } from "react";

const API_URL =
    window.location.hostname === "localhost" ? "http://localhost:3333" : "https://api." + window.location.host;
export const getApiUrl = () => {
    return API_URL;
};

export const emptySplitApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: async (headers) => {
            const authorization = await getAuthorization();
            if (authorization) {
                headers.append("authorization", authorization);
            }
            return headers;
        },
    }),
    endpoints: () => ({}),
});
