export const MIN_PRICE = 100;
export const MAX_PRICE = 1000000;

export const MINUTES_LIST_PER_LESSON = [
    5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 130, 140, 150,
    160, 170, 180, 190, 200, 210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370, 380,
    390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520, 530, 540, 550, 560, 570, 580, 590, 600, 610,
    620, 630, 640, 650, 660, 670, 680, 690, 700, 710, 720,
];

export const LOCATIONS_WITH_ONLY_LOGO = [
    "/Forbidden",
    "/InfoInput",
    "/InfoInputComplete",
    "/Teacher/InfoInput",
    "/Teacher/InfoInputComplete",
    "/CompletedWithdrawal",
    "/AboutTrail",
    "/AboutTeacher",
];
