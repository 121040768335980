import styles from "../index.module.scss";

interface Props {
    title: string;
    children: React.ReactNode;
    borderBottom?: boolean;
}

export const ListItem: React.VFC<Props> = (props) => {
    return (
        <li className={styles.listItem} style={{ borderBottom: props.borderBottom ? "1px solid #305077" : "none" }}>
            <div className={styles.itemTitle}>{props.title}</div>
            <div className={styles.itemContent}>{props.children}</div>
        </li>
    );
};
