import { memo, useCallback, useEffect } from "react";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import styles from "@/pages/Student/CreatePublicRequest/index.module.scss";
import { Optional } from "@/components/Tag/Optional";

interface Props {
    value: string;
    checked: boolean;
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    open?: boolean;
    courseType: string;
    validation: boolean;
}

export const Remarks: React.VFC<Props> = memo(function Remarks(props) {
    const fixPlaceholder = useCallback(() => {
        const element: HTMLInputElement | null = document.getElementById("remarksTextArea") as HTMLInputElement;
        if (element) {
            element.placeholder = element.placeholder.replace(/\\n/g, "\n");
        }
    }, []);

    useEffect(() => {
        fixPlaceholder();
    }, []);

    const getExample = useCallback(() => {
        if (props.courseType === "short") {
            return "例）人見知りなので、どんどん話しかけていただけると嬉しいです。";
        } else if (props.courseType === "regular") {
            return "例）部活の関係上、不定期にお休みをいただく場合がありますので、ご理解いただけますと幸いです。";
        }
    }, [props.courseType]);

    return (
        <li className={styles.listItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>備考</div>
                    {!props.open && <Optional />}
                </div>
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>{props.value ?? "-"}</div>
                ) : (
                    <>
                        <TextareaAutosize
                            required
                            placeholder={`備考(任意300字以内)\n\n${getExample()}`}
                            className={styles.textArea}
                            name="additionalDescription"
                            onChange={props.handleChange}
                            minRows={5}
                            id="remarksTextArea"
                            value={props.value}
                        />
                        {props.checked && !props.validation && (
                            <div className={styles.helperText}>
                                この項目は任意ですが、入力する場合は300字以内で入力してください。
                            </div>
                        )}
                    </>
                )}
            </div>
        </li>
    );
});
