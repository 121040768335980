import { MenuItem, Select } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { memo } from "react";

import styles from "../index.module.scss";
import { SubjectResponse } from "@/store/autogenApi";

interface Props {
    title: string;
    subjectKey: keyof SubjectResponse;
    value: string | undefined;
    items: string[];
    handleChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
        key: keyof SubjectResponse,
    ) => void;
    changeToJapanese: (value: string) => string;
}

const theme = createTheme({
    overrides: {
        MuiList: {
            root: {
                maxHeight: "500px",
                overflowY: "scroll",
            },
            padding: {
                paddingTop: "0",
                paddingBottom: "0",
            },
        },
    },
});

export const SelectTemplate: React.VFC<Props> = memo(function SelectTemplate(props) {
    return (
        <div className={styles.condition}>
            <div className={styles.conditionTitle}>{props.title}</div>
            <ThemeProvider theme={theme}>
                <Select
                    className={styles.select}
                    disableUnderline
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "center",
                            horizontal: "right",
                        },
                        transformOrigin: {
                            vertical: "center",
                            horizontal: "left",
                        },
                        getContentAnchorEl: null,
                    }}
                    value={props.value}
                    onChange={(e) => {
                        props.handleChange(e, props.subjectKey);
                    }}
                >
                    <MenuItem key={""} value={""} className={styles.selectItem}>
                        -
                    </MenuItem>
                    {props.items.map((item) => {
                        return (
                            <MenuItem key={item} value={item} className={styles.selectItem}>
                                {props.changeToJapanese(item)}
                            </MenuItem>
                        );
                    })}
                </Select>
            </ThemeProvider>
        </div>
    );
});
