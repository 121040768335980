import { MyPublicRequestCard } from "./MyPublicRequestCard";
import styles from "./index.module.scss";
import { PublicRequestResponse } from "@/store/autogenApi";
import { RequestCard } from "@/components/RequestCard";

interface Props {
    visible: boolean;
    publicRequests: PublicRequestResponse[];
    repetition: number;
    handlePublicRequestCardClick: (request: PublicRequestResponse) => void;
}

export const MyPublicRequestsTabContents: React.VFC<Props> = (props) => {
    return (
        <div role="tabpanel" hidden={!props.visible} className={styles.tabContents}>
            {props.visible && (
                <>
                    {props.publicRequests.length === 0 ? (
                        <div className={styles.emptyPublicRequests}>対象の下書きがありません。</div>
                    ) : (
                        <div className={styles.cardsList}>
                            {[...Array(Math.ceil(props.publicRequests.length / props.repetition))]
                                .map((_, i) => i)
                                .map((idx) => (
                                    <>
                                        <div className={styles.cards} key={idx}>
                                            {props.publicRequests
                                                .slice(
                                                    idx * props.repetition,
                                                    idx * props.repetition + props.repetition,
                                                )
                                                .map((publicRequest) => {
                                                    return (
                                                        <RequestCard
                                                            publicRequest={publicRequest}
                                                            key={publicRequest.publicRequestId}
                                                            isFlex
                                                        />
                                                    );
                                                })}
                                            {props.publicRequests.slice(
                                                idx * props.repetition,
                                                idx * props.repetition + props.repetition,
                                            ).length < props.repetition &&
                                                [
                                                    ...Array(
                                                        props.repetition -
                                                            props.publicRequests.slice(
                                                                idx * props.repetition,
                                                                idx * props.repetition + props.repetition,
                                                            ).length,
                                                    ),
                                                ].map((_, idx) => <RequestCard key={idx} isHidden isFlex />)}
                                        </div>
                                    </>
                                ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
