import { BaseScheduleResponse } from "@/store/autogenApi";
import { ListItem } from "../ListItem";

import styles from "../index.module.scss";
import { getTotalTimeInAMonth } from "@/utils/BaseScheduleUtils";

interface Props {
    baseSchedules: BaseScheduleResponse[];
    isConfirmed?: boolean;
}

export const TotalTimeInAMonth: React.VFC<Props> = (props) => {
    return (
        <ListItem title="月の平均授業時間" borderBottom>
            <div className={styles.totalTime}>{getTotalTimeInAMonth(props.baseSchedules)}</div>
        </ListItem>
    );
};
