import styles from "@/pages/Common/LP/index.module.scss";
import trailImage from "@/images/logo-v2-with-trail.png";
import topPageTopImage from "../img/topPageTop.png";
import topPageBottomImage from "../img/topPageBottom.png";
import teacherSearchImage from "../img/teacherSearch.png";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { useCallback, useState } from "react";
import { ClickableHandIcon } from "@/globalIcons";
import { StyledModal } from "@/components/StyledModal";

const imageSx = {
    flex: 1,
    minWidth: 0, // 設定しないと何故かはみ出る
    cursor: "pointer",
    "&:hover": {
        opacity: 0.8,
    },
};

const images = [topPageTopImage, topPageBottomImage, teacherSearchImage];

export const AboutStudentTopContents: React.VFC = () => {
    const [detailModalOpen, setDetailModalOpen] = useState(false);
    const [targetImage, setTargetImage] = useState<string | null>(null);
    const handleImageClick = useCallback((image: string) => {
        setDetailModalOpen(true);
        setTargetImage(image);
    }, []);
    const handleModalClose = useCallback(() => {
        setDetailModalOpen(false);
    }, []);
    return (
        <div className={styles.aboutTopContents}>
            <div className={styles.catchCopyWrapper}>
                <div className={styles.preCatchCopy}>1人1人に合った授業を選べる時代</div>
                <div className={styles.catchCopy}>
                    <span className={styles.strong}>【学び】</span>のフリマアプリ
                </div>
                <img src={trailImage} className={styles.logo} />
                <div className={styles.preCatchCopy}>
                    で<span className={styles.strong}>あなたに合った先生</span>
                    を見つけよう
                </div>
            </div>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    padding: "20px",
                    margin: "20px auto",
                    borderRadius: "8px",
                    backgroundColor: "#305077",
                    position: "relative",
                }}
            >
                {images.map((image) => (
                    <Box
                        component="img"
                        src={image}
                        alt="mockup image"
                        sx={imageSx}
                        onClick={() => {
                            handleImageClick(image);
                        }}
                    />
                ))}
                <Box
                    sx={{
                        position: "absolute",
                        right: "0",
                        bottom: "0",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#FFF",
                        border: "1px solid #305077",
                        padding: "4px",
                    }}
                >
                    <Box
                        sx={{
                            color: "#305077",
                            fontSize: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <ClickableHandIcon />
                    </Box>
                    <Box
                        sx={{
                            color: "#305077",
                            fontSize: "12px",
                            fontWeight: "bold",
                        }}
                    >
                        クリックで拡大！
                    </Box>
                </Box>
            </Box>
            <StyledModal
                open={detailModalOpen}
                existCloseButton
                autoResize
                style={{ borderRadius: "30px", padding: "0 20px" }}
                onClose={handleModalClose}
            >
                <Box
                    component="img"
                    src={targetImage}
                    sx={{
                        display: "block",
                        width: { xs: "100%", md: "400px" },
                        margin: "20px auto",
                    }}
                />
            </StyledModal>
            <div className={styles.bottomWrapper}>
                <Box
                    sx={{
                        fontSize: "20px",
                        textAlign: "center",
                        background: "radial-gradient(rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%)",
                        marginBottom: "20px",
                    }}
                >
                    Trailは、
                    <Box sx={{ fontWeight: "bold" }}>
                        体験としての「学び」を
                        <br />
                        売り買いすることができる
                        <br />
                        スキルマーケット
                    </Box>
                </Box>
                <div className={styles.squares}>
                    <div className={styles.square}>
                        <div className={styles.pointIndex}>POINT1.</div>
                        <div className={styles.pointDescription}>
                            <span className={styles.strong}>あなたに合った</span>
                            <br />
                            形で最適な授業を受けられる
                        </div>
                    </div>
                    <div className={styles.square}>
                        <div className={styles.pointIndex}>POINT2.</div>
                        <div className={styles.pointDescription}>
                            <span className={styles.strong}>自由</span>
                            <br />
                            に予算を設定して先生を募集
                        </div>
                    </div>
                    <div className={styles.square}>
                        <div className={styles.pointIndex}>POINT3.</div>
                        <div className={styles.pointDescription}>
                            <span className={styles.strong}>名大生</span>
                            <br />
                            を中心に優秀な先生が多数在籍
                        </div>
                    </div>
                </div>
                <div className={styles.signUpWrapper}>
                    <Button className={styles.signUpButton} component={Link} to="/SignUp?type=student">
                        新規ユーザー登録へ（無料）
                    </Button>
                </div>
            </div>
        </div>
    );
};
