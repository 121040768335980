import { useCallback, useEffect, useRef, useState } from "react";

import emailjs from "@emailjs/browser";
import { Button, CircularProgress, useMediaQuery } from "@material-ui/core";
import { init } from "emailjs-com";
import { Link } from "react-router-dom";

import { RouterPrompt } from "@/components/RouterPrompt";

import styles from "./index.module.scss";
import { INQUIRY_LIST } from "./inquiryList";
import { Mandatory } from "@/components/Tag/Mandatory";
import { InquiryConfirmationModal } from "@/pageComponents/Common/Inquiry/InquiryConfirmationModal";
import { Navigation } from "@/components/Navigation";
import { useNavigation } from "@/components/Navigation/NavigationContext";

interface Validation {
    inquiryType: boolean;
    userName: boolean;
    userEmail: boolean;
    message: boolean;
}

const NAVIGATION_LIST: Navigation[] = [{ title: "お問い合わせ", url: "/Inquiry" }];

export const Inquiry: React.VFC = () => {
    const [complete, setComplete] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(false);
    const [validation, setValidation] = useState<Validation>({
        inquiryType: false,
        userName: false,
        userEmail: false,
        message: false,
    });
    const [messageLength, setMessageLength] = useState<number>(0);
    const [isEdited, setIsEdited] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const [currentInquiryWrapperEl, setCurrentInquiryWrapperEl] = useState<HTMLElement | null>(null);

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    const sm = useMediaQuery("(min-width: 600px)");
    const md = useMediaQuery("(min-width: 960px)");

    const inquiryWrapperRef = useCallback((headerEl: HTMLElement | null) => {
        setCurrentInquiryWrapperEl(headerEl);
    }, []);

    const changeHeaderWrapperHeight = useCallback(() => {
        const header = document.getElementById("header");
        const footer = document.getElementById("footer");
        if (!header || !footer || !currentInquiryWrapperEl) return;
        const headerHeight = header.offsetHeight;
        const footerHeight = footer.offsetHeight;
        const windowHeight = window.innerHeight;
        console.log(windowHeight);
        // 調整用
        const delta = md ? 40 : sm ? 32 : 20;
        currentInquiryWrapperEl.style.minHeight = `${windowHeight - (headerHeight + footerHeight + delta)}px`;
    }, [currentInquiryWrapperEl]);

    useEffect(() => {
        changeHeaderWrapperHeight();
        window.addEventListener("resize", changeHeaderWrapperHeight);
        return () => {
            window.removeEventListener("resize", changeHeaderWrapperHeight);
        };
    }, [changeHeaderWrapperHeight]);

    const form = useRef<HTMLFormElement | null | undefined>(undefined);

    useEffect(() => {
        init("glGiDP7Y21Gmw8--4");
    }, []);

    // EmailJSのSriviceID・TemplateID・UserIDを入力
    const emailjsServiceId = "service_jpbfblr";
    const emailjsTemplateId = "template_rnirn2t";
    const emailjsUserId = "glGiDP7Y21Gmw8--4";

    // EmailJSのUserIDで初期化
    init(emailjsUserId);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setIsEdited(true);
        const name = e.target.name;
        const value = e.target.value;
        let result: boolean;
        if (name === "inquiryType") {
            if (value.length > 0) {
                result = true;
            } else {
                result = false;
            }
        } else if (name === "userName") {
            if (0 < value.length && value.length <= 20) {
                result = true;
            } else {
                result = false;
            }
        } else if (name === "userEmail") {
            const pattern =
                /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
            if (pattern.test(value)) {
                result = true;
            } else {
                result = false;
            }
        } else {
            setMessageLength(value.length);
            if (0 < value.length && value.length <= 2000) {
                result = true;
            } else {
                result = false;
            }
        }
        setValidation({ ...validation, [name]: result });
    };

    const handleCheckButtonClick = useCallback(() => {
        setChecked(true);
        const validationComplete = Object.entries(validation).every(([key, value]) => key && value);
        if (validationComplete) {
            setModalOpen(true);
        }
    }, [validation, setChecked, setModalOpen]);

    const handleModalClose = useCallback(() => {
        setModalOpen(false);
    }, []);

    const handleSubmitButtonClick = useCallback(async () => {
        setIsProcessing(true);
        await emailjs.sendForm(emailjsServiceId, emailjsTemplateId, form.current, emailjsUserId).then(
            (result) => {
                result;
                setComplete(true);
                // console.log(result.text);
            },
            (error) => {
                error;
                // console.log(error.text);
            },
        );
        setModalOpen(false);
    }, [setIsProcessing, setComplete, form, emailjsServiceId, emailjsTemplateId, emailjsUserId]);

    return (
        <>
            <RouterPrompt
                when={!complete && isEdited}
                message="フォームは未送信です。現在の内容は失われますが、操作を続行してもよろしいですか？"
                onOK={() => true}
                onCancel={() => false}
            />
            <div className={styles.inquiryWrapper} ref={inquiryWrapperRef}>
                <div className={styles.inquiryContents}>
                    <h2 className={styles.inquiryFormTitle}>お問い合わせ</h2>
                    {complete ? (
                        <div className={styles.completeWrapper}>
                            <div className={styles.complete}>
                                入力内容が送信されました。自動返信メールを受信したことをご確認ください。
                            </div>
                            <Button className={styles.homeButton} component={Link} to="/">
                                ホームに戻る
                            </Button>
                        </div>
                    ) : (
                        <>
                            <div className={styles.noticeWrapper}>
                                <div className={styles.notice}>
                                    お問い合わせには、下記の営業時間内に順次対応させていただきます。
                                </div>
                                <div className={styles.notice}>
                                    営業時間 9：00～17：00（土・日・祝日と当社の年末年始・夏季休暇を除く）
                                </div>
                                <div className={styles.notice}>
                                    休日にいただいたメールは翌営業日以降のご返信とさせていただきます。お問合せ内容によってはお返事までにお時間をいただく場合や、お返事を差し上げられない場合がございます。予めご了承ください。
                                </div>
                            </div>
                            <form
                                ref={(node: HTMLFormElement | null) => {
                                    form.current = node;
                                }}
                                className={styles.inquiryForm}
                            >
                                <div className={styles.formItems}>
                                    <div className={styles.formItem} style={{ borderBottom: "1px solid #CCC" }}>
                                        <div className={styles.leftWrapper}>
                                            <label className={styles.itemTitle}>お問い合わせ種別</label>
                                            <Mandatory />
                                        </div>
                                        <div className={styles.rightWrapper}>
                                            <select
                                                name="inquiryType"
                                                onChange={handleChange}
                                                className={styles.select}
                                                style={{ color: validation.inquiryType ? "#333" : "#CCC" }}
                                            >
                                                <option hidden>(選択必須)</option>
                                                {INQUIRY_LIST.map((inquiryType, index) => (
                                                    <option key={index} value={inquiryType}>
                                                        {inquiryType}
                                                    </option>
                                                ))}
                                            </select>
                                            {checked && !validation.inquiryType && (
                                                <div className={styles.error}>選択してください。</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.formItem} style={{ borderBottom: "1px solid #CCC" }}>
                                        <div className={styles.leftWrapper}>
                                            <label className={styles.itemTitle}>お名前</label>
                                            <Mandatory />
                                        </div>
                                        <div className={styles.rightWrapper}>
                                            <input
                                                type="text"
                                                name="userName"
                                                onChange={handleChange}
                                                className={styles.textInput}
                                                placeholder="(必須20字以内)"
                                            />
                                            {checked && !validation.userName && (
                                                <div className={styles.error}>20字以内で入力してください。</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.formItem} style={{ borderBottom: "1px solid #CCC" }}>
                                        <div className={styles.leftWrapper}>
                                            <label className={styles.itemTitle}>メールアドレス</label>
                                            <Mandatory />
                                        </div>
                                        <div className={styles.rightWrapper}>
                                            <input
                                                type="text"
                                                name="userEmail"
                                                onChange={handleChange}
                                                className={styles.textInput}
                                                placeholder="(必須)"
                                            />
                                            {checked && !validation.userEmail && (
                                                <div className={styles.error}>
                                                    メールアドレスに誤りがあります。正しく入力してください。
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.formItem}>
                                        <div className={styles.leftWrapper}>
                                            <label className={styles.itemTitle}>メッセージ</label>
                                            <Mandatory />
                                        </div>
                                        <div className={styles.rightWrapper}>
                                            <div className={styles.textAreaAndCount}>
                                                <textarea
                                                    name="message"
                                                    onChange={handleChange}
                                                    className={styles.textArea}
                                                    placeholder="(必須2000字以内)"
                                                />
                                                <div className={styles.count}>{messageLength}/2000字</div>
                                            </div>
                                            {checked && !validation.message && (
                                                <div className={styles.error}>2000字以内で入力してください。</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <Button className={styles.submitButton} onClick={handleCheckButtonClick}>
                                    確認
                                </Button>
                            </form>
                        </>
                    )}
                </div>
            </div>
            {form.current && (
                <InquiryConfirmationModal
                    formElement={form.current}
                    open={modalOpen}
                    isProcessing={isProcessing}
                    handleClose={handleModalClose}
                    handleSubmitButtonClick={handleSubmitButtonClick}
                />
            )}
        </>
    );
};
