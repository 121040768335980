import { TextField } from "@material-ui/core";
import { memo, useCallback, useEffect, useState } from "react";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Mandatory } from "@/components/Tag/Mandatory";

import styles from "@/pages/Teacher/IdentificationForStripe/index.module.scss";

interface Props {
    checked: boolean;
    handlePhoneChange: (value: string) => void;
}

export const Phone: React.VFC<Props> = memo(function Phone(props) {
    const [first, setFirst] = useState("");
    const [second, setSecond] = useState("");
    const [third, setThird] = useState("");

    useEffect(() => {
        const numberOfDigits = first.length + second.length + third.length;
        if (numberOfDigits === 10 || numberOfDigits === 11) {
            props.handlePhoneChange(`${first}${second}${third}`);
        } else {
            props.handlePhoneChange("");
        }
    }, [first, second, third]);

    const handleFirstChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        setFirst(value);
    }, []);

    const handleSecondChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        setSecond(value);
    }, []);

    const handleThirdChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        setThird(value);
    }, []);
    return (
        <div className={styles.formItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitle}>電話番号</div>
                <Mandatory />
            </div>
            <div className={styles.rightWrapper}>
                <div className={styles.phoneWrapper}>
                    <TextField
                        variant="outlined"
                        placeholder="〇〇〇"
                        className={styles.textField}
                        value={first}
                        onChange={handleFirstChange}
                    />
                    <div className={styles.bar}>-</div>
                    <TextField
                        variant="outlined"
                        placeholder="〇〇〇〇"
                        className={styles.textField}
                        value={second}
                        onChange={handleSecondChange}
                    />
                    <div className={styles.bar}>-</div>
                    <TextField
                        variant="outlined"
                        placeholder="〇〇〇〇"
                        className={styles.textField}
                        value={third}
                        onChange={handleThirdChange}
                    />
                </div>
                <ErrorMessage
                    content="メールアドレスを入力してください。"
                    when={props.checked && (first.length === 0 || second.length === 0 || third.length === 0)}
                />
                <ErrorMessage
                    content="不正なメールアドレスです。"
                    when={
                        props.checked &&
                        first.length > 0 &&
                        second.length > 0 &&
                        third.length > 0 &&
                        first.length + second.length + third.length !== 10 &&
                        first.length + second.length + third.length !== 11
                    }
                />
            </div>
        </div>
    );
});
