import { useMediaQuery } from "@material-ui/core";
import { useHistory, useLocation } from "react-router";

import { CourseType, DraftPublicRequestResponse } from "@/store/autogenApi";
import { memo, useCallback, useEffect, useState } from "react";
import { DraftPublicRequestDetailsModal } from "./DraftPublicRequestDetailsModal";
import { MyDraftPublicRequestsTabContents } from "./MyDraftPublicRequestsTabContents";
import { TabsTemplate } from "@/components/TabsTemplate";
import { getTabInfoList } from "@/utils/DraftPublicRequestUtils";

interface Props {
    draftPublicRequests: DraftPublicRequestResponse[];
}

const COURSE_TYPES: CourseType[] = ["regular", "short"];

export const MyDraftPublicRequestsContents: React.VFC<Props> = memo(function MyDraftPublicRequestsContents(props) {
    const [courseType, setCourseType] = useState<CourseType>("regular");
    const [targetDraftPublicRequest, setTargetPublicRequest] = useState<DraftPublicRequestResponse | undefined>(
        undefined,
    );

    const sm = useMediaQuery("(min-width:600px)");
    const md = useMediaQuery("(min-width:960px)");
    const lg = useMediaQuery("(min-width:1280px)");
    const xl = useMediaQuery("(min-width:1536px)");

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const courseTypeQueryParams = searchParams.get("courseType");

    useEffect(() => {
        if (courseTypeQueryParams) {
            setCourseType(courseTypeQueryParams as CourseType);
        }
    }, [courseTypeQueryParams]);

    const history = useHistory();

    const handleTabChange = useCallback((event: React.ChangeEvent<Record<string, unknown>>, value: string) => {
        switch (value) {
            case "regular":
                history.push("/MyPage/MyDraftPublicRequests?courseType=regular");
                break;
            case "short":
                history.push("/MyPage/MyDraftPublicRequests?courseType=short");
                break;
        }
    }, []);

    const handleDraftPublicRequestCardClick = useCallback((publicRequest: DraftPublicRequestResponse) => {
        setTargetPublicRequest(publicRequest);
    }, []);

    const handleDraftPublicRequestDetailsModalClose = useCallback(() => {
        setTargetPublicRequest(undefined);
    }, []);

    return (
        <>
            <TabsTemplate
                targetTabValue={courseType}
                tabInfoList={getTabInfoList(props.draftPublicRequests)}
                handleTabChange={handleTabChange}
            >
                {COURSE_TYPES.map((targetCourseType) => (
                    <MyDraftPublicRequestsTabContents
                        visible={targetCourseType === courseType}
                        draftPublicRequests={props.draftPublicRequests.filter(
                            (publicRequest) => publicRequest.courseType === targetCourseType,
                        )}
                        repetition={xl ? 6 : lg ? 6 : md ? 6 : sm ? 5 : 3}
                        handleDraftPublicRequestCardClick={handleDraftPublicRequestCardClick}
                    />
                ))}
            </TabsTemplate>
            {targetDraftPublicRequest && (
                <DraftPublicRequestDetailsModal
                    targetDraftPublicRequest={targetDraftPublicRequest}
                    handleDraftPublicRequestDetailsModalClose={handleDraftPublicRequestDetailsModalClose}
                />
            )}
        </>
    );
});
