import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { Button, useMediaQuery } from "@material-ui/core";

import { LoadingProgress } from "@/components/LoadingProgress";
import styles from "@/pages/Teacher/CreateCourse/index.module.scss";
import {
    CourseType,
    CreateAvailableTimeRequestParams,
    CreateLessonRequestParams,
    LessonResponse,
    SubjectResponse,
    UpdateAvailableTimeRequestBody,
} from "@/store/autogenApi";

import { AdditionalDescription } from "./Items/AdditionalDescription";
import { ApplicationPeriod } from "./Items/ApplicationPeriod";
import { MaxNumber } from "./Items/MaxNumber";
import { ContentDescription } from "./Items/ContentDescription";
import { PictureUpload } from "./Items/PictureUpload";
import { PricePerHour } from "./Items/PricePerHour";
import { Schedules } from "./Items/Schedules";
import { Subjects } from "./Items/Subjects";
import { TargetDescription } from "./Items/TargetDescription";
import { Title } from "./Items/Title";
import { FileInfo } from "@/components/TrimmingModal";
import { config, ConfigKey } from "./LimitConfig";
import { CourseTypeComponent } from "./Items/CourseType";
import { TeachingMaterial } from "./Items/TeachingMaterial";
import { MinNumber } from "./Items/MinNumber";
import { getExistLessonConflict, getExistLessonConflictWithinLessons } from "@/utils/LessonUtils";
import { CreateCourseConfirmModal } from "./ConfirmModal";
import { WHAT_DAY_LIST } from "@/utils/WhatDayList";
import { PlainButton } from "@/components/PlainButton";
import { useHistory } from "react-router";

interface Props {
    modalMaxHeight: number;
    isProcessing: boolean;
    currentCourseId: string;
    existingLessons: LessonResponse[];
    isEdited: boolean;
    courseType: CourseType | undefined;
    pricePerHour: number;
    maxParticipants: number;
    minParticipants: number;
    title: string;
    teachingMaterial: string;
    courseDescription: string;
    targetDescription: string;
    additionalDescription: string;
    subjects: SubjectResponse[];
    maxMinutesPerLesson: number;
    maxDaysInAWeek: number;
    applicationPeriod: number;
    deadlineNumber: number;
    availableTimes: CreateAvailableTimeRequestParams[] | UpdateAvailableTimeRequestBody[];
    fileInfoList: FileInfo[];
    courseTypeChecked: boolean;
    existStudent: boolean;
    lessons: CreateLessonRequestParams[];
    applyingDeadline: Date;
    setAvailableTimes: React.Dispatch<
        React.SetStateAction<(CreateAvailableTimeRequestParams | UpdateAvailableTimeRequestBody)[]>
    >;
    deleteImage: (idx: number) => void;
    setFileInfoList: React.Dispatch<React.SetStateAction<FileInfo[]>>;
    setSubjects: React.Dispatch<React.SetStateAction<SubjectResponse[]>>;
    handleConfirmButtonClick: () => Promise<void>;
    handleDraftButtonClick: () => Promise<void>;
    handleCourseTypeChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handlePriceChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleMaxNumberChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleMinNumberChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleTitleChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleTeachingMaterialChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleCourseDescriptionChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleTargetDescriptionChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleAdditionalDescriptionChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleApplicationPeriodChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleDeadlineNumberChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleMaxDaysInAWeekChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleMaxMinutesPerLessonChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    setCourseTypeChecked: React.Dispatch<React.SetStateAction<boolean>>;
    handleLessonsChange: (newLessons: CreateLessonRequestParams[]) => void;
    handleApplyingDeadlineChange: (newApplyingDeadline: Date) => void;
}

export const CourseContents: React.VFC<Props> = memo((props) => {
    const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [priceRange, setPriceRange] = useState<number[]>([0, 0]);

    const deadlineValidation = useMemo<boolean>(() => {
        if (!props.applyingDeadline) return false;
        const targetDeadline = new Date(props.applyingDeadline);
        const targetLesson = props.lessons[0];
        if (!targetLesson) return false;
        const startTimeOfFirstLesson = new Date(targetLesson.startTime);
        return targetDeadline.getTime() < startTimeOfFirstLesson.getTime();
    }, [props.applyingDeadline, props.lessons]);

    const availableTimesLengthValidation = useMemo<boolean>(
        () =>
            props.availableTimes.some((availableTime) => {
                if (
                    availableTime.startHour != undefined &&
                    availableTime.startMinute != undefined &&
                    availableTime.endHour != undefined &&
                    availableTime.endMinute != undefined
                ) {
                    return true;
                } else {
                    return false;
                }
            }),
        [props.availableTimes],
    );

    const availableTimesConflictIndexes = useMemo<number[]>(() => {
        const results = props.availableTimes.map((availableTime, idx) => {
            if (
                availableTime.startHour == undefined ||
                availableTime.startMinute == undefined ||
                availableTime.endHour == undefined ||
                availableTime.endMinute == undefined
            )
                return false;
            const { startHour, startMinute, endHour, endMinute } = availableTime;
            const targetExistingLessons = props.existingLessons.filter(
                (lesson) => new Date(lesson.startTime).getDay() === idx,
            );
            const result = targetExistingLessons.some((lesson) => {
                const lessonStartTime = new Date(lesson.startTime);
                const lessonEndTime = new Date(lesson.endTime);
                if (
                    // lessonStartTime.getHour(), lessonStartTime.getMinute()を使って比較
                    // 比較の大小は左が早い時間であることを前提とする(<の向き)
                    // 開始時間が既存の授業時間内にあるか
                    ((lessonStartTime.getHours() < startHour ||
                        (lessonStartTime.getHours() === startHour && lessonStartTime.getMinutes() < startMinute)) &&
                        (startHour < lessonEndTime.getHours() ||
                            (startHour === lessonEndTime.getHours() && startMinute < lessonEndTime.getMinutes()))) ||
                    // 終了時間が既存の授業時間内にあるか
                    ((lessonStartTime.getHours() < endHour ||
                        (lessonStartTime.getHours() === endHour && lessonStartTime.getMinutes() < endMinute)) &&
                        (endHour < lessonEndTime.getHours() ||
                            (endHour === lessonEndTime.getHours() && endMinute < lessonEndTime.getMinutes()))) ||
                    // 既存の授業時間がavailableTimeの間にあるか
                    ((startHour < lessonStartTime.getHours() ||
                        (startHour === lessonStartTime.getHours() && startMinute < lessonStartTime.getMinutes())) &&
                        (lessonEndTime.getHours() < endHour ||
                            (lessonEndTime.getHours() === endHour && lessonEndTime.getMinutes() < endMinute)))
                ) {
                    return true;
                } else {
                    return false;
                }
            });
            return result;
        });
        const conflictIndexes = results
            .map((result, idx) => {
                if (result) return idx;
            })
            .flatMap((result) => (result === undefined ? [] : [result]));
        return conflictIndexes;
    }, [props.existingLessons, props.availableTimes]);

    const isNotDuplicateValidation = useMemo<boolean>(
        () => !getExistLessonConflictWithinLessons(props.lessons),
        [props.lessons],
    );

    const otherCourseScheduleValidation = useMemo(
        () => !getExistLessonConflict(props.lessons, props.existingLessons),
        [props.lessons, props.existingLessons],
    );

    const md = useMediaQuery("(min-width:960px)");
    const history = useHistory();

    const getAvailableTimesConflictIndexes = useCallback(() => {
        const results = props.availableTimes.map((availableTime, idx) => {
            if (
                availableTime.startHour == undefined ||
                availableTime.startMinute == undefined ||
                availableTime.endHour == undefined ||
                availableTime.endMinute == undefined
            )
                return false;
            const { startHour, startMinute, endHour, endMinute } = availableTime;
            const targetExistingLessons = props.existingLessons.filter(
                (lesson) => new Date(lesson.startTime).getDay() === idx,
            );
            const result = targetExistingLessons.some((lesson) => {
                const lessonStartTime = new Date(lesson.startTime);
                const lessonEndTime = new Date(lesson.endTime);
                if (
                    // lessonStartTime.getHour(), lessonStartTime.getMinute()を使って比較
                    // 比較の大小は左が早い時間であることを前提とする(<の向き)
                    // 開始時間が既存の授業時間内にあるか
                    ((lessonStartTime.getHours() < startHour ||
                        (lessonStartTime.getHours() === startHour && lessonStartTime.getMinutes() < startMinute)) &&
                        (startHour < lessonEndTime.getHours() ||
                            (startHour === lessonEndTime.getHours() && startMinute < lessonEndTime.getMinutes()))) ||
                    // 終了時間が既存の授業時間内にあるか
                    ((lessonStartTime.getHours() < endHour ||
                        (lessonStartTime.getHours() === endHour && lessonStartTime.getMinutes() < endMinute)) &&
                        (endHour < lessonEndTime.getHours() ||
                            (endHour === lessonEndTime.getHours() && endMinute < lessonEndTime.getMinutes()))) ||
                    // 既存の授業時間がavailableTimeの間にあるか
                    ((startHour < lessonStartTime.getHours() ||
                        (startHour === lessonStartTime.getHours() && startMinute < lessonStartTime.getMinutes())) &&
                        (lessonEndTime.getHours() < endHour ||
                            (lessonEndTime.getHours() === endHour && lessonEndTime.getMinutes() < endMinute)))
                ) {
                    return true;
                } else {
                    return false;
                }
            });
            return result;
        });
        const conflictIndexes = results
            .map((result, idx) => {
                if (result) return idx;
            })
            .flatMap((result) => (result === undefined ? [] : [result]));
        return conflictIndexes;
    }, [props.existingLessons, props.availableTimes]);

    useEffect(() => {
        if (props.courseType === "short") {
            setPriceRange([1000, 1000000]);
        } else if (props.courseType === "regular") {
            setPriceRange([1000, 100000]);
        } else {
            setPriceRange([500, 10000]);
        }
    }, [props.courseType]);

    const getIsValid = useCallback((key: ConfigKey, value: number) => {
        const minValue = (config[key] as number[])[0];
        const maxValue = (config[key] as number[])[1];
        return minValue <= value && value <= maxValue;
    }, []);

    const checkValidation = useCallback(() => {
        if (!props.courseType) return false;
        // 基本情報に該当し、class情報に該当しないもの
        // priceはclassによって異なるため、基本情報には含めず、shortとregularに含める
        const commonValidationKeys: string[] = [
            "courseType",
            "title",
            "subjects",
            "courseDescription",
            "teachingMaterial",
            "targetDescription",
            "fileInfoList",
            "additionalDescription",
        ];

        const shortCourseValidationKeys: string[] = [
            "lessonsLength",
            "areAllLessonsUpcoming",
            "lessonConflictIsNotExist",
            "classDeadline",
            "lessonConflictIsNotExistWithinLessons",
            "maxParticipants",
            "minParticipants",
            "pricePerHour",
        ];

        const regularCourseValidationKeys: string[] = [
            "availableTimesLength",
            "maxDaysInAWeek",
            "maxMinutesPerLesson",
            "applicationPeriod",
            "pricePerHour",
        ];
        const commonValidation = commonValidationKeys.every((key) => {
            if (key === "courseType") {
                return props.courseType !== undefined;
            }
            if (key === "title") {
                return getIsValid(key, props.title.length);
            }
            if (key === "subjects") {
                return props.subjects.length !== 0;
            }
            if (key === "courseDescription") {
                return getIsValid(key, props.courseDescription.length);
            }
            if (key === "teachingMaterial") {
                return getIsValid(key, props.teachingMaterial.length);
            }
            if (key === "targetDescription") {
                return getIsValid(key, props.targetDescription.length);
            }
            if (key === "additionalDescription") {
                return getIsValid(key, props.additionalDescription.length);
            }
            if (key === "fileInfoList") {
                return true;
            }
        });
        if (!commonValidation) return false;

        // 講座基本情報編集の場合はcommonValidationがtrueであればtrueを返す
        if (props.currentCourseId) return true;

        if (props.courseType === "short") {
            const shortCourseValidation = shortCourseValidationKeys.every((key) => {
                if (key === "lessonsLength") {
                    return props.lessons.length >= 1;
                }
                if (key === "areAllLessonsUpcoming") {
                    return props.lessons.every((lesson) => new Date().getTime() < new Date(lesson.startTime).getTime());
                }
                if (key === "lessonConflictIsNotExist") {
                    return otherCourseScheduleValidation;
                }
                if (key === "classDeadline") {
                    return deadlineValidation;
                }
                if (key === "lessonConflictIsNotExistWithinLessons") {
                    return isNotDuplicateValidation;
                }
                if (key === "maxParticipants") {
                    return getIsValid(key, props.maxParticipants) && props.maxParticipants >= props.minParticipants;
                }
                if (key === "minParticipants") {
                    return getIsValid(key, props.minParticipants);
                }
                if (key === "pricePerHour") {
                    return priceRange[0] <= props.pricePerHour && props.pricePerHour <= priceRange[1];
                }
            });
            return shortCourseValidation;
        } else if (props.courseType === "regular") {
            const regularCourseValidation = regularCourseValidationKeys.every((key) => {
                if (key === "availableTimesLength") {
                    return availableTimesLengthValidation;
                }
                if (key === "maxDaysInAWeek") {
                    return getIsValid(key, props.maxDaysInAWeek);
                }
                if (key === "maxMinutesPerLesson") {
                    return getIsValid(key, props.maxMinutesPerLesson);
                }
                if (key === "applicationPeriod") {
                    return getIsValid(key, props.applicationPeriod);
                }
                if (key === "pricePerHour") {
                    return priceRange[0] <= props.pricePerHour && props.pricePerHour <= priceRange[1];
                }
            });
            return regularCourseValidation;
        }
        return true;
    }, [
        props.courseType,
        props.title,
        props.maxParticipants,
        props.minParticipants,
        props.courseDescription,
        props.targetDescription,
        props.fileInfoList,
        props.additionalDescription,
        props.pricePerHour,
        props.subjects,
        props.maxMinutesPerLesson,
        props.maxDaysInAWeek,
        props.availableTimes,
        props.applicationPeriod,
        props.teachingMaterial,
        props.lessons,
        props.existingLessons,
        priceRange,
        deadlineValidation,
        availableTimesLengthValidation,
        isNotDuplicateValidation,
        otherCourseScheduleValidation,
        getIsValid,
    ]);

    const handleCheckButtonClick = useCallback(() => {
        setIsChecked(true);
        if (!checkValidation()) {
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        setConfirmModalOpen(true);
    }, [checkValidation]);

    const handleConfirmModalClose = useCallback(() => {
        setConfirmModalOpen(false);
    }, []);

    const handleBackButtonClick = useCallback(() => {
        if (!props.currentCourseId) return;
        history.push(`/CourseManagement/${props.currentCourseId}`);
    }, [props.currentCourseId]);

    return (
        <div className={styles.pageBody}>
            <div className={styles.createCourseWrapper}>
                <div className={styles.contents}>
                    <div className={styles.createCourseTopWrapper}>
                        {props.currentCourseId && (
                            <PlainButton noHorizontalPadding text="戻る" handleClick={handleBackButtonClick} />
                        )}
                        <div className={styles.createCourseTitle}>講座{props.currentCourseId ? "編集" : "作成"}</div>
                        {props.currentCourseId && (
                            <div className={styles.editSchedule}>
                                授業日程の編集は講座管理ページから行ってください。
                            </div>
                        )}
                    </div>
                    {isChecked && props.courseType && !checkValidation() && (
                        <div className={styles.errors}>
                            入力内容に不備があります。修正後、もう一度「確認」を押してください。
                        </div>
                    )}
                    <div className={styles.inputListWrapper}>
                        <div className={styles.inputListTitle}>講座基本情報</div>
                        <ul className={styles.inputList}>
                            <LoadingProgress loadingComplete={Boolean(!props.currentCourseId || props.courseType)}>
                                <CourseTypeComponent
                                    isChecked={props.courseTypeChecked}
                                    validation={props.courseType !== undefined}
                                    courseType={props.courseType}
                                    existStudent={props.existStudent}
                                    handleChange={props.handleCourseTypeChange}
                                />
                            </LoadingProgress>
                            {props.courseType && (
                                <>
                                    <Title
                                        isChecked={isChecked}
                                        value={props.title}
                                        validation={getIsValid("title", props.title.length)}
                                        existStudent={props.existStudent}
                                        handleChange={props.handleTitleChange}
                                    />
                                    {props.subjects && (
                                        <Subjects
                                            isChecked={isChecked}
                                            subjects={props.subjects}
                                            courseType={props.courseType}
                                            setSubjects={props.setSubjects}
                                        />
                                    )}
                                    <ContentDescription
                                        isChecked={isChecked}
                                        value={props.courseDescription as string}
                                        validation={getIsValid("courseDescription", props.courseDescription.length)}
                                        courseType={props.courseType}
                                        handleChange={props.handleCourseDescriptionChange}
                                    />
                                    <TeachingMaterial
                                        isChecked={isChecked}
                                        value={props.teachingMaterial}
                                        validation={getIsValid("teachingMaterial", props.teachingMaterial.length)}
                                        handleChange={props.handleTeachingMaterialChange}
                                    />
                                    <TargetDescription
                                        isChecked={isChecked}
                                        value={props.targetDescription}
                                        validation={getIsValid("targetDescription", props.targetDescription.length)}
                                        handleChange={props.handleTargetDescriptionChange}
                                    />
                                    <PictureUpload
                                        deleteImage={props.deleteImage}
                                        fileInfoList={props.fileInfoList}
                                        validation={true}
                                        setFileInfoList={props.setFileInfoList}
                                    />
                                    <AdditionalDescription
                                        handleChange={props.handleAdditionalDescriptionChange}
                                        isChecked={isChecked}
                                        value={props.additionalDescription as string}
                                        validation={getIsValid(
                                            "additionalDescription",
                                            props.additionalDescription.length,
                                        )}
                                        courseType={props.courseType}
                                    />
                                </>
                            )}
                        </ul>
                    </div>
                    {!props.currentCourseId && props.courseType && (
                        <div className={styles.inputListWrapper}>
                            <div className={styles.inputListTitle}>今回の募集情報</div>
                            <ul className={styles.inputList}>
                                {((!props.currentCourseId && props.courseType === "short") ||
                                    props.courseType !== "short") && (
                                    <Schedules
                                        isChecked={isChecked}
                                        deadlineNumber={props.deadlineNumber}
                                        maxMinutesPerLesson={props.maxMinutesPerLesson}
                                        courseType={props.courseType}
                                        maxDaysInAWeek={props.maxDaysInAWeek}
                                        availableTimes={props.availableTimes}
                                        existingLessons={props.existingLessons}
                                        lessons={props.lessons}
                                        applyingDeadline={props.applyingDeadline}
                                        availableTimesLengthValidation={availableTimesLengthValidation}
                                        setAvailableTimes={props.setAvailableTimes}
                                        handleMaxMinutesPerLessonChange={props.handleMaxMinutesPerLessonChange}
                                        handleMaxDaysInAWeekChange={props.handleMaxDaysInAWeekChange}
                                        otherCourseScheduleValidation={otherCourseScheduleValidation}
                                        isNotDuplicateValidation={isNotDuplicateValidation}
                                        deadlineValidation={deadlineValidation}
                                        handleLessonsChange={props.handleLessonsChange}
                                        handleApplyingDeadlineChange={props.handleApplyingDeadlineChange}
                                    />
                                )}
                                {(props.courseType === "regular" ||
                                    (props.courseType === "short" && props.lessons.length > 0)) && (
                                    <PricePerHour
                                        isChecked={isChecked}
                                        howManyDays={props.lessons?.length}
                                        courseType={props.courseType}
                                        pricePerHour={props.pricePerHour}
                                        lessons={props.lessons}
                                        priceRange={priceRange}
                                        validation={
                                            priceRange[0] <= props.pricePerHour && props.pricePerHour <= priceRange[1]
                                        }
                                        existStudent={props.existStudent}
                                        handleChange={props.handlePriceChange}
                                    />
                                )}
                                {props.courseType === "regular" && (
                                    <ApplicationPeriod
                                        applicationPeriod={props.applicationPeriod}
                                        courseType={props.courseType}
                                        validation={getIsValid("applicationPeriod", props.applicationPeriod)}
                                        isChecked={isChecked}
                                        handleChange={props.handleApplicationPeriodChange}
                                    />
                                )}
                                {props.courseType === "short" && (
                                    <>
                                        <MinNumber
                                            validation={getIsValid("minParticipants", props.minParticipants)}
                                            minParticipants={props.minParticipants}
                                            maxParticipants={props.maxParticipants}
                                            isChecked={isChecked}
                                            existStudent={props.existStudent}
                                            handleChange={props.handleMinNumberChange}
                                        />
                                        <MaxNumber
                                            validation={getIsValid("maxParticipants", props.maxParticipants)}
                                            maxParticipants={props.maxParticipants}
                                            isChecked={isChecked}
                                            existStudent={props.existStudent}
                                            handleChange={props.handleMaxNumberChange}
                                        />
                                    </>
                                )}
                            </ul>
                        </div>
                    )}
                    {props.courseType && (
                        <>
                            {availableTimesConflictIndexes.length > 0 && (
                                <div className={styles.conflictNotice}>
                                    {availableTimesConflictIndexes.map((idx) => WHAT_DAY_LIST[idx]).join("・")}
                                    の対応可能時間帯が他の講座の授業時間と重複しています。カレンダーを確認して、それでもよろしければ「確認」を押してください。
                                </div>
                            )}
                            <div className={styles.temporarySaveAndCheckButtonsWrapper}>
                                <Button className={styles.temporarySaveButton} onClick={props.handleDraftButtonClick}>
                                    下書きとして保存
                                </Button>
                                <Button
                                    className={styles.checkButton}
                                    onClick={() => {
                                        handleCheckButtonClick();
                                        props.setCourseTypeChecked(true);
                                    }}
                                >
                                    確認
                                </Button>
                            </div>
                        </>
                    )}
                    <CreateCourseConfirmModal
                        confirmModalOpen={confirmModalOpen}
                        isProcessing={props.isProcessing}
                        currentCourseId={props.currentCourseId}
                        existingLessons={props.existingLessons}
                        modalMaxHeight={props.modalMaxHeight}
                        courseType={props.courseType}
                        title={props.title}
                        teachingMaterial={props.teachingMaterial}
                        pricePerHour={props.pricePerHour}
                        maxParticipants={props.maxParticipants}
                        minParticipants={props.minParticipants}
                        applicationPeriod={props.applicationPeriod}
                        maxDaysInAWeek={props.maxDaysInAWeek}
                        maxMinutesPerLesson={props.maxMinutesPerLesson}
                        deadlineNumber={props.deadlineNumber}
                        subjects={props.subjects}
                        courseDescription={props.courseDescription}
                        targetDescription={props.targetDescription}
                        additionalDescription={props.additionalDescription}
                        fileInfoList={props.fileInfoList}
                        availableTimes={props.availableTimes}
                        lessons={props.lessons}
                        applyingDeadline={props.applyingDeadline}
                        availableTimesLengthValidation={availableTimesLengthValidation}
                        deadlineValidation={deadlineValidation}
                        setAvailableTimes={props.setAvailableTimes}
                        handleConfirmButtonClick={props.handleConfirmButtonClick}
                        setFileInfoList={props.setFileInfoList}
                        deleteImage={props.deleteImage}
                        setSubjects={props.setSubjects}
                        handleTitleChange={props.handleTitleChange}
                        handleTeachingMaterialChange={props.handleTeachingMaterialChange}
                        handlePriceChange={props.handlePriceChange}
                        handleMaxNumberChange={props.handleMaxNumberChange}
                        handleMinNumberChange={props.handleMinNumberChange}
                        handleApplicationPeriodChange={props.handleApplicationPeriodChange}
                        handleMaxDaysInAWeekChange={props.handleMaxDaysInAWeekChange}
                        handleMaxMinutesPerLessonChange={props.handleMaxMinutesPerLessonChange}
                        handleCourseDescriptionChange={props.handleCourseDescriptionChange}
                        handleTargetDescriptionChange={props.handleTargetDescriptionChange}
                        handleAdditionalDescriptionChange={props.handleAdditionalDescriptionChange}
                        handleCourseTypeChange={props.handleCourseTypeChange}
                        handleLessonsChange={props.handleLessonsChange}
                        handleApplyingDeadlineChange={props.handleApplyingDeadlineChange}
                        handleConfirmModalClose={handleConfirmModalClose}
                    />
                </div>
            </div>
        </div>
    );
});
