import {
    ELEMENTARY_SCHOOL_SUBJECT_CONFIG,
    HIGH_SCHOOL_SUBJECT_CONFIG,
    JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG,
    UNIVERSITY_SUBJECT_CONFIG,
} from "@/SubjectConfig";
import styles from "@/pages/Teacher/CreateCourse/index.module.scss";

import { memo, useCallback, useState } from "react";
import { SubjectResponse } from "@/store/autogenApi";
import { SubjectsComponent } from "@/components/SubjectsComponent";
import { Mandatory } from "@/components/Tag/Mandatory";
import { useMediaQuery } from "@material-ui/core";

interface Props {
    open?: boolean;
    isChecked: boolean;
    subjects: SubjectResponse[];
    courseType: string;
    setSubjects: React.Dispatch<React.SetStateAction<SubjectResponse[]>>;
}

export interface Open {
    level: string;
    subject: string;
}

export const Subjects: React.VFC<Props> = memo(function Subjects(props) {
    const smUp = useMediaQuery("(min-width:600px)");

    const [open, setOpen] = useState<Open>({
        level: "",
        subject: "",
    });

    const handlePopoverOpen = useCallback((level: string, subject: string) => {
        setOpen({ level: level, subject: subject });
    }, []);

    const handlePopoverClose = useCallback(() => {
        setOpen({ level: "", subject: "" });
    }, []);

    const getConfig = useCallback((level: string) => {
        if (level === "university") {
            return UNIVERSITY_SUBJECT_CONFIG;
        } else if (level === "highSchool") {
            return HIGH_SCHOOL_SUBJECT_CONFIG;
        } else if (level === "juniorHighSchool") {
            return JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG;
        } else if (level === "elementarySchool") {
            return ELEMENTARY_SCHOOL_SUBJECT_CONFIG;
        } else {
            return "";
        }
    }, []);

    const getSchool = useCallback((school: string) => {
        if (school === "university") {
            return "大学生・社会人等";
        } else if (school === "highSchool") {
            return "高校生";
        } else if (school === "juniorHighSchool") {
            return "中学生";
        } else {
            return "小学生";
        }
    }, []);

    return (
        <div className={styles.searchBySubject}>
            <li className={styles.listItem}>
                <div className={styles.leftWrapper}>
                    <div className={styles.titleWrapper}>
                        <div className={styles.title}>科目（複数選択可）</div>
                        {!props.open && <Mandatory />}
                    </div>
                </div>
                <div className={`${styles.rightWrapper} ${styles.subjectsRightWrapper}`}>
                    {props.open ? (
                        <div className={styles.openTrue}>
                            <ul className={styles.openSubjectList}>
                                {props.subjects.map((subject, idx) => {
                                    const config = subject.level ? getConfig(subject.level) : "";
                                    if (config) {
                                        const subjectInfo = subject.category ? config[subject.category] : "";
                                        if (subjectInfo) {
                                            const subCategoryKey = subject.subCategory;
                                            if (subjectInfo.sub && subCategoryKey) {
                                                return (
                                                    <li key={idx} className={styles.openListItem}>
                                                        {subject.level && getSchool(subject.level)} -{subjectInfo.label}{" "}
                                                        -{subjectInfo.sub[subCategoryKey].label}
                                                    </li>
                                                );
                                            } else {
                                                return (
                                                    <li key={idx} className={styles.openListItem}>
                                                        {subject.level && getSchool(subject.level)} -{" "}
                                                        {subjectInfo.label}
                                                    </li>
                                                );
                                            }
                                        }
                                    }
                                })}
                            </ul>
                        </div>
                    ) : (
                        <>
                            <SubjectsComponent
                                checked={props.isChecked}
                                subjects={props.subjects}
                                setSubjects={props.setSubjects}
                            />
                            {props.isChecked && props.subjects.length === 0 && (
                                <div
                                    className={styles.helperText}
                                    style={{ margin: smUp ? "0 20px 20px" : "0 10px 0" }}
                                >
                                    この項目は必須です。少なくとも1つチェックをつけてください。
                                </div>
                            )}
                        </>
                    )}
                </div>
            </li>
        </div>
    );
});
