import { FAQDetailsBase } from "../../FAQDetailsBase";

import { REFUND_CONFIGS } from "./Refund";

export const Refund: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="受講停止時の返金について"
            text="講座の種類によって返金の対象となる期間が異なります。"
            list={["単発・短期講座", "定期講座"]}
            showIdx
            FAQConfigs={REFUND_CONFIGS}
        />
    );
};
