import { PrivateRequestResponse } from "@/store/autogenApi";
import { ListItem } from "./ListItem";

import styles from "./index.module.scss";
import { getDaysAndTime } from "@/utils/BaseScheduleUtils";

interface Props {
    privateRequest: PrivateRequestResponse | undefined;
    isConfirmed?: boolean;
}

export const WeeklySchedule: React.VFC<Props> = (props) => {
    return (
        <ListItem title={props.isConfirmed ? "スケジュール" : "スケジュール"} borderBottom>
            <ul className={styles.baseSchedules}>
                {props.privateRequest?.baseSchedules?.map((baseSchedule) => (
                    <li className={styles.baseSchedule} key={baseSchedule.baseScheduleId}>
                        {`${getDaysAndTime(baseSchedule)}`}
                    </li>
                ))}
            </ul>
        </ListItem>
    );
};
