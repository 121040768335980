import { FAQDetailsBase } from "../../FAQDetailsBase";

import { LOGIN_FAILED_CONFIGS } from "./LoginFailed";

export const LoginFailed: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="ログインができない"
            text="Trailにログインができない場合は、以下の原因が考えられます。"
            FAQConfigs={LOGIN_FAILED_CONFIGS}
            list={[
                "「ログイン」ではなく「新規登録」しようとしている",
                "メールアドレスが正しくない",
                "パスワードが正しくない",
                "ログイン方法を間違っている",
            ]}
        />
    );
};
