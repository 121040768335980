import { Button, MenuItem, Select } from "@material-ui/core";
import { useHistory } from "react-router";

import styles from "@/pages/Teacher/MyPage/index.module.scss";
import { PrivateUserResponse } from "@/store/autogenApi";

interface Props {
    bankAccountList: any[];
    checkedBankAccount: any;
    handleChange: (
        event: React.ChangeEvent<{
            value: string;
        }>,
    ) => void;
}

export const BankAccounts: React.VFC<Props> = (props) => {
    const history = useHistory();
    const handleAddButtonClick = () => {
        history.push("/teacher/MyPage/BankAccountSetting");
    };
    return (
        <div className={styles.bankAccountsWrapper}>
            <div className={styles.bankAccountsTitle}>振込先の銀行口座</div>
            {props.bankAccountList.length === 0 ? (
                <div className={styles.noBankAccountsWrapper}>
                    <div className={styles.noBankAccounts}>
                        登録済みの銀行口座はありません。銀行口座を登録してください。
                    </div>
                    <Button className={styles.addButton} onClick={handleAddButtonClick}>
                        銀行口座を追加
                    </Button>
                </div>
            ) : (
                <div className={styles.targetBankAccountWrapper}>
                    {props.checkedBankAccount && (
                        <Select
                            variant="outlined"
                            onChange={props.handleChange}
                            defaultValue={props.checkedBankAccount.id}
                            className={styles.select}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "right",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                        >
                            {props.bankAccountList.map((bankAccount) => {
                                return (
                                    <MenuItem
                                        key={bankAccount.id}
                                        value={bankAccount.id}
                                        className={styles.bankAccountMenuItem}
                                    >
                                        <div className={styles.bankAccountTopWrapper}>
                                            <div className={styles.bankFullName}>
                                                {bankAccount.bankFullName ?? "〇〇銀行"}
                                            </div>
                                            <div className={styles.branchName}>
                                                {bankAccount.branchName ?? "〇〇支店"}
                                            </div>
                                        </div>
                                        <div className={styles.accountNumber}>****{bankAccount.last4.slice(-3)}</div>
                                        <div className={styles.accountHolderName}>
                                            {bankAccount.account_holder_name}
                                        </div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    )}
                </div>
            )}
        </div>
    );
};
