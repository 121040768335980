import { memo } from "react";

import { Button } from "@material-ui/core";

import styles from "@/pageComponents/Common/InfoInput/userInfoInput.module.scss";

import { InfoInputModal } from "@/pageComponents/Common/InfoInput/ProfileInput/InfoInputModal";
import { BirthDate } from "@/pageComponents/Common/InfoInput/ProfileInput/Items/BirthDate";
import { Gender } from "@/pageComponents/Common/InfoInput/ProfileInput/Items/Gender";
import { Grade } from "@/pageComponents/Common/InfoInput/ProfileInput/Items/Grade";
import { Prefecture } from "@/pageComponents/Common/InfoInput/ProfileInput/Items/Prefecture";
import { RealName } from "@/pageComponents/Common/InfoInput/ProfileInput/Items/RealName";
import { useLocation } from "react-router";

interface Props {
    checked: boolean;
    open: boolean;
    firstName: string;
    lastName: string;
    firstNameKana: string;
    lastNameKana: string;
    birthDate: Date | undefined;
    isBirthDatePublic: boolean;
    grade: string | undefined;
    isGradePublic: boolean;
    gender: string | undefined;
    isGenderPublic: boolean;
    prefecture: string | undefined;
    isPrefecturePublic: boolean;
    handleIsBirthDatePublicChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleIsGradePublicChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleIsGenderPublicChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleIsPrefecturePublicChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleFirstNameChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleLastNameChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleFirstNameKanaChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleLastNameKanaChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleBirthDateChange: (birthDate: Date) => void;
    handleGradeChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleGenderChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handlePrefectureChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;

    handleOpen: () => void;
    handleClose: () => void;
    handleNextStepButtonClick: () => void;
    handleIsComposingChange: (value: boolean) => void;
}

export const FormContents: React.VFC<Props> = memo(function FormContents(props) {
    const location = useLocation();
    const currentPath = location.pathname;
    const isStudent = currentPath === "/InfoInput";

    return (
        <div
            className={styles.infoInputWrapper}
            // style={{ display: props.activeStep === 1 ? "block" : "none" }
        >
            <>
                <div className={styles.infoInputTitle}>ユーザー情報（共通）入力</div>
                {isStudent ? (
                    <div className={styles.importantNotice}>実際に受講する人の情報を入力してください。</div>
                ) : (
                    <div className={styles.publish}>
                        先生アカウントでご利用の場合は、公開設定に関わらず本名以外の以下の項目が公開されます。
                    </div>
                )}
                <div className={styles.inputList}>
                    <RealName
                        checked={props.checked}
                        firstName={props.firstName}
                        lastName={props.lastName}
                        firstNameKana={props.firstNameKana}
                        lastNameKana={props.lastNameKana}
                        handleFirstNameChange={props.handleFirstNameChange}
                        handleLastNameChange={props.handleLastNameChange}
                        handleFirstNameKanaChange={props.handleFirstNameKanaChange}
                        handleLastNameKanaChange={props.handleLastNameKanaChange}
                        handleIsComposingChange={props.handleIsComposingChange}
                    />
                    <BirthDate
                        birthDate={props.birthDate}
                        handleBirthDateChange={props.handleBirthDateChange}
                        checked={props.checked}
                        isPublic={props.isBirthDatePublic}
                        handleIsPublicChange={props.handleIsBirthDatePublicChange}
                    />
                    <Grade
                        value={props.grade}
                        handleChange={props.handleGradeChange}
                        checked={props.checked}
                        isPublic={props.isGradePublic}
                        handleIsPublicChange={props.handleIsGradePublicChange}
                    />
                    <Gender
                        value={props.gender}
                        handleChange={props.handleGenderChange}
                        checked={props.checked}
                        isPublic={props.isGenderPublic}
                        handleIsPublicChange={props.handleIsGenderPublicChange}
                    />
                    <Prefecture
                        value={props.prefecture}
                        handleChange={props.handlePrefectureChange}
                        checked={props.checked}
                        isPublic={props.isPrefecturePublic}
                        handleIsPublicChange={props.handleIsPrefecturePublicChange}
                    />
                </div>
                <Button className={styles.checkButton} variant="contained" onClick={props.handleOpen}>
                    確認
                </Button>
                <InfoInputModal
                    checked={props.checked}
                    open={props.open}
                    firstName={props.firstName}
                    lastName={props.lastName}
                    firstNameKana={props.firstNameKana}
                    lastNameKana={props.lastNameKana}
                    birthDate={props.birthDate}
                    isBirthDatePublic={props.isBirthDatePublic}
                    grade={props.grade}
                    isGradePublic={props.isGradePublic}
                    gender={props.gender}
                    isGenderPublic={props.isGenderPublic}
                    prefecture={props.prefecture}
                    isPrefecturePublic={props.isPrefecturePublic}
                    handleClose={props.handleClose}
                    handleNextStepButtonClick={props.handleNextStepButtonClick}
                />
            </>
        </div>
    );
});
