import { useState, useEffect } from "react";

import { CourseResponse } from "@/store/autogenApi";
import { useGetLatestRegularCoursesQuery } from "@/store/hooks/courses";
import { CourseCardsBase } from "@/components/CourseCardsBase";

interface Props {
    repetitionNumber?: number;
}

export const LatestRegularCourses: React.VFC<Props> = (props) => {
    const [courses, setCourses] = useState<CourseResponse[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const latestRegularCoursesQueryState = useGetLatestRegularCoursesQuery(15);
    useEffect(() => {
        (async () => {
            if (!latestRegularCoursesQueryState.isFetching && latestRegularCoursesQueryState.isSuccess) {
                setCourses(latestRegularCoursesQueryState.data);
                setIsLoaded(true);
            }
        })();
    }, [latestRegularCoursesQueryState]);

    return (
        <CourseCardsBase
            title="新着定期講座"
            courses={courses}
            destination="/SearchResults?searchType=course&keyword=&courseType=regular&level=&category=&subCategory=&rankNumber=&university=&faculty=&department=&orderBy=latest"
            isLoaded={isLoaded}
            repetitionNumber={props.repetitionNumber}
            hasTwoLines
        />
    );
};
