import { BaseScheduleResponse } from "@/store/autogenApi";
import { ListItem } from "./ListItem";

import styles from "./index.module.scss";
import { getBaseScheduleInfo, getDaysAndTime } from "@/utils/BaseScheduleUtils";

interface Props {
    baseSchedules: BaseScheduleResponse[];
    isConfirmed?: boolean;
}

export const BaseSchedules: React.VFC<Props> = (props) => {
    return (
        <ListItem title="基本スケジュール" borderBottom>
            <div className={styles.baseSchedulesWrapper}>
                <ul className={styles.baseSchedules}>
                    {props.baseSchedules?.map((baseSchedule) => (
                        <li className={styles.baseSchedule} key={baseSchedule.baseScheduleId}>
                            {`${getBaseScheduleInfo(baseSchedule)}`}
                        </li>
                    ))}
                </ul>
                <div className={styles.baseScheduleDescription}>
                    ※
                    基本スケジュールは先生の授業日程作成を補助するものであり、月に1回基本スケジュールに従って授業日程が自動生成されます。実際の授業日程と差分がある場合は手動で日程の追加・削除が可能です。
                </div>
            </div>
        </ListItem>
    );
};
