import { memo, useCallback, useEffect, useRef, useState } from "react";

import styles from "./index.module.scss";

import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { TemporaryMessage } from "./items/TemporaryMessage";
import { MyMessage } from "./items/MyMessage";
import { ChatResponse, MessageResponse, PublicStudentResponse, PublicTeacherResponse } from "@/store/autogenApi";
import { OtherMessage } from "./items/OtherMessage";
import { getAMOrPM } from "@/utils/GetAMOrPM";
import { getOnlyTime } from "@/utils/GetOnlyTime";
import { WHAT_DAY_LIST } from "@/utils/WhatDayList";

interface Props {
    chat: ChatResponse;
    temporaryObjectURLsList: string[][];
    handleIconButtonClick: (target: PublicStudentResponse | PublicTeacherResponse) => void;
    handlePreviousMessagesChange?: (messages: MessageResponse[]) => void;
}

export const MessageList: React.VFC<Props> = memo(function MessagesList(props) {
    const [messagesByDate, setMessagesByDate] = useState<MessageResponse[][]>([]);
    const studentId = useSelector((state: RootState) => state.jwt.studentId) as string;
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId) as string;
    const userMode = useSelector((state: RootState) => state.jwt.userMode as string);

    const messagesByDateRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
        const newMessagesByDate: MessageResponse[][] = [];
        props.chat.messages.forEach((message) => {
            const date = new Date(message.createdAt).toLocaleDateString();
            const index = newMessagesByDate.findIndex((messages) => {
                return new Date(messages[0].createdAt).toLocaleDateString() === date;
            });
            if (index === -1) {
                newMessagesByDate.push([message]);
            } else {
                newMessagesByDate[index].push(message);
            }
        });
        setMessagesByDate(newMessagesByDate);
    }, [props.chat]);

    const getDate = useCallback((messages: MessageResponse[]) => {
        const targetMessage = messages[0];
        const date = new Date(targetMessage.createdAt);
        const now = new Date();
        const withinAYear = date.getFullYear() === now.getFullYear();
        if (!withinAYear) {
            return `${date.getFullYear()}.${date.getMonth()}.${date.getDate()}`;
        }
        if (date.getDate() === now.getDate()) {
            return "今日";
        }
        if (date.getDate() === now.getDate() - 1) {
            return "昨日";
        }
        return `${date.getMonth()}.${date.getDate()}(${WHAT_DAY_LIST[date.getDay()]})`;
    }, []);

    const getReadInfo = useCallback(
        (message: MessageResponse) => {
            const isOwnMessage =
                userMode === "student"
                    ? studentId === message.student?.studentId
                    : teacherId === message.teacher?.teacherId;
            const howManyRead = props.chat.readStatuses.filter((readStatus) => {
                const readStatusDate = new Date(readStatus.updatedAt ?? readStatus.createdAt);
                const messageDate = new Date(message.createdAt);
                const isOwnReadStatus =
                    userMode === "student" ? studentId === readStatus.studentId : teacherId === readStatus.teacherId;
                return messageDate.getTime() <= readStatusDate.getTime() && !isOwnReadStatus;
            }).length;
            const howManyOtherUsers = props.chat.teachers.length + props.chat.students.length - 1;
            if (howManyRead === 0 || (!isOwnMessage && howManyOtherUsers === 1)) return "";
            if (howManyRead === 1 && isOwnMessage && howManyOtherUsers === 1) return "既読";
            return `既読 ${howManyRead}`;
        },
        [props.chat, studentId, teacherId, userMode],
    );

    return (
        <div
            className={styles.messagesByDate}
            ref={(messagesEl) => {
                messagesByDateRef.current = messagesEl;
                if (!messagesEl) return;
                if (props.temporaryObjectURLsList.length === 0) {
                    messagesEl.scrollTo(0, messagesEl.scrollHeight);
                }
            }}
        >
            {messagesByDate.map((messages) => {
                return (
                    <div className={styles.messageListWrapper}>
                        <div className={styles.dateWrapper}>{getDate(messages)}</div>
                        <ul className={styles.messageList}>
                            {messages?.map((message) => {
                                if (
                                    (userMode === "student" && studentId === message.student?.studentId) ||
                                    (userMode === "teacher" && teacherId === message.teacher?.teacherId)
                                ) {
                                    return (
                                        <MyMessage
                                            message={message}
                                            AMOrPM={getAMOrPM(message)}
                                            readInfo={getReadInfo(message)}
                                            createdAt={getOnlyTime(new Date(message.createdAt))}
                                        />
                                    );
                                } else {
                                    return (
                                        <OtherMessage
                                            message={message}
                                            AMOrPM={getAMOrPM(message)}
                                            createdAt={getOnlyTime(new Date(message.createdAt))}
                                            handleIconButtonClick={props.handleIconButtonClick}
                                        />
                                    );
                                }
                            })}
                            {props.temporaryObjectURLsList.map((temporaryObjectURLs, idx) => (
                                <TemporaryMessage
                                    temporaryObjectURLs={temporaryObjectURLs}
                                    messagesEl={messagesByDateRef.current}
                                    idx={idx}
                                />
                            ))}
                        </ul>
                    </div>
                );
            })}
        </div>
    );
});
