import { memo, useCallback, useRef } from "react";
import { useState, useEffect } from "react";

import { useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { LoadingProgress } from "@/components/LoadingProgress";
import { Navigation, NavigationComponent } from "@/components/Navigation";
import { CourseType } from "./CourseType";
import { Deadline } from "./Deadline";
import { FixedContents } from "./FixedContents/index";
import { Price } from "./Price";
import { ProposalList } from "./ProposalList";
import { Remarks } from "./Remarks";
import { RequestDescription } from "./RequestDescription";
import { ScheduleDescription } from "./ScheduleDescription";
import { StudentInfoComponent } from "./StudentInfo";
import { SubjectComponent } from "./Subject";
import styles from "@/pages/Common/PublicRequestDetails/index.module.scss";
import {
    PrivateUserResponse,
    PublicRequestResponse,
    UpdatePublicRequestBrowsingHistoryRequestBody,
} from "@/store/autogenApi";
import { useUpdateRequestBrowsingHistoryMutation } from "@/store/hooks/teachers";
import { RootState } from "@/ducks";
import { RequestsWithTheSameSubjects } from "./RequestsWithTheSameSubjects";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { getLevelAndCategory } from "@/utils/SubjectUtils";

interface Props {
    publicRequest: PublicRequestResponse;
    privateUser: PrivateUserResponse;
}

export interface LeftTime {
    date: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export const RequestDetailsContents: React.VFC<Props> = memo(function RequestDetailsContents(props) {
    const [studentInfoModalOpen, setStudentInfoModalOpen] = useState<boolean>(false);

    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const userMode = useSelector((state: RootState) => state.jwt.userMode);

    const smUp = useMediaQuery("(min-width:600px)", { noSsr: true });
    const mdUp = useMediaQuery("(min-width:960px)", { noSsr: true });
    const lgUp = useMediaQuery("(min-width:1280px)", { noSsr: true });
    const exLgUp = useMediaQuery("(min-width:1360px)", { noSsr: true });

    const fixedContentsEl: React.MutableRefObject<HTMLElement | null> = useRef(null);
    const requestDetailsContentsWrapperEl: React.MutableRefObject<HTMLElement | null> = useRef(null);

    const updateRequestBrowsingHistory = useUpdateRequestBrowsingHistoryMutation();

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        const firstSubject = props.publicRequest.subjects[0];
        if (!firstSubject || !props.publicRequest.title) {
            updateNavigationList([
                {
                    title: "講座リクエスト検索",
                    url: `/SearchResults?searchType=publicRequest`,
                },
            ]);
            return;
        }
        updateNavigationList([
            {
                title: "講座リクエスト検索",
                url: `/SearchResults?searchType=publicRequest`,
            },
            {
                title: getLevelAndCategory(firstSubject),
                url: `/SearchResults?searchType=publicRequest&level=${firstSubject.level}&category=${firstSubject.category}`,
            },
            {
                title: props.publicRequest.title,
                url: `/PublicRequestDetails/${props.publicRequest.publicRequestId}`,
            },
        ]);
    }, [props.publicRequest]);

    // useEffect(() => {
    //     const fixedContents = fixedContentsEl.current;
    //     const requestDetailsContentsWrapper = fixedContentsEl.current;
    //     if (fixedContents && requestDetailsContentsWrapper) {
    //         const scrollHeight = fixedContents.scrollHeight;
    //         fixedContents.scrollTop = scrollHeight;
    //     }
    // }, [fixedContentsEl.current, requestDetailsContentsWrapperEl.current]);

    const fixedContentsFade = useCallback(() => {
        const scrollingElement = document.scrollingElement;
        const header = document.getElementById("header");
        const footer = document.getElementById("footer");
        const contents = document.getElementById("contents");
        const requestDetailsContentsWrapper = requestDetailsContentsWrapperEl.current;
        if (
            scrollingElement &&
            fixedContentsEl.current &&
            header &&
            footer &&
            contents &&
            requestDetailsContentsWrapper
        ) {
            // const footerHeight = footer.scrollHeight;
            let threshold: number;
            if (smUp) {
                const fixedContentsHeight = fixedContentsEl.current.clientHeight;
                threshold = contents.clientHeight - fixedContentsHeight;
            } else {
                const styles = window.getComputedStyle(requestDetailsContentsWrapper);
                const marginTop = Number(styles.marginTop.replace("px", ""));
                const marginBottom = Number(styles.marginBottom.replace("px", ""));
                const totalHeight = requestDetailsContentsWrapper.clientHeight + marginTop + marginBottom;
                const navigationWrapperHeight = 28;
                threshold =
                    totalHeight +
                    header.offsetHeight +
                    navigationWrapperHeight +
                    fixedContentsEl.current.clientHeight -
                    window.innerHeight;
            }
        }
    }, [fixedContentsEl.current, requestDetailsContentsWrapperEl.current]);

    useEffect(() => {
        fixedContentsFade();
        window.addEventListener("scroll", () => {
            fixedContentsFade();
        });
        return () => {
            window.removeEventListener("scroll", () => {
                fixedContentsFade();
            });
        };
    }, [fixedContentsFade]);

    useEffect(() => {
        (async () => {
            setIsLoaded(true);
            (async () => {
                if (userMode === "teacher") {
                    const requestBody: UpdatePublicRequestBrowsingHistoryRequestBody = {
                        teacherId: props.privateUser.teacherId as string,
                        publicRequestId: props.publicRequest.publicRequestId,
                    };
                    await updateRequestBrowsingHistory({
                        updatePublicRequestBrowsingHistoryRequestBody: requestBody,
                    });
                }
            })();
        })();
    }, []);

    const history = useHistory();
    const handleEditButtonClick = useCallback((params: string) => {
        history.push(`/CreatePublicRequest/${params}`);
    }, []);

    return (
        <div
            className={styles.requestDetailsContentsWrapper}
            ref={(node: HTMLElement | null) => {
                requestDetailsContentsWrapperEl.current = node;
            }}
        >
            <div className={styles.requestDetailsContents}>
                <div className={styles.mainWrapper} id="contents">
                    <LoadingProgress loadingComplete={isLoaded}>
                        <>
                            <CourseType courseType={props.publicRequest.courseType} />
                            <div className={styles.title}>{props.publicRequest?.title}</div>
                            {userMode === "student" &&
                                props.publicRequest.student?.studentId === props.privateUser?.studentId && (
                                    <ProposalList publicRequest={props.publicRequest} />
                                )}
                            <StudentInfoComponent
                                student={props.publicRequest.student}
                                studentInfoModalOpen={studentInfoModalOpen}
                                setStudentInfoModalOpen={setStudentInfoModalOpen}
                            />
                            {props.publicRequest.subjects && (
                                <SubjectComponent subjects={props.publicRequest.subjects} />
                            )}
                            <RequestDescription description={props.publicRequest?.publicRequestDescription} />
                            <Price
                                maxPrice={props.publicRequest?.maxPrice}
                                minPrice={props.publicRequest?.minPrice}
                                courseType={props.publicRequest.courseType}
                            />
                            <ScheduleDescription scheduleDescription={props.publicRequest.scheduleDescription} />
                            <Deadline deadline={new Date(props.publicRequest.applyingDeadline)} />
                            <Remarks remarks={props.publicRequest.additionalDescription} />
                        </>
                    </LoadingProgress>
                </div>
                {isLoaded && props.publicRequest.proposals && props.publicRequest.student?.studentId && (
                    <FixedContents
                        user={props.privateUser}
                        publicRequestId={props.publicRequest.publicRequestId}
                        proposals={props.publicRequest.proposals}
                        userMode={userMode}
                        fixedContentsEl={fixedContentsEl}
                        student={props.publicRequest.student}
                        courseType={props.publicRequest.courseType}
                        title={props.publicRequest.title}
                        applyingDeadline={new Date(props.publicRequest.applyingDeadline)}
                        handleEditButtonClick={handleEditButtonClick}
                    />
                )}
            </div>
            <div className={styles.requests}>
                <RequestsWithTheSameSubjects subjects={props.publicRequest.subjects} />
            </div>
        </div>
    );
});
