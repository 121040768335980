import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { memo, useCallback, useState } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import styles from "./index.module.scss";

interface Props {
    label: string;
    value: string;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TextInput: React.VFC<Props> = memo(function TextInput(props) {
    return (
        <div className={styles.textInputWrapper}>
            <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">{props.label}</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    type="text"
                    label={props.label}
                    onChange={props.handleChange}
                    value={props.value}
                />
            </FormControl>
        </div>
    );
});
