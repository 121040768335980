import { Button } from "@material-ui/core";
import { memo, useCallback, useState } from "react";
import { PublicStudentResponse } from "@/store/autogenApi";

import { StudentInfoModal } from "../StudentInfoModal";

import styles from "./index.module.scss";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    student: PublicStudentResponse;
}

export const StudentDetailsButton: React.VFC<Props> = memo(function StudentDetailsButton(props) {
    const [open, setOpen] = useState(false);
    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);
    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);
    return (
        <>
            <Button className={styles.studentDetailsButton} onClick={handleOpen}>
                <AvatarFromS3
                    url={props.student.iconImageUrl}
                    objectKey={props.student.iconImageObjectKey}
                    className={styles.avatar}
                />
                <div className={styles.studentNickname}>{props.student.nickname}</div>
            </Button>
            <StudentInfoModal student={props.student} open={open} handleStudentInfoModalClose={handleClose} />
        </>
    );
});
