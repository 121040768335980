import styles from "@/pages/Common/LP/index.module.scss";
import { OverWrapper } from "@/components/OverWrapper";
import backgroundImage from "@/pageComponents/Common/AboutTeacher/img/teacher.jpg";
import trailImage from "@/images/logo-v2-with-trail.png";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

export const AboutTeacherTopContents: React.VFC = () => {
    return (
        <OverWrapper
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
        >
            <div className={styles.aboutTopContents}>
                <div className={styles.catchCopyWrapper}>
                    <div className={styles.catchCopy}>
                        <span className={styles.strong}>自由に勉強を教えて</span>
                        <br />
                        <span className={styles.strong}>稼ぎたい</span>なら
                    </div>
                    <img src={trailImage} className={styles.logo} />
                    <div className={styles.catchCopySub}>
                        講座内容・時給までも
                        <br />
                        <span className={styles.strong}>あなたが決められる</span>
                    </div>
                </div>
                <OverWrapper
                    style={{
                        background: "linear-gradient(0deg, #000 0%, transparent 100%)",
                        height: "fit-content",
                    }}
                >
                    <div className={styles.bottomWrapper}>
                        <div className={styles.squares}>
                            <div className={styles.square}>
                                <span className={styles.strong}>東海限定</span>
                                <br />
                                だからこそできる
                                <br />
                                納得のマッチング
                            </div>
                            <div className={styles.square}>
                                <span className={styles.strong}>完全オンライン</span>
                                <br />
                                だから移動不要
                                <br />
                                自宅にいながら稼げる
                            </div>
                        </div>
                        <div className={styles.signUpWrapper}>
                            <div className={styles.minutes}>＼30秒で完了！／</div>
                            <Button className={styles.signUpButton} component={Link} to="/SignUp?type=teacher">
                                新規ユーザー登録へ（無料）
                            </Button>
                        </div>
                    </div>
                </OverWrapper>
            </div>
        </OverWrapper>
    );
};
