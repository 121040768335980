import { FAQDetailsBase } from "../../FAQDetailsBase";

import { RESERVE_COURSE_CONFIGS } from "./ReserveCourseConfigs";

export const ReserveCourse: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="講座を受講予約（事前決済）する"
            text="このページでは、講座を受講予約（事前決済）する手順についてご説明します。対象の講座の種類によって、受講予約の手順が異なります。"
            FAQConfigs={RESERVE_COURSE_CONFIGS}
            list={["単発・短期講座の受講予約", "定期講座の受講予約"]}
        />
    );
};
