import { Button } from "@material-ui/core";
import { useHistory } from "react-router";

import styles from "@/pages/Common/FAQDetails/index.module.scss";

export const FAQBackButton: React.VFC = () => {
    const history = useHistory();
    const handleBackButtonClick = () => {
        if (history.length === 1) {
            history.push("/FAQ");
        } else {
            history.goBack();
        }
    };
    return (
        <Button className={styles.backButton} onClick={handleBackButtonClick}>
            戻る
        </Button>
    );
};
