import { Button, Modal, TextareaAutosize } from "@material-ui/core";

import styles from "./index.module.scss";
import { memo, useCallback, useEffect, useState } from "react";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Processing } from "@/components/Processing";
import { useUpdateMemoMutation } from "@/store/hooks/classes";
import { ClassResponse } from "@/store/autogenApi";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { ToastContents } from "@/components/Toast";

interface Props {
    targetClass: ClassResponse;
    modalOpen: boolean;
    handleModalClose: () => void;
}

export const UpdateMemoModal: React.VFC<Props> = memo(function UpdateMemoModal(props) {
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [memoString, setMemoString] = useState<string>("");
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isConfirmMode, setIsConfirmMode] = useState<boolean>(false);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);

    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);

    const updateMemo = useUpdateMemoMutation();

    useEffect(() => {
        if (!props.targetClass.memo) return;
        setMemoString(props.targetClass.memo);
    }, [props.targetClass]);

    const handleMemoStringChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMemoString(e.target.value);
    }, []);

    const handleCheckButtonClick = useCallback(() => {
        setIsChecked(true);
        if (memoString.length > 500) return;
        setIsConfirmMode(true);
    }, [memoString]);

    const handleConfirmButtonClick = useCallback(async () => {
        setIsProcessing(true);
        const { isSuccess } = await updateMemo({
            updateMemoRequestBody: {
                classId: props.targetClass.classId,
                memo: memoString,
            },
        });
        setIsProcessing(false);
        if (isSuccess) {
            setIsCompleted(true);
            toast(<ToastContents title="メモ更新完了" isCompleted />);
        } else {
            toast(<ToastContents title="メモ更新失敗" isFailed />);
        }
    }, [studentId, memoString, updateMemo]);

    const history = useHistory();

    const handleModalClose = useCallback(() => {
        if (isProcessing) return;
        if (isCompleted) {
            history.push(`/MyPage/ReservedCourses?courseType=${props.targetClass.course?.courseType}`);
        }
        setIsChecked(false);
        setMemoString("");
        setIsProcessing(false);
        setIsConfirmMode(false);
        setIsCompleted(false);
        props.handleModalClose();
    }, [isProcessing, isCompleted, props.targetClass, props.handleModalClose]);

    const handleBackButtonClick = useCallback(() => {
        setIsConfirmMode(false);
    }, []);

    return (
        <Modal open={props.modalOpen} onClose={handleModalClose}>
            <div className={styles.updateMemoModalContents}>
                {isConfirmMode ? (
                    <div className={styles.confirmModeContents}>
                        <div className={styles.updateMemoNotice}>下記の内容でメモを更新します。よろしいですか？</div>
                        <div className={styles.memo}>{memoString}</div>
                        <div className={styles.buttonsWrapper}>
                            <Button className={styles.backButton} onClick={handleBackButtonClick}>
                                戻る
                            </Button>
                            <Button className={styles.confirmButton} onClick={handleConfirmButtonClick}>
                                {isProcessing ? <Processing /> : "確定"}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className={styles.inputModeContents}>
                        <div className={styles.updateMemoTitle}>メモ更新</div>
                        <div className={styles.memoDescription}>
                            このメモの内容は生徒さんにも表示されます。次回授業の情報や課題など、メモを自由に使って授業を円滑に進めてください。
                        </div>
                        <div className={styles.memoWrapper}>
                            <TextareaAutosize
                                className={styles.memoTextarea}
                                value={memoString}
                                onChange={handleMemoStringChange}
                                placeholder="(500字以下)&#13;&#10;例1）&#13;&#10;次回授業は「〇〇」という単元を扱います。...&#13;&#10;例2）&#13;&#10;⚪︎月⚪︎日の授業の復習として、次の課題を進めてください。...&#13;&#10;"
                                minRows={8}
                                maxRows={8}
                            />
                            <div className={styles.count}>{memoString.length}/500文字</div>
                            <ErrorMessage
                                content="500字以下で入力してください。"
                                when={isChecked && memoString.length > 500}
                            />
                        </div>
                        <div className={styles.buttonsWrapper}>
                            <Button className={styles.closeButton} onClick={handleModalClose}>
                                閉じる
                            </Button>
                            <Button className={styles.checkButton} onClick={handleCheckButtonClick}>
                                確認
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
});
