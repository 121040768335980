import { memo, useCallback, useEffect, useState } from "react";

import { Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "@/pages/Teacher/MyPage/index.module.scss";
import { Mandatory } from "@/components/Tag/Mandatory";

interface Props {
    handleBirthDateChange?: (birthDate: Date) => void;
    edit?: boolean;
    birthDate?: Date;
    checked: boolean;
}

export const BirthDate: React.VFC<Props> = memo(function BirthDate(props) {
    const [year, setYear] = useState<number>(2000);
    const [month, setMonth] = useState<number>(1);
    const [date, setDate] = useState<number>(1);

    const YEAR_LIST = [...Array(123)].map((_, i) => i + 1900);
    const MONTH_LIST = [...Array(12)].map((_, i) => i + 1);
    const DATE_LIST = [...Array(31)].map((_, i) => i + 1);

    useEffect(() => {
        if (props.birthDate) {
            const modifiedBirthDate = new Date(props.birthDate);
            if (
                modifiedBirthDate.getFullYear() !== year ||
                modifiedBirthDate.getMonth() !== month - 1 ||
                modifiedBirthDate.getDate() !== date
            ) {
                setYear(modifiedBirthDate.getFullYear());
                setMonth(modifiedBirthDate.getMonth() + 1);
                setDate(modifiedBirthDate.getDate());
            }
        }
    }, [props.birthDate]);

    const handleYearChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
            const value = e.target.value as number;
            setYear(value);
            month &&
                date &&
                props.handleBirthDateChange &&
                props.handleBirthDateChange(new Date(value, month - 1, date));
        },
        [month, date, props.handleBirthDateChange],
    );
    const handleMonthChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
            const value = e.target.value as number;
            setMonth(value);
            year && date && props.handleBirthDateChange && props.handleBirthDateChange(new Date(year, value - 1, date));
        },
        [year, date, props.handleBirthDateChange],
    );
    const handleDateChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
            const value = e.target.value as number;
            setDate(value);
            year &&
                month &&
                props.handleBirthDateChange &&
                props.handleBirthDateChange(new Date(year, month - 1, value));
        },
        [year, month, props.handleBirthDateChange],
    );

    const getBirthDate = useCallback((data: Date) => {
        if (data) {
            const fixedData = new Date(data);
            const year = fixedData.getFullYear();
            const month = fixedData.getMonth() + 1;
            const date = fixedData.getDate();
            const result = `${year}年${month}月${date}日`;
            return result;
        } else {
            return "";
        }
    }, []);

    return (
        <>
            {props.edit ? (
                <li className={styles.editListItem}>
                    <div className={styles.leftWrapper}>
                        <div className={styles.titleWrapper}>
                            <div className={styles.title}>生年月日</div>
                            <Mandatory />
                        </div>
                    </div>
                    <div className={styles.rightWrapper}>
                        <div className={styles.notice}>※生年月日を基に年齢を表示します。生年月日は公開されません。</div>
                        <div className={styles.dateInfoWrapper}>
                            <div className={styles.dateItemWrapper}>
                                <Select
                                    variant="outlined"
                                    name="year"
                                    value={year}
                                    onChange={handleYearChange}
                                    className={styles.select}
                                >
                                    <MenuItem key="" value=""></MenuItem>
                                    {YEAR_LIST.map((year) => (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <div className={styles.dateItemTitle}>年</div>
                            </div>
                            <div className={styles.dateItemWrapper}>
                                <Select
                                    variant="outlined"
                                    name="month"
                                    value={month}
                                    onChange={handleMonthChange}
                                    className={styles.select}
                                >
                                    <MenuItem key="" value=""></MenuItem>
                                    {MONTH_LIST.map((month) => (
                                        <MenuItem key={month} value={month}>
                                            {month}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <div className={styles.dateItemTitle}>月</div>
                            </div>
                            <div className={styles.dateItemWrapper}>
                                <Select
                                    variant="outlined"
                                    name="date"
                                    value={date}
                                    onChange={handleDateChange}
                                    className={styles.select}
                                >
                                    <MenuItem key="" value=""></MenuItem>
                                    {DATE_LIST.map((date) => (
                                        <MenuItem key={date} value={date}>
                                            {date}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <div className={styles.dateItemTitle}>日</div>
                            </div>
                        </div>
                    </div>
                </li>
            ) : (
                <li className={styles.profileItem} style={{ borderBottom: props.edit ? "" : "#305077 1px solid" }}>
                    <div className={styles.profileTitle}>生年月日</div>
                    <div className={styles.profileSpace}></div>
                    <div className={styles.profileValue}>
                        {props.birthDate != undefined && getBirthDate(props.birthDate)}
                    </div>
                </li>
            )}
        </>
    );
});
