import { memo, useCallback, useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RouterPrompt } from "@/components/RouterPrompt";
import { RootState } from "@/ducks";
import { FormContents } from "@/pageComponents/Teacher/Identification/FormContents";
import { useCreateTeacherIdentificationMutation } from "@/store/hooks/teacherIdentifications";
import styles from "./index.module.scss";
import { useUploadFiles } from "@/utils/UploadFiles";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { Navigation } from "@/components/Navigation";

export interface FileInfo {
    file: File;
    temporaryUrl: string;
}

export interface Url {
    idCard: string;
    studentCard: string;
}

export interface RealName {
    firstName: string;
    lastName: string;
}

const NAVIGATION_LIST: Navigation[] = [{ title: "本人確認", url: "/Teacher/Identification" }];

export const TeacherIdentification: React.VFC = memo(function TeacherIdentification() {
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [idCardFileInfo, setIdCardFileInfo] = useState<FileInfo | undefined>(undefined);
    const [studentCardFileInfo, setStudentCardFileInfo] = useState<FileInfo | undefined>(undefined);
    const [realName, setRealName] = useState<RealName>({
        firstName: "",
        lastName: "",
    });
    const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
    const [isComplete, setIsComplete] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [modalHeight, setModalHeight] = useState<number>(0);

    const [isEdited, setIsEdited] = useState<boolean>(false);
    const userId = useSelector((state: RootState) => state.jwt.userId as string);
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);

    const createTeacherIdentification = useCreateTeacherIdentificationMutation();
    const uploadFiles = useUploadFiles();

    const changeModalHeight = useCallback(() => {
        const innerHeight = window.innerHeight;
        setModalHeight(innerHeight * 0.75);
    }, []);

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    useEffect(() => {
        changeModalHeight();
        window.addEventListener("resize", () => {
            changeModalHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalHeight();
            });
        };
    }, []);

    const handleIdCardChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setIsEdited(true);
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            const temporaryUrl = URL.createObjectURL(file);
            setIdCardFileInfo({ file, temporaryUrl });
        }
    }, []);

    const handleIdCardDeleteButtonClick = useCallback(() => {
        setIdCardFileInfo(undefined);
    }, []);

    const handleStudentCardChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setIsEdited(true);
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            const temporaryUrl = URL.createObjectURL(file);
            setStudentCardFileInfo({ file, temporaryUrl });
        }
    }, []);

    const handleStudentCardDeleteButtonClick = useCallback(() => {
        setStudentCardFileInfo(undefined);
    }, []);

    const handleConfirmButtonClick = useCallback(() => {
        (async () => {
            if (!idCardFileInfo) return;
            const idCardUrlsPromise = await uploadFiles([idCardFileInfo.file], `users/${userId}/teacher/idCard`);
            const studentCardUrlsPromise = studentCardFileInfo
                ? await uploadFiles([studentCardFileInfo.file], `users/${userId}/teacher/studentCard`)
                : undefined;
            const [idCardUrls, studentCardUrls] = await Promise.all([idCardUrlsPromise, studentCardUrlsPromise]);
            const { isSuccess } = await createTeacherIdentification({
                createTeacherIdentificationRequestBody: {
                    teacherId,
                    studentCardImageUrl: studentCardUrls ? studentCardUrls[0] : undefined,
                    idCardImageUrl: idCardUrls[0],
                },
            });
            setConfirmModalOpen(false);
            window.scrollTo(0, 0);
            if (isSuccess) {
                setIsComplete(true);
            } else {
                setIsError(true);
            }
        })();
    }, [teacherId, userId, idCardFileInfo, studentCardFileInfo]);

    const handleOpen = useCallback(() => {
        setIsChecked(true);
        if (!idCardFileInfo) return;
        setConfirmModalOpen(true);
    }, [idCardFileInfo]);

    const handleClose = () => {
        setConfirmModalOpen(false);
    };

    const handleRealNameChange = useCallback(
        (key: keyof RealName, value: string) => {
            setIsEdited(true);
            setRealName({ ...realName, [key]: value });
        },
        [realName],
    );

    const handleOKButtonClick = useCallback(() => {
        return true;
    }, []);

    const handleCancelButtonClick = useCallback(() => {
        return false;
    }, []);

    return (
        <div className={styles.identificationBackgroundWrapper}>
            <RouterPrompt
                when={!isComplete && isEdited && !isError}
                message="本人確認が未完了です。現在の入力内容は失われますが、このページを終了してもよろしいですか？"
                onOK={handleOKButtonClick}
                onCancel={handleCancelButtonClick}
            />
            <FormContents
                isChecked={isChecked}
                confirmModalOpen={confirmModalOpen}
                isComplete={isComplete}
                isError={isError}
                realName={realName}
                modalHeight={modalHeight}
                idCardFileInfo={idCardFileInfo}
                studentCardFileInfo={studentCardFileInfo}
                handleIdCardChange={handleIdCardChange}
                handleIdCardDeleteButtonClick={handleIdCardDeleteButtonClick}
                handleStudentCardChange={handleStudentCardChange}
                handleStudentCardDeleteButtonClick={handleStudentCardDeleteButtonClick}
                handleRealNameChange={handleRealNameChange}
                handleOpen={handleOpen}
                handleClose={handleClose}
                handleConfirmButtonClick={handleConfirmButtonClick}
            />
        </div>
    );
});
