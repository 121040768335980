import { Select, ThemeProvider, createTheme, makeStyles, useMediaQuery } from "@material-ui/core";
import { memo, useCallback, useEffect, useState } from "react";

import styles from "./index.module.scss";

interface Props {
    name: string;
    value: number | string | undefined;
    isBold?: boolean;
    smFontSize?: string;
    isHorizontalMenu?: boolean;
    isInlineBlock?: boolean;
    style?: React.CSSProperties;
    options: { value: string | number; label: string }[];
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

export const DefaultSelect: React.VFC<Props> = memo(function DefaultSelect(props) {
    return (
        <div style={{ display: props.isInlineBlock ? "inline-block" : "block", ...props.style }}>
            <select name={props.name} value={props.value} onChange={props.handleChange} className={styles.select}>
                {props.options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
});
