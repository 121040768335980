import { useState, useEffect } from "react";

import { CourseResponse, PublicTeacherResponse } from "@/store/autogenApi";
import { TeacherCards } from "@/components/TeacherCards";
import { useGetLatestTeachersQuery } from "@/store/hooks/teachers";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";

interface Props {
    repetitionNumber?: number;
}

export const LatestTeachers: React.VFC<Props> = (props) => {
    const teachersQueryState = useGetLatestTeachersQuery();

    return (
        <QueryLoadingWrapper {...teachersQueryState}>
            {(teachers) => (
                <TeacherCards
                    title="最近登録した先生"
                    teachers={teachers}
                    destination="/SearchResults?searchType=teacher"
                    isLoaded={true}
                    repetitionNumber={props.repetitionNumber}
                    hasTwoLines
                />
            )}
        </QueryLoadingWrapper>
    );
};
