import { useEffect } from "react";

import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Navigation } from "@/components/Navigation";

import styles from "./index.module.scss";
import { RootState } from "@/ducks";
import { useNavigation } from "@/components/Navigation/NavigationContext";

const NAVIGATION_LIST: Navigation[] = [{ title: "先生ガイドライン", url: "/TeacherGuidelines" }];

export const TeacherGuidelines: React.VFC = () => {
    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    const userMode = useSelector((state: RootState) => state.jwt.userMode);
    return (
        <>
            <div className={styles.teacherGuidelinesWrapper}>
                <h2 className={styles.title}>Trail先生ガイドライン</h2>
                <section className={styles.teacherGuidelines}>
                    <section className={styles.sentence}>
                        Trailで開催して頂ける講座は「1人または複数の参加者にオンライン形式にて学びの提供を目的として開催されるイベント」になります。
                        先生が個人で講座を開催する場合に該当するガイドラインを以下に定義します。
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>1.Trailにおける情報開示</h3>
                        <section className={styles.description}>
                            Trailでは安心して参加できる学びのコミュニティの発展と維持のため、先生に対して、先生ページ上における経歴などの必要情報の開示を義務づけるものとします。
                        </section>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>2.Trailにおける本人確認</h3>
                        <section className={styles.description}>
                            Trailでは、より安心して生徒が学びを受けられるように、先生に本人確認をお願いしております。本人確認の際には、下記2点をご提示いただくものとします。
                        </section>
                        <ol className={styles.items}>
                            <li className={styles.item}>1) 本名</li>
                            <li className={styles.item}>
                                2) 本人確認書類
                                <section className={styles.itemDescription}>※提示可能な本人確認書類 </section>
                                <ol className={styles.smallItems}>
                                    <li className={styles.smallItem}>a) 運転免許証</li>
                                    <li className={styles.smallItem}>b) パスポート</li>
                                    <li className={styles.smallItem}>c) マイナンバーカード</li>
                                    <li className={styles.smallItem}>d) 住民基本台帳</li>
                                    <li className={styles.smallItem}>e) 在留カード</li>
                                    <li className={styles.smallItem}>f) 運転経歴証明書</li>
                                    <li className={styles.smallItem}>g) 特別永住者証明書</li>
                                </ol>
                            </li>
                        </ol>
                        <section className={styles.description}>
                            なお、本人確認書類の提出は、「本人確認」からお手続きください。また、在卒大学確認バッジの獲得を希望される方は上記2点に加えて、学生証を提示してください。
                        </section>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>3.Trailに相応しくない行為について</h3>
                        <section className={styles.description}>
                            先生は、教科や形態を問わず先生の自由な内容で教えることができますが、以下の行為については行わないものとします。
                        </section>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1) サイト外での活動や行為も含めて、犯罪行為およびその他の他人に迷惑となる行為
                            </li>
                            <li className={styles.item}>
                                2) 告知内容と著しく異なる内容で講座を開催する行為（価格、内容、日時を含む）
                            </li>
                            <li className={styles.item}>3) 教育行為から逸脱する行為</li>
                            <li className={styles.item}>4) 別の営利目的や宗教への勧誘を目的とした行為</li>
                            <li className={styles.item}>
                                5) Trailから外部サイトでの予約申込口に誘導する「広告的」な使い方
                            </li>
                            <li className={styles.item}>6) Trailを介さず受講料を直接参加者から徴収する行為</li>
                            <li className={styles.item}>7) 生徒の個人としての人権やその他の権利を侵害するような行為</li>
                            <li className={styles.item}>
                                8)
                                事前に参加者からの承諾を得ないで参加者の肖像権やプライバシーを侵害しうる行為（写真撮影、動画配信などを含む）
                            </li>
                            <li className={styles.item}>
                                9) レビューを不正に操作する行為
                                <ol className={styles.smallItems}>
                                    <li className={styles.smallItem}>
                                        ・レビューを投稿するよう生徒に強要する行為、またはそれを仄めかす行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        ・実体験に基づかないレビューを投稿するまたは投稿させる行為
                                    </li>
                                    <li className={styles.smallItem}>
                                        ・先生同士で共謀して相互にレビューを投稿し合う行為（自身の講座に対して高評価のレビューを投稿してもらうことと引き換えに他の先生が開催している講座に対して高評価のレビューを投稿する行為等）
                                    </li>
                                    <li className={styles.smallItem}>
                                        ・高評価のレビューを条件に対価（レビューの対価としてキャッシュバック、イベントや交流会への参加資格、値引き、追加特典、景品等）を与える行為
                                    </li>
                                </ol>
                            </li>
                            <li className={styles.item}>
                                11) 先生が複数アカウント又は他ユーザーと共謀し、ポイントを利用し不正に売上を取得する行為
                            </li>
                            <li className={styles.item}>
                                12) その他Trailで開催する講座として相応しくないと当社が判断した行為
                            </li>
                        </ol>
                        <section className={styles.description}>
                            Trailのサイト内外での活動を含め、過去に先生がTrailに相応しくない行為を行ったものと当社が判断した場合は、当社はその事実をベースに以降の先生の講座掲載をお断りする権利を持つと共に、その事実をサイト上で公表することができるものとし、先生はこれに一切の異議を申し立てないものとします。また、公表によって先生に生じることのある損害などについて、当社は一切の責任を負わないものとします。
                        </section>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>4.講座の作成について </h3>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                (1)講座の作成から承認・公開について
                                <section className={styles.itemDescription}>
                                    先生は講座の作成を自由に行うことができます。作成した講座は即時公開されますが、内容に関して不適切であると判断された場合には削除の対象となる場合があります。
                                </section>
                            </li>
                            <li className={styles.item}>
                                (2)単発・短期講座について
                                <section className={styles.itemDescription}>
                                    定期講座とは、先生が予め指定した日時に1回だけ開催される授業形式の講座のことを指します。
                                </section>
                            </li>
                            <li className={styles.item}>
                                (3)定期講座について
                                <section className={styles.itemDescription}>
                                    定期講座とは、毎週など決まった時間に開催される授業形式の講座のことを指します。
                                </section>
                            </li>
                            <li className={styles.item}>
                                (4)開催形式について
                                <section className={styles.itemDescription}>
                                    Trailでは、インターネット上でビデオ会議ツールZoomを使って講座を開催する「オンライン形式」で講座を開催することができます。
                                </section>
                            </li>
                            <li className={styles.item}>
                                (5)講座作成にあたっての禁止事項について
                                <section className={styles.itemDescription}>特に以下の項目は禁じられています。</section>
                                <ol className={styles.smallItems}>
                                    <li className={styles.smallItem}>
                                        ・本サイトを受講予約プラットフォームとしてではなく、広告媒体として使用する行為（外部サイトや教室への誘導、受講料の振込先記載・メール・電話の直接予約申込など弊社システムを経由しない受講料の支払いへの誘導など）
                                    </li>
                                    <li className={styles.smallItem}>
                                        ・受講料を、過去提示していた金額と比較して著しく高額とサイト運営事務局が判断する水準へ引き上げる行為
                                    </li>
                                    <li className={styles.smallItem}>・無料、もしくは実質無料とみなされる講座の掲載</li>
                                    <li className={styles.smallItem}>・虚偽の記載</li>
                                    <li className={styles.smallItem}>
                                        ・Trailへの手数料支払いを意図的に回避するような記載
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>5.公開をお断りする可能性のある講座及び先生について</h3>
                        <section className={styles.description}>
                            以下の講座は講座の公開をお断りする可能性があります。
                        </section>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                1)教育とは別の目的のために開催されていると当社が判断する講座（営業、商材販売、採用、別イベントへの勧誘などを目的とした講座。またはサービス、商品等を購入させる等、勧誘を前提とした講座）
                            </li>
                            <li className={styles.item}>2)講座の開催期間を越える役務提供を行う行為 </li>
                            <li className={styles.item}>3)個人による参加を受け付けていない、企業・団体向け講座</li>
                            <li className={styles.item}>4)本人確認手続きの結果、当社が利用を不適当と判断した場合</li>
                            <li className={styles.item}>
                                5)以前に本ガイドラインに違反した為に講座の公開あるいはユーザー登録の権利を剥奪されているユーザーにより作成された講座
                            </li>
                        </ol>
                        <section className={styles.description}>
                            上記にかかわらず、先生のTrail内外での活動において、当社がTrailに相応しくないとみなす行為があったとみなされた場合には、当社の判断により以降の講座の掲載及び先生としての利用権限の停止措置を行う可能性があります。
                        </section>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>6.公開後の講座内容の変更について </h3>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                (1)受講予約がない場合について
                                <section className={styles.itemDescription}>
                                    講座が公開された後も受講予約がない場合は、講座内容の加筆修正を行うことができます。
                                    開催日程（日時、開催場所、定員など）・金額についても、変更があった場合は実体に則した形に修正・変更していただくことが可能です。但し、講座で提供するスキルの内容や提供のされ方自体が公開当時から大幅に異なるような内容の変更についてはお控えください。
                                    仮にサイト運営事務局が大幅な乖離と認めた変更を発見した場合は、事前の通告なく講座の公開を取りやめる可能性があります。
                                </section>
                            </li>
                            <li className={styles.item}>
                                (2)受講予約がある場合について
                                <section className={styles.itemDescription}>
                                    すでに受講予約がある場合、混乱や争いを避けるため、修正・変更を行うことはできないものとします。
                                </section>
                            </li>
                            <li className={styles.item}>
                                (3)講座内容の変更に対する対応について
                                <section className={styles.itemDescription}>
                                    仮にサイト運営事務局が大幅な乖離と認めた変更を発見した場合は、事前の通告なく講座の公開を取りやめる可能性があります。また生徒より「当初の告知内容と当日提供されたサービスが異なる」というような異議申し立てがあった場合について、事前の通告なく講座の公開を取りやめる可能性があるとともに、生徒に生じた被害やその損害賠償については当社は一切の責任を負わないものとします。
                                    なお、受講予約受付後の講座内容の変更が相次ぐ場合には、以降の講座の作成・開催及びその他のユーザー機能の利用権利を剥奪される可能性がありますのでご留意ください。
                                </section>
                            </li>
                        </ol>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>7.受講料の設定について </h3>
                        <section className={styles.description}>
                            受講料は、単発・短期講座については全日程に対する価格を500円から10万円までの範囲で、定期講座については1時間あたりの価格を500円から10万円までの範囲で、先生が自由に設定することができるものとします。また、自習室については月の利用料を5,000円から20万円までの範囲で先生が自由に設定することができるものとします。
                            また品質担保の観点から、無料もしくは実質無料とみなされる講座については掲載できないものとします。
                        </section>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>8.手数料のお支払いおよび受講料の受け取りについて</h3>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                (1)手数料の支払いについて
                                <section className={styles.itemDescription}>
                                    先生は、利用規約に従い、Trailを通じて講座を予約した生徒から支払われた受講料の総額から、先生と当社の間で合意されている手数料を、当社に対して支払うものとします。手数料の適用料率については先生と当社の間で別途合意がなされていない限り、手数料ページに定義してある通りとします。かかる手数料は、消費税相当額を含まない金額を意味するものとし、先生は別途消費税相当額を当社に支払うものとします。手数料の料率については、ランク
                                </section>
                            </li>
                            <li className={styles.item}>
                                (2)受講料の受け取りについて
                                <section className={styles.itemDescription}>
                                    当社に対する手数料の支払いを担保するため、先生は、生徒が先生に対して支払う受講料のうち、手数料に相当する金額を当社が先生に代わって受領する権限を当社に対して付与するものとします。当社は、先生に代わって受領した受講料をもって、手数料の支払いに充てることができるものとし、受講料および手数料の支払いに関する一連の過程において、利息は発生しないものとします。
                                </section>
                            </li>
                        </ol>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>9.開催と先生側の理由による開催キャンセルについて</h3>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                (1)講座開催の義務とキャンセル・中止の原則禁止について
                                <section className={styles.itemDescription}>
                                    先生は、講座の開催日程を公開したとき、責任を持って、告知した内容の通りに講座を開催する義務を負うものとします。
                                    先生は、当社が設けているキャンセル及び返金のルールに対して齟齬のあるキャンセルポリシーを、別途定義及び提示することはできないものとします。
                                    先生側の理由による開催のキャンセル・中止は、やむを得ない場合を除いては原則できないものとします。仮に、先生が、開催のキャンセルにあたって、本ガイドラインに定めるプロセスを経ず、予約者から、開催の実態やキャンセルプロセスについての異議申し立てが発生した場合、当社は先生に警告を発することがあります。
                                    もし先生が当社の忠告を聞き入れなかった場合、あるいは従わないと当社が判断した場合は、以降の、講座の作成・開催およびその他のユーザー機能の利用権利を剥奪する可能性がありますのでご留意ください。
                                </section>
                            </li>
                            <li className={styles.item}>
                                (2) やむを得ない場合における開催のキャンセルについて
                                <section className={styles.itemDescription}>
                                    先生は日程を公開したとき以降、やむを得ない場合を除いては原則、責任を持って告知した内容の通りに日程を開催する義務を負う事とします。万が一、やむを得ない理由により先生側から講座の開催をキャンセルしなければならない事態が発生した場合は、講座がまだ開催されていないタイミングにおいてのみ、先生が速やかに以下のプロセスに沿って対応するものとします。
                                </section>
                                <ol className={styles.smallItems}>
                                    <li className={styles.smallItem}>
                                        1）開催キャンセルを決定した時点で、予約者全員に対して開催をキャンセルすること、加えて単発・短期講座・自習室の場合はキャンセルと同時に返金処理が行われる旨を伝えていただき、当該開催日程の「予約の状況」ページ内の「開催をキャンセルする」ボタンから、開催のキャンセル/返金処理を実行する。
                                    </li>
                                    <li className={styles.smallItem}>
                                        2）処理の実行に際しては、キャンセル理由と単発・短期講座の場合はキャンセルと同時に返金処理がなされる旨の説明を併記する。
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <section className={styles.description}>
                            また、開催のキャンセルに際する講座の振替実施の対応は、先生に委ねるものとし、その実施に伴ういかなる争いや損害が発生した場合についても、当社は一切の責任を負わず不介入の立場をとるものとします。
                        </section>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>10.生徒側の理由による参加キャンセルについて </h3>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                (1)単発・短期講座の参加キャンセルについて
                                <ol className={styles.smallItems}>
                                    <li className={styles.smallItem}>
                                        1)予約締切日時までのキャンセル申し出について
                                        <section className={styles.smallItemDescription}>
                                            生徒は予約締切日時までは予約キャンセルが可能であり、当社は受講料を生徒に返還するものとします。
                                            なお、生徒は予約締切日時までのキャンセルについては、当社側の理由によりキャンセルが実行できなかった場合を除いて、返金を伴う予約のキャンセル成立には、生徒自身がサイト上でキャンセルの実行を行い、キャンセル確定の自動通知メールを確認する必要があります。
                                            生徒側からの任意の連絡のみでは予約のキャンセルは成立したものとはみなされませんのでご留意ください。
                                        </section>
                                    </li>
                                    <li className={styles.smallItem}>
                                        2)予約締切日時経過後のキャンセルについて
                                        <section className={styles.smallItemDescription}>
                                            生徒は予約締切日時を経過した後は、いかなる理由においても予約をキャンセルすることはできないものとし、先生に対しては受講料の返金や振替対応の義務は原則発生しないものとします。
                                            仮に、予約締切日時経過後の参加のキャンセル要望が発生した場合、その際の返金や講座の振替実施をする場合などの一切の判断と対応は、先生に委ねるものとし、その実施に伴ういかなる争いや損害が発生した場合についても、当社は一切の責任を負わず不介入の立場をとるものとします。
                                        </section>
                                    </li>
                                </ol>
                            </li>
                            <li className={styles.item}>
                                (2)定期講座の参加キャンセルについて
                                <section className={styles.itemDescription}>
                                    生徒はいつでもキャンセルをすることができるものとします。その場合、次の請求期間の請求は停止され、受講ができなくなります。
                                </section>
                            </li>
                        </ol>
                        <section className={styles.description}>
                            最後に、開催キャンセルおよび返金プロセスの実行については、一度実行するとシステム上取り消し及び変更処理ができないことを理解し、慎重な判断と対応をする必要がある旨ご留意ください。
                        </section>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>11.オンライン講座について </h3>
                        <section className={styles.description}>
                            オンライン講座とは、ビデオ会議ツールZoomを使ったオンライン形式の講座を指します。
                        </section>
                        <ol className={styles.items}>
                            <li className={styles.item}>
                                (1)開催できるオンライン講座の要件
                                <section className={styles.itemDescription}>
                                    Trailで開催できるオンライン講座は、ビデオ会議ツールZoomを用いて、先生と生徒がリアルタイムでコミュニケーションを取りながら講義をおこなう開催形式であることが必要要件となります。
                                    そのため、以下のような開催形式の講座はオンライン講座として開催することはできません。
                                </section>
                                <ol className={styles.smallItems}>
                                    <li className={styles.smallItem}>
                                        ・生徒に対し、予め撮影した動画を送付する、動画サイトのURLを案内するといった形式の講座
                                    </li>
                                    <li className={styles.smallItem}>
                                        ・開催日時に動画を再生・視聴するのみの講座（講座中に資料として動画を見せることはこの限りではありません）
                                    </li>
                                    <li className={styles.smallItem}>
                                        ・生徒からの提出物を先生が添削するのみで完結する講座
                                    </li>
                                    <li className={styles.smallItem}>
                                        ・先生側から一方向にて映像・音声を配信し、予約した生徒の参加が先生側で確認できない形式の講座（Live配信など）
                                    </li>
                                    <li className={styles.smallItem}>
                                        ・生徒からの質問などのコミュニケーションを一切受け付けないような形式の講座
                                    </li>
                                </ol>
                            </li>
                            <li className={styles.item}>
                                (2)オンライン講座に必要な環境について
                                <section className={styles.itemDescription}>
                                    先生は、講座を配信するための外部環境、通信速度等、安定してオンライン講座が開催できる環境づくりに努めるものとします。なお、PCをはじめとした各種機器、ビデオ会議ツール、通信費などオンライン開催に必要な環境は先生自身で用意し、かかる費用はすべて先生の自己負担となります。
                                </section>
                            </li>
                            <li className={styles.item}>
                                (3)接続サポート・トラブル対応について
                                <section className={styles.itemDescription}>
                                    講座への接続方法の案内、当日の接続に関するサポートは先生自身がおこなうものとします。先生・生徒共に当日の接続サポートは当社では対応しかねますので、事前に接続テストをおこない、スムーズに開催できるよう十分に準備をおこなってください。
                                    なお、接続トラブル軽減のため、参加URL等の接続方法の案内は開催開始の直前ではなく、時間に余裕をもって早めに生徒に案内するよう心がけましょう。
                                </section>
                            </li>
                            <li className={styles.item}>
                                (4)開催の中止について
                                <section className={styles.itemDescription}>
                                    講座開始前・開催中において、機器または通信トラブルにより講座の開催が困難な場合は、先生は、参加者と話し合い、別の日程にて再度開催するなど、適切な対応をとるものとします。講座中に発生した機器・通信トラブルの原因の特定、責任の所在について、当社は一切の判断ができかねますので予めご了承ください。
                                    尚、参加者から当社に対し、通信品質等に関する報告が複数回あり、当社が改善の必要があると判断した場合、先生に対し通信品質改善についての通告、またはオンライン講座の掲載の停止をさせて頂く場合がございます。
                                </section>
                            </li>
                            <li className={styles.item}>
                                (5)講座の録画について
                                <section className={styles.itemDescription}>
                                    講座のプロモーション、参加者への復習等を目的として、講座内容の録画をする場合は、事前あるいは当日に参加者にも承諾得ておこなうものとし、参加者の映像・声を含むデータファイルを無断でインターネット等に掲載することがないよう十分注意をしてください。
                                </section>
                            </li>
                        </ol>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>12.生徒とのコミュニケーションについて</h3>
                        <section className={styles.description}>
                            生徒とのコミュニケーションとは、先生と生徒との間で、開催当日およびその前後にメール、メッセージなど、オンラインで発生するすべてのやりとりを指します。
                            先生は、講座の開催のみならず、講義内容や準備及び事後の対応などについて、何かしらの変更や遅延の余地が発生する場合は、生徒に不安を与えないようタイムリーなコミュニケーションを心がけるものとします。
                            仮に先生からのコミュニケーションの著しい遅延や対応不足が生徒の不安を招き、当社へのクレームが頻繁に発生する場合は、当社は先生に警告を発する場合があります。
                            もし先生が当社の警告を聞き入れなかった場合、あるいは従わないと当社が判断した場合は、先生はそれ以降、講座の作成・開催およびその他のユーザー機能の利用権利を剥奪される可能性がありますのでご留意ください。
                        </section>
                    </section>
                    <section className={styles.chapterWrapper}>
                        <h3 className={styles.heading}>13.生徒からの異議申し立てとクーリングオフ期間について</h3>
                        <section className={styles.description}>
                            もし講座が告知通りに開催されなかった場合、講座を受講予約した生徒は、講座の開催中から、講座の終了から1週間までの期間中に、先生による違反行為の指摘および役務提供についての異議申し立てが出来ることとします。
                            異議申し立てを受けた後、当社は講座開催の真偽について事実確認を行い、先生側に問題があると判断された場合には然るべき対応（生徒へのポイント返還、先生に対する返金要求等）を行います。
                        </section>
                    </section>
                    <section className={styles.dateWrapper}>
                        <div className={styles.date}>2024年4月12日制定</div>
                    </section>
                </section>
                <Button component={Link} to={userMode === "teacher" ? "/" : "/"} className={styles.toHomeButton}>
                    ホームに戻る
                </Button>
            </div>
        </>
    );
};
