import { useSelector } from "react-redux";
import { RootState } from "@/ducks";

import { QueryLoadingWrapper } from "../../../QueryLoadingWrapper";

import { TeacherAnnouncementListContents } from "./TeacherAnnouncementListContents";

import { AnnouncementsTooltip } from "../AnnouncementsTooltip";
import { useGetTeacherCheckedAnnouncementIdsQuery } from "@/store/hooks/teachers";
import { useGetTeacherAnnouncementsQuery } from "@/store/hooks/announcements";

export const TeacherAnnouncementList: React.VFC = () => {
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId) as string;
    const teacherCheckedAnnouncementIdsQueryState = useGetTeacherCheckedAnnouncementIdsQuery(teacherId);
    const teacherAnnouncementsQueryState = useGetTeacherAnnouncementsQuery(teacherId);

    return (
        <QueryLoadingWrapper
            {...teacherAnnouncementsQueryState}
            loadingComponent={
                <AnnouncementsTooltip
                    open={false}
                    title={<></>}
                    badgeContent={0}
                    handleCheckButtonClick={() => {}}
                    handleTooltipOpen={() => {}}
                    handleTooltipClose={() => {}}
                />
            }
        >
            {(announcements) => (
                <QueryLoadingWrapper
                    {...teacherCheckedAnnouncementIdsQueryState}
                    loadingComponent={
                        <AnnouncementsTooltip
                            open={false}
                            title={<></>}
                            badgeContent={0}
                            handleCheckButtonClick={() => {}}
                            handleTooltipOpen={() => {}}
                            handleTooltipClose={() => {}}
                        />
                    }
                >
                    {(checkedAnnouncementIds) => {
                        const unreadAnnouncements = announcements.filter(
                            (announcement) => !checkedAnnouncementIds.includes(announcement.announcementId),
                        );
                        return <TeacherAnnouncementListContents announcements={unreadAnnouncements} />;
                    }}
                </QueryLoadingWrapper>
            )}
        </QueryLoadingWrapper>
    );
};
