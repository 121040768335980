import { memo, useCallback } from "react";
import { useState, useEffect } from "react";

import { Hidden } from "@material-ui/core";
import { useSelector } from "react-redux";

import { PCContents } from "./PCContents";
import { SPContents } from "./SPContents";
import { ChatResponse } from "@/store/autogenApi";
import { RootState } from "@/ducks";
import { useUpdateReadStatusMutation } from "@/store/hooks/readStatuses";
import { Socket } from "socket.io-client";

interface Props {
    chats: ChatResponse[];
    previousChats: ChatResponse[];
    openedChatId: string | undefined;
    socketInfos: SocketInfo[];
    isPrevious: boolean;
    isProcessing: boolean;
    handleIsProcessingChange: (isProcessing: boolean) => void;
    handlePreviousChatsChange: (chats: ChatResponse[]) => void;
    handleSocketInfosChange: (chats: ChatResponse[]) => void;
    handleOpenedChatIdChange: (chatId: string | undefined) => void;
    refetch: () => void;
}

export interface ChatInfo extends ChatResponse {
    chatMessage: string;
}

export interface SocketInfo {
    socket: Socket;
    chatId: string;
}

export const ChatsContents: React.VFC<Props> = memo(function ChatsContents(props) {
    const [chatInfos, setChatInfos] = useState<ChatInfo[]>([]);
    const [temporaryObjectURLsList, setTemporaryObjectURLsList] = useState<string[][]>([]);
    const [isWaitingToRefetch, setIsWaitingToRefetch] = useState(false);

    const currentStudentId = useSelector((state: RootState) => state.jwt.studentId as string);
    const currentTeacherId = useSelector((state: RootState) => state.jwt.teacherId as string);
    const userMode = useSelector((state: RootState) => state.jwt.userMode);

    const updateReadStatus = useUpdateReadStatusMutation();

    const refetchChats = useCallback(
        async (isRead: boolean) => {
            console.log("refetchChats");
            if (!props.openedChatId) {
                props.refetch();
                return;
            }
            const openedChatInfo = props.chats.find((chat) => chat.chatId === props.openedChatId);
            console.log(openedChatInfo);
            if (!openedChatInfo) {
                props.refetch();
                return;
            }
            const socket = props.socketInfos.find((socketInfo) => socketInfo.chatId === openedChatInfo.chatId)?.socket;
            console.log(socket);
            if (!socket) {
                props.refetch();
                return;
            }
            const readStatus = openedChatInfo.readStatuses.find((readStatus) =>
                userMode === "student"
                    ? readStatus.studentId === currentStudentId
                    : readStatus.teacherId === currentTeacherId,
            );
            console.log(readStatus);
            if (!readStatus) {
                props.refetch();
                return;
            }
            await updateReadStatus({
                readStatusId: readStatus.readStatusId,
            });
            if (isRead) return;
            const roomId = props.openedChatId;
            socket.emit("readMessage", {
                roomId,
            });
        },
        [
            props.socketInfos,
            props.refetch,
            props.chats,
            props.openedChatId,
            userMode,
            currentStudentId,
            currentTeacherId,
        ],
    );

    useEffect(() => {
        if (!isWaitingToRefetch) return;
        if (props.isProcessing) return;
        (async () => {
            await refetchChats(true);
            setIsWaitingToRefetch(false);
        })();
    }, [isWaitingToRefetch, props.isProcessing, refetchChats]);

    useEffect(() => {
        (async () => {
            const socketOnPromise = props.socketInfos.map(async (socketInfo) => {
                socketInfo.socket.on("joined", (payload) => {
                    console.log("Joined: " + payload);
                });
                socketInfo.socket.on("messageWasCreated", async (payload) => {
                    console.log("Created: " + payload);
                    await refetchChats(false);
                });
                socketInfo.socket.on("messageWasOpened", async (payload) => {
                    console.log("opened: " + payload);
                    await refetchChats(false);
                });
                socketInfo.socket.on("messageWasRead", async (payload) => {
                    console.log("read: " + payload);
                    if (props.isProcessing) {
                        setIsWaitingToRefetch(true);
                    } else {
                        await refetchChats(true);
                    }
                });
            });
            await Promise.all(socketOnPromise);
        })();
        return () => {
            (async () => {
                const socketOffPromise = props.socketInfos.map(async (socketInfo) => {
                    socketInfo.socket.off("joined");
                    socketInfo.socket.off("messageWasCreated");
                    socketInfo.socket.off("messageWasOpened");
                    socketInfo.socket.off("messageWasRead");
                });
                await Promise.all(socketOffPromise);
            })();
        };
    }, [props.socketInfos, refetchChats, props.refetch, props.openedChatId, props.isProcessing]);

    useEffect(() => {
        props.handlePreviousChatsChange(props.chats);
    }, [props.chats, props.handlePreviousChatsChange]);

    useEffect(() => {
        if (props.chats.length === 0) return;
        const newChatInfos = props.chats.map((chats) => {
            return {
                ...chats,
                chatMessage: "",
            };
        });
        setChatInfos(newChatInfos);
        props.handleIsProcessingChange(false);
        props.handlePreviousChatsChange(props.chats);
        const isNewConnection = props.chats.length !== props.previousChats.length;
        if (!isNewConnection) return;
        props.handleSocketInfosChange(props.chats);
    }, [props.chats, props.previousChats, props.handleSocketInfosChange, props.handlePreviousChatsChange]);

    useEffect(() => {
        const messageWrapper = document.getElementById("message-wrapper");
        const scrollContents = document.getElementById("scroll-contents");
        if (messageWrapper && scrollContents) {
            messageWrapper.scrollTop = scrollContents?.scrollHeight;
        }
    }, [props.openedChatId]);

    const handleListButtonClick = useCallback(
        async (chat: ChatResponse) => {
            if (userMode === "student") {
                const socketInfo = props.socketInfos.find((info) => info.chatId === chat.chatId);
                if (!socketInfo) return;
                props.handleOpenedChatIdChange(chat.chatId);
                const targetChatInfo = chatInfos.find((info) => info.chatId === chat.chatId);
                const targetMessages = targetChatInfo?.messages;
                if (!targetMessages) return;
                const targetReadStatus = targetChatInfo.readStatuses.find(
                    (status) => status.studentId === currentStudentId,
                );
                if (!targetReadStatus) return;
                const unreadLength = targetMessages.filter((message) => {
                    const isUnread =
                        new Date(targetReadStatus.updatedAt || targetReadStatus.createdAt) <=
                        new Date(message.createdAt);
                    if (message.student?.studentId !== currentStudentId && isUnread) {
                        return true;
                    }
                    return false;
                }).length;
                if (unreadLength === 0) return;
                await updateReadStatus({
                    readStatusId: targetReadStatus.readStatusId,
                });
                const roomId = chat.chatId;
                socketInfo.socket.emit("openedMessage", {
                    roomId,
                });
            } else if (userMode === "teacher") {
                const socketInfo = props.socketInfos.find((info) => info.chatId === chat.chatId);
                if (!socketInfo) return;
                props.handleOpenedChatIdChange(chat.chatId);
                const targetChatInfo = chatInfos.find((info) => info.chatId === chat.chatId);
                const targetMessages = targetChatInfo?.messages;
                if (!targetMessages) return;
                const targetReadStatus = targetChatInfo.readStatuses.find(
                    (status) => status.teacherId === currentTeacherId,
                );
                if (!targetReadStatus) return;
                const unreadLength = targetMessages.filter((message) => {
                    const isUnread =
                        new Date(targetReadStatus.updatedAt || targetReadStatus.createdAt) <=
                        new Date(message.createdAt);
                    if (message.teacher?.teacherId !== currentTeacherId && isUnread) {
                        return true;
                    }
                    return false;
                }).length;
                if (unreadLength === 0) return;
                await updateReadStatus({
                    readStatusId: targetReadStatus.readStatusId,
                });
                const roomId = chat.chatId;
                socketInfo.socket.emit("openedMessage", {
                    roomId,
                });
            }
        },
        [props.handleOpenedChatIdChange, chatInfos, userMode, currentStudentId, currentTeacherId, props.socketInfos],
    );

    const handleBackButtonClick = useCallback(() => {
        props.handleOpenedChatIdChange(undefined);
    }, [props.handleOpenedChatIdChange]);

    // const handleBackButtonClick = () => {
    //     setOpenedMessagesIndex(undefined);
    // };

    const handleTemporaryObjectURLsListChange = useCallback((urlsList: string[][]) => {
        setTemporaryObjectURLsList(urlsList);
    }, []);

    const handleChatMessageChange = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            if (!props.openedChatId) return;
            const newChatInfos = chatInfos.map((chatInfo) => {
                if (chatInfo.chatId === props.openedChatId) {
                    return {
                        ...chatInfo,
                        chatMessage: e.target.value,
                    };
                } else {
                    return chatInfo;
                }
            });
            setChatInfos(newChatInfos);
        },
        [chatInfos, props.openedChatId],
    );

    const clearChatMessage = useCallback(
        (chatId: string) => {
            const newChatInfos = chatInfos.map((chatInfo) => {
                if (chatInfo.chatId === chatId) {
                    return {
                        ...chatInfo,
                        chatMessage: "",
                    };
                } else {
                    return chatInfo;
                }
            });
            setChatInfos(newChatInfos);
        },
        [chatInfos],
    );
    return (
        <>
            <Hidden smDown>
                <PCContents
                    chatInfos={chatInfos}
                    openedChatInfo={chatInfos.find((chatInfo) => chatInfo.chatId === props.openedChatId)}
                    temporaryObjectURLsList={temporaryObjectURLsList}
                    socketInfos={props.socketInfos}
                    isProcessing={props.isProcessing}
                    handleIsProcessingChange={props.handleIsProcessingChange}
                    clearChatMessage={clearChatMessage}
                    handleBackButtonClick={handleBackButtonClick}
                    handleListButtonClick={handleListButtonClick}
                    handleChatMessageChange={handleChatMessageChange}
                    handleTemporaryObjectURLsListChange={handleTemporaryObjectURLsListChange}
                />
            </Hidden>
            <Hidden mdUp>
                <SPContents
                    chatInfos={chatInfos}
                    openedChatInfo={chatInfos.find((chatInfo) => chatInfo.chatId === props.openedChatId)}
                    temporaryObjectURLsList={temporaryObjectURLsList}
                    socketInfos={props.socketInfos}
                    isProcessing={props.isProcessing}
                    handleIsProcessingChange={props.handleIsProcessingChange}
                    clearChatMessage={clearChatMessage}
                    handleBackButtonClick={handleBackButtonClick}
                    handleListButtonClick={handleListButtonClick}
                    handleChatMessageChange={handleChatMessageChange}
                    handleTemporaryObjectURLsListChange={handleTemporaryObjectURLsListChange}
                />
            </Hidden>
        </>
    );
});
