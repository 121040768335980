import { FAQDetailsBase } from "../../FAQDetailsBase";

import { EMAIL_NOT_ARRIVED_CONFIGS } from "./EmailNotArrivedConfigs";

export const EmailNotArrived: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="登録時のメールが届きません"
            text="Trailの登録の後、認証メールを送信しておりますが、以下の理由から受信メールが確認できない可能性があります。"
            FAQConfigs={EMAIL_NOT_ARRIVED_CONFIGS}
            list={[
                "迷惑メールフォルダに届いている",
                "メール受信設定により、メールフォルダ自体に届いていない",
                "登録したメールアドレスが正しくない",
            ]}
        />
    );
};
