import { Button, TextareaAutosize } from "@material-ui/core";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { TeacherToDoResponse } from "@/store/autogenApi";
import { useDisapprovePrivateRequestMutation } from "@/store/hooks/privateRequests";
import { ErrorMessage } from "../../../../../ErrorMessage";
import { Processing } from "../../../../../Processing";
import { ToastContents } from "../../../../../Toast";
import styles from "../index.module.scss";

interface Props {
    targetTeacherToDo: TeacherToDoResponse | null;
    maxHeight: number;
    handleClose: () => void;
    handleBackButtonClick: () => void;
}

export const DisapprovalModeContents: React.VFC<Props> = (props) => {
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [disapprovalMessage, setDisapprovalMessage] = useState<string>("");
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const disapprovePrivateRequest = useDisapprovePrivateRequestMutation();

    const handleDisapprovalCommentChange = useCallback((e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        setDisapprovalMessage(e.target.value as string);
    }, []);

    const handleConfirmButtonClick = useCallback(async () => {
        setIsChecked(true);
        if (disapprovalMessage.length < 10 || 500 < disapprovalMessage.length) return;
        const privateRequest = props.targetTeacherToDo?.class?.privateRequest;
        if (!props.targetTeacherToDo || !privateRequest) return;
        setIsProcessing(true);
        const result = await disapprovePrivateRequest({
            disapprovePrivateRequestRequestBody: {
                privateRequestId: privateRequest.privateRequestId,
                teacherToDoId: props.targetTeacherToDo.teacherToDoId,
                message: disapprovalMessage,
            },
        });
        setIsProcessing(false);
        if (result.isSuccess) {
            toast(<ToastContents title="スケジュール申請非承認完了" isCompleted />);
        } else {
            toast(<ToastContents title="スケジュール申請非承認失敗" isFailed />);
        }
    }, [disapprovePrivateRequest, props.targetTeacherToDo, disapprovalMessage]);

    return (
        <div className={styles.disapprovalWrapper} style={{ maxHeight: props.maxHeight }}>
            <div className={styles.disapprovalTitle}>非承認</div>
            <div className={styles.pleaseInput}>非承認の理由を記入して、「確定」を押してください。</div>
            <TextareaAutosize
                className={styles.disapprovalComment}
                value={disapprovalMessage}
                onChange={handleDisapprovalCommentChange}
                placeholder="10文字以上500文字以内"
                minRows={5}
                maxRows={10}
            />
            <ErrorMessage
                content="10文字以上500文字以内で入力してください。"
                when={isChecked && (disapprovalMessage.length < 10 || 500 < disapprovalMessage.length)}
            />
            <div className={styles.buttonsWrapper}>
                <Button className={styles.backButton} onClick={props.handleBackButtonClick}>
                    戻る
                </Button>
                <Button className={styles.disapprovalConfirmButton} onClick={handleConfirmButtonClick}>
                    {isProcessing ? <Processing /> : "確定"}
                </Button>
            </div>
        </div>
    );
};
