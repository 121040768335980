import { memo } from "react";
import { EmailNotificationsSettingResponse } from "@/store/autogenApi";
import { Switch } from "../Switch";
import styles from "../index.module.scss";
import { NotificationsItem } from "../NotificationsItem";

interface Props {
    allowEmailNotifications: boolean;
    message: boolean;
    favorite: boolean;
    information: boolean;
    recommendation: boolean;
    maintenance: boolean;
    review: boolean;
    newCourse: boolean;
    newRequest: boolean;
    emailNotificationsSetting: EmailNotificationsSettingResponse;
    getIsEveryNotificationsItemTheSame: () => boolean;
    setIsEdited: React.Dispatch<React.SetStateAction<boolean>>;
    handleAllowEmailNotificationsChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleMessageChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleFavoriteChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleInformationChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleRecommendationChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleMaintenanceChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleReviewChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleNewCourseChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleNewRequestChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const EmailNotificationsSetting: React.VFC<Props> = memo(function EmailNotificationsSetting(props) {
    return (
        <div className={`${styles.notificationsWrapper}`}>
            <div className={styles.notificationsGridWrapper}>
                <div className={`${styles.notificationsTitle} ${styles.targetTitle}`}>メール通知</div>
                <Switch
                    checked={props.allowEmailNotifications}
                    handleChange={props.handleAllowEmailNotificationsChange}
                />
                {props.allowEmailNotifications && (
                    <div className={styles.notificationsItems}>
                        <NotificationsItem
                            title="メッセージ"
                            checked={props.message}
                            handleChange={props.handleMessageChange}
                        />
                        <NotificationsItem
                            title="お気に入り"
                            checked={props.favorite}
                            handleChange={props.handleFavoriteChange}
                        />
                        <NotificationsItem
                            title="お知らせ"
                            checked={props.information}
                            handleChange={props.handleInformationChange}
                        />
                        <NotificationsItem
                            title="おすすめ"
                            checked={props.recommendation}
                            handleChange={props.handleRecommendationChange}
                        />
                        <NotificationsItem
                            title="メンテナンス"
                            checked={props.maintenance}
                            handleChange={props.handleMaintenanceChange}
                        />
                        <NotificationsItem
                            title="レビュー"
                            checked={props.review}
                            handleChange={props.handleReviewChange}
                        />
                        <NotificationsItem
                            title="新着講座"
                            checked={props.newCourse}
                            handleChange={props.handleNewCourseChange}
                        />
                        <NotificationsItem
                            title="新着講座リクエスト"
                            checked={props.newRequest}
                            handleChange={props.handleNewRequestChange}
                        />
                    </div>
                )}
            </div>
        </div>
    );
});
