import { memo, useCallback, useState } from "react";
import { Button } from "@material-ui/core";
import { MdMessage } from "react-icons/md";
import styles from "./index.module.scss";
import { ChatModal } from "../ChatModal";

interface Props {
    studentId: string;
    teacherId: string;
    buttonLabel: string;
    style?: React.CSSProperties;
}

export const IndividualChatButton: React.VFC<Props> = memo(function ProposalComponent(props) {
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const handleChatModalOpen = useCallback(() => {
        setModalOpen(true);
    }, []);

    const handleChatModalClose = useCallback(() => {
        setModalOpen(false);
    }, []);

    return (
        <>
            <Button className={styles.questionButton} onClick={handleChatModalOpen} style={props.style}>
                <MdMessage className={styles.questionIcon} />
                <span className={styles.question}>{props.buttonLabel}</span>
            </Button>
            {modalOpen && (
                <ChatModal handleClose={handleChatModalClose} studentId={props.studentId} teacherId={props.teacherId} />
            )}
        </>
    );
});
