import { FAQDetailsBase } from "../../FAQDetailsBase";

import { PROHIBITED_ACTION_CONFIGS } from "./ProhibitedActionConfigs";

export const ProhibitedAction: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="Trailで禁止されている行為について"
            text="いつもTrailをご利用いただきありがとうございます。下記の行為は、いずれも違反行為となりますので、それらの行為をされている先生につきましては直ちに是正をお願いいたします。継続して不正行為を行っている場合、利用停止となる場合がございますのでご注意ください。"
            list={[
                "Trailを利用せずに受講予約を受け付ける行為",
                "他サービスや商品の宣伝・勧誘行為",
                "不正に講座実績を作る行為",
            ]}
            FAQConfigs={PROHIBITED_ACTION_CONFIGS}
        />
    );
};
