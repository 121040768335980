import { CourseCard } from "@/components/CourseCard";
import styles from "@/pages/Student/MyPage/index.module.scss";
import { CourseResponse } from "@/store/autogenApi";

interface Props {
    visible: boolean;
    courses: CourseResponse[];
    repetition: number;
}

export const TabContents: React.VFC<Props> = (props) => {
    return (
        <div role="tabpanel" hidden={!props.visible} className={styles.tabContents}>
            {props.visible && (
                <>
                    {props.courses.length === 0 ? (
                        <div className={styles.emptyCourses}>対象の講座がありません。</div>
                    ) : (
                        <div className={styles.cardsList}>
                            {[...Array(Math.ceil(props.courses.length / props.repetition))]
                                .map((_, i) => i)
                                .map((idx) => (
                                    <>
                                        <div className={styles.cards} key={idx}>
                                            {props.courses
                                                .slice(
                                                    idx * props.repetition,
                                                    idx * props.repetition + props.repetition,
                                                )
                                                .map((course) => {
                                                    return <CourseCard course={course} key={course.courseId} isFlex />;
                                                })}
                                            {props.courses.slice(
                                                idx * props.repetition,
                                                idx * props.repetition + props.repetition,
                                            ).length < props.repetition &&
                                                [
                                                    ...Array(
                                                        props.repetition -
                                                            props.courses.slice(
                                                                idx * props.repetition,
                                                                idx * props.repetition + props.repetition,
                                                            ).length,
                                                    ),
                                                ].map((_, idx) => <CourseCard key={idx} isHidden isFlex />)}
                                        </div>
                                    </>
                                ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
