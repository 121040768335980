import styles1 from "@/pages/Teacher/CreateCourse/index.module.scss";
import styles2 from "./index.module.scss";
import { CreateAvailableTimeRequestParams, LessonResponse, UpdateAvailableTimeRequestBody } from "@/store/autogenApi";

import { WeeklyScheduleComponent } from "./WeeklySchedule";

import { memo, useCallback } from "react";
import { getDayAndTime } from "@/utils/AvailableTimeUtils";
import { LessonCalendarButton } from "@/components/LessonCalendarButton";
import { HourAndMinute } from "@/utils/DateUtils";

interface Props {
    checked: boolean;
    confirmModalOpen?: boolean;
    dayAnchorEl: HTMLButtonElement | null;
    newStartTime: HourAndMinute;
    newEndTime: HourAndMinute;
    maxDaysInAWeek: number;
    maxMinutesPerLesson: number;
    activeDayOfWeekPopoverIdx: number | undefined;
    availableTimes: (CreateAvailableTimeRequestParams | UpdateAvailableTimeRequestBody)[];
    existingLessons: LessonResponse[];
    availableTimesLengthValidation: boolean;
    handleMaxMinutesPerLessonChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleMaxDaysInAWeekChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    confirmDayTime: () => void;
    handleStartTimeChange: (
        e: React.ChangeEvent<{
            name: string;
            value: number;
        }>,
    ) => void;
    handleEndTimeChange: (
        e: React.ChangeEvent<{
            name: string;
            value: number;
        }>,
    ) => void;
    handleDayTimePopoverClose: () => void;
    handleResetButtonClick: () => void;
    handleDayTimeButtonClick: (event: React.MouseEvent<HTMLButtonElement>, idx: number) => void;
}

export const RegularCourse: React.VFC<Props> = memo(function RegularCourse(props) {
    const checkDayTime = useCallback(
        (dayOfWeekIndex: number) => {
            const targetAvailableTime = props.availableTimes.find(
                (availableTime) => availableTime.dayOfWeekIndex === dayOfWeekIndex,
            );
            if (!targetAvailableTime) return undefined;
            if (!targetAvailableTime.isActive) return true;
            const startTimeHour = targetAvailableTime.startHour;
            const startTimeMinute = targetAvailableTime.startMinute;
            const endTimeHour = targetAvailableTime.endHour;
            const endTimeMinute = targetAvailableTime.endMinute;
            if (
                startTimeHour == undefined ||
                startTimeMinute == undefined ||
                endTimeHour == undefined ||
                endTimeMinute == undefined
            )
                return false;
            if (startTimeHour < endTimeHour) {
                return true;
            } else if (startTimeHour === endTimeHour) {
                return startTimeMinute < endTimeMinute;
            } else {
                return false;
            }
        },
        [props.availableTimes],
    );

    return (
        <>
            {props.confirmModalOpen ? (
                <div className={styles1.openTrue}>
                    <ul className={styles1.schedules}>
                        {props.availableTimes.map((availableTime, idx) => {
                            return (
                                <li className={styles1.schedule} key={idx}>
                                    {`${getDayAndTime(availableTime)}`}
                                </li>
                            );
                        })}
                    </ul>
                    <div className={styles1.value}>
                        週に{props.maxDaysInAWeek}回以内・各{props.maxMinutesPerLesson}分以内を想定
                    </div>
                </div>
            ) : (
                <>
                    <WeeklyScheduleComponent
                        newStartTime={props.newStartTime}
                        newEndTime={props.newEndTime}
                        anchorEl={props.dayAnchorEl}
                        activeDayOfWeekPopoverIdx={props.activeDayOfWeekPopoverIdx}
                        maxDaysInAWeek={props.maxDaysInAWeek}
                        maxMinutesPerLesson={props.maxMinutesPerLesson}
                        availableTimes={props.availableTimes}
                        availableTimesLengthValidation={props.availableTimesLengthValidation}
                        handleMaxMinutesPerLessonChange={props.handleMaxMinutesPerLessonChange}
                        handleMaxDaysInAWeekChange={props.handleMaxDaysInAWeekChange}
                        confirmDayTime={props.confirmDayTime}
                        handleStartTimeChange={props.handleStartTimeChange}
                        handleEndTimeChange={props.handleEndTimeChange}
                        handleDayTimePopoverClose={props.handleDayTimePopoverClose}
                        handleDayTimeButtonClick={props.handleDayTimeButtonClick}
                        handleResetButtonClick={props.handleResetButtonClick}
                        checkDayTime={checkDayTime}
                        checked={props.checked}
                    />
                    <div className={styles2.lessonCalendarWrapper}>
                        <LessonCalendarButton
                            lessons={props.existingLessons}
                            existingLessons={props.existingLessons}
                            onlyView
                            isPlain
                            style={{ paddingLeft: 0, paddingRight: 0, marginTop: "20px" }}
                        />
                    </div>
                </>
            )}
        </>
    );
});
