import { memo, useCallback, useState } from "react";

import FormHelperText from "@material-ui/core/FormHelperText";

import { FileInfo, TrimmingModal } from "@/components/TrimmingModal";
import styles from "@/pages/Teacher/MyPage/index.module.scss";
import { Optional } from "@/components/Tag/Optional";
import { ImageFromS3 } from "@/components/atoms/images/ImageFromS3";

interface Props {
    checked: boolean;
    edit: boolean;
    backgroundImageFileInfo: FileInfo | undefined;
    existingBackgroundImageUrl?: string;
    existingBackgroundImageObjectKey?: string;
    setBackgroundImageFileInfo?: React.Dispatch<React.SetStateAction<FileInfo>>;
}

export const BackgroundImageWrapper: React.VFC<Props> = memo(function BackgroundImageWrapper(props) {
    const [trimmingModalOpen, setTrimmingModalOpen] = useState(false);
    const [targetFile, setTargetFile] = useState<File | undefined>(undefined);
    const selectImg = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            setTargetFile(file);
            handleTrimmingModalOpen();
        }
    }, []);
    const handleTrimmingModalClose = useCallback(() => {
        setTrimmingModalOpen(false);
    }, []);
    const handleTrimmingModalOpen = useCallback(() => {
        setTrimmingModalOpen(true);
    }, []);
    return (
        <li className={styles.editListItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>先生詳細ページ用背景画像</div>
                    <Optional />
                </div>
            </div>
            <div className={styles.rightWrapper}>
                <label className={styles.backgroundImageLabel}>
                    <ImageFromS3 url={props.backgroundImageFileInfo?.url} className={styles.img} />
                    <input type="file" accept="image/*" onChange={selectImg} className={styles.photoInput} />
                    <TrimmingModal
                        open={trimmingModalOpen}
                        handleClose={handleTrimmingModalClose}
                        setFileInfo={props.setBackgroundImageFileInfo}
                        targetFile={targetFile}
                        aspect={3 / 2}
                        shape="rect"
                    />
                </label>
                {props.backgroundImageFileInfo?.url == undefined &&
                    props.existingBackgroundImageObjectKey == "" &&
                    props.checked && (
                        <FormHelperText className={styles.helperText}>画像をアップロードしてください。</FormHelperText>
                    )}
            </div>
        </li>
    );
});
