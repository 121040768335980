import { memo, useCallback, useEffect, useState } from "react";

import styles from "../index.module.scss";
import { MessageResponse, PublicStudentResponse, PublicTeacherResponse } from "@/store/autogenApi";
import { FilesContents } from "../../FilesContents";

import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    message: MessageResponse;
    AMOrPM: string;
    createdAt: string;
    handleIconButtonClick: (target: PublicStudentResponse | PublicTeacherResponse) => void;
}

export const OtherMessage: React.VFC<Props> = memo(function NormalMessage(props) {
    const userMode = useSelector((state: RootState) => state.jwt.userMode);
    const [url, setUrl] = useState<string | undefined>(undefined);
    const [objectKey, setObjectKey] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (userMode === "student") {
            setUrl(props.message.teacher?.iconImageUrl);
            setObjectKey(props.message.teacher?.iconImageObjectKey);
        } else {
            setUrl(props.message.student?.iconImageUrl);
            setObjectKey(props.message.student?.iconImageObjectKey);
        }
    }, [userMode]);

    const getPersonalMeetingContent = useCallback(() => {
        const personalMeetingUrl = props.message.personalMeetingUrl;
        if (!personalMeetingUrl) return;
        const teacherNickname = props.message.teacher?.nickname;
        if (!teacherNickname) return;
        return (
            <div className={styles.invitation}>
                トピック: {teacherNickname}'s Personal Meeting Room
                <br />
                <br />
                Zoomミーティングに参加する
                <br />
                <a className={styles.meetingUrl} href={props.message.personalMeetingUrl}>
                    {props.message.personalMeetingUrl}
                </a>
            </div>
        );
    }, [props.message]);

    return (
        <li className={styles.messageItem} key={props.message.messageId}>
            <Button
                className={styles.avatarButton}
                onClick={() => {
                    props.handleIconButtonClick(
                        (props.message.student ?? props.message.teacher) as
                            | PublicStudentResponse
                            | PublicTeacherResponse,
                    );
                }}
            >
                <AvatarFromS3 url={url} objectKey={objectKey} className={styles.messageAvatar} />
            </Button>
            <div
                className={styles.otherWrapper}
                style={
                    props.message.messageType === "file"
                        ? {
                              width: "70%",
                              marginLeft: "12px",
                          }
                        : {}
                }
            >
                {props.message.messageType === "file" ? (
                    <FilesContents fileUrls={props.message.fileUrls} fileObjectKeys={props.message.fileObjectKeys} />
                ) : (
                    <div className={styles.otherBalloon}>
                        {props.message.messageType === "personalMeeting" && getPersonalMeetingContent()}
                        {props.message.messageType === "text" && (
                            <div className={styles.text}>{props.message.text}</div>
                        )}
                    </div>
                )}
                <div className={styles.otherTimeWrapper}>
                    <div className={styles.AMOrPM}>{props.AMOrPM}</div>
                    <div className={styles.createdAt}>{props.createdAt}</div>
                </div>
            </div>
        </li>
    );
});
