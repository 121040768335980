import { PublicRequestResponse } from "@/store/autogenApi";

export const getPrice = (publicRequest: PublicRequestResponse) => {
    return `¥${publicRequest?.minPrice}~¥${publicRequest?.maxPrice}`;
};

export const getCourseType = (publicRequest: PublicRequestResponse) => {
    if (publicRequest.courseType === "short") {
        return "単発・短期";
    } else {
        return "定期";
    }
};

export const getStatus = (publicRequest: PublicRequestResponse) => {
    const now = new Date();
    const deadline = new Date(publicRequest.applyingDeadline);
    if (now > deadline) {
        return "募集終了";
    } else {
        return "募集中";
    }
};

export const getTabInfoList = (publicRequests: PublicRequestResponse[]) => {
    let shortCount = 0;
    let regularCount = 0;
    publicRequests.forEach((request) => {
        if (request.courseType === "short") {
            shortCount++;
        } else {
            regularCount++;
        }
    });
    return [
        { value: "regular", label: `定期(${regularCount})` },
        { value: "short", label: `単発・短期(${shortCount})` },
    ];
};
