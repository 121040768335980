import { TextField } from "@material-ui/core";
import { memo } from "react";
import { Optional } from "@/components/Tag/Optional";

import styles from "@/pages/Teacher/IdentificationForStripe/index.module.scss";

interface Props {
    checked: boolean;
    line2: string;
    handleLine2Change: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const Line2: React.VFC<Props> = memo(function Line2(props) {
    return (
        <div className={styles.formItem} style={{ borderBottom: "none" }}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitle}>建物名・部屋番号</div>
                <Optional />
            </div>
            <div className={styles.rightWrapper}>
                <TextField
                    variant="outlined"
                    placeholder="〇〇ビル〇〇号室"
                    className={styles.textField}
                    value={props.line2}
                    onChange={props.handleLine2Change}
                />
            </div>
        </div>
    );
});
