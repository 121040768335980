import TextField from "@material-ui/core/TextField";
import { memo, useCallback, useEffect } from "react";
import styles from "./index.module.scss";
import { CourseType } from "@/store/autogenApi";
import { ListItem } from "../ListItem";
import { ErrorMessage } from "@/components/ErrorMessage";
import { PRICE_RANGE } from "..";

interface Props {
    isConfirmMode?: boolean;
    courseType: CourseType;
    howManyDays: number;
    isChecked: boolean;
    price: number;
    validation: boolean;
    existStudent?: boolean;
    handleChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
}

export const Price: React.VFC<Props> = memo(function Price(props) {
    const getUnit = useCallback(() => {
        if (props.courseType === "short") {
            return `1日程(${props.howManyDays}日間の授業)合計で`;
        } else if (props.courseType === "regular") {
            return "1時間あたり";
        } else {
            return "30分あたり";
        }
    }, [props.courseType, props.howManyDays]);

    return (
        <ListItem
            isConfirmMode={props.isConfirmMode}
            borderTop
            title={`価格(
            ${getUnit()})`}
            inputModeContents={
                <>
                    {props.existStudent ? (
                        <div className={styles.existStudentWrapper}>
                            <div className={styles.existStudent}>既に参加者がいるため、この項目は変更できません。</div>
                            <div className={styles.existingValue}>
                                {getUnit()}
                                :¥
                                {typeof props.price === "number" &&
                                    new Intl.NumberFormat("ja-JP").format(Math.floor(props.price))}
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className={styles.priceWrapper}>
                                <div className={styles.perHourWrapper}>
                                    {/* <div className={styles.textWrapper}>
                                    <div className={styles.priceTitle}>
                                        {props.courseType === "short"
                                            ? `1日程(${props.howManyDays}日間の授業)合計で`
                                            : "1時間あたり"}
                                    </div>
                                    <div className={styles.range}>（￥1000 ~ ￥100,000）</div>
                                </div> */}
                                    <div className={styles.price}>
                                        <span className={styles.yen}>￥</span>
                                        <TextField
                                            variant="outlined"
                                            required
                                            placeholder="0"
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            className={styles.textField}
                                            onChange={props.handleChange}
                                            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                                            type="number"
                                            value={props.price ? props.price : ""}
                                        />
                                        <div className={styles.limit}>{`(${new Intl.NumberFormat("ja-JP").format(
                                            Math.floor(PRICE_RANGE[0]),
                                        )}~${new Intl.NumberFormat("ja-JP").format(Math.floor(PRICE_RANGE[1]))})`}</div>
                                    </div>
                                </div>
                            </div>
                            <ErrorMessage
                                when={props.isChecked && !props.validation}
                                content={`この項目は必須です。${PRICE_RANGE[0]}以上${PRICE_RANGE[1]}
                                以下で選択してください。`}
                            />
                        </>
                    )}
                </>
            }
            confirmModeContents={
                <div className={styles.confirmModeFlexContents}>
                    <div>{getUnit()}</div>
                    <div>:</div>
                    <div>
                        ￥
                        {typeof props.price === "number" &&
                            new Intl.NumberFormat("ja-JP").format(Math.floor(props.price))}
                    </div>
                </div>
            }
        />
    );
});
