import styles from "./index.module.scss";

import { AiOutlineEdit, AiOutlinePlusCircle } from "react-icons/ai";
import { useCallback, useEffect, useState } from "react";
import { RegisterNewCreditCard } from "./RegisterNewCreditCard";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe, StripeElementsOptions } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import {
    useCreateSetUpIntentMutation,
    useDetachPaymentMethodMutation,
    useUpdateDefaultPaymentMethodMutation,
} from "@/store/hooks/stripe";
import { Button, Checkbox, CircularProgress, createTheme, MuiThemeProvider } from "@material-ui/core";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import VISA from "@/images/VISA.jpg";
import AMEX from "@/images/AMEX.jpg";
import Mastercard from "@/images/Mastercard.jpg";
import JCB from "@/images/JCB.jpg";
import DISCOVER from "@/images/DISCOVER.jpg";
import DinersClub from "@/images/DinersClub.jpg";
import { DeleteConfirmationModal } from "./DeleteConfirmationModal";
import { RootState } from "@/ducks";
import { PaymentMethod } from "@/store/autogenApi";
import { userConfig } from "@/pageComponents/Common/InfoInput/ProfileInput/Config";
import { useGetConfig } from "@/store/hooks/config";

interface Props {
    targetPaymentMethod: any | undefined;
    paymentMethods: any[];
    defaultPaymentMethod: PaymentMethod | null;
    handlePaymentMethodModalClose?: () => void;
    setTargetPaymentMethod: React.Dispatch<React.SetStateAction<any | undefined>>;
}

interface OtherPaymentMethod {
    title: string;
    type: string;
    fee: number;
}

const OTHER_PAYMENT_METHODS: OtherPaymentMethod[] = [];

export const PaymentSettingContents: React.VFC<Props> = (props) => {
    const [isRegisterMode, setIsRegisterMode] = useState<boolean>(false);
    const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isDetaching, setIsDetaching] = useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState<boolean>(false);
    const [newPaymentMethod, setNewPaymentMethod] = useState<any | undefined>(undefined);

    const [stripe, setStripe] = useState<Stripe>();

    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);
    const createSetUpIntent = useCreateSetUpIntentMutation();
    const detachPaymentMethod = useDetachPaymentMethodMutation();
    const [getConfig] = useGetConfig();
    const theme = createTheme({
        overrides: {
            MuiCheckbox: {
                root: {
                    "&:hover": {
                        backgroundColor: "#e1edfb",
                    },
                },
                colorSecondary: {
                    "&$checked": {
                        color: "#305077",
                    },
                    "&:hover": {
                        backgroundColor: "#e1edfb !important",
                    },
                },
            },
            MuiIconButton: {
                colorSecondary: {
                    color: "#305077",
                },
            },
        },
    });

    useEffect(() => {
        setNewPaymentMethod(props.targetPaymentMethod);
    }, [props.targetPaymentMethod]);

    useEffect(() => {
        (async () => {
            const config = await getConfig();
            setStripe(await loadStripe(config.data.stripe.stripePublishableKey));
            const createSetUpIntentState = await createSetUpIntent({
                createSetUpIntentRequestBody: {
                    studentId: studentId,
                },
            });
            if (createSetUpIntentState.isSuccess) {
                setClientSecret(createSetUpIntentState.data ?? undefined);
            }
        })();
    }, []);
    const handleBackButtonClick = () => {
        setIsRegisterMode(false);
    };
    const handleRegisterNewCreditCardButtonClick = () => {
        setIsRegisterMode(true);
    };

    const handleEditButtonClick = useCallback(() => {
        setIsEditMode(!isEditMode);
    }, [isEditMode]);

    const getSrc = (brand: string) => {
        if (brand === "visa") return VISA;
        if (brand === "amex") return AMEX;
        if (brand === "mastercard") return Mastercard;
        if (brand === "jcb") return JCB;
        if (brand === "discover") return DISCOVER;
        if (brand === "diners") return DinersClub;
    };

    const handleCreditCardInfoButtonClick = useCallback((paymentMethod: any) => {
        setNewPaymentMethod(paymentMethod);
    }, []);

    const handleOtherPaymentMethodButtonClick = useCallback((paymentMethod: any) => {
        setNewPaymentMethod(paymentMethod);
    }, []);

    const handleUpdateButtonClick = useCallback(async () => {
        if (newPaymentMethod == undefined) return;
        if (newPaymentMethod.type === "card") {
            props.setTargetPaymentMethod(newPaymentMethod);
            props.handlePaymentMethodModalClose && props.handlePaymentMethodModalClose();
        }
    }, [newPaymentMethod]);

    const handleDeleteConfirmModalOpen = useCallback(() => {
        setDeleteConfirmModalOpen(true);
    }, []);

    const handleDeleteConfirmModalClose = useCallback(() => {
        setDeleteConfirmModalOpen(false);
    }, []);

    const handleDeleteConfirmButtonClick = useCallback(async (paymentMethodId: string) => {
        setIsDetaching(true);
        await detachPaymentMethod({
            paymentMethodId: paymentMethodId,
        });
        setIsDetaching(false);
        setDeleteConfirmModalOpen(false);
        props.handlePaymentMethodModalClose && props.handlePaymentMethodModalClose();
    }, []);

    const appearance = {
        theme: "stripe",
    };
    const options = {
        clientSecret,
        appearance,
        locale: "ja",
    } as StripeElementsOptions;
    return (
        <div className={styles.paymentSettingWrapper}>
            {isRegisterMode ? (
                <Elements stripe={stripe} options={options}>
                    <RegisterNewCreditCard studentId={studentId} handleBackButtonClick={handleBackButtonClick} />
                </Elements>
            ) : (
                <div className={styles.paymentMainWrapper}>
                    {props.paymentMethods.length > 0 && (
                        <button className={styles.editButton} onClick={handleEditButtonClick}>
                            {isEditMode ? (
                                <div className={styles.edit}>完了</div>
                            ) : (
                                <>
                                    <AiOutlineEdit className={styles.editIcon} />
                                    <div className={styles.edit}>編集する</div>
                                </>
                            )}
                        </button>
                    )}
                    <div className={styles.paymentSettingTitle}>支払い方法</div>
                    <div className={styles.creditCardWrapper}>
                        {props.paymentMethods.filter((paymentMethod) => paymentMethod.type === "card").length === 0 ? (
                            <div className={styles.emptyCreditCardInfo}></div>
                        ) : (
                            <ul className={styles.creditCardInfoList}>
                                {props.paymentMethods
                                    .filter((paymentMethod) => paymentMethod.type === "card")
                                    .map((cardInfo) => (
                                        <li key={cardInfo.id} className={styles.creditCardInfo}>
                                            {isEditMode ? (
                                                <div
                                                    className={styles.creditCardInfoButton}
                                                    onClick={() => {
                                                        handleCreditCardInfoButtonClick(cardInfo);
                                                    }}
                                                >
                                                    <div className={styles.cardInfoBody}>
                                                        <img
                                                            src={getSrc(cardInfo.card.brand)}
                                                            alt=""
                                                            className={styles.brandImage}
                                                        />
                                                        <div className={styles.rightWrapper}>
                                                            <div className={styles.creditCardTitle}>
                                                                クレジットカード
                                                            </div>
                                                            <div
                                                                className={styles.cardNumber}
                                                            >{`**** **** **** ${cardInfo.card.last4}`}</div>
                                                            <div className={styles.exp}>
                                                                {cardInfo.card.exp_month}/{cardInfo.card.exp_year}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Button
                                                        className={styles.deleteButton}
                                                        onClick={handleDeleteConfirmModalOpen}
                                                    >
                                                        削除
                                                    </Button>
                                                    <DeleteConfirmationModal
                                                        modalOpen={deleteConfirmModalOpen}
                                                        isDetaching={isDetaching}
                                                        handleClose={handleDeleteConfirmModalClose}
                                                        handleDeleteConfirmButtonClick={() => {
                                                            handleDeleteConfirmButtonClick(cardInfo.id);
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <button
                                                    className={`${styles.creditCardInfoButton} ${styles.hoverTransparent}`}
                                                    onClick={() => {
                                                        handleCreditCardInfoButtonClick(cardInfo);
                                                    }}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <MuiThemeProvider theme={theme}>
                                                        <Checkbox
                                                            icon={<MdRadioButtonUnchecked />}
                                                            checkedIcon={<MdRadioButtonChecked />}
                                                            checked={
                                                                newPaymentMethod != null &&
                                                                newPaymentMethod?.type === "card" &&
                                                                newPaymentMethod?.id === cardInfo.id
                                                            }
                                                        />
                                                    </MuiThemeProvider>
                                                    <div className={styles.cardInfoBody}>
                                                        <img
                                                            src={getSrc(cardInfo.card.brand)}
                                                            alt=""
                                                            className={styles.brandImage}
                                                        />
                                                        <div className={styles.rightWrapper}>
                                                            <div className={styles.creditCardTitle}>
                                                                クレジットカード
                                                            </div>
                                                            <div
                                                                className={styles.cardNumber}
                                                            >{`**** **** **** ${cardInfo.card.last4}`}</div>
                                                            <div className={styles.exp}>
                                                                {cardInfo.card.exp_month}/{cardInfo.card.exp_year}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </button>
                                            )}
                                        </li>
                                    ))}
                            </ul>
                        )}
                        {!isEditMode && (
                            <button
                                onClick={handleRegisterNewCreditCardButtonClick}
                                className={styles.registerNewCreditCardButton}
                                style={props.paymentMethods.length === 0 ? { marginTop: "0" } : { marginTop: "10px" }}
                            >
                                <AiOutlinePlusCircle className={styles.plusIcon} />
                                <div className={styles.plus}>新しいクレジットカードを登録する</div>
                            </button>
                        )}
                    </div>
                    {!isEditMode && OTHER_PAYMENT_METHODS.length > 0 && (
                        <div className={styles.otherPaymentMethodWrapper}>
                            <ul className={styles.otherPaymentMethodList}>
                                {OTHER_PAYMENT_METHODS.map((paymentMethod) => (
                                    <li key={paymentMethod.title} className={styles.otherPaymentMethod}>
                                        <button
                                            className={`${styles.otherPaymentMethodButton} ${styles.hoverTransparent}`}
                                            onClick={() => {
                                                handleOtherPaymentMethodButtonClick(paymentMethod);
                                            }}
                                            style={{
                                                cursor: "pointer",
                                            }}
                                        >
                                            <MuiThemeProvider theme={theme}>
                                                <Checkbox
                                                    icon={<MdRadioButtonUnchecked />}
                                                    checkedIcon={<MdRadioButtonChecked />}
                                                    checked={paymentMethod.type === newPaymentMethod?.type}
                                                />
                                            </MuiThemeProvider>
                                            <div className={styles.paymentMethodBody}>
                                                <div className={styles.rightWrapper}>
                                                    <div className={styles.paymentMethodTitle}>
                                                        {paymentMethod.title}
                                                    </div>
                                                    <div
                                                        className={styles.paymentMethodFee}
                                                    >{`手数料:¥${new Intl.NumberFormat("ja-JP").format(
                                                        paymentMethod.fee,
                                                    )}`}</div>
                                                </div>
                                            </div>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {!isEditMode && (
                        <Button className={styles.updateButton} onClick={handleUpdateButtonClick}>
                            {isUpdating ? (
                                <CircularProgress className={styles.isUpdating} />
                            ) : (
                                <div className={styles.update}>決定</div>
                            )}
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};
