import { AiFillCalculator, AiOutlinePercentage, AiOutlineRead } from "react-icons/ai";
import { BiNetworkChart } from "react-icons/bi";
import { BiCube } from "react-icons/bi";
import {
    BsArrowUpRight,
    BsBriefcaseFill,
    BsCircle,
    BsQuestionCircleFill,
    BsSoundwave,
    BsThreeDots,
    BsTriangle,
} from "react-icons/bs";
import { CgBorderStyleDashed, CgCodeClimate } from "react-icons/cg";
import { FaExchangeAlt, FaLevelUpAlt, FaStarOfLife } from "react-icons/fa";
import {
    GiAncientRuins,
    GiAtom,
    GiBirdTwitter,
    GiBlackBook,
    GiBlackHoleBolas,
    GiChemicalDrop,
    GiChemicalTank,
    GiCrossedAirFlows,
    GiCrown,
    GiCycle,
    GiDna2,
    GiEarthAsiaOceania,
    GiElectricalResistance,
    GiEruption,
    GiFallDown,
    GiFallingBlob,
    GiIceCube,
    GiInfinity,
    GiIronHulledWarship,
    GiJapan,
    GiKimono,
    GiMagnet,
    GiModernCity,
    GiMolecule,
    GiMuscleUp,
    GiMountainRoad,
    GiPillDrop,
    GiPocketBow,
    GiProgression,
    GiSouthAmerica,
    GiSpeaker,
    GiSpring,
    GiStoneSpear,
    GiVote,
    GiTrail,
} from "react-icons/gi";
import { GoNote } from "react-icons/go";
import { ImMan } from "react-icons/im";
import { MdHearing, MdPeople, MdTerrain } from "react-icons/md";
import { RiAncientGateLine, RiComputerFill, RiEnglishInput } from "react-icons/ri";
import { TiWaves } from "react-icons/ti";
import { VscCaseSensitive } from "react-icons/vsc";
import { WiTrain } from "react-icons/wi";

import styles from "./icon.module.scss";

export type SubjectConfig = {
    [subject: string]: {
        label: string;
        sub?: {
            [subject: string]: {
                label: string;
                icon: JSX.Element;
            };
        };
        icon: JSX.Element;
    };
};

export const UNIVERSITY_SUBJECT_CONFIG: SubjectConfig = {
    career: {
        label: "キャリア・就活",
        icon: <BsBriefcaseFill className={styles.icon} />,
    },
    english: {
        label: "英語",
        sub: {
            conversation: {
                label: "英会話",
                icon: <BsSoundwave className={styles.icon} />,
            },
            TOEIC: {
                label: "TOEIC",
                icon: <RiEnglishInput className={styles.icon} />,
            },
            TOEFL: {
                label: "TOEFL",
                icon: <RiEnglishInput className={styles.icon} />,
            },
            EIKEN: {
                label: "英検",
                icon: <RiEnglishInput className={styles.icon} />,
            },
            other: {
                label: "その他",
                icon: <BsThreeDots className={styles.icon} />,
            },
        },
        icon: <VscCaseSensitive className={styles.icon} />,
    },
    programming: {
        label: "プログラミング",
        icon: <RiComputerFill className={styles.icon} />,
    },
    other: {
        label: "その他",
        icon: <BsQuestionCircleFill className={styles.icon} />,
    },
};

export const HIGH_SCHOOL_SUBJECT_CONFIG: SubjectConfig = {
    japanese: {
        label: "国語",
        sub: {
            modern: {
                label: "現代文",
                icon: <GiModernCity className={styles.icon} />,
            },
            classic: {
                label: "古文",
                icon: <GiKimono className={styles.icon} />,
            },
            chinese: {
                label: "漢文",
                icon: <RiAncientGateLine className={styles.icon} />,
            },
        },
        icon: <GiBlackBook className={styles.icon} />,
    },
    math: {
        label: "数学",
        sub: {
            math1: {
                label: "数学Ⅰ",
                icon: <BsTriangle className={styles.icon} />,
            },
            mathA: {
                label: "数学A",
                icon: <AiOutlinePercentage className={styles.icon} />,
            },
            math2: {
                label: "数学Ⅱ",
                icon: <BsCircle className={styles.icon} />,
            },
            mathB: {
                label: "数学B",
                icon: <BsArrowUpRight className={styles.icon} />,
            },
            math3: {
                label: "数学Ⅲ",
                icon: <GiInfinity className={styles.icon} />,
            },
        },
        icon: <AiFillCalculator className={styles.icon} />,
    },
    english: {
        label: "英語",
        sub: {
            grammar: {
                label: "英文法",
                icon: <CgBorderStyleDashed className={styles.icon} />,
            },
            reading: {
                label: "長文読解",
                icon: <AiOutlineRead className={styles.icon} />,
            },
            speaking: {
                label: "英会話",
                icon: <GiSpeaker className={styles.icon} />,
            },
        },
        icon: <VscCaseSensitive className={styles.icon} />,
    },
    worldHistory: {
        label: "世界史",
        sub: {
            primitive: {
                label: "原始",
                icon: <GiStoneSpear className={styles.icon} />,
            },
            ancient: {
                label: "古代",
                icon: <GiAncientRuins className={styles.icon} />,
            },
            middle: {
                label: "中世",
                icon: <GiCrown className={styles.icon} />,
            },
            earlyModern: {
                label: "近世",
                icon: <WiTrain className={styles.icon} />,
            },
            modern: {
                label: "近代",
                icon: <GiModernCity className={styles.icon} />,
            },
        },
        icon: <GiIronHulledWarship className={styles.icon} />,
    },
    japaneseHistory: {
        label: "日本史",
        sub: {
            japanesePrimitive: {
                label: "原始",
                icon: <GiStoneSpear className={styles.icon} />,
            },
            japaneseAncient: {
                label: "古代",
                icon: <GiKimono className={styles.icon} />,
            },
            japaneseMiddle: {
                label: "中世",
                icon: <GiPocketBow className={styles.icon} />,
            },
            japaneseEarlyModern: {
                label: "近世",
                icon: <WiTrain className={styles.icon} />,
            },
            japaneseModern: {
                label: "近代",
                icon: <GiModernCity className={styles.icon} />,
            },
        },
        icon: <GiJapan className={styles.icon} />,
    },
    geography: {
        label: "地理",
        sub: {
            systematic: {
                label: "系統地理",
                icon: <CgCodeClimate className={styles.icon} />,
            },
            chorography: {
                label: "地誌",
                icon: <GiSouthAmerica className={styles.icon} />,
            },
        },
        icon: <GiMountainRoad className={styles.icon} />,
    },
    basicPhysics: {
        label: "物理基礎",
        sub: {
            forceAndMotion: {
                label: "力と運動",
                icon: <GiFallDown className={styles.icon} />,
            },
            energy: {
                label: "エネルギー",
                icon: <GiSpring className={styles.icon} />,
            },
            wave: {
                label: "波動",
                icon: <TiWaves className={styles.icon} />,
            },
            electricity: {
                label: "電気",
                icon: <GiElectricalResistance className={styles.icon} />,
            },
        },
        icon: <GiFallingBlob className={styles.icon} />,
    },
    physics: {
        label: "物理",
        sub: {
            dynamics: {
                label: "力学",
                icon: <GiFallDown className={styles.icon} />,
            },
            thermodynamics: {
                label: "熱力学",
                icon: <BiCube className={styles.icon} />,
            },
            wave: {
                label: "波動",
                icon: <TiWaves className={styles.icon} />,
            },
            electromagnetism: {
                label: "電磁気学",
                icon: <GiMagnet className={styles.icon} />,
            },
            quantumMechanics: {
                label: "原子",
                icon: <GiAtom className={styles.icon} />,
            },
        },
        icon: <GiFallingBlob className={styles.icon} />,
    },
    basicChemistry: {
        label: "化学基礎",
        sub: {
            humanLife: {
                label: "化学と人間生活",
                icon: <GiChemicalTank className={styles.icon} />,
            },
            composition: {
                label: "物質の構成",
                icon: <GiIceCube className={styles.icon} />,
            },
            change: {
                label: "物質の変化",
                icon: <FaExchangeAlt className={styles.icon} />,
            },
        },
        icon: <GiChemicalDrop className={styles.icon} />,
    },
    chemistry: {
        label: "化学",
        sub: {
            theoretical: {
                label: "理論化学",
                icon: <GoNote className={styles.icon} />,
            },
            inorganic: {
                label: "無機化学",
                icon: <GiPillDrop className={styles.icon} />,
            },
            organic: {
                label: "有機化学",
                icon: <GiMolecule className={styles.icon} />,
            },
        },
        icon: <GiChemicalDrop className={styles.icon} />,
    },
    basicBiology: {
        label: "生物基礎",
        sub: {
            livingThingsAndGenetics: {
                label: "生物と遺伝子",
                icon: <GiDna2 className={styles.icon} />,
            },
            bodyRegulation: {
                label: "ヒトの体の調節",
                icon: <GiMuscleUp className={styles.icon} />,
            },
            biodiversityAndEcosystems: {
                label: "生物の多様性と生態系",
                icon: <GiCycle className={styles.icon} />,
            },
        },
        icon: <ImMan className={styles.icon} />,
    },
    biology: {
        label: "生物",
        sub: {
            evolution: {
                label: "生物の進化",
                icon: <GiProgression className={styles.icon} />,
            },
            lifePhenomenaAndMaterials: {
                label: "生命現象と物質",
                icon: <FaStarOfLife className={styles.icon} />,
            },
            geneticInformation: {
                label: "遺伝情報の発現と発生",
                icon: <GiDna2 className={styles.icon} />,
            },
            responseToEnvironment: {
                label: "生物の環境応答",
                icon: <FaLevelUpAlt className={styles.icon} />,
            },
            ecologyAndEnvironment: {
                label: "生態と環境",
                icon: <GiBirdTwitter className={styles.icon} />,
            },
        },
        icon: <ImMan className={styles.icon} />,
    },
    basicEarthScience: {
        label: "地学基礎",
        sub: {
            appearance: {
                label: "地球のすがた",
                icon: <MdTerrain className={styles.icon} />,
            },
            change: {
                label: "変動する地球",
                icon: <FaExchangeAlt className={styles.icon} />,
            },
        },
        icon: <GiEarthAsiaOceania className={styles.icon} />,
    },
    earthScience: {
        label: "地学",
        sub: {
            appearance: {
                label: "地球の概観",
                icon: <MdTerrain className={styles.icon} />,
            },
            activityAndHistory: {
                label: "地球の活動と歴史",
                icon: <GiEruption className={styles.icon} />,
            },
            atmosphereAndOcean: {
                label: "地球の大気と海洋",
                icon: <GiCrossedAirFlows className={styles.icon} />,
            },
            structure: {
                label: "宇宙の構造",
                icon: <GiBlackHoleBolas className={styles.icon} />,
            },
        },
        icon: <GiEarthAsiaOceania className={styles.icon} />,
    },
    programming: {
        label: "プログラミング",
        icon: <RiComputerFill className={styles.icon} />,
    },
    futureCourse: {
        label: "進路",
        icon: <GiTrail className={styles.icon} />,
    },
    other: {
        label: "その他",
        icon: <BsQuestionCircleFill className={styles.icon} />,
    },
};

export const JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG: SubjectConfig = {
    japanese: {
        label: "国語",
        sub: {
            modern: {
                label: "現代文",
                icon: <GiModernCity className={styles.icon} />,
            },
            classic: {
                label: "古典",
                icon: <GiKimono className={styles.icon} />,
            },
            chinese: {
                label: "漢文",
                icon: <RiAncientGateLine className={styles.icon} />,
            },
        },
        icon: <GiBlackBook className={styles.icon} />,
    },
    math: {
        label: "数学",
        icon: <AiFillCalculator className={styles.icon} />,
    },
    english: {
        label: "英語",
        sub: {
            grammar: {
                label: "英文法",
                icon: <CgBorderStyleDashed className={styles.icon} />,
            },
            reading: {
                label: "長文読解",
                icon: <AiOutlineRead className={styles.icon} />,
            },
            speaking: {
                label: "英会話",
                icon: <GiSpeaker className={styles.icon} />,
            },
            listening: {
                label: "リスニング",
                icon: <MdHearing className={styles.icon} />,
            },
        },
        icon: <VscCaseSensitive className={styles.icon} />,
    },
    society: {
        label: "社会",
        sub: {
            geography: {
                label: "地理",
                icon: <GiMountainRoad className={styles.icon} />,
            },
            history: {
                label: "歴史",
                icon: <GiIronHulledWarship className={styles.icon} />,
            },
            citizens: {
                label: "公民",
                icon: <GiVote className={styles.icon} />,
            },
        },
        icon: <BiNetworkChart className={styles.icon} />,
    },
    science: {
        label: "理科",
        sub: {
            physics: {
                label: "物理",
                icon: <GiFallingBlob className={styles.icon} />,
            },
            chemistry: {
                label: "化学",
                icon: <GiChemicalDrop className={styles.icon} />,
            },
            biology: {
                label: "生物",
                icon: <ImMan className={styles.icon} />,
            },
            earthScience: {
                label: "地学",
                icon: <GiEarthAsiaOceania className={styles.icon} />,
            },
        },
        icon: <GiChemicalDrop className={styles.icon} />,
    },
    programming: {
        label: "プログラミング",
        icon: <RiComputerFill className={styles.icon} />,
    },
    futureCourse: {
        label: "進路",
        icon: <GiTrail className={styles.icon} />,
    },
    other: {
        label: "その他",
        icon: <BsQuestionCircleFill className={styles.icon} />,
    },
};

export const ELEMENTARY_SCHOOL_SUBJECT_CONFIG: SubjectConfig = {
    japanese: {
        label: "国語",
        icon: <GiBlackBook className={styles.icon} />,
    },
    math: {
        label: "算数",
        icon: <AiFillCalculator className={styles.icon} />,
    },
    english: {
        label: "英語",
        icon: <VscCaseSensitive className={styles.icon} />,
    },
    society: {
        label: "社会",
        icon: <BiNetworkChart className={styles.icon} />,
    },
    science: {
        label: "理科",
        icon: <GiChemicalDrop className={styles.icon} />,
    },
    programming: {
        label: "プログラミング",
        icon: <RiComputerFill className={styles.icon} />,
    },
    futureCourse: {
        label: "進路",
        icon: <GiTrail className={styles.icon} />,
    },
    other: {
        label: "その他",
        icon: <BsQuestionCircleFill className={styles.icon} />,
    },
};
