import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { UseMutationOptions } from "./utils/types";

export const useCreatePrivateRequestMutation = (options?: UseMutationOptions) => {
    const [createPrivateRequest] = defaultApi.endpoints.createPrivateRequest.useMutation();
    return useMutationFunctionWrapper(createPrivateRequest, options?.errorMessages);
};

export const useApprovePrivateRequestMutation = (options?: UseMutationOptions) => {
    const [approvePrivateRequest] = defaultApi.endpoints.approvePrivateRequest.useMutation();
    return useMutationFunctionWrapper(approvePrivateRequest, options?.errorMessages);
};

export const useDisapprovePrivateRequestMutation = (options?: UseMutationOptions) => {
    const [disapprovePrivateRequest] = defaultApi.endpoints.disapprovePrivateRequest.useMutation();
    return useMutationFunctionWrapper(disapprovePrivateRequest, options?.errorMessages);
};

export const useRejectPayingForCourseMutation = (options?: UseMutationOptions) => {
    const [rejectPayingForCourse] = defaultApi.endpoints.rejectPayingForCourse.useMutation();
    return useMutationFunctionWrapper(rejectPayingForCourse, options?.errorMessages);
};

export const useGetPrivateRequestsQuery = (studentId: string) => {
    return defaultApi.endpoints.getPrivateRequests.useQuery({ studentId });
};
