import { OverWrapper } from "@/components/OverWrapper";
import styles from "@/pages/Common/LP/index.module.scss";
import shockImage from "../img/shock.png";

export const Background: React.VFC = () => {
    return (
        <OverWrapper
            style={{ backgroundColor: "#e7f3ff", borderTop: "1px solid #999", borderBottom: "1px solid #999" }}
        >
            <div className={styles.backgroundWrapper}>
                <div className={styles.background}>
                    <div className={styles.backgroundTitle}>Trailに込められた思い</div>
                    <div className={styles.messages}>
                        多くの方々に支えられてリリースされた、【学び】のフリマアプリ Trail ですが、その背景にあったのは
                        <span className={styles.strong}>2人の大学生の存在</span>でした。
                        <br />
                        <br />
                        <div className={styles.shockImageWrapper}>
                            <img src={shockImage} className={styles.shockImage} />
                            <div className={styles.shockImageDescription}>
                                塾講師のバイトをしていた心優しいAくん。彼は生徒さんの役に立てることに嬉しさを感じる一方で、
                                <span className={styles.strong}>
                                    生徒に対して不誠実な同僚や塾長との関係に悩まされて
                                </span>
                                いました。
                                <br />
                                <br />
                                さらに、授業時間外の雑務が多いことに加えて、塾には実質手数料として半額以上を引かれていました。
                                <br />
                                <br />
                                一方、大学から少し離れた地元に住むBくんは、近所の子に直接勉強を教えていました。
                                嫌な人間関係もなく、
                                <span className={styles.strong}>塾を介していないため給与も高い</span>
                                という、Aくんとは真逆の状態でした。
                            </div>
                        </div>
                        <br />
                        <br />
                        これらのことから、私たちは思いました。
                        <span className={styles.strong}>Bくんのような自由な働き方</span>ができるようになるべきだ、と。
                        <br />
                        <br />
                        しかしながら、そのように直接生徒さんと繋がることは現実には難しい...
                        <br />
                        であるならば、そのような環境を創出しよう。
                        <br />
                        そして、単に繋がれるだけではなく、
                        <span className={styles.strong}>先生が実績を積み重ね、個人として輝ける場所</span>にしよう。
                        <br />
                        そういう思いから生まれたのがこの「Trail」なのです。
                        <br />
                        <br />
                        開発には2年以上の時間を要しましたが、その間に多くの方々に支えていただき、ようやくリリースに至ることができました。
                        その時間を賭してまで開発を続けられた理由は、
                        <span className={styles.strong}>
                            Trailによって先生にとって最適な環境を提供することができると本気で信じている
                        </span>
                        からです。 勉強を教えることで稼ぎたいならぜひ一度で良いのでTrailを試してみてください。
                    </div>
                </div>
            </div>
        </OverWrapper>
    );
};
