import React, { JSXElementConstructor, ReactElement, memo, useCallback, useEffect, useState } from "react";

import { Hidden } from "@material-ui/core";

import { MenuBar } from "@/pageComponents/Teacher/MyPage/MenuBar";

import styles from "./index.module.scss";

interface Props {
    title: string;
    activeTab: string;
    setUpdated?: (value: boolean) => void;
    setEdit?: (value: boolean) => void;
    children: ReactElement<any, string | JSXElementConstructor<any>>;
}

export const MyPage: React.VFC<Props> = memo(function MyPage(props) {
    const [currentMyPageBodyEl, setMyPageBodyEl] = useState<HTMLElement | null>(null);
    const [myPageHeight, setMyPageHeight] = useState<number>(0);

    const myPageBodyRef = useCallback((headerEl: HTMLElement | null) => {
        setMyPageBodyEl(headerEl);
    }, []);

    const changeMyPageBodyHeight = useCallback(() => {
        const body = document.getElementById("body");
        if (!body || !currentMyPageBodyEl) return;
        const bodyMinHeight = parseInt(body.style.minHeight);
        // 調整用
        const delta = 50;
        setMyPageHeight(bodyMinHeight - delta);
    }, [currentMyPageBodyEl]);

    useEffect(() => {
        changeMyPageBodyHeight();
        window.addEventListener("resize", changeMyPageBodyHeight);
        return () => {
            window.removeEventListener("resize", changeMyPageBodyHeight);
        };
    }, [changeMyPageBodyHeight]);

    const cloneChildren = React.cloneElement(props.children, {
        style: {
            height: `500px`,
        },
    });

    const isInChats = props.activeTab === "chats";

    const isInCardPage = ["favorite", "history", "myDraftCourses", "myCourses", "myProposals"].includes(
        props.activeTab,
    );

    return (
        <div className={styles.myPageBody} ref={myPageBodyRef} style={{ minHeight: `${myPageHeight}px` }}>
            <div className={styles.header}>
                <div className={styles.title}>{props.title}</div>
            </div>
            <div className={styles.contentsWrapper}>
                <Hidden smDown>
                    <MenuBar
                        fontSize="medium"
                        activeTab={props.activeTab}
                        setUpdated={props.setUpdated}
                        setEdit={props.setEdit}
                    />
                </Hidden>
                <div
                    className={styles.mainContents}
                    style={isInCardPage ? { backgroundColor: "transparent", boxShadow: "none" } : {}}
                >
                    {isInChats ? cloneChildren : props.children}
                </div>
            </div>
            <Hidden mdUp>
                <MenuBar
                    fontSize="small"
                    activeTab={props.activeTab}
                    setUpdated={props.setUpdated}
                    setEdit={props.setEdit}
                />
            </Hidden>
        </div>
    );
});
