import { memo, useCallback, useEffect, useState } from "react";

import { Button } from "@material-ui/core";
import { useHistory, useLocation } from "react-router";

import { Navigation } from "@/components/Navigation";
import { SearchFAQ } from "@/components/SearchFAQ";
import { REGISTRATION_REQUIRED_CONFIGS } from "@/pageComponents/Common/FAQDetails/AboutTrail/RegistrationRequired/RegistrationRequiredConfigs";
import { RuleOrGuideline } from "@/pageComponents/Common/FAQDetails/RuleOfTrail/RuleOrGuideline";
import { RULE_OR_GUIDELINE_CONFIGS } from "@/pageComponents/Common/FAQDetails/RuleOfTrail/RuleOrGuideline/RuleOrGuidelineConfigs";
import { ProhibitedAction } from "@/pageComponents/Common/FAQDetails/RuleOfTrail/ProhibitedAction";
import { PROHIBITED_ACTION_CONFIGS } from "@/pageComponents/Common/FAQDetails/RuleOfTrail/ProhibitedAction/ProhibitedActionConfigs";
import { ABOUT_REVIEW_CONFIGS } from "@/pageComponents/Common/FAQDetails/RuleOfTrail/AboutReview/AboutReviewConfigs";
import { EmailNotArrived } from "@/pageComponents/Common/FAQDetails/AboutSignUp/EmailNotArrived";
import { EMAIL_NOT_ARRIVED_CONFIGS } from "@/pageComponents/Common/FAQDetails/AboutSignUp/EmailNotArrived/EmailNotArrivedConfigs";
import { LoginFailed } from "@/pageComponents/Common/FAQDetails/AboutLogin/LoginFailed";
import { LOGIN_FAILED_CONFIGS } from "@/pageComponents/Common/FAQDetails/AboutLogin/LoginFailed/LoginFailed";
import { ForgotPassword } from "@/pageComponents/Common/FAQDetails/AboutLogin/ForgotPassword";
import { FORGOT_PASSWORD_CONFIGS } from "@/pageComponents/Common/FAQDetails/AboutLogin/ForgotPassword/ForgotPassword";
import { Receipt } from "@/pageComponents/Common/FAQDetails/AboutPayment/Receipt";
import { RECEIPT_CONFIGS } from "@/pageComponents/Common/FAQDetails/AboutPayment/Receipt/Receipt";
import { CANCEL_COURSE_CONFIGS } from "@/pageComponents/Common/FAQDetails/AboutPayment/CancelCourse/CancelCourse";
import { CancelCourse } from "@/pageComponents/Common/FAQDetails/AboutPayment/CancelCourse";
import { Refund } from "@/pageComponents/Common/FAQDetails/AboutPayment/Refund";
import { REFUND_CONFIGS } from "@/pageComponents/Common/FAQDetails/AboutPayment/Refund/Refund";
import { CreditCard } from "@/pageComponents/Common/FAQDetails/AboutPayment/CreditCard";
import { CREDIT_CARD_CONFIGS } from "@/pageComponents/Common/FAQDetails/AboutPayment/CreditCard/CreditCard";
import { Tax } from "@/pageComponents/Common/FAQDetails/AboutPayment/Tax";
import { TAX_CONFIGS } from "@/pageComponents/Common/FAQDetails/AboutPayment/Tax/tax";
import { Message } from "@/pageComponents/Common/FAQDetails/AboutOperation/Message";
import { MESSAGE_CONFIGS } from "@/pageComponents/Common/FAQDetails/AboutOperation/Message/Message";
import { Profile } from "@/pageComponents/Common/FAQDetails/AboutOperation/Profile";
import { PROFILE_CONFIGS } from "@/pageComponents/Common/FAQDetails/AboutOperation/Profile/Profile";
import { Caution } from "@/pageComponents/Common/FAQDetails/AboutCourse/Caution";
import { CAUTION_CONFIGS } from "@/pageComponents/Common/FAQDetails/AboutCourse/Caution/Caution";
import { CourseType } from "@/pageComponents/Common/FAQDetails/AboutCourse/CourseType";
import { COURSE_TYPE_CONFIGS } from "@/pageComponents/Common/FAQDetails/AboutCourse/CourseType/CourseType";
import { CHANGE_COURSE_FEE_CONFIGS } from "@/pageComponents/Common/FAQDetails/AboutCourseCreation/ChangeCourseFee/ChangeCourseFee";
import { TaxReturn } from "@/pageComponents/Common/FAQDetails/AboutTax/TaxReturn";
import { TAX_RETURN_CONFIGS } from "@/pageComponents/Common/FAQDetails/AboutTax/TaxReturn/TaxReturn";
import { Contact } from "@/pageComponents/Common/FAQDetails/AboutOther/Contact";
import { CONTACT_CONFIGS } from "@/pageComponents/Common/FAQDetails/AboutOther/Contact/Contact";
import { Withdrawal } from "@/pageComponents/Common/FAQDetails/AboutOther/Withdrawal";
import { WITHDRAWAL_CONFIGS } from "@/pageComponents/Common/FAQDetails/AboutOther/Withdrawal/Withdrawal";
import { RegistrationRequired } from "@/pageComponents/Common/FAQDetails/AboutTrail/RegistrationRequired";
import { ServiceOfTrail } from "@/pageComponents/Common/FAQDetails/AboutTrail/ServiceOfTrail";
import { SERVICE_OF_TRAIL_CONFIGS } from "@/pageComponents/Common/FAQDetails/AboutTrail/ServiceOfTrail/ServiceOfTrailConfigs";
import { SupportTime } from "@/pageComponents/Common/FAQDetails/AboutTrail/SupportTime";
import { SUPPORT_TIME_CONFIGS } from "@/pageComponents/Common/FAQDetails/AboutTrail/SupportTime/SupportTimeConfigs";
import { WhatToLearn } from "@/pageComponents/Common/FAQDetails/AboutTrail/WhatToLearn";
import { WHAT_TO_LEARN_CONFIGS } from "@/pageComponents/Common/FAQDetails/AboutTrail/WhatToLearn/WhatToLearnConfigs";
import { ReleaseInfo202404 } from "@/pageComponents/Common/FAQDetails/ReleaseInfo/ReleaseInfo202404";
import { RELEASE_INFO_202210_CONFIGS } from "@/pageComponents/Common/FAQDetails/ReleaseInfo/ReleaseInfo202404/ReleaseInfo202404";
import { FavoriteCourse } from "@/pageComponents/Common/FAQDetails/StudentGuideline/FavoriteCourse";
import { FAVORITE_COURSE_CONFIGS } from "@/pageComponents/Common/FAQDetails/StudentGuideline/FavoriteCourse/FavoriteCourseConfigs";
import { PostReview } from "@/pageComponents/Common/FAQDetails/StudentGuideline/PostReview";
import { POST_REVIEW_CONFIGS } from "@/pageComponents/Common/FAQDetails/StudentGuideline/PostReview/PostReviewConfigs";
import { SignUpAsStudent } from "@/pageComponents/Common/FAQDetails/StudentGuideline/SignUpAsStudent";
import { REGISTER_AS_STUDENT_CONFIGS } from "@/pageComponents/Common/FAQDetails/StudentGuideline/SignUpAsStudent/SignUpAsStudentConfigs";
import { ReserveCourse } from "@/pageComponents/Common/FAQDetails/StudentGuideline/ReserveCourse";
import { RESERVE_COURSE_CONFIGS } from "@/pageComponents/Common/FAQDetails/StudentGuideline/ReserveCourse/ReserveCourseConfigs";
import { SearchCourse } from "@/pageComponents/Common/FAQDetails/StudentGuideline/SearchCourse";
import { SEARCH_COURSE_CONFIGS } from "@/pageComponents/Common/FAQDetails/StudentGuideline/SearchCourse/SearchCourseConfigs";
import { AboutTeacherDetailsPage } from "@/pageComponents/Common/FAQDetails/TeacherGuideline/AboutTeacherDetailsPage";
import { ABOUT_TEACHER_DETAILS_PAGE_CONFIGS } from "@/pageComponents/Common/FAQDetails/TeacherGuideline/AboutTeacherDetailsPage/SignUpAsTeacherConfigs";
import { CancelHoldingCourse } from "@/pageComponents/Common/FAQDetails/TeacherGuideline/CancelHoldingCourse";
import { CANCEL_HOLDING_COURSE_CONFIGS } from "@/pageComponents/Common/FAQDetails/TeacherGuideline/CancelHoldingCourse/CancelCourseConfigs";
import { CheckReservation } from "@/pageComponents/Common/FAQDetails/TeacherGuideline/CheckReservation";
import { CHECK_RESERVATION_CONFIGS } from "@/pageComponents/Common/FAQDetails/TeacherGuideline/CheckReservation/CheckReservationConfigs";
import { CourseFlow } from "@/pageComponents/Common/FAQDetails/TeacherGuideline/CourseFlow";
import { COURSE_FLOW_CONFIGS } from "@/pageComponents/Common/FAQDetails/TeacherGuideline/CourseFlow/CourseFlowConfigs";
import { SignUpAsTeacher } from "@/pageComponents/Common/FAQDetails/TeacherGuideline/SignUpAsTeacher";
import { REGISTER_AS_TEACHER_CONFIGS } from "@/pageComponents/Common/FAQDetails/TeacherGuideline/SignUpAsTeacher/SignUpAsTeacherConfigs";
import { TeacherVerification } from "@/pageComponents/Common/FAQDetails/TeacherGuideline/TeacherVerification";
import { TEACHER_VERIFICATION_CONFIGS } from "@/pageComponents/Common/FAQDetails/TeacherGuideline/TeacherVerification/TeacherVerificationConfigs";

import styles from "./index.module.scss";
import { MonthlyFee } from "@/pageComponents/Common/FAQDetails/AboutPayment/MonthlyFee";
import { MONTHLY_FEE_CONFIGS } from "@/pageComponents/Common/FAQDetails/AboutPayment/MonthlyFee/MonthlyFee";
import { useNavigation } from "@/components/Navigation/NavigationContext";

interface FAQ {
    title: string;
    text: string;
    type: string;
}

export const FAQSearchResult: React.VFC = memo(function FAQSearchResult() {
    const [FAQs, setFAQs] = useState<FAQ[]>([]);
    const query = new URLSearchParams(useLocation().search);
    const keyword = query.get("keyword");
    const targetDOMArray = [
        // ・StudentGuideline
        SignUpAsStudent,
        SearchCourse,
        FavoriteCourse,
        ReserveCourse,
        PostReview,
        // ・TeacherGuideline
        SignUpAsTeacher,
        AboutTeacherDetailsPage,
        CourseFlow,
        CheckReservation,
        CancelHoldingCourse,
        TeacherVerification,
        // ・AboutTrail
        ServiceOfTrail,
        WhatToLearn,
        SupportTime,
        RegistrationRequired,
        RuleOrGuideline,
        ProhibitedAction,
        // ・AboutSignUp
        EmailNotArrived,
        // ・AboutLoin
        LoginFailed,
        ForgotPassword,
        // ・AboutPayment
        Receipt,
        CancelCourse,
        Refund,
        CreditCard,
        Tax,
        MonthlyFee,
        // AboutOperation
        Message,
        Profile,
        // AboutCourse
        Caution,
        CourseType,
        // AboutTax
        TaxReturn,
        // AboutOther
        Contact,
        Withdrawal,
        // ReleaseInfo
        ReleaseInfo202404,
    ];
    const ConfigsArray = [
        // StudentGuideline
        REGISTER_AS_STUDENT_CONFIGS,
        SEARCH_COURSE_CONFIGS,
        FAVORITE_COURSE_CONFIGS,
        RESERVE_COURSE_CONFIGS,
        POST_REVIEW_CONFIGS,
        // TeacherGuideline
        REGISTER_AS_TEACHER_CONFIGS,
        ABOUT_TEACHER_DETAILS_PAGE_CONFIGS,
        COURSE_FLOW_CONFIGS,
        CHECK_RESERVATION_CONFIGS,
        CANCEL_HOLDING_COURSE_CONFIGS,
        TEACHER_VERIFICATION_CONFIGS,
        // AboutTrail
        SERVICE_OF_TRAIL_CONFIGS,
        WHAT_TO_LEARN_CONFIGS,
        SUPPORT_TIME_CONFIGS,
        REGISTRATION_REQUIRED_CONFIGS,
        // RuleOfTrail
        RULE_OR_GUIDELINE_CONFIGS,
        PROHIBITED_ACTION_CONFIGS,
        ABOUT_REVIEW_CONFIGS,
        // AboutSignUp
        EMAIL_NOT_ARRIVED_CONFIGS,
        // AboutLogin
        LOGIN_FAILED_CONFIGS,
        FORGOT_PASSWORD_CONFIGS,
        // AboutPayment
        RECEIPT_CONFIGS,
        CANCEL_COURSE_CONFIGS,
        REFUND_CONFIGS,
        CREDIT_CARD_CONFIGS,
        TAX_CONFIGS,
        MONTHLY_FEE_CONFIGS,
        // AboutOperation
        MESSAGE_CONFIGS,
        PROFILE_CONFIGS,
        // AboutCourse
        CAUTION_CONFIGS,
        COURSE_TYPE_CONFIGS,
        // AboutCourseCreation
        CHANGE_COURSE_FEE_CONFIGS,
        TAX_RETURN_CONFIGS,
        // AboutOther
        CONTACT_CONFIGS,
        WITHDRAWAL_CONFIGS,
        // ReleaseInfo
        RELEASE_INFO_202210_CONFIGS,
    ];
    const { updateNavigationList } = useNavigation();
    useEffect(() => {
        if (!keyword) return;
        updateNavigationList([
            {
                title: `ヘルプ・よくある質問`,
                url: `/FAQ`,
            },
            {
                title: `検索結果`,
                url: `/FAQSearchResult/${keyword}`,
            },
        ]);
        const filteredFAQs: FAQ[] = targetDOMArray
            .map((targetDOM, idx) => {
                if (keyword) {
                    const titlePattern = /FAQDetailsBase,\s\{\s+title:\s\"(.+)\"/g;
                    const titleResult = targetDOM.toString().match(titlePattern)?.toString().replace(/\s+/g, "");
                    const startDoubleQuoteIndex = titleResult?.indexOf(":") ?? 0;
                    const modifiedTitleResult = titleResult?.slice(startDoubleQuoteIndex + 2).replace('"', "") ?? "";
                    const titleResultArray =
                        modifiedTitleResult[0] === "\\"
                            ? modifiedTitleResult.split(`\\u`).slice(1)
                            : modifiedTitleResult.split(`\\u`).slice(0);
                    const title = titleResultArray
                        .map((result) => {
                            if (Number.isNaN(parseInt(result, 16))) {
                                return result;
                            }
                            if (result.length === 4) {
                                return String.fromCharCode(parseInt(result, 16));
                            }
                            return [String.fromCharCode(parseInt(result.slice(0, 4), 16)), result.slice(4)];
                        })
                        .flat()
                        .join("");
                    const textPattern = /text\:\s\"(.+)\"/g;
                    const domTextResultArray = targetDOM
                        .toString()
                        .match(textPattern)
                        ?.map((targetText) => {
                            const textStartDoubleQuoteIndex = targetText?.indexOf(":") ?? 0;
                            const textResultString = targetText
                                .slice(textStartDoubleQuoteIndex)
                                .replace('"', "")
                                .replace(":", "")
                                .replace(/\s/, "");
                            const textResultArray =
                                textResultString[0] === "\\"
                                    ? textResultString.split(`\\u`).slice(1)
                                    : textResultString.split(`\\u`).slice(0);
                            const textResult = textResultArray
                                .map((result) => {
                                    if (Number.isNaN(parseInt(result, 16))) {
                                        return result;
                                    }
                                    if (result.length === 4) {
                                        return String.fromCharCode(parseInt(result, 16));
                                    }
                                    return [String.fromCharCode(parseInt(result.slice(0, 4), 16)), result.slice(4)];
                                })
                                .flat()
                                .join("")
                                .replace('"', "");
                            return textResult;
                        });
                    const domText = domTextResultArray?.join(" / ") ?? "";
                    const configsText = ConfigsArray[idx]
                        .map((config) => {
                            if (config.contents) {
                                return [config.text, ...config.contents?.map((content) => content.text)];
                            } else {
                                return config.text;
                            }
                        })
                        .flat()
                        .join(" / ");
                    const text = domText + " / " + configsText;
                    const name = targetDOM.name;
                    const type = name.slice(0, 1).toLowerCase() + name.slice(1);
                    const spaceRegex = /\s+/g;
                    if (keyword && spaceRegex.test(keyword)) {
                        const keywordArray = keyword?.split(/\s+/g).filter((keyword) => keyword) ?? [];
                        let fixedText = text;
                        const allIncludes = keywordArray
                            .map((keyword) => {
                                const highlightedText = getHighlightedText(fixedText, keyword);
                                if (highlightedText) {
                                    fixedText = highlightedText;
                                    return true;
                                }
                                return false;
                            })
                            .every((result) => result);
                        if (allIncludes) {
                            return {
                                title: title,
                                text: fixedText,
                                type: type,
                            };
                        }
                        return null;
                    }
                    const highlightedText = getHighlightedText(text, keyword);
                    if (highlightedText) {
                        return {
                            title: title,
                            text: getHighlightedText(text, keyword),
                            type: type,
                        };
                    }
                    return null;
                }
                return null;
            })
            .filter((FAQ): FAQ is FAQ => FAQ != null);
        setFAQs(filteredFAQs);
    }, [keyword]);

    useEffect(() => {
        const FAQsNode = document.getElementById("FAQs");
        FAQsNode?.childNodes.forEach((span, idx) => {
            span.childNodes.forEach((button) => {
                if (FAQs[idx] && FAQs[idx].text) {
                    if (button.childNodes.length > 1) {
                        button.childNodes[button.childNodes.length - 1].remove();
                    }
                    if (button.childNodes.length === 1) {
                        const divElement = document.createElement("div");
                        divElement.innerHTML = FAQs[idx].text;
                        divElement.classList.add(styles.FAQText);
                        button.appendChild(divElement);
                    }
                }
            });
        });
    }, [FAQs]);
    const history = useHistory();
    const handleFAQButtonClick = useCallback(
        (type: string) => {
            history.push(`/FAQDetails?type=${type}`);
        },
        [history],
    );

    const getHighlightedText = useCallback((text: string, keyword: string) => {
        if (keyword && text.includes(keyword)) {
            const startIdxArray: number[] = [];
            for (let i = 0; i < text.length; i++) {
                const idx = text.slice(i).indexOf(keyword, i);
                if (idx === -1) {
                    break;
                }
                startIdxArray.push(idx + i);
            }
            const uniqueStartIdxArray = Array.from(new Set(startIdxArray));
            let modifiedText = text;
            uniqueStartIdxArray?.forEach((startIdx, idx) => {
                const startSpan = `<span class="${styles.strong}">`;
                const endSpan = "</span>";
                const incremental = (startSpan.length + endSpan.length) * idx;
                const newStartIdx = startIdx + incremental;
                const before = modifiedText.slice(0, newStartIdx);
                const target = modifiedText.slice(newStartIdx, newStartIdx + keyword.length);
                const after = modifiedText.slice(newStartIdx + keyword.length);
                const result = before + startSpan + target + endSpan + after;
                modifiedText = result;
            });
            return modifiedText;
        } else {
            return null;
        }
    }, []);

    return (
        <div className={styles.FAQSearchResultWrapper}>
            <SearchFAQ />
            <div className={styles.searchResultTitle}>
                「{keyword}」の検索結果({FAQs.length}件)
            </div>
            <div className={styles.FAQs} id="FAQs">
                {FAQs.length === 0 ? (
                    <div className={styles.emptyFAQ}>該当する検索結果がありませんでした。</div>
                ) : (
                    FAQs.map((FAQ, idx) => {
                        return (
                            <Button
                                className={styles.FAQ}
                                style={{ textTransform: "none" }}
                                key={idx}
                                onClick={() => {
                                    handleFAQButtonClick(FAQ.type);
                                }}
                            >
                                <div className={styles.FAQTitle}>{FAQ.title}</div>
                            </Button>
                        );
                    })
                )}
            </div>
        </div>
    );
});
