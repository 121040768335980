import { memo } from "react";
import styles from "./index.module.scss";
import { NotificationsSetting } from "./Targets/NotificationsSetting";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { useGetEmailNotificationsSetting, useGetPushNotificationsSetting } from "@/store/hooks/users";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { AccountSetting } from "./Targets/AccountSetting";

interface Props {}

export const SettingContents: React.VFC<Props> = memo(function NoticeContents(props) {
    const userId = useSelector((state: RootState) => state.jwt.userId as string);

    const pushNotificationsSettingQueryState = useGetPushNotificationsSetting(userId);
    const emailNotificationsSettingQueryState = useGetEmailNotificationsSetting(userId);
    return (
        <div className={styles.settingWrapper}>
            <AccountSetting />
            <QueryLoadingWrapper {...pushNotificationsSettingQueryState}>
                {(pushNotificationsSetting) => (
                    <QueryLoadingWrapper {...emailNotificationsSettingQueryState}>
                        {(emailNotificationsSetting) => (
                            <NotificationsSetting
                                pushNotificationsSetting={pushNotificationsSetting}
                                emailNotificationsSetting={emailNotificationsSetting}
                            />
                        )}
                    </QueryLoadingWrapper>
                )}
            </QueryLoadingWrapper>
        </div>
    );
});
