import { OverWrapper } from "@/components/OverWrapper";
import styles from "@/pages/Common/LP/index.module.scss";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

export const PostScript: React.VFC = () => {
    return (
        <OverWrapper style={{ backgroundColor: "#fefff2" }}>
            <div className={styles.postScriptWrapper}>
                <div className={styles.postScriptTitle}>最後に・・・</div>
                <div className={styles.postScript}>
                    Trailのご説明をここまで読んでくださってありがとうございます。
                    <br />
                    <br />
                    今回は、Trailで自由に先生として活動してほしいという思いを熱くお伝えしました。
                    <br />
                    <br />
                    わたしたちはテレビCMで見るような大手の塾などではありませんので、あなたも少なからずTrailを利用することに不安があるかもしれません。
                    <br />
                    <br />
                    ただ、1つ言えるのは、Trailは、<span className={styles.strong}>先生も主役として活躍できる</span>
                    ことを目指して作られたサービスであるということです。
                    <br />
                    <br />
                    <span className={styles.strong}>家にいながら、自分のペースで、自分のやりたいことを教えて稼ぐ</span>
                    ことができる。
                    <br />
                    Trailに登録して、ぜひそんな最高の体験を楽しんでください。
                </div>
                <Button className={styles.signUpButton} component={Link} to="/SignUp?type=teacher">
                    新規ユーザー登録へ（無料）
                </Button>
            </div>
        </OverWrapper>
    );
};
