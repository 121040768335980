import { FAQDetailsBase } from "../../FAQDetailsBase";

import { CANCEL_COURSE_CONFIGS } from "./CancelCourse";

export const CancelCourse: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="受講停止・欠席したい"
            text="「受講停止」は授業の全てを受けないこと、「欠席」は対象の1授業を受けないことをそれぞれ指します。受講停止・欠席したい場合、講座の種類によって対応が異なります。"
            list={["単発・短期講座", "定期講座"]}
            FAQConfigs={CANCEL_COURSE_CONFIGS}
        />
    );
};
