import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Rating from "@material-ui/lab/Rating";
import { useHistory } from "react-router";

import bronzeBadge from "@/images/B.png";
import goldBadge from "@/images/G.png";
import platinumBadge from "@/images/P.png";
import regularBadge from "@/images/R.png";
import silverBadge from "@/images/S.png";
import { CourseResponse, ClassResponse } from "@/store/autogenApi";

import styles from "./index.module.scss";

import defaultCourseImage from "@/images/defaultCourseImage.jpg";
import { memo, useCallback, useEffect, useState } from "react";

import { AiFillStar } from "react-icons/ai";
import { getWhatDays } from "@/utils/AvailableTimeUtils";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";
import { useMediaQuery } from "@material-ui/core";
import { ImageFromS3 } from "@/components/atoms/images/ImageFromS3";

interface Props {
    course?: CourseResponse;
    isHidden?: boolean;
    isFlex?: boolean;
}

interface WhatDaySet {
    sunday: number;
    monday: number;
    tuesday: number;
    wednesday: number;
    thursday: number;
    friday: number;
    saturday: number;
}

const WHAT_DAY_SET: WhatDaySet = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
};

const WHAT_DAY_LIST = ["日", "月", "火", "水", "木", "金", "土"];

export const CourseCard: React.VFC<Props> = memo(function CourseCard(props) {
    const [activeRegularClass, setActiveRegularClass] = useState<ClassResponse | undefined>(undefined);
    const [priceWrapperEl, setPriceWrapperEl] = useState<HTMLElement | null>(null);
    const [teacherInfoWrapperBodyEl, setTeacherInfoWrapperEl] = useState<HTMLElement | null>(null);

    const smUp = useMediaQuery("(min-width:600px)");

    const priceWrapperRef = useCallback((el: HTMLElement | null) => {
        setPriceWrapperEl(el);
    }, []);

    const teacherInfoWrapperRef = useCallback((el: HTMLElement | null) => {
        setTeacherInfoWrapperEl(el);
    }, []);

    // useEffect(() => {
    //     const priceWrapperWidth = priceWrapperEl?.clientWidth;
    //     if (priceWrapperWidth && teacherInfoWrapperBodyEl) {
    //         teacherInfoWrapperBodyEl.style.setProperty("width", `calc(100% - ${priceWrapperWidth}px)`);
    //     }
    // }, [priceWrapperEl, teacherInfoWrapperBodyEl]);

    useEffect(() => {
        const nowActiveClass = props.course?.classes.find((classResponse) => classResponse.status === "recruiting");
        setActiveRegularClass(nowActiveClass);
    }, [props.course?.classes]);

    const getPrice = useCallback(() => {
        if (props.course?.courseType === "short") {
            const prices = props.course.classes.map((classResponse) => classResponse.pricePerPayment);
            const isAllEqual = prices.every((price) => price === prices[0]);
            return isAllEqual ? `${prices[0]}` : `${prices[0]}`;
        } else if (props.course?.courseType === "regular") {
            return activeRegularClass ? `${activeRegularClass.pricePerHour}` : "";
        } else {
            return props.course?.classes[0].pricePerPayment;
        }
    }, [props.course, activeRegularClass]);

    const getHowLong = useCallback(() => {
        if (
            props.course &&
            props.course?.courseType === "short" &&
            props.course?.classes &&
            props.course?.classes[0] &&
            props.course?.classes[0].lessons &&
            props.course?.classes[0].lessons[0] &&
            props.course?.classes[0].lessons[0].endTime &&
            props.course?.classes[0].lessons[0].startTime
        ) {
            const startTime = new Date(props.course?.classes[0].lessons[0].startTime);
            const endTime = new Date(props.course?.classes[0].lessons[0].endTime);
            const diffTime = endTime.getTime() - startTime.getTime();
            const howManyMinutes = diffTime / (1000 * 60);
            const targetApplyingDeadline = props.course?.classes[0].applyingDeadline;
            const targetLessons = props.course?.classes.filter(
                (course) => course.applyingDeadline === targetApplyingDeadline,
            );
            const howManyDays = targetLessons.length;
            return `${howManyMinutes}分×${howManyDays}回`;
        }
    }, [props.course]);

    const getLessonExampleTimes = useCallback(() => {
        if (props.course && props.course?.classes && props.course?.classes[0] && props.course?.classes[0].lessons) {
            const targetLessons = props.course?.classes[0].lessons;
            const lessonExampleTimes = targetLessons.map((lesson) => {
                if (lesson.startTime && lesson.endTime) {
                    const startTime = new Date(lesson.startTime);
                    const endTime = new Date(lesson.endTime);
                    const month = startTime.getMonth() + 1;
                    const date = startTime.getDate();
                    const day = WHAT_DAY_LIST[startTime.getDay()];
                    const startHour = startTime.getHours();
                    const startMinute = ("00" + startTime.getMinutes()).slice(-2);
                    const finishHour = endTime.getHours();
                    const finishMinute = ("00" + endTime.getMinutes()).slice(-2);
                    return `${month}月${date}日(${day}) ${startHour}:${startMinute}~${finishHour}:${finishMinute}`;
                }
            });
            if (lessonExampleTimes.length >= 2) {
                const cutLessonExampleTimes = lessonExampleTimes.slice(0, 1);
                const whatTime = cutLessonExampleTimes.join("・") + " 他";
                return whatTime;
            } else {
                const whatTime = lessonExampleTimes.join("・");
                return whatTime;
            }
        }
    }, [props.course]);

    const getSrc = useCallback((rankNumber: number) => {
        if (rankNumber === 1) {
            return regularBadge;
        } else if (rankNumber === 2) {
            return bronzeBadge;
        } else if (rankNumber === 3) {
            return silverBadge;
        } else if (rankNumber === 4) {
            return goldBadge;
        } else if (rankNumber === 5) {
            return platinumBadge;
        } else {
            return "";
        }
    }, []);

    const history = useHistory();
    const detail = useCallback((id?: string) => {
        history.push(`/CourseDetails/${id}`);
    }, []);

    const getCourseScore = useCallback(() => {
        if (!props.course?.reviews) return 0;
        if (props.course.reviews.length === 0) {
            return 0;
        } else if (props.course.reviews.length === 1) {
            return props.course.reviews[0].score.toFixed(1);
        } else {
            const sum = props.course?.reviews.reduce((acc, cur) => acc + (cur.score as number), 0);
            return (sum / props.course.reviews.length).toFixed(1);
        }
    }, [props.course?.reviews]);

    const getCourseAchievement = useCallback(() => {
        return props.course?.reviews?.length;
    }, [props.course?.reviews]);

    const getUnit = useCallback(() => {
        if (props.course?.courseType === "regular") {
            return "円/時間";
        }
        return "円/講座";
    }, [props.course?.courseType]);

    return (
        <Card
            className={styles.card}
            style={{
                visibility: props.isHidden ? "hidden" : "visible",
                flex: props.isFlex ? 1 : "none",
                width: props.isFlex ? "auto" : smUp ? "130px" : "110px",
                backgroundColor: "#FFF !important",
            }}
            onClick={() => {
                detail(props.course?.courseId);
            }}
        >
            <div className={styles.mediaWrapper}>
                <ImageFromS3
                    url={props.course?.thumbnailUrl ?? defaultCourseImage}
                    objectKey={props.course?.thumbnailObjectKey}
                    className={styles.media}
                />
                <Typography variant="body2" color="textSecondary" component="div" className={styles.ratingWrapper}>
                    <AiFillStar className={styles.star} />
                    <div className={styles.averageReviewScore}>{getCourseScore()}</div>
                    <div className={styles.totalAchievements}>({getCourseAchievement()})</div>
                </Typography>
            </div>
            <CardContent className={styles.cardContents}>
                <Typography variant="body2" color="textSecondary" component="div" className={styles.title}>
                    {props.course?.title}
                </Typography>
                <div className={styles.teacherInfoWrapper} ref={teacherInfoWrapperRef}>
                    <AvatarFromS3
                        url={props.course?.teacher?.iconImageUrl ?? defaultCourseImage}
                        objectKey={props.course?.teacher?.iconImageObjectKey}
                        className={styles.avatar}
                    />
                    <div className={styles.teacherNickname}>{props.course?.teacher?.nickname}先生</div>
                    {props.course?.teacher != undefined &&
                        props.course?.teacher?.rankNumber != undefined &&
                        Boolean(getSrc(props.course?.teacher?.rankNumber)) && (
                            <img src={getSrc(props.course?.teacher?.rankNumber)} alt="" className={styles.rankBadge} />
                        )}
                </div>
                <div className={styles.schedule}>
                    {props.course?.courseType === "regular"
                        ? `授業可：${getWhatDays(props.course.availableTimes ?? [])}`
                        : `${getLessonExampleTimes()}`}
                </div>
                <div className={styles.priceWrapper} ref={priceWrapperRef}>
                    <div className={styles.strong}>{getPrice()}</div>
                    {getUnit()}
                </div>
            </CardContent>
        </Card>
    );
});
