import { memo } from "react";

import { useGetPrivateUserQuery } from "@/store/hooks/users";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { Process } from "./Process";

interface Props {
    userId: string;
    forStudent: boolean;
    handleClose: () => void;
    handleIsProcessingForSignInChange: (value: boolean) => void;
}

export const SignInProcess: React.VFC<Props> = memo(function SignInProcess(props) {
    const privateUserQueryState = useGetPrivateUserQuery(props.userId);
    return (
        <QueryLoadingWrapper {...privateUserQueryState}>
            {(user) => (
                <Process
                    user={user}
                    userId={props.userId}
                    forStudent={props.forStudent}
                    handleClose={props.handleClose}
                    handleIsProcessingForSignInChange={props.handleIsProcessingForSignInChange}
                />
            )}
        </QueryLoadingWrapper>
    );
});
