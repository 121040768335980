import { OverWrapper } from "@/components/OverWrapper";
import styles from "@/pages/Common/LP/index.module.scss";
import businessContestImage from "../img/tongari.jpg";
import JFCImage from "../img/JFC.jpg";

export const Achievements: React.VFC = () => {
    return (
        <OverWrapper style={{ backgroundColor: "#FFF", borderTop: "1px solid #999", borderBottom: "1px solid #999" }}>
            <div className={styles.achievementsWrapper}>
                <div className={styles.achievementsDescription}>外部から多くの応援をいただいております。</div>
                <div className={styles.achievementsTitle}>Support</div>
                <div className={styles.achievements}>
                    <div className={styles.achievement}>
                        <img src={businessContestImage} className={styles.achievementImage} />
                        <div className={styles.achievementDescription}>
                            <span className={styles.strong}>名古屋大学</span>主催のビジネスプランコンテストで
                            <br />
                            <span className={styles.strong}>大和証券賞</span>
                            を受賞！
                        </div>
                    </div>
                    <div className={styles.achievement}>
                        <img
                            src={JFCImage}
                            className={styles.achievementImage}
                            style={{ width: "50%", margin: "0 auto" }}
                        />
                        <div className={styles.achievementDescription}>
                            <span className={styles.strong}>日本政策金融公庫様</span>よりご融資を実行いただきました！
                        </div>
                    </div>
                </div>
            </div>
        </OverWrapper>
    );
};
