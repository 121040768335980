import { DraftPublicRequestResponse } from "@/store/autogenApi";

export const getPrice = (draftPublicRequest: DraftPublicRequestResponse) => {
    return `¥${draftPublicRequest?.minPrice}~¥${draftPublicRequest?.maxPrice}`;
};

export const getCourseType = (draftPublicRequest: DraftPublicRequestResponse) => {
    if (draftPublicRequest.courseType === "short") {
        return "単発・短期";
    } else {
        return "定期";
    }
};

export const getStatus = (draftPublicRequest: DraftPublicRequestResponse) => {
    const now = new Date();
    const deadline = new Date(draftPublicRequest.applyingDeadline);
    if (now > deadline) {
        return "募集終了";
    } else {
        return "募集中";
    }
};

export const getTabInfoList = (draftPublicRequests: DraftPublicRequestResponse[]) => {
    let shortCount = 0;
    let regularCount = 0;
    draftPublicRequests.forEach((request) => {
        if (request.courseType === "short") {
            shortCount++;
        } else {
            regularCount++;
        }
    });
    return [
        { value: "regular", label: `定期(${regularCount})` },
        { value: "short", label: `単発・短期(${shortCount})` },
    ];
};
