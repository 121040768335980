import { Button } from "@material-ui/core";

import { SubjectDetailButton } from "./SubjectDetailButton";
import styles from "./index.module.scss";
import { memo, useCallback, useState } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";

interface Props {
    categoryKey: string;
    object: ObjectType;
    level: string;
    mode: "student" | "teacher";
}

interface ObjectType {
    label: string;
    sub?:
        | {
              [subject: string]: {
                  label: string;
                  icon: JSX.Element;
              };
          }
        | undefined;
    icon: JSX.Element;
}

export const SubjectContents: React.VFC<Props> = memo(function SubjectContents(props) {
    const [targetCategory, setTargetCategory] = useState<string | undefined>(undefined);

    const handlePopoverOpen = useCallback((categoryKey: string) => {
        setTargetCategory(categoryKey);
    }, []);

    const handlePopoverClose = useCallback(() => {
        setTargetCategory(undefined);
    }, []);

    const getLeft = useCallback((subjectLabel: string) => {
        const left = 66 + 8 * (subjectLabel.length - 3);
        return left;
    }, []);

    const history = useHistory();

    const handleSubjectButtonClick = useCallback(() => {
        const searchType = props.mode === "teacher" ? "publicRequest" : "course";
        history.push(`/SearchResults?searchType=${searchType}&level=${props.level}&category=${props.categoryKey}`);
    }, [props.level, props.categoryKey, props.mode]);

    return (
        <div className={styles.subjectWrapper} key={props.categoryKey} onMouseLeave={handlePopoverClose}>
            <Button
                className={styles.subjectButton}
                onMouseOver={() => {
                    handlePopoverOpen(props.categoryKey);
                }}
                onClick={handleSubjectButtonClick}
            >
                <>
                    {props.object.icon}
                    <span className={styles.title} style={{ marginRight: 0 }}>
                        {props.object.label}
                    </span>
                </>
            </Button>
            <div
                className={styles.tooltip}
                style={{
                    display: targetCategory === props.categoryKey && props.object.sub ? "flex" : "none",
                    left: getLeft(props.object.label),
                    top: 0,
                }}
                onMouseLeave={handlePopoverClose}
            >
                {props.object.sub &&
                    Object.entries(props.object?.sub).map(([subCategoryKey, subjectDetail]) => {
                        return (
                            <SubjectDetailButton
                                key={subCategoryKey}
                                level={props.level}
                                categoryKey={props.categoryKey}
                                subjectDetail={subjectDetail}
                                subCategoryKey={subCategoryKey}
                            />
                        );
                    })}
            </div>
        </div>
    );
});
