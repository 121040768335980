import { memo, useEffect } from "react";

interface Props {
    value: string;
    handleChange: (value: any) => void;
}

export const ToKana: React.VFC<Props> = memo(function ToKana(props) {
    useEffect(() => {
        props.handleChange(props.value);
    }, [props.value, props.handleChange]);
    return <></>;
});
