import { memo, useCallback, useEffect, useState } from "react";
import { StyledModal } from "@/components/StyledModal";
import { DraftCourseResponse } from "@/store/autogenApi";
import { ExistDraftCourse } from "./ExistDraftCourse";

interface Props {
    draftCourseId: string | null;
    existCourse: boolean;
    handleDiscardDraftButtonClick: () => void;
    handleUseDraftButtonClick: (draftCourse: DraftCourseResponse | undefined) => void;
}

export const UseDraftCourseModal: React.VFC<Props> = memo(function UseDraftCourseModal(props) {
    const [useDraftCourseModalOpen, setUseDraftCourseModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (props.draftCourseId !== null) {
            setUseDraftCourseModalOpen(true);
        }
    }, [props.draftCourseId]);

    const handleDiscardDraftButtonClick = useCallback(() => {
        setUseDraftCourseModalOpen(false);
        props.handleDiscardDraftButtonClick();
    }, [props.handleDiscardDraftButtonClick]);

    const handleUseDraftButtonClick = useCallback(
        (draftCourse: DraftCourseResponse | undefined) => {
            setUseDraftCourseModalOpen(false);
            props.handleUseDraftButtonClick(draftCourse);
        },
        [props.handleUseDraftButtonClick],
    );

    if (props.draftCourseId == null) {
        return null;
    }
    return (
        <StyledModal
            open={useDraftCourseModalOpen}
            autoResize
            onClose={() => {
                // 何もしない
            }}
            style={{ maxWidth: "500px" }}
        >
            <ExistDraftCourse
                existCourse={Boolean(props.existCourse)}
                draftCourseId={props.draftCourseId}
                handleDiscardDraftButtonClick={handleDiscardDraftButtonClick}
                handleUseDraftButtonClick={handleUseDraftButtonClick}
            />
        </StyledModal>
    );
});
