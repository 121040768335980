import styles from "./index.module.scss";
import { memo } from "react";
import { Button, useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";

interface Props {
    text: string;
    icon: JSX.Element;
    handleClick: () => void;
}

export const SearchTypeButton: React.VFC<Props> = memo(function SearchCourse(props) {
    const userMode = useSelector((state: RootState) => state.jwt.userMode);
    const xsUp = useMediaQuery("(min-width:375px)");
    const biggerThanLowerLimit = useMediaQuery("(min-width:600px)");
    const smallerThanUpperLimit = useMediaQuery("(max-width:959px)");
    const smallerThanTeacherUpperLimit = useMediaQuery("(max-width:745px)");
    return (
        <Button className={styles.searchTypeButton} onClick={props.handleClick}>
            {xsUp &&
                !(
                    biggerThanLowerLimit &&
                    ((userMode !== "teacher" && smallerThanUpperLimit) ||
                        (userMode === "teacher" && smallerThanTeacherUpperLimit))
                ) && <>{props.icon}</>}
            <div className={styles.searchTypeWrapper}>
                <div className={styles.searchType}>{props.text}</div>
                <div className={styles.search}>を探す</div>
            </div>
        </Button>
    );
});
