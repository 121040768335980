import FormHelperText from "@material-ui/core/FormHelperText";

import styles from "../../userInfoInput.module.scss";

interface Props {
    value: string;
}

export const None: React.VFC<Props> = (props) => {
    return (
        <>
            {props.value.length === 0 && (
                <FormHelperText className={styles.helperText}>入力してください。</FormHelperText>
            )}
        </>
    );
};
