import { memo } from "react";

import styles from "./index.module.scss";

interface Props {
    title: string;
    value?: string;
    children?: React.ReactNode;
    isLastItem?: boolean;
}

export const ListItem: React.VFC<Props> = memo(function ListItem(props) {
    return (
        <li className={styles.listItem} style={{ borderBottom: props.isLastItem ? "none" : "1px solid #CCC" }}>
            <div className={styles.leftWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>{props.title}</div>
                </div>
            </div>
            <div className={styles.rightWrapper}>
                {props.children ?? <div className={styles.value}>{props.value}</div>}
            </div>
        </li>
    );
});
