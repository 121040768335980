import { useEffect, useState } from "react";

import { Hidden } from "@material-ui/core";

import { UserType } from "@/protectedRouter/ProtectedRouterConfig";
import { ClassResponse, CourseResponse } from "@/store/autogenApi";

import { PCContents } from "./PCContents";
import { SPContents } from "./SPContents";

interface Props {
    studentId: string | null | undefined;
    frequency?: string;
    course: CourseResponse;
    title?: string;
    isFavorite: boolean;
    isHostTeacher: boolean;
    userMode: UserType;
    addScheduleModalOpen: boolean;
    fixedContentsEl: React.MutableRefObject<HTMLElement | null>;
    privateRequestModalOpen: boolean;
    courseTeacherId: string;
    isReservationButtonClicked: boolean;
    thresholdOver: boolean;
    getJapaneseDay: (idx: number) => string;
    getDiffTime: (date: Date) => string;
    handleReservationButtonClick: (classId: string) => void;
    handleAddScheduleModalClose: () => void;
    handleAddScheduleModalOpenButtonClick: () => void;
    handleClassButtonClick: (classId: string) => void;
    handlePrivateRequestModalClose: () => void;
    handleSignUpModalOpen: () => void;
    initializeReservationButtonClicked: () => void;
}

export const RegularCourseContents: React.VFC<Props> = (props) => {
    const [isBeforeDeadline, setIsBeforeDeadline] = useState<boolean>(false);
    // 募集中のクラス
    const [activeClass, setActiveClass] = useState<ClassResponse | undefined>(undefined);
    // 予約済みのクラス
    const [reservedClass, setReservedClass] = useState<ClassResponse | undefined>(undefined);

    useEffect(() => {
        const fetchedIsBeforeDeadline = props.course.classes.some((classResponse) => {
            if (classResponse.applyingDeadline) {
                const result = new Date(classResponse.applyingDeadline).getTime() - new Date().getTime() > 0;
                return result;
            } else {
                return false;
            }
        });
        setIsBeforeDeadline(fetchedIsBeforeDeadline);
        const fetchedActiveClass = props.course.classes.find(
            (classResponse) =>
                classResponse.status === "recruiting" &&
                classResponse.applyingDeadline &&
                new Date(classResponse.applyingDeadline).getTime() - new Date().getTime() > 0,
        );
        setActiveClass(fetchedActiveClass);
        const fetchedReservedClass = props.course.classes.find((classResponse) => {
            const isIncludedStudentId = classResponse.participants?.some(
                (participant) => participant.studentId === props.studentId,
            );
            return isIncludedStudentId && classResponse.status === "ongoing";
        });
        setReservedClass(fetchedReservedClass);
    }, [props.course.classes]);

    return (
        <>
            <Hidden smUp>
                <SPContents
                    courseId={props.course.courseId}
                    classes={props.course.classes}
                    title={props.title}
                    isFavorite={props.isFavorite}
                    isHostTeacher={props.isHostTeacher}
                    fixedContentsEl={props.fixedContentsEl}
                    userMode={props.userMode}
                    addScheduleModalOpen={props.addScheduleModalOpen}
                    privateRequestModalOpen={props.privateRequestModalOpen}
                    isReservationButtonClicked={props.isReservationButtonClicked}
                    courseTeacherId={props.courseTeacherId}
                    privateRequest={activeClass?.privateRequest}
                    thresholdOver={props.thresholdOver}
                    activeClass={activeClass}
                    reservedClass={reservedClass}
                    getJapaneseDay={props.getJapaneseDay}
                    getDiffTime={props.getDiffTime}
                    handleReservationButtonClick={props.handleReservationButtonClick}
                    handleAddScheduleModalOpenButtonClick={props.handleAddScheduleModalOpenButtonClick}
                    handleAddScheduleModalClose={props.handleAddScheduleModalClose}
                    handleClassButtonClick={props.handleClassButtonClick}
                    handlePrivateRequestModalClose={props.handlePrivateRequestModalClose}
                    handleSignUpModalOpen={props.handleSignUpModalOpen}
                    initializeReservationButtonClicked={props.initializeReservationButtonClicked}
                />
            </Hidden>
            <Hidden xsDown>
                <PCContents
                    courseId={props.course.courseId}
                    classes={props.course.classes}
                    title={props.title}
                    isFavorite={props.isFavorite}
                    isHostTeacher={props.isHostTeacher}
                    fixedContentsEl={props.fixedContentsEl}
                    userMode={props.userMode}
                    isBeforeDeadline={isBeforeDeadline}
                    addScheduleModalOpen={props.addScheduleModalOpen}
                    privateRequestModalOpen={props.privateRequestModalOpen}
                    courseTeacherId={props.courseTeacherId}
                    isReservationButtonClicked={props.isReservationButtonClicked}
                    privateRequest={activeClass?.privateRequest}
                    thresholdOver={props.thresholdOver}
                    activeClass={activeClass}
                    reservedClass={reservedClass}
                    getJapaneseDay={props.getJapaneseDay}
                    getDiffTime={props.getDiffTime}
                    handleReservationButtonClick={props.handleReservationButtonClick}
                    handleAddScheduleModalOpenButtonClick={props.handleAddScheduleModalOpenButtonClick}
                    handleAddScheduleModalClose={props.handleAddScheduleModalClose}
                    handleClassButtonClick={props.handleClassButtonClick}
                    handlePrivateRequestModalClose={props.handlePrivateRequestModalClose}
                    handleSignUpModalOpen={props.handleSignUpModalOpen}
                    initializeReservationButtonClicked={props.initializeReservationButtonClicked}
                />
            </Hidden>
        </>
    );
};
