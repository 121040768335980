import { useCallback, useEffect, useState } from "react";

import { Button, Checkbox, createTheme, MuiThemeProvider } from "@material-ui/core";

import styles from "@/pages/Teacher/MyPage/index.module.scss";

import { AiOutlineEdit, AiOutlinePlusCircle } from "react-icons/ai";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import { BankAccountRegistration } from "./BankAccoutRegistration";
import { useUpdateDefaultBankAccountMutation } from "@/store/hooks/stripe";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { EditModeContents } from "./EditModeContents";
import { Processing } from "@/components/Processing";

interface Props {
    bankAccountList: any[];
    bankInfoList: any[];
}

export interface ModifiedBankAccount {
    id: string;
    bankFullName: string | undefined;
    branchName: string | undefined;
    last3: string;
    accountHolderName: string;
    isDefault: boolean;
}

export const BankAccountSettingContents: React.VFC<Props> = (props) => {
    const [isRegisterMode, setIsRegisterMode] = useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [newDefaultBankAccountId, setNewDefaultBankAccountId] = useState<string | null>(null);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [modifiedBankAccountList, setModifiedBankAccountList] = useState<ModifiedBankAccount[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    const [defaultChanged, setDefaultChanged] = useState<boolean>(false);

    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);
    const updateDefaultBankAccount = useUpdateDefaultBankAccountMutation();

    const theme = createTheme({
        overrides: {
            MuiCheckbox: {
                root: {
                    "&:hover": {
                        backgroundColor: "#e1edfb",
                    },
                },
                colorSecondary: {
                    "&$checked": {
                        color: "#305077",
                    },
                    "&:hover": {
                        backgroundColor: "#e1edfb !important",
                    },
                },
            },
            MuiIconButton: {
                colorSecondary: {
                    color: "#305077",
                },
            },
        },
    });

    useEffect(() => {
        const list = props.bankAccountList
            .map((bankAccount) => {
                const id = bankAccount.id;
                const bankCode = bankAccount.routing_number.slice(0, 4);
                const bankFullName = props.bankInfoList.find(
                    (bankInfo) => bankInfo.bankCode === bankCode,
                )?.bankFullName;
                const branchCode = bankAccount.routing_number.slice(4, 7);
                const branchName = props.bankInfoList
                    .filter((bankInfo) => bankInfo.bankCode === bankCode)
                    .find((branch) => branch.branchCode === branchCode)?.branchName;
                const last3 = bankAccount.last4.slice(-3);
                const accountHolderName = bankAccount.account_holder_name;
                const isDefault = bankAccount.default_for_currency;
                if (isDefault) {
                    setNewDefaultBankAccountId(id);
                }
                // if (!bankFullName || !branchName) return undefined;
                const modifiedBankAccount: ModifiedBankAccount = {
                    id,
                    bankFullName,
                    branchName,
                    last3,
                    accountHolderName,
                    isDefault,
                };
                return modifiedBankAccount;
            })
            .flatMap((x) => (x === undefined ? [] : x));
        setModifiedBankAccountList(list);
    }, [props.bankAccountList, props.bankInfoList]);

    const handleBackButtonClick = () => {
        setIsRegisterMode(false);
        setIsEditMode(false);
        setIsError(false);
    };
    const handleRegisterNewBankAccountButtonClick = () => {
        setIsRegisterMode(true);
    };

    const handleEditButtonClick = useCallback(() => {
        setIsEditMode(!isEditMode);
    }, [isEditMode]);

    const handleBankAccountButtonClick = useCallback(
        (bankAccountId: string) => {
            const currentDefaultBankAccountId = modifiedBankAccountList.find(
                (bankAccount) => bankAccount.isDefault,
            )?.id;
            if (currentDefaultBankAccountId === bankAccountId) {
                setDefaultChanged(false);
            } else {
                setDefaultChanged(true);
            }
            setNewDefaultBankAccountId(bankAccountId);
        },
        [newDefaultBankAccountId],
    );

    const handleUpdateButtonClick = useCallback(async () => {
        if (newDefaultBankAccountId === null) return;
        setIsUpdating(true);
        const result = await updateDefaultBankAccount({
            updateDefaultBankAccountRequestBody: {
                teacherId,
                bankAccountId: newDefaultBankAccountId,
            },
        });
        if (!result.isSuccess) setIsError(true);
        setIsUpdating(false);
    }, [newDefaultBankAccountId]);

    return (
        <div className={styles.bankAccountSettingWrapper}>
            {isRegisterMode ? (
                <BankAccountRegistration
                    bankInfoList={props.bankInfoList}
                    handleBackButtonClick={handleBackButtonClick}
                />
            ) : (
                <div className={styles.bankAccountMainWrapper}>
                    {isError ? (
                        <div className={styles.errorWrapper}>
                            <div className={styles.errorMessage}>処理中にエラーが発生しました。</div>
                            <Button className={styles.errorBackButton} onClick={handleBackButtonClick}>
                                戻る
                            </Button>
                        </div>
                    ) : (
                        <>
                            {/* <div className={styles.paymentSettingTitle}>お支払い設定</div> */}
                            <button className={styles.editButton} onClick={handleEditButtonClick}>
                                {isEditMode ? (
                                    <div className={styles.edit}>完了</div>
                                ) : (
                                    <>
                                        <AiOutlineEdit className={styles.editIcon} />
                                        <div className={styles.edit}>編集する</div>
                                    </>
                                )}
                            </button>
                            <div className={styles.bankAccountListWrapper}>
                                <div className={styles.bankAccountTitle}>銀行口座</div>
                                {isEditMode && (
                                    <div className={styles.notice}>デフォルトの銀行口座は削除できません。</div>
                                )}
                                {modifiedBankAccountList.length === 0 ? (
                                    <div className={styles.emptyBankAccount}>登録されている銀行口座がありません。</div>
                                ) : (
                                    <ul className={styles.bankAccountList}>
                                        {modifiedBankAccountList.map((bankAccount) => (
                                            <li key={bankAccount.id} className={styles.bankAccount}>
                                                {isEditMode ? (
                                                    <EditModeContents
                                                        bankAccount={bankAccount}
                                                        defaultBankAccountId=""
                                                        handleBackButtonClick={handleBackButtonClick}
                                                    />
                                                ) : (
                                                    <button
                                                        className={`${styles.bankAccountButton} ${styles.hoverTransparent}`}
                                                        onClick={() => {
                                                            handleBankAccountButtonClick(bankAccount.id);
                                                        }}
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <MuiThemeProvider theme={theme}>
                                                            <Checkbox
                                                                icon={<MdRadioButtonUnchecked />}
                                                                checkedIcon={<MdRadioButtonChecked />}
                                                                checked={bankAccount.id === newDefaultBankAccountId}
                                                            />
                                                        </MuiThemeProvider>
                                                        <div className={styles.bankAccountBody}>
                                                            <div className={styles.bankAccountTopWrapper}>
                                                                <div className={styles.bankFullName}>
                                                                    {bankAccount.bankFullName ?? "〇〇銀行"}
                                                                </div>
                                                                <div className={styles.branchName}>
                                                                    {bankAccount.branchName ?? "〇〇支店"}
                                                                </div>
                                                            </div>
                                                            <div className={styles.accountNumber}>
                                                                ****{bankAccount.last3}
                                                            </div>
                                                            <div className={styles.accountHolderName}>
                                                                {bankAccount.accountHolderName}
                                                            </div>
                                                        </div>
                                                    </button>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                {!isEditMode && (
                                    <button
                                        onClick={handleRegisterNewBankAccountButtonClick}
                                        className={styles.registerNewCreditCardButton}
                                    >
                                        <AiOutlinePlusCircle className={styles.plusIcon} />
                                        <div className={styles.plus}>新しい銀行口座を登録する</div>
                                    </button>
                                )}
                            </div>
                            {!isEditMode && (
                                <Button
                                    className={defaultChanged ? styles.updateButton : styles.disabledButton}
                                    onClick={handleUpdateButtonClick}
                                >
                                    {isUpdating ? <Processing /> : <div className={styles.update}>更新する</div>}
                                </Button>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};
