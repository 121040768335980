import styles from "@/pageComponents/Common/MyPage/Setting/index.module.scss";

import { MyPage } from ".";

import { memo, useEffect } from "react";

import { SettingContents } from "@/pageComponents/Common/MyPage/Setting/SettingContents";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { Navigation } from "@/components/Navigation";

const NAVIGATION_LIST: Navigation[] = [
    { title: "マイページ", url: "/MyPage/Profile" },
    { title: "設定", url: "/MyPage/PointsRecordHistory" },
];

export const Setting: React.VFC = memo(function TeacherNotice() {
    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    return (
        <MyPage title="設定" activeTab="setting">
            <div className={styles.notificationsSettingWrapper}>
                <SettingContents />
            </div>
        </MyPage>
    );
});
