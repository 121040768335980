import { memo } from "react";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { useGetPublicRequestQuery } from "@/store/hooks/publicRequests";
import { CommonComponent } from "./Common";

interface Props {
    currentRequestId: string;
}

export const UpdateComponent: React.VFC<Props> = memo(function UpdateComponent(props) {
    const requestQueryState = useGetPublicRequestQuery(props.currentRequestId);

    return (
        <QueryLoadingWrapper {...requestQueryState}>
            {(publicRequest) => <CommonComponent existingPublicRequest={publicRequest} />}
        </QueryLoadingWrapper>
    );
});
