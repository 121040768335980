import { memo, useCallback, useEffect, useState } from "react";

import { Button } from "@material-ui/core";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";

import {
    ELEMENTARY_SCHOOL_SUBJECT_CONFIG,
    HIGH_SCHOOL_SUBJECT_CONFIG,
    JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG,
    SubjectConfig,
    UNIVERSITY_SUBJECT_CONFIG,
} from "@/SubjectConfig";
import styles from "../index.module.scss";

import { SelectTemplate } from "./SelectTemplate";
import { SubjectQueries } from "@/pages/Common/CourseSearchResults";

interface Props {
    targetSubject: SubjectQueries;
    setTargetSubject: React.Dispatch<React.SetStateAction<SubjectQueries>>;
    checkNarrowDownHeight: () => void;
}

export const SubjectConditions: React.VFC<Props> = memo(function SubjectCondition(props) {
    const [accordionOpen, setAccordionOpen] = useState<boolean>(false);

    useEffect(() => {
        const level = props.targetSubject.level;
        if (level) {
            setAccordionOpen(true);
        } else {
            setAccordionOpen(false);
        }
    }, [props.targetSubject]);

    const handleChange = useCallback(
        (
            e: React.ChangeEvent<{
                value: unknown;
            }>,
            key: keyof SubjectQueries,
        ) => {
            const value = e.target.value as string;
            if (key === "level") {
                props.setTargetSubject({ ...props.targetSubject, level: value, category: "", subCategory: "" });
            } else if (key === "category") {
                props.setTargetSubject({ ...props.targetSubject, category: value, subCategory: "" });
            } else {
                props.setTargetSubject({ ...props.targetSubject, subCategory: value });
            }
        },
        [props.setTargetSubject, props.targetSubject],
    );

    const changeToJapaneseLevel = useCallback((value: string) => {
        if (value === "university") {
            return "大学生・社会人";
        } else if (value === "highSchool") {
            return "高校生";
        } else if (value === "juniorHighSchool") {
            return "中学生";
        } else if (value === "elementarySchool") {
            return "小学生";
        } else {
            return "";
        }
    }, []);

    const getCategories = useCallback(() => {
        let targetSubjectConfig: SubjectConfig;
        if (props.targetSubject.level === "university") {
            targetSubjectConfig = UNIVERSITY_SUBJECT_CONFIG;
        } else if (props.targetSubject.level === "highSchool") {
            targetSubjectConfig = HIGH_SCHOOL_SUBJECT_CONFIG;
        } else if (props.targetSubject.level === "juniorHighSchool") {
            targetSubjectConfig = JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG;
        } else if (props.targetSubject.level === "elementarySchool") {
            targetSubjectConfig = ELEMENTARY_SCHOOL_SUBJECT_CONFIG;
        } else {
            return [];
        }
        const categories = Object.entries(targetSubjectConfig).map(([key, _]) => {
            return key;
        });
        return categories;
    }, [props.targetSubject]);

    const changeToJapaneseCategory = useCallback(
        (key: string) => {
            let targetSubjectConfig: SubjectConfig;
            if (props.targetSubject.level === "university") {
                targetSubjectConfig = UNIVERSITY_SUBJECT_CONFIG;
            } else if (props.targetSubject.level === "highSchool") {
                targetSubjectConfig = HIGH_SCHOOL_SUBJECT_CONFIG;
            } else if (props.targetSubject.level === "juniorHighSchool") {
                targetSubjectConfig = JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG;
            } else if (props.targetSubject.level === "elementarySchool") {
                targetSubjectConfig = ELEMENTARY_SCHOOL_SUBJECT_CONFIG;
            } else {
                return "";
            }
            const value = targetSubjectConfig[key].label;
            return value;
        },
        [props.targetSubject],
    );

    const getSubCategories = useCallback(() => {
        let targetSubjectConfig: SubjectConfig;
        if (props.targetSubject.level === "university") {
            targetSubjectConfig = UNIVERSITY_SUBJECT_CONFIG;
        } else if (props.targetSubject.level === "highSchool") {
            targetSubjectConfig = HIGH_SCHOOL_SUBJECT_CONFIG;
        } else if (props.targetSubject.level === "juniorHighSchool") {
            targetSubjectConfig = JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG;
        } else if (props.targetSubject.level === "elementarySchool") {
            targetSubjectConfig = ELEMENTARY_SCHOOL_SUBJECT_CONFIG;
        } else {
            return [];
        }
        if (props.targetSubject.category) {
            const sub = targetSubjectConfig[props.targetSubject.category].sub;
            if (sub) {
                const subCategories = Object.entries(sub).map(([key, value]) => {
                    value;
                    const subCategory = key;
                    return subCategory;
                });
                return subCategories;
            } else {
                return [];
            }
        } else {
            return [];
        }
    }, [props.targetSubject]);

    const changeToJapaneseSubCategory = useCallback(
        (key: string) => {
            let targetSubjectConfig: SubjectConfig;
            if (props.targetSubject.level === "university") {
                targetSubjectConfig = UNIVERSITY_SUBJECT_CONFIG;
            } else if (props.targetSubject.level === "highSchool") {
                targetSubjectConfig = HIGH_SCHOOL_SUBJECT_CONFIG;
            } else if (props.targetSubject.level === "juniorHighSchool") {
                targetSubjectConfig = JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG;
            } else if (props.targetSubject.level === "elementarySchool") {
                targetSubjectConfig = ELEMENTARY_SCHOOL_SUBJECT_CONFIG;
            } else {
                return "";
            }
            if (props.targetSubject.category) {
                const sub = targetSubjectConfig[props.targetSubject.category].sub;
                if (sub) {
                    const value = sub[key].label;
                    return value;
                } else {
                    return "";
                }
            } else {
                return "";
            }
        },
        [props.targetSubject],
    );

    const handleAccordionClick = useCallback(() => {
        setAccordionOpen(!accordionOpen);
        props.checkNarrowDownHeight();
    }, [accordionOpen]);

    return (
        <div className={styles.conditionsWrapper} style={{ borderBottom: "1px solid #305077" }}>
            <Button className={styles.accordionButton} onClick={handleAccordionClick}>
                <div className={styles.narrowDownTitle}>科目</div>
                <div className={styles.flex} />
                {accordionOpen ? (
                    <RiArrowUpSLine className={styles.arrow} />
                ) : (
                    <RiArrowDownSLine className={styles.arrow} />
                )}
            </Button>
            {accordionOpen && (
                <div className={styles.conditions}>
                    <SelectTemplate
                        title="レベル"
                        subjectKey="level"
                        value={props.targetSubject.level}
                        items={["university", "highSchool", "juniorHighSchool", "elementarySchool"]}
                        changeToJapanese={changeToJapaneseLevel}
                        handleChange={handleChange}
                    />
                    <SelectTemplate
                        title="カテゴリ"
                        subjectKey="category"
                        value={props.targetSubject.category}
                        items={getCategories()}
                        changeToJapanese={changeToJapaneseCategory}
                        handleChange={handleChange}
                    />
                    <SelectTemplate
                        title="サブカテゴリ"
                        subjectKey="subCategory"
                        value={props.targetSubject.subCategory}
                        items={getSubCategories()}
                        changeToJapanese={changeToJapaneseSubCategory}
                        handleChange={handleChange}
                    />
                </div>
            )}
        </div>
    );
});
