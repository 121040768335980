import { Badge, Button, Hidden, List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import FavoriteIcon from "@material-ui/icons/Favorite";
import HistoryIcon from "@material-ui/icons/History";
import NotificationIcon from "@material-ui/icons/Notifications";
import MailIcon from "@material-ui/icons/Mail";
import PaymentIcon from "@material-ui/icons/Payment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { memo, useCallback } from "react";
import { BiCoinStack } from "react-icons/bi";
import { RiFileList2Line } from "react-icons/ri";
import { VscTriangleRight } from "react-icons/vsc";
import { Link, useHistory } from "react-router-dom";

import styles from "@/pages/Student/MyPage/index.module.scss";
import { AiFillSetting } from "react-icons/ai";
import { RootState } from "@/ducks";
import { useSelector } from "react-redux";
import { useGetStudentCheckedAnnouncementIdsQuery } from "@/store/hooks/students";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { useGetStudentAnnouncementsQuery } from "@/store/hooks/announcements";
import { useGetStudentChatsQueryState } from "@/store/hooks/chats";
import { getStudentHowManyUnreadMessages } from "@/utils/ChatUtils";
import { DraftIcon } from "@/globalIcons";

interface Props {
    fontSize: "small" | "medium";
    setDetailIdx?: (idx: number | undefined) => void;
    setUpdated?: (value: boolean) => void;
    handleBackButtonClick?: () => void;
    activeTab: string;
}

export const MenuBar: React.VFC<Props> = memo(function MenuBar(props) {
    const useStyles = makeStyles(() => ({
        badge: {
            color: "#FFF",
            backgroundColor: "#C09547",
        },
    }));
    const style = useStyles();

    const history = useHistory();

    const studentId = useSelector((state: RootState) => state.jwt.studentId) as string;
    const studentCheckedAnnouncementIdsQueryState = useGetStudentCheckedAnnouncementIdsQuery(studentId);
    const announcementsQueryState = useGetStudentAnnouncementsQuery(studentId);
    const studentChatsQueryState = useGetStudentChatsQueryState(studentId);

    const handleCreatePublicRequestButtonClick = useCallback(() => {
        history.push("/CreatePublicRequest");
    }, []);
    return (
        <>
            <div className={styles.gridMenu}>
                <div className={styles.menuBar}>
                    <List className={styles.menuList}>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "profile"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            {props.handleBackButtonClick ? (
                                <Button
                                    className={styles.menuLink}
                                    onClick={() => {
                                        props.handleBackButtonClick && props.handleBackButtonClick();
                                    }}
                                >
                                    <AssignmentIndIcon className={styles.icon} />
                                    <ListItemText className={styles.itemTitle}>プロフィール</ListItemText>
                                    {props.fontSize === "small" && (
                                        <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                    )}
                                    <Hidden smDown>
                                        {props.activeTab === "profile" && (
                                            <VscTriangleRight className={styles.triangleIcon} />
                                        )}
                                    </Hidden>
                                </Button>
                            ) : (
                                <Button component={Link} to="/MyPage/Profile" className={styles.menuLink}>
                                    <AssignmentIndIcon className={styles.icon} />
                                    <ListItemText className={styles.itemTitle}>プロフィール</ListItemText>
                                    {props.fontSize === "small" && (
                                        <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                    )}
                                    <Hidden smDown>
                                        {props.activeTab === "profile" && (
                                            <VscTriangleRight className={styles.triangleIcon} />
                                        )}
                                    </Hidden>
                                </Button>
                            )}
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "history"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button component={Link} to="/MyPage/History" className={styles.menuLink}>
                                <HistoryIcon className={styles.icon} />
                                <ListItemText className={styles.itemTitle}>最近チェックした講座</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "history" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "favorite"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button component={Link} to="/MyPage/Favorite" className={styles.menuLink}>
                                <FavoriteIcon className={styles.icon} />
                                <ListItemText className={styles.itemTitle}>気になる講座</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "favorite" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "announcements"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button component={Link} to="/MyPage/Announcements" className={styles.menuLink}>
                                <QueryLoadingWrapper
                                    {...announcementsQueryState}
                                    loadingComponent={
                                        <Badge
                                            badgeContent={0}
                                            className={styles.badge}
                                            classes={{ badge: style.badge }}
                                            overlap="rectangular"
                                        >
                                            <NotificationIcon className={styles.icon} />
                                        </Badge>
                                    }
                                >
                                    {(announcements) => (
                                        <QueryLoadingWrapper
                                            {...studentCheckedAnnouncementIdsQueryState}
                                            loadingComponent={
                                                <Badge
                                                    badgeContent={0}
                                                    className={styles.badge}
                                                    classes={{ badge: style.badge }}
                                                    overlap="rectangular"
                                                >
                                                    <NotificationIcon className={styles.icon} />
                                                </Badge>
                                            }
                                        >
                                            {(checkedAnnouncementIds) => {
                                                const howManyUnreadAnnouncements = announcements.filter(
                                                    (announcement) =>
                                                        !checkedAnnouncementIds.includes(announcement.announcementId),
                                                ).length;
                                                return (
                                                    <Badge
                                                        badgeContent={howManyUnreadAnnouncements}
                                                        className={styles.badge}
                                                        classes={{ badge: style.badge }}
                                                        overlap="rectangular"
                                                    >
                                                        <NotificationIcon className={styles.icon} />
                                                    </Badge>
                                                );
                                            }}
                                        </QueryLoadingWrapper>
                                    )}
                                </QueryLoadingWrapper>
                                <ListItemText className={styles.itemTitle}>お知らせ</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "announcements" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "chats"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button component={Link} to="/MyPage/Chats" className={styles.menuLink}>
                                <QueryLoadingWrapper
                                    {...studentChatsQueryState}
                                    loadingComponent={
                                        <Badge
                                            badgeContent={0}
                                            className={styles.badge}
                                            classes={{ badge: style.badge }}
                                            overlap="rectangular"
                                        >
                                            <MailIcon className={styles.icon} />
                                        </Badge>
                                    }
                                >
                                    {(chats) => {
                                        const howManyUnreadMessages = getStudentHowManyUnreadMessages(chats, studentId);
                                        return (
                                            <Badge
                                                badgeContent={howManyUnreadMessages}
                                                className={styles.badge}
                                                classes={{ badge: style.badge }}
                                                overlap="rectangular"
                                            >
                                                <MailIcon className={styles.icon} />
                                            </Badge>
                                        );
                                    }}
                                </QueryLoadingWrapper>
                                <ListItemText className={styles.itemTitle}>チャット一覧</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "chats" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "courseRequests"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button component={Link} to="/MyPage/MyPublicRequests" className={styles.menuLink}>
                                <RiFileList2Line className={styles.icon} />
                                <ListItemText className={styles.itemTitle}>My講座リクエスト一覧</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "courseRequests" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "draftPublicRequests"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button component={Link} to="/MyPage/MyDraftPublicRequests" className={styles.menuLink}>
                                <DraftIcon className={styles.icon} />
                                <ListItemText className={styles.itemTitle}>下書き講座リクエスト一覧</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "draftPublicRequests" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "reservedCourses"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button component={Link} to="/MyPage/ReservedCourses" className={styles.menuLink}>
                                <CheckCircleOutlineIcon className={styles.icon} />
                                <ListItemText className={styles.itemTitle}>予約済みの講座一覧</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "reservedCourses" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "lessonCalendar"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button component={Link} to="/MyPage/LessonCalendar" className={styles.menuLink}>
                                <CalendarMonthIcon className={styles.icon} />
                                <ListItemText className={styles.itemTitle}>授業カレンダー</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "lessonCalendar" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        {/* <ListItem
                            button
                            className={styles.menuItem}
                            style={{ backgroundColor: props.activeTab === "pastCourses" ? "#EAEAEA" : "FFF" }}
                        >
                            <Button component={Link} to="/MyPage/PastCourses" className={styles.menuLink}>
                                <MenuBookIcon className={styles.icon} />
                                <ListItemText className={styles.itemTitle}>受講履歴</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "pastCourses" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem> */}
                        {/* <ListItem
                            button
                            className={styles.menuItem}
                            style={{ backgroundColor: props.activeTab === "privacy" ? "#EAEAEA" : "FFF" }}
                        >
                            {props.setUpdated ? (
                                <Button
                                    className={styles.menuLink}
                                    onClick={() => {
                                        props.setUpdated && props.setUpdated(false);
                                    }}
                                >
                                    <SecurityIcon className={styles.icon} />
                                    <ListItemText className={styles.itemTitle}>プライバシー設定</ListItemText>
                                    {props.fontSize === "small" && (
                                        <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                    )}
                                    <Hidden smDown>
                                        {props.activeTab === "privacy" && (
                                            <VscTriangleRight className={styles.triangleIcon} />
                                        )}
                                    </Hidden>
                                </Button>
                            ) : (
                                <Button component={Link} to="/MyPage/Privacy" className={styles.menuLink}>
                                    <SecurityIcon className={styles.icon} />
                                    <ListItemText className={styles.itemTitle}>プライバシー設定</ListItemText>
                                    {props.fontSize === "small" && (
                                        <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                    )}
                                    <Hidden smDown>
                                        {props.activeTab === "privacy" && (
                                            <VscTriangleRight className={styles.triangleIcon} />
                                        )}
                                    </Hidden>
                                </Button>
                            )}
                        </ListItem> */}
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "paymentSetting"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button component={Link} to="/MyPage/PaymentSetting" className={styles.menuLink}>
                                <PaymentIcon className={styles.icon} />
                                <ListItemText className={styles.itemTitle}>お支払い設定</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "paymentSetting" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "pointsRecordHistory"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button component={Link} to="/MyPage/PointsRecordHistory" className={styles.menuLink}>
                                <BiCoinStack className={styles.icon} />
                                <ListItemText className={styles.itemTitle}>ポイント履歴</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "pointsRecordHistory" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={{
                                borderBottom: "none",
                                backgroundColor: props.activeTab === "setting" ? "#EAEAEA" : "FFF",
                            }}
                        >
                            <Button component={Link} to="/MyPage/Setting" className={styles.menuLink}>
                                <AiFillSetting className={styles.icon} />
                                <ListItemText className={styles.itemTitle}>設定</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "setting" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                    </List>
                </div>
                <Button className={styles.createPublicRequestButton} onClick={handleCreatePublicRequestButtonClick}>
                    <AddIcon className={styles.createPublicRequestIcon} />
                    <div className={styles.createPublicRequest}>講座リクエスト作成</div>
                </Button>
            </div>
        </>
    );
});
