import styles1 from "../../index.module.scss";
import styles2 from "./index.module.scss";
import { CreateLessonRequestParams, LessonResponse } from "@/store/autogenApi";
import { ErrorMessage } from "@/components/ErrorMessage";
import { memo } from "react";
import { LessonCalendarButton } from "@/components/LessonCalendarButton";
import { getLessonTime } from "@/utils/LessonUtils";

interface Props {
    isChecked: boolean;
    isConfirmMode?: boolean;
    existingLessons: LessonResponse[];
    lessons: CreateLessonRequestParams[];
    lessonsDuplicateValidation: boolean;
    otherCourseLessonDuplicateValidation: boolean;
    lengthValidation: boolean;
    price: number;
    handleLessonsChange: (newLessons: CreateLessonRequestParams[]) => void;
}

export const ShortCourse: React.VFC<Props> = memo(function ShortCourse(props) {
    return (
        <>
            {props.isConfirmMode ? (
                <div className={styles1.openTrue}>
                    <div className={styles1.schedulesList}>
                        <div className={styles1.schedulesWrapper}>
                            <ul className={styles1.schedules}>
                                {props.lessons?.map((lesson, idx) => {
                                    return (
                                        <li className={styles1.schedule} key={idx}>
                                            {getLessonTime(lesson)}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className={styles2.lessonsWrapper}>
                        <LessonCalendarButton
                            lessons={props.lessons}
                            existingLessons={props.existingLessons}
                            isChecked={props.isChecked}
                            lessonsDuplicateValidation={props.lessonsDuplicateValidation}
                            otherCourseLessonDuplicateValidation={props.otherCourseLessonDuplicateValidation}
                            handleLessonsChange={props.handleLessonsChange}
                        />
                    </div>
                    <ErrorMessage
                        content="この項目は必須です。少なくとも1つの日程を入力してください。"
                        when={props.isChecked && !props.lengthValidation}
                        style={{ marginTop: "10px" }}
                    />
                </>
            )}
        </>
    );
});
