import { Button, Modal } from "@material-ui/core";
import { memo } from "react";
import styles from "./index.module.scss";
import { Processing } from "../Processing";

interface Props {
    open: boolean;
    isCreating: boolean;
    handleClose: () => void;
    handleCreateMeetingButtonClick: () => void;
}

export const PersonalMeetingModal: React.VFC<Props> = memo(function PersonalMeetingModal(props) {
    return (
        <Modal open={props.open} onClose={props.handleClose}>
            <div className={styles.modalContents}>
                <div className={styles.meetingDescription}>
                    無料のミーティングを作成します。このミーティングに対して料金は発生しません。授業時間外の補足や質問対応などにご利用ください。
                </div>
                <div className={styles.buttonsWrapper}>
                    <Button className={styles.closeButton} onClick={props.handleClose}>
                        閉じる
                    </Button>
                    <Button className={styles.createMeetingButton} onClick={props.handleCreateMeetingButtonClick}>
                        {props.isCreating ? <Processing /> : <>ミーティングを作成</>}
                    </Button>
                </div>
            </div>
        </Modal>
    );
});
