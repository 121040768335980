import { ClassResponse } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions } from "./utils/types";

export const useGetClassQuery = (classId: string) => {
    const result = defaultApi.endpoints.getClassByClassId.useQuery({ classId: classId });
    return useQueryResult(result, (response: ClassResponse) => response);
};

export const useWithdrawFromClassMutation = (options?: UseMutationOptions) => {
    const [withdrawFromClass] = defaultApi.endpoints.withdrawFromClass.useMutation();
    return useMutationFunctionWrapper(withdrawFromClass, options?.errorMessages);
};

export const useReserveShortCourseMutation = (options?: UseMutationOptions) => {
    const [reserveShortCourse] = defaultApi.endpoints.reserveShortCourse.useMutation();
    return useMutationFunctionWrapper(reserveShortCourse, options?.errorMessages);
};

export const useReserveRegularCourseMutation = (options?: UseMutationOptions) => {
    const [reserveRegularCourse] = defaultApi.endpoints.reserveRegularCourse.useMutation();
    return useMutationFunctionWrapper(reserveRegularCourse, options?.errorMessages);
};

export const useCloseApplicationMutation = (options?: UseMutationOptions) => {
    const [closeApplication] = defaultApi.endpoints.closeApplication.useMutation();
    return useMutationFunctionWrapper(closeApplication, options?.errorMessages);
};

export const useApplyForUpdateMonthlyFeeMutation = (options?: UseMutationOptions) => {
    const [applyForUpdateMonthlyFee] = defaultApi.endpoints.applyForUpdateMonthlyFee.useMutation();
    return useMutationFunctionWrapper(applyForUpdateMonthlyFee, options?.errorMessages);
};

export const useApplyForTemporaryUpdateMonthlyFeeMutation = (options?: UseMutationOptions) => {
    const [applyForTemporaryUpdateMonthlyFee] = defaultApi.endpoints.applyForTemporaryUpdateMonthlyFee.useMutation();
    return useMutationFunctionWrapper(applyForTemporaryUpdateMonthlyFee, options?.errorMessages);
};

export const useDisapproveUpdateMonthlyFeeMutation = (options?: UseMutationOptions) => {
    const [disapproveUpdateMonthlyFee] = defaultApi.endpoints.disapproveUpdateMonthlyFee.useMutation();
    return useMutationFunctionWrapper(disapproveUpdateMonthlyFee, options?.errorMessages);
};

export const useApproveUpdateMonthlyFeeMutation = (options?: UseMutationOptions) => {
    const [approveUpdateMonthlyFee] = defaultApi.endpoints.approveUpdateMonthlyFee.useMutation();
    return useMutationFunctionWrapper(approveUpdateMonthlyFee, options?.errorMessages);
};

export const useDisapproveTemporaryUpdateMonthlyFeeMutation = (options?: UseMutationOptions) => {
    const [disapproveTemporaryUpdateMonthlyFee] =
        defaultApi.endpoints.disapproveTemporaryUpdateMonthlyFee.useMutation();
    return useMutationFunctionWrapper(disapproveTemporaryUpdateMonthlyFee, options?.errorMessages);
};

export const useApproveTemporaryUpdateMonthlyFeeMutation = (options?: UseMutationOptions) => {
    const [approveTemporaryUpdateMonthlyFee] = defaultApi.endpoints.approveTemporaryUpdateMonthlyFee.useMutation();
    return useMutationFunctionWrapper(approveTemporaryUpdateMonthlyFee, options?.errorMessages);
};

export const reportToCompleteClassMutation = (options?: UseMutationOptions) => {
    const [reportToCompleteClass] = defaultApi.endpoints.reportToCompleteClass.useMutation();
    return useMutationFunctionWrapper(reportToCompleteClass, options?.errorMessages);
};

export const useUpdateMemoMutation = (options?: UseMutationOptions) => {
    const [updateMemo] = defaultApi.endpoints.updateMemo.useMutation();
    return useMutationFunctionWrapper(updateMemo, options?.errorMessages);
};

export const useUpdateClassNameMutation = (options?: UseMutationOptions) => {
    const [updateClassName] = defaultApi.endpoints.updateClassName.useMutation();
    return useMutationFunctionWrapper(updateClassName, options?.errorMessages);
};

export const useAddClassMutation = (options?: UseMutationOptions) => {
    const [addClass] = defaultApi.endpoints.addClass.useMutation();
    return useMutationFunctionWrapper(addClass, options?.errorMessages);
};
