import { FAQDetailsBase } from "../../FAQDetailsBase";

import { COURSE_FLOW_CONFIGS } from "./CourseFlowConfigs";

export const CourseFlow: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="講座開催までの流れ"
            text="ここでは講座開催までの流れをご説明します。講座の種類ごとにの開催までの流れが異なります。"
            FAQConfigs={COURSE_FLOW_CONFIGS}
            list={["単発・短期講座", "定期講座"]}
        />
    );
};
