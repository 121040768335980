import { createTheme, MuiThemeProvider, TextField } from "@material-ui/core";
import { memo } from "react";

import styles from "./index.module.scss";

interface Props {
    placeholder: string;
    name: string;
    value: string;
    handleChange?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    handleIsComposingChange?: (value: boolean) => void;
}

export const StyledTextField: React.VFC<Props> = memo(function StyledTextField(props) {
    const theme = createTheme({
        overrides: {
            MuiOutlinedInput: {
                input: {
                    color: "#305077",
                    fontSize: "14px",
                    padding: "12px 10px !important",
                    "@media (max-width: 600px)": {
                        fontSize: "13px",
                        padding: "10px !important",
                    },
                    "&::placeholder": {
                        color: "#305077",
                        fontSize: "14px",
                        "@media (max-width: 600px)": {
                            fontSize: "13px",
                        },
                    },
                },
            },
            MuiFormControl: {
                marginNormal: {
                    marginTop: "0 !important",
                    marginBottom: "0 !important",
                },
            },
        },
    });

    return (
        <MuiThemeProvider theme={theme}>
            <TextField
                label=""
                variant="outlined"
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                className={styles.input}
                name={props.name}
                value={props.value}
                placeholder={props.placeholder}
                onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                onChange={props.handleChange}
                onCompositionStart={() => {
                    props.handleIsComposingChange && props.handleIsComposingChange(true);
                }}
                onCompositionEnd={() => {
                    props.handleIsComposingChange && props.handleIsComposingChange(false);
                }}
            />
        </MuiThemeProvider>
    );
});
