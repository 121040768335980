import { memo } from "react";
import styles from "./index.module.scss";
import { Mandatory } from "@/components/Tag/Mandatory";
import { Optional } from "@/components/Tag/Optional";

interface Props {
    title: string;
    isConfirmMode?: boolean;
    isOptional?: boolean;
    borderTop?: boolean;
    hasSpecialRightWrapper?: boolean;
    inputModeContents: React.ReactNode;
    confirmModeContents: React.ReactNode;
}

export const ListItem: React.VFC<Props> = memo(function ListItem(props) {
    return (
        <li className={styles.listItem} style={{ borderTop: props.borderTop ? "1px solid #CCC" : "none" }}>
            <div className={styles.leftWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>{props.title}</div>
                    {!props.isConfirmMode && <>{props.isOptional ? <Optional /> : <Mandatory />}</>}
                </div>
            </div>
            <div
                className={
                    props.hasSpecialRightWrapper
                        ? `${styles.rightWrapper} ${styles.specialRightWrapper}`
                        : styles.rightWrapper
                }
            >
                {props.isConfirmMode ? (
                    <div className={styles.confirmModeContents}>{props.confirmModeContents}</div>
                ) : (
                    <div className={styles.editModeContents}>{props.inputModeContents}</div>
                )}
            </div>
        </li>
    );
});
