import { MyDraftPublicRequestCard } from "./MyDraftPublicRequestCard";
import styles from "./index.module.scss";
import { DraftPublicRequestResponse } from "@/store/autogenApi";

interface Props {
    visible: boolean;
    draftPublicRequests: DraftPublicRequestResponse[];
    repetition: number;
    handleDraftPublicRequestCardClick: (request: DraftPublicRequestResponse) => void;
}

export const MyDraftPublicRequestsTabContents: React.VFC<Props> = (props) => {
    return (
        <div role="tabpanel" hidden={!props.visible} className={styles.tabContents}>
            {props.visible && (
                <>
                    {props.draftPublicRequests.length === 0 ? (
                        <div className={styles.emptyDraftPublicRequests}>対象の講座がありません。</div>
                    ) : (
                        <div className={styles.cardsList}>
                            {[...Array(Math.ceil(props.draftPublicRequests.length / props.repetition))]
                                .map((_, i) => i)
                                .map((idx) => (
                                    <>
                                        <div className={styles.cards} key={idx}>
                                            {props.draftPublicRequests
                                                .slice(
                                                    idx * props.repetition,
                                                    idx * props.repetition + props.repetition,
                                                )
                                                .map((draftPublicRequest) => {
                                                    return (
                                                        <MyDraftPublicRequestCard
                                                            draftPublicRequest={draftPublicRequest}
                                                            key={draftPublicRequest.draftPublicRequestId}
                                                            isFlex
                                                            handleDraftPublicRequestCardClick={
                                                                props.handleDraftPublicRequestCardClick
                                                            }
                                                        />
                                                    );
                                                })}
                                            {props.draftPublicRequests.slice(
                                                idx * props.repetition,
                                                idx * props.repetition + props.repetition,
                                            ).length < props.repetition &&
                                                [
                                                    ...Array(
                                                        props.repetition -
                                                            props.draftPublicRequests.slice(
                                                                idx * props.repetition,
                                                                idx * props.repetition + props.repetition,
                                                            ).length,
                                                    ),
                                                ].map((_, idx) => (
                                                    <MyDraftPublicRequestCard
                                                        key={idx}
                                                        isHidden
                                                        isFlex
                                                        handleDraftPublicRequestCardClick={
                                                            props.handleDraftPublicRequestCardClick
                                                        }
                                                    />
                                                ))}
                                        </div>
                                    </>
                                ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
