import { differenceInDays, eachDayOfInterval, endOfMonth, endOfWeek, startOfMonth, startOfWeek } from "date-fns";
import { memo, useCallback, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { WHAT_DAY_LIST } from "@/utils/WhatDayList";
import { CreateLessonRequestParams, LessonResponse } from "@/store/autogenApi";
import { getLessonTimeOnly } from "@/utils/LessonUtils";
import { useParams } from "react-router";

interface Props {
    lessonCalendarStartDate: Date;
    lessons: (LessonResponse | CreateLessonRequestParams)[];
    handleLessonCalendarStartDateChange: (lessonCalendarStartDate: Date) => void;
    handleUnitChange: (value: "week" | "month") => void;
}

export const LessonCalendarOfMonth: React.VFC<Props> = memo((props) => {
    const [days, setDays] = useState<Date[]>([]);
    const [currentRowsEl, setCurrentRowsEl] = useState<HTMLElement | null>(null);
    const [lessonWidth, setLessonWidth] = useState<number>(0);
    const [startOfTheMonth, setStartOfTheMonth] = useState<Date | undefined>(undefined);
    const [endOfTheMonth, setEndOfTheMonth] = useState<Date | undefined>(undefined);
    const [isPastMonth, setIsPastMonth] = useState<boolean>(false);
    const [thisWeekIdx, setThisWeekIdx] = useState<number>(0);

    const { classId } = useParams<{ classId?: string }>();

    const isInMyPage = location.pathname.includes("/MyPage/");
    const isInCourseManagementPage = location.pathname.includes("/CourseManagement/");
    const isInClassPage = location.pathname.includes("/Class/");
    const isInCreateCoursePage = location.pathname.includes("/CreateCourse");

    const rowsRef = useCallback((rowsEl: HTMLElement | null) => {
        setCurrentRowsEl(rowsEl);
    }, []);

    const changeLessonWidth = useCallback(() => {
        if (!currentRowsEl) return;
        // paddingなどを考慮して-10
        const lessonWidth = currentRowsEl.clientWidth / 7 - 10;
        setLessonWidth(lessonWidth);
    }, [currentRowsEl]);

    useEffect(() => {
        changeLessonWidth();
        window.addEventListener("resize", changeLessonWidth);
        return () => {
            window.removeEventListener("resize", changeLessonWidth);
        };
    }, [changeLessonWidth]);

    useEffect(() => {
        const EOW = endOfWeek(props.lessonCalendarStartDate);
        let start: Date;
        if (EOW.getMonth() !== props.lessonCalendarStartDate.getMonth()) {
            // 週初めと週終わりが異なる月の場合は、次月をセット
            start = startOfMonth(EOW);
            setStartOfTheMonth(start);
            props.handleLessonCalendarStartDateChange(start);
        } else {
            start = startOfMonth(props.lessonCalendarStartDate);
            setStartOfTheMonth(start);
        }
        const first = startOfWeek(start);
        const now = new Date();
        const difference = differenceInDays(now, first);
        const newThisWeekIdx = Math.floor(difference / 7);
        setThisWeekIdx(newThisWeekIdx);
        const end = endOfMonth(props.lessonCalendarStartDate);
        setEndOfTheMonth(end);
        const last = endOfWeek(end);
        setIsPastMonth(last.getTime() < now.getTime());
        const newDays = eachDayOfInterval({ start: first, end: last });
        setDays(newDays);
    }, [props.lessonCalendarStartDate]);

    const getIsLessonValid = useCallback(
        (lesson: LessonResponse | CreateLessonRequestParams) => {
            // マイページは全て
            if (isInMyPage) return true;
            // 講座・クラスページはclassIdが一致するものと新規授業
            if (isInCourseManagementPage || isInClassPage) {
                if (!lesson.hasOwnProperty("lessonId")) return true;
                return (lesson as LessonResponse).class?.classId === classId;
            }
            // 講座作成ページは新規授業のみ
            if (isInCreateCoursePage) return !lesson.hasOwnProperty("lessonId");
            return false;
        },
        [isInMyPage, isInCourseManagementPage, isInClassPage, isInCreateCoursePage, classId],
    );

    const handleRowButtonClick = useCallback(
        (rowIdx: number) => {
            const newDate = days[rowIdx * 7];
            props.handleLessonCalendarStartDateChange(newDate);
            props.handleUnitChange("week");
        },
        [days, props.handleLessonCalendarStartDateChange, props.handleUnitChange],
    );

    const getIsToday = useCallback((date: Date) => {
        const today = new Date();
        return (
            date.getFullYear() === today.getFullYear() &&
            date.getMonth() === today.getMonth() &&
            date.getDate() === today.getDate()
        );
    }, []);

    return (
        <div className={styles.lessonCalendarOfMonth}>
            <div className={styles.lessonCalendarOfMonthHeadings}>
                {[...Array(7)].map((_, idx) => (
                    <div
                        className={
                            idx === 0
                                ? `${styles.lessonCalendarOfMonthHeading} ${styles.sundayHeading}`
                                : idx === 6
                                ? `${styles.lessonCalendarOfMonthHeading} ${styles.saturdayHeading}`
                                : styles.lessonCalendarOfMonthHeading
                        }
                    >
                        {WHAT_DAY_LIST[idx]}
                    </div>
                ))}
            </div>
            <div className={styles.rows} ref={rowsRef}>
                {[...Array(days.length / 7)].map((_, rowIdx) => (
                    <div
                        className={styles.row}
                        style={{
                            pointerEvents: !isPastMonth && thisWeekIdx <= rowIdx ? "auto" : "none",
                        }}
                        onClick={() => {
                            handleRowButtonClick(rowIdx);
                        }}
                    >
                        {[...Array(7)].map((_, colIdx) => {
                            const idx = rowIdx * 7 + colIdx;
                            const date = days[idx];
                            const startOfMonthDay = startOfTheMonth ? startOfTheMonth.getDay() : undefined;
                            const isThisMonth =
                                rowIdx === 0
                                    ? // 1行目はstartOfMonthDay以降
                                      startOfMonthDay != undefined && startOfMonthDay + 1 <= colIdx + 1
                                    : startOfMonthDay != undefined &&
                                      endOfTheMonth &&
                                      //日付<=初日の日付-重複+月末の日付
                                      idx + 1 <= startOfMonthDay + 1 - 1 + endOfTheMonth.getDate();
                            return (
                                <div
                                    className={styles.cell}
                                    style={{
                                        borderTop: rowIdx === 0 ? "1px solid #CCC" : "0",
                                        borderLeft: colIdx === 0 ? "1px solid #CCC" : "0",
                                    }}
                                >
                                    <div
                                        className={styles.date}
                                        style={
                                            getIsToday(date)
                                                ? {
                                                      backgroundColor: "#305077",
                                                      color: "#FFF",
                                                      fontWeight: "bold",
                                                      // 今月以外は半透明
                                                      opacity: isThisMonth ? 1 : 0.5,
                                                  }
                                                : {
                                                      // 今月以外は半透明
                                                      opacity: isThisMonth ? 1 : 0.5,
                                                  }
                                        }
                                    >
                                        {date.getDate()}
                                    </div>
                                    <ul className={styles.lessons}>
                                        {props.lessons
                                            .filter((lesson) => {
                                                const targetYear = props.lessonCalendarStartDate.getFullYear();
                                                const isTheSameYear =
                                                    new Date(lesson.startTime).getFullYear() === targetYear;
                                                if (!isTheSameYear) return false;
                                                const startTimeMonth = new Date(lesson.startTime).getMonth() + 1;
                                                const targetMonth = props.lessonCalendarStartDate.getMonth() + 1;
                                                const startTimeDate = new Date(lesson.startTime).getDate();
                                                if (!startOfTheMonth) return false;
                                                // 先月で見えている日数
                                                const lastMonthDaysCount = startOfTheMonth.getDay();
                                                if (startTimeMonth === targetMonth) {
                                                    // 今月
                                                    const targetDate = rowIdx * 7 + colIdx + 1;
                                                    return startTimeDate === targetDate - lastMonthDaysCount;
                                                } else if (
                                                    startTimeMonth === targetMonth - 1 &&
                                                    days[0].getTime() <= new Date(lesson.startTime).getTime()
                                                ) {
                                                    // 先月で見えている日付
                                                    if (rowIdx !== 0) return false;
                                                    if (!startOfTheMonth) return false;
                                                    const targetDate = new Date(startOfTheMonth);
                                                    const startOfTheMonthWeekIdx = startOfTheMonth.getDay();
                                                    if (startOfTheMonthWeekIdx <= colIdx) return;
                                                    targetDate.setDate(
                                                        targetDate.getDate() - (startOfTheMonthWeekIdx - colIdx),
                                                    );
                                                    return startTimeDate === targetDate.getDate();
                                                } else if (
                                                    startTimeMonth === targetMonth + 1 &&
                                                    // 最終日より前か最終日
                                                    (new Date(lesson.startTime).getTime() <=
                                                        days[days.length - 1].getTime() ||
                                                        startTimeDate === days[days.length - 1].getDate())
                                                ) {
                                                    // 来月で見えている日付
                                                    if (!endOfTheMonth) return false;
                                                    const targetDate = new Date(endOfTheMonth);
                                                    const endOfTheMonthWeekIdx = endOfTheMonth.getDay();
                                                    if (endOfTheMonthWeekIdx >= colIdx || rowIdx != days.length / 7 - 1)
                                                        return;
                                                    targetDate.setDate(
                                                        endOfTheMonth.getDate() + (colIdx - endOfTheMonthWeekIdx),
                                                    );
                                                    return startTimeDate === targetDate.getDate();
                                                } else {
                                                    return false;
                                                }
                                            })
                                            .map((lesson) => (
                                                <li
                                                    className={styles.lesson}
                                                    style={{
                                                        backgroundColor: getIsLessonValid(lesson) ? "#305077" : "#999",
                                                        // 今月以外は半透明
                                                        opacity: isThisMonth ? 1 : 0.5,
                                                    }}
                                                >
                                                    {lessonWidth > 0 && (
                                                        // ↑この条件ないと1行目のレッスン表示がおかしくなる
                                                        <>
                                                            <div
                                                                className={styles.lessonName}
                                                                style={{ maxWidth: `${lessonWidth}px` }}
                                                            >
                                                                {lesson.hasOwnProperty("lessonId")
                                                                    ? (lesson as LessonResponse).class?.course?.title
                                                                    : "新規授業"}
                                                            </div>
                                                            <div
                                                                className={styles.lessonTime}
                                                                style={{ width: `${lessonWidth}px` }}
                                                            >
                                                                {getLessonTimeOnly(lesson)}
                                                            </div>
                                                        </>
                                                    )}
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
});
