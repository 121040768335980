import { memo } from "react";
import { Button } from "@material-ui/core";
import styles from "./index.module.scss";

interface Props {
    className?: string;
    isCentered?: boolean;
    isFlex?: boolean;
    fontSize?: number;
    children: React.ReactNode;
    border?: boolean;
    handleClick: (params?: any) => void;
}

export const WhiteButton: React.VFC<Props> = memo(function WhiteButton(props) {
    return (
        <Button
            className={`${props.className} ${styles.whiteButton} ${props.isCentered ? ` ${styles.center}` : ""}`}
            style={{
                flex: props.isFlex ? "1" : "auto",
                fontSize: props.fontSize ? `${props.fontSize}px` : "14px",
                ...(props.border === false ? { border: "none", boxShadow: "none" } : { border: "1px solid #305077" }),
            }}
            onClick={props.handleClick}
        >
            {props.children}
        </Button>
    );
});
