import { Button, Hidden } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import styles1 from "@/pages/Teacher/CreateCourse/index.module.scss";
import styles2 from "./index.module.scss";
import { CreateLessonRequestParams, LessonResponse } from "@/store/autogenApi";
import { WHAT_DAY_LIST } from "@/utils/WhatDayList";
import { memo, useCallback, useState } from "react";
import { ErrorMessage } from "@/components/ErrorMessage";
import { BiTimeFive } from "react-icons/bi";
import { DeadlinePopover } from "@/components/DeadlinePopover";
import { DatePicker } from "@/components/DatePicker";
import { LessonCalendarButton } from "@/components/LessonCalendarButton";
import { HourAndMinute } from "@/utils/DateUtils";

interface Props {
    checked: boolean;
    confirmModalOpen?: boolean;
    scheduleAnchorEl: HTMLButtonElement | null;
    deadlineValidation: boolean;
    otherCourseScheduleValidation: boolean;
    lengthValidation: boolean;
    newStartTime: HourAndMinute;
    newEndTime: HourAndMinute;
    newDeadlineTime: HourAndMinute;
    isNotDuplicateValidation: boolean;
    existingLessons: LessonResponse[];
    lessons: CreateLessonRequestParams[];
    applyingDeadline: Date;
    handleSchedulePopoverClose: () => void;
    handleDeadlineDateChange: (newDate: Date) => void;
    handleDeadlineTimeChange: (
        e: React.ChangeEvent<{
            name: string;
            value: number;
        }>,
    ) => void;
    handleStartTimeChange: (
        e: React.ChangeEvent<{
            name: string;
            value: number;
        }>,
    ) => void;
    handleEndTimeChange: (
        e: React.ChangeEvent<{
            name: string;
            value: number;
        }>,
    ) => void;
    deleteTargetLesson: (idx: number) => void;
    handleNewDeadlineTimeChange: (hourAndMinute: HourAndMinute) => void;
    handleLessonsChange: (newLessons: CreateLessonRequestParams[]) => void;
    handleApplyingDeadlineChange: (newApplyingDeadline: Date) => void;
}

export interface StartAndFinishDate {
    startDate: Date;
    finishDate: Date;
}

export const ShortCourse: React.VFC<Props> = memo(function ShortCourse(props) {
    const [deadlineAnchorEl, setDeadlineAnchorEl] = useState<HTMLButtonElement | null>(null);

    const deadlinePopoverOpen = Boolean(deadlineAnchorEl);

    const getJapanesePeriod = useCallback((startTime: Date, endTime: Date) => {
        const month = startTime.getMonth() + 1;
        const date = startTime.getDate();
        const startHour = startTime.getHours();
        const startMinute = startTime.getMinutes();
        const finishHour = endTime.getHours();
        const finishMinute = endTime.getMinutes();
        const fixedMonth = ("00" + month).slice(-2);
        const fixedDate = ("00" + date).slice(-2);
        const fixedStartHour = ("00" + startHour).slice(-2);
        const fixedStartMinute = ("00" + startMinute).slice(-2);
        const fixedFinishHour = ("00" + finishHour).slice(-2);
        const fixedFinishMinute = ("00" + finishMinute).slice(-2);
        const dayIdx = startTime.getDay();
        const result = `${fixedMonth}月${fixedDate}日(${WHAT_DAY_LIST[dayIdx]}) ${fixedStartHour}:${fixedStartMinute} ~ ${fixedFinishHour}:${fixedFinishMinute}`;
        return result;
    }, []);

    const getIsDisabledDeadline = useCallback((date: MaterialUiPickersDate) => {
        if (!date) return true;
        const fixedDate = date as any as Date;
        const midnight = new Date();
        midnight.setHours(0, 0, 0, 0);
        return fixedDate < midnight;
    }, []);

    const getApplicationPeriod = useCallback((date: Date) => {
        const now = new Date();
        let diffTime = date.getTime() - now.getTime();
        const diffDate = Math.floor(diffTime / (1000 * 24 * 60 * 60));
        diffTime -= diffDate * 1000 * 24 * 60 * 60;
        const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
        return `${diffDate}日と${diffHours}時間`;
    }, []);

    const getJapaneseTime = useCallback((value: Date) => {
        const month = value.getMonth() + 1;
        const date = value.getDate();
        const hour = value.getHours();
        const minute = value.getMinutes();
        const fixedMonth = ("00" + month).slice(-2);
        const fixedDate = ("00" + date).slice(-2);
        const fixedStartHour = ("00" + hour).slice(-2);
        const fixedStartMinute = ("00" + minute).slice(-2);
        const dayIdx = value.getDay();
        const result = `${fixedMonth}月${fixedDate}日(${WHAT_DAY_LIST[dayIdx]}) ${fixedStartHour}:${fixedStartMinute}`;
        return result;
    }, []);

    const handleTimeButtonForDeadlineClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setDeadlineAnchorEl(event.currentTarget);
            const applyingDeadline = props.applyingDeadline;
            props.handleNewDeadlineTimeChange({
                hour: applyingDeadline ? new Date(applyingDeadline).getHours() : null,
                minute: applyingDeadline ? new Date(applyingDeadline).getMinutes() : null,
            });
        },
        [props.applyingDeadline],
    );

    const handleDeadlinePopoverClose = useCallback(() => {
        setDeadlineAnchorEl(null);
    }, []);

    const confirmDeadlineTime = useCallback(() => {
        const applyingDeadline = new Date(props.applyingDeadline);
        if (typeof props.newDeadlineTime.hour === "number") {
            applyingDeadline.setHours(props.newDeadlineTime.hour);
        }
        if (typeof props.newDeadlineTime.minute === "number") {
            applyingDeadline.setMinutes(props.newDeadlineTime.minute);
        }
        props.handleApplyingDeadlineChange(applyingDeadline);
        handleDeadlinePopoverClose();
    }, [props.newDeadlineTime, props.applyingDeadline, props.handleApplyingDeadlineChange, handleDeadlinePopoverClose]);

    return (
        <>
            {props.confirmModalOpen ? (
                <div className={styles1.openTrue}>
                    <div className={styles1.schedulesWrapper}>
                        <ul className={styles1.schedules}>
                            {props.lessons?.map((lesson, idx2) => {
                                return (
                                    <li className={styles1.schedule} key={idx2}>
                                        ・
                                        {lesson.startTime &&
                                            lesson.endTime &&
                                            getJapanesePeriod(new Date(lesson.startTime), new Date(lesson.endTime))}
                                    </li>
                                );
                            })}
                        </ul>
                        {props.applyingDeadline && (
                            <div className={styles1.deadlineWrapper}>
                                <div className={styles1.deadline}>
                                    {`募集締め切り：${getJapaneseTime(new Date(props.applyingDeadline))}`}
                                </div>
                                <div className={styles1.applicationPeriod}>
                                    {`(残り${getApplicationPeriod(new Date(props.applyingDeadline))})`}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <>
                    <div className={styles2.lessonCalendarWrapper}>
                        <LessonCalendarButton
                            isChecked={props.checked}
                            lessons={props.lessons}
                            existingLessons={props.existingLessons}
                            lessonsDuplicateValidation={props.isNotDuplicateValidation}
                            otherCourseLessonDuplicateValidation={props.otherCourseScheduleValidation}
                            handleLessonsChange={props.handleLessonsChange}
                        />
                        <ErrorMessage
                            content="他の講座日程と被っているため、この日程では登録できません。"
                            when={props.checked && !props.otherCourseScheduleValidation}
                            style={{ textAlign: "left", width: "100%", marginTop: "10px" }}
                        />
                    </div>
                    <ErrorMessage
                        content="この項目は必須です。少なくとも1つの日程を入力してください。"
                        when={props.checked && !props.lengthValidation}
                        style={{ textAlign: "left", width: "100%", marginTop: "10px" }}
                    />
                    <div className={styles2.deadlineWrapper}>
                        <div className={styles2.deadlineFlexWrapper}>
                            <div className={styles2.deadlineTitle}>募集締め切り：</div>
                            <div className={styles2.deadlineInnerDiv}>
                                <div className={styles2.dateAndTimeWrapper}>
                                    <DatePicker
                                        date={props.applyingDeadline}
                                        handleDateChange={props.handleDeadlineDateChange}
                                        getIsDisabledDate={getIsDisabledDeadline}
                                    />
                                    <Button
                                        className={styles2.timeButton}
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                            handleTimeButtonForDeadlineClick(e);
                                        }}
                                    >
                                        <div className={styles2.selectedTimesWrapper}>
                                            <div className={styles2.selectedTime}>
                                                <span className={styles2.whichTime}>
                                                    {(
                                                        "0" +
                                                        (props.applyingDeadline
                                                            ? new Date(props.applyingDeadline).getHours()
                                                            : 0)
                                                    ).slice(-2)}
                                                </span>
                                                <span className={styles2.colon}>:</span>
                                                <span className={styles2.whichTime}>
                                                    {(
                                                        "0" +
                                                        (props.applyingDeadline
                                                            ? new Date(props.applyingDeadline).getMinutes()
                                                            : 0)
                                                    ).slice(-2)}
                                                </span>
                                            </div>
                                        </div>
                                        <Hidden smDown>
                                            <BiTimeFive className={styles2.timeIcon} />
                                        </Hidden>
                                    </Button>
                                    <DeadlinePopover
                                        open={deadlinePopoverOpen}
                                        anchorEl={deadlineAnchorEl}
                                        newDeadlineTime={props.newDeadlineTime}
                                        confirmDeadlineTime={confirmDeadlineTime}
                                        handleClose={handleDeadlinePopoverClose}
                                        handleDeadlineTimeChange={props.handleDeadlineTimeChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {props.deadlineValidation && props.applyingDeadline && (
                            <div className={styles2.applicationPeriod}>
                                {`(残り${getApplicationPeriod(new Date(props.applyingDeadline))})`}
                            </div>
                        )}
                        {props.lessons.length > 0 && (
                            <ErrorMessage
                                content="締め切り日時は、初回授業日時より前になるように設定してください。"
                                when={!props.deadlineValidation}
                                style={{ textAlign: "left", width: "100%", marginTop: "10px" }}
                            />
                        )}
                    </div>
                </>
            )}
        </>
    );
});
