import {
    Button,
    useMediaQuery,
    // useMediaQuery
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { PublicRequestResponse } from "@/store/autogenApi";
import { useGetMyPublicRequestsQuery } from "@/store/hooks/publicRequests";

import { MyPage } from ".";
import { RootState } from "@/ducks";
import { MyPublicRequestsContents } from "@/pageComponents/Student/MyPage/MyPublicRequests";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { Navigation } from "@/components/Navigation";
import { useEffect } from "react";

const NAVIGATION_LIST: Navigation[] = [
    { title: "マイページ", url: "/MyPage/Profile" },
    { title: "My講座リクエスト一覧", url: "/MyPage/MyPublicRequests" },
];

export const MyPublicRequests: React.VFC = () => {
    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);

    const requestsQueryState = useGetMyPublicRequestsQuery(studentId);

    const sm = useMediaQuery("(min-width:960px)");

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    const getStatus = (publicRequest: PublicRequestResponse) => {
        const publicRequestId = publicRequest.publicRequestId;
        const fetchedDeadline = publicRequest.applyingDeadline;
        if (publicRequestId && fetchedDeadline) {
            const fixedDeadline = new Date(fetchedDeadline);
            const currentTime = new Date();
            const isBeforeDeadline = currentTime < fixedDeadline;
            if (isBeforeDeadline) {
                return "募集中";
            }
            if (publicRequest.proposals) {
                const courseExist = publicRequest.proposals.some((proposal) => proposal.class?.course);
                if (courseExist) {
                    const now = new Date();
                    const courseToBeHeld = publicRequest.proposals
                        .filter((proposal) => proposal.class?.course)
                        .some((proposal) => {
                            if (proposal.class?.course?.classes) {
                                const lastClass =
                                    proposal.class?.course.classes[proposal.class?.course.classes.length - 1];
                                const lastCourse =
                                    lastClass && lastClass.lessons && lastClass.lessons[lastClass.lessons.length - 1];
                                const targetEndTime = lastCourse && lastCourse.endTime;
                                if (targetEndTime) {
                                    const result = new Date(targetEndTime).getTime() - now.getTime() > 0;
                                    return result;
                                } else {
                                    return false;
                                }
                            } else {
                                return false;
                            }
                        });
                    if (courseToBeHeld) {
                        return "授業予定あり";
                    } else {
                        return "全授業完了";
                    }
                } else {
                    return "募集期間終了";
                }
            } else {
                return "募集期間終了";
            }
        }
    };

    const history = useHistory();
    const detail = (publicRequestId: string) => {
        history.push(`/PublicRequestDetails/${publicRequestId}`);
    };

    const getCourseType = (publicRequest: PublicRequestResponse) => {
        if (publicRequest.courseType === "short") return "単発・短期";
        if (publicRequest.courseType === "regular") return "定期";
    };

    const getTargetDateString = (publicRequest: PublicRequestResponse) => {
        if (!publicRequest.updatedAt) return publicRequest.createdAt;
        return publicRequest.updatedAt;
    };

    const getTimestamp = (publicRequest: PublicRequestResponse) => {
        const dateString = getTargetDateString(publicRequest);
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hour = ("00" + date.getHours()).slice(-2);
        const minute = ("00" + date.getMinutes()).slice(-2);
        return `${year}/${month}/${day} ${hour}:${minute}`;
    };

    return (
        <MyPage title="My講座リクエスト一覧" activeTab="courseRequests">
            <QueryLoadingWrapper {...requestsQueryState}>
                {(requests) => <MyPublicRequestsContents publicRequests={requests} />}
            </QueryLoadingWrapper>
        </MyPage>
    );
};
