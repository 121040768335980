import { FAQDetailsBase } from "../../FAQDetailsBase";

export const Profile: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="プロフィールを一般公開にした場合、どんな情報が公開されますか？"
            text="プロフィールを一般公開にした場合、以下の情報が公開されます。"
            list={["ニックネーム", "年齢", "性別", "お住まいの都道府県", "アイコン画像"]}
        />
    );
};
