import { memo, useCallback, useEffect, useState } from "react";

import { Modal } from "@material-ui/core";

import styles from "./index.module.scss";
import { MdOutlineClear } from "react-icons/md";
import { Fade } from "@mui/material";

interface Props {
    open: boolean;
    onClose: () => void;
    children: React.ReactNode;
    style?: React.CSSProperties;
    existCloseButton?: boolean;
    autoResize?: boolean;
    // 親側でmaxModalContentsHeightを取得したい場合に使用（LessonCalendar等）
    handleMaxModalContentsHeight?: (maxModalHeight: number) => void;
}

export const StyledModal: React.VFC<Props> = memo(function StyledModal(props) {
    const [maxModalHeight, setMaxModalHeight] = useState<number>(0);
    const [modalContentsHeight, setModalContentsHeight] = useState<number>(0);
    const [closeButtonEl, setCloseButtonEl] = useState<HTMLElement | null>(null);

    const closeButtonRef = useCallback((el: HTMLElement | null) => {
        setCloseButtonEl(el);
    }, []);

    useEffect(() => {
        if (props.handleMaxModalContentsHeight) {
            props.handleMaxModalContentsHeight(modalContentsHeight);
        }
    }, [props.handleMaxModalContentsHeight, modalContentsHeight]);

    useEffect(() => {
        if (!closeButtonEl) {
            setModalContentsHeight(maxModalHeight);
            return;
        }
        const closeButtonHeight = closeButtonEl.clientHeight;
        const modalContentsHeight = maxModalHeight - closeButtonHeight;
        setModalContentsHeight(modalContentsHeight);
    }, [closeButtonEl, maxModalHeight]);

    useEffect(() => {
        changeModalHeight();
        window.addEventListener("resize", () => {
            changeModalHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalHeight();
            });
        };
    }, []);

    const changeModalHeight = useCallback(() => {
        const innerHeight = window.innerHeight;
        setMaxModalHeight(innerHeight * 0.85);
    }, []);

    return (
        <Modal open={props.open} onClose={props.onClose}>
            <Fade
                in={props.open}
                timeout={{
                    enter: 1000,
                    exit: 500,
                }}
            >
                <div
                    className={styles.modalContentsWrapper}
                    style={props.autoResize ? { maxHeight: maxModalHeight, ...props.style } : props.style}
                >
                    {props.existCloseButton && (
                        <button className={styles.closeButton} onClick={props.onClose} ref={closeButtonRef}>
                            <MdOutlineClear className={styles.closeIcon} />
                        </button>
                    )}
                    <div
                        className={styles.modalContents}
                        style={props.autoResize ? { maxHeight: modalContentsHeight } : {}}
                    >
                        {props.children}
                    </div>
                </div>
            </Fade>
        </Modal>
    );
});
