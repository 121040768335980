import { memo, useEffect } from "react";

import { SetStateAction, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { CreateUserRequestBody, Gender, PrivateUserResponse } from "@/store/autogenApi";
import { useToKanaQuery } from "@/store/hooks/kana";
import { dateToString } from "@/utils/DateUtils";

import { userConfig } from "./Config";
import { FormContents } from "./FormContents";

import { useCognitoUser } from "@/components/Authentication";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { ToKana } from "./ToKana";

interface Props {
    createUserRequestBody: CreateUserRequestBody | undefined;
    nextStep: () => void;
    setCreateUserRequestBody: React.Dispatch<SetStateAction<CreateUserRequestBody | undefined>>;
}

export interface Info {
    nickname: string;
    gender: string;
    birthDate: string;
    prefecture: string;
    profession: string;
    background: string;
    catchCopy: string;
    profile: string;
}

export const ProfileInput: React.VFC<Props> = memo(function ProfileInput(props) {
    const [checked, setChecked] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [firstNameKana, setFirstNameKana] = useState<string>("");
    const [lastNameKana, setLastNameKana] = useState<string>("");
    const [birthDate, setBirthDate] = useState<Date | undefined>(undefined);
    const [isBirthDatePublic, setIsBirthDatePublic] = useState<boolean>(true);
    const [grade, setGrade] = useState<string | undefined>(undefined);
    const [isGradePublic, setIsGradePublic] = useState<boolean>(true);
    const [gender, setGender] = useState<string | undefined>(undefined);
    const [isGenderPublic, setIsGenderPublic] = useState<boolean>(true);
    const [prefecture, setPrefecture] = useState<string | undefined>(undefined);
    const [isPrefecturePublic, setIsPrefecturePublic] = useState<boolean>(true);
    const [isComposing, setIsComposing] = useState<boolean>(false);
    const lastNameKanaQueryState = useToKanaQuery(lastName ?? "");
    const firstNameKanaQueryState = useToKanaQuery(firstName ?? "");

    const userId = useSelector((state: RootState) => state.jwt.userId as string);

    useEffect(() => {
        // 2回目の訪問の場合は、既に入力済みの情報をsetする
        if (props.createUserRequestBody) {
            setFirstName(props.createUserRequestBody.firstName);
            setLastName(props.createUserRequestBody.lastName);
            setFirstNameKana(props.createUserRequestBody.firstNameKana);
            setLastNameKana(props.createUserRequestBody.lastNameKana);
            setBirthDate(new Date(props.createUserRequestBody.birthDate));
            setIsBirthDatePublic(props.createUserRequestBody.isBirthDatePublic);
            setGrade(props.createUserRequestBody.grade);
            setIsGradePublic(props.createUserRequestBody.isGradePublic);
            setGender(props.createUserRequestBody.gender);
            setIsGenderPublic(props.createUserRequestBody.isGenderPublic);
            setPrefecture(props.createUserRequestBody.prefecture);
            setIsPrefecturePublic(props.createUserRequestBody.isPrefecturePublic);
        }
    }, [props.createUserRequestBody]);

    const handleIsBirthDatePublicChange = useCallback(
        (
            e: React.ChangeEvent<{
                value: unknown;
            }>,
        ) => {
            if (e.target.value === "private") {
                setIsBirthDatePublic(false);
            } else {
                setIsBirthDatePublic(true);
            }
        },
        [],
    );

    const handleIsGradePublicChange = useCallback(
        (
            e: React.ChangeEvent<{
                value: unknown;
            }>,
        ) => {
            if (e.target.value === "private") {
                setIsGradePublic(false);
            } else {
                setIsGradePublic(true);
            }
        },
        [],
    );

    const handleIsGenderPublicChange = useCallback(
        (
            e: React.ChangeEvent<{
                value: unknown;
            }>,
        ) => {
            if (e.target.value === "private") {
                setIsGenderPublic(false);
            } else {
                setIsGenderPublic(true);
            }
        },
        [],
    );

    const handleIsPrefecturePublicChange = useCallback(
        (
            e: React.ChangeEvent<{
                value: unknown;
            }>,
        ) => {
            if (e.target.value === "private") {
                setIsPrefecturePublic(false);
            } else {
                setIsPrefecturePublic(true);
            }
        },
        [],
    );

    const handleFirstNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(e.target.value);
        if (!e.target.value) {
            setFirstNameKana("");
        }
    }, []);

    const handleLastNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(e.target.value);
        if (!e.target.value) {
            setLastNameKana("");
        }
    }, []);

    const handleFirstNameKanaChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setFirstNameKana(e.target.value);
    }, []);

    const handleLastNameKanaChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setLastNameKana(e.target.value);
    }, []);

    const handleFirstNameKanaChangeDirectly = useCallback((value) => {
        setFirstNameKana(value);
    }, []);

    const handleLastNameKanaChangeDirectly = useCallback((value) => {
        setLastNameKana(value);
    }, []);

    const handleBirthDateChange = useCallback((birthDate: Date) => {
        setBirthDate(birthDate);
    }, []);

    const handleGradeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setGrade(e.target.value);
    }, []);

    const handleGenderChange = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        setGender(e.target.value as string);
    }, []);

    const handlePrefectureChange = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        setPrefecture(e.target.value as string);
    }, []);

    const isValidUserInfoValue = useCallback((key: keyof PrivateUserResponse, value: string) => {
        const upperLimit = userConfig[key];
        if (upperLimit) {
            return value.length > 0 && value.length <= upperLimit;
        } else {
            false;
        }
    }, []);

    const handleOpen = useCallback(() => {
        const failureFunc = () => {
            setChecked(true);
            window.scrollTo(0, 150);
        };
        const inputComplete =
            firstName && lastName && firstNameKana && lastNameKana && grade && gender && birthDate && prefecture;
        inputComplete ? setOpen(true) : failureFunc();
    }, [isValidUserInfoValue, firstName, lastName, firstNameKana, lastNameKana, grade, gender, birthDate, prefecture]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleIsComposingChange = useCallback((value: boolean) => {
        setIsComposing(value);
    }, []);

    const cognitoUser = useCognitoUser() as any;
    const handleNextStepButtonClick = useCallback(() => {
        if (!cognitoUser) return;
        const email = cognitoUser.attributes.email;
        if (!email) return;
        const createUserRequestBody: CreateUserRequestBody = {
            userId: userId,
            firstName: firstName,
            lastName: lastName,
            firstNameKana: firstNameKana,
            lastNameKana: lastNameKana,
            grade: grade as string,
            isGradePublic: isGradePublic,
            gender: gender as Gender,
            isGenderPublic: isGenderPublic,
            birthDate: dateToString(birthDate as Date),
            isBirthDatePublic: isBirthDatePublic,
            prefecture: prefecture as string,
            isPrefecturePublic: isPrefecturePublic,
            email: email as string,
        };
        props.setCreateUserRequestBody(createUserRequestBody);
        props.nextStep();
    }, [
        firstName,
        lastName,
        firstNameKana,
        lastNameKana,
        grade,
        gender,
        birthDate,
        prefecture,
        isGradePublic,
        isGenderPublic,
        isBirthDatePublic,
        isPrefecturePublic,
        cognitoUser,
    ]);

    return (
        <>
            <FormContents
                checked={checked}
                open={open}
                firstName={firstName}
                lastName={lastName}
                firstNameKana={firstNameKana}
                lastNameKana={lastNameKana}
                birthDate={birthDate}
                isBirthDatePublic={isBirthDatePublic}
                grade={grade}
                isGradePublic={isGradePublic}
                gender={gender}
                isGenderPublic={isGenderPublic}
                prefecture={prefecture}
                isPrefecturePublic={isPrefecturePublic}
                handleFirstNameChange={handleFirstNameChange}
                handleLastNameChange={handleLastNameChange}
                handleFirstNameKanaChange={handleFirstNameKanaChange}
                handleLastNameKanaChange={handleLastNameKanaChange}
                handleBirthDateChange={handleBirthDateChange}
                handleIsBirthDatePublicChange={handleIsBirthDatePublicChange}
                handleGradeChange={handleGradeChange}
                handleIsGradePublicChange={handleIsGradePublicChange}
                handleGenderChange={handleGenderChange}
                handleIsGenderPublicChange={handleIsGenderPublicChange}
                handlePrefectureChange={handlePrefectureChange}
                handleIsPrefecturePublicChange={handleIsPrefecturePublicChange}
                handleOpen={handleOpen}
                handleClose={handleClose}
                handleNextStepButtonClick={handleNextStepButtonClick}
                handleIsComposingChange={handleIsComposingChange}
            />
            <QueryLoadingWrapper {...lastNameKanaQueryState} isHidden>
                {(lastNameKana) => <ToKana value={lastNameKana} handleChange={handleLastNameKanaChangeDirectly} />}
            </QueryLoadingWrapper>
            <QueryLoadingWrapper {...firstNameKanaQueryState} isHidden>
                {(firstNameKana) => <ToKana value={firstNameKana} handleChange={handleFirstNameKanaChangeDirectly} />}
            </QueryLoadingWrapper>
        </>
    );
});
