import styles from "@/pages/Common/TeacherDetails/index.module.scss";
import { PublicTeacherResponse, PublicUserResponse } from "@/store/autogenApi";

import { Background } from "./Background";
import { BasicInfo } from "./BasicInfo";
import { ContentDescription } from "./ContentDescription";
import { Subjects } from "./Subjects";

interface Props {
    teacher: PublicTeacherResponse;
    user: PublicUserResponse;
    // courses: Course[];
    // reviews: Review[];
    handleMoreButtonClick: (number: number) => void;
}

export const Top: React.VFC<Props> = (props) => {
    return (
        <div className={styles.topTabContentsWrapper}>
            <BasicInfo teacher={props.teacher} user={props.user} />
            <Subjects subjects={props.teacher.teachableSubjects} />
            <Background background={props.teacher.backgroundDescription ? props.teacher.backgroundDescription : ""} />
            <ContentDescription
                contentDescription={props.teacher.contentDescription ? props.teacher.contentDescription : ""}
            />
        </div>
    );
};
