/* eslint-disable react/react-in-jsx-scope */

import { memo, useCallback, useEffect, useState } from "react";

import { Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Cropper from "react-easy-crop";
import { Point } from "react-easy-crop/types";

import getCroppedImg from "./cropImage";
import styles from "./index.module.scss";

interface Props {
    open: boolean;
    handleClose: () => void;
    setFileInfo?: React.Dispatch<React.SetStateAction<FileInfo>>;
    fileInfoList?: FileInfo[];
    setFileInfoList?: React.Dispatch<React.SetStateAction<FileInfo[]>>;
    targetFile: File | undefined;
    aspect: number;
    shape: "rect" | "round";
}

export interface FileInfo {
    file: File | undefined;
    url: string;
}

export const TrimmingModal: React.VFC<Props> = memo(function TrimmingModal(props) {
    const [src, setSrc] = useState<string | undefined>(undefined);
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState({ x: 0, y: 0, width: 300, height: 300 });
    const [rotation, setRotation] = useState<number>(0);
    const [modalHeight, setModalHeight] = useState<number>(0);

    useEffect(() => {
        if (props.targetFile) {
            setSrc(URL.createObjectURL(props.targetFile));
        }
        changeModalHeight();
        window.addEventListener("resize", () => {
            changeModalHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalHeight();
            });
        };
    }, [props.targetFile]);

    const changeModalHeight = () => {
        const innerHeight = window.innerHeight;
        setModalHeight(innerHeight * 0.75);
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const confirmCroppedImage = useCallback(async () => {
        if (props.targetFile && src) {
            const file = props.targetFile;
            const canvas = await getCroppedImg(src, croppedAreaPixels, rotation);
            if (canvas) {
                canvas.toBlob(async (blob) => {
                    if (blob) {
                        // Courseなどの複数画像をアップロードする場合
                        if (props.setFileInfo) {
                            props.setFileInfo({
                                file: new File([blob], file.name, { type: file.type }),
                                url: canvas?.toDataURL(file.type),
                            });
                        }

                        // Studentのiconなどの単一画像をアップロードする場合
                        if (props.setFileInfoList && props.fileInfoList) {
                            props.setFileInfoList([
                                ...props.fileInfoList,
                                {
                                    file: new File([blob], file.name, { type: file.type }),
                                    url: canvas?.toDataURL(file.type),
                                },
                            ]);
                        }
                    }
                });
            }
            //const url = URL.createObjectURL(blob);
            //alert(file);
            props.handleClose();
            setCrop({ x: 0, y: 0 });
            setZoom(1);
            setRotation(0);
        }
    }, [croppedAreaPixels, rotation]);

    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className={styles.trimmingModalContents} style={{ height: modalHeight }}>
                <div className={styles.trimmingTitle}>トリミング</div>
                <div className={styles.cropContainer}>
                    <Cropper
                        image={src}
                        crop={crop}
                        zoom={zoom}
                        rotation={rotation}
                        cropShape={props.shape}
                        aspect={props.aspect}
                        onZoomChange={setZoom}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                    />
                </div>
                <div className={styles.buttonsWrapper}>
                    <Button variant="contained" onClick={props.handleClose} className={styles.backButton}>
                        戻る
                    </Button>

                    <Button variant="contained" onClick={confirmCroppedImage} className={styles.confirmButton}>
                        確定
                    </Button>
                </div>
            </div>
        </Modal>
    );
});
