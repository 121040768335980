import { Button } from "@material-ui/core";
import { memo } from "react";

import styles from "./index.module.scss";

interface Props {
    successMessage: string;
    buttonContent: string;
    handleButtonClick: () => void;
    style?: React.CSSProperties;
}

export const IsSuccess: React.VFC<Props> = memo(function IsSuccess(props) {
    return (
        <div className={styles.successWrapper} style={props.style}>
            <div className={styles.success}>{props.successMessage}</div>
            <Button className={styles.button} onClick={props.handleButtonClick}>
                {props.buttonContent}
            </Button>
        </div>
    );
});
