import { memo } from "react";
import styles from "./index.module.scss";
import { IconType } from "react-icons/lib";

interface Props {
    handleClick: () => void;
    text: string;
    Icon?: IconType;
    redColor?: boolean;
    isReverse?: boolean;
    noHorizontalPadding?: boolean;
    style?: React.CSSProperties;
    className?: string;
}

export const PlainButton: React.VFC<Props> = memo(function PlainButton(props) {
    return (
        <button
            className={`${styles.plainButton} ${props.className}`}
            onClick={props.handleClick}
            style={{ padding: props.noHorizontalPadding ? "4px 0" : "4px 8px", ...props.style }}
        >
            {!props.isReverse && props.Icon && (
                <props.Icon className={props.redColor ? styles.redIcon : styles.navyIcon} />
            )}
            <span className={props.redColor ? styles.redText : styles.navyText}>{props.text}</span>
            {props.isReverse && props.Icon && (
                <props.Icon className={props.redColor ? styles.redIcon : styles.navyIcon} />
            )}
        </button>
    );
});
