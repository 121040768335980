import { Badge, Button, makeStyles, Tooltip, withStyles } from "@material-ui/core";
import { memo } from "react";

import styles from "../index.module.scss";

import CheckIcon from "@material-ui/icons/Check";

interface Props {
    title: JSX.Element;
    badgeContent: number;
    open: boolean;
    handleCheckButtonClick: () => void;
    handleTooltipOpen: () => void;
    handleTooltipClose: () => void;
}

export const StyledTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[10],
        fontSize: 11,
        marginTop: -10,
        padding: 0,
        width: "fit-content",
        maxWidth: "fit-content",
    },
}))(Tooltip);

const useStyles = makeStyles(() => ({
    navbar: {
        height: 32,
        minHeight: 32,
    },
    badge: {
        color: "#FFF",
        backgroundColor: "#C09547",
    },
}));

export const ToDosTooltip: React.VFC<Props> = memo(function ToDoTooltip(props) {
    const style = useStyles();
    return (
        <StyledTooltip
            interactive={true}
            placement="bottom"
            className={styles.tooltip}
            open={props.open}
            title={props.title}
            onClose={props.handleTooltipClose}
        >
            <Button
                className={styles.iconButton}
                onClick={props.handleCheckButtonClick}
                onMouseEnter={props.handleTooltipOpen}
            >
                <Badge badgeContent={props.badgeContent} classes={{ badge: style.badge }} overlap="rectangular">
                    <CheckIcon className={styles.icon} />
                </Badge>
            </Button>
        </StyledTooltip>
    );
});
