import { memo } from "react";

import styles from "@/pages/Common/Inquiry/index.module.scss";

interface Props {
    label: string;
    value: string;
    borderBottom?: boolean;
}

export const InquiryFormItem: React.VFC<Props> = memo(function InquiryFormItem(props) {
    return (
        <li className={styles.item} style={{ borderBottom: props.borderBottom ? "1px solid #333" : "none" }}>
            <div className={styles.itemTitle}>{props.label}</div>
            <div className={styles.itemValue}>{props.value}</div>
        </li>
    );
});
