import { FAQConfig } from "./FAQConfig";
import { FAQBackButton } from "./items/FAQBackButton";
import { Heading } from "./items/Heading";
import { List } from "./items/List";
import { Section } from "./items/Section";
import { Subheading } from "./items/Subheading";
import { Subsection } from "./items/Subsection";
import { Content, TOC } from "./items/TOC";
import { Text } from "./items/Text";
import { Title } from "./items/Title";
import { UrlInfos } from "./items/UrlInfos";

export interface UrlInfo {
    url: string;
    word: string;
}
interface Props {
    title: string;
    text: string;
    FAQConfigs?: FAQConfig[];
    list?: string[];
    showIdx?: boolean;
    urlInfos?: UrlInfo[];
    toc?: Content[];
}

export const FAQDetailsBase: React.VFC<Props> = (props) => {
    return (
        <>
            <Title>{props.title}</Title>
            <Text>{props.text}</Text>
            {props.toc && <TOC toc={props.toc} />}
            {props.urlInfos && <UrlInfos urlInfos={props.urlInfos} />}
            {props.list && <List list={props.list} showIdx={props.showIdx} />}
            {props.FAQConfigs?.map((value, idx) => (
                <Section key={idx}>
                    <Heading>
                        {value.showIdx ? `${idx + 1}.` : "・"} {value.title}
                    </Heading>
                    <Text>{value.text}</Text>
                    {value.list && <List list={value.list} />}
                    {value.contents?.map((content) => (
                        <Subsection>
                            <Subheading>{content.title}</Subheading>
                            <Text>{content.text}</Text>
                        </Subsection>
                    ))}
                </Section>
            ))}
            <FAQBackButton />
        </>
    );
};
