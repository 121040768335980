import { ReservedCourseCard } from "./ReservedCourseCard";
import styles from "./index.module.scss";
import { ClassResponse } from "@/store/autogenApi";

interface Props {
    visible: boolean;
    classes: ClassResponse[];
    repetition: number;
    handleCourseCardClick: (course: ClassResponse) => void;
}

export const ReservedCoursesTabContents: React.VFC<Props> = (props) => {
    return (
        <div role="tabpanel" hidden={!props.visible} className={styles.tabContents}>
            {props.visible && (
                <>
                    {props.classes.length === 0 ? (
                        <div className={styles.emptyCourses}>対象の講座がありません。</div>
                    ) : (
                        <div className={styles.cardsList}>
                            {[...Array(Math.ceil(props.classes.length / props.repetition))]
                                .map((_, i) => i)
                                .map((idx) => (
                                    <>
                                        <div className={styles.cards} key={idx}>
                                            {props.classes
                                                .slice(
                                                    idx * props.repetition,
                                                    idx * props.repetition + props.repetition,
                                                )
                                                .map((classResponse) => {
                                                    return (
                                                        <ReservedCourseCard
                                                            class={classResponse}
                                                            key={classResponse.classId}
                                                            isFlex
                                                            handleCourseCardClick={props.handleCourseCardClick}
                                                        />
                                                    );
                                                })}
                                            {props.classes.slice(
                                                idx * props.repetition,
                                                idx * props.repetition + props.repetition,
                                            ).length < props.repetition &&
                                                [
                                                    ...Array(
                                                        props.repetition -
                                                            props.classes.slice(
                                                                idx * props.repetition,
                                                                idx * props.repetition + props.repetition,
                                                            ).length,
                                                    ),
                                                ].map((_, idx) => (
                                                    <ReservedCourseCard
                                                        key={idx}
                                                        isHidden
                                                        isFlex
                                                        handleCourseCardClick={props.handleCourseCardClick}
                                                    />
                                                ))}
                                        </div>
                                    </>
                                ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
