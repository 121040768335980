import { memo, useCallback, useState } from "react";
import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { RouterPromptWithDraft } from "@/components/RouterPromptWithDraft";
import { RootState } from "@/ducks";
import { CourseType, DraftPublicRequestResponse, PublicRequestResponse, SubjectResponse } from "@/store/autogenApi";
import { useCreatePublicRequestMutation, useUpdatePublicRequestMutation } from "@/store/hooks/publicRequests";
import {
    useCreateDraftPublicRequestMutation,
    useUpdateDraftPublicRequestMutation,
    useDeleteDraftPublicRequestMutation,
} from "@/store/hooks/draftPublicRequests";
import { dateToString } from "@/utils/DateUtils";
import { config, ConfigKey } from "../LimitConfig";
import { RequestContents } from "../RequestContents";
import { UseDraftPublicRequestModal } from "../UseDraftPublicRequestModal";
import { PleaseSignUpModal } from "@/components/PleaseSignUpModal";

interface Props {
    existingPublicRequest?: PublicRequestResponse;
}

export interface PriceRange {
    min: number;
    max: number;
}

export const CommonComponent: React.VFC<Props> = memo(function CommonComponent(props) {
    const [complete, setComplete] = useState<boolean>(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(false);
    const [modalMaxHeight, setModalMaxHeight] = useState<number>(0);
    const [applicationPeriod, setApplicationPeriod] = useState<number>(0);
    const [subjects, setSubjects] = useState<SubjectResponse[]>([]);
    const [howManyErrors, setHowManyErrors] = useState<number>(0);
    const [processing, setProcessing] = useState<boolean>(false);
    const [title, setTitle] = useState<string>("");
    const [courseType, setCourseType] = useState<CourseType | undefined>(undefined);
    const [publicRequestDescription, setRequestDescription] = useState<string>("");
    const [scheduleDescription, setScheduleDescription] = useState<string>("");
    const [firstDateDescription, setStartDateDescription] = useState<string>("");
    const [additionalDescription, setAdditionalDescription] = useState<string>("");
    const [minPrice, setMinPrice] = useState<number>(0);
    const [maxPrice, setMaxPrice] = useState<number>(0);
    const [isEdited, setIsEdited] = useState<boolean | undefined>(undefined);
    const [newRequestId, setNewRequestId] = useState<string | undefined>(undefined);
    const [hasError, setHasError] = useState<boolean>(false);
    const [isTemporarySaved, setIsTemporarySaved] = useState<boolean>(false);
    const [signUpModalOpen, setSignUpModalOpen] = useState<boolean>(false);

    const { currentRequestId } = useParams<{ currentRequestId: string }>();

    const query = new URLSearchParams(useLocation().search);
    const draftPublicRequestId = query.get("draftPublicRequestId");

    const createPublicRequest = useCreatePublicRequestMutation();
    const updatePublicRequest = useUpdatePublicRequestMutation();
    const createDraftPublicRequest = useCreateDraftPublicRequestMutation();
    const updateDraftPublicRequest = useUpdateDraftPublicRequestMutation();
    const deleteDraftPublicRequest = useDeleteDraftPublicRequestMutation();

    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);
    const history = useHistory();
    const userMode = useSelector((state: RootState) => state.jwt.userMode);

    useEffect(() => {
        if (userMode !== "student") {
            setSignUpModalOpen(true);
        }
    }, []);

    useEffect(() => {
        if (currentRequestId) {
            (async () => {
                if (props.existingPublicRequest) {
                    const fetchedPublicRequest = props.existingPublicRequest;
                    setCourseType(fetchedPublicRequest.courseType);
                    setTitle(fetchedPublicRequest.title);
                    setRequestDescription(fetchedPublicRequest.publicRequestDescription);
                    setScheduleDescription(fetchedPublicRequest.scheduleDescription);
                    fetchedPublicRequest.additionalDescription &&
                        setAdditionalDescription(fetchedPublicRequest.additionalDescription);
                    fetchedPublicRequest.firstDateDescription &&
                        setStartDateDescription(fetchedPublicRequest.firstDateDescription);
                    if (fetchedPublicRequest.subjects) {
                        setSubjects(fetchedPublicRequest.subjects);
                    }
                    if (fetchedPublicRequest.minPrice && fetchedPublicRequest.maxPrice) {
                        setMinPrice(fetchedPublicRequest.minPrice);
                        setMaxPrice(fetchedPublicRequest.maxPrice);
                    }
                    const applicationPeriod = Math.round(
                        (new Date(fetchedPublicRequest.applyingDeadline).getTime() -
                            new Date(fetchedPublicRequest.createdAt).getTime()) /
                            (1000 * 60 * 60 * 24),
                    );
                    setApplicationPeriod(applicationPeriod);
                    setIsEdited(false);
                } else {
                    setIsEdited(false);
                }
            })();
        }
    }, [props.existingPublicRequest]);

    const handleConfirmButtonClick = useCallback(async () => {
        setProcessing(true);
        window.scrollTo(0, 0);
        const applyingDeadline = new Date();
        applyingDeadline.setDate(applyingDeadline.getDate() + applicationPeriod);
        if (!courseType) return;
        if (props.existingPublicRequest) {
            const result = await updatePublicRequest({
                updatePublicRequestRequestBody: {
                    publicRequestId: currentRequestId,
                    courseType: courseType as CourseType,
                    title: title,
                    publicRequestDescription: publicRequestDescription,
                    scheduleDescription: scheduleDescription,
                    firstDateDescription: firstDateDescription,
                    additionalDescription,
                    studentId: studentId,
                    subjectIds: subjects.map((subject) => subject.subjectId),
                    applyingDeadline: dateToString(applyingDeadline),
                    minPrice: minPrice,
                    maxPrice: maxPrice,
                },
            });
            if (result.isSuccess) {
                const { publicRequestId } = result.data;
                setNewRequestId(publicRequestId);
                setComplete(true);
            } else {
                setHasError(true);
            }
        } else {
            const result = await createPublicRequest({
                createPublicRequestRequestBody: {
                    studentId: studentId,
                    courseType: courseType as CourseType,
                    title: title,
                    publicRequestDescription: publicRequestDescription,
                    scheduleDescription: scheduleDescription,
                    firstDateDescription: firstDateDescription,
                    additionalDescription,
                    subjectIds: subjects.map((subject) => subject.subjectId),
                    applyingDeadline: dateToString(applyingDeadline),
                    minPrice: minPrice,
                    maxPrice: maxPrice,
                    isPublic: true,
                },
            });
            window.scroll({ top: 0 });
            if (result.isSuccess) {
                const { publicRequestId } = result.data;
                setNewRequestId(publicRequestId);
                setComplete(true);
            } else {
                setHasError(true);
            }
        }
    }, [
        courseType,
        title,
        publicRequestDescription,
        scheduleDescription,
        firstDateDescription,
        additionalDescription,
        subjects,
        minPrice,
        maxPrice,
        studentId,
        currentRequestId,
        props.existingPublicRequest,
        createPublicRequest,
        updatePublicRequest,
        applicationPeriod,
        dateToString,
        setComplete,
        setNewRequestId,
        setHasError,
        setProcessing,
    ]);

    const handleDraftButtonClick = useCallback(async () => {
        setProcessing(true);
        window.scrollTo(0, 0);
        const applyingDeadline = new Date();
        applyingDeadline.setDate(applyingDeadline.getDate() + applicationPeriod);
        if (props.existingPublicRequest?.draftPublicRequest?.draftPublicRequestId) {
            const result = await updateDraftPublicRequest({
                updateDraftPublicRequestRequestBody: {
                    draftPublicRequestId: props.existingPublicRequest.draftPublicRequest.draftPublicRequestId,
                    publicRequestId: currentRequestId,
                    courseType: courseType,
                    title: title,
                    publicRequestDescription: publicRequestDescription,
                    scheduleDescription: scheduleDescription,
                    firstDateDescription: firstDateDescription,
                    studentId: studentId,
                    subjectIds: subjects.map((subject) => subject.subjectId),
                    applyingDeadline: dateToString(applyingDeadline),
                    minPrice: minPrice,
                    maxPrice: maxPrice,
                },
            });
            if (result.isSuccess) {
                setIsTemporarySaved(true);
                setComplete(true);
            } else {
                setHasError(true);
            }
        } else {
            const result = await createDraftPublicRequest({
                createDraftPublicRequestRequestBody: {
                    publicRequestId: currentRequestId,
                    studentId: studentId,
                    courseType: courseType as CourseType,
                    title: title,
                    publicRequestDescription: publicRequestDescription,
                    scheduleDescription: scheduleDescription,
                    firstDateDescription: firstDateDescription,
                    subjectIds: subjects.map((subject) => subject.subjectId),
                    applyingDeadline: dateToString(applyingDeadline),
                    minPrice: minPrice,
                    maxPrice: maxPrice,
                },
            });
            window.scroll({ top: 0 });
            if (result.isSuccess) {
                setIsTemporarySaved(true);
                setComplete(true);
            } else {
                setHasError(true);
            }
        }
    }, [
        currentRequestId,
        courseType,
        title,
        publicRequestDescription,
        scheduleDescription,
        firstDateDescription,
        subjects,
        minPrice,
        maxPrice,
        studentId,
        currentRequestId,
        props.existingPublicRequest,
        createPublicRequest,
        updatePublicRequest,
        applicationPeriod,
        dateToString,
        setComplete,
        setNewRequestId,
        setHasError,
        setProcessing,
    ]);

    const zenkakuToHankaku = useCallback((str: string) => {
        if (!str) return ""; // 偽であれば空文字を返す

        const hankaku_eisu = () => {
            if (!str) return "";
            return String.fromCharCode(str.charCodeAt(0) - 0xfee0);
        };

        str = str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, hankaku_eisu); // 英数
        str = str.replace(/[−―‐ー]/g, "-"); // 色々なハイフンも統一
        str = str.replace(/＠/g, "@"); // @マークも親切に統一
        str = str.replace(/[ts ]/g, ""); // ついでにタブやスペースを消す
        return str;
    }, []);

    const handleApplicationPeriodChange = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        setIsEdited(true);
        const newApplicationPeriod = Number(e.target.value);
        setApplicationPeriod(newApplicationPeriod);
    }, []);

    const handlePriceChange = useCallback((range: PriceRange) => {
        setIsEdited(true);
        setMinPrice(range.min);
        setMaxPrice(range.max);
    }, []);

    const changeModalMaxHeight = useCallback(() => {
        const innerHeight = window.innerHeight;
        setModalMaxHeight(innerHeight * 0.75);
    }, []);

    useEffect(() => {
        changeModalMaxHeight();
        window.addEventListener("resize", () => {
            changeModalMaxHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalMaxHeight();
            });
        };
    }, []);

    const handleOKButtonClick = useCallback(() => {
        return true;
    }, []);

    const handlePromptDraftButtonClick = useCallback(async () => {
        await handleDraftButtonClick();
        return true;
    }, [handleDraftButtonClick]);

    const handleCancelButtonClick = useCallback(() => {
        return false;
    }, []);

    const handleCourseTypeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setIsEdited(true);
        const courseType = e.target.value as CourseType;
        setCourseType(courseType);
    }, []);

    const getIsValid = useCallback((key: ConfigKey, value: number) => {
        const minValue = (config[key] as number[])[0];
        const maxValue = (config[key] as number[])[1];
        return minValue <= value && value <= maxValue;
    }, []);

    const checkValidation = useCallback(() => {
        if (!courseType) return false;
        const commonValidationKeys: string[] = [
            "courseType",
            "title",
            "subjects",
            "publicRequestDescription",
            "additionalDescription",
            "maxPrice",
            "minPrice",
            "applicationPeriod",
        ];

        const shortCourseValidationKeys: string[] = [];

        const regularCourseValidationKeys: string[] = ["firstDateDescription"];
        const commonValidation = commonValidationKeys.every((key) => {
            console.log(key);
            if (key === "courseType") {
                return courseType != undefined;
            }
            if (key === "title") {
                return getIsValid(key, title.length);
            }
            if (key === "publicRequestDescription") {
                return getIsValid(key, publicRequestDescription.length);
            }
            if (key === "additionalDescription") {
                return additionalDescription === "" || getIsValid(key, additionalDescription.length);
            }
            if (key === "maxPrice") {
                return getIsValid(key, maxPrice);
            }
            if (key === "minPrice") {
                return getIsValid(key, minPrice) && minPrice <= maxPrice;
            }
            if (subjects.length === 0) {
                return false;
            }
            return true;
        });
        if (!commonValidation) {
            return false;
        }
        if (courseType === "regular") {
            const regularCourseValidation = regularCourseValidationKeys.every((key) => {
                if (key === "firstDateDescription") {
                    return getIsValid(key, firstDateDescription.length);
                }
            });
            if (!regularCourseValidation) {
                return false;
            }
        }
        return true;
    }, [
        courseType,
        title,
        publicRequestDescription,
        additionalDescription,
        maxPrice,
        minPrice,
        subjects,
        firstDateDescription,
    ]);

    const handleCheckButtonClick = useCallback(() => {
        setChecked(true);
        const isValid = checkValidation();
        if (isValid) {
            setConfirmModalOpen(true);
        } else {
            window.scroll({ top: 0, behavior: "smooth" });
        }
    }, [checkValidation]);

    const handleTitleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setIsEdited(true);
        const newTitle = e.target.value;
        setTitle(newTitle);
    }, []);

    const handleRequestDescriptionChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setIsEdited(true);
        const newRequestDescription = e.target.value;
        setRequestDescription(newRequestDescription);
    }, []);

    const handleScheduleDescriptionChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setIsEdited(true);
        const newScheduleDescription = e.target.value;
        setScheduleDescription(newScheduleDescription);
    }, []);

    const handleAdditionalDescriptionChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setIsEdited(true);
        const newAdditionalDescription = e.target.value;
        setAdditionalDescription(newAdditionalDescription);
    }, []);

    const handleStartDateDescriptionChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setIsEdited(true);
        const newStartDateDescription = e.target.value;
        setStartDateDescription(newStartDateDescription);
    }, []);

    const handleUseDraftButtonClick = useCallback((draftPublicRequest: DraftPublicRequestResponse | undefined) => {
        (async () => {
            if (!draftPublicRequest) return;
            setCourseType(draftPublicRequest.courseType);
            setTitle(draftPublicRequest.title);
            setRequestDescription(draftPublicRequest.publicRequestDescription);
            setScheduleDescription(draftPublicRequest.scheduleDescription);
            draftPublicRequest.additionalDescription &&
                setAdditionalDescription(draftPublicRequest.additionalDescription);
            draftPublicRequest.firstDateDescription && setStartDateDescription(draftPublicRequest.firstDateDescription);
            if (draftPublicRequest.subjects) {
                setSubjects(draftPublicRequest.subjects);
            }
            if (draftPublicRequest.minPrice && draftPublicRequest.maxPrice) {
                setMinPrice(draftPublicRequest.minPrice);
                setMaxPrice(draftPublicRequest.maxPrice);
            }
            const applicationPeriod = Math.round(
                (new Date(draftPublicRequest.applyingDeadline).getTime() -
                    new Date(draftPublicRequest.createdAt).getTime()) /
                    (1000 * 60 * 60 * 24),
            );
            setApplicationPeriod(applicationPeriod);
            await deleteDraftPublicRequest({
                draftPublicRequestId: draftPublicRequest.draftPublicRequestId,
            });
        })();
    }, []);

    const handleDiscardDraftButtonClick = useCallback(async () => {
        (async () => {
            if (!draftPublicRequestId) return;
            await deleteDraftPublicRequest({
                draftPublicRequestId,
            });
        })();
    }, [draftPublicRequestId]);

    return (
        <>
            <RouterPromptWithDraft
                when={Boolean(isEdited && !complete && !hasError)}
                onOK={handleOKButtonClick}
                onDraft={handlePromptDraftButtonClick}
                onCancel={handleCancelButtonClick}
                message={`講座リクエスト${
                    currentRequestId ? "編集" : "作成"
                }が未完了です。未保存の編集内容は失われますがよろしいですか？`}
            />
            <RequestContents
                open={confirmModalOpen}
                checked={checked}
                complete={complete}
                subjects={subjects}
                hasError={hasError}
                currentRequestId={currentRequestId}
                newRequestId={newRequestId}
                minPrice={minPrice}
                maxPrice={maxPrice}
                modalMaxHeight={modalMaxHeight}
                applicationPeriod={applicationPeriod}
                howManyErrors={howManyErrors}
                processing={processing}
                courseType={courseType}
                title={title}
                publicRequestDescription={publicRequestDescription}
                scheduleDescription={scheduleDescription}
                additionalDescription={additionalDescription}
                firstDateDescription={firstDateDescription}
                isTemporarySaved={isTemporarySaved}
                getIsValid={getIsValid}
                handleConfirmButtonClick={handleConfirmButtonClick}
                handleDraftButtonClick={handleDraftButtonClick}
                setOpen={setConfirmModalOpen}
                setSubjects={setSubjects}
                handleCheckButtonClick={handleCheckButtonClick}
                handleApplicationPeriodChange={handleApplicationPeriodChange}
                handlePriceChange={handlePriceChange}
                handleCourseTypeChange={handleCourseTypeChange}
                handleTitleChange={handleTitleChange}
                handleScheduleDescriptionChange={handleScheduleDescriptionChange}
                handleRequestDescriptionChange={handleRequestDescriptionChange}
                handleAdditionalDescriptionChange={handleAdditionalDescriptionChange}
                handleStartDateDescriptionChange={handleStartDateDescriptionChange}
            />
            <UseDraftPublicRequestModal
                draftPublicRequestId={draftPublicRequestId}
                existPublicRequest={Boolean(props.existingPublicRequest)}
                handleDiscardDraftButtonClick={handleDiscardDraftButtonClick}
                handleUseDraftButtonClick={handleUseDraftButtonClick}
            />
            <PleaseSignUpModal
                modalOpen={signUpModalOpen}
                userMode={userMode}
                actionTitle="「講座リクエスト」を作成する"
            />
        </>
    );
});
