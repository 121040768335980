import { useSelector } from "react-redux";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { useGetMyDraftPublicRequestsQuery } from "@/store/hooks/draftPublicRequests";
import { MyPage } from ".";
import { RootState } from "@/ducks";
import { MyDraftPublicRequestsContents } from "@/pageComponents/Student/MyPage/MyDraftPublicRequests";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { useEffect } from "react";
import { Navigation } from "@/components/Navigation";

const NAVIGATION_LIST: Navigation[] = [
    { title: "マイページ", url: "/MyPage/Profile" },
    { title: "下書き講座リクエスト一覧", url: "/MyPage/MyDraftPublicRequests" },
];

export const MyDraftPublicRequests: React.VFC = () => {
    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);

    const draftPublicRequestsQueryState = useGetMyDraftPublicRequestsQuery(studentId);

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    return (
        <MyPage title="下書き講座リクエスト一覧" activeTab="draftPublicRequests">
            <QueryLoadingWrapper {...draftPublicRequestsQueryState}>
                {(draftPublicRequests) => <MyDraftPublicRequestsContents draftPublicRequests={draftPublicRequests} />}
            </QueryLoadingWrapper>
        </MyPage>
    );
};
