import styles from "@/pages/Common/LP/index.module.scss";

export const EasyToStart: React.VFC = () => {
    return (
        <div className={styles.easyToStartWrapper}>
            <div className={styles.easyToStartTitle}>簡単に始められる3つの特徴</div>
            <div className={styles.features}>
                <div className={styles.feature}>
                    <div className={styles.featureDescription}>入会金・登録料</div>
                    <div className={styles.featureStrongDescription}>無料！</div>
                </div>
                <div className={styles.feature}>
                    <div className={styles.featureDescription}>検索・予約・決済まで</div>
                    <div className={styles.featureStrongDescription}>オンラインで完結！</div>
                </div>
                <div className={styles.feature}>
                    <div className={styles.featureDescription}>受講前の</div>
                    <div className={styles.featureStrongDescription}>無料面談可能！</div>
                </div>
            </div>
        </div>
    );
};
