import { useState, useCallback, useEffect } from "react";

import styles from "@/pages/Student/Payment/index.module.scss";
import { ClassResponse } from "@/store/autogenApi";
import { useHistory } from "react-router-dom";
import { PaymentInfoInputStep } from "./PaymentInfoInputStepContents";
import { ConfirmStepContents } from "./ConfirmStepContents";
import { IsError } from "@/components/IsError";
import { IsSuccess } from "@/components/IsSuccess";
import { StyledStepper } from "./StyledStepper";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { getLevelAndCategory } from "@/utils/SubjectUtils";

interface Props {
    defaultPaymentMethod: any;
    class: ClassResponse;
    pointsBalance: number;
    studentToDoId?: string;
}

export const PaymentContents: React.VFC<Props> = (props) => {
    const [points, setPoints] = useState(0);
    const [hasOtherError, setHasOtherError] = useState(false);
    const [hasUpdatedError, setHasUpdatedError] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [isChecked, setIsChecked] = useState(false);
    const [price, setPrice] = useState<number | undefined>(undefined);
    const [firstDate, setFirstDate] = useState<Date | undefined>(undefined);
    const [targetPaymentMethod, setTargetPaymentMethod] = useState<any | undefined>(undefined);

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        const firstSubject = props.class.course?.subjects[0];
        if (!firstSubject || !props.class.course?.title) {
            updateNavigationList([
                {
                    title: "支払い",
                    url: `/Payment/${props.class.classId}`,
                },
            ]);
            return;
        }
        updateNavigationList([
            {
                title: "講座検索",
                url: `/SearchResults?searchType=course`,
            },
            {
                title: getLevelAndCategory(firstSubject),
                url: `/SearchResults?searchType=course&level=${firstSubject.level}&category=${firstSubject.category}`,
            },
            {
                title: props.class.course?.title,
                url: `/CourseDetails/${props.class.course.courseId}`,
            },
            {
                title: "支払い",
                url: `/Payment/${props.class.classId}`,
            },
        ]);
    }, [props.class]);

    const getIsPaymentMethodValid = useCallback(
        (paymentMethod: any) => {
            const courseType = props.class.course?.courseType;
            if (!courseType) return false;
            return paymentMethod != undefined;
        },
        [props.class.course?.courseType],
    );

    useEffect(() => {
        if (!props.defaultPaymentMethod) return;
        const isValid = getIsPaymentMethodValid(props.defaultPaymentMethod);
        if (!isValid) return;
        setTargetPaymentMethod(props.defaultPaymentMethod);
    }, [props.defaultPaymentMethod, getIsPaymentMethodValid]);

    const getPrice = useCallback(() => {
        // 短期はpricePerPaymentが確定して（変動の可能性がない）いるので、それを使う
        const course = props.class.course;
        if (!course) return undefined;
        if (course.courseType === "short") {
            return props.class.pricePerPayment;
        }
        // 定期はpricePerPaymentが確定していないので、confirmedMonthlyFee（先生が決めた値）を使う
        const privateRequest = props.class.privateRequest;
        if (!privateRequest) return undefined;
        if (course.courseType === "regular" && privateRequest.confirmedMonthlyFee) {
            return privateRequest.confirmedMonthlyFee;
        }
    }, [props.class]);

    useEffect(() => {
        const newPrice = getPrice();
        setPrice(newPrice);
    }, [getPrice]);

    useEffect(() => {
        if (!props.class.privateRequest?.confirmedFirstDate) return;
        const newFirstDate = new Date(props.class.privateRequest.confirmedFirstDate);
        setFirstDate(newFirstDate);
    }, [props.class.privateRequest]);

    const history = useHistory();

    const handleDetailsButtonClick = () => {
        history.replace(`/CourseDetails/${props.class.course?.courseId}`);
    };

    const handleHomeButtonClick = () => {
        history.replace("/");
    };

    const handleCheckButtonClick = useCallback(() => {
        setIsChecked(true);
        const isValid = getIsPaymentMethodValid(targetPaymentMethod);
        if (!isValid) return;
        if (targetPaymentMethod == undefined) return;
        setActiveStep(1);
        window.scrollTo(0, 0);
    }, [targetPaymentMethod, getIsPaymentMethodValid]);

    const handleBackButtonClick = useCallback(() => {
        setActiveStep(0);
    }, []);

    return (
        <div className={styles.paymentWrapper}>
            <div className={styles.paymentContentsWrapper}>
                {!hasUpdatedError && !hasOtherError && <StyledStepper activeStep={activeStep} />}
                {activeStep === 0 && (
                    <PaymentInfoInputStep
                        class={props.class}
                        defaultPaymentMethod={props.defaultPaymentMethod}
                        pointsBalance={props.pointsBalance}
                        points={points}
                        targetPaymentMethod={targetPaymentMethod}
                        isChecked={isChecked}
                        firstDate={firstDate}
                        price={price}
                        setTargetPaymentMethod={setTargetPaymentMethod}
                        setPoints={setPoints}
                        handleCheckButtonClick={handleCheckButtonClick}
                    />
                )}
                {activeStep === 1 && (
                    <>
                        {hasUpdatedError ? (
                            <IsError
                                errorMessage="購入処理中に講座内容が更新されたため、購入処理がキャンセルされました。講座内容をご確認ください。"
                                buttonContent="講座詳細"
                                handleButtonClick={handleDetailsButtonClick}
                            />
                        ) : (
                            <>
                                {hasOtherError ? (
                                    <IsError
                                        errorMessage="予期せぬエラーが発生しました。お手数ですが、もう一度お試しください。"
                                        buttonContent="講座詳細"
                                        handleButtonClick={handleDetailsButtonClick}
                                    />
                                ) : (
                                    <ConfirmStepContents
                                        defaultPaymentMethod={props.defaultPaymentMethod}
                                        class={props.class}
                                        price={price}
                                        pointsBalance={props.pointsBalance}
                                        targetPaymentMethod={targetPaymentMethod}
                                        firstDate={firstDate}
                                        studentToDoId={props.studentToDoId}
                                        setHasOtherError={setHasOtherError}
                                        setHasUpdatedError={setHasUpdatedError}
                                        handleBackButtonClick={handleBackButtonClick}
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
