import { FAQDetailsBase } from "../../FAQDetailsBase";

import { MESSAGE_CONFIGS } from "./Message";

export const Message: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="先生からの連絡を確認したい"
            text="Trailの連絡手段は2種類存在し、それぞれ用途が異なります。"
            list={["グループチャット", "個人チャット"]}
            FAQConfigs={MESSAGE_CONFIGS}
            showIdx
        />
    );
};
