import { memo, useCallback } from "react";
import styles from "../../index.module.scss";
import { LessonResponse, CreateLessonRequestParams } from "@/store/autogenApi";
import { ConfirmedLessonTimeBlocks } from "./ConfirmedLessonTimeBlocks";
import { NewLessonTimeBlocks } from "./NewLessonTimeBlocks";

interface Props {
    filteredLessons: (LessonResponse | CreateLessonRequestParams)[];
    newLessonStartYIdx: number | undefined;
    newLessonEndYIdx: number | undefined;
    newLessonXIdx: number | undefined;
    targetDayOfWeekIdx: number;
    isDragging: boolean;
    newLessonTimeString: string;
    lessonCalendarLanesEl: HTMLElement | null;
    isUpdatingTemporaryLesson: boolean;
    targetLesson: LessonResponse | undefined;
    targetNewLessonIdx?: number;
    isClickedValidBlock: boolean;
    handleNewLessonAnchorElChange: (el: HTMLElement) => void;
    getLaneDate: (idx: number) => Date;
    handleValidBlockClick: (lesson: LessonResponse, idx: number, el: HTMLElement) => void;
    handleNewLessonStartYIdxChange: (idx: number | undefined) => void;
    handleNewLessonEndYIdxChange: (idx: number | undefined) => void;
    handleNewLessonXIdxChange: (idx: number | undefined) => void;
    handleNewLessonPopoverOpen: () => void;
    // 既存授業
    handleTargetLessonChange: (lesson: LessonResponse | undefined) => void;
    // 新規授業
    handleTargetNewLessonIdxChange: (lesson: CreateLessonRequestParams) => void;
    getNewLessonIdx: (lesson: CreateLessonRequestParams) => number;
}

export const LessonTimeBlocks: React.VFC<Props> = memo((props) => {
    const getLessonTimeIdxRange = useCallback((lesson: LessonResponse | CreateLessonRequestParams) => {
        const startTime = new Date(lesson.startTime);
        const endTime = new Date(lesson.endTime);
        const startTimeIdx = startTime.getHours() * 4 + Math.floor(startTime.getMinutes() / 15);
        const endTimeIdx = endTime.getHours() * 4 + Math.floor(endTime.getMinutes() / 15);
        return [startTimeIdx, endTimeIdx];
    }, []);

    return (
        <div className={styles.lessonTimeBlocksWrapper}>
            {props.filteredLessons.map((lesson, idx) => {
                const lessonTimeRange = getLessonTimeIdxRange(lesson);
                const newLessonIdx = props.getNewLessonIdx(lesson);
                return (
                    <ConfirmedLessonTimeBlocks
                        key={idx}
                        lesson={lesson}
                        targetDayOfWeekIdx={props.targetDayOfWeekIdx}
                        lessonTimeRange={lessonTimeRange}
                        lessonCalendarLanesEl={props.lessonCalendarLanesEl}
                        targetLesson={props.targetLesson}
                        isUpdatingTemporaryLesson={props.isUpdatingTemporaryLesson}
                        newLessonIdx={newLessonIdx}
                        targetNewLessonIdx={props.targetNewLessonIdx}
                        isClickedValidBlock={props.isClickedValidBlock}
                        handleValidBlockClick={props.handleValidBlockClick}
                        handleNewLessonStartYIdxChange={props.handleNewLessonStartYIdxChange}
                        handleNewLessonEndYIdxChange={props.handleNewLessonEndYIdxChange}
                        handleNewLessonXIdxChange={props.handleNewLessonXIdxChange}
                        handleNewLessonPopoverOpen={props.handleNewLessonPopoverOpen}
                        handleTargetLessonChange={props.handleTargetLessonChange}
                        handleTargetNewLessonIdxChange={props.handleTargetNewLessonIdxChange}
                    />
                );
            })}
            {props.newLessonXIdx === props.targetDayOfWeekIdx &&
                props.newLessonStartYIdx != undefined &&
                props.newLessonEndYIdx != undefined && (
                    <NewLessonTimeBlocks
                        newLessonStartYIdx={props.newLessonStartYIdx}
                        newLessonEndYIdx={props.newLessonEndYIdx}
                        newLessonTimeString={props.newLessonTimeString}
                        isDragging={props.isDragging}
                        targetLesson={props.targetLesson}
                        handleNewLessonAnchorElChange={props.handleNewLessonAnchorElChange}
                        handleNewLessonStartYIdxChange={props.handleNewLessonStartYIdxChange}
                        handleNewLessonEndYIdxChange={props.handleNewLessonEndYIdxChange}
                    />
                )}
        </div>
    );
});
