import { TextField } from "@material-ui/core";
import { memo } from "react";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Mandatory } from "@/components/Tag/Mandatory";

import { RealName } from "@/pages/Teacher/Identification";
import styles from "@/pages/Teacher/IdentificationForStripe/index.module.scss";

interface Props {
    checked: boolean;
    city: string;
    handleCityChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const City: React.VFC<Props> = memo(function City(props) {
    return (
        <div className={styles.formItem} style={{ borderBottom: "1px solid #CCC" }}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitle}>市区町村</div>
                <Mandatory />
            </div>
            <div className={styles.rightWrapper}>
                <TextField
                    variant="outlined"
                    placeholder="〇〇市〇〇区"
                    className={styles.textField}
                    value={props.city}
                    onChange={props.handleCityChange}
                />
                <ErrorMessage content="市区町村を入力してください。" when={props.checked && props.city.length === 0} />
            </div>
        </div>
    );
});
