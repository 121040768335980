import { useState, useCallback, memo, useEffect } from "react";

import { Badge, Button, makeStyles, Tooltip, withStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { useHistory } from "react-router-dom";

import { TeacherToDoResponse } from "@/store/autogenApi";
import { useUpdateTeacherToDoMutation } from "@/store/hooks/teacherToDos";
import styles from "../../index.module.scss";
import { ApprovePrivateRequestModal } from "./ApprovePrivateRequestModal";
import { ToDosTooltip } from "../ToDosTooltip";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    teacherToDos: TeacherToDoResponse[];
    teacherId: string;
}

export const TeacherToDoListContents: React.VFC<Props> = memo(function TeacherToDoListContents(props) {
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const [targetTeacherToDo, setTargetTeacherToDo] = useState<TeacherToDoResponse | null>(null);
    const [maxModalHeight, setMaxModalHeight] = useState<number>(0);

    useEffect(() => {
        changeModalHeight();
        window.addEventListener("resize", () => {
            changeModalHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalHeight();
            });
        };
    }, []);

    const changeModalHeight = useCallback(() => {
        const innerHeight = window.innerHeight;
        setMaxModalHeight(innerHeight * 0.75);
    }, []);

    const updateTeacherToDo = useUpdateTeacherToDoMutation();

    const getDiffTime = useCallback((createdAt: Date) => {
        const currentTime = new Date();
        const fixedTime = new Date(createdAt);
        const diffTimeByMillisecond = currentTime.getTime() - fixedTime.getTime();
        const diffDate = Math.floor(diffTimeByMillisecond / (1000 * 60 * 60 * 24));
        const diffHours = Math.floor(diffTimeByMillisecond / (1000 * 60 * 60)) - diffDate * 24;
        const diffMinutes = Math.floor(diffTimeByMillisecond / (1000 * 60)) - diffHours * 60;
        if (diffDate === 0) {
            if (diffHours === 0) {
                if (diffMinutes === 0) {
                    return "ちょうど今";
                } else {
                    return diffMinutes + "分前";
                }
            } else {
                return diffHours + "時間前";
            }
        } else {
            return diffDate + "日前";
        }
    }, []);

    const handleTooltipClose = useCallback(() => {
        setTooltipOpen(false);
    }, []);

    const handleTooltipOpen = useCallback(() => {
        setTooltipOpen(true);
    }, []);

    const handleCheckButtonClick = useCallback(() => {
        if (tooltipOpen) {
            setTooltipOpen(false);
        } else {
            setTooltipOpen(true);
        }
    }, []);

    const history = useHistory();

    const handleTeacherToDoClick = useCallback(async (teacherToDo: TeacherToDoResponse) => {
        setTooltipOpen(false);
        const type = teacherToDo.toDoType;
        if (type === "createCourse" || type === "reportCompletion") {
            const url = `/CourseManagement/${teacherToDo.class?.course?.courseId}`;
            history.push(url);
        } else if (type === "courseIsWithdrawn") {
            await updateTeacherToDo({
                teacherToDoId: teacherToDo.teacherToDoId,
            });
            const url = `/CourseManagement/${teacherToDo.class?.course?.courseId}`;
            history.push(url);
        } else if (type === "checkEvaluation") {
            await updateTeacherToDo({
                teacherToDoId: teacherToDo.teacherToDoId,
            });
            const url = `/Reviews/${teacherToDo.class?.course?.courseId}`;
            history.push(url);
        } else if (type === "approvePrivateRequest") {
            setTargetTeacherToDo(teacherToDo);
        }
    }, []);

    const getToDoTitle = useCallback((teacherToDo: TeacherToDoResponse) => {
        if (teacherToDo.toDoType === "createCourse") {
            return "授業依頼が届きました。";
        } else if (teacherToDo.toDoType === "reportCompletion") {
            return "予定されていた授業が完了しました。";
        } else if (teacherToDo.toDoType === "checkEvaluation") {
            return "授業への評価が届きました。";
        } else if (teacherToDo.toDoType === "courseIsWithdrawn") {
            return "受講を停止しました。";
        } else if (teacherToDo.toDoType === "approvePrivateRequest") {
            return "スケジュール申請が届きました。";
        }
    }, []);

    const getToDoRequest = useCallback((teacherToDo: TeacherToDoResponse) => {
        if (teacherToDo.toDoType === "createCourse") {
            return "スケジュールを作成してください。";
        } else if (teacherToDo.toDoType === "overallEvaluation") {
            return "授業完了報告をしてください。";
        } else if (teacherToDo.toDoType === "checkEvaluation") {
            return "評価を確認してください。";
        } else if (teacherToDo.toDoType === "courseIsWithdrawn") {
            return "参加者を確認してください。";
        } else if (teacherToDo.toDoType === "approvePrivateRequest") {
            return "スケジュールを確認してください。";
        }
    }, []);

    const handleApprovePrivateRequestModalClose = useCallback(() => {
        setTargetTeacherToDo(null);
    }, []);

    return (
        <>
            <ToDosTooltip
                open={tooltipOpen}
                badgeContent={props.teacherToDos.length}
                handleTooltipClose={handleTooltipClose}
                handleTooltipOpen={handleTooltipOpen}
                handleCheckButtonClick={handleCheckButtonClick}
                title={
                    <div className={styles.toDoTooltipContents}>
                        <div className={styles.toDoListTitle}>やることリスト</div>
                        {props.teacherToDos.length ? (
                            <ul className={styles.toDos} style={{ maxHeight: maxModalHeight }}>
                                {props.teacherToDos.map((teacherToDo, idx) => (
                                    <li className={styles.toDo}>
                                        <Button
                                            key={teacherToDo.teacherToDoId}
                                            className={styles.toDoButton}
                                            onClick={() => {
                                                handleTeacherToDoClick(teacherToDo);
                                            }}
                                            style={{ borderTop: idx === 0 ? "none" : "1px solid #CCC" }}
                                        >
                                            <AvatarFromS3
                                                url={teacherToDo.student.iconImageUrl}
                                                objectKey={teacherToDo.student.iconImageObjectKey}
                                                className={styles.avatar}
                                            />
                                            <div className={styles.centerWrapper}>
                                                <div className={styles.title}>{getToDoTitle(teacherToDo)}</div>
                                                <div className={styles.publicRequest}>
                                                    {getToDoRequest(teacherToDo)}
                                                </div>
                                            </div>
                                            <div className={styles.diffTime}>
                                                {teacherToDo.createdAt && getDiffTime(new Date(teacherToDo.createdAt))}
                                            </div>
                                        </Button>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className={styles.nothing}>現在やることはありません。</div>
                        )}
                    </div>
                }
            />
            {targetTeacherToDo && (
                <ApprovePrivateRequestModal
                    open={targetTeacherToDo != null}
                    targetTeacherToDo={targetTeacherToDo}
                    handleApprovePrivateRequestModalClose={handleApprovePrivateRequestModalClose}
                />
            )}
        </>
    );
});
