import { ListItem } from "../ListItem";

import styles from "../index.module.scss";
import { useCallback } from "react";
import { ClassResponse } from "@/store/autogenApi";
import { getTotalMinutesInAMonth } from "@/utils/BaseScheduleUtils";
import { AiOutlineQuestionCircle } from "react-icons/ai";

interface Props {
    class: ClassResponse | undefined;
    isConfirmed?: boolean;
}

export const Estimate: React.VFC<Props> = (props) => {
    const handleAboutMonthlyFeeLinkButtonClick = useCallback(() => {
        window.open("/FAQDetails?type=monthlyFee");
    }, []);

    return (
        <ListItem title="月額料金の目安" borderBottom>
            <div className={styles.estimateWrapper}>
                <div className={styles.estimate}>
                    ¥
                    {props.class?.privateRequest?.estimatedMonthlyFee
                        ? new Intl.NumberFormat("ja-JP").format(props.class?.privateRequest?.estimatedMonthlyFee)
                        : 0}
                </div>
                <div className={styles.estimateDescription}>
                    ※
                    生徒さんは目安価格をご覧の上でスケジュール申請されていますが、こちらはあくまで目安になります。生徒さんのご要望に合わせて月額料金を調整してください。
                </div>
                <button className={styles.aboutMonthlyFeeLinkButton} onClick={handleAboutMonthlyFeeLinkButtonClick}>
                    <AiOutlineQuestionCircle className={styles.questionIcon} />
                    <div className={styles.aboutMonthlyFee}>月額料金の目安の算出方法</div>
                </button>
            </div>
        </ListItem>
    );
};
