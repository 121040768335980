import { memo } from "react";
import { MenuItem } from "@material-ui/core";
import styles from "./index.module.scss";
import { ErrorMessage } from "@/components/ErrorMessage";
import { StyledSelect } from "@/components/StyledSelect";
import { ListItem } from "../ListItem";

interface Props {
    maxParticipants?: number;
    isChecked: boolean;
    isConfirmMode?: boolean;
    validation: boolean;
    existStudent?: boolean;
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

export const MaxParticipants: React.VFC<Props> = memo(function MaxParticipants(props) {
    return (
        <ListItem
            title="最大募集人数"
            isConfirmMode={props.isConfirmMode}
            borderTop
            inputModeContents={
                <>
                    {props.existStudent ? (
                        <div className={styles.existStudentWrapper}>
                            <div className={styles.existStudent}>既に参加者がいるため、この項目は変更できません。</div>
                            <div className={styles.existingValue}>{props.maxParticipants}人</div>
                        </div>
                    ) : (
                        <>
                            <div className={styles.selectWrapper}>
                                <StyledSelect
                                    name="maxParticipants"
                                    value={props.maxParticipants ? props.maxParticipants : 0}
                                    isInlineBlock
                                    handleChange={props.handleChange}
                                >
                                    <MenuItem key={0} value={0} className={`${styles.numberItem} ${styles.zero}`} />
                                    {[...Array(10)].map((_, i) => (
                                        <MenuItem key={i + 1} value={i + 1} className={styles.numberItem}>
                                            {i + 1}
                                        </MenuItem>
                                    ))}
                                </StyledSelect>
                                人
                            </div>
                            <ErrorMessage
                                when={props.isChecked && !props.validation}
                                content="この項目は必須です。選択してください。"
                            />
                            <div className={styles.description}>
                                ※1対1で授業を行いたい場合は「1」を選択してください。
                            </div>
                        </>
                    )}
                </>
            }
            confirmModeContents={<>{props.maxParticipants}人</>}
        />
    );
});
