import { memo, useCallback, useState } from "react";

import { Button, Hidden } from "@material-ui/core";
import { VscTriangleLeft, VscTriangleRight } from "react-icons/vsc";

import styles from "./index.module.scss";
import { PublicTeacherResponse } from "@/store/autogenApi";
import { TeacherCard } from "../TeacherCard";

interface Props {
    title: string;
    teachers: PublicTeacherResponse[];
    repetition: number;
    isFlex?: boolean;
    hasTwoLines?: boolean;
}

export const RepetitionCard: React.VFC<Props> = memo(function RepetitionCard(props) {
    const [listNumber, setListNumber] = useState<number>(0);
    const previous = useCallback(() => {
        if (listNumber === 0) {
            const maxListNumber = Math.ceil(props.teachers.length / props.repetition) - 1;
            setListNumber(maxListNumber);
        } else {
            setListNumber(listNumber - 1);
        }
    }, [listNumber]);
    const next = useCallback(() => {
        const maxListNumber = Math.ceil(props.teachers.length / props.repetition) - 1;
        if (listNumber === maxListNumber) {
            setListNumber(0);
        } else {
            setListNumber(listNumber + 1);
        }
    }, [listNumber]);

    return (
        <>
            <Hidden mdUp>
                <div className={styles.mdUpHidden}>
                    {props.teachers.length === 0 ? (
                        <div className={styles.emptyTeachers}>対象の講座がありません。</div>
                    ) : (
                        <div className={styles.cards}>
                            {props.teachers.map((teacher) => {
                                return <TeacherCard teacher={teacher} key={teacher.teacherId} isFlex={props.isFlex} />;
                            })}
                        </div>
                    )}
                </div>
            </Hidden>
            <Hidden smDown>
                <div className={styles.smDownHidden}>
                    {props.teachers.length === 0 ? (
                        <div className={styles.emptyTeachers}>対象の講座がありません。</div>
                    ) : (
                        <>
                            <Hidden xsDown>
                                {props.teachers.length > props.repetition && (
                                    <Button className={styles.prevButton} onClick={previous}>
                                        <VscTriangleLeft size="20px" />
                                    </Button>
                                )}
                            </Hidden>
                            {props.hasTwoLines ? (
                                <div className={styles.verticalWrapper}>
                                    <div className={styles.cards}>
                                        {props.teachers
                                            .slice(
                                                listNumber * props.repetition,
                                                listNumber * props.repetition + Math.ceil(props.repetition / 2),
                                            )
                                            .map((teacher) => {
                                                return (
                                                    <TeacherCard
                                                        teacher={teacher}
                                                        key={teacher.teacherId}
                                                        isFlex={props.isFlex}
                                                    />
                                                );
                                            })}
                                        {props.teachers.slice(
                                            listNumber * props.repetition,
                                            listNumber * props.repetition + Math.ceil(props.repetition / 2),
                                        ).length < Math.ceil(props.repetition / 2) &&
                                            [
                                                ...Array(
                                                    Math.ceil(props.repetition / 2) -
                                                        props.teachers.slice(
                                                            listNumber * props.repetition,
                                                            listNumber * props.repetition +
                                                                Math.ceil(props.repetition / 2),
                                                        ).length,
                                                ),
                                            ].map((_, idx) => <TeacherCard key={idx} isHidden isFlex={props.isFlex} />)}
                                    </div>
                                    <div className={styles.cards}>
                                        {props.teachers
                                            .slice(
                                                listNumber * props.repetition + Math.ceil(props.repetition / 2),
                                                listNumber * props.repetition + props.repetition,
                                            )
                                            .map((teacher) => {
                                                return (
                                                    <TeacherCard
                                                        teacher={teacher}
                                                        key={teacher.teacherId}
                                                        isFlex={props.isFlex}
                                                    />
                                                );
                                            })}
                                        {props.teachers.slice(
                                            listNumber * props.repetition + Math.ceil(props.repetition / 2),
                                            listNumber * props.repetition + props.repetition,
                                        ).length <
                                            props.repetition / 2 &&
                                            [
                                                ...Array(
                                                    props.repetition / 2 -
                                                        props.teachers.slice(
                                                            listNumber * props.repetition +
                                                                Math.ceil(props.repetition / 2),
                                                            listNumber * props.repetition + props.repetition,
                                                        ).length,
                                                ),
                                            ].map((_, idx) => <TeacherCard key={idx} isHidden isFlex={props.isFlex} />)}
                                    </div>
                                </div>
                            ) : (
                                <div className={styles.cards}>
                                    {props.teachers
                                        .slice(
                                            listNumber * props.repetition,
                                            listNumber * props.repetition + props.repetition,
                                        )
                                        .map((teacher) => {
                                            return (
                                                <TeacherCard
                                                    teacher={teacher}
                                                    key={teacher.teacherId}
                                                    isFlex={props.isFlex}
                                                />
                                            );
                                        })}
                                    {props.teachers.slice(
                                        listNumber * props.repetition,
                                        listNumber * props.repetition + props.repetition,
                                    ).length < props.repetition &&
                                        [
                                            ...Array(
                                                props.repetition -
                                                    props.teachers.slice(
                                                        listNumber * props.repetition,
                                                        listNumber * props.repetition + props.repetition,
                                                    ).length,
                                            ),
                                        ].map((_, idx) => <TeacherCard key={idx} isHidden isFlex={props.isFlex} />)}
                                </div>
                            )}
                            {props.teachers.length > props.repetition && (
                                <Hidden xsDown>
                                    <Button className={styles.nextButton} onClick={next}>
                                        <VscTriangleRight size="20px" />
                                    </Button>
                                </Hidden>
                            )}
                        </>
                    )}
                </div>
            </Hidden>
        </>
    );
});
