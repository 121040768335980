import { Button, CircularProgress, Modal, TextField } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { memo, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CurrentCognitoUserContext, useCognitoUser } from "@/components/Authentication";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Password } from "@/components/Password";
import { TextInput } from "@/components/TextInput";
import { ToastContents } from "@/components/Toast";

import styles from "../index.module.scss";

interface Props {}

export const EmailSetting = memo(function EmailSetting(props: Props) {
    const [updateEmailModalOpen, setUpdateEmailModalOpen] = useState<boolean>(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordForConfirm, setPasswordForConfirm] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isPasswordCorrect, setIsPasswordCorrect] = useState(true);
    const [isResent, setIsResent] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [isSentVerificationCode, setIsSentVerificationCode] = useState(false);
    const [isProcessingForVerifying, setIsProcessingForVerifying] = useState<boolean>(false);
    const [isValidVerificationCode, setIsValidVerificationCode] = useState(true);
    const [isProcessingForSendingVerificationCode, setIsProcessingForSendingVerificationCode] =
        useState<boolean>(false);
    const [verificationCode, setVerificationCode] = useState<string>("");

    const cognitoUser = useCognitoUser() as any;

    const handleEmailChangeButtonClick = useCallback(() => {
        setUpdateEmailModalOpen(true);
    }, []);
    const handleUpdateEmailModalClose = useCallback(() => {
        setUpdateEmailModalOpen(false);
        setIsChecked(false);
        setIsEmailValid(false);
        setVerificationCode("");
        setIsSentVerificationCode(false);
        setIsValidVerificationCode(true);
        setIsResent(false);
    }, []);

    const regexp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.+-]*@[A-Za-z0-9_.-]+\.[A-Za-z0-9]+$/;
    const handleEmailChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
            setIsEmailValid(regexp.test(event.target.value));
        },
        [regexp],
    );

    const ratz = /[a-z]/,
        rAtZ = /[A-Z]/,
        r0t9 = /[0-9]/;
    const handlePasswordChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            setPassword(value);
            const result = ratz.test(value) && rAtZ.test(value) && r0t9.test(value) && value.length >= 8;
            setIsPasswordValid(result);
        },
        [ratz, rAtZ, r0t9],
    );

    const handlePasswordChangeForConfirm = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordForConfirm(event.target.value);
    }, []);

    const handleSendVerificationCodeButtonClick = useCallback(async () => {
        setIsChecked(true);
        if (!isEmailValid) return;
        if (!isPasswordValid) return;
        try {
            setIsProcessingForSendingVerificationCode(true);
            // パスワードが一致しているか確認
            const aaa = await Auth.signIn(cognitoUser.attributes.email, password);
            console.log(aaa);
            await Auth.updateUserAttributes(cognitoUser, { email });
            setIsProcessingForSendingVerificationCode(false);
            setIsSentVerificationCode(true);
            setIsPasswordCorrect(true);
        } catch (error) {
            if (error.name === "NotAuthorizedException") {
                setIsProcessingForSendingVerificationCode(false);
                setIsPasswordCorrect(false);
                return;
            }
            console.log(error);
        }
    }, [email, password, passwordForConfirm, cognitoUser, isEmailValid, isPasswordValid, isPasswordCorrect]);

    const { setCurrentCognitoUser } = useContext(CurrentCognitoUserContext);

    const handleVerificationCodeSubmitButtonClick = useCallback(async () => {
        if (!isEmailValid) return;
        if (!isPasswordValid) return;
        try {
            setIsProcessingForVerifying(true);
            const result = await Auth.verifyCurrentUserAttributeSubmit("email", verificationCode);
            if (result === "SUCCESS") {
                await Auth.signOut();
                const currentCognitoUser = await Auth.signIn(email, password);
                setCurrentCognitoUser(currentCognitoUser);
                setIsProcessingForVerifying(false);
                setIsVerified(true);
                setIsSentVerificationCode(false);
                setIsValidVerificationCode(true);
                setEmail("");
                setPassword("");
                setUpdateEmailModalOpen(false);
                toast(<ToastContents title="メールアドレスを更新しました。" isCompleted />);
            } else {
                setIsProcessingForVerifying(false);
                setIsValidVerificationCode(false);
            }
        } catch (error) {
            console.log(error);
            setIsProcessingForVerifying(false);
            setIsValidVerificationCode(false);
        }
    }, [email, verificationCode, isEmailValid, isPasswordValid, password]);

    const handleResendVerificationCodeButtonClick = useCallback(async () => {
        setIsChecked(true);
        setIsValidVerificationCode(true);
        setVerificationCode("");
        if (!isEmailValid) return;
        setIsProcessingForSendingVerificationCode(true);
        try {
            await Auth.updateUserAttributes(cognitoUser, { email });
            setIsProcessingForSendingVerificationCode(false);
            setIsSentVerificationCode(true);
            setIsResent(true);
        } catch (error) {
            console.log(error);
        }
    }, [email, cognitoUser, isEmailValid]);

    const handleVerificationCodeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIsValidVerificationCode(true);
        setVerificationCode(event.target.value);
    }, []);

    return (
        <div className={styles.targetWrapper}>
            <div className={styles.targetTitle}>メールアドレス</div>
            <div className={styles.target}>{cognitoUser?.attributes.email}</div>
            <button className={styles.changeButton} onClick={handleEmailChangeButtonClick}>
                変更
            </button>
            <Modal open={updateEmailModalOpen} onClose={handleUpdateEmailModalClose}>
                <div className={styles.updateModalContents}>
                    <>
                        {isSentVerificationCode ? (
                            <div className={styles.sentWrapper}>
                                <div className={styles.message}>
                                    以下のメールアドレスに認証コードを{isResent ? "再送信" : "送信"}しました。
                                </div>
                                <div className={styles.email}>{email}</div>
                                <div className={styles.verificationCodeWrapper}>
                                    <div className={styles.horizontalWrapper}>
                                        <TextField
                                            variant="outlined"
                                            className={styles.verificationCodeInput}
                                            value={verificationCode}
                                            label={"認証コード"}
                                            onChange={handleVerificationCodeChange}
                                        />
                                        <Button
                                            onClick={handleVerificationCodeSubmitButtonClick}
                                            className={styles.verificationCodeSubmitButton}
                                        >
                                            {isProcessingForVerifying ? (
                                                <CircularProgress className={styles.processing} />
                                            ) : (
                                                <div className={styles.verify}>認証</div>
                                            )}
                                        </Button>
                                    </div>
                                    <ErrorMessage
                                        content="認証コードが正しくありません。"
                                        when={isChecked && !isValidVerificationCode}
                                        style={{ textAlign: "left", margin: "5px auto 0" }}
                                    />
                                    <button
                                        onClick={handleResendVerificationCodeButtonClick}
                                        className={styles.resendButton}
                                    >
                                        {isProcessingForSendingVerificationCode ? (
                                            <CircularProgress className={styles.progress} />
                                        ) : (
                                            <div className={styles.resend}>認証コードを再送信</div>
                                        )}
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className={styles.beforeSentWrapper}>
                                {/* {props.error && (
                        <div className={styles.errorMessage}>メールアドレスまたはパスワードに誤りがあります</div>
                    )} */}
                                <div className={styles.inputWrapper}>
                                    <TextInput
                                        label="新しいメールアドレス"
                                        value={email}
                                        handleChange={handleEmailChange}
                                    />
                                    <ErrorMessage
                                        content="メールアドレスが正しくありません。"
                                        when={isChecked && !isEmailValid}
                                        style={{ marginBottom: "5px" }}
                                    />
                                    <ErrorMessage
                                        content="このメールアドレスは既に使用されています。"
                                        when={isChecked && email === cognitoUser?.attributes.email}
                                        style={{ marginBottom: "5px" }}
                                    />
                                    <Password label="パスワード" value={password} handleChange={handlePasswordChange} />
                                    {/* <TextField
                                        id="password-confirm-input"
                                        label="Password(確認用)"
                                        type="password"
                                        autoComplete="current-password"
                                        margin="normal"
                                        onChange={handlePasswordChangeForConfirm}
                                        className={styles.input}
                                        variant="outlined"
                                        size="small"
                                    />
                                    <ErrorMessage
                                        content="パスワードは小文字、大文字、数字をそれぞれ1文字以上含む8文字以上で入力してください。"
                                        when={isChecked && !isPasswordValid}
                                        style={{ marginBottom: "5px" }}
                                    /> */}
                                    <ErrorMessage
                                        content="パスワードが正しくありません。"
                                        when={isChecked && (!isPasswordCorrect || password === "")}
                                        style={{ marginBottom: "5px" }}
                                    />
                                </div>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleSendVerificationCodeButtonClick}
                                    className={styles.sendVerificationCodeButton}
                                >
                                    {isProcessingForSendingVerificationCode ? (
                                        <CircularProgress className={styles.processing} />
                                    ) : (
                                        <div className={styles.send}>認証コードを送信</div>
                                    )}
                                </Button>
                            </div>
                        )}
                    </>
                </div>
            </Modal>
        </div>
    );
});
