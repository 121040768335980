import Tooltip from "@material-ui/core/Tooltip";
import Rating from "@material-ui/lab/Rating";
import { AiOutlineCheckCircle } from "react-icons/ai";

import { CourseResponse, PublicTeacherResponse, PublicUserResponse } from "@/store/autogenApi";
import { IndividualChatButton } from "@/components/IndividualChatButton";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import styles from "@/pages/Common/TeacherDetails/index.module.scss";
import { ImageFromS3 } from "@/components/atoms/images/ImageFromS3";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";
import { useCallback } from "react";
import { SchoolIcon } from "@/globalIcons";
import defaultBackgroundImageUrl from "@/images/backgroundTemplate.jpg";

interface Props {
    teacher: PublicTeacherResponse;
    user: PublicUserResponse;
    courses: CourseResponse[];
    getTotalReviewsLength: () => number;
}

export const TopInfoPCContents: React.VFC<Props> = (props) => {
    const studentId = useSelector((state: RootState) => state.jwt.studentId);
    const userMode = useSelector((state: RootState) => state.jwt.userMode);

    const getEducationalBackground = useCallback(() => {
        if (props.teacher.educationalBackground == undefined) return "未登録";
        const educationalBackground = props.teacher.educationalBackground;
        return `${educationalBackground.university} ${educationalBackground.faculty} ${educationalBackground.department}`;
    }, [props.teacher]);

    return (
        <div className={styles.PCTopInfoWrapper}>
            <div className={styles.profileWrapper}>
                <div className={styles.profile}>
                    <AvatarFromS3
                        className={styles.imageIcon}
                        url={props.teacher.iconImageUrl}
                        objectKey={props.teacher.iconImageObjectKey}
                    />
                    {userMode === "student" && studentId && (
                        <IndividualChatButton
                            studentId={studentId}
                            teacherId={props.teacher.teacherId}
                            buttonLabel="チャット"
                        />
                    )}
                </div>
                <div className={styles.rightInfoWrapper}>
                    <div className={styles.catchCopyAndNickname}>
                        <p className={styles.teacherCatchCopy}>{props.teacher.catchCopy}</p>
                        <p className={styles.teacherNickname}>{props.teacher.nickname} 先生</p>
                    </div>
                    <div className={styles.identificationWrapper}>
                        {props.teacher.identificationStatus === "approved" ? (
                            <div className={styles.identifiedWrapper}>
                                <AiOutlineCheckCircle className={styles.checkedIcon} />
                                <div className={styles.identified}>本人確認済み</div>
                            </div>
                        ) : (
                            <div className={styles.notIdentifiedWrapper}>
                                <div className={styles.notIdentified}>本人確認前</div>
                            </div>
                        )}
                    </div>
                    {props.teacher.isEducationalBackgroundVerified ? (
                        <Tooltip title="学歴確認済" arrow>
                            <div className={styles.educationalBackgroundWrapper}>
                                <SchoolIcon className={styles.identifiedSchoolIcon} />
                                <div className={styles.educationalBackground}>{getEducationalBackground()}</div>
                            </div>
                        </Tooltip>
                    ) : (
                        <div className={styles.educationalBackgroundWrapper}>
                            <SchoolIcon className={styles.schoolIcon} />
                            <div className={styles.educationalBackground}>{getEducationalBackground()}</div>
                        </div>
                    )}
                    <div className={styles.review}>
                        <Tooltip title="先生の平均レビュー" arrow>
                            <div className={styles.ratingWrapper}>
                                <Rating
                                    name="half-rating-read"
                                    defaultValue={
                                        props.teacher.stats?.averageReviewScore
                                            ? props.teacher.stats?.averageReviewScore
                                            : 0
                                    }
                                    precision={0.5}
                                    readOnly
                                    className={styles.rating}
                                    size="small"
                                />
                                <div className={styles.score}>({props.getTotalReviewsLength()})</div>
                            </div>
                        </Tooltip>
                    </div>
                    <div className={styles.participantsAndAchievement}>
                        <div className={styles.teachStatus}>
                            <p className={styles.statusTitle}>教えた人数：</p>
                            <p className={styles.statusScore}>{props.teacher.totalAchievements}人</p>
                        </div>
                        <div className={styles.teachStatus}>
                            <p className={styles.statusTitle}>教えた回数：</p>
                            <p className={styles.statusScore}>{props.teacher.totalAchievements}回</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.coverWrapper}>
                <ImageFromS3
                    url={props.teacher.backgroundImageUrl}
                    objectKey={props.teacher.backgroundImageObjectKey}
                    defaultUrl={defaultBackgroundImageUrl}
                    className={styles.imageCover}
                />
            </div>
            {/* <Button className={styles.editButton} onClick={handleEditButtonClick}>
                プロフィールを編集する
            </Button> */}
            {/* {props.teacher ? (
                <div className={styles.authorizedWrapper}>
                    <div className={styles.authorized}>Zoomアカウント連携済み</div>
                    <AiOutlineCheckCircle className={styles.checkIcon} />
                </div>
            ) : (
                <Button className={styles.notAuthorizedButton} onClick={zoomAuthorize}>
                    Zoomアカウントと連携する
                </Button>
            )} */}
        </div>
    );
};
