import { useEffect, useState } from "react";

import { Hidden } from "@material-ui/core";

import { UserType } from "@/protectedRouter/ProtectedRouterConfig";
import { ClassResponse } from "@/store/autogenApi";

import { PCContents } from "./PCContents";
import { SPContents } from "./SPContents";

interface Props {
    smUp: boolean;
    studentId: string | null | undefined;
    courseId: string;
    howMuchLackOfPoints: number;
    title?: string;
    isFavorite: boolean;
    targetClass: ClassResponse | undefined;
    activeClasses: ClassResponse[];
    userMode: UserType;
    isHostTeacher: boolean;
    addScheduleModalOpen: boolean;
    fixedContentsEl: React.MutableRefObject<HTMLElement | null>;
    totalNecessaryPoints: number;
    formula: string;
    isReservationButtonClicked: boolean;
    thresholdOver: boolean;
    getJapaneseDay: (idx: number) => string;
    getDiffTime: (date: Date) => string;
    handleTargetClassChange: (classId: string | undefined) => void;
    handleChargeButtonClick: () => void;
    handleReservationButtonClick: (classId: string) => void;
    handleAddScheduleModalClose: () => void;
    handleAddScheduleModalOpenButtonClick: () => void;
    handleClassButtonClick: (classId: string) => void;
    handleSignUpModalOpen: () => void;
    initializeReservationButtonClicked: () => void;
}

export const ShortCourseContents: React.VFC<Props> = (props) => {
    const [isReserved, setIsReserved] = useState<boolean>(false);

    useEffect(() => {
        if (props.activeClasses.length > 0 && props.targetClass) {
            const participants = props.targetClass.participants;
            if (props.studentId && participants) {
                const fetchedIsReserved = participants
                    .map((student) => student.studentId.toString())
                    .includes(props.studentId);
                setIsReserved(fetchedIsReserved);
            }
        }
    }, [props.studentId, props.activeClasses]);
    return (
        <>
            <Hidden smUp>
                <SPContents
                    courseId={props.courseId}
                    isFavorite={props.isFavorite}
                    isHostTeacher={props.isHostTeacher}
                    targetClass={props.targetClass}
                    isReserved={isReserved}
                    addScheduleModalOpen={props.addScheduleModalOpen}
                    activeClasses={props.activeClasses}
                    howMuchLackOfPoints={props.howMuchLackOfPoints}
                    fixedContentsEl={props.fixedContentsEl}
                    totalNecessaryPoints={props.totalNecessaryPoints}
                    formula={props.formula}
                    userMode={props.userMode}
                    isReservationButtonClicked={props.isReservationButtonClicked}
                    thresholdOver={props.thresholdOver}
                    getJapaneseDay={props.getJapaneseDay}
                    handleTargetClassChange={props.handleTargetClassChange}
                    handleChargeButtonClick={props.handleChargeButtonClick}
                    handleReservationButtonClick={props.handleReservationButtonClick}
                    handleAddScheduleModalOpenButtonClick={props.handleAddScheduleModalOpenButtonClick}
                    handleAddScheduleModalClose={props.handleAddScheduleModalClose}
                    handleClassButtonClick={props.handleClassButtonClick}
                    handleSignUpModalOpen={props.handleSignUpModalOpen}
                    initializeReservationButtonClicked={props.initializeReservationButtonClicked}
                />
            </Hidden>
            <Hidden xsDown>
                <PCContents
                    smUp={props.smUp}
                    courseId={props.courseId}
                    isFavorite={props.isFavorite}
                    isHostTeacher={props.isHostTeacher}
                    targetClass={props.targetClass}
                    activeClasses={props.activeClasses}
                    howMuchLackOfPoints={props.howMuchLackOfPoints}
                    isReserved={isReserved}
                    addScheduleModalOpen={props.addScheduleModalOpen}
                    userMode={props.userMode}
                    fixedContentsEl={props.fixedContentsEl}
                    totalNecessaryPoints={props.totalNecessaryPoints}
                    formula={props.formula}
                    isReservationButtonClicked={props.isReservationButtonClicked}
                    thresholdOver={props.thresholdOver}
                    getJapaneseDay={props.getJapaneseDay}
                    getDiffTime={props.getDiffTime}
                    handleTargetClassChange={props.handleTargetClassChange}
                    handleChargeButtonClick={props.handleChargeButtonClick}
                    handleReservationButtonClick={props.handleReservationButtonClick}
                    handleAddScheduleModalOpenButtonClick={props.handleAddScheduleModalOpenButtonClick}
                    handleAddScheduleModalClose={props.handleAddScheduleModalClose}
                    handleClassButtonClick={props.handleClassButtonClick}
                    handleSignUpModalOpen={props.handleSignUpModalOpen}
                    initializeReservationButtonClicked={props.initializeReservationButtonClicked}
                />
            </Hidden>
        </>
    );
};
