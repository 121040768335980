import { memo, useCallback, useEffect } from "react";
import { useState } from "react";

import { Button, createTheme, ThemeProvider } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";

import { logout } from "@/actions/logging";
import { RouterPrompt } from "@/components/RouterPrompt";
import { ProfileInput } from "@/pageComponents/Common/InfoInput/ProfileInput";
import { TeacherProfileInput } from "@/pageComponents/Teacher/InfoInput/TeacherProfileInput";

import styles from "@/pages/Teacher/InfoInput/index.module.scss";
import { CreateUserRequestBody, JwtRequestBody } from "@/store/autogenApi";
import { RootState } from "@/ducks";
import { decodeJwt } from "@/ducks/jwt";
import { useNavigation } from "@/components/Navigation/NavigationContext";

export const TeacherInfoInput: React.VFC = memo(function TeacherInfoInput() {
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [createUserRequestBody, setCreateUserRequestBody] = useState<CreateUserRequestBody | undefined>(undefined);

    const userId = useSelector((state: RootState) => state.jwt.userId as string);

    const STEPS = ["ユーザー情報（共通）入力", "先生情報入力"];

    const theme = createTheme({
        overrides: {
            MuiStepper: {
                root: {
                    padding: "10px",
                },
            },
            MuiStepIcon: {
                root: {
                    fontSize: "28px",
                    "@media (max-width: 960px)": {
                        fontSize: "24px",
                    },
                    "@media (max-width: 600px)": {
                        fontSize: "20px",
                    },
                },
                text: {
                    fontSize: "16px",
                    "@media (max-width: 960px)": {
                        fontSize: "16px",
                    },
                    "@media (max-width: 600px)": {
                        fontSize: "16px",
                    },
                },
                active: {
                    color: "#305077 !important",
                },
                completed: {
                    color: "#305077 !important",
                },
            },
            MuiStepLabel: {
                label: {
                    color: "#305077 !important",
                    fontWeight: "bold !important" as "bold",
                    fontSize: "16px",
                    "@media (max-width: 960px)": {
                        fontSize: "14px",
                    },
                    "@media (max-width: 600px)": {
                        fontSize: "13px",
                    },
                },
            },
            MuiTypography: {
                body1: {
                    fontSize: "16px",
                    "@media (max-width: 960px)": {
                        fontSize: "14px",
                    },
                    "@media (max-width: 600px)": {
                        fontSize: "13px",
                    },
                },
            },
            MuiSvgIcon: {
                root: {
                    fontSize: "30px",
                    "@media (max-width: 960px)": {
                        fontSize: "25px",
                    },
                    "@media (max-width: 600px)": {
                        fontSize: "18px",
                    },
                },
            },
        },
    });

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList([]);
        const beforeUnloadFunc = (e: BeforeUnloadEvent) => {
            e.preventDefault();
            e.returnValue = "";
        };
        window.addEventListener("beforeunload", beforeUnloadFunc);
        window.addEventListener("unload", deleteUser);
        return () => {
            window.removeEventListener("beforeunload", beforeUnloadFunc);
        };
    }, []);

    const history = useHistory();

    useEffect(() => {
        if (!isCompleted) return;
        history.push("/ThanksSignUp?userType=teacher");
    }, [isCompleted]);

    useEffect(() => {
        if (!hasError) return;
        history.push("/SignUp");
    }, [hasError]);

    const dispatch = useDispatch();

    const deleteUser = useCallback(async () => {
        const token = localStorage.getItem("token");
        const jwtRequestBody = decodeJwt(token) as JwtRequestBody;
        const userMode = jwtRequestBody.userMode;
        const userId = jwtRequestBody.userId;
        if (userMode === undefined && userId) {
            await Auth.deleteUser();
            dispatch(logout());
            localStorage.clear();
        }
    }, []);

    const nextStep = useCallback(() => {
        setActiveStep(activeStep + 1);
        window.scrollTo({
            top: 0,
            behavior: "auto",
        });
    }, [activeStep]);

    const previousStep = useCallback(() => {
        setActiveStep(activeStep - 1);
    }, [activeStep]);

    const handleOKButtonClick = useCallback(() => {
        (async () => {
            if (userId) {
                await Auth.deleteUser();
                dispatch(logout());
                localStorage.clear();
            }
        })();
        return true;
    }, [userId]);

    const handleCancelButtonClick = useCallback(() => {
        return false;
    }, []);

    const handleHasErrorChange = useCallback((hasError: boolean) => {
        setHasError(hasError);
    }, []);
    return (
        <>
            <RouterPrompt
                when={!hasError && !isCompleted}
                message={
                    "新規登録が未完了です。\n現在の入力内容及びアカウントデータは失われますが、このページを終了してもよろしいですか？"
                }
                onOK={handleOKButtonClick}
                onCancel={handleCancelButtonClick}
            />
            <div className={styles.page}>
                <div className={styles.contents}>
                    <div className={styles.signUpTitle}>先生登録</div>
                    <div className={styles.stepperWrapper}>
                        <ThemeProvider theme={theme}>
                            <Stepper alternativeLabel activeStep={activeStep}>
                                {STEPS.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </ThemeProvider>
                        <Button
                            onClick={previousStep}
                            className={styles.previousButton}
                            style={{ display: activeStep === 0 ? "none" : "" }}
                        >
                            「{STEPS[activeStep - 1]}」へ戻る
                        </Button>
                    </div>
                    <div className={styles.stepperContents}>
                        {activeStep === 0 && (
                            <ProfileInput
                                createUserRequestBody={createUserRequestBody}
                                setCreateUserRequestBody={setCreateUserRequestBody}
                                nextStep={nextStep}
                            />
                        )}
                        {activeStep === 1 && (
                            <TeacherProfileInput
                                createUserRequestBody={createUserRequestBody}
                                deleteUser={deleteUser}
                                setIsCompleted={setIsCompleted}
                                handleHasErrorChange={handleHasErrorChange}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
});
