import { memo } from "react";
import styles from "./index.module.scss";
import { Mandatory } from "../Tag/Mandatory";
import { Optional } from "../Tag/Optional";

interface Props {
    // true: 必須項目, false: 任意項目, undefined: なし
    isMandatory?: boolean;
    itemTitle: string;
    itemDescription?: string;
    children?: React.ReactNode;
    isFirstItem?: boolean;
}

export const FormItem: React.VFC<Props> = memo(function FormItem(props) {
    return (
        <li className={styles.formItemWrapper} style={{ borderTop: props.isFirstItem ? "none" : "1px solid #305077" }}>
            <div className={styles.itemTitle}>
                {props.itemTitle}
                {props.isMandatory === true ? <Mandatory /> : props.isMandatory === false ? <Optional /> : null}
            </div>
            <div className={styles.itemRightWrapper}>
                {props.itemDescription && <div className={styles.itemDescription}>{props.itemDescription}</div>}
                {props.children != null && <div className={styles.itemDescription}>{props.children}</div>}
            </div>
        </li>
    );
});
