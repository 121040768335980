export const FAQConfig = [
    {
        title: "ご利用ガイド",
        contents: [
            {
                title: "生徒の方へ",
                type: "forStudent",
                contents: [
                    {
                        title: "生徒として新規登録する",
                        type: "signUpAsStudent",
                    },
                    {
                        title: "講座を探す",
                        type: "searchCourse",
                    },
                    {
                        title: "気になった講座を「気になる」登録する",
                        type: "favoriteCourse",
                    },
                    {
                        title: "講座を受講予約（事前決済）する",
                        type: "reserveCourse",
                    },
                    {
                        title: "レビューを投稿する",
                        type: "postReview",
                    },
                ],
            },
            {
                title: "先生の方へ",
                type: "forTeacher",
                contents: [
                    {
                        title: "先生登録・プロフィール登録をする",
                        type: "signUpAsTeacher",
                    },
                    {
                        title: "先生詳細ページについて",
                        type: "aboutTeacherDetailsPage",
                    },
                    {
                        title: "講座開催までの流れ",
                        type: "courseFlow",
                    },
                    {
                        title: "予約状況を確認する",
                        type: "checkReservation",
                    },
                    {
                        title: "講座の開催をキャンセルする",
                        type: "cancelHoldingCourse",
                    },
                    {
                        title: "先生の本人確認について",
                        type: "teacherVerification",
                    },
                ],
            },
        ],
    },
    {
        title: "Trailについて",
        contents: [
            {
                title: "はじめてTrailを利用する",
                type: "forBeginner",
                contents: [
                    {
                        title: "Trailはどのようなサービスですか？",
                        type: "serviceOfTrail",
                    },
                    {
                        title: "Trailでは何が学べますか？",
                        type: "whatToLearn",
                    },
                    {
                        title: "サポート対応時間はいつですか？",
                        type: "supportTime",
                    },
                    {
                        title: "利用するのに登録が必要ですか？",
                        type: "registrationRequired",
                    },
                ],
            },
            {
                title: "Trailのルールについて",
                type: "ruleOfTrail",
                contents: [
                    {
                        title: "利用のルール・ガイドラインはありますか？",
                        type: "ruleOrGuideline",
                    },
                    {
                        title: "Trailで禁止されている行為について",
                        type: "prohibitedAction",
                    },
                    {
                        title: "レビューとは",
                        type: "aboutReview",
                    },
                ],
            },
            {
                title: "新規登録について",
                type: "aboutRegistration",
                contents: [
                    {
                        title: "登録時のメールが届きません",
                        type: "emailNotArrived",
                    },
                ],
            },
            {
                title: "ログインについて",
                type: "aboutLogin",
                contents: [
                    {
                        title: "ログインができない",
                        type: "loginFailed",
                    },
                    {
                        title: "パスワードを忘れてしまった",
                        type: "forgotPassword",
                    },
                ],
            },
        ],
    },
    {
        title: "講座を受講する",
        contents: [
            {
                title: "決済・キャンセル・返金について",
                type: "aboutPayment",
                contents: [
                    {
                        title: "領収書を発行してほしい",
                        type: "receipt",
                    },
                    {
                        title: "講座をキャンセル／欠席したい",
                        type: "cancelCourse",
                    },
                    {
                        title: "受講停止時の返金について",
                        type: "refund",
                    },
                    {
                        title: "講座が開講されなかった場合の返金について",
                        type: "refundForNonOpening",
                    },
                    {
                        title: "クレジットカード情報の変更と削除の方法",
                        type: "creditCard",
                    },
                    {
                        title: "受講に消費税はかかりますか？",
                        type: "tax",
                    },
                    {
                        title: "定期講座の月額料金の目安の算出方法",
                        type: "monthlyFee",
                    },
                ],
            },
            {
                title: "操作について",
                type: "aboutOperation",
                contents: [
                    {
                        title: "先生からの連絡を確認したい",
                        type: "message",
                    },
                    {
                        title: "プロフィールを一般公開にした場合、どんな情報が公開されますか？",
                        type: "profile",
                    },
                ],
            },
        ],
    },
    {
        title: "講座を開催する",
        contents: [
            {
                title: "Trailで開催できる講座について",
                type: "aboutCourse",
                contents: [
                    {
                        title: "講座開催の注意事項について",
                        type: "caution",
                    },
                    {
                        title: "Trailではどのような講座を開催することができますか？",
                        type: "courseType",
                    },
                ],
            },
            {
                title: "講座作成について",
                type: "aboutCourseCreation",
                contents: [
                    {
                        title: "講座料金を変更したい",
                        type: "changeCourseFee",
                    },
                ],
            },
        ],
    },
    {
        title: "先生向けノウハウ",
        contents: [
            {
                title: "確定申告・納税について",
                type: "aboutTax",
                contents: [
                    {
                        title: "確定申告についてよくある質問",
                        type: "taxReturn",
                    },
                ],
            },
        ],
    },
    {
        title: "その他よくある質問",
        contents: [
            {
                title: "よくある質問",
                type: "aboutOther",
                contents: [
                    {
                        title: "Trailに関するお問い合わせ",
                        type: "contact",
                    },
                    {
                        title: "Trailを退会したい",
                        type: "withdrawal",
                    },
                ],
            },
            {
                title: "リリース情報",
                type: "releaseInfo",
                contents: [
                    {
                        title: "2022年10月リリース情報",
                        type: "ReleaseInfo202404",
                    },
                ],
            },
        ],
    },
];
