import { memo } from "react";

import { ListItem } from "@material-ui/core";

import styles from "./index.module.scss";
import { StyledSelect } from "../StyledSelect";

interface Props {
    hour: number | undefined;
    minute: number | undefined;
    handleHourChange: (
        e: React.ChangeEvent<{
            value: number;
        }>,
    ) => void;
    handleMinuteChange: (
        e: React.ChangeEvent<{
            value: number;
        }>,
    ) => void;
}

export const HourAndMinuteComponent: React.VFC<Props> = memo(function WhichTimeComponent(props) {
    return (
        <div className={styles.hourAndMinuteWrapper}>
            <div className={styles.hourWrapper}>
                <StyledSelect name="hour" value={props.hour} handleChange={props.handleHourChange}>
                    <ListItem
                        value={undefined}
                        className={styles.minuteListItem}
                        style={{
                            cursor: "pointer",
                            backgroundColor: props.hour == undefined ? "#EEE" : undefined,
                        }}
                    />
                    {[...Array(24)].map((_: undefined, hour: number) => (
                        <ListItem
                            value={hour}
                            key={hour}
                            className={styles.hourListItem}
                            style={{
                                cursor: "pointer",
                                backgroundColor: props.hour == hour ? "#EEE" : undefined,
                            }}
                        >
                            {("00" + hour).slice(-2)}
                        </ListItem>
                    ))}
                </StyledSelect>
            </div>
            <div className={styles.colon}>:</div>
            <div className={styles.minuteWrapper}>
                <StyledSelect name="minute" value={props.minute} handleChange={props.handleMinuteChange}>
                    <ListItem
                        value={undefined}
                        className={styles.minuteListItem}
                        style={{
                            cursor: "pointer",
                            backgroundColor: props.minute == null ? "#EEE" : undefined,
                        }}
                    />
                    {[...Array(12)].map((_: undefined, idx: number) => {
                        const minute = idx * 5;
                        return (
                            <ListItem
                                value={minute}
                                key={minute}
                                className={styles.minuteListItem}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: props.minute == minute ? "#EEE" : undefined,
                                }}
                            >
                                {("00" + minute).slice(-2)}
                            </ListItem>
                        );
                    })}
                </StyledSelect>
            </div>
        </div>
    );
});
