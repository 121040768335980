import { memo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { useParams } from "react-router";
import { useGetPrivateUserQuery } from "@/store/hooks/users";
import { useGetPublicRequestQuery } from "@/store/hooks/publicRequests";
import { RequestDetailsContents } from "@/pageComponents/Common/PublicRequestDetails/PublicRequestDetailsContents";
import { PrivateUserResponse } from "@/store/autogenApi";

export const RequestDetails: React.VFC = memo(function RequestDetails() {
    const userId = useSelector((state: RootState) => state.jwt.userId as string);
    const { id } = useParams<{ id: string }>();
    const privateUserQueryState = useGetPrivateUserQuery(userId);
    const requestQueryState = useGetPublicRequestQuery(id);

    return (
        <QueryLoadingWrapper {...privateUserQueryState}>
            {(privateUser) => (
                <QueryLoadingWrapper {...requestQueryState}>
                    {(publicRequest) => (
                        <RequestDetailsContents
                            privateUser={privateUser as PrivateUserResponse}
                            publicRequest={publicRequest}
                        />
                    )}
                </QueryLoadingWrapper>
            )}
        </QueryLoadingWrapper>
    );
});
