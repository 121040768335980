import { memo } from "react";

import styles from "@/pages/Student/MyPage/index.module.scss";
import { ChatResponse } from "@/store/autogenApi";

import { ChatInfo, SocketInfo } from "../ChatsContents";
import { ChatList } from "../ChatList";
import { TargetMessages } from "../TargetMessages";

interface Props {
    chatInfos: ChatInfo[];
    openedChatInfo: ChatInfo | undefined;
    temporaryObjectURLsList: string[][];
    socketInfos: SocketInfo[];
    isProcessing: boolean;
    handleIsProcessingChange: (isProcessing: boolean) => void;
    clearChatMessage: (chatId: string) => void;
    handleListButtonClick: (chat: ChatResponse) => Promise<void>;
    handleBackButtonClick: () => void;
    handleChatMessageChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    handleTemporaryObjectURLsListChange: (urlsList: string[][]) => void;
}

export const SPContents: React.VFC<Props> = memo(function SPContents(props) {
    return (
        <>
            {props.chatInfos.length === 0 ? (
                <div className={styles.emptyMessage}>メッセージのやり取りがまだありません。</div>
            ) : (
                <>
                    {props.openedChatInfo == undefined ? (
                        <ChatList
                            openedChatInfo={props.openedChatInfo}
                            chatInfos={props.chatInfos}
                            handleListButtonClick={props.handleListButtonClick}
                        />
                    ) : (
                        <TargetMessages
                            chatInfos={props.chatInfos}
                            openedChatInfo={props.openedChatInfo}
                            temporaryObjectURLsList={props.temporaryObjectURLsList}
                            socketInfos={props.socketInfos}
                            isProcessing={props.isProcessing}
                            handleIsProcessingChange={props.handleIsProcessingChange}
                            clearChatMessage={props.clearChatMessage}
                            handleBackButtonClick={props.handleBackButtonClick}
                            handleChatMessageChange={props.handleChatMessageChange}
                            handleTemporaryObjectURLsListChange={props.handleTemporaryObjectURLsListChange}
                        />
                    )}
                </>
            )}
        </>
    );
});
