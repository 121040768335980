import { Button, Modal } from "@material-ui/core";

import styles from "./index.module.scss";
import { memo, useCallback, useEffect, useState } from "react";
import { CreateBaseScheduleParams } from "@/store/autogenApi";
import { Item } from "./Item";
import { ApplicationMessageInput } from "./ApplicationMessageInput";
import { useCreatePrivateRequestMutation } from "@/store/hooks/privateRequests";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { Processing } from "@/components/Processing";
import { StartDateDescription } from "./StartDateDescription";
import { LastDateDescription } from "./LastDateDescription";
import { getBaseScheduleInfo, getPricePerMonth } from "@/utils/BaseScheduleUtils";
import { BaseSchedulesInput } from "@/components/BaseSchedulesInput";
import { useGetStudentLessonsQuery } from "@/store/hooks/lessons";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { ToastContents } from "@/components/Toast";
import { toast } from "react-toastify";

interface Props {
    price: number;
    courseTeacherId: string;
    privateRequestModalOpen: boolean;
    classId: string;
    handlePrivateRequestModalClose: () => void;
}

export type WhichTime = "startTime" | "endTime";

export const PrivateRequestModal: React.VFC<Props> = memo(function PrivateRequestModal(props) {
    const [isRequestChecked, setRequestIsChecked] = useState(false);
    const [modalHeight, setModalHeight] = useState<number>(0);
    const [firstDateDescription, setStartDateDescription] = useState<string>("");
    const [lastDateDescription, setLastDateDescription] = useState<string>("");
    const [applicationMessage, setApplicationMessage] = useState<string>("");
    const [isConfirmMode, setIsConfirmMode] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [baseSchedules, setBaseSchedules] = useState<CreateBaseScheduleParams[]>([]);

    const { id } = useParams<{ id: string }>();
    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);

    const createPrivateRequest = useCreatePrivateRequestMutation();
    const studentLessonsQueryState = useGetStudentLessonsQuery(studentId);

    useEffect(() => {
        changeModalHeight();
        window.addEventListener("resize", () => {
            changeModalHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalHeight();
            });
        };
    }, []);

    const changeModalHeight = () => {
        const innerHeight = window.innerHeight;
        setModalHeight(innerHeight * 0.75);
    };

    const getIsBaseSchedulesValid = useCallback(() => {
        const result = baseSchedules.some((baseSchedule) => {
            if (baseSchedule.howManyMinutes <= 0) {
                return false;
            }
            return true;
        });
        return result;
    }, [baseSchedules]);

    const handleStartDateDescriptionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setStartDateDescription(event.target.value);
    }, []);

    const handleLastDateDescriptionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setLastDateDescription(event.target.value);
    }, []);

    const handleApplicationMessageChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setApplicationMessage(event.target.value);
    }, []);

    const handleCheckButtonClick = useCallback(() => {
        setRequestIsChecked(true);
        const isWeeklyScheduleValid = getIsBaseSchedulesValid();
        const isApplicationMessageValid = 0 < applicationMessage.length && applicationMessage.length <= 500;
        const isStartDateDescriptionValid = 0 < firstDateDescription.length && firstDateDescription.length <= 500;
        const isLastDateDescriptionValid = lastDateDescription.length <= 500;
        if (
            !isWeeklyScheduleValid ||
            !isApplicationMessageValid ||
            !isStartDateDescriptionValid ||
            !isLastDateDescriptionValid
        )
            return;
        setIsConfirmMode(true);
    }, [getIsBaseSchedulesValid, applicationMessage]);

    const handleBackButtonClick = useCallback(() => {
        setIsConfirmMode(false);
    }, []);

    const handleConfirmButtonClick = useCallback(async () => {
        setIsProcessing(true);
        const estimatedMonthlyFee = getPricePerMonth(baseSchedules, props.price);
        const { isSuccess } = await createPrivateRequest({
            createPrivateRequestRequestBody: {
                classId: props.classId,
                teacherId: props.courseTeacherId,
                studentId: studentId,
                message: applicationMessage,
                estimatedMonthlyFee: estimatedMonthlyFee,
                firstDateDescription,
                lastDateDescription,
                baseSchedules,
            },
        });
        setIsProcessing(false);
        if (isSuccess) {
            toast(<ToastContents title="スケジュール申請完了" isCompleted />);
        } else {
            toast(<ToastContents title="スケジュール申請失敗" isFailed />);
        }
    }, [
        applicationMessage,
        firstDateDescription,
        lastDateDescription,
        createPrivateRequest,
        id,
        studentId,
        props.classId,
        props.courseTeacherId,
        baseSchedules,
        props.price,
    ]);

    const handleDeleteButtonClick = useCallback(
        (idx: number) => {
            const newBaseSchedules = baseSchedules.filter((_, index) => index !== idx);
            setBaseSchedules(newBaseSchedules);
        },
        [baseSchedules],
    );

    const handleBaseSchedulesChange = useCallback((newBaseSchedules: CreateBaseScheduleParams[]) => {
        setBaseSchedules(newBaseSchedules);
    }, []);

    return (
        <Modal
            open={props.privateRequestModalOpen}
            onClose={props.handlePrivateRequestModalClose}
            className={styles.privateRequestModal}
        >
            <div className={styles.privateRequestModalContents} style={{ height: `${modalHeight}px` }}>
                <div className={styles.scheduleApplicationTitle}>スケジュール申請</div>
                <div className={styles.scheduleApplicationDescription}>
                    ※
                    定期講座の支払いにはクレジットカードが必要です。先生がスケジュール申請を承認し、月額料金が確定した後で情報を入力していただきますので、クレジットカードをお持ちの方に限りスケジュール申請をお願いします。
                </div>
                {isConfirmMode ? (
                    <>
                        <div className={styles.confirmMessage}>以下の内容で申請しますがよろしいですか？</div>
                        <ul className={styles.itemList}>
                            <Item title="授業希望日程" borderBottom>
                                <ul className={styles.schedules}>
                                    {baseSchedules.map((baseSchedule, idx) => {
                                        return (
                                            <li className={styles.schedule} key={idx}>
                                                {getBaseScheduleInfo(baseSchedule)}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Item>
                            <Item title="講座開始希望日" borderBottom>
                                <div className={styles.confirmedStartDate}>{firstDateDescription}</div>
                            </Item>
                            <Item title="講座終了予定日" borderBottom>
                                <div className={styles.confirmedStartDate}>{lastDateDescription}</div>
                            </Item>
                            <Item title="申請メッセージ">
                                <div className={styles.confirmedApplicationMessage}>{applicationMessage}</div>
                            </Item>
                        </ul>
                        <div className={styles.buttonsWrapper}>
                            <Button className={styles.backButton} onClick={handleBackButtonClick}>
                                戻る
                            </Button>
                            <Button className={styles.checkButton} onClick={handleConfirmButtonClick}>
                                {isProcessing ? <Processing /> : "確定"}
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <ul className={styles.itemList}>
                            <Item title="基本スケジュール" borderBottom isMandatory>
                                <QueryLoadingWrapper {...studentLessonsQueryState}>
                                    {(existingLessons) => (
                                        <BaseSchedulesInput
                                            isRequestChecked={isRequestChecked}
                                            validation={getIsBaseSchedulesValid()}
                                            baseSchedules={baseSchedules}
                                            pricePerHour={props.price}
                                            isInPrivateRequestModal
                                            existingLessons={existingLessons}
                                            handleBaseScheduleDeleteButtonClick={handleDeleteButtonClick}
                                            handleBaseSchedulesChange={handleBaseSchedulesChange}
                                        />
                                    )}
                                </QueryLoadingWrapper>
                            </Item>
                            <Item title="講座開始希望日" borderBottom isMandatory>
                                <StartDateDescription
                                    isRequestChecked={isRequestChecked}
                                    value={firstDateDescription}
                                    handleChange={handleStartDateDescriptionChange}
                                />
                            </Item>
                            <Item title="講座終了予定日" borderBottom isOptional>
                                <LastDateDescription
                                    isRequestChecked={isRequestChecked}
                                    value={lastDateDescription}
                                    handleChange={handleLastDateDescriptionChange}
                                />
                            </Item>
                            <Item title="申請メッセージ" isMandatory>
                                <ApplicationMessageInput
                                    isRequestChecked={isRequestChecked}
                                    value={applicationMessage}
                                    handleChange={handleApplicationMessageChange}
                                />
                            </Item>
                        </ul>
                        <div className={styles.buttonsWrapper}>
                            <Button className={styles.backButton} onClick={props.handlePrivateRequestModalClose}>
                                閉じる
                            </Button>
                            <Button className={styles.checkButton} onClick={handleCheckButtonClick}>
                                確認
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
});
