import { QueryState } from "@/store/hooks/utils/parseResult";

type Props<T> = QueryState<T> & {
    isHidden?: boolean;
    children: (data: T | undefined, isLoaded: boolean) => React.ReactNode;
};

export const QueryLoadingWrapperOnlyPart = <T,>(props: Props<T>) => {
    return <>{props.children(props.data, props.isSuccess && !props.isFetching)}</>;
};
