import { memo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { RootState } from "@/ducks";
import { useGetAllFavoriteCoursesQuery } from "@/store/hooks/students";
import { CommonComponent } from "./CommonComponent";
import { FavoriteContents } from "./FavoriteContents";

interface Props {
    isSPVersion?: boolean;
    isSimplified?: boolean;
    userMode: string;
    favoriteCount: number;
    isReservationButtonClicked: boolean;
    handleSignUpModalOpen: () => void;
    initializeReservationButtonClicked: () => void;
}

export const ForStudentComponent: React.VFC<Props> = memo(function ForStudentComponent(props) {
    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);
    const { id } = useParams<{ id: string }>();
    const favoriteCoursesQueryState = useGetAllFavoriteCoursesQuery(studentId);
    return (
        <QueryLoadingWrapper {...favoriteCoursesQueryState}>
            {(favoriteCourses) => (
                <CommonComponent
                    isSPVersion={props.isSPVersion}
                    isSimplified={props.isSimplified}
                    userMode={props.userMode}
                    isReservationButtonClicked={props.isReservationButtonClicked}
                    favoriteCount={props.favoriteCount}
                    isFavorite={favoriteCourses.some((course) => course.courseId === id)}
                    handleSignUpModalOpen={props.handleSignUpModalOpen}
                    initializeReservationButtonClicked={props.initializeReservationButtonClicked}
                />
            )}
        </QueryLoadingWrapper>
    );
});
