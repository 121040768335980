import styles from "./index.module.scss";
import { CreateAvailableTimeRequestParams, LessonResponse, UpdateAvailableTimeRequestBody } from "@/store/autogenApi";

import { WeeklyScheduleComponent } from "./WeeklySchedule";

import { memo, useCallback, useState } from "react";
import { getDayAndTime } from "@/utils/AvailableTimeUtils";
import { LessonCalendarButton } from "@/components/LessonCalendarButton";
import { HourAndMinute } from "@/utils/DateUtils";
import { ListItem } from "../../ListItem";

interface Props {
    isChecked: boolean;
    isConfirmMode?: boolean;
    maxDaysInAWeek: number;
    maxMinutesPerLesson: number;
    availableTimes: (CreateAvailableTimeRequestParams | UpdateAvailableTimeRequestBody)[];
    existingLessons: LessonResponse[];
    availableTimesLengthValidation: boolean;
    handleMaxMinutesPerLessonChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleMaxDaysInAWeekChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleAvailableTimesChange: (
        newAvailableTimes: (CreateAvailableTimeRequestParams | UpdateAvailableTimeRequestBody)[],
    ) => void;
}

export const RegularCourseSchedule: React.VFC<Props> = memo(function RegularCourseSchedule(props) {
    const [activeDayOfWeekPopoverIdx, setActiveDayOfWeekPopoverIdx] = useState<number | undefined>(undefined);
    const [newStartTime, setNewStartTime] = useState<HourAndMinute>({
        hour: null,
        minute: null,
    });
    const [newEndTime, setNewEndTime] = useState<HourAndMinute>({
        hour: null,
        minute: null,
    });
    const [dayAnchorEl, setDayAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [deletedAvailableTimes, setDeletedAvailableTimes] = useState<Partial<UpdateAvailableTimeRequestBody>[]>([]);

    const handleDayTimePopoverClose = useCallback(() => {
        setActiveDayOfWeekPopoverIdx(undefined);
        setDayAnchorEl(null);
        setNewStartTime({
            hour: null,
            minute: null,
        });
        setNewEndTime({
            hour: null,
            minute: null,
        });
    }, []);

    const handleResetButtonClick = useCallback(() => {
        const newAvailableTimes = props.availableTimes.map((availableTime) => {
            if (availableTime.dayOfWeekIndex === activeDayOfWeekPopoverIdx) {
                const newAvailableTime: CreateAvailableTimeRequestParams | UpdateAvailableTimeRequestBody = {
                    dayOfWeekIndex: availableTime.dayOfWeekIndex,
                    isActive: false,
                    startHour: null,
                    startMinute: null,
                    endHour: null,
                    endMinute: null,
                };
                return newAvailableTime;
            }
            return availableTime;
        });
        props.handleAvailableTimesChange(newAvailableTimes);
        handleDayTimePopoverClose();
    }, [activeDayOfWeekPopoverIdx, props.availableTimes, props.handleAvailableTimesChange]);
    const checkDayTime = useCallback(
        (dayOfWeekIndex: number) => {
            const targetAvailableTime = props.availableTimes.find(
                (availableTime) => availableTime.dayOfWeekIndex === dayOfWeekIndex,
            );
            if (!targetAvailableTime) return undefined;
            if (!targetAvailableTime.isActive) return true;
            const startTimeHour = targetAvailableTime.startHour;
            const startTimeMinute = targetAvailableTime.startMinute;
            const endTimeHour = targetAvailableTime.endHour;
            const endTimeMinute = targetAvailableTime.endMinute;
            if (
                startTimeHour == undefined ||
                startTimeMinute == undefined ||
                endTimeHour == undefined ||
                endTimeMinute == undefined
            )
                return false;
            if (startTimeHour < endTimeHour) {
                return true;
            } else if (startTimeHour === endTimeHour) {
                return startTimeMinute < endTimeMinute;
            } else {
                return false;
            }
        },
        [props.availableTimes],
    );

    // 定期講座confirm
    const confirmDayTime = useCallback(() => {
        console.log(props.availableTimes);
        if (
            newStartTime.hour == null ||
            newStartTime.minute == null ||
            newEndTime.hour == null ||
            newEndTime.minute == null ||
            activeDayOfWeekPopoverIdx == undefined
        )
            return;
        const existingAvailableTime = deletedAvailableTimes.find(
            (availableTime) => availableTime.dayOfWeekIndex === activeDayOfWeekPopoverIdx,
        );
        const newAvailableTimeId = existingAvailableTime ? existingAvailableTime.availableTimeId : undefined;
        const newAvailableTime = {
            dayOfWeekIndex: activeDayOfWeekPopoverIdx,
            startHour: newStartTime.hour,
            startMinute: newStartTime.minute,
            endHour: newEndTime.hour,
            endMinute: newEndTime.minute,
            isActive: true,
        };
        const modifiedAvailableTime: CreateAvailableTimeRequestParams | UpdateAvailableTimeRequestBody =
            newAvailableTimeId
                ? {
                      availableTimeId: newAvailableTimeId,
                      ...newAvailableTime,
                  }
                : (newAvailableTime as CreateAvailableTimeRequestParams);
        const newAvailableTimes = props.availableTimes.map((availableTime) => {
            if (availableTime.dayOfWeekIndex === activeDayOfWeekPopoverIdx) {
                return modifiedAvailableTime;
            } else {
                return availableTime;
            }
        });
        console.log(newAvailableTimes);
        props.handleAvailableTimesChange(newAvailableTimes);
        handleDayTimePopoverClose();
    }, [
        activeDayOfWeekPopoverIdx,
        deletedAvailableTimes,
        newStartTime,
        newEndTime,
        handleDayTimePopoverClose,
        props.handleAvailableTimesChange,
        props.availableTimes,
    ]);

    const handleDayTimeButtonClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>, idx: number) => {
            setActiveDayOfWeekPopoverIdx(idx);
            setDayAnchorEl(event.currentTarget);
            const targetAvailableTime = props.availableTimes.find(
                (availableTime) => availableTime.dayOfWeekIndex === idx,
            );
            if (!targetAvailableTime) return;
            const startHour = targetAvailableTime.startHour == undefined ? null : targetAvailableTime.startHour;
            const startMinute = targetAvailableTime.startMinute == undefined ? null : targetAvailableTime.startMinute;
            const endHour = targetAvailableTime.endHour == undefined ? null : targetAvailableTime.endHour;
            const endMinute = targetAvailableTime.endMinute == undefined ? null : targetAvailableTime.endMinute;
            setNewStartTime({
                hour: startHour,
                minute: startMinute,
            });
            setNewEndTime({
                hour: endHour,
                minute: endMinute,
            });
        },
        [props.availableTimes],
    );

    const handleStartTimeChange = useCallback(
        (e: React.ChangeEvent<{ name: string; value: number }>) => {
            if (e.target.name === "hour") {
                setNewStartTime({ ...newStartTime, hour: e.target.value });
            } else {
                setNewStartTime({ ...newStartTime, minute: e.target.value });
            }
        },
        [newStartTime],
    );

    const handleEndTimeChange = useCallback(
        (e: React.ChangeEvent<{ name: string; value: number }>) => {
            if (e.target.name === "hour") {
                setNewEndTime({ ...newEndTime, hour: e.target.value });
            } else {
                setNewEndTime({ ...newEndTime, minute: e.target.value });
            }
        },
        [newEndTime],
    );

    return (
        <ListItem
            isConfirmMode={props.isConfirmMode}
            title="対応可能時間帯"
            hasSpecialRightWrapper
            inputModeContents={
                <>
                    <WeeklyScheduleComponent
                        newStartTime={newStartTime}
                        newEndTime={newEndTime}
                        anchorEl={dayAnchorEl}
                        activeDayOfWeekPopoverIdx={activeDayOfWeekPopoverIdx}
                        maxDaysInAWeek={props.maxDaysInAWeek}
                        maxMinutesPerLesson={props.maxMinutesPerLesson}
                        availableTimes={props.availableTimes}
                        availableTimesLengthValidation={props.availableTimesLengthValidation}
                        handleMaxMinutesPerLessonChange={props.handleMaxMinutesPerLessonChange}
                        handleMaxDaysInAWeekChange={props.handleMaxDaysInAWeekChange}
                        confirmDayTime={confirmDayTime}
                        handleStartTimeChange={handleStartTimeChange}
                        handleEndTimeChange={handleEndTimeChange}
                        handleDayTimePopoverClose={handleDayTimePopoverClose}
                        handleDayTimeButtonClick={handleDayTimeButtonClick}
                        handleResetButtonClick={handleResetButtonClick}
                        checkDayTime={checkDayTime}
                        isChecked={props.isChecked}
                    />
                    <div className={styles.lessonCalendarWrapper}>
                        <LessonCalendarButton
                            lessons={props.existingLessons}
                            existingLessons={props.existingLessons}
                            onlyView
                            isPlain
                            style={{ paddingLeft: 0, paddingRight: 0, marginTop: "20px" }}
                        />
                    </div>
                </>
            }
            confirmModeContents={
                <>
                    <ul className={styles.availableTimes}>
                        {props.availableTimes.map((availableTime, idx) => {
                            return (
                                <li className={styles.availableTime} key={idx}>
                                    {`${getDayAndTime(availableTime)}`}
                                </li>
                            );
                        })}
                    </ul>
                    <div className={styles.maxInfo}>
                        週に{props.maxDaysInAWeek}回以内・各{props.maxMinutesPerLesson}分以内を想定
                    </div>
                </>
            }
        />
    );
});
