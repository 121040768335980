import React from "react";
import { useGetPresignedUrlForGet } from "@/store/hooks/files";
import { Avatar } from "@material-ui/core";

interface Props {
    objectKey: string;
    className?: string;
    title?: string;
    ref?: (node: HTMLImageElement | null) => void;
}

export const AvatarFromObjectKey: React.VFC<Props> = ({ objectKey, className, title, ref }) => {
    const preSignedUrlQueryState = useGetPresignedUrlForGet(objectKey);

    return preSignedUrlQueryState.isSuccess && preSignedUrlQueryState.data !== undefined ? (
        <Avatar
            src={preSignedUrlQueryState.data.preSignedUrl}
            className={className}
            ref={ref}
            title={title}
            alt="ロード中です"
        />
    ) : null;
};
