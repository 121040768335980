import { Button, Popover } from "@material-ui/core";
import { HourAndMinuteComponent } from "./HourAndMinute";
import styles from "./index.module.scss";
import { memo } from "react";
import { HourAndMinute } from "@/utils/DateUtils";

interface Props {
    open: boolean;
    startTime?: Date | undefined;
    endTime?: Date | undefined;
    newStartTime: HourAndMinute;
    newEndTime: HourAndMinute;
    coursesIdx?: number;
    anchorEl: HTMLButtonElement | null;
    confirmWhichTime: () => void;
    handleClose: () => void;
    handleStartTimeChange: (
        e: React.ChangeEvent<{
            name: string;
            value: number;
        }>,
    ) => void;
    handleEndTimeChange: (
        e: React.ChangeEvent<{
            name: string;
            value: number;
        }>,
    ) => void;
    handleResetButtonClick?: () => void;
}

export const WhichTimePopover: React.VFC<Props> = memo(function WhichTimePopover(props) {
    return (
        <>
            <Popover
                open={props.open}
                anchorEl={props.anchorEl}
                onClose={props.handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                className={styles.timePopOver}
            >
                <div className={styles.popOverContents}>
                    <div className={styles.startAndFinishWrapper}>
                        {props.coursesIdx !== undefined && (
                            <div className={styles.titlesWrapper}>
                                <div className={styles.startTimeTitle}>
                                    {props.coursesIdx ? props.coursesIdx + 1 : 1}日目 開始時刻
                                </div>
                                <div className={styles.space}></div>
                                <div className={styles.endTimeTitle}>
                                    {props.coursesIdx ? props.coursesIdx + 1 : 1}日目 終了時刻
                                </div>
                            </div>
                        )}
                        <div className={styles.selectsWrapper}>
                            <HourAndMinuteComponent
                                hourAndMinute={props.newStartTime}
                                whichTimeString="startTime"
                                handleChange={props.handleStartTimeChange}
                            />
                            <div className={styles.tilde}>~</div>
                            <HourAndMinuteComponent
                                hourAndMinute={props.newEndTime}
                                whichTimeString="endTime"
                                handleChange={props.handleEndTimeChange}
                            />
                        </div>
                    </div>
                    <div className={styles.scheduleButtonsWrapper}>
                        {props.handleResetButtonClick && (
                            <Button className={styles.scheduleResetButton} onClick={props.handleResetButtonClick}>
                                リセット
                            </Button>
                        )}
                        <Button className={styles.scheduleConfirmButton} onClick={props.confirmWhichTime}>
                            確定
                        </Button>
                    </div>
                </div>
            </Popover>
        </>
    );
});
