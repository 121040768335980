import { memo, useCallback, useState } from "react";

import { Button, Hidden } from "@material-ui/core";
import { VscTriangleLeft, VscTriangleRight } from "react-icons/vsc";

import styles from "./index.module.scss";
import { PublicRequestResponse } from "@/store/autogenApi";
import { RequestCard } from "../RequestCard";

interface Props {
    title: string;
    requests: PublicRequestResponse[];
    repetition: number;
    isFlex?: boolean;
    hasTwoLines?: boolean;
}

export const RepetitionCard: React.VFC<Props> = memo(function RepetitionCard(props) {
    const [listNumber, setListNumber] = useState<number>(0);
    const previous = useCallback(() => {
        if (listNumber === 0) {
            const maxListNumber = Math.ceil(props.requests.length / props.repetition) - 1;
            setListNumber(maxListNumber);
        } else {
            setListNumber(listNumber - 1);
        }
    }, [listNumber]);
    const next = useCallback(() => {
        const maxListNumber = Math.ceil(props.requests.length / props.repetition) - 1;
        if (listNumber === maxListNumber) {
            setListNumber(0);
        } else {
            setListNumber(listNumber + 1);
        }
    }, [listNumber]);

    return (
        <>
            <Hidden mdUp>
                <div className={styles.mdUpHidden}>
                    {props.requests.length === 0 ? (
                        <div className={styles.emptyRequests}>対象の講座リクエストがありません</div>
                    ) : (
                        <div className={styles.cards}>
                            {props.requests.map((publicRequest) => {
                                return (
                                    <RequestCard publicRequest={publicRequest} key={publicRequest.publicRequestId} />
                                );
                            })}
                        </div>
                    )}
                </div>
            </Hidden>
            <Hidden smDown>
                <div className={styles.smDownHidden}>
                    {props.requests.length === 0 ? (
                        <div className={styles.emptyRequests}>対象の講座リクエストがありません</div>
                    ) : (
                        <>
                            <Hidden xsDown>
                                {props.requests.length > props.repetition && (
                                    <Button className={styles.prevButton} onClick={previous}>
                                        <VscTriangleLeft size="20px" />
                                    </Button>
                                )}
                            </Hidden>
                            {props.hasTwoLines ? (
                                <div className={styles.verticalWrapper}>
                                    <div className={styles.cards}>
                                        {props.requests
                                            .slice(
                                                listNumber * props.repetition,
                                                listNumber * props.repetition + Math.ceil(props.repetition / 2),
                                            )
                                            .map((publicRequest) => {
                                                return (
                                                    <RequestCard
                                                        publicRequest={publicRequest}
                                                        key={publicRequest.publicRequestId}
                                                        isFlex={props.isFlex}
                                                    />
                                                );
                                            })}
                                        {props.requests.slice(
                                            listNumber * props.repetition,
                                            listNumber * props.repetition + Math.ceil(props.repetition / 2),
                                        ).length < Math.ceil(props.repetition / 2) &&
                                            [
                                                ...Array(
                                                    Math.ceil(props.repetition / 2) -
                                                        props.requests.slice(
                                                            listNumber * props.repetition,
                                                            listNumber * props.repetition +
                                                                Math.ceil(props.repetition / 2),
                                                        ).length,
                                                ),
                                            ].map((_, key) => <RequestCard key={key} isHidden isFlex={props.isFlex} />)}
                                    </div>
                                    {props.requests.slice(
                                        listNumber * props.repetition + Math.ceil(props.repetition / 2),
                                        listNumber * props.repetition + props.repetition,
                                    ).length > 0 && (
                                        <div className={styles.cards}>
                                            {props.requests
                                                .slice(
                                                    listNumber * props.repetition + Math.ceil(props.repetition / 2),
                                                    listNumber * props.repetition + props.repetition,
                                                )
                                                .map((publicRequest) => {
                                                    return (
                                                        <RequestCard
                                                            publicRequest={publicRequest}
                                                            key={publicRequest.publicRequestId}
                                                            isFlex={props.isFlex}
                                                        />
                                                    );
                                                })}
                                            {props.requests.slice(
                                                listNumber * props.repetition + Math.ceil(props.repetition / 2),
                                                listNumber * props.repetition + props.repetition,
                                            ).length < Math.ceil(props.repetition / 2) &&
                                                [
                                                    ...Array(
                                                        Math.ceil(props.repetition / 2) -
                                                            props.requests.slice(
                                                                listNumber * props.repetition +
                                                                    Math.ceil(props.repetition / 2),
                                                                listNumber * props.repetition + props.repetition,
                                                            ).length,
                                                    ),
                                                ].map((_, key) => (
                                                    <RequestCard key={key} isHidden isFlex={props.isFlex} />
                                                ))}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className={styles.cards}>
                                    {props.requests
                                        .slice(
                                            listNumber * props.repetition,
                                            listNumber * props.repetition + props.repetition,
                                        )
                                        .map((publicRequest) => {
                                            return (
                                                <RequestCard
                                                    publicRequest={publicRequest}
                                                    key={publicRequest.publicRequestId}
                                                    isFlex={props.isFlex}
                                                />
                                            );
                                        })}
                                    {props.requests.slice(
                                        listNumber * props.repetition,
                                        listNumber * props.repetition + props.repetition,
                                    ).length < props.repetition &&
                                        [
                                            ...Array(
                                                props.repetition -
                                                    props.requests.slice(
                                                        listNumber * props.repetition,
                                                        listNumber * props.repetition + props.repetition,
                                                    ).length,
                                            ),
                                        ].map((_, key) => <RequestCard key={key} isHidden isFlex={props.isFlex} />)}
                                </div>
                            )}
                            {props.requests.length > props.repetition && (
                                <Hidden xsDown>
                                    <Button className={styles.nextButton} onClick={next}>
                                        <VscTriangleRight size="20px" />
                                    </Button>
                                </Hidden>
                            )}
                        </>
                    )}
                </div>
            </Hidden>
        </>
    );
});
