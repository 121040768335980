import styles from "./index.module.scss";
import { useLocation } from "react-router";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

export const ThanksSignUp: React.VFC = () => {
    const query = new URLSearchParams(useLocation().search);
    const userType = query.get("userType");

    return (
        <div className={styles.page}>
            <div className={styles.contents}>
                <div className={styles.signUpTitle}>{userType === "student" ? "生徒" : "先生"}登録完了</div>
                <div className={styles.completeMessageWrapper}>
                    <div className={styles.completeMessage}>ご登録いただきありがとうございます！</div>
                    <div className={styles.completeMessage}>Trailでの活動をお楽しみください！</div>
                    <div className={styles.completeMessage}>ご利用にはログインが必要です。</div>
                </div>
                <Button component={Link} to="/SignIn" className={styles.toTopButton}>
                    ログインへ進む
                </Button>
            </div>
        </div>
    );
};
