import { useMediaQuery } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router";

import { PublicRequestResponse } from "@/store/autogenApi";

import styles from "./index.module.scss";
import { useCallback } from "react";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    publicRequest?: PublicRequestResponse;
    isHidden?: boolean;
    isFlex?: boolean;
}

export const RequestCard: React.VFC<Props> = (props) => {
    const smUp = useMediaQuery("(min-width:600px)");
    const getPrice = () => {
        if (props.isHidden) return "";
        return `¥${props.publicRequest?.minPrice}~¥${props.publicRequest?.maxPrice}`;
    };

    const history = useHistory();
    const detail = useCallback((publicRequestId: string) => {
        history.push(`/PublicRequestDetails/${publicRequestId}`);
    }, []);

    return (
        <Card
            className={styles.card}
            style={{
                visibility: props.isHidden ? "hidden" : "visible",
                flex: props.isFlex ? 1 : "none",
                width: props.isFlex ? "auto" : smUp ? "130px" : "110px",
            }}
            onClick={() => {
                props.publicRequest?.publicRequestId && detail(props.publicRequest?.publicRequestId);
            }}
        >
            <CardContent className={styles.cardContents}>
                <Typography className={styles.title}>{props.publicRequest?.title}</Typography>
                <Typography className={styles.description}>{props.publicRequest?.publicRequestDescription}</Typography>
                <div className={styles.studentInfoWrapper}>
                    <AvatarFromS3
                        url={props.publicRequest?.student?.iconImageUrl}
                        objectKey={props.publicRequest?.student?.iconImageObjectKey}
                        className={styles.avatar}
                    />
                    <div className={styles.teacherNickname}>{props.publicRequest?.student?.nickname}さん</div>
                </div>
                <div className={styles.priceAndHowLong}>
                    <div className={styles.priceWrapper}>
                        <Typography className={styles.price}>
                            <div className={styles.strong}>{getPrice()}</div>/時間
                        </Typography>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};
