import { FAQDetailsBase } from "../../FAQDetailsBase";

import { REGISTER_AS_STUDENT_CONFIGS } from "./SignUpAsStudentConfigs";

export const SignUpAsStudent: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="生徒として新規登録する"
            text="このページでは、生徒として新規登録する方法についてご説明します。登録には以下の2つの方法がご利用いただけます。"
            FAQConfigs={REGISTER_AS_STUDENT_CONFIGS}
            list={["メールアドレスで新規登録"]}
        />
    );
};
