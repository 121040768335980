import { memo, useCallback, useEffect, useState } from "react";
import styles from "./index.module.scss";

import { CourseType, ClassResponse, LessonResponse } from "@/store/autogenApi";
import { getNextLessonFromClass, getNextLessonTimeFromClass } from "@/utils/LessonUtils";
import { PlainButton } from "@/components/PlainButton";
import { UpdateMemoModal } from "./UpdateMemoModal";

interface Props {
    courseId: string;
    courseType: CourseType;
    targetClass: ClassResponse;
}

export const NextLessonInfo: React.VFC<Props> = memo(function NextLessonInfo(props) {
    const [nextLesson, nextLessonTime] = useState<LessonResponse | undefined>(undefined);
    const [updateMemoModalOpen, setUpdateMemoModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!props.targetClass) return;
        const newNextLesson = getNextLessonFromClass(props.targetClass);
        nextLessonTime(newNextLesson);
    }, [props.targetClass]);

    const handleUpdateMemoButtonClick = useCallback(() => {
        setUpdateMemoModalOpen(true);
    }, []);

    const handleUpdateMemoModalClose = useCallback(() => {
        setUpdateMemoModalOpen(false);
    }, []);

    return (
        <div className={styles.nextLessonInfoWrapper}>
            <div className={styles.nextLessonInfoTitle}>次回授業情報</div>
            <div className={styles.nextLessonInfoContents}>
                <div className={styles.nextLessonWrapper}>
                    <div className={styles.nextLessonTitle}>日時：</div>
                    <div className={styles.nextLesson}>{getNextLessonTimeFromClass(props.targetClass)}</div>
                    {nextLesson && (
                        <a className={styles.start} href={nextLesson.startUrl} target="_blank">
                            授業開始
                        </a>
                    )}
                </div>
                <div className={styles.memoWrapper}>
                    <div className={styles.memoTitle}>メモ：</div>
                    <div className={styles.memoRightWrapper}>
                        <div className={styles.memo}>{props.targetClass.memo ?? "なし"}</div>
                        <PlainButton
                            text="変更"
                            style={{ paddingTop: "0", paddingBottom: "0" }}
                            handleClick={handleUpdateMemoButtonClick}
                        />
                        <UpdateMemoModal
                            modalOpen={updateMemoModalOpen}
                            targetClass={props.targetClass}
                            handleModalClose={handleUpdateMemoModalClose}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});
