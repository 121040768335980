import { DraftPublicRequestResponse } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions } from "./utils/types";

export const useGetMyDraftPublicRequestsQuery = (studentId: string) => {
    const result = defaultApi.endpoints.getMyDraftPublicRequests.useQuery({ studentId });
    return useQueryResult(result, (response: DraftPublicRequestResponse[]) => response);
};

export const useGetDraftPublicRequestQuery = (draftPublicRequestId: string) => {
    const result = defaultApi.endpoints.getDraftPublicRequest.useQuery({ draftPublicRequestId });
    return useQueryResult(result, (response: DraftPublicRequestResponse) => response);
};

export const useCreateDraftPublicRequestMutation = (options?: UseMutationOptions) => {
    const [createPublicRequest] = defaultApi.endpoints.createDraftPublicRequest.useMutation();
    return useMutationFunctionWrapper(createPublicRequest, options?.errorMessages);
};

export const useUpdateDraftPublicRequestMutation = (options?: UseMutationOptions) => {
    const [updateRequest] = defaultApi.endpoints.updateDraftPublicRequest.useMutation();
    return useMutationFunctionWrapper(updateRequest, options?.errorMessages);
};

export const useDeleteDraftPublicRequestMutation = (options?: UseMutationOptions) => {
    const [deleteDraftPublicRequest] = defaultApi.endpoints.deleteDraftPublicRequest.useMutation();
    return useMutationFunctionWrapper(deleteDraftPublicRequest, options?.errorMessages);
};
