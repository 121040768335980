import { memo, useCallback, useEffect, useState } from "react";

import { Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import styles from "@/pages/Teacher/CreateCourse/index.module.scss";
import {
    CourseType,
    CreateAvailableTimeRequestParams,
    CreateLessonRequestParams,
    LessonResponse,
    SubjectResponse,
    UpdateAvailableTimeRequestBody,
} from "@/store/autogenApi";

import { AdditionalDescription } from "./Items/AdditionalDescription";
import { ApplicationPeriod } from "./Items/ApplicationPeriod";
import { MaxNumber } from "./Items/MaxNumber";
import { ContentDescription } from "./Items/ContentDescription";
import { PictureUpload } from "./Items/PictureUpload";
import { PricePerHour } from "./Items/PricePerHour";
import { Schedules } from "./Items/Schedules";
import { Subjects } from "./Items/Subjects";
import { TargetDescription } from "./Items/TargetDescription";
import { Title } from "./Items/Title";
import { FileInfo } from "@/components/TrimmingModal";
import { config, ConfigKey } from "./LimitConfig";
import { CourseTypeComponent } from "./Items/CourseType";
import { Processing } from "@/components/Processing";
import { TeachingMaterial } from "./Items/TeachingMaterial";
import { MinNumber } from "./Items/MinNumber";
import { getExistLessonConflict, getExistLessonConflictWithinLessons } from "@/utils/LessonUtils";

interface Props {
    confirmModalOpen: boolean;
    modalMaxHeight: number;
    isProcessing: boolean;
    currentCourseId: string;
    existingLessons: LessonResponse[];
    courseType: CourseType | undefined;
    pricePerHour: number;
    maxParticipants: number;
    minParticipants: number;
    title: string;
    teachingMaterial: string;
    courseDescription: string;
    targetDescription: string;
    additionalDescription: string;
    subjects: SubjectResponse[];
    maxMinutesPerLesson: number;
    maxDaysInAWeek: number;
    applicationPeriod: number;
    deadlineNumber: number;
    availableTimes: CreateAvailableTimeRequestParams[] | UpdateAvailableTimeRequestBody[];
    fileInfoList: FileInfo[];
    lessons: CreateLessonRequestParams[];
    applyingDeadline: Date;
    availableTimesLengthValidation: boolean;
    deadlineValidation: boolean;
    setAvailableTimes: React.Dispatch<
        React.SetStateAction<(CreateAvailableTimeRequestParams | UpdateAvailableTimeRequestBody)[]>
    >;
    deleteImage: (idx: number) => void;
    setFileInfoList: React.Dispatch<React.SetStateAction<FileInfo[]>>;
    setSubjects: React.Dispatch<React.SetStateAction<SubjectResponse[]>>;
    handleConfirmButtonClick: () => Promise<void>;
    handleCourseTypeChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handlePriceChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleMaxNumberChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleMinNumberChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleTitleChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleTeachingMaterialChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleCourseDescriptionChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleTargetDescriptionChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleAdditionalDescriptionChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleApplicationPeriodChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleMaxDaysInAWeekChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleMaxMinutesPerLessonChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleLessonsChange: (newLessons: CreateLessonRequestParams[]) => void;
    handleApplyingDeadlineChange: (newApplyingDeadline: Date) => void;
    handleConfirmModalClose: () => void;
}

export const CreateCourseConfirmModal: React.VFC<Props> = memo((props) => {
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [priceRange, setPriceRange] = useState<number[]>([0, 0]);

    useEffect(() => {
        if (props.courseType === "short") {
            setPriceRange([1000, 1000000]);
        } else if (props.courseType === "regular") {
            setPriceRange([1000, 100000]);
        } else {
            setPriceRange([500, 10000]);
        }
    }, [props.courseType]);

    return (
        <Modal
            open={props.confirmModalOpen}
            onClose={props.handleConfirmModalClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={styles.noticeModal}
        >
            <div className={styles.modalContents} style={{ maxHeight: `${props.modalMaxHeight}px` }}>
                <div className={styles.message}>
                    以下の内容で講座を{props.currentCourseId ? "更新" : "作成"}
                    します。よろしいですか？
                </div>
                <div className={styles.inputListWrapper}>
                    <div className={styles.inputListTitle}>講座基本情報</div>
                    <ul className={styles.inputList}>
                        <CourseTypeComponent
                            open
                            isChecked={isChecked}
                            courseType={props.courseType}
                            validation={true}
                            handleChange={props.handleCourseTypeChange}
                        />
                        {props.courseType && (
                            <>
                                <Title
                                    isChecked={isChecked}
                                    value={props.title}
                                    open
                                    validation={true}
                                    handleChange={props.handleTitleChange}
                                />
                                <TeachingMaterial
                                    isChecked={isChecked}
                                    value={props.teachingMaterial}
                                    open
                                    validation={true}
                                    handleChange={props.handleTeachingMaterialChange}
                                />
                                {props.subjects && (
                                    <Subjects
                                        open
                                        isChecked={isChecked}
                                        subjects={props.subjects}
                                        courseType={props.courseType}
                                        setSubjects={props.setSubjects}
                                    />
                                )}
                                <ContentDescription
                                    open
                                    validation={true}
                                    isChecked={isChecked}
                                    value={props.courseDescription as string}
                                    courseType={props.courseType}
                                    handleChange={props.handleCourseDescriptionChange}
                                />
                                <TargetDescription
                                    open
                                    validation={true}
                                    isChecked={isChecked}
                                    value={props.targetDescription}
                                    handleChange={props.handleTargetDescriptionChange}
                                />
                                <PictureUpload
                                    open
                                    validation={true}
                                    fileInfoList={props.fileInfoList}
                                    deleteImage={props.deleteImage}
                                    setFileInfoList={props.setFileInfoList}
                                />
                                <AdditionalDescription
                                    open
                                    validation={true}
                                    isChecked={isChecked}
                                    value={props.additionalDescription as string}
                                    courseType={props.courseType}
                                    handleChange={props.handleAdditionalDescriptionChange}
                                />
                            </>
                        )}
                    </ul>
                </div>
                {/* 新規講座作成時のみ今回の募集情報を入力 */}
                {!props.currentCourseId && (
                    <div className={styles.inputListWrapper}>
                        <div className={styles.inputListTitle}>今回の募集情報</div>
                        <ul className={styles.inputList}>
                            {props.courseType && (
                                <>
                                    {
                                        <Schedules
                                            confirmModalOpen
                                            isChecked={isChecked}
                                            deadlineNumber={props.deadlineNumber}
                                            maxMinutesPerLesson={props.maxMinutesPerLesson}
                                            courseType={props.courseType}
                                            maxDaysInAWeek={props.maxDaysInAWeek}
                                            availableTimes={props.availableTimes}
                                            existingLessons={props.existingLessons}
                                            lessons={props.lessons}
                                            applyingDeadline={props.applyingDeadline}
                                            setAvailableTimes={props.setAvailableTimes}
                                            handleMaxMinutesPerLessonChange={props.handleMaxMinutesPerLessonChange}
                                            handleMaxDaysInAWeekChange={props.handleMaxDaysInAWeekChange}
                                            otherCourseScheduleValidation={getExistLessonConflict(
                                                props.lessons,
                                                props.existingLessons,
                                            )}
                                            isNotDuplicateValidation={getExistLessonConflictWithinLessons(
                                                props.lessons,
                                            )}
                                            availableTimesLengthValidation={props.availableTimesLengthValidation}
                                            deadlineValidation={props.deadlineValidation}
                                            handleLessonsChange={props.handleLessonsChange}
                                            handleApplyingDeadlineChange={props.handleApplyingDeadlineChange}
                                        />
                                    }
                                    <PricePerHour
                                        open
                                        howManyDays={props.lessons.length}
                                        courseType={props.courseType}
                                        validation={true}
                                        isChecked={isChecked}
                                        pricePerHour={props.pricePerHour}
                                        lessons={props.lessons}
                                        priceRange={priceRange}
                                        handleChange={props.handlePriceChange}
                                    />
                                    {props.courseType === "regular" && (
                                        <ApplicationPeriod
                                            open
                                            validation={true}
                                            applicationPeriod={props.applicationPeriod}
                                            courseType={props.courseType}
                                            isChecked={isChecked}
                                            handleChange={props.handleApplicationPeriodChange}
                                        />
                                    )}
                                    {props.courseType === "short" && (
                                        <>
                                            <MinNumber
                                                open
                                                validation={true}
                                                minParticipants={props.minParticipants}
                                                maxParticipants={props.maxParticipants}
                                                isChecked={isChecked}
                                                handleChange={props.handleMinNumberChange}
                                            />
                                            <MaxNumber
                                                open
                                                validation={true}
                                                maxParticipants={props.maxParticipants}
                                                isChecked={isChecked}
                                                handleChange={props.handleMaxNumberChange}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </ul>
                    </div>
                )}
                <div className={styles.buttonsWrapper}>
                    <Button className={styles.backButton} onClick={props.handleConfirmModalClose}>
                        戻る
                    </Button>
                    <Button className={styles.confirmButton} onClick={props.handleConfirmButtonClick}>
                        {props.isProcessing ? <Processing /> : "確定"}
                    </Button>
                </div>
            </div>
        </Modal>
    );
});
