import { Button, TextareaAutosize } from "@material-ui/core";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { StudentToDoResponse } from "@/store/autogenApi";
import { useRejectPayingForCourseMutation } from "@/store/hooks/privateRequests";
import { ErrorMessage } from "@/components/ErrorMessage";
import { IsError } from "@/components/IsError";
import { IsSuccess } from "@/components/IsSuccess";
import { Processing } from "@/components/Processing";
import styles from "../index.module.scss";

interface Props {
    targetStudentToDo: StudentToDoResponse | null;
    isError: boolean;
    isSuccess: boolean;
    handleClose: () => void;
    handleBackButtonClick: () => void;
    setIsSuccess: Dispatch<SetStateAction<boolean>>;
    setIsError: Dispatch<SetStateAction<boolean>>;
}

export const DisapprovalModeContents: React.VFC<Props> = (props) => {
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [disapprovalMessage, setDisapprovalMessage] = useState<string>("");
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const rejectPayingForCourse = useRejectPayingForCourseMutation();

    const handleDisapprovalCommentChange = useCallback((e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        setDisapprovalMessage(e.target.value as string);
    }, []);

    const handleConfirmButtonClick = useCallback(async () => {
        setIsChecked(true);
        if (!props.targetStudentToDo?.class?.privateRequest) return;
        setIsProcessing(true);
        const result = await rejectPayingForCourse({
            rejectPayingForCourseRequestBody: {
                privateRequestId: props.targetStudentToDo.class.privateRequest?.privateRequestId,
                studentToDoId: props.targetStudentToDo.studentToDoId,
                message: disapprovalMessage,
            },
        });
        setIsProcessing(false);
        if (result.isSuccess) {
            props.setIsSuccess(true);
        } else {
            props.setIsError(true);
        }
    }, [rejectPayingForCourse, props.targetStudentToDo, disapprovalMessage]);

    return (
        <div className={styles.disapprovalWrapper}>
            {props.isError ? (
                <IsError
                    errorMessage="処理中にエラーが発生しました。"
                    buttonContent="閉じる"
                    handleButtonClick={props.handleClose}
                />
            ) : (
                <>
                    {props.isSuccess ? (
                        <IsSuccess
                            successMessage="受講をキャンセルしました。"
                            buttonContent="閉じる"
                            handleButtonClick={props.handleClose}
                        />
                    ) : (
                        <>
                            <div className={styles.disapprovalTitle}>受講キャンセル</div>
                            <div className={styles.pleaseInput}>
                                キャンセルの理由を記入して、「確定」を押してください。
                            </div>
                            <TextareaAutosize
                                className={styles.disapprovalComment}
                                value={disapprovalMessage}
                                onChange={handleDisapprovalCommentChange}
                                placeholder="10文字以上500文字以内"
                                minRows={5}
                                maxRows={10}
                            />
                            <ErrorMessage
                                content="入力してください。"
                                when={isChecked && disapprovalMessage.length === 0}
                            />
                            <ErrorMessage
                                content="10文字以上500文字以内で入力してください。"
                                when={
                                    isChecked &&
                                    disapprovalMessage.length !== 0 &&
                                    (10 < disapprovalMessage.length || disapprovalMessage.length > 5000)
                                }
                            />
                            <div className={styles.buttonsWrapper}>
                                <Button className={styles.backButton} onClick={props.handleBackButtonClick}>
                                    戻る
                                </Button>
                                <Button className={styles.disapprovalConfirmButton} onClick={handleConfirmButtonClick}>
                                    {isProcessing ? <Processing /> : "確定"}
                                </Button>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};
