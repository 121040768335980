import { OverWrapper } from "@/components/OverWrapper";
import styles from "@/pages/Common/LP/index.module.scss";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { AiOutlineCaretRight } from "react-icons/ai";

export const ThreeStepsSignUp: React.VFC = () => {
    return (
        <OverWrapper style={{ backgroundColor: "#F7F7F7" }}>
            <div className={styles.threeStepsSignUpWrapper}>
                <div className={styles.threeStepsSignUpTitle}>わずか2ステップで、簡単講座作成！</div>
                <div className={styles.steps}>
                    <div className={styles.step}>新規登録</div>
                    <AiOutlineCaretRight />
                    <div className={styles.step}>内容入力</div>
                </div>
                <div className={styles.signUpWrapper}>
                    <div className={styles.minutes}>＼30秒で完了！／</div>
                    <Button className={styles.signUpButton} component={Link} to="/SignUp?type=teacher">
                        新規ユーザー登録へ（無料）
                    </Button>
                </div>
            </div>
        </OverWrapper>
    );
};
