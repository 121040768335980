import { Button, Modal } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { memo, useCallback, useState } from "react";
import { StudentToDoResponse } from "@/store/autogenApi";

import styles from "./index.module.scss";
import { MonthlyFee } from "./MonthlyFee";
import { CourseTitle } from "./CourseTitle";
import { DisapprovalModeContents } from "./DisapproveModeContents";
import { TeacherInfo } from "./TeacherInfo";
import { WeeklySchedule } from "./WeeklySchedule";
import { useHistory } from "react-router";

interface Props {
    open: boolean;
    targetStudentToDo: StudentToDoResponse;
    handleModalClose: () => void;
}

export const PayForCourseModal: React.VFC<Props> = memo(function PayForCourseModal(props) {
    const [disapproveMode, setDisapproveMode] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const history = useHistory();

    const handleApproveButtonClick = useCallback(() => {
        if (!props.targetStudentToDo.class) return;
        history.push(
            `/Payment/${props.targetStudentToDo.class.classId}?studentToDoId=${props.targetStudentToDo.studentToDoId}`,
        );
        props.handleModalClose();
    }, [history, props.handleModalClose, props.targetStudentToDo.class, props.targetStudentToDo.studentToDoId]);

    const handleBackButtonClick = useCallback(() => {
        setDisapproveMode(false);
    }, []);

    const handleDisapproveButtonClick = useCallback(() => {
        setDisapproveMode(true);
    }, []);

    const handleModalClose = useCallback(() => {
        props.handleModalClose();
        setDisapproveMode(false);
        setIsSuccess(false);
        setIsError(false);
        if (isSuccess || isError) {
            window.location.reload();
        }
    }, [props.handleModalClose, isSuccess, isError]);

    return (
        <Modal open={props.open} onClose={handleModalClose}>
            <div className={styles.payForCourseModalContents}>
                <div className={styles.modalHeader}>
                    <button className={styles.laterButton} onClick={handleModalClose}>
                        <CloseIcon />
                    </button>
                    <div className={styles.modalTitle}>定期講座の受講</div>
                </div>
                {disapproveMode ? (
                    <DisapprovalModeContents
                        targetStudentToDo={props.targetStudentToDo}
                        isSuccess={isSuccess}
                        isError={isError}
                        handleBackButtonClick={handleBackButtonClick}
                        handleClose={handleModalClose}
                        setIsSuccess={setIsSuccess}
                        setIsError={setIsError}
                    />
                ) : (
                    <>
                        <div className={styles.middleWrapper}>
                            <div className={styles.targetCourseWrapper}>
                                <div className={styles.report}>
                                    あなたが先生に送ったスケジュール申請が承認されました。この講座を受講する場合は「支払い情報を入力」、そうでない場合は「受講キャンセルの理由を入力」をクリックしてください。
                                </div>
                                <ul className={styles.itemList}>
                                    <CourseTitle courseTitle={props.targetStudentToDo.class?.course?.title} />
                                    <TeacherInfo teacher={props.targetStudentToDo.class?.course?.teacher} />
                                    <WeeklySchedule privateRequest={props.targetStudentToDo.class?.privateRequest} />
                                    <MonthlyFee
                                        confirmedMonthlyFee={
                                            props.targetStudentToDo.class?.privateRequest?.confirmedMonthlyFee
                                        }
                                    />
                                </ul>
                            </div>
                        </div>
                        <div className={styles.modalFooter}>
                            <Button className={styles.disapproveButton} onClick={handleDisapproveButtonClick}>
                                受講キャンセルの理由を入力
                            </Button>
                            <Button className={styles.approveButton} onClick={handleApproveButtonClick}>
                                支払い情報を入力
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
});
