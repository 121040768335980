import { Button } from "@material-ui/core";

import { FileInfo } from "@/components/TrimmingModal";
import styles from "@/pages/Student/InfoInput/index.module.scss";
import { CreateStudentRequestBody, SubjectResponse } from "@/store/autogenApi";

import { studentConfig } from "@/pageComponents/Student/InfoInput/StudentInfoInput/Config";
import { InfoInputModal } from "@/pageComponents/Student/InfoInput/StudentInfoInput/InfoInputModal";
import { FavoriteSubjectsComponent } from "@/pageComponents/Student/InfoInput/StudentInfoInput/Items/FavoriteSubjects";
import { IconImage } from "@/pageComponents/Student/InfoInput/StudentInfoInput/Items/IconImage";
import { NickName } from "@/pageComponents/Student/InfoInput/StudentInfoInput/Items/NickName";

interface Props {
    checked: boolean;
    open: boolean;
    fileInfo: FileInfo | undefined;
    createStudentRequestBody: CreateStudentRequestBody | undefined;
    favoriteSubjects: SubjectResponse[];
    isProcessing: boolean;
    setFavoriteSubjects: React.Dispatch<React.SetStateAction<SubjectResponse[]>>;
    setFileInfo: React.Dispatch<React.SetStateAction<FileInfo>>;
    handleStudentParamsChange: (
        e: React.ChangeEvent<
            | HTMLInputElement
            | {
                  name?: string | undefined;
                  value: unknown;
              }
        >,
    ) => void;
    handleOpen: () => void;
    handleClose: () => void;
    confirm: () => Promise<void>;
}

export const FormContents: React.VFC<Props> = (props) => {
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 150,
                width: "fit-content",
            },
        },
        anchorOrigin: {
            vertical: "top" as number | "bottom" | "top" | "center",
            horizontal: "right" as number | "center" | "left" | "right",
        },
        transformOrigin: {
            vertical: "top" as number | "bottom" | "top" | "center",
            horizontal: "left" as number | "center" | "left" | "right",
        },
    };

    return (
        <div className={styles.infoInputWrapper}>
            <h3 className={styles.infoInputTitle}>生徒情報入力</h3>
            <div className={styles.description}>
                生徒用の情報を入力してください。なお、後から修正することも可能です。
            </div>
            <ul className={styles.inputList}>
                <NickName
                    value={props.createStudentRequestBody?.nickname ?? ""}
                    handleChange={props.handleStudentParamsChange}
                    checked={props.checked}
                    upperLimit={studentConfig.nickname}
                />
                <FavoriteSubjectsComponent
                    checked={props.checked}
                    favoriteSubjects={props.favoriteSubjects}
                    setFavoriteSubjects={props.setFavoriteSubjects}
                />
                <IconImage setFileInfo={props.setFileInfo} fileInfo={props.fileInfo} checked={props.checked} />
            </ul>
            <Button className={styles.checkButton} variant="contained" onClick={props.handleOpen}>
                確認
            </Button>
            <InfoInputModal
                createStudentRequestBody={props.createStudentRequestBody}
                fileInfo={props.fileInfo}
                checked={props.checked}
                open={props.open}
                favoriteSubjects={props.favoriteSubjects}
                isProcessing={props.isProcessing}
                setFavoriteSubjects={props.setFavoriteSubjects}
                handleClose={props.handleClose}
                confirm={props.confirm}
            />
        </div>
    );
};
