import { FAQDetailsBase } from "../../FAQDetailsBase";

export const ForgotPassword: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="パスワードを忘れてしまった"
            text="パスワードを忘れた場合には、下記から再設定をすることができます。"
        />
    );
};
