import { ProposalResponse } from "../autogenApi";
import { defaultApi } from "../defaultApi";
import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions } from "./utils/types";

export const useGetProposalQuery = (proposalId: string) => {
    const result = defaultApi.endpoints.getProposal.useQuery({ proposalId });
    return useQueryResult(result, (response: ProposalResponse) => response);
};

export const useGetMyProposalsQuery = (teacherId: string) => {
    const result = defaultApi.endpoints.getMyProposalsByTeacherId.useQuery({ teacherId });
    return useQueryResult(result, (response: ProposalResponse[]) => response);
};

export const useReserveProposedShortCourseMutation = (options?: UseMutationOptions) => {
    const [reserveProposedShortCourse] = defaultApi.endpoints.reserveProposedShortCourse.useMutation();
    return useMutationFunctionWrapper(reserveProposedShortCourse, options?.errorMessages);
};

export const useReserveProposedRegularCourseMutation = (options?: UseMutationOptions) => {
    const [reserveProposedRegularCourse] = defaultApi.endpoints.reserveProposedRegularCourse.useMutation();
    return useMutationFunctionWrapper(reserveProposedRegularCourse, options?.errorMessages);
};

export const useCreateProposalMutation = (options?: UseMutationOptions) => {
    const [addProposal] = defaultApi.endpoints.createProposal.useMutation();
    return useMutationFunctionWrapper(addProposal, options?.errorMessages);
};

export const useUpdateProposalMutation = (options?: UseMutationOptions) => {
    const [updateProposal] = defaultApi.endpoints.updateProposal.useMutation();
    return useMutationFunctionWrapper(updateProposal, options?.errorMessages);
};
