export const GRADE_LIST_FOR_TEACHER = [
    "高校1年",
    "高校2年",
    "高校3年",
    "大学1年",
    "大学2年",
    "大学3年",
    "大学4年",
    "大学5年",
    "大学6年",
    "大学院1年",
    "大学院2年",
    "大学院3年",
    "社会人",
    "その他",
];
