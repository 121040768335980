import styles from "@/pages/Common/PublicRequestDetails/index.module.scss";
import { memo, useCallback } from "react";

interface Props {
    courseType?: string;
}

export const CourseType: React.VFC<Props> = memo(function CourseType(props) {
    const getCourseTypeName = useCallback(() => {
        if (props.courseType === "short") {
            return "単発・短期講座リクエスト";
        } else if (props.courseType === "regular") {
            return "定期講座リクエスト";
        } else {
            return "";
        }
    }, [props.courseType]);

    return (
        <div className={styles.courseType} id="detail">
            {getCourseTypeName()}
        </div>
    );
});
