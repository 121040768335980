import { memo } from "react";
import { Mandatory } from "@/components/Tag/Mandatory";
import styles from "@/pages/Teacher/CreateCourse/index.module.scss";

interface Props {
    handleChange: (
        e: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>,
    ) => void;
    isChecked: boolean;
    value: string | undefined;
    open?: boolean;
    validation: boolean;
    existStudent?: boolean;
}

export const Title: React.VFC<Props> = memo(function Title(props) {
    return (
        <li className={styles.listItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>タイトル</div>
                    {!props.open && <Mandatory />}
                </div>
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>{props.value}</div>
                ) : (
                    <>
                        {props.existStudent ? (
                            <div className={styles.existStudentWrapper}>
                                <div className={styles.existStudent}>
                                    既に参加者がいるため、この項目は変更できません。
                                </div>
                                <div className={styles.existingValue}>{props.value}</div>
                            </div>
                        ) : (
                            <>
                                <input
                                    type="text"
                                    placeholder="タイトル(必須20字以内)"
                                    className={styles.input}
                                    onChange={props.handleChange}
                                    name="title"
                                    autoComplete="off"
                                    value={props.value}
                                />
                                {props.isChecked && !props.validation && (
                                    <div className={styles.helperText}>
                                        この項目は必須です。20字以内で入力してください。
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </li>
    );
});
