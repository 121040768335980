import { memo, useCallback } from "react";

import styles from "./index.module.scss";

import { PublicStudentResponse, PublicUserResponse } from "@/store/autogenApi";
import { Button } from "@material-ui/core";
import { genderSet } from "@/utils/GenderSet";
import { ListItem } from "./ListItem";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { ChatModal } from "../ChatModal";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    user: PublicUserResponse;
    student: PublicStudentResponse;
    modalHeight: number;
    isChatVisible?: boolean;
    chatModalOpen?: boolean;
    handleChatModalOpen?: () => void;
    handleChatModalClose?: () => void;
}

export const StudentInfo: React.VFC<Props> = memo(function StudentInfo(props) {
    const userMode = useSelector((state: RootState) => state.jwt.userMode as string);
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);
    const getBirthDate = useCallback((birthDateString: string) => {
        const birthDate = new Date(birthDateString);
        const year = birthDate.getFullYear();
        const month = birthDate.getMonth() + 1;
        const date = birthDate.getDate();
        return `${year}年${month}月${date}日`;
    }, []);
    return (
        <div className={styles.studentInfoWrapper}>
            <AvatarFromS3
                url={props.student.iconImageUrl}
                objectKey={props.student.iconImageObjectKey}
                className={styles.modalAvatar}
            />
            <div className={styles.profile}>
                <span className={styles.nickname}>{props.student?.nickname}</span>
                さんのプロフィール
            </div>
            <ul className={styles.infoList} style={{ maxHeight: `${props.modalHeight - 200}px` }}>
                <ListItem title="性別" value={props.user.isGenderPublic ? genderSet[props.user.gender] : "非公開"} />
                <ListItem
                    title="生年月日"
                    value={props.user.isBirthDatePublic ? `${getBirthDate(props.user.birthDate)}` : "非公開"}
                />
                <ListItem title="学年" value={props.user.isGradePublic ? `${props.user.grade}` : "非公開"} />
                <ListItem
                    title="都道府県"
                    value={props.user.isPrefecturePublic ? `${props.user.prefecture}` : "非公開"}
                    isLastItem
                />
            </ul>
            {props.isChatVisible && userMode === "teacher" && props.handleChatModalClose && (
                <>
                    <Button className={styles.individualChatButton} onClick={props.handleChatModalOpen}>
                        個人チャット
                    </Button>
                    {props.chatModalOpen && (
                        <ChatModal
                            studentId={props.student.studentId}
                            teacherId={teacherId}
                            handleClose={props.handleChatModalClose as () => void}
                        />
                    )}
                </>
            )}
        </div>
    );
});
