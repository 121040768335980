import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
    ClassResponse,
    CourseType,
    CreateAvailableTimeRequestParams,
    LessonResponse,
    UpdateAvailableTimeRequestBody,
} from "@/store/autogenApi";
import styles from "./index.module.scss";
import { Price } from "./ListItems/Price";
import { NavyButton } from "@/components/Buttons/NavyButton";
import { MinParticipants } from "./ListItems/MinParticipants";
import { MaxParticipants } from "./ListItems/MaxParticipants";
import { ApplicationPeriod } from "./ListItems/ApplicationPeriod";
import { getExistLessonConflict, getExistLessonConflictWithinLessons } from "@/utils/LessonUtils";
import { ShortCourseSchedules } from "./ListItems/ShortCourseSchedules";
import { RegularCourseSchedule } from "./ListItems/RegularCourseSchedule";
import { WHAT_DAY_LIST } from "@/utils/WhatDayList";
import { MINUTES_LIST_PER_LESSON } from "@/constants";
import { StyledModal } from "@/components/StyledModal";
import { useAddClassMutation } from "@/store/hooks/classes";
import { toast } from "react-toastify";
import { ToastContents } from "@/components/Toast";
import { dateToString } from "@/utils/DateUtils";
import { useParams } from "react-router";
import { WhiteButton } from "@/components/Buttons/WhiteButton";
import { Processing } from "@/components/Processing";

interface Props {
    isUpsertModalOpened: boolean;
    courseType: CourseType;
    targetClass?: ClassResponse;
    existingLessons: LessonResponse[];
    handleUpsertModalClose: () => void;
}

export const PRICE_RANGE = [1000, 100000];

const INITIAL_AVAILABLE_TIMES: CreateAvailableTimeRequestParams[] = [...Array(7)].map((_, idx) => ({
    isActive: false,
    dayOfWeekIndex: idx,
    startHour: null,
    startMinute: null,
    endHour: null,
    endMinute: null,
}));

const now = new Date();
const threeDaysFromNow = new Date();
threeDaysFromNow.setDate(threeDaysFromNow.getDate() + 3);
threeDaysFromNow.setHours(now.getHours(), 0, 0, 0);

export const UpsertModal: React.VFC<Props> = memo(function UpsertModal(props) {
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [isConfirmMode, setIsConfirmMode] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [price, setPrice] = useState<number>(0);
    const [lessons, setLessons] = useState<LessonResponse[]>([]);
    const [existStudent, setExistStudent] = useState<boolean>(false);
    const [maxMinutesPerLesson, setMaxMinutesPerLesson] = useState<number>(0);
    const [maxDaysInAWeek, setMaxDaysInAWeek] = useState<number>(0);
    const [availableTimes, setAvailableTimes] =
        useState<(CreateAvailableTimeRequestParams | UpdateAvailableTimeRequestBody)[]>(INITIAL_AVAILABLE_TIMES);
    const [applyingDeadline, setApplyingDeadline] = useState<Date>(threeDaysFromNow);
    const [minParticipants, setMinParticipants] = useState<number>(0);
    const [maxParticipants, setMaxParticipants] = useState<number>(0);
    const [applicationPeriod, setApplicationPeriod] = useState<number>(0);

    const deadlineValidation = useMemo<boolean>(() => {
        const targetDeadline = new Date(applyingDeadline);
        const targetLesson = lessons[0];
        if (!targetLesson) return false;
        const startTimeOfFirstLesson = new Date(targetLesson.startTime);
        return targetDeadline < startTimeOfFirstLesson;
    }, [applyingDeadline, lessons]);

    const lessonsLengthValidation = useMemo<boolean>(() => lessons.length > 0, [lessons]);

    const isNotDuplicateValidation = useMemo<boolean>(() => !getExistLessonConflictWithinLessons(lessons), [lessons]);

    const priceValidation = useMemo<boolean>(() => PRICE_RANGE[0] <= price && price <= PRICE_RANGE[1], [price]);

    const otherCourseScheduleValidation = useMemo(
        () => !getExistLessonConflict(props.existingLessons, lessons),
        [lessons, props.existingLessons],
    );

    const availableTimesValidation = useMemo<boolean>(
        () =>
            availableTimes.some((availableTime) => {
                if (
                    availableTime.startHour != undefined &&
                    availableTime.startMinute != undefined &&
                    availableTime.endHour != undefined &&
                    availableTime.endMinute != undefined
                ) {
                    return true;
                } else {
                    return false;
                }
            }),
        [availableTimes],
    );

    const availableTimesConflictIndexes = useMemo<number[]>(() => {
        const results = availableTimes.map((availableTime, idx) => {
            if (
                availableTime.startHour == undefined ||
                availableTime.startMinute == undefined ||
                availableTime.endHour == undefined ||
                availableTime.endMinute == undefined
            )
                return false;
            const { startHour, startMinute, endHour, endMinute } = availableTime;
            const targetExistingLessons = props.existingLessons.filter(
                (lesson) => new Date(lesson.startTime).getDay() === idx,
            );
            const result = targetExistingLessons.some((lesson) => {
                const lessonStartTime = new Date(lesson.startTime);
                const lessonEndTime = new Date(lesson.endTime);
                if (
                    // lessonStartTime.getHour(), lessonStartTime.getMinute()を使って比較
                    // 比較の大小は左が早い時間であることを前提とする(<の向き)
                    // 開始時間が既存の授業時間内にあるか
                    ((lessonStartTime.getHours() < startHour ||
                        (lessonStartTime.getHours() === startHour && lessonStartTime.getMinutes() < startMinute)) &&
                        (startHour < lessonEndTime.getHours() ||
                            (startHour === lessonEndTime.getHours() && startMinute < lessonEndTime.getMinutes()))) ||
                    // 終了時間が既存の授業時間内にあるか
                    ((lessonStartTime.getHours() < endHour ||
                        (lessonStartTime.getHours() === endHour && lessonStartTime.getMinutes() < endMinute)) &&
                        (endHour < lessonEndTime.getHours() ||
                            (endHour === lessonEndTime.getHours() && endMinute < lessonEndTime.getMinutes()))) ||
                    // 既存の授業時間がavailableTimeの間にあるか
                    ((startHour < lessonStartTime.getHours() ||
                        (startHour === lessonStartTime.getHours() && startMinute < lessonStartTime.getMinutes())) &&
                        (lessonEndTime.getHours() < endHour ||
                            (lessonEndTime.getHours() === endHour && lessonEndTime.getMinutes() < endMinute)))
                ) {
                    return true;
                } else {
                    return false;
                }
            });
            return result;
        });
        const conflictIndexes = results
            .map((result, idx) => {
                if (result) return idx;
            })
            .flatMap((result) => (result === undefined ? [] : [result]));
        return conflictIndexes;
    }, [availableTimes]);

    const minParticipantsValidation = useMemo(() => 0 < minParticipants && minParticipants <= 10, [minParticipants]);

    const maxParticipantsValidation = useMemo(() => 0 < maxParticipants && maxParticipants <= 10, [maxParticipants]);

    const participantsValidation = useMemo(
        () => minParticipants <= maxParticipants,
        [minParticipants, maxParticipants],
    );

    const applicationPeriodValidation = useMemo(
        () => 0 < applicationPeriod && applicationPeriod <= 30,
        [applicationPeriod],
    );

    const maxDaysInAWeekValidation = useMemo(() => 1 <= maxDaysInAWeek && maxDaysInAWeek <= 7, [maxDaysInAWeek]);

    const maxMinutesPerLessonValidation = useMemo(
        () =>
            MINUTES_LIST_PER_LESSON[0] <= maxMinutesPerLesson &&
            maxMinutesPerLesson <= MINUTES_LIST_PER_LESSON[MINUTES_LIST_PER_LESSON.length - 1],
        [maxMinutesPerLesson],
    );

    const { courseId } = useParams<{ courseId: string }>();

    const addClass = useAddClassMutation();

    const handlePriceChange = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        if (isNaN(Number(e.target.value))) return;
        setPrice(Number(e.target.value));
    }, []);

    const handleMinParticipantsChange = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        if (isNaN(Number(e.target.value))) return;
        setMinParticipants(Number(e.target.value));
    }, []);

    const handleMaxParticipantsChange = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        if (isNaN(Number(e.target.value))) return;
        setMaxParticipants(Number(e.target.value));
    }, []);

    const handleApplicationPeriodChange = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        const newApplicationPeriod = e.target.value as number;
        setApplicationPeriod(newApplicationPeriod);
    }, []);

    const handleAvailableTimesChange = useCallback(
        (newAvailableTimes: (CreateAvailableTimeRequestParams | UpdateAvailableTimeRequestBody)[]) => {
            setAvailableTimes(newAvailableTimes);
        },
        [],
    );

    const handleLessonsChange = useCallback((newLessons: LessonResponse[]) => {
        setLessons(newLessons);
    }, []);

    const handleApplyingDeadlineChange = useCallback((newApplyingDeadline: Date) => {
        setApplyingDeadline(newApplyingDeadline);
    }, []);

    const handleMaxMinutesPerLessonChange = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        const newMaxMinutesPerLesson = e.target.value as number;
        setMaxMinutesPerLesson(newMaxMinutesPerLesson);
    }, []);

    const handleMaxDaysInAWeekChange = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        const newMaxDaysInAWeek = e.target.value as number;
        setMaxDaysInAWeek(newMaxDaysInAWeek);
    }, []);

    const handleUpsertModalClose = useCallback(() => {
        setIsChecked(false);
        setIsConfirmMode(false);
        props.handleUpsertModalClose();
    }, [props.handleUpsertModalClose]);

    const handleCheckButtonClick = useCallback(() => {
        setIsChecked(true);
        const commonValidations = [
            priceValidation,
            minParticipantsValidation,
            maxParticipantsValidation,
            participantsValidation,
        ];
        const regularCourseValidations = [
            availableTimesValidation,
            maxDaysInAWeekValidation,
            maxMinutesPerLessonValidation,
            applicationPeriodValidation,
        ];
        const shortCourseValidation = [
            lessonsLengthValidation,
            deadlineValidation,
            isNotDuplicateValidation,
            otherCourseScheduleValidation,
        ];
        if (props.courseType === "regular") {
            const validations = [...commonValidations, ...regularCourseValidations];
            const result = validations.every((validation) => validation);
            if (!result) return;
            setIsConfirmMode(true);
        }
        if (props.courseType === "short") {
            const validations = [...commonValidations, ...shortCourseValidation];
            const result = validations.every((validation) => validation);
            if (!result) return;
            setIsConfirmMode(true);
        }
    }, [
        lessonsLengthValidation,
        isNotDuplicateValidation,
        otherCourseScheduleValidation,
        deadlineValidation,
        availableTimesValidation,
        maxDaysInAWeekValidation,
        maxMinutesPerLessonValidation,
        applicationPeriodValidation,
        priceValidation,
        minParticipantsValidation,
        maxParticipantsValidation,
        participantsValidation,
    ]);

    const handleConfirmButtonClick = useCallback(() => {
        (async () => {
            setIsProcessing(true);
            const { isSuccess } = await addClass({
                addClassRequestBody: {
                    courseId,
                    availableTimes,
                    applyingDeadline: dateToString(applyingDeadline),
                    lessons,
                    maxDaysInAWeek,
                    maxMinutesPerLesson,
                    maxParticipants: props.courseType === "regular" ? 1 : maxParticipants,
                    minParticipants: props.courseType === "regular" ? 1 : minParticipants,
                    price: price,
                },
            });
            setIsProcessing(false);
            if (isSuccess) {
                toast(<ToastContents title={`クラス追加完了`} isCompleted />);
            } else {
                toast(<ToastContents title={`クラス追加失敗`} isFailed />);
            }
            handleUpsertModalClose();
        })();
    }, [
        availableTimes,
        applyingDeadline,
        lessons,
        maxDaysInAWeek,
        maxMinutesPerLesson,
        maxParticipants,
        minParticipants,
        price,
        handleUpsertModalClose,
    ]);

    const handleBackButtonClick = useCallback(() => {
        setIsConfirmMode(false);
    }, []);

    return (
        <StyledModal
            open={props.isUpsertModalOpened}
            autoResize
            style={{ paddingTop: 0, paddingBottom: 0 }}
            onClose={handleUpsertModalClose}
        >
            <div className={styles.upsertModeContentsWrapper}>
                <div className={styles.upsertModeContentsTitleWrapper}>
                    <div className={styles.upsertModeContentsTitle}>クラス追加</div>
                </div>
                <div className={styles.upsertModeContents}>
                    <ul className={styles.itemList}>
                        {props.courseType === "regular" && (
                            <RegularCourseSchedule
                                isChecked={isChecked}
                                isConfirmMode={isConfirmMode}
                                maxMinutesPerLesson={maxMinutesPerLesson}
                                maxDaysInAWeek={maxDaysInAWeek}
                                availableTimes={availableTimes}
                                existingLessons={props.existingLessons}
                                availableTimesLengthValidation={availableTimesValidation}
                                handleAvailableTimesChange={handleAvailableTimesChange}
                                handleMaxMinutesPerLessonChange={handleMaxMinutesPerLessonChange}
                                handleMaxDaysInAWeekChange={handleMaxDaysInAWeekChange}
                            />
                        )}
                        {props.courseType === "short" && (
                            <ShortCourseSchedules
                                isChecked={isChecked}
                                isConfirmMode={isConfirmMode}
                                existingLessons={props.existingLessons}
                                lessons={lessons}
                                applyingDeadline={applyingDeadline}
                                lengthValidation={lessonsLengthValidation}
                                otherCourseScheduleValidation={otherCourseScheduleValidation}
                                isNotDuplicateValidation={isNotDuplicateValidation}
                                deadlineValidation={deadlineValidation}
                                handleLessonsChange={handleLessonsChange}
                                handleApplyingDeadlineChange={handleApplyingDeadlineChange}
                            />
                        )}
                        <Price
                            isChecked={isChecked}
                            isConfirmMode={isConfirmMode}
                            howManyDays={lessons.length}
                            courseType={props.courseType}
                            price={price}
                            validation={priceValidation}
                            existStudent={existStudent}
                            handleChange={handlePriceChange}
                        />
                        {props.courseType === "regular" && (
                            <ApplicationPeriod
                                isChecked={isChecked}
                                isConfirmMode={isConfirmMode}
                                applicationPeriod={applicationPeriod}
                                courseType={props.courseType}
                                validation={applicationPeriodValidation}
                                handleChange={handleApplicationPeriodChange}
                            />
                        )}
                        {props.courseType !== "regular" && (
                            <>
                                <MinParticipants
                                    isChecked={isChecked}
                                    isConfirmMode={isConfirmMode}
                                    minParticipantsValidation={minParticipantsValidation}
                                    participantsValidation={participantsValidation}
                                    minParticipants={minParticipants}
                                    maxParticipants={maxParticipants}
                                    existStudent={existStudent}
                                    handleChange={handleMinParticipantsChange}
                                />
                                <MaxParticipants
                                    isChecked={isChecked}
                                    isConfirmMode={isConfirmMode}
                                    validation={maxParticipantsValidation}
                                    maxParticipants={maxParticipants}
                                    existStudent={existStudent}
                                    handleChange={handleMaxParticipantsChange}
                                />
                            </>
                        )}
                    </ul>
                    {availableTimesConflictIndexes.length > 0 && (
                        <div className={styles.conflictNotice}>
                            {availableTimesConflictIndexes.map((idx) => WHAT_DAY_LIST[idx]).join("・")}
                            の対応可能時間帯が他の講座の授業時間と重複しています。カレンダーを確認して、それでもよろしければ「確認」を押してください。
                        </div>
                    )}
                    {isConfirmMode ? (
                        <div className={styles.confirmButtonWrapper}>
                            <WhiteButton isCentered handleClick={handleBackButtonClick}>
                                戻る
                            </WhiteButton>
                            <NavyButton isCentered handleClick={handleConfirmButtonClick}>
                                {isProcessing ? <Processing /> : "確定"}
                            </NavyButton>
                        </div>
                    ) : (
                        <div className={styles.checkButtonWrapper}>
                            <WhiteButton isCentered handleClick={handleUpsertModalClose}>
                                閉じる
                            </WhiteButton>
                            <NavyButton isCentered handleClick={handleCheckButtonClick}>
                                確認
                            </NavyButton>
                        </div>
                    )}
                </div>
            </div>
        </StyledModal>
    );
});
