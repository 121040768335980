import { Button, Modal } from "@material-ui/core";
import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { ErrorMessage } from "@/components/ErrorMessage";
import styles from "@/pages/Student/Payment/index.module.scss";

interface Props {
    pointsModalOpen: boolean;
    pointsBalance: number;
    handleClose: () => void;
    setPoints: Dispatch<SetStateAction<number>>;
}

export const PointsModal: React.VFC<Props> = (props) => {
    const [targetPoints, setTargetPoints] = useState<number>(0);
    const [checked, setChecked] = useState<boolean>(false);
    const [isOverError, setIsOverError] = useState<boolean>(false);
    const [isEmptyError, setIsEmptyError] = useState<boolean>(true);

    const handleConfirmButtonClick = useCallback(() => {
        setChecked(true);
        if (targetPoints > props.pointsBalance) {
            setIsOverError(true);
            return;
        }
        if (targetPoints === 0) {
            setIsEmptyError(true);
            return;
        }
        props.setPoints(targetPoints);
        props.handleClose();
    }, [props, targetPoints]);

    const handleChange = useCallback(
        (e: React.ChangeEvent<{ value: string }>) => {
            const value = Number(e.target.value);
            setTargetPoints(value);
            if (value > props.pointsBalance) {
                setIsOverError(true);
                return;
            }
            if (value === 0) {
                setIsEmptyError(true);
                return;
            }
            setIsOverError(false);
            setIsEmptyError(false);
        },
        [props.pointsBalance],
    );
    return (
        <Modal open={props.pointsModalOpen} onClose={props.handleClose}>
            <div className={styles.pointsModalContents}>
                <ErrorMessage when={checked && isOverError} content="ポイント残高を超えるポイントは使用できません。" />
                <ErrorMessage when={checked && isEmptyError} content="ポイントを入力してください。" />
                <div className={styles.table}>
                    <div className={styles.pointsBalanceWrapper}>
                        <div className={styles.pointsBalanceTitle}>ポイント残高</div>
                        <div className={styles.pointsBalance}>{props.pointsBalance}pt</div>
                    </div>
                    <div className={styles.pointsWrapper}>
                        <div className={styles.pointsTitle}>使用ポイント</div>
                        <div className={styles.pointsInputWrapper}>
                            <input
                                type="number"
                                className={styles.pointsInput}
                                placeholder="使用ポイントを入力"
                                onChange={handleChange}
                                value={targetPoints != 0 ? targetPoints : undefined}
                            />
                            <div className={styles.unit}>pt</div>
                        </div>
                    </div>
                </div>
                <div className={styles.buttonsWrapper}>
                    <Button className={styles.backButton} onClick={props.handleClose}>
                        戻る
                    </Button>
                    <Button className={styles.confirmButton} onClick={handleConfirmButtonClick}>
                        確定
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
