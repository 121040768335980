import { combineReducers, configureStore, Store } from "@reduxjs/toolkit";
import { defaultApi } from "@/store/defaultApi";
import { reducer } from "./jwt";

const rootReducer = combineReducers({
    jwt: reducer,
    [defaultApi.reducerPath]: defaultApi.reducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(defaultApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
