import { FAQConfig } from "@/FAQConfig";

export const CANCEL_HOLDING_COURSE_CONFIGS: FAQConfig[] = [
    {
        title: "My講座一覧ページへのアクセス",
        text: "メニューバーの「My講座一覧」をクリックすると、My講座一覧ページに移動します。",
    },
    {
        title: "講座管理ページへのアクセス",
        text: "My講座一覧ページで対象の講座の「講座管理」をクリックすると、講座管理ページに移動します。",
    },
    {
        title: "グループチャットで事情説明",
        text: "参加者がいる場合、グループチャットで事情説明を行い、参加者にキャンセルを伝えます。誠意ある対応を心がけましょう。参加者がいる状態で日程を変更したい場合は参加者に同意を得た上で新たな日程を運営に連絡してください。通常、24時間以内に日程が変更されます。",
    },
    {
        title: "講座のキャンセル",
        text: "日程変更ではなく、講座をキャンセルしたい場合、講座管理ページの「講座キャンセル」をクリックすると、講座のキャンセルが完了します。",
    },
];
