import { ClassResponse } from "@/store/autogenApi";
import { CreateLessonRequestParams } from "@/store/autogenApi";

export const getStatus = (classResponse: ClassResponse | undefined) => {
    if (!classResponse) return "";
    switch (classResponse.status) {
        case "recruiting":
            return "募集中";
        case "recruitmentClosed":
            return "募集期間終了";
        case "existApplication":
            return "スケジュール申請あり";
        case "ongoing":
            return "進行中";
        case "completed":
            return "全授業完了";
        case "deleted":
            return "削除済み";
        default:
            return "";
    }
};

export const getTabInfoList = (classes: ClassResponse[]) => {
    let shortCount = 0;
    let regularCount = 0;
    classes.forEach((classResponse) => {
        if (!classResponse.course) return;
        if (classResponse.course.courseType === "short") {
            shortCount++;
        } else {
            regularCount++;
        }
    });
    return [
        { value: "regular", label: `定期(${regularCount})` },
        { value: "short", label: `単発・短期(${shortCount})` },
    ];
};

export const getPricePerPaymentForShortCourse = (pricePerHour: number, lessons: CreateLessonRequestParams[]) => {
    const howManyMinutes = getHowManyMinutes(lessons);
    return pricePerHour * (howManyMinutes / 60);
};

export const getHowMuchTime = (lessons: CreateLessonRequestParams[]) => {
    const howManyMinutes = getHowManyMinutes(lessons);
    const hour = Math.floor(howManyMinutes / 60);
    const minutes = ("00" + (howManyMinutes % 60)).slice(-2);
    return `${hour}時間${minutes}分`;
};

const getHowManyMinutes = (lessons: CreateLessonRequestParams[]) => {
    return (
        lessons.reduce(
            (acc, lesson) => acc + (new Date(lesson.endTime).getTime() - new Date(lesson.startTime).getTime()),
            0,
        ) /
        1000 /
        60
    );
};
