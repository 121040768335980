import { Button, Modal } from "@material-ui/core";
import styles from "./index.module.scss";
import { memo } from "react";

interface Props {
    duplicateModalOpen: boolean;
    handleDuplicateModalClose: () => void;
}

export const DuplicateModal: React.VFC<Props> = memo(function DuplicateModal(props) {
    return (
        <Modal
            open={props.duplicateModalOpen}
            onClose={props.handleDuplicateModalClose}
            className={styles.duplicateModal}
        >
            <div className={styles.duplicateModalContents}>
                <div className={styles.existOtherCourse}>
                    予約しようとしている時間帯には既に別の講座の予定が入っているため、この日程を予約できません。
                </div>
                <div className={styles.buttonsWrapper}>
                    <Button className={styles.backButton} onClick={props.handleDuplicateModalClose}>
                        戻る
                    </Button>
                </div>
            </div>
        </Modal>
    );
});
