import styles from "@/pages/Student/InfoInput/index.module.scss";
import { Optional } from "../../Tag/Optional";

import { SubjectsComponent } from "@/components/SubjectsComponent";
import { useState } from "react";
import { SubjectResponse } from "@/store/autogenApi";
import {
    ELEMENTARY_SCHOOL_SUBJECT_CONFIG,
    HIGH_SCHOOL_SUBJECT_CONFIG,
    JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG,
    UNIVERSITY_SUBJECT_CONFIG,
} from "@/SubjectConfig";

interface Props {
    open?: boolean;
    checked: boolean;
    favoriteSubjects: SubjectResponse[];
    setFavoriteSubjects: React.Dispatch<React.SetStateAction<SubjectResponse[]>>;
}

export interface Open {
    level: string;
    subject: string;
}

export const FavoriteSubjectsComponent: React.VFC<Props> = (props) => {
    const [open, setOpen] = useState<Open>({
        level: "",
        subject: "",
    });

    const handlePopoverOpen = (level: string, subject: string) => {
        setOpen({ level: level, subject: subject });
    };

    const handlePopoverClose = () => {
        setOpen({ level: "", subject: "" });
    };

    const getConfig = (level: string) => {
        if (level === "university") {
            return UNIVERSITY_SUBJECT_CONFIG;
        } else if (level === "highSchool") {
            return HIGH_SCHOOL_SUBJECT_CONFIG;
        } else if (level === "juniorHighSchool") {
            return JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG;
        } else if (level === "elementarySchool") {
            return ELEMENTARY_SCHOOL_SUBJECT_CONFIG;
        } else {
            return "";
        }
    };

    const getSchool = (school: string) => {
        if (school === "university") {
            return "大学・社会人等";
        } else if (school === "highSchool") {
            return "高校";
        } else if (school === "juniorHighSchool") {
            return "中学校";
        } else {
            return "小学校";
        }
    };

    return (
        <div className={styles.inputItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitleWrapper}>
                    <div className={styles.itemTitle}>興味のある分野（複数選択可）</div>
                    {!props.open && <Optional />}
                </div>
            </div>
            <div className={`${styles.rightWrapper} ${styles.subjectRightWrapper}`}>
                {props.open ? (
                    <div className={styles.openTrue}>
                        <ul className={styles.openSubjectList}>
                            {props.favoriteSubjects.length > 0 ? (
                                props.favoriteSubjects.map((subject, idx) => {
                                    const config = subject.level ? getConfig(subject.level) : "";
                                    if (config) {
                                        const subjectInfo = subject.category ? config[subject.category] : "";
                                        const subCategoryKey = subject.subCategory;
                                        if (!subjectInfo) return;
                                        if (!subjectInfo.sub || !subCategoryKey) {
                                            return (
                                                <li key={idx} className={styles.openListItem}>
                                                    {subject.level && getSchool(subject.level)} - {subjectInfo.label}
                                                </li>
                                            );
                                        }
                                        return (
                                            <li key={idx} className={styles.openListItem}>
                                                {subject.level && getSchool(subject.level)} - {subjectInfo.label} -{" "}
                                                {subjectInfo.sub[subCategoryKey].label}
                                            </li>
                                        );
                                    }
                                })
                            ) : (
                                <div>選択なし</div>
                            )}
                        </ul>
                    </div>
                ) : (
                    <SubjectsComponent
                        checked={props.checked}
                        subjects={props.favoriteSubjects}
                        setSubjects={props.setFavoriteSubjects}
                    />
                )}
            </div>
        </div>
    );
};
