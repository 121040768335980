import { Button, useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";

// import { SNSWrapper } from "../SNSWrapper";

import { Menu } from "./Menu";
import styles from "./index.module.scss";

interface Props {}

export const Footer: React.VFC<Props> = (props) => {
    const largerThanTarget = useMediaQuery("(min-width:600px)");
    return (
        <div className={styles.background} id="footer">
            <div className={styles.footerContainer}>
                {largerThanTarget ? (
                    <>
                        <div className={styles.menuWrapper}>
                            <Menu />
                        </div>
                        <div className={styles.footerBottomWrapper}>
                            <div className={styles.opinionWrapper}>
                                <span className={styles.please}>ご意見・不具合のご報告などはこちら</span>
                                <Button className={styles.opinionButton} component={Link} to="/Inquiry">
                                    お問い合わせ
                                </Button>
                            </div>
                            <div className={styles.buttonsWrapper}>
                                {/* <SNSWrapper /> */}
                                <div className={styles.copyRight}>© 2024 Trail All Rights Reserved</div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.menuWrapper}>
                            <Menu />
                        </div>
                        <div className={styles.rightWrapper}>
                            <div className={styles.opinionWrapper}>
                                <span className={styles.please}>ご意見・不具合のご報告などはこちら</span>
                                <Button className={styles.opinionButton} component={Link} to="/Inquiry">
                                    お問い合わせ
                                </Button>
                            </div>
                            <div className={styles.buttonsWrapper}>
                                {/* <SNSWrapper /> */}
                                <div className={styles.copyRight}>© 2024 Trail All Rights Reserved</div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
