import { FAQDetailsBase } from "../../FAQDetailsBase";

export const Withdrawal: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="Trailを退会したい"
            text="Trailを退会する場合は、下記よりお願いいたします。（ログイン中のみアクセス可能）"
            urlInfos={[
                {
                    url: "/Withdrawal",
                    word: "Trailを退会する",
                },
            ]}
        />
    );
};
