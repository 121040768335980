import { PrivateTeacherResponse } from "@/store/autogenApi";

type Config = Record<keyof PrivateTeacherResponse, number>;

export const config: Partial<Config> = {
    nickname: 20,
    catchCopy: 30,
    contentDescription: 500,
    backgroundDescription: 500,
};
