import { AiFillFile, AiFillFileExcel, AiFillFilePdf, AiFillFilePpt, AiFillFileWord } from "react-icons/ai";

import styles from "./index.module.scss";
import { memo } from "react";

interface Props {
    fileUrl: string;
}

export const FileIcon: React.VFC<Props> = memo(function FileIcon(props) {
    const pdfRegex = /.pdf$/;
    if (pdfRegex.test(props.fileUrl)) {
        return <AiFillFilePdf className={styles.pdfIcon} />;
    }

    const oldWordRegex = /.doc$/;
    const newWordRegex = /.docx$/;
    if (oldWordRegex.test(props.fileUrl) || newWordRegex.test(props.fileUrl)) {
        return <AiFillFileWord className={styles.wordIcon} />;
    }

    const oldExcelRegex = /.xls$/;
    const newExcelRegex = /.xlsx$/;
    if (oldExcelRegex.test(props.fileUrl) || newExcelRegex.test(props.fileUrl)) {
        return <AiFillFileExcel className={styles.excelIcon} />;
    }

    const oldPowerPointRegex = /.ppt$/;
    const newPowerPointRegex = /.pptx$/;
    if (oldPowerPointRegex.test(props.fileUrl) || newPowerPointRegex.test(props.fileUrl)) {
        return <AiFillFilePpt className={styles.powerPointIcon} />;
    }

    return <AiFillFile className={styles.fileIcon} />;
});
