import {
    EmailNotificationsSettingResponse,
    PointsRecordResponse,
    PrivateUserResponse,
    PublicUserResponse,
    PushNotificationsSettingResponse,
} from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions } from "./utils/types";

export const useGetExistUserQuery = (id: string) => {
    const result = defaultApi.endpoints.getExistUser.useQuery({ userId: id });
    return useQueryResult(result, (response: boolean) => response);
};

export const useGetPublicUserQuery = (id: string) => {
    const result = defaultApi.endpoints.getPublicUser.useQuery({ userId: id });
    return useQueryResult(result, (response: PublicUserResponse) => response);
};

export const useGetPrivateUserQuery = (id: string) => {
    const result = defaultApi.endpoints.getPrivateUser.useQuery({ userId: id });
    return useQueryResult(result, (response: PrivateUserResponse | null) => response);
};

export const useGetPointsRecordHistoryQuery = (userId: string) => {
    const result = defaultApi.endpoints.getPointsHistory.useQuery({ userId: userId });
    return useQueryResult(result, (response: PointsRecordResponse[]) => response);
};

export const useCreateUserMutation = (options?: UseMutationOptions) => {
    const [createUser] = defaultApi.endpoints.createUser.useMutation();
    return useMutationFunctionWrapper(createUser, options?.errorMessages);
};

export const useUpdateUserMutation = (options?: UseMutationOptions) => {
    const [updateUser] = defaultApi.endpoints.updateUser.useMutation();
    return useMutationFunctionWrapper(updateUser, options?.errorMessages);
};

export const useDeleteUserMutation = (options?: UseMutationOptions) => {
    const [deleteUser] = defaultApi.endpoints.deleteUser.useMutation();
    return useMutationFunctionWrapper(deleteUser, options?.errorMessages);
};

export const useGetPushNotificationsSetting = (userId: string) => {
    const result = defaultApi.endpoints.getPushNotificationsSetting.useQuery({ userId: userId });
    return useQueryResult(result, (response: PushNotificationsSettingResponse) => response);
};

export const useGetEmailNotificationsSetting = (userId: string) => {
    const result = defaultApi.endpoints.getEmailNotificationsSetting.useQuery({ userId: userId });
    return useQueryResult(result, (response: EmailNotificationsSettingResponse) => response);
};
