import { memo } from "react";
import styles from "@/pages/Teacher/IdentificationForStripe/index.module.scss";

export const Description: React.VFC = memo(function Description() {
    return (
        <div className={styles.descriptionWrapper}>
            <div className={styles.identificationTitle}>Stripe用情報入力</div>
            <div className={styles.descriptionWrapper}>
                <div className={styles.description}>
                    口座振込のためのStripeが定める情報が必要です。以下の項目をご入力ください。ご入力いただいた情報は、Stripeのみに提供されます。
                </div>
            </div>
        </div>
    );
});
