import { Modal } from "@material-ui/core";
import { AnyARecord } from "dns";
import React from "react";
import { useSelector } from "react-redux";
import { PaymentSettingContents } from "@/components/Payment/PaymentSettingContents";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { RootState } from "@/ducks";

import styles from "@/pages/Student/Payment/index.module.scss";
import { useGetPaymentMethodsQuery } from "@/store/hooks/stripe";

interface Props {
    paymentMethodModalOpen: boolean;
    targetPaymentMethod: any | undefined;
    defaultPaymentMethod: any;
    setTargetPaymentMethod: React.Dispatch<React.SetStateAction<any | undefined>>;
    handlePaymentMethodModalClose: () => void;
}

export const PaymentMethodModal: React.VFC<Props> = (props) => {
    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);
    const paymentMethodsState = useGetPaymentMethodsQuery(studentId);
    return (
        <Modal open={props.paymentMethodModalOpen} onClose={props.handlePaymentMethodModalClose}>
            <div className={styles.paymentMethodModalContents}>
                <QueryLoadingWrapper {...paymentMethodsState}>
                    {(paymentMethods) => (
                        <PaymentSettingContents
                            paymentMethods={paymentMethods}
                            targetPaymentMethod={props.targetPaymentMethod}
                            defaultPaymentMethod={props.defaultPaymentMethod}
                            setTargetPaymentMethod={props.setTargetPaymentMethod}
                            handlePaymentMethodModalClose={props.handlePaymentMethodModalClose}
                        />
                    )}
                </QueryLoadingWrapper>
            </div>
        </Modal>
    );
};
