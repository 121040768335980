import { FAQConfig } from "@/FAQConfig";

export const EMAIL_NOT_ARRIVED_CONFIGS: FAQConfig[] = [
    {
        title: "迷惑メールフォルダに届いている",
        text: "Trailが送信しているメールはメール転送サービスを利用しているため、なりすましメールと判定されている可能性がございます。受信フォルダ、迷惑メールフォルダから、メールアドレス「___@___」を検索してください。",
    },
    {
        title: "メール受信設定により、メールフォルダ自体に届いていない",
        text: "携帯キャリア、Gmailにおける受信設定を確認し、Trailからのメールの受信許可設定を行ってください。受信許可の設定が行われていない場合は、以下を『許可』の設定に変更してください。",
        list: ["ドメイン設定が可能な場合：@___", "メールアドレス設定の場合：___@___"],
    },
    {
        title: "登録したメールアドレスが正しくない",
        text: "講座検索で受けたい講座が見つからない場合、講座リクエストで様々な条件を指定してピンポイントで公開依頼を行うことができます。依頼に対して応募した先生から提案が届くので、その中から自分に合った先生を選ぶことができます。",
    },
];
