import {
    Button,
    // Button,
    Modal,
    // TextField
} from "@material-ui/core";
import { useHistory } from "react-router";

import styles from "@/pages/Common/TopPage/student.module.scss";
import { useCallback } from "react";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { logout } from "@/actions/logging";

// import googleButton from "./images/googleButton.png";

interface Props {
    open: boolean;
    handleClose: () => void;
}

export const PleaseStudentSignUpModal: React.VFC<Props> = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const handleStudentSignUpButtonClick = useCallback(async () => {
        await Auth.signOut();
        dispatch(logout());
        localStorage.clear();
        history.push("/SignUp?type=student");
    }, []);
    return (
        <Modal open={props.open} onClose={props.handleClose}>
            <div className={styles.pleaseTeacherSignUpContents}>
                <div className={styles.pleaseSignUp}>生徒アカウントの登録がされていません。登録してください。</div>
                <div className={styles.buttonsWrapper}>
                    <Button className={styles.backButton} onClick={props.handleClose}>
                        閉じる
                    </Button>
                    <Button className={styles.chargeButton} onClick={handleStudentSignUpButtonClick}>
                        生徒登録する
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
