import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { logout } from "@/actions/logging";
import { useCognitoUser } from "@/components/Authentication";

import { FileInfo } from "@/components/TrimmingModal";
import { RootState } from "@/ducks";
import { CreateStudentRequestBody, CreateUserRequestBody, SubjectResponse } from "@/store/autogenApi";
import { useCreateStudentMutation } from "@/store/hooks/students";
import { useCreateUserMutation, useUpdateUserMutation } from "@/store/hooks/users";
import { useUploadFiles } from "@/utils/UploadFiles";

import { studentConfig } from "./Config";
import { FormContents } from "./FormContents";

interface Props {
    createStudentRequestBody: CreateStudentRequestBody | undefined;
    createUserRequestBody: CreateUserRequestBody | undefined;
    userId: string;
    deleteUser: () => Promise<void>;
    handleStudentParamsChange: (
        e: React.ChangeEvent<
            | HTMLInputElement
            | {
                  name?: string | undefined;
                  value: unknown;
              }
        >,
    ) => void;
    handleHasErrorChange: (hasError: boolean) => void;
    setIsCompleted: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface Info {
    nickname: string;
    gender: string;
    birthDate: string;
    prefecture: string;
    profession: string;
    background: string;
    catchCopy: string;
    profile: string;
}

export const StudentProfileInput: React.VFC<Props> = (props) => {
    const [checked, setChecked] = useState<boolean>(false);
    const [fileInfo, setFileInfo] = useState<FileInfo | undefined>(undefined);
    const [open, setOpen] = useState(false);
    const [favoriteSubjects, setFavoriteSubjects] = useState<SubjectResponse[]>([]);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const createStudent = useCreateStudentMutation();
    const createUser = useCreateUserMutation();

    const dispatch = useDispatch();

    const userId = useSelector((state: RootState) => state.jwt.userId as string);

    const uploadFiles = useUploadFiles();
    const cognitoUser = useCognitoUser() as any;
    const confirm = useCallback(async () => {
        try {
            setIsProcessing(true);
            const modifiedCreateUserParams: CreateUserRequestBody = {
                ...(props.createUserRequestBody as CreateUserRequestBody),
                userId: props.userId,
                email: cognitoUser.attributes.email,
            };
            await createUser({
                createUserRequestBody: modifiedCreateUserParams,
            });
            const files = fileInfo?.file ? [fileInfo.file] : [];
            const basePath = "users/" + props.userId + "/student/iconImage";
            const urls = await uploadFiles(files, basePath);
            const modifiedCreateStudentParams: CreateStudentRequestBody = {
                ...(props.createStudentRequestBody as CreateStudentRequestBody),
                iconImageUrl: urls[0],
                userId: props.userId,
                favoriteSubjectIds: favoriteSubjects.map((subject) => subject.subjectId),
            };
            await createStudent({
                createStudentRequestBody: modifiedCreateStudentParams,
            });
            window.scrollTo(0, 0);
            dispatch(logout());
            localStorage.clear();
            setIsProcessing(false);
            props.setIsCompleted(true);
        } catch (error) {
            console.log(error);
            setIsProcessing(false);
            alert("予期せぬエラーが発生しました。登録をやり直してください。");
            await Auth.deleteUser();
            dispatch(logout());
            localStorage.clear();
            props.handleHasErrorChange(true);
        }
    }, [
        props.createUserRequestBody,
        props.createStudentRequestBody,
        props.userId,
        fileInfo,
        favoriteSubjects,
        userId,
        cognitoUser,
        createStudent,
        createUser,
        dispatch,
        props.handleHasErrorChange,
    ]);

    const isValidStudentInfoValue = (key: keyof CreateStudentRequestBody, value: string) => {
        const upperLimit = studentConfig[key];
        if (upperLimit) {
            return value.length > 0 && value.length <= upperLimit;
        } else {
            false;
        }
    };

    const handleOpen = () => {
        const failureFunc = () => {
            setChecked(true);
            window.scrollTo(0, 150);
        };
        const characterLimit =
            props.createStudentRequestBody?.nickname &&
            0 < props.createStudentRequestBody?.nickname.length &&
            props.createStudentRequestBody?.nickname.length <= 30;
        characterLimit ? setOpen(true) : failureFunc();
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <FormContents
            checked={checked}
            open={open}
            fileInfo={fileInfo}
            favoriteSubjects={favoriteSubjects}
            isProcessing={isProcessing}
            createStudentRequestBody={props.createStudentRequestBody}
            setFileInfo={setFileInfo}
            setFavoriteSubjects={setFavoriteSubjects}
            handleStudentParamsChange={props.handleStudentParamsChange}
            handleOpen={handleOpen}
            handleClose={handleClose}
            confirm={confirm}
        />
    );
};
