import { memo, useCallback, useEffect, useState } from "react";

import { Button, InputBase, MenuItem, Select, TextField } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";

import styles from "../index.module.scss";

interface Props {
    keyword: string;
    setKeyword: React.Dispatch<React.SetStateAction<string>>;
    checkNarrowDownHeight: () => void;
}

const theme = createTheme({
    overrides: {
        MuiList: {
            root: {
                maxHeight: "500px",
                overflowY: "scroll",
            },
            padding: {
                paddingTop: "0",
                paddingBottom: "0",
            },
        },
    },
});

export const KeywordConditions: React.VFC<Props> = memo(function KeywordConditions(props) {
    const [accordionOpen, setAccordionOpen] = useState<boolean>(false);

    useEffect(() => {
        const keywordNumber = props.keyword;
        if (keywordNumber) {
            setAccordionOpen(true);
        } else {
            setAccordionOpen(false);
        }
    }, [props.keyword]);

    const handleChange = useCallback(
        (
            e: React.ChangeEvent<{
                value: unknown;
            }>,
        ) => {
            const value = e.target.value as string;
            props.setKeyword(value);
        },
        [props.setKeyword],
    );

    const handleAccordionClick = useCallback(() => {
        setAccordionOpen(!accordionOpen);
        props.checkNarrowDownHeight();
    }, [accordionOpen]);

    return (
        <div className={styles.conditionsWrapper} style={{ borderBottom: "1px solid #305077" }}>
            <Button className={styles.accordionButton} onClick={handleAccordionClick}>
                <div className={styles.narrowDownTitle}>キーワード</div>
                <div className={styles.flex} />
                {accordionOpen ? (
                    <RiArrowUpSLine className={styles.arrow} />
                ) : (
                    <RiArrowDownSLine className={styles.arrow} />
                )}
            </Button>
            {accordionOpen && (
                <div className={styles.conditions}>
                    <div className={styles.condition}>
                        <ThemeProvider theme={theme}>
                            <InputBase
                                placeholder="古典文法, 微分, 英文解釈..."
                                className={styles.inputArea}
                                inputProps={{ "aria-label": "search" }}
                                value={props.keyword}
                                onChange={handleChange}
                            />
                        </ThemeProvider>
                    </div>
                </div>
            )}
        </div>
    );
});
