import { memo, useCallback } from "react";
import { Button } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { FaChalkboardTeacher, FaRegHandshake } from "react-icons/fa";
import { MdCheckCircleOutline } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import { ProposalResponse, PublicRequestResponse } from "@/store/autogenApi";
import styles from "./index.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { IndividualChatButton } from "@/components/IndividualChatButton";
import { getLessonTime } from "@/utils/LessonUtils";
import { getRankBadgeSrc } from "@/utils/TeacherUtils";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";
import { getBaseScheduleInfo } from "@/utils/BaseScheduleUtils";

interface Props {
    proposal: ProposalResponse;
    publicRequest: PublicRequestResponse;
}

export const ProposalComponent: React.VFC<Props> = memo(function ProposalComponent(props) {
    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);

    const getUnitOfPricePeyPayment = useCallback(() => {
        if (props.publicRequest.courseType === "short") {
            return "講座全体で";
        } else if (props.publicRequest.courseType === "regular") {
            return "月額";
        }
    }, [props.publicRequest.courseType]);

    const history = useHistory();

    const handleManagementButtonClick = useCallback(() => {
        const classResponse = props.proposal.class;
        if (classResponse) {
            const classId = classResponse.classId;
            if (classId) {
                history.push(`/Class/${classId}`);
            }
        }
    }, [props.proposal.class, history]);

    const handlePleaseButtonClick = useCallback(() => {
        history.push(`/PaymentForProposal/${props.proposal.proposalId}`);
    }, [props.proposal]);

    return (
        <div
            className={
                props.proposal.class?.course ? `${styles.proposal} ${styles.myTeacherProposal}` : `${styles.proposal}`
            }
        >
            <div className={styles.proposalInfoWrapper}>
                <div className={styles.leftWrapper}>
                    <div className={styles.topWrapper}>
                        <Button component={Link} to={`/TeacherDetails/${props.proposal.teacher?.teacherId}`}>
                            <AvatarFromS3
                                className={styles.avatar}
                                url={props.proposal.teacher.iconImageUrl}
                                objectKey={props.proposal.teacher.iconImageObjectKey}
                            />
                        </Button>
                        <div className={styles.teacherInfo}>
                            <div className={styles.nicknameAndRank}>
                                <Button
                                    component={Link}
                                    to={`/TeacherDetails/${props.proposal.teacher?.teacherId}`}
                                    className={styles.nicknameButton}
                                >
                                    <div className={styles.nickname}>{props.proposal.teacher?.nickname}先生</div>
                                </Button>
                                <img
                                    className={styles.rankBadge}
                                    src={
                                        props.proposal.teacher?.rankNumber
                                            ? getRankBadgeSrc(props.proposal.teacher?.rankNumber)
                                            : ""
                                    }
                                    style={{ display: props.proposal.teacher?.rankNumber ? "block" : "none" }}
                                />
                            </div>
                            <div className={styles.status}>
                                <div className={styles.achievement}>
                                    指導実績：{props.proposal.teacher?.totalAchievements}件
                                </div>
                                <div className={styles.scoreWrapper}>
                                    総合評価：
                                    <div className={styles.ratingWrapper}>
                                        <Rating
                                            name="half-rating-read"
                                            value={
                                                props.proposal.teacher?.stats?.averageReviewScore
                                                    ? props.proposal.teacher?.stats?.averageReviewScore
                                                    : 0
                                            }
                                            precision={0.1}
                                            readOnly
                                            className={styles.rating}
                                            size="small"
                                        />
                                        <div className={styles.value}>
                                            ({props.proposal.teacher?.stats?.averageReviewScore})
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.identificationWrapper}>
                                    {props.proposal.teacher.identificationStatus === "approved" && (
                                        <div className={styles.isIdentifiedWrapper}>
                                            <MdCheckCircleOutline className={styles.isIdentifiedIcon} />
                                            <div className={styles.isIdentified}>本人確認済み</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.proposalContentsWrapper}>
                        <div className={styles.scheduleWrapper}>
                            <div className={styles.scheduleTitle}>スケジュール</div>
                            {props.publicRequest.courseType === "short" ? (
                                <ul className={styles.classes}>
                                    {props.proposal.class?.lessons.map((lesson, idx) => {
                                        return (
                                            <li className={styles.lesson} key={lesson.lessonId}>
                                                {`${idx + 1}日目: ${getLessonTime(lesson)}`}
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : (
                                <ul className={styles.classes}>
                                    {props.proposal.baseSchedules.map((baseSchedule, idx) => {
                                        return (
                                            <li className={styles.lesson} key={baseSchedule.baseScheduleId}>
                                                {getBaseScheduleInfo(baseSchedule)}
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                        </div>
                        <div className={styles.messageWrapper}>
                            <div className={styles.messageTitle}>提案メッセージ</div>
                            <div className={styles.message}>{props.proposal.content}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.rightWrapper}>
                    <div className={styles.pricePerHourWrapper}>
                        <div className={styles.unit}>(1時間あたり)</div>
                        <div className={styles.pricePerHour}>
                            ￥{new Intl.NumberFormat("ja-JP").format(props.proposal.class.pricePerHour)}
                        </div>
                    </div>
                    <div className={styles.pricePerPaymentWrapper}>
                        <div className={styles.unit}>({getUnitOfPricePeyPayment()})</div>
                        <div className={styles.pricePerPayment}>
                            ￥{new Intl.NumberFormat("ja-JP").format(props.proposal.class.pricePerPayment)}
                        </div>
                    </div>
                    {props.proposal.class?.course ? (
                        <>
                            <div className={styles.requestedWrapper}>
                                <AiOutlineCheckCircle className={styles.checkIcon} />
                                <div className={styles.requested}>授業依頼済み</div>
                            </div>
                            <Button className={styles.classButton} onClick={handleManagementButtonClick}>
                                <FaChalkboardTeacher className={styles.classIcon} />
                                <span className={styles.class}>クラスページへ</span>
                            </Button>
                        </>
                    ) : (
                        <Button className={styles.requestButton} onClick={handlePleaseButtonClick}>
                            <FaRegHandshake className={styles.requestIcon} />
                            <span className={styles.publicRequest}>授業を依頼する</span>
                        </Button>
                    )}
                    <IndividualChatButton
                        studentId={studentId}
                        teacherId={props.proposal.teacher.teacherId}
                        buttonLabel={
                            props.proposal.messages && props.proposal.messages.length === 0 ? "質問する" : "チャット"
                        }
                    />
                </div>
            </div>
        </div>
    );
});
