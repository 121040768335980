import { memo, useCallback, useMemo, useState } from "react";
import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";

import { RouterPromptWithDraft } from "@/components/RouterPromptWithDraft";
import { RootState } from "@/ducks";
import { CourseContents } from "../CourseContents";
import {
    CourseResponse,
    CourseType,
    CreateAvailableTimeRequestParams,
    CreateCourseRequestBody,
    CreateClassRequestBody,
    CreateLessonRequestParams,
    LessonResponse,
    SubjectResponse,
    UpdateAvailableTimeRequestBody,
    UpdateCourseRequestBody,
    UpdateDraftCourseRequestBody,
    DraftCourseResponse,
    CreateDraftCourseRequestBody,
    ZoomAccountStatus,
} from "@/store/autogenApi";
import { useCreateCourseMutation, useUpdateCourseMutation } from "@/store/hooks/courses";
import {
    useCreateDraftCourseMutation,
    useDeleteDraftCourseMutation,
    useUpdateDraftCourseMutation,
} from "@/store/hooks/draftCourses";
import { dateToString } from "@/utils/DateUtils";
import { PleaseLinkZoomModal } from "@/components/PleaseLinkZoomModal";
import { FileInfo } from "@/components/TrimmingModal";
import { zenkakuToHankaku } from "@/utils/ZenkakuToHankaku";
import { UseDraftCourseModal } from "../UseDraftCourseModal";
import { useUploadFiles } from "@/utils/UploadFiles";
import { getPricePerPaymentForShortCourse } from "@/utils/ClassUtils";

interface Props {
    existingCourse?: CourseResponse;
    zoomAccountStatus: ZoomAccountStatus;
    existingLessons: LessonResponse[];
    isCompleted: boolean;
    isProcessing: boolean;
    handleIsCompletedChange: (isCompleted: boolean) => void;
    handleIsTemporarySavedChange: (isTemporarySaved: boolean) => void;
    handleHasErrorChange: (hasError: boolean) => void;
    handleNewCourseIdChange: (newCourseId: string | undefined) => void;
    handleIsProcessingChange: (isProcessing: boolean) => void;
}

const INITIAL_AVAILABLE_TIMES: CreateAvailableTimeRequestParams[] = [...Array(7)].map((_, idx) => ({
    isActive: false,
    dayOfWeekIndex: idx,
    startHour: null,
    startMinute: null,
    endHour: null,
    endMinute: null,
}));

const dayAfterTomorrow = new Date();
dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
dayAfterTomorrow.setHours(0, 0, 0, 0);

export const CommonComponent: React.VFC<Props> = memo(function CommonComponent(props) {
    const [modalMaxHeight, setModalMaxHeight] = useState<number>(0);
    const [isEdited, setIsEdited] = useState<boolean>(false);
    const [zoomModalOpen, setZoomModalOpen] = useState<boolean>(false);
    const [courseTypeChecked, setCourseTypeChecked] = useState(false);
    const [existStudent, setExistStudent] = useState(false);
    const [courseType, setCourseType] = useState<CourseType | undefined>(undefined);
    const [title, setTitle] = useState<string>("");
    const [teachingMaterial, setTeachingMaterial] = useState<string>("");
    const [maxParticipants, setMaxNumber] = useState<number>(0);
    const [minParticipants, setMinNumber] = useState<number>(0);
    const [courseDescription, setCourseDescription] = useState<string>("");
    const [targetDescription, setTargetDescription] = useState<string>("");
    const [fileInfoList, setFileInfoList] = useState<FileInfo[]>([]);
    const [additionalDescription, setAdditionalDescription] = useState<string>("");
    const [pricePerHour, setPrice] = useState<number>(0);
    const [deadlineNumber, setDeadlineNumber] = useState<number>(0);
    const [maxDaysInAWeek, setMaxDaysInAWeek] = useState<number>(0);
    const [maxMinutesPerLesson, setMaxMinutesPerLesson] = useState<number>(0);
    const [availableTimes, setAvailableTimes] =
        useState<(CreateAvailableTimeRequestParams | UpdateAvailableTimeRequestBody)[]>(INITIAL_AVAILABLE_TIMES);
    const [applicationPeriod, setApplicationPeriod] = useState<number>(0);
    const [subjects, setSubjects] = useState<SubjectResponse[]>([]);
    const [lessons, setLessons] = useState<CreateLessonRequestParams[]>([]);
    const [applyingDeadline, setApplyingDeadline] = useState<Date>(dayAfterTomorrow);

    const { currentCourseId } = useParams<{ currentCourseId: string }>();
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);

    const query = new URLSearchParams(useLocation().search);
    const draftCourseId = query.get("draftCourseId");

    const createCourseMutate = useCreateCourseMutation();
    const updateCourseMutate = useUpdateCourseMutation();
    const createDraftCourse = useCreateDraftCourseMutation();
    const updateDraftCourse = useUpdateDraftCourseMutation();
    const deleteDraftCourse = useDeleteDraftCourseMutation();
    const uploadFiles = useUploadFiles();

    useEffect(() => {
        if (props.zoomAccountStatus !== "active") {
            setZoomModalOpen(true);
        }
    }, [props.zoomAccountStatus]);

    const changeModalMaxHeight = useCallback(() => {
        const innerHeight = window.innerHeight;
        setModalMaxHeight(innerHeight * 0.75);
    }, []);

    useEffect(() => {
        changeModalMaxHeight();
        window.addEventListener("resize", () => {
            changeModalMaxHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalMaxHeight();
            });
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (props.existingCourse) {
                const fetchedCourse = props.existingCourse;
                setCourseType(fetchedCourse.courseType);
                setTitle(fetchedCourse.title ?? "");
                setTeachingMaterial(fetchedCourse.teachingMaterial ?? "");
                setCourseDescription(fetchedCourse.courseDescription ?? "");
                setTargetDescription(fetchedCourse.targetDescription ?? "");
                setAdditionalDescription(fetchedCourse.additionalDescription ?? "");
                setSubjects(fetchedCourse.subjects);
                if (fetchedCourse.imageUrls) {
                    const fileInfos = fetchedCourse.imageUrls.map((url) => {
                        return { file: undefined, url };
                    });
                    setFileInfoList(fileInfos);
                }
                setIsEdited(false);
            } else {
                setIsEdited(false);
            }
        })();
    }, [props.existingCourse]);

    const handleCourseTypeChange = useCallback(
        (event: React.ChangeEvent<{ value: unknown }>) => {
            setIsEdited(true);
            const value = event.target.value as CourseType;
            setCourseType(value);
            if (value === "regular") {
                setMaxNumber(1);
            } else {
                setMaxNumber(0);
            }
        },
        [pricePerHour],
    );

    const handleTitleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIsEdited(true);
        setTitle(event.target.value);
    }, []);

    const handleTeachingMaterialChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIsEdited(true);
        setTeachingMaterial(event.target.value);
    }, []);

    const handleMaxNumberChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIsEdited(true);
        setMaxNumber(Number(event.target.value));
    }, []);

    const handleMinNumberChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIsEdited(true);
        setMinNumber(Number(event.target.value));
    }, []);

    const handleCourseDescriptionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIsEdited(true);
        setCourseDescription(event.target.value);
    }, []);

    const handleTargetDescriptionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIsEdited(true);
        setTargetDescription(event.target.value);
    }, []);

    const handleAdditionalDescriptionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIsEdited(true);
        setAdditionalDescription(event.target.value);
    }, []);

    const handleMaxDaysInAWeekChange = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        setIsEdited(true);
        const newHowManyDaysInAWeek = e.target.value as number;
        setMaxDaysInAWeek(newHowManyDaysInAWeek);
    }, []);

    const handleMaxMinutesPerLessonChange = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        setIsEdited(true);
        const newMaxMinutesPerLesson = e.target.value as number;
        setMaxMinutesPerLesson(newMaxMinutesPerLesson);
    }, []);

    const handleDeadlineNumberChange = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        setIsEdited(true);
        const newDeadlineNumber = e.target.value as number;
        setDeadlineNumber(newDeadlineNumber);
    }, []);

    const handleApplicationPeriodChange = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        setIsEdited(true);
        const newApplicationPeriod = e.target.value as number;
        setApplicationPeriod(newApplicationPeriod);
    }, []);

    const handleLessonsChange = useCallback((newLessons: CreateLessonRequestParams[]) => {
        setIsEdited(true);
        setLessons(newLessons);
    }, []);

    const handleApplyingDeadlineChange = useCallback((newApplyingDeadline: Date) => {
        setIsEdited(true);
        setApplyingDeadline(newApplyingDeadline);
    }, []);

    const handlePriceChange = useCallback(
        (
            e: React.ChangeEvent<
                | HTMLInputElement
                | {
                      value: unknown;
                  }
            >,
        ) => {
            setIsEdited(true);
            const value = zenkakuToHankaku(e.target.value as string);
            const fixedValue = Number(value) ? Number(value) : 0;
            setPrice(fixedValue);
        },
        [],
    );

    const deleteImage = useCallback(
        (idx: number) => {
            if (fileInfoList.length >= 1 && fileInfoList.length <= 1) {
                setFileInfoList(fileInfoList.filter((_, targetIdx) => targetIdx !== idx));
            }
        },
        [fileInfoList],
    );

    const handleConfirmButtonClick = useCallback(async () => {
        props.handleIsProcessingChange(true);
        window.scrollTo(0, 0);
        const courseId = currentCourseId ?? crypto.randomUUID();
        const urls = await Promise.all(
            fileInfoList.map(async (fileInfo) => {
                if (fileInfo.file) {
                    const urls = await uploadFiles([fileInfo.file], `courses/${courseId}`);
                    const url = urls[0];
                    return url;
                }
                return fileInfo.url;
            }),
        );
        if (!courseType) return;
        if (courseType === "short") {
            if (props.existingCourse) {
                const mergedRequestBody: UpdateCourseRequestBody = {
                    courseId,
                    teacherId: teacherId,
                    courseType: courseType,
                    title: title,
                    subjectIds: subjects.map((subject) => subject.subjectId),
                    teachingMaterial: teachingMaterial,
                    courseDescription: courseDescription,
                    targetDescription: targetDescription,
                    additionalDescription: additionalDescription,
                    imageUrls: urls,
                };
                const updateCourseState = await updateCourseMutate({
                    updateCourseRequestBody: mergedRequestBody as UpdateCourseRequestBody,
                });
                if (updateCourseState.isSuccess) {
                    props.handleIsProcessingChange(false);
                    props.handleNewCourseIdChange(updateCourseState.data.courseId);
                    props.handleIsCompletedChange(true);
                } else {
                    props.handleIsProcessingChange(false);
                    props.handleHasErrorChange(true);
                    setIsEdited(false);
                }
            } else {
                const pricePerPayment = getPricePerPaymentForShortCourse(pricePerHour, lessons);
                const createClassRequestBody: CreateClassRequestBody = {
                    pricePerHour,
                    pricePerPayment,
                    maxParticipants,
                    minParticipants,
                    lessons,
                    applyingDeadline: dateToString(applyingDeadline),
                };
                const mergedRequestBody: CreateCourseRequestBody = {
                    courseId,
                    teacherId: teacherId,
                    courseType: courseType,
                    title: title,
                    subjectIds: subjects.map((subject) => subject.subjectId),
                    teachingMaterial: teachingMaterial,
                    courseDescription: courseDescription,
                    targetDescription: targetDescription,
                    additionalDescription: additionalDescription,
                    createClassBody: createClassRequestBody,
                    imageUrls: urls,
                };
                const createCourseState = await createCourseMutate({
                    createCourseRequestBody: mergedRequestBody as CreateCourseRequestBody,
                });
                if (createCourseState.isSuccess) {
                    props.handleIsProcessingChange(false);
                    props.handleNewCourseIdChange(createCourseState.data.courseId);
                    window.scroll({ top: 0 });
                    props.handleIsCompletedChange(true);
                } else {
                    props.handleIsProcessingChange(false);
                    props.handleHasErrorChange(true);
                }
            }
        } else if (courseType === "regular") {
            const regularCourseDeadline = new Date();
            regularCourseDeadline.setDate(regularCourseDeadline.getDate() + applicationPeriod);
            if (props.existingCourse) {
                const mergedRequestBody: UpdateCourseRequestBody = {
                    courseId,
                    teacherId: teacherId,
                    courseType: courseType,
                    title: title,
                    subjectIds: subjects.map((subject) => subject.subjectId),
                    teachingMaterial: teachingMaterial,
                    courseDescription: courseDescription,
                    targetDescription: targetDescription,
                    additionalDescription: additionalDescription,
                    imageUrls: urls,
                };
                const updateCourseState = await updateCourseMutate({
                    updateCourseRequestBody: mergedRequestBody,
                });
                if (updateCourseState.isSuccess) {
                    props.handleIsProcessingChange(false);
                    props.handleNewCourseIdChange(updateCourseState.data.courseId);
                    props.handleIsCompletedChange(true);
                } else {
                    props.handleIsProcessingChange(false);
                    props.handleHasErrorChange(true);
                    setIsEdited(false);
                }
            } else {
                const fixedClass: CreateClassRequestBody = {
                    applyingDeadline: dateToString(regularCourseDeadline),
                    lessons: [],
                    maxDaysInAWeek: maxDaysInAWeek,
                    maxMinutesPerLesson: maxMinutesPerLesson,
                    pricePerHour: pricePerHour,
                };
                const mergedRequestBody: CreateCourseRequestBody = {
                    courseId,
                    teacherId: teacherId,
                    courseType: courseType,
                    title: title,
                    subjectIds: subjects.map((subject) => subject.subjectId),
                    teachingMaterial: teachingMaterial,
                    courseDescription: courseDescription,
                    targetDescription: targetDescription,
                    additionalDescription: additionalDescription,
                    availableTimes: availableTimes as CreateAvailableTimeRequestParams[],
                    createClassBody: fixedClass,
                    imageUrls: urls,
                };
                const createCourseState = await createCourseMutate({
                    createCourseRequestBody: mergedRequestBody,
                });
                if (createCourseState.isSuccess) {
                    props.handleIsProcessingChange(false);
                    props.handleNewCourseIdChange(createCourseState.data.courseId);
                    window.scroll({ top: 0 });
                    props.handleIsCompletedChange(true);
                } else {
                    props.handleIsProcessingChange(false);
                    props.handleHasErrorChange(true);
                }
            }
        }
    }, [
        fileInfoList,
        courseType,
        teacherId,
        title,
        subjects,
        teachingMaterial,
        courseDescription,
        targetDescription,
        pricePerHour,
        additionalDescription,
        applicationPeriod,
        maxDaysInAWeek,
        maxMinutesPerLesson,
        currentCourseId,
        maxParticipants,
        minParticipants,
        availableTimes,
        lessons,
        props.existingCourse,
        updateCourseMutate,
        createCourseMutate,
        props.handleIsProcessingChange,
        props.handleNewCourseIdChange,
        props.handleIsTemporarySavedChange,
        props.handleIsCompletedChange,
    ]);

    const handleDraftButtonClick = useCallback(async () => {
        props.handleIsProcessingChange(true);
        window.scrollTo(0, 0);
        const draftCourseId = props.existingCourse?.draftCourse?.draftCourseId ?? crypto.randomUUID();
        const urls = await Promise.all(
            fileInfoList.map(async (fileInfo) => {
                console.log(fileInfo.file, fileInfo.url);
                if (fileInfo.file) {
                    const urls = await uploadFiles([fileInfo.file], `draftCourses/${draftCourseId}`);
                    return urls[0];
                }
                return fileInfo.url;
            }),
        );
        if (!courseType) return;
        if (courseType === "short") {
            if (props.existingCourse?.draftCourse?.draftCourseId) {
                const mergedRequestBody: UpdateDraftCourseRequestBody = {
                    draftCourseId: props.existingCourse.draftCourse.draftCourseId,
                    courseId: props.existingCourse.courseId,
                    courseType: courseType,
                    title: title,
                    subjectIds: subjects.map((subject) => subject.subjectId),
                    teachingMaterial: teachingMaterial,
                    courseDescription: courseDescription,
                    targetDescription: targetDescription,
                    additionalDescription: additionalDescription,
                    imageUrls: urls,
                };
                const { isSuccess, data } = await updateDraftCourse({
                    updateDraftCourseRequestBody: mergedRequestBody,
                });
                if (isSuccess) {
                    props.handleIsProcessingChange(false);
                    props.handleNewCourseIdChange((data as DraftCourseResponse).course?.courseId);
                    props.handleIsTemporarySavedChange(true);
                    props.handleIsCompletedChange(true);
                } else {
                    props.handleIsProcessingChange(false);
                    props.handleHasErrorChange(true);
                    setIsEdited(false);
                }
            } else {
                const mergedRequestBody: CreateDraftCourseRequestBody = {
                    draftCourseId: draftCourseId,
                    courseId: props.existingCourse?.courseId,
                    teacherId: teacherId,
                    courseType: courseType,
                    title: title,
                    subjectIds: subjects.map((subject) => subject.subjectId),
                    teachingMaterial: teachingMaterial,
                    courseDescription: courseDescription,
                    targetDescription: targetDescription,
                    additionalDescription: additionalDescription,
                    imageUrls: urls,
                };
                const { isSuccess, data } = await createDraftCourse({
                    createDraftCourseRequestBody: mergedRequestBody,
                });
                if (isSuccess) {
                    props.handleIsProcessingChange(false);
                    props.handleNewCourseIdChange((data as DraftCourseResponse).course?.courseId);
                    window.scroll({ top: 0 });
                    props.handleIsTemporarySavedChange(true);
                    props.handleIsCompletedChange(true);
                } else {
                    props.handleIsProcessingChange(false);
                    props.handleHasErrorChange(true);
                }
            }
        } else if (courseType === "regular") {
            const regularCourseDeadline = new Date();
            regularCourseDeadline.setDate(regularCourseDeadline.getDate() + applicationPeriod);
            if (props.existingCourse?.draftCourse?.draftCourseId) {
                const mergedRequestBody: UpdateDraftCourseRequestBody = {
                    draftCourseId: props.existingCourse.draftCourse.draftCourseId,
                    courseId: props.existingCourse.courseId,
                    teacherId: teacherId,
                    courseType: courseType,
                    title: title,
                    subjectIds: subjects.map((subject) => subject.subjectId),
                    teachingMaterial: teachingMaterial,
                    courseDescription: courseDescription,
                    targetDescription: targetDescription,
                    additionalDescription: additionalDescription,
                    imageUrls: urls,
                };
                const { isSuccess, data } = await updateDraftCourse({
                    updateDraftCourseRequestBody: mergedRequestBody,
                });
                if (isSuccess) {
                    props.handleIsProcessingChange(false);
                    props.handleNewCourseIdChange((data as DraftCourseResponse).course?.courseId);
                    props.handleIsTemporarySavedChange(true);
                    props.handleIsCompletedChange(true);
                } else {
                    props.handleIsProcessingChange(false);
                    props.handleHasErrorChange(true);
                    setIsEdited(false);
                }
            } else {
                const mergedRequestBody: CreateDraftCourseRequestBody = {
                    draftCourseId: draftCourseId,
                    courseId: props.existingCourse?.courseId,
                    teacherId: teacherId,
                    courseType: courseType,
                    title: title,
                    subjectIds: subjects.map((subject) => subject.subjectId),
                    teachingMaterial: teachingMaterial,
                    courseDescription: courseDescription,
                    targetDescription: targetDescription,
                    additionalDescription: additionalDescription,
                    availableTimes: availableTimes as CreateAvailableTimeRequestParams[],
                    imageUrls: urls,
                };
                const { isSuccess, data } = await createDraftCourse({
                    createDraftCourseRequestBody: mergedRequestBody,
                });
                if (isSuccess) {
                    props.handleIsProcessingChange(false);
                    props.handleNewCourseIdChange((data as DraftCourseResponse).course?.courseId);
                    window.scroll({ top: 0 });
                    props.handleIsTemporarySavedChange(true);
                    props.handleIsCompletedChange(true);
                } else {
                    props.handleIsProcessingChange(false);
                    props.handleHasErrorChange(true);
                }
            }
        }
    }, [
        fileInfoList,
        courseType,
        teacherId,
        title,
        subjects,
        teachingMaterial,
        courseDescription,
        targetDescription,
        pricePerHour,
        additionalDescription,
        applicationPeriod,
        maxDaysInAWeek,
        maxMinutesPerLesson,
        currentCourseId,
        maxParticipants,
        minParticipants,
        availableTimes,
        lessons,
        props.existingCourse,
        updateDraftCourse,
        createDraftCourse,
        props.handleIsProcessingChange,
        props.handleNewCourseIdChange,
        props.handleIsTemporarySavedChange,
        props.handleIsCompletedChange,
    ]);

    const handleOKButtonClick = useCallback(() => {
        return true;
    }, []);

    const handleCancelButtonClick = useCallback(() => {
        return false;
    }, []);

    const handlePromptDraftButtonClick = useCallback(async () => {
        await handleDraftButtonClick();
        return true;
    }, [handleDraftButtonClick]);

    const handleUseDraftButtonClick = useCallback((draftCourse: DraftCourseResponse | undefined) => {
        (async () => {
            if (!draftCourse) return;
            setCourseType(draftCourse.courseType);
            setTitle(draftCourse.title ?? "");
            setTeachingMaterial(draftCourse.teachingMaterial ?? "");
            setCourseDescription(draftCourse.courseDescription ?? "");
            setTargetDescription(draftCourse.targetDescription ?? "");
            setAdditionalDescription(draftCourse.additionalDescription ?? "");
            setSubjects(draftCourse.subjects);
            // const updateAvailableTimeRequestBodies = draftCourse.availableTimes?.map((availableTime) => {
            //     const { dayOfWeek, ...rest } = availableTime;
            //     return {
            //         ...rest,
            //         dayOfWeekIndex: dayOfWeek.dayOfWeekIndex,
            //     } as UpdateAvailableTimeRequestBody;
            // });
            // setAvailableTimes(updateAvailableTimeRequestBodies ?? []);
            if (draftCourse.thumbnailUrl) {
                setFileInfoList([
                    {
                        file: undefined,
                        url: draftCourse.thumbnailUrl,
                    },
                ]);
            }
            await deleteDraftCourse({
                draftCourseId: draftCourse.draftCourseId,
            });
        })();
    }, []);

    const handleDiscardDraftButtonClick = useCallback(() => {
        (async () => {
            if (!draftCourseId) return;
            await deleteDraftCourse({
                draftCourseId,
            });
        })();
    }, [deleteDraftCourse, draftCourseId]);

    const history = useHistory();

    const handleBackButtonClick = useCallback(() => {
        history.push("/");
    }, [history]);

    return (
        <>
            <RouterPromptWithDraft
                when={Boolean(isEdited && !props.isCompleted)}
                onOK={handleOKButtonClick}
                onDraft={handlePromptDraftButtonClick}
                onCancel={handleCancelButtonClick}
                message={`講座${
                    currentCourseId ? "編集" : "作成"
                }が未完了です。未保存の編集内容は失われますがよろしいですか？`}
            />
            <CourseContents
                isProcessing={props.isProcessing}
                currentCourseId={currentCourseId}
                existingLessons={props.existingLessons}
                modalMaxHeight={modalMaxHeight}
                isEdited={isEdited}
                courseType={courseType}
                title={title}
                teachingMaterial={teachingMaterial}
                pricePerHour={pricePerHour}
                maxParticipants={maxParticipants}
                minParticipants={minParticipants}
                applicationPeriod={applicationPeriod}
                maxDaysInAWeek={maxDaysInAWeek}
                maxMinutesPerLesson={maxMinutesPerLesson}
                deadlineNumber={deadlineNumber}
                subjects={subjects}
                courseDescription={courseDescription}
                targetDescription={targetDescription}
                additionalDescription={additionalDescription}
                fileInfoList={fileInfoList}
                existStudent={existStudent}
                courseTypeChecked={courseTypeChecked}
                availableTimes={availableTimes}
                lessons={lessons}
                applyingDeadline={applyingDeadline}
                setAvailableTimes={setAvailableTimes}
                handleConfirmButtonClick={handleConfirmButtonClick}
                handleDraftButtonClick={handleDraftButtonClick}
                setCourseTypeChecked={setCourseTypeChecked}
                setFileInfoList={setFileInfoList}
                deleteImage={deleteImage}
                setSubjects={setSubjects}
                handleTitleChange={handleTitleChange}
                handleTeachingMaterialChange={handleTeachingMaterialChange}
                handlePriceChange={handlePriceChange}
                handleMaxNumberChange={handleMaxNumberChange}
                handleMinNumberChange={handleMinNumberChange}
                handleApplicationPeriodChange={handleApplicationPeriodChange}
                handleMaxDaysInAWeekChange={handleMaxDaysInAWeekChange}
                handleMaxMinutesPerLessonChange={handleMaxMinutesPerLessonChange}
                handleDeadlineNumberChange={handleDeadlineNumberChange}
                handleCourseDescriptionChange={handleCourseDescriptionChange}
                handleTargetDescriptionChange={handleTargetDescriptionChange}
                handleAdditionalDescriptionChange={handleAdditionalDescriptionChange}
                handleCourseTypeChange={handleCourseTypeChange}
                handleLessonsChange={handleLessonsChange}
                handleApplyingDeadlineChange={handleApplyingDeadlineChange}
            />
            <UseDraftCourseModal
                draftCourseId={draftCourseId}
                existCourse={Boolean(props.existingCourse)}
                handleDiscardDraftButtonClick={handleDiscardDraftButtonClick}
                handleUseDraftButtonClick={handleUseDraftButtonClick}
            />
            <PleaseLinkZoomModal
                modalOpen={zoomModalOpen}
                targetAction="講座を作成する"
                zoomAccountStatus={props.zoomAccountStatus}
                handleBackButtonClick={handleBackButtonClick}
            />
        </>
    );
});
