import styles from "@/pages/Common/LP/index.module.scss";
import { OverWrapper } from "@/components/OverWrapper";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { useGetLatestTeachersQuery } from "@/store/hooks/teachers";
import { TeacherCard } from "@/components/TeacherCard";
import teacherPageImage from "../img/teacherPage.jpg";
import coursePageImage from "../img/coursePage.jpg";
import requestPageImage from "../img/requestPage.jpg";
import BronzeBadge from "@/images/B.png";
import GoldBadge from "@/images/G.png";
import PlatinumBadge from "@/images/P.png";
import RegularBadge from "@/images/R.png";
import SilverBadge from "@/images/S.png";
import { TeacherDetailInfoModal } from "@/components/TeacherDetailInfoModal";
import { useCallback, useState } from "react";
import { PublicTeacherResponse } from "@/store/autogenApi";

export const WhatsTrail: React.VFC = () => {
    const [openedTeacher, setOpenedTeacher] = useState<PublicTeacherResponse | undefined>(undefined);

    const teachersQueryState = useGetLatestTeachersQuery();

    const handleTeacherCardClick = useCallback((teacher: PublicTeacherResponse) => {
        setOpenedTeacher(teacher);
    }, []);

    const handleModalClose = useCallback(() => {
        setOpenedTeacher(undefined);
    }, []);

    return (
        <div className={styles.whatsTrailWrapper}>
            <OverWrapper
                style={{
                    backgroundColor: "#e7f3ff",
                    padding: "20px 10px",
                    borderTop: "1px solid #999",
                    borderBottom: "1px solid #999",
                }}
            >
                <div className={styles.outlineWrapper}>
                    <div className={styles.message}>Trailは</div>
                    <div className={styles.strongMessageWrapper}>
                        <span className={styles.strong}>先生が個人として輝ける</span>
                    </div>
                    <div className={styles.message}>【学び】のフリマアプリ</div>
                </div>
            </OverWrapper>
            <div className={styles.teachersWrapper}>
                <div className={styles.teachersTitle}>こんな先生が登録しています！</div>
                <QueryLoadingWrapper {...teachersQueryState}>
                    {(teachers) => {
                        const eighteenTeachers = teachers.slice(0, 18);
                        return (
                            <div className={styles.teachers}>
                                {[...Array(6)].map((_, i) => (
                                    <div className={styles.teacherRow} key={i}>
                                        {eighteenTeachers.slice(i * 3, (i + 1) * 3).map((teacher) => (
                                            <TeacherCard
                                                key={teacher.teacherId}
                                                teacher={teacher}
                                                isFlex
                                                handleClick={handleTeacherCardClick}
                                            />
                                        ))}
                                    </div>
                                ))}
                            </div>
                        );
                    }}
                </QueryLoadingWrapper>
                {openedTeacher && (
                    <TeacherDetailInfoModal
                        teacher={openedTeacher}
                        disableToChangeLocation
                        handleClose={handleModalClose}
                    />
                )}
                <div>・・・</div>
            </div>
            <OverWrapper
                style={{
                    backgroundColor: "#FFF",
                    padding: "10px",
                    borderTop: "1px solid #999",
                    borderBottom: "1px solid #999",
                }}
            >
                <div className={styles.detailsWrapper}>
                    <div className={styles.detailsTitle}>
                        <span className={styles.strong}>評価されるべきは運営ではなく先生</span>
                        <br />
                        が基本方針だからこその圧倒的な自由
                    </div>
                    <div className={styles.details}>
                        <div className={styles.detail}>
                            <div className={styles.detailTitle}>- 先生 -</div>
                            <div className={styles.detailContents}>
                                <div className={styles.detailImageWrapper}>
                                    <img src={teacherPageImage} className={styles.detailImage} />
                                </div>
                                <div className={styles.detailDescription}>
                                    先生は自分のページを持つことができ、そこには基本情報や対応可能科目、経歴等が事細かに記載することができます。
                                    <br />
                                    <span className={styles.strong}>自分はどういう先生なのかを自分の言葉で紡いで</span>
                                    魅力的なページを作成しましょう。
                                    <br />
                                    <br />
                                    また、後述の「講座」の開催数やそれに対するレビュー評価によって先生のランクが決定されます。
                                    <br />
                                    ランクが高くなればなるほど、手数料が低くなるため、
                                    <span className={styles.strong}>頑張った分だけ収入が増える</span>
                                    仕組みになっています。
                                    <br />
                                    最高ランクのプラチナになると、手数料は20%。一般的な塾講師バイトや家庭教師バイトの場合酷い場合では半分以上を中間手数料として引かれていることを考えると、圧倒的に低く業界最高水準となっているため、効率よく稼ぐことができます。
                                </div>
                            </div>
                            <table className={styles.feeTable}>
                                <tbody>
                                    <tr className={styles.tableRow}>
                                        <th className={styles.tableHeader}>ランク</th>
                                        <td className={styles.tableData}>-</td>
                                        <td className={styles.tableData}>レギュラー</td>
                                        <td className={styles.tableData}>ブロンズ</td>
                                        <td className={styles.tableData}>シルバー</td>
                                        <td className={styles.tableData}>ゴールド</td>
                                        <td className={styles.tableData}>プラチナ</td>
                                    </tr>
                                    <tr className={styles.tableRow}>
                                        <th className={styles.tableHeader}>バッジ</th>
                                        <td className={styles.tableData}>-</td>
                                        <td className={styles.tableData}>
                                            <img src={RegularBadge} alt="" className={styles.badgeImage} />
                                        </td>
                                        <td className={styles.tableData}>
                                            <img src={BronzeBadge} alt="" className={styles.badgeImage} />
                                        </td>
                                        <td className={styles.tableData}>
                                            <img src={SilverBadge} alt="" className={styles.badgeImage} />
                                        </td>
                                        <td className={styles.tableData}>
                                            <img src={GoldBadge} alt="" className={styles.badgeImage} />
                                        </td>
                                        <td className={styles.tableData}>
                                            <img src={PlatinumBadge} alt="" className={styles.badgeImage} />
                                        </td>
                                    </tr>
                                    <tr className={styles.tableRow}>
                                        <th className={styles.tableHeader}>累計授業数</th>
                                        <td className={styles.tableData}>-</td>
                                        <td className={styles.tableData}>1以上</td>
                                        <td className={styles.tableData}>10以上</td>
                                        <td className={styles.tableData}>30以上</td>
                                        <td className={styles.tableData}>50以上</td>
                                        <td className={styles.tableData}>100以上</td>
                                    </tr>
                                    <tr className={styles.tableRow}>
                                        <th className={styles.tableHeader}>直近3か月の売上金</th>
                                        <td className={styles.tableData}>-</td>
                                        <td className={styles.tableData}>-</td>
                                        <td className={styles.tableData}>¥30,000以上</td>
                                        <td className={styles.tableData}>¥50,000以上</td>
                                        <td className={styles.tableData}>¥100,000以上</td>
                                        <td className={styles.tableData}>¥200,000以上</td>
                                    </tr>
                                    <tr className={styles.tableRow}>
                                        <th className={styles.tableHeader}>レビューの平均点</th>
                                        <td className={styles.tableData}>-</td>
                                        <td className={styles.tableData}>-</td>
                                        <td className={styles.tableData}>3.0以上</td>
                                        <td className={styles.tableData}>3.5以上</td>
                                        <td className={styles.tableData}>4.0以上</td>
                                        <td className={styles.tableData}>4.5以上</td>
                                    </tr>
                                    <tr className={styles.tableRow}>
                                        <th className={styles.tableHeader}>先生の授業手数料</th>
                                        <td className={styles.tableData}>30%</td>
                                        <td className={styles.tableData}>28%</td>
                                        <td className={styles.tableData}>26%</td>
                                        <td className={styles.tableData}>24%</td>
                                        <td className={styles.tableData}>22%</td>
                                        <td className={styles.tableData}>20%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={styles.detail}>
                            <div className={styles.detailTitle}>- 講座 -</div>
                            <div className={styles.detailContents}>
                                <div className={styles.detailImageWrapper}>
                                    <img src={coursePageImage} className={styles.detailImage} />
                                </div>
                                <div className={styles.detailDescription}>
                                    先生は自由に講座を作成することができます。
                                    <br />
                                    <span className={styles.strong}>講座内容・料金までも先生自身が設定</span>
                                    することができ、運営は余計な介入を一切しません。
                                    <br />
                                    「【名大理系志望必見！】名大理系数学完全攻略」といったピンポイントに授業を行う「単発・短期講座」と、「【成績UPに自信あり】学校の数学をフォローします」のような定期的な授業を行う「定期講座」があり、それらを自由に作成できます。
                                    <br />
                                    受講後のレビューが実績として蓄積されていくため、実績が溜まってきたら料金を高く設定してみるのもありです。
                                    <br />
                                    <span className={styles.strong}>あなたの強みを活かした</span>
                                    講座を作成してみましょう。
                                </div>
                            </div>
                            <div className={styles.priceWrapper}>
                                <div className={styles.priceTitle}>定期講座の授業料（月謝）の例</div>
                                <div className={styles.priceDescription}>
                                    定期講座の授業料は「時間単価」「週の授業回数」「各回の授業時間」によって決定されます。それらの条件は全て
                                    <span className={styles.strong}>あなた自身で決定</span>
                                    することができます。また、上述の通り先生のランクによって手数料が変動します。
                                </div>
                                <div className={styles.cases}>
                                    <div className={styles.case}>
                                        <div className={styles.caseTitle}>- 例1 -</div>
                                        <div className={styles.conditionsWrapper}>
                                            <div className={styles.conditionCase}>ランクなしの先生が</div>
                                            <div className={styles.conditions}>
                                                <div className={styles.condition}>・時間単価：¥3,000</div>
                                                <div className={styles.condition}>・週2回</div>
                                                <div className={styles.condition}>・各2時間</div>
                                            </div>
                                            <div className={styles.conditionCase}>
                                                で生徒さん1人に対して授業をした場合
                                            </div>
                                        </div>
                                        <div className={styles.formula}>
                                            ¥3,000
                                            <span className={styles.unit}>円/時間</span> × 2
                                            <span className={styles.unit}>回/週</span>× 2
                                            <span className={styles.unit}>時間/回</span>× (ひと月の平均週数)
                                            <span className={styles.unit}>週/月</span>× (1 - 0.3)
                                            <span className={styles.unit}>（手数料30%）</span>≒
                                            <span className={styles.total}>
                                                ¥36,498<span className={styles.unit}>円/月</span>
                                            </span>
                                        </div>
                                        <div className={styles.formula}>
                                            この条件で2人の生徒さんを担当した場合、
                                            <br />
                                            ¥36,498
                                            <span className={styles.unit}>円/月</span>× 2 =
                                            <span className={styles.total}>
                                                ¥72,996<span className={styles.unit}>円/月</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className={styles.case}>
                                        <div className={styles.caseTitle}>- 例2 -</div>
                                        <div className={styles.conditionsWrapper}>
                                            <div className={styles.conditionCase}>シルバーランクの先生が</div>
                                            <div className={styles.conditions}>
                                                <div className={styles.condition}>・時間単価：¥3,500</div>
                                                <div className={styles.condition}>・週2回</div>
                                                <div className={styles.condition}>・各1.5時間</div>
                                            </div>
                                            <div className={styles.conditionCase}>
                                                で生徒さん1人に対して授業をした場合
                                            </div>
                                        </div>
                                        <div className={styles.formula}>
                                            ¥3,500<span className={styles.unit}>円/時間</span>× 2
                                            <span className={styles.unit}>回/週</span>× 1.5
                                            <span className={styles.unit}>時間/回</span>× (ひと月の平均週数)
                                            <span className={styles.unit}>週/月</span>× (1 - 0.24)
                                            <span className={styles.unit}>（手数料24%）</span>≒
                                            <span className={styles.total}>
                                                ¥34,673<span className={styles.unit}>円/月</span>
                                            </span>
                                        </div>
                                        <div className={styles.formula}>
                                            この条件で3人の生徒さんを担当した場合、
                                            <br />
                                            ¥34,673
                                            <span className={styles.unit}>円/月</span>× 3 =
                                            <span className={styles.total}>
                                                ¥104,019<span className={styles.unit}>円/月</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className={styles.case}>
                                        <div className={styles.caseTitle}>- 例3 -</div>
                                        <div className={styles.conditionsWrapper}>
                                            <div className={styles.conditionCase}>プラチナランクの先生が</div>
                                            <div className={styles.conditions}>
                                                <div className={styles.condition}>・時間単価：¥5,000</div>
                                                <div className={styles.condition}>・週2回</div>
                                                <div className={styles.condition}>・各1時間</div>
                                            </div>
                                            <div className={styles.conditionCase}>
                                                で生徒さん1人に対して授業をした場合
                                            </div>
                                        </div>
                                        <div className={styles.formula}>
                                            ¥5,000<span className={styles.unit}>円/時間</span>× 2
                                            <span className={styles.unit}>回/週</span>× 1
                                            <span className={styles.unit}>時間/回</span>× (ひと月の平均週数)
                                            <span className={styles.unit}>週/月</span> ≒
                                            <span className={styles.total}>
                                                ¥34,760<span className={styles.unit}>円/月</span>
                                            </span>
                                        </div>
                                        <div className={styles.formula}>
                                            この条件で4人の生徒さんを担当した場合、
                                            <br />
                                            ¥34,760
                                            <span className={styles.unit}>円/月</span>× 4 =
                                            <span className={styles.total}>
                                                ¥139,040<span className={styles.unit}>円/月</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.averageWeeks}>
                                    ※ (ひと月の平均週数) = 365日 / 12ヶ月 / 7日 ≒ 4.345週
                                </div>
                            </div>
                        </div>
                        <div className={styles.detail}>
                            <div className={styles.detailTitle}>- 講座リクエスト -</div>
                            <div className={styles.detailContents}>
                                <div className={styles.detailImageWrapper}>
                                    <img src={requestPageImage} className={styles.detailImage} />
                                </div>
                                <div className={styles.detailDescription}>
                                    先生は作成した講座に生徒さんが参加してくれるのを待つだけでなく、
                                    <span className={styles.strong}>自ら「講座リクエスト」に提案</span>
                                    をすることができます。
                                    <br />
                                    「講座リクエスト」というのは、生徒さんが作成する、例えば「英語の長文読解のコツを教えてください！」などの要望のことで、Trailではその要望に対して
                                    <span className={styles.strong}>
                                        講座内容・料金を先生が自由に設定して提案することが可能
                                    </span>
                                    です。
                                    <br />
                                    「この生徒さん担当できそう！」と思ったら積極的に提案を送ってみましょう。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </OverWrapper>
        </div>
    );
};
