import { FAQDetailsBase } from "../../FAQDetailsBase";

export const Tax: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="受講に消費税はかかりますか？"
            text="領収書は入金時に自動的に発行され、登録したメールアドレスに送信されます。受信したメールをご確認ください。"
        />
    );
};
