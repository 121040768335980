/* eslint-disable @typescript-eslint/no-explicit-any */
import { SerializedError } from "@reduxjs/toolkit";
import { BaseQueryFn, FetchBaseQueryError, MutationDefinition } from "@reduxjs/toolkit/dist/query";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";

import { ErrorMessages } from "./types";

export type MutationState<T> =
    | { isSuccess: true; data: T; errorMessage: undefined }
    | { isSuccess: false; data: undefined; errorMessage: string };

type MutationFunctionArgs<MutationFunction> = MutationFunction extends MutationTrigger<
    MutationDefinition<infer U, BaseQueryFn, string, any, string>
>
    ? U
    : never;

type MutationFunctionResponse<MutationFunction> = MutationFunction extends MutationTrigger<
    MutationDefinition<any, BaseQueryFn, string, infer U, string>
>
    ? U
    : never;

export const useMutationFunctionWrapper = <
    MutationFunction extends MutationTrigger<
        MutationDefinition<any, BaseQueryFn<any, unknown, FetchBaseQueryError, any, any>, string, any, string>
    >,
    Args extends MutationFunctionArgs<MutationFunction>,
    Response extends MutationFunctionResponse<MutationFunction>,
>(
    mutationFn: MutationFunction,
    errorMessage?: ErrorMessages,
) => {
    return async (args: Args): Promise<MutationState<Response>> => {
        const result = await mutationFn(args);
        if ("error" in result) {
            const mutationErrorMessage = getRtkQueryErrorMessage(result.error);
            // eslint-disable-next-line @typescript-eslint/no-console
            const errorMessage = JSON.stringify(mutationErrorMessage);
            return {
                isSuccess: false,
                data: undefined,
                errorMessage: errorMessage,
            };
        }
        return { isSuccess: true, data: result.data, errorMessage: undefined };
    };
};

export const getRtkQueryErrorMessage = (error: FetchBaseQueryError | SerializedError) => {
    if ("status" in error) {
        if (error.data) {
            return error.data;
        }
        return error.status;
    }
    return error.message;
};
