import { Button } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";

import styles from "./index.module.scss";
import { HourAndMinuteComponent } from "./HourAndMinute";
import { HourAndMinute } from "@/utils/DateUtils";

interface Props {
    open: boolean;
    anchorEl: HTMLButtonElement | null;
    newDeadlineTime: HourAndMinute;
    handleClose: () => void;
    confirmDeadlineTime: () => void;
    handleDeadlineTimeChange: (
        e: React.ChangeEvent<{
            name: string;
            value: number;
        }>,
    ) => void;
}

export const DeadlinePopover: React.VFC<Props> = (props) => {
    return (
        <Popover
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.handleClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            className={styles.timePopOver}
        >
            <div className={styles.popOverContents}>
                <div className={styles.startAndFinishWrapper}>
                    <div className={styles.titlesWrapper}>
                        <div className={styles.startTimeTitle}>締め切り時刻</div>
                    </div>
                    <div className={styles.selectsWrapper}>
                        <HourAndMinuteComponent
                            hourAndMinute={props.newDeadlineTime}
                            whichTimeString="startTime"
                            handleChange={props.handleDeadlineTimeChange}
                        />
                    </div>
                </div>
                <div className={styles.scheduleButtonsWrapper}>
                    <Button className={styles.scheduleCancelButton} onClick={props.handleClose}>
                        キャンセル
                    </Button>
                    <Button className={styles.scheduleConfirmButton} onClick={props.confirmDeadlineTime}>
                        確定
                    </Button>
                </div>
            </div>
        </Popover>
    );
};
