import { useState, useCallback, useEffect } from "react";

import styles from "@/pages/Student/Payment/index.module.scss";
import { ProposalResponse } from "@/store/autogenApi";

import { createTheme, Step, StepLabel, Stepper, ThemeProvider } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { PaymentInfoInputStep } from "./PaymentInfoInputStepContents";
import { ConfirmStepContents } from "./ConfirmStepContents";
import { IsError } from "@/components/IsError";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { getLevelAndCategory } from "@/utils/SubjectUtils";

interface Props {
    defaultPaymentMethod: any;
    proposal: ProposalResponse;
    pointsBalance: number;
}

const STEPS = ["支払い情報の入力", "支払い内容の確認", "完了"];

export const PaymentContents: React.VFC<Props> = (props) => {
    const [points, setPoints] = useState(0);
    const [hasOtherError, setHasOtherError] = useState(false);
    const [hasUpdatedError, setHasUpdatedError] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [isChecked, setIsChecked] = useState(false);
    const [targetPaymentMethod, setTargetPaymentMethod] = useState<any | undefined>(undefined);

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        const firstSubject = props.proposal.class?.course?.subjects[0];
        if (!firstSubject || !props.proposal.class?.course?.title) {
            updateNavigationList([
                {
                    title: "支払い",
                    url: `/Payment/${props.proposal.class?.classId}`,
                },
            ]);
            return;
        }
        updateNavigationList([
            {
                title: "講座検索",
                url: `/SearchResults?searchType=course`,
            },
            {
                title: getLevelAndCategory(firstSubject),
                url: `/SearchResults?searchType=course&level=${firstSubject.level}&category=${firstSubject.category}`,
            },
            {
                title: props.proposal.class?.course?.title,
                url: `/CourseDetails/${props.proposal.class?.course?.courseId}`,
            },
            {
                title: "支払い",
                url: `/Payment/${props.proposal?.class?.classId}`,
            },
        ]);
    }, [props.proposal]);

    const getIsPaymentMethodValid = useCallback(
        (paymentMethod: any) => {
            const courseType = props.proposal.courseType;
            if (!courseType) return false;
            return paymentMethod != undefined;
        },
        [props.proposal.courseType],
    );

    useEffect(() => {
        if (!props.defaultPaymentMethod) return;
        const isValid = getIsPaymentMethodValid(props.defaultPaymentMethod);
        if (!isValid) return;
        setTargetPaymentMethod(props.defaultPaymentMethod);
    }, [props.defaultPaymentMethod, getIsPaymentMethodValid]);

    const history = useHistory();

    const theme = createTheme({
        overrides: {
            MuiStepper: {
                root: {
                    padding: "10px",
                },
            },
            MuiStepIcon: {
                active: {
                    color: "#305077 !important",
                },
                completed: {
                    color: "#305077 !important",
                },
            },
            MuiStepLabel: {
                label: {
                    color: "#333 !important",
                    fontWeight: "bold !important" as "bold",
                    fontSize: "16px",
                    "@media (max-width: 960px)": {
                        fontSize: "14px",
                    },
                    "@media (max-width: 600px)": {
                        fontSize: "14px",
                    },
                },
            },
            MuiTypography: {
                body1: {
                    fontSize: "16px",
                    "@media (max-width: 960px)": {
                        fontSize: "14px",
                    },
                    "@media (max-width: 600px)": {
                        fontSize: "14px",
                    },
                },
            },
            MuiSvgIcon: {
                root: {
                    fontSize: "30px",
                    "@media (max-width: 960px)": {
                        fontSize: "25px",
                    },
                    "@media (max-width: 600px)": {
                        fontSize: "25px",
                    },
                },
            },
        },
    });

    const handleDetailsButtonClick = () => {
        history.replace(`/PublicRequestDetails/${props.proposal.publicRequest?.publicRequestId}`);
    };

    const handleHomeButtonClick = () => {
        history.replace("/");
    };

    const handleCheckButtonClick = useCallback(() => {
        setIsChecked(true);
        const isValid = getIsPaymentMethodValid(targetPaymentMethod);
        if (!isValid) return;
        if (targetPaymentMethod == undefined) return;
        setActiveStep(1);
        window.scrollTo(0, 0);
    }, [targetPaymentMethod, getIsPaymentMethodValid]);

    const handleBackButtonClick = useCallback(() => {
        setActiveStep(0);
    }, []);

    return (
        <div className={styles.paymentWrapper}>
            <div className={styles.paymentContentsWrapper}>
                {!hasUpdatedError && !hasOtherError && (
                    <ThemeProvider theme={theme}>
                        <Stepper alternativeLabel activeStep={activeStep}>
                            {STEPS.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </ThemeProvider>
                )}
                {activeStep === 0 && (
                    <PaymentInfoInputStep
                        proposal={props.proposal}
                        defaultPaymentMethod={props.defaultPaymentMethod}
                        pointsBalance={props.pointsBalance}
                        points={points}
                        targetPaymentMethod={targetPaymentMethod}
                        isChecked={isChecked}
                        setTargetPaymentMethod={setTargetPaymentMethod}
                        setPoints={setPoints}
                        handleCheckButtonClick={handleCheckButtonClick}
                    />
                )}
                {activeStep === 1 && (
                    <>
                        {hasUpdatedError ? (
                            <IsError
                                errorMessage="購入処理中に講座内容が更新されたため、購入処理がキャンセルされました。講座内容をご確認ください。"
                                buttonContent="講座リクエスト詳細"
                                handleButtonClick={handleDetailsButtonClick}
                            />
                        ) : (
                            <>
                                {hasOtherError ? (
                                    <IsError
                                        errorMessage="予期せぬエラーが発生しました。お手数ですが、もう一度お試しください。"
                                        buttonContent="講座リクエスト詳細"
                                        handleButtonClick={handleDetailsButtonClick}
                                    />
                                ) : (
                                    <ConfirmStepContents
                                        defaultPaymentMethod={props.defaultPaymentMethod}
                                        proposal={props.proposal}
                                        pointsBalance={props.pointsBalance}
                                        targetPaymentMethod={targetPaymentMethod}
                                        setHasOtherError={setHasOtherError}
                                        setHasUpdatedError={setHasUpdatedError}
                                        handleBackButtonClick={handleBackButtonClick}
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
