import { Button } from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/People";
import Rating from "@material-ui/lab/Rating";
import { GiCycle } from "react-icons/gi";
import styles from "@/pages/Common/CourseDetails/index.module.scss";
import { ReviewResponse } from "@/store/autogenApi";
import { memo } from "react";

interface Props {
    reviews: ReviewResponse[];
    totalPastCourseParticipants: number;
    reviewsRef: React.RefObject<HTMLDivElement>;
}

export const PastInfo: React.VFC<Props> = memo(function PastInfo(props) {
    const scrollToReviews = () => {
        const ref = props.reviewsRef.current;
        ref &&
            ref.scrollIntoView({
                behavior: "smooth",
                block: "end",
            });
    };

    const getCourseScore = () => {
        if (props.reviews) {
            if (props.reviews?.length === 0) {
                return 0;
            } else if (props.reviews?.length === 1) {
                return props.reviews[0].score;
            } else {
                const sum = props.reviews.reduce((acc, cur) => acc + (cur.score as number), 0);
                return sum / props.reviews.length;
            }
        } else {
            return 0;
        }
    };

    const getCourseAchievement = () => {
        return props.reviews?.length;
    };

    return (
        <div className={styles.pastInfo}>
            <Button
                className={styles.ratingButton}
                onClick={scrollToReviews}
                style={{ pointerEvents: props.reviewsRef.current ? "auto" : "none" }}
            >
                <div className={styles.evaluation}>
                    <Rating
                        name="half-rating-read"
                        value={getCourseScore()}
                        precision={0.5}
                        readOnly
                        className={styles.rating}
                        size="small"
                    />
                    <span className={styles.courseScore}>{`(${getCourseAchievement()})`}</span>
                </div>
                <div className={styles.review}>レビュー{props.reviews.length}件</div>
            </Button>
            <div className={styles.theNumberOfTimesWrapper}>
                {/* <StyledTooltip title="開講回数" arrow> */}
                <div className={styles.theNumberOfTimesHeld}>
                    <GiCycle className={styles.icon} />
                    <span className={styles.courseAchievementTitle}>開講回数：</span>
                    <span className={styles.courseAchievement}>{getCourseAchievement()}回</span>
                </div>
                {/* </StyledTooltip> */}
                <div className={styles.theNumberOfParticipants}>
                    <PeopleIcon className={styles.icon} />
                    <span className={styles.totalPastCourseParticipantsTitle}>受講人数：</span>
                    <span className={styles.totalPastCourseParticipants}>{props.totalPastCourseParticipants}人</span>
                </div>
            </div>
        </div>
    );
});
