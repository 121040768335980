import { memo, useCallback, useEffect, useRef, useState } from "react";

import { Button, Hidden, useMediaQuery } from "@material-ui/core";
import queryString from "query-string";
import { RiFocus3Line } from "react-icons/ri";

import { LoadingProgress } from "@/components/LoadingProgress";
import styles from "@/pages/Common/TeacherSearchResults/index.module.scss";
import { CourseResponse, OrderBy, PublicTeacherResponse } from "@/store/autogenApi";

import { NarrowDown } from "@/components/NarrowDown";
import { EducationalBackGroundQueries, SubjectQueries } from "@/pages/Common/TeacherSearchResults";
import { TeacherCard } from "@/components/TeacherCard";
import { PageNumbers } from "@/components/PageNumbers";
import { Sort } from "@/components/Sort";

interface Props {
    rankNumber: number;
    targetSubject: SubjectQueries;
    targetEducationalBackground: EducationalBackGroundQueries;
    searchResults: PublicTeacherResponse[];
    isLoaded: boolean;
    query: queryString.ParsedQuery<string>;
    isConfirmed: boolean;
    keyword: string;
    pageNumber: number;
    orderBy: OrderBy;
    existCondition: boolean;
    setPageNumber: React.Dispatch<React.SetStateAction<number>>;
    setKeyword: React.Dispatch<React.SetStateAction<string>>;
    setIsConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    setTargetSubject: React.Dispatch<React.SetStateAction<SubjectQueries>>;
    setRank: React.Dispatch<React.SetStateAction<number>>;
    setTargetEducationalBackground: React.Dispatch<React.SetStateAction<EducationalBackGroundQueries>>;
    search: () => void;
    getSubjectCondition: () => string;
    getRankCondition: (
        rankNumber: number,
    ) => "レギュラー以上" | "ブロンズ以上" | "シルバー以上" | "ゴールド以上" | "プラチナ" | "-" | undefined;
    getBelongingCondition: () => string;
    deleteKeywordCondition: () => void;
    deleteSubjectCondition: () => void;
    deleteRankCondition: () => void;
    deleteBelongingCondition: () => void;
    handleClearButtonClick: () => void;
    handleOrderByChange: (
        e: React.ChangeEvent<{
            value: string;
        }>,
    ) => void;
}

export const TeacherSearchResultComponent: React.VFC<Props> = memo(function TeacherSearchResultComponent(props) {
    const [repetitionNumber, setRepetitionNumber] = useState<number>(0);
    const [isOver, setIsOver] = useState<boolean>(false);
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    const searchResultPageEl: React.MutableRefObject<HTMLElement | null | undefined> = useRef();

    const searchResultPageRef = useCallback((node: HTMLElement | null) => {
        searchResultPageEl.current = node;
    }, []);

    // useEffect(() => {
    //     const scrollWidth = searchResultPageEl.current?.scrollWidth;
    //     console.log(scrollWidth);
    //     if (scrollWidth && scrollWidth === 1200) {
    //         setIsOver(true);
    //     }
    // }, [searchResultPageEl]);

    useEffect(() => {
        changeIsOver();
        window.addEventListener("resize", () => {
            changeIsOver();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeIsOver();
            });
        };
    }, []);

    const changeIsOver = useCallback(() => {
        const scrollWidth = searchResultPageEl.current?.scrollWidth;
        if (scrollWidth && scrollWidth === 1200 && !isOver) {
            setIsOver(true);
        } else if (scrollWidth && scrollWidth < 1200 && isOver) {
            setIsOver(false);
        }
    }, [isOver]);

    const xsUp = useMediaQuery("(min-width:600px)");
    const mdUp = useMediaQuery("(min-width:1200px)");

    const smallThresholdUp = useMediaQuery("(min-width:600px)");
    const largeThresholdUp = useMediaQuery("(min-width:1000px)");

    useEffect(() => {
        if (!xsUp) {
            setRepetitionNumber(3);
            return;
        }
        if (!mdUp) {
            setRepetitionNumber(5);
            return;
        }
        setRepetitionNumber(6);
    }, [xsUp, mdUp]);

    const handleNarrowDownButtonClick = useCallback(() => {
        setDrawerOpen(true);
    }, []);

    const handleDrawerClose = useCallback(() => {
        setDrawerOpen(false);
    }, []);

    const getConditionsCount = useCallback(() => {
        let count = 0;
        if (props.targetSubject.level) {
            count++;
        }
        if (props.rankNumber) {
            count++;
        }
        if (props.targetEducationalBackground.university) {
            count++;
        }
        return count;
    }, [props.targetSubject, props.rankNumber, props.targetEducationalBackground]);

    const thresholdNumber = largeThresholdUp ? 10 : smallThresholdUp ? 8 : 6;

    return (
        <div className={styles.searchResultPage} ref={searchResultPageRef}>
            <Hidden smDown>
                <NarrowDown
                    rankNumber={props.rankNumber}
                    targetSubject={props.targetSubject}
                    targetEducationalBackground={props.targetEducationalBackground}
                    isConfirmed={props.isConfirmed}
                    keyword={props.keyword}
                    searchResultPageEl={searchResultPageEl}
                    setKeyword={props.setKeyword}
                    setIsConfirmed={props.setIsConfirmed}
                    search={props.search}
                    setRank={props.setRank}
                    setTargetSubject={props.setTargetSubject}
                    getRankCondition={props.getRankCondition}
                    handleClearButtonClick={props.handleClearButtonClick}
                    setTargetEducationalBackground={props.setTargetEducationalBackground}
                />
            </Hidden>
            <div className={styles.searchResultsWrapper}>
                <div className={styles.searchResultsContents}>
                    <div className={styles.searchResultsMiddleWrapper}>
                        <div
                            className={styles.searchResultsInfoWrapper}
                            style={{ alignItems: props.existCondition ? "flex-end" : "flex-start" }}
                        >
                            {props.existCondition ? (
                                <div className={styles.searchConditionsWrapper}>
                                    {props.query.keyword && (
                                        <div className={styles.condition}>
                                            <div className={styles.title}>キーワード：</div>
                                            <div className={styles.conditionContents}>{props.query.keyword}</div>
                                            <Button
                                                className={styles.conditionDeleteButton}
                                                onClick={props.deleteKeywordCondition}
                                            >
                                                ×
                                            </Button>
                                        </div>
                                    )}
                                    {props.query.level && (
                                        <div className={styles.condition}>
                                            <div className={styles.title}>対応可能科目：</div>
                                            <div className={styles.conditionContents}>
                                                {props.getSubjectCondition()}
                                            </div>
                                            <Button
                                                className={styles.conditionDeleteButton}
                                                onClick={props.deleteSubjectCondition}
                                            >
                                                ×
                                            </Button>
                                        </div>
                                    )}
                                    {!Number.isNaN(Number(props.query.rankNumber)) &&
                                        Number(props.query.rankNumber) != 0 && (
                                            <div className={styles.condition}>
                                                <div className={styles.title}>ランク：</div>
                                                <div className={styles.conditionContents}>
                                                    {props.getRankCondition(Number(props.query.rankNumber))}
                                                </div>
                                                <Button
                                                    className={styles.conditionDeleteButton}
                                                    onClick={props.deleteRankCondition}
                                                >
                                                    ×
                                                </Button>
                                            </div>
                                        )}
                                    {props.query.university && (
                                        <div className={styles.condition}>
                                            <div className={styles.title}>在・卒大学：</div>
                                            <div className={styles.conditionContents}>
                                                {props.getBelongingCondition()}
                                            </div>
                                            <Button
                                                className={styles.conditionDeleteButton}
                                                onClick={props.deleteBelongingCondition}
                                            >
                                                ×
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <h1 className={styles.searchResultTitle}>全ての先生</h1>
                            )}

                            <div className={styles.searchResultsInfoRightWrapper}>
                                <Sort
                                    value={props.orderBy}
                                    orderSets={[
                                        { value: "latest", label: "新着順" },
                                        { value: "rank", label: "ランク順" },
                                        { value: "averageReviewScore", label: "平均スコア順" },
                                    ]}
                                    handleChange={props.handleOrderByChange}
                                />
                                {props.isLoaded && props.searchResults.length > 0 && (
                                    <div className={styles.howManySearchResults}>
                                        {props.searchResults.length}件中 {(props.pageNumber - 1) * 50 + 1} ~{" "}
                                        {props.pageNumber * 50 < props.searchResults.length
                                            ? props.pageNumber * 50
                                            : props.searchResults.length}
                                        件 を表示
                                    </div>
                                )}
                            </div>
                        </div>
                        <Hidden mdUp>
                            <div className={styles.narrowDownButtonWrapper}>
                                <Button className={styles.narrowDownButton} onClick={handleNarrowDownButtonClick}>
                                    <RiFocus3Line className={styles.icon} />
                                    <div className={styles.narrowDownTitle}>絞り込み({getConditionsCount()})</div>
                                </Button>
                                <NarrowDown
                                    drawerOpen={drawerOpen}
                                    rankNumber={props.rankNumber}
                                    targetEducationalBackground={props.targetEducationalBackground}
                                    keyword={props.keyword}
                                    searchResultPageEl={searchResultPageEl}
                                    setKeyword={props.setKeyword}
                                    search={props.search}
                                    targetSubject={props.targetSubject}
                                    isConfirmed={props.isConfirmed}
                                    setIsConfirmed={props.setIsConfirmed}
                                    setRank={props.setRank}
                                    setTargetSubject={props.setTargetSubject}
                                    handleClose={handleDrawerClose}
                                    handleClearButtonClick={props.handleClearButtonClick}
                                    getRankCondition={props.getRankCondition}
                                    setTargetEducationalBackground={props.setTargetEducationalBackground}
                                />
                            </div>
                        </Hidden>
                    </div>
                    <LoadingProgress loadingComplete={props.isLoaded}>
                        <>
                            {props.isLoaded && (
                                <>
                                    {repetitionNumber && (
                                        <div className={styles.cardsWrapper}>
                                            {props.searchResults.length === 0 ? (
                                                <div className={styles.emptyCourses}>該当する先生がいません。</div>
                                            ) : (
                                                <>
                                                    {[
                                                        ...Array(
                                                            Math.ceil(
                                                                props.searchResults.slice(
                                                                    (props.pageNumber - 1) * 50,
                                                                    (props.pageNumber - 1) * 50 + 50,
                                                                ).length / repetitionNumber,
                                                            ),
                                                        ),
                                                    ].map((_, idx1, self) => {
                                                        const shortage =
                                                            (repetitionNumber -
                                                                (props.searchResults.slice(
                                                                    (props.pageNumber - 1) * 50,
                                                                    (props.pageNumber - 1) * 50 + 50,
                                                                ).length %
                                                                    repetitionNumber)) %
                                                            repetitionNumber;
                                                        return (
                                                            <div className={styles.cards} key={idx1}>
                                                                {props.searchResults
                                                                    .slice(
                                                                        (props.pageNumber - 1) * 50,
                                                                        (props.pageNumber - 1) * 50 + 50,
                                                                    )
                                                                    .filter(
                                                                        (_, idx2) =>
                                                                            idx1 * repetitionNumber <= idx2 &&
                                                                            idx2 < (idx1 + 1) * repetitionNumber,
                                                                    )
                                                                    .map((searchResult) => (
                                                                        <TeacherCard
                                                                            key={searchResult.teacherId}
                                                                            teacher={searchResult}
                                                                            isFlex
                                                                        />
                                                                    ))}
                                                                {idx1 === self.length - 1 &&
                                                                    [...Array(shortage)].map((_, idx) => {
                                                                        return (
                                                                            <TeacherCard key={idx} isHidden isFlex />
                                                                        );
                                                                    })}
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                            {props.searchResults.length > 50 && (
                                <PageNumbers
                                    pageNumber={props.pageNumber}
                                    setPageNumber={props.setPageNumber}
                                    items={props.searchResults}
                                    itemsPerPage={50}
                                    thresholdNumber={thresholdNumber}
                                />
                            )}
                        </>
                    </LoadingProgress>
                </div>
            </div>
        </div>
    );
});
