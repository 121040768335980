import { Button, createTheme, MuiThemeProvider, Tab, Tabs, useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";

import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { RequestListBase } from "@/pageComponents/Teacher/MyPage/RequestListBase";
import { useGetFavoriteRequestsQuery } from "@/store/hooks/teachers";

import { MyPage } from ".";
import { RootState } from "@/ducks";
import { LoadingProgress } from "@/components/LoadingProgress";
import { useCallback, useEffect, useState } from "react";

import styles from "./index.module.scss";
import { CourseType, PublicRequestResponse } from "@/store/autogenApi";
import { TabContents } from "@/pageComponents/Teacher/MyPage/TabContents";

import queryString from "query-string";
import { useHistory } from "react-router";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { Navigation } from "@/components/Navigation";

const theme = createTheme({
    overrides: {
        MuiTabs: {
            root: {
                color: "#305077",
                paddingLeft: 0,
                boxShadow: "none !important",
            },
            indicator: {
                backgroundColor: "#305077",
            },
        },
        MuiTab: {
            root: {
                opacity: 0.7,
                fontWeight: "bold",
                "&$selected": {
                    opacity: 1,
                    pointerEvents: "none",
                    color: "#305077",
                    "&:hover": {
                        color: "#305077",
                    },
                },
            },
        },
    },
});

const COURSE_TYPES: CourseType[] = ["regular", "short"];

const NAVIGATION_LIST: Navigation[] = [
    { title: "マイページ", url: "/Teacher/MyPage/Profile" },
    { title: "気になる講座リクエスト", url: "/Teacher/MyPage/Favorite" },
];

export const TeacherFavorite: React.VFC = () => {
    const [courseType, setCourseType] = useState<CourseType>("regular");
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);
    const favoriteRequestsQueryState = useGetFavoriteRequestsQuery(teacherId);

    const sm = useMediaQuery("(min-width:600px)");
    const md = useMediaQuery("(min-width:960px)");
    const lg = useMediaQuery("(min-width:1280px)");
    const xl = useMediaQuery("(min-width:1536px)");

    const query = queryString.parse(window.location.search);
    const history = useHistory();

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    useEffect(() => {
        if (query.courseType) {
            setCourseType(query.courseType as CourseType);
        }
    }, [query]);

    const getLabel = useCallback((courseType: CourseType, requests: PublicRequestResponse[]) => {
        const targetRequests = requests.filter((course) => course.courseType === courseType);
        switch (courseType) {
            case "regular":
                return `定期(${targetRequests.length})`;
            case "short":
                return `単発・短期(${targetRequests.length})`;
        }
    }, []);

    const handleChange = useCallback((event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
        switch (newValue) {
            case 0:
                history.push("/Teacher/MyPage/Favorite?courseType=regular");
                break;
            case 1:
                history.push("/Teacher/MyPage/Favorite?courseType=short");
                break;
        }
    }, []);
    return (
        <MyPage title="気になる講座リクエスト" activeTab="favorite">
            <QueryLoadingWrapper {...favoriteRequestsQueryState}>
                {(requests) => (
                    <div className={styles.requestsWrapper}>
                        <div className={styles.tabsWrapper}>
                            <MuiThemeProvider theme={theme}>
                                <Tabs value={COURSE_TYPES.indexOf(courseType)} onChange={handleChange}>
                                    {COURSE_TYPES.map((courseType) => (
                                        <Tab label={getLabel(courseType, requests)} />
                                    ))}
                                </Tabs>
                            </MuiThemeProvider>
                        </div>
                        <div className={styles.tabContentsWrapper}>
                            {COURSE_TYPES.map((targetCourseType) => (
                                <TabContents
                                    visible={targetCourseType === courseType}
                                    requests={requests.filter((request) => request.courseType === targetCourseType)}
                                    repetition={xl ? 6 : lg ? 6 : md ? 6 : sm ? 5 : 3}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </QueryLoadingWrapper>
        </MyPage>
    );
};
