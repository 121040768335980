import { Button } from "@material-ui/core";

import styles from "./index.module.scss";

interface Props {
    handleClick: () => void;
    IconJSX: JSX.Element;
    itemTitle: string;
    borderBottom: boolean;
}

export const LinkButtonTemplate: React.VFC<Props> = (props) => {
    return (
        <Button
            onClick={props.handleClick}
            className={styles.itemButton}
            style={{ borderBottom: props.borderBottom ? "1px solid #305077" : "none" }}
        >
            {props.IconJSX}
            <div className={styles.itemTitle}>{props.itemTitle}</div>
        </Button>
    );
};
