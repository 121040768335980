import {
    CourseResponse,
    CourseType,
    ClassResponse,
    PrivateStudentResponse,
    PublicStudentResponse,
    NominalTypingAnnouncementString,
} from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions } from "./utils/types";

export const useGetPublicStudentQuery = (studentId: string) => {
    const result = defaultApi.endpoints.getPublicStudent.useQuery({ studentId: studentId });
    return useQueryResult(result, (response: PublicStudentResponse) => response);
};

export const useGetPrivateStudentQuery = (studentId: string) => {
    const result = defaultApi.endpoints.getPrivateStudent.useQuery({ studentId: studentId });
    return useQueryResult(result, (response: PrivateStudentResponse) => response);
};

export const useGetReservedClassesQuery = (studentId: string) => {
    const result = defaultApi.endpoints.getReservedClasses.useQuery({ studentId: studentId });
    return useQueryResult(result, (response: ClassResponse[]) => response);
};

export const useGetPastCoursesQuery = (studentId: string) => {
    const result = defaultApi.endpoints.getPastCourses.useQuery({ studentId: studentId });
    return useQueryResult(result, (response: CourseResponse[]) => response);
};

export const useGetAllFavoriteCoursesQuery = (studentId: string) => {
    const result = defaultApi.endpoints.getAllFavoriteCourses.useQuery({ studentId: studentId });
    return useQueryResult(result, (response: CourseResponse[]) => response);
};

export const useGetFavoriteCoursesQuery = (studentId: string, courseType?: CourseType) => {
    const result = defaultApi.endpoints.getFavoriteCourses.useQuery({ studentId, courseType });
    return useQueryResult(result, (response: CourseResponse[]) => response);
};

export const useGetCourseBrowsingHistoryQuery = (studentId: string, courseType?: CourseType) => {
    const result = defaultApi.endpoints.getCourseBrowsingHistory.useQuery({
        studentId,
        courseType,
    });
    return useQueryResult(result, (response: CourseResponse[]) => response);
};

export const useGetStudentCheckedAnnouncementIdsQuery = (studentId: string) => {
    const result = defaultApi.endpoints.getStudentCheckedAnnouncementIds.useQuery({
        studentId: studentId,
    });
    return useQueryResult(result, (response: NominalTypingAnnouncementString[]) => response);
};

export const useUpdateStudentCheckedAnnouncementsMutation = (options?: UseMutationOptions) => {
    const [updateStudentCheckedAnnouncements] = defaultApi.endpoints.updateStudentCheckedAnnouncements.useMutation();
    return useMutationFunctionWrapper(updateStudentCheckedAnnouncements, options?.errorMessages);
};

export const useCreateStudentMutation = (options?: UseMutationOptions) => {
    const [createStudent] = defaultApi.endpoints.createStudent.useMutation();
    return useMutationFunctionWrapper(createStudent, options?.errorMessages);
};

export const useUpdateStudentMutation = (options?: UseMutationOptions) => {
    const [updateStudent] = defaultApi.endpoints.updateStudent.useMutation();
    return useMutationFunctionWrapper(updateStudent, options?.errorMessages);
};

export const useUpdateFavoriteCoursesMutation = (options?: UseMutationOptions) => {
    const [updateFavoriteCourses] = defaultApi.endpoints.updateFavoriteCourses.useMutation();
    return useMutationFunctionWrapper(updateFavoriteCourses, options?.errorMessages);
};

export const useUpdateCourseBrowsingHistoryMutation = (options?: UseMutationOptions) => {
    const [updateCourseBrowsingHistory] = defaultApi.endpoints.updateCourseBrowsingHistory.useMutation();
    return useMutationFunctionWrapper(updateCourseBrowsingHistory, options?.errorMessages);
};

export const useDeleteStudentMutation = (options?: UseMutationOptions) => {
    const [deleteStudent] = defaultApi.endpoints.deleteStudent.useMutation();
    return useMutationFunctionWrapper(deleteStudent, options?.errorMessages);
};
