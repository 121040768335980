import { useParams } from "react-router";
import { useGetClassQuery } from "@/store/hooks/classes";

import { ClassContents } from "@/pageComponents/Student/Class";
import { memo } from "react";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";

export const Class: React.VFC = memo(function Class() {
    const { classId } = useParams<{ classId: string }>();
    const classQueryState = useGetClassQuery(classId);

    return (
        <QueryLoadingWrapper {...classQueryState}>
            {(classResponse) => <ClassContents class={classResponse} />}
        </QueryLoadingWrapper>
    );
});
