import { memo, useCallback, useEffect, useState } from "react";
import { Button, Hidden } from "@material-ui/core";
import styles from "../../index.module.scss";
import { SelectTemplate } from "./SelectTemplate";
import { EducationalBackgroundResponse } from "@/store/autogenApi";
import { EducationalBackGroundQueries } from "@/pages/Common/CourseSearchResults";

interface Props {
    educationalBackgrounds: EducationalBackgroundResponse[];
    targetEducationalBackground: EducationalBackGroundQueries;
    handleIsConfirmedChange: () => void;
    handleBelongingNarrowDownButtonClick: () => void;
    setTargetEducationalBackground: React.Dispatch<React.SetStateAction<EducationalBackGroundQueries>>;
}

export const UniversitiesSearchContents: React.VFC<Props> = memo(function UniversitiesSearchContents(props) {
    const [modalHeight, setModalHeight] = useState<number>(0);
    const [universities, setUniversities] = useState<string[]>([]);
    const [faculties, setFaculties] = useState<string[]>([]);
    const [departments, setDepartments] = useState<string[]>([]);
    const [educationalBackgrounds, setEducationalBackgrounds] = useState<EducationalBackgroundResponse[]>([]);

    const changeModalHeight = useCallback(() => {
        const innerHeight = window.innerHeight;
        setModalHeight(innerHeight * 0.75);
    }, []);

    useEffect(() => {
        changeModalHeight();
        window.addEventListener("resize", () => {
            changeModalHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalHeight();
            });
        };
    }, []);

    useEffect(() => {
        if (!props.educationalBackgrounds) return;
        setEducationalBackgrounds(props.educationalBackgrounds);
        const onlyUniversities = props.educationalBackgrounds.map(
            (educationalBackground) => educationalBackground.university,
        );
        const uniqueUniversities = Array.from(new Set(onlyUniversities));
        setUniversities(uniqueUniversities);
    }, [props.educationalBackgrounds]);

    const handleFacultyChange = useCallback(
        (
            e: React.ChangeEvent<{
                value: unknown;
            }>,
        ) => {
            const faculty = e.target.value as string;
            props.setTargetEducationalBackground({
                ...props.targetEducationalBackground,
                faculty: faculty,
                department: "",
            });
            const targetDepartment = educationalBackgrounds
                .filter(
                    (educationalBackground) =>
                        educationalBackground.university &&
                        props.targetEducationalBackground.university &&
                        educationalBackground.university === props.targetEducationalBackground.university &&
                        educationalBackground.faculty &&
                        educationalBackground.faculty === faculty,
                )
                .map((educationalBackground) => educationalBackground.department) as string[];
            setDepartments(targetDepartment);
        },
        [educationalBackgrounds, props.setTargetEducationalBackground, props.targetEducationalBackground],
    );

    const handleDepartmentChange = useCallback(
        (
            e: React.ChangeEvent<{
                value: unknown;
            }>,
        ) => {
            const department = e.target.value as string;
            props.setTargetEducationalBackground({ ...props.targetEducationalBackground, department: department });
        },
        [props.setTargetEducationalBackground, props.targetEducationalBackground],
    );

    const handleUniversityButtonClick = useCallback(
        (university: string) => {
            props.setTargetEducationalBackground({
                university: university,
                faculty: "",
                department: "",
            });
            const targetFaculties = educationalBackgrounds
                .filter(
                    (educationalBackground) =>
                        educationalBackground.university && educationalBackground.university === university,
                )
                .map((educationalBackground) => educationalBackground.faculty);
            const set = new Set(targetFaculties);
            const uniqueFaculties = Array.from(set) as string[];
            uniqueFaculties.sort();
            setFaculties(uniqueFaculties);
        },
        [educationalBackgrounds],
    );

    return (
        <>
            {universities.length > 0 ? (
                <>
                    {props.targetEducationalBackground.university ? (
                        <div className={styles.targetEducationalBackgroundWrapper}>
                            {/* <Button onClick={searchAgain}>再検索</Button> */}
                            <div className={styles.centerWrapper}>
                                <div className={styles.university}>
                                    大学：{props.targetEducationalBackground.university}
                                </div>
                                {props.targetEducationalBackground.faculty != undefined && (
                                    <SelectTemplate
                                        title="学部"
                                        educationalBackgroundKey="faculty"
                                        value={props.targetEducationalBackground.faculty}
                                        items={faculties}
                                        handleChange={handleFacultyChange}
                                    />
                                )}
                                {props.targetEducationalBackground.department != undefined && (
                                    <SelectTemplate
                                        title="学科"
                                        educationalBackgroundKey="department"
                                        value={props.targetEducationalBackground.department}
                                        items={departments}
                                        handleChange={handleDepartmentChange}
                                    />
                                )}
                            </div>
                            <Hidden lgUp>
                                <Button
                                    className={styles.targetEducationalBackgroundNarrowDownButton}
                                    onClick={props.handleIsConfirmedChange}
                                >
                                    確定
                                </Button>
                            </Hidden>
                            <Hidden mdDown>
                                <Button
                                    className={styles.targetEducationalBackgroundNarrowDownButton}
                                    onClick={props.handleBelongingNarrowDownButtonClick}
                                >
                                    絞り込み
                                </Button>
                            </Hidden>
                        </div>
                    ) : (
                        <div className={styles.universities} style={{ maxHeight: `${modalHeight}px` }}>
                            {universities.map((university) => {
                                return (
                                    <Button
                                        className={styles.universityButton}
                                        key={university}
                                        onClick={() => {
                                            handleUniversityButtonClick(university);
                                        }}
                                    >
                                        {university}
                                    </Button>
                                );
                            })}
                        </div>
                    )}
                </>
            ) : (
                <div className={styles.emptyUniversities}>該当する大学がありません。</div>
            )}
        </>
    );
});
