import { Badge, Button, Hidden, List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import FavoriteIcon from "@material-ui/icons/Favorite";
import HistoryIcon from "@material-ui/icons/History";
import NotificationIcon from "@material-ui/icons/Notifications";
import MailIcon from "@material-ui/icons/Mail";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { AiFillSetting, AiOutlineBank } from "react-icons/ai";
import { BiCoinStack } from "react-icons/bi";
import { RiFileList2Line, RiMoneyCnyBoxLine } from "react-icons/ri";
import { VscTriangleRight } from "react-icons/vsc";
import { TbPigMoney } from "react-icons/tb";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import styles from "@/pages/Teacher/MyPage/index.module.scss";
import { RootState } from "@/ducks";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { useGetTeacherCheckedAnnouncementIdsQuery } from "@/store/hooks/teachers";
import { useGetTeacherAnnouncementsQuery } from "@/store/hooks/announcements";
import { useGetTeacherChatsQueryState } from "@/store/hooks/chats";
import { getTeacherHowManyUnreadMessages } from "@/utils/ChatUtils";
import { DraftIcon, LightBulbIcon } from "@/globalIcons";

interface Props {
    fontSize: "small" | "medium";
    setUpdated?: (value: boolean) => void;
    setEdit?: (value: boolean) => void;
    activeTab: string;
}

export const MenuBar: React.VFC<Props> = (props) => {
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);

    const useStyles = makeStyles(() => ({
        badge: {
            color: "#FFF",
            backgroundColor: "#C09547",
        },
    }));
    const style = useStyles();

    const history = useHistory();

    const teacherChatsQueryState = useGetTeacherChatsQueryState(teacherId);
    const teacherCheckedAnnouncementIdsQueryState = useGetTeacherCheckedAnnouncementIdsQuery(teacherId);
    const announcementsQueryState = useGetTeacherAnnouncementsQuery(teacherId);

    const handleCreateCourseButtonClick = () => {
        history.push("/CreateCourse");
    };
    return (
        <>
            <div className={styles.gridMenu}>
                <div className={styles.menuBar}>
                    <List className={styles.menuList}>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "profile"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button component={Link} to="/Teacher/MyPage/Profile" className={styles.menuLink}>
                                <AssignmentIndIcon className={styles.icon} />
                                <ListItemText className={styles.itemTitle}>プロフィール</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "profile" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "history"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button component={Link} to="/Teacher/MyPage/History" className={styles.menuLink}>
                                <HistoryIcon className={styles.icon} />
                                <ListItemText className={styles.itemTitle}>閲覧履歴(講座リクエスト)</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "history" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "favorite"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button component={Link} to="/Teacher/MyPage/Favorite" className={styles.menuLink}>
                                <FavoriteIcon className={styles.icon} />
                                <ListItemText className={styles.itemTitle}>気になる講座リクエスト</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "favorite" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "announcements"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button component={Link} to="/Teacher/MyPage/Announcements" className={styles.menuLink}>
                                <QueryLoadingWrapper
                                    {...announcementsQueryState}
                                    loadingComponent={
                                        <Badge
                                            badgeContent={0}
                                            className={styles.badge}
                                            classes={{ badge: style.badge }}
                                            overlap="rectangular"
                                        >
                                            <NotificationIcon className={styles.icon} />
                                        </Badge>
                                    }
                                >
                                    {(announcements) => (
                                        <QueryLoadingWrapper
                                            {...teacherCheckedAnnouncementIdsQueryState}
                                            loadingComponent={
                                                <Badge
                                                    badgeContent={0}
                                                    className={styles.badge}
                                                    classes={{ badge: style.badge }}
                                                    overlap="rectangular"
                                                >
                                                    <NotificationIcon className={styles.icon} />
                                                </Badge>
                                            }
                                        >
                                            {(checkedAnnouncementIds) => {
                                                const howManyUnreadAnnouncements = announcements.filter(
                                                    (announcement) =>
                                                        !checkedAnnouncementIds.includes(announcement.announcementId),
                                                ).length;
                                                return (
                                                    <Badge
                                                        badgeContent={howManyUnreadAnnouncements}
                                                        className={styles.badge}
                                                        classes={{ badge: style.badge }}
                                                        overlap="rectangular"
                                                    >
                                                        <NotificationIcon className={styles.icon} />
                                                    </Badge>
                                                );
                                            }}
                                        </QueryLoadingWrapper>
                                    )}
                                </QueryLoadingWrapper>
                                <ListItemText className={styles.itemTitle}>お知らせ</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "announcements" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "chats"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button component={Link} to="/Teacher/MyPage/Chats" className={styles.menuLink}>
                                <QueryLoadingWrapper
                                    {...teacherChatsQueryState}
                                    loadingComponent={
                                        <Badge
                                            badgeContent={0}
                                            className={styles.badge}
                                            classes={{ badge: style.badge }}
                                            overlap="rectangular"
                                        >
                                            <MailIcon className={styles.icon} />
                                        </Badge>
                                    }
                                >
                                    {(chats) => {
                                        const howManyUnreadMessages = getTeacherHowManyUnreadMessages(chats, teacherId);
                                        return (
                                            <Badge
                                                badgeContent={howManyUnreadMessages}
                                                className={styles.badge}
                                                classes={{ badge: style.badge }}
                                                overlap="rectangular"
                                            >
                                                <MailIcon className={styles.icon} />
                                            </Badge>
                                        );
                                    }}
                                </QueryLoadingWrapper>
                                <ListItemText className={styles.itemTitle}>チャット一覧</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "chats" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "myCourses"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button component={Link} to="/Teacher/MyPage/MyCourses" className={styles.menuLink}>
                                <RiFileList2Line className={styles.icon} />
                                <ListItemText className={styles.itemTitle}>My講座一覧</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "myCourses" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "myDraftCourses"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button component={Link} to="/Teacher/MyPage/MyDraftCourses" className={styles.menuLink}>
                                <DraftIcon className={styles.icon} />
                                <ListItemText className={styles.itemTitle}>下書き講座一覧</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "myDraftCourses" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "myProposals"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button component={Link} to="/Teacher/MyPage/MyProposals" className={styles.menuLink}>
                                <LightBulbIcon className={styles.icon} />
                                <ListItemText className={styles.itemTitle}>提案一覧</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "myProposals" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "lessonCalendar"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button component={Link} to="/Teacher/MyPage/LessonCalendar" className={styles.menuLink}>
                                <CalendarMonthIcon className={styles.icon} />
                                <ListItemText className={styles.itemTitle}>授業カレンダー</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "lessonCalendar" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "bankAccountSetting"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button
                                component={Link}
                                to="/Teacher/MyPage/BankAccountSetting"
                                className={styles.menuLink}
                            >
                                <AiOutlineBank className={styles.icon} />
                                <ListItemText className={styles.itemTitle}>振込用銀行口座設定</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "bankAccountSetting" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "applicationForTransfer"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button
                                component={Link}
                                to="/Teacher/MyPage/ApplicationForTransfer"
                                className={styles.menuLink}
                            >
                                <RiMoneyCnyBoxLine className={styles.icon} />
                                <ListItemText className={styles.itemTitle}>振込申請</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "applicationForTransfer" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "proceedsRecords"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button component={Link} to="/Teacher/MyPage/ProceedsRecords" className={styles.menuLink}>
                                <TbPigMoney className={styles.icon} />
                                <ListItemText className={styles.itemTitle}>売上金履歴</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "proceedsRecordHistory" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={
                                props.activeTab === "pointsRecordHistory"
                                    ? { backgroundColor: "#EAEAEA", pointerEvents: "none" }
                                    : { backgroundColor: "FFF" }
                            }
                        >
                            <Button
                                component={Link}
                                to="/Teacher/MyPage/PointsRecordHistory"
                                className={styles.menuLink}
                            >
                                <BiCoinStack className={styles.icon} />
                                <ListItemText className={styles.itemTitle}>ポイント履歴</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "pointsRecordHistory" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                        <ListItem
                            button
                            className={styles.menuItem}
                            style={{
                                borderBottom: "none",
                                backgroundColor: props.activeTab === "setting" ? "#EAEAEA" : "FFF",
                            }}
                        >
                            <Button component={Link} to="/Teacher/MyPage/Setting" className={styles.menuLink}>
                                <AiFillSetting className={styles.icon} />
                                <ListItemText className={styles.itemTitle}>設定</ListItemText>
                                {props.fontSize === "small" && (
                                    <ArrowForwardIosIcon className={styles.arrow} fontSize={props.fontSize} />
                                )}
                                <Hidden smDown>
                                    {props.activeTab === "setting" && (
                                        <VscTriangleRight className={styles.triangleIcon} />
                                    )}
                                </Hidden>
                            </Button>
                        </ListItem>
                    </List>
                </div>
                <Button className={styles.createCourseButton} onClick={handleCreateCourseButtonClick}>
                    <AddIcon className={styles.createCourseIcon} />
                    <div className={styles.createCourse}>講座作成</div>
                </Button>
            </div>
        </>
    );
};
