import { StudentToDoResponse } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions } from "./utils/types";

export const useGetStudentToDosByStudentIdQuery = (studentId: string) => {
    const result = defaultApi.endpoints.getStudentToDos.useQuery({ studentId: studentId });
    return useQueryResult(result, (response: StudentToDoResponse[]) => response);
};

export const useCreateStudentToDoMutation = (options?: UseMutationOptions) => {
    const [createStudentToDo] = defaultApi.endpoints.createStudentToDo.useMutation();
    return useMutationFunctionWrapper(createStudentToDo, options?.errorMessages);
};

export const useCompleteStudentToDoMutation = (options?: UseMutationOptions) => {
    const [completeStudentToDo] = defaultApi.endpoints.completeStudentToDo.useMutation();
    return useMutationFunctionWrapper(completeStudentToDo, options?.errorMessages);
};
