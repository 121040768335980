import {
    UNIVERSITY_SUBJECT_CONFIG,
    HIGH_SCHOOL_SUBJECT_CONFIG,
    JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG,
    ELEMENTARY_SCHOOL_SUBJECT_CONFIG,
} from "@/SubjectConfig";

import { SchoolCategory } from "./SchoolCategory";
import styles from "./index.module.scss";
import { memo, useCallback, useState } from "react";
import { SubjectResponse } from "@/store/autogenApi";
import { useGetSubjectsQuery } from "@/store/hooks/subjects";
import { QueryLoadingWrapper } from "../QueryLoadingWrapper";

interface Props {
    open?: boolean;
    checked: boolean;
    subjects: SubjectResponse[];
    setSubjects: React.Dispatch<React.SetStateAction<SubjectResponse[]>>;
    style?: React.CSSProperties;
}

export const SubjectsComponent: React.VFC<Props> = memo(function SubjectsComponent(props) {
    const allSubjectsQueryState = useGetSubjectsQuery();

    return (
        <div className={styles.schoolCategories} style={props.style}>
            <QueryLoadingWrapper {...allSubjectsQueryState}>
                {(allSubjects) => (
                    <>
                        <SchoolCategory
                            level="university"
                            subjectConfig={UNIVERSITY_SUBJECT_CONFIG}
                            allSubjects={allSubjects}
                            baseSubjects={props.subjects}
                            subjects={props.subjects.filter((subject) => subject.level === "university")}
                            setSubjects={props.setSubjects}
                        />
                        <SchoolCategory
                            level="highSchool"
                            subjectConfig={HIGH_SCHOOL_SUBJECT_CONFIG}
                            allSubjects={allSubjects}
                            baseSubjects={props.subjects}
                            subjects={props.subjects.filter((subject) => subject.level === "highSchool")}
                            setSubjects={props.setSubjects}
                        />
                        <SchoolCategory
                            level="juniorHighSchool"
                            subjectConfig={JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG}
                            allSubjects={allSubjects}
                            baseSubjects={props.subjects}
                            subjects={props.subjects.filter((subject) => subject.level === "juniorHighSchool")}
                            setSubjects={props.setSubjects}
                        />
                        <SchoolCategory
                            level="elementarySchool"
                            subjectConfig={ELEMENTARY_SCHOOL_SUBJECT_CONFIG}
                            allSubjects={allSubjects}
                            baseSubjects={props.subjects}
                            subjects={props.subjects.filter((subject) => subject.level === "elementarySchool")}
                            setSubjects={props.setSubjects}
                        />
                    </>
                )}
            </QueryLoadingWrapper>
        </div>
    );
});
