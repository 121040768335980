import { FAQConfig } from "@/FAQConfig";

export const CAUTION_CONFIGS: FAQConfig[] = [
    {
        title: "講座の内容に関する事項",
        text: "",
        contents: [
            {
                title: "対象者を限定した募集について",
                text: "",
            },
        ],
    },
    {
        title: "権利・法律に関する事項",
        text: "",
        contents: [
            {
                title: "知的財産権にかかわる使用許諾について",
                text: "Trailでは、講座内容および講座で使用する教材・画像などについて知的財産権や肖像権を遵守した上で登録するよう利用規約で定めております。知的財産権や肖像権を侵害するおそれのある行為によって先生に生じることのある損害などについては、先生ご自身が責任を負うものとしますため、ご留意ください。",
            },
        ],
    },
];
