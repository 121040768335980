import { memo, useCallback, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { Socket } from "socket.io-client";
import { ClassResponse, ChatResponse } from "@/store/autogenApi";
import { CircularProgress } from "@material-ui/core";
import styles from "./index.module.scss";
import { useGetGroupChatQueryState } from "@/store/hooks/chats";
import { QueryLoadingWrapper } from "../QueryLoadingWrapper";
import { ChatContents } from "../ChatContents";
import { getApiUrl } from "@/store/emptyApi";

interface Props {
    createdAt?: Date;
    class: ClassResponse;
}

export const GroupChat: React.VFC<Props> = memo(function GroupChat(props) {
    const [socket, setSocket] = useState<Socket | undefined>(undefined);
    const [previousChat, setPreviousChat] = useState<ChatResponse | undefined>(undefined);

    const groupChatQueryState = useGetGroupChatQueryState(props.class.chatId);
    const apiUrl = getApiUrl();

    useEffect(() => {
        const chatId = props.class.chatId;
        const roomId = chatId;
        console.log("Connecting..");
        const newSocket = io(apiUrl, { query: { roomId } });
        setSocket(newSocket);
    }, [props.class, apiUrl]);

    useEffect(() => {
        if (!socket) return;
        return () => {
            console.log("disconnect");
            socket.disconnect();
        };
    }, [socket]);

    const handlePreviousChatChange = useCallback((chat: ChatResponse) => {
        setPreviousChat(chat);
    }, []);

    return (
        <QueryLoadingWrapper
            {...groupChatQueryState}
            loadingComponent={
                previousChat ? (
                    <ChatContents
                        socket={socket}
                        chat={previousChat}
                        isPrevious
                        refetch={groupChatQueryState.refetch}
                        handlePreviousChatChange={handlePreviousChatChange}
                    />
                ) : (
                    <div className={styles.circularProgressWrapper}>
                        <CircularProgress className={styles.circularProgress} />
                    </div>
                )
            }
        >
            {(chat) => (
                <ChatContents
                    socket={socket}
                    chat={chat}
                    isPrevious={false}
                    refetch={groupChatQueryState.refetch}
                    handlePreviousChatChange={handlePreviousChatChange}
                />
            )}
        </QueryLoadingWrapper>
    );
});
