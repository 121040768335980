import styles from "@/pages/Common/TeacherDetails/index.module.scss";
import { ReviewResponse } from "@/store/autogenApi";
import { ReviewComponent } from "../Review";

interface Props {
    reviews: ReviewResponse[];
}

export const ReviewsComponent: React.VFC<Props> = (props) => {
    return (
        <div className={styles.reviewTabContentsWrapper}>
            <div className={styles.reviewsWrapper}>
                <div className={styles.reviewTitle}>レビュー</div>
                {props.reviews.length === 0 ? (
                    <div className={styles.emptyReview}>まだレビューがありません</div>
                ) : (
                    <ul className={styles.reviewList} id="reviewList">
                        {props.reviews.map((review, idx) => {
                            return <ReviewComponent review={review} key={idx} />;
                        })}
                    </ul>
                )}
            </div>
        </div>
    );
};
