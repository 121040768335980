import { Modal } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { memo } from "react";
import { MdMail } from "react-icons/md";
import { Link } from "react-router-dom";

import styles from "@/pages/Common/SignIn/index.module.scss";

import { SignInByEmail } from "./SignInByEmail";

interface Props {
    signInMode: string;
    isProcessingForSignIn: boolean;
    byEmail: boolean;
    handleSignInAsStudentButtonClick: () => void;
    handleSignInAsTeacherButtonClick: () => void;
    handleClose: () => void;
    handleEmailButtonClick: () => void;
    handleGoogleButtonClick: (forStudent: boolean) => void;
    handleUserIdChange: (value: string) => void;
    handleIsProcessingForSignInChange: (value: boolean) => void;
}

export const SignInComponent: React.VFC<Props> = memo(function SignInComponent(props) {
    return (
        <div className={styles.signInWrapper}>
            <div className={styles.signInContents}>
                {
                    <>
                        <h1 className={styles.signInTitle}>ログイン</h1>
                        <div className={styles.accountType}>ログインをご希望のアカウントタイプを選択してください。</div>
                        <div className={styles.studentAndTeacherWrapper}>
                            <Button
                                className={styles.signInAsStudentButton}
                                onClick={props.handleSignInAsStudentButtonClick}
                            >
                                <div className={styles.signInAsStudent}>
                                    <span className={styles.student}>生徒</span>
                                    としてログイン
                                </div>
                            </Button>
                            <Button
                                className={styles.signInAsTeacherButton}
                                onClick={props.handleSignInAsTeacherButtonClick}
                            >
                                <div className={styles.signInAsTeacher}>
                                    <span className={styles.teacher}>先生</span>
                                    としてログイン
                                </div>
                            </Button>
                        </div>
                        <div className={styles.signUpLink}>
                            登録がお済みでない方は
                            <Link className={styles.here} to="/SignUp">
                                こちら
                            </Link>
                        </div>
                    </>
                }
                <Modal open={props.signInMode !== ""} onClose={props.handleClose}>
                    <div className={styles.modalContents}>
                        <div className={styles.signInContentsWrapper}>
                            <div className={styles.signInModeTitle}>
                                {props.signInMode === "student" ? "生徒" : "先生"}ログイン
                            </div>
                            <div className={styles.signInContents}>
                                {props.byEmail ? (
                                    <SignInByEmail
                                        handleClose={props.handleClose}
                                        handleUserIdChange={props.handleUserIdChange}
                                        handleIsProcessingForSignInChange={props.handleIsProcessingForSignInChange}
                                        isProcessingForSignIn={props.isProcessingForSignIn}
                                        forStudent={props.signInMode === "student" ? true : false}
                                        signInModeInJapanese={props.signInMode === "student" ? "生徒" : "先生"}
                                    />
                                ) : (
                                    <>
                                        <div className={styles.emailSignInWrapper}>
                                            <Button
                                                className={styles.emailSignInButton}
                                                onClick={props.handleEmailButtonClick}
                                            >
                                                <MdMail className={styles.emailIcon} />
                                                <div className={styles.emailSignIn}>メールアドレスでログイン</div>
                                            </Button>
                                        </div>
                                        {/* <div className={styles.otherSignInWrapper}>
                                        <div className={styles.or}>OR</div>
                                        <Button
                                            className={styles.googleSignInButton}
                                            onClick={() =>
                                                props.handleGoogleButtonClick(
                                                    props.signInMode === "student" ? true : false,
                                                )
                                            }
                                        >
                                            <img src={googleButtonImage} alt="" className={styles.googleButtonImage} />
                                        </Button>
                                    </div> */}
                                    </>
                                )}
                            </div>
                        </div>
                        {/* <Button
                        onClick={() => {
                            if (props.signInMode === "student") {
                                dispatch(
                                    login(
                                        "student",
                                        "0ojw0je0dmvowmegf0m",
                                        "58e0e042-0d06-49aa-8df6-a28343b49c3a",
                                        null,
                                    ),
                                );
                                history.push("/");
                            } else {
                                dispatch(
                                    login(
                                        "teacher",
                                        "gjw0jrpm3oinf09j3-mrpkdml",
                                        null,
                                        "02a9887e-122e-49ef-8ab0-31cac9e07b8b",
                                    ),
                                );
                                history.push("/");
                            }
                        }}
                    >
                        ログイン
                    </Button> */}
                    </div>
                </Modal>
            </div>
        </div>
    );
});
