import { useEffect, useState } from "react";

import { Button } from "@material-ui/core";

import styles from "./index.module.scss";

interface Props {
    contentDescription: string;
}

export const ContentDescription: React.VFC<Props> = (props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOver, setIsOver] = useState<boolean>(false);

    const showMore = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const contentDescriptionNode = document.getElementById("contentDescription");
        const scrollHeight = contentDescriptionNode?.scrollHeight;
        if (scrollHeight && scrollHeight > 200) {
            setIsOver(true);
        }
    }, [props.contentDescription]);

    return (
        <>
            <section className={styles.contentDescriptionWrapper}>
                <h2 className={styles.contentDescriptionTitle}>こんなことを教えています</h2>
                <div
                    className={styles.contentDescription}
                    style={{ overflowY: isOpen ? "auto" : "hidden", maxHeight: isOpen ? "none" : "200px" }}
                    id="contentDescription"
                >
                    {props.contentDescription}
                </div>
                {isOver && (
                    <div className={styles.buttonWrapper} style={{ position: isOpen ? "inherit" : "absolute" }}>
                        <Button className={styles.button} onClick={showMore}>
                            {isOpen ? "閉じる" : "すべて見る"}
                        </Button>
                    </div>
                )}
                {/* <p className={isOpen ? "" : styles.classroomProfileMore}>{props.contentDescription}</p>
                <OpenCloseButton isOpen={isOpen} setIsOpen={setIsOpen} btnContent={btnContent} /> */}
            </section>
        </>
    );
};
