import { memo, useCallback, useEffect, useState } from "react";

import { Button, useMediaQuery } from "@material-ui/core";

import { CourseResponse, ClassResponse } from "@/store/autogenApi";

import styles from "./index.module.scss";
import { GroupChat } from "@/components/GroupChat";
import { useHistory, useLocation } from "react-router";
import { LessonCalendarContents } from "@/components/LessonCalendar";
import { useGetTeacherLessonsQuery } from "@/store/hooks/lessons";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { TabInfo, TabsTemplate } from "@/components/TabsTemplate";
import { TabPanel } from "@/components/TabPanel";
import { OverTabs } from "@/components/OverTabs";
import { ClassInfo } from "./ClassInfo";

interface Props {
    targetClass: ClassResponse;
    course: CourseResponse;
    handleTargetClassChange: (classResponse: ClassResponse | undefined) => void;
}

interface TabPanelsProps {
    targetTabValue: string;
    targetClass: ClassResponse;
    course: CourseResponse;
    disableToJoin: boolean;
}

const TAB_INFO_LIST: TabInfo[] = [
    { value: "classInfo", label: "クラス情報" },
    { value: "lessonCalendar", label: "授業カレンダー" },
    { value: "chat", label: "グループチャット" },
];

export const TargetClass: React.VFC<Props> = memo(function ClassList(props) {
    const [targetTabValue, setTargetTabValue] = useState<string>("classInfo");

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const tagQueryParams = searchParams.get("tag");

    const mdUp = useMediaQuery("(min-width:960px)");

    const history = useHistory();

    useEffect(() => {
        if (!tagQueryParams) return;
        setTargetTabValue("lessonCalendar");
    }, [tagQueryParams]);

    useEffect(() => {
        const teacherToDoTypes = props.targetClass.teacherToDos
            .filter((toDo) => !toDo.isCompleted)
            .map((toDo) => toDo.toDoType);
    }, [props.targetClass]);

    const handleFAQButtonClick = useCallback(() => {
        history.push("/FAQ");
    }, []);

    const handleContactButtonClick = useCallback(() => {
        history.push("/Inquiry");
    }, []);

    const handleTabChange = useCallback((e: React.ChangeEvent<Record<string, unknown>>, newValue: string) => {
        setTargetTabValue(newValue);
    }, []);
    return (
        <div className={styles.targetClassWrapper}>
            <div className={styles.mainWrapper}>
                {mdUp ? (
                    <TabsTemplate
                        tabInfoList={TAB_INFO_LIST}
                        targetTabValue={targetTabValue}
                        handleTabChange={handleTabChange}
                        style={{ borderRadius: "4px 4px 0 0 !important;" }}
                    >
                        <TabPanels
                            targetTabValue={targetTabValue}
                            targetClass={props.targetClass}
                            course={props.course}
                            disableToJoin={false}
                        />
                    </TabsTemplate>
                ) : (
                    <OverTabs
                        tabInfoList={TAB_INFO_LIST}
                        targetTabValue={targetTabValue}
                        handleTabChange={handleTabChange}
                    >
                        <TabPanels
                            targetTabValue={targetTabValue}
                            targetClass={props.targetClass}
                            course={props.course}
                            disableToJoin={false}
                        />
                    </OverTabs>
                )}
            </div>
            <div className={styles.bottomButtonsWrapper}>
                <Button className={styles.FAQButton} onClick={handleFAQButtonClick}>
                    よくある質問を確認する
                </Button>
                <Button className={styles.contactButton} onClick={handleContactButtonClick}>
                    運営に問い合わせる
                </Button>
            </div>
        </div>
    );
});

const TabPanels: React.VFC<TabPanelsProps> = memo(function Tabs(props) {
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);

    const teacherLessonsQueryState = useGetTeacherLessonsQuery(teacherId);
    return (
        <>
            <TabPanel hidden={props.targetTabValue !== "classInfo"}>
                <ClassInfo classResponse={{ ...props.targetClass, course: props.course }} />
            </TabPanel>
            <TabPanel hidden={props.targetTabValue !== "lessonCalendar"}>
                <QueryLoadingWrapper {...teacherLessonsQueryState}>
                    {(lessons) => <LessonCalendarContents lessons={lessons} isDraggable />}
                </QueryLoadingWrapper>
            </TabPanel>
            <TabPanel hidden={props.targetTabValue !== "chat"}>
                <div className={styles.groupChatWrapper}>
                    <GroupChat
                        createdAt={new Date(props.targetClass.createdAt)}
                        class={{
                            ...props.targetClass,
                            course: props.course,
                        }}
                    />
                </div>
            </TabPanel>
        </>
    );
});
