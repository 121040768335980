import { TextField } from "@material-ui/core";
import { RealName } from "@/pages/Teacher/Identification";
import styles from "@/pages/Teacher/Identification/index.module.scss";
import { memo } from "react";

interface Props {
    isChecked: boolean;
    realName: RealName;
    handleRealNameChange: (key: keyof RealName, value: string) => void;
}

export const RealNameComponent: React.VFC<Props> = memo(function RealNameComponent(props) {
    return (
        <div className={styles.formItem} style={{ borderBottom: "1px solid #CCC" }}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitle}>本名</div>
                <div className={styles.mandatory}>必須</div>
            </div>
            <div className={styles.rightWrapper}>
                <div className={styles.realName}>
                    <TextField
                        variant="outlined"
                        placeholder="姓"
                        className={styles.textField}
                        value={props.realName.firstName}
                        onChange={(e) => {
                            props.handleRealNameChange("firstName", e.target.value);
                        }}
                    />
                    <TextField
                        variant="outlined"
                        placeholder="名"
                        className={styles.textField}
                        value={props.realName.lastName}
                        onChange={(e) => {
                            props.handleRealNameChange("lastName", e.target.value);
                        }}
                    />
                </div>
                {props.isChecked && (props.realName.firstName === "" || props.realName.lastName === "") && (
                    <div className={styles.pleaseInput}>姓・名の両方を入力してください。</div>
                )}
            </div>
        </div>
    );
});
