import { Button } from "@material-ui/core";
import { memo, useCallback } from "react";

import styles from "./index.module.scss";
import { useHistory } from "react-router";
import { RootState } from "@/ducks";
import { useSelector } from "react-redux";

interface Props {
    level: string;
    subjectDetail: SubjectDetail;
    subCategoryKey: string;
    categoryKey: string;
    mobile?: boolean;
}

interface SubjectDetail {
    label: string;
    icon: JSX.Element;
}

export const SubjectDetailButton: React.VFC<Props> = memo(function SubjectDetailsButton(props) {
    const history = useHistory();
    const userMode = useSelector((state: RootState) => state.jwt.userMode as string);
    const handleSubjectDetailButtonClick = useCallback(() => {
        const searchType = userMode === "teacher" ? "publicRequest" : "course";
        history.push(
            `/SearchResults?searchType=${searchType}&level=${props.level}&category=${props.categoryKey}&subCategory=${props.subCategoryKey}`,
        );
    }, [props.level, props.categoryKey, props.subCategoryKey, userMode]);
    return (
        <Button
            key={props.subCategoryKey}
            className={styles.subjectDetailButton}
            style={{
                fontWeight: "bold",
            }}
            onClick={handleSubjectDetailButtonClick}
        >
            <div className={styles.flexLabel}>
                {props.subjectDetail.icon}
                <div className={styles.title} style={{ marginLeft: "10px", textAlign: "left" }}>
                    {props.subjectDetail.label}
                </div>
            </div>
        </Button>
    );
});
