// 必要な機能をインポート
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getToken, getMessaging, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration
export const firebaseConfig = {
    apiKey: "AIzaSyBX_PvanEGng1RAC2hz6yE3-cK5Co0xHtQ",
    authDomain: "pairbloom-b20dd.firebaseapp.com",
    projectId: "pairbloom-b20dd",
    storageBucket: "pairbloom-b20dd.appspot.com",
    messagingSenderId: "419045746968",
    appId: "1:419045746968:web:f2bdcdbb3a85d77989d66d",
    measurementId: "G-6BFQC7WYVQ",
};

// Firebaseを初期化
export const firebaseApp = initializeApp(firebaseConfig);

export const firestore = getFirestore(firebaseApp);
export const messaging = getMessaging(firebaseApp);

const getOrRegisterServiceWorker = async () => {
    if ("serviceWorker" in navigator) {
        const serviceWorker = await window.navigator.serviceWorker.getRegistration("/");
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register("/firebase-messaging-sw.js", {
            scope: "/",
        });
    }
    throw new Error("The browser doesn`t support service worker.");
};

// const sw = await window.navigator.serviceWorker.register("/sw.js");
// window.Notification.requestPermission((permission) => {
//     if (permission === "granted") {
//         sw.showNotification("Hello world");
//     }
// });

export const getFCMToken = async (vapidKey: string | undefined) => {
    const serviceWorkerRegistration = await getOrRegisterServiceWorker();
    return await getToken(messaging, { vapidKey, serviceWorkerRegistration });
};

export const onForegroundMessage = () => new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));
