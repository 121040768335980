import { Button, Modal } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import styles from "./index.module.scss";
import { ZoomAccountStatus } from "@/store/autogenApi";
import { useCallback } from "react";

interface Props {
    modalOpen: boolean;
    targetAction: string;
    zoomAccountStatus: ZoomAccountStatus;
    handleBackButtonClick: () => void;
    handleModalClose?: () => void;
}

export const PleaseLinkZoomModal: React.VFC<Props> = (props) => {
    const history = useHistory();
    const handleLinkZoomButtonClick = useCallback(() => {
        history.push("/LinkZoomAccount");
    }, [history]);
    const handleInquiryButtonClick = useCallback(() => {
        history.push("/Inquiry");
    }, [history]);

    const handleClose = useCallback(() => {
        if (props.handleModalClose) {
            props.handleModalClose();
        }
    }, [props.handleModalClose]);

    return props.zoomAccountStatus === "active" ? null : (
        <Modal open={props.modalOpen} className={styles.pleaseLinkModal} onClose={handleClose}>
            <div className={styles.pleaseModalContents}>
                {props.zoomAccountStatus === "inactive" && (
                    <>
                        <div className={styles.requireWrapper}>
                            {props.targetAction}にはZoomアカウントの連携が必要です。
                        </div>
                        <div className={styles.buttonsWrapper}>
                            <Button className={styles.homeButton} onClick={props.handleBackButtonClick}>
                                戻る
                            </Button>
                            <Button className={styles.linkZoomAccountButton} onClick={handleLinkZoomButtonClick}>
                                Zoom連携
                            </Button>
                        </div>
                    </>
                )}
                {props.zoomAccountStatus === "pending" && (
                    <>
                        <div className={styles.requireWrapper}>
                            {props.targetAction}
                            にはZoomアカウントの連携が必要ですが、未認証の状態になっています。Zoomから届いた招待メールを確認して認証を行ってください。メールが届いていない場合はお問い合わせください。
                        </div>
                        <div className={styles.buttonsWrapper}>
                            <Button className={styles.homeButton} onClick={props.handleBackButtonClick}>
                                戻る
                            </Button>
                            <Button className={styles.linkZoomAccountButton} onClick={handleInquiryButtonClick}>
                                お問い合わせ
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};
