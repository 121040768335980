import { FAQConfig } from "@/FAQConfig";

export const ABOUT_TEACHER_DETAILS_PAGE_CONFIGS: FAQConfig[] = [
    {
        title: "先生詳細ページにアクセスする",
        text: "先生アカウントにてマイページの「プロフィール」からアクセスできます。",
    },
    {
        title: "「編集する」ボタンを押す",
        text: "画面上部の「編集する」ボタンを押します。",
    },
    {
        title: "先生情報の編集",
        text: "先生情報を編集します。",
    },
    {
        title: "編集完了",
        text: "この画面が表示されれば、編集完了となります。",
    },
];
