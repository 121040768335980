import { useSelector } from "react-redux";

import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { useGetMyProposalsQuery } from "@/store/hooks/proposals";

import { MyProposalsContents } from "@/pageComponents/Teacher/MyPage/MyProposals";

import { MyPage } from ".";
import { RootState } from "@/ducks";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { useEffect } from "react";
import { Navigation } from "@/components/Navigation";

const NAVIGATION_LIST: Navigation[] = [
    { title: "マイページ", url: "/Teacher/MyPage/Profile" },
    { title: "提案一覧", url: "/Teacher/MyPage/MyProposals" },
];

export const MyProposals: React.VFC = () => {
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);

    const proposalsQueryState = useGetMyProposalsQuery(teacherId);

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    return (
        <MyPage title="提案一覧" activeTab="myProposals">
            <QueryLoadingWrapper {...proposalsQueryState}>
                {(proposals) => <MyProposalsContents proposals={proposals} />}
            </QueryLoadingWrapper>
        </MyPage>
    );
};
