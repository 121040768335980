import { GetPublicRequestSearchResultsApiArg, PublicRequestResponse } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions } from "./utils/types";

export const useGetPublicRequestQuery = (publicRequestId: string) => {
    const result = defaultApi.endpoints.getPublicRequest.useQuery({ publicRequestId: publicRequestId });
    return useQueryResult(result, (response: PublicRequestResponse) => response);
};

export const useGetLatestPublicRequestsForShortCourseQuery = (howMany: number) => {
    const result = defaultApi.endpoints.getLatestPublicRequestsForShortCourse.useQuery({ howMany: howMany });
    return useQueryResult(result, (response: PublicRequestResponse[]) => response);
};

export const useGetLatestPublicRequestsForRegularCourseQuery = (howMany: number) => {
    const result = defaultApi.endpoints.getLatestPublicRequestsForRegularCourse.useQuery({ howMany: howMany });
    return useQueryResult(result, (response: PublicRequestResponse[]) => response);
};

export const useGetApproachingDeadlinePublicRequestsForShortCourseQuery = (howMany: number) => {
    const result = defaultApi.endpoints.getApproachingDeadlinePublicRequestsForShortCourse.useQuery({
        howMany: howMany,
    });
    return useQueryResult(result, (response: PublicRequestResponse[]) => response);
};

export const useGetApproachingDeadlinePublicRequestsForRegularCourseQuery = (howMany: number) => {
    const result = defaultApi.endpoints.getApproachingDeadlinePublicRequestsForRegularCourse.useQuery({
        howMany: howMany,
    });
    return useQueryResult(result, (response: PublicRequestResponse[]) => response);
};

export const useGetPublicRequestSearchResultsQuery = (args: GetPublicRequestSearchResultsApiArg) => {
    const result = defaultApi.endpoints.getPublicRequestSearchResults.useQuery(args);
    return useQueryResult(result, (response: PublicRequestResponse[]) => response);
};

export const useGetMyPublicRequestsQuery = (studentId: string) => {
    const result = defaultApi.endpoints.getMyPublicRequests.useQuery({ studentId: studentId });
    return useQueryResult(result, (response: PublicRequestResponse[]) => response);
};

export const useCreatePublicRequestMutation = (options?: UseMutationOptions) => {
    const [createPublicRequest] = defaultApi.endpoints.createPublicRequest.useMutation();
    return useMutationFunctionWrapper(createPublicRequest, options?.errorMessages);
};

export const useUpdatePublicRequestMutation = (options?: UseMutationOptions) => {
    const [updateRequest] = defaultApi.endpoints.updatePublicRequest.useMutation();
    return useMutationFunctionWrapper(updateRequest, options?.errorMessages);
};

export const useDeleteProposalMutation = (options?: UseMutationOptions) => {
    const [deleteProposal] = defaultApi.endpoints.deleteProposal.useMutation();
    return useMutationFunctionWrapper(deleteProposal, options?.errorMessages);
};

export const useGetPublicRequestFavoriteCountQuery = (publicRequestId: string) => {
    const result = defaultApi.endpoints.getPublicRequestFavoriteCount.useQuery({ publicRequestId });
    return useQueryResult(result, (response: number) => response);
};
