import styles from "./index.module.scss";
import { DraftCourseResponse } from "@/store/autogenApi";
import { MyDraftCourseCard } from "./MyDraftCourseCard";

interface Props {
    visible: boolean;
    draftCourses: DraftCourseResponse[];
    repetition: number;
    handleDraftCourseCardClick: (draftCourse: DraftCourseResponse) => void;
}

export const MyDraftCoursesTabContents: React.VFC<Props> = (props) => {
    return (
        <div role="tabpanel" hidden={!props.visible} className={styles.tabContents}>
            {props.visible && (
                <>
                    {props.draftCourses.length === 0 ? (
                        <div className={styles.emptyCourses}>下書き講座がありません。</div>
                    ) : (
                        <div className={styles.cardsList}>
                            {[...Array(Math.ceil(props.draftCourses.length / props.repetition))]
                                .map((_, i) => i)
                                .map((idx) => (
                                    <>
                                        <div className={styles.cards} key={idx}>
                                            {props.draftCourses
                                                .slice(
                                                    idx * props.repetition,
                                                    idx * props.repetition + props.repetition,
                                                )
                                                .map((draftCourse) => {
                                                    return (
                                                        <MyDraftCourseCard
                                                            draftCourse={draftCourse}
                                                            key={draftCourse.draftCourseId}
                                                            isFlex
                                                            handleDraftCourseCardClick={
                                                                props.handleDraftCourseCardClick
                                                            }
                                                        />
                                                    );
                                                })}
                                            {props.draftCourses.slice(
                                                idx * props.repetition,
                                                idx * props.repetition + props.repetition,
                                            ).length < props.repetition &&
                                                [
                                                    ...Array(
                                                        props.repetition -
                                                            props.draftCourses.slice(
                                                                idx * props.repetition,
                                                                idx * props.repetition + props.repetition,
                                                            ).length,
                                                    ),
                                                ].map((_, idx) => (
                                                    <MyDraftCourseCard
                                                        key={idx}
                                                        isHidden
                                                        isFlex
                                                        handleDraftCourseCardClick={props.handleDraftCourseCardClick}
                                                    />
                                                ))}
                                        </div>
                                    </>
                                ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
