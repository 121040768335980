import { useEffect, useState } from "react";

import { Button } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import queryString from "query-string";
import { AiOutlineCreditCard } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import styles from "./index.module.scss";
import { RootState } from "@/ducks";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { useGetPrivateUserQuery } from "@/store/hooks/users";

export const PointsCharge: React.VFC = () => {
    const [way, setWay] = useState<string>("");
    const [chargeAmountInput, setChargeAmountInput] = useState<number>(0);
    const [nextButtonClicked, setNextButtonClicked] = useState<boolean>(false);
    const [necessaryAmount, setNecessaryAmount] = useState<number>(0);

    const AMOUNT_LIST = [500, 1000, 2000, 3000, 5000, 10000, 30000, 50000];

    const query = queryString.parse(window.location.search);

    const userId = useSelector((state: RootState) => state.jwt.userId as string);

    const userQueryState = useGetPrivateUserQuery(userId);

    useEffect(() => {
        if (!isNaN(Number(query.necessaryPoints))) {
            setNecessaryAmount(Number(query.necessaryPoints));
        }
    }, []);

    const handleHistoryBackButtonClick = () => {
        history.goBack();
    };
    const handleWayButtonClick = (value: string) => {
        setWay(value);
    };
    const handleBackButtonClick = () => {
        setWay("");
    };
    const zenkakuToHankaku = (str: string) => {
        if (!str) return ""; // 偽であれば空文字を返す

        const hankaku_eisu = () => {
            if (!str) return "";
            return String.fromCharCode(str.charCodeAt(0) - 0xfee0);
        };

        str = str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, hankaku_eisu); // 英数
        str = str.replace(/[−―‐ー]/g, "-"); // 色々なハイフンも統一
        str = str.replace(/＠/g, "@"); // @マークも親切に統一
        str = str.replace(/[ts ]/g, ""); // ついでにタブやスペースを消す
        return str;
    };
    const handleChargeAmountInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const string = e.target.value;
        const fixedString = zenkakuToHankaku(string);
        const fixedNumber = Number(fixedString) ? Number(fixedString) : 0;
        setChargeAmountInput(fixedNumber);
    };
    const history = useHistory();
    const handleNextButtonClick = () => {
        setNextButtonClicked(true);
        if (100 <= chargeAmountInput && chargeAmountInput <= 100000) {
            history.push(`/Payment?amount=${chargeAmountInput}`);
        }
    };
    const handlePriceButtonClick = (amount: number) => {
        history.push(`/Payment?amount=${amount}`);
    };

    return (
        <div className={styles.pointPurchaseWrapper}>
            <QueryLoadingWrapper {...userQueryState}>
                {(user) => (
                    <>
                        {way === "" ? (
                            <>
                                <div className={styles.topWrapper}>
                                    <div className={styles.pointChargeWaysTitle}>ポイントチャージの方法</div>
                                    <Button className={styles.backButton} onClick={handleHistoryBackButtonClick}>
                                        戻る
                                    </Button>
                                </div>
                                <div className={styles.pointChargeWayButtons}>
                                    <Button
                                        className={styles.pointChargeWayButton}
                                        style={{ borderTop: "none" }}
                                        onClick={() => {
                                            handleWayButtonClick("クレジットカード・デビットカード");
                                        }}
                                    >
                                        <div className={styles.itemWrapper}>
                                            <AiOutlineCreditCard className={styles.creditIcon} />
                                            <div className={styles.itemTitle}>クレジットカード・デビットカード</div>
                                        </div>
                                        <ArrowForwardIosIcon className={styles.arrowIcon} />
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={styles.topWrapper}>
                                    <Button className={styles.backButton} onClick={handleBackButtonClick}>
                                        戻る
                                    </Button>
                                    <div className={styles.wayTitle}>{way}</div>
                                </div>
                                {way === "クレジットカード・デビットカード" && (
                                    <div className={styles.creditInfoWrapper}>
                                        <div className={styles.heldPointsWrapper}>
                                            <div className={styles.heldPointsTitle}>保有ポイント</div>
                                            <div className={styles.heldPoints}>{user?.pointsBalance ?? 0}pt</div>
                                        </div>
                                        {necessaryAmount !== 0 && (
                                            <>
                                                <div className={styles.necessaryPointsWrapper}>
                                                    <div className={styles.necessaryPointsTitle}>
                                                        今回必要なポイント
                                                    </div>
                                                    <div className={styles.necessaryPoints}>
                                                        {Number(necessaryAmount)}pt
                                                    </div>
                                                </div>
                                                <div className={styles.necessaryPointsWrapper}>
                                                    <div className={styles.necessaryPointsTitle}>差分</div>
                                                    <div className={styles.necessaryPoints}>
                                                        {typeof user?.pointsBalance === "number"
                                                            ? necessaryAmount - user.pointsBalance
                                                            : 0}
                                                        pt
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <Button className={styles.wayButton} onClick={handleBackButtonClick}>
                                            <div className={styles.heldPointsTitle}>支払い方法</div>
                                            <div className={styles.heldPoints}>クレジットカード・デビットカード</div>
                                            <ArrowForwardIosIcon className={styles.arrowIcon} />
                                        </Button>
                                    </div>
                                )}
                                <div className={styles.chargeWrapper}>
                                    <div className={styles.chargeTitle}>チャージ額の選択</div>
                                    <ul className={styles.chargeAmountList}>
                                        {necessaryAmount != 0 && (
                                            <li className={styles.chargeAmountItem} style={{ borderTop: "none" }}>
                                                <div className={styles.chargeAmount}>
                                                    差分
                                                    {typeof user?.pointsBalance === "number"
                                                        ? necessaryAmount - user.pointsBalance
                                                        : 0}
                                                    (pt)だけ購入する
                                                </div>
                                                <Button
                                                    className={styles.priceButton}
                                                    onClick={() => {
                                                        const amount =
                                                            typeof user?.pointsBalance === "number"
                                                                ? necessaryAmount - user.pointsBalance
                                                                : 0;
                                                        handlePriceButtonClick(amount);
                                                    }}
                                                >
                                                    {typeof user?.pointsBalance === "number"
                                                        ? necessaryAmount - user.pointsBalance
                                                        : 0}
                                                    円
                                                </Button>
                                            </li>
                                        )}
                                        {AMOUNT_LIST.map((targetAmount, idx) => (
                                            <li
                                                className={styles.chargeAmountItem}
                                                key={idx}
                                                style={{
                                                    borderTop:
                                                        !necessaryAmount && idx === 0
                                                            ? "none"
                                                            : "border: 1px solid #AAA",
                                                }}
                                            >
                                                <div className={styles.chargeAmount}>{targetAmount}pt</div>
                                                <Button
                                                    className={styles.priceButton}
                                                    onClick={() => {
                                                        handlePriceButtonClick(targetAmount);
                                                    }}
                                                >
                                                    {targetAmount}円
                                                </Button>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className={styles.chargeInputWrapper}>
                                        <div className={styles.chargeInputTitle}>
                                            チャージ額を任意入力(100円〜100,000円)
                                        </div>
                                        <div className={styles.chargeInputContents}>
                                            <div className={styles.inputAndYen}>
                                                <input
                                                    type="text"
                                                    className={styles.input}
                                                    onChange={handleChargeAmountInputChange}
                                                />
                                                <div className={styles.yen}>円</div>
                                            </div>
                                            <Button className={styles.nextButton} onClick={handleNextButtonClick}>
                                                次へ
                                            </Button>
                                        </div>
                                        {nextButtonClicked &&
                                            (chargeAmountInput < 100 || 100000 < chargeAmountInput) && (
                                                <div className={styles.error}>
                                                    100〜100000の範囲で入力してください。
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
            </QueryLoadingWrapper>
        </div>
    );
};
