import { useEffect, useState } from "react";

import { CircularProgress } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { RouterPrompt } from "@/components/RouterPrompt";

import styles from "./index.module.scss";

export const ZoomAuthenticating: React.VFC = () => {
    const query = new URLSearchParams(useLocation().search);
    const [complete, setComplete] = useState<boolean>(false);

    useEffect(() => {
        complete && history.push("/Teacher/InfoInput?step=1");
    }, [complete]);

    const history = useHistory();

    const dispatch = useDispatch();

    const handleOKButtonClick = () => {
        return true;
    };

    const handleCancelButtonClick = () => {
        return false;
    };

    return (
        <>
            <RouterPrompt
                when={!complete}
                message="新規登録が未完了です。\n現在の入力内容は失われますが、このページを終了してもよろしいですか？"
                onOK={handleOKButtonClick}
                onCancel={handleCancelButtonClick}
            />
            <div className={styles.zoomAuthenticating}>
                <CircularProgress className={styles.circularProgress} />
                <div className={styles.message}>認証中...</div>
            </div>
        </>
    );
};
