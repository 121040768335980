import { UserType } from "@/protectedRouter/ProtectedRouterConfig";
import { AvailableTimeResponse, ClassResponse, PrivateRequestResponse } from "@/store/autogenApi";
import { RegularCourseContents } from "./RegularCourseContents";
import { ShortCourseContents } from "./ShortCourseContents";
import { memo } from "react";

interface Props {
    // shortCourse用加工済み
    activeClasses: ClassResponse[];
    activeClassForRegularCourse: ClassResponse | undefined;
    courseType: string;
    studentId: string | null | undefined;
    courseId: string;
    userMode: UserType;
    isHostTeacher: boolean;
    privateRequest: PrivateRequestResponse | undefined;
    courseTeacherId: string;
    privateRequestModalOpen: boolean;
    availableTimes: AvailableTimeResponse[] | undefined;
    handlePrivateRequestModalClose: () => void;
    getJapaneseDay: (dayIdx: number) => string;
    handleReservationButtonClick: (classId: string) => void;
    handleClassButtonClick: (classId: string) => void;
}

export const CourseSchedules: React.VFC<Props> = memo(function CourseSchedules(props) {
    const getDiffTime = (deadline: Date) => {
        const fixedDeadline = new Date(deadline);
        const now = new Date();
        if (fixedDeadline.getTime() <= now.getTime()) {
            return "";
        } else {
            const diffTime = fixedDeadline.getTime() - now.getTime();
            const dateNumber = 1000 * 24 * 60 * 60;
            const diffDate = Math.floor(diffTime / dateNumber);
            const diffHour = Math.floor((diffTime - dateNumber * diffDate) / (dateNumber / 24));
            if (diffDate === 0) {
                const diffMinute = Math.floor(
                    (diffTime - dateNumber * diffDate - (dateNumber / 24) * diffHour) / (dateNumber / (24 * 60)),
                );
                if (diffHour === 0) {
                    return `${diffMinute}分`;
                } else {
                    return `${diffHour}時間${diffMinute}分`;
                }
            } else {
                return `${diffDate}日と${diffHour}時間`;
            }
        }
    };

    return (
        <>
            {props.courseType === "short" && (
                <ShortCourseContents
                    activeClasses={props.activeClasses}
                    courseType={props.courseType}
                    studentId={props.studentId}
                    userMode={props.userMode}
                    isHostTeacher={props.isHostTeacher}
                    getDiffTime={getDiffTime}
                    getJapaneseDay={props.getJapaneseDay}
                    handleReservationButtonClick={props.handleReservationButtonClick}
                    handleClassButtonClick={props.handleClassButtonClick}
                />
            )}
            {props.courseType === "regular" && (
                <RegularCourseContents
                    availableTimes={props.availableTimes}
                    activeClassForRegularCourse={props.activeClassForRegularCourse}
                    courseType={props.courseType}
                    studentId={props.studentId}
                    courseId={props.courseId}
                    userMode={props.userMode}
                    isHostTeacher={props.isHostTeacher}
                    privateRequest={props.privateRequest}
                    courseTeacherId={props.courseTeacherId}
                    privateRequestModalOpen={props.privateRequestModalOpen}
                    getDiffTime={getDiffTime}
                    getJapaneseDay={props.getJapaneseDay}
                    handleReservationButtonClick={props.handleReservationButtonClick}
                    handleClassButtonClick={props.handleClassButtonClick}
                    handlePrivateRequestModalClose={props.handlePrivateRequestModalClose}
                />
            )}
        </>
    );
});
