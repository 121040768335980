import { Button } from "@material-ui/core";
import { Hidden } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { TabsForAboutStudent } from "@/pageComponents/Common/AboutStudent/TabsForAboutStudent";

import styles from "./index.module.scss";
import { RootState } from "@/ducks";
import { OverWrapper } from "@/components/OverWrapper";
import backgroundImage from "@/pageComponents/Common/AboutStudent/img/stationery.jpg";

export const AboutStudent: React.VFC = () => {
    const userMode = useSelector((state: RootState) => state.jwt.userMode);

    return (
        <>
            <OverWrapper
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}
            >
                <div className={styles.aboutStudentTopImgContainer}>
                    <Hidden mdUp>
                        <div className={styles.topImageForMobile} />
                    </Hidden>
                    <div className={styles.aboutStudentTopTitleContainer}>
                        <div className={styles.aboutStudentTopMessageWrapper}>
                            <p className={styles.topMessage}>先生も料金も「あなた」が決める！</p>
                            <p className={styles.topMessage}>【学び】のフリマアプリTrail</p>
                        </div>
                        {userMode === "anonymous" && (
                            <div className={styles.aboutStudentTopButtonContainer}>
                                <Button
                                    className={styles.aboutStudentTopButton}
                                    component={Link}
                                    to="/SignUp/ForStudent"
                                >
                                    今すぐ登録！
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </OverWrapper>
            <TabsForAboutStudent userMode={userMode} />
        </>
    );
};
