import { useSelector } from "react-redux";

import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { useGetMyCoursesQuery } from "@/store/hooks/courses";

import { MyCoursesContents } from "@/pageComponents/Teacher/MyPage/MyCourses";

import { MyPage } from ".";
import { RootState } from "@/ducks";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { useEffect } from "react";
import { Navigation } from "@/components/Navigation";

const NAVIGATION_LIST: Navigation[] = [
    { title: "マイページ", url: "/Teacher/MyPage/Profile" },
    { title: "My講座一覧", url: "/Teacher/MyPage/MyCourses" },
];

export const MyCourses: React.VFC = () => {
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);

    const coursesQueryState = useGetMyCoursesQuery(teacherId);

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    return (
        <MyPage title="My講座一覧" activeTab="myCourses">
            <QueryLoadingWrapper {...coursesQueryState}>
                {(courses) => <MyCoursesContents courses={courses} />}
            </QueryLoadingWrapper>
        </MyPage>
    );
};
