import { AppBar, createTheme, MuiThemeProvider, Tab, Tabs } from "@material-ui/core";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { TabContents } from "@/pageComponents/Common/SearchResults";
import { CourseSearchResults } from "../CourseSearchResults";
import { RequestSearchResults } from "../RequestSearchResults";
import { TeacherSearchResults } from "../TeacherSearchResults";

import queryString from "query-string";

import styles from "./index.module.scss";
import { useHistory } from "react-router";
import { OverWrapper } from "@/components/OverWrapper";

const theme = createTheme({
    overrides: {
        MuiTabs: {
            root: {
                color: "#305077",
                paddingLeft: 0,
                boxShadow: "none !important",
            },
            indicator: {
                backgroundColor: "#305077",
            },
        },
        MuiTab: {
            root: {
                fontWeight: "bold",
                "&$selected": {
                    pointerEvents: "none",
                    color: "#305077",
                    "&:hover": {
                        color: "#305077",
                    },
                },
            },
        },
    },
});

export const SearchResults: React.VFC = memo(function SearchResults() {
    const [value, setValue] = useState(0);

    const query = queryString.parse(window.location.search);
    const history = useHistory();

    const searchResultsWrapperEl: React.MutableRefObject<HTMLElement | null | undefined> = useRef();

    const searchResultsWrapperRef = useCallback((node: HTMLElement | null) => {
        searchResultsWrapperEl.current = node;
    }, []);

    useEffect(() => {
        const currentSearchResultPageEl = searchResultsWrapperEl.current;
        if (!currentSearchResultPageEl) return;
        const pathName = window.location.pathname;
        const onResize = () => {
            const header = document.getElementById("header");
            const footer = document.getElementById("footer");
            if (!header || !footer) return;
            const headerHeight = header.offsetHeight;
            const footerHeight = footer.offsetHeight;
            const viewHeight = window.innerHeight;
            const minHeight = viewHeight - (headerHeight + footerHeight);
            currentSearchResultPageEl.style.minHeight = `${minHeight}px`;
        };
        onResize();
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [searchResultsWrapperEl.current]);

    useEffect(() => {
        const searchType = query.searchType as string;
        if (searchType === "course") {
            setValue(0);
        } else if (searchType === "publicRequest") {
            setValue(1);
        } else if (searchType === "teacher") {
            setValue(2);
        }
    }, [query.searchType]);

    const handleChange = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
        if (newValue === 0) {
            history.push(
                `/SearchResults?searchType=course&keyword=&courseType=&level=&category=&subCategory=&rankNumber=&university=&faculty=&department=&orderBy=latest`,
            );
        } else if (newValue === 1) {
            history.push(
                `/SearchResults?searchType=publicRequest&keyword=&courseType=&level=&category=&subCategory=&orderBy=latest`,
            );
        } else {
            history.push(
                `/SearchResults?searchType=teacher&keyword=&level=&category=&subCategory=&rankNumber=&university=&faculty=&department=&orderBy=latest`,
            );
        }
    };
    return (
        <div className={styles.searchResultsWrapper} ref={searchResultsWrapperRef}>
            <TabContents value={value} index={0}>
                <CourseSearchResults query={query} />
            </TabContents>
            <TabContents value={value} index={1}>
                <RequestSearchResults query={query} />
            </TabContents>
            <TabContents value={value} index={2}>
                <TeacherSearchResults query={query} />
            </TabContents>
        </div>
    );
});
