import { memo } from "react";
import { AiFillAlert, AiOutlineAlert, AiOutlineCheckCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import { MdOutlineClose, MdOutlineSmsFailed, MdSmsFailed } from "react-icons/md";
import styles from "./index.module.scss";

interface Props {
    title?: string;
    body?: string;
    isCompleted?: boolean;
    isFailed?: boolean;
}

export const ToastContents: React.VFC<Props> = memo(function ToastifiedNotification(props) {
    return (
        <>
            {props.title && (
                <div className={styles.titleWrapper}>
                    {props.isFailed && <AiOutlineExclamationCircle className={styles.isFailedIcon} />}
                    {props.isCompleted && <AiOutlineCheckCircle className={styles.isCompletedIcon} />}
                    <div className={styles.title}>{props.title}</div>
                </div>
            )}
            {props.body && <div className={styles.body}>{props.body}</div>}
            <button className={styles.closeButton}>
                <MdOutlineClose className={styles.closeIcon} />
            </button>
        </>
    );
});
