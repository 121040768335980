import { CircularProgress } from "@material-ui/core";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useMakeLatestCreditCardDefaultMutation } from "@/store/hooks/stripe";

interface Props {
    studentId: string;
    handleBackButtonClick: () => void;
}

export const RegisterNewCreditCard: React.VFC<Props> = (props) => {
    const stripe = useStripe();
    const elements = useElements();

    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const makeLatestCreditCardDefault = useMakeLatestCreditCardDefaultMutation();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsProcessing(true);
        if (!stripe || !elements) return;
        const { error } = await stripe.confirmSetup({
            elements,
            redirect: "if_required",
        });
        if (error) {
            setIsProcessing(false);
            return;
        }
        await makeLatestCreditCardDefault({
            makeLatestPaymentMethodDefaultRequestBody: {
                studentId: props.studentId,
            },
        });
        setIsProcessing(false);
        props.handleBackButtonClick();
    };
    return (
        <div className={styles.registerNewCreditCardWrapper}>
            <button onClick={props.handleBackButtonClick} className={styles.backButton}>
                戻る
            </button>
            <div className={styles.registerNewCreditCardTitle}>クレジットカードの登録</div>
            <form className={styles.paymentForm} onSubmit={handleSubmit}>
                {/* {languages[0] != "ja" && (
                    <div className={styles.notice}>
                        ※お使いのブラウザの言語設定によっては以下の項目が日本語以外で表記されている場合がございます。項目は上から順に「カード番号」「有効期限」「セキュリティコード」「国」を表しています。
                    </div>
                )} */}
                <PaymentElement className={styles.paymentElement} />
                <button className={styles.registerButton}>
                    {isProcessing ? (
                        <CircularProgress className={styles.progress} />
                    ) : (
                        <div className={styles.register}>登録</div>
                    )}
                </button>
                {/* Show any error or success messages */}
            </form>
        </div>
    );
};
