import { memo } from "react";
import { Button } from "@material-ui/core";
import styles from "./index.module.scss";

interface Props {
    isCentered?: boolean;
    isFlex?: boolean;
    fontSize?: number;
    children: React.ReactNode;
    handleClick: (params?: any) => void;
}

export const RedButton: React.VFC<Props> = memo(function RedButton(props) {
    return (
        <Button
            className={props.isCentered ? `${styles.redButton} ${styles.center}` : styles.redButton}
            style={{ flex: props.isFlex ? "1" : "auto", fontSize: props.fontSize ? `${props.fontSize}px` : "14px" }}
            variant="contained"
            color="primary"
            onClick={props.handleClick}
        >
            {props.children}
        </Button>
    );
});
