import { PrivateRequestResponse } from "@/store/autogenApi";
import { ListItem } from "./ListItem";

interface Props {
    privateRequest: PrivateRequestResponse | undefined;
    borderBottom?: boolean;
}

export const ApplicationMessage: React.VFC<Props> = (props) => {
    return (
        <ListItem title="生徒さんからのメッセージ" borderBottom={props.borderBottom}>
            <>{props.privateRequest?.message}</>
        </ListItem>
    );
};
