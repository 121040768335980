import { memo } from "react";
import styles from "@/pages/Common/CourseDetails/index.module.scss";
import { UserType } from "@/protectedRouter/ProtectedRouterConfig";
import { AvailableTimeResponse, ClassResponse, PrivateRequestResponse } from "@/store/autogenApi";
import { getDayAndTime } from "@/utils/AvailableTimeUtils";

interface Props {
    availableTimes?: AvailableTimeResponse[] | undefined;
    activeClassForRegularCourse: ClassResponse | undefined;
    courseType: string;
    studentId: string | null | undefined;
    courseId: string;
    userMode: UserType;
    isHostTeacher: boolean;
    privateRequest: PrivateRequestResponse | undefined;
    courseTeacherId: string;
    privateRequestModalOpen: boolean;
    getDiffTime: (deadline: Date) => string;
    getJapaneseDay: (dayIdx: number) => string;
    handleReservationButtonClick: (classId: string) => void;
    handleClassButtonClick: (classId: string) => void;
    handlePrivateRequestModalClose: () => void;
}

export const RegularCourseContents: React.VFC<Props> = memo(function RegularCourseContents(props) {
    return (
        <div className={styles.dateWrapper}>
            <div className={styles.dateHeadingWrapper}>
                <span className={styles.dateHeading}>対応可能時間帯</span>
            </div>
            <div className={styles.regularCourseWrapper}>
                {props.activeClassForRegularCourse ? (
                    <>
                        <div className={styles.leftWrapper}>
                            <div className={styles.basicScheduleWrapper}>
                                <ul className={styles.regularCourseScheduleList}>
                                    {props.availableTimes && (
                                        <>
                                            {props.availableTimes?.map((availableTime, idx) => {
                                                return (
                                                    <li className={styles.scheduleItem} key={idx}>
                                                        {`${getDayAndTime(availableTime)}`}
                                                    </li>
                                                );
                                            })}
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className={styles.rightWrapper}>
                            <div className={styles.maxDaysInAWeek}>
                                回数：各週{props.activeClassForRegularCourse?.maxDaysInAWeek}日以内
                            </div>
                            <div className={styles.maxMinutesPerLesson}>
                                時間：各授業{props.activeClassForRegularCourse?.maxMinutesPerLesson}分以内
                            </div>
                            {props.activeClassForRegularCourse?.pricePerHour && (
                                <div className={styles.regularCoursePriceWrapper}>
                                    授業料：
                                    {`¥${new Intl.NumberFormat("ja-JP").format(
                                        props.activeClassForRegularCourse.pricePerHour,
                                    )}/1時間`}
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.noOpeningForStudent}>現在、受講者の募集はありません。</div>
                    </>
                )}
            </div>
        </div>
    );
});
