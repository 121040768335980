import { memo, useCallback, useState } from "react";
import { Optional } from "@/components/Tag/Optional";

import { FileInfo, TrimmingModal } from "@/components/TrimmingModal";
import styles from "@/pages/Teacher/InfoInput/index.module.scss";
import backgroundTemplate from "@/images/backgroundTemplate.jpg";

interface Props {
    setFileInfo?: React.Dispatch<React.SetStateAction<FileInfo>>;
    confirmModalOpen?: boolean;
    backgroundImageFileInfo: FileInfo | undefined;
    checked: boolean;
}

export const BackgroundImage: React.VFC<Props> = memo(function BackgroundImage(props) {
    const [trimmingModalOpen, setTrimmingModalOpen] = useState(false);
    const [targetFile, setTargetFile] = useState<File | undefined>(undefined);
    const selectImg = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            setTargetFile(file);
            handleTrimmingModalOpen();
        }
    }, []);
    const handleTrimmingModalClose = useCallback(() => {
        setTrimmingModalOpen(false);
    }, []);
    const handleTrimmingModalOpen = useCallback(() => {
        setTrimmingModalOpen(true);
    }, []);
    return (
        <div className={styles.inputItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitleWrapper}>
                    <span className={styles.itemTitle}>背景画像</span>
                </div>
                {!props.confirmModalOpen && <Optional />}
            </div>
            <div className={styles.rightWrapper}>
                {props.confirmModalOpen ? (
                    <div className={styles.openTrue}>
                        <img
                            alt="aaa"
                            src={props.backgroundImageFileInfo?.url ?? backgroundTemplate}
                            className={styles.backgroundImage}
                        />
                    </div>
                ) : (
                    <>
                        <div className={styles.notice}>先生詳細画面の背景として利用されます。</div>
                        <label className={styles.backgroundImageLabel}>
                            <img
                                alt="aaa"
                                src={props.backgroundImageFileInfo?.url ?? backgroundTemplate}
                                className={styles.backgroundImage}
                            />
                            <input type="file" accept="image/*" onChange={selectImg} className={styles.photoInput} />
                        </label>
                        <TrimmingModal
                            open={trimmingModalOpen}
                            handleClose={handleTrimmingModalClose}
                            setFileInfo={props.setFileInfo}
                            targetFile={targetFile}
                            aspect={3 / 2}
                            shape="rect"
                        />
                        {!props.backgroundImageFileInfo && (
                            <div className={styles.notice}>
                                ※ 画像をアップロードしない場合、こちらのデフォルト画像が適用されます。
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
});
