import { useSelector } from "react-redux";

import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { RootState } from "@/ducks";
import { WithdrawalContents } from "@/pageComponents/Common/Withdrawal/WithdrawalContents";
import { useGetPrivateUserQuery } from "@/store/hooks/users";

export const Withdrawal = () => {
    const userId = useSelector((state: RootState) => state.jwt.userId as string);
    const privateUserQueryState = useGetPrivateUserQuery(userId);
    return (
        <QueryLoadingWrapper {...privateUserQueryState}>
            {(privateUser) => <>{privateUser && <WithdrawalContents user={privateUser} />}</>}
        </QueryLoadingWrapper>
    );
};
