import { useCallback } from "react";
import { FaChalkboardTeacher } from "react-icons/fa";
import { MdAutoStories, MdOutlineSummarize } from "react-icons/md";
import { useHistory } from "react-router";
import styles from "./index.module.scss";
import { SearchTypeButton } from "./SearchTypeButton";

export const SearchTypes = () => {
    const history = useHistory();
    const handleTeacherButtonClick = useCallback(() => {
        history.push(
            "/SearchResults?searchType=teacher&keyword=&level=&category=&subCategory=&rankNumber=&university=&faculty=&department=&orderBy=latest",
        );
    }, []);
    const handleCourseButtonClick = useCallback(() => {
        history.push(
            "/SearchResults?searchType=course&keyword=&courseType=&level=&category=&subCategory=&rankNumber=&university=&faculty=&department=&orderBy=latest",
        );
    }, []);

    const handleRequestButtonClick = useCallback(() => {
        history.push(
            "/SearchResults?searchType=publicRequest&keyword=&courseType=&level=&category=&subCategory=&orderBy=latest",
        );
    }, []);
    return (
        <div className={styles.searchTypes}>
            <SearchTypeButton
                text="講座"
                icon={<MdAutoStories className={styles.icon} />}
                handleClick={handleCourseButtonClick}
            />
            <SearchTypeButton
                text="講座リクエスト"
                icon={<MdOutlineSummarize className={styles.icon} />}
                handleClick={handleRequestButtonClick}
            />
            <SearchTypeButton
                text="先生"
                icon={<FaChalkboardTeacher className={styles.icon} />}
                handleClick={handleTeacherButtonClick}
            />
        </div>
    );
};
