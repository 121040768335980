import { ListItem } from "./ListItem";

interface Props {
    courseTitle: string | undefined;
}

export const CourseTitle: React.VFC<Props> = (props) => {
    return (
        <ListItem title="講座名" borderBottom>
            {props.courseTitle}
        </ListItem>
    );
};
