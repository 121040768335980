import { TextField } from "@material-ui/core";
import { memo, useCallback, useEffect, useState } from "react";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Mandatory } from "@/components/Tag/Mandatory";

import styles from "@/pages/Teacher/IdentificationForStripe/index.module.scss";

interface Props {
    checked: boolean;
    handlePostalCodeChange: (value: string) => void;
}

export const PostalCode: React.VFC<Props> = memo(function PostalCode(props) {
    const [firstHalf, setFirstHalf] = useState("");
    const [secondHalf, setSecondHalf] = useState("");

    useEffect(() => {
        if (firstHalf.length === 3 && secondHalf.length === 4) {
            props.handlePostalCodeChange(`${firstHalf}${secondHalf}`);
        } else {
            props.handlePostalCodeChange("");
        }
    }, [firstHalf, secondHalf]);

    const handleFirstHalfChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        setFirstHalf(value);
    }, []);

    const handleSecondHalfChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        setSecondHalf(value);
    }, []);

    return (
        <div className={styles.formItem} style={{ borderBottom: "1px solid #CCC" }}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitle}>郵便番号</div>
                <Mandatory />
            </div>
            <div className={styles.rightWrapper}>
                <div className={styles.postalCodeWrapper}>
                    <div className={styles.bar}>〒</div>
                    <TextField
                        variant="outlined"
                        placeholder="〇〇〇"
                        className={styles.textField}
                        value={firstHalf}
                        onChange={handleFirstHalfChange}
                    />
                    <div className={styles.bar}>-</div>
                    <TextField
                        variant="outlined"
                        placeholder="〇〇〇〇"
                        className={styles.textField}
                        value={secondHalf}
                        onChange={handleSecondHalfChange}
                    />
                </div>
                <ErrorMessage
                    content="郵便番号を入力してください。"
                    when={props.checked && (firstHalf.length === 0 || secondHalf.length === 0)}
                />
                <ErrorMessage
                    content="不正な郵便番号です。"
                    when={
                        props.checked &&
                        firstHalf.length !== 0 &&
                        firstHalf.length !== 3 &&
                        secondHalf.length !== 0 &&
                        secondHalf.length !== 4
                    }
                />
                <div className={styles.autoComplete}>郵便番号を入力すると、都道府県以降が自動入力されます。</div>
            </div>
        </div>
    );
});
