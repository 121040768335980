import { Modal, useMediaQuery } from "@material-ui/core";
import { memo, useCallback } from "react";
import styles from "../index.module.scss";
import { CourseResponse, LessonResponse } from "@/store/autogenApi";
import { WhiteButton } from "@/components/Buttons/WhiteButton";
import { NavyButton } from "@/components/Buttons/NavyButton";
import { useHistory } from "react-router";
import { AiFillStar } from "react-icons/ai";
import { getSubject } from "@/utils/SubjectUtils";
import { getCourseType } from "@/utils/CourseUtils";
import { getLessonTime } from "@/utils/LessonUtils";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import defaultCourseImage from "@/images/defaultCourseImage.jpg";
import { EditIcon } from "@/globalIcons";
import { ImageFromS3 } from "@/components/atoms/images/ImageFromS3";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    modalOpen: boolean;
    targetLesson: LessonResponse;
    handleCourseDetailsModalClose: () => void;
    handleEditButtonClick: (lessonId: string) => void;
}

export const CourseDetailsModal: React.VFC<Props> = memo(function CourseDetailsModal(props: Props) {
    const history = useHistory();

    const mdUp = useMediaQuery("(min-width:960px)");

    const userMode = useSelector((state: RootState) => state.jwt.userMode);

    const isCreateCourse = location.pathname.includes("/CreateCourse");

    const getCourseScore = useCallback(() => {
        if (props.targetLesson.class?.course?.reviews) {
            if (props.targetLesson.class?.course?.reviews?.length === 0) {
                return 0;
            } else if (props.targetLesson.class?.course?.reviews?.length === 1) {
                return props.targetLesson.class?.course?.reviews[0].score;
            } else {
                const sum = props.targetLesson.class?.course?.reviews.reduce(
                    (acc, cur) => acc + (cur.score as number),
                    0,
                );
                return sum / props.targetLesson.class?.course?.reviews.length;
            }
        } else {
            return 0;
        }
    }, [props.targetLesson]);

    const handleClassButtonClick = useCallback(() => {
        props.handleCourseDetailsModalClose();
        history.push(`/Class/${props.targetLesson.class?.classId}`);
    }, [props.targetLesson]);

    const handleCourseManagementButtonClick = useCallback(() => {
        props.handleCourseDetailsModalClose();
        history.push(
            `/CourseManagement/${props.targetLesson.class?.course?.courseId}/${props.targetLesson.class?.classId}`,
        );
    }, [props.targetLesson]);

    const handleJoinButtonClick = useCallback(() => {
        props.handleCourseDetailsModalClose();
        window.open(props.targetLesson.joinUrl as string, "_blank");
    }, [props.targetLesson]);

    const handleStartButtonClick = useCallback(() => {
        props.handleCourseDetailsModalClose();
        window.open(props.targetLesson.startUrl as string, "_blank");
    }, [props.targetLesson]);

    const handleTeacherInfoButtonClick = useCallback(() => {
        props.handleCourseDetailsModalClose();
        history.push(`/TeacherDetails/${props.targetLesson.class?.course?.teacher?.teacherId}`);
    }, [props.targetLesson]);

    const isInCourseManagement = location.pathname.includes("/CourseManagement");
    const isInClass = location.pathname.includes("/Class");

    console.log(props.targetLesson);

    return (
        <Modal open={props.modalOpen} onClose={props.handleCourseDetailsModalClose}>
            <div
                className={styles.courseDetailsModalContents}
                style={{ maxWidth: isInCourseManagement || isInClass ? "400px" : "500px" }}
            >
                {isInCourseManagement && (
                    <button
                        className={styles.editButton}
                        onClick={() => {
                            props.handleEditButtonClick(props.targetLesson.lessonId);
                        }}
                    >
                        <EditIcon className={styles.editIcon} />
                    </button>
                )}
                <div className={styles.topFlex}>
                    <ImageFromS3
                        url={props.targetLesson?.class?.course?.thumbnailUrl}
                        objectKey={props.targetLesson?.class?.course?.thumbnailObjectKey}
                        className={styles.thumbnail}
                    />
                    <div className={styles.flexRightWrapper}>
                        <div className={styles.topCourseContents}>
                            <div className={styles.courseType}>
                                {getCourseType(props.targetLesson.class?.course as CourseResponse)}
                            </div>
                            <div className={styles.ratingWrapper}>
                                <AiFillStar className={styles.star} />
                                <div className={styles.averageReviewScore}>{getCourseScore()}</div>
                                <div className={styles.totalAchievements}>
                                    ({props.targetLesson.class?.course?.reviews?.length})
                                </div>
                            </div>
                        </div>
                        <div className={styles.courseDetailsTitle}>{props.targetLesson.class?.course?.title}</div>
                        {userMode === "student" && (
                            <button className={styles.teacherInfoButton} onClick={handleTeacherInfoButtonClick}>
                                <AvatarFromS3
                                    url={props.targetLesson.class.course.teacher.iconImageUrl}
                                    objectKey={props.targetLesson.class.course.teacher.iconImageObjectKey}
                                    className={styles.teacherAvatar}
                                />
                                <div className={styles.teacherNicknameWrapper}>
                                    <div className={styles.teacherNickname}>
                                        {props.targetLesson.class?.course?.teacher?.nickname}
                                    </div>
                                    <div className={styles.teacherWord}>先生</div>
                                </div>
                            </button>
                        )}
                        <div className={styles.lessonTime}>{getLessonTime(props.targetLesson)}</div>
                        {userMode === "teacher" && (
                            <ul className={styles.students}>
                                {props.targetLesson.class?.participants.map((participant) => (
                                    <li className={styles.student}>
                                        <AvatarFromS3
                                            url={participant.iconImageUrl}
                                            objectKey={participant.iconImageObjectKey}
                                            className={styles.studentAvatar}
                                        />
                                        <div className={styles.studentNicknameWrapper}>
                                            <div className={styles.studentNickname}>{participant.nickname}</div>
                                            <div className={styles.studentWord}>さん</div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                        <div className={styles.subjects}>
                            {props.targetLesson.class?.course?.subjects.map((subject) => (
                                <div className={styles.subject}>{getSubject(subject)}</div>
                            ))}
                        </div>
                        <div className={styles.courseDescription}>
                            {props.targetLesson.class?.course?.courseDescription}
                        </div>
                    </div>
                </div>
                <div
                    className={styles.buttonsWrapper}
                    style={{
                        display: isCreateCourse ? "block" : "flex",
                        width: isCreateCourse ? "fit-content" : "auto",
                    }}
                >
                    {!isInCourseManagement && !isInClass && (
                        <WhiteButton
                            handleClick={
                                userMode === "student" ? handleClassButtonClick : handleCourseManagementButtonClick
                            }
                            fontSize={mdUp ? 15 : 14}
                            isFlex={isCreateCourse ? false : true}
                        >
                            {userMode === "student" ? "クラスページ" : "クラス管理"}
                        </WhiteButton>
                    )}
                    {!isCreateCourse && (
                        <NavyButton
                            handleClick={userMode === "student" ? handleJoinButtonClick : handleStartButtonClick}
                            fontSize={mdUp ? 15 : 14}
                            isFlex
                        >
                            今すぐ{userMode === "student" ? "参加" : "開始"}
                        </NavyButton>
                    )}
                </div>
            </div>
        </Modal>
    );
});
