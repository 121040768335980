import { FAQDetailsBase } from "../../FAQDetailsBase";

export const WhatToLearn: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="Trailでは何が学べますか？"
            text="Trailでは、様々な講座を開催しています。例えば、学校の授業の復習から受験対策まで、あらゆる要望に答えることが可能です。講座が見つからない時は「講座リクエスト」をしてみましょう。生徒さんの要望を叶えられる先生が応募してくれるはずです。"
        />
    );
};
