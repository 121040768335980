import { useParams } from "react-router";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { PaymentContents } from "@/pageComponents/Student/PaymentForProposal/PaymentContents";
import { useGetPrivateUserQuery } from "@/store/hooks/users";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { useGetDefaultPaymentMethodQuery } from "@/store/hooks/stripe";
import { useGetProposalQuery } from "@/store/hooks/proposals";

export const PaymentForProposal: React.VFC = () => {
    const { proposalId } = useParams<{ proposalId: string }>();
    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);
    const userId = useSelector((state: RootState) => state.jwt.userId as string);
    const proposalQueryState = useGetProposalQuery(proposalId);
    const userQueryState = useGetPrivateUserQuery(userId);
    const defaultPaymentMethodQueryState = useGetDefaultPaymentMethodQuery(studentId);

    return (
        <QueryLoadingWrapper {...proposalQueryState}>
            {(proposal) => (
                <QueryLoadingWrapper {...userQueryState}>
                    {(user) => (
                        <QueryLoadingWrapper {...defaultPaymentMethodQueryState}>
                            {(defaultPaymentMethod) => (
                                <PaymentContents
                                    defaultPaymentMethod={defaultPaymentMethod}
                                    proposal={proposal}
                                    pointsBalance={user ? user.pointsBalance : 0}
                                />
                            )}
                        </QueryLoadingWrapper>
                    )}
                </QueryLoadingWrapper>
            )}
        </QueryLoadingWrapper>
    );
};
