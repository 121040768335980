import { FAQConfig } from "@/FAQConfig";

export const CANCEL_COURSE_CONFIGS: FAQConfig[] = [
    {
        title: "単発・短期講座",
        text: "単発・短期講座の受講停止・欠席をする手順について説明します。",
        contents: [
            {
                title: "①クラスページに移動",
                text: "受講中の講座一覧ページから、「授業管理」をクリックしてクラスページに移動してください。",
            },
            {
                title: "②先生への連絡",
                text: "クラスページの「グループチャット」から先生に受講停止・欠席の連絡をしてください。誠意ある対応をお願いします。",
            },
            {
                title: "③受講停止・欠席",
                text: "クラスページの「受講停止」をクリックして受講停止をしてください。受講停止した日時と講座開始までの時間から返金額が算出され、即時にポイントとして返金されます。なお、欠席をする場合、特に操作は必要ありませんが、返金はできません。",
            },
        ],
    },
    {
        title: "定期講座",
        text: "定期講座の受講停止・欠席をする手順について説明します。",
        contents: [
            {
                title: "①クラスページに移動",
                text: "受講中の講座一覧ページから、「授業管理」をクリックしてクラスページに移動してください。",
            },
            {
                title: "②先生への連絡",
                text: "定期講座の場合、先生が授業の日時を変更することが可能です。クラスページの「グループチャット」から先生に受講停止の連絡・日時変更のお願いをしてください。誠意ある対応をお願いします。",
            },
            {
                title: "③受講停止",
                text: "クラスページの「受講停止」をクリックして受講停止をしてください。受講停止した日時と講座開始までの時間から返金額が算出され、即時にポイントとして返金されます。",
            },
        ],
    },
];
