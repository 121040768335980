import { memo } from "react";
import { SubjectResponse } from "@/store/autogenApi";
import { CoursesWithTargetSubject } from "./CoursesWithTargetSubject";

interface Props {
    subjects: SubjectResponse[];
    repetitionNumber?: number;
}

export const CoursesWithTheSameSubjects: React.VFC<Props> = memo(function CoursesWithTheSameSubjects(props) {
    return (
        <>
            {props.subjects.slice(0, 3).map((subject) => (
                <CoursesWithTargetSubject subject={subject} repetitionNumber={props.repetitionNumber} />
            ))}
        </>
    );
});
