import { memo } from "react";
import { useParams } from "react-router";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { useGetCourseFavoriteCountQuery } from "@/store/hooks/courses";
import { CommonComponent } from "./CommonComponent";
import { FavoriteContents } from "./FavoriteContents";
import { ForStudentComponent } from "./ForStudentComponent";

interface Props {
    isSPVersion?: boolean;
    isSimplified?: boolean;
    userMode: string;
    isReservationButtonClicked: boolean;
    handleSignUpModalOpen: () => void;
    initializeReservationButtonClicked: () => void;
}

export const Favorite: React.VFC<Props> = memo(function Favorite(props) {
    const { id } = useParams<{ id: string }>();
    const favoriteCountQuery = useGetCourseFavoriteCountQuery(id);
    return (
        <QueryLoadingWrapper {...favoriteCountQuery}>
            {(favoriteCount) => {
                if (props.userMode === "student") {
                    return (
                        <ForStudentComponent
                            isSPVersion={props.isSPVersion}
                            isSimplified={props.isSimplified}
                            userMode={props.userMode}
                            favoriteCount={favoriteCount}
                            isReservationButtonClicked={props.isReservationButtonClicked}
                            handleSignUpModalOpen={props.handleSignUpModalOpen}
                            initializeReservationButtonClicked={props.initializeReservationButtonClicked}
                        />
                    );
                } else {
                    return (
                        <CommonComponent
                            isSPVersion={props.isSPVersion}
                            isSimplified={props.isSimplified}
                            userMode={props.userMode}
                            isReservationButtonClicked={props.isReservationButtonClicked}
                            favoriteCount={favoriteCount}
                            isFavorite={false}
                            handleSignUpModalOpen={props.handleSignUpModalOpen}
                            initializeReservationButtonClicked={props.initializeReservationButtonClicked}
                        />
                    );
                }
            }}
        </QueryLoadingWrapper>
    );
});
