import { Select, ThemeProvider, createTheme, makeStyles, useMediaQuery } from "@material-ui/core";
import { memo, useCallback, useEffect, useState } from "react";

import styles from "./index.module.scss";

interface Props {
    children: React.ReactNode;
    name: string;
    value: number | string | undefined;
    isBold?: boolean;
    smFontSize?: string;
    isHorizontalMenu?: boolean;
    isInlineBlock?: boolean;
    style?: React.CSSProperties;
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

export const StyledSelect: React.VFC<Props> = memo(function StyledSelect(props) {
    const [selectEl, setSelectEl] = useState<HTMLElement | null>(null);

    useEffect(() => {
        if (!selectEl) return;
        if (!props.isBold) return;
        // 子要素のdivを取得して、font-weightを変更する
        const divEl = selectEl.querySelector("div");
        if (!divEl) return;
        divEl.style.fontWeight = "bold";
    }, [selectEl, props.isBold]);

    const selectRef = useCallback((selectEl: HTMLElement | null) => {
        setSelectEl(selectEl);
    }, []);

    const smUp = useMediaQuery("(min-width:600px)");

    const theme = createTheme({
        overrides: {
            MuiSelect: {
                outlined: {
                    padding: smUp ? "15px 25px 15px 15px !important" : "10px 20px 10px 10px !important",
                    fontSize: props.smFontSize ?? (smUp ? "14px" : "13px"),
                },
                iconOutlined: {
                    right: "0px !important",
                    width: "20px",
                    height: "20px",
                },
            },
            MuiTouchRipple: {
                root: {
                    fontSize: smUp ? "14px !important" : "13px !important",
                },
            },
            MuiMenuItem: {
                root: {
                    fontSize: smUp ? "14px !important" : "13px !important",
                    padding: "5px 10px !important",
                    minHeight: "20px !important",
                },
            },
            MuiListItem: {
                gutters: {
                    paddingLeft: "10px",
                },
            },
        },
    });

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 200,
                width: "fit-content",
                overFlow: "scroll",
                color: "#305077",
                fontSize: smUp ? "14px !important" : "13px !important",
            },
        },
        anchorOrigin: props.isHorizontalMenu
            ? {
                  vertical: "top" as number | "bottom" | "top" | "center",
                  horizontal: "right" as number | "center" | "left" | "right",
              }
            : {
                  vertical: "bottom" as number | "bottom" | "top" | "center",
                  horizontal: "left" as number | "center" | "left" | "right",
              },
        transformOrigin: {
            vertical: "top" as number | "bottom" | "top" | "center",
            horizontal: "left" as number | "center" | "left" | "right",
        },
        getContentAnchorEl: null,
    };

    return (
        <div style={{ display: props.isInlineBlock ? "inline-block" : "block", ...props.style }}>
            <ThemeProvider theme={theme}>
                <Select
                    variant="outlined"
                    name={props.name}
                    value={props.value}
                    onChange={props.handleChange}
                    className={styles.select}
                    MenuProps={MenuProps}
                    ref={selectRef}
                >
                    {props.children}
                </Select>
            </ThemeProvider>
        </div>
    );
});
