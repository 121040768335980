import {
    Button,
    // Button,
    Modal,
    // TextField
} from "@material-ui/core";

import styles from "@/pages/Teacher/MyPage/index.module.scss";
import { Processing } from "@/components/Processing";

// import googleButton from "./images/googleButton.png";

interface Props {
    modalOpen: boolean;
    checkedBankAccount: any;
    actualAmount: number;
    isProcessing: boolean;
    handleModalClose: () => void;
    handleConfirmButtonClick: () => void;
}

export const TransferConfirmModal: React.VFC<Props> = (props) => {
    return (
        <Modal open={props.modalOpen} onClose={props.handleModalClose} className={styles.courseCompleteModal}>
            <div className={styles.transferConfirmModalContents}>
                <ul className={styles.items}>
                    <li className={styles.item}>
                        <div className={styles.itemTitle}>振込先</div>
                        <div className={styles.itemValue}>
                            <div className={styles.bankAccountTopWrapper}>
                                <div className={styles.bankFullName}>
                                    {props.checkedBankAccount.bankFullName ?? "〇〇銀行"}
                                </div>
                                <div className={styles.branchName}>
                                    {props.checkedBankAccount.branchName ?? "〇〇支店"}
                                </div>
                            </div>
                            <div className={styles.accountNumber}>****{props.checkedBankAccount.last4.slice(-3)}</div>
                            <div className={styles.accountHolderName}>
                                {props.checkedBankAccount.account_holder_name}
                            </div>
                        </div>
                    </li>
                    <li className={styles.item} style={{ borderBottom: "none" }}>
                        <div className={styles.itemTitle}>振込金額</div>
                        <div className={styles.itemValue}>
                            {`¥${new Intl.NumberFormat("ja-JP").format(props.actualAmount)}`}円
                        </div>
                    </li>
                </ul>
                <div className={styles.transferConfirm}>この内容で振込申請してもよろしいですか？</div>
                <div className={styles.buttonsWrapper}>
                    <Button className={styles.backButton} onClick={props.handleModalClose}>
                        戻る
                    </Button>
                    <Button className={styles.confirmButton} onClick={props.handleConfirmButtonClick}>
                        {props.isProcessing ? <Processing /> : "確定"}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
