import { Button } from "@material-ui/core";
import { BiPlusCircle } from "react-icons/bi";

import { BaseSchedulePopover } from "../BaseSchedulePopover";
import styles from "./index.module.scss";
import { CreateBaseScheduleParams, LessonResponse, RepetitionUnit } from "@/store/autogenApi";

import { memo, useCallback, useMemo, useState } from "react";
import { ErrorMessage } from "../ErrorMessage";
import { WhiteButton } from "../Buttons/WhiteButton";
import { getBaseScheduleInfo, getPricePerMonth, getTotalTimeInAMonth } from "@/utils/BaseScheduleUtils";
import { LessonCalendarButton } from "../LessonCalendarButton";
import { DeleteIcon, EditIcon } from "@/globalIcons";

interface Props {
    isRequestChecked: boolean;
    validation: boolean;
    baseSchedules: CreateBaseScheduleParams[];
    pricePerHour: number;
    isInPrivateRequestModal?: boolean;
    existingLessons: LessonResponse[];
    handleBaseScheduleDeleteButtonClick: (idx: number) => void;
    handleBaseSchedulesChange: (newBaseSchedules: CreateBaseScheduleParams[]) => void;
}

export const BaseSchedulesInput: React.VFC<Props> = memo(function WeeklyScheduleComponent(props) {
    const [newBaseSchedule, setNewBaseSchedule] = useState<Partial<CreateBaseScheduleParams> | undefined>(undefined);
    const [isNewBaseScheduleChecked, setIsNewBaseScheduleChecked] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
    const [activePopoverIdx, setActivePopoverIdx] = useState<number | undefined>(undefined);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [repetitionValidation, setRepetitionValidation] = useState<boolean>(true);

    const daysValidation = useMemo(() => {
        if (!newBaseSchedule) return false;
        const isWeekly = newBaseSchedule.repetitionUnit === "week" && newBaseSchedule.repetitionUnitNumber === 1;
        if (!isWeekly) return true;
        return newBaseSchedule.howManyTimes === newBaseSchedule.dayOfWeekIndexes?.length;
    }, [
        newBaseSchedule?.repetitionUnit,
        newBaseSchedule?.repetitionUnitNumber,
        newBaseSchedule?.howManyTimes,
        newBaseSchedule?.dayOfWeekIndexes,
    ]);

    const timeValidation = useMemo(
        () => newBaseSchedule && newBaseSchedule.howManyMinutes >= 0,
        [newBaseSchedule?.howManyMinutes],
    );

    const handleStartTimeChange = useCallback(
        (hour: number, minute: number) => {
            setNewBaseSchedule({ ...newBaseSchedule, startHour: hour, startMinute: minute });
        },
        [newBaseSchedule],
    );

    const handleHowManyMinutesChange = useCallback(
        (howManyMinutes: number) => {
            setNewBaseSchedule({ ...newBaseSchedule, howManyMinutes });
        },
        [newBaseSchedule],
    );

    const isValidBaseSchedule = (
        baseSchedule: Partial<CreateBaseScheduleParams>,
    ): baseSchedule is CreateBaseScheduleParams => {
        return (
            baseSchedule.startHour !== undefined &&
            baseSchedule.startMinute !== undefined &&
            baseSchedule.howManyMinutes !== undefined &&
            baseSchedule.howManyMinutes > 0 &&
            baseSchedule.repetitionUnitNumber !== undefined &&
            baseSchedule.repetitionUnit !== undefined &&
            baseSchedule.dayOfWeekIndexes !== undefined &&
            (baseSchedule.repetitionUnitNumber === 1 && baseSchedule.repetitionUnit === "week"
                ? baseSchedule.dayOfWeekIndexes.length === baseSchedule.howManyTimes
                : true) &&
            baseSchedule.howManyTimes !== undefined &&
            baseSchedule.isActive !== undefined
        );
    };

    const handlePopoverOpen = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, idx?: number) => {
            setPopoverOpen(true);
            setActivePopoverIdx(idx);
            setAnchorEl(event.currentTarget);
            if (idx === undefined) {
                setNewBaseSchedule({
                    isActive: false,
                    dayOfWeekIndexes: [],
                    repetitionUnitNumber: 1,
                    repetitionUnit: "week",
                    howManyTimes: 1,
                    startHour: 0,
                    startMinute: 0,
                    howManyMinutes: 0,
                });
            } else {
                setNewBaseSchedule(props.baseSchedules[idx]);
            }
        },
        [props.baseSchedules],
    );

    const handlePopoverClose = useCallback(() => {
        setPopoverOpen(false);
        setActivePopoverIdx(undefined);
        setAnchorEl(null);
        setNewBaseSchedule(undefined);
        setIsNewBaseScheduleChecked(false);
    }, []);

    const confirmBaseSchedule = useCallback(() => {
        setIsNewBaseScheduleChecked(true);
        if (!newBaseSchedule) return;
        const baseSchedule = isValidBaseSchedule(newBaseSchedule) ? newBaseSchedule : undefined;
        if (!baseSchedule) return;
        // 新規追加の場合
        if (activePopoverIdx === undefined) {
            const isOneWeek = baseSchedule.repetitionUnitNumber === 1 && baseSchedule.repetitionUnit === "week";
            const modifiedBaseSchedule = {
                ...baseSchedule,
                dayOfWeekIndexes: isOneWeek ? baseSchedule.dayOfWeekIndexes : [],
                startHour: isOneWeek ? baseSchedule.startHour : undefined,
                startMinute: isOneWeek ? baseSchedule.startMinute : undefined,
            } as CreateBaseScheduleParams;
            const newBaseSchedules = [...props.baseSchedules, modifiedBaseSchedule];
            props.handleBaseSchedulesChange(newBaseSchedules);
            handlePopoverClose();
            return;
        }
        // 編集の場合
        const newBaseSchedules = props.baseSchedules.map((targetBaseSchedule, idx) => {
            if (idx === activePopoverIdx) {
                if (baseSchedule.repetitionUnitNumber === 1 && baseSchedule.repetitionUnit === "week")
                    return baseSchedule;
                return { ...baseSchedule, dayOfWeekIndexes: [], startHour: undefined, startMinute: undefined };
            } else {
                return targetBaseSchedule;
            }
        });
        props.handleBaseSchedulesChange(newBaseSchedules);
        handlePopoverClose();
    }, [
        props.baseSchedules,
        activePopoverIdx,
        newBaseSchedule,
        props.handleBaseSchedulesChange,
        isValidBaseSchedule,
        handlePopoverClose,
    ]);

    const handleDayChange = useCallback(
        (e: React.ChangeEvent<{ checked: boolean }>, dayIdx: number) => {
            const isChecked = e.target.checked;
            const dayIndexes = [...(newBaseSchedule?.dayOfWeekIndexes ?? [])];
            if (isChecked) {
                dayIndexes.push(dayIdx);
                dayIndexes.sort((a, b) => a - b);
                setNewBaseSchedule({ ...newBaseSchedule, dayOfWeekIndexes: dayIndexes });
            } else {
                const newDayIndexes = dayIndexes.filter((dayIndex) => dayIndex !== dayIdx);
                setNewBaseSchedule({ ...newBaseSchedule, dayOfWeekIndexes: newDayIndexes });
            }
        },
        [newBaseSchedule],
    );

    const handleRepetitionUnitNumberChange = useCallback(
        (e: React.ChangeEvent<{ value: unknown }>) => {
            const value = Number(e.target.value);
            setNewBaseSchedule({ ...newBaseSchedule, repetitionUnitNumber: value });
        },
        [newBaseSchedule],
    );

    const handleRepetitionUnitChange = useCallback(
        (e: React.ChangeEvent<{ value: unknown }>) => {
            const value = e.target.value as RepetitionUnit;
            setNewBaseSchedule({ ...newBaseSchedule, repetitionUnit: value, repetitionUnitNumber: 1, howManyTimes: 1 });
            setRepetitionValidation(true);
        },
        [newBaseSchedule],
    );

    const handleHowManyTimesChange = useCallback(
        (e: React.ChangeEvent<{ value: unknown }>) => {
            const value = Number(e.target.value);
            setNewBaseSchedule({ ...newBaseSchedule, howManyTimes: value });
        },
        [newBaseSchedule],
    );

    return (
        <div className={styles.baseSchedulesWrapper}>
            {props.baseSchedules.length === 0 && (
                <div className={styles.messageWrapper}>
                    <p className={styles.message}>
                        {props.isInPrivateRequestModal
                            ? "授業を希望する基本スケジュールを提示してください。既に他の授業予定がある場合は重複にお気をつけください。"
                            : "授業の基本スケジュールを入力してください。既に他の授業予定がある場合は重複にお気をつけください。"}
                    </p>
                </div>
            )}
            {props.baseSchedules.length > 0 && (
                <ul className={styles.baseSchedules}>
                    {props.baseSchedules.map((baseSchedule, idx) => {
                        return (
                            <li key={idx} className={styles.baseSchedule}>
                                <div className={styles.baseScheduleInfo}>{getBaseScheduleInfo(baseSchedule)}</div>
                                <Button
                                    className={styles.editButton}
                                    onClick={(e) => {
                                        handlePopoverOpen(e, idx);
                                    }}
                                >
                                    <EditIcon />
                                </Button>
                                <Button
                                    className={styles.deleteButton}
                                    onClick={() => {
                                        props.handleBaseScheduleDeleteButtonClick(idx);
                                    }}
                                >
                                    <DeleteIcon />
                                </Button>
                            </li>
                        );
                    })}
                </ul>
            )}
            <BaseSchedulePopover
                open={popoverOpen}
                isNewBaseScheduleChecked={isNewBaseScheduleChecked}
                newBaseSchedule={newBaseSchedule}
                anchorEl={anchorEl}
                daysValidation={daysValidation}
                timeValidation={timeValidation}
                repetitionValidation={repetitionValidation}
                handleClose={handlePopoverClose}
                handleDayChange={handleDayChange}
                handleRepetitionUnitNumberChange={handleRepetitionUnitNumberChange}
                handleRepetitionUnitChange={handleRepetitionUnitChange}
                handleHowManyTimesChange={handleHowManyTimesChange}
                handleStartTimeChange={handleStartTimeChange}
                handleHowManyMinutesChange={handleHowManyMinutesChange}
                confirmBaseSchedule={confirmBaseSchedule}
            />
            <WhiteButton
                border={false}
                handleClick={(e) => {
                    handlePopoverOpen(e);
                }}
            >
                <BiPlusCircle />
                <div style={{ marginBottom: "2px" }}>追加</div>
            </WhiteButton>
            <div style={{ margin: "10px 0" }}>
                <ErrorMessage
                    content="この項目は必須です。少なくとも1つ選択してください。"
                    when={props.isRequestChecked && !props.validation}
                />
            </div>
            {props.validation && props.isInPrivateRequestModal && (
                <div className={styles.estimateWrapper}>
                    <div className={styles.pricePerHour}>
                        時間単価：¥{new Intl.NumberFormat("ja-JP").format(props.pricePerHour)}
                    </div>
                    <div className={styles.totalTime}>
                        月の平均合計時間：{getTotalTimeInAMonth(props.baseSchedules)}
                    </div>
                    <div className={styles.estimate}>
                        {props.isInPrivateRequestModal ? "月額の目安：" : "月額"}
                        {`¥${new Intl.NumberFormat("ja-JP").format(
                            getPricePerMonth(props.baseSchedules, props.pricePerHour),
                        )}`}
                    </div>
                    {props.isInPrivateRequestModal && (
                        <div className={styles.estimateDescription}>
                            (これはあくまで目安です。授業について要望がある場合は、以下の「申請メッセージ」で先生にお伝えください。最終的な価格はそれらを勘案したうえで先生が決定します。)
                        </div>
                    )}
                </div>
            )}
            <LessonCalendarButton lessons={[]} existingLessons={props.existingLessons} onlyView />
        </div>
    );
});
