import { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { RootState } from "@/ducks";
import { UpdateFavoriteCoursesRequestBody } from "@/store/autogenApi";
import { useUpdateFavoriteCoursesMutation } from "@/store/hooks/students";
import { FavoriteContents } from "./FavoriteContents";

interface Props {
    isSPVersion?: boolean;
    isSimplified?: boolean;
    userMode: string;
    favoriteCount: number;
    isFavorite: boolean;
    isReservationButtonClicked: boolean;
    handleSignUpModalOpen: () => void;
    initializeReservationButtonClicked: () => void;
}

export const CommonComponent: React.VFC<Props> = memo(function CommonComponent(props) {
    const { id } = useParams<{ id: string }>();
    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);
    const updateFavoriteCourses = useUpdateFavoriteCoursesMutation();

    const handleFavoriteButtonClick = useCallback(async () => {
        if (props.userMode === "student") {
            const requestBody: UpdateFavoriteCoursesRequestBody = {
                studentId: studentId,
                courseId: id,
            };
            await updateFavoriteCourses({
                updateFavoriteCoursesRequestBody: requestBody,
            });
        } else {
            props.handleSignUpModalOpen();
        }
    }, [props.userMode, studentId, id, updateFavoriteCourses, props.handleSignUpModalOpen]);
    return (
        <FavoriteContents
            isSPVersion={props.isSPVersion}
            isSimplified={props.isSimplified}
            userMode={props.userMode}
            isReservationButtonClicked={props.isReservationButtonClicked}
            favoriteCount={props.favoriteCount}
            isFavorite={props.isFavorite}
            handleSignUpModalOpen={props.handleSignUpModalOpen}
            initializeReservationButtonClicked={props.initializeReservationButtonClicked}
            handleFavoriteButtonClick={handleFavoriteButtonClick}
        />
    );
});
