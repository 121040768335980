import { Button } from "@material-ui/core";
import { memo, useCallback, useState } from "react";
import { RiCloseFill } from "react-icons/ri";

import styles from "../index.module.scss";
import { ChatInfo } from "../ChatsContents";
import { InfoIcon } from "@/globalIcons";
import { ChatInfoModal } from "./ChatInfoModal";

interface Props {
    openedChatInfo: ChatInfo | undefined;
    userMode: string;
    handleBackButtonClick: () => void;
}

export const TargetMessagesHeader: React.VFC<Props> = memo(function TargetMessagesHeader(props) {
    const [chatInfoModalOpen, setChatInfoModalOpen] = useState<boolean>(false);

    const getNickname = useCallback(() => {
        if (!props.openedChatInfo) return "";
        if (props.openedChatInfo.chatType === "individual") {
            if (props.userMode === "student") {
                return props.openedChatInfo.teachers[0].nickname;
            } else {
                return props.openedChatInfo.students[0].nickname;
            }
        }
        return `${props.openedChatInfo.class?.className}(${props.openedChatInfo.class?.course?.title})`;
    }, [props.openedChatInfo, props.userMode]);

    const handleChatInfoButtonClick = useCallback(() => {
        setChatInfoModalOpen(true);
    }, []);

    const handleChatInfoModalClose = useCallback(() => {
        setChatInfoModalOpen(false);
    }, []);

    return (
        <div className={styles.messageModalHeader}>
            <div className={styles.relative}>
                <Button className={styles.closeButton} onClick={props.handleBackButtonClick}>
                    <RiCloseFill className={styles.closeIcon} />
                </Button>
                <div className={styles.messageTitle}>{getNickname()}</div>
                <div className={styles.infoButtonWrapper}>
                    {props.openedChatInfo && (
                        <>
                            <Button className={styles.infoButton} onClick={handleChatInfoButtonClick}>
                                <InfoIcon className={styles.infoIcon} />
                            </Button>
                            <ChatInfoModal
                                chatInfo={props.openedChatInfo}
                                open={chatInfoModalOpen}
                                chatType={props.openedChatInfo.chatType}
                                onClose={handleChatInfoModalClose}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
});
