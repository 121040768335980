import { TextareaAutosize } from "@material-ui/core";

import styles from "@/pages/Teacher/MyPage/index.module.scss";
import { None } from "../Errors/None";
import { Over } from "../Errors/Over";

import { ErrorMessage } from "@/components/ErrorMessage";
import { memo } from "react";
import { Mandatory } from "@/components/Tag/Mandatory";

interface Props {
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    checked: boolean;
    edit: boolean;
    value: string | undefined;
}

export const CatchCopy: React.VFC<Props> = memo(function CatchCopy(props) {
    return (
        <>
            {props.edit ? (
                <>
                    <li className={styles.editListItem}>
                        <div className={styles.leftWrapper}>
                            <div className={styles.titleWrapper}>
                                <div className={styles.title}>キャッチコピー</div>
                                <div className={styles.limit}>(30文字以内)</div>
                                <Mandatory />
                            </div>
                        </div>
                        <div className={styles.rightWrapper}>
                            <TextareaAutosize
                                placeholder="例）丁寧な指導なら誰にも負けません"
                                className={styles.textArea}
                                name="catchCopy"
                                value={props.value}
                                onChange={props.handleChange}
                                minRows={2}
                            />
                            <ErrorMessage content="入力してください。" when={props.checked && !props.value} />
                            <ErrorMessage
                                content="30文字以内で入力してください。"
                                when={props.checked && !!props.value && props.value.length > 30}
                            />
                        </div>
                    </li>
                </>
            ) : (
                <li className={styles.profileItem} style={{ borderBottom: props.edit ? "" : "#305077 1px solid" }}>
                    <div className={styles.profileTitle}>キャッチコピー</div>
                    <div className={styles.profileSpace}></div>
                    <div className={styles.profileValue}>{props.value}</div>
                </li>
            )}
        </>
    );
});
