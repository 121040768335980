import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { memo } from "react";

import { Mandatory } from "@/components/Tag/Mandatory";
import styles from "@/pages/Student/MyPage/index.module.scss";
import { genderSet } from "@/utils/GenderSet";
import { None } from "../Errors/None";

interface Props {
    value: string;
    checked: boolean;
    edit: boolean;
    isPublic: boolean;
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

export const Gender: React.VFC<Props> = memo(function Gender(props) {
    return (
        <>
            {props.edit ? (
                <li className={styles.editListItem}>
                    <div className={styles.leftWrapper}>
                        <div className={styles.titleWrapper}>
                            <div className={styles.title}>性別</div>
                            <Mandatory />
                        </div>
                    </div>
                    <div className={styles.rightWrapper}>
                        <Select
                            variant="outlined"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="gender"
                            value={props.value}
                            onChange={props.handleChange}
                            className={styles.select}
                        >
                            <MenuItem value="male">男性</MenuItem>
                            <MenuItem value="female">女性</MenuItem>
                            <MenuItem value="other">その他</MenuItem>
                            <MenuItem value="noAnswer">回答しない</MenuItem>
                        </Select>
                        <None value={props.value} checked={props.checked} />
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="isGenderPublic"
                                onChange={props.handleChange}
                                value={props.isPublic ? "public" : "private"}
                            >
                                <FormControlLabel
                                    value="public"
                                    control={<Radio />}
                                    label="公開"
                                    className={styles.radioButtonLabel}
                                />
                                <FormControlLabel
                                    value="private"
                                    control={<Radio />}
                                    label="非公開"
                                    className={styles.radioButtonLabel}
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </li>
            ) : (
                <li className={styles.profileItem} style={{ borderBottom: props.edit ? "" : "#305077 1px solid" }}>
                    <div className={styles.profileTitleWrapper}>
                        <div className={styles.profileTitle}>性別</div>
                        <div className={styles.isPublic}>{props.isPublic ? "公開" : "非公開"}</div>
                    </div>
                    <div className={styles.profileSpace}></div>
                    <div className={styles.profileValue}>{genderSet[props.value]}</div>
                </li>
            )}
        </>
    );
});
