import { memo, useCallback, useEffect, useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";

import styles from "../index.module.scss";

import { StyledSelect } from "../../../../../StyledSelect";
import { ErrorMessage } from "../../../../../ErrorMessage";
import { WHAT_DAY_LIST } from "@/utils/WhatDayList";

interface Props {
    handleFirstDateChange?: (confirmedFirstDate: Date | undefined) => void;
    open?: boolean;
    confirmedFirstDate: Date | undefined;
    isChecked: boolean;
}

export const FirstDate: React.VFC<Props> = memo(function FirstDate(props) {
    const [year, setYear] = useState<number | undefined>(undefined);
    const [month, setMonth] = useState<number | undefined>(undefined);
    const [date, setDate] = useState<number | undefined>(undefined);
    const [yearList, setYearList] = useState<number[]>([]);
    const [monthList, setMonthList] = useState<number[]>([]);
    const [dateList, setDateList] = useState<number[]>([]);
    const [isFirstDateValid, setIsFirstDateValid] = useState<boolean>(true);

    useEffect(() => {
        const today = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);
        const oneMonthLater = getOneMonthLater(today);
        oneMonthLater.setHours(0, 0, 0, 0);
        const thisMonth = today.getMonth() + 1;
        const nextMonth = thisMonth === 12 ? 1 : thisMonth + 1;
        const monthAfterNext = nextMonth === 12 ? 1 : nextMonth + 1;
        setMonthList([thisMonth, nextMonth, monthAfterNext]);
        const thisYear = today.getFullYear();
        setYearList([thisYear, thisYear + 1]);
    }, []);

    useEffect(() => {
        if (!props.confirmedFirstDate) {
            setIsFirstDateValid(false);
            return;
        }
        setIsFirstDateValid(true);
    }, [props.confirmedFirstDate]);

    useEffect(() => {
        if (props.confirmedFirstDate) {
            const modifiedFirstDate = new Date(props.confirmedFirstDate);
            if (
                year == undefined ||
                month == undefined ||
                date == undefined ||
                (year &&
                    month &&
                    date &&
                    (modifiedFirstDate.getFullYear() !== year ||
                        modifiedFirstDate.getMonth() !== month - 1 ||
                        modifiedFirstDate.getDate() !== date))
            ) {
                setYear(modifiedFirstDate.getFullYear());
                setMonth(modifiedFirstDate.getMonth() + 1);
                setDate(modifiedFirstDate.getDate());
            }
        }
    }, [props.confirmedFirstDate, year, month, date]);

    useEffect(() => {
        if (!year || !month) return;
        const confirmedFirstDate = new Date(year, month - 1, 1);
        const lastDate = new Date(year, month, 0);
        const newDateList = [...Array(lastDate.getDate() - confirmedFirstDate.getDate() + 1).keys()].map(
            (idx) => idx + 1,
        );
        setDateList(newDateList);
    }, [year, month]);

    const handleYearChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
            const value = e.target.value as number;
            setYear(value);
            setMonth(undefined);
            setDate(undefined);
            props.handleFirstDateChange && props.handleFirstDateChange(undefined);
        },
        [month, date, props.handleFirstDateChange],
    );

    const handleMonthChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
            const value = e.target.value as number;
            setMonth(value);
            setDate(undefined);
            props.handleFirstDateChange && props.handleFirstDateChange(undefined);
        },
        [year, date, props.handleFirstDateChange],
    );

    const handleDateChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
            const value = e.target.value as number;
            setDate(value);
            if (year && month) {
                const modifiedFirstDate = new Date(year, month - 1, value);
                props.handleFirstDateChange && props.handleFirstDateChange(modifiedFirstDate);
                const threeDaysAgo = new Date(modifiedFirstDate);
                threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
                threeDaysAgo.setHours(23, 59, 59, 999);
            }
        },
        [year, month, props.handleFirstDateChange],
    );

    const getOneMonthLater = useCallback((date) => {
        // 基準の年月日を取得
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        // 基準の年月からDateオブジェクトを生成
        const nextDate = new Date(year, month);
        // 月の設定を変更
        nextDate.setMonth(nextDate.getMonth() + 1);
        // 末日を取得
        const lastDay = new Date(nextDate.getFullYear(), nextDate.getMonth() + 1, 0).getDate();
        // 元の日にちが該当月に無い場合はその月の末日を設定する
        if (lastDay < day) {
            nextDate.setDate(lastDay);
        } else {
            nextDate.setDate(day);
        }
        return nextDate;
    }, []);

    return (
        <div className={styles.firstDateWrapper}>
            <div className={styles.firstDateTitle}>開始日</div>
            {(props.confirmedFirstDate == undefined ||
                (props.confirmedFirstDate?.getFullYear() === year &&
                    props.confirmedFirstDate?.getMonth() + 1 === month &&
                    props.confirmedFirstDate?.getDate() === date)) && (
                <div className={styles.dateListWrapper}>
                    <div className={styles.dateItemWrapper}>
                        <StyledSelect name="year" value={year} handleChange={handleYearChange}>
                            <MenuItem key="" value={undefined}></MenuItem>
                            {yearList.map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </StyledSelect>
                        <div className={styles.dateItemTitle}>年</div>
                    </div>
                    <div className={styles.dateItemWrapper}>
                        <StyledSelect name="month" value={month} handleChange={handleMonthChange}>
                            <MenuItem key="" value={undefined}></MenuItem>
                            {monthList.map((month) => (
                                <MenuItem key={month} value={month}>
                                    {month}
                                </MenuItem>
                            ))}
                        </StyledSelect>
                        <div className={styles.dateItemTitle}>月</div>
                    </div>
                    <div className={styles.dateItemWrapper}>
                        <StyledSelect name="date" value={date} handleChange={handleDateChange}>
                            <MenuItem key="" value={undefined}></MenuItem>
                            {dateList.map((date) => (
                                <MenuItem key={date} value={date}>
                                    {date}
                                </MenuItem>
                            ))}
                        </StyledSelect>
                        <div className={styles.dateItemTitle}>日</div>
                    </div>
                    {props.confirmedFirstDate != undefined && (
                        <div className={styles.dateItemWrapper}>
                            <div className={styles.dateItemTitle}>{`(${
                                WHAT_DAY_LIST[props.confirmedFirstDate.getDay()]
                            })`}</div>
                        </div>
                    )}
                </div>
            )}
            <ErrorMessage content="入力してください。" when={props.isChecked && (!year || !month || !date)} />
            <ErrorMessage content="1カ月後以内の日付を入力してください。" when={props.isChecked && !isFirstDateValid} />
            <div className={styles.rewardDescription}>
                定期講座の初回報酬の支払いは、各生徒さんの初回授業日の1か月後に行われます。
                <br />
                （例1 初回授業:1月1日 報酬支払い:2月1日, 例2 初回授業:1月31日 報酬支払い:3月1日）
                <br />
                以降受講が続く限り、1か月ごとに報酬が支払われます。
            </div>
        </div>
    );
});
