export const zenkakuToHankaku = (str: string) => {
    if (!str) return ""; // 偽であれば空文字を返す

    const hankaku_eisu = () => {
        if (!str) return "";
        return String.fromCharCode(str.charCodeAt(0) - 0xfee0);
    };

    str = str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, hankaku_eisu); // 英数
    str = str.replace(/[−―‐ー]/g, "-"); // 色々なハイフンも統一
    str = str.replace(/＠/g, "@"); // @マークも親切に統一
    str = str.replace(/[ts ]/g, ""); // ついでにタブやスペースを消す
    return str;
};
