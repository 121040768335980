import { useSelector } from "react-redux";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { MyPage } from ".";
import { RootState } from "@/ducks";
import { memo, useEffect } from "react";
import { ProceedsRecordsContents } from "@/pageComponents/Teacher/MyPage/ProceedsRecords";
import { useGetProceedsRecordsQuery } from "@/store/hooks/proceedsRecords";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { Navigation } from "@/components/Navigation";

const NAVIGATION_LIST: Navigation[] = [
    { title: "マイページ", url: "/Teacher/MyPage/Profile" },
    { title: "ポイント履歴", url: "/Teacher/MyPage/PointsRecordHistory" },
];

export const ProceedsRecords: React.VFC = memo(function ProceedsRecords() {
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);
    const proceedsRecordsQueryState = useGetProceedsRecordsQuery(teacherId);

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    return (
        <MyPage title="売上金履歴" activeTab="proceedsRecords">
            <QueryLoadingWrapper {...proceedsRecordsQueryState}>
                {(proceedsRecords) => <ProceedsRecordsContents proceedsRecords={proceedsRecords} />}
            </QueryLoadingWrapper>
        </MyPage>
    );
});
