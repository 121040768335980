import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { useGetDefaultPaymentMethodQuery, useGetPaymentMethodsQuery } from "@/store/hooks/stripe";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { PaymentSettingContents } from "@/pageComponents/Student/MyPage/PaymentSetting/PaymentSettingContents";
import { memo, useEffect } from "react";
import { MyPage } from ".";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { Navigation } from "@/components/Navigation";

const NAVIGATION_LIST: Navigation[] = [
    { title: "マイページ", url: "/MyPage/Profile" },
    { title: "お支払い設定", url: "/MyPage/PaymentSetting" },
];

export const PaymentSetting: React.VFC = memo(function PaymentSetting() {
    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);
    const paymentMethodsQueryState = useGetPaymentMethodsQuery(studentId);
    const defaultPaymentMethodQueryState = useGetDefaultPaymentMethodQuery(studentId);

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    return (
        <MyPage title="お支払い設定" activeTab="paymentSetting">
            <QueryLoadingWrapper {...paymentMethodsQueryState}>
                {(paymentMethods) => (
                    <QueryLoadingWrapper {...defaultPaymentMethodQueryState}>
                        {(defaultPaymentMethod) => (
                            <PaymentSettingContents
                                paymentMethods={paymentMethods}
                                defaultPaymentMethod={defaultPaymentMethod}
                            />
                        )}
                    </QueryLoadingWrapper>
                )}
            </QueryLoadingWrapper>
        </MyPage>
    );
});
