import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { FileInfo, RealName } from "@/pages/Teacher/Identification";
import styles from "@/pages/Teacher/Identification/index.module.scss";
import { Description } from "./Description";
import { IdentificationModal } from "./IdentificationModal";
import { IdCard } from "./Items/IdCard";
import { RealNameComponent } from "./Items/RealName";
import { StudentCard } from "./Items/StudentCard";
import { memo } from "react";

interface Props {
    isChecked: boolean;
    confirmModalOpen: boolean;
    isComplete: boolean;
    isError: boolean;
    realName: RealName;
    modalHeight: number;
    idCardFileInfo: FileInfo | undefined;
    studentCardFileInfo: FileInfo | undefined;
    handleOpen: () => void;
    handleClose: () => void;
    handleIdCardChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleIdCardDeleteButtonClick: () => void;
    handleStudentCardChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleStudentCardDeleteButtonClick: () => void;
    handleConfirmButtonClick: () => void;
    handleRealNameChange: (key: keyof RealName, value: string) => void;
}

export const FormContents: React.VFC<Props> = memo(function FormContents(props) {
    return (
        <div className={styles.identificationWrapper}>
            {props.isError ? (
                <div className={styles.errorWrapper}>
                    <div className={styles.error}>エラーが発生しました。</div>
                    <Button className={styles.homeButton} component={Link} to="/">
                        ホームに戻る
                    </Button>
                </div>
            ) : (
                <>
                    {props.isComplete ? (
                        <div className={styles.completeWrapper}>
                            <div className={styles.complete}>本人確認手続きが完了しました。</div>
                            <div className={styles.complete}>審査結果をお待ちください。</div>
                            <Button className={styles.homeButton} component={Link} to="/">
                                ホームに戻る
                            </Button>
                        </div>
                    ) : (
                        <>
                            <Description />
                            <div className={styles.form}>
                                <RealNameComponent
                                    isChecked={props.isChecked}
                                    realName={props.realName}
                                    handleRealNameChange={props.handleRealNameChange}
                                />
                                <IdCard
                                    handleIdCardChange={props.handleIdCardChange}
                                    handleIdCardDeleteButtonClick={props.handleIdCardDeleteButtonClick}
                                    temporaryUrl={props.idCardFileInfo?.temporaryUrl}
                                    isChecked={props.isChecked}
                                />
                                <StudentCard
                                    handleStudentCardChange={props.handleStudentCardChange}
                                    handleStudentCardDeleteButtonClick={props.handleStudentCardDeleteButtonClick}
                                    temporaryUrl={props.studentCardFileInfo?.temporaryUrl}
                                    isChecked={props.isChecked}
                                />
                            </div>
                            <Button className={styles.checkButton} variant="contained" onClick={props.handleOpen}>
                                確認
                            </Button>
                            <IdentificationModal
                                modalHeight={props.modalHeight}
                                realName={props.realName}
                                idCardTemporaryUrl={props.idCardFileInfo?.temporaryUrl}
                                studentCardTemporaryUrl={props.studentCardFileInfo?.temporaryUrl}
                                confirmModalOpen={props.confirmModalOpen}
                                handleClose={props.handleClose}
                                handleConfirmButtonClick={props.handleConfirmButtonClick}
                            />
                        </>
                    )}
                </>
            )}
        </div>
    );
});
