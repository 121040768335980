import { FAQDetailsBase } from "../../FAQDetailsBase";

import { POST_REVIEW_CONFIGS } from "./PostReviewConfigs";

export const PostReview: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="レビューを投稿する"
            text="このページでは、レビューを投稿する手順についてご説明します"
            FAQConfigs={POST_REVIEW_CONFIGS}
        />
    );
};
