import { useCallback, useContext, useState } from "react";

import { Button, Hidden } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { logout } from "@/actions/logging";
import styles from "../index.module.scss";

import { ProfileMenu } from "./ProfileMenu";
import { StyledTooltip } from "./StyledToolTip";
import { PrivateTeacherResponse, PrivateUserResponse } from "@/store/autogenApi";
import { Auth } from "aws-amplify";
import { CurrentCognitoUserContext } from "../../Authentication";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    teacher: PrivateTeacherResponse;
    user: PrivateUserResponse;
    handleSwitchButtonClick: () => void;
}

export const Profile: React.VFC<Props> = (props) => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const [isProcessingForSignOut, setIsProcessingForSignOut] = useState<boolean>(false);

    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    };

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const history = useHistory();
    const handleLinkButtonClick = (params: string) => {
        history.push(`${params}`);
        handleTooltipClose();
        handleDrawerClose();
    };

    const dispatch = useDispatch();
    const { setCurrentCognitoUser } = useContext(CurrentCognitoUserContext);

    const handleSignOutButtonClick = useCallback(async () => {
        setIsProcessingForSignOut(true);
        await Auth.signOut();
        dispatch(logout());
        localStorage.clear();
        setCurrentCognitoUser(undefined);
        history.push("/");
        setIsProcessingForSignOut(false);
    }, [history, dispatch, setCurrentCognitoUser]);

    const handleCreateCourseButtonClick = () => {
        history.push("/CreateCourse");
        handleTooltipClose();
        handleDrawerClose();
    };

    return (
        <>
            {/* スマホ版 */}
            <Hidden mdUp>
                <Button className={styles.iconButton} onClick={handleDrawerOpen}>
                    <AvatarFromS3
                        url={props.teacher.iconImageUrl}
                        objectKey={props.teacher.iconImageObjectKey}
                        className={styles.avatar}
                    />
                </Button>
                <Drawer open={drawerOpen} onClose={handleDrawerClose} anchor="right">
                    <ProfileMenu
                        handleLinkButtonClick={handleLinkButtonClick}
                        handleSignOutButtonClick={handleSignOutButtonClick}
                        handleSwitchButtonClick={props.handleSwitchButtonClick}
                        handleCreateCourseButtonClick={handleCreateCourseButtonClick}
                        isProcessingForSignOut={isProcessingForSignOut}
                        teacher={props.teacher}
                        user={props.user}
                    />
                </Drawer>
            </Hidden>
            {/* PC版 */}
            <Hidden smDown>
                <StyledTooltip
                    title={
                        <ProfileMenu
                            handleLinkButtonClick={handleLinkButtonClick}
                            handleSignOutButtonClick={handleSignOutButtonClick}
                            handleSwitchButtonClick={props.handleSwitchButtonClick}
                            handleCreateCourseButtonClick={handleCreateCourseButtonClick}
                            isProcessingForSignOut={isProcessingForSignOut}
                            teacher={props.teacher}
                            user={props.user}
                        />
                    }
                    interactive
                    open={tooltipOpen}
                    onMouseEnter={handleTooltipOpen}
                    onClose={handleTooltipClose}
                    placement="bottom-start"
                >
                    <Button
                        aria-label="account of current user"
                        color="inherit"
                        className={styles.iconButton}
                        disableRipple
                    >
                        <AvatarFromS3
                            url={props.teacher.iconImageUrl}
                            objectKey={props.teacher.iconImageObjectKey}
                            className={styles.avatar}
                        />
                    </Button>
                </StyledTooltip>
            </Hidden>
        </>
    );
};
