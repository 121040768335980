import { createTheme, MuiThemeProvider, Select } from "@material-ui/core";

import styles from "@/pages/Teacher/MyPage/index.module.scss";

interface Props {
    children: React.ReactNode;
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

export const StyledSelect: React.VFC<Props> = (props) => {
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 150,
                width: "fit-content",
                color: "#305077",
            },
        },
        anchorOrigin: {
            vertical: "top" as number | "bottom" | "top" | "center",
            horizontal: "right" as number | "center" | "left" | "right",
        },
        transformOrigin: {
            vertical: "top" as number | "bottom" | "top" | "center",
            horizontal: "left" as number | "center" | "left" | "right",
        },
    };

    const theme = createTheme({
        overrides: {
            MuiSelect: {
                select: {
                    width: "fit-content",
                    height: "100px",
                    color: "#305077",
                },
            },
            MuiListItem: {
                root: {
                    minHeight: "30px !important",
                },
            },
        },
    });

    return (
        <MuiThemeProvider theme={theme}>
            <Select
                variant="outlined"
                defaultValue="choose"
                onChange={props.handleChange}
                className={styles.select}
                MenuProps={MenuProps}
            >
                {props.children}
            </Select>
        </MuiThemeProvider>
    );
};
