import { memo } from "react";
import { Button } from "@material-ui/core";
import styles from "./index.module.scss";
import { Processing } from "../Processing";

interface Props {
    isCentered?: boolean;
    isFlex?: boolean;
    fontSize?: number;
    className?: string;
    style?: React.CSSProperties;
    children: React.ReactNode;
    isLoading?: boolean;
    handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const NavyButton: React.VFC<Props> = memo(function NavyButton(props) {
    return (
        <Button
            className={`${props.className} ${styles.navyButton} ${props.isCentered ? `${styles.center}` : ``}`}
            onClick={props.handleClick}
            style={{
                flex: props.isFlex ? "1" : "auto",
                fontSize: props.fontSize ? `${props.fontSize}px` : "14px",
                ...props.style,
            }}
        >
            {props.isLoading ? <Processing /> : props.children}
        </Button>
    );
});
