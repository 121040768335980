import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Hidden, useMediaQuery } from "@material-ui/core";
import { LatestRequestsForRegularCourse } from "@/pageComponents/Teacher/TopPage/LatestRequestsForRegularCourse";
import { LatestRequestsForShortCourse } from "@/pageComponents/Teacher/TopPage/LatestRequestsForShortCourse";
import { SearchBySubject } from "@/components/SearchBySubject";
import styles from "./teacher.module.scss";
import { NewsList } from "@/pageComponents/Common/TopPage/NewsList";
import { TopImageCarousel } from "@/pageComponents/Common/TopPage/TopImageCarousel";
import { useNavigation } from "@/components/Navigation/NavigationContext";

export const TeacherTopPage: React.VFC = memo(function TeacherTopPage() {
    const topPageContentsEl: React.MutableRefObject<HTMLElement | null | undefined> = useRef();
    const [carouselWrapperEl, setCarouselWrapperEl] = useState<HTMLElement | null>(null);
    const [repetitionNumber, setRepetitionNumber] = useState<number | undefined>(undefined);

    const threshold1 = useMediaQuery("(min-width:960px)");
    const threshold2 = useMediaQuery("(min-width:1120px)");
    const threshold3 = useMediaQuery("(min-width:1280px)");

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList([]);
    }, []);

    useEffect(() => {
        if (!threshold1) {
            setRepetitionNumber(undefined);
            return;
        }
        if (!threshold2) {
            setRepetitionNumber(14);
            return;
        }
        if (!threshold3) {
            setRepetitionNumber(14);
            return;
        }
        setRepetitionNumber(14);
    }, [threshold1, threshold2, threshold3]);

    const carouselWrapperRef = useCallback((el: HTMLElement | null) => {
        setCarouselWrapperEl(el);
    }, []);

    useEffect(() => {
        window.addEventListener("resize", () => {
            changeImageWrapperWidth();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeImageWrapperWidth();
            });
        };
    }, []);
    useEffect(() => {
        changeImageWrapperWidth();
    }, [topPageContentsEl.current]);
    const changeImageWrapperWidth = () => {
        const topPageContents = topPageContentsEl.current;
        if (topPageContents && carouselWrapperEl) {
            const newScrollWidth = Math.floor(topPageContents.offsetWidth);
            carouselWrapperEl.style.maxWidth = `${newScrollWidth}px`;
        }
    };
    return (
        <div
            className={styles.teacherTopPageContents}
            ref={(node: HTMLElement | null) => {
                topPageContentsEl.current = node;
            }}
        >
            <TopImageCarousel />
            {/* PC版 */}
            <Hidden smDown>
                <div className={styles.contentsWrapper}>
                    {/* <HistoryOfRequestForRegularCourse repetitionNumber={repetitionNumber} />
                    <HistoryOfRequestForShortCourse repetitionNumber={repetitionNumber} /> */}
                    <LatestRequestsForRegularCourse repetitionNumber={repetitionNumber} />
                    <LatestRequestsForShortCourse repetitionNumber={repetitionNumber} />
                    {/* <ApproachingDeadlineRequestsForRegularCourse repetitionNumber={repetitionNumber} />
                    <ApproachingDeadlineRequestsForShortCourse repetitionNumber={repetitionNumber} /> */}
                    <SearchBySubject mode="teacher" />
                    <NewsList />
                </div>
            </Hidden>
            {/* スマホ・タブレット版 */}
            <Hidden mdUp>
                <div className={styles.contentsWrapper}>
                    {/* <HistoryOfRequestForRegularCourse repetitionNumber={repetitionNumber} />
                    <HistoryOfRequestForShortCourse repetitionNumber={repetitionNumber} /> */}
                    <LatestRequestsForRegularCourse repetitionNumber={repetitionNumber} />
                    <LatestRequestsForShortCourse repetitionNumber={repetitionNumber} />
                    {/* <ApproachingDeadlineRequestsForRegularCourse repetitionNumber={repetitionNumber} />
                    <ApproachingDeadlineRequestsForShortCourse repetitionNumber={repetitionNumber} /> */}
                    <SearchBySubject mode="teacher" />
                    <NewsList />
                </div>
            </Hidden>
        </div>
    );
});
