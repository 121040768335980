import { FAQDetailsBase } from "../../FAQDetailsBase";

export const RegistrationRequired: React.VFC = () => {
    return (
        <FAQDetailsBase
            title="利用するのに登録が必要ですか？"
            text="講座の閲覧は登録なしで可能ですが、講座を受けるには生徒登録、講座を開催するには先生登録がそれぞれ必要です。"
        />
    );
};
