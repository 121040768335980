import { memo, useEffect } from "react";
import { useState } from "react";

import { useSelector } from "react-redux";
import { Contents } from "@/pageComponents/Student/MyPage/Profile/Contents";
import { useGetPrivateStudentQuery, useGetReservedClassesQuery } from "@/store/hooks/students";
import { useGetPrivateUserQuery } from "@/store/hooks/users";

import { MyPage } from ".";
import { RootState } from "@/ducks";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { PrivateUserResponse } from "@/store/autogenApi";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { Navigation } from "@/components/Navigation";

const NAVIGATION_LIST: Navigation[] = [
    { title: "マイページ", url: "/MyPage/Profile" },
    { title: "プロフィール", url: "/MyPage/Profile" },
];

export const Profile: React.VFC = memo(function Profile() {
    const [edit, setEdit] = useState<boolean>(false);

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);
    const userId = useSelector((state: RootState) => state.jwt.userId as string);

    const userQueryState = useGetPrivateUserQuery(userId);
    const studentQueryState = useGetPrivateStudentQuery(studentId);
    const takenClassesQueryState = useGetReservedClassesQuery(studentId);

    return (
        <MyPage title="プロフィール" activeTab="profile">
            <QueryLoadingWrapper {...userQueryState}>
                {(user) => (
                    <QueryLoadingWrapper {...studentQueryState}>
                        {(student) => (
                            <QueryLoadingWrapper {...takenClassesQueryState}>
                                {(takenClasses) => (
                                    <Contents
                                        existingStudent={student}
                                        existingUser={user as PrivateUserResponse}
                                        takenClasses={takenClasses}
                                        edit={edit}
                                        setEdit={setEdit}
                                    />
                                )}
                            </QueryLoadingWrapper>
                        )}
                    </QueryLoadingWrapper>
                )}
            </QueryLoadingWrapper>
        </MyPage>
    );
});
