import { memo } from "react";
import { WhiteButton } from "../Buttons/WhiteButton";
import { NavyButton } from "../Buttons/NavyButton";
import { RedButton } from "../Buttons/RedButton";
import styles from "./index.module.scss";

interface Props {
    pairType: number;
    // 0: 削除系（白：赤）
    // 1: 通常（白：青）
    leftButtonContents: React.ReactNode;
    rightButtonContents: React.ReactNode;
    handleLeftButtonClick: () => void;
    handleRightButtonClick: () => void;
}

export const ButtonPair: React.VFC<Props> = memo(function ButtonPair(props) {
    if (props.pairType === 0) {
        return (
            <div className={styles.buttonPair}>
                <WhiteButton handleClick={props.handleLeftButtonClick} isFlex border>
                    {props.leftButtonContents}
                </WhiteButton>
                <RedButton handleClick={props.handleRightButtonClick} isFlex>
                    {props.rightButtonContents}
                </RedButton>
            </div>
        );
    }
    return (
        <div className={styles.buttonPair}>
            <WhiteButton handleClick={props.handleLeftButtonClick} isFlex>
                {props.leftButtonContents}
            </WhiteButton>
            <NavyButton handleClick={props.handleRightButtonClick} isFlex>
                {props.rightButtonContents}
            </NavyButton>
        </div>
    );
});
