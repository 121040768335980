import { useCallback, useEffect, useState } from "react";

import { Button, Modal } from "@material-ui/core";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";

import styles from "@/pages/Teacher/InfoInput/index.module.scss";

import { SelectTemplate } from "../SelectTemplate";

import { Belonging } from "..";
import { Universities } from "./Universities";
import { EducationalBackgroundResponse } from "@/store/autogenApi";

interface Props {
    open: boolean;
    universities: string[];
    faculties: string[];
    departments: string[];
    educationalBackground: Belonging;
    isConfirmed: boolean;
    setEducationalBackground: React.Dispatch<React.SetStateAction<Belonging>>;
    handleClose: () => void;
    handleFacultyChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleDepartmentChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleBackButtonClick: () => void;
    handleIsConfirmedChange: () => void;
    handleUniversityButtonClick: (university: string, educationalBackgrounds: EducationalBackgroundResponse[]) => void;
    handleBelongingNarrowDownButtonClick: () => void;
    handleNoUniversityCheckChange: (checked: boolean) => void;
}

export const UniversitiesSearchModal: React.VFC<Props> = (props) => {
    const [initial, setInitial] = useState<string>("");
    const [modalHeight, setModalHeight] = useState<number>(0);
    const JAPANESE_SYLLABARIES_LIST = [
        ["あ", "い", "う", "え", "お"],
        ["か", "き", "く", "け", "こ"],
        ["さ", "し", "す", "せ", "そ"],
        ["た", "ち", "つ", "て", "と"],
        ["な", "に", "ぬ", "ね", "の"],
        ["は", "ひ", "ふ", "へ", "ほ"],
        ["ま", "み", "む", "め", "も"],
        ["や", "", "ゆ", "", "よ"],
        ["ら", "り", "る", "れ", "ろ"],
        ["わ", "", "を", "", "ん"],
    ];

    const changeModalHeight = useCallback(() => {
        const innerHeight = window.innerHeight;
        setModalHeight(innerHeight * 0.75);
    }, []);

    useEffect(() => {
        changeModalHeight();
        window.addEventListener("resize", () => {
            changeModalHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalHeight();
            });
        };
    }, []);

    const handleInitialClick = useCallback((val: string) => {
        props.handleNoUniversityCheckChange(false);
        setInitial(val);
    }, []);

    const handleClose = useCallback(() => {
        setInitial("");
        props.handleClose();
    }, []);

    return (
        <Modal open={props.open} onClose={handleClose}>
            <div className={styles.universityModalContents} style={{ outline: "none" }}>
                <div className={styles.universitySearchTitleWrapper}>
                    {props.universities.length > 0 ? (
                        <Button className={styles.backButton} onClick={handleClose}>
                            <BackIcon className={styles.backIcon} />
                        </Button>
                    ) : (
                        <Button className={styles.closeButton} onClick={handleClose}>
                            <CloseIcon className={styles.closeIcon} />
                        </Button>
                    )}
                    <div className={styles.universitySearchTitle}>大学検索</div>
                </div>
                {initial ? (
                    <>
                        {props.educationalBackground.university ? (
                            <div className={styles.targetEducationalBackgroundWrapper}>
                                {/* <Button onClick={searchAgain}>再検索</Button> */}
                                <div className={styles.centerWrapper}>
                                    <div className={styles.university}>
                                        大学：{props.educationalBackground.university}
                                    </div>
                                    {props.educationalBackground.faculty != undefined && (
                                        <SelectTemplate
                                            title="学部"
                                            educationalBackgroundKey="faculty"
                                            value={props.educationalBackground.faculty}
                                            items={props.faculties}
                                            handleChange={props.handleFacultyChange}
                                        />
                                    )}
                                    {props.educationalBackground.department != undefined && (
                                        <SelectTemplate
                                            title="学科"
                                            educationalBackgroundKey="department"
                                            value={props.educationalBackground.department}
                                            items={props.departments}
                                            handleChange={props.handleDepartmentChange}
                                        />
                                    )}
                                </div>
                                <Button
                                    className={styles.educationalBackgroundNarrowDownButton}
                                    onClick={props.handleIsConfirmedChange}
                                >
                                    確定
                                </Button>
                            </div>
                        ) : (
                            <Universities
                                initial={initial}
                                handleUniversityButtonClick={props.handleUniversityButtonClick}
                            />
                        )}
                    </>
                ) : (
                    <div className={styles.syllabariesWrapper}>
                        <div className={styles.description}>大学名の頭文字を選択してください。</div>
                        <div className={styles.syllabariesContents}>
                            {JAPANESE_SYLLABARIES_LIST.map((syllabaries, idx1) => {
                                return (
                                    <div className={styles.syllabaries} key={idx1}>
                                        {syllabaries.map((syllabary, idx2) => {
                                            return (
                                                <Button
                                                    className={
                                                        syllabary
                                                            ? `${styles.syllabaryButton}`
                                                            : `${styles.syllabaryButton} ${styles.space}`
                                                    }
                                                    key={idx2}
                                                    onClick={() => {
                                                        handleInitialClick(syllabary);
                                                    }}
                                                >
                                                    {syllabary}
                                                </Button>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};
