import { Gender } from "@/store/autogenApi";

type GenderSet = Record<Gender, string>;

export const genderSet: GenderSet = {
    male: "男性",
    female: "女性",
    other: "その他",
    noAnswer: "回答しない",
};
