import { memo, useCallback, useEffect } from "react";
import { useState } from "react";

import queryString from "query-string";
import { useHistory } from "react-router";

import {
    HIGH_SCHOOL_SUBJECT_CONFIG,
    JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG,
    ELEMENTARY_SCHOOL_SUBJECT_CONFIG,
    SubjectConfig,
    UNIVERSITY_SUBJECT_CONFIG,
} from "@/SubjectConfig";
import { QueryLoadingWrapperOnlyPart } from "@/components/QueryLoadingWrapper/QueryLoadingWrapperPart";
import { RequestSearchResultsComponent } from "@/pageComponents/Common/RequestSearchResults";
import { useGetPublicRequestSearchResultsQuery } from "@/store/hooks/publicRequests";
import { CourseType, OrderBy } from "@/store/autogenApi";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { Navigation } from "@/components/Navigation";

export interface Props {
    query: queryString.ParsedQuery<string>;
}

export interface Subject {
    level: string;
    category: string;
    subCategory: string;
}

const NAVIGATION_LIST: Navigation[] = [{ title: "講座リクエスト検索", url: "/SearchResults?searchType=publicRequest" }];

export const RequestSearchResults: React.VFC<Props> = memo(function RequestSearchResults(props) {
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId);
    const requestSearchResultQuery = useGetPublicRequestSearchResultsQuery({
        keyword: typeof props.query.keyword === "string" ? props.query.keyword : undefined,
        courseType:
            typeof props.query.courseType === "string" && props.query.courseType !== ""
                ? (props.query.courseType as CourseType)
                : undefined,
        level: typeof props.query.level === "string" ? props.query.level : undefined,
        category: typeof props.query.category === "string" ? props.query.category : undefined,
        subCategory: typeof props.query.subCategory === "string" ? props.query.subCategory : undefined,
        orderBy: props.query.orderBy as OrderBy,
        teacherId: teacherId ?? undefined,
    });
    const [subject, setSubject] = useState<Subject>({
        level: "",
        category: "",
        subCategory: "",
    });
    const [keyword, setKeyword] = useState<string>("");
    const [courseType, setCourseType] = useState<string>("");
    const [orderBy, setOrderBy] = useState<OrderBy>("latest");
    const [pageNumber, setPageNumber] = useState(1);
    const [existCondition, setExistCondition] = useState<boolean>(false);

    const history = useHistory();

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    useEffect(() => {
        (async () => {
            setKeyword(props.query.keyword as string);
            setSubject({
                level: props.query.level as string,
                category: props.query.category as string,
                subCategory: props.query.subCategory as string,
            });
            const orderBy =
                !props.query.orderBy || props.query.orderBy === "" ? "latest" : (props.query.orderBy as OrderBy);
            setOrderBy(orderBy);
            setCourseType(props.query.courseType as string);
            if (props.query.pageNumber) {
                setPageNumber(Number(props.query.pageNumber));
            }
            if (
                props.query.keyword ||
                props.query.courseType ||
                props.query.level ||
                props.query.category ||
                props.query.subCategory
            ) {
                setExistCondition(true);
            } else {
                setExistCondition(false);
            }
        })();
    }, [history.location.search]);

    const getCourseTypeCondition = useCallback(() => {
        if (props.query.courseType === "regular") {
            return "定期講座";
        } else if (props.query.courseType === "short") {
            return "単発・短期講座";
        } else {
            return "";
        }
    }, [props.query.courseType]);

    const getSubjectCondition = useCallback(() => {
        let targetSubjectConfig: SubjectConfig;
        let level: string;
        if (props.query.level === "university") {
            targetSubjectConfig = UNIVERSITY_SUBJECT_CONFIG;
            level = "大学生・社会人";
        } else if (props.query.level === "highSchool") {
            targetSubjectConfig = HIGH_SCHOOL_SUBJECT_CONFIG;
            level = "高校生";
        } else if (props.query.level === "juniorHighSchool") {
            targetSubjectConfig = JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG;
            level = "中学生";
        } else if (props.query.level === "elementarySchool") {
            targetSubjectConfig = ELEMENTARY_SCHOOL_SUBJECT_CONFIG;
            level = "小学生";
        } else {
            return "";
        }
        if (!props.query.category || typeof props.query.category !== "string") return level;
        const category = targetSubjectConfig[props.query.category].label;
        const subCategories = targetSubjectConfig[props.query.category].sub;
        if (subCategories && props.query.subCategory && typeof props.query.subCategory === "string") {
            const subCategory = subCategories[props.query.subCategory].label;
            return `${level}-${category}-${subCategory}`;
        } else {
            return `${level}-${category}`;
        }
    }, [props.query.level, props.query.category, props.query.subCategory]);

    const search = useCallback(() => {
        const keywordQueryParams = keyword ? `&keyword=${keyword}` : "";
        const courseTypeQueryParams = courseType ? `&courseType=${courseType}` : "";
        const levelQueryParams = subject.level ? `&level=${subject.level}` : "";
        const categoryQueryParams = subject.category ? `&category=${subject.category}` : "";
        const subCategoryQueryParams = subject.subCategory ? `&subCategory=${subject.subCategory}` : "";
        const orderByQueryParams = orderBy ? `&orderBy=${orderBy}` : "";
        const queryParams = [
            keywordQueryParams,
            courseTypeQueryParams,
            levelQueryParams,
            categoryQueryParams,
            subCategoryQueryParams,
            orderByQueryParams,
        ].join("");
        history.push(`/SearchResults?searchType=publicRequest${queryParams}`);
    }, [keyword, courseType, subject.level, subject.category, subject.subCategory, history]);

    const deleteKeywordCondition = useCallback(() => {
        const courseTypeQueryParams = props.query.courseType ? `&courseType=${props.query.courseType}` : "";
        const levelQueryParams = props.query.level ? `&level=${props.query.level}` : "";
        const categoryQueryParams = props.query.category ? `&category=${props.query.category}` : "";
        const subCategoryQueryParams = props.query.subCategory ? `&subCategory=${props.query.subCategory}` : "";
        const orderByQueryParams = props.query.orderBy ? `&orderBy=${props.query.orderBy}` : "";
        const queryParams = [
            courseTypeQueryParams,
            levelQueryParams,
            categoryQueryParams,
            subCategoryQueryParams,
            orderByQueryParams,
        ].join("");
        history.push(`/SearchResults?searchType=publicRequest${queryParams}`);
    }, [props.query]);

    const deleteCourseTypeCondition = useCallback(() => {
        const keywordQueryParams = props.query.keyword ? `&keyword=${props.query.keyword}` : "";
        const levelQueryParams = props.query.level ? `&level=${props.query.level}` : "";
        const categoryQueryParams = props.query.category ? `&category=${props.query.category}` : "";
        const subCategoryQueryParams = props.query.subCategory ? `&subCategory=${props.query.subCategory}` : "";
        const orderByQueryParams = props.query.orderBy ? `&orderBy=${props.query.orderBy}` : "";
        const queryParams = [
            keywordQueryParams,
            levelQueryParams,
            categoryQueryParams,
            subCategoryQueryParams,
            orderByQueryParams,
        ].join("");
        history.push(`/SearchResults?searchType=publicRequest${queryParams}`);
    }, [props.query]);

    const deleteSubjectCondition = useCallback(() => {
        const keywordQueryParams = props.query.keyword ? `&keyword=${props.query.keyword}` : "";
        const courseTypeQueryParams = props.query.courseType ? `&courseType=${props.query.courseType}` : "";
        const orderByQueryParams = props.query.orderBy ? `&orderBy=${props.query.orderBy}` : "";
        const queryParams = [keywordQueryParams, courseTypeQueryParams, orderByQueryParams].join("");
        history.push(`/SearchResults?searchType=publicRequest${queryParams}`);
    }, [props.query]);

    const handleClearButtonClick = useCallback(() => {
        history.push(`/SearchResults?searchType=publicRequest&orderBy=${props.query.orderBy}`);
    }, [props.query]);

    const handleOrderByChange = useCallback(
        (
            e: React.ChangeEvent<{
                value: string;
            }>,
        ) => {
            const newOrder = e.target.value;
            history.push(
                `/SearchResults?searchType=publicRequest&keyword=${keyword}&courseType=${courseType}&level=${subject.level}&category=${subject.category}&subCategory=${subject.subCategory}&orderBy=${newOrder}`,
            );
        },
        [],
    );

    return (
        <>
            <QueryLoadingWrapperOnlyPart {...requestSearchResultQuery}>
                {(requests, isLoaded) => (
                    <RequestSearchResultsComponent
                        subject={subject}
                        query={props.query}
                        keyword={keyword}
                        courseType={courseType}
                        pageNumber={pageNumber}
                        orderBy={orderBy}
                        existCondition={existCondition}
                        setPageNumber={setPageNumber}
                        setKeyword={setKeyword}
                        setCourseType={setCourseType}
                        setSubject={setSubject}
                        search={search}
                        getCourseTypeCondition={getCourseTypeCondition}
                        getSubjectCondition={getSubjectCondition}
                        deleteKeywordCondition={deleteKeywordCondition}
                        deleteCourseTypeCondition={deleteCourseTypeCondition}
                        deleteSubjectCondition={deleteSubjectCondition}
                        handleClearButtonClick={handleClearButtonClick}
                        handleOrderByChange={handleOrderByChange}
                        requests={requests ?? []}
                        isLoaded={isLoaded}
                    />
                )}
            </QueryLoadingWrapperOnlyPart>
        </>
    );
});
