import { SubjectResponse } from "@/store/autogenApi";
import {
    ELEMENTARY_SCHOOL_SUBJECT_CONFIG,
    HIGH_SCHOOL_SUBJECT_CONFIG,
    JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG,
    SubjectConfig,
    UNIVERSITY_SUBJECT_CONFIG,
} from "@/SubjectConfig";

export const getSubject = (subject: SubjectResponse) => {
    let japaneseLevel: string;
    let subjectConfig: SubjectConfig;
    const level = subject.level;
    const category = subject.category;
    const subCategory = subject.subCategory;
    if (level === "university") {
        subjectConfig = UNIVERSITY_SUBJECT_CONFIG;
        japaneseLevel = "大学・社会人";
    } else if (level === "highSchool") {
        subjectConfig = HIGH_SCHOOL_SUBJECT_CONFIG;
        japaneseLevel = "高校";
    } else if (level === "juniorHighSchool") {
        subjectConfig = JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG;
        japaneseLevel = "中学校";
    } else {
        subjectConfig = ELEMENTARY_SCHOOL_SUBJECT_CONFIG;
        japaneseLevel = "小学校";
    }
    if (category && subjectConfig[category]) {
        const japaneseCategory = subjectConfig[category].label;
        const subCategories = subjectConfig[category].sub;
        if (subCategories && subCategory) {
            const japaneseSubCategory = subCategories[subCategory].label;
            return `${japaneseLevel}-${japaneseCategory}-${japaneseSubCategory}`;
        } else {
            return `${japaneseLevel}-${japaneseCategory}`;
        }
    } else {
        return japaneseLevel;
    }
};

export const getLevelAndCategory = (subject: SubjectResponse) => {
    let japaneseLevel: string;
    let subjectConfig: SubjectConfig;
    const level = subject.level;
    const category = subject.category;
    if (level === "university") {
        subjectConfig = UNIVERSITY_SUBJECT_CONFIG;
        japaneseLevel = "大学・社会人";
    } else if (level === "highSchool") {
        subjectConfig = HIGH_SCHOOL_SUBJECT_CONFIG;
        japaneseLevel = "高校";
    } else if (level === "juniorHighSchool") {
        subjectConfig = JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG;
        japaneseLevel = "中学校";
    } else {
        subjectConfig = ELEMENTARY_SCHOOL_SUBJECT_CONFIG;
        japaneseLevel = "小学校";
    }
    if (!category) return japaneseLevel;
    const japaneseCategory = subjectConfig[category].label;
    const levelAndCategory = `${japaneseLevel}-${japaneseCategory}`;
    return levelAndCategory;
};
