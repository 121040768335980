import { FAQConfig } from "@/FAQConfig";

export const TEACHER_VERIFICATION_CONFIGS: FAQConfig[] = [
    {
        title: "先生詳細ページへのアクセス",
        text: "先生アカウントにてマイページの「プロフィール」からアクセスできます。",
    },
    {
        title: "本人確認ページへのアクセス",
        text: "先生詳細ページの「本人確認」をクリックすると、本人確認ページに移動します。",
    },
    {
        title: "必要事項の入力",
        text: "姓名を入力し、本人確認書類をアップロードしてください。終わったら「確認」をクリックします。",
    },
    {
        title: "入力内容の確認",
        text: "入力内容を確認し、問題がなければ「確定」をクリックします。",
    },
    {
        title: "本人確認完了",
        text: "先生詳細画面に本人確認バッジが表示されれば、本人確認完了となります。",
    },
];
