import { useSelector } from "react-redux";

import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { useGetStudentAnnouncementsQuery } from "@/store/hooks/announcements";

import { MyPage } from ".";
import { RootState } from "@/ducks";
import { AnnouncementsContents } from "@/pageComponents/Common/MyPage/Announcements";
import { useGetStudentCheckedAnnouncementIdsQuery } from "@/store/hooks/students";
import { useCallback, useEffect, useState } from "react";
import { useNavigation } from "@/components/Navigation/NavigationContext";
import { Navigation } from "@/components/Navigation";

const NAVIGATION_LIST: Navigation[] = [
    { title: "マイページ", url: "/MyPage/Profile" },
    { title: "お知らせ", url: "/MyPage/Announcements" },
];

export const Announcements: React.VFC = () => {
    const [previousCheckedAnnouncementIds, setPreviousCheckedAnnouncementIds] = useState<string[]>([]);
    const studentId = useSelector((state: RootState) => state.jwt.studentId as string);

    const announcementsQueryState = useGetStudentAnnouncementsQuery(studentId);
    const checkedAnnouncementIdsQueryState = useGetStudentCheckedAnnouncementIdsQuery(studentId);

    const { updateNavigationList } = useNavigation();

    useEffect(() => {
        updateNavigationList(NAVIGATION_LIST);
    }, []);

    const handlePreviousCheckedAnnouncementIdsChange = useCallback((announcementIds: string[]) => {
        setPreviousCheckedAnnouncementIds(announcementIds);
    }, []);

    return (
        <MyPage title="お知らせ" activeTab="announcements">
            <QueryLoadingWrapper {...announcementsQueryState}>
                {(announcements) => (
                    <QueryLoadingWrapper
                        {...checkedAnnouncementIdsQueryState}
                        loadingComponent={
                            <AnnouncementsContents
                                announcements={announcements}
                                checkedAnnouncementIds={previousCheckedAnnouncementIds}
                                handlePreviousCheckedAnnouncementIdsChange={handlePreviousCheckedAnnouncementIdsChange}
                            />
                        }
                    >
                        {(checkedAnnouncementIds) => (
                            <AnnouncementsContents
                                announcements={announcements}
                                checkedAnnouncementIds={checkedAnnouncementIds}
                                handlePreviousCheckedAnnouncementIdsChange={handlePreviousCheckedAnnouncementIdsChange}
                            />
                        )}
                    </QueryLoadingWrapper>
                )}
            </QueryLoadingWrapper>
        </MyPage>
    );
};
