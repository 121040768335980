import { memo, useCallback } from "react";
import styles from "../index.module.scss";
import { Button, Popover, makeStyles } from "@material-ui/core";
import { HourAndMinuteComponent } from "@/components/HourAndMinute";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CreateLessonRequestParams } from "@/store/autogenApi";
import { dateToString } from "@/utils/DateUtils";
import { DatePicker } from "../../../DatePicker";

interface Props {
    open: boolean;
    anchorEl: HTMLElement | null;
    targetLessonId: string | undefined;
    newLessonXIdx: number | undefined;
    isUpdatingTemporaryLesson: boolean;
    newLessonStartTime: Date | undefined;
    newLessonEndTime: Date | undefined;
    isDraggable: boolean;
    handleDeleteButtonClick: () => void;
    handlePopoverClose: () => void;
    handleStartHourChange: (
        e: React.ChangeEvent<{
            value: number;
        }>,
    ) => void;
    handleStartMinuteChange: (
        e: React.ChangeEvent<{
            value: number;
        }>,
    ) => void;
    handleEndHourChange: (
        e: React.ChangeEvent<{
            value: number;
        }>,
    ) => void;
    handleEndMinuteChange: (
        e: React.ChangeEvent<{
            value: number;
        }>,
    ) => void;
    handleNewLessonOnlyDateChange: (date: Date) => void;
    handleLessonModalOpen: () => void;
    handleIsDeleteModeChange: (value: boolean) => void;
    handleCalendarClose?: () => void;
    createNewLessonFromCreateCourse?: (lesson: CreateLessonRequestParams) => void;
    updateNewLessonFromCreateCourse?: (lesson: CreateLessonRequestParams) => void;
    deleteNewLessonFromCreateCourse?: () => void;
}

export const NewLessonPopover: React.VFC<Props> = memo((props) => {
    const getMarginLeft = useCallback(() => {
        if (props.newLessonXIdx === undefined) return "5px";
        if ([0, 1, 2].includes(props.newLessonXIdx)) return "5px";
        return "-5px";
    }, [props.newLessonXIdx]);

    const useGapStyles = makeStyles({
        paper: {
            marginLeft: getMarginLeft(),
        },
    });
    const classes = useGapStyles();

    const getAnchorOriginHorizontal = useCallback(() => {
        if (props.newLessonXIdx === undefined) return "right";
        if ([0, 1, 2].includes(props.newLessonXIdx)) return "right";
        return "left";
    }, [props.newLessonXIdx]);

    const getTransformOriginHorizontal = useCallback(() => {
        if (props.newLessonXIdx === undefined) return "left";
        if ([0, 1, 2].includes(props.newLessonXIdx)) return "left";
        return "right";
    }, [props.newLessonXIdx]);

    const handleConfirmButtonClick = useCallback(() => {
        if (!props.createNewLessonFromCreateCourse || !props.updateNewLessonFromCreateCourse) return;
        if (props.newLessonStartTime == undefined || props.newLessonEndTime == undefined) return;
        if (props.isUpdatingTemporaryLesson) {
            props.updateNewLessonFromCreateCourse({
                startTime: dateToString(props.newLessonStartTime),
                endTime: dateToString(props.newLessonEndTime),
            });
        } else {
            props.createNewLessonFromCreateCourse({
                startTime: dateToString(props.newLessonStartTime),
                endTime: dateToString(props.newLessonEndTime),
            });
        }
        props.handlePopoverClose();
        if (props.handleCalendarClose) {
            props.handleCalendarClose();
        }
    }, [
        props.createNewLessonFromCreateCourse,
        props.updateNewLessonFromCreateCourse,
        props.newLessonStartTime,
        props.newLessonEndTime,
        props.isUpdatingTemporaryLesson,
        props.handlePopoverClose,
        props.handleCalendarClose,
    ]);

    return (
        <Popover
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.handlePopoverClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: getAnchorOriginHorizontal(),
            }}
            transformOrigin={{
                vertical: "center",
                horizontal: getTransformOriginHorizontal(),
            }}
            classes={{ paper: classes.paper }}
        >
            <div className={styles.popOverContents}>
                <div className={styles.titlesWrapper}>
                    <div className={styles.startTimeTitle}>{props.targetLessonId ? "授業更新" : "新規授業"}</div>
                    {(props.targetLessonId || props.deleteNewLessonFromCreateCourse) && (
                        <button className={styles.deleteButton} onClick={props.handleDeleteButtonClick}>
                            <RiDeleteBin6Line className={styles.deleteIcon} />
                        </button>
                    )}
                </div>
                {props.isDraggable && (
                    <div className={styles.popOverDescription}>必要に応じて日時を調整してください。</div>
                )}
                <div className={styles.timeWrapper}>
                    <div className={styles.dateWrapper}>
                        <div className={styles.pickerWrapper}>
                            <DatePicker
                                date={props.newLessonStartTime}
                                handleDateChange={props.handleNewLessonOnlyDateChange}
                            />
                        </div>
                    </div>
                    <div className={styles.startAndFinishWrapper}>
                        <div className={styles.selectsWrapper}>
                            <HourAndMinuteComponent
                                hour={props.newLessonStartTime?.getHours()}
                                minute={props.newLessonStartTime?.getMinutes()}
                                handleHourChange={props.handleStartHourChange}
                                handleMinuteChange={props.handleStartMinuteChange}
                            />
                            <div className={styles.tilde}>~</div>
                            <HourAndMinuteComponent
                                hour={props.newLessonEndTime?.getHours()}
                                minute={props.newLessonEndTime?.getMinutes()}
                                handleHourChange={props.handleEndHourChange}
                                handleMinuteChange={props.handleEndMinuteChange}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.scheduleButtonsWrapper}>
                    <Button className={styles.scheduleCancelButton} onClick={props.handlePopoverClose}>
                        キャンセル
                    </Button>
                    {props.createNewLessonFromCreateCourse && props.updateNewLessonFromCreateCourse ? (
                        <Button className={styles.scheduleConfirmButton} onClick={handleConfirmButtonClick}>
                            確定
                        </Button>
                    ) : (
                        <Button className={styles.scheduleConfirmButton} onClick={props.handleLessonModalOpen}>
                            確認
                        </Button>
                    )}
                </div>
            </div>
        </Popover>
    );
});
